export default {
  attributes: {
    id: {
      type: 'uuid',
      defaultValue: null
    },
    type: {
      type: 'string',
      defaultValue: null // or 'term' or 'product'
    },
    category: {
      type: 'string',
      defaultValue: null // or 'system' or 'part'
    },
    element: {
      type: 'uuid',
      defaultValue: null
    },
    elementVersion: {
      type: 'uuid',
      defaultValue: null
    },
    term: {
      type: 'uuid',
      defaultValue: null
    },
    drawing: {
      type: 'uuid',
      defaultValue: null
    },
    highlightsList: {
      type: 'array',
      defaultValue: []
    },
    curve: {
      type: 'string',
      defaultValue: 'natural'
    },
    pointStyle: {
      type: 'string',
      defaultValue: 'none'
    },
    hasLeadLine: {
      type: 'boolean',
      defaultValue: false
    },
    startX: {
      type: 'number',
      defaultValue: 0
    },
    startY: {
      type: 'number',
      defaultValue: 0
    },
    midX: {
      type: 'number',
      defaultValue: 0
    },
    midY: {
      type: 'number',
      defaultValue: 0
    },
    endX: {
      type: 'number',
      defaultValue: 0
    },
    endY: {
      type: 'number',
      defaultValue: 0
    },
    labelOrientation: {
      type: 'string',
      defaultValue: 'auto' // bottom, left, right, auto
    },
    createdAt: {
      type: 'date'
    }
  }
}
