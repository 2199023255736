import Component from '@glimmer/component';
import { action } from '@ember/object';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

class SessionTimer extends Component {
  @tracked time = 0;
  @service sessionTime;

  get classNames() {
    let classNames = [this.styleNamespace, 'session-timer'];
    return classNames.join(' ');
  }

  get styleNamespace() {
    return podNames['session-timer'];
  }

  @action
  didInsert() {
    this.sessionTime.setup(this.args.disclosureId);
    this._handleMouseleave = this.handleMouseleave.bind(this);
    document.addEventListener('mouseleave', this.handleMouseleave);
  }

  @action
  handleMouseleave() {
    this.sessionTime.update(true);
    // console.log('leave');
  }

  @action
  willDestroyNode() {
    this.sessionTime.update();
    this.sessionTime.clear();
    document.removeEventListener('mouseleave', this.handleMouseleave);
    // console.log('before destroy');
  }
}

export default SessionTimer;
