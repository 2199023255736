import { action, computed } from '@ember/object';

import Component from '@glimmer/component';
import { connect } from 'ember-redux';
import { getInventionUi } from '../../../selectors/invention-ui';
import { getPriorArt } from '../../../selectors/prior-art';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

const stateToComputed = (state, attrs) => ({
  priorArt: getPriorArt(state, attrs.priorArtId),
  ui: getInventionUi(state)
});

const dispatchToActions = {};

class PriorArtEditor extends Component {
  @tracked _name;
  @tracked _description;
  @tracked _link;
  @tracked _conceivedAt;
  @tracked _asset;

  @service data;
  
  changedAttributes = {};

  get styleNamespace() {
    return podNames['prior-art-editor'];
  }

  @computed('ui.updatingPriorArtAsset')
  get updatingPriorArtAsset() {
    return this.ui && this.ui.updatingPriorArtAsset;
  }
  @action
  setUpdatingPriorArtAsset() {
    this.data.setUpdatingPriorArtAsset(true);
  }

  @action
  onAssetChange(assetId) {
    this._asset = assetId;
    this.changedAttributes['asset'] = assetId;
    this.data.setUpdatingPriorArtAsset(false);
  }
}

export default connect(stateToComputed, dispatchToActions)(PriorArtEditor);
