import { action, computed } from '@ember/object';
import {
  defaultArtboardHeight,
  defaultArtboardWidth,
} from '../../../constants/settings';
import {
  getDefaultMarkerCategory,
  getInventionUi,
  getPreviewMode,
  getSelectedElementVersions,
  getSelectedElements,
  getSelectedTerms,
  getContextActive,
  getContextWidth,
  getShowingExplorer,
  getExplorerWidth
} from '../../../selectors/invention-ui';
import { getElement, getElementInstanceOf } from '../../../selectors/element';
import { keyResponder, onKey } from 'ember-keyboard';
import { restartableTask, task } from 'ember-concurrency-decorators';

import Component from '@glimmer/component';
import ENV from '../../../config/environment';
import Konva from 'konva';
import { connect } from 'ember-redux';
import { debounce } from '@ember/runloop';
import { getDrawing } from '../../../selectors/drawing';
import { getImage } from '../../../selectors/image';
import { getMarker } from '../../../selectors/marker';
import { getMarkers } from '../../../selectors/marker';
import { getPreferredElementVersionId } from '../../../selectors/product';
import { htmlSafe } from '@ember/string';
import { omit } from 'lodash';
import podNames from 'ember-component-css/pod-names';
import { reverse } from 'lodash';
import { inject as service } from '@ember/service';
import { timeout } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import { union } from '@ember/object/computed';
import uuid from 'uuid/v4';

const dispatchToActions = {};

const stateToComputed = (state, attrs) => ({
  drawing: getDrawing(state, attrs.drawingId),
  ui: getInventionUi(state),
  selectedTerms: getSelectedTerms(state),
  selectedElements: getSelectedElements(state),
  selectedElementVersions: getSelectedElementVersions(state),
  allMarkers: getMarkers(state),
  defaultMarkerCategory: getDefaultMarkerCategory(state),
  previewMode: getPreviewMode(state),
  contextActive: getContextActive(state),
  contextWidth: getContextWidth(state),
  showingExplorer: getShowingExplorer(state),
  explorerWidth: getExplorerWidth(state),
});

@keyResponder
class DrawingEditorKonva extends Component {
  @service redux;
  @service models;
  @service assets;
  @service data;
  @service testing;
  @service applicationState;
  @service tracking;

  defaultArtboardWidth = defaultArtboardWidth;
  defaultArtboardHeight = defaultArtboardHeight;

  containerDomElementId = `canvas-container-${uuid()}`;
  verticalPadding = 19.5;
  nodePadding = 25;
  tweens = [];

  minScale = 0.05;
  maxScale = 1.05;

  @tracked markerCreateMode = false;
  @tracked bulkCreateMode = false;
  @tracked highlightCreateMode = false;
  @tracked highlightPoints = [];
  @tracked creatingHighlightPoints = [];

  @tracked didFirstRender = false;
  @tracked onCanvas = false;
  @tracked createMode = false;
  @tracked stage;
  @tracked layer;
  @tracked mouseX;
  @tracked mouseY;
  @tracked stageScale;
  @tracked stageX;
  @tracked stageY;
  @tracked stageWidth = 500;
  @tracked stageHeight = 500;
  @tracked visibleAreaIndex = 0;

  @tracked selectedBulkCreateItemId;
  @tracked bulkCreateItems = {
    ids: [],
    entities: {},
  };

  willDestroy() {
    this.stage.off('dragend');
    this.stage.destroy();
  }

  get styleNamespace() {
    return podNames['drawing'];
  }

  get classNames() {
    let classNames = ['drawing-editor', this.styleNamespace];
    if (this.handToolMode) classNames.push('hand-tool');
    if (this.markerCreateMode) classNames.push('marker-create-mode');
    if (this.bulkCreateMode) classNames.push('bulk-create-mode');
    if (this.highlightCreateMode) classNames.push('highlight-create-mode');
    return classNames.join(' ');
  }

  get bulkCreateItemsList() {
    return (this.bulkCreateItems && this.bulkCreateItems.ids) || [];
  }

  @action
  onResetBulkCreateItems() {
    this.bulkCreateItems = {
      ids: [],
      entities: {},
    };
    this.onAddBulkCreateItem();
  }

  @action
  onAddBulkCreateItem() {
    const bulkCreateItemId = uuid();
    const bulkCreateItem = {
      id: bulkCreateItemId,
      category: this.defaultMarkerCategory,
      name: '',
      resultId: null,
      resultType: null,
      resultElementVersion: null,
    };
    this.bulkCreateItems = {
      ids: [...this.bulkCreateItems.ids, bulkCreateItemId],
      entities: {
        ...this.bulkCreateItems.entities,
        [bulkCreateItemId]: bulkCreateItem,
      },
    };
    this.selectedBulkCreateItemId = bulkCreateItemId;
  }

  @action
  onRemoveBulkCreateItem() {
    const bulkCreateItemId = this.selectedBulkCreateItemId;
    const removedIndex = this.bulkCreateItemsList.findIndex(
      (id) => bulkCreateItemId === id
    );

    let selectedItemId;

    if (this.bulkCreateItemsList.length > 1) {
      if (removedIndex === 0) {
        selectedItemId = this.bulkCreateItemsList[0];
      } else {
        selectedItemId = this.bulkCreateItemsList[removedIndex - 1];
      }
    }

    this.bulkCreateItems = {
      ids: this.bulkCreateItems.ids.filter((id) => bulkCreateItemId !== id),
      entities: omit(this.bulkCreateItems.entities, [bulkCreateItemId]),
    };

    this.selectedBulkCreateItemId = selectedItemId;
  }

  @action
  onUpdateBulkCreateItem(bulkCreateItemId, attributes) {
    this.bulkCreateItems = {
      ...this.bulkCreateItems,
      entities: {
        ...this.bulkCreateItems.entities,
        [bulkCreateItemId]: {
          ...this.bulkCreateItems.entities[bulkCreateItemId],
          ...attributes,
        },
      },
    };
  }

  @action
  onAddBulkCreateItems() {
    this.createMarkers(true);
    return this.closeCreateMode();
  }

  @action
  onSelectBulkCreateItem(bulkCreateItemId) {
    this.selectedBulkCreateItemId = bulkCreateItemId;
  }

  resetBulkCreateItems() {
    this.bulkCreateItems = {
      ids: [],
      entities: {},
    };
  }

  @computed('drawing.markersList')
  get markersList() {
    return this.drawing && this.drawing.markersList;
  }

  @computed('drawing.imagesList')
  get imagesList() {
    return this.drawing && this.drawing.imagesList;
  }

  @computed('ui.selectedMarkers')
  get selectedMarkers() {
    return this.ui && this.ui.selectedMarkers;
  }

  @computed('selectedMarkers.length')
  get selectedMarkerId() {
    return this.selectedMarkers.length === 1 && this.selectedMarkers[0];
  }

  @computed('selectedMarkerId', 'allMarkers')
  get selectedMarker() {
    const state = this.redux.getState();
    return this.selectedMarkerId && getMarker(state, this.selectedMarkerId);
  }

  @computed('selectedMarker')
  get notSelectMarker() {
    return !this.selectedMarker;
  }

  @computed('selectedMarker.highlightsList')
  get highlightsList() {
    return this.selectedMarker ? this.selectedMarker.highlightsList : [];
  }

  @computed('ui.selectedImages.[]')
  get selectedImages() {
    return this.ui && this.ui.selectedImages;
  }

  @computed('selectedImages.[]')
  get selectedImageId() {
    return (
      this.selectedImages &&
      this.selectedImages.length === 1 &&
      this.selectedImages[0]
    );
  }

  @union('selectedImages', 'selectedMarkers')
  selectedItems;

  // eslint-disable-next-line ember/require-computed-property-dependencies
  @computed('selectedItems.[]')
  get selectedNodes() {
    return (
      this.selectedItems &&
      this.selectedItems.map((itemId) => {
        return this.stage.findOne(`#${itemId}`);
      })
    );
  }

  @computed('selectedItems.length')
  get isMultiselected() {
    return this.selectedItems && this.selectedItems.length > 1;
  }

  @computed('selectedMarkers.[]')
  get selectedMarkerModels() {
    return this.selectedMarkers.map((id) => this.models.find(id));
  }

  @computed('selectedImages.[]')
  get selectedImageModels() {
    return this.selectedImages.map((id) => this.models.find(id));
  }

  @computed('drawing.orientation')
  get orientation() {
    return this.drawing && this.drawing.orientation;
  }

  @computed('applicationState.cmdKeyDown')
  get cmdKeyDown() {
    return this.applicationState.cmdKeyDown;
  }

  @computed('applicationState.shiftKeyDown')
  get shiftKeyDown() {
    return this.applicationState.shiftKeyDown;
  }

  @computed('shiftKeyDown')
  get multiSelectMode() {
    return this.shiftKeyDown;
  }

  get handToolMode() {
    return this.spacebarKeyDown;
  }

  @computed('domElementId')
  get artboardMaskId() {
    return `artboard-mask-${this.domElementId}`;
  }

  @computed('artboardWidth')
  get artboardX() {
    return (-1 * this.artboardWidth) / 2;
  }

  @computed('artboardHeight')
  get artboardY() {
    return (-1 * this.artboardHeight) / 2;
  }

  @computed('orientation', 'defaultArtboardWidth', 'defaultArtboardHeight')
  get artboardWidth() {
    return this.orientation === 'portrait'
      ? this.defaultArtboardWidth
      : this.defaultArtboardHeight;
  }

  @computed('orientation', 'defaultArtboardWidth', 'defaultArtboardHeight')
  get artboardHeight() {
    return this.orientation === 'portrait'
      ? this.defaultArtboardHeight
      : this.defaultArtboardWidth;
  }

  @computed('artboardX', 'artboardY', 'domElementId', 'x', 'y')
  get artboardOffset() {
    // const artboardOffset = $(`#artboard${this.domElementId}`).offset();
    // const graphOffset = $(`#drawing-canvas-${this.domElementId}`).offset();

    // return {
    //   x: artboardOffset.left - graphOffset.left,
    //   y: artboardOffset.top - graphOffset.top,
    // };phOffset = $(`#drawing-canvas-${this.domElementId}`).offset();

    return {
      x: 0,
      y: 0,
    };
  }

  @computed('artboardOffset.{x,y}')
  get figureTitleStyle() {
    const top = this.artboardOffset.y;
    const left = this.artboardOffset.x;
    return htmlSafe(`top: ${top}px; left: ${left}px;`);
  }

  @computed('highlightPoints.[]', 'graphMouseX', 'graphMouseY')
  get highlightPointsString() {
    let highlightPointsString = '';
    this.highlightPoints.forEach((point) => {
      highlightPointsString = highlightPointsString + `${point.x},${point.y} `;
    });
    return highlightPointsString + `${this.graphMouseX},${this.graphMouseY} `;
  }

  @computed('ui.showingImageUploadModal')
  get showingImageUploadModal() {
    return this.ui && this.ui.showingImageUploadModal;
  }

  @computed('highlightsList.[]')
  get reverseHighlightsList() {
    const highlightsList = [...this.highlightsList];
    return reverse(highlightsList);
  }

  @computed('imagesList.[]')
  get reverseImagesList() {
    const imagesList = [...this.imagesList];
    return reverse(imagesList);
  }

  @computed('reverseImagesList.[]')
  get images() {
    const state = this.redux.getState();
    return this.reverseImagesList.map((imageId) => getImage(state, imageId));
  }

  @computed('allMarkers', 'markersList.[]')
  get markers() {
    const state = this.redux.getState();
    return this.markersList.map((markerId) => getMarker(state, markerId));
  }

  @computed('selectedTerms.[]')
  get selectedTerm() {
    return this.selectedTerms.length === 1 && this.selectedTerms[0];
  }

  @computed('selectedElements.[]')
  get selectedElement() {
    return this.selectedElements.length === 1 && this.selectedElements[0];
  }

  get activeContext() {
    return this.selectedElement ||
      this.selectedTerm ||
      this.selectedMarkerId ||
      this.selectedImageId
      ? true
      : false;
  }

  @computed(
    'selectedElementVersions.[]',
    'selectedElement.elementVersionsList.[]'
  )
  get selectedElementVersion() {
    let selectedElementVersion;
    if (this.selectedElement) {
      const state = this.redux.getState();
      const element = getElement(state, this.selectedElement);
      selectedElementVersion =
        element.elementVersionsList.length === 1 &&
        element.elementVersionsList[0];
    } else {
      selectedElementVersion =
        this.selectedElementVersions.length === 1 &&
        this.selectedElementVersions[0];
    }
    return selectedElementVersion;
  }

  @onKey('Escape', { event: 'keydown' })
  handleEscDown(keyboardEvent) {
    keyboardEvent.preventDefault();
    this.closeCreateMode();
  }

  @onKey('Space', { event: 'keydown' })
  handleSpacebarDown(/* keyboardEvent */) {
    this.spacebarKeyDown = true;
  }

  @onKey('Space', { event: 'keyup' })
  handleSpacebarUp(/* keyboardEvent */) {
    this.spacebarKeyDown = false;
  }

  fitStageToContainer() {
    const stage = this.stage;
    const container = document.getElementById(this.containerDomElementId);
    const width = container.offsetWidth;
    const height = container.offsetHeight;
    stage.width(width);
    stage.height(height);
    this.stageWidth = width;
    this.stageHeight = height;
    stage.batchDraw();
  }

  handleStageDragEnd() {
    // debounce(this, this.updateTreeCoordinates, 500);
    this.tracking.trackEvent('drawing_panned');
  }

  handleStageDragMove() {
    this.stageX = this.stage.x();
    this.stageY = this.stage.y();
  }

  handleWheel(e) {
    e.evt.preventDefault();
    const stage = e.target.getStage();
    const oldScale = stage.scaleX();
    const mousePointTo = {
      x: stage.getPointerPosition().x / oldScale - stage.x() / oldScale,
      y: stage.getPointerPosition().y / oldScale - stage.y() / oldScale,
    };
    const deltaY = e.evt.deltaY;
    const scaleBy = 1.17;
    let newScale = deltaY < 0 ? oldScale * scaleBy : oldScale / scaleBy;

    // constrain
    if (newScale > this.maxScale) newScale = this.maxScale;
    if (newScale < this.minScale) newScale = this.minScale;

    const newPos = {
      x: stage.getPointerPosition().x - mousePointTo.x * newScale,
      y: stage.getPointerPosition().y - mousePointTo.y * newScale,
    };
    stage.scale({ x: newScale, y: newScale });
    stage.position(newPos);
    this.stageScale = newScale;
    this.stageX = newPos.x;
    this.stageY = newPos.y;
    stage.batchDraw();
    // debounce(this, this.updateTreeCoordinates, 500);
  }

  handleMouseup(/*event*/) {
    // if (this.actionMode) {
    //   if (
    //     this.hoveringElementId &&
    //     this.hoveringElementId !== this.creatingElementId
    //   ) {
    //     this.createModeClickElement(this.hoveringElementId);
    //   } else if (
    //     this.hoveringElementVersionId &&
    //     this.hoveringElementVersionId !== this.creatingElementVersionId
    //   ) {
    //     this.createModeClickElementVersion(this.hoveringElementVersionId);
    //   } else if (!this.hoveringElementId && !this.hoveringElementVersionId) {
    //     this.createFromBackground(event);
    //   }
    // }
  }

  handleBackgroundClick(/*event*/) {
    if (this.highlightCreateMode) {
      return this.addCreatingHighlightPoint();
    }

    if (this.createMode || this.cmdKeyDown) {
      // this.createFromBackground(event);
    } else {
      this.deselectAll();
    }
  }

  @restartableTask()
  *debounceUpdateImagesAndMarkers(
    imageModels,
    markerModels,
    DEBOUNCE_MS = ENV.DEBOUNCE_MS
  ) {
    yield timeout(DEBOUNCE_MS);

    const images = imageModels.map((model) => {
      return {
        id: model.id,
        attributes: {
          x: model.x,
          y: model.y,
        },
      };
    });

    const markers = markerModels.map((model) => {
      return {
        id: model.id,
        attributes: {
          startX: model.startX,
          startY: model.startY,
          midX: model.midX,
          midY: model.midY,
          endX: model.endX,
          endY: model.endY,
        },
      };
    });

    this.args.onUpdateDrawingItems(this.args.drawingId, images, markers);
  }

  @task()
  *addImageFromFile(file, externalUrl = '') {
    const fileObj = { file, externalUrl };
    const parsedAssets = yield this.data.addAssetsFromFiles([fileObj]);
    this.args.onAddImageFromAsset(this.args.drawingId, parsedAssets);
  }

  createMarker() {
    const { x, y } = this.getPointerPosition();

    const attributes = {
      drawing: this.args.drawingId,
      startX: x,
      startY: y,
      midX: x,
      midY: y,
      endX: x,
      endY: y,
      type: this.defaultMarkerCategory === 'term' ? 'term' : 'element',
      category: this.defaultMarkerCategory,
    };

    if (this.selectedTerm) {
      attributes['term'] = this.selectedTerm;
      attributes['type'] = 'term';
      attributes['category'] = 'term';
    }

    if (this.selectedElement) {
      const state = this.redux.getState();
      const elementId = this.selectedElement;
      const element = getElement(state, elementId);
      const preferredElementVersionId = getPreferredElementVersionId(
        state,
        elementId
      );

      attributes['element'] = elementId;
      attributes['elementVersion'] = preferredElementVersionId;
      attributes['type'] = 'element';
      attributes['category'] = element.category;

      if (element.component) {
        const instanceOf = getElementInstanceOf(state, elementId);
        attributes['element'] = instanceOf.id;
        attributes['category'] = instanceOf.category;
      }
    }

    console.log('attributes', attributes)
    this.args.onCreateMarker(this.args.drawingId, attributes);
  }

  getPointerPosition() {
    const stage = this.stage;
    const pointerPos = stage.getPointerPosition();
    const scale = stage.scaleX();
    const x = pointerPos.x / scale - stage.x() / scale;
    const y = pointerPos.y / scale - stage.y() / scale;
    return { x, y };
  }

  createMarkers(center = false) {
    const pointerPos = this.getPointerPosition();
    const x = center ? 0 : pointerPos.x;
    const y = center ? 0 : pointerPos.y;
    const markersData = [];

    this.bulkCreateItemsList.forEach((bulkCreateItemId, index) => {
      const bulkCreateItem = this.bulkCreateItems.entities[bulkCreateItemId];
      // TODO: better to use voronoi tessellation to avoid overlap?
      const randomX = index ? Math.floor(x + (Math.random() * 150 - 75)) : x;
      const randomY = index ? Math.floor(y + (Math.random() * 150 - 75)) : y;
      const attributes = {
        drawing: this.args.drawingId,
        startX: randomX,
        startY: randomY,
        midX: randomX,
        midY: randomY,
        endX: randomX,
        endY: randomY,
        type: bulkCreateItem.category === 'term' ? 'term' : 'element',
        category: bulkCreateItem.category,
      };

      if (
        bulkCreateItem &&
        bulkCreateItem.resultId &&
        bulkCreateItem.resultType === 'term'
      ) {
        attributes['term'] = bulkCreateItem.resultId;
      }

      if (
        bulkCreateItem &&
        bulkCreateItem.resultId &&
        bulkCreateItem.resultType === 'element'
      ) {
        attributes['element'] = bulkCreateItem.resultId;
        attributes['elementVersion'] = bulkCreateItem.resultElementVersion;
        attributes['category'] = bulkCreateItem.category;
      }

      if (bulkCreateItem && !bulkCreateItem.resultId && bulkCreateItem.name) {
        attributes.name = bulkCreateItem.name;
      }

      markersData.push(attributes);
    });
    this.args.onCreateMarkers(this.args.drawingId, markersData);
  }

  closeCreateMode() {
    this.markerCreateMode = false;
    this.bulkCreateMode = false;
    this.highlightCreateMode = false;
    this.creatingHighlightPoints = [];
  }

  deselectAll() {
    if (
      this.selectedItems.length ||
      this.selectedTerm ||
      this.selectedElement ||
      this.selectedElementVersion
    ) {
      this.args.onDeselectDrawingItems(this.args.drawingId);
      this.tracking.trackEvent('drawing_deselected_all');
    }
  }

  addCreatingHighlightPoint() {
    const { x, y } = this.getPointerPosition();
    this.creatingHighlightPoints.pushObject({ x, y });
    this.tracking.trackEvent('highlight_added_point');
  }

  @action
  setCreatingImageAsset() {
    this.args.onSetCreatingImageAsset(this.args.drawingId);
  }

  @action
  addHighlight(point) {
    if (!this.selectedMarkerId) {
      return;
    }
    const pointsArray = [...this.creatingHighlightPoints, point];

    let points = pointsArray.map((point) => {
      const x = Math.round(point.x);
      const y = Math.round(point.y);
      return `${x},${y}`;
    });

    points = points.join(' ');

    const attributes = { points };

    this.args.onAddHighlight(
      this.args.drawingId,
      this.selectedMarkerId,
      attributes
    );

    this.creatingHighlightPoints = [];
    this.highlightCreateMode = false;

    this.tracking.trackEvent('created_highlight');
  }

  @action
  toggleHighlightCreateMode() {
    if (this.highlightCreateMode) {
      this.closeCreateMode();
      this.tracking.trackEvent('drawing_left_highlight_create_mode');
    } else {
      this.closeCreateMode();
      this.tracking.trackEvent('drawing_entered_highlight_create_mode');
      this.highlightCreateMode = true;
    }
    this.creatingHighlightPoints = [];
  }

  @action
  toggleMarkerCreateMode() {
    if (this.markerCreateMode) {
      this.closeCreateMode();
      this.tracking.trackEvent('drawing_left_marker_create_mode');
    } else {
      this.closeCreateMode();
      this.tracking.trackEvent('drawing_entered_marker_create_mode');
      this.markerCreateMode = true;
    }
  }

  @action
  toggleBulkCreateMode() {
    if (this.bulkCreateMode) {
      this.closeCreateMode();
      this.tracking.trackEvent('drawing_left_bulk_create_mode');
    } else {
      this.closeCreateMode();
      this.tracking.trackEvent('drawing_entered_bulk_create_mode');
      this.onResetBulkCreateItems();
      this.bulkCreateMode = true;
    }
  }

  @action
  onImageClick(imageId, isSelected) {
    if (this.highlightCreateMode) {
      return this.addCreatingHighlightPoint();
    }

    if (this.markerCreateMode) {
      this.createMarker();
      return this.closeCreateMode();
    }

    if (this.bulkCreateMode) {
      this.createMarkers();
      return this.closeCreateMode();
    }

    if (this.cmdKeyDown) {
      return this.createMarker();
    }

    if (isSelected) {
      if (this.multiSelectMode) {
        this.args.onDeselectImage(imageId);
      } else {
        this.args.onDeselectDrawingItems(this.args.drawingId);
        this.tracking.trackEvent('drawing_deselected_all');
      }
    } else {
      this.args.onSelectImage(imageId, this.multiSelectMode);
      this.tracking.trackEvent('selected_image');
    }
  }

  @action
  onLabelClick(elementId, elementVersionId, termId) {
    if (this.highlightCreateMode) {
      return this.addCreatingHighlightPoint();
    }

    if (this.markerCreateMode) {
      this.createMarker();
      return this.closeCreateMode();
    }

    if (this.cmdKeyDown) {
      return this.createMarker();
    }

    if (termId) {
      this.args.onSelectTerm(termId);
      this.tracking.trackEvent('marker_label_selected_term');
    } else {
      this.args.onSelectElement(elementId);
      this.tracking.trackEvent('marker_label_selected_system');
    }
  }

  @action
  onMarkerClick(markerId, isSelected) {
    if (this.highlightCreateMode) {
      return this.addCreatingHighlightPoint();
    }

    if (this.markerCreateMode) {
      this.createMarker();
      return this.closeCreateMode();
    }

    if (this.bulkCreateMode) {
      this.createMarkers();
      return this.closeCreateMode();
    }

    if (this.cmdKeyDown) {
      return this.createMarker();
    }

    if (isSelected) {
      if (this.multiSelectMode) {
        this.args.onDeselectMarker(markerId);
      } else {
        this.args.onDeselectDrawingItems(this.args.drawingId);
        this.tracking.trackEvent('drawing_deselected_all');
      }
    } else {
      this.args.onSelectMarker(markerId, this.multiSelectMode);
      this.tracking.trackEvent('selected_marker');
    }
  }

  @action
  onArtboardClick() {
    if (this.cmdKeyDown) {
      return this.createMarker();
    }
    if (this.markerCreateMode) {
      this.createMarker();
      this.closeCreateMode();
    } else if (this.bulkCreateMode) {
      this.createMarkers();
      this.closeCreateMode();
    } else if (this.highlightCreateMode) {
      this.addCreatingHighlightPoint();
    } else {
      this.deselectAll();
    }
  }

  @action
  onHighlightArtboardClick() {
    // TODO !!
    let x = 0;
    let y = 0;

    if (!this.highlightPoints) {
      this.highlightPoints = [];
    }

    this.highlightPoints.pushObject({ x, y });
  }

  @action
  onFigureClick() {
    this.deselectAll();
  }

  @action
  onMultiselectDrag(dx, dy, draggingId) {
    this.selectedNodes.forEach((node) => {
      if (node.id() !== draggingId) {
        const x = node.x() + dx;
        const y = node.y() + dy;

        node.x(x);
        node.y(y);
      }
    });
    // return;
    // this.selectedImageModels.forEach((model) => {
    //   model.setProperties({
    //     x: model.x + dx,
    //     y: model.y + dy,
    //   });
    // });

    // this.selectedMarkerModels.forEach((model) => {
    //   model.setProperties({
    //     startX: model.startX + dx,
    //     startY: model.startY + dy,
    //     midX: model.midX + dx,
    //     midY: model.midY + dy,
    //     endX: model.endX + dx,
    //     endY: model.endY + dy,
    //   });
    // });

    // this.debounceUpdateImagesAndMarkers.perform(
    //   this.selectedImageModels,
    //   this.selectedMarkerModels
    // );
  }

  @action
  onMultiselectDragEnd() {
    const images = [];
    const markers = [];

    this.selectedNodes.forEach((node) => {
      const x = node.x();
      const y = node.y();
      const nodeType = node.getAttr('nodeType');
      const nodeId = node.id();

      switch (nodeType) {
        case 'image':
          images.push({
            id: nodeId,
            attributes: { x, y },
          });
          break;
        case 'marker': {
          const children = node.getChildren();
          const startNode = children.find((child) => child.name() === 'start');
          const midNode = children.find((child) => child.name() === 'mid');
          const endNode = children.find((child) => child.name() === 'end');

          const startX = startNode.x() + x;
          const startY = startNode.y() + y;

          const midX = midNode.x() + x;
          const midY = midNode.y() + y;

          const endX = endNode.x() + x;
          const endY = endNode.y() + y;

          markers.push({
            id: nodeId,
            attributes: {
              startX,
              startY,
              midX,
              midY,
              endX,
              endY,
            },
          });

          break;
        }
      }
    });

    this.args.onUpdateDrawingItems(this.args.drawingId, images, markers);
  }

  @action
  removeSelected() {
    if (!this.selectedItems.length) {
      return;
    }

    this.args.onRemoveSelectedDrawingItems(
      this.args.drawingId,
      this.selectedImages,
      this.selectedMarkers,
      []
    );

    this.tracking.trackEvent('drawing_removed_selected');
  }

  @action
  onTogglePreviewMode() {
    if (this.previewMode) {
      this.markerCreateMode = false;
      this.bulkCreateMode = false;
      this.highlightCreateMode = false;
    }
  }

  @action
  setup() {
    // let { x, y, k } = this.versionTreeCoordinates;

    let x = 0;
    let y = 0;
    let k = 1;

    const stage = new Konva.Stage({
      container: this.containerDomElementId,
      width: this.stageWidth,
      height: this.stageHeight,
      x,
      y,
      scale: {
        x: k,
        y: k,
      },
      draggable: true,
    });

    this.stageScale = k;
    this.stageX = x;
    this.stageY = y;

    stage.on('dragend', () => this.handleStageDragEnd());
    stage.on('dragmove', () => this.handleStageDragMove());

    // create up to 5 layers for better performance
    // add the layers to the stage
    const artboardLayer = new Konva.Layer({ name: 'artboard' });
    const imagesLayer = new Konva.Layer({ name: 'images' });
    const markersLayer = new Konva.Layer({ name: 'markers' });
    const highlightsLayer = new Konva.Layer({ name: 'highlights' });

    stage.add(artboardLayer);
    stage.add(imagesLayer);
    stage.add(markersLayer);
    stage.add(highlightsLayer);

    // add stage listeners
    stage.on('wheel', (event) => this.handleWheel(event));
    stage.on('mouseenter', () => (this.onCanvas = true));
    stage.on('mouseleave', () => (this.onCanvas = false));
    stage.on('mousemove', (event) => this.handleMouseMove(event));
    stage.on('click', (event) => {
      if (event.target === stage) {
        this.handleBackgroundClick(event);
      }
    });
    stage.on('mouseup', (event) => this.handleMouseup(event));

    this.artboardLayer = imagesLayer;
    this.imagesLayer = imagesLayer;
    this.markersLayer = markersLayer;
    this.highlightsLayer = highlightsLayer;
    // this.elementVersionsLayer = elementVersionsLayer;
    this.stage = stage;

    if (ENV.environment === 'test') {
      this.testing.drawingStage = this.stage;
    }

    // fit the canvas to the container
    this.fitStageToContainer();
    this.fitToScreen();
  }

  centerGraph() {
    // const { x, y, k } = this.versionTreeCoordinates;
    // if (x === 0 && y === 0 && k === 0) {
    //   this.fitToNode(null, false);
    // }
  }

  incrementVisibleAreaIndex() {
    this.visibleAreaIndex++;
  }

  @action
  onVisibleAreaChanged() {
    debounce(this, this.incrementVisibleAreaIndex, 10);
  }

  handleMouseMove(event) {
    this.mouseX = event.evt.layerX;
    this.mouseY = event.evt.layerY;
  }

  @action
  onScheduleRender() {
    debounce(this, this.scheduleRender, 10);
  }

  scheduleRender(layer) {
    if (layer) {
      layer.batchDraw();
    } else {
      this.artboardLayer.batchDraw();
      this.imagesLayer.batchDraw();
      this.markersLayer.batchDraw();
      this.highlightsLayer.batchDraw();
    }

    if (!this.didFirstRender) {
      this.didFirstRender = true;
    }
  }

  moveStage(stage, location, scale, animate = true) {
    const { x, y } = location;

    if (animate) {
      const tween = new Konva.Tween({
        duration: 0.35,
        easing: Konva.Easings.EaseInOut,
        node: stage,
        scaleX: (scale && scale.x) || 1,
        scaleY: (scale && scale.y) || 1,
        x,
        y,
        onUpdate: () => {
          this.stageX = stage.x();
          this.stageY = stage.y();
          this.stageScale = stage.scaleX();
        },
      });
      tween.play();
      stage.batchDraw();

      // debounce(this, this.updateTreeCoordinates, 350);
    } else {
      stage.scale(scale);
      stage.x(x);
      stage.y(y);
      this.stageX = stage.x();
      this.stageY = stage.y();
      this.stageScale = stage.scaleX();
      // this.updateTreeCoordinates;
    }
  }

  @action
  onActionMode(/*elem, [actionMode]*/) {
    // this.creatingElementId = null;
    // this.creatingElementVersionId = null;
  }

  @action
  onUndo(/*elem, [actionMode]*/) {
    // this.creatingElementId = null;
    // this.creatingElementVersionId = null;
    this.closeCreateMode();
  }

  @action
  zoomIn() {
    const stage = this.stage;
    const scaleBy = 1.07;
    const oldScale = stage.scaleX();
    let newScale = scaleBy * oldScale;
    // constrain
    if (newScale > this.maxScale) newScale = this.maxScale;

    stage.scale({ x: newScale, y: newScale });
    stage.batchDraw();
    this.stageScale = newScale;
    this.stageX = stage.x();
    this.stageY = stage.y();
    this.tracking.trackEvent('drawing_zoomed_in');
  }

  @action
  zoomOut() {
    const stage = this.stage;
    const scaleBy = 0.93;
    const oldScale = stage.scaleX();
    let newScale = scaleBy * oldScale;
    // constrain
    if (newScale < this.minScale) newScale = this.minScale;
    stage.scale({ x: newScale, y: newScale });
    this.stageScale = newScale;
    this.stageX = stage.x();
    this.stageY = stage.y();
    stage.batchDraw();
    this.tracking.trackEvent('drawing_zoomed_out');
  }

  @action
  removeSelectedItems() {}

  @action
  onUpdateMarker(markerId, attributes) {
    this.args.onUpdateMarker(this.args.drawingId, markerId, attributes);
  }

  @action
  onUpdateHighlight(markerId, highlightId, attributes) {
    this.args.onUpdateHighlight(markerId, highlightId, attributes);
  }

  @action
  enterCreateMode() {
    this.createMode = true;
  }

  @action
  leaveCreateMode() {
    this.createMode = false;
  }

  @action
  onResize() {
    this.fitStageToContainer();
  }

  @action
  fitToNode(nodeId, animate = true) {
    const stage = this.stage;
    const scale = 0.35;
    const width = stage.width();
    const height = stage.height();
    const originalScale = stage.scaleX();

    let rect = {
      height: 183.6,
      width: 183.6,
      x: -91.8,
      y: -91.8,
    };

    stage.scale({
      x: scale,
      y: scale,
    });

    if (nodeId) {
      const node = stage.findOne(`#${nodeId}`);
      rect = node.getClientRect({ relativeTo: 'stage' });
    }

    const delta = {
      x: width / 2 - (rect.x + rect.width / 2),
      y: height / 2 - (rect.y + rect.height / 2),
    };

    const x = stage.x();
    const y = stage.y();

    const stageCenter = {
      x: x + delta.x,
      y: y + delta.y,
    };

    stage.scale({
      x: originalScale,
      y: originalScale,
    });

    this.moveStage(stage, stageCenter, { x: scale, y: scale }, animate);
  }

  get bounds() {
    const width = defaultArtboardWidth;
    const height = defaultArtboardHeight;
    const x = width / 2;
    const y = height / 2;

    return {
      x,
      y,
      width,
      height,
    };
  }

  @action
  onFitToScreen() {
    this.fitToScreen();
    this.tracking.trackEvent('drawing_zoomed_fit');
  }

  @computed('showingExplorer', 'explorerWidth')
  get drawingOptionsStyle() {
    const explorerWidth = this.showingExplorer ? this.explorerWidth : 0;
    return htmlSafe(`margin-left:${explorerWidth}px`);
  }

  fitToScreen() {
    const stage = this.stage;
    const bounds = this.bounds;
    const padding = 150;

    const contextWidth = this.contextActive ? this.contextWidth : 0;
    const explorerWidth = this.showingExplorer ? this.explorerWidth : 0;
    const width = stage.width() - explorerWidth - contextWidth;
    const height = stage.height();

    let scale = Math.min(
      width / (bounds.width + padding),
      height / (bounds.height + padding)
    );

    stage.scale({
      x: scale,
      y: scale,
    });

    let x = explorerWidth ? width / 2 + explorerWidth : width / 2;
    x = x + 10; // padding;

    stage.x(x);
    stage.y(height / 2);

    stage.draw();
    this.stageScale = stage.scaleX();
    this.stageX = stage.x();
    this.stageY = stage.y();
  }
}

export default connect(stateToComputed, dispatchToActions)(DrawingEditorKonva);
