import Component from '@glimmer/component';
import { action } from '@ember/object';
import { batchGroupBy } from '../../../utils/redux';
import { connect } from 'ember-redux';
import { getActiveProduct } from '../../../selectors/invention-ui';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

const stateToComputed = (state) => ({
  product: getActiveProduct(state),
});
const dispatchToActions = {};

class MilestonesModal extends Component {
  @tracked product;
  @tracked priorArt;

  @service data;
  @service tracking;

  get styleNamespace() {
    return podNames['milestones-modal'];
  }

  get classNames() {
    let classNames = [this.styleNamespace, 'milestones-modal'];
    return classNames.join(' ');
  }

  @action
  didInsert() {
    this.tracking.trackEvent('opened_milestones_checklist');
  }

  @action
  hideModal() {
    batchGroupBy.start();
    this.data.setShowingProductChecklist(false);
    batchGroupBy.end();
    this.tracking.trackEvent('closed_milestones_checklist');
  }

  @action
  onSelectPriorArt(priorArt) {
    this.priorArt = priorArt;
  }

  @action
  createComparison() {}
}

export default connect(stateToComputed, dispatchToActions)(MilestonesModal);
