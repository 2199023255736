import { SET_ACTIVE_INVENTION } from './actionTypes';

export const setActiveInvention = inventionId => {
  return {
    type: SET_ACTIVE_INVENTION,
    payload: {
      inventionId
    }
  };
};
