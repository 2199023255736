import { action, computed } from '@ember/object';
import {
  getSelectedMarkers,
  getSelectedMethodEdges,
  getSelectedMethodNodes,
} from '../../../selectors/invention-ui';

import Component from '@glimmer/component';
import { connect } from 'ember-redux';
import { getFigureNumber } from '../../../selectors/figure';
import { getMethod } from '../../../selectors/method';
import { getPreviewNumbering } from '../../../selectors/settings';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';
import { union } from '@ember/object/computed';

const stateToComputed = (state, attrs) => ({
  method: getMethod(state, attrs.drawingId),
  selectedMarkers: getSelectedMarkers(state),
  figureNum: getFigureNumber(state, attrs.methodId),
  selectedMethodNodes: getSelectedMethodNodes(state),
  selectedMethodEdges: getSelectedMethodEdges(state),
  previewNumbering: getPreviewNumbering(state),
});

const dispatchToActions = {};

class PreviewContextMethod extends Component {
  @service tracking;
  @service store;

  get styleNamespace() {
    return podNames['preview-context-method'];
  }

  get classNames() {
    let classNames = [this.styleNamespace];
    return classNames.join(' ');
  }

  @computed('args.methodId')
  get methodModel() {
    return this.store.peekRecord('method', this.args.methodId);
  }

  @union('selectedMethodNodes', 'selectedMethodEdges')
  selectedMethodItems;

  @action
  onSelectStep(modelId, modelType, isSelected) {
    if (this.args.onSelectMethodNode && modelType === 'method-node') {
      this.args.onSelectMethodNode(modelId, isSelected);
    } else if (this.args.onSelectMethodEdge && modelType === 'method-edge') {
      this.args.onSelectMethodEdge(modelId, isSelected);
    }
  }
}

export default connect(
  stateToComputed,
  dispatchToActions
)(PreviewContextMethod);
