import {
  ARTBOARD_FILL,
  ARTBOARD_STROKE,
  BLACK_AND_WHITE_STROKE,
} from '../../../constants/colors';

import Component from '@glimmer/component';
import Konva from 'konva';
import { action } from '@ember/object';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';

class FigureArtboardKonva extends Component {
  @service settings;

  constructor(owner, args) {
    super(owner, args);
    this.onScheduleRender = this.args.onScheduleRender;
    this._width = this.args._width;
    this._height = this.args._height;
    this._blackAndWhiteMode = this.settings.blackAndWhiteMode;

    this.setup();
  }

  willDestroy() {
    this.artboardNode.off('click');
    this.onScheduleRender();
  }

  get styleNamespace() {
    return podNames['figure-artboard-konva'];
  }

  get classNames() {
    let classNames = [this.styleNamespace];
    if (this.args.isSelected) classNames.push('is-selected');
    return classNames.join(' ');
  }

  get strokeColor() {
    let color = ARTBOARD_STROKE;

    if (this.settings.blackAndWhiteMode) {
      color = BLACK_AND_WHITE_STROKE;
    }

    return color;
  }

  get fillColor() {
    let color = ARTBOARD_FILL;
    return color;
  }

  get strokeWidth() {
    return this.settings.blackAndWhiteMode ? 1.5 : 1;
  }

  setup() {
    const artboardNode = new Konva.Rect({
      id: 'figure-artboard',
      name: 'artboard',
      x: 0,
      y: 0,
      transformsEnabled: 'position',
      fill: this.fillColor,
      stroke: this.strokeColor,
      strokeWidth: this.strokeWidth,
      width: this.args.width,
      height: this.args.height,
      strokeScaleEnabled: false,
      offset: {
        x: this.args.width / 2,
        y: this.args.height / 2,
      },
    });

    // add events
    artboardNode.on('click', () => {
      if (this.args.onClick) this.args.onClick();
    });

    this.args.layer.add(artboardNode);
    this.artboardNode = artboardNode;

    this.onScheduleRender();
  }

  updateColors() {
    this.artboardNode.fill(this.fillColor);
    this.artboardNode.stroke(this.strokeColor);
  }

  @action
  onUpdate(elem, [width, height, blackAndWhiteMode]) {
    let detailsChanged = false;

    if (this._width !== width || this._height !== height) {
      this._width = width;
      this._height = height;

      this.artboardNode.width(this.args.width);
      this.artboardNode.height(this.args.height);
      this.artboardNode.offset({
        x: this.args.width / 2,
        y: this.args.height / 2,
      });

      detailsChanged = true;
    }

    if (this._blackAndWhiteMode !== blackAndWhiteMode) {
      this._blackAndWhiteMode = blackAndWhiteMode;
      this.updateColors();
      detailsChanged = true;
    }

    if (detailsChanged) {
      this.onScheduleRender(this.args.layer);
    }
  }
}

export default FigureArtboardKonva;
