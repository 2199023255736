import Component from '@glimmer/component';
import { connect } from 'ember-redux';
import { getElementVersion } from '../../../selectors/element-version';
import podNames from 'ember-component-css/pod-names';

const dispatchToActions = {};

const stateToComputed = (state, attrs) => ({
  elementVersion: getElementVersion(state, attrs.elementVersionId),
});


class ElementVersionContextAmount extends Component {

  get classNames() {
    let classNames = ['element-version-context-amount', this.styleNamespace];
    if (this.args.amountType === 'custom') classNames.push('is-custom');
    return classNames.join(' ');
  }

  get styleNamespace() {
    return podNames['element-version-context-amount'];
  }
}

export default connect(
  stateToComputed,
  dispatchToActions
)(ElementVersionContextAmount);
