import { action, computed } from '@ember/object';
import { getMethod, getMethodOutcomeId } from '../../../selectors/method';

import Component from '@glimmer/component';
import { bool } from '@ember/object/computed';
import { connect } from 'ember-redux';
import { getPreviewMode } from '../../../selectors/invention-ui';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

const stateToComputed = (state, attrs) => ({
  method: getMethod(state, attrs.methodId),
  featureId: getMethodOutcomeId(state, attrs.methodId),
  previewMode: getPreviewMode(state),
});

const dispatchToActions = {};

class MethodContext extends Component {
  @service store;
  @service tracking;
  @tracked isOverflowed = false;
  scrollableComponent = null;

  get styleNamespace() {
    return podNames['method-context'];
  }

  get classNames() {
    let classNames = [this.styleNamespace];
    if (this.isOverflowed) classNames.push('is-overflowed');
    return classNames.join(' ');
  }

  @computed('method.orientation')
  get orientation() {
    return this.method && this.method.orientation;
  }

  @computed('args.methodId')
  get methodModel() {
    return this.store.peekRecord('method', this.args.methodId);
  }

  @bool('methodModel.traversedNodesList.length') hasTraversedNodes;

  @computed('method.element')
  get elementName() {
    return (
      this.method &&
      this.method.element &&
      `<mention class="mention" data-type="element" data-id="${this.method.element}"></mention>`
    );
  }

  @action
  setOrientation(orientation) {
    return this.args.onUpdateMethod && this.args.onUpdateMethod(this.args.methodId, { orientation })
  }
}

export default connect(stateToComputed, dispatchToActions)(MethodContext);
