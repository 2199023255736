import Base from 'ember-simple-auth/authenticators/base';
import RSVP from 'rsvp';

export default class MagicAuthenticator extends Base {
  restore(data) {
    return RSVP.resolve(data);
  }

  authenticate(options) {
    return RSVP.resolve(options);
  }

  invalidate(data) {
    return RSVP.resolve(data);
  }
}
