import Component from '@glimmer/component';
import podNames from "ember-component-css/pod-names";

export default class UiModalHeader extends Component {

  get styleNamespace() {
    return podNames['ui-modal-header'];
  }

  get classNames() {
    let classNames = [this.styleNamespace, 'ui-modal-header'];
    if (this.args.isFloating) classNames.push('is-floating');
    if (this.args.isBorderless) classNames.push('is-borderless');
    if (this.args.size === 'large') classNames.push('is-large');
    return classNames.join(' ');
  }

}