import { get } from '@ember/object';
import { getPresentState } from '../utils/redux';

export const getComponent = (state, componentId) =>
  get(getPresentState(state), `components.entities.${componentId}`);

export const getComponents = (state) =>
  get(getPresentState(state), `components.entities`);

export const getComponentList = (state) =>
  get(getPresentState(state), `components.ids`);

export const getComponentInstanceOf = (state, componentId) => {
  const component = get(
    getPresentState(state),
    `components.entities.${componentId}`
  );
  return (
    component &&
    component.element &&
    get(getPresentState(state), `elements.entities.${component.element}`)
  );
};

export const getPrimaryInstancesList = (state) => {
  const componentsList = getComponentList(state);
  return componentsList.map((componentId) => {
    const component = getComponent(state, componentId);
    return component.primaryInstance;
  });
};
