export default {
  attributes: {
    id: {
      type: 'uuid',
      defaultValue: null
    },
    type: {
      type: 'string',
      defaultValue: '' // 'event', 'design'
    },
    description: {
      type: 'string',
      defaultValue: ''
    },
    viewAngle: {
      type: 'string',
      defaultValue: ''
    },
    orientation: {
      type: 'enum',
      values: ['portrait', 'landscape'],
      defaultValue: 'portrait'
    },
    size: {
      type: 'string',
      defaultValue: 'us-letter'
    },
    imagesList: {
      type: 'array',
      defaultValue: []
    },
    markersList: {
      type: 'array',
      defaultValue: []
    },
    createdAt: {
      type: 'date'
    },
    updatedAt: {
      type: 'date'
    }
  }
}
