export const featuresList = [
  'function',
  'attachment',
  'interaction',
  'position',
  // 'analogs',
  'measurement',
  'shape',
  'material',
  // 'definition',
  'general',
  // 'absent'
];

export const termFeaturesList = [
  'definition',
  'analogs',
  'function',
  'attachment',
  'interaction',
  'position',
  'measurement',
  'shape',
  'material',
  'general',
];

export const elementFeaturesList = [
  'function',
  'attachment',
  'interaction',
  'position',
  // 'analogs',
  'measurement',
  'shape',
  'material',
  // 'definition',
  'general',
  // 'absent'
];

export const requirementsList = [
  'function',
  'measurement',
  'shape',
  'position',
  'interaction',
  'material',
  'attachment',
  'general',
];

export const defaultElementColumnsList = [
  'system_name',
  'functional_requirements',
  'success_event',
  'solution',
  'solution_type',
  'designing',
  'drawings',
  'method',
  'nonfunctional_requirements',
  'features',
  'definitions',
];

export const defaultElementColumns = {
  system_name: {
    id: 'system_name',
    name: 'System Name',
    width: 'wide',
    visible: true,
    wrappable: true,
    wrap: false,
  },
  functional_requirements: {
    id: 'functional_requirements',
    name: 'Function',
    width: 'wide',
    visible: true,
    wrappable: true,
    wrap: false,
  },
  success_event: {
    id: 'success_event',
    name: 'Success Event',
    width: 'wide',
    visible: true,
    wrappable: true,
    wrap: false,
  },
  solution: {
    id: 'solution',
    name: 'Solution',
    width: 'narrow',
    visible: true,
    wrappable: true,
    wrap: false,
  },
  solution_type: {
    id: 'solution_type',
    name: 'Solution Type',
    width: 'narrow',
    visible: true,
    wrappable: false,
    wrap: false,
  },
  designing: {
    id: 'designing',
    name: 'Designing',
    width: 'narrow',
    visible: true,
    wrappable: false,
    wrap: false,
  },
  drawings: {
    id: 'drawings',
    name: 'Drawings',
    width: 'narrow',
    visible: false,
    wrappable: false,
    wrap: false,
  },
  method: {
    id: 'method',
    name: 'Method',
    width: 'narrow',
    visible: false,
    wrappable: false,
    wrap: false,
  },
  nonfunctional_requirements: {
    id: 'nonfunctional_requirements',
    name: 'User Requirements',
    width: 'wide',
    visible: false,
    wrappable: true,
    wrap: false,
  },
  features: {
    id: 'features',
    name: 'User Requirements',
    width: 'wide',
    visible: false,
    wrappable: true,
    wrap: false,
  },
  definitions: {
    id: 'definitions',
    name: 'Definitions',
    width: 'wide',
    visible: false,
    wrappable: true,
    wrap: false,
  },
};

export const defaultArtboardWidth = 1144;
export const defaultArtboardHeight = 1584;
export const alphabet = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z',
];
