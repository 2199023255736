import { curveBundle, line } from 'd3-shape';

// import ELK, { ElkNode } from 'elkjs/lib/elk.bundled';
import ELK from 'elkjs/lib/elk.bundled';
import { keyBy } from 'lodash';
import { pointDistance } from './graph';
import uuid from 'uuid/v4';

const elk = new ELK();

export const getLayout = (nodes, edges, layoutOptions = {}) => {
  // nodes = nodes.map(node => {
  //   const sides = ['NORTH', 'SOUTH', 'EAST', 'WEST'];
  //   const ports = sides.map((side, index) => ({
  //     id: `${node.id}_${index}`,
  //     // width: 18,
  //     // height: 18,
  //     properties: {
  //       'port.side': side,
  //       'port.index': index,
  //     },
  //   }))

  //   return {
  //     ...node,
  //     ports
  //   }
  // });
  const graph = {
    id: 'root',
    layoutOptions: {
      ...defaultLayoutOptions,
      ...layoutOptions,
    },
    children: nodes,
    edges: edges,
  };
  return new Promise((resolve, reject) => {
    elk.layout(graph).then(resolve).catch(reject);
  });
};

const parsePosition = (point, nodeBounds) => {
  const { x, y, width, height } = nodeBounds;

  let position = 'WEST';
  
  if (Math.round(Math.abs(point.x - x)) === Math.round(width)) {
    position = 'EAST';
  } else if (Math.round(Math.abs(point.x - x)) === 0) {
    position = 'WEST';
  } else if (Math.round(Math.abs(point.y - y)) === Math.round(height)) {
    position = 'SOUTH';
  } else if (Math.round(Math.abs(point.y - y)) === 0) {
    position = 'NORTH';
  }

  return position;
};

export const getEdgePositions = (sourceNode, targetNode, section) => {
  const startPoint = {
    x: section.startPoint.x,
    y: section.startPoint.y,
  };

  const sourcePosition = parsePosition(startPoint, sourceNode);

  const endPoint = {
    x: section.endPoint.x,
    y: section.endPoint.y,
  };

  const targetPosition = parsePosition(endPoint, targetNode);

  return {
    sourcePosition,
    targetPosition,
  };
  // const endSection = sections[sections.length - 1];
};

export const getEdgePath = (sections, interpolation = 'linear') => {
  if (!sections?.length) {
    return null;
  }

  if (sections[0].bendPoints) {
    const points = sections
      ? [
          sections[0].startPoint,
          ...(sections[0].bendPoints || []),
          sections[0].endPoint,
        ]
      : [];

    let pathFn = line()
      .x((d) => d.x)
      .y((d) => d.y);

    if (interpolation === 'curved') {
      pathFn = pathFn.curve(curveBundle.beta(1));
    }
    return pathFn(points);
  } else {
    return getBezierPath({
      sourceX: sections[0].startPoint.x,
      sourceY: sections[0].startPoint.y,
      targetX: sections[0].endPoint.x,
      targetY: sections[0].endPoint.y,
    });
  }
};

export const getBendPoints = (sections) => {
  if (!sections?.length) {
    return {};
  }

  let bendPointsArray = sections[0].bendPoints || [];
  bendPointsArray = bendPointsArray.map((bendPoint) => ({
    id: uuid(),
    x: bendPoint.x,
    y: bendPoint.y,
  }));

  bendPointsArray = removeCloseBendPoints(bendPointsArray);

  return keyBy(bendPointsArray, 'id');
};

export const removeCloseBendPoints = (
  bendPointsArray,
  distanceThreshold = 40
) => {
  let currentPoint;
  return bendPointsArray.filter((point) => {
    let valid = true;
    if (currentPoint) {
      const distance = pointDistance(
        currentPoint.x,
        currentPoint.y,
        point.x,
        point.y
      );

      const samePlane =
        point.x === currentPoint.x || point.y === currentPoint.y;
      valid = samePlane && distance < distanceThreshold ? false : true;
    }
    currentPoint = point;
    return valid;
  });
};

/**
 * Center helper.
 * Ref: https://github.com/wbkd/react-flow/blob/main/src/components/Edges/utils.ts#L18
 */
function getBezierCenter({ sourceX, sourceY, targetX, targetY }) {
  const xOffset = Math.abs(targetX - sourceX) / 2;
  const centerX = targetX < sourceX ? targetX + xOffset : targetX - xOffset;

  const yOffset = Math.abs(targetY - sourceY) / 2;
  const centerY = targetY < sourceY ? targetY + yOffset : targetY - yOffset;

  return [centerX, centerY, xOffset, yOffset];
}

/**
 * Path helper utils.
 * Ref: https://github.com/wbkd/react-flow/blob/main/src/components/Edges/BezierEdge.tsx#L19
 */
export function getBezierPath({
  sourceX,
  sourceY,
  sourcePosition = 'bottom',
  targetX,
  targetY,
  targetPosition = 'top',
}) {
  const leftAndRight = ['left', 'right'];
  const [centerX, centerY] = getBezierCenter({
    sourceX,
    sourceY,
    targetX,
    targetY,
  });

  let path = `M${sourceX},${sourceY} C${sourceX},${centerY} ${targetX},${centerY} ${targetX},${targetY}`;

  if (
    leftAndRight.includes(sourcePosition) &&
    leftAndRight.includes(targetPosition)
  ) {
    path = `M${sourceX},${sourceY} C${centerX},${sourceY} ${centerX},${targetY} ${targetX},${targetY}`;
  } else if (leftAndRight.includes(targetPosition)) {
    path = `M${sourceX},${sourceY} C${sourceX},${targetY} ${sourceX},${targetY} ${targetX},${targetY}`;
  } else if (leftAndRight.includes(sourcePosition)) {
    path = `M${sourceX},${sourceY} C${targetX},${sourceY} ${targetX},${sourceY} ${targetX},${targetY}`;
  }

  return path;
}

const defaultLayoutOptions = {
  /**
   * Hints for where node labels are to be placed; if empty, the node label’s position is not modified.
   *
   * @see https://www.eclipse.org/elk/reference/options/org-eclipse-elk-nodeLabels-placement.html
   */
  'elk.nodeLabels.placement': 'INSIDE V_CENTER H_RIGHT',

  /**
   * Select a specific layout algorithm.
   *
   * Uses "layered" strategy.
   * It emphasizes the direction of edges by pointing as many edges as possible into the same direction.
   * The nodes are arranged in layers, which are sometimes called “hierarchies”,
   * and then reordered such that the number of edge crossings is minimized.
   * Afterwards, concrete coordinates are computed for the nodes and edge bend points.
   *
   * @see https://www.eclipse.org/elk/reference/algorithms.html
   * @see https://www.eclipse.org/elk/reference/options/org-eclipse-elk-algorithm.html
   * @see https://www.eclipse.org/elk/reference/algorithms/org-eclipse-elk-layered.html
   */
  'elk.algorithm': 'org.eclipse.elk.layered',

  /**
   * Overall direction of edges: horizontal (right / left) or vertical (down / up).
   *
   * @see https://www.eclipse.org/elk/reference/options/org-eclipse-elk-direction.html
   */
  'elk.direction': 'DOWN',

  'elk.padding': '[left=30, top=30, right=30, bottom=0]',

  /**
   * Strategy for node layering.
   *
   * @see https://www.eclipse.org/elk/reference/options/org-eclipse-elk-layered-layering-strategy.html
   */
  'org.eclipse.elk.layered.layering.strategy': 'INTERACTIVE',

  /**
   * What kind of edge routing style should be applied for the content of a parent node.
   * Algorithms may also set this option to single edges in order to mark them as splines.
   * The bend point list of edges with this option set to SPLINES
   * must be interpreted as control points for a piecewise cubic spline.
   *
   * @see https://www.eclipse.org/elk/reference/options/org-eclipse-elk-edgeRouting.html
   */
  'org.eclipse.elk.edgeRouting': 'ORTHOGONAL',

  /**
   * Adds bend points even if an edge does not change direction.
   * If true, each long edge dummy will contribute a bend point to its edges
   * and hierarchy-crossing edges will always get a bend point where they cross hierarchy boundaries.
   * By default, bend points are only added where an edge changes direction.
   *
   * @see https://www.eclipse.org/elk/reference/options/org-eclipse-elk-layered-unnecessaryBendpoints.html
   */
  'elk.layered.unnecessaryBendpoints': 'true',
  // 'elk.layered.unnecessaryBendpoints': 'false',
  'org.eclipse.elk.spacing.edgeNode': '50',

  /**
   * The spacing to be preserved between nodes and edges that are routed next to the node’s layer.
   * For the spacing between nodes and edges that cross the node’s layer ‘spacing.edgeNode’ is used.
   *
   * @see https://www.eclipse.org/elk/reference/options/org-eclipse-elk-layered-spacing-edgeNodeBetweenLayers.html
   */
  'elk.layered.spacing.edgeNodeBetweenLayers': '70',

  /**
   * Tells the BK node placer to use a certain alignment (out of its four)
   * instead of the one producing the smallest height, or the combination of all four.
   *
   * @see https://www.eclipse.org/elk/reference/options/org-eclipse-elk-layered-nodePlacement-bk-fixedAlignment.html
   */
  'org.eclipse.elk.layered.nodePlacement.bk.fixedAlignment': 'BALANCED',
  // 'org.eclipse.elk.alignment': 'CENTER',

  'org.eclipse.elk.layered.wrapping.strategy': 'SINGLE_EDGE',
  // 'org.eclipse.elk.layered.nodePlacement.favorStraightEdges': 'true',
  // 'org.eclipse.elk.layered.wrapping.correctionFactor': '2.0',
  // 'org.eclipse.elk.layered.layering.minWidth.upperBoundOnWidth': '-1',
  // 'org.eclipse.elk.rectpacking.trybox': 'true',
  // 'org.eclipse.elk.layered.thoroughness': '15',
  // 'org.eclipse.elk.nodeSize.fixedGraphSize': 'true',
  // 'org.eclipse.elk.rectpacking.packing.compaction.rowHeightReevaluation': 'true',

  // 'org.eclipse.elk.contentAlignment': 'V_BOTTOM H_RIGHT',
  // 'org.eclipse.elk.layered.wrapping.additionalEdgeSpacing': '40',

  /**
   * Strategy for cycle breaking.
   *
   * Cycle breaking looks for cycles in the graph and determines which edges to reverse to break the cycles.
   * Reversed edges will end up pointing to the opposite direction of regular edges
   * (that is, reversed edges will point left if edges usually point right).
   *
   * @see https://www.eclipse.org/elk/reference/options/org-eclipse-elk-layered-cycleBreaking-strategy.html
   */
  'org.eclipse.elk.layered.cycleBreaking.strategy': 'DEPTH_FIRST',

  /**
   * Whether this node allows to route self loops inside of it instead of around it.
   *
   * If set to true, this will make the node a compound node if it isn’t already,
   * and will require the layout algorithm to support compound nodes with hierarchical ports.
   *
   * @see https://www.eclipse.org/elk/reference/options/org-eclipse-elk-insideSelfLoops-activate.html
   */
  'org.eclipse.elk.insideSelfLoops.activate': 'true',

  /**
   * Whether each connected component should be processed separately.
   *
   * @see https://www.eclipse.org/elk/reference/options/org-eclipse-elk-separateConnectedComponents.html
   */
  separateConnectedComponents: 'false',

  /**
   * Spacing to be preserved between pairs of connected components.
   * This option is only relevant if ‘separateConnectedComponents’ is activated.
   *
   * @see https://www.eclipse.org/elk/reference/options/org-eclipse-elk-spacing-componentComponent.html
   */
  'spacing.componentComponent': '60',

  /**
   * TODO: Should be spacing.baseValue?
   * An optional base value for all other layout options of the ‘spacing’ group.
   * It can be used to conveniently alter the overall ‘spaciousness’ of the drawing.
   * Whenever an explicit value is set for the other layout options, this base value will have no effect.
   * The base value is not inherited, i.e. it must be set for each hierarchical node.
   *
   * @see https://www.eclipse.org/elk/reference/groups/org-eclipse-elk-layered-spacing.html
   */
  spacing: '25',

  /**
   * The spacing to be preserved between any pair of nodes of two adjacent layers.
   * Note that ‘spacing.nodeNode’ is used for the spacing between nodes within the layer itself.
   *
   * @see https://www.eclipse.org/elk/reference/options/org-eclipse-elk-layered-spacing-nodeNodeBetweenLayers.html
   */
  'spacing.nodeNodeBetweenLayers': '85',

  'org.eclipse.elk.spacing.nodeNode': '30',
};
