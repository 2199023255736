export default {
  attributes: {
    showWarnings: {
      defaultValue: true,
      type: 'boolean',
    },
    previewLanguage: {
      defaultValue: 'report', // patent-specification
      type: 'string',
    },
    previewNumbering: {
      defaultValue: 'patent-specification', // patent-specification
      type: 'string',
    },
    showNovelty: {
      defaultValue: true,
      type: 'boolean',
    },
    theme: {
      defaultValue: 'auto', // 'dark' 
      type: 'string',
    },
    blackAndWhiteMode: {
      defaultValue: false,
      type: 'boolean',
    },
  },
};


