export default {
  attributes: {
    id: {
      type: 'uuid',
      defaultValue: null
    },
    name: {
      type: 'string',
      defaultValue: ''
    },
    featuresList: {
      type: 'array',
      defaultValue: []
    },
    createdAt: {
      type: 'date'
    },
  }
};
