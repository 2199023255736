import Component from '@glimmer/component';
import { connect } from 'ember-redux';
import podNames from 'ember-component-css/pod-names';

const dispatchToActions = {};

const stateToComputed = null;

class HelpButton extends Component {
  get styleNamespace() {
    return podNames['help-button'];
  }

  get classNames() {
    let classNames = [this.styleNamespace, 'help-button'];
    return classNames.join(' ');
  }
}

export default connect(stateToComputed, dispatchToActions)(HelpButton);
