import Component from '@ember/component';
import { computed } from '@ember/object';
// import { equal } from '@ember/object/computed';

const UiUserIcon = Component.extend({
  classNames: ['user-icon'],
  initials: computed('firstName', 'lastName', function() {
    const firstIntital = this.firstName && this.firstName.charAt(0) || '';
    const lastIntital = this.lastName && this.lastName.charAt(0) || '';
    return `${firstIntital}${lastIntital}`;
  }),
});

export default UiUserIcon;
