import { get } from '@ember/object';
import { getPresentState } from '../utils/redux';

export const getRootNodeId = (state) =>
  get(getPresentState(state), `graph.rootNode.id`);

export const getGraphIndex = (state) =>
  get(getPresentState(state), `graph.index`);

export const getRootNode = (state) =>
  get(getPresentState(state), `graph.rootNode`);

export const getElementsMap = (state, productId) => {
  const map = productId
  ? get(getPresentState(state), `graph.products.${productId}.elementsMap`)
  : get(getPresentState(state), `graph.elementsMap`);
  return map;
}

export const getElementVersionsMap = (state, productId) =>
  productId
    ? get(
        getPresentState(state),
        `graph.products.${productId}.elementVersionsMap`
      )
    : get(getPresentState(state), `graph.elementVersionsMap`);

export const getOrphanNodesList = (state) =>
  get(getPresentState(state), `graph.orphanNodesList`);

export const getDisconnectedNodesList = (state) =>
  get(getPresentState(state), `graph.disconnectedNodesList`);

export const getMethodsList = (state) =>
  get(getPresentState(state), `graph.methodsList`);

export const getMethodsMap = (state) =>
  get(getPresentState(state), `graph.methodsMap`);

export const getGraphProduct = (state, productId) =>
  get(getPresentState(state), `graph.products.${productId}`);
