import { action, computed } from '@ember/object';

import Component from '@glimmer/component';
import { connect } from 'ember-redux';
import { getActiveFeature } from '../../../selectors/invention-ui';
import { getElement } from '../../../selectors/element';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';

const dispatchToActions = {};

const stateToComputed = (state, attrs) => ({
  activeFeatureId: getActiveFeature(state),
  element: getElement(state, attrs.elementId),
});

class ElementContextRequirements extends Component {
  @service data;
  @service tracking;

  get styleNamespace() {
    return podNames['element-context-requirements'];
  }

  get classNames() {
    let classNames = [this.styleNamespace, 'element-context-requirements'];
    return classNames.join(' ');
  }

  @computed('element.requirementsList', 'activeFeatureId')
  get hasActive() {
    return (
      this.activeFeatureId &&
      this.element.requirementsList.includes(this.activeFeatureId)
    );
  }

  @action
  removeRequirement(featureId) {
    this.args.onRemoveRequirement(this.args.elementId, featureId);
  }

  @action
  createRequirement() {
    this.args.onAddRequirement(this.args.elementId, 'functional_requirement');
  }

  @action
  onSortRequirementsList(requirementsList) {
    this.args.onUpdateElement(this.args.elementId, { requirementsList });
  }
}

export default connect(
  stateToComputed,
  dispatchToActions
)(ElementContextRequirements);
