import Component from '@glimmer/component';
import { connect } from 'ember-redux';
import { getPreviewMode } from '../../../selectors/invention-ui';
import podNames from 'ember-component-css/pod-names';

const stateToComputed = (state) => ({
  previewMode: getPreviewMode(state),
});

const dispatchToActions = {};

class PatentSpecificationFigure extends Component {
  get styleNamespace() {
    return podNames['patent-specification-figure'];
  }

  get classNames() {
    let classNames = [this.styleNamespace, 'patent-specification-figure'];
    return classNames.join(' ');
  }
}

export default connect(
  stateToComputed,
  dispatchToActions
)(PatentSpecificationFigure);
