import Component from '@glimmer/component';
import { action } from '@ember/object';
import { connect } from 'ember-redux';
import { getActivePatentSpecificationEditorSection } from '../../../selectors/invention-ui';
import { getPatentSpecification } from '../../../selectors/patent-specification';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';
import { values } from 'lodash';
import FeatureTypes from '../../../constants/types/features';

const stateToComputed = (state) => ({
  patentSpecification: getPatentSpecification(state),
  activeSection: getActivePatentSpecificationEditorSection(state),
});
const dispatchToActions = {};

class RulesEditor extends Component {
  @service tracking;

  rules = values(FeatureTypes);

  get styleNamespace() {
    return podNames['rules-editor'];
  }

  get classNames() {
    let classNames = [this.styleNamespace, 'rules-editor'];
    return classNames.join(' ');
  }

  exampleValues = Object.freeze({
    one: "Examples of the {{{element}}} may include {{{analogs_0}}}.",
    two: "Examples of the {{{element}}} may include {{{analogs_0}}} and {{{analogs_1}}}.",
    twoPlus: "Examples of the {{{element}}} may include {{#each analogs_collection as |analog index|}}{{#if @first}}{{{analog}}}{{else if @last}} and {{{analog}}}{{else}}, {{{analog}}}{{/if}}{{/each}}.",
  })

  @action
  didInsert() {
    this.tracking.trackEvent('opened_patent_specification');
  }

  @action
  hideModal() {
    this.args.onSetShowingPatentSpecificationEditor(false);
    this.tracking.trackEvent('closed_patent_specification');
  }

  @action
  onSelect(value) {
    this.args.onSetActivePatentSpecificationEditorSection(value);
  }

  

  @action
  onSetType() {
    const type =
      this.patentSpecification.type === 'provisional'
        ? 'nonprovisional'
        : 'provisional';
    this.args.onUpdatePatentSpecification(null, { type });
  }

  @action
  onSetNarrativeType(narrativeType) {
    this.args.onSetNarrativeType(narrativeType);
  }

  @action
  onShowFundingSources() {
    const showFundingSources = !this.patentSpecification.showFundingSources;
    this.args.onUpdatePatentSpecification(null, { showFundingSources });
  }

  @action
  onShowCrossReference() {
    const showCrossReference = !this.patentSpecification.showCrossReference;
    this.args.onUpdatePatentSpecification(null, { showCrossReference });
  }

  @action
  onShowBoilerplate() {
    const showBoilerplate = !this.patentSpecification.showBoilerplate;
    this.args.onUpdatePatentSpecification(null, { showBoilerplate });
  }
}

export default connect(
  stateToComputed,
  dispatchToActions
)(RulesEditor);
