import Component from '@glimmer/component';
import { exists } from '../../../utils/general';
import podNames from 'ember-component-css/pod-names';

class UiContextContainer extends Component {
  constructor() {
    super(...arguments); // super must be called first.
    this.isInteractive = exists(this.args.isInteractive, true);
    this.hideBorder = this.args.hideBorder || false;
    this.side = exists(this.args.side, 'right');
    this.isLeft = this.side === 'left';
    this.isRight = this.side === 'right';
    this.isMedium = this.args.size === 'medium';
    this.isLarge = this.args.size === 'large';
    this.isSmall = this.args.size === 'small';
  }

  get classNames() {
    let classNames = [this.styleNamespace, 'ui-context-container'];
    if (this.isInteractive) classNames.push('is-interactive');
    if (this.hideBorder) classNames.push('hide-border');
    if (this.isLeft) classNames.push('is-left');
    if (this.isRight) classNames.push('is-right');
    if (this.isMedium) classNames.push('is-medium');
    if (this.isLarge) classNames.push('is-large');
    if (this.isSmall) classNames.push('is-small');
    return classNames.join(' ');
  }

  get styleNamespace() {
    return podNames['ui-context-container'];
  }

}

export default UiContextContainer;
