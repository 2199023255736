import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { htmlSafe } from '@ember/string';
import { action, computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { connect } from 'ember-redux';
import podNames from 'ember-component-css/pod-names';
import { getAsset } from '../../../selectors/asset';

const stateToComputed = (state, attrs) => ({
  asset: getAsset(state, attrs.assetId),
});

const dispatchToActions = {};

class AssetEditor extends Component {

  @service assets;

  @tracked blobUrl;
  @tracked _name;

  changedAttributes = {};

  get styleNamespace() {
    return podNames['asset-editor'];
  }

  get classNames() {
    let classNames = [this.styleNamespace, 'asset-editor'];
    return classNames.join(' ');
  }


  @action
  onInsert() {
    this._name = this.asset.name;
  }

  @computed('blobUrl')
  get backgroundImageStyle() {
    return htmlSafe(`background-image: url(${this.blobUrl})`); 
  }

  @action
  onSave() {
    this.args.onSave(this.args.productId, this.changedAttributes)
  }

  @action
  onClick() {
    if (this.args.onClick) this.args.onClick(this.args.assetId);
  }   

  @action
  onNameChange(name) {
    this.changedAttributes['name'] = name;
  }

  @action
  async setBlobUrl() {
    this.blobUrl = null;
    const blobUrl = await this.assets.getAssetUrl(this.args.assetId);
    this.blobUrl = blobUrl;
  }
}

export default connect(stateToComputed, dispatchToActions)(AssetEditor);
