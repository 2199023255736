import Component from '@glimmer/component';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency-decorators';
import { tracked } from '@glimmer/tracking';
import { typeOf } from '@ember/utils';

class DisclosureItem extends Component {
  @service export;
  @service tracking;
  @service worker;
  @service inventionsDb;

  @tracked state;

  constructor() {
    super(...arguments);
    this.setInvention.perform(this.args.disclosureId);
  }

  get styleNamespace() {
    return podNames['disclosure-item'];
  }


  @task
  *setInvention(disclosureId) {
    let _invention = yield this.inventionsDb.getInvention(disclosureId);

    const worker1 = yield this.worker.open('json-parse');
    const parsedInvention = yield worker1.postMessage(_invention);

    worker1.terminate();

    if (parsedInvention) {
      let invention;

      if (typeOf(parsedInvention.present) === 'string') {
        const worker2 = yield this.worker.open('json-parse');
        invention = yield worker2.postMessage(
          'json-parse',
          parsedInvention.present
        );
        worker2.terminate();
      } else {
        invention = parsedInvention.present;
      }

      if (invention) {
        this.state = invention;
      }
      
    }
  }
}
export default DisclosureItem;
