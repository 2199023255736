import Component from '@glimmer/component';
import { computed } from '@ember/object';
import { connect } from 'ember-redux';
import { getPatentabilityType } from '../../../selectors/type';
import { getProductPatentabilityValue } from '../../../selectors/product';
import podNames from 'ember-component-css/pod-names';

const stateToComputed = (state, attrs) => ({
  patentabilityType: getPatentabilityType(attrs.patentabilityTypeId),
  productPatentabilityValue: getProductPatentabilityValue(
    state,
    attrs.productId,
    attrs.patentabilityTypeId
  ),
});
const dispatchToActions = {};

class PatentabilityQuestion extends Component {
  get styleNamespace() {
    return podNames['patentability-question'];
  }

  get classNames() {
    let classNames = [this.styleNamespace, 'patentability-question'];
    return classNames.join(' ');
  }

  @computed('productPatentabilityValue', 'patentabilityType.options.[]')
  get activeOption() {
    return (
      this.patentabilityType && 
      this.productPatentabilityValue &&
      this.patentabilityType.options.find(
        (option) => this.productPatentabilityValue === option.id
      )
    );
  }
}

export default connect(
  stateToComputed,
  dispatchToActions
)(PatentabilityQuestion);
