export function focusAndPlaceCaretAtEnd(el) {
  el.focus();

  if (
    typeof window.getSelection != 'undefined' &&
    typeof document.createRange != 'undefined'
  ) {
    // el.focus();
    // el.setSelectionRange(el.value.length,el.value.length);
    const range = document.createRange();
    range.selectNodeContents(el);
    range.collapse(false);
    const sel = window.getSelection();
    sel.removeAllRanges();
    sel.addRange(range);
  } else if (typeof document.body.createTextRange != 'undefined') {
    const textRange = document.body.createTextRange();
    textRange.moveToElementText(el);
    textRange.collapse(false);
    textRange.select();
  }
}

// export function focusAndSelectAll(el) {
//   el.focus();

//   if (
//     typeof window.getSelection != 'undefined' &&
//     typeof document.createRange != 'undefined'
//   ) {
//     // el.focus();
//     // el.setSelectionRange(el.value.length,el.value.length);
//     const range = document.createRange();
//     range.selectNodeContents(el);
//     const sel = window.getSelection();
//     sel.removeAllRanges();
//     sel.addRange(range);
//   } else if (typeof document.body.createTextRange != 'undefined') {
//     const textRange = document.body.createTextRange();
//     textRange.moveToElementText(el);
//     textRange.select();
//   }
// }
