import { action, computed } from '@ember/object';
import {
  getActiveProduct,
  getActiveProductId,
  getActiveView,
  getInventionUi,
  getPreviewMode,
  getSelectedProductId,
  getShowingFigures,
  getShowingSearch,
} from '../../../selectors/invention-ui';

import Component from '@glimmer/component';
import ENV from '../../../config/environment';
import { connect } from 'ember-redux';
import { getFigureNumber } from '../../../selectors/figure';
import { getInvention } from '../../../selectors/invention';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';

const dispatchToActions = {};

const stateToComputed = (state, attrs) => ({
  activeProductId: getActiveProductId(state),
  selectedProductId: getSelectedProductId(state),
  activeProduct: getActiveProduct(state),
  activeView: getActiveView(state),
  invention: getInvention(state, attrs.inventionId),
  inventionUi: getInventionUi(state),
  previewMode: getPreviewMode(state),
  showingFigures: getShowingFigures(state),
  showingSearch: getShowingSearch(state),
});

class MainNav extends Component {
  @service sessionManager;
  @service redux;
  @service data;
  @service tracking;

  showDebug = ENV.environment === 'development';

  viewOptions = [
    {
      id: 'edit',
      name: 'Edit',
      testId: 'drawing-edit-button',
    },
    {
      id: 'preview',
      name: 'Review',
      testId: 'drawing-preview-button',
    },
  ];

  navigationOptions = [
    {
      id: 'graph',
      name: 'Graph',
      testId: 'main-nav-graph',
    },
    {
      id: 'specification',
      name: 'Specification',
      testId: 'main-nav-specification',
    },
    // ,{
    //   id: 'presentation',
    //   name: 'Presentation'
    // }
  ];

  get classNames() {
    let classNames = ['main-nav', this.styleNamespace];
    // if (this.isTest) classNames.push('is-test');
    return classNames.join(' ');
  }

  get styleNamespace() {
    return podNames['main-nav'];
  }

  get sessionUser() {
    return this.sessionManager.user;
  }

  get showBackButton() {
    return this.args.routeName && this.args.routeName !== 'graph';
  }

  get showEditPreviewSwitch() {
    return true;
    // return this.args.routeName && this.args.routeName !== 'graph';
  }

  get showRouteTitle() {
    return this.args.routeName && this.args.routeName !== 'graph';
  }

  get showPatentSpecificationButton() {
    return this.args.routeName && this.args.routeName === 'graph';
  }

  @computed('activeProductId', 'args.routeName')
  get showProductChecklist() {
    return (
      this.activeProductId &&
      this.args.routeName &&
      this.args.routeName === 'graph'
    );
  }

  @computed('previewMode')
  get activeViewId() {
    return this.previewMode ? 'preview' : 'edit';
  }

  @computed('args.{routeName,routeModelId}')
  get routeTitle() {
    const state = this.redux.getState();
    let title = '';
    switch (this.args.routeName) {
      case 'drawing': {
        const sequence = getFigureNumber(state, this.args.routeModelId);
        title = `Figure ${sequence}`;
        break;
      }
      case 'method': {
        const sequence = getFigureNumber(state, this.args.routeModelId);
        title = `Figure ${sequence}`;
        break;
      }
      case 'patent-specification': {
        title = `Specification`;
        break;
      }
    }

    return title;
  }

  @computed('inventionUi.showingNotes')
  get showingNotes() {
    return this.inventionUi.showingNotes;
  }

  @action
  toggleScratchpad() {
    const value = this.showingNotes ? false : true;
    this.data.setShowingNotes(value);
  }

  @action
  onSetShowingInventionSummaryEditor(section) {
    if (this.args.onSetShowingInventionSummaryEditor) {
      this.args.onSetShowingInventionSummaryEditor(true, section);
    }
  }

  @action
  selectProduct() {
    if (this.selectedProductId === this.activeProductId) {
      this.data.selectProduct(null);
    } else {
      this.data.selectProduct(this.activeProductId);
      this.tracking.trackEvent('selected_product');
    }
  }

  @computed('activeView')
  get activeNavigationOption() {
    return this.activeView && this.getNavigationOption(this.activeView);
  }

  getNavigationOption(id) {
    return this.navigationOptions.find((option) => option.id === id);
  }

  @action
  setActiveNavigationOption(id) {
    this.data.setActiveView(id);
  }

  @action
  onShowPatentSpecification() {
    if (this.args.onStackNavigationPush) {
      this.args.onStackNavigationPush(
        'patent-specification',
        'patent-specification',
        'patent-specification'
      );
    }
  }

  @action
  onSetShowingFigures() {
    if (this.args.onSetShowingFigures) {
      const value = !this.showingFigures;
      this.args.onSetShowingFigures(value);
    }
  }

  @action
  onSetShowingSearch() {
    if (this.args.onSetShowingSearch) {
      const value = !this.showingSearch;
      this.args.onSetShowingSearch(value);
    }
  }

  @action
  onTogglePreviewMode(id) {
    if (this.args.onSetPreviewMode) {
      const value = id === 'edit' ? false : true;
      this.args.onSetPreviewMode(value);
    }
  }
}

export default connect(stateToComputed, dispatchToActions)(MainNav);

// import Component from '@glimmer/component';
// import { computed } from '@ember/object';
// import { connect } from 'ember-redux';
// import { findIndex } from 'lodash';
// import { getDrawing } from '../../../selectors/drawing';
// import { getPreviewMode } from '../../../selectors/invention-ui';
// import { getFiguresList } from '../../../selectors/invention';
// import podNames from 'ember-component-css/pod-names';

// const dispatchToActions = {};

// const stateToComputed = (state, attrs) => ({
//   figuresList: getFiguresList(state),
//   drawing: getDrawing(state, attrs.drawingId),
//   previewMode: getPreviewMode(state),
// });

// class DrawingNav extends Component {
//   viewOptions = [
//     {
//       id: 'edit',
//       name: 'Edit',
//       testId: 'drawing-edit-button',
//     },
//     {
//       id: 'preview',
//       name: 'Preview',
//       testId: 'drawing-preview-button',
//     },
//   ];

//   get classNames() {
//     let classNames = ['drawing-nav', this.styleNamespace];
//     return classNames.join(' ');
//   }

//   get styleNamespace() {
//     return podNames['drawing-nav'];
//   }

//   get sessionUser() {
//     return this.sessionManager.user;
//   }

//   @computed('figuresList.[]', 'args.drawingId')
//   get sequence() {
//     return findIndex(this.figuresList, ['id', this.args.drawingId]) + 1;
//   }

//   @computed('previewMode')
//   get activeViewId() {
//     return this.previewMode ? 'preview' : 'edit';
//   }
// }

// export default connect(stateToComputed, dispatchToActions)(DrawingNav);
