import documentationTypes from '../constants/types/documentation';
import milestoneTypes from '../constants/types/milestones';
// import featureCategoryTypes from '../constants/types/feature-categories';
// import featureTypes from '../constants/types/features';
import patentabilityTypes from '../constants/types/patentability';

export const getDocumentationType = (typeId) => documentationTypes.entities[typeId];
export const getDocumentationTypes = () => documentationTypes.ids;

export const getMilestoneType = (typeId) => milestoneTypes.entities[typeId];
export const getMilestoneTypes = () => milestoneTypes.ids;

export const getPatentabilityType = (typeId) => patentabilityTypes.entities[typeId];
export const getPatentabilityTypes = () => patentabilityTypes.ids;
// export const getFeatureType = (typeId) => featureTypes[typeId];
// export const getFeatureCategoryType = (typeId) => featureCategoryTypes[typeId];
export const getPatentabilitySections = () => [
  {
    id: 'conception',
    label: 'Conception',
    typesList: ['conception'],
  },
  {
    id: 'invention_proof',
    label: 'Invention Proof',
    typesList: ['invention_proof'],
  },
  {
    id: 'patent_types',
    label: 'Patent Types',
    typesList: ['patent_types'],
  },
  {
    id: 'invention_categories',
    label: 'Invention Categories',
    typesList: ['invention_categories'],
  },
  {
    id: 'prior_art',
    label: 'Prior Art',
    typesList: [
      'prior_art_market',
      'prior_art_patented',
      'prior_art_published',
    ],
  },
  {
    id: 'public_disclosure',
    label: 'Public Disclosure',
    typesList: ['public_disclosure'],
  },
];
