import Component from '@glimmer/component';
import { connect } from 'ember-redux';
import podNames from 'ember-component-css/pod-names';
import { getPriorArt } from '../../../selectors/prior-art';
import uuid from 'uuid/v4';

const stateToComputed = (state, attrs) => ({
  priorArt: getPriorArt(state, attrs.priorArtId),
});

const dispatchToActions = {};

class ComparisonPriorArt extends Component {
  domElementId = `comparison-prior-art-${uuid()}`;

  get styleNamespace() {
    return podNames['comparison-prior-art-cell'];
  }

  get classNames() {
    let classNames = [this.styleNamespace, 'comparison-prior-art-cell'];
    return classNames.join(' ');
  }

}

export default connect(
  stateToComputed,
  dispatchToActions
)(ComparisonPriorArt);
