export default [
  {
    name: 'Sequential Step 1 First',
    conditions: {
      sequential_count: {
        equal: 1,
      },
      conditional_count: {
        equal: 0,
      },
      alternative_count: {
        equal: 0,
      },
      revisited_sequential_count: {
        equal: 0,
      },
      revisited_conditional_count: {
        equal: 0,
      },
      revisited_alternative_count: {
        equal: 0,
      },
      language: {
        is: 'patent_specification_preferred_version',
      },
      index: {
        equal: 0,
      },
    },
    event: {
      template: `First, {{{sequential_0.text}}} (Step {{{sequential_0.ordinal}}}).`,
      required: ['sequential_0'],
    },
  },
  {
    name: 'Alternative Step 1 First',
    conditions: {
      sequential_count: {
        equal: 0,
      },
      conditional_count: {
        equal: 0,
      },
      alternative_count: {
        equal: 1,
      },
      revisited_sequential_count: {
        equal: 0,
      },
      revisited_conditional_count: {
        equal: 0,
      },
      revisited_alternative_count: {
        equal: 0,
      },
      language: {
        is: 'patent_specification_preferred_version',
      },
      index: {
        equal: 0,
      },
    },
    event: {
      template: `First, {{{alternative_0.text}}} (Step {{{alternative_0.ordinal}}}).`,
      required: ['alternative_0'],
    },
  },
  {
    name: 'Sequential Step 1',
    conditions: {
      sequential_count: {
        equal: 1,
      },
      conditional_count: {
        equal: 0,
      },
      alternative_count: {
        equal: 0,
      },
      revisited_sequential_count: {
        equal: 0,
      },
      revisited_conditional_count: {
        equal: 0,
      },
      revisited_alternative_count: {
        equal: 0,
      },
      language: {
        is: 'patent_specification_preferred_version',
      },
      index: {
        greater: 0,
      },
    },
    event: {
      template: `Then {{{sequential_0.text}}} (Step {{{sequential_0.ordinal}}}).`,
      required: ['sequential_0'],
    },
  },
  {
    name: 'Alternative Step 1',
    conditions: {
      sequential_count: {
        equal: 0,
      },
      conditional_count: {
        equal: 0,
      },
      alternative_count: {
        equal: 1,
      },
      revisited_sequential_count: {
        equal: 0,
      },
      revisited_conditional_count: {
        equal: 0,
      },
      revisited_alternative_count: {
        equal: 0,
      },
      language: {
        is: 'patent_specification_preferred_version',
      },
      index: {
        greater: 0,
      },
    },
    event: {
      template: `Alternatively {{{alternative_0.text}}} (Step {{{alternative_0.ordinal}}}).`,
      required: ['alternative_0'],
    },
  },
  {
    name: 'Revisited Sequential Step 1',
    conditions: {
      sequential_count: {
        equal: 0,
      },
      conditional_count: {
        equal: 0,
      },
      alternative_count: {
        equal: 0,
      },
      revisited_sequential_count: {
        equal: 1,
      },
      revisited_conditional_count: {
        equal: 0,
      },
      revisited_alternative_count: {
        equal: 0,
      },
      language: {
        is: 'patent_specification_preferred_version',
      },
      index: {
        greater: 0,
      },
    },
    event: {
      template: `From <span class="method-ordinal">Step {{{revisited_sequential_0.viaOrdinal}}}</span> refer back to <span class="method-ordinal">Step {{{revisited_sequential_0.ordinal}}}</span>.`,
      required: ['revisited_sequential_0'],
    },
  },
  {
    name: 'Alternative Sequential Step 1',
    conditions: {
      sequential_count: {
        equal: 0,
      },
      conditional_count: {
        equal: 0,
      },
      alternative_count: {
        equal: 0,
      },
      revisited_sequential_count: {
        equal: 0,
      },
      revisited_conditional_count: {
        equal: 0,
      },
      revisited_alternative_count: {
        equal: 1,
      },
      language: {
        is: 'patent_specification_preferred_version',
      },
      index: {
        greater: 0,
      },
    },
    event: {
      template: `From <span class="method-ordinal">Step {{{revisited_alternative_0.viaOrdinal}}}</span> alternatively refer back to <span class="method-ordinal">Step {{{revisited_alternative_0.ordinal}}}</span>.`,
      required: ['revisited_alternative_0'],
    },
  },
  {
    name: 'Revisited Conditional Step 1',
    conditions: {
      sequential_count: {
        equal: 0,
      },
      conditional_count: {
        equal: 0,
      },
      alternative_count: {
        equal: 0,
      },
      revisited_sequential_count: {
        equal: 0,
      },
      revisited_conditional_count: {
        equal: 1,
      },
      revisited_alternative_count: {
        equal: 0,
      },
      language: {
        is: 'patent_specification_preferred_version',
      },
      index: {
        greater: 0,
      },
    },
    event: {
      template: `From <span class="method-ordinal">Step {{{revisited_conditional_0.viaOrdinal}}}</span> refer back to <span class="method-ordinal">Step {{{revisited_conditional_0.ordinal}}}</span>.`,
      required: ['revisited_conditional_0'],
    },
  },
  {
    name: 'Conditional 1',
    conditions: {
      sequential_count: {
        equal: 0,
      },
      conditional_count: {
        equal: 1,
      },
      alternative_count: {
        equal: 0,
      },
      revisited_sequential_count: {
        equal: 0,
      },
      revisited_conditional_count: {
        equal: 0,
      },
      revisited_alternative_count: {
        equal: 0,
      },
      language: {
        is: 'patent_specification_preferred_version',
      },
    },
    event: {
      template: `If {{{conditional_0.text}}} (Step {{{conditional_0.ordinal}}})...`,
      required: ['conditional_0'],
    },
  },
  {
    name: 'Conditional 1 + Sequential 1',
    conditions: {
      sequential_count: {
        equal: 1,
      },
      conditional_count: {
        equal: 1,
      },
      alternative_count: {
        equal: 0,
      },
      revisited_sequential_count: {
        equal: 0,
      },
      revisited_conditional_count: {
        equal: 0,
      },
      revisited_alternative_count: {
        equal: 0,
      },
      language: {
        is: 'patent_specification_preferred_version',
      },
    },
    event: {
      template: `If {{{conditional_0.text}}} (Step {{{conditional_0.ordinal}}}), {{{sequential_0.text}}} (Step {{{sequential_0.ordinal}}}).`,
      required: ['conditional_0', 'sequential_0'],
    },
  },
  {
    name: 'Conditional 1 + Alternative 1',
    conditions: {
      sequential_count: {
        equal: 0,
      },
      conditional_count: {
        equal: 1,
      },
      alternative_count: {
        equal: 1,
      },
      revisited_sequential_count: {
        equal: 0,
      },
      revisited_conditional_count: {
        equal: 0,
      },
      revisited_alternative_count: {
        equal: 0,
      },
      language: {
        is: 'patent_specification_preferred_version',
      },
    },
    event: {
      template: `If {{{conditional_0.text}}} (Step {{{conditional_0.ordinal}}}) then alternatively {{{alternative_0.text}}} (Step {{{alternative_0.ordinal}}}).`,
      required: ['conditional_0', 'alternative_0'],
    },
  },
  {
    name: 'Conditional 1 + Revisited Sequential 1',
    conditions: {
      sequential_count: {
        equal: 0,
      },
      conditional_count: {
        equal: 1,
      },
      alternative_count: {
        equal: 0,
      },
      revisited_sequential_count: {
        equal: 1,
      },
      revisited_conditional_count: {
        equal: 0,
      },
      revisited_alternative_count: {
        equal: 0,
      },
      language: {
        is: 'patent_specification_preferred_version',
      },
    },
    event: {
      template: `If {{{conditional_0.text}}} (Step {{{conditional_0.ordinal}}}), then refer back to Step {{{revisited_sequential_0.ordinal}}}.`,
      required: ['conditional_0', 'revisited_sequential_0'],
    },
  },
  {
    name: 'Conditional 1 + Revisited Alternative 1',
    conditions: {
      sequential_count: {
        equal: 0,
      },
      conditional_count: {
        equal: 1,
      },
      alternative_count: {
        equal: 0,
      },
      revisited_sequential_count: {
        equal: 0,
      },
      revisited_conditional_count: {
        equal: 0,
      },
      revisited_alternative_count: {
        equal: 1,
      },
      language: {
        is: 'patent_specification_preferred_version',
      },
    },
    event: {
      template: `If {{{conditional_0.text}}} (Step {{{conditional_0.ordinal}}}) then alternatively refer back to Step {{{revisited_alternative_0.ordinal}}}.`,
      required: ['conditional_0', 'revisited_alternative_0'],
    },
  },
  {
    name: 'Conditional 1 + Revisited Conditional 1',
    conditions: {
      sequential_count: {
        equal: 0,
      },
      conditional_count: {
        equal: 1,
      },
      alternative_count: {
        equal: 0,
      },
      revisited_sequential_count: {
        equal: 0,
      },
      revisited_conditional_count: {
        equal: 1,
      },
      revisited_alternative_count: {
        equal: 0,
      },
      language: {
        is: 'patent_specification_preferred_version',
      },
    },
    event: {
      template: `If {{{conditional_0.text}}} (Step {{{conditional_0.ordinal}}}), then refer back to Step {{{revisited_conditional_0.ordinal}}}.`,
      required: ['conditional_0', 'revisited_conditional_0'],
    },
  },
  {
    name: 'Conditional >1 + Sequential 1',
    conditions: {
      sequential_count: {
        equal: 1,
      },
      conditional_count: {
        greater: 1,
      },
      alternative_count: {
        equal: 0,
      },
      revisited_sequential_count: {
        equal: 0,
      },
      revisited_conditional_count: {
        equal: 0,
      },
      revisited_alternative_count: {
        equal: 0,
      },
      language: {
        is: 'patent_specification_preferred_version',
      },
    },
    event: {
      template: `{{#each conditional_collection as |conditional_step index|}}{{#if @first}}If  {{{conditional_step.text}}} (Step {{{conditional_step.ordinal}}}){{else if @last}} and {{{conditional_step.text}}} (Step {{{conditional_step.ordinal}}}){{else}}, {{{conditional_step.text}}} (Step {{{conditional_step.ordinal}}}){{/if}}{{/each}} then {{{sequential_0.text}}} (Step {{{sequential_0.ordinal}}}).`,
      required: ['conditional_collection', 'sequential_0'],
    },
  },
  {
    name: 'Conditional >1 + Alternative 1',
    conditions: {
      sequential_count: {
        equal: 0,
      },
      conditional_count: {
        greater: 1,
      },
      alternative_count: {
        equal: 1,
      },
      revisited_sequential_count: {
        equal: 0,
      },
      revisited_conditional_count: {
        equal: 0,
      },
      revisited_alternative_count: {
        equal: 0,
      },
      language: {
        is: 'patent_specification_preferred_version',
      },
    },
    event: {
      template: `{{#each conditional_collection as |conditional_step index|}}{{#if @first}}If  {{{conditional_step.text}}} (Step {{{conditional_step.ordinal}}}){{else if @last}} and {{{conditional_step.text}}} (Step {{{conditional_step.ordinal}}}){{else}}, {{{conditional_step.text}}} (Step {{{conditional_step.ordinal}}}){{/if}}{{/each}} then alternatively {{{alternative_0.text}}} (Step {{{alternative_0.ordinal}}}).`,
      required: ['conditional_collection', 'alternative_0'],
    },
  },
  {
    name: 'Conditional >1 + Revisited Conditional 1',
    conditions: {
      sequential_count: {
        equal: 0,
      },
      conditional_count: {
        greater: 1,
      },
      alternative_count: {
        equal: 0,
      },
      revisited_sequential_count: {
        equal: 0,
      },
      revisited_conditional_count: {
        equal: 1,
      },
      revisited_alternative_count: {
        equal: 0,
      },
      language: {
        is: 'patent_specification_preferred_version',
      },
    },
    event: {
      template: `{{#each conditional_collection as |conditional_step index|}}{{#if @first}}If  {{{conditional_step.text}}} (Step {{{conditional_step.ordinal}}}){{else if @last}} and {{{conditional_step.text}}} (Step {{{conditional_step.ordinal}}}){{else}}, {{{conditional_step.text}}} (Step {{{conditional_step.ordinal}}}){{/if}}{{/each}} then refer back to Step {{{revisited_conditional_0.ordinal}}}.`,
      required: ['conditional_collection', 'revisited_conditional_0'],
    },
  },
  {
    name: 'Conditional >1 + Revisited Sequential 1',
    conditions: {
      sequential_count: {
        equal: 0,
      },
      conditional_count: {
        greater: 1,
      },
      alternative_count: {
        equal: 0,
      },
      revisited_sequential_count: {
        equal: 1,
      },
      revisited_conditional_count: {
        equal: 0,
      },
      revisited_alternative_count: {
        equal: 0,
      },
      language: {
        is: 'patent_specification_preferred_version',
      },
    },
    event: {
      template: `{{#each conditional_collection as |conditional_step index|}}{{#if @first}}If  {{{conditional_step.text}}} (Step {{{conditional_step.ordinal}}}){{else if @last}} and {{{conditional_step.text}}} (Step {{{conditional_step.ordinal}}}){{else}}, {{{conditional_step.text}}} (Step {{{conditional_step.ordinal}}}){{/if}}{{/each}} then refer back to Step {{{revisited_sequential_0.ordinal}}}.`,
      required: ['conditional_collection', 'revisited_sequential_0'],
    },
  },
  {
    name: 'Conditional >1 + Revisited Alternative 1',
    conditions: {
      sequential_count: {
        equal: 0,
      },
      conditional_count: {
        greater: 1,
      },
      alternative_count: {
        equal: 0,
      },
      revisited_sequential_count: {
        equal: 0,
      },
      revisited_conditional_count: {
        equal: 0,
      },
      revisited_alternative_count: {
        equal: 1,
      },
      language: {
        is: 'patent_specification_preferred_version',
      },
    },
    event: {
      template: `{{#each conditional_collection as |conditional_step index|}}{{#if @first}}If  {{{conditional_step.text}}} (Step {{{conditional_step.ordinal}}}){{else if @last}} and {{{conditional_step.text}}} (Step {{{conditional_step.ordinal}}}){{else}}, {{{conditional_step.text}}} (Step {{{conditional_step.ordinal}}}){{/if}}{{/each}} then alternatively refer back to Step {{{revisited_alternative_0.ordinal}}}.`,
      required: ['conditional_collection', 'revisited_alternative_0'],
    },
  },
  {
    name: 'Conditional >1',
    conditions: {
      sequential_count: {
        equal: 0,
      },
      conditional_count: {
        greater: 1,
      },
      alternative_count: {
        equal: 0,
      },
      revisited_sequential_count: {
        equal: 0,
      },
      revisited_conditional_count: {
        equal: 0,
      },
      revisited_alternative_count: {
        equal: 0,
      },
      language: {
        is: 'patent_specification_preferred_version',
      },
    },
    event: {
      template: `{{#each conditional_collection as |conditional_step index|}}{{#if @first}}If  {{{conditional_step.text}}} (Step {{{conditional_step.ordinal}}}){{else if @last}} and {{{conditional_step.text}}} (Step {{{conditional_step.ordinal}}}){{else}}, {{{conditional_step.text}}} (Step {{{conditional_step.ordinal}}}){{/if}}{{/each}} ...`,
      required: ['conditional_collection'],
    },
  },
  {
    name: 'Sequential Step 1 First',
    conditions: {
      sequential_count: {
        equal: 1,
      },
      conditional_count: {
        equal: 0,
      },
      alternative_count: {
        equal: 0,
      },
      revisited_sequential_count: {
        equal: 0,
      },
      revisited_conditional_count: {
        equal: 0,
      },
      revisited_alternative_count: {
        equal: 0,
      },
      language: {
        is: 'report_list',
      },
      index: {
        equal: 0,
      },
    },
    event: {
      template: `{{{sequential_0.text}}} <span class="method-ordinal">(Step {{{sequential_0.ordinal}}})</span>.`,
      required: ['sequential_0'],
    },
  },
  {
    name: 'Alternative Step 1 First',
    conditions: {
      sequential_count: {
        equal: 0,
      },
      conditional_count: {
        equal: 0,
      },
      alternative_count: {
        equal: 1,
      },
      revisited_sequential_count: {
        equal: 0,
      },
      revisited_conditional_count: {
        equal: 0,
      },
      revisited_alternative_count: {
        equal: 0,
      },
      language: {
        is: 'report_list',
      },
      index: {
        equal: 0,
      },
    },
    event: {
      template: `{{{alternative_0.text}}} <span class="method-ordinal">(Step {{{alternative_0.ordinal}}})</span>.`,
      required: ['alternative_0'],
    },
  },
  {
    name: 'Sequential Step 1',
    conditions: {
      sequential_count: {
        equal: 1,
      },
      conditional_count: {
        equal: 0,
      },
      alternative_count: {
        equal: 0,
      },
      revisited_sequential_count: {
        equal: 0,
      },
      revisited_conditional_count: {
        equal: 0,
      },
      revisited_alternative_count: {
        equal: 0,
      },
      language: {
        is: 'report_list',
      },
      index: {
        greater: 0,
      },
    },
    event: {
      template: `{{{sequential_0.text}}} <span class="method-ordinal">(Step {{{sequential_0.ordinal}}})</span>.`,
      required: ['sequential_0'],
    },
  },
  {
    name: 'Alternative Step 1',
    conditions: {
      sequential_count: {
        equal: 0,
      },
      conditional_count: {
        equal: 0,
      },
      alternative_count: {
        equal: 1,
      },
      revisited_sequential_count: {
        equal: 0,
      },
      revisited_conditional_count: {
        equal: 0,
      },
      revisited_alternative_count: {
        equal: 0,
      },
      language: {
        is: 'report_list',
      },
      index: {
        greater: 0,
      },
    },
    event: {
      template: `Alternatively {{{alternative_0.text}}} <span class="method-ordinal">(Step {{{alternative_0.ordinal}}})</span>.`,
      required: ['alternative_0'],
    },
  },
  {
    name: 'Revisited Sequential Step 1',
    conditions: {
      sequential_count: {
        equal: 0,
      },
      conditional_count: {
        equal: 0,
      },
      alternative_count: {
        equal: 0,
      },
      revisited_sequential_count: {
        equal: 1,
      },
      revisited_conditional_count: {
        equal: 0,
      },
      revisited_alternative_count: {
        equal: 0,
      },
      language: {
        is: 'report_list',
      },
      index: {
        greater: 0,
      },
    },
    event: {
      template: `From <span class="method-ordinal">Step {{{revisited_sequential_0.viaOrdinal}}}</span> go to <span class="method-ordinal">Step {{{revisited_sequential_0.ordinal}}}</span>.`,
      required: ['revisited_sequential_0'],
    },
  },
  {
    name: 'Revisited Alternative Step 1',
    conditions: {
      sequential_count: {
        equal: 0,
      },
      conditional_count: {
        equal: 0,
      },
      alternative_count: {
        equal: 0,
      },
      revisited_sequential_count: {
        equal: 0,
      },
      revisited_conditional_count: {
        equal: 0,
      },
      revisited_alternative_count: {
        equal: 1,
      },
      language: {
        is: 'report_list',
      },
      index: {
        greater: 0,
      },
    },
    event: {
      template: `From <span class="method-ordinal">Step {{{revisited_alternative_0.viaOrdinal}}}</span> alternatively go to <span class="method-ordinal">Step {{{revisited_alternative_0.ordinal}}}</span>.`,
      required: ['revisited_alternative_0'],
    },
  },
  {
    name: 'Revisited Conditional Step 1',
    conditions: {
      sequential_count: {
        equal: 0,
      },
      conditional_count: {
        equal: 0,
      },
      alternative_count: {
        equal: 0,
      },
      revisited_sequential_count: {
        equal: 0,
      },
      revisited_conditional_count: {
        equal: 1,
      },
      revisited_alternative_count: {
        equal: 0,
      },
      language: {
        is: 'report_list',
      },
      index: {
        greater: 0,
      },
    },
    event: {
      template: `From <span class="method-ordinal">Step {{{revisited_conditional_0.viaOrdinal}}}</span> if <span class="method-ordinal">Step {{{revisited_conditional_0.ordinal}}}</span>...`,
      required: ['revisited_conditional_0'],
    },
  },
  {
    name: 'Conditional 1',
    conditions: {
      sequential_count: {
        equal: 0,
      },
      conditional_count: {
        equal: 1,
      },
      alternative_count: {
        equal: 0,
      },
      revisited_sequential_count: {
        equal: 0,
      },
      revisited_conditional_count: {
        equal: 0,
      },
      revisited_alternative_count: {
        equal: 0,
      },
      language: {
        is: 'report_list',
      },
    },
    event: {
      template: `if {{{conditional_0.text}}}... <span class="method-ordinal">(Step {{{conditional_0.ordinal}}})</span>`,
      required: ['conditional_0'],
    },
  },
  {
    name: 'Conditional 1 + Sequential 1',
    conditions: {
      sequential_count: {
        equal: 1,
      },
      conditional_count: {
        equal: 1,
      },
      alternative_count: {
        equal: 0,
      },
      revisited_sequential_count: {
        equal: 0,
      },
      revisited_conditional_count: {
        equal: 0,
      },
      revisited_alternative_count: {
        equal: 0,
      },
      language: {
        is: 'report_list',
      },
    },
    event: {
      template: `If {{{conditional_0.text}}} then {{{sequential_0.text}}}.`,
      required: ['conditional_0', 'sequential_0'],
    },
  },
  {
    name: 'Conditional 1 + Alternative 1',
    conditions: {
      sequential_count: {
        equal: 0,
      },
      conditional_count: {
        equal: 1,
      },
      alternative_count: {
        equal: 1,
      },
      revisited_sequential_count: {
        equal: 0,
      },
      revisited_conditional_count: {
        equal: 0,
      },
      revisited_alternative_count: {
        equal: 0,
      },
      language: {
        is: 'report_list',
      },
    },
    event: {
      template: `If {{{conditional_0.text}}} then alternatively {{{alternative_0.text}}}.`,
      required: ['conditional_0', 'alternative_0'],
    },
  },
  {
    name: 'Conditional 1 + Revisited Sequential 1',
    conditions: {
      sequential_count: {
        equal: 0,
      },
      conditional_count: {
        equal: 1,
      },
      alternative_count: {
        equal: 0,
      },
      revisited_sequential_count: {
        equal: 1,
      },
      revisited_conditional_count: {
        equal: 0,
      },
      revisited_alternative_count: {
        equal: 0,
      },
      language: {
        is: 'report_list',
      },
    },
    event: {
      template: `If {{{conditional_0.text}}} then refer back to step "{{{revisited_sequential_0.text}}}."`,
      required: ['conditional_0', 'revisited_sequential_0'],
    },
  },
  {
    name: 'Conditional 1 + Revisited Alternative 1',
    conditions: {
      sequential_count: {
        equal: 0,
      },
      conditional_count: {
        equal: 1,
      },
      alternative_count: {
        equal: 0,
      },
      revisited_sequential_count: {
        equal: 0,
      },
      revisited_conditional_count: {
        equal: 0,
      },
      revisited_alternative_count: {
        equal: 1,
      },
      language: {
        is: 'report_list',
      },
    },
    event: {
      template: `If {{{conditional_0.text}}} then alternatively refer back to step "{{{revisited_alternative_0.text}}}."`,
      required: ['conditional_0', 'revisited_alternative_0'],
    },
  },
  {
    name: 'Conditional 1 + Revisited Conditional 1',
    conditions: {
      sequential_count: {
        equal: 0,
      },
      conditional_count: {
        equal: 1,
      },
      alternative_count: {
        equal: 0,
      },
      revisited_sequential_count: {
        equal: 0,
      },
      revisited_conditional_count: {
        equal: 1,
      },
      revisited_alternative_count: {
        equal: 0,
      },
      language: {
        is: 'report_list',
      },
    },
    event: {
      template: `If {{{conditional_0.text}}} then refer back to step "if {{{revisited_conditional_0.text}}}."`,
      required: ['conditional_0', 'revisited_conditional_0'],
    },
  },
  {
    name: 'Conditional >1 + Sequential 1',
    conditions: {
      sequential_count: {
        equal: 1,
      },
      conditional_count: {
        greater: 1,
      },
      alternative_count: {
        equal: 0,
      },
      revisited_sequential_count: {
        equal: 0,
      },
      revisited_conditional_count: {
        equal: 0,
      },
      revisited_alternative_count: {
        equal: 0,
      },
      language: {
        is: 'report_list',
      },
    },
    event: {
      template: `{{#each conditional_collection as |conditional_step index|}}{{#if @first}}If  {{{conditional_step.text}}}{{else if @last}} and {{{conditional_step.text}}}{{else}}, {{{conditional_step.text}}}{{/if}}{{/each}} then {{{sequential_0.text}}}.`,
      required: ['conditional_collection', 'sequential_0'],
    },
  },
  {
    name: 'Conditional >1 + Alternative 1',
    conditions: {
      sequential_count: {
        equal: 0,
      },
      conditional_count: {
        greater: 1,
      },
      alternative_count: {
        equal: 1,
      },
      revisited_sequential_count: {
        equal: 0,
      },
      revisited_conditional_count: {
        equal: 0,
      },
      revisited_alternative_count: {
        equal: 0,
      },
      language: {
        is: 'report_list',
      },
    },
    event: {
      template: `{{#each conditional_collection as |conditional_step index|}}{{#if @first}}If  {{{conditional_step.text}}}{{else if @last}} and {{{conditional_step.text}}}{{else}}, {{{conditional_step.text}}}{{/if}}{{/each}} then alternatively {{{alternative_0.text}}}.`,
      required: ['conditional_collection', 'alternative_0'],
    },
  },
  {
    name: 'Conditional >1 + Revisited Conditional 1',
    conditions: {
      sequential_count: {
        equal: 0,
      },
      conditional_count: {
        greater: 1,
      },
      alternative_count: {
        equal: 0,
      },
      revisited_sequential_count: {
        equal: 0,
      },
      revisited_conditional_count: {
        equal: 1,
      },
      revisited_alternative_count: {
        equal: 0,
      },
      language: {
        is: 'report_list',
      },
    },
    event: {
      template: `{{#each conditional_collection as |conditional_step index|}}{{#if @first}}If  {{{conditional_step.text}}}{{else if @last}} and {{{conditional_step.text}}}{{else}}, {{{conditional_step.text}}}{{/if}}{{/each}} then refer back to step "if {{{revisited_conditional_0.text}}}."`,
      required: ['conditional_collection', 'revisited_conditional_0'],
    },
  },
  {
    name: 'Conditional >1 + Revisited Sequential 1',
    conditions: {
      sequential_count: {
        equal: 0,
      },
      conditional_count: {
        greater: 1,
      },
      alternative_count: {
        equal: 0,
      },
      revisited_sequential_count: {
        equal: 1,
      },
      revisited_conditional_count: {
        equal: 0,
      },
      revisited_alternative_count: {
        equal: 0,
      },
      language: {
        is: 'report_list',
      },
    },
    event: {
      template: `{{#each conditional_collection as |conditional_step index|}}{{#if @first}}If  {{{conditional_step.text}}}{{else if @last}} and {{{conditional_step.text}}}{{else}}, {{{conditional_step.text}}}{{/if}}{{/each}} then refer back to step "{{{revisited_sequential_0.text}}}."`,
      required: ['conditional_collection', 'revisited_sequential_0'],
    },
  },
  {
    name: 'Conditional >1 + Revisited Alternative 1',
    conditions: {
      sequential_count: {
        equal: 0,
      },
      conditional_count: {
        greater: 1,
      },
      alternative_count: {
        equal: 0,
      },
      revisited_sequential_count: {
        equal: 0,
      },
      revisited_conditional_count: {
        equal: 0,
      },
      revisited_alternative_count: {
        equal: 1,
      },
      language: {
        is: 'report_list',
      },
    },
    event: {
      template: `{{#each conditional_collection as |conditional_step index|}}{{#if @first}}If  {{{conditional_step.text}}}{{else if @last}} and {{{conditional_step.text}}}{{else}}, {{{conditional_step.text}}}{{/if}}{{/each}} then alternatively refer back to step "{{{revisited_alternative_0.text}}}."`,
      required: ['conditional_collection', 'revisited_alternative_0'],
    },
  },
  {
    name: 'Conditional >1',
    conditions: {
      sequential_count: {
        equal: 0,
      },
      conditional_count: {
        greater: 1,
      },
      alternative_count: {
        equal: 0,
      },
      revisited_sequential_count: {
        equal: 0,
      },
      revisited_conditional_count: {
        equal: 0,
      },
      revisited_alternative_count: {
        equal: 0,
      },
      language: {
        is: 'report_list',
      },
    },
    event: {
      template: `{{#each conditional_collection as |conditional_step index|}}{{#if @first}}If  {{{conditional_step.text}}}{{else if @last}} and {{{conditional_step.text}}}{{else}}, {{{conditional_step.text}}}{{/if}}{{/each}} ...`,
      required: ['conditional_collection'],
    },
  },
  {
    name: 'Sequential Step 1 First',
    conditions: {
      sequential_count: {
        equal: 1,
      },
      conditional_count: {
        equal: 0,
      },
      alternative_count: {
        equal: 0,
      },
      revisited_sequential_count: {
        equal: 0,
      },
      revisited_conditional_count: {
        equal: 0,
      },
      revisited_alternative_count: {
        equal: 0,
      },
      language: {
        is: 'presentation_list',
      },
      index: {
        equal: 0,
      },
    },
    event: {
      template: `{{{sequential_0.text}}}.`,
      required: ['sequential_0'],
    },
  },
  {
    name: 'Alternative Step 1 First',
    conditions: {
      sequential_count: {
        equal: 0,
      },
      conditional_count: {
        equal: 0,
      },
      alternative_count: {
        equal: 1,
      },
      revisited_sequential_count: {
        equal: 0,
      },
      revisited_conditional_count: {
        equal: 0,
      },
      revisited_alternative_count: {
        equal: 0,
      },
      language: {
        is: 'presentation_list',
      },
      index: {
        equal: 0,
      },
    },
    event: {
      template: `{{{alternative_0.text}}}.`,
      required: ['alternative_0'],
    },
  },
  {
    name: 'Sequential Step 1',
    conditions: {
      sequential_count: {
        equal: 1,
      },
      conditional_count: {
        equal: 0,
      },
      alternative_count: {
        equal: 0,
      },
      revisited_sequential_count: {
        equal: 0,
      },
      revisited_conditional_count: {
        equal: 0,
      },
      revisited_alternative_count: {
        equal: 0,
      },
      language: {
        is: 'presentation_list',
      },
      index: {
        greater: 0,
      },
    },
    event: {
      template: `{{{sequential_0.text}}}.`,
      required: ['sequential_0'],
    },
  },
  {
    name: 'Alternative Step 1',
    conditions: {
      sequential_count: {
        equal: 0,
      },
      conditional_count: {
        equal: 0,
      },
      alternative_count: {
        equal: 1,
      },
      revisited_sequential_count: {
        equal: 0,
      },
      revisited_conditional_count: {
        equal: 0,
      },
      revisited_alternative_count: {
        equal: 0,
      },
      language: {
        is: 'presentation_list',
      },
      index: {
        greater: 0,
      },
    },
    event: {
      template: `Alternatively {{{alternative_0.text}}}.`,
      required: ['alternative_0'],
    },
  },
  {
    name: 'Revisited Sequential Step 1',
    conditions: {
      sequential_count: {
        equal: 0,
      },
      conditional_count: {
        equal: 0,
      },
      alternative_count: {
        equal: 0,
      },
      revisited_sequential_count: {
        equal: 1,
      },
      revisited_conditional_count: {
        equal: 0,
      },
      revisited_alternative_count: {
        equal: 0,
      },
      language: {
        is: 'presentation_list',
      },
      index: {
        greater: 0,
      },
    },
    event: {
      template: `From Step <span class="step-number">{{{revisited_sequential_0.viaNumber}}}</span> go to Step <span class="step-number">{{{revisited_sequential_0.number}}}</span>.`,
      required: ['revisited_sequential_0'],
    },
  },
  {
    name: 'Revisited Alternative Step 1',
    conditions: {
      sequential_count: {
        equal: 0,
      },
      conditional_count: {
        equal: 0,
      },
      alternative_count: {
        equal: 0,
      },
      revisited_sequential_count: {
        equal: 0,
      },
      revisited_conditional_count: {
        equal: 0,
      },
      revisited_alternative_count: {
        equal: 1,
      },
      language: {
        is: 'presentation_list',
      },
      index: {
        greater: 0,
      },
    },
    event: {
      template: `From Step <span class="step-number">{{{revisited_alternative_0.viaNumber}}}</span> alternatively go to Step <span class="step-number">{{{revisited_alternative_0.number}}}</span>.`,
      required: ['revisited_alternative_0'],
    },
  },
  {
    name: 'Revisited Conditional Step 1',
    conditions: {
      sequential_count: {
        equal: 0,
      },
      conditional_count: {
        equal: 0,
      },
      alternative_count: {
        equal: 0,
      },
      revisited_sequential_count: {
        equal: 0,
      },
      revisited_conditional_count: {
        equal: 1,
      },
      revisited_alternative_count: {
        equal: 0,
      },
      language: {
        is: 'presentation_list',
      },
      index: {
        greater: 0,
      },
    },
    event: {
      template: `From Step <span class="step-number">{{{revisited_conditional_0.viaNumber}}}</span> if ... (go to Step <span class="step-number">{{{revisited_conditional_0.number}}})</span>`,
      required: ['revisited_conditional_0'],
    },
  },
  {
    name: 'Conditional 1',
    conditions: {
      sequential_count: {
        equal: 0,
      },
      conditional_count: {
        equal: 1,
      },
      alternative_count: {
        equal: 0,
      },
      revisited_sequential_count: {
        equal: 0,
      },
      revisited_conditional_count: {
        equal: 0,
      },
      revisited_alternative_count: {
        equal: 0,
      },
      language: {
        is: 'presentation_list',
      },
    },
    event: {
      template: `if {{{conditional_0.text}}}...`,
      required: ['conditional_0'],
    },
  },
];
