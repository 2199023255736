import { action, computed } from '@ember/object';

import Component from '@glimmer/component';
import { connect } from 'ember-redux';
import { getCustomer } from '../../../selectors/customer';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';

const stateToComputed = (state, attrs) => ({
  customer: getCustomer(state, attrs.customerId),
});

const dispatchToActions = {};

class CustomerEditor extends Component {
  @service tracking;

  get styleNamespace() {
    return podNames['customer-editor'];
  }

  @computed('customer.number')
  get number() {
    return this.customer && this.customer.number || 0;
  }

  @computed('customer.revenue')
  get revenue() {
    return this.customer && this.customer.revenue || 0;
  }

  @computed('number', 'revenue')
  get total() {
    return Math.floor(Number(this.number) * Number(this.revenue)).toLocaleString();
  }


  @action 
  onUpdateCustomerName(customerId, attributes) {
    this.args.onUpdateCustomer(customerId, attributes);
    this.tracking.trackEvent('updated_customer_name')
  }

  @action 
  onUpdateCustomerDescription(customerId, attributes) {
    this.args.onUpdateCustomer(customerId, attributes);
    this.tracking.trackEvent('updated_customer_description')
  }

  @action 
  onUpdateCustomerNumber(customerId, attributes) {
    this.args.onUpdateCustomer(customerId, attributes);
    this.tracking.trackEvent('updated_customer_number')
  }

  @action 
  onUpdateCustomerRevenue(customerId, attributes) {
    this.args.onUpdateCustomer(customerId, attributes);
    this.tracking.trackEvent('updated_customer_revenue')
  }
}

export default connect(stateToComputed, dispatchToActions)(CustomerEditor);
