export default {
  attributes: {
    id: {
      type: 'uuid',
      defaultValue: null
    },
    drawing: {
      type: 'uuid',
      defaultValue: null
    },
    marker: {
      type: 'uuid',
      defaultValue: null
    },
    points: {
      type: 'string',
      defaultValue: ''
    },
    curve: {
      type: 'string',
      defaultValue: 'linear'
    },
    createdAt: {
      type: 'date'
    }
  }
}
