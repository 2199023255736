import Service from '@ember/service';
import { computed } from "@ember/object";
import ENV from '../config/environment';

export default Service.extend({

  framework: computed(function() {
    return ENV.FRAMEWORK;
  }),

  isElectron: computed.equal('framework', 'electron'),

  isWeb: computed.equal('framework', 'web'),

  isCordova: computed.equal('framework', 'cordova'),

});
