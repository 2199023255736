import {
  getActiveProductId,
  getCollapsedNodesList,
  getInventionUi,
} from './invention-ui';
import { getAllImagesList, getDrawing } from './drawing';
import { getElement, getElements, getElementsList } from './element';
import {
  getElementVersion,
  getElementVersions,
  getElementVersionsList,
} from './element-version';
import {
  getFigureNumbering,
  getLocalDrawingNumbering,
} from '../utils/numbering';
import { getInventionState, getModelAttributeIncrement } from '../utils/redux';
import {
  getOrphanNodesList,
  getRootNodeId
} from './graph';
import { getProduct, getProducts, getProductsList } from './product';
import { getTerm, getTerms, getTermsList } from './term';

import { addFromSchema } from '../utils/schema';
import { createSelector } from 'reselect';
import { get } from '@ember/object';
import { getCoordinatesMap } from '../utils/graph';
import {
  getFigureNumber,
} from './figure';
import { getImage } from './image';
import { getMarker } from './marker';
import { getMethod } from './method';
import { getNumberingIndex } from './invention-ui';
import { isTextEqual } from '../utils/string';
import { keyBy } from 'lodash';

export const getElementIncrement = (state, name = 'System') => {
  const elements = getElements(state);
  const increment = getModelAttributeIncrement(elements, 'name', name);
  return increment;
};

export const getProductIncrement = (state) => {
  const products = getProducts(state);
  const increment = getModelAttributeIncrement(products, 'name', 'Prototype');
  return increment;
};

export const getTermIncrement = (state) => {
  const terms = getTerms(state);
  const increment = getModelAttributeIncrement(terms, 'name', 'Term');
  return increment;
};

export const getFigure = (state, figureId) => {
  let figure;
  const drawing = getDrawing(state, figureId);
  const method = getMethod(state, figureId);

  if (figureId && drawing) {
    figure = drawing;
  }

  if (figureId && method) {
    figure = method;
  }
  return figure;
};

export const getReferencesList = (state) => {
  const elementVersions = getElementVersionsList(state).map(
    (elementVersionId) => getElementVersion(state, elementVersionId)
  );
  const products = getProductsList(state).map((productId) =>
    getProduct(state, productId)
  );
  const terms = getTermsList(state).map((termId) => getTerm(state, termId));
  const elementVersionReferenceArrays = elementVersions.map(
    (elementVersion) => elementVersion.markersList
  );
  const productReferenceArrays = products.map((product) => product.markersList);
  const termReferenceArrays = terms.map((term) => term.markersList);

  const referencesArray = [
    ...elementVersionReferenceArrays,
    ...productReferenceArrays,
    ...termReferenceArrays,
  ];
  const referencesList = [...new Set([].concat(referencesArray))];
  return referencesList;
};

export const getAssetsList = (state) => {
  const images = getAllImagesList(state).map((imageId) =>
    getImage(state, imageId)
  );
  const assets = [];
  images.forEach((image) => {
    if (image.asset) assets.push(image.asset);
  });
  return assets;
};

export const getElementOrdinal = (state, elementId, elementVersionId) => {
  const numberingIndex = getNumberingIndex(state);
  const figureNumbering = getFigureNumbering(numberingIndex, {
    state,
  });

  let ordinal;

  if (figureNumbering[elementId]) {
    if (elementVersionId) {
      ordinal = figureNumbering[elementId][elementVersionId];
    } else {
      ordinal = figureNumbering[elementId].default;
    }
  }

  return ordinal;
};

export const getMarkerNumber = (state, markerId) => {
  const marker = getMarker(state, markerId);
  const drawingId = marker && marker.drawing;

  if (!drawingId) {
    return;
  }
  
  const numberingIndex = getNumberingIndex(state);
  const numbering = getLocalDrawingNumbering(numberingIndex, drawingId, state);

  let number;

  if (marker && marker.type === 'element') {
    const elementId = marker.element;
    const elementVersionId = marker.elementVersion;
    if (numbering[elementId]) {
      if (elementVersionId) {
        number = numbering[elementId][elementVersionId];
      } else {
        number = numbering[elementId].default;
      }
    }
  }

  if (marker && marker.type === 'term') {
    const termId = marker.term;
    if (numbering[termId]) {
      number = numbering[termId].default;
    }
  }

  return number;
};

export const getMarkerOrdinal = (state, markerId) => {
  const marker = getMarker(state, markerId);
  const numberingIndex = getNumberingIndex(state);
  const figureNumbering = getFigureNumbering(numberingIndex, {
    state,
  });

  let ordinal;

  if (marker && marker.type === 'element') {
    const elementId = marker.element;
    const elementVersionId = marker.elementVersion;
    if (figureNumbering[elementId]) {
      if (elementVersionId) {
        ordinal = figureNumbering[elementId][elementVersionId];
      } else {
        ordinal = figureNumbering[elementId].default;
      }
    }
  }

  if (marker && marker.type === 'term') {
    const termId = marker.term;
    if (figureNumbering[termId]) {
      ordinal = figureNumbering[termId].default;
    }
  }

  return ordinal;
};

export const getTermOrdinal = (state, termId) => {
  const numberingIndex = getNumberingIndex(state);

  const figureNumbering = getFigureNumbering(numberingIndex, {
    state,
  });

  let ordinal;

  if (figureNumbering[termId]) {
    ordinal = figureNumbering[termId].default;
  }

  return ordinal;
};

export const getMethodNodeNumber = (nodeId, traversedNodesList = []) => {
  const nodeIndex = traversedNodesList.indexOf(nodeId);
  return nodeIndex + 1;
};

export const getMethodEdgeNumber = (viaEdgeId, traversedEdgesList = []) => {
  const edgeIndex = traversedEdgesList.indexOf(viaEdgeId);
  return edgeIndex + 1;
};

export const getMethodNodeOrdinal = (
  state,
  methodId,
  methodNodeId,
  traversedNodesList = []
) => {
  const methodNodeIndex = traversedNodesList.indexOf(methodNodeId);
  let ordinal = null;

  if (methodNodeIndex >= 0) {
    const figureNum = getFigureNumber(state, methodId);
    const baseMultiplier = 100;
    const methodBaseNumber = baseMultiplier * figureNum;
    ordinal = methodBaseNumber + methodNodeIndex;
  }
  return ordinal;
};

export const getElementVersionIncrement = (state, _elementId) => {
  const element = getElement(state, _elementId);
  const elementVersions = element.elementVersionsList.map((elementVersionId) =>
    getElementVersion(state, elementVersionId)
  );
  const increment = getModelAttributeIncrement(
    elementVersions,
    'name',
    'Solution'
  );
  return increment;
};

export const getElementCoords = (state, elementVersionId) => {
  const productId = getActiveProductId(state);
  const rootNodeId = getRootNodeId(state);
  const rootElement = getElement(state, rootNodeId);
  const orphanNodesList = getOrphanNodesList(state);
  const elements = getElements(state);
  const elementVersions = getElementVersions(state);
  const collapsedNodesList = getCollapsedNodesList(state);
  const activeView = getInventionUi(state).activeProduct
    ? 'elements'
    : 'solutions';
  const element = addFromSchema('element', {
    id: 'new-element-id',
    elementVersion: elementVersionId,
  });
  let preferredElementVersionsMap;

  if (productId) {
    const product = getProduct(state, productId);
    const preferredElementVersionsList = product.preferredElementVersionsList;
    preferredElementVersionsMap = keyBy(preferredElementVersionsList, (obj) => {
      return `${obj.element}_${obj.elementVersion}`;
    });
  }
  const _elementVersions = {
    ...elementVersions,
    [elementVersionId]: {
      ...elementVersions[elementVersionId],
      elementsList: [
        ...elementVersions[elementVersionId].elementsList,
        element.id,
      ],
    },
  };

  const _elements = {
    ...elements,
    [element.id]: element,
  };

  const coordinatesMap = getCoordinatesMap({
    state,
    rootElement,
    orphanNodesList,
    elements: _elements,
    elementVersions: _elementVersions,
    collapsedNodesList,
    activeView,
    preferredElementVersionsMap,
  });

  return {
    x: coordinatesMap[element.id].endX,
    y: coordinatesMap[element.id].endY,
  };
};

export const getParentlessElementCoords = (state) => {
  const rootNodeId = getRootNodeId(state);
  const rootElement = getElement(state, rootNodeId);
  const orphanNodesList = getOrphanNodesList(state);
  const elements = getElements(state);
  const elementVersions = getElementVersions(state);
  const activeView = getInventionUi(state).activeProduct
    ? 'elements'
    : 'solutions';
  const element = addFromSchema('element', { id: 'new-element-id' });
  const _orphanNodesList = [...orphanNodesList, element.id];
  const _elements = {
    ...elements,
    [element.id]: element,
  };

  const coordinatesMap = getCoordinatesMap({
    state,
    rootElement,
    orphanNodesList: _orphanNodesList,
    elements: _elements,
    elementVersions,
    activeView,
  });

  return {
    x: coordinatesMap[element.id].endX,
    y: coordinatesMap[element.id].endY,
  };
};

export const getElementVersionCoords = (state, elementId) => {
  const rootNodeId = getRootNodeId(state);
  const rootElement = getElement(state, rootNodeId);
  const orphanNodesList = getOrphanNodesList(state);
  const elements = getElements(state);
  const elementVersions = getElementVersions(state);
  const activeView = getInventionUi(state).activeProduct
    ? 'elements'
    : 'solutions';
  const collapsedNodesList = getCollapsedNodesList(state);

  const elementVersion = addFromSchema('element-version', {
    id: 'new-element-version-id',
    x: elements[elementId].x,
    y: elements[elementId].y,
  });

  const _elements = {
    ...elements,
    [elementId]: {
      ...elements[elementId],
      elementVersionsList: [
        ...elements[elementId].elementVersionsList,
        elementVersion.id,
      ],
    },
  };

  const _elementVersions = {
    ...elementVersions,
    [elementVersion.id]: elementVersion,
  };

  const coordinatesMap = getCoordinatesMap({
    state,
    rootElement,
    orphanNodesList,
    elements: _elements,
    elementVersions: _elementVersions,
    collapsedNodesList,
    activeView,
  });

  const endX = coordinatesMap[elementVersion.id]
    ? coordinatesMap[elementVersion.id].endX
    : elements[elementId].x;
  const endY = coordinatesMap[elementVersion.id]
    ? coordinatesMap[elementVersion.id].endY
    : elements[elementId].y;

  return {
    x: endX,
    y: endY,
  };
};

export const getInvention = (state) => getInventionState(state);

export const getInventionId = (state) => {
  const invention = getInventionState(state);
  return (invention && invention.id) || null;
};

export const getHighlights = (state) =>
  get(getInventionState(state), `highlights`);

export const getInventionPatentSpecification = (state) =>
  get(getInventionState(state), `patentSpecification`);

export const getDrawingsList = (state) =>
  get(getInventionState(state), `drawingsList`);

export const getFiguresList = (state) =>
  get(getInventionState(state), `figuresList`);

export const getElementNameExists = (state, _elementId, name) => {
  let elementsList = getElementsList(state);
  elementsList = elementsList.filter((id) => id !== _elementId);
  const elements = elementsList.map((elementId) =>
    getElement(state, elementId)
  );
  const existingElement =
    name && elements.find((element) => isTextEqual(element.name, name));
  return existingElement ? true : false;
};

export const getTermNameExists = (state, termId, name) => {
  let termsList = getTermsList(state);
  termsList = termsList.filter((id) => id !== termId);
  const terms = termsList.map((termId) => getTerm(state, termId));
  const existing = terms.find((term) => isTextEqual(term.name, name));
  return existing ? true : false;
};

export const getNameExists = (state, id, name) => {
  const elementNameExists = getElementNameExists(state, id, name);
  const termNameExists = getTermNameExists(state, id, name);
  return elementNameExists || termNameExists;
};

export const getDeletedReferencesList = createSelector(
  (state) => getInventionState(state).deletedReferencesList,
  (deletedReferencesList) => {
    return deletedReferencesList;
  }
);

export const getDeletedElementsList = createSelector(
  (state) => getInventionState(state).deletedElementsList,
  (deletedElementsList) => {
    return deletedElementsList;
  }
);
