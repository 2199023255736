import { action, computed } from '@ember/object';
import {
  getActiveProductId,
  getInventionUi,
} from '../../../selectors/invention-ui';

import Component from '@glimmer/component';
import { batchGroupBy } from '../../../utils/redux';
import { connect } from 'ember-redux';
import { getProductsList } from '../../../selectors/product';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

const dispatchToActions = {};

const stateToComputed = (state) => ({
  inventionUi: getInventionUi(state),
  productsList: getProductsList(state),
  activeProductId: getActiveProductId(state),
});

class ProductsContext extends Component {
  @service applicationState;
  @service data;
  @service tracking;

  @tracked switched;

  get classNames() {
    let classNames = [this.styleNamespace, 'products-context'];
    return classNames.join(' ');
  }

  get styleNamespace() {
    return podNames['products-context'];
  }

  @computed('applicationState.undoIndex')
  get undoIndex() {
    return this.applicationState.undoIndex;
  }

  @computed('selectedProductId', 'productsList.length')
  get canRemoveProduct() {
    return this.productsList.length > 1 && this.selectedProductId;
  }

  @computed(
    'inventionUi.selectedProducts.[]'
  )
  get selectedProductId() {
    let selectedProductId =
      (this.inventionUi &&
        this.inventionUi.selectedProducts &&
        this.inventionUi.selectedProducts.length &&
        this.inventionUi.selectedProducts[0]) ||
      null

    return selectedProductId;
  }

  @action
  onUpdate() {
    this.switched = !this.switched;
  }

  @action
  addProduct() {
    batchGroupBy.start();
    const productId = this.data.addProduct();
    this.data.selectProduct(productId);
    batchGroupBy.end();
    this.tracking.trackEvent('added_product');
  }

  @action
  removeProduct(productId) {
    batchGroupBy.start();
    this.data.removeProduct(productId);
    batchGroupBy.end();
    this.tracking.trackEvent('removed_product');
  }

  @action
  setActiveProduct(productId) {
    batchGroupBy.start();
    this.data.setActiveProduct(productId);
    if (this.args.onClose) {
      this.args.onClose();
    }
    batchGroupBy.end();
    this.tracking.trackEvent('set_active_product');
  }

  @action
  duplicateProduct(productId) {
    batchGroupBy.start();
    this.data.duplicateProduct(productId);
    batchGroupBy.end();
    this.tracking.trackEvent('duplicated_product');
  }

  @action
  onSortProductsList(productsList) {
    batchGroupBy.start();
    this.data.sortProductsList(productsList);
    batchGroupBy.end();
    this.tracking.trackEvent('sorted_products');
  }

  @action
  selectProduct(productId) {
    batchGroupBy.start();
    if (this.selectedProductId === productId) {
      this.data.selectProduct();
    } else {
      this.data.selectProduct(productId);
    }
    batchGroupBy.end();
    this.tracking.trackEvent('selected_product');
  }

}

export default connect(stateToComputed, dispatchToActions)(ProductsContext);
