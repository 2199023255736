import {
  ADD_GRAPH,
  SET_ROOT_NODE,
  UPDATE_GRAPH,
  UPDATE_ORPHAN_NODES_LIST,
} from './actionTypes';
import { getGraph, getProductGraphs } from '../utils/graph';

import { getGraphIndex } from '../selectors/graph';

export const setRootNode = (nodeId, nodeType) => {
  return {
    type: SET_ROOT_NODE,
    payload: {
      nodeId,
      nodeType,
    },
  };
};

export const updateOrphanNodesList = (orphanNodesList) => {
  return {
    type: UPDATE_ORPHAN_NODES_LIST,
    payload: {
      orphanNodesList,
    },
  };
};

export const addGraph = (state) => {
  const {
    elementsMap,
    elementVersionsMap,
    disconnectedNodesList,
    methodsList,
    methodsMap,
  } = getGraph(state);

  const products = getProductGraphs(state);
  const index = 0;

  return {
    type: ADD_GRAPH,
    payload: {
      elementsMap,
      elementVersionsMap,
      disconnectedNodesList,
      methodsList,
      methodsMap,
      products,
      index,
    },
  };
};

export const updateGraph = (state) => {
  const {
    elementsMap,
    elementVersionsMap,
    disconnectedNodesList,
    methodsList,
    methodsMap,
  } = getGraph(state);

  const products = getProductGraphs(state);
  const oldGraphIndex = getGraphIndex(state) || 0;
  const index = oldGraphIndex + 1;

  return {
    type: UPDATE_GRAPH,
    payload: {
      elementsMap,
      elementVersionsMap,
      disconnectedNodesList,
      methodsList,
      methodsMap,
      products,
      index,
    },
  };
};
