/* eslint-disable ember/no-new-mixins */
import Mixin from '@ember/object/mixin';
import { task, timeout } from 'ember-concurrency';
import ENV from '../config/environment';

export default Mixin.create({

  init() {
    this._super(...arguments);
    this.set('changedAttributes', {});
  },

  debounceUpdateAttributes: task(function*(
    attributes,
    DEBOUNCE_MS = ENV.DEBOUNCE_MS
  ) {
    const changedAttributes = {
      ...this.changedAttributes,
      ...attributes
    };

    this.set('changedAttributes', changedAttributes);
    const saveModel = this.saveModel;

    yield timeout(DEBOUNCE_MS);

    this.onDebounceUpdateAttributes(changedAttributes, saveModel);

    this.set('changedAttributes', {});
  }).restartable(),


  onDebounceUpdateAttributes() {
    // overwrite this
  }

});
