import { action, computed } from '@ember/object';
import {
  getActiveComparisonElement,
  getInventionUi,
} from '../../../selectors/invention-ui';
import {
  getComparison,
  getComparisons,
  getComparisonsList,
} from '../../../selectors/comparison';

import Component from '@glimmer/component';
import { batchGroupBy } from '../../../utils/redux';
import { connect } from 'ember-redux';
import { getElement } from '../../../selectors/element';
import { getPreferredElementVersionId } from '../../../selectors/product';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

const dispatchToActions = {};

const stateToComputed = (state) => ({
  inventionUi: getInventionUi(state),
  comparisonsList: getComparisonsList(state),
  allComparisons: getComparisons(state),
  activeElementId: getActiveComparisonElement(state),
});

class ComparisonContext extends Component {
  @service applicationState;
  @service data;
  @service tracking;

  @tracked switched = false;

  get classNames() {
    let classNames = [this.styleNamespace, 'comparison-context'];
    return classNames.join(' ');
  }

  get styleNamespace() {
    return podNames['comparison-context'];
  }

  @computed('applicationState.undoIndex')
  get undoIndex() {
    return this.applicationState.undoIndex;
  }

  // eslint-disable-next-line ember/require-computed-property-dependencies
  @computed('comparisons.[]')
  get productComparisonList() {
    return this.comparisons.map((comparison) => comparison.id);
  }

  // eslint-disable-next-line ember/require-computed-property-dependencies
  @computed('comparisonsList.[]', 'args.productId', 'allComparisons')
  get comparisons() {
    const state = this.redux.getState();
    return this.comparisonsList
      .map((comparisonId) => getComparison(state, comparisonId))
      .filter(
        (comparison) =>
          comparison.product && comparison.product === this.args.productId
      );
  }

  // eslint-disable-next-line ember/require-computed-property-dependencies
  @computed('comparisons.@each.featuresList', 'allComparisons')
  get novelFeaturesList() {
    let featuresList = [];
    this.comparisons.forEach((comparison) => {
      featuresList = [...featuresList, ...comparison.featuresList];
    });

    return featuresList;
  }

  // eslint-disable-next-line ember/require-computed-property-dependencies
  @computed('activeElementId', 'args.productId')
  get activeElementVersionId() {
    const state = this.redux.getState();
    return (
      this.activeElementId &&
      this.args.productId &&
      getPreferredElementVersionId(
        state,
        this.activeElementId,
        this.args.productId
      )
    );
  }

  // eslint-disable-next-line ember/require-computed-property-dependencies
  @computed('activeElementId')
  get activeElement() {
    const state = this.redux.getState();
    return this.activeElementId && getElement(state, this.activeElementId);
  }

  @action
  onUpdate() {
    this.switched = !this.switched;
  }

  @action
  onSelectElement(elementId) {
    batchGroupBy.start();
    this.data.setActiveComparisonElement(elementId);
    batchGroupBy.end();
  }

  @action
  onUpdateComparison(featureId, comparisonId, isNovel) {
    const state = this.redux.getState();
    const comparison = getComparison(state, comparisonId);
    if (isNovel) {
      const featuresList = comparison.featuresList.filter(
        (id) => id !== featureId
      );
      this.data.updateComparison(comparisonId, { featuresList });
      this.tracking.trackEvent('prior_art_comparison_added_novelty');
    } else {
      const featuresList = [...comparison.featuresList, featureId].uniq();
      this.data.updateComparison(comparisonId, { featuresList });
      this.tracking.trackEvent('prior_art_comparison_removed_novelty');
    }
  }
}

export default connect(stateToComputed, dispatchToActions)(ComparisonContext);
