import {
  getPreferredElementVersionId,
  getPreferredProductId,
} from '../selectors/product';

import { alphabet } from '../constants/settings';
import { getDrawing } from '../selectors/drawing';
import { getElement } from '../selectors/element';
import { getElementVersion } from '../selectors/element-version';
import { getFiguresList } from '../selectors/figure';
import { getMarker } from '../selectors/marker';
import { getMethodNode } from '../selectors/method-node';
import { getMethodNodeOrdinal } from '../selectors/invention';
import { getMethodTraversal } from './method';
import { getTerm } from '../selectors/term';
import moize from 'moize';
import { uniq } from 'lodash';

const _getFigureNumbering = (numberingIndex, { state, baseMultiplier = 100 }) => {
  let baseNumber;
  let elementBaseNumber;
  const visited = {};
  const preferredProductId = getPreferredProductId(state);
  const figuresList = getFiguresList(state);

  figuresList.forEach((figure, index) => {
    baseNumber = baseMultiplier * (index + 1);

    if (figure.type === 'drawing') {
      const drawing = getDrawing(state, figure.id);
      const drawingMarkers = drawing.markersList.map((markerId) =>
        getMarker(state, markerId)
      );
      const markerReferences = drawingMarkers
        .filter((marker) => {
          return (
            (marker.term &&
              !visited[marker.term] &&
              getTerm(state, marker.term)) ||
            (marker.element &&
              marker.elementVersion &&
              !visited[marker.element] &&
              getElementVersion(state, marker.elementVersion) &&
              getElement(state, marker.element) &&
              getElement(state, marker.element).category === 'part')
          );
        })
        .map((marker) => marker.element || marker.term);
      const uniqReferences = uniq(markerReferences);

      elementBaseNumber = 0;

      uniqReferences.forEach((refId) => {
        const number = baseNumber + elementBaseNumber;
        visited[refId] = {
          default: `${number}`,
        };

        const element = getElement(state, refId);
        if (element) {
          let elementVersionsList = [...element.elementVersionsList];

          const prefferedElementVersionId = getPreferredElementVersionId(
            state,
            element.id,
            preferredProductId
          );

          visited[refId][prefferedElementVersionId] = `${number}`;

          elementVersionsList = elementVersionsList.filter(
            (id) => id !== prefferedElementVersionId
          );

          elementVersionsList.forEach((elementVersionId, index) => {
            const letter = alphabet[index];
            visited[refId][elementVersionId] = `${number}${letter}`;
          });
        }

        elementBaseNumber++;
      });
    }
    if (figure.type === 'method') {
      //
      const methodId = figure.id;
      const methodTraveral = getMethodTraversal(state, methodId);

      if (methodTraveral) {
        const traversedNodesList = methodTraveral.traversedNodesList;

        traversedNodesList.forEach((methodNodeId) => {
          const methodNode = getMethodNode(state, methodNodeId);
          if (methodNode.type === 'element' && methodNode.element) {
            const refId = methodNode.element;
            const number = getMethodNodeOrdinal(
              state,
              methodId,
              methodNodeId,
              traversedNodesList
            );

            visited[refId] = {
              default: `${number}`,
            };

            const element = getElement(state, methodNode.element);

            if (element) {
              let elementVersionsList = [...element.elementVersionsList];

              const prefferedElementVersionId = getPreferredElementVersionId(
                state,
                element.id,
                preferredProductId
              );

              visited[refId][prefferedElementVersionId] = `${number}`;

              elementVersionsList = elementVersionsList.filter(
                (id) => id !== prefferedElementVersionId
              );

              elementVersionsList.forEach((elementVersionId, index) => {
                const letter = alphabet[index];
                visited[refId][elementVersionId] = `${number}${letter}`;
              });
            }
          }
        });
      }
    }
  });
  return visited;
};

const _getLocalDrawingNumbering = (numberingIndex, drawingId, state) => {
  const visited = {};
  const preferredProductId = getPreferredProductId(state);
  const drawing = getDrawing(state, drawingId);
  const drawingMarkers = drawing.markersList.map((markerId) =>
    getMarker(state, markerId)
  );
  const markerReferences = drawingMarkers
    .filter((marker) => {
      return (
        (marker.term && !visited[marker.term] && getTerm(state, marker.term)) ||
        (marker.element &&
          marker.elementVersion &&
          !visited[marker.element] &&
          getElementVersion(state, marker.elementVersion) &&
          getElement(state, marker.element))
      );
    })
    .map((marker) => marker.element || marker.term);
  const uniqReferences = uniq(markerReferences);

  let number = 1;

  uniqReferences.forEach((refId) => {
    visited[refId] = {
      default: `${number}`,
    };

    const element = getElement(state, refId);
    if (element) {
      let elementVersionsList = [...element.elementVersionsList];

      const prefferedElementVersionId = getPreferredElementVersionId(
        state,
        element.id,
        preferredProductId
      );

      visited[refId][prefferedElementVersionId] = `${number}`;

      elementVersionsList = elementVersionsList.filter(
        (id) => id !== prefferedElementVersionId
      );

      elementVersionsList.forEach((elementVersionId, index) => {
        const letter = alphabet[index];
        visited[refId][elementVersionId] = `${number}${letter}`;
      });
    }

    number++;
  });

  return visited;
};

const getFigureNumberingSerializer = (args) => {
  return [JSON.stringify(args[0])];
};

export const getFigureNumbering = moize.serializeWith(getFigureNumberingSerializer)(
  _getFigureNumbering
);



const getLocalDrawingNumberingSerializer = (args) => {
  return [JSON.stringify(args[0]), JSON.stringify(args[1])];
};

export const getLocalDrawingNumbering = moize.serializeWith(
  getLocalDrawingNumberingSerializer
)(_getLocalDrawingNumbering);
