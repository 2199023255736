export default {
  attributes: {
    id: {
      type: 'uuid',
      defaultValue: null
    },
    name: {
      type: 'string',
      defaultValue: ''
    },
    type: {
      type: 'type',
      defaultValue: 'element-version' // 
    },
    known: {
      type: 'boolean',
      defaultValue: false
    },
    category: {
      type: 'type',
      defaultValue: 'machine' // article-of-manufacture, process, composition
    },
    element: {
      type: 'uuid',
      defaultValue: null
    },
    method: {
      type: 'uuid',
      defaultValue: null
    },
    amountType: {
      type: 'type',
      defaultValue: 'one' // 'plurality, custom
    },
    amountCustom: {
      type: 'string',
      defaultValue: '' 
    },
    inventing: {
      type: 'boolean',
      defaultValue: true
    },
    elementsList: {
      type: 'array',
      defaultValue: []
    },
    constraintsList: {
      type: 'array',
      defaultValue: []
    },
    comprisesList: {
      type: 'array',
      defaultValue: []
    },
    featuresList: {
      type: 'array',
      defaultValue: []
    },
    // term prop
    definitionsList: {
      type: 'array',
      defaultValue: []
    },
    markersList: {
      type: 'array',
      defaultValue: []
    },
    createdAt: {
      type: 'date'
    },
    x: {
      type: 'number',
      defaultValue: 0
    },
    y: {
      type: 'number',
      defaultValue: 0
    }
    // parent: {
    //   type: 'uuid',
    //   defaultValue: null
    // },
  }
}
