import { action, computed } from '@ember/object';
import { getElement, getElementsList } from '../../../selectors/element';
import { getTerm, getTermsList } from '../../../selectors/term';

import Component from '@glimmer/component';
import { connect } from 'ember-redux';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import uuid from 'uuid/v4';

const dispatchToActions = {};

const stateToComputed = (state) => ({
  elementsList: getElementsList(state),
  termsList: getTermsList(state),
});

class DrawingCreateBulkItem extends Component {
  @service redux;
  @service data;

  @tracked selectedItem;
  @tracked query;
  @tracked value = '';
  @tracked results;
  @tracked result;
  @tracked isFocused = false;

  searcher;
  domElementId = `drawing-create-bulk-item-${uuid()}`;

  get styleNamespace() {
    return podNames['drawing-create-bulk-item'];
  }

  get classNames() {
    let classNames = [this.styleNamespace];
    if (this.args.isSelected) classNames.push('is-selected');
    return classNames.join(' ');
  }

  @computed('args.{allBulkCreateItems,bulkCreateItemId}')
  get bulkCreateItem() {
    return this.args.allBulkCreateItems[this.args.bulkCreateItemId]
  }

  matcher(value = '', text = '') {
    return value.toUpperCase().startsWith(text.toUpperCase()) ? true : false;
  }

  @action
  didInsert() {
    const textarea = document.querySelector(`#${this.domElementId} .textarea`);
    textarea.focus();
  }

  get placeholder() {
    let placeholder;
    switch (this.bulkCreateItem.category) {
      case 'part':
        placeholder = 'Type to find or add a part';
        break;
      case 'system':
        placeholder = 'Type to find or add a system';
        break;
      case 'term':
        placeholder = 'Type to find or add a term';
        break;
    }
    return placeholder;
  }

  @computed('elements.[]',  'terms.[]',  'bulkCreateItem.category')
  get options() {
    return this.bulkCreateItem.category === 'term' ? this.terms : this.elements;
  }

  @computed('elementsList.[]', 'bulkCreateItem.category')
  get elements() {
    const state = this.redux.getState();
    return this.elementsList
      .filter((elementId) => {
        const element = getElement(state, elementId);
        return element && !element.instanceOf && element.category === this.bulkCreateItem.category;
      })
      .map((elementId) => getElement(state, elementId));
  }

  @computed('termsList.[]', 'bulkCreateItem.category')
  get terms() {
    const state = this.redux.getState();
    return this.termsList
      .map((termId) => getTerm(state, termId));
  }

  @action
  onSelectResult(resultId, resultType, resultElementVersion, category) {
    this.args.onUpdate(this.args.bulkCreateItemId, {
      category: resultType === 'term' ? 'term' : category,
      resultId,
      resultType,
      resultElementVersion,
    });
  }

  @action
  onChange(name) {
    const results = [];
    const matches = this.options.filter((option) => {
      return this.matcher(option.name, name);
    });

    matches.forEach((match) => {
      if (match.type === 'element') {
        match.elementVersionsList.forEach((elementVersionId) => {
          results.push({
            id: match.id,
            type: 'element',
            elementVersionId,
          });
        });
      } else {
        results.push({
          id: match.id,
          type: 'term',
        });
      }
    });

    this.value = name;
    this.results = results;
    this.args.onUpdate(this.args.bulkCreateItemId, { name });
  }

  @action
  onSelectCategory(category) {
    this.args.onUpdate(this.args.bulkCreateItemId, {
      category
    });
    this.data.setDefaultMarkerCategory(category);
    this.results = [];
  }

  @action
  onEnter() {
    if (this.args.onEnter) {
      this.args.onEnter(this.args.bulkCreateItemId);
    }
  }

  @action
  onFocus() {
    this.isFocused = true;
  }

  @action
  onBlur() {
    this.isFocused = false;
  }
}

export default connect(
  stateToComputed,
  dispatchToActions
)(DrawingCreateBulkItem);
