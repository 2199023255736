import { every, forEach, uniqBy } from 'lodash';
import { getElement, getElementRequirementsList } from '../selectors/element';
import { getIsPreferred, getPreferredProductId } from '../selectors/product';

import $ from 'jquery';
import Engine from 'json-rules-engine-simplified';
import FeatureRules from '../constants/feature-rules/index';
import FeatureTypes from '../constants/types/features';
import FigureRules from '../constants/figure-rules/index';
import MethodRules from '../constants/method-rules/index';
import { getDrawing } from '../selectors/drawing';
import { getElementVersion } from '../selectors/element-version';
import { getFeature } from '../selectors/feature';
import { getMarker } from '../selectors/marker';
import { getMethod } from '../selectors/method';
import { getPatentSpecification } from '../selectors/patent-specification';
import { getTerm } from '../selectors/term';
import indefinite from 'indefinite';
import moize from 'moize';
import { typeOf } from '@ember/utils';
import { underscore } from '@ember/string';

const Handlebars = window.Handlebars;

const _getEngine = (customRules) => {
  let rules = customRules ? customRules : [];
  rules = rules.concat(FeatureRules);
  rules = rules.concat(FigureRules);
  rules = rules.concat(MethodRules);
  return new Engine(rules);
};

export const getEngine = moize(_getEngine, {
  isDeepEqual: true,
});

const _getCompiledTemplate = (template = '') => {
  return Handlebars.compile(template);
};

const getCompiledTemplate = moize(_getCompiledTemplate);

const _getMissingContext = (required = [], context = {}) => {
  return required.filter((prop) => {
    return typeOf(context[prop]) === 'undefined';
  });
};

const getMissingContext = moize(_getMissingContext, {
  isDeepEqual: true,
});

const _getRule = (query = {}, engine) => {
  const rules = engine.run(query);
  return rules[0];
};

export const getRule = moize(_getRule, {
  isDeepEqual: true,
});

const _realize = (query = {}, context = {}, customRules = []) => {
  const engine = getEngine(customRules);
  const rule = getRule(query, engine);
  if (!rule) {
    // eslint-disable-next-line no-console
    console.error(`Can't find realization rule for the query`, query);
    return '';
  }
  const { required, template } = rule;
  const compiledTemplate = getCompiledTemplate(template);
  const missingContext = getMissingContext(required, context);

  if (missingContext.length) {
    // eslint-disable-next-line no-console
    console.error(
      `Can't realize b/c missing context in the rule`,
      rule,
      missingContext
    );
    return '';
  }

  return compiledTemplate(context);
};

export const realize = moize(_realize, {
  isDeepEqual: true,
});

export const mentionContentTemplate = (
  elementName,
  elementId,
  elementVersionId
) => {
  const dataElementVersionId = elementVersionId
    ? `data-element-version-id="${elementVersionId}"`
    : '';
  return `<mention class="mention" data-id="${elementId}" data-name="${elementName}" data-type="element" ${dataElementVersionId}>${elementName}</mention>`;
};

export const termMentionContentTemplate = (termName, termId) => {
  return `<mention class="mention" data-id="${termId}" data-name="${termName}" data-type="term">${termName}</mention>`;
};

export const figureMentionContentTemplate = (name, figureId, figureType) => {
  return `<mention class="mention" data-id="${figureId}" data-name="${name}" data-type="${figureType}">${name}</mention>`;
};

export const placeholderContentTemplate = (
  content = '',
  modelId,
  modelType,
  modelAttr,
  featureType
) => {
  return `<span data-model-id="${modelId}" data-model-type="${modelType}" data-model-attr="${modelAttr}" data-type="${featureType}" class="realization-placeholder">${content}</span>`;
};

export const realizationContentTemplate = (
  content = '',
  modelId,
  modelType,
  modelAttr,
  featureType
) => {
  return `<span data-model-id="${modelId}"  data-model-type="${modelType}"  data-model-attr="${modelAttr}" data-type="${featureType}" class="realization-content">${content}</span>`;
};

export const missingRealizationContentTemplate = (realizationContent = '') =>
  `<span class="missing-realization-content">${realizationContent}</span>`;

export const indefiniteArticle = (
  content = '',
  { capitalize = false, numbers = 'colloquial', articleOnly = false } = {}
) => {
  if (!content) {
    return '';
  }

  const text = $(`<div>${content}</div>`).text();
  const firstWord = text.split(' ')[0];
  const articleAndWord = indefinite(firstWord, {
    capitalize: capitalize,
    numbers: numbers,
  });
  const article = articleAndWord.split(' ')[0];

  return articleOnly ? article : `${article} ${content}`;
};

export const getElementVersionRealizations = ({
  models,
  state,
  elementId,
  elementVersionId,
  realizedFeatures = {},
  languageCategory = 'patent_specification',
}) => {
  const preferredProductId = getPreferredProductId(state);

  const isPreferred = getIsPreferred(
    state,
    elementId,
    elementVersionId,
    preferredProductId
  );

  let language;

  switch (languageCategory) {
    case 'patent_specification':
      // language = isPreferred
      //   ? 'patent_specification_preferred_version'
      //   : 'patent_specification_alt_version';
      language = isPreferred
        ? 'patent_specification_preferred_version'
        : 'patent_specification_preferred_version';
      break;
    // case 'disclosure':
    //   language = 'report_list_abbreviated';
    //   break;
  }

  const elementVersion = getElementVersion(state, elementVersionId);
  const _element = getElement(state, elementVersion.element);
  const definitionsList = elementVersion.definitionsList;
  const requirementsList = getElementRequirementsList(state, _element.id);
  const constraintsList = elementVersion.constraintsList;
  const featuresList = elementVersion.featuresList;
  const comprisesList = elementVersion.comprisesList;

  const requirementsFeatures = requirementsList.map((featureId) =>
    getFeature(state, featureId)
  );

  const definitionsFeatures = definitionsList.map((featureId) =>
    getFeature(state, featureId)
  );

  const constraintsFeatures = constraintsList.map((featureId) =>
    getFeature(state, featureId)
  );

  const features = featuresList.map((featureId) =>
    getFeature(state, featureId)
  );
  const comprisesFeatures = comprisesList.map((featureId) =>
    getFeature(state, featureId)
  );

  let allFeatures = requirementsFeatures
    .concat(definitionsFeatures)
    .concat(constraintsFeatures)
    .concat(features)
    .concat(comprisesFeatures);

  allFeatures = allFeatures.filter((feature) => feature && feature.value);

  const featureGroups = getFeatureGroups(allFeatures, realizedFeatures);

  const realizations = featureGroups.map((group) => {
    const featuresList = group.featuresList;
    const features = featuresList.map((featureId) =>
      getFeature(state, featureId)
    );

    const featureTypeId = features[0] && features[0].type;

    const options = {
      state,
      featuresList,
      featureTypeId,
      elementId: _element.id,
      elementVersionId,
      language,
    };

    if (models) {
      const featureModels = featuresList.map((featureId) =>
        models.findOrCreate(featureId, 'feature', getFeature(state, featureId))
      );
      options['featureModels'] = featureModels;
    }
    return getFeatureRealization(options);
    // return getFeatureRealization(options);
  });

  return realizations;
};

export const getTermRealization = ({
  state,
  models,
  termId,
  language = 'patent_specification_preferred_version',
}) => {
  const term = getTerm(state, termId);
  const definitionsList = term.definitionsList.filter((featureId) => {
    const feature = getFeature(state, featureId);
    return feature && feature.type === 'definition';
  });
  const examplesList = term.definitionsList.filter((featureId) => {
    const feature = getFeature(state, featureId);
    return feature && feature.type === 'analogs';
  });
  const detailsList = term.definitionsList.filter((featureId) => {
    const feature = getFeature(state, featureId);
    return feature && feature.type === 'detail';
  });

  let definitionsModels =
    models && definitionsList.map((featureId) => models.find(featureId));
  let detailsModels =
    models && examplesList.map((featureId) => models.find(featureId));
  let examplesModels =
    models && detailsList.map((featureId) => models.find(featureId));

  const definitionsRealization = definitionsList.length
    ? getFeatureRealization({
        state,
        featureModels: definitionsModels,
        featuresList: definitionsList,
        featureTypeId: 'definition',
        termId,
        language,
      })
    : '';

  const detailsRealization = detailsList.length
    ? getFeatureRealization({
        state,
        featureModels: detailsModels,
        featuresList: detailsList,
        featureTypeId: 'detail',
        termId,
        language,
      })
    : '';

  const examplesRealization = examplesList.length
    ? getFeatureRealization({
        state,
        featureModels: examplesModels,
        featuresList: examplesList,
        featureTypeId: 'analogs',
        termId,
        language,
      })
    : '';

  return `${definitionsRealization} ${detailsRealization} ${examplesRealization}`;
};

export const getStepLeadin = (index) => {
  // TODO this only goes up to 26 steps
  const alphabet = [
    'a',
    'b',
    'c',
    'd',
    'e',
    'f',
    'g',
    'h',
    'i',
    'j',
    'k',
    'l',
    'm',
    'n',
    'o',
    'p',
    'q',
    'r',
    's',
    't',
    'u',
    'v',
    'w',
    'x',
    'y',
    'z',
  ];
  return alphabet[index];
};

export const getFeatureGroups = (features, realizedFeatures) => {
  const groups = [];
  const singularFeatureTypes = ['measurement', 'detail'];

  let index = 0;

  features.forEach((feature) => {
    if (!realizedFeatures[feature.id]) {
      realizedFeatures[feature.id] = true;

      if (singularFeatureTypes.includes(feature.type)) {
        index++;
        groups[index] = {
          type: feature.type,
          featuresList: [feature.id],
        };
      } else if (!groups[index]) {
        groups[index] = {
          type: feature.type,
          featuresList: [feature.id],
        };
      } else if (groups[index] && groups[index].type === feature.type) {
        groups[index] = {
          ...groups[index],
          featuresList: [...groups[index].featuresList, feature.id],
        };
      } else if (groups[index] && groups[index].type !== feature.type) {
        index++;
        groups[index] = {
          type: feature.type,
          featuresList: [feature.id],
        };
      }
    }
  });

  return groups;
};

export const hasValue = (value) => {
  let hasValue = false;

  if (typeof value === 'object') {
    if (value.element) {
      hasValue = true;
    } else {
      hasValue = every(value, (val) => (val ? true : false));
    }
  } else {
    hasValue = value ? true : false;
  }
  return hasValue;
};

export const getFeatureRealization = ({
  state,
  featureModels,
  featuresList,
  featureTypeId,
  termId,
  termModel,
  elementId,
  elementVersionId,
  elementModel,
  language,
  wrapContent = true,
}) => {
  let features = [];

  if (featureModels) {
    features = featureModels;
  } else {
    features = featuresList
      .filter((featureId) => getFeature(state, featureId))
      .map((featureId) => getFeature(state, featureId));
    // .filter((feature) => feature.value);
  }

  let element;

  if (elementModel) {
    element = elementModel;
  } else {
    element = elementId && getElement(state, elementId);
  }

  let term;

  if (termModel) {
    term = termModel;
  } else {
    term = termId && getTerm(state, termId);
  }

  const featureType = FeatureTypes[featureTypeId];
  const { inputType, examples, inputs, defaultValue } = featureType;
  const example = examples[0];
  const underscoredTypeId = underscore(featureType.id);
  const query = {
    subject: 'feature',
    type: underscoredTypeId,
    language,
  };

  query[`${underscoredTypeId}_count`] = featuresList.length;

  if (inputType === 'form') {
    features.forEach((feature) => {
      const featureValue = feature.value;
      forEach(inputs, (input) => {
        query[`${input.id}`] = featureValue[input.id] || defaultValue[input.id];
      });
    });
  }

  const context = {};

  context['element'] = missingRealizationContentTemplate(
    termId ? 'Term Missing' : 'Element Missing'
  );

  if (element) {
    const mention = mentionContentTemplate(
      element.name,
      elementId,
      elementVersionId
    );

    context['element'] = mention;
  }

  if (term) {
    const mention = termMentionContentTemplate(term.name, termId);

    context['element'] = mention;
  }

  if (inputType === 'form') {
    features.forEach((feature, index) => {
      const featureValue = feature.value;
      forEach(inputs, (input) => {
        const underscoredInputId = underscore(input.id);
        if (wrapContent) {
          context[`${underscoredInputId}_${index}`] = featureValue[input.id]
            ? realizationContentTemplate(
                featureValue[input.id],
                feature.id,
                'feature',
                'value',
                input.id
              )
            : placeholderContentTemplate(
                example[input.id],
                feature.id,
                'feature',
                'value',
                input.id
              );
        } else {
          context[`${underscoredInputId}_${index}`] = featureValue[input.id]
            ? featureValue[input.id]
            : example[input.id];
        }
      });
    });
  } else if (inputType === 'element') {
    context[`${underscoredTypeId}_collection`] = [];
    features.forEach((feature, index) => {
      const _elementId = feature.value.element;
      const _amount = feature.value.amount;
      const _custom = feature.value.custom;
      const _plural = feature.value.plural;
      const _element = _elementId && getElement(state, _elementId);
      let _elementName = _element && _element.name;

      let leadIn;

      switch (_amount) {
        case 'one':
          leadIn = indefiniteArticle(_elementName, { articleOnly: true });
          break;
        case 'one-plus':
          leadIn = 'one or more';
          break;
        case 'plurality':
          leadIn = 'a plurality of';
          break;
        case 'custom':
          leadIn = _custom || '';
          break;
        default:
          leadIn = indefiniteArticle(_elementName, { articleOnly: true });
      }

      // ? realizationContentTemplate(
      //   `<span class="mention" data-plural=${_plural} data-type="element" data-id="${_elementId}">${_elementName}</span>`,
      //   feature.id,
      //   'feature',
      //   'value',
      //   featureType.id
      // )

      let value;
      if (wrapContent) {
        value = _elementId
          ? `<span class="mention" data-plural=${_plural} data-type="element" data-id="${_elementId}">${_elementName}</span>`
          : placeholderContentTemplate(
              example,
              feature.id,
              'feature',
              'value',
              featureType.id
            );
      } else {
        value = _elementId
          ? `<span class="mention" data-plural=${_plural} data-type="element" data-id="${_elementId}">${_elementName}</span>`
          : example;
      }

      value = `${leadIn} ${value}`;

      context[`${underscoredTypeId}_${index}`] = value;
      context[`${underscoredTypeId}_collection`].push(value);
    });
  } else {
    context[`${underscoredTypeId}_collection`] = [];
    features.forEach((feature, index) => {
      let value;
      const featureValue =
        feature.value &&
        typeof feature.value === 'string' &&
        feature.value.trim();
      if (wrapContent) {
        value = featureValue
          ? realizationContentTemplate(
              featureValue,
              feature.id,
              'feature',
              'value',
              featureType.id
            )
          : placeholderContentTemplate(
              example,
              feature.id,
              'feature',
              'value',
              featureType.id
            );
      } else {
        value = featureValue ? featureValue : example;
      }
      context[`${underscoredTypeId}_${index}`] = value;
      context[`${underscoredTypeId}_collection`].push(value);
    });
  }
  return realize(query, context);
};

export const getDrawingRealization = (
  state,
  drawingId,
  realizedElementVersions = {}
) => {
  const drawing = getDrawing(state, drawingId);
  const patentSpecification = getPatentSpecification(state);
  const markersList = drawing.markersList;
  const blacklist = patentSpecification.blacklist;
  const patentSpecificationMarkersList = markersList.filter(
    (markerId) =>
      !blacklist.find((blacklistItem) => markerId === blacklistItem.id)
  );
  const realizedFeatures = {};
  const filteredMarkers = patentSpecificationMarkersList
    .filter((markerId) => {
      const marker = getMarker(state, markerId);
      const elementVersionId = marker.elementVersion;
      const element = marker.element;
      return (
        element &&
        elementVersionId &&
        !realizedElementVersions[elementVersionId]
      );
    })
    .map((markerId) => {
      const marker = getMarker(state, markerId);
      return marker;
    });

  // only write marker realizations once per drawing
  const uniqMarkers = uniqBy(filteredMarkers, 'elementVersion');

  const realizations = uniqMarkers.map((marker) => {
    const elementId = marker.element;
    const elementVersionId = marker.elementVersion;
    realizedElementVersions[elementVersionId] = true;
    return getElementVersionRealizations({
      state,
      elementId,
      elementVersionId,
      elementVersionIndex: 0,
      realizedFeatures,
      languageCategory: 'patent_specification',
    }).join(' ');
  });
  return realizations.join(' ');
};

export const getMethodLegendRealization = ({
  state,
  sequence,
  language = 'patent_specification_preferred_version',
  methodId,
}) => {
  const name = `FIG. ${sequence}`;
  const mentionTemplate = figureMentionContentTemplate(
    name,
    methodId,
    'method'
  );

  const query = {
    subject: 'method',
    type: 'figure-description',
    language,
  };

  const method = getMethod(state, methodId);
  const elementId = method && method.element;
  const element = elementId && getElement(state, method.element);
  const featureId = element && element.outcome;
  const outcome =
    featureId &&
    getFeatureRealization({
      state,
      featuresList: [featureId],
      featureTypeId: 'element_step',
      elementId: elementId,
      language,
      // language: 'report_list',
    });

  const context = {
    figure_name: mentionTemplate,
    outcome: outcome,
  };

  return realize(query, context);
};

export const getDrawingLegendRealization = ({
  sequence,
  viewAngle,
  description,
  viewAngleExample,
  descriptionExample,
  language = 'patent_specification_preferred_version',
  drawingId,
}) => {
  const name = `FIG. ${sequence}`;
  const mentionTemplate = figureMentionContentTemplate(
    name,
    drawingId,
    'drawing'
  );

  const query = {
    subject: 'drawing',
    type: 'figure-description',
    language,
  };

  const context = {
    figure_name: mentionTemplate,
    view_angle: viewAngle
      ? realizationContentTemplate(viewAngle, drawingId, 'drawing', 'viewAngle')
      : placeholderContentTemplate(
          viewAngleExample,
          drawingId,
          'drawing',
          'viewAngle'
        ),
    figure_description: description
      ? realizationContentTemplate(
          description,
          drawingId,
          'drawing',
          'description'
        )
      : placeholderContentTemplate(
          descriptionExample,
          drawingId,
          'drawing',
          'description'
        ),
  };

  return realize(query, context);
};

Handlebars.registerHelper('indefinite', function (content, capitalize) {
  capitalize = capitalize === true ? true : false;
  return indefiniteArticle(content, { capitalize: capitalize });
});

Handlebars.registerHelper('cap', function (content) {
  const mentionElement = $(content)[0];
  if (mentionElement) {
    mentionElement.dataset.capFirstLetter = true;
    content = mentionElement.outerHTML;
  }
  return content;
});
