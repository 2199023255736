import Component from '@glimmer/component';
import podNames from 'ember-component-css/pod-names';


class UiShape extends Component {

  get styleNamespace() {
    return podNames['ui-shape'];
  }

  get classNames() {
    let classNames = [this.styleNamespace, 'ui-shape', 'shape'];
    if (this.args.type) classNames.push(this.args.type)
    return classNames.join(' ');
  }
  
}

export default UiShape;
