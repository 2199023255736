import Component from '@glimmer/component';
import { computed } from '@ember/object';
import { connect } from 'ember-redux';
import { getElement } from '../../../selectors/element';
import { getElementVersion } from '../../../selectors/element-version';
import podNames from 'ember-component-css/pod-names';

const stateToComputed = (state, attrs) => ({
  element: getElement(state, attrs.elementId),
  elementVersion: getElementVersion(state, attrs.elementVersionId),
});

const dispatchToActions = {};

class PreviewRealizationElementVersion extends Component {
  get styleNamespace() {
    return podNames['preview-realization-element-version'];
  }

  get classNames() {
    let classNames = [this.styleNamespace, 'preview-realization-element-version'];
    return classNames.join(' ');
  }

  @computed('element.category')
  get showOutcome() {
    return this.element && this.element.category === 'system'
  }
}

export default connect(
  stateToComputed,
  dispatchToActions
)(PreviewRealizationElementVersion);
