import {
  addAsset,
  removeAsset,
  sortAssetsList,
  updateAsset,
} from '../actions/asset';
import {
  addComparison,
  removeComparison,
  sortComparisonsList,
  updateComparison,
} from '../actions/comparison';
import {
  addComponent,
  removeComponent,
  updateComponent,
} from '../actions/component';
import {
  addCustomer,
  removeCustomer,
  sortCustomersList,
  updateCustomer,
} from '../actions/customer';
import {
  addDeletedReference,
  removeDeletedReference,
} from '../actions/deleted-reference';
import {
  addDrawing,
  removeDrawing,
  removeSelectedDrawingItems,
  sortDrawingsList,
  updateDrawing,
  updateDrawingItems,
} from '../actions/drawing';
import { addElement, removeElement, updateElement } from '../actions/element';
import {
  addElementVersion,
  removeElementVersion,
  updateElementVersion,
} from '../actions/element-version';
import {
  addFeature,
  removeFeature,
  removeFeatures,
  setActiveFeature,
  updateFeature,
} from '../actions/feature';
import { addFigures, updateFigures } from '../actions/figures';
import {
  addGraph,
  setRootNode,
  updateGraph,
  updateOrphanNodesList,
} from '../actions/graph';
import {
  addHighlight,
  removeHighlight,
  updateHighlight,
} from '../actions/highlight';
import { addImage, updateImage } from '../actions/image';
import { addInvention, updateNodeCoordinates } from '../actions/invention';
import { addMarker, updateMarker } from '../actions/marker';
import { addMethod, removeMethod, updateMethod } from '../actions/method';
import {
  addMethodEdge,
  removeMethodEdge,
  updateMethodEdge,
} from '../actions/method-edge';
import {
  addMethodNode,
  removeMethodNode,
  updateMethodNode,
} from '../actions/method-node';
import {
  addPriorArt,
  removePriorArt,
  sortPriorArtsList,
  updatePriorArt,
} from '../actions/prior-art';
import {
  addProduct,
  removeProduct,
  sortProductsList,
  updateProduct,
} from '../actions/product';
import {
  addReview,
  removeReview,
  sortReviewsList,
  updateReview,
} from '../actions/review';
import {
  addTerm,
  removeTerm,
  sortTermsList,
  updateTerm,
} from '../actions/term';
import {
  clearSelection,
  deselectDrawing,
  deselectDrawingItems,
  deselectElement,
  deselectImage,
  deselectMarker,
  deselectMethodEdge,
  deselectMethodEdgePoint,
  deselectMethodItems,
  deselectMethodNode,
  deselectTerm,
  hideExplorer,
  hideScratchpad,
  selectAsset,
  selectComparison,
  selectCustomer,
  selectDrawing,
  selectElement,
  selectElementVersion,
  selectImage,
  selectMarker,
  selectMethodEdge,
  selectMethodEdgePoint,
  selectMethodNode,
  selectPriorArt,
  selectProduct,
  selectReview,
  selectTerm,
  setActiveComparison,
  setActiveComparisonElement,
  setActiveContextTab,
  setActiveDrawing,
  setActiveElementVersionListItem,
  setActiveInventionSummaryEditorSection,
  setActiveMethod,
  setActiveMilestoneContext,
  setActivePatentSpecificationEditorSection,
  setActivePatentabilitySection,
  setActiveProduct,
  setActiveProductView,
  setActiveReview,
  setActiveView,
  setCollapsedProblem,
  setCollapsedSolution,
  setCreatingAsset,
  setCreatingComparison,
  setCreatingImageAsset,
  setCreatingReview,
  setDefaultElementCategory,
  setDefaultMarkerCategory,
  setDefaultMethodNodeType,
  setDrawingPreviewMode,
  setEditingAsset,
  setEditingComparison,
  setEditingProduct,
  setEditingReview,
  setExplorerWidth,
  setFigureType,
  setPatentSpecificationPreviewMode,
  setPreviewMode,
  setShowingAssets,
  setShowingComparisonMatrix,
  setShowingCustomers,
  setShowingDrawingSelectionModal,
  setShowingDrawings,
  setShowingElementVersionSelector,
  setShowingFigures,
  setShowingGetStarted,
  setShowingHistoryVersionCreateModal,
  setShowingInventionSummaryEditor,
  setShowingPatentSpecification,
  setShowingPatentSpecificationEditor,
  setShowingPatentability,
  setShowingPriorArts,
  setShowingProductChecklist,
  setShowingProducts,
  setShowingSearch,
  setShowingSettings,
  setUpdatingImageAsset,
  setUpdatingPriorArtAsset,
  setUpdatingProductAsset,
  setUpdatingProductCustomers,
  setUpdatingProductPriorArt,
  showExplorer,
  showNotes,
  updateCollapsedNodes,
  updateElementColumnsData,
  updateNavigationStack,
  updateNavigationStackSelectedItems,
} from '../actions/invention-ui';
import {
  defaultArtboardHeight,
  defaultArtboardWidth,
} from '../constants/settings';
import { filter, forEach, keyBy, omit, sortBy, values } from 'lodash';
import {
  findClosestSmallestIndex,
  insertAtIndex,
  insertBeforeOrAfter,
} from '../utils/array';
import {
  getActiveNavigationStack,
  getActiveProductId,
  getCollapsedDescendantsList,
  getCollapsedNodesList,
  getDefaultElementCategory,
  getElementColumnsData,
  getNavigationStack,
  getSelectedElement,
  getSelectedElementVersion,
  getSelectedElementVersions,
  getSelectedElements,
  getSelectedImages,
  getSelectedMarkers,
  getSelectedMethodEdgePoints,
  getSelectedMethodEdges,
  getSelectedMethodNodes,
  getSelectedTerms,
  getShowingFigures,
} from '../selectors/invention-ui';
import { getAsset, getAssetsList } from '../selectors/asset';
import {
  getBendPoints,
  getEdgePath,
  getEdgePositions,
} from '../utils/method-layout';
import { getComparison, getComparisonsList } from '../selectors/comparison';
import { getDrawing, getDrawings } from '../selectors/drawing';
import { getElement, getElements, getElementsList } from '../selectors/element';
import {
  getElementCoords,
  getElementIncrement,
  getElementVersionCoords,
  getElementVersionIncrement,
  getParentlessElementCoords,
  getProductIncrement,
  getTermIncrement,
} from '../selectors/invention';
import {
  getElementVersion,
  getElementVersions,
  getElementVersionsList,
} from '../selectors/element-version';
import {
  getElementVersionsMap,
  getElementsMap,
  getOrphanNodesList,
  getRootNodeId,
} from '../selectors/graph';
import { getFeature, getFeatures } from '../selectors/feature';
import { getMarker, getMarkers, getMarkersList } from '../selectors/marker';
import { getMeta, getSessionTime } from '../selectors/meta';
import { getMethodNode, getMethodNodesList } from '../selectors/method-node';
import {
  getPreferredElementVersionId,
  getProduct,
  getProductsList,
} from '../selectors/product';
import {
  getReview,
  getReviewByProductAndUser,
  getReviewsList,
} from '../selectors/review';
import { getTerm, getTermsList } from '../selectors/term';

import { ActionCreators } from 'redux-undo';
import ENV from '../config/environment';
import Service from '@ember/service';
import axios from 'axios';
// import { getImage } from '../selectors/image';
import { getComponent } from '../selectors/component';
import { getCoordinatesMap } from '../utils/graph';
import { getCustomersList } from '../selectors/customer';
import { getFeatureRealization } from '../utils/realization';
import { getHighlight } from '../selectors/highlight';
import { getImage } from '../selectors/image';
import { getMethod } from '../selectors/method';
import { getMethodEdge } from '../selectors/method-edge';
import { getPreferredElementVersionsList } from '../selectors/product';
import { getPriorArtsList } from '../selectors/prior-art';
import { getRandomMethodNodeCoords } from '../utils/method';
import { parseImageFile } from '../utils/image';
import { removeMethodEdgePoint } from '../actions/method-edge-point';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { underscore } from '@ember/string';
import { updateMeta } from '../actions/meta';
import { updatePatentSpecification } from '../actions/patent-specification';
import { updatePresentation } from '../actions/presentation';
import { updateSettings } from '../actions/settings';
import uuid from 'uuid/v4';
import moment from 'moment';
import { task } from 'ember-concurrency-decorators';
import { timeout } from 'ember-concurrency';

export default class Data extends Service {
  @service store;
  @service redux;
  @service models;
  @service sessionManager;
  @service assets;
  @service tracking;
  @service applicationState;

  @tracked canAddVersionToCloud = true;

  constructor(owner, args) {
    super(owner, args);
  }

  async addInvention(inventionId) {
    const user = this.sessionManager.userId;

    // add invention
    this.redux.store.dispatch(addInvention(inventionId, { user }));

    // add product
    const productId = uuid();
    const productName = 'Prototype 1';

    this.addProduct({ name: productName }, productId, false);
    this.setActiveProduct(productId, false);

    // add root element and version
    const productElementId = uuid();
    const productElementVersionId = uuid();
    this.addElement(
      {
        category: 'product',
        x: 0,
        y: 0,
      },
      null,
      productElementId,
      { category: 'machine' },
      productElementVersionId
    );

    // add the parts node
    const partsElementId = uuid();
    this.addElement(
      {
        category: 'part',
        name: 'Product Design',
        x: 0,
        y: 0,
      },
      null,
      partsElementId,
      { category: 'machine' }
    );

    // and connect it to the root
    this.addComprisesRelationship(productElementVersionId, partsElementId);

    // add the use
    const useElementId = uuid();
    this.addElement(
      {
        category: 'system',
        name: 'Customer Use',
        x: 0,
        y: 0,
      },
      null,
      useElementId,
      { category: 'process' }
    );

    // and connect it to the root
    this.addComprisesRelationship(productElementVersionId, useElementId);

    // add the manufacture node
    const manufactureElementId = uuid();
    this.addElement(
      {
        category: 'system',
        name: 'Manufacturing',
        x: 0,
        y: 0,
      },
      null,
      manufactureElementId,
      { category: 'process' }
    );

    // and connect it to the root
    this.addComprisesRelationship(
      productElementVersionId,
      manufactureElementId
    );

    // make productElementId the root
    this.redux.store.dispatch(setRootNode(productElementId, 'element'));

    // register disclosure and instance
    await this.registerMeta(inventionId);

    // select the product node
    // this.selectElement(productElementId);

    // clear the undo history
    this.redux.store.dispatch(ActionCreators.clearHistory());
  }

  @task
  *setAddVersionToCloudTimeout() {
    this.canAddVersionToCloud = false;
    yield timeout(30000); // only update every 30 seconds
    this.canAddVersionToCloud = true;
  }

  async autoSaveToCloud(instanceId, state, stringifiedState, name = '') {
    if (this.canAddVersionToCloud) {
      this.setAddVersionToCloudTimeout.perform();
      try {
        name = name || `Autosave - ${moment().format('MMMM Do YYYY, h:mm a')}`;

        // save it to remote
        await this.addVersionToCloud(instanceId, stringifiedState, name);

        // save any unsaved assets to remote
        await this.addAssetsToCloud(instanceId, state);
        console.log('autosaved', name)
      } catch (err) {
        console.error(err);
      }
    }
  }

  async addVersionToCloud(instanceId, state, name = '') {
    try {
      const id = uuid();
      await axios({
        method: 'POST',
        url: ENV.IDENTITY_API_URL + '/versions',
        data: {
          id,
          instanceId,
          state,
          name,
          // assets!
        },
        headers: {
          Authorization: this.sessionManager.accessToken,
        },
      });
    } catch (err) {
      console.error(err);
    }
  }

  async addAssetsToCloud(instanceId, state) {
    const instance = await this.store.findRecord('instance', instanceId);
    const instanceAssets =
      (instance.assets && instance.assets.map(({ id }) => id)) || [];
    const assetsList = getAssetsList(state);

    assetsList.forEach((assetId) => {
      if (!instanceAssets.includes(assetId)) {
        this.addAssetToCloud(instanceId, assetId);
      }
    });
  }

  async addAssetToCloud(instanceId, assetId) {
    try {
      const blob = await this.assets.getAsset(assetId, 'blob');
      const dataUri = await this.assets.getAsset(assetId, 'base64');
      const fileType = blob.type;
      const upload = {
        uri: dataUri,
      };
      await axios({
        method: 'POST',
        url: ENV.IDENTITY_API_URL + '/assets',
        data: {
          id: assetId,
          instanceId,
          fileType,
          upload,
        },
        headers: {
          Authorization: this.sessionManager.accessToken,
        },
      });
    } catch (err) {
      console.error(err);
    }
  }

  async getDisclosure(disclosureId) {
    let disclosure;

    if (!disclosureId) {
      return false;
    }

    try {
      disclosure = await axios({
        method: 'GET',
        url: ENV.IDENTITY_API_URL + `/disclosures/${disclosureId}`,
        headers: {
          Authorization: this.sessionManager.accessToken,
        },
      });
    } catch (err) {
      console.error(err);
    }

    return disclosure;
  }
  async registerMeta(inventionId) {
    if (ENV.environment === 'test') {
      return;
    }
    const state = this.redux.getState();
    const meta = getMeta(state);
    const user = this.sessionManager.userId;
    const releaseVersion = ENV.releaseVersion;

    // if disclosure isn't registered, register it
    let disclosureId = meta.disclosure;

    const disclosureExists = await this.getDisclosure(disclosureId);

    if (!disclosureExists) {
      disclosureId = await this.registerDisclosure();
    }

    // check if instance exists and create one if it doesn't
    await this.registerInstance(disclosureId, inventionId);

    // update meta
    this.redux.store.dispatch(
      updateMeta({
        releaseVersion,
        user,
        disclosure: disclosureId,
        instance: inventionId,
      })
    );

    // clear the undo history
    this.redux.store.dispatch(ActionCreators.clearHistory());
  }

  async registerDisclosure() {
    let disclosureId = null;
    try {
      const res = await axios({
        method: 'POST',
        url: ENV.IDENTITY_API_URL + '/disclosures',
        data: {},
        headers: {
          Authorization: this.sessionManager.accessToken,
        },
      });
      disclosureId = res.data.id;
    } catch (err) {
      console.error(err);
    }
    return disclosureId;
  }

  async registerInstance(disclosureId, id) {
    try {
      await axios({
        method: 'POST',
        url: ENV.IDENTITY_API_URL + '/instances',
        data: {
          id,
          disclosureId,
        },
        headers: {
          Authorization: this.sessionManager.accessToken,
        },
      });
    } catch (err) {
      console.error(err);
    }
  }

  updateElementCoords(elementId, x, y, productId) {
    let state = this.redux.getState();
    const element = getElement(state, elementId);
    if (productId && element.elementVersionsList.length === 1) {
      const elementVersionId = element.elementVersionsList[0];
      this.updateElementVersion(elementVersionId, { x, y });
    } else {
      this.updateElement(elementId, { x, y });
    }
  }

  updateElementsListSort(elementId, x, y, parentElementVersionId) {
    const state = this.redux.getState();
    const parentElementVersion = getElementVersion(
      state,
      parentElementVersionId
    );
    // get the siblings
    let siblingIds = parentElementVersion.elementsList;

    // if it was created on the graph and has siblings, insert at x position
    if (siblingIds.length) {
      siblingIds = siblingIds.filter((id) => id !== elementId);

      const siblingsXArray = siblingIds.map((siblingId) => {
        const element = getElement(state, siblingId);
        return element.x;
      });

      const sequence = findClosestSmallestIndex(x, siblingsXArray) + 1;

      const elementsList = insertAtIndex(siblingIds, sequence, elementId);

      const comprisesFeatures = parentElementVersion.comprisesList.map(
        (featureId) => getFeature(state, featureId)
      );

      const comprisesList = elementsList.map((elementId) => {
        const feature = comprisesFeatures.find(
          (feature) => feature.value.element === elementId
        );
        return feature.id;
      });

      this.updateElementVersion(parentElementVersionId, {
        elementsList,
        comprisesList,
      });
    }
  }

  parseElementVersionCategory(elementCategory, parentElementVersionId) {
    const state = this.redux.getState();

    let category;

    // get the default element version category
    switch (elementCategory) {
      case 'product':
      case 'system':
        category = 'process';
        break;
      case 'part':
        category = 'machine';
        break;
    }

    // if parent and parent isn't a product, inherit the solution
    // category (machine, process, etc.) from parent
    if (parentElementVersionId) {
      const parentElementVersion = getElementVersion(
        state,
        parentElementVersionId
      );
      const parentElement =
        parentElementVersion && getElement(state, parentElementVersion.element);

      if (parentElement && parentElement.category !== 'product') {
        category = parentElementVersion.category;
      }
    }

    return category;
  }

  parseElementCategory(parentElementVersionId) {
    const state = this.redux.getState();

    // get the default category
    let category = getDefaultElementCategory(state);

    // if parentElementVersion, inherit the parent element category (part, system, etc)
    if (parentElementVersionId) {
      const parentElementVersion = getElementVersion(
        state,
        parentElementVersionId
      );

      const parentElement =
        parentElementVersion &&
        parentElementVersion.element &&
        getElement(state, parentElementVersion.element);

      // if the parent is the product node, use the default
      if (parentElement && parentElement.category !== 'product') {
        category = parentElement.category;
      }
    }

    return category;
  }

  addElement(
    attributes,
    parentElementVersionId,
    elementId,
    childElementVersionAttributes = {},
    childElementVersionId
  ) {
    let state = this.redux.getState();
    let x = attributes.x;
    let y = attributes.y;
    let category = attributes.category;
    const hasNoCoords = isNaN(x) || isNaN(y);
    const hasCoords = !hasNoCoords;
    let name = attributes.name;

    // add the element
    elementId = elementId || uuid();

    // remove collapsed node if creating from a collapsed node
    if (parentElementVersionId) {
      this.removeCollapsedNodeIfCreatingFrom(parentElementVersionId);
    }

    // create default coordinates if none exists
    if (hasNoCoords) {
      const coords = parentElementVersionId
        ? getElementCoords(state, parentElementVersionId)
        : getParentlessElementCoords(state);
      x = coords.x;
      y = coords.y;
    }

    // get the element category if undefined
    if (!category) {
      category = this.parseElementCategory(parentElementVersionId);
    }

    // get the child element version category if it doesn't exist
    if (!childElementVersionAttributes['category']) {
      childElementVersionAttributes['category'] =
        this.parseElementVersionCategory(category, parentElementVersionId);
    }

    // create default name if none exists
    if (!name) {
      const namePreamble = category === 'part' ? 'Part' : 'System';
      const increment = getElementIncrement(state, namePreamble);
      name = `${namePreamble} ${increment}`;
    }

    const elementAttributes = {
      ...attributes,
      category,
      name,
      x,
      y,
    };

    this.redux.store.dispatch(addElement(elementAttributes, elementId));

    // add a blank outcome feature
    this.addOutcome(elementId);

    // add a blank requirement feature
    this.addFunctionalRequirement(elementId);

    // add child element version
    const elementVersionId = this.addElementVersion(
      { x, y, ...childElementVersionAttributes },
      elementId,
      childElementVersionId
    );

    // update products preferredElementVersionsLists
    const productsList = getProductsList(state);
    productsList.forEach((productId) =>
      this.setPreferredElementVersion(productId, elementId, elementVersionId)
    );

    // if parentElementVersionId add comprises relationship
    if (parentElementVersionId) {
      if (hasCoords) {
        const sequence = this.getElementSequenceFromX(
          parentElementVersionId,
          x
        );
        this.addComprisesRelationship(
          parentElementVersionId,
          elementId,
          sequence
        );
      } else {
        this.addComprisesRelationship(parentElementVersionId, elementId);
      }
    }

    // if not parentElementVersionId add to orphanNodesList
    if (!parentElementVersionId && category !== 'product') {
      this.addOrphanNode(elementId);
    }

    return elementId;
  }

  getElementSequenceFromX(sourceElementVersionId, targetX) {
    const state = this.redux.getState();
    let sequence;

    // get the element version
    const sourceElementVersion = getElementVersion(
      state,
      sourceElementVersionId
    );

    // get the siblings
    const siblingIds = sourceElementVersion.elementsList;

    // if it has siblings, insert at x position
    if (siblingIds.length) {
      const siblingsXArray = siblingIds.map((siblingId) => {
        const element = getElement(state, siblingId);
        return element.x;
      });

      sequence = findClosestSmallestIndex(targetX, siblingsXArray) + 1;
    }

    return sequence;
  }

  addOutcome(elementId, value = '') {
    // add element outcome feature
    const outcomeFeatureId = uuid();
    const outcomeFeatureAttributes = {
      element: elementId,
      type: 'element_step',
      value,
    };

    this.redux.store.dispatch(
      addFeature(outcomeFeatureAttributes, outcomeFeatureId)
    );

    this.updateElement(elementId, {
      outcome: outcomeFeatureId,
    });
  }

  addFunctionalRequirement(elementId, value = '') {
    const state = this.redux.getState();
    // add element functional requirement
    const requirementId = uuid();
    const requirementFeatureAttributes = {
      element: elementId,
      type: 'functional_requirement',
      value,
    };
    const element = getElement(state, elementId);
    const requirementsList = element.requirementsList || [];

    this.redux.store.dispatch(
      addFeature(requirementFeatureAttributes, requirementId)
    );

    this.updateElement(elementId, {
      requirementsList: [...requirementsList, requirementId],
    });
  }

  addElementVersion(attributes = {}, parentElementId, elementVersionId) {
    let x = attributes.x;
    let y = attributes.y;
    let name = attributes.name;

    const state = this.redux.getState();
    const parentElement = getElement(state, parentElementId);

    elementVersionId = elementVersionId || uuid();

    // create default name if none exists
    if (!name) {
      const increment = getElementVersionIncrement(state, parentElementId);
      name = `Solution ${increment}`;
    }

    // create default coordinates if none exists
    // if (!x && !y) {
    //   x = parentElement.x;
    //   y = parentElement.y;
    // }

    // create default coordinates if none exists
    if (isNaN(x) || isNaN(y)) {
      const coords = getElementVersionCoords(state, parentElementId);
      x = coords.x;
      y = coords.y;
    }

    const elementVersionAttributes = {
      ...attributes,
      element: parentElementId,
      x,
      y,
      name,
    };

    this.redux.store.dispatch(
      addElementVersion(elementVersionAttributes, elementVersionId)
    );

    this.updateElement(parentElementId, {
      elementVersionsList: [
        ...parentElement.elementVersionsList,
        elementVersionId,
      ],
    });

    // add method to child element version
    this.addMethod(parentElementId, elementVersionId);

    return elementVersionId;
  }

  addElementVersionMarker(elementVersionId, markerId) {
    const state = this.redux.getState();
    const elementVersion = getElementVersion(state, elementVersionId);
    const markersList = [...elementVersion.markersList, markerId];
    this.updateElementVersion(elementVersionId, { markersList });
  }

  removeElementVersionMarker(elementVersionId, markerId) {
    const state = this.redux.getState();
    const elementVersion = getElementVersion(state, elementVersionId);
    const markersList = elementVersion.markersList.filter(
      (id) => id !== markerId
    );
    this.updateElementVersion(elementVersionId, { markersList });
  }

  updateElement(elementId, attributes = {}) {
    this.redux.store.dispatch(updateElement(elementId, attributes));
    return elementId;
  }

  updateElementVersion(elementVersionId, attributes = {}) {
    this.redux.store.dispatch(
      updateElementVersion(elementVersionId, attributes)
    );
    return elementVersionId;
  }

  addComponent(
    elementId,
    attributes = {},
    componentId = uuid(),
    instanceId = uuid()
  ) {
    let state = this.redux.getState();

    const element = getElement(state, elementId);

    // get the current element category
    const category = element.category;

    // get current element coords
    const x = element.x;
    const y = element.y;

    // get current element parent
    const parentElementVersionId = element.elementVersion;

    // update the current element so it doesn't show in the graph
    this.updateElement(elementId, {
      isComponent: true,
      component: componentId,
      x: 0,
      y: 0,
    });

    // create the instance
    this.redux.store.dispatch(
      addElement(
        {
          x,
          y,
          category,
          component: componentId,
          instanceOf: elementId,
        },
        instanceId
      )
    );

    // add a blank outcome feature
    this.addOutcome(instanceId);

    // add a blank requirement feature
    this.addFunctionalRequirement(instanceId);

    // create the component
    this.redux.store.dispatch(
      addComponent(
        {
          ...attributes,
          element: elementId,
          primaryInstance: instanceId,
          instancesList: [instanceId],
        },
        componentId
      )
    );

    // if has parent
    if (parentElementVersionId) {
      // remove it from its parent elementsList
      this.removeComprisesRelationship(parentElementVersionId, elementId);

      // get index of element in parent elementsList
      const elementVersion = getElementVersion(state, parentElementVersionId);
      const sequence = elementVersion.elementsList.indexOf(elementId);

      // replace it with the new instance
      this.addComprisesRelationship(
        parentElementVersionId,
        instanceId,
        sequence
      );
    }

    // if not parentElementVersionId add to orphanNodesList
    if (!parentElementVersionId) {
      this.addOrphanNode(instanceId);
    }

    // select the new instance
    this.selectElement(instanceId);

    // update products preferredElementVersionsLists
    const productsList = getProductsList(state);
    productsList.forEach((productId) => {
      // get the existing preferredElementVersion
      const preferredElementVersionId = getPreferredElementVersionId(
        state,
        elementId,
        productId
      );

      // remove the existing preferredElementVersion for the elementId
      this.removePreferredElementVersion(productId, elementId);

      // add the instance preferredElementVersion
      this.setPreferredElementVersion(
        productId,
        instanceId,
        preferredElementVersionId
      );
    });

    return elementId;
  }

  detachMainComponentInstance(instanceId) {
    const state = this.redux.getState();
    const instance = getElement(state, instanceId);
    const component = getComponent(state, instance.component);
    const instanceOfId = component.element;
    const parentElementVersionId = instance.elementVersion;
    const x = instance.x;
    const y = instance.y;

    // if activeProduct get the preferredElementVersion
    // of the instance and set it to the element
    const productsList = getProductsList(state);
    productsList.forEach((productId) => {
      // get the existing preferredElementVersion
      const preferredElementVersionId = getPreferredElementVersionId(
        state,
        instanceId,
        productId
      );
      // add the instance preferredElementVersion
      this.setPreferredElementVersion(
        productId,
        instanceOfId,
        preferredElementVersionId
      );
    });

    // remove the instance
    this.removeTreeItems([instanceId], [], [], false);

    // update the original element
    this.updateElement(instanceOfId, {
      component: null,
      isComponent: false,
      instanceOf: null,
      x,
      y,
    });

    // if parentElementVersionId add comprises relationship
    if (parentElementVersionId) {
      this.addComprisesRelationship(parentElementVersionId, instanceOfId);
    }
  }

  // detachComponentInstance(instanceId) {
  //   const state = this.redux.getState();
  //   const instance = getElement(state, instanceId);
  //   const component = getComponent(state, instance.component);
  //   const instanceOfId = component.element;

  //   this.removeTreeItems([instanceId], [], []);
  // }

  updateComponent(componentId, attributes = {}) {
    this.redux.dispatch(updateComponent(componentId, attributes));
  }

  removeComponent(componentId) {
    this.redux.dispatch(removeComponent(componentId));
  }

  removeComponentInstance(instanceId, componentId) {
    const state = this.redux.getState();
    const component = getComponent(state, componentId);

    if (component.primaryInstance === instanceId) {
      const instancesList = component.instancesList.filter(
        (id) => id !== instanceId
      );
      // if more instances in list set the next one to the primary
      if (instancesList.length) {
        this.updateComponent(componentId, {
          primaryInstance: instancesList[0],
          instancesList,
        });
      } else {
        //  if not more instances in list ...
        // console.log('not more instances in list ...');
        // set component element isComponent to false
        // put it back in the graph as parentless
        const elementId = component.element;
        this.updateElement(elementId, {
          component: null,
          elementVersion: null,
          isComponent: false,
          instanceOf: null,
        });

        // delete component
        this.removeComponent(componentId);
      }
    } else {
      this.updateComponent(componentId, {
        instancesList: component.instancesList.filter(
          (id) => id !== instanceId
        ),
      });
    }
  }

  setAsPrimaryInstance(instanceId) {
    let state = this.redux.getState();

    const element = getElement(state, instanceId);
    const componentId = element.component;

    if (!componentId) {
      throw "the element your set as primary isn't an instance";
    }

    const component = getComponent(state, componentId);

    const isInstance = component.instancesList.includes(instanceId);

    if (!isInstance) {
      throw "the element your set as primary isn't an instance";
    }

    // update the existing component with the new primary instance
    this.updateComponent(componentId, {
      primaryInstance: instanceId,
    });
  }

  getElementProperties(elementId) {
    let state = this.redux.getState();
    const element = getElement(state, elementId);

    const name = element.name;

    const outcomeId = element.outcome;
    const outcomeFeature = getFeature(state, outcomeId);
    const outcomeValue = outcomeFeature.value;

    const requirementsList = element.requirementsList;
    const requirementValues = requirementsList.map((featureId) => {
      const feature = getFeature(state, featureId);
      return feature && feature.value;
    });

    return {
      name,
      outcomeValue,
      requirementValues,
    };
  }

  getElementVersionProperties(elementVersionId) {
    const state = this.redux.getState();
    const elementVersion = getElementVersion(state, elementVersionId);

    const name = elementVersion.name;
    const category = elementVersion.category;
    const known = elementVersion.known;

    const constraintsList = elementVersion.constraintsList;
    const constraints = constraintsList.map((featureId) => {
      const feature = getFeature(state, featureId);
      return (
        feature &&
        feature.value && {
          type: feature.type,
          value: feature.value,
        }
      );
    });

    const featuresList = elementVersion.featuresList;
    const features = featuresList.map((featureId) => {
      const feature = getFeature(state, featureId);
      return (
        feature &&
        feature.value && {
          type: feature.type,
          value: feature.value,
        }
      );
    });

    const definitionsList = elementVersion.definitionsList;
    const definitions = definitionsList.map((featureId) => {
      const feature = getFeature(state, featureId);
      return (
        feature &&
        feature.value && {
          type: feature.type,
          value: feature.value,
        }
      );
    });

    return {
      name,
      category,
      known,
      constraints,
      features,
      definitions,
    };
  }

  pasteSystemProperties(sourceElementId, targetElementId, type) {
    const state = this.redux.getState();

    // get the properties of the existing element
    const { outcomeValue, requirementValues } =
      this.getElementProperties(sourceElementId);

    const isAll = type === 'all';
    const isOutcome = isAll || type === 'required_event';
    const isRequirements = isAll || type === 'required_functions';

    const targetElement = getElement(state, targetElementId);

    // overwrite the outcome value
    if (isOutcome) {
      const outcomeId = targetElement.outcome;
      this.updateFeature(outcomeId, {
        value: outcomeValue,
      });
      this.models.update(outcomeId, { value: outcomeValue });
    }

    // add the requirements
    if (isRequirements) {
      requirementValues.forEach((value) => {
        this.addFunctionalRequirement(targetElementId, value);
      });
    }
  }

  pasteSolutionProperties(
    sourceElementVersionId,
    targetElementVersionId,
    type
  ) {
    // get the properties of the existing element
    const { constraints, features, definitions, category, known } =
      this.getElementVersionProperties(sourceElementVersionId);

    const isAll = type === 'all';
    const isContraints = isAll || type === 'constraints';
    const isFeatures = isAll || type === 'features';
    const isDefinitions = isAll || type === 'definitions';

    if (isAll) {
      this.updateElementVersion(targetElementVersionId, {
        category,
        known,
      });
    }

    // add the contraints
    if (isContraints) {
      constraints.forEach((constraint) => {
        this.addConstraint(
          targetElementVersionId,
          constraint.type,
          constraint.value
        );
      });
    }

    // add the features
    if (isFeatures) {
      features.forEach((feature) => {
        this.addFeature(targetElementVersionId, feature.type, feature.value);
      });
    }

    // add the definitions
    if (isDefinitions) {
      definitions.forEach((definition) => {
        this.addDefinition(
          targetElementVersionId,
          definition.type,
          definition.value
        );
      });
    }
  }

  pasteShallowElementDuplicate(parentElementVersionId, elementId, x, y) {
    let state = this.redux.getState();

    const element = getElement(state, elementId);
    const category = element && element.category;

    const isInstance = element.component && element.instanceOf ? true : false;

    // if the clipboard element is an instance,
    // update the elementId to be the instanceOf
    if (isInstance) {
      elementId = element.instanceOf;
    }

    if (!x && !y) {
      const coords = parentElementVersionId
        ? getElementCoords(state, parentElementVersionId)
        : getParentlessElementCoords(state);
      x = coords.x;
      y = coords.y;
    }

    // get the properties of the existing element
    const { name, outcomeValue, requirementValues } =
      this.getElementProperties(elementId);

    // duplicate element id
    const duplicateElementId = uuid();

    // duplicate name = OLD_NAME + copy i
    const increment = getElementIncrement(state, `${name} Copy`);
    const duplicateElementName = `${name} Copy ${increment}`;

    // create the a blank element
    this.addElement(
      { name: duplicateElementName, category, x, y },
      parentElementVersionId,
      duplicateElementId,
      {}
    );

    // add the existing elements properties to the duplicate element

    // update state and get the created element
    state = this.redux.getState();
    const duplicateElement = getElement(state, duplicateElementId);

    // update the outcome feature with the outcome value
    const outcomeId = duplicateElement.outcome;

    this.updateFeature(outcomeId, {
      value: outcomeValue,
    });

    // update the first requirement with the requirement value
    if (requirementValues[0]) {
      const requirementId = duplicateElement.requirementsList[0];
      this.updateFeature(requirementId, {
        value: requirementValues[0],
      });
    }

    // create any additional requirement features
    if (requirementValues.length > 1) {
      requirementValues.forEach((value, index) => {
        // skip the first b/c we already updated the existing one aboce
        if (index) {
          this.addFunctionalRequirement(duplicateElementId, value);
        }
      });
    }

    //

    // remove collapsed node if creating from a collapsed node
    if (parentElementVersionId) {
      this.removeCollapsedNodeIfCreatingFrom(parentElementVersionId);
    }

    return duplicateElementId;
  }

  pasteShallowElementVersionDuplicate(
    parentElementId,
    sourceElementVersionId,
    x,
    y
  ) {
    let state = this.redux.getState();

    const parentElement = getElement(state, parentElementId);

    if (parentElement && parentElement.component && parentElement.instanceOf) {
      parentElementId = parentElement.instanceOf;
    }

    if (!x && !y) {
      const coords = parentElementId
        ? getElementVersionCoords(state, parentElementId)
        : getParentlessElementCoords(state);
      x = coords.x;
      y = coords.y;
    }

    const sourceElementVersion = getElementVersion(
      state,
      sourceElementVersionId
    );

    // duplicate name = OLD_NAME + copy i
    const name = `${sourceElementVersion.name} Copy`;

    // add element version
    const targetElementVersionId = this.addElementVersion(
      { x, y, name },
      parentElementId
    );

    // add the existing elements properties to the duplicate element
    this.pasteSolutionProperties(
      sourceElementVersionId,
      targetElementVersionId,
      'all'
    );

    // remove collapsed node if creating from a collapsed node
    if (parentElementId) {
      this.removeCollapsedNodeIfCreatingFrom(parentElementId);
    }

    return targetElementVersionId;
  }

  removeCollapsedNodeIfCreatingFrom(parentElementVersionId) {
    const state = this.redux.getState();
    const activeProductId = getActiveProductId(state);
    const collapsedNodesList = getCollapsedNodesList(state, activeProductId);
    const parentElementVersion = getElementVersion(
      state,
      parentElementVersionId
    );
    const parentElementId =
      parentElementVersion && parentElementVersion.element;

    if (collapsedNodesList.includes(parentElementVersionId)) {
      this.removeCollapsedNode(parentElementVersionId, activeProductId);
    }

    if (parentElementId && collapsedNodesList.includes(parentElementId)) {
      this.removeCollapsedNode(parentElementId, activeProductId);
    }
  }

  pasteInstance(parentElementVersionId, sourceInstanceId, x, y) {
    let state = this.redux.getState();

    const instanceId = uuid();

    if (!x && !y) {
      const coords = parentElementVersionId
        ? getElementCoords(state, parentElementVersionId)
        : getParentlessElementCoords(state);
      x = coords.x;
      y = coords.y;
    }

    const sourceInstance = getElement(state, sourceInstanceId);

    //find component
    const componentId = sourceInstance.component;

    if (!componentId) {
      throw "the element your attempting to paste isn't an instance";
    }

    const component = getComponent(state, componentId);

    // update the existing component with the new instance
    this.updateComponent(componentId, {
      instancesList: [...component.instancesList, instanceId],
    });

    // remove collapsed node if creating from a collapsed node
    if (parentElementVersionId) {
      this.removeCollapsedNodeIfCreatingFrom(parentElementVersionId);
    }

    // if not parentElementVersionId add to orphanNodesList
    if (!parentElementVersionId) {
      this.addOrphanNode(instanceId);
    }

    // add the element
    const instanceAttributes = {
      component: componentId,
      instanceOf: component.element,
      x,
      y,
    };

    this.redux.store.dispatch(addElement(instanceAttributes, instanceId));

    // add a blank outcome feature
    this.addOutcome(instanceId);

    // add a blank requirement feature
    this.addFunctionalRequirement(instanceId);

    // update products preferredElementVersionsLists
    const productsList = getProductsList(state);
    productsList.forEach((productId) => {
      // get the existing preferredElementVersion
      const preferredElementVersionId = getPreferredElementVersionId(
        state,
        sourceInstanceId,
        productId
      );
      // add the instance preferredElementVersion
      this.setPreferredElementVersion(
        productId,
        instanceId,
        preferredElementVersionId
      );
    });

    // if parentElementVersionId add comprises relationship
    if (parentElementVersionId) {
      this.addComprisesRelationship(parentElementVersionId, instanceId);
    }

    return instanceId;
  }

  removeElement(elementId) {
    const state = this.redux.getState();
    const element = getElement(state, elementId);
    if (element.instanceOf && element.component) {
      this.removeComponentInstance(elementId, element.component);
    }
    if (!element.elementVersion) {
      this.removeOrphanNode(elementId);
    }

    this.removeElementFromPreferredElementVersionLists(elementId);
    this.redux.store.dispatch(removeElement(elementId, element.name));
    this.redux.store.dispatch(
      addDeletedReference(elementId, { name: element.name, type: 'element' })
    );

    element.requirementsList.forEach((requirementId) => {
      this.redux.store.dispatch(removeFeature(requirementId));
    });

    this.redux.store.dispatch(removeFeature(element.outcome));
  }

  removeElementVersion(elementVersionId) {
    const state = this.redux.getState();
    const elementVersion = getElementVersion(state, elementVersionId);

    elementVersion.markersList.forEach((markerId) =>
      this.removeMarkerReferences(markerId)
    );

    this.redux.store.dispatch(removeElementVersion(elementVersionId));

    // remove method and all its nodes, etc.
    const methodId = elementVersion.method;
    if (methodId) this.removeMethod(methodId);

    // remove all version features
    this.redux.store.dispatch(removeFeatures(elementVersion.featuresList));
  }

  addMethod(elementId, elementVersionId, methodId = uuid()) {
    const methodNodeId = uuid();

    const methodNodeAttributes = {
      method: methodId,
      type: 'start',
      label: 'START',
      x: -60,
      y: -510,
      width: 100,
      height: 50,
    };

    this.redux.store.dispatch(
      addMethod(
        {
          element: elementId,
          elementVersion: elementVersionId,
          methodNodesList: [methodNodeId],
        },
        methodId
      )
    );

    this.redux.store.dispatch(
      addMethodNode(methodNodeAttributes, methodNodeId)
    );

    this.updateElementVersion(elementVersionId, {
      method: methodId,
    });

    this.createMethodModel(methodId);
  }

  createMethodModel(methodId) {
    const state = this.redux.getState();
    const method = getMethod(state, methodId);
    this.store.createRecord('method', {
      id: methodId,
      updatedAt: method.updatedAt,
    });
  }

  removeMethodNode(methodNodeId) {
    const state = this.redux.getState();
    const methodNode = getMethodNode(state, methodNodeId);
    if (methodNode && methodNode.type === 'custom') {
      // remove the feature
      this.redux.store.dispatch(removeFeature(methodNode.feature));
    }
    this.redux.store.dispatch(removeMethodNode(methodNodeId));
  }

  removeMethod(methodId) {
    const state = this.redux.getState();
    const method = getMethod(state, methodId);
    const { methodNodesList, methodEdgesList } = method;
    let methodEdgePointsList = [];

    methodNodesList.forEach((methodNodeId) => {
      this.removeMethodNode(methodNodeId);
    });

    methodEdgesList.forEach((methodEdgeId) => {
      const methodEdge = getMethodEdge(state, methodEdgeId);
      methodEdgePointsList = [
        ...methodEdgePointsList,
        ...methodEdge.methodEdgePointsList,
      ];
      this.redux.store.dispatch(removeMethodEdge(methodEdgeId));
    });

    methodEdgePointsList.forEach((methodEdgePointId) => {
      this.redux.store.dispatch(removeMethodEdgePoint(methodEdgePointId));
    });

    this.redux.store.dispatch(removeMethod(methodId));
  }

  getConnectedMethodEdges(methodId, nodeIds) {
    const state = this.redux.getState();
    const method = getMethod(state, methodId);
    return method.methodEdgesList
      .filter((methodEdgeId) => {
        const methodEdge = getMethodEdge(state, methodEdgeId);
        return (
          nodeIds.includes(methodEdge.source) ||
          nodeIds.includes(methodEdge.target)
        );
      })
      .uniq();
  }

  updateMethod(methodId, attributes = {}) {
    this.redux.dispatch(updateMethod(methodId, attributes));
    this.updateMethodModel(methodId);
    this.updateMethodElementsList(methodId);
  }

  updateMethodModel(methodId) {
    const state = this.redux.getState();
    const method = getMethod(state, methodId);
    const methodModel = this.store.peekRecord('method', methodId);
    if (methodModel) methodModel.updatedAt = method.updatedAt;
  }

  updateMethodElementsList(methodId) {
    const state = this.redux.getState();
    const method = getMethod(state, methodId);
    const methodModel = this.store.peekRecord('method', methodId);
    if (method && methodModel) {
      const elementVersionId = method.elementVersion;
      const elementVersion = getElementVersion(state, elementVersionId);
      const elementsList = elementVersion.elementsList;
      const traversedNodesList = methodModel.traversedNodesList;

      if (!traversedNodesList.length || !elementsList.length) {
        return;
      }

      const traversedElementsList = traversedNodesList
        .filter((methodNodeId) => {
          const methodNode = getMethodNode(state, methodNodeId);
          return methodNode && methodNode.element;
        })
        .map((methodNodeId) => {
          const methodNode = getMethodNode(state, methodNodeId);
          return methodNode.element;
        });

      const disconnectedElementsList = elementsList.filter(
        (elementId) => !traversedElementsList.includes(elementId)
      );

      const updatedElementsList = [
        ...traversedElementsList,
        ...disconnectedElementsList,
      ];

      const listsAreDifferent =
        elementsList.toString() !== updatedElementsList.toString();

      if (listsAreDifferent) {
        this.updateElementVersion(elementVersionId, {
          elementsList: updatedElementsList,
        });
        this.updateGraph();
      }
    }
  }

  updateMethodItems(methodId, methodNodesUpdates = []) {
    methodNodesUpdates.forEach((update) => {
      const { id, attributes } = update;
      this.updateMethodNode(id, attributes);
    });

    this.updateMethod(methodId, {});
  }

  getMethodEdgeFromBendPointId(state, bendPointId, methodEdgesList) {
    return methodEdgesList.find((methodEdgeId) => {
      const methodEdge = getMethodEdge(state, methodEdgeId);
      return methodEdge && methodEdge.bendPoints[bendPointId] ? true : false;
    });
  }

  removeMethodItems(
    methodId,
    methodNodesList = [],
    methodEdgesList = [],
    methodEdgePointsList = []
  ) {
    const state = this.redux.getState();
    const method = getMethod(state, methodId);

    const connectedEdges = this.getConnectedMethodEdges(
      methodId,
      methodNodesList
    );

    methodEdgesList = methodEdgesList.concat(connectedEdges).uniq();

    methodEdgePointsList.forEach((methodEdgePointId) => {
      const methodEdgeId = method.methodEdgesList.find((methodEdgeId) => {
        const methodEdge = getMethodEdge(state, methodEdgeId);
        return methodEdge && methodEdge.bendPoints[methodEdgePointId]
          ? true
          : false;
      });
      if (methodEdgeId) {
        const methodEdge = getMethodEdge(state, methodEdgeId);
        const bendPoints = omit(methodEdge.bendPoints, methodEdgePointId);
        this.updateMethodEdge(methodEdgeId, {
          bendPoints,
        });
      }
    });

    methodNodesList.forEach((methodNodeId) => {
      this.removeMethodNode(methodNodeId);
    });

    methodEdgesList.forEach((methodEdgeId) => {
      this.redux.store.dispatch(removeMethodEdge(methodEdgeId));
    });

    methodEdgePointsList.forEach((methodEdgePointId) => {
      this.redux.store.dispatch(removeMethodEdgePoint(methodEdgePointId));
    });

    this.updateMethod(methodId, {
      methodNodesList: method.methodNodesList.filter(
        (id) => !methodNodesList.includes(id)
      ),
      methodEdgesList: method.methodEdgesList.filter(
        (id) => !methodEdgesList.includes(id)
      ),
    });
  }

  addMethodNode(attributes, methodId, methodNodeId = uuid()) {
    const state = this.redux.getState();
    const method = getMethod(state, methodId);
    const orientation = method.orientation;

    let { type, x, y, width, height } = attributes;

    if (isNaN(width) || isNaN(height)) {
      width = 280;
      height = 70;
    }

    if (isNaN(x) || isNaN(y)) {
      const coords = getRandomMethodNodeCoords(orientation);
      x = coords.x;
      y = coords.y;
    }

    if (type === 'custom') {
      const featureId = uuid();
      const featureAttributes = {
        type: 'custom_step',
        value: '',
      };

      this.redux.store.dispatch(addFeature(featureAttributes, featureId));

      attributes.feature = featureId;
    }

    attributes = {
      ...attributes,
      method: methodId,
      x,
      y,
      width,
      height,
    };

    this.redux.store.dispatch(addMethodNode(attributes, methodNodeId));

    const methodNodesList = [...method.methodNodesList, methodNodeId];

    this.updateMethod(methodId, { methodNodesList });
  }

  addMethodEdge(attributes, methodId, methodEdgeId = uuid()) {
    const state = this.redux.getState();

    this.redux.store.dispatch(addMethodEdge(attributes, methodEdgeId));

    const method = getMethod(state, methodId);

    const methodEdgesList = [...method.methodEdgesList, methodEdgeId];

    this.updateMethod(methodId, { methodEdgesList });
  }

  dropNode(draggedId, draggedType, droppedId, droppedType, droppedArea) {
    const state = this.redux.getState();
    // determine if same parent

    // if moving elements
    if (draggedType === 'element' && droppedType === 'element') {
      // get elements
      const draggedElement = getElement(state, draggedId);
      const droppedElement = getElement(state, droppedId);

      // if sorting
      if (droppedArea === 'top' || droppedArea === 'bottom') {
        // if parent elementVersions are different, move them
        if (
          draggedElement.elementVersion &&
          droppedElement.elementVersion &&
          draggedElement.elementVersion !== droppedElement.elementVersion
        ) {
          // remove the dragged element's relations
          this.removeComprisesRelationship(
            draggedElement.elementVersion,
            draggedElement.id
          );
          // add it to the dropped element's parent
          this.addComprisesRelationship(
            droppedElement.elementVersion,
            draggedElement.id
          );
        }

        // if moving to root
        if (draggedElement.elementVersion && !droppedElement.elementVersion) {
          // remove the dragged element's relations
          this.removeComprisesRelationship(
            draggedElement.elementVersion,
            draggedElement.id
          );

          console.log('moving to root');
        }

        // if moving from root
        if (!draggedElement.elementVersion && droppedElement.elementVersion) {
          // add it to the dropped element's parent
          this.addComprisesRelationship(
            droppedElement.elementVersion,
            draggedElement.id
          );
        }

        // if sorting root
        if (!droppedElement.elementVersion) {
          // get the orphan nodes list
          const orphanNodesList = getOrphanNodesList(state);

          // filter out the draggedId
          const filteredOrphanNodesList = orphanNodesList.filter(
            (id) => id !== draggedId
          );

          // insert the draggedId before or after the droppedId
          const direction = droppedArea === 'top' ? 'before' : 'after';
          const updatedOrphanNodesList = insertBeforeOrAfter(
            filteredOrphanNodesList,
            draggedId,
            droppedId,
            direction
          );

          this.updateOrphanNodesList(updatedOrphanNodesList);
        }

        // get the parent elementVersion
        const parentElementVersionId = droppedElement.elementVersion;

        // if parent elementVersion, arrange the dragged node into it
        if (parentElementVersionId) {
          const parentElementVersion = getElementVersion(
            state,
            parentElementVersionId
          );

          // filter out the draggedId
          const filteredElementsList = parentElementVersion.elementsList.filter(
            (id) => id !== draggedId
          );

          // insert the draggedId before or after the droppedId
          const direction = droppedArea === 'top' ? 'before' : 'after';
          const elementsList = insertBeforeOrAfter(
            filteredElementsList,
            draggedId,
            droppedId,
            direction
          );

          // update elementVersion
          this.updateElementVersion(parentElementVersionId, { elementsList });
        }
      } else if (droppedArea === 'middle') {
        console.log('dropped into!');
        const productId = getActiveProductId(state);
        const parentElementVersionId = getPreferredElementVersionId(
          state,
          droppedId,
          productId
        );

        if (draggedElement.elementVersion) {
          // remove the dragged element's relations
          this.removeComprisesRelationship(
            draggedElement.elementVersion,
            draggedElement.id
          );
        }

        if (parentElementVersionId) {
          // add it to the dropped element's preferred elementVersion
          this.addComprisesRelationship(
            parentElementVersionId,
            draggedElement.id
          );

          // expand the dropped node if collapsed
          const collapsedNodesList = getCollapsedNodesList(state, productId);
          const isCollapsed = collapsedNodesList.includes(droppedId);
          if (isCollapsed) {
            this.removeCollapsedNode(droppedId, productId);
          }
        }
      }
    }
  }

  addComprisesRelationship(sourceElementVersionId, targetElementId, sequence) {
    const state = this.redux.getState();
    const comprisesFeatureId = uuid();
    const methodNodeId = uuid();
    const sourceElementVersion = getElementVersion(
      state,
      sourceElementVersionId
    );
    const methodId = sourceElementVersion.method;
    const targetElement = getElement(state, targetElementId);
    const outcomeFeatureId = targetElement.outcome;
    const sourceElementVersionElementId = sourceElementVersion.element;

    const comprisesFeatureAttributes = {
      type: 'comprises',
      value: {
        amount: 'one',
        element: targetElementId,
      },
      element: sourceElementVersionElementId,
      elementVersion: sourceElementVersionId,
    };

    this.redux.store.dispatch(
      addFeature(comprisesFeatureAttributes, comprisesFeatureId)
    );

    const methodNodeAttributes = {
      type: 'element',
      element: targetElementId,
      feature: outcomeFeatureId,
    };

    let elementsList = [...sourceElementVersion.elementsList, targetElementId];

    if (typeof sequence === 'number') {
      const _elementsList = [...sourceElementVersion.elementsList];
      elementsList = insertAtIndex(_elementsList, sequence, targetElementId);
    }

    this.updateElementVersion(sourceElementVersionId, {
      elementsList,
      comprisesList: [
        ...sourceElementVersion.comprisesList,
        comprisesFeatureId,
      ],
    });

    this.updateElement(targetElementId, {
      elementVersion: sourceElementVersionId,
    });

    // remove element from orphanNodesList
    this.removeOrphanNode(targetElementId);

    // create a method node
    this.addMethodNode(methodNodeAttributes, methodId, methodNodeId);

    return {
      methodNodeId,
      comprisesFeatureId,
    };
  }

  switchComprisesRelationship(sourceElementVersionId, targetElementId) {
    const state = this.redux.getState();
    const elementVersionsMap = getElementVersionsMap(state);
    const targetElement = getElement(state, targetElementId);
    const targetElementNode = elementVersionsMap[targetElementId];

    // for immediate child parent switcheroos
    // if (targetElement.elementVersionsList.includes(sourceElementVersionId)) {
    //   this.removeVersionRelationship(targetElementId, sourceElementVersionId);
    // }

    // for descendent switcharoos, remove relavent comprises
    if (
      targetElementNode &&
      targetElementNode.descendants.includes(sourceElementVersionId)
    ) {
      targetElement.elementVersionsList.forEach((elementVersionId) => {
        const elementVersion = getElementVersion(state, elementVersionId);
        elementVersion.elementsList.forEach((elementId) => {
          const elementNode = elementVersionsMap[elementId];
          if (elementNode.descendants.includes(sourceElementVersionId)) {
            this.removeComprisesRelationship(elementVersionId, elementId);
          }
        });
      });
    }

    // remove the old comprises relationship
    const oldSourceElementVersionId = targetElement.elementVersion;
    this.removeComprisesRelationship(
      oldSourceElementVersionId,
      targetElementId
    );

    // add the new comprises relationship
    this.addComprisesRelationship(sourceElementVersionId, targetElementId);
  }

  removeComprisesRelationship(sourceElementVersionId, targetElementId) {
    const state = this.redux.getState();
    const targetElement = getElement(state, targetElementId);

    const sourceElementVersion = getElementVersion(
      state,
      sourceElementVersionId
    );

    // remove element method nodes from parent element version's method
    const methodId = sourceElementVersion.method;
    const method = getMethod(state, methodId);
    const methodNodesList = method.methodNodesList.filter((methodNodeId) => {
      const methodNode = getMethodNode(state, methodNodeId);
      return methodNode.element === targetElementId;
    });

    this.removeMethodItems(methodId, methodNodesList, [], [], false);

    const comprisesFeature = sourceElementVersion.comprisesList
      .map((featureId) => {
        return getFeature(state, featureId);
      })
      .find((feature) => {
        return (
          feature.value.element === targetElementId &&
          feature.type === 'comprises'
        );
      });

    const comprisesFeatureId = comprisesFeature.id;

    // const outcomeFeatureId = targetElement.outcome;

    // remove children from element version
    this.updateElementVersion(sourceElementVersionId, {
      elementsList: sourceElementVersion.elementsList.filter((elementId) => {
        return elementId !== targetElementId;
      }),
      comprisesList: sourceElementVersion.comprisesList.filter((featureId) => {
        return featureId !== comprisesFeatureId;
      }),
    });

    // remove element version from element
    this.updateElement(targetElementId, {
      elementVersion: null,
    });

    // add element to orphanNodesList if its not a component
    if (targetElement && !targetElement.isComponent)
      this.addOrphanNode(targetElementId);

    // remove comprises feature
    this.redux.dispatch(removeFeature(comprisesFeatureId));
  }

  addOrphanNode(nodeId) {
    const state = this.redux.getState();
    let orphanNodesList = getOrphanNodesList(state);
    orphanNodesList = [...orphanNodesList, nodeId].uniq();
    this.updateOrphanNodesList(orphanNodesList);
  }

  removeOrphanNode(nodeId) {
    const state = this.redux.getState();
    let orphanNodesList = getOrphanNodesList(state);
    orphanNodesList = orphanNodesList.filter((id) => id !== nodeId);
    this.updateOrphanNodesList(orphanNodesList);
  }

  updateOrphanNodesList(orphanNodesList) {
    this.redux.dispatch(updateOrphanNodesList(orphanNodesList));
  }

  addFeature(elementVersionId, type, value, featureId = uuid()) {
    const state = this.redux.getState();
    const elementVersion = getElementVersion(state, elementVersionId);
    const featuresList = [...elementVersion.featuresList, featureId];
    const featureAttributes = {
      element: elementVersion.element,
      elementVersion: elementVersionId,
      type,
      value,
    };

    // add the feature
    this.redux.store.dispatch(addFeature(featureAttributes, featureId));

    // add it to the element version
    this.updateElementVersion(elementVersionId, { featuresList });

    return featureId;
  }

  removeFeature(elementVersionId, featureId) {
    const state = this.redux.getState();
    const elementVersion = getElementVersion(state, elementVersionId);
    const featuresList = elementVersion.featuresList.filter(
      (id) => id !== featureId
    );

    // remove the feature
    this.redux.store.dispatch(removeFeature(featureId));

    // remove it from the element version
    this.updateElementVersion(elementVersionId, { featuresList });

    return featureId;
  }

  addConstraint(elementVersionId, type, value, featureId = uuid()) {
    const state = this.redux.getState();
    const elementVersion = getElementVersion(state, elementVersionId);
    const constraintsList = [...elementVersion.constraintsList, featureId];
    const featureAttributes = {
      element: elementVersion.element,
      elementVersion: elementVersionId,
      type,
      value,
    };

    // add the feature
    this.redux.store.dispatch(addFeature(featureAttributes, featureId));

    // add it to the element version
    this.updateElementVersion(elementVersionId, { constraintsList });

    return featureId;
  }

  removeConstraint(elementVersionId, featureId) {
    const state = this.redux.getState();
    const elementVersion = getElementVersion(state, elementVersionId);
    const constraintsList = elementVersion.constraintsList.filter(
      (id) => id !== featureId
    );

    // remove the feature
    this.redux.store.dispatch(removeFeature(featureId));

    // remove it from the element version
    this.updateElementVersion(elementVersionId, { constraintsList });
  }

  addDefinition(elementVersionId, type, value, featureId = uuid()) {
    const state = this.redux.getState();
    const elementVersion = getElementVersion(state, elementVersionId);
    const definitionsList = [...elementVersion.definitionsList, featureId];
    const featureAttributes = {
      element: elementVersion.element,
      elementVersion: elementVersionId,
      type,
      value,
    };

    // add the feature
    this.redux.store.dispatch(addFeature(featureAttributes, featureId));

    // add it to the element version
    this.updateElementVersion(elementVersionId, { definitionsList });

    return featureId;
  }

  removeDefinition(elementVersionId, featureId) {
    const state = this.redux.getState();
    const elementVersion = getElementVersion(state, elementVersionId);
    const definitionsList = elementVersion.definitionsList.filter(
      (id) => id !== featureId
    );

    // remove the feature
    this.redux.store.dispatch(removeFeature(featureId));

    // remove it from the element version
    this.updateElementVersion(elementVersionId, { definitionsList });
  }

  addTermDefinition(termId, type, value, featureId = uuid()) {
    const state = this.redux.getState();
    const term = getTerm(state, termId);
    const definitionsList = [...term.definitionsList, featureId];
    const featureAttributes = {
      term: termId,
      type,
      value,
    };

    // add the feature
    this.redux.store.dispatch(addFeature(featureAttributes, featureId));

    // add it to the element version
    this.updateTerm(termId, { definitionsList });

    return featureId;
  }

  removeTermDefinition(termId, featureId) {
    const state = this.redux.getState();
    const term = getTerm(state, termId);
    const definitionsList = term.definitionsList.filter(
      (id) => id !== featureId
    );

    // remove the feature
    this.redux.store.dispatch(removeFeature(featureId));

    // remove it from the element version
    this.updateTerm(termId, { definitionsList });
  }

  addRequirement(elementId, type, value, featureId = uuid()) {
    const state = this.redux.getState();
    const element = getElement(state, elementId);
    const requirementsList = [...element.requirementsList, featureId];
    const featureAttributes = {
      element: elementId,
      type,
      value,
    };

    // add the feature
    this.redux.store.dispatch(addFeature(featureAttributes, featureId));

    // add it to the element
    this.updateElement(elementId, { requirementsList });

    return featureId;
  }

  removeRequirement(elementId, featureId) {
    const state = this.redux.getState();
    const element = getElement(state, elementId);
    const requirementsList = element.requirementsList.filter(
      (id) => id !== featureId
    );

    // remove the feature
    this.redux.store.dispatch(removeFeature(featureId));

    // remove it from the element
    this.updateElement(elementId, { requirementsList });
  }

  setActiveFeature(featureId) {
    // make it active
    this.redux.store.dispatch(setActiveFeature(featureId));
  }

  switchVersionRelationship(sourceElementId, targetElementVersionId) {
    const state = this.redux.getState();
    const elementVersionsMap = getElementVersionsMap(state);
    const targetElementVersion = getElementVersion(
      state,
      targetElementVersionId
    );
    const targetElementVersionNode = elementVersionsMap[targetElementVersionId];

    // for immediate child parent switcheroos
    if (targetElementVersion.elementsList.includes(sourceElementId)) {
      this.removeComprisesRelationship(targetElementVersionId, sourceElementId);
    } else if (
      targetElementVersionNode &&
      targetElementVersionNode.descendants.includes(sourceElementId)
    ) {
      // for descendent switcharoos, remove relavent version relationships
      targetElementVersion.elementsList.forEach((elementId) => {
        const element = getElement(state, elementId);
        element.elementVersionsList.forEach((elementVersionId) => {
          const elementVersionNode = elementVersionsMap[elementVersionId];
          if (elementVersionNode.descendants.includes(sourceElementId)) {
            this.removeVersionRelationship(elementId, elementVersionId);
          }
        });
      });
    }
    // remove the old version relationship
    const oldSourceElementId = targetElementVersion.element;
    this.removeVersionRelationship(oldSourceElementId, targetElementVersionId);

    // add the new version relationship
    this.addVersionRelationship(sourceElementId, targetElementVersionId);
  }

  addVersionRelationship(sourceElementId, targetElementVersionId) {
    const state = this.redux.getState();
    const targetElementVersion = getElementVersion(
      state,
      targetElementVersionId
    );

    const oldElementVersionsList = getElement(
      state,
      sourceElementId
    ).elementVersionsList;

    const elementVersionsList = [
      ...oldElementVersionsList,
      targetElementVersionId,
    ];

    const markers = filter(
      getMarkers(state),
      (marker) => marker.elementVersion === targetElementVersionId
    );

    forEach(markers, (marker) => {
      this.updateMarker(marker.id, {
        element: sourceElementId,
      });
      this.updateDrawing(marker.drawing, {});
    });

    const features = filter(
      getFeatures(state),
      (feature) => feature.elementVersion === targetElementVersionId
    );

    forEach(features, (feature) => {
      this.updateFeature(feature.id, {
        element: sourceElementId,
      });
    });

    const drawings = filter(
      getDrawings(state),
      (drawing) => drawing.elementVersion === targetElementVersionId
    );

    forEach(drawings, (drawing) => {
      this.updateDrawing(drawing.id, {
        element: sourceElementId,
      });
    });

    if (targetElementVersion.method) {
      this.updateMethod(targetElementVersion.method, {
        element: sourceElementId,
      });
    }

    this.updateElement(sourceElementId, {
      elementVersionsList,
    });

    this.updateElementVersion(targetElementVersionId, {
      element: sourceElementId,
    });
  }

  removeTreeItems(
    selectedElements = [],
    selectedElementVersions = [],
    selectedEdges = [],
    removeDescendants = true
  ) {
    let state = this.redux.getState();
    const connectedEdges = [];
    const rootElementId = getRootNodeId(state);
    const productId = getActiveProductId(state);

    if (removeDescendants) {
      let elementDescendants = [];
      let elementVersionDescendants = [];

      const selectedNodes = [...selectedElements, ...selectedElementVersions];
      const elementVersionsMap = getElementVersionsMap(state);

      selectedNodes.forEach((nodeId) => {
        const node = elementVersionsMap[nodeId];
        if (node) {
          node.instanceDescendants.forEach((descendantId) => {
            const descendant = elementVersionsMap[descendantId];
            if (descendant && descendant.type === 'element') {
              elementDescendants.push(descendantId);
            }
            if (descendant && descendant.type === 'element-version') {
              elementVersionDescendants.push(descendantId);
            }
          });
        }
      });

      selectedElements = [...selectedElements, ...elementDescendants].uniq();

      selectedElementVersions = [
        ...selectedElementVersions,
        ...elementVersionDescendants,
      ].uniq();
    }

    selectedElements.forEach((_elementId) => {
      const element = getElement(state, _elementId);

      if (element.elementVersion) {
        connectedEdges.push({
          id: `${element.elementVersion}-${_elementId}`,
          type: 'comprises',
          source: element.elementVersion,
          target: _elementId,
        });
      }

      element.elementVersionsList.forEach((elementVersionId) => {
        if (
          element.elementVersionsList.length === 1 &&
          element.id !== rootElementId
        ) {
          selectedElementVersions = [
            ...selectedElementVersions,
            element.elementVersionsList[0],
          ].uniq();
        }
        if (_elementId !== rootElementId) {
          connectedEdges.push({
            id: `${_elementId}-${elementVersionId}`,
            type: 'version',
            source: _elementId,
            target: elementVersionId,
          });
        }
      });
    });

    selectedElementVersions.forEach((elementVersionId) => {
      const elementVersion = getElementVersion(state, elementVersionId);

      if (elementVersion.element) {
        connectedEdges.push({
          id: `${elementVersion.element}-${elementVersionId}`,
          type: 'version',
          source: elementVersion.element,
          target: elementVersionId,
        });
      }

      elementVersion.elementsList.forEach((_elementId) => {
        connectedEdges.push({
          id: `${elementVersionId}-${_elementId}`,
          type: 'comprises',
          source: elementVersionId,
          target: _elementId,
        });
      });
    });

    const edges = connectedEdges.concat(selectedEdges);
    const uniqueEdges = edges.uniqBy('id');

    uniqueEdges.forEach((edge) => {
      switch (edge.type) {
        case 'comprises': {
          const sourceId = edge.source;
          const targetElementId = edge.target;
          const sourceType = edge.sourceType;

          let sourceElementVersionId;

          if (sourceType === 'element' && productId) {
            const preferredElementVersionId = getPreferredElementVersionId(
              state,
              sourceId,
              productId
            );

            sourceElementVersionId = preferredElementVersionId;
          } else {
            sourceElementVersionId = sourceId;
          }

          this.removeComprisesRelationship(
            sourceElementVersionId,
            targetElementId
          );
          break;
        }
        case 'version': {
          const sourceElementId = edge.source;
          const targetElementVersionId = edge.target;
          this.removeVersionRelationship(
            sourceElementId,
            targetElementVersionId
          );
          break;
        }
      }
      // updatedInvention = removeEdge(updatedInvention, edge);
    });
    selectedElements = selectedElements.filter((id) => id !== rootElementId);

    selectedElements.forEach((_elementId) => {
      this.removeElement(_elementId);
    });

    selectedElementVersions.forEach((elementVersionId) => {
      this.removeElementVersion(elementVersionId);
    });
  }

  removeVersionRelationship(sourceElementId, targetElementVersionId) {
    const state = this.redux.getState();
    const targetElementVersion = getElementVersion(
      state,
      targetElementVersionId
    );
    const elementId = sourceElementId;
    const elementVersionId = targetElementVersionId;
    const elementVersionsList = getElement(
      state,
      elementId
    ).elementVersionsList.filter((id) => id !== elementVersionId);

    const markers = filter(
      getMarkers(state),
      (marker) => marker.elementVersion === targetElementVersionId
    );

    forEach(markers, (marker) => {
      this.updateMarker(marker.id, {
        element: null,
        elementVersion: null,
      });
      this.updateDrawing(marker.drawing, {});
    });

    const features = filter(
      getFeatures(state),
      (feature) => feature.elementVersion === targetElementVersionId
    );

    forEach(features, (feature) => {
      this.updateFeature(feature.id, {
        element: null,
      });
    });

    const drawings = filter(
      getDrawings(state),
      (drawing) => drawing.elementVersion === targetElementVersionId
    );

    forEach(drawings, (drawing) => {
      this.updateDrawing(drawing.id, {
        element: null,
        elementVersion: null,
      });
    });

    if (targetElementVersion.method) {
      this.updateMethod(targetElementVersion.method, {
        element: null,
      });
    }

    this.removeElementVersionFromPreferredElementVersionLists(elementVersionId);

    this.updateElement(elementId, {
      elementVersionsList,
    });

    this.updateElementVersion(elementVersionId, {
      element: null,
    });
  }

  removeElementFromPreferredElementVersionLists(elementId) {
    const state = this.redux.getState();
    const productsList = getProductsList(state);

    productsList.forEach((productId) => {
      const product = getProduct(state, productId);
      const preferredElementVersionsList =
        product.preferredElementVersionsList.filter(
          (obj) => obj.element !== elementId
        );

      this.updateProduct(productId, {
        preferredElementVersionsList,
      });
      // const preferredElementVersionsList = product.preferredElementVersionsList.filter(
      //   (obj) => obj.elementId !== elementVersionId && obj.elementVersion  !== elementVersionId
      // );
    });
  }

  removeElementVersionFromPreferredElementVersionLists(elementVersionId) {
    const state = this.redux.getState();
    const productsList = getProductsList(state);

    productsList.forEach((productId) => {
      const product = getProduct(state, productId);
      const preferredElementVersionsList =
        product.preferredElementVersionsList.map((obj) => {
          const _obj = { ...obj };
          if (_obj.elementVersion === elementVersionId) {
            _obj.elementVersion = null;
          }
          return _obj;
        });

      this.updateProduct(productId, {
        preferredElementVersionsList,
      });
    });
  }

  addTerm(attributes = {}, termId = uuid()) {
    const state = this.redux.getState();
    let name = attributes.name;

    // create default name if none exists
    if (!name) {
      const increment = getTermIncrement(state);
      name = `Term ${increment}`;
      attributes['name'] = name;
    }

    this.redux.store.dispatch(addTerm(attributes, termId));

    // add a default definition
    this.addTermDefinition(termId, 'definition');

    return termId;
  }

  sortTermsList(termsList) {
    this.redux.store.dispatch(sortTermsList(termsList));
  }

  alphabetizeTerms() {
    const state = this.redux.getState();
    const termsList = getTermsList(state);
    const terms = termsList.map((termId) => getTerm(state, termId));
    const alphabetizeTerms = sortBy(terms, ['name']);
    const alphabetizeTermsList = alphabetizeTerms.map((term) => term.id);
    // return console.log('alphabetizeTermsList', alphabetizeTermsList)
    this.redux.store.dispatch(sortTermsList(alphabetizeTermsList));
  }

  addTermMarker(termId, markerId) {
    const state = this.redux.getState();
    const term = getTerm(state, termId);
    const markersList = [...term.markersList, markerId];
    this.updateTerm(termId, { markersList });
  }

  removeTermMarker(termId, markerId) {
    const state = this.redux.getState();
    const term = getTerm(state, termId);
    if (term) {
      const markersList = term.markersList.filter((id) => id !== markerId);
      this.updateTerm(termId, { markersList });
    }
  }

  updateTerm(termId, attributes = {}) {
    this.redux.store.dispatch(updateTerm(termId, attributes));
    return termId;
  }

  removeTerms(termsList) {
    termsList.forEach((termId) => this.removeTerm(termId));
  }

  removeTerm(termId) {
    const state = this.redux.getState();
    const term = getTerm(state, termId);
    const markers = filter(
      getMarkers(state),
      (marker) => marker.term === termId
    );

    forEach(markers, (marker) => {
      this.updateMarker(marker.id, {
        term: null,
        element: null,
        elementVersion: null,
      });
      this.updateDrawing(marker.drawing, {});
    });
    this.redux.store.dispatch(removeFeatures(term.definitionsList));
    this.redux.store.dispatch(removeTerm(termId));
    this.redux.store.dispatch(
      addDeletedReference(termId, { name: term.name, type: 'term' })
    );
    return termId;
  }

  convertElementToTerm(elementId) {
    const state = this.redux.getState();
    const termId = elementId;
    const element = getElement(state, elementId);
    const name = element.name;
    const elementVersionId =
      element.elementVersionsList.length === 1 &&
      element.elementVersionsList[0];

    if (!elementVersionId) return;

    const elementVersion = getElementVersion(state, elementVersionId);

    const referencesList = [...elementVersion.markersList];

    const definitionsList = [...elementVersion.definitionsList];

    let additionalDetailFeatureValues = [];

    const language = 'report_list_abbreviated';

    element.requirementsList.forEach((featureId) => {
      const feature = getFeature(state, featureId);
      if (feature && feature.value) {
        const realization = getFeatureRealization({
          state,
          featuresList: [featureId],
          featureTypeId: feature.type,
          elementId,
          elementVersionId,
          language,
          wrapContent: false,
        });
        additionalDetailFeatureValues.push(realization);
      }
    });

    const outcomeFeature = getFeature(state, element.outcome);

    if (outcomeFeature && outcomeFeature.value) {
      const outcomeRealization = getFeatureRealization({
        state,
        featuresList: [element.outcome],
        featureTypeId: 'element_step',
        elementId,
        elementVersionId,
        language,
        wrapContent: false,
      });
      additionalDetailFeatureValues.push(outcomeRealization);
    }

    elementVersion.constraintsList.forEach((featureId) => {
      const feature = getFeature(state, featureId);
      if (feature && feature.value) {
        const realization = getFeatureRealization({
          state,
          featuresList: [featureId],
          featureTypeId: feature.type,
          elementId,
          elementVersionId,
          language,
          wrapContent: false,
        });
        additionalDetailFeatureValues.push(realization);
      }
    });

    elementVersion.featuresList
      .filter((featureId) => {
        const feature = getFeature(state, featureId);
        return (
          feature.value && !['definition', 'analogs'].includes(feature.type)
        );
      })
      .forEach((featureId) => {
        const feature = getFeature(state, featureId);
        const realization = getFeatureRealization({
          state,
          featuresList: [featureId],
          featureTypeId: feature.type,
          elementId,
          elementVersionId,
          language,
          wrapContent: false,
        });
        additionalDetailFeatureValues.push(realization);
      });

    definitionsList.forEach((featureId) =>
      this.updateFeature(featureId, {
        element: null,
        elementVersion: null,
        term: termId,
      })
    );

    additionalDetailFeatureValues.forEach((value) => {
      const featureId = uuid();
      const featureAttributes = {
        term: termId,
        type: 'detail',
        value,
      };
      definitionsList.push(featureId);
      // add the feature
      this.redux.store.dispatch(addFeature(featureAttributes, featureId));
    });

    // remove the element and element-version
    this.removeTreeItems([elementId], [elementVersionId], [], false);

    // remove the deleted reference
    this.redux.store.dispatch(removeDeletedReference(elementId));

    // convert the drawing referenced markers
    referencesList.forEach((markerId) => {
      this.updateMarker(markerId, {
        element: null,
        elementVersion: null,
        term: termId,
        type: 'term',
      });
    });

    // create term
    this.addTerm(
      {
        name,
        definitionsList,
        referencesList,
      },
      termId
    );

    return termId;
  }

  convertElementToSystem(elementId) {
    let state = this.redux.getState();
    this.updateElement(elementId, {
      category: 'system',
    });

    const markersList = getMarkersList(state);
    const elementMarkers = markersList.filter((markerId) => {
      const marker = getMarker(state, markerId);
      return marker.element && marker.element === elementId;
    });

    // convert the referenced markers to systems
    elementMarkers.forEach((markerId) => {
      this.updateMarker(markerId, {
        category: 'system',
      });
    });

    return elementId;
  }

  convertElementToPart(elementId) {
    let state = this.redux.getState();

    this.updateElement(elementId, {
      category: 'part',
    });

    const markersList = getMarkersList(state);
    const elementMarkers = markersList.filter((markerId) => {
      const marker = getMarker(state, markerId);
      return marker.element && marker.element === elementId;
    });

    // convert the referenced markers to systems
    elementMarkers.forEach((markerId) => {
      this.updateMarker(markerId, {
        category: 'part',
      });
    });

    return elementId;
  }

  convertTermToElement(termId, category = 'system') {
    let state = this.redux.getState();
    const term = getTerm(state, termId);
    const elementId = termId;
    const name = term.name;
    const markersList = [...term.markersList];
    const definitionsList = [...term.definitionsList];

    // remove the term
    this.redux.store.dispatch(removeTerm(termId));

    // create parentless element
    const elementVersionAttributes = {
      markersList,
      definitionsList,
      category: 'machine',
    };

    this.addElement(
      { name, category },
      null,
      elementId,
      elementVersionAttributes
    );
    state = this.redux.getState();
    const element = getElement(state, elementId);
    this.models.overwrite(elementId, 'element', element);

    const elementVersionId = element.elementVersionsList[0];

    definitionsList.forEach((featureId) =>
      this.updateFeature(featureId, {
        element: elementId,
        elementVersion: elementVersionId,
        term: null,
      })
    );

    // convert the drawing referenced markers
    markersList.forEach((markerId) => {
      this.updateMarker(markerId, {
        element: elementId,
        elementVersion: elementVersionId,
        term: null,
        type: 'element',
      });
    });

    return elementId;
  }

  removePreferredElementVersion(productId, elementId) {
    const state = this.redux.getState();
    const product = getProduct(state, productId);

    let preferredElementVersionsList =
      product.preferredElementVersionsList.filter(
        (obj) => obj.element !== elementId
      );

    this.updateProduct(productId, { preferredElementVersionsList });
  }

  setPreferredElementVersion(
    productId,
    elementId,
    elementVersionId
    // elementVersionsList
  ) {
    const state = this.redux.getState();
    const product = getProduct(state, productId);

    let preferredElementVersionsList =
      product.preferredElementVersionsList.filter(
        (obj) => obj.element !== elementId
      );

    preferredElementVersionsList = [
      ...preferredElementVersionsList,
      {
        element: elementId,
        elementVersion: elementVersionId,
      },
    ];

    // preferredElementVersionsList = uniqBy(preferredElementVersionsList, 'element');

    this.updateProduct(productId, { preferredElementVersionsList });
  }

  toggleCollapsedNode(nodeId) {
    const state = this.redux.getState();
    const productId = getActiveProductId(state);
    const collapsedNodesList = getCollapsedNodesList(state, productId);
    const isCollapsed = collapsedNodesList.includes(nodeId);
    if (isCollapsed) {
      this.removeCollapsedNode(nodeId, productId);
      this.tracking.trackEvent('explorer_expanded_node');
    } else {
      this.addCollapsedNode(nodeId, productId);
      this.tracking.trackEvent('explorer_collapsed_node');
    }
  }

  addCollapsedNode(nodeId, productId) {
    const state = this.redux.getState();
    productId = productId || getActiveProductId(state) || 'root';

    let collapsedNodesList = getCollapsedNodesList(state, productId);

    collapsedNodesList = [...collapsedNodesList, nodeId].uniq();

    let collapsedDescendantsList = [];

    const elementVersionsMap = getElementVersionsMap(state, productId);

    collapsedNodesList.forEach((nodeId) => {
      const node = elementVersionsMap[nodeId];
      const descendants = node
        ? node.descendants.filter((id) => id !== nodeId)
        : [];
      collapsedDescendantsList = collapsedDescendantsList.concat(descendants);
    });

    collapsedDescendantsList = collapsedDescendantsList.uniq();

    this.redux.store.dispatch(
      updateCollapsedNodes(
        productId,
        collapsedNodesList,
        collapsedDescendantsList
      )
    );
  }

  zoomToNode(nodeId, select) {
    const state = this.redux.getState();
    let activeProductId = getActiveProductId(state);

    if (activeProductId) {
      const elementsMap = getElementsMap(state, activeProductId);
      const isShownOnProduct = elementsMap[nodeId] ? true : false;

      if (!isShownOnProduct) {
        // go to all products
        activeProductId = null;
        this.setActiveProduct(activeProductId);
      }
    }

    const collapsedDescendantsList = getCollapsedDescendantsList(
      state,
      activeProductId
    );
    const isCollapsedDescendant = collapsedDescendantsList.includes(nodeId);

    // if its collapsed descendant, uncollapse its ancestor
    if (isCollapsedDescendant) {
      const collapsedNodesList = getCollapsedNodesList(state, activeProductId);
      const elementsMap = getElementsMap(state, activeProductId);
      const ancestors = elementsMap[nodeId] && elementsMap[nodeId].ancestors;
      ancestors.forEach((ancestorId) => {
        if (collapsedNodesList.includes(ancestorId)) {
          this.removeCollapsedNode(ancestorId, activeProductId);
        }
      });
    }

    // select the primary instance
    if (select) this.selectElement(nodeId);
  }

  removeCollapsedNode(nodeId, productId) {
    const state = this.redux.getState();
    productId = productId || getActiveProductId(state) || 'root';

    let collapsedNodesList = getCollapsedNodesList(state, productId);

    collapsedNodesList = collapsedNodesList.filter((id) => id !== nodeId);

    let collapsedDescendantsList = [];

    const elementVersionsMap = getElementVersionsMap(state, productId);

    collapsedNodesList.forEach((nodeId) => {
      const node = elementVersionsMap[nodeId];
      const descendants = node
        ? node.descendants.filter((id) => id !== nodeId)
        : [];
      collapsedDescendantsList = collapsedDescendantsList.concat(descendants);
    });

    collapsedDescendantsList = collapsedDescendantsList.uniq();

    this.redux.store.dispatch(
      updateCollapsedNodes(
        productId,
        collapsedNodesList,
        collapsedDescendantsList
      )
    );
  }

  getCoordinatesMap(productId) {
    const state = this.redux.getState();
    const rootNodeId = getRootNodeId(state);
    const rootElement = getElement(state, rootNodeId);
    const orphanNodesList = getOrphanNodesList(state);
    const elements = getElements(state);
    const elementVersions = getElementVersions(state);
    const collapsedNodesList = getCollapsedNodesList(state, productId);
    const activeView = productId ? 'elements' : 'solutions';

    let preferredElementVersionsMap;

    if (productId) {
      const product = getProduct(state, productId);
      const preferredElementVersionsList = product.preferredElementVersionsList;
      preferredElementVersionsMap = keyBy(
        preferredElementVersionsList,
        (obj) => {
          return `${obj.element}_${obj.elementVersion}`;
        }
      );
    }

    const coordinatesMap = getCoordinatesMap({
      state,
      rootElement,
      orphanNodesList,
      elements,
      elementVersions,
      collapsedNodesList,
      activeView,
      preferredElementVersionsMap,
    });

    return coordinatesMap;
  }

  getPresentationCoordinatesMap(productId, collapsedNodesList) {
    const activeView = 'elements';
    const state = this.redux.getState();
    const rootNodeId = getRootNodeId(state);
    const rootElement = getElement(state, rootNodeId);
    const orphanNodesList = []; // ignore disconnected nodes in presentation
    const elements = getElements(state);
    const elementVersions = getElementVersions(state);

    let preferredElementVersionsMap;

    if (productId) {
      const product = getProduct(state, productId);
      const preferredElementVersionsList = product.preferredElementVersionsList;
      preferredElementVersionsMap = keyBy(
        preferredElementVersionsList,
        (obj) => {
          return `${obj.element}_${obj.elementVersion}`;
        }
      );
    }

    const coordinatesMap = getCoordinatesMap({
      state,
      rootElement,
      orphanNodesList,
      elements,
      elementVersions,
      collapsedNodesList,
      activeView,
      preferredElementVersionsMap,
    });

    return coordinatesMap;
  }

  arrangeSeq(productId) {
    const activeView = productId ? 'elements' : 'solutions';

    const state = this.redux.getState();
    const isElementsView = activeView === 'elements';
    const elementsList = getElementsList(state);
    const elementVersionsList = getElementVersionsList(state);
    const coordinatesMap = this.getCoordinatesMap(productId);

    const elementsObj = {};
    const elementVersionsObj = {};

    elementVersionsList.forEach((elementVersionId) => {
      if (coordinatesMap[elementVersionId]) {
        const x = coordinatesMap[elementVersionId].endX;
        const y = coordinatesMap[elementVersionId].endY;
        const id = elementVersionId;
        elementVersionsObj[elementVersionId] = {
          id,
          x,
          y,
        };
      }
    });

    elementsList.forEach((elementId) => {
      if (coordinatesMap[elementId]) {
        const x = coordinatesMap[elementId].endX;
        const y = coordinatesMap[elementId].endY;
        const id = elementId;
        elementsObj[elementId] = {
          id,
          x,
          y,
        };

        const element = getElement(state, elementId);

        if (
          isElementsView &&
          element &&
          element.elementVersionsList.length === 1
        ) {
          elementVersionsObj[element.elementVersionsList[0]] = {
            id: element.elementVersionsList[0],
            x,
            y,
          };
        }
      }
    });

    const elementsArray = values(elementsObj);
    const elementVersionsArray = values(elementVersionsObj);

    this.redux.store.dispatch(
      updateNodeCoordinates(elementsArray, elementVersionsArray)
    );
  }

  showPresentation(reviewMode = false) {
    const state = this.redux.getState();
    const activeProductId =
      getActiveProductId(state) || getProductsList(state)[0];
    // const presentation = getPresentation(state);
    const preferredElementVersions = getPreferredElementVersionsList(state);
    const selectedElementVersionId = getSelectedElementVersion(state);
    const selectedElementId = getSelectedElement(state);

    let present = null;

    if (selectedElementVersionId) {
      present = selectedElementVersionId;
    } else if (selectedElementId) {
      const element = getElement(state, selectedElementId);
      const preferredElementVersionId = element.elementVersionsList.find(
        (elementVersionId) =>
          preferredElementVersions.includes(elementVersionId)
      );
      if (preferredElementVersionId) {
        present = preferredElementVersionId;
      }
    }

    // if reviewMode create a review if it doesn't exist
    // this.findOrCreateReview(activeProductId);

    this.redux.store.dispatch(
      updatePresentation({
        reviewMode,
        product: activeProductId,
        present,
        past: [],
      })
    );
  }

  findOrCreateReview(productId, userId) {
    const state = this.redux.getState();
    userId = userId || this.sessionManager.userId;
    let reviewId = getReviewByProductAndUser(state, productId, userId);

    if (!reviewId) {
      reviewId = this.addReview({
        product: productId,
        user: userId,
      });
    }

    return reviewId;
  }

  setPresentationView(view) {
    this.redux.store.dispatch(
      updatePresentation({
        view,
      })
    );
  }

  setPresentationProduct(productId, reviewMode) {
    if (reviewMode) {
      // if reviewMode create a review if it doesn't exist
      this.findOrCreateReview(productId);
    }

    this.redux.store.dispatch(
      updatePresentation({
        product: productId,
      })
    );
  }

  getElementVersion(elementVersionId) {
    const state = this.redux.getState();
    return getElementVersion(state, elementVersionId);
  }

  addPriorArt(attributes = {}, priorArtId = uuid()) {
    const state = this.redux.getState();
    this.redux.store.dispatch(addPriorArt(attributes, priorArtId));
    this.redux.store.dispatch(selectPriorArt(priorArtId));

    // default add the comparison to any product refs
    const productsList = getProductsList(state);
    productsList.forEach((productId) => {
      this.addComparison({
        product: productId,
        priorArt: priorArtId,
      });
    });

    return priorArtId;
  }

  removePriorArt(priorArtId) {
    const state = this.redux.getState();

    const priorArtsLists = getPriorArtsList(state);
    const comparisonsList = getComparisonsList(state);
    const priorArtIndex = priorArtsLists.indexOf(priorArtId);
    const previousId = priorArtsLists[priorArtIndex - 1];
    const nextId = priorArtsLists[priorArtIndex + 1];

    const comparisonIds = comparisonsList.filter((comparisonId) => {
      const comparison = getComparison(state, comparisonId);
      return comparison.priorArt === priorArtId;
    });

    comparisonIds.forEach((comparisonId) =>
      this.removeComparison(comparisonId)
    );

    let selectedPriorArtId = previousId || nextId;
    selectedPriorArtId = selectedPriorArtId || null;

    this.redux.store.dispatch(removePriorArt(priorArtId));
    this.redux.store.dispatch(selectPriorArt(selectedPriorArtId));

    // find comparisons and remove prior art from them
  }

  updatePriorArt(priorArtId, attributes) {
    this.redux.store.dispatch(updatePriorArt(priorArtId, attributes));
  }

  selectPriorArt(priorArtId) {
    this.redux.store.dispatch(selectPriorArt(priorArtId));
  }

  setShowingPriorArts(value) {
    this.redux.store.dispatch(setShowingPriorArts(value));
  }

  setShowingCustomers(value) {
    this.redux.store.dispatch(setShowingCustomers(value));
  }

  setShowingPatentability(value) {
    this.redux.store.dispatch(setShowingPatentability(value));
  }

  setActivePatentabilitySection(value) {
    this.redux.store.dispatch(setActivePatentabilitySection(value));
  }

  setShowingPatentSpecification(value) {
    this.redux.store.dispatch(setShowingPatentSpecification(value));
  }

  setDrawingPreviewMode(value) {
    this.redux.store.dispatch(setDrawingPreviewMode(value));
  }

  setPreviewMode(value) {
    this.redux.store.dispatch(setPreviewMode(value));
  }

  sortPriorArtsList(priorArtsList) {
    this.redux.store.dispatch(sortPriorArtsList(priorArtsList));
  }

  sortDrawingsList(drawingsList) {
    this.redux.store.dispatch(sortDrawingsList(drawingsList));
    this.updateFigures();
  }

  addReview(attributes = {}, reviewId = uuid()) {
    this.redux.store.dispatch(addReview(attributes, reviewId));
    this.redux.store.dispatch(selectReview(reviewId));
    this.redux.store.dispatch(setCreatingReview(false));
    return reviewId;
  }

  removeReview(reviewId) {
    const state = this.redux.getState();
    const reviewsList = getReviewsList(state);
    const reviewIndex = reviewsList.indexOf(reviewId);
    const previousId = reviewsList[reviewIndex - 1];
    const nextId = reviewsList[reviewIndex + 1];

    let selectedReviewId = previousId || nextId;
    selectedReviewId = selectedReviewId || null;

    this.redux.store.dispatch(removeReview(reviewId));
    this.redux.store.dispatch(selectReview(selectedReviewId));

    // find comparisons and remove product from them
  }

  updateReview(reviewId, attributes = {}) {
    this.redux.store.dispatch(updateReview(reviewId, attributes));
    return reviewId;
  }

  editReview(reviewId) {
    this.redux.store.dispatch(setEditingReview(reviewId));
    return reviewId;
  }

  viewReview(reviewId) {
    this.redux.store.dispatch(setActiveReview(reviewId));
    return reviewId;
  }

  selectReview(reviewId) {
    this.redux.store.dispatch(setCreatingReview(false));
    this.redux.store.dispatch(setEditingReview(null));
    this.redux.store.dispatch(selectReview(reviewId));
  }

  setCreatingReview(value) {
    this.redux.store.dispatch(setEditingReview(null));
    this.redux.store.dispatch(setCreatingReview(value));
  }

  sortReviewsList(reviewsList) {
    this.redux.store.dispatch(sortReviewsList(reviewsList));
  }

  addProduct(attributes = {}, productId = uuid(), shouldUpdateGraph = true) {
    const state = this.redux.getState();
    let name = attributes.name;
    const elementsList = getElementsList(state);

    if (!attributes.preferredElementVersionsList) {
      const preferredElementVersionsList = [];
      elementsList.forEach((elementId) => {
        const element = getElement(state, elementId);
        if (element && element.elementVersionsList.length === 1) {
          preferredElementVersionsList.push({
            element: elementId,
            elementVersion: element.elementVersionsList[0],
          });
        }
        if (element && element.elementVersionsList.length > 1) {
          preferredElementVersionsList.push({
            element: elementId,
            elementVersion: null,
          });
        }
      });
      attributes['preferredElementVersionsList'] = preferredElementVersionsList;
    }

    // create default name if none exists
    if (!name) {
      const increment = getProductIncrement(state);
      name = `Version ${increment}`;
      attributes['name'] = name;
    }

    this.redux.store.dispatch(addProduct(attributes, productId));

    if (shouldUpdateGraph) {
      this.updateGraph();
    }

    return productId;
  }

  addGraph() {
    const state = this.redux.getState();
    this.redux.store.dispatch(addGraph(state));
  }

  updateGraph() {
    const state = this.redux.getState();
    this.redux.store.dispatch(updateGraph(state));
    console.log('graph update');
    this.updateFigures();
  }

  addFigures() {
    const state = this.redux.getState();
    this.redux.store.dispatch(addFigures(state));
  }

  updateFigures() {
    const state = this.redux.getState();
    console.log('figures update');
    this.redux.store.dispatch(updateFigures(state));
  }

  duplicateProduct(oldProductId, productId = uuid()) {
    const state = this.redux.getState();
    const oldProduct = getProduct(state, oldProductId);

    this.addProduct(
      {
        ...oldProduct,
        name: `Copy of ${oldProduct.name}`,
        comparisonsList: [],
      },
      productId
    );

    oldProduct.comparisonsList.forEach((comparisonId) => {
      const oldComparison = getComparison(state, comparisonId);
      this.addComparison({
        ...oldComparison,
        product: productId,
      });
    });

    return productId;
  }

  duplicateDrawing(sourceDrawingId, withMarkers) {
    const state = this.redux.getState();
    const sourceDrawing = getDrawing(state, sourceDrawingId);
    const { orientation, viewAngle, description } = sourceDrawing;

    const drawingId = this.addDrawing({
      orientation,
      viewAngle,
      description,
    });

    sourceDrawing.imagesList.forEach((imageId) => {
      const image = getImage(state, imageId);
      this.addImage(drawingId, { ...image });
    });

    if (withMarkers) {
      sourceDrawing.markersList.forEach((sourceMarkerId) => {
        const sourceMarker = getMarker(state, sourceMarkerId);
        let attributes = {
          ...sourceMarker,
          drawing: drawingId,
          highlightsList: [],
        };

        attributes = omit(attributes, ['id']);

        const markerId = this.addMarker(drawingId, attributes);

        sourceMarker.highlightsList.forEach((highlightId) => {
          const highlight = getHighlight(state, highlightId);
          console.log('h', highlight);
          console.log('h2', {
            ...highlight,
            drawing: drawingId,
            marker: markerId,
          });
          this.addHighlight(drawingId, markerId, {
            ...highlight,
            drawing: drawingId,
            marker: markerId,
          });
        });
      });
    }

    return drawingId;
  }

  removeProduct(productId) {
    const state = this.redux.getState();
    const product = getProduct(state, productId);
    const activeProductId = getActiveProductId(state);
    const productsList = getProductsList(state);
    const productIndex = productsList.indexOf(productId);
    const previousId = productsList[productIndex - 1];
    const nextId = productsList[productIndex + 1];

    let selectedProductId = previousId || nextId;
    selectedProductId = selectedProductId || null;

    // remove comparisons
    product.comparisonsList.forEach((comparisonId) =>
      this.removeComparison(comparisonId)
    );

    // remove reviews
    const reviewsList = getReviewsList(state).filter((reviewId) => {
      const review = getReview(state, reviewId);
      return review.product === productId;
    });
    reviewsList.forEach((reviewId) => this.removeReview(reviewId));

    if (productId === activeProductId) {
      this.setActiveProduct(selectedProductId || null);
    }

    this.redux.store.dispatch(selectProduct(selectedProductId));

    this.redux.store.dispatch(removeProduct(productId));

    return productId;
  }

  addProductMarker(productId, markerId) {
    const state = this.redux.getState();
    const product = getProduct(state, productId);
    const markersList = [...product.markersList, markerId];
    this.updateProduct(productId, { markersList });
  }

  removeProductMarker(productId, markerId) {
    const state = this.redux.getState();
    const product = getProduct(state, productId);
    const markersList = product.markersList.filter((id) => id !== markerId);
    this.updateProduct(productId, { markersList });
  }

  setActiveProduct(productId, arrange = true) {
    // const state = this.redux.getState();
    this.redux.store.dispatch(setActiveProduct(productId));
    if (arrange) {
      this.arrangeSeq(productId);
    }
  }

  setActiveProductView(value) {
    this.redux.store.dispatch(setActiveProductView(value));
  }

  updateProduct(productId, attributes = {}) {
    this.redux.store.dispatch(updateProduct(productId, attributes));
    return productId;
  }

  editProduct(productId) {
    this.redux.store.dispatch(setEditingProduct(productId));
    return productId;
  }

  selectProduct(productId) {
    // this.redux.store.dispatch(setEditingProduct(null));
    this.redux.store.dispatch(selectProduct(productId));
  }

  setShowingProducts(value) {
    this.redux.store.dispatch(setShowingProducts(value));
  }

  setShowingDrawings(value) {
    this.redux.store.dispatch(setShowingDrawings(value));
  }

  setActiveMilestoneContext(value) {
    this.redux.store.dispatch(setActiveMilestoneContext(value));
  }

  setShowingProductChecklist(value) {
    this.redux.store.dispatch(setShowingProductChecklist(value));
  }

  sortProductsList(reviewsList) {
    this.redux.store.dispatch(sortProductsList(reviewsList));
  }

  addComparison(attributes = {}, comparisonId = uuid()) {
    const state = this.redux.getState();
    const productId = attributes.product;
    const product = getProduct(state, productId);
    const comparisonsList = [...product.comparisonsList, comparisonId];
    this.redux.store.dispatch(addComparison(attributes, comparisonId));
    this.updateProduct(productId, { comparisonsList });
    // this.redux.store.dispatch(selectComparison(comparisonId));
    // this.redux.store.dispatch(setCreatingComparison(false));
    return comparisonId;
  }

  removeComparison(comparisonId) {
    const state = this.redux.getState();
    const comparison = getComparison(state, comparisonId);
    const productId = comparison.product;
    const product = getProduct(state, productId);
    const comparisonsList = product.comparisonsList.filter(
      (id) => id !== comparisonId
    );
    this.redux.store.dispatch(removeComparison(comparisonId));
    this.updateProduct(productId, { comparisonsList });
  }

  updateComparison(comparisonId, attributes = {}) {
    this.redux.store.dispatch(updateComparison(comparisonId, attributes));
    return comparisonId;
  }

  editComparison(comparisonId) {
    this.redux.store.dispatch(setEditingComparison(comparisonId));
    return comparisonId;
  }

  viewComparison(comparisonId) {
    this.redux.store.dispatch(setActiveComparison(comparisonId));
    return comparisonId;
  }

  selectComparison(comparisonId) {
    this.redux.store.dispatch(setCreatingComparison(false));
    this.redux.store.dispatch(setEditingComparison(null));
    this.redux.store.dispatch(selectComparison(comparisonId));
  }

  setCreatingComparison(value) {
    this.redux.store.dispatch(setEditingComparison(null));
    this.redux.store.dispatch(setCreatingComparison(value));
  }

  sortComparisonsList(comparisonsList) {
    this.redux.store.dispatch(sortComparisonsList(comparisonsList));
  }

  addAsset(attributes = {}, assetId = uuid()) {
    this.redux.store.dispatch(addAsset(attributes, assetId));
    this.redux.store.dispatch(selectAsset(assetId));
    this.redux.store.dispatch(setCreatingAsset(false));
  }

  removeAsset(assetId) {
    const state = this.redux.getState();
    const assetsLists = getAssetsList(state);
    const assetIndex = assetsLists.indexOf(assetId);
    const previousId = assetsLists[assetIndex - 1];
    const nextId = assetsLists[assetIndex + 1];

    let selectedAssetId = previousId || nextId;
    selectedAssetId = selectedAssetId || null;

    this.redux.store.dispatch(removeAsset(assetId));
    this.redux.store.dispatch(selectAsset(selectedAssetId));

    // find comparisons and remove prior art from them
  }

  updateAsset(assetId, attributes) {
    this.redux.store.dispatch(updateAsset(assetId, attributes));
  }

  selectAsset(assetId) {
    this.redux.store.dispatch(setCreatingAsset(false));
    this.redux.store.dispatch(setEditingAsset(null));
    this.redux.store.dispatch(selectAsset(assetId));
  }

  setCreatingAsset(value) {
    this.redux.store.dispatch(setCreatingAsset(value));
    this.redux.store.dispatch(setEditingAsset(null));
  }

  editAsset(assetId) {
    this.redux.store.dispatch(setEditingAsset(assetId));
    return assetId;
  }

  setShowingAssets(value) {
    this.redux.store.dispatch(setShowingAssets(value));
    if (value === false) {
      this.setCreatingAsset(false);
      this.setCreatingImageAsset(null);
      this.setUpdatingImageAsset(null);
      this.setUpdatingProductAsset(null);
      this.setUpdatingPriorArtAsset(null);
    }
  }

  sortAssetsList(assetsList) {
    this.redux.store.dispatch(sortAssetsList(assetsList));
  }

  async parseAssetFiles(fileObjs = []) {
    try {
      const parsedFiles = [];

      for (let i = 0; i < fileObjs.length; i++) {
        const parsedFile = await this.parseAssetFile(fileObjs[i]);
        parsedFiles.push(parsedFile);
      }

      return parsedFiles;
    } catch (err) {
      console.error(err);
    }
  }

  async parseAssetFile(fileObj, assetId) {
    try {
      assetId = assetId || uuid();
      const { file, externalUrl } = fileObj;

      const parsedFile = await parseImageFile(
        file,
        defaultArtboardWidth,
        defaultArtboardHeight
      );

      const { blob, attributes } = parsedFile;

      let name = attributes.name;

      if (externalUrl) {
        name = name || externalUrl;
      }

      return {
        ...attributes,
        name,
        assetId,
        externalUrl,
        blob,
      };
    } catch (err) {
      console.error(err);
    }
  }

  async addParsedAssetsToDB(parsedAssets = []) {
    try {
      for (let i = 0; i < parsedAssets.length; i++) {
        const { assetId, blob } = parsedAssets[i];
        await this.assets.addAsset(assetId, blob);
      }
    } catch (err) {
      console.error(err);
    }
  }

  async addDrawingFromAssetId(assetId) {
    const drawingId = this.addDrawing();
    const assetBlob = await this.assets.getAsset(assetId);
    const parsedAsset = await this.parseAssetFile({ file: assetBlob }, assetId);
    this.addAssets([parsedAsset]);
    this.addImageFromAsset(drawingId, assetId);
  }

  async addDrawingsFromAssetIds(assetIds) {
    for (let i = 0; i < assetIds.length; i++) {
      await this.addDrawingFromAssetId(assetIds[i]);
    }
  }

  addAssets(parsedAssets) {
    for (let i = 0; i < parsedAssets.length; i++) {
      const { name, fileType, fileWidth, fileHeight, externalUrl, assetId } =
        parsedAssets[i];

      const asset = {
        id: assetId,
        name,
        fileType,
        width: fileWidth,
        height: fileHeight,
        externalUrl,
      };

      this.addAsset(asset, assetId);
    }

    return parsedAssets.map((obj) => obj.assetId);
  }

  async addAssetsFromFiles(fileObjs = []) {
    try {
      const parsedAssets = await this.parseAssetFiles(fileObjs);
      await this.addParsedAssetsToDB(parsedAssets);
      return parsedAssets;
    } catch (err) {
      console.error(err);
    }
  }

  setCreatingImageAsset(drawingId) {
    this.setCreatingAsset(true);
    this.redux.store.dispatch(setCreatingImageAsset(drawingId));
  }

  setUpdatingImageAsset(imageId) {
    this.redux.store.dispatch(setUpdatingImageAsset(imageId));
  }

  setUpdatingProductAsset(productId) {
    this.redux.store.dispatch(setUpdatingProductAsset(productId));
    this.redux.store.dispatch(selectAsset(null));
  }

  setUpdatingProductPriorArt(productId) {
    this.redux.store.dispatch(setUpdatingProductPriorArt(productId));
  }

  addCustomer(attributes = {}, customerId = uuid()) {
    const state = this.redux.getState();
    this.redux.store.dispatch(addCustomer(attributes, customerId));
    this.redux.store.dispatch(selectCustomer(customerId));

    // default add the customer to any product refs
    const productsList = getProductsList(state);
    productsList.forEach((productId) => {
      const product = getProduct(state, productId);
      if (product.customersList) {
        const customersList = [...product.customersList, customerId];
        this.updateProduct(productId, { customersList });
      }
    });

    return customerId;
  }

  removeCustomer(customerId) {
    const state = this.redux.getState();

    const customersList = getCustomersList(state);
    const customerIndex = customersList.indexOf(customerId);
    const previousId = customersList[customerIndex - 1];
    const nextId = customersList[customerIndex + 1];

    let selectedCustomerId = previousId || nextId;
    selectedCustomerId = selectedCustomerId || null;

    // remove the customer from any product refs
    const productsList = getProductsList(state);
    productsList.forEach((productId) => {
      const product = getProduct(state, productId);
      if (product.customersList && product.customersList.includes(customerId)) {
        const customersList = product.customersList.filter(
          (id) => id !== customerId
        );
        this.updateProduct(productId, { customersList });
      }
    });

    this.redux.store.dispatch(removeCustomer(customerId));
    this.redux.store.dispatch(selectCustomer(selectedCustomerId));
  }

  updateCustomer(customerId, attributes) {
    this.redux.store.dispatch(updateCustomer(customerId, attributes));
  }

  selectCustomer(customerId) {
    this.redux.store.dispatch(selectCustomer(customerId));
  }

  sortCustomersList(customersList) {
    this.redux.store.dispatch(sortCustomersList(customersList));
  }

  setUpdatingProductCustomers(productId) {
    this.redux.store.dispatch(setUpdatingProductCustomers(productId));
  }

  setUpdatingPriorArtAsset(priorArtId) {
    this.redux.store.dispatch(setUpdatingPriorArtAsset(priorArtId));
  }

  setMarkerType(markerId, type, category = null) {
    const state = this.redux.getState();

    const marker = getMarker(state, markerId);

    this.removeMarkerReferences(markerId);

    this.updateMarker(markerId, {
      type,
      category,
      term: null,
      element: null,
      elementVersion: null,
    });

    this.updateDrawing(marker.drawing, {});
    this.updateFigures();
  }

  addMarker(drawingId, attributes = {}) {
    const state = this.redux.getState();
    const markerId = uuid();

    // add the reference
    if (attributes['elementVersion']) {
      this.addElementVersionMarker(attributes['elementVersion'], markerId);
    }
    if (attributes['term']) {
      this.addTermMarker(attributes['term'], markerId);
    }
    if (attributes['product']) {
      this.addProductMarker(attributes['product'], markerId);
    }

    this.redux.store.dispatch(addMarker(attributes, markerId));

    const drawing = getDrawing(state, drawingId);

    const markersList = [...drawing.markersList, markerId];

    this.updateDrawing(drawingId, { markersList });

    return markerId;
  }

  updateMarker(markerId, attributes = {}) {
    this.redux.store.dispatch(updateMarker(markerId, attributes));
    return markerId;
  }

  removeMarkerReferences(markerId) {
    const state = this.redux.getState();
    const marker = getMarker(state, markerId);

    if (marker.term) {
      this.removeTermMarker(marker.term, markerId);
    }
    if (marker.elementVersion) {
      this.removeElementVersionMarker(marker.elementVersion, markerId);
    }
    if (marker.product) {
      this.removeProductMarker(marker.product, markerId);
    }
  }

  addDrawing(attributes = {}) {
    const drawingId = uuid();
    this.redux.store.dispatch(addDrawing(attributes, drawingId));
    this.updateFigures();
    return drawingId;
  }

  updateDrawing(drawingId, attributes = {}) {
    this.redux.store.dispatch(updateDrawing(drawingId, attributes));
    return drawingId;
  }

  removeDrawing(drawingId) {
    const state = this.redux.getState();
    const drawing = getDrawing(state, drawingId);
    const imagesList = drawing.imagesList;
    const markersList = drawing.markersList;

    const highlightsList = [];

    markersList.forEach((markerId) => {
      const marker = getMarker(state, markerId);
      marker.highlightsList.forEach((highlightId) =>
        highlightsList.push(highlightId)
      );
      this.removeMarkerReferences(markerId);
    });

    this.redux.dispatch(
      removeDrawing(drawingId, imagesList, markersList, highlightsList)
    );

    this.updateFigures();
  }

  addImage(drawingId, attributes = {}) {
    const state = this.redux.getState();
    const imageId = uuid();
    const drawing = getDrawing(state, drawingId);
    const imagesList = [imageId, ...drawing.imagesList];

    attributes = {
      ...attributes,
      drawing: drawingId,
    };

    this.redux.store.dispatch(addImage(attributes, imageId));
    this.updateDrawing(drawingId, { imagesList });

    return imageId;
  }

  addImageFromAsset(drawingId, assetId) {
    const state = this.redux.getState();
    const asset = getAsset(state, assetId);

    const attributes = {
      width: asset.width,
      height: asset.height,
      x: (-1 * asset.width) / 2,
      y: (-1 * asset.height) / 2,
      asset: assetId,
    };

    const imageId = this.addImage(drawingId, attributes);

    this.selectImage(imageId);

    this.setShowingAssets(false);

    return imageId;
  }

  updateImageAsset(imageId, assetId) {
    const state = this.redux.getState();
    const asset = getAsset(state, assetId);
    const image = getImage(state, imageId);
    const drawingId = image.drawing;

    const attributes = {
      asset: assetId,
    };

    const assetRatio = asset.width / asset.height;
    const imageRatio = image.width / image.height;
    const ratioDiff = assetRatio !== imageRatio;

    if (ratioDiff) {
      attributes['width'] = asset.width;
      attributes['height'] = asset.height;
    }

    this.redux.store.dispatch(updateImage(imageId, attributes));

    this.updateDrawing(drawingId, {});

    this.setShowingAssets(false);
  }

  updateImage(imageId, attributes) {
    const state = this.redux.getState();
    const image = getImage(state, imageId);
    const drawingId = image.drawing;

    this.redux.store.dispatch(updateImage(imageId, attributes));

    this.updateDrawing(drawingId, {});
  }

  updateProductAsset(/*productId, assetId*/) {}

  updatePriorArtAsset(/*priorArtId, assetId*/) {}

  setShowingComparisonMatrix(value) {
    // const state = this.redux.getState();

    // if (value) {
    //   const selectedElementId = getSelectedElement(state);
    //   const selectedElementVersionId = getSelectedElementVersion(state);

    //   if (!elementId) {
    //     if (selectedElementId) {
    //       elementId = selectedElementId;
    //     } else if (selectedElementVersionId) {
    //       const elementVersion = getElementVersion(
    //         state,
    //         selectedElementVersionId
    //       );
    //       elementId = (elementVersion && elementVersion.element) || null;
    //     }
    //   }

    //   this.setActiveComparisonElement(elementId);
    // }

    this.redux.store.dispatch(setShowingComparisonMatrix(value));
  }

  setPatentSpecificationPreviewMode(value) {
    this.redux.store.dispatch(setPatentSpecificationPreviewMode(value));
  }

  setActiveComparisonElement(elementId = null) {
    this.redux.store.dispatch(setActiveComparisonElement(elementId));
  }

  setShowingHistoryVersionCreateModal(value) {
    this.redux.store.dispatch(setShowingHistoryVersionCreateModal(value));
  }

  setActiveView(viewId = null) {
    this.redux.store.dispatch(setActiveView(viewId));
  }

  setActiveMethod(methodId = null) {
    this.redux.store.dispatch(setActiveMethod(methodId));
  }

  selectElement(elementId = null) {
    this.redux.store.dispatch(selectElement(elementId));
  }

  deselectElement(elementId) {
    this.redux.store.dispatch(deselectElement(elementId));
  }

  selectTerm(termId = null, isSelected) {
    if (isSelected) {
      this.redux.store.dispatch(deselectTerm(termId));
    } else {
      this.redux.store.dispatch(selectTerm(termId));
    }
  }

  deselectTerm(termId) {
    this.redux.store.dispatch(deselectTerm(termId));
  }

  selectCustomStep(featureId) {
    const state = this.redux.getState();
    const methodNodesList = getMethodNodesList(state);
    const customMethodNode = methodNodesList
      .map((methodNodeId) => {
        return getMethodNode(state, methodNodeId);
      })
      .find((methodNode) => {
        return methodNode.type === 'custom' && methodNode.feature === featureId;
      });

    if (customMethodNode) {
      this.stackNavigationPush('method', 'method', customMethodNode.method);
      this.selectMethodNode(customMethodNode.id);
    }
  }

  selectElementVersion(elementVersionId = null) {
    this.redux.store.dispatch(selectElementVersion(elementVersionId));
  }

  setActiveDrawing(drawingId = null) {
    this.redux.store.dispatch(setActiveDrawing(drawingId));
  }

  setShowingDrawingSelectionModal(value) {
    this.redux.store.dispatch(setShowingDrawingSelectionModal(value));
  }

  deselectMarker(markerId) {
    this.redux.store.dispatch(deselectMarker(markerId));
  }

  selectMarker(markerId, multiSelectMode) {
    this.redux.store.dispatch(selectMarker(markerId, multiSelectMode));
  }

  setCollapsedProblem(value) {
    this.redux.store.dispatch(setCollapsedProblem(value));
  }

  setCollapsedSolution(value) {
    this.redux.store.dispatch(setCollapsedSolution(value));
  }

  setShowingElementVersionSelector(value) {
    this.redux.store.dispatch(setShowingElementVersionSelector(value));
  }

  setShowingFigures(value) {
    this.redux.store.dispatch(setShowingFigures(value));
  }

  setShowingSearch(value) {
    this.redux.store.dispatch(setShowingSearch(value));
  }

  setShowingGetStarted(value) {
    this.redux.store.dispatch(setShowingGetStarted(value));
  }

  setDefaultElementCategory(value) {
    this.redux.store.dispatch(setDefaultElementCategory(value));
  }

  setDefaultMarkerCategory(value) {
    this.redux.store.dispatch(setDefaultMarkerCategory(value));
  }

  setDefaultMethodNodeType(value) {
    this.redux.store.dispatch(setDefaultMethodNodeType(value));
  }

  setActiveContextTab(tabId) {
    this.redux.store.dispatch(setActiveContextTab(tabId));
    switch (tabId) {
      case 'elements':
        this.tracking.trackEvent('explorer_viewed_systems');
        break;
      case 'figures':
        this.tracking.trackEvent('explorer_viewed_figures');
        break;
      case 'terms':
        this.tracking.trackEvent('explorer_viewed_glossary');
        break;
    }
  }

  setFigureType(type) {
    this.redux.store.dispatch(setFigureType(type));
    switch (type) {
      case 'drawings':
        this.tracking.trackEvent('explorer_filtered_figures_drawings');
        break;
      case 'methods':
        this.tracking.trackEvent('explorer_filtered_figures_methods');
        break;
    }
  }

  setActiveElementVersionListItem(elementVersionId) {
    this.redux.store.dispatch(
      setActiveElementVersionListItem(elementVersionId)
    );
  }

  setExplorerWidth(width) {
    this.redux.store.dispatch(setExplorerWidth(width));
    this.tracking.trackEvent('resized_explorer');
  }

  showExplorer() {
    this.redux.store.dispatch(showExplorer());
    this.tracking.trackEvent('opened_explorer');
  }

  hideExplorer() {
    this.redux.store.dispatch(hideExplorer());
    this.tracking.trackEvent('closed_explorer');
  }

  setShowingNotes(value) {
    if (value) {
      this.redux.store.dispatch(showNotes());
      this.tracking.trackEvent('opened_comments');
    } else {
      this.redux.store.dispatch(hideScratchpad());
      this.tracking.trackEvent('closed_comments');
    }
  }

  updateElementColumn(columnId, attributes) {
    const state = this.redux.getState();
    const elementColumnsData = getElementColumnsData(state) || {};

    let updatedElementColumnsData;

    if (elementColumnsData[columnId]) {
      updatedElementColumnsData = {
        ...elementColumnsData,
        [columnId]: {
          ...elementColumnsData[columnId],
          ...attributes,
        },
      };
    } else {
      updatedElementColumnsData = {
        ...elementColumnsData,
        [columnId]: attributes,
      };
    }

    this.redux.dispatch(updateElementColumnsData(updatedElementColumnsData));
  }

  updateFeature(featureId, attributes = {}) {
    this.redux.store.dispatch(updateFeature(featureId, attributes));
  }

  updatePatentSpecification(attributes = {}) {
    this.redux.store.dispatch(updatePatentSpecification(attributes));
  }

  selectMethodEdge(methodEdgeId) {
    this.redux.store.dispatch(selectMethodEdge(methodEdgeId));
  }

  deselectMethodEdge(methodEdgeId) {
    this.redux.store.dispatch(deselectMethodEdge(methodEdgeId));
  }

  selectMethodEdgePoint(bendPointId) {
    this.redux.store.dispatch(selectMethodEdgePoint(bendPointId));
  }

  deselectMethodEdgePoint(bendPointId) {
    this.redux.store.dispatch(deselectMethodEdgePoint(bendPointId));
  }

  selectMethodNode(methodNodeId) {
    this.redux.store.dispatch(selectMethodNode(methodNodeId));
  }

  deselectMethodNode(methodNodeId) {
    this.redux.store.dispatch(deselectMethodNode(methodNodeId));
  }

  removeSelectedDrawingItems(
    drawingId,
    imagesList = [],
    markersList = [],
    highlightsList = []
  ) {
    const state = this.redux.getState();

    highlightsList = [...highlightsList];

    markersList.forEach((markerId) => {
      const marker = getMarker(state, markerId);
      marker.highlightsList.forEach((highlightId) =>
        highlightsList.push(highlightId)
      );
      this.removeMarkerReferences(markerId);
    });

    this.redux.store.dispatch(
      removeSelectedDrawingItems(
        drawingId,
        imagesList,
        markersList,
        highlightsList
      )
    );

    this.updateFigures();
  }

  getStackSelectedItems() {
    const state = this.redux.getState();

    const selectedElements = getSelectedElements(state) || [];
    const selectedElementVersions = getSelectedElementVersions(state) || [];
    const selectedTerms = getSelectedTerms(state) || [];
    const selectedImages = getSelectedImages(state) || [];
    const selectedMarkers = getSelectedMarkers(state) || [];
    const selectedMethodNodes = getSelectedMethodNodes(state) || [];
    const selectedMethodEdges = getSelectedMethodEdges(state) || [];
    const selectedMethodEdgePoints = getSelectedMethodEdgePoints(state) || [];
    const showingFigures = getShowingFigures(state);

    return {
      selectedElements,
      selectedElementVersions,
      selectedTerms,
      selectedImages,
      selectedMarkers,
      selectedMethodNodes,
      selectedMethodEdges,
      selectedMethodEdgePoints,
      showingFigures,
    };
  }

  updateNavigationStackSelectedItems(selectedItems = {}) {
    const items = {
      selectedElements: [],
      selectedElementVersions: [],
      selectedTerms: [],
      selectedImages: [],
      selectedMarkers: [],
      selectedMethodNodes: [],
      selectedMethodEdges: [],
      selectedMethodEdgePoints: [],
      showingFigures: false,
      ...selectedItems,
    };
    this.redux.store.dispatch(updateNavigationStackSelectedItems(items));
  }

  stackNavigationPush(routeName, modelType, modelId) {
    const state = this.redux.getState();
    const navigationStack = getNavigationStack(state);
    const activeNavigationStack = getActiveNavigationStack(state);
    const key = `${routeName}:${modelId}`;
    const previousStack = navigationStack[navigationStack.length - 1];
    if (previousStack) {
      const selectedItems = this.getStackSelectedItems();
      previousStack.selectedItems = selectedItems;

      // save the scroll top info if on patent-specification
      if (previousStack.routeName === 'patent-specification') {
        const domElement = document.getElementById('patent-specification');
        const scrollTop = (domElement && domElement.scrollTop) || 0;
        previousStack.scrollTop = scrollTop;
      }
    }

    // don't navigate is already there
    if (activeNavigationStack && activeNavigationStack.key === key) {
      return;
    }

    const stack = {
      routeName,
      modelType,
      modelId,
      key,
    };

    const updatedNavigationStack = [...navigationStack, stack];

    this.redux.store.dispatch(clearSelection());
    this.redux.store.dispatch(updateNavigationStack(updatedNavigationStack));

    const underscoredRouteName = underscore(routeName);
    this.tracking.trackEvent(`opened_${underscoredRouteName}`);
  }

  stackNavigationFilter(modelType, modelId) {
    const state = this.redux.getState();
    const navigationStack = getNavigationStack(state);

    const updatedNavigationStack = navigationStack.filter((stack) => {
      return stack.modelId !== modelId;
    });

    this.redux.store.dispatch(clearSelection());
    this.redux.store.dispatch(updateNavigationStack(updatedNavigationStack));
  }

  stackNavigationPop() {
    const state = this.redux.getState();
    const navigationStack = getNavigationStack(state);

    this.tracking.trackEvent('stack_navigation_pop');

    // don't over pop
    if (navigationStack.length === 1) {
      return;
    }

    const previousStack = navigationStack[navigationStack.length - 2];
    if (previousStack && previousStack.selectedItems) {
      this.updateNavigationStackSelectedItems(previousStack.selectedItems);
    }

    const currentStack = navigationStack[navigationStack.length - 1];
    if (currentStack && currentStack.routeName) {
      const underscoredRouteName = underscore(currentStack.routeName);
      this.tracking.trackEvent(`closed_${underscoredRouteName}`);
    }

    // this.setPreviewMode(false);

    const updatedNavigationStack = navigationStack.slice(0, -1);
    // this.redux.store.dispatch(clearSelection());
    this.redux.store.dispatch(updateNavigationStack(updatedNavigationStack));
  }

  stackNavigationReset() {
    const state = this.redux.getState();
    const navigationStack = getNavigationStack(state);
    const previousStack = navigationStack && navigationStack[0];

    this.tracking.trackEvent('stack_navigation_reset');

    if (previousStack && previousStack.selectedItems) {
      this.updateNavigationStackSelectedItems(previousStack.selectedItems);
    }

    if (navigationStack.length) {
      navigationStack.forEach(({ routeName }, index) => {
        if (index) {
          const underscoredRouteName = underscore(routeName);
          this.tracking.trackEvent(`closed_${underscoredRouteName}`);
        }
      });
    }
    const updatedNavigationStack = [
      {
        routeName: 'graph',
        modelType: null,
        modelId: null,
        key: 'graph',
        selectedItems: {},
      },
    ];
    // this.setPreviewMode(false);
    this.redux.store.dispatch(updateNavigationStack(updatedNavigationStack));
  }

  selectDrawing(drawingId) {
    this.redux.store.dispatch(selectDrawing(drawingId));
  }

  deselectDrawing(drawingId) {
    this.redux.store.dispatch(deselectDrawing(drawingId));
  }

  selectImage(imageId, multiSelectMode) {
    this.redux.store.dispatch(selectImage(imageId, multiSelectMode));
  }

  deselectImage(imageId) {
    this.redux.store.dispatch(deselectImage(imageId));
  }

  deselectDrawingItems(drawingId) {
    this.redux.store.dispatch(deselectDrawingItems(drawingId));
  }

  deselectMethodItems(methodId) {
    this.redux.store.dispatch(deselectMethodItems(methodId));
  }

  updateDrawingItems(drawingId, images, markers) {
    this.redux.store.dispatch(updateDrawingItems(images, markers));
    this.updateDrawing(drawingId, {});
  }

  addHighlight(drawingId, markerId, attributes = {}) {
    const highlightId = uuid();
    attributes = {
      ...attributes,
      id: highlightId,
      drawing: drawingId,
      marker: markerId,
    };
    this.redux.store.dispatch(addHighlight(markerId, attributes, highlightId));
    this.updateDrawing(drawingId, {});
    return highlightId;
  }

  removeHighlight(drawingId, markerId, highlightId) {
    this.redux.store.dispatch(removeHighlight(markerId, highlightId));
    this.updateDrawing(drawingId, {});
  }

  updateHighlight(markerId, highlightId, attributes = {}) {
    const state = this.redux.getState();
    const marker = getMarker(state, markerId);
    this.redux.store.dispatch(updateHighlight(highlightId, attributes));
    this.updateMarker(markerId, {});
    this.updateDrawing(marker.drawing, {});
  }

  updateMethodNode(methodNodeId, attributes = {}) {
    this.redux.store.dispatch(updateMethodNode(methodNodeId, attributes));
  }

  updateMethodEdge(methodEdgeId, attributes = {}) {
    this.redux.store.dispatch(updateMethodEdge(methodEdgeId, attributes));
  }

  updateMethodLayout(
    methodId,
    layout,
    offsetX = 0,
    offsetY = 0,
    artboardWidth
  ) {
    // do offsets
    // const graphWidth = max(layout.children, node => node.x + node.width);

    offsetX = offsetX - (artboardWidth - layout.width) / 2;

    const nodes = layout.children.map((node) => ({
      ...node,
      x: node.x - offsetX,
      y: node.y - offsetY,
    }));

    const edges = layout.edges.map((edge) => ({
      ...edge,
      sections: edge.sections.map((section) => ({
        ...section,
        startPoint: {
          x: section.startPoint.x - offsetX,
          y: section.startPoint.y - offsetY,
        },
        endPoint: {
          x: section.endPoint.x - offsetX,
          y: section.endPoint.y - offsetY,
        },
        bendPoints: section.bendPoints
          ? section.bendPoints.map((bendPoint) => ({
              id: uuid(),
              x: bendPoint.x - offsetX,
              y: bendPoint.y - offsetY,
            }))
          : [],
      })),
    }));

    nodes.forEach((node) =>
      this.updateMethodNode(node.id, {
        x: node.x,
        y: node.y,
        width: node.width,
        height: node.height,
        // x: node.x,
        // y: node.y,
      })
    );

    edges.forEach((edge) => {
      const path = getEdgePath(edge.sections);
      const bendPoints = getBendPoints(edge.sections);
      const section = edge.sections[0];
      const sourceNode = nodes.find(
        (node) => node.id === section.incomingShape
      );
      const targetNode = nodes.find(
        (node) => node.id === section.outgoingShape
      );

      const { sourcePosition, targetPosition } = getEdgePositions(
        sourceNode,
        targetNode,
        section
      );

      this.updateMethodEdge(edge.id, {
        path,
        bendPoints,
        sourcePosition,
        targetPosition,
      });
    });

    this.updateMethod(methodId, {});
  }

  setShowingSettings(value) {
    this.redux.store.dispatch(setShowingSettings(value));
  }

  updateSettings(attributes) {
    this.redux.store.dispatch(updateSettings(attributes));
  }

  setActivePatentSpecificationEditorSection(value) {
    this.redux.store.dispatch(setActivePatentSpecificationEditorSection(value));
  }

  setShowingPatentSpecificationEditor(value) {
    this.redux.store.dispatch(setShowingPatentSpecificationEditor(value));
  }

  setActiveInventionSummaryEditorSection(value) {
    this.redux.store.dispatch(setActiveInventionSummaryEditorSection(value));
  }

  setShowingInventionSummaryEditor(value) {
    this.redux.store.dispatch(setShowingInventionSummaryEditor(value));
  }

  updateSessionTime(currentTime) {
    if (!currentTime) return;
    const state = this.redux.getState();
    const sessionTime = getSessionTime(state) || 0;
    const updatedSessionTime = sessionTime + currentTime;

    this.redux.store.dispatch(
      updateMeta({
        sessionTime: updatedSessionTime,
      })
    );
  }
}
