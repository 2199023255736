import Component from '@glimmer/component';
import { action } from '@ember/object';
import { connect } from 'ember-redux';
import { getDefaultElementCategory } from '../../../selectors/invention-ui';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';

const dispatchToActions = {};

const stateToComputed = (state) => ({
  defaultElementCategory: getDefaultElementCategory(state)
});

class InventionGraphCreateOptions extends Component {
  @service data;

  get styleNamespace() {
    return podNames['invention-graph-create-options'];
  }

  get classNames() {
    let classNames = [this.styleNamespace];
    return classNames.join(' ');
  }

  @action
  onSelectCategory(category) {
    this.data.setDefaultElementCategory(category)
  }
}

export default connect(
  stateToComputed,
  dispatchToActions
)(InventionGraphCreateOptions);