import Component from '@glimmer/component';
import FeatureCategories from '../../../constants/types/feature-categories';
import { action } from '@ember/object';
import { addFeature } from '../../../actions/feature';
import { batchGroupBy } from '../../../utils/redux';
import { connect } from 'ember-redux';
import { getElementVersion } from '../../../selectors/element-version';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import uuid from 'uuid/v4';

const stateToComputed = null;

const dispatchToActions = {};

class FeaturePrompts extends Component {
  @service data;
  @tracked isActive = false;

  get styleNamespace() {
    return podNames['feature-prompts'];
  }

  get classNames() {
    let classNames = [this.styleNamespace, 'feature-prompts'];
    if (this.isActive) classNames.push('is-active');
    return classNames.join(' ');
  }

  get listType() {
    return this.args.listType || 'element-features';
  }

  get categories() {
    return FeatureCategories;
  }

  @action
  didInsert() {
    this.isActive = true;
  }

  @action
  addFeature(type) {
    const state = this.redux.getState();
    const elementVersion = getElementVersion(state, this.args.elementVersionId);
    const featureId = uuid();
    const featuresList = [...elementVersion.featuresList, featureId];
    const featureAttributes = {
      element: elementVersion.element,
      elementVersion: this.args.elementVersionId,
      type,
    };

    batchGroupBy.start();

    // add the feature
    this.redux.store.dispatch(addFeature(featureAttributes, featureId));

    // add it to the element version
    this.data.updateElementVersion(this.args.elementVersionId, { featuresList });

    batchGroupBy.end();
  }
}

export default connect(stateToComputed, dispatchToActions)(FeaturePrompts);
