import Component from '@glimmer/component';
import podNames from 'ember-component-css/pod-names';

class UiDashboardPlaceholder extends Component {
  get styleNamespace() {
    return podNames['ui-dashboard-placeholder'];
  }

  get classNames() {
    let classNames = [this.styleNamespace, 'ui-dashboard-placeholder'];
    return classNames.join(' ');
  }
}

export default UiDashboardPlaceholder;
