import { action, computed } from '@ember/object';

import Component from '@glimmer/component';
import { connect } from 'ember-redux';
import { getAsset } from '../../../selectors/asset';
import { htmlSafe } from '@ember/string';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

const stateToComputed = (state, attrs) => ({
  asset: getAsset(state, attrs.assetId),
});

const dispatchToActions = {};

class AssetItem extends Component {
  @service assets;

  @tracked blobUrl;

  get styleNamespace() {
    return podNames['asset-item'];
  }

  get classNames() {
    let classNames = [this.styleNamespace, 'asset-item'];
    if (this.args.isContain) classNames.push('is-contain');
    return classNames.join(' ');
  }

  @computed('blobUrl')
  get backgroundImageStyle() {
    return htmlSafe(`background-image: url(${this.blobUrl})`); 
  }

  @action
  onClick() {
    if (this.args.onClick) this.args.onClick(this.args.assetId);
  }

  @action
  async setBlobUrl() {
    if (this.args.assetId && this.asset) {
      const blobUrl = await this.assets.getAssetUrl(this.args.assetId);
      this.blobUrl = blobUrl;
    }
  }

}

export default connect(stateToComputed, dispatchToActions)(AssetItem);
