

export const TEAL_100 = 'hsl(182,70%,15%)';
export const TEAL_200 = 'hsl(182,70%,25%)';
export const TEAL_300 = 'hsl(182,65%,35%)';
export const TEAL_400 = 'hsl(182,65%,45%)';
export const TEAL_500 = 'hsl(182,55%,68%)';
export const TEAL_600 = 'hsl(182,52%,81%)';
export const TEAL_700 = 'hsl(182,50%,91%)';
export const TEAL_OPAQUE = 'rgb(89,181,187, 0.75)';
export const TEAL_OPAQUER = 'rgb(89,181,187, 0.5)';
export const TEAL_OPAQUEST = 'rgb(89,181,187, 0.15)';

export const PURPLE_100 = 'hsl(274, 65%, 15%)';
export const PURPLE_200 = 'hsl(274, 60%, 25%)';
export const PURPLE_300 = 'hsl(274, 55%, 35%)';
export const PURPLE_400 = 'hsl(274, 50%, 50%)';
export const PURPLE_500 = 'hsl(274, 45%, 65%)';
export const PURPLE_600 = 'hsl(274, 45%, 82%)';
export const PURPLE_700 = 'hsl(274, 40%, 92%)';

export const GREEN_100 = 'hsl(94, 65%, 13%)';
export const GREEN_200 = 'hsl(94, 65%, 25%)';
export const GREEN_300 = 'hsl(94, 60%, 35%)';
export const GREEN_400 = 'hsl(94, 55%, 43%)';
export const GREEN_500 = 'hsl(94, 50%, 65%)';
export const GREEN_600 = 'hsl(94, 50%, 80%)';
export const GREEN_700 = 'hsl(94, 40%, 92%)';

export const BLUE_100 = 'hsl(213, 70%, 15%)';
export const BLUE_200 = 'hsl(213, 65%, 25%)';
export const BLUE_300 = 'hsl(213, 65%, 35%)';
export const BLUE_400 = 'hsl(213, 60%, 55%)';
export const BLUE_500 = 'hsl(213, 60%, 65%)';
export const BLUE_600 = 'hsl(213, 60%, 82%)';
export const BLUE_700 = 'hsl(213, 50%, 95%)';

export const BRICK_100 = 'hsl(16, 75%, 15%)';
export const BRICK_200 = 'hsl(16, 70%, 25%)';
export const BRICK_300 = 'hsl(16, 70%, 35%)';
export const BRICK_400 = 'hsl(16, 65%, 55%)';
export const BRICK_500 = 'hsl(16, 65%, 65%)';
export const BRICK_600 = 'hsl(16, 60%, 82%)';
export const BRICK_700 = 'hsl(16, 55%, 95%)';

export const PINK_100 = 'hsl(300, 85%, 15%)';
export const PINK_200 = 'hsl(300, 85%, 15%)';
export const PINK_300 = 'hsl(300, 80%, 35%)';
export const PINK_400 = 'hsl(300, 75%, 65%)';
export const PINK_500 = 'hsl(300, 75%, 78%)';
export const PINK_600 = 'hsl(300, 60%, 85%)';
export const PINK_700 = 'hsl(300, 50%, 95%)';

export const BLACK = 'hsl(182, 2%, 0%)';
export const GRAY_100 = 'hsl(182, 2%, 25%)';
export const GRAY_200 = 'hsl(182, 2%, 35%)';
export const GRAY_300 = 'hsl(182, 2%, 59%)';
export const GRAY_400 = 'hsl(182, 2%, 77%)';
export const GRAY_500 = 'hsl(182, 1%, 87%)';
export const GRAY_600 = 'hsl(182, 1%, 93%)';
export const GRAY_700 = 'hsl(182, 1%, 96%)';
export const WHITE = 'hsl(182, 2%, 100%)';
export const WHITE_OPAQUE = 'rgb(255, 255, 255, 0.75)';
export const WHITE_OPAQUER = 'rgb(255, 255, 255, 0.5)';
export const WHITE_OPAQUEST = 'rgb(255, 255, 255, 0.15)';

export const DARKBLUE_100 = 'hsl(213, 30%, 8%)';
export const DARKBLUE_200 = 'hsl(213, 28%, 13%)';
export const DARKBLUE_250 = 'hsl(213, 28%, 16%)';
export const DARKBLUE_300 = 'hsl(213, 24%, 19%)';
export const DARKBLUE_400 = 'hsl(213, 22%, 24%)';
export const DARKBLUE_500 = 'hsl(213, 20%, 30.3%)';
export const DARKBLUE_600 = 'hsl(213, 12%, 48%)';
export const DARKBLUE_700 = 'hsl(213, 12%, 60%)';
export const DARKBLUE_200_OPAQUE = 'rgba(24, 32, 42, 0.75)';
export const DARKBLUE_200_OPAQUER = 'rgba(24, 32, 42, 0.5)';
export const DARKBLUE_200_OPAQUEST = 'rgba(24, 32, 42, 0.15)';
export const DARKBLUE_100_OPAQUE = 'rgba(15, 20, 26, 0.75)';
export const DARKBLUE_100_OPAQUER = 'rgba(15, 20, 26, 0.5)';
export const DARKBLUE_100_OPAQUEST = 'rgba(15, 20, 26, 0.15)';


export const BODY_COLOR = '#000';
export const BODY_COLOR_LIGHT = GRAY_300;
export const BORDER_COLOR_LIGHT = GRAY_400;
export const BODY_COLOR_DARK = '#FFF';
export const BODY_COLOR_LIGHT_DARK = DARKBLUE_700;
export const BORDER_COLOR_LIGHT_DARK = DARKBLUE_700;

export const ELEMENT_NODE_GREY_FILL = GRAY_500;
export const ELEMENT_NODE_GREY_FILL_LIGHT = GRAY_600;
export const ELEMENT_NODE_GREY_STROKE = GRAY_300;
export const ELEMENT_NODE_GREY_STROKE_LIGHT = GRAY_400;
export const ELEMENT_NODE_GREY_COLOR = BLACK;


export const ELEMENT_NODE_SELECTED_CHILD_STROKE = GRAY_500;
export const ELEMENT_NODE_SELECTED_CHILD_STROKE_DARK = DARKBLUE_400;

export const ELEMENT_NODE_NOVELTY_FILL = PINK_600;
export const ELEMENT_NODE_NOVELTY_FILL_LIGHT = PINK_700;
export const ELEMENT_NODE_NOVELTY_STROKE = PINK_400;
export const ELEMENT_NODE_NOVELTY_STROKE_LIGHT = PINK_500;
export const ELEMENT_NODE_NOVELTY_COLOR = BLACK;
export const NOVELTY_EDGE_STROKE = PINK_400;

export const WARNING_NODE_FILL = '#fbe99e';
export const WARNING_NODE_STROKE = '#c8a109';

export const SELECTED_NODE_STROKE = TEAL_500;
export const SELECTED_NODE_STROKE_DARK = TEAL_400;
export const ELEMENT_NODE_FILL = TEAL_600;
export const ELEMENT_NODE_ROOT_FILL = TEAL_500;
export const ELEMENT_NODE_ROOT_STROKE = TEAL_400;
export const ELEMENT_NODE_FILL_LIGHT = TEAL_700;
export const ELEMENT_NODE_FILL_LIGHTER = TEAL_700;
export const ELEMENT_NODE_STROKE = TEAL_400;
export const ELEMENT_NODE_COLOR = BLACK;
export const ELEMENT_NODE_HEADER_COLOR = TEAL_200;
export const ELEMENT_EDGE_STROKE = TEAL_400;
export const ELEMENT_COLLAPSED_NODE_STROKE = TEAL_500;
export const ELEMENT_COLLAPSED_NODE_FILL = TEAL_600;

export const ELEMENT_VERSION_NODE_FILL = '#D6C1E8';
export const ELEMENT_VERSION_NODE_FILL_LIGHT = '#ecdff7';
export const ELEMENT_VERSION_NODE_FILL_LIGHTER = '#f2edf9';
export const ELEMENT_VERSION_NODE_STROKE = '#9967c5';
export const ELEMENT_VERSION_NODE_COLOR = BLACK;
export const ELEMENT_VERSION_EDGE_STROKE = '#9967c5';
export const ELEMENT_VERSION_COLLAPSED_NODE_STROKE = '#E1CEF1';
export const ELEMENT_VERSION_COLLAPSED_NODE_FILL = '#CEAAED';
export const ELEMENT_VERSION_NODE_HEADER_COLOR = '#9967c5';


// $process: #688100;
// $process-light: #EAFBA1;
// $article: #3A3693;
// $article-light: #CFCDF1;
// $machine: #9967c5;
// $machine-light: #ecdff7;

export const BLACK_AND_WHITE_FILL = '#FFFFFF';
export const BLACK_AND_WHITE_STROKE = '#000000';
export const BLACK_AND_WHITE_COLOR = '#000000';

export const PLACEHOLDER_FILL = GRAY_600;
export const PLACEHOLDER_STROKE = GRAY_300;
export const PLACEHOLDER_TEXT = GRAY_200;

export const PLACEHOLDER_FILL_DARK = DARKBLUE_250;
export const PLACEHOLDER_STROKE_DARK = DARKBLUE_600;
export const PLACEHOLDER_TEXT_DARK = DARKBLUE_600;

export const PRIMARY_COLOR = TEAL_400;
export const PRIMARY_COLOR_OPAQUE = TEAL_OPAQUE;
export const PRIMARY_COLOR_OPAQUER = TEAL_OPAQUER;
export const PRIMARY_COLOR_OPAQUEST = TEAL_OPAQUEST;

export const ARTBOARD_FILL = WHITE;
export const ARTBOARD_STROKE = GRAY_500;
export const MARKER_STROKE = BLACK;
export const MARKER_SELECTED_STROKE = TEAL_400;
export const MARKER_OPAQUE_STROKE = TEAL_OPAQUER;
export const MARKER_POINT_FILL = WHITE_OPAQUER;
export const MARKER_POINT_SELECTED_FILL = WHITE_OPAQUER;
export const MARKER_TEXT_COLOR = BLACK;
export const MARKER_SELECTED_TEXT_COLOR = TEAL_300;
export const MARKER_LABEL_FILL = GRAY_700;
export const MARKER_NUMBER_FILL = WHITE;
export const MARKER_SELECTED_NUMBER_FILL = TEAL_700;
export const MARKER_SELECTED_LABEL_FILL = TEAL_600;
export const MARKER_NOVEL_TEXT_COLOR = PINK_300;
export const MARKER_NOVEL_LABEL_FILL = TEAL_200;

export const HIGHLIGHT_FILL = TEAL_OPAQUEST;
export const HIGHLIGHT_STROKE = TEAL_400;

export const ELEMENT_VERSION_CATEGORY_MACHINE_DARK = PURPLE_300;
export const ELEMENT_VERSION_CATEGORY_MACHINE = PURPLE_400;
export const ELEMENT_VERSION_CATEGORY_MACHINE_LIGHT = PURPLE_600;
export const ELEMENT_VERSION_CATEGORY_MACHINE_LIGHTER = PURPLE_700;

export const ELEMENT_VERSION_CATEGORY_PROCESS_DARK = GREEN_300;
export const ELEMENT_VERSION_CATEGORY_PROCESS = GREEN_400;
export const ELEMENT_VERSION_CATEGORY_PROCESS_LIGHT = GREEN_600;
export const ELEMENT_VERSION_CATEGORY_PROCESS_LIGHTER = GREEN_700;

export const ELEMENT_VERSION_CATEGORY_ARTICLE_DARK = BLUE_300;
export const ELEMENT_VERSION_CATEGORY_ARTICLE = BLUE_400;
export const ELEMENT_VERSION_CATEGORY_ARTICLE_LIGHT = BLUE_600;
export const ELEMENT_VERSION_CATEGORY_ARTICLE_LIGHTER = BLUE_700;

export const ELEMENT_VERSION_CATEGORY_COMPOSITION_DARK = BRICK_300;
export const ELEMENT_VERSION_CATEGORY_COMPOSITION = BRICK_400;
export const ELEMENT_VERSION_CATEGORY_COMPOSITION_LIGHT = BRICK_600;
export const ELEMENT_VERSION_CATEGORY_COMPOSITION_LIGHTER = BRICK_700;


export const ELEMENT_VERSION_CATEGORY_MACHINE_SELECTED_DARK = PURPLE_400;
export const ELEMENT_VERSION_CATEGORY_MACHINE_SELECTED = PURPLE_600;
export const ELEMENT_VERSION_CATEGORY_PROCESS_SELECTED_DARK = GREEN_400;
export const ELEMENT_VERSION_CATEGORY_PROCESS_SELECTED = GREEN_600;
export const ELEMENT_VERSION_CATEGORY_ARTICLE_SELECTED_DARK = BLUE_400;
export const ELEMENT_VERSION_CATEGORY_ARTICLE_SELECTED = BLUE_600;
export const ELEMENT_VERSION_CATEGORY_COMPOSITION_SELECTED_DARK = BRICK_400;
export const ELEMENT_VERSION_CATEGORY_COMPOSITION_SELECTED = BRICK_600;

export const EDGE_CATEGORY_ARTICLE = BLUE_500;
export const EDGE_CATEGORY_ARTICLE_DARK = BLUE_400;
export const EDGE_CATEGORY_COMPOSITION = BRICK_500;
export const EDGE_CATEGORY_COMPOSITION_DARK = BRICK_400;
export const EDGE_CATEGORY_MACHINE = PURPLE_500;
export const EDGE_CATEGORY_MACHINE_DARK = PURPLE_400;
export const EDGE_CATEGORY_PROCESS = GREEN_500;
export const EDGE_CATEGORY_PROCESS_DARK = GREEN_400;

export const TERM_NODE_FILL = '#dddddd';
export const TERM_NODE_FILL_LIGHT = '#e9e9e9';
export const TERM_NODE_STROKE = '#999999';
export const TERM_EDGE_STROKE = '#999999';
export const TERM_COLLAPSED_NODE_FILL = '#e9e9e9';
export const TERM_COLLAPSED_NODE_STROKE = '#777777';
export const PRIOR_ART_EDGE_STROKE = '#9967c5';
export const PRIOR_ART_NODE_FILL = '#D6C1E8';
export const PRIOR_ART_NODE_STROKE = '#9967c5';
export const PRIOR_ART_COLLAPSED_NODE_FILL = '#E1CEF1';
export const PRIOR_ART_COLLAPSED_NODE_STROKE = '#CEAAED';



export const METHOD_NODE_FILL = '#FFFFFF';
export const METHOD_NODE_STROKE = '#000000';
export const METHOD_NODE_STROKE_DISCONNECTED = GRAY_400;
export const METHOD_NODE_COLOR = '#000000';