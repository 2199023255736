import Component from '@ember/component';
import { computed } from '@ember/object';
import { htmlSafe } from '@ember/string';

const UiDropdownItem = Component.extend({
  classNames: ['dropdown-item'],
  tagName: 'a',
  dd: null,
  classNameBindings: [
    'disabled',
    'hasNestedMenu',
    'isLarge',
    'isColorLight',
  ],
  label: '',
  icon: null,
  iconType: null,
  disabled: false,
  isChecked: false,
  isColorLight: false,
  closeAfterClick: true,
  ariaLabel: '',
  shortcut: '',
  hasNestedMenu: false,
  universalShortcut: computed('shortcut', function() {
    let shortcut = this.shortcut;
    shortcut = shortcut.replace(/\+/g, '');
    shortcut = shortcut.replace('Shift', '&#8679;');
    shortcut = shortcut.replace('Cmd', '⌘');
    return htmlSafe(shortcut);
  }),
  htmlLabel: computed('label', function() {
    return htmlSafe(this.label);
  }),
  // ariaDisabled: computed('disabled', function() {
  //   return String(this.disabled);
  // })
  click() {
    if (this.onClick) {
      this.onClick()
    }
    if (this.closeAfterClick && this.dd) {
      this.dd.actions.close()
    }
  }
});

export default UiDropdownItem;
