import Component from '@glimmer/component';
import ENV from '../../../config/environment';
import { action } from '@ember/object';
import { debounce } from '@ember/runloop';
import { exists } from '../../../utils/general';
import { getNameExists } from '../../../selectors/invention';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

class UiModelAttrInput extends Component {
  @service applicationState;
  @service models;
  @service clipboard;
  @service redux;

  @tracked switched = false;
  @tracked _value = '';
  @tracked isActive = false;
  @tracked uniqueError;
  @tracked uniqueErrorName = '';

  _allowEmpty = true;
  _modelId = null;

  get styleNamespace() {
    return podNames['ui-model-attr-input'];
  }

  get classNames() {
    let classNames = [this.styleNamespace];
    if (this.args.isIndented) classNames.push('is-indented');
    if (this.args.isMarginless) classNames.push('is-marginless');
    return classNames.join(' ');
  }

  get inputType() {
    return this.args.inputType || 'text';
  }

  @action
  onInsert() {
    this._value = this.args.value;
    this._modelId = this.args.modelId;
    this._allowEmpty = exists(this.args.allowEmpty, true);
    this.uniqueError = null;
    this.uniqueErrorName = '';
  }

  @action
  onUpdateModelId() {
    if (this._modelId !== this.args.modelId) {
      this._modelId = this.args.modelId;
      this.switched = !this.switched;
      this.uniqueError = null;
      this.uniqueErrorName = '';
      this.updateValue();
    }
  }

  get model() {
    return (
      this.args.model &&
      this.models.findOrCreate(
        this.args.modelId,
        this.args.modelType,
        this.args.model
      )
    );
  }

  get inputTestId() {
    return this.args.testId && `${this.args.testId}-input`;
  }

  @action
  willDestroy() {
    this.isActive = false;
  }

  @action
  onUpdate() {
    // this.isActive = false;
    this.switched = !this.switched;
    this.updateValue();
  }

  updateValue() {
    this._value = this.args.value;
    this.models.update(this.args.modelId, { [this.args.modelAttr]: this.args.value })
  }

  isEqual(value1, value2) {
    return JSON.stringify(value1) === JSON.stringify(value2);
  }

  debounceUpdate(args) {
    const value = args.value;
    this.args.onUpdate(this.args.modelId, { [this.args.modelAttr]: value });
  }

  nameIsUnique(name) {
    const state = this.redux.store.getState();
    const nameExists = getNameExists(state, this.args.termId, name);
    return !nameExists;
  }

  @action
  onDebounceEdit(value) {
    const isntEmpty = this._allowEmpty ? true : value;
    const isUnique = this.args.enforceUnique ? this.nameIsUnique(value) : true;
    if (isntEmpty && !this.isEqual(value, this.args.value) && isUnique) {
      this.uniqueError = false;
      this.uniqueErrorName = value;
      if (this.model) {
        this.model.set(this.args.modelAttr, value);
      }
      debounce(this, this.debounceUpdate, { value }, ENV.DEBOUNCE_MS);
    } else if (!isUnique) {
      this.uniqueErrorName = value;
      this.uniqueError = true;
    }
  }

  @action
  onBlur() {
    this.isActive = false;
    if (this.args.onBlur) {
      this.args.onBlur();
    }
  }

  @action
  onFocus() {
    this.isActive = true;
    if (this.args.onFocus) {
      this.args.onFocus();
    }
  }

  @action
  onTributeElement(value) {
    this.args.onUpdate(this.args.modelId, { [this.args.modelAttr]: value });
    this.updateValue();
  }
}

export default UiModelAttrInput;
