import { action, computed } from '@ember/object';
import { getDrawing, getDrawingsList } from '../../../selectors/drawing';

import Component from '@glimmer/component';
import { connect } from 'ember-redux';
import moment from 'moment';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';
import { sort } from '@ember/object/computed';

const stateToComputed = (state) => ({
  drawingsList: getDrawingsList(state),
});
const dispatchToActions = {};

class DrawingSelectionModal extends Component {
  @service tracking;
  @service redux;

  get styleNamespace() {
    return podNames['drawing-selection-modal'];
  }

  get classNames() {
    let classNames = [this.styleNamespace, 'drawing-selection-modal'];
    return classNames.join(' ');
  }

  @computed('drawingsList.[]')
  get drawings() {
    const state = this.redux.getState();
    return this.drawingsList.map((drawingId) => {
      const drawing = getDrawing(state, drawingId);

      const timestamp = moment(drawing.createdAt).format('x');
      return {
        ...drawing,
        timestamp,
      };
    });
  }

  createdSort = ['timestamp:desc'];

  @sort('drawings', 'createdSort')
  sortedDrawings;

  @action
  didInsert() {
    this.tracking.trackEvent('opened_drawing_selection_modal');
  }

  @action
  hideModal() {
    if (this.args.onSetShowingDrawingSelectionModal) {
      this.args.onSetShowingDrawingSelectionModal(false);
    }
  }

  @action
  addDrawing() {
    if (this.args.onAddDrawing) {
      this.args.onAddDrawing();
    }
  }

  @action
  onSetActiveDrawing(drawingId) {
    if (this.args.onSetActiveDrawing) {
      this.args.onSetActiveDrawing(drawingId);
    }
  }
}

export default connect(
  stateToComputed,
  dispatchToActions
)(DrawingSelectionModal);
