import {
  ADD_ELEMENT_VERSION,
  REMOVE_COMPRISES_RELATIONSHIP,
  REMOVE_ELEMENT_VERSION,
  UPDATE_ELEMENT_VERSION,
} from './actionTypes';

import uuid from 'uuid/v4';

export const removeComprisesRelationship = (
  sourceElementVersionId,
  targetElementId,
  comprisesFeatureId,
  outcomeFeatureId
) => {
  const updatedAt = new Date();
  return {
    type: REMOVE_COMPRISES_RELATIONSHIP,
    payload: {
      sourceElementVersionId,
      targetElementId,
      comprisesFeatureId,
      outcomeFeatureId,
      updatedAt,
    },
  };
};

export const addElementVersion = (
  attributes,
  elementVersionId = uuid()
) => {
  
  return {
    type: ADD_ELEMENT_VERSION,
    payload: {
      elementVersionId,
      attributes,
    },
  };
};

export const updateElementVersion = (elementVersionId, attributes) => {
  return {
    type: UPDATE_ELEMENT_VERSION,
    payload: {
      elementVersionId,
      attributes,
    },
  };
};

export const removeElementVersion = (
  elementVersionId,
) => {
  return {
    type: REMOVE_ELEMENT_VERSION,
    payload: {
      elementVersionId,
    },
  };
};
