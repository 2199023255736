import { action, computed } from '@ember/object';
import {
  defaultArtboardHeight,
  defaultArtboardWidth,
} from '../../../constants/settings';
import {
  getDefaultMethodNodeType,
  getInventionUi,
  getPreviewMode,
  getSelectedElementVersions,
  getSelectedElements,
  getSelectedMethodEdgePoints,
  getSelectedMethodEdges,
  getSelectedTerms,
  getContextActive,
  getContextWidth,
  getShowingExplorer,
  getExplorerWidth
} from '../../../selectors/invention-ui';
import { getMethod, getMethodStartNodeId } from '../../../selectors/method';
import {
  getMethodNode,
  getMethodNodeLabel,
} from '../../../selectors/method-node';
import { keyResponder, onKey } from 'ember-keyboard';

import Component from '@glimmer/component';
import ENV from '../../../config/environment';
import Konva from 'konva';
import { alias } from '@ember/object/computed';
import { connect } from 'ember-redux';
import { debounce } from '@ember/runloop';
import { getLayout } from '../../../utils/method-layout';
import { getMethodEdge } from '../../../selectors/method-edge';
import { getNodeHeight } from '../../../utils/method';
import { getPreviewNumbering } from '../../../selectors/settings';
import { htmlSafe } from '@ember/string';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { union } from '@ember/object/computed';
import uuid from 'uuid/v4';

const dispatchToActions = {};

const stateToComputed = (state, attrs) => ({
  method: getMethod(state, attrs.methodId),
  ui: getInventionUi(state),
  selectedTerms: getSelectedTerms(state),
  selectedElements: getSelectedElements(state),
  selectedElementVersions: getSelectedElementVersions(state),
  // defaultMethodNodeCategory: ...
  previewMode: getPreviewMode(state),
  methodStartNodeId: getMethodStartNodeId(state, attrs.methodId),
  selectedMethodEdgePoints: getSelectedMethodEdgePoints(state),
  selectedMethodEdges: getSelectedMethodEdges(state),
  defaultMethodNodeType: getDefaultMethodNodeType(state),
  previewNumbering: getPreviewNumbering(state),
  contextActive: getContextActive(state),
  contextWidth: getContextWidth(state),
  showingExplorer: getShowingExplorer(state),
  explorerWidth: getExplorerWidth(state),
});

@keyResponder
class MethodEditorKonva extends Component {
  @service redux;
  @service store;
  @service models;
  @service assets;
  @service data;
  @service testing;
  @service applicationState;
  @service tracking;

  defaultArtboardWidth = defaultArtboardWidth;
  defaultArtboardHeight = defaultArtboardHeight;

  containerDomElementId = `canvas-container-${uuid()}`;
  verticalPadding = 19.5;
  nodePadding = 25;
  tweens = [];

  minScale = 0.05;
  maxScale = 1.05;

  @tracked stepCreateMode = false;
  @tracked edgeCreateMode = false;
  // @tracked bulkCreateMode = false;

  @tracked creatingSourceId = null;

  @tracked builtMethodNodeModels = false;
  @tracked didFirstRender = false;
  @tracked onCanvas = false;
  @tracked createMode = false;
  @tracked stage;
  @tracked layer;
  @tracked mouseX;
  @tracked mouseY;
  @tracked stageMouseX;
  @tracked stageMouseY;
  @tracked stageScale;
  @tracked stageX;
  @tracked stageY;
  @tracked stageWidth = 500;
  @tracked stageHeight = 500;
  @tracked visibleAreaIndex = 0;

  @tracked selectedBulkCreateItemId;
  @tracked bulkCreateItems = {
    ids: [],
    entities: {},
  };

  willDestroy() {
    this.stage.off('dragend');
    this.stage.destroy();
  }

  get styleNamespace() {
    return podNames['method'];
  }

  @computed(
    'handToolMode',
    'stepCreateMode',
    'edgeCreateMode',
    'defaultMethodNodeType',
    'styleNamespace'
  )
  get classNames() {
    let classNames = ['method-editor', this.styleNamespace];
    if (this.handToolMode) classNames.push('hand-tool');
    if (this.stepCreateMode) classNames.push('step-create-mode');
    if (this.edgeCreateMode) classNames.push('edge-create-mode');
    // if (this.bulkCreateMode) classNames.push('bulk-create-mode');
    if (this.defaultMethodNodeType) classNames.push(this.defaultMethodNodeType);
    return classNames.join(' ');
  }

  @alias('methodModel.disconnectedNodesList') disconnectedNodesList;
  @alias('methodModel.disconnectedEdgesList') disconnectedEdgesList;

  @computed('args.methodId')
  get methodModel() {
    return this.store.peekRecord('method', this.args.methodId);
  }

  get bulkCreateItemsList() {
    return (this.bulkCreateItems && this.bulkCreateItems.ids) || [];
  }

  // @action
  // onResetBulkCreateItems() {
  //   this.bulkCreateItems = {
  //     ids: [],
  //     entities: {},
  //   };
  //   this.onAddBulkCreateItem();
  // }

  // @action
  // onAddBulkCreateItem() {
  //   const bulkCreateItemId = uuid();
  //   const bulkCreateItem = {
  //     id: bulkCreateItemId,
  //     category: this.defaultMethodNodeCategory,
  //     name: '',
  //     resultId: null,
  //     resultType: null,
  //     resultElementVersion: null,
  //   };
  //   this.bulkCreateItems = {
  //     ids: [...this.bulkCreateItems.ids, bulkCreateItemId],
  //     entities: {
  //       ...this.bulkCreateItems.entities,
  //       [bulkCreateItemId]: bulkCreateItem,
  //     },
  //   };
  //   this.selectedBulkCreateItemId = bulkCreateItemId;
  // }

  // @action
  // onRemoveBulkCreateItem() {
  //   const bulkCreateItemId = this.selectedBulkCreateItemId;
  //   const removedIndex = this.bulkCreateItemsList.findIndex(
  //     (id) => bulkCreateItemId === id
  //   );

  //   let selectedItemId;

  //   if (this.bulkCreateItemsList.length > 1) {
  //     if (removedIndex === 0) {
  //       selectedItemId = this.bulkCreateItemsList[0];
  //     } else {
  //       selectedItemId = this.bulkCreateItemsList[removedIndex - 1];
  //     }
  //   }

  //   this.bulkCreateItems = {
  //     ids: this.bulkCreateItems.ids.filter((id) => bulkCreateItemId !== id),
  //     entities: omit(this.bulkCreateItems.entities, [bulkCreateItemId]),
  //   };

  //   this.selectedBulkCreateItemId = selectedItemId;
  // }

  // @action
  // onUpdateBulkCreateItem(bulkCreateItemId, attributes) {
  //   this.bulkCreateItems = {
  //     ...this.bulkCreateItems,
  //     entities: {
  //       ...this.bulkCreateItems.entities,
  //       [bulkCreateItemId]: {
  //         ...this.bulkCreateItems.entities[bulkCreateItemId],
  //         ...attributes,
  //       },
  //     },
  //   };
  // }

  // @action
  // onAddBulkCreateItems() {
  //   this.createMethodSteps(true);
  //   return this.closeCreateMode();
  // }

  // @action
  // onSelectBulkCreateItem(bulkCreateItemId) {
  //   this.selectedBulkCreateItemId = bulkCreateItemId;
  // }

  // resetBulkCreateItems() {
  //   this.bulkCreateItems = {
  //     ids: [],
  //     entities: {},
  //   };
  // }

  buildMethodModels() {
    const state = this.redux.getState();
    const startNodeId = getMethodStartNodeId(state, this.args.methodId);
    this.methodNodesList.forEach((methodNodeId) => {
      const methodNode = getMethodNode(state, methodNodeId);
      const model = this.models.findOrCreate(
        methodNodeId,
        'method-node',
        methodNode
      );
      const label = getMethodNodeLabel(
        state,
        methodNodeId,
        null,
        `label-${methodNodeId}`,
        startNodeId === methodNodeId
      );
      const height = getNodeHeight(label, methodNode.width);
      model.set('height', height);
      // const textValue =
    });
  }

  updateMethodModels() {
    const state = this.redux.getState();
    this.methodNodesList.forEach((methodNodeId) => {
      const methodNode = getMethodNode(state, methodNodeId);
      const { x, y, width, height } = methodNode;
      const model = this.models.findOrCreate(
        methodNodeId,
        'method-node',
        methodNode
      );
      model.setProperties({ x, y, width, height });
    });
  }

  @computed('method.methodNodesList', 'args.methodId')
  get methodNodesList() {
    return (this.method && this.method.methodNodesList) || [];
  }

  @computed('method.methodEdgesList', 'args.methodId')
  get methodEdgesList() {
    return this.method && this.method.methodEdgesList;
  }

  @computed('ui.selectedMethodNodes')
  get selectedMethodNodes() {
    return this.ui && this.ui.selectedMethodNodes;
  }

  @computed('selectedMethodNodes.length')
  get selectedMethodNodeId() {
    return this.selectedMethodNodes.length === 1 && this.selectedMethodNodes[0];
  }

  @computed('selectedMethodNodeId', 'allMethodNodes')
  get selectedMethodNode() {
    const state = this.redux.getState();
    return (
      this.selectedMethodNodeId &&
      getMethodNode(state, this.selectedMethodNodeId)
    );
  }

  @computed('creatingSourceId', 'stage')
  get creatingSource() {
    let source;
    if (this.creatingSourceId) {
      const methodNode = this.stage.findOne(`#${this.creatingSourceId}`);
      const labelNode = this.stage.findOne(`#${this.creatingSourceId}-label`);
      if (methodNode && labelNode) {
        source = {
          id: this.creatingSourceId,
          x: methodNode.x() + labelNode.width() / 2,
          y: methodNode.y() + labelNode.height() / 2,
          width: labelNode.width(),
          height: labelNode.height(),
        };
      }
    }
    return source;
  }

  @computed('selectedMethodNode')
  get notSelectMethodNode() {
    return !this.selectedMethodNode;
  }

  @computed('selectedMethodEdges.length')
  get selectedMethodEdgeId() {
    return this.selectedMethodEdges.length === 1 && this.selectedMethodEdges[0];
  }

  @computed('selectedMethodEdgeId', 'allMethodEdges')
  get selectedMethodEdge() {
    const state = this.redux.getState();
    return (
      this.selectedMethodEdgeId &&
      getMethodEdge(state, this.selectedMethodEdgeId)
    );
  }

  @computed('selectedMethodEdgePoints.length')
  get selectedMethodEdgePointId() {
    return (
      this.selectedMethodEdgePoints.length === 1 &&
      this.selectedMethodEdgePoints[0]
    );
  }

  @union(
    'selectedMethodNodes',
    'selectedMethodEdges',
    'selectedMethodEdgePoints'
  )
  selectedItems;

  // eslint-disable-next-line ember/require-computed-property-dependencies
  @computed('selectedItems.[]')
  get selectedNodes() {
    return (
      this.selectedItems &&
      this.selectedItems.map((itemId) => {
        return this.stage.findOne(`#${itemId}`);
      })
    );
  }

  @computed('selectedItems.length')
  get isMultiselected() {
    return this.selectedItems && this.selectedItems.length > 1;
  }

  @computed('selectedMethodNodes.[]')
  get selectedMethodNodeModels() {
    return this.selectedMethodNodes.map((id) => this.models.find(id));
  }

  @computed('selectedMethodEdgePoints.[]')
  get selectedMethodEdgePointModels() {
    return this.selectedMethodEdgePoints.map((id) => this.models.find(id));
  }

  @computed('method.orientation')
  get orientation() {
    return this.method && this.method.orientation;
  }

  @computed('applicationState.cmdKeyDown')
  get cmdKeyDown() {
    return this.applicationState.cmdKeyDown;
  }

  @computed('applicationState.shiftKeyDown')
  get shiftKeyDown() {
    return this.applicationState.shiftKeyDown;
  }

  @computed('shiftKeyDown')
  get multiSelectMode() {
    return this.shiftKeyDown;
  }

  get handToolMode() {
    return this.spacebarKeyDown;
  }

  @computed('domElementId')
  get artboardMaskId() {
    return `artboard-mask-${this.domElementId}`;
  }

  @computed('artboardWidth')
  get artboardX() {
    return (-1 * this.artboardWidth) / 2;
  }

  @computed('artboardHeight')
  get artboardY() {
    return (-1 * this.artboardHeight) / 2;
  }

  @computed('orientation', 'defaultArtboardWidth', 'defaultArtboardHeight')
  get artboardWidth() {
    return this.orientation === 'portrait'
      ? this.defaultArtboardWidth
      : this.defaultArtboardHeight;
  }

  @computed('orientation', 'defaultArtboardWidth', 'defaultArtboardHeight')
  get artboardHeight() {
    return this.orientation === 'portrait'
      ? this.defaultArtboardHeight
      : this.defaultArtboardWidth;
  }

  @computed('artboardX', 'artboardY', 'domElementId', 'x', 'y')
  get artboardOffset() {
    // const artboardOffset = $(`#artboard${this.domElementId}`).offset();
    // const graphOffset = $(`#method-canvas-${this.domElementId}`).offset();

    // return {
    //   x: artboardOffset.left - graphOffset.left,
    //   y: artboardOffset.top - graphOffset.top,
    // };phOffset = $(`#method-canvas-${this.domElementId}`).offset();

    return {
      x: 0,
      y: 0,
    };
  }

  @computed('artboardOffset.{x,y}')
  get figureTitleStyle() {
    const top = this.artboardOffset.y;
    const left = this.artboardOffset.x;
    return htmlSafe(`top: ${top}px; left: ${left}px;`);
  }

  @computed('highlightPoints.[]', 'graphMouseX', 'graphMouseY')
  get highlightPointsString() {
    let highlightPointsString = '';
    this.highlightPoints.forEach((point) => {
      highlightPointsString = highlightPointsString + `${point.x},${point.y} `;
    });
    return highlightPointsString + `${this.graphMouseX},${this.graphMouseY} `;
  }

  @computed('selectedElements.[]')
  get selectedElement() {
    return this.selectedElements.length === 1 && this.selectedElements[0];
  }

  @onKey('Escape', { event: 'keydown' })
  handleEscDown(keyboardEvent) {
    keyboardEvent.preventDefault();
    this.closeCreateMode();
  }
  f;
  @onKey('Space', { event: 'keydown' })
  handleSpacebarDown(/* keyboardEvent */) {
    this.spacebarKeyDown = true;
  }

  @onKey('Space', { event: 'keyup' })
  handleSpacebarUp(/* keyboardEvent */) {
    this.spacebarKeyDown = false;
  }

  fitStageToContainer() {
    const stage = this.stage;
    const container = document.getElementById(this.containerDomElementId);
    const width = container.offsetWidth;
    const height = container.offsetHeight;
    stage.width(width);
    stage.height(height);
    this.stageWidth = width;
    this.stageHeight = height;
    stage.batchDraw();
  }

  handleStageDragEnd() {
    // debounce(this, this.updateTreeCoordinates, 500);
    this.tracking.trackEvent('method_panned');
  }

  handleStageDragMove() {
    this.stageX = this.stage.x();
    this.stageY = this.stage.y();
  }

  handleWheel(e) {
    e.evt.preventDefault();
    const stage = e.target.getStage();
    const oldScale = stage.scaleX();
    const mousePointTo = {
      x: stage.getPointerPosition().x / oldScale - stage.x() / oldScale,
      y: stage.getPointerPosition().y / oldScale - stage.y() / oldScale,
    };
    const deltaY = e.evt.deltaY;
    const scaleBy = 1.17;
    let newScale = deltaY < 0 ? oldScale * scaleBy : oldScale / scaleBy;

    // constrain
    if (newScale > this.maxScale) newScale = this.maxScale;
    if (newScale < this.minScale) newScale = this.minScale;

    const newPos = {
      x: stage.getPointerPosition().x - mousePointTo.x * newScale,
      y: stage.getPointerPosition().y - mousePointTo.y * newScale,
    };
    stage.scale({ x: newScale, y: newScale });
    stage.position(newPos);
    this.stageScale = newScale;
    this.stageX = newPos.x;
    this.stageY = newPos.y;
    stage.batchDraw();
    // debounce(this, this.updateTreeCoordinates, 500);
  }

  handleMouseup(/*event*/) {
    // if (this.actionMode) {
    //   if (
    //     this.hoveringElementId &&
    //     this.hoveringElementId !== this.creatingElementId
    //   ) {
    //     this.createModeClickElement(this.hoveringElementId);
    //   } else if (
    //     this.hoveringElementVersionId &&
    //     this.hoveringElementVersionId !== this.creatingElementVersionId
    //   ) {
    //     this.createModeClickElementVersion(this.hoveringElementVersionId);
    //   } else if (!this.hoveringElementId && !this.hoveringElementVersionId) {
    //     this.createFromBackground(event);
    //   }
    // }
  }

  handleBackgroundClick(/*event*/) {
    if (this.createMode || this.cmdKeyDown) {
      // this.createFromBackground(event);
    } else {
      this.deselectAll();
    }
  }

  createMethodNode() {
    const { x, y } = this.getPointerPosition();
    const type = this.defaultMethodNodeType;
    const parentElementVersionId = this.method.elementVersion;
    this.args.onAddMethodNode(
      { type, x, y },
      this.args.methodId,
      parentElementVersionId
    );

    // let width = 280;
    // let height = 70;

    // x = x - width / 2;
    // y = y - height / 2;

    // const methodNodeId = uuid();

    // const attributes = {
    //   x,
    //   y,
    //   width,
    //   height,
    //   type,
    // };

    // batchGroupBy.start();

    // this.data.addMethodNode(attributes, this.args.methodId, methodNodeId);

    // this.redux.store.dispatch(selectMethodNode(methodNodeId));

    // this.data.updateGraph();

    // batchGroupBy.end();

    // getPointerPosition

    this.tracking.trackEvent('method_created_method_node');
  }

  getPointerPosition() {
    const stage = this.stage;
    const pointerPos = stage.getPointerPosition();
    const scale = stage.scaleX();
    const x = pointerPos.x / scale - stage.x() / scale;
    const y = pointerPos.y / scale - stage.y() / scale;
    return { x, y };
  }

  createMethodNodes(center = false) {
    const pointerPos = this.getPointerPosition();
    const x = center ? 0 : pointerPos.x;
    const y = center ? 0 : pointerPos.y;
    const methodNodesData = [];

    this.bulkCreateItemsList.forEach((bulkCreateItemId, index) => {
      const bulkCreateItem = this.bulkCreateItems.entities[bulkCreateItemId];
      // TODO: better to use voronoi tessellation to avoid overlap?
      const randomX = index ? Math.floor(x + (Math.random() * 150 - 75)) : x;
      const randomY = index ? Math.floor(y + (Math.random() * 150 - 75)) : y;
      const attributes = {
        method: this.args.methodId,
        startX: randomX,
        startY: randomY,
        midX: randomX,
        midY: randomY,
        endX: randomX,
        endY: randomY,
        type: bulkCreateItem.category === 'term' ? 'term' : 'element',
        category: bulkCreateItem.category,
      };

      if (
        bulkCreateItem &&
        bulkCreateItem.resultId &&
        bulkCreateItem.resultType === 'term'
      ) {
        attributes['term'] = bulkCreateItem.resultId;
      }

      if (
        bulkCreateItem &&
        bulkCreateItem.resultId &&
        bulkCreateItem.resultType === 'element'
      ) {
        attributes['element'] = bulkCreateItem.resultId;
        attributes['elementVersion'] = bulkCreateItem.resultElementVersion;
        attributes['category'] = bulkCreateItem.category;
      }

      if (bulkCreateItem && !bulkCreateItem.resultId && bulkCreateItem.name) {
        attributes.name = bulkCreateItem.name;
      }

      methodNodesData.push(attributes);
    });
    this.args.onCreateMethodNodes(this.args.methodId, methodNodesData);
  }

  closeCreateMode() {
    this.stepCreateMode = false;
    // this.bulkCreateMode = false;
    this.edgeCreateMode = false;
    this.creatingSourceId = null;
  }

  deselectAll() {
    if (
      this.selectedItems.length ||
      this.selectedTerm ||
      this.selectedElement ||
      this.selectedElementVersion
    ) {
      this.args.onDeselectMethodItems(this.args.methodId);
      this.tracking.trackEvent('method_deselected_all');
    }
  }

  findDuplicateMethodEdge(source, target) {
    const state = this.redux.getState();
    return this.methodEdgesList.find((methodEdgeId) => {
      const methodEdge = getMethodEdge(state, methodEdgeId);
      return methodEdge.source === source && methodEdge.target === target;
    });
  }

  createMethodEdge(sourceId, targetId) {
    const targetIsStartNode = targetId === this.methodStartNodeId;
    const isDuplicate = this.findDuplicateMethodEdge(sourceId, targetId);
    const cantCreate = isDuplicate || targetIsStartNode;

    if (cantCreate) {
      return this.closeCreateMode();
    }

    const state = this.redux.getState();
    const sourceMethodNode = getMethodNode(state, sourceId);
    const targetMethodNode = getMethodNode(state, targetId);
    const sourcePosition =
      targetMethodNode.y >= sourceMethodNode.y ? 'SOUTH' : 'NORTH';
    const targetPosition =
      targetMethodNode.y >= sourceMethodNode.y ? 'NORTH' : 'SOUTH';

    const attributes = {
      method: this.args.methodId,
      source: sourceId,
      sourcePosition,
      target: targetId,
      targetPosition,
    };

    this.args.onAddMethodEdge(attributes, this.args.methodId);
    this.closeCreateMode();
  }

  @action
  setCreatingImageAsset() {
    this.args.onSetCreatingImageAsset(this.args.methodId);
  }

  @action
  toggleStepCreateMode() {
    if (this.stepCreateMode) {
      this.closeCreateMode();
      this.tracking.trackEvent('method_left_step_create_mode');
    } else {
      this.closeCreateMode();
      this.tracking.trackEvent('method_entered_step_create_mode');
      this.stepCreateMode = true;
    }
  }

  @action
  toggleEdgeCreateMode() {
    if (this.edgeCreateMode) {
      this.closeCreateMode();
      this.tracking.trackEvent('method_left_edge_create_mode');
    } else {
      this.closeCreateMode();
      this.tracking.trackEvent('method_entered_edge_create_mode');
      this.edgeCreateMode = true;
    }
  }

  // @action
  // toggleBulkCreateMode() {
  //   if (this.bulkCreateMode) {
  //     this.closeCreateMode();
  //     this.tracking.trackEvent('method_left_bulk_create_mode');
  //   } else {
  //     this.closeCreateMode();
  //     this.tracking.trackEvent('method_entered_bulk_create_mode');
  //     this.onResetBulkCreateItems();
  //     this.bulkCreateMode = true;
  //   }
  // }

  @action
  onLabelClick(elementId) {
    if (this.stepCreateMode) {
      this.createMethodNode();
      return this.closeCreateMode();
    }

    if (this.cmdKeyDown) {
      return this.createMethodNode();
    }

    if (elementId) {
      this.args.onSelectElement(elementId);
      this.tracking.trackEvent('marker_label_selected_system');
    }
  }

  @action
  onMethodNodeClick(methodNodeId, isSelected, type) {
    if (this.edgeCreateMode) {
      if (this.creatingSourceId) {
        this.createMethodEdge(this.creatingSourceId, methodNodeId);
      } else {
        this.creatingSourceId = methodNodeId;
        return;
      }
    }
    if (this.stepCreateMode) {
      this.createMethodNode();
      return this.closeCreateMode();
    }

    // if (this.bulkCreateMode) {
    //   this.createMethodNodes();
    //   return this.closeCreateMode();
    // }

    if (this.cmdKeyDown) {
      return this.createMethodNode();
    }

    if (isSelected) {
      if (this.multiSelectMode) {
        this.args.onDeselectMethodNode(methodNodeId);
      } else {
        this.args.onDeselectMethodItems(this.args.methodId);
        this.tracking.trackEvent('method_deselected_all');
      }
    } else {
      this.args.onSelectMethodNode(methodNodeId, this.multiSelectMode, type);
      this.tracking.trackEvent('selected_method_node');
    }
  }

  @action
  onMethodEdgeClick(methodEdgeId, isSelected) {
    // if (this.stepCreateMode || this.bulkCreateMode || this.cmdKeyDown) {
    //   return;
    // }
    if (this.stepCreateMode || this.cmdKeyDown) {
      return;
    }

    if (isSelected) {
      if (this.multiSelectMode) {
        this.args.onDeselectMethodEdge(methodEdgeId);
      } else {
        this.args.onDeselectMethodItems(this.args.methodId);
        this.tracking.trackEvent('method_deselected_all');
      }
    } else {
      this.args.onSelectMethodEdge(methodEdgeId, this.multiSelectMode);
      this.tracking.trackEvent('selected_method_edge');
    }
  }

  @action
  onBendPointClick(bendPointId, isSelected) {
    // if (this.stepCreateMode || this.bulkCreateMode || this.cmdKeyDown) {
    //   return;
    // }
    if (this.stepCreateMode || this.cmdKeyDown) {
      return;
    }

    if (isSelected) {
      if (this.multiSelectMode) {
        this.args.onDeselectMethodEdgePoint(bendPointId);
      } else {
        this.args.onDeselectMethodItems(this.args.methodId);
        this.tracking.trackEvent('method_deselected_all');
      }
    } else {
      this.args.onSelectMethodEdgePoint(bendPointId);
      this.tracking.trackEvent('selected_method_edge_bend_point');
    }
  }

  @action
  onArtboardClick() {
    if (this.cmdKeyDown) {
      return this.createMethodNode();
    }
    if (this.stepCreateMode) {
      this.createMethodNode();
      this.closeCreateMode();
    } else {
      this.deselectAll();
    }

    // else if (this.bulkCreateMode) {
    //   this.createMethodNodes();
    //   this.closeCreateMode();
    // }
  }

  @action
  onFigureClick() {
    this.deselectAll();
  }

  @action
  onMultiselectDrag(dx, dy, draggingId) {
    this.selectedNodes.forEach((node) => {
      if (node.id() !== draggingId) {
        const x = node.x() + dx;
        const y = node.y() + dy;

        node.x(x);
        node.y(y);
      }
    });
  }

  @action
  onMultiselectDragEnd() {
    const methodNodes = [];

    this.selectedNodes.forEach((node) => {
      const x = node.x();
      const y = node.y();
      const nodeType = node.getAttr('nodeType');
      const nodeId = node.id();

      switch (nodeType) {
        case 'method-node':
          methodNodes.push({
            id: nodeId,
            attributes: { x, y },
          });
          break;
      }
    });

    this.args.onUpdateMethodItems(this.args.methodId, methodNodes);
  }

  @action
  removeSelected() {
    if (!this.selectedItems.length) {
      return;
    }

    const state = this.redux.getState();

    // filter element nodes into a separate list
    const elementsList = this.selectedMethodNodes
      .filter((methodNodeId) => {
        const methodNode = getMethodNode(state, methodNodeId);
        return methodNode.element;
      })
      .map((methodNodeId) => {
        const methodNode = getMethodNode(state, methodNodeId);
        return methodNode.element;
      });

    // filter out start node and any element nodes
    const methodNodesList = this.selectedMethodNodes.filter((methodNodeId) => {
      const methodNode = getMethodNode(state, methodNodeId);
      const isStartNode = methodNode.type === 'start';
      const isElementNode = methodNode.element ? true : false;
      const canDelete = !isStartNode && !isElementNode;
      return canDelete;
    });

    this.args.onRemoveSelectedMethodItems(
      this.args.methodId,
      methodNodesList,
      this.selectedMethodEdges,
      this.selectedMethodEdgePoints,
      elementsList
    );

    this.tracking.trackEvent('method_removed_selected');
  }

  @action
  onTogglePreviewMode() {
    if (this.previewMode) {
      this.stepCreateMode = false;
      this.edgeCreateMode = false;
      // this.bulkCreateMode = false;
    }
  }

  @action
  setup() {
    // let { x, y, k } = this.versionTreeCoordinates;
    this.buildMethodModels();

    let x = 0;
    let y = 0;
    let k = 1;

    const stage = new Konva.Stage({
      container: this.containerDomElementId,
      width: this.stageWidth,
      height: this.stageHeight,
      x,
      y,
      scale: {
        x: k,
        y: k,
      },
      draggable: true,
    });

    this.stageScale = k;
    this.stageX = x;
    this.stageY = y;

    stage.on('dragend', () => this.handleStageDragEnd());
    stage.on('dragmove', () => this.handleStageDragMove());

    // create up to 5 layers for better performance
    // add the layers to the stage
    const artboardLayer = new Konva.Layer({ name: 'artboard' });
    const methodEdgesLayer = new Konva.Layer({ name: 'edges' });
    const methodEdgePointsLayer = new Konva.Layer({ name: 'edge_points' });
    const methodNodesLayer = new Konva.Layer({ name: 'nodes' });

    stage.add(artboardLayer);
    stage.add(methodEdgesLayer);
    stage.add(methodEdgePointsLayer);
    stage.add(methodNodesLayer);
    // add stage listeners
    stage.on('wheel', (event) => this.handleWheel(event));
    stage.on('mouseenter', () => (this.onCanvas = true));
    stage.on('mouseleave', () => (this.onCanvas = false));
    stage.on('mousemove', (event) => this.handleMouseMove(event));
    stage.on('click', (event) => {
      if (event.target === stage) {
        this.handleBackgroundClick(event);
      }
    });
    stage.on('mouseup', (event) => this.handleMouseup(event));

    this.artboardLayer = methodEdgesLayer;
    this.methodEdgesLayer = methodEdgesLayer;
    this.methodEdgePointsLayer = methodEdgePointsLayer;
    this.methodNodesLayer = methodNodesLayer;

    this.stage = stage;

    if (ENV.environment === 'test') {
      this.testing.methodStage = this.stage;
    }

    // fit the canvas to the container
    this.fitStageToContainer();
    this.fitToScreen();
  }

  centerGraph() {
    // const { x, y, k } = this.versionTreeCoordinates;
    // if (x === 0 && y === 0 && k === 0) {
    //   this.fitToNode(null, false);
    // }
  }

  incrementVisibleAreaIndex() {
    this.visibleAreaIndex++;
  }

  @action
  onVisibleAreaChanged() {
    debounce(this, this.incrementVisibleAreaIndex, 10);
  }

  handleMouseMove(event) {
    const pointerPosition = this.stage.getPointerPosition();
    const scale = this.stage.scaleX();
    this.stageMouseX = pointerPosition.x / scale - this.stage.x() / scale;
    this.stageMouseY = pointerPosition.y / scale - this.stage.y() / scale;
    this.mouseX = event.evt.layerX;
    this.mouseY = event.evt.layerY;
  }

  @action
  onScheduleRender() {
    debounce(this, this.scheduleRender, 10);
  }

  scheduleRender(layer) {
    if (layer) {
      layer.batchDraw();
    } else {
      this.artboardLayer.batchDraw();
      this.methodEdgesLayer.batchDraw();
      this.methodEdgePointsLayer.batchDraw();
      this.methodNodesLayer.batchDraw();
    }

    if (!this.didFirstRender) {
      this.didFirstRender = true;
    }
  }

  moveStage(stage, location, scale, animate = true) {
    const { x, y } = location;

    if (animate) {
      const tween = new Konva.Tween({
        duration: 0.35,
        easing: Konva.Easings.EaseInOut,
        node: stage,
        scaleX: (scale && scale.x) || 1,
        scaleY: (scale && scale.y) || 1,
        x,
        y,
        onUpdate: () => {
          this.stageX = stage.x();
          this.stageY = stage.y();
          this.stageScale = stage.scaleX();
        },
      });
      tween.play();
      stage.batchDraw();

      // debounce(this, this.updateTreeCoordinates, 350);
    } else {
      stage.scale(scale);
      stage.x(x);
      stage.y(y);
      this.stageX = stage.x();
      this.stageY = stage.y();
      this.stageScale = stage.scaleX();
      // this.updateTreeCoordinates;
    }
  }

  @action
  onActionMode(/*elem, [actionMode]*/) {
    // this.creatingElementId = null;
    // this.creatingElementVersionId = null;
  }

  @action
  zoomIn() {
    const stage = this.stage;
    const scaleBy = 1.07;
    const oldScale = stage.scaleX();
    let newScale = scaleBy * oldScale;
    // constrain
    if (newScale > this.maxScale) newScale = this.maxScale;

    stage.scale({ x: newScale, y: newScale });
    stage.batchDraw();
    this.stageScale = newScale;
    this.stageX = stage.x();
    this.stageY = stage.y();
    this.tracking.trackEvent('method_zoomed_in');
  }

  @action
  zoomOut() {
    const stage = this.stage;
    const scaleBy = 0.93;
    const oldScale = stage.scaleX();
    let newScale = scaleBy * oldScale;
    // constrain
    if (newScale < this.minScale) newScale = this.minScale;
    stage.scale({ x: newScale, y: newScale });
    this.stageScale = newScale;
    this.stageX = stage.x();
    this.stageY = stage.y();
    stage.batchDraw();
    this.tracking.trackEvent('method_zoomed_out');
  }

  @action
  removeSelectedItems() {}

  @action
  onUpdateMethodNode(methodNodeId, attributes) {
    this.args.onUpdateMethodNode(this.args.methodId, methodNodeId, attributes);
  }

  @action
  onUpdateMethodEdge(methodEdgeId, attributes) {
    this.args.onUpdateMethodEdge(this.args.methodId, methodEdgeId, attributes);
  }

  @action
  enterCreateMode() {
    this.createMode = true;
  }

  @action
  leaveCreateMode() {
    this.createMode = false;
  }

  @action
  onResize() {
    this.fitStageToContainer();
  }

  @action
  fitToNode(nodeId, animate = true) {
    const stage = this.stage;
    const scale = 0.35;
    const width = stage.width();
    const height = stage.height();
    const originalScale = stage.scaleX();

    let rect = {
      height: 183.6,
      width: 183.6,
      x: -91.8,
      y: -91.8,
    };

    stage.scale({
      x: scale,
      y: scale,
    });

    if (nodeId) {
      const node = stage.findOne(`#${nodeId}`);
      rect = node.getClientRect({ relativeTo: 'stage' });
    }

    const delta = {
      x: width / 2 - (rect.x + rect.width / 2),
      y: height / 2 - (rect.y + rect.height / 2),
    };

    const x = stage.x();
    const y = stage.y();

    const stageCenter = {
      x: x + delta.x,
      y: y + delta.y,
    };

    stage.scale({
      x: originalScale,
      y: originalScale,
    });

    this.moveStage(stage, stageCenter, { x: scale, y: scale }, animate);
  }

  get bounds() {
    const width = defaultArtboardWidth;
    const height = defaultArtboardHeight;
    const x = width / 2;
    const y = height / 2;

    return {
      x,
      y,
      width,
      height,
    };
  }

  @action
  onFitToScreen() {
    this.fitToScreen();
    this.tracking.trackEvent('method_zoomed_fit');
  }

  fitToScreen() {
    const stage = this.stage;
    const bounds = this.bounds;
    const padding = 150;

    const contextWidth = this.contextActive ? this.contextWidth : 0;
    const explorerWidth = this.showingExplorer ? this.explorerWidth : 0;
    const width = stage.width() - explorerWidth - contextWidth;
    const height = stage.height();

    let scale = Math.min(
      width / (bounds.width + padding),
      height / (bounds.height + padding)
    );

    stage.scale({
      x: scale,
      y: scale,
    });

    let x = explorerWidth ? width / 2 + explorerWidth : width / 2;
    x = x + 10; // padding;

    stage.x(x);
    stage.y(height / 2);

    stage.draw();
    this.stageScale = stage.scaleX();
    this.stageX = stage.x();
    this.stageY = stage.y();
  }

  @action
  async autoLayout() {
    const state = this.redux.getState();
    const offsetX = this.artboardWidth / 2;
    const offsetY = this.artboardHeight / 2;
    const aspect = this.artboardWidth / this.artboardHeight;

    let nodesList = this.methodNodesList.filter(
      (methodNodeId) => !this.disconnectedNodesList.includes(methodNodeId)
    );

    let edgesList = this.methodEdgesList.filter(
      (methodEdgeId) => !this.disconnectedEdgesList.includes(methodEdgeId)
    );

    const nodes = nodesList.map((methodNodeId) => {
      const graphNode = this.stage.findOne(`#${methodNodeId}-background`);
      const height = (graphNode && graphNode.height()) || 0;
      let width = (graphNode && graphNode.width()) || 0;

      // this randomness makes it so ELK doesn't layout reflexive edges
      // on top of each other when the nodes are the same exact width
      width = width + Math.random() * 1;

      return {
        id: methodNodeId,
        width,
        height,
      };
      // if (this.methodNodesList[index + 1]) {
      //   edges.push({
      //     id: `${methodNodeId}_${this.methodNodesList[index + 1]}`,
      //     sources: [methodNodeId],
      //     targets: [this.methodNodesList[index + 1]],
      //   });
      // }
    });

    const edges = edgesList.map((methodEdgeId) => {
      const methodEdge = getMethodEdge(state, methodEdgeId);
      return {
        id: methodEdgeId,
        sources: [`${methodEdge.source}`],
        targets: [`${methodEdge.target}`],
      };
    });

    const layout = await getLayout(nodes, edges, {
      'org.eclipse.elk.aspectRatio': `${aspect}f`,
    });

    this.args.onUpdateMethodLayout(
      this.args.methodId,
      layout,
      offsetX,
      offsetY,
      this.artboardWidth
    );

    this.updateMethodModels();

    this.applicationState.methodAutoArrangeIndex++;
  }
}

export default connect(stateToComputed, dispatchToActions)(MethodEditorKonva);
