import {
  deselectAllMethod,
  deselectGraph,
} from '../../../actions/invention-ui';

import Component from '@glimmer/component';
import { action } from '@ember/object';
import { deselectAll as deselectAllDrawing } from '../../../actions/drawing';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';

class UiContextCloseButton extends Component {
  @service redux;
  @service data;

  get styleNamespace() {
    return podNames['ui-context-close-button'];
  }

  get classNames() {
    return [this.styleNamespace];
  }

  @action
  onClose() {
    switch (this.args.context) {
      case 'search': {
        return this.data.setShowingSearch(false);
      }
      case 'figures': {
        return this.data.setShowingFigures(false);
      }
      case 'image':
      case 'marker':
      case 'highlight': {
        return this.redux.store.dispatch(
          deselectAllDrawing(this.args.drawingId)
        );
      }
      case 'method-edge':
      case 'method-node': {
        return this.redux.store.dispatch(deselectAllMethod(this.args.methodId));
      }
      case 'comprises':
      case 'term':
      case 'product':
      case 'element':
      case 'element-version': {
        return this.redux.store.dispatch(deselectGraph());
      }
    }
  }
}

export default UiContextCloseButton;
