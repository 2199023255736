import {
    UPDATE_META
} from '../actions/actionTypes';
import { addFromSchema } from '../utils/schema';

const initialState = addFromSchema('meta');
  
  const metaReducer = (state = initialState, action) => {
    const { type, payload } = action;
    
    switch (type) {

      case UPDATE_META: {
        const { attributes } = payload;
        return {
          ...state,
          ...attributes,
        };
      }
  
      /* istanbul ignore next */
      default:
        return state;
    }
  };
  
  export default metaReducer;