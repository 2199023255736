import Component from '@glimmer/component';
import { action } from '@ember/object';
import podNames from 'ember-component-css/pod-names';

class DrawingRealizationPreview extends Component {
  get styleNamespace() {
    return podNames['drawing-realization-preview'];
  }

  get classNames() {
    let classNames = [this.styleNamespace];
    return classNames.join(' ');
  }

  @action
  onClose() {
    if (this.args.onClose) {
      this.args.onClose();
    }
  }
}

export default DrawingRealizationPreview;
