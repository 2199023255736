import Component from '@glimmer/component';
import { equal } from '@ember/object/computed';
import { computed } from '@ember/object';
import { htmlSafe } from '@ember/string';
import podNames from 'ember-component-css/pod-names';
import { connect } from 'ember-redux';
import { getShowingExplorer, getExplorerWidth } from '../../../selectors/invention-ui';

const stateToComputed = (state) => ({
  showingExplorer: getShowingExplorer(state),
  explorerWidth: getExplorerWidth(state),
});

const dispatchToActions = {};


class UiActionMenu extends Component {
  get styleNamespace() {
    return podNames['ui-action-menu'];
  }

  get classNames() {
    let classNames = [this.styleNamespace, 'ui-action-menu'];
    if (this.isTop) classNames.push('is-top');
    if (this.isBottom) classNames.push('is-bottom');
    return classNames.join(' ');
  }

  @computed('showingExplorer', 'explorerWidth')
  get left() {
    return this.showingExplorer ? this.explorerWidth : 0;
  }

  @computed('left')
  get leftStyle() {
    return htmlSafe(`left:${this.left}px;`);
  }

  @equal('args.placement', 'top') isTop;
  @equal('args.placement', 'bottom') isBottom;
}

export default connect(stateToComputed, dispatchToActions)(UiActionMenu);