import Component from '@glimmer/component';
import podNames from 'ember-component-css/pod-names';

class UiContextContainerHeaderSpacer extends Component {
  get classNames() {
    let classNames = [this.styleNamespace, 'ui-context-container-header-spacer'];
    return classNames.join(' ');
  }

  get styleNamespace() {
    return podNames['ui-context-container-header-spacer'];
  }
}

export default UiContextContainerHeaderSpacer;
