import { action, computed } from '@ember/object';
import { getElement, getElementName } from '../../../selectors/element';
import {
  getPreferredElementVersion,
  getProduct,
} from '../../../selectors/product';

import Component from '@glimmer/component';
import { connect } from 'ember-redux';
import { getElementVersion } from '../../../selectors/element-version';
import { htmlSafe } from '@ember/string';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';
import uuid from 'uuid/v4';

const dispatchToActions = {};

const stateToComputed = (state, attrs) => ({
  product: getProduct(state, attrs.productId),
  parent: getElement(state, attrs.parent),
  element: getElement(state, attrs.elementId),
  elementName: getElementName(state, attrs.elementId),
  elementVersion: getPreferredElementVersion(
    state,
    attrs.elementId,
    attrs.productId
  ),
  // rootNodeId: getRootNodeId(state),
});

class ElementTableRow extends Component {
  @service redux;
  depthPaddingWidth = 25;
  domElementId = `ui-table-row-${uuid()}`;

  get styleNamespace() {
    return podNames['element-table-row'];
  }

  // eslint-disable-next-line ember/require-computed-property-dependencies
  @computed(
    'args.{isDragged,isDraggedOver,isDragging,isSelected,isSelectedDescendant,isSelectedChild,isCollapsed}',
    'canDropTop',
    'canDropMiddle',
    'canDropBottom'
  )
  get classNames() {
    let classNames = [this.styleNamespace, 'element-table-row'];
    if (this.args.isSelected) classNames.push('is-selected');
    if (this.args.isSelectedDescendant)
      classNames.push('is-selected-descendant');
    if (this.args.isSelectedChild) classNames.push('is-selected-child');
    if (this.args.isCollapsed) classNames.push('is-collapsed');
    if (this.args.isDragging) classNames.push('is-dragging');
    if (this.args.isDragged) classNames.push('is-dragged');
    if (this.args.isDraggedOver) classNames.push('is-dragged-over');
    if (this.canDropTop) classNames.push('is-dragged-over-top');
    if (this.canDropMiddle) classNames.push('is-dragged-over-middle');
    if (this.canDropBottom) classNames.push('is-dragged-over-bottom');
    return classNames.join(' ');
  }

  @computed('element.elementVersion')
  get parentElementVersion() {
    const state = this.redux.getState();
    return (
      this.element.elementVersion &&
      getElementVersion(state, this.element.elementVersion)
    );
  }

  @computed('elementVersion.name')
  get elementVersionName() {
    return this.elementVersion && this.elementVersion.name;
  }

  get gridStyle() {
    return htmlSafe(
      // `grid-template-columns: repeat(${this.columnWidths}, minmax(200px, 1fr));`
      `grid-template-columns: ${this.args.columnWidths};`
    );
  }

  get canDropTop() {
    return (
      this.args.isDragging &&
      this.args.isDraggedOver &&
      this.args.draggedOverArea === 'top' &&
      !this.args.isDraggedDescendant
    );
  }

  get canDropMiddle() {
    return (
      this.args.isDragging &&
      this.args.isDraggedOver &&
      this.args.draggedOverArea === 'middle' &&
      !this.args.isDraggedParent &&
      !this.args.isDraggedDescendant &&
      !this.args.isDragged
    );
  }

  get canDropBottom() {
    const hasChildren = this.args.children && this.args.children.length;
    const hasVisibleChildren = hasChildren && !this.args.isCollapsed;
    return (
      this.args.isDragging &&
      this.args.isDraggedOver &&
      this.args.draggedOverArea === 'bottom' &&
      !this.args.isDraggedDescendant &&
      !hasVisibleChildren
    );
  }

  @action
  didInsert() {
    // add dragover events
    const domElement = document.getElementById(this.domElementId);

    if (domElement && this.args.isDraggable) {
      const dragoverTopElement = domElement.querySelector('.item-dragover-top');
      this._handleMouseenterTop = this.handleMouseenterTop.bind(this);
      dragoverTopElement.addEventListener(
        'mouseenter',
        this._handleMouseenterTop
      );

      const dragoverMiddleElement = domElement.querySelector(
        '.item-dragover-middle'
      );
      this._handleMouseenterMiddle = this.handleMouseenterMiddle.bind(this);
      dragoverMiddleElement.addEventListener(
        'mouseenter',
        this._handleMouseenterMiddle
      );

      const dragoverBottomElement = domElement.querySelector(
        '.item-dragover-bottom'
      );
      this._handleMouseenterBottom = this.handleMouseenterBottom.bind(this);
      dragoverBottomElement.addEventListener(
        'mouseenter',
        this._handleMouseenterBottom
      );
    }
  }

  @action
  willDestroyNode() {
    const domElement = document.getElementById(this.domElementId);
    if (domElement && this.args.isDraggable) {
      // remove dragover events
      const dragoverTopElement = domElement.querySelector('.item-dragover-top');
      dragoverTopElement.removeEventListener(
        'mouseenter',
        this._handleMouseenterTop
      );
      const dragoverBottomElement = domElement.querySelector(
        '.item-dragover-bottom'
      );
      dragoverBottomElement.removeEventListener(
        'mouseenter',
        this._handleMouseenterBottom
      );
      const dragoverMiddleElement = domElement.querySelector(
        '.item-dragover-middle'
      );
      dragoverMiddleElement.removeEventListener(
        'mouseenter',
        this._handleMouseenterMiddle
      );
    }
  }

  handleMouseenterTop(event) {
    const area = 'top';
    this.args.onDragenter(event, 'element', this.args.elementId, area);
  }

  handleMouseenterMiddle(event) {
    const area = 'middle';
    this.args.onDragenter(event, 'element', this.args.elementId, area);
  }

  handleMouseenterBottom(event) {
    const area = 'bottom';
    this.args.onDragenter(event, 'element', this.args.elementId, area);
  }

  @computed('args.depth')
  get depth() {
    return this.args.depth || 0;
  }

  @computed('depth', 'depthPaddingWidth')
  get depthPadding() {
    return (this.depth - 2) * this.depthPaddingWidth;
  }

  @computed('depthPadding')
  get depthStyle() {
    return htmlSafe(`margin-left: ${this.depthPadding}px`);
  }

  @computed('depthPadding')
  get dragoverDepthStyle() {
    return htmlSafe(`margin-left: ${this.depthPadding}px`);
  }

  @computed('args.children.[]')
  get hasChildren() {
    return this.args.children && this.args.children.length;
  }

  @computed('args.isCollapsible', 'hasChildren')
  get showCaret() {
    return this.args.isCollapsible && this.hasChildren;
  }

  @action
  onMousedown(event) {
    if (this.args.onMousedown) {
      this.args.onMousedown(event, this.args.elementId, 'element');
    }
  }

  @action
  onClick() {
    if (this.args.onClick) {
      this.args.onClick(this.args.elementId, this.args.isSelected);
    }
  }

  @action
  onDoubleClick() {
    if (this.args.onDoubleClick) {
      this.args.onDoubleClick(this.args.elementId);
    }
  }

  @action
  onCollapseClick() {
    if (this.args.onCollapseClick) {
      this.args.onCollapseClick(this.args.elementId);
    }
  }
}

export default connect(stateToComputed, dispatchToActions)(ElementTableRow);
