import { action, computed } from '@ember/object';
import { and, equal, or } from '@ember/object/computed';

import Component from '@glimmer/component';
import { connect } from 'ember-redux';
import { getElement } from '../../../selectors/element';
import { getPreferredElementVersion } from '../../../selectors/product';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';

const stateToComputed = (state, attrs) => ({
  element: getElement(state, attrs.elementId),
  elementVersion: getPreferredElementVersion(
    state,
    attrs.elementId,
    attrs.productId
  ),
});

const dispatchToActions = {};

class PreviewContextElement extends Component {
  @service store;
  get styleNamespace() {
    return podNames['preview-context-element'];
  }

  get classNames() {
    let classNames = [this.styleNamespace, 'preview-context-element'];
    return classNames.join(' ');
  }

  @computed('elementVersion.markersList.[]')
  get markersList() {
    return this.elementVersion && this.elementVersion.markersList;
  }

  @or('markersList.length', 'showMethod') showFigures;
  @equal('element.category', 'system') isSystem;
  @and('isSystem', 'methodHasNodes') showMethod;

  @computed('elementVersion.method')
  get methodModel() {
    return (
      this.elementVersion && this.elementVersion.method && this.store.peekRecord('method', this.elementVersion.method)
    );
  }

  @computed('methodModel.methodNodesList.[]')
  get methodHasNodes() {
    return this.methodModel && this.methodModel.methodNodesList.length > 1
      ? true
      : false;
  }

  @action
  onMarkerReferenceClick(markerId) {
    if (this.args.onMarkerReferenceClick) {
      this.args.onMarkerReferenceClick(markerId);
    }
  }
}

export default connect(
  stateToComputed,
  dispatchToActions
)(PreviewContextElement);
