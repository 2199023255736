import Component from '@glimmer/component';
import { computed } from '@ember/object';
import { connect } from 'ember-redux';
import { getElementVersionMethodId } from '../../../selectors/element-version';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';

const dispatchToActions = {};

const stateToComputed = (state, attrs) => ({
  methodId: attrs.methodId || getElementVersionMethodId(state, attrs.elementVersionId),
});

class ElementVersionContextMethod extends Component {
  @service store;
  get styleNamespace() {
    return podNames['element-version-context-method'];
  }

  get classNames() {
    let classNames = [this.styleNamespace, 'element-version-context-method'];
    return classNames.join(' ');
  }

  @computed('methodId')
  get methodModel() {
    return (
      this.methodId && this.store.peekRecord('method', this.methodId)
    );
  }

  @computed('methodModel.methodNodesList.[]')
  get methodHasNodes() {
    return this.methodModel && this.methodModel.methodNodesList.length > 1;
  }
}

export default connect(
  stateToComputed,
  dispatchToActions
)(ElementVersionContextMethod);
