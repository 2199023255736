import { get } from '@ember/object';
import { getSettingsState } from '../utils/redux';

export const getSettings = (state) => getSettingsState(state);

export const getShowWarnings = (state) =>
  get(getSettingsState(state), `showWarnings`);

export const getShowNovelty = (state) =>
  get(getSettingsState(state), `showNovelty`);

export const getPreviewLanguage = (state) =>
  get(getSettingsState(state), `previewLanguage`);

export const getPreviewNumbering = (state) =>
  get(getSettingsState(state), `previewNumbering`);

export const getNavigationDirection = (state) =>
  get(getSettingsState(state), `navigationDirection`);