import { action, computed } from '@ember/object';

import Component from '@glimmer/component';
import { connect } from 'ember-redux';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';

const dispatchToActions = {};

const stateToComputed = null;

class PreviewNavigation extends Component {
  @service redux;

  get classNames() {
    let classNames = ['preview-nav', this.styleNamespace];
    return classNames.join(' ');
  }

  get styleNamespace() {
    return podNames['preview-nav'];
  }

  @computed('inventionUi.showingNotes')
  get showingNotes() {
    return this.inventionUi.showingNotes;
  }

  get atStart() {
    return this.args.activeIndex === -1;
  }

  get atEnd() {
    return this.args.activeIndex === this.args.pathLength - 1;
  }

  @action
  toggleScratchpad() {
    // updatedState.ui.previewModeShowNovelty = true;
    // updatedState.ui.previewModeShowWarnings = true;
    // updatedState.ui.previewModeStyle = 'report';
    // updatedState.ui.previewModeGraphNavigation = 'breadth';
  }

  @action
  onSetShowingSettings() {
    this.args.onSetShowingSettings(true);
  }


}

export default connect(stateToComputed, dispatchToActions)(PreviewNavigation);
