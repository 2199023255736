export default {
  attributes: {
    id: {
      type: 'uuid',
      defaultValue: null
    },
    name: {
      type: 'string',
      defaultValue: ''
    },
    type: {
      type: 'type',
      defaultValue: 'element'
    },
    category: {
      type: 'type',
      defaultValue: 'element' // 'term'
    },
    elementVersion: {
      type: 'uuid',
      defaultValue: null
    },
    x: {
      type: 'number',
      defaultValue: 0
    },
    y: {
      type: 'number',
      defaultValue: 0
    },
    outcome: {
      type: 'string',
      defaultValue: ''
    },
    requirementsList: {
      type: 'array',
      defaultValue: []
    },
    elementVersionsList: {
      type: 'array',
      defaultValue: []
    },
    createdAt: {
      type: 'date'
    },
  }
};
