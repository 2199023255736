import Component from '@glimmer/component';
import Konva from 'konva';
import { PRIMARY_COLOR } from '../../../constants/colors';
import { action } from '@ember/object';
import { connect } from 'ember-redux';
import podNames from 'ember-component-css/pod-names';

const dispatchToActions = {};

const stateToComputed = null;

class MethodEdge extends Component {
  constructor(owner, args) {
    super(owner, args);
    this.onScheduleRender = this.args.onScheduleRender;
  }

  @action
  didInsert() {
    this.setup();
    this._sourceX = this.args.sourceX;
    this._sourceY = this.args.sourceY;
    this._targetX = this.args.targetX;
    this._targetY = this.args.targetY;
  }

  @action
  willDestroyNode() {
    this.containerNode.destroy();
    this.onScheduleRender();
  }

  get styleNamespace() {
    return podNames['method-connect-edge'];
  }

  get classNames() {
    let classNames = [this.styleNamespace, 'method-connect-edge'];
    return classNames.join(' ');
  }

  rendered = false;
  arrowRadius = 7;
  strokeWidth = 2;

  get strokeColor() {
    return PRIMARY_COLOR;
  }

  setup() {
    const containerNode = new Konva.Group({
      id: this.args.methodEdgeId,
      nodeType: 'methodEdge',
      zIndex: this.args.index + 100,
    });

    const arrowNode = new Konva.Arrow({
      points: [
        this.args.sourceX,
        this.args.sourceY,
        this.args.targetX,
        this.args.targetY,
      ],
      pointerWidth: this.arrowRadius * 2,
      pointerLength: this.arrowRadius * 2,
      fill: this.strokeColor,
      stroke: this.strokeColor,
      strokeWidth: this.strokeWidth,
    });

    // add events
    if (this.args.onClick) {
      containerNode.on('click', () =>
        this.args.onClick(this.args.methodEdgeId, this.args.isSelected)
      );
    }

    this.args.layer.add(containerNode);

    containerNode.add(arrowNode);
    // containerNode.add(ordinalNode);

    this.containerNode = containerNode;
    this.arrowNode = arrowNode;

    this.updatePosition();
    this.onScheduleRender();
  }

  updatePosition() {
    this.arrowNode.points([
      this.args.sourceX,
      this.args.sourceY,
      this.args.targetX,
      this.args.targetY,
    ]);
  }

  @action
  onUpdate(elem, [sourceX, sourceY, targetX, targetY]) {
    let detailsChanged = false;

    if (
      this._sourceX !== sourceX ||
      this._sourceY !== sourceY ||
      this._targetX !== targetX ||
      this._targetY !== targetY
    ) {
      this._sourceX = sourceX;
      this._sourceY = sourceY;
      this._targetX = targetX;
      this._targetY = targetY;

      this.updatePosition();
      detailsChanged = true;
    }

    if (detailsChanged) {
      this.onScheduleRender(this.args.layer);
    }
  }
}

export default connect(stateToComputed, dispatchToActions)(MethodEdge);
