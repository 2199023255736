import {
  defaultElementColumns,
  defaultElementColumnsList,
} from '../constants/settings';
import { getElementVersionsMap, getElementsMap } from './graph';

import { get } from '@ember/object';
import { getMethodNode } from './method-node';
import { getProduct } from './product';
import { getUiState } from '../utils/redux';

export const getInventionUi = (state) => getUiState(state);

export const getImage = (state) => get(getUiState(state), ``);

export const getGraphUpdateIndex = (state) =>
  get(getUiState(state), `graphUpdateIndex`);

export const getActiveFeature = (state) =>
  get(getUiState(state), `activeFeature`);

export const getActiveElementVersionListItem = (state) =>
  get(getUiState(state), `activeElementVersionListItem`);

export const getActiveProductId = (state) =>
  get(getUiState(state), `activeProduct`);

export const getActiveContextTab = (state) =>
  get(getUiState(state), `activeContextTab`);

export const getActiveDrawingId = (state) =>
  get(getUiState(state), `activeDrawing`);

export const getActiveMethodId = (state) =>
  get(getUiState(state), `activeMethod`);

export const getActiveProductView = (state) =>
  get(getUiState(state), `activeProductView`);

export const getShowingSettings = (state) =>
  get(getUiState(state), `showingSettings`);

export const getShowingPatentability = (state) =>
  get(getUiState(state), `showingPatentability`);

export const getActivePatentabilitySection = (state) =>
  get(getUiState(state), `activePatentabilitySection`);

export const getDefaultElementCategory = (state) =>
  get(getUiState(state), `defaultElementCategory`);

export const getDefaultMarkerCategory = (state) =>
  get(getUiState(state), `defaultMarkerCategory`);

export const getDefaultMethodNodeType = (state) =>
  get(getUiState(state), `defaultMethodNodeType`);

export const getNavigationStack = (state) =>
  get(getUiState(state), `navigationStack`);

export const getActiveNavigationStack = (state) => {
  const navigationStack = getNavigationStack(state) || [];
  return navigationStack.length && navigationStack[navigationStack.length - 1];
};

export const getActiveProduct = (state) => {
  const productId = getActiveProductId(state);
  return productId && getProduct(state, productId);
};

export const getShowingFigures = (state) =>
  get(getUiState(state), `showingFigures`);

export const getShowingSearch = (state) =>
  get(getUiState(state), `showingSearch`);

export const getActiveComparisonElement = (state) =>
  get(getUiState(state), `activeComparisonElement`);

export const getElementColumnsData = (state) =>
  get(getUiState(state), `elementColumnsData`);

export const getElementColumnsList = (state) => {
  const elementColumnsList = get(getUiState(state), `elementColumnsList`);
  return elementColumnsList && elementColumnsList.length
    ? elementColumnsList
    : defaultElementColumnsList;
};

export const getElementColumnWidths = (state) => {
  const elementColumnsList = getElementColumnsList(state);
  return (
    elementColumnsList &&
    elementColumnsList
      .map((columnId) => {
        const column = getElementColumn(state, columnId);
        let width;
        switch (column.width) {
          case 'wide':
            width = 'minmax(300px, 1fr)';
            break;
          case 'narrow':
            width = 'minmax(130px, 1fr)';
            break;
          default:
            width = 'minmax(200px, 1fr)';
        }
        return width;
      })
      .join(' ')
  );
};

export const getVisibleElementColumnsList = (state) =>
  get(getUiState(state), `visibleElementColumnsList`);

export const getPreviewMode = (state) => get(getUiState(state), `previewMode`);

export const getElementColumn = (state, id) => {
  let elementColumn = defaultElementColumns[id];
  const elementColumnsData = get(getUiState(state), `elementColumnsData.${id}`);

  if (elementColumn && elementColumnsData) {
    elementColumn = {
      ...elementColumn,
      ...elementColumnsData,
    };
  }

  return elementColumn;
};

export const getActiveProductChecklistView = (state) =>
  get(getUiState(state), `activeProductChecklistView`);

export const getShowingElementVersionSelector = (state) =>
  get(getUiState(state), `showingElementVersionSelector`);

export const getShowingProductChecklist = (state) =>
  get(getUiState(state), `showingProductChecklist`);

export const getMethodRealizationPreviewMode = (state) =>
  get(getUiState(state), `methodRealizationPreviewMode`);

export const getSelectedClaims = (state) =>
  get(getUiState(state), `selectedClaims`);

export const getSelectedElements = (state) =>
  get(getUiState(state), `selectedElements`);

export const getCloudBackupsEnabled = (state) =>
  get(getUiState(state), `cloudBackupsEnabled`);

export const getSelectedDescendants = (state) => {
  const selectedElements = getSelectedElements(state);
  const selectedElementVersions = getSelectedElementVersions(state);
  const selectedNodes = [...selectedElements, ...selectedElementVersions];

  let descendants = [];
  const elementVersionsMap = getElementVersionsMap(state);

  selectedNodes.forEach((nodeId) => {
    const node = elementVersionsMap[nodeId];

    if (node) {
      descendants = [...descendants, ...node.descendants];
    }
  });

  return descendants.uniq();
};

export const getSelectedChildren = (state) => {
  const activeProductId = getActiveProductId(state);
  let children = [];

  if (activeProductId) {
    const selectedElements = getSelectedElements(state);
    const selectedElementVersions = getSelectedElementVersions(state);
    const elementsTreeMap = getElementsMap(state, activeProductId);
    const solutionsTreeMap = getElementVersionsMap(state);

    selectedElements.forEach((nodeId) => {
      const node = elementsTreeMap[nodeId];

      if (node) {
        children = [...children, ...node.children];
      }
    });

    selectedElementVersions.forEach((nodeId) => {
      const node = solutionsTreeMap[nodeId];

      if (node) {
        children = [...children, ...node.children];
      }
    });
  }

  return children.uniq();
};

export const getSelectedElement = (state) =>
  getSelectedElements(state).length === 1 && getSelectedElements(state)[0];

export const getSelectedElementId = (state) =>
  getSelectedElements(state).length === 1 && getSelectedElements(state)[0];

export const getSelectedTermId = (state) =>
  getSelectedTerms(state).length === 1 && getSelectedTerms(state)[0];

export const getVersionTreeCoordinates = (state) =>
  get(getUiState(state), `versionTreeCoordinates`);

export const getSelectedElementVersions = (state) =>
  get(getUiState(state), `selectedElementVersions`);

export const getSelectedElementVersion = (state) =>
  getSelectedElementVersions(state).length === 1 &&
  getSelectedElementVersions(state)[0];

export const getSelectedEdges = (state) =>
  get(getUiState(state), `selectedEdges`);

export const getSelectedEdge = (state) => {
  const selectedEdges = getSelectedEdges(state);
  return (
    selectedEdges && selectedEdges.length === 1 && selectedEdges[0]
  );
};

export const getSelectedTerms = (state) =>
  get(getUiState(state), `selectedTerms`);

export const getSelectedProducts = (state) =>
  get(getUiState(state), `selectedProducts`);

export const getSelectedProductId = (state) => {
  const selectedProducts = getSelectedProducts(state);
  return (
    selectedProducts && selectedProducts.length === 1 && selectedProducts[0]
  );
};

export const getNumberingIndex = (state) =>
  get(getUiState(state), `numberingIndex`);


export const getActiveView = (state) => get(getUiState(state), `activeView`);

export const getSelectedMethodNodes = (state) =>
  get(getUiState(state), `selectedMethodNodes`);

export const getSelectedMethodNodeId = (state) => {
  const selectedMethodNodes = getSelectedMethodNodes(state);
  return (
    selectedMethodNodes &&
    selectedMethodNodes.length === 1 &&
    selectedMethodNodes[0]
  );
};

export const getSelectedMethodNode = (state) => {
  const selectedMethodNodeId = getSelectedMethodNodeId(state);
  return selectedMethodNodeId && getMethodNode(state, selectedMethodNodeId);
};

export const getSelectedMethodEdges = (state) =>
  get(getUiState(state), `selectedMethodEdges`);

export const getSelectedMethodEdgePoints = (state) =>
  get(getUiState(state), `selectedMethodEdgePoints`);

export const getSelectedMethodEdgeId = (state) => {
  const selectedMethodEdges = getSelectedMethodEdges(state);
  return (
    selectedMethodEdges &&
    selectedMethodEdges.length === 1 &&
    selectedMethodEdges[0]
  );
};
export const getSelectedDrawings = (state) =>
  get(getUiState(state), `selectedDrawings`);

export const getSelectedDrawingId = (state) => {
  const selectedDrawings = getSelectedDrawings(state);
  return (
    selectedDrawings && selectedDrawings.length === 1 && selectedDrawings[0]
  );
};

export const getSelectedImages = (state) =>
  get(getUiState(state), `selectedImages`);

export const getSelectedImageId = (state) => {
  const selectedImages = getSelectedImages(state);
  return selectedImages && selectedImages.length === 1 && selectedImages[0];
};

export const getSelectedMarkers = (state) =>
  get(getUiState(state), `selectedMarkers`);

export const getShowingExplorer = (state) =>
  get(getUiState(state), `showingExplorer`);

export const getExplorerWidth = (state) =>
  get(getUiState(state), `explorerWidth`);

export const getContextActive = (state) => {
    return getSelectedElementId(state) || 
    getSelectedTermId(state) ||
    getSelectedElementVersion(state) ||
    getSelectedMarkerId(state) ||
    getSelectedImageId(state) ||
    getSelectedMethodNodeId(state) ||
    getSelectedMethodEdgeId(state) ||
    getSelectedEdge(state) ||
    getShowingFigures(state) ||
    getShowingSearch(state) ? true : false;
}
  

export const getContextWidth = (/*state*/) => {
  return 348;
}
  

export const getActivePatentSpecificationEditorSection = (state) =>
  get(getUiState(state), `activePatentSpecificationEditorSection`);

export const getActiveInventionSummarySection = (state) =>
  get(getUiState(state), `activeInventionSummaryEditorSection`);

export const getShowingPatentSpecificationEditor = (state) =>
  get(getUiState(state), `showingPatentSpecificationEditor`);

export const getShowingPatentSpecificationDetailedDescription = (state) =>
  get(getUiState(state), `showingPatentSpecificationDetailedDescription`);

export const getShowingPatentSpecificationClaims = (state) =>
  get(getUiState(state), `showingPatentSpecificationClaims`);

export const getShowingPatentSpecificationAbstract = (state) =>
  get(getUiState(state), `showingPatentSpecificationAbstract`);

export const getSelectedMarkerId = (state) => {
  const selectedMarkers = getSelectedMarkers(state);
  return selectedMarkers && selectedMarkers.length === 1 && selectedMarkers[0];
};

export const getCollapsedNodesList = (state, productId) => {
  productId = productId || 'root';
  return (
    get(getUiState(state), `collapsedNodes.${productId}.collapsedNodesList`) ||
    []
  );
};

export const getCollapsedDescendantsList = (state, productId) => {
  productId = productId || 'root';
  return (
    get(getUiState(state), `collapsedNodes.${productId}.collapsedDescendantsList`) ||
    []
  );
};
