import Component from '@glimmer/component';
import { action } from '@ember/object';
import { connect } from 'ember-redux';
import { getMilestoneType } from '../../../selectors/type';
import { getProduct } from '../../../selectors/product';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';

const dispatchToActions = {};

const stateToComputed = (state, attrs) => ({
  milestoneType: getMilestoneType(attrs.milestoneTypeId),
  product: getProduct(state, attrs.productId),
});

class MilestoneContext extends Component {
  @service data;
  @service tracking;

  get styleNamespace() {
    return podNames['milestone-context'];
  }

  get classNames() {
    let classNames = [this.styleNamespace, 'milestone-context'];
    return classNames.join(' ');
  }
  @action
  didInsert() {
    this.trackVisit();
  }

  @action
  onUpdate() {
    this.trackVisit();
  }

  trackVisit() {
    if (this.milestoneTypeId !== this.args.milestoneTypeId) {
      this.milestoneTypeId = this.args.milestoneTypeId;
      this.tracking.trackEvent(`visited_milestone_${this.args.milestoneTypeId}`)
    }
  }

  @action
  onUpdateProduct(productId, attributes = {}) {
    if (this.args.onUpdateProduct) {
      this.args.onUpdateProduct(productId, attributes);
    }
  }
}

export default connect(stateToComputed, dispatchToActions)(MilestoneContext);
