import Model, { attr } from '@ember-data/model';

export default class InstanceModel extends Model {
  @attr versions;
  @attr assets;
  @attr assetIds;
  @attr disclosureId;
  @attr createdAt;

  get namedVersions() {
    return this.versions && this.versions.filter((version) => version.name);
  }

  get mostRecentVersion() {
    return this.versions && this.versions.length && this.versions[0];
  }
}
