import Service from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class Clipboard extends Service {
  @tracked elementId = null;
  @tracked elementVersionId = null;
  @tracked instancesList = [];
  @tracked pastePropertiesIndex = 0;

  copyElement(elementId, elementVersionId) {
    this.clear();
    this.elementId = elementId;
    this.elementVersionId = elementVersionId;
  }

  copyElementVersion(elementVersionId) {
    this.clear();
    this.elementVersionId = elementVersionId;
  }

  copyInstances(instancesList) {
    this.clear();
    this.instancesList = instancesList;
  }

  clear() {
    this.elementId = null;
    this.elementVersionId = null;
    this.instancesList = [];
  }
}
