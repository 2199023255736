export default {
  attributes: {
    name: {
      type: 'string',
      defaultValue: ''
    },
    asset: {
      type: 'uuid',
      defaultValue: ''
    },
    description: {
      type: 'string',
      defaultValue: ''
    },
    link: {
      type: 'string',
      defaultValue: ''
    },
    conceivedAt: {
      type: 'date',
      defaultValue: null
    }
  }
};




