import { HIGHLIGHT_FILL, HIGHLIGHT_STROKE } from '../constants/colors';
import { blobUrlToBase64JPEG, getCanvasBlob } from '../utils/image';
// const defaultArtboardWidth = 2000;
// const defaultArtboardHeight = 2000;
import {
  defaultArtboardHeight,
  defaultArtboardWidth,
} from '../constants/settings';
import { enqueueTask, task } from 'ember-concurrency-decorators';
// import { getElementOrdinal, getTermOrdinal } from '../selectors/invention';
import { max, maxBy, min, minBy } from 'lodash';

import ENV from '../config/environment';
import Konva from 'konva';
import Service from '@ember/service';
import { getAutoLabelOrientation } from '../utils/drawing';
import { getDrawing } from '../selectors/drawing';
import { getHighlight } from '../selectors/highlight';
import { getImage } from '../selectors/image';
import { getMarker } from '../selectors/marker';
import moment from 'moment';
import { reverse } from 'lodash';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class ReferencesCache extends Service {
  @service worker;
  @service assets;
  @service imageFiltersCache;
  @service redux;
  @service figuresCacheKonva;

  @tracked blobUrlCache;
  @tracked dataUrlCache;

  isTest = ENV.environment === 'test';

  maxWorkers = 4;
  workerIndex = 0;

  constructor(owner, args) {
    super(owner, args);
    this.blobUrlCache = {};
    this.dataUrlCache = {};
  }

  getMarkerLabelNodeHeight(lines) {
    let height = 20;
    switch (lines) {
      case 1:
        height = 20;
        break;
      case 2:
        height = 45;
        break;
      case 3:
        height = 70;
        break;
      case 4:
        height = 95;
        break;
      case 5:
        height = 120;
        break;
      case 6:
        height = 145;
        break;
    }
    return height;
  }

  getLabelOffset({
    nodeHeight,
    labelWidth,
    startRadius,
    labelOrientation,
    startY,
    midY,
    endY,
  }) {
    const padding = 13;
    const orientation =
      labelOrientation === 'auto'
        ? getAutoLabelOrientation({
            startY,
            endY,
            midY,
          })
        : labelOrientation;

    let offset;

    switch (orientation) {
      case 'top':
        offset = {
          x: labelWidth / 2,
          y: padding + startRadius + nodeHeight,
        };
        break;
      case 'bottom':
        offset = {
          x: labelWidth / 2,
          y: -1 * (padding + startRadius),
        };
        break;
      case 'left':
        offset = {
          x: padding + startRadius + labelWidth,
          y: nodeHeight / 2,
        };
        break;
      case 'right':
        offset = {
          x: -1 * (padding + startRadius),
          y: nodeHeight / 2,
        };
        break;
    }
    return offset;
  }

  getMarkerNode(marker) {
    const {
      pointStyle,
      curve,
      startX,
      startY,
      midX,
      midY,
      endX,
      endY,
      labelOrientation,
      // ordinal,
    } = marker;

    const label = '';
    // const label = ordinal;
    const hasArrow = pointStyle === 'arrow';
    const arrowWidth = 15;
    const arrowLength = 20;
    const isCurved = curve !== 'linear';
    const tension = 0.5;
    const hasLabel = label ? true : false;
    const labelWidth = 240;
    const startRadius = 0;
    const fontFamily = 'Inter';
    const fontSize = 20;

    const markerNode = new Konva.Group({
      id: marker.id,
    });

    const arrowNode = new Konva.Arrow({
      points: [startX, startY, midX, midY, endX, endY],
      pointerWidth: hasArrow ? arrowWidth : 0,
      pointerLength: hasArrow ? arrowLength : 0,
      bezier: isCurved ? true : false,
      tension: isCurved ? tension : 0,
      fill: '#000000',
      stroke: '#000000',
      strokeWidth: 2,
    });

    const labelNode = new Konva.Group({
      visible: hasLabel,
      x: startX,
      y: startY,
    });

    const labelTextNode = new Konva.Text({
      width: labelWidth,
      ellipsis: true,
      text: label,
      align: 'center',
      fontSize: fontSize,
      lineHeight: 1.235,
      fontFamily: fontFamily,
      fill: '#000000',
      padding: 0,
      listening: false,
    });

    const nodeHeight = this.getMarkerLabelNodeHeight(
      labelTextNode.textArr.length
    );

    labelNode.offset(
      this.getLabelOffset({
        nodeHeight,
        labelWidth,
        startRadius,
        labelOrientation,
        startY,
        midY,
        endY,
      })
    );
    labelNode.add(labelTextNode);

    markerNode.add(arrowNode);
    markerNode.add(labelNode);

    return markerNode;
  }

  getMarkerWidth(marker) {
    const padding = 40;
    const points = [marker.startX, marker.midX, marker.endX];
    const minX = min(points);
    const maxX = max(points);
    const width = Math.abs(minX - maxX);
    return width + padding;
  }

  getMarkerHeight(marker) {
    const padding = 40;
    const points = [marker.startY, marker.midY, marker.endY];
    const minY = min(points);
    const maxY = max(points);
    const height = Math.abs(minY - maxY);
    return height + padding;
  }

  getHighlightsBounds(highlights) {
    let top;
    let bottom;
    let left;
    let right;

    highlights.forEach((highlight, index) => {
      const pointsArray = highlight.points.split(' ').map((pointString) => {
        const pointArray = pointString.split(',');
        const x = Number(pointArray[0]);
        const y = Number(pointArray[1]);
        return { x, y };
      });

      const _top = minBy(pointsArray, (point) => point.y).y;
      const _left = minBy(pointsArray, (point) => point.x).x;
      const _right = maxBy(pointsArray, (point) => point.x).x;
      const _bottom = maxBy(pointsArray, (point) => point.y).y;

      if (index === 0) {
        top = _top;
        left = _left;
        right = _right;
        bottom = _bottom;
      } else {
        top = Math.min(_top, top);
        left = Math.min(_left, left);
        right = Math.max(_right, right);
        bottom = Math.max(_bottom, bottom);
      }
    });

    const width = Math.abs(right - left);
    const height = Math.abs(bottom - top);
    const x = left + width / 2;
    const y = top + height / 2;

    return {
      top,
      bottom,
      left,
      right,
      width,
      height,
      x,
      y,
    };
  }

  getArtboardDimensions(orientation) {
    const artboardWidth =
      orientation === 'portrait' ? defaultArtboardWidth : defaultArtboardHeight;
    const artboardHeight =
      orientation === 'portrait' ? defaultArtboardHeight : defaultArtboardWidth;

    return {
      artboardWidth,
      artboardHeight,
    };
  }

  getStageDimensions(orientation) {
    const stageWidth =
      orientation === 'portrait' ? defaultArtboardWidth : defaultArtboardHeight;
    const stageHeight =
      orientation === 'portrait' ? defaultArtboardHeight : defaultArtboardWidth;

    return {
      stageWidth,
      stageHeight,
    };
  }

  getImageElement(blobUrl) {
    return new Promise((resolve, reject) => {
      const assetFile = new Image();

      assetFile.onload = () => {
        resolve(assetFile);
      };

      assetFile.onerror = () => {
        reject('Error building image element');
      };
      assetFile.src = blobUrl;
    });
  }

  async getImageNode(image) {
    const { x, y, width, height } = image;
    const assetNode = new Konva.Image({
      x,
      y,
      width,
      height,
      rotation: image.rotation ? image.rotation : 0,
    });
    const imageElement = await this.getImageElement(image.blobUrl);

    assetNode.image(imageElement);
    // assetNode.cache();
    // assetNode.filters([ThresholdFilter]);
    // assetNode.threshold(50);

    return assetNode;
  }

  getHighlightNode(highlight, scale) {
    const strokeWidth = 30;
    const points = [];
    const pointsArray = highlight.points.split(' ').map((pointString) => {
      const pointArray = pointString.split(',');
      const x = Number(pointArray[0]);
      const y = Number(pointArray[1]);
      return { x, y };
    });
    pointsArray.forEach((point) => {
      points.push(point.x);
      points.push(point.y);
    });

    const lineNode = new Konva.Line({
      points: points,
      fill: HIGHLIGHT_FILL,
      stroke: HIGHLIGHT_STROKE,
      strokeWidth: Math.floor(strokeWidth / scale),
      closed: true,
    });

    return lineNode;
  }

  @enqueueTask({ maxConcurrency: 6 })
  *getReferenceBlob(markerId, drawing/*, forceScale*/) {
    const state = this.redux.getState();
    const shouldScale = true;
    const orientation = drawing.orientation;
    const marker = {
      ...getMarker(state, markerId),
    };

    // const elementOrdinal =
    //   marker.element &&
    //   marker.elementVersion &&
    //   getElementOrdinal(state, marker.element, marker.elementVersion);
    // const termOrdinal = marker.term && getTermOrdinal(state, marker.term);
    // const ordinal = marker.type === 'element' ? elementOrdinal : termOrdinal;
    // marker.ordinal = ordinal;

    marker.ordinal = '';

    const { artboardWidth, artboardHeight } =
      this.getArtboardDimensions(orientation);
    const { stageWidth, stageHeight } = this.getStageDimensions(orientation);
    const highlights = marker.highlightsList.map((highlightId) => {
      return getHighlight(state, highlightId);
    });
    const imagesList = [...drawing.imagesList];
    const reverseImagesList = reverse(imagesList);
    const images = [];

    for (let i = 0; i < reverseImagesList.length; i++) {
      const imageId = reverseImagesList[i];
      const image = getImage(state, imageId);

      if (image.asset) {
        const blob = yield this.imageFiltersCache.getBlob.perform(imageId);
        const blobUrl = yield this.imageFiltersCache.getBlobUrl.perform(imageId);
  
        images.push({
          ...image,
          blob,
          blobUrl,
        });
      }
    }

    let boundsWidth;
    let boundsHeight;
    let boundsX;
    let boundsY;

    if (highlights.length) {
      const highlightsBounds = this.getHighlightsBounds(highlights);
      boundsWidth = highlightsBounds.width;
      boundsHeight = highlightsBounds.height;
      boundsX = highlightsBounds.x;
      boundsY = highlightsBounds.y;
    } else {
      boundsWidth = this.getMarkerWidth(marker);
      boundsHeight = this.getMarkerWidth(marker);
      boundsX = boundsWidth / 2;
      boundsY = boundsHeight / 2;
    }

    const width = Math.min(artboardWidth, artboardHeight);
    // const width = artboardWidth;
    // const height = artboardHeight;

    const padding = 150;

    // let scale = Math.min(
    //   width / (boundsWidth + padding),
    //   height / (boundsHeight + padding)
    // );

    let scale = Math.min(
      width / (boundsWidth + padding),
      width / (boundsHeight + padding)
    );

    // if (forceScale) {
      // scale = forceScale
    // }

    // scale = 1
    // boundsX = 0
    // boundsY = 0

    const markers = [marker];

    const data = {
      stageWidth,
      stageHeight,
      scale,
      boundsX,
      boundsY,
      artboardWidth,
      artboardHeight,
      images,
      highlights,
      markers,
      shouldScale
    };

    const canOffscreenCanvas = typeof OffscreenCanvas !== 'undefined';

    let blob;

    if (canOffscreenCanvas) {
      this.workerIndex++;
      
      if (this.workerIndex >= this.maxWorkers) {
        this.workerIndex = 0;
      }

      let worker;

      if (this[`workerInstance${this.workerIndex}`]) {
        worker = this[`workerInstance${this.workerIndex}`];
      } else {
        worker = yield this.worker.open('konva-reference-image');
        this[`workerInstance${this.workerIndex}`] = worker;
      }

      blob = yield worker.postMessage(data);

      if (this.isTest) {
        worker.terminate();
        this[`workerInstance${this.workerIndex}`] = null;
      }

    } else {
      blob = yield this.getCanvasBlob(data);
    }

    return blob;
  }

  async getCanvasBlob(data) {
    const {
      stageWidth,
      stageHeight,
      scale,
      boundsX,
      boundsY,
      artboardWidth,
      artboardHeight,
      images,
      highlights,
      markers,
    } = data;
    const container = document.createElement('div');

    const stage = new Konva.Stage({
      container,
      x: stageWidth / 2,
      y: stageHeight / 2,
      width: stageWidth,
      height: stageHeight,
      scaleX: scale,
      scaleY: scale,
      offset: {
        x: boundsX,
        y: boundsY,
      },
    });

    const layer = new Konva.Layer({ name: 'images' });

    stage.add(layer);

    const group = new Konva.Group({
      x: stageWidth / 2,
      y: stageHeight / 2,
      offset: {
        x: stageWidth / 2,
        y: stageHeight / 2,
      },
    });

    layer.add(group);

    const artboardNode = new Konva.Rect({
      name: 'artboard',
      x: 0,
      y: 0,
      fill: '#FFFFFF',
      width: artboardWidth,
      height: artboardHeight,
      offset: {
        x: artboardWidth / 2,
        y: artboardHeight / 2,
      },
    });

    group.add(artboardNode);

    const imageNodes = await Promise.all(
      images.map(async (image) => {
        return await this.getImageNode(image);
      })
    );
    imageNodes.forEach((imageNode) => group.add(imageNode));

    const highlightNodes = highlights.map((highlight) => {
      return this.getHighlightNode(highlight, scale);
    });
    highlightNodes.forEach((highlightNode) => group.add(highlightNode));

    const markerNodes = markers.map((marker) => {
      return this.getMarkerNode(marker);
    });
    markerNodes.forEach((markerNode) => group.add(markerNode));

    // const circleNode = new Konva.Circle({
    //   name: 'circle',
    //   x: boundsX,
    //   y: boundsY,
    //   fill: '#FF0000',
    //   radius: 20,
    // });
    // group.add(circleNode);

    const canvas = stage._toKonvaCanvas()._canvas;
    const blob = await getCanvasBlob(canvas);

    stage.destroy();

    return blob;
  }

  async cacheUrls(referenceId) {
    const drawing = this.getReferenceDrawing(referenceId);
    const updatedAt = drawing.updatedAt || new Date();
    const blob = await this.getReferenceBlob.perform(referenceId, drawing);

    const blobUrl = URL.createObjectURL(blob);

    this.blobUrlCache[referenceId] = {
      blobUrl,
      updatedAt,
    };
  }

  getReferenceDrawing(referenceId) {
    const state = this.redux.getState();
    const marker = getMarker(state, referenceId);
    return marker && marker.drawing && getDrawing(state, marker.drawing);
  }

  @task
  *getBlobUrl(referenceId) {
    let blobUrl;
    const drawing = this.getReferenceDrawing(referenceId);

    if (!drawing) {
      return
    }
    const updatedAt = drawing.updatedAt;

    if (
      this.blobUrlCache[referenceId] &&
      moment(updatedAt).isSame(this.blobUrlCache[referenceId].updatedAt)
    ) {
      blobUrl = this.blobUrlCache[referenceId].blobUrl;
    } else {
      try {
        const blob = yield this.getReferenceBlob.perform(referenceId, drawing);
        blobUrl = URL.createObjectURL(blob);
        this.blobUrlCache[referenceId] = {
          blobUrl,
          updatedAt,
        };
      } catch (err) {
        console.error(err);
      }
    }

    return blobUrl;
  }

  async getDataUrl(referenceId) {
    let dataUrl;
    const drawing = this.getReferenceDrawing(referenceId);
    const updatedAt = drawing.updatedAt;
    if (
      this.dataUrlCache[referenceId] &&
      moment(updatedAt).isSame(this.dataUrlCache[referenceId].updatedAt)
    ) {
      dataUrl = this.dataUrlCache[referenceId].dataUrl;
    } else {
      try {
        const blob = await this.getReferenceBlob.perform(referenceId, drawing);
        const blobUrl = URL.createObjectURL(blob);

        dataUrl = await blobUrlToBase64JPEG(
          blobUrl,
          defaultArtboardWidth / 2,
          defaultArtboardHeight / 2
        );

        this.dataUrlCache[referenceId] = {
          dataUrl,
          updatedAt,
        };
      } catch (err) {
        console.error(err);
      }
    }
    return dataUrl;
  }
}
