import {
  BLACK_AND_WHITE_COLOR,
  BLACK_AND_WHITE_FILL,
  BLACK_AND_WHITE_STROKE,
  ELEMENT_COLLAPSED_NODE_STROKE,
  ELEMENT_NODE_COLOR,
  ELEMENT_NODE_FILL,
  ELEMENT_NODE_FILL_LIGHT,
  ELEMENT_NODE_GREY_COLOR,
  ELEMENT_NODE_GREY_FILL,
  ELEMENT_NODE_GREY_FILL_LIGHT,
  ELEMENT_NODE_GREY_STROKE,
  ELEMENT_NODE_GREY_STROKE_LIGHT,
  ELEMENT_NODE_HEADER_COLOR,
  ELEMENT_NODE_NOVELTY_STROKE,
  ELEMENT_NODE_NOVELTY_STROKE_LIGHT,
  ELEMENT_NODE_SELECTED_CHILD_STROKE,
  ELEMENT_NODE_SELECTED_CHILD_STROKE_DARK,
  ELEMENT_NODE_STROKE,
  ELEMENT_VERSION_CATEGORY_ARTICLE,
  ELEMENT_VERSION_CATEGORY_ARTICLE_LIGHT,
  ELEMENT_VERSION_CATEGORY_ARTICLE_SELECTED,
  ELEMENT_VERSION_CATEGORY_ARTICLE_SELECTED_DARK,
  ELEMENT_VERSION_CATEGORY_COMPOSITION,
  ELEMENT_VERSION_CATEGORY_COMPOSITION_LIGHT,
  ELEMENT_VERSION_CATEGORY_COMPOSITION_SELECTED,
  ELEMENT_VERSION_CATEGORY_COMPOSITION_SELECTED_DARK,
  ELEMENT_VERSION_CATEGORY_MACHINE,
  ELEMENT_VERSION_CATEGORY_MACHINE_LIGHT,
  ELEMENT_VERSION_CATEGORY_MACHINE_SELECTED,
  ELEMENT_VERSION_CATEGORY_MACHINE_SELECTED_DARK,
  ELEMENT_VERSION_CATEGORY_PROCESS,
  ELEMENT_VERSION_CATEGORY_PROCESS_LIGHT,
  ELEMENT_VERSION_CATEGORY_PROCESS_SELECTED,
  ELEMENT_VERSION_CATEGORY_PROCESS_SELECTED_DARK,
  ELEMENT_VERSION_NODE_STROKE,
  PLACEHOLDER_FILL,
  PLACEHOLDER_FILL_DARK,
  PLACEHOLDER_STROKE,
  PLACEHOLDER_STROKE_DARK,
  PLACEHOLDER_TEXT,
  PLACEHOLDER_TEXT_DARK,
  WARNING_NODE_FILL,
  WARNING_NODE_STROKE,
} from '../../../constants/colors';
import { COMPONENT_PATH, INSTANCE_PATH } from '../../../constants/icon-paths';
import { action, computed } from '@ember/object';
import { alias, and, bool, gt, not } from '@ember/object/computed';
import { getElement, getElementParent } from '../../../selectors/element';

/* eslint-disable ember/use-brace-expansion */
import Component from '@glimmer/component';
import ENV from '../../../config/environment';
import Konva from 'konva';
import { connect } from 'ember-redux';
import { getActiveFeature } from '../../../selectors/invention-ui';
import { getComponentInstanceOf } from '../../../selectors/component';
import { getDrawing } from '../../../selectors/drawing';
import { getElementVersion } from '../../../selectors/element-version';
import { getMarker } from '../../../selectors/marker';
import { getMethod } from '../../../selectors/method';
import { getMethodNode } from '../../../selectors/method-node';
import { getMethodNodeOrdinal } from '../../../selectors/invention';
import { getProduct } from '../../../selectors/product';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency-decorators';
import { textValue } from '../../../utils/string';

// import { getPreferredElementVersion } from '../../../selectors/product';

const dispatchToActions = {};

const stateToComputed = (state, attrs) => ({
  parent: getElementParent(state, attrs.elementId),
  element: getElement(state, attrs.elementId),
  instanceOf: getComponentInstanceOf(state, attrs.componentId),
  activeFeatureId: getActiveFeature(state),
  product: getProduct(state, attrs.productId),
});

class InventionGraphSystem extends Component {
  @service redux;
  @service referencesCache;
  @service methodsCacheKonva;
  @service models;
  @service store;
  @service settings;

  fontFamily = 'Inter';
  textHeight = 105;
  spacer = 26;
  outcomePlaceholderHeight = 125;
  verticalPadding = 19.5;
  horizontalPadding = 19.5;
  blackAndWhiteModeStroke = 6;
  blackAndWhiteModeFontWeight = '600';
  borderRadius = 26;
  radius = 200;
  height = 400;
  width = 400;
  _isNovel = false;
  _isNovelAncestor = false;
  _isCollapsed = false;
  _visibleAreaIndex = 0;
  _elementIsSelected = '';
  _x = 0;
  _y = 0;
  _reachedScaleThreshold = false;

  constructor(owner, args) {
    super(owner, args);
    this.onScheduleRender = this.args.onScheduleRender;
    this.elementsLayer = this.args.elementsLayer;
    this.interactiveLayer = this.args.interactiveLayer;
  }

  @action
  didInsert() {
    this._previewMode = this.previewMode;
    this._showArrow = this.showArrow;
    this._isNovel = this.args.isNovel;
    this._isNovelAncestor = this.args.isNovelAncestor;
    this._isDisconnected = this.args.isDisconnected;
    this._isCollapsed = this.args.isCollapsed;
    this._visibleAreaIndex = this.args.visibleAreaIndex;
    this._elementIsSelected = this.elementIsSelected;
    this._elementVersionIsSelected = this.elementVersionIsSelected;
    this._isSelectedChild = this.args.isSelectedChild;
    this._x = this.args.x;
    this._y = this.args.y;
    this._reachedScaleThreshold = this.args.reachedScaleThreshold;
    this._solutionsString = this.solutionsString;
    this._referenceId = this.referenceId;
    this._referenceUpdatedAt = this.referenceUpdatedAt;
    this._name = this.name;
    this._showElementPlaceholder = this.showElementVersionPlaceholder;
    this._showElementVersion = this.showElementVersion;
    this._showElementVersionTwo = this.showElementVersionTwo;
    this._showElementVersionThree = this.showElementVersionThree;
    this._outcome = this.outcome;
    this._outcomeIsTraversed = this.outcomeIsTraversed;
    this._methodNodeOrdinal = this.methodNodeOrdinal;
    this._showOutcome = this.showOutcome;
    this._showDrawing = this.showDrawing;
    this._showDrawingPlaceholder = this.showDrawingPlaceholder;
    this._showMethodPlaceholder = this.showMethodPlaceholder;
    this._showMethod = this.showMethod;
    this._methodUpdatedAt = this.methodUpdatedAt;
    this._elementVersionIsKnown = this.known;
    this._elementVersionCategory = this.elementVersionCategory;
    this._elementVersionIsSelected = this.elementVersionIsSelected;
    this.activeLayer = this.args.isSelected
      ? this.interactiveLayer
      : this.elementsLayer;
    this._isPrimaryInstance = this.args.isPrimaryInstance;
    this._isInstance = this.isInstance;
    this._darkMode = this.settings.darkMode;
    this._blackAndWhiteMode = this.settings.blackAndWhiteMode;
    this.setup();
  }

  @action
  willDestroyNode() {
    // remove events
    if (this.args.onContextClick) {
      this.elementNode.off('contextmenu');
    }
    if (this.args.onClick) {
      this.elementNode.off('click');
    }
    if (this.args.onDragStart) {
      this.elementNode.off('dragstart');
    }
    if (this.args.onDragMove) {
      this.elementNode.off('dragmove');
    }
    if (this.args.onDragEnd) {
      this.elementNode.off('dragend');
    }
    if (this.args.onMouseenter) {
      this.elementNode.off('mouseenter');
    }
    if (this.args.onMouseleave) {
      this.elementNode.off('mouseleave');
    }
    this.elementNode.destroy();
    this.onScheduleRender();
  }

  get styleNamespace() {
    return podNames['invention-graph-element-konva'];
  }

  get classNames() {
    let classNames = [this.styleNamespace];
    if (this.args.isSelected) classNames.push('is-selected');
    if (this.args.isCollapsed) classNames.push('is-collapsed');
    if (this.args.isDisconnected) classNames.push('is-disconnected');
    return classNames.join(' ');
  }

  @gt('args.elementVersionsList', 1) hasMultipleElementVersions;

  @alias('args.name') name;
  @alias('args.preferredElementVersion') elementVersion;
  @alias('elementVersion.category') elementVersionCategory;
  @alias('elementVersionTwo.category') elementVersionTwoCategory;
  @alias('elementVersionThree.category') elementVersionThreeCategory;
  @alias('args.parentElementVersionId') parentElementVersionId;
  @alias('args.preferredElementVersionId') elementVersionId;
  @alias('args.isInstance') isInstance;
  @alias('args.outcome') outcome;
  @alias('hasOutcome') showOutcome;

  @bool('args.productId') hasActiveProduct;
  @bool('elementVersion.known') elementVersionIsKnown;
  @bool('args.preferredElementVersionId') hasElementVersion;
  @bool('elementVersionTwo') hasElementVersionTwo;
  @bool('elementVersionThree') hasElementVersionThree;
  @bool('referenceId') hasReference;
  @bool('hasReference') showDrawing;
  @bool('outcome') hasOutcome;
  @bool('methodHasNodes') showMethod;

  @not('showDrawing') showDrawingPlaceholder;
  @not('elementVersionIsKnown') elementVersionIsntKnown;
  @not('hasElementVersion') hasNoElementVersion;
  @not('showOutcome') showOutcomePlaceholder;
  @not('showMethod') showMethodPlaceholder;

  @and('hasActiveProduct', 'hasElementVersion') showElementVersion;
  @and('hasActiveProduct', 'hasNoElementVersion')
  showElementVersionPlaceholder;
  @and('hasActiveProduct', 'hasElementVersionTwo')
  showElementVersionTwo;
  @and('hasActiveProduct', 'hasElementVersionThree')
  showElementVersionThree;

  @computed('parentElementVersionId')
  get parentElementVersion() {
    const state = this.redux.getState();
    return (
      this.parentElementVersionId &&
      getElementVersion(state, this.parentElementVersionId)
    );
  }

  @computed('parentElementVersion.method')
  get parentMethodId() {
    return this.parentElementVersion && this.parentElementVersion.method;
  }

  @computed('parentMethodId')
  get parentMethodModel() {
    return (
      this.parentMethodId &&
      this.store.peekRecord('method', this.parentMethodId)
    );
  }

  @computed('parentMethodModel.updatedAt')
  get parentMethodUpdatedAt() {
    return this.parentMethodModel && this.parentMethodModel.updatedAt;
  }

  @computed(
    'parentMethodId',
    'methodNodeId',
    'outcomeIsTraversed',
    'parentMethodModel.traversedNodesList.[]'
  )
  get methodNodeOrdinal() {
    const state = this.redux.getState();
    return (
      this.outcomeIsTraversed &&
      this.methodNodeId &&
      getMethodNodeOrdinal(
        state,
        this.parentMethodId,
        this.methodNodeId,
        this.parentMethodModel.traversedNodesList
      )
    );
  }

  @computed('args.elementId', 'parentMethodModel.methodNodesList.[]')
  get methodNodeId() {
    const state = this.redux.getState();
    return (
      this.parentMethodModel &&
      this.parentMethodModel.methodNodesList.find((methodNodeId) => {
        const methodNode = getMethodNode(state, methodNodeId);
        return methodNode.element === this.args.elementId;
      })
    );
  }

  @computed('parent.category')
  get isTopLevel() {
    return this.parent && this.parent.category === 'product';
  }

  @computed('args.elementId', 'parentMethodModel.traversedNodesList.[]')
  get outcomeIsTraversed() {
    const state = this.redux.getState();
    return this.parentMethodModel &&
      this.parentMethodModel.traversedNodesList.find((methodNodeId) => {
        const methodNode = getMethodNode(state, methodNodeId);
        return methodNode.element === this.args.elementId;
      })
      ? true
      : false;
  }

  @computed('outcomeIsTraversed', 'isTopLevel')
  get outcomeDashEnabled() {
    let outcomeDashEnabled = this.outcomeIsTraversed ? false : true;

    if (this.isTopLevel) {
      outcomeDashEnabled = false;
    }

    return outcomeDashEnabled;
  }

  get outcomeStrokeColor() {
    return this.textColor;
  }

  get outcomeFillColor() {
    return '#FFFFFF';
  }

  @computed('elementVersionId', 'args.elementVersionsList.[]')
  get solutionsString() {
    return (
      this.args.elementVersionsList &&
      this.elementVersionId &&
      this.elementVersionId + this.args.elementVersionsList.join(',')
    );
  }

  @computed('args.elementVersionsList', 'elementVersionId')
  get elementVersionTwo() {
    const state = this.redux.getState();
    const elementVersionsList = this.args.elementVersionsList.filter(
      (elementVersionId) => elementVersionId !== this.elementVersionId
    );
    const elementVersionTwoId = elementVersionsList[0];
    return elementVersionTwoId && getElementVersion(state, elementVersionTwoId);
  }

  @computed('args.elementVersionsList', 'elementVersionId')
  get elementVersionThree() {
    const state = this.redux.getState();
    const elementVersionsList = this.args.elementVersionsList.filter(
      (elementVersionId) => elementVersionId !== this.elementVersionId
    );
    const elementVersionThreeId = elementVersionsList[1];
    return (
      elementVersionThreeId && getElementVersion(state, elementVersionThreeId)
    );
  }

  @computed('args.{isSelected,productId}', 'elementVersionIsSelected')
  get elementIsSelected() {
    let elementIsSelected = this.args.isSelected;

    if (this.args.productId) {
      elementIsSelected =
        this.args.isSelected && !this.elementVersionIsSelected;
    }
    return elementIsSelected;
  }

  @computed(
    'args.selectedElementVersions.[]',
    'elementVersionId',
    'args.isSelected'
  )
  get elementVersionIsSelected() {
    return this.elementVersionId &&
      // this.args.isSelected &&
      this.args.selectedElementVersions &&
      this.args.selectedElementVersions.includes(this.elementVersionId)
      ? true
      : false;
  }

  @computed('elementVersion.markersList.[]')
  get referenceId() {
    return (
      this.elementVersion &&
      this.elementVersion.markersList &&
      this.elementVersion.markersList.length &&
      this.elementVersion.markersList[0]
    );
  }

  @computed('args.{drawings,methods}', 'referenceId')
  get referenceSource() {
    let source;
    const state = this.redux.getState();
    const marker = this.referenceId && getMarker(state, this.referenceId);
    if (marker) {
      source = getDrawing(state, marker.drawing);
    }
    return source;
  }

  @computed('referenceSource.updatedAt', 'referenceId')
  get referenceUpdatedAt() {
    return (
      this.referenceId && this.referenceSource && this.referenceSource.updatedAt
    );
  }

  @computed('args.{elementId,nodesMap}')
  get siblings() {
    const id = this.args.elementId;
    const node = this.args.nodesMap && this.args.nodesMap[id];
    const siblings =
      node && node.parent && this.args.nodesMap[node.parent].children;
    return siblings;
  }

  @computed('siblings.[]')
  get hasSiblings() {
    return this.siblings && this.siblings.length > 1;
  }

  @computed('siblings.[]', 'args.elementId')
  get isLastSibling() {
    const index = this.siblings && this.siblings.indexOf(this.args.elementId);
    const length = this.siblings && this.siblings.length;
    return length && index === length - 1;
  }

  @computed('hasSiblings', 'isLastSibling', 'siblings.length')
  get showArrow() {
    const isLoneSibling = this.siblings.length === 1;
    return isLoneSibling || (this.hasSiblings && !this.isLastSibling)
      ? true
      : false;
  }

  @computed('elementVersionId')
  get hasSolution() {
    return this.elementVersionId ? true : false;
  }

  // @computed('elementVersionId', 'args.elementVersionsList.[]')
  // get hasTwoSolutions() {
  //   return this.elementVersionId &&
  //     this.args.elementVersionsList &&
  //     this.args.elementVersionsList.length >= 2
  //     ? true
  //     : false;
  // }

  // @computed('elementVersionId', 'args.elementVersionsList.[]')
  // get hasThreeOrMoreSolutions() {
  //   return this.elementVersionId &&
  //     this.args.elementVersionsList &&
  //     this.args.elementVersionsList.length >= 3
  //     ? true
  //     : false;
  // }

  get textWidth() {
    return this.width - this.horizontalPadding * 2;
  }

  get elementVersionTextWidth() {
    return this.elementVersionTransform.width - this.horizontalPadding;
  }

  get collapsedTransform() {
    const x = 0;
    const y = this.height + 325;
    return { x, y };
  }

  get elementVersionTransform() {
    let x = 0;
    let y = 0;
    let width = 100;
    let height = 100;
    const padding = 168;
    return {
      x,
      y,
      width,
      height,
      padding,
    };
  }

  get elementVersionTwoTransform() {
    let x = -10;
    let y = -10;
    let width = 90;
    let height = 90;
    let rotation = -9;
    return {
      x,
      y,
      width,
      height,
      rotation,
    };
  }
  get elementVersionThreeTransform() {
    let x = -10;
    let y = -10;
    let width = 90;
    let height = 90;
    let rotation = -20;
    return {
      x,
      y,
      width,
      height,
      rotation,
    };
  }

  get drawingTransform() {
    let rotation = -11;
    let offset = {
      x: -40,
      y: 210,
    };
    let width = this.width / 2 + this.spacer / 2;
    let height = width + 5;
    let x = -1 * (width + this.spacer / 2);
    let y = 0;
    return { x, y, rotation, offset, width, height };
  }

  get outcomeTransform() {
    const x = 0;
    const y = 0;
    const width = 400;
    const offset = {
      x: this.width / 2,
      y: this.height / 2 - this.textHeight - this.spacer,
    };
    return { x, y, width, offset };
  }

  get outcomePlaceholderTransform() {
    const x = 0;
    const y = 0;
    const width = 400;
    const height = this.outcomePlaceholderHeight;
    const offset = {
      x: this.width / 2,
      y: this.height / 2 - this.textHeight - this.spacer,
    };
    return { x, y, width, height, offset };
  }

  get methodTransform() {
    let width = this.width / 2 + this.spacer / 2;
    let height = width * 1.319;
    let x = this.spacer / 2;
    let y = 0;
    let rotation = 11;
    let offset = {
      x: 40,
      y: 250,
    };

    return { x, y, rotation, offset, width, height };
  }

  get methodStrokeColor() {
    let color = this.placeholderStrokeColor;

    if (this.settings.blackAndWhiteMode) {
      color = BLACK_AND_WHITE_STROKE;
    }

    return color;
  }

  @computed('elementVersion.method')
  get methodId() {
    return this.elementVersion && this.elementVersion.method;
  }

  @computed('methodId')
  get methodModel() {
    return this.methodId && this.store.peekRecord('method', this.methodId);
  }

  @computed('methodModel.updatedAt')
  get methodUpdatedAt() {
    return this.methodModel && this.methodModel.updatedAt;
  }

  @computed('methodHasDisconnectNodes')
  get methodDashEnabled() {
    return this.methodHasDisconnectNodes ? true : false;
  }

  @computed('methodModel.disconnectedNodesList.[]')
  get methodHasDisconnectNodes() {
    return this.methodModel && this.methodModel.disconnectedNodesList.length
      ? true
      : false;
  }

  @computed('methodModel.methodNodesList.[]')
  get methodHasNodes() {
    return this.methodModel && this.methodModel.methodNodesList.length > 1;
  }

  getNameNodeY(lines) {
    const lineHeight = 35 * 1.1;
    const nodeHeight = 400;
    return nodeHeight / 2 - (lines * lineHeight) / 2;
  }

  getSolutionNameNodeY(nameNodeY, nameNodeLines) {
    const paddingTop = 10;
    const nameNodeHeight = nameNodeLines * (35 * 1.1);
    return paddingTop + nameNodeY + nameNodeHeight;
  }

  outcomeBackgroundNodeHeight(lines) {
    const headerHeight = 0;
    let height = this.outcomePlaceholderHeight;

    if (!this.hasOutcome) {
      lines = 0;
    }

    switch (lines) {
      case 1:
        height = 64;
        break;
      case 2:
        height = 96;
        break;
      case 3:
        height = 129;
        break;
      case 4:
        height = 162;
        break;
      case 5:
        height = 194;
        break;
      case 6:
        height = 227;
        break;
    }

    return height + headerHeight + 1;
  }

  get strokeColor() {
    let color = ELEMENT_NODE_STROKE;

    if (this.args.isDisconnected) {
      color = ELEMENT_NODE_GREY_STROKE;
    }
    if (this.settings.blackAndWhiteMode) {
      color = BLACK_AND_WHITE_STROKE;
    }
    return color;
  }

  get strokeColorLight() {
    let color = ELEMENT_COLLAPSED_NODE_STROKE;

    if (this.args.isCollapsed && this.args.isNovelAncestor) {
      color = ELEMENT_NODE_NOVELTY_STROKE_LIGHT;
    }
    if (this.args.isDisconnected) {
      color = ELEMENT_NODE_GREY_STROKE_LIGHT;
    }
    if (this.settings.blackAndWhiteMode) {
      color = BLACK_AND_WHITE_STROKE;
    }
    return color;
  }

  get strokeColorWarning() {
    let color = WARNING_NODE_STROKE;
    return color;
  }

  get noveltyNodeVisible() {
    return (
      this.args.isNovel || (this.args.isCollapsed && this.args.isNovelAncestor)
    );
  }

  get placeholderDash() {
    return [15, 10];
  }

  get placeholderFillColor() {
    let color = this.settings.darkMode
      ? PLACEHOLDER_FILL_DARK
      : PLACEHOLDER_FILL;

    if (this.settings.blackAndWhiteMode) {
      color = BLACK_AND_WHITE_FILL;
    }

    return color;
  }

  get placeholderStrokeColor() {
    let color = this.settings.darkMode
      ? PLACEHOLDER_STROKE_DARK
      : PLACEHOLDER_STROKE;

    if (this.settings.blackAndWhiteMode) {
      color = BLACK_AND_WHITE_STROKE;
    }

    return color;
  }

  get drawingStrokeColor() {
    let color = this.placeholderStrokeColor;

    if (this.settings.blackAndWhiteMode) {
      color = BLACK_AND_WHITE_STROKE;
    }

    return color;
  }

  get placeholderSelectedStrokeColor() {
    let color = this.settings.darkMode
      ? PLACEHOLDER_STROKE_DARK
      : PLACEHOLDER_STROKE;

    if (this.settings.blackAndWhiteMode) {
      color = BLACK_AND_WHITE_STROKE;
    }

    return color;
  }

  get placeholderTextColor() {
    let color = this.settings.darkMode
      ? PLACEHOLDER_TEXT_DARK
      : PLACEHOLDER_TEXT;

    if (this.settings.blackAndWhiteMode) {
      color = BLACK_AND_WHITE_COLOR;
    }

    return color;
  }

  get outcomeArrowFill() {
    return this.settings.darkMode ? PLACEHOLDER_STROKE_DARK : '#000';
  }

  get outcomeHeaderFill() {
    return this.settings.darkMode ? '#FFF' : '#000';
  }

  get fillColor() {
    let color = ELEMENT_NODE_FILL;

    if (this.args.isDisconnected) {
      color = ELEMENT_NODE_GREY_FILL;
    }
    if (this.settings.blackAndWhiteMode) {
      color = BLACK_AND_WHITE_FILL;
    }
    return color;
  }

  get selectedNodeFill() {
    let color = ELEMENT_NODE_STROKE;

    if (this.args.isDisconnected) {
      color = ELEMENT_NODE_GREY_FILL;
    }

    return color;
  }

  get fillColorLight() {
    let color = ELEMENT_NODE_FILL_LIGHT;

    if (this.args.isDisconnected) {
      color = ELEMENT_NODE_GREY_FILL_LIGHT;
    }
    if (this.settings.blackAndWhiteMode) {
      color = BLACK_AND_WHITE_FILL;
    }
    return color;
  }

  get fillColorCollapsed() {
    let color = '#FFF';

    if (this.args.isDisconnected) {
      color = ELEMENT_NODE_GREY_FILL_LIGHT;
    }

    if (this.settings.blackAndWhiteMode) {
      color = BLACK_AND_WHITE_FILL;
    }
    return color;
  }

  get strokeColorCollapsed() {
    let color = '#000';

    if (this.args.isDisconnected) {
      color = ELEMENT_NODE_GREY_STROKE;
    }

    if (this.settings.blackAndWhiteMode) {
      color = BLACK_AND_WHITE_STROKE;
    }
    return color;
  }

  get fillColorWarning() {
    let color = WARNING_NODE_FILL;
    if (this.args.isDisconnected) {
      color = ELEMENT_NODE_GREY_FILL_LIGHT;
    }
    if (this.settings.blackAndWhiteMode) {
      color = BLACK_AND_WHITE_FILL;
    }
    return color;
  }

  @computed(
    'elementVersionCategory',
    'args.isDisconnected',
    'settings.blackAndWhiteMode'
  )
  get elementVersionFillColor() {
    return this.getElementVersionFillColor(
      this.elementVersionCategory,
      this.args.isDisconnected
    );
  }

  @computed(
    'elementVersionTwoCategory',
    'args.isDisconnected',
    'settings.blackAndWhiteMode'
  )
  get elementVersionTwoFillColor() {
    return this.getElementVersionFillColor(
      this.elementVersionTwoCategory,
      this.args.isDisconnected
    );
  }

  @computed(
    'elementVersionThreeCategory',
    'args.isDisconnected',
    'args.isSelectedChild',
    'settings.blackAndWhiteMode'
  )
  get elementVersionThreeFillColor() {
    return this.getElementVersionFillColor(
      this.elementVersionThreeCategory,
      this.args.isDisconnected
    );
  }

  @computed(
    'elementVersionCategory',
    'args.isDisconnected',
    'settings.blackAndWhiteMode'
  )
  get elementVersionStrokeColor() {
    return this.getElementVersionStrokeColor(
      this.elementVersionCategory,
      this.args.isDisconnected
    );
  }

  @computed(
    'elementVersionCategory',
    'args.isDisconnected',
    'args.isSelectedChild',
    'settings.blackAndWhiteMode',
    'settings.settings.darkMode'
  )
  get elementVersionSelectedFillColor() {
    return this.getElementVersionSelectedFillColor(
      this.elementVersionCategory,
      this.args.isDisconnected
    );
  }

  @computed(
    'elementVersionTwoCategory',
    'args.isDisconnected',
    'settings.blackAndWhiteMode'
  )
  get elementVersionTwoStrokeColor() {
    return this.getElementVersionStrokeColor(
      this.elementVersionTwoCategory,
      this.args.isDisconnected
    );
  }

  @computed(
    'elementVersionThreeCategory',
    'args.isDisconnected',
    'settings.blackAndWhiteMode'
  )
  get elementVersionThreeStrokeColor() {
    return this.getElementVersionStrokeColor(
      this.elementVersionThreeCategory,
      this.args.isDisconnected
    );
  }

  getElementVersionFillColor(category, isDisconnected) {
    let color = ELEMENT_NODE_GREY_FILL;

    if (category === 'machine') {
      color = ELEMENT_VERSION_CATEGORY_MACHINE_LIGHT;
      if (this.elementVersionIsKnown) {
        // color = ELEMENT_VERSION_CATEGORY_MACHINE;
        color = ELEMENT_NODE_GREY_FILL;
      }
    }
    if (category === 'process') {
      color = ELEMENT_VERSION_CATEGORY_PROCESS_LIGHT;
      if (this.elementVersionIsKnown) {
        // color = ELEMENT_VERSION_CATEGORY_PROCESS;
        color = ELEMENT_NODE_GREY_FILL;
      }
    }
    if (category === 'article-of-manufacture') {
      color = ELEMENT_VERSION_CATEGORY_ARTICLE_LIGHT;
      if (this.elementVersionIsKnown) {
        // color = ELEMENT_VERSION_CATEGORY_ARTICLE;
        color = ELEMENT_NODE_GREY_FILL;
      }
    }
    if (category === 'composition') {
      color = ELEMENT_VERSION_CATEGORY_COMPOSITION_LIGHT;
      if (this.elementVersionIsKnown) {
        // color = ELEMENT_VERSION_CATEGORY_COMPOSITION;
        color = ELEMENT_NODE_GREY_FILL;
      }
    }

    if (isDisconnected) {
      color = ELEMENT_NODE_GREY_FILL;
    }

    if (this.settings.blackAndWhiteMode) {
      color = BLACK_AND_WHITE_FILL;
    }
    return color;
  }

  getElementVersionSelectedFillColor(category, isDisconnected) {
    let color = this.settings.darkMode
      ? ELEMENT_NODE_SELECTED_CHILD_STROKE_DARK
      : ELEMENT_NODE_SELECTED_CHILD_STROKE;

    if (category === 'machine') {
      color = this.settings.darkMode
        ? ELEMENT_VERSION_CATEGORY_MACHINE_SELECTED_DARK
        : ELEMENT_VERSION_CATEGORY_MACHINE_SELECTED;
    }
    if (category === 'process') {
      color = this.settings.darkMode
        ? ELEMENT_VERSION_CATEGORY_PROCESS_SELECTED_DARK
        : ELEMENT_VERSION_CATEGORY_PROCESS_SELECTED;
    }
    if (category === 'article-of-manufacture') {
      color = this.settings.darkMode
        ? ELEMENT_VERSION_CATEGORY_ARTICLE_SELECTED_DARK
        : ELEMENT_VERSION_CATEGORY_ARTICLE_SELECTED;
    }
    if (category === 'composition') {
      color = this.settings.darkMode
        ? ELEMENT_VERSION_CATEGORY_COMPOSITION_SELECTED_DARK
        : ELEMENT_VERSION_CATEGORY_COMPOSITION_SELECTED;
    }

    if (isDisconnected) {
      color = this.settings.darkMode
        ? ELEMENT_NODE_SELECTED_CHILD_STROKE_DARK
        : ELEMENT_NODE_SELECTED_CHILD_STROKE;
    }

    if (this.args.isSelectedChild) {
      color = this.settings.darkMode
        ? ELEMENT_NODE_SELECTED_CHILD_STROKE_DARK
        : ELEMENT_NODE_SELECTED_CHILD_STROKE;
    }

    if (this.settings.blackAndWhiteMode) {
      color = BLACK_AND_WHITE_STROKE;
    }
    return color;
  }

  getElementVersionStrokeColor(category, isDisconnected) {
    let color = ELEMENT_VERSION_NODE_STROKE;

    if (category === 'machine') {
      color = ELEMENT_VERSION_CATEGORY_MACHINE;
      if (this.elementVersionIsKnown) {
        // color = ELEMENT_VERSION_CATEGORY_MACHINE_DARK;
        color = ELEMENT_NODE_GREY_STROKE;
      }
    }
    if (category === 'process') {
      color = ELEMENT_VERSION_CATEGORY_PROCESS;
      if (this.elementVersionIsKnown) {
        // color = ELEMENT_VERSION_CATEGORY_PROCESS_DARK;
        color = ELEMENT_NODE_GREY_STROKE;
      }
    }
    if (category === 'article-of-manufacture') {
      color = ELEMENT_VERSION_CATEGORY_ARTICLE;
      if (this.elementVersionIsKnown) {
        // color = ELEMENT_VERSION_CATEGORY_ARTICLE_DARK;
        color = ELEMENT_NODE_GREY_STROKE;
      }
    }
    if (category === 'composition') {
      color = ELEMENT_VERSION_CATEGORY_COMPOSITION;
      if (this.elementVersionIsKnown) {
        // color = ELEMENT_VERSION_CATEGORY_COMPOSITION_DARK;
        color = ELEMENT_NODE_GREY_STROKE;
      }
    }

    if (isDisconnected) {
      color = ELEMENT_NODE_GREY_STROKE;
    }
    if (this.settings.blackAndWhiteMode) {
      color = BLACK_AND_WHITE_STROKE;
    }
    return color;
  }

  get textColor() {
    let color = ELEMENT_NODE_COLOR;

    if (this.args.isDisconnected) {
      color = ELEMENT_NODE_GREY_COLOR;
    }
    if (this.settings.blackAndWhiteMode) {
      color = BLACK_AND_WHITE_COLOR;
    }
    return color;
  }

  get nameColor() {
    let color = this.settings.darkMode ? '#FFF' : '#000';

    if (this.settings.blackAndWhiteMode) {
      color = BLACK_AND_WHITE_COLOR;
    }
    return color;
  }

  get headerTextColor() {
    let color = ELEMENT_NODE_HEADER_COLOR;

    if (this.args.isDisconnected) {
      color = ELEMENT_NODE_GREY_STROKE;
    }
    return color;
  }

  setup() {
    const elementNode = new Konva.Group({
      id: this.args.elementId,
      x: this.args.x,
      y: this.args.y,
      name: 'node',
      draggable: this.args.previewMode ? false : true,
      category: 'system',
      nodeType: 'element',
      transformsEnabled: 'position',
    });

    const noveltyHighlightNode = new Konva.Circle({
      radius: 60,
      fill: ELEMENT_NODE_NOVELTY_STROKE,
      visible: this.noveltyNodeVisible,
      // opacity: 0.5,
      listening: false,
      // strokeScaleEnabled: false,
      transformsEnabled: 'position',
      offset: {
        x: (-1 * this.width) / 2,
        y: this.height / 2 - 94,
      },
    });

    const noveltyDotNode = new Konva.Circle({
      radius: 20,
      fill: ELEMENT_NODE_NOVELTY_STROKE,
      visible: this.noveltyNodeVisible,
      // opacity: 0.5,
      listening: false,
      transformsEnabled: 'position',
      offset: {
        x: (-1 * this.width) / 2,
        y: this.height / 2 - 94,
      },
    });

    const elementBackgroundNode = new Konva.Rect({
      height: this.height,
      width: this.width - 54,
      // fill: 'rgba(0,255,255,0.1)',
      fill: 'transparent',
      // stroke: this.strokeColor,
      // stroke: this.strokeColor,
      hitStrokeWidth: 0,
      strokeWidth: this.settings.blackAndWhiteMode
        ? this.blackAndWhiteModeStroke
        : 7,
      transformsEnabled: 'position',
      visible: true,
      offset: {
        x: this.width / 2,
        y: this.height / 2,
      },
    });

    const methodPlaceholderNode = new Konva.Group({
      visible: this.showMethodPlaceholder,
      y: this.methodTransform.y,
      x: this.methodTransform.x,
      listening: true,
      rotation: this.methodTransform.rotation,
      offset: this.methodTransform.offset,
    });

    const methodPlaceholderBackgroundNode = new Konva.Rect({
      width: this.methodTransform.width,
      height: this.methodTransform.height,
      fill: this.placeholderFillColor,
      stroke: this.placeholderStrokeColor,
      hitStrokeWidth: 0,
      strokeWidth: this.settings.blackAndWhiteMode
        ? this.blackAndWhiteModeStroke
        : 3,
      transformsEnabled: 'position',
      listening: true,
      dash: this.placeholderDash,
      dashEnabled: true,
    });

    const methodPlaceholderTextNode = new Konva.Text({
      width: this.methodTransform.width,
      height: this.methodTransform.height,
      align: 'center',
      verticalAlign: 'middle',
      text: 'Method',
      fontSize: 24,
      fontStyle: this.settings.blackAndWhiteMode
        ? this.blackAndWhiteModeFontWeight
        : '400',
      lineHeight: 1.235,
      fontFamily: this.fontFamily,
      fill: this.placeholderTextColor,
      transformsEnabled: 'position',
      listening: false,
    });
    // }).cache();

    methodPlaceholderNode.add(methodPlaceholderBackgroundNode);
    methodPlaceholderNode.add(methodPlaceholderTextNode);

    const methodNode = new Konva.Group({
      visible: this.showMethod,
      y: this.methodTransform.y,
      x: this.methodTransform.x,
      listening: true,
      rotation: this.methodTransform.rotation,
      offset: this.methodTransform.offset,
    });

    const methodBackgroundNode = new Konva.Rect({
      width: this.methodTransform.width,
      height: this.methodTransform.height,
      fill: '#FFF',
      stroke: this.methodStrokeColor,
      hitStrokeWidth: 0,
      strokeWidth: this.settings.blackAndWhiteMode
        ? this.blackAndWhiteModeStroke
        : 3,
      transformsEnabled: 'position',
      listening: true,
      dash: this.placeholderDash,
      dashEnabled: this.methodDashEnabled,
    });

    const methodImageNode = new Konva.Image({
      x: 3,
      y: 3,
      width: this.methodTransform.width - 6,
      height: this.methodTransform.height - 6,
      transformsEnabled: 'position',
      listening: false,
      visible: !this._reachedScaleThreshold,
    });

    methodNode.add(methodBackgroundNode);
    methodNode.add(methodImageNode);

    const drawingPlaceholderNode = new Konva.Group({
      visible: this.showDrawingPlaceholder,
      y: this.drawingTransform.y,
      x: this.drawingTransform.x,
      listening: true,
      rotation: this.drawingTransform.rotation,
      offset: this.drawingTransform.offset,
    });

    const drawingPlaceholderBackgroundNode = new Konva.Rect({
      width: this.drawingTransform.width,
      height: this.drawingTransform.height,
      fill: this.placeholderFillColor,
      stroke: this.placeholderStrokeColor,
      hitStrokeWidth: 0,
      strokeWidth: this.settings.blackAndWhiteMode
        ? this.blackAndWhiteModeStroke
        : 3,
      transformsEnabled: 'position',
      listening: true,
      dash: this.placeholderDash,
      dashEnabled: true,
    });

    const drawingPlaceholderTextNode = new Konva.Text({
      width: this.drawingTransform.width,
      height: this.drawingTransform.height,
      align: 'center',
      verticalAlign: 'middle',
      text: 'Drawing',
      fontSize: 24,
      fontStyle: this.settings.blackAndWhiteMode
        ? this.blackAndWhiteModeFontWeight
        : '400',
      lineHeight: 1.235,
      fontFamily: this.fontFamily,
      fill: this.placeholderTextColor,
      transformsEnabled: 'position',
      listening: false,
    });
    // }).cache();

    drawingPlaceholderNode.add(drawingPlaceholderBackgroundNode);
    drawingPlaceholderNode.add(drawingPlaceholderTextNode);

    const drawingNode = new Konva.Group({
      visible: this.showDrawing,
      y: this.drawingTransform.y,
      x: this.drawingTransform.x,
      listening: true,
      rotation: this.drawingTransform.rotation,
      offset: this.drawingTransform.offset,
    });

    const drawingStrokeNode = new Konva.Rect({
      width: this.drawingTransform.width,
      height: this.drawingTransform.height,
      stroke: this.drawingStrokeColor,
      hitStrokeWidth: 0,
      strokeWidth: this.settings.blackAndWhiteMode
        ? this.blackAndWhiteModeStroke
        : 3,
      transformsEnabled: 'position',
      listening: false,
      visible: true,
    });

    const drawingBackgroundNode = new Konva.Rect({
      width: this.drawingTransform.width,
      height: this.drawingTransform.height,
      hitStrokeWidth: 0,
      fill: '#FFFFFF',
      transformsEnabled: 'position',
      listening: true,
      visible: true,
    });

    const drawingImageNode = new Konva.Image({
      width: this.drawingTransform.width - 20,
      height: this.drawingTransform.height - 20,
      transformsEnabled: 'position',
      listening: false,
      visible: !this._reachedScaleThreshold,
      offset: {
        x: -10,
        y: -10,
      },
    });

    drawingNode.add(drawingBackgroundNode);
    drawingNode.add(drawingImageNode);
    drawingNode.add(drawingStrokeNode);

    const nameNode = new Konva.Text({
      width: this.width - 40,
      height: this.textHeight,
      verticalAlign: 'bottom',
      text: this.name,
      fontSize: 26,
      lineHeight: 1.2,
      fontFamily: this.fontFamily,
      fontStyle: '500',
      fill: this.nameColor,
      transformsEnabled: 'position',
      listening: false,
      ellipsis: true,
      offset: {
        y: this.height / 2,
        x: this.width / 2,
      },
    });

    const elementVersionNode = new Konva.Group({
      name: 'element-version-node',
      x: this.elementVersionTransform.x,
      nodeType: 'element-version',
      transformsEnabled: 'position',
      visible: this.showElementVersion ? true : false,
    });

    const elementVersionSelectedNode = new Konva.Rect({
      width: this.elementVersionTransform.width,
      height: this.elementVersionTransform.height,
      fill: this.elementVersionSelectedFillColor,
      stroke: this.elementVersionSelectedFillColor,
      hitStrokeWidth: 0,
      strokeWidth: 12,
      visible:
        this.elementVersionIsntKnown && this.elementVersionIsSelected
          ? true
          : false,
      listening: false,
      strokeScaleEnabled: false,
      transformsEnabled: 'position',
      offset: {
        x: this.elementVersionTransform.width / 2,
        y: this.elementVersionTransform.height / 2,
      },
    });

    const knownElementVersionSelectedNode = new Konva.RegularPolygon({
      radius: this.elementVersionTransform.width / 2,
      fill: this.elementVersionSelectedFillColor,
      stroke: this.elementVersionSelectedFillColor,
      hitStrokeWidth: 0,
      sides: 8,
      rotation: 22.5,
      strokeWidth: 12,
      visible:
        this.elementVersionIsKnown && this.elementVersionIsSelected
          ? true
          : false,
      listening: false,
      strokeScaleEnabled: false,
    });

    const elementVersionBackgroundNode = new Konva.Rect({
      width: this.elementVersionTransform.width,
      height: this.elementVersionTransform.height,
      fill: this.elementVersionFillColor,
      stroke: this.elementVersionStrokeColor,
      hitStrokeWidth: 0,
      strokeWidth: this.settings.blackAndWhiteMode
        ? this.blackAndWhiteModeStroke
        : 4,
      transformsEnabled: 'position',
      visible: this.elementVersionIsntKnown,
      offset: {
        x: this.elementVersionTransform.width / 2,
        y: this.elementVersionTransform.height / 2,
      },
    });

    const knownElementVersionBackgroundNode = new Konva.RegularPolygon({
      radius: this.elementVersionTransform.width / 2,
      fill: this.elementVersionFillColor,
      stroke: this.elementVersionStrokeColor,
      sides: 8,
      rotation: 22.5,
      strokeWidth: this.settings.blackAndWhiteMode
        ? this.blackAndWhiteModeStroke
        : 4,
      visible: this.elementVersionIsKnown,
    });

    const elementVersionTwoNode = new Konva.Rect({
      x: this.elementVersionTwoTransform.x,
      y: this.elementVersionTwoTransform.y,
      width: this.elementVersionTwoTransform.width,
      height: this.elementVersionTwoTransform.height,
      fill: this.elementVersionTwoFillColor,
      stroke: this.elementVersionTwoStrokeColor,
      hitStrokeWidth: 0,
      strokeWidth: this.settings.blackAndWhiteMode
        ? this.blackAndWhiteModeStroke
        : 3,
      rotation: this.elementVersionTwoTransform.rotation,
      // transformsEnabled: 'position',
      visible: this.showElementVersionTwo,
      offset: {
        x: this.elementVersionTwoTransform.width / 2,
        y: this.elementVersionTwoTransform.height / 2,
      },
    });

    const elementVersionThreeNode = new Konva.Rect({
      x: this.elementVersionThreeTransform.x,
      y: this.elementVersionThreeTransform.y,
      width: this.elementVersionThreeTransform.width,
      height: this.elementVersionThreeTransform.height,
      fill: this.elementVersionThreeFillColor,
      stroke: this.elementVersionThreeStrokeColor,
      hitStrokeWidth: 0,
      strokeWidth: this.settings.blackAndWhiteMode
        ? this.blackAndWhiteModeStroke
        : 3,
      rotation: this.elementVersionThreeTransform.rotation,
      // transformsEnabled: 'position',
      visible: this.showElementVersionThree,
      offset: {
        x: this.elementVersionThreeTransform.width / 2,
        y: this.elementVersionThreeTransform.height / 2,
      },
    });

    elementVersionNode.add(methodNode);
    elementVersionNode.add(methodPlaceholderNode);
    elementVersionNode.add(drawingNode);
    elementVersionNode.add(drawingPlaceholderNode);
    elementVersionNode.add(elementVersionThreeNode);
    elementVersionNode.add(elementVersionTwoNode);
    elementVersionNode.add(elementVersionSelectedNode);
    elementVersionNode.add(knownElementVersionSelectedNode);
    elementVersionNode.add(elementVersionBackgroundNode);
    elementVersionNode.add(knownElementVersionBackgroundNode);

    const elementVersionPlaceholderNode = new Konva.Group({
      visible: this.showElementVersionPlaceholder,
      x: this.elementVersionTransform.x,
      y: this.elementVersionTransform.y,
      // rotation: 4,
      transformsEnabled: 'position',
    });

    const elementVersionPlaceholderBackgroundNode = new Konva.Rect({
      width: this.elementVersionTransform.width,
      height: this.elementVersionTransform.height,
      fill: this.placeholderFillColor,
      stroke: this.placeholderStrokeColor,
      hitStrokeWidth: 0,
      strokeWidth: this.settings.blackAndWhiteMode
        ? this.blackAndWhiteModeStroke
        : 3,
      transformsEnabled: 'position',
      listening: true,
      dash: this.placeholderDash,
      dashEnabled: true,
      offset: {
        x: this.elementVersionTransform.width / 2,
        y: this.elementVersionTransform.height / 2,
      },
    });

    elementVersionPlaceholderNode.add(elementVersionPlaceholderBackgroundNode);

    const outcomePlaceholderNode = new Konva.Group({
      visible: this.showOutcomePlaceholder,
      x: this.outcomePlaceholderTransform.x,
      y: this.outcomePlaceholderTransform.y,
      // rotation: 4,
      offset: this.outcomePlaceholderTransform.offset,
      transformsEnabled: 'position',
    });

    const outcomePlaceholderBackgroundNode = new Konva.Rect({
      width: this.outcomePlaceholderTransform.width,
      height: this.outcomePlaceholderTransform.height,
      fill: this.placeholderFillColor,
      stroke: this.placeholderStrokeColor,
      hitStrokeWidth: 0,
      strokeWidth: this.settings.blackAndWhiteMode
        ? this.blackAndWhiteModeStroke
        : 3,
      transformsEnabled: 'position',
      listening: true,
      dash: this.placeholderDash,
      dashEnabled: true,
    });

    const outcomePlaceholderTextNode = new Konva.Text({
      width: this.outcomePlaceholderTransform.width,
      height: this.outcomePlaceholderTransform.height,
      align: 'center',
      verticalAlign: 'middle',
      text: 'Success Event',
      fontSize: 24,
      fontStyle: this.settings.blackAndWhiteMode
        ? this.blackAndWhiteModeFontWeight
        : '400',
      lineHeight: 1.235,
      fontFamily: this.fontFamily,
      fill: this.placeholderTextColor,
      transformsEnabled: 'position',
      listening: false,
    });
    // }).cache();

    outcomePlaceholderNode.add(outcomePlaceholderBackgroundNode);
    outcomePlaceholderNode.add(outcomePlaceholderTextNode);

    const outcomeNode = new Konva.Group({
      visible: this.showOutcome,
      y: this.outcomeTransform.y,
      x: this.outcomeTransform.x,
      transformsEnabled: 'position',
      offset: this.outcomeTransform.offset,
    });

    const outcomeHeaderTextNode = new Konva.Text({
      width: this.outcomeTransform.width,
      text: this.methodNodeOrdinal || '',
      fontSize: 22,
      lineHeight: 1.235,
      fontFamily: this.fontFamily,
      fill: this.outcomeHeaderFill,
      transformsEnabled: 'position',
      listening: false,
      align: 'right',
      x: 0,
      y: -28,
      visible: this.methodNodeOrdinal ? true : false,
    });

    const outcomeTextNode = new Konva.Text({
      width: this.outcomeTransform.width - 30,
      height: 200,
      ellipsis: true,
      text: textValue(this.outcome || ''),
      fontSize: 26,
      lineHeight: 1.235,
      fontFamily: this.fontFamily,
      fill: this.textColor,
      transformsEnabled: 'position',
      listening: false,
      visible: this._reachedScaleThreshold ? false : true,
      x: 22,
      y: 17,
    }).cache();

    const outcomeBackgroundNodeHeight = this.outcomeBackgroundNodeHeight(
      outcomeTextNode.textArr.length
    );

    elementVersionNode.y(
      outcomeBackgroundNodeHeight + this.elementVersionTransform.padding
    );

    const outcomeBackgroundNode = new Konva.Rect({
      width: this.outcomeTransform.width,
      height: outcomeBackgroundNodeHeight,
      fill: this.outcomeFillColor,
      stroke: this.outcomeStrokeColor,
      hitStrokeWidth: 0,
      strokeWidth: this.settings.blackAndWhiteMode
        ? this.blackAndWhiteModeStroke
        : 3,
      // strokeScaleEnabled: false,
      transformsEnabled: 'position',
      listening: true,
      dash: [15, 10],
      dashEnabled: this.outcomeDashEnabled,
    });

    const outcomeArrowNode = new Konva.Group({
      transformsEnabled: 'position',
      listening: false,
      y: outcomeBackgroundNodeHeight / 2,
      x: this.outcomeTransform.width,
      visible: this.showArrow ? true : false,
      // visible: false,
    });

    const outcomeArrowArrowNode = new Konva.Arrow({
      points: [0, 0, 40, 0],
      stroke: this.outcomeArrowFill,
      hitStrokeWidth: 0,
      // rotation: -4,
      strokeWidth: 7,
      listening: false,
      x: 7,
    });

    outcomeArrowNode.add(outcomeArrowArrowNode);

    outcomeNode.add(outcomeBackgroundNode);
    outcomeNode.add(outcomeHeaderTextNode);
    outcomeNode.add(outcomeTextNode);
    outcomeNode.add(outcomeArrowNode);

    const collapsedNode = new Konva.Rect({
      width: this.outcomeTransform.width,
      height: 100,
      visible: this.args.isCollapsed,
      fill: this.fillColorCollapsed,
      stroke: this.strokeColorCollapsed,
      hitStrokeWidth: 0,
      strokeWidth: this.settings.blackAndWhiteMode
        ? this.blackAndWhiteModeStroke
        : 3,
      x: this.outcomeTransform.x + 10,
      y: this.outcomeTransform.y + 7.5,
      transformsEnabled: 'position',
      offset: this.outcomeTransform.offset,
    });

    const collapsedTwoNode = new Konva.Rect({
      width: this.outcomeTransform.width,
      height: 100,
      visible: this.args.isCollapsed,
      fill: this.fillColorCollapsed,
      stroke: this.strokeColorCollapsed,
      hitStrokeWidth: 0,
      strokeWidth: this.settings.blackAndWhiteMode
        ? this.blackAndWhiteModeStroke
        : 3,
      x: this.outcomeTransform.x + 20,
      y: this.outcomeTransform.y + 15,
      transformsEnabled: 'position',
      offset: this.outcomeTransform.offset,
    });

    const collapsedThreeNode = new Konva.Rect({
      width: this.outcomeTransform.width,
      height: 100,
      visible: this.args.isCollapsed,
      fill: this.fillColorCollapsed,
      stroke: this.strokeColorCollapsed,
      hitStrokeWidth: 0,
      strokeWidth: this.settings.blackAndWhiteMode
        ? this.blackAndWhiteModeStroke
        : 3,
      x: this.outcomeTransform.x + 30,
      y: this.outcomeTransform.y + 22.5,
      transformsEnabled: 'position',
      offset: this.outcomeTransform.offset,
    });

    collapsedNode.height(outcomeBackgroundNodeHeight);
    collapsedTwoNode.height(outcomeBackgroundNodeHeight);
    collapsedThreeNode.height(outcomeBackgroundNodeHeight);

    const componentNode = new Konva.Group({
      x: 0,
      y: 0,
      transformsEnabled: 'position',
      visible: this.isInstance ? true : false,
      offset: {
        x: this.width / 2,
        y: this.outcomeTransform.offset.y,
      },
    });

    const componentNodeBackgroundNode = new Konva.Rect({
      width: 40,
      height: 30,
      fill: this.outcomeFillColor,
      hitStrokeWidth: 0,
      transformsEnabled: 'position',
      cornerRadius: 10,
      offset: {
        x: 20,
        y: 15,
      },
    });

    // const componentNodeBackgroundNode = new Konva.Circle({
    //   radius: 20,
    //   // fill: 'transparent',
    //   // stroke: 'transparent',
    //   fill: this.outcomeFillColor,
    //   // stroke: this.outcomeStrokeColor,
    //   hitStrokeWidth: 0,
    //   strokeWidth: 3,
    //   transformsEnabled: 'position',
    // });

    const componentPrimaryNode = new Konva.Path({
      data: COMPONENT_PATH,
      fill: this.outcomeStrokeColor,
      visible: this.args.isPrimaryInstance ? true : false,
      scale: {
        x: 3.5,
        y: 3.5,
      },
      offset: {
        x: 9,
        y: 7.5,
      },
    }).cache();

    const componentInstanceNode = new Konva.Path({
      data: INSTANCE_PATH,
      fill: this.outcomeStrokeColor,
      visible: this.isInstance ? true : false,
      scale: {
        x: 3.5,
        y: 3.5,
      },
      offset: {
        x: 9,
        y: 7.5,
      },
    }).cache();

    componentNode.add(componentNodeBackgroundNode);
    componentNode.add(componentPrimaryNode);
    componentNode.add(componentInstanceNode);

    const outcomeSelectedNode = new Konva.Rect({
      y: this.outcomeTransform.y,
      x: this.outcomeTransform.x,
      width: this.outcomeTransform.width,
      height: outcomeBackgroundNodeHeight,
      fill: this.elementVersionSelectedFillColor,
      stroke: this.elementVersionSelectedFillColor,
      hitStrokeWidth: 0,
      strokeWidth: 12,
      visible: this.elementIsSelected || this.isSelectedChild ? true : false,
      // opacity: 0.5,
      listening: false,
      strokeScaleEnabled: false,
      transformsEnabled: 'position',
      offset: this.outcomeTransform.offset,
    });

    const drawingSelectedNode = new Konva.Rect({
      x: this.drawingTransform.x,
      width: this.drawingTransform.width,
      height: this.drawingTransform.height,
      hitStrokeWidth: 0,
      fill: this.elementVersionSelectedFillColor,
      stroke: this.elementVersionSelectedFillColor,
      strokeScaleEnabled: false,
      strokeWidth: 12,
      listening: true,
      visible: this.showElementVersion && this.elementIsSelected,
      rotation: this.drawingTransform.rotation,
      offset: this.drawingTransform.offset,
    });

    drawingSelectedNode.y(
      outcomeBackgroundNodeHeight + this.elementVersionTransform.padding
    );

    const methodSelectedNode = new Konva.Rect({
      x: this.methodTransform.x,
      width: this.methodTransform.width,
      height: this.methodTransform.height,
      hitStrokeWidth: 0,
      fill: this.elementVersionSelectedFillColor,
      stroke: this.elementVersionSelectedFillColor,
      strokeScaleEnabled: false,
      strokeWidth: 12,
      listening: true,
      visible: this.showElementVersion && this.elementIsSelected,
      rotation: this.methodTransform.rotation,
      offset: this.methodTransform.offset,
    });

    methodSelectedNode.y(
      outcomeBackgroundNodeHeight + this.elementVersionTransform.padding
    );

    // TODO opacity kills end-to-end tests
    if (ENV.environment !== 'test') {
      noveltyHighlightNode.opacity(0.5);
    }

    elementNode.add(methodSelectedNode);
    elementNode.add(drawingSelectedNode);
    elementNode.add(outcomeSelectedNode);
    elementNode.add(collapsedThreeNode);
    elementNode.add(collapsedTwoNode);
    elementNode.add(collapsedNode);
    elementNode.add(nameNode);
    elementNode.add(outcomePlaceholderNode);
    elementNode.add(outcomeNode);
    elementNode.add(elementBackgroundNode);
    elementNode.add(elementVersionPlaceholderNode);
    elementNode.add(elementVersionNode);
    elementNode.add(noveltyHighlightNode);
    elementNode.add(noveltyDotNode);
    elementNode.add(componentNode);

    // add events
    if (this.args.onClick) {
      elementNode.on('click', (e) => {
        const isRightClick = e.evt ? e.evt.altKey || e.evt.button === 2 : false;
        this.args.onClick(
          this.args.elementId,
          this.args.isSelected,
          isRightClick
        );
      });
    }

    if (this.args.onContextClick) {
      elementNode.on('contextmenu', (event) => {
        this.args.onContextClick(this.args.elementId, event);
      });
    }

    if (this.args.onDragStart) {
      elementNode.on('dragstart', (event) => {
        if (this.args.previewMode) return false;
        if (this.args.actionMode) {
          event.target.stopDrag();
        }
        this.args.onDragStart(event, this.args.elementId);
      });
    }

    if (this.args.onDragMove) {
      elementNode.on('dragmove', (event) => {
        if (this.args.previewMode) return false;
        if (this.args.actionMode) {
          return event.target.stopDrag();
        }
        this.args.onDragMove(event, this.args.elementId);
      });
    }

    if (this.args.onDragMove) {
      elementNode.on('dragend', (event) => {
        if (this.args.previewMode) return false;
        if (this.args.actionMode) {
          return;
        }
        this.args.onDragEnd(event, this.args.elementId);
      });
    }

    if (this.args.onMouseenter) {
      elementNode.on('mouseenter', (event) => {
        if (this.args.previewMode) return false;
        this.args.onMouseenter(event, this.args.elementId);
      });
    }

    if (this.args.onMouseleave) {
      elementNode.on('mouseleave', (event) => {
        if (this.args.previewMode) return false;
        this.args.onMouseleave(event, this.args.elementId);
      });
    }

    this.elementsLayer.add(elementNode);

    elementNode.visible(elementNode.isClientRectOnScreen());

    this.methodPlaceholderNode = methodPlaceholderNode;
    this.methodPlaceholderTextNode = methodPlaceholderTextNode;
    this.methodPlaceholderBackgroundNode = methodPlaceholderBackgroundNode;
    this.methodNode = methodNode;
    this.methodBackgroundNode = methodBackgroundNode;
    this.methodImageNode = methodImageNode;
    this.drawingPlaceholderNode = drawingPlaceholderNode;
    this.drawingPlaceholderTextNode = drawingPlaceholderTextNode;
    this.drawingPlaceholderBackgroundNode = drawingPlaceholderBackgroundNode;
    this.drawingNode = drawingNode;
    this.drawingBackgroundNode = drawingBackgroundNode;
    this.drawingStrokeNode = drawingStrokeNode;
    this.drawingImageNode = drawingImageNode;
    this.nameNode = nameNode;
    this.collapsedNode = collapsedNode;
    this.collapsedTwoNode = collapsedTwoNode;
    this.collapsedThreeNode = collapsedThreeNode;
    this.outcomePlaceholderNode = outcomePlaceholderNode;
    this.outcomePlaceholderTextNode = outcomePlaceholderTextNode;
    this.outcomePlaceholderBackgroundNode = outcomePlaceholderBackgroundNode;
    this.outcomeNode = outcomeNode;
    this.outcomeBackgroundNode = outcomeBackgroundNode;
    this.methodSelectedNode = methodSelectedNode;
    this.drawingSelectedNode = drawingSelectedNode;
    this.outcomeSelectedNode = outcomeSelectedNode;
    this.outcomeHeaderTextNode = outcomeHeaderTextNode;
    this.outcomeTextNode = outcomeTextNode;
    this.outcomeArrowNode = outcomeArrowNode;
    this.outcomeArrowArrowNode = outcomeArrowArrowNode;
    this.elementBackgroundNode = elementBackgroundNode;
    this.noveltyHighlightNode = noveltyHighlightNode;
    this.noveltyDotNode = noveltyDotNode;
    this.elementNode = elementNode;
    this.elementVersionPlaceholderNode = elementVersionPlaceholderNode;
    this.elementVersionPlaceholderBackgroundNode =
      elementVersionPlaceholderBackgroundNode;
    this.elementVersionTwoNode = elementVersionTwoNode;
    this.elementVersionThreeNode = elementVersionThreeNode;
    this.elementVersionNode = elementVersionNode;
    this.elementVersionBackgroundNode = elementVersionBackgroundNode;
    this.knownElementVersionBackgroundNode = knownElementVersionBackgroundNode;
    this.elementVersionSelectedNode = elementVersionSelectedNode;
    this.knownElementVersionSelectedNode = knownElementVersionSelectedNode;
    this.componentNode = componentNode;
    this.componentNodeBackgroundNode = componentNodeBackgroundNode;
    this.componentPrimaryNode = componentPrimaryNode;
    this.componentInstanceNode = componentInstanceNode;
    // this.categoryMachineNode = categoryMachineNode;
    // this.categoryArticleNode = categoryArticleNode;
    // this.categoryProcessNode = categoryProcessNode;

    if (this.referenceId) {
      this.onUpdateReferenceImage.perform(this.referenceId);
    } else {
      this.onUpdateReferenceImage.perform(null);
    }

    if (this.methodId) {
      this.onUpdateMethodImage.perform(this.methodId);
    }

    // this.onUpdatePlaceholderImages.perform();

    this.onScheduleRender();
  }

  handleSelected() {
    this.outcomeSelectedNode.visible(this.elementIsSelected ? true : false);
    this.drawingSelectedNode.visible(this.showElementVersion && this.elementIsSelected);
    this.methodSelectedNode.visible(this.showElementVersion && this.elementIsSelected);
    this.onScheduleRender();
  }

  handleDeselected() {
    this.outcomeSelectedNode.visible(false);
    this.drawingSelectedNode.visible(false);
    this.methodSelectedNode.visible(false);
    this.onScheduleRender();
  }

  updateColors() {
    this.elementVersionBackgroundNode.fill(this.elementVersionFillColor);
    this.elementVersionBackgroundNode.stroke(this.elementVersionStrokeColor);

    this.elementVersionTwoNode.fill(this.elementVersionTwoFillColor);
    this.elementVersionTwoNode.stroke(this.elementVersionTwoStrokeColor);

    this.elementVersionThreeNode.fill(this.elementVersionThreeFillColor);
    this.elementVersionThreeNode.stroke(this.elementVersionThreeStrokeColor);

    this.elementVersionSelectedNode.fill(this.elementVersionSelectedFillColor);
    this.elementVersionSelectedNode.stroke(
      this.elementVersionSelectedFillColor
    );

    this.knownElementVersionBackgroundNode.fill(this.elementVersionFillColor);
    this.knownElementVersionBackgroundNode.stroke(
      this.elementVersionStrokeColor
    );

    this.knownElementVersionSelectedNode.fill(
      this.elementVersionSelectedFillColor
    );
    this.knownElementVersionSelectedNode.stroke(
      this.elementVersionSelectedFillColor
    );

    // this.categoryMachineNode.fill(this.elementVersionStrokeColor);
    // this.categoryArticleNode.fill(this.elementVersionStrokeColor);
    // this.categoryProcessNode.fill(this.elementVersionStrokeColor);

    this.nameNode.fill(this.nameColor);

    this.outcomeTextNode.cache();
    this.outcomeTextNode.fill(this.textColor);
    this.outcomeTextNode.clearCache();

    this.outcomeSelectedNode.stroke(this.elementVersionSelectedFillColor);
    this.outcomeSelectedNode.fill(this.elementVersionSelectedFillColor);

    this.drawingSelectedNode.stroke(this.elementVersionSelectedFillColor);
    this.drawingSelectedNode.fill(this.elementVersionSelectedFillColor);

    this.methodSelectedNode.stroke(this.elementVersionSelectedFillColor);
    this.methodSelectedNode.fill(this.elementVersionSelectedFillColor);

    // this.outcomeBackgroundNode.fill(this.fillColorLight);
    // this.outcomeBackgroundNode.stroke(this.textColor);
    this.collapsedNode.fill(this.fillColorCollapsed);
    this.collapsedNode.stroke(this.strokeColorCollapsed);
    this.collapsedTwoNode.fill(this.fillColorCollapsed);
    this.collapsedTwoNode.stroke(this.strokeColorCollapsed);
    this.collapsedThreeNode.fill(this.fillColorCollapsed);
    this.collapsedThreeNode.stroke(this.strokeColorCollapsed);

    this.componentNodeBackgroundNode.fill(this.outcomeFillColor);
    this.componentNodeBackgroundNode.stroke(this.outcomeStrokeColor);

    this.componentPrimaryNode.clearCache();
    this.componentPrimaryNode.fill(this.outcomeStrokeColor);
    this.componentPrimaryNode.cache();

    this.componentInstanceNode.clearCache();
    this.componentInstanceNode.fill(this.outcomeStrokeColor);
    this.componentInstanceNode.cache();

    this.methodBackgroundNode.stroke(this.methodStrokeColor);
    this.methodPlaceholderBackgroundNode.fill(this.placeholderFillColor);
    this.methodPlaceholderBackgroundNode.stroke(this.placeholderStrokeColor);
    this.methodPlaceholderTextNode.fill(this.placeholderTextColor);

    this.drawingStrokeNode.stroke(this.drawingStrokeColor);
    this.drawingPlaceholderBackgroundNode.fill(this.placeholderFillColor);
    this.drawingPlaceholderBackgroundNode.stroke(this.placeholderStrokeColor);
    this.drawingPlaceholderTextNode.fill(this.placeholderTextColor);

    this.elementVersionPlaceholderBackgroundNode.fill(
      this.placeholderFillColor
    );
    this.elementVersionPlaceholderBackgroundNode.stroke(
      this.placeholderStrokeColor
    );

    this.outcomePlaceholderTextNode.fill(this.placeholderTextColor);
    this.outcomePlaceholderBackgroundNode.fill(this.placeholderFillColor);
    this.outcomePlaceholderBackgroundNode.stroke(this.placeholderStrokeColor);
    this.outcomeHeaderTextNode.fill(this.outcomeHeaderFill);
    this.outcomeArrowArrowNode.stroke(this.outcomeArrowFill);
  }

  @task
  *onUpdateReferenceImage(referenceId) {
    let blobUrl;

    const state = this.redux.getState();

    if (getMarker(state, referenceId)) {
      blobUrl = yield this.referencesCache.getBlobUrl.perform(referenceId);
    }

    if (!blobUrl) {
      return;
    }

    const imageFile = new Image();

    imageFile.onload = () => {
      this.drawingImageNode.image(imageFile);
      this.drawingImageNode.cache();
      if (this.args.isDisconnected || this.settings.blackAndWhiteMode) {
        const filters = [Konva.Filters.Grayscale];
        this.drawingImageNode.filters(filters);
      }

      this.onScheduleRender();
    };

    imageFile.src = blobUrl;
  }

  @task
  *onUpdateMethodImage(methodId) {
    let blobUrl;

    const state = this.redux.getState();

    if (methodId && getMethod(state, methodId)) {
      blobUrl = yield this.methodsCacheKonva.getBlobUrl.perform(methodId);
    }

    if (!blobUrl) {
      return;
    }

    const imageFile = new Image();

    imageFile.onload = () => {
      this.methodImageNode.image(imageFile);
      this.methodImageNode.cache();

      this.onScheduleRender();
    };

    imageFile.src = blobUrl;
  }

  // @task
  // // eslint-disable-next-line require-yield
  // *onUpdatePlaceholderImages() {
  //   let blobUrl = `./assets/img/placeholder-light.jpg`;

  //   const imageFile = new Image();

  //   imageFile.onload = () => {
  //     this.outcomePlaceholderBackgroundNode.fillPriority('pattern');
  //     this.outcomePlaceholderBackgroundNode.fillPatternImage(imageFile);

  //     this.onScheduleRender();
  //   };

  //   imageFile.src = blobUrl;
  // }

  @action
  updateVisibility() {
    this.elementNode.visible(this.elementNode.isClientRectOnScreen());
  }

  @action
  onUpdate(
    elem,
    [
      previewMode,
      isNovel,
      isNovelAncestor,
      isDisconnected,
      isCollapsed,
      visibleAreaIndex,
      elementIsSelected,
      x,
      y,
      reachedScaleThreshold,
      solutionsString,
      hasReference,
      referenceUpdatedAt,
      name,
      showElementVersionPlaceholder,
      showElementVersion,
      showElementVersionTwo,
      showElementVersionThree,
      outcome,
      outcomeIsTraversed,
      methodNodeOrdinal,
      showOutcome,
      showDrawing,
      showDrawingPlaceholder,
      showMethod,
      showMethodPlaceholder,
      methodUpdatedAt,
      elementVersionIsKnown,
      elementVersionCategory,
      elementVersionIsSelected,
      isSelectedChild,
      isCreatingFrom,
      isCreatingTo,
      showArrow,
      isPrimaryInstance,
      isInstance,
      darkMode,
      blackAndWhiteMode,
    ]
  ) {
    let detailsChanged = false;
    let visibleAreaChanged = false;

    if (this._previewMode !== previewMode) {
      this._previewMode = previewMode;
      if (previewMode) {
        this.elementNode.draggable(false);
      } else {
        this.elementNode.draggable(true);
      }
      this.onScheduleRender();
    }

    if (this._showElementVersionPlaceholder !== showElementVersionPlaceholder) {
      this._showElementVersionPlaceholder = showElementVersionPlaceholder;
      if (showElementVersionPlaceholder) {
        this.elementVersionPlaceholderNode.visible(true);
      } else {
        this.elementVersionPlaceholderNode.visible(false);
      }
      this.onScheduleRender();
    }

    if (this._isDisconnected !== isDisconnected) {
      this._isDisconnected = isDisconnected;
      this.updateColors();
      const filters = isDisconnected ? [Konva.Filters.Grayscale] : [];
      this.drawingImageNode.filters(filters);
      detailsChanged = true;
    }

    if (this._isNovel !== isNovel) {
      this._isNovel = isNovel;
      this.noveltyHighlightNode.visible(this.noveltyNodeVisible);
      this.noveltyDotNode.visible(this.noveltyNodeVisible);
      this.updateColors();
      detailsChanged = true;
    }

    if (this._isNovelAncestor !== isNovelAncestor) {
      this._isNovelAncestor = isNovelAncestor;
      this.noveltyHighlightNode.visible(this.noveltyNodeVisible);
      this.noveltyDotNode.visible(this.noveltyNodeVisible);
      this.updateColors();
      detailsChanged = true;
    }

    if (this._isCollapsed !== isCollapsed) {
      this._isCollapsed = isCollapsed;
      this.collapsedNode.visible(isCollapsed);
      this.collapsedTwoNode.visible(isCollapsed);
      this.collapsedThreeNode.visible(isCollapsed);

      this.noveltyHighlightNode.visible(this.noveltyNodeVisible);
      this.noveltyDotNode.visible(this.noveltyNodeVisible);
      this.updateColors();
    }

    if (this._visibleAreaIndex !== visibleAreaIndex) {
      this._visibleAreaIndex = visibleAreaIndex;
      visibleAreaChanged = true;
    }

    if (visibleAreaChanged) {
      this.updateVisibility();
    }

    if (this._elementIsSelected !== elementIsSelected) {
      this._elementIsSelected = elementIsSelected;
      if (elementIsSelected) {
        this.handleSelected();
      } else {
        this.handleDeselected();
      }
    }

    if (this._solutionsString !== solutionsString) {
      this._solutionsString = solutionsString;
      this.onScheduleRender();
    }

    if (this._hasReference !== hasReference) {
      this._hasReference = hasReference;
      if (this.referenceId) {
        this.onUpdateReferenceImage.perform(this.referenceId);
      } else {
        this.onUpdateReferenceImage.perform(null);
      }
    }

    if (this._referenceUpdatedAt !== referenceUpdatedAt) {
      this._referenceUpdatedAt = referenceUpdatedAt;
      if (this.referenceId) {
        this.onUpdateReferenceImage.perform(this.referenceId);
      } else {
        this.onUpdateReferenceImage.perform(null);
      }
    }

    if (this._name !== name) {
      detailsChanged = true;
      this._name = name;
      // this.nameNode.clearCache();
      this.nameNode.text(name);
      // const nameNodeLines = this.nameNode.textArr.length;
      // const nameNodeY = this.getNameNodeY(nameNodeLines);
      // this.nameNode.y(nameNodeY);
      // this.nameNode.cache();
    }

    if (this._showElementVersion !== showElementVersion) {
      detailsChanged = true;
      this._showElementVersion = showElementVersion;
      this.elementVersionNode.visible(this.showElementVersion);
    }

    if (this._showElementVersionTwo !== showElementVersionTwo) {
      detailsChanged = true;
      this._showElementVersionTwo = showElementVersionTwo;

      this.elementVersionTwoNode.visible(this.showElementVersionTwo);
    }

    if (this._showElementVersionThree !== showElementVersionThree) {
      detailsChanged = true;
      this._showElementVersionThree = showElementVersionThree;

      this.elementVersionThreeNode.visible(this.showElementVersionThree);
    }

    const textOutcome = textValue(outcome || '');
    if (this._outcome !== textOutcome) {
      detailsChanged = true;
      this._outcome = textOutcome;
      this.outcomeTextNode.clearCache();
      this.outcomeTextNode.text(textOutcome);
      this.outcomeTextNode.cache();
      const outcomeHeight = this.outcomeBackgroundNodeHeight(
        this.outcomeTextNode.textArr.length
      );
      this.outcomeBackgroundNode.height(outcomeHeight);
      this.outcomeSelectedNode.height(outcomeHeight);
      this.collapsedNode.height(outcomeHeight);
      this.collapsedTwoNode.height(outcomeHeight);
      this.collapsedThreeNode.height(outcomeHeight);
      this.elementVersionNode.y(
        outcomeHeight + this.elementVersionTransform.padding
      );
      this.drawingSelectedNode.y(
        outcomeHeight + this.elementVersionTransform.padding
      );
      this.methodSelectedNode.y(
        outcomeHeight + this.elementVersionTransform.padding
      );
    }

    if (this._outcomeIsTraversed !== outcomeIsTraversed) {
      detailsChanged = true;
      this._outcomeIsTraversed = outcomeIsTraversed;
      this.outcomeBackgroundNode.fill(this.outcomeFillColor);
      this.outcomeBackgroundNode.stroke(this.outcomeStrokeColor);
      this.outcomeBackgroundNode.dashEnabled(this.outcomeDashEnabled);
    }

    if (this._methodNodeOrdinal !== methodNodeOrdinal) {
      detailsChanged = true;
      this._methodNodeOrdinal = methodNodeOrdinal;
      this.outcomeHeaderTextNode.visible(this.methodNodeOrdinal ? true : false);
      this.outcomeHeaderTextNode.text(this.methodNodeOrdinal);
    }

    if (this._showOutcome !== showOutcome) {
      this._showOutcome = showOutcome;
      this.outcomeNode.visible(this.showOutcome);
      this.outcomePlaceholderNode.visible(this.showOutcomePlaceholder);
      detailsChanged = true;
    }

    if (this._showDrawing !== showDrawing) {
      this._showDrawing = showDrawing;
      this.drawingNode.visible(this.showDrawing);
      this.onScheduleRender();
    }

    if (this._showDrawingPlaceholder !== showDrawingPlaceholder) {
      this._showDrawingPlaceholder = showDrawingPlaceholder;
      this.drawingPlaceholderNode.visible(this.showDrawingPlaceholder);
      this.onScheduleRender();
    }

    if (this._showMethod !== showMethod) {
      this._showMethod = showMethod;
      this.methodNode.visible(this.showMethod);
      this.onScheduleRender();
    }

    if (this._showMethodPlaceholder !== showMethodPlaceholder) {
      this._showMethodPlaceholder = showMethodPlaceholder;
      this.methodPlaceholderNode.visible(this.showMethodPlaceholder);
      this.onScheduleRender();
    }

    if (this._methodUpdatedAt !== methodUpdatedAt) {
      this._methodUpdatedAt = methodUpdatedAt;
      this.methodBackgroundNode.dashEnabled(this.methodDashEnabled);
      this.drawingNode.rotation(this.drawingTransform.rotation);
      this.drawingNode.offset(this.drawingTransform.offset);
      this.drawingPlaceholderNode.rotation(this.drawingTransform.rotation);
      this.drawingPlaceholderNode.offset(this.drawingTransform.offset);

      if (this.methodId) {
        this.onUpdateMethodImage.perform(this.methodId);
      }
      this.onScheduleRender();
    }

    if (this._elementVersionIsKnown !== elementVersionIsKnown) {
      this._elementVersionIsKnown = elementVersionIsKnown;
      detailsChanged = true;
      this.elementVersionBackgroundNode.visible(this.elementVersionIsntKnown);
      this.elementVersionSelectedNode.visible(
        this.elementVersionIsntKnown && elementVersionIsSelected
      );
      this.knownElementVersionBackgroundNode.visible(
        this.elementVersionIsKnown
      );
      this.knownElementVersionSelectedNode.visible(
        this.elementVersionIsKnown && elementVersionIsSelected
      );
      this.updateColors();
    }

    if (this._elementVersionCategory !== elementVersionCategory) {
      this._elementVersionCategory = elementVersionCategory;
      if (this.referenceId) {
        this.onUpdateReferenceImage.perform(this.referenceId);
      }
      this.updateColors();
    }

    if (this._elementVersionIsSelected !== elementVersionIsSelected) {
      this._elementVersionIsSelected = elementVersionIsSelected;

      this.elementVersionSelectedNode.visible(
        this.elementVersionIsntKnown && this.elementVersionIsSelected
          ? true
          : false
      );

      this.knownElementVersionSelectedNode.visible(
        this.elementVersionIsKnown && this.elementVersionIsSelected
          ? true
          : false
      );
    }

    if (this._isSelectedChild !== isSelectedChild) {
      this._isSelectedChild = isSelectedChild;
      detailsChanged = true;
      if (isSelectedChild) {
        this.outcomeSelectedNode.visible(true);
      } else {
        this.outcomeSelectedNode.visible(this.elementIsSelected);
      }

      this.updateColors();
    }

    if (this._isCreatingFrom !== isCreatingFrom) {
      this._isCreatingFrom = isCreatingFrom;
      if (isCreatingFrom) {
        if (this.showElementVersion) {
          this.elementVersionSelectedNode.visible(
            this.elementVersionIsntKnown ? true : false
          );

          this.knownElementVersionSelectedNode.visible(
            this.elementVersionIsKnown ? true : false
          );
        }
        this.onScheduleRender();
      } else {
        if (!this._isCreatingFrom && !this.elementVersionIsSelected) {
          this.elementVersionSelectedNode.visible(false);

          this.knownElementVersionSelectedNode.visible(false);
        }
        this.onScheduleRender();
      }
    }

    if (this._isCreatingTo !== isCreatingTo) {
      this._isCreatingTo = isCreatingTo;
      if (isCreatingTo) {
        this.outcomeSelectedNode.visible(true);
        this.drawingSelectedNode.visible(true);
        this.methodSelectedNode.visible(true);
        this.onScheduleRender();
      } else {
        if (!this._isCreatingTo && !this._elementIsSelected) {
          this.outcomeSelectedNode.visible(false);
          this.drawingSelectedNode.visible(false);
          this.methodSelectedNode.visible(false);
          this.onScheduleRender();
        }
      }
    }

    if (this._x !== x || this._y !== y) {
      this._x = x;
      this._y = y;
      this.elementNode.x(x);
      this.elementNode.y(y);
      this.onScheduleRender();
    }

    if (this._reachedScaleThreshold !== reachedScaleThreshold) {
      this._reachedScaleThreshold = reachedScaleThreshold;
      detailsChanged = true;
      if (reachedScaleThreshold) {
        // this.outcomeHeaderTextNode.visible(false);
        this.outcomeTextNode.visible(false);
        this.drawingImageNode.visible(false);
        this.methodImageNode.visible(false);
        this.outcomePlaceholderTextNode.visible(false);
        this.drawingPlaceholderTextNode.visible(false);
        // this.categoryMachineNode.visible(false);
        // this.categoryArticleNode.visible(false);
        // this.categoryProcessNode.visible(false);
      } else {
        // this.outcomeHeaderTextNode.visible(true);
        this.outcomeTextNode.visible(true);
        this.drawingImageNode.visible(true);
        this.methodImageNode.visible(true);
        this.outcomePlaceholderTextNode.visible(true);
        this.drawingPlaceholderTextNode.visible(true);
        // this.categoryMachineNode.visible(
        //   this.elementVersionCategory === 'machine'
        // );
        // this.categoryArticleNode.visible(
        //   this.elementVersionCategory === 'article-of-manufacture'
        // );
        // this.categoryProcessNode.visible(
        //   this.elementVersionCategory === 'process'
        // );
      }
    }

    if (this._showArrow !== showArrow) {
      this._showArrow = showArrow;
      detailsChanged = true;
      this.outcomeArrowNode.visible(showArrow ? true : false);
    }

    if (this._isPrimaryInstance !== isPrimaryInstance) {
      this._isPrimaryInstance = isPrimaryInstance;
      this.componentPrimaryNode.visible(isPrimaryInstance);
      detailsChanged = true;
    }

    if (this._isInstance !== isInstance) {
      this._isInstance = isInstance;
      this.componentInstanceNode.visible(isInstance);
      detailsChanged = true;
    }

    if (this._darkMode !== darkMode) {
      this._darkMode = darkMode;
      this.updateColors();
      detailsChanged = true;
    }

    if (this._blackAndWhiteMode !== blackAndWhiteMode) {
      this._blackAndWhiteMode = blackAndWhiteMode;
      this.updateColors();

      if (this.reference) {
        this.onUpdateReferenceImage.perform(
          this.reference.id,
          this.reference.type
        );
      } else {
        this.onUpdateReferenceImage.perform(null);
      }
      detailsChanged = true;
    }

    if (detailsChanged) {
      this.onScheduleRender(this.activeLayer);
    }
  }
}

export default connect(
  stateToComputed,
  dispatchToActions
)(InventionGraphSystem);
