import AssetSchema from '../schemas/asset';
import ComparisonSchema from '../schemas/comparison';
import ComponentSchema from '../schemas/component';
import CustomerSchema from '../schemas/customer';
import DrawingSchema from '../schemas/drawing';
import ElementSchema from '../schemas/element';
import ElementVersionSchema from '../schemas/element-version';
import FeatureSchema from '../schemas/feature';
import FiguresSchema from '../schemas/figures';
import GraphSchema from '../schemas/graph';
import HighlightSchema from '../schemas/highlight';
import ImageSchema from '../schemas/image';
import InventionSchema from '../schemas/invention';
import InventionUISchema from '../schemas/invention-ui';
import MarkerSchema from '../schemas/marker';
import MetaSchema from '../schemas/meta';
import MethodEdgePointSchema from '../schemas/method-edge-point';
import MethodEdgeSchema from '../schemas/method-edge';
import MethodNodeSchema from '../schemas/method-node';
import MethodSchema from '../schemas/method';
import NoteSchema from '../schemas/note';
import PatentSpecificationSchema from '../schemas/patent-specification';
import Presentation from '../schemas/presentation';
import PriorArtSchema from '../schemas/prior-art';
import ProductSchema from '../schemas/product';
import ReviewSchema from '../schemas/review';
import SettingsSchema from '../schemas/settings';
import TermSchema from '../schemas/term';
import UserDbSchema from '../schemas/user-db';
import { forEach } from 'lodash';

const getSchema = (schemaId) => {
  let schema;

  switch (schemaId) {
    case 'user-db':
      schema = UserDbSchema;
      break;
    case 'meta':
      schema = MetaSchema;
      break;
    case 'invention':
      schema = InventionSchema;
      break;
    case 'invention-ui':
      schema = InventionUISchema;
      break;
    case 'settings':
      schema = SettingsSchema;
      break;
    case 'figures':
      schema = FiguresSchema;
      break;
    case 'note':
      schema = NoteSchema;
      break;
    case 'product':
      schema = ProductSchema;
      break;
    case 'element':
      schema = ElementSchema;
      break;
    case 'element-version':
      schema = ElementVersionSchema;
      break;
    case 'feature':
      schema = FeatureSchema;
      break;
    case 'drawing':
      schema = DrawingSchema;
      break;
    case 'image':
      schema = ImageSchema;
      break;
    case 'marker':
      schema = MarkerSchema;
      break;
    case 'method':
      schema = MethodSchema;
      break;
    case 'method-node':
      schema = MethodNodeSchema;
      break;
    case 'method-edge':
      schema = MethodEdgeSchema;
      break;
    case 'method-edge-point':
      schema = MethodEdgePointSchema;
      break;
    case 'highlight':
      schema = HighlightSchema;
      break;
    case 'patent-specification':
      schema = PatentSpecificationSchema;
      break;
    case 'presentation':
      schema = Presentation;
      break;
    case 'term':
      schema = TermSchema;
      break;
    case 'graph':
      schema = GraphSchema;
      break;
    case 'review':
      schema = ReviewSchema;
      break;
    case 'asset':
      schema = AssetSchema;
      break;
    case 'prior-art':
      schema = PriorArtSchema;
      break;
    case 'comparison':
      schema = ComparisonSchema;
      break;
    case 'component':
      schema = ComponentSchema;
      break;
    case 'customer':
      schema = CustomerSchema;
      break;
  }

  return {
    ...schema,
  };
};

export const addFromSchema = (schemaId, data = {}) => {
  const schema = getSchema(schemaId);
  const initialState = getIntitalState(schema);
  let updatedData = {
    ...initialState,
    ...data,
  };
  // if (schemaId === 'invention' && data.ui) {
  //   updatedData = {
  //     ...updatedData,
  //     ui: {
  //       ...initialState.ui,
  //       ...data.ui
  //     }
  //   }
  // }
  return updatedData;
};

const getIntitalState = (schema) => {
  const state = {};

  forEach(schema.attributes, (value, key) => {
    state[key] = value.defaultValue;
  });

  return {
    ...state,
  };
};

// const deleteUnusedKeys = (schema, data = {}) => {
//   const state = {};

//   _.forEach(schema.attributes, (value, key) => {
//     state[key] = value.defaultValue;
//   });

//   return {
//     ...state,
//   };
// };
