import Component from '@ember/component';
import { equal } from '@ember/object/computed';

const UiInfoWindow = Component.extend({
  classNames: ['info-window'],
  classNameBindings: [
    'isJumbo',
    'isLarge',
    'isMedium',
    'isSmall',
  ],
  size: 'medium', // small, medium, large
  isJumbo: equal('size', 'jumbo'),
  isLarge: equal('size', 'large'),
  isMedium: equal('size', 'medium'),
  isSmall: equal('size', 'small'),
});

export default UiInfoWindow;
