import Component from '@glimmer/component';
import podNames from 'ember-component-css/pod-names';

class UiContextSectionHeader extends Component {
  constructor() {
    super(...arguments); // super must be called first.
    this.isSmall = this.args.isSmall || false;
    this.isLarge = this.args.isLarge || false;
    this.isPaddingless = this.args.isPaddingless || false;
    this.hasBorderBottom = this.args.hasBorderBottom || false;
    this.hasPaddingOffset = this.args.hasPaddingOffset || false;
    this.isTransparent = this.args.isTransparent || false;
    this.isAutoHeight = this.args.isAutoHeight || false;
    this.isSticky = this.args.isSticky || false;
    this.isDark = this.args.isDark || false;
  }

  get classNames() {
    let classNames = [this.styleNamespace, 'ui-context-section-header'];
    if (this.isSmall) classNames.push('is-small');
    if (this.isLarge) classNames.push('is-large');
    if (this.isAutoHeight) classNames.push('is-auto-height');
    if (this.hasBorderBottom) classNames.push('has-border-bottom');
    if (this.hasPaddingOffset) classNames.push('has-padding-offset');
    if (this.isTransparent) classNames.push('is-transparent');
    if (this.isPaddingless) classNames.push('is-paddingless');
    if (this.isSticky) classNames.push('is-sticky');
    if (this.isDark) classNames.push('is-dark');
    return classNames.join(' ');
  }

  get styleNamespace() {
    return podNames['ui-context-section-header'];
  }
}

export default UiContextSectionHeader;
