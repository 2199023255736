import {
  BLACK_AND_WHITE_COLOR,
  BLACK_AND_WHITE_FILL,
  BLACK_AND_WHITE_STROKE,
  BODY_COLOR,
  BODY_COLOR_DARK,
  BODY_COLOR_LIGHT,
  BODY_COLOR_LIGHT_DARK,
  BORDER_COLOR_LIGHT,
  BORDER_COLOR_LIGHT_DARK,
  ELEMENT_COLLAPSED_NODE_STROKE,
  ELEMENT_NODE_COLOR,
  ELEMENT_NODE_FILL,
  ELEMENT_NODE_FILL_LIGHT,
  ELEMENT_NODE_GREY_COLOR,
  ELEMENT_NODE_GREY_FILL,
  ELEMENT_NODE_GREY_FILL_LIGHT,
  ELEMENT_NODE_GREY_STROKE,
  ELEMENT_NODE_GREY_STROKE_LIGHT,
  ELEMENT_NODE_HEADER_COLOR,
  ELEMENT_NODE_NOVELTY_STROKE,
  ELEMENT_NODE_NOVELTY_STROKE_LIGHT,
  ELEMENT_NODE_SELECTED_CHILD_STROKE,
  ELEMENT_NODE_SELECTED_CHILD_STROKE_DARK,
  ELEMENT_NODE_STROKE,
  ELEMENT_VERSION_CATEGORY_ARTICLE,
  ELEMENT_VERSION_CATEGORY_ARTICLE_LIGHT,
  ELEMENT_VERSION_CATEGORY_ARTICLE_SELECTED,
  ELEMENT_VERSION_CATEGORY_ARTICLE_SELECTED_DARK,
  ELEMENT_VERSION_CATEGORY_COMPOSITION,
  ELEMENT_VERSION_CATEGORY_COMPOSITION_LIGHT,
  ELEMENT_VERSION_CATEGORY_COMPOSITION_SELECTED,
  ELEMENT_VERSION_CATEGORY_COMPOSITION_SELECTED_DARK,
  ELEMENT_VERSION_CATEGORY_MACHINE,
  ELEMENT_VERSION_CATEGORY_MACHINE_LIGHT,
  ELEMENT_VERSION_CATEGORY_MACHINE_SELECTED,
  ELEMENT_VERSION_CATEGORY_MACHINE_SELECTED_DARK,
  ELEMENT_VERSION_CATEGORY_PROCESS,
  ELEMENT_VERSION_CATEGORY_PROCESS_LIGHT,
  ELEMENT_VERSION_CATEGORY_PROCESS_SELECTED,
  ELEMENT_VERSION_CATEGORY_PROCESS_SELECTED_DARK,
  ELEMENT_VERSION_NODE_STROKE,
  PLACEHOLDER_FILL,
  PLACEHOLDER_FILL_DARK,
  PLACEHOLDER_STROKE,
  PLACEHOLDER_STROKE_DARK,
  PLACEHOLDER_TEXT,
  PLACEHOLDER_TEXT_DARK,
  TEAL_300,
  TEAL_500,
  TEAL_600,
  WARNING_NODE_FILL,
  WARNING_NODE_STROKE,
} from '../../../constants/colors';
import {
  COMPONENT_PATH,
  INSTANCE_PATH,
  KNOWN_PATH,
} from '../../../constants/icon-paths';
import { action, computed } from '@ember/object';
import { getElement, getElementParent } from '../../../selectors/element';
import {
  getPreferredElementVersionId,
  getProduct,
} from '../../../selectors/product';

/* eslint-disable ember/use-brace-expansion */
import Component from '@glimmer/component';
import ENV from '../../../config/environment';
import Konva from 'konva';
import { connect } from 'ember-redux';
import { getActiveFeature } from '../../../selectors/invention-ui';
import { getComponentInstanceOf } from '../../../selectors/component';
import { getDrawing } from '../../../selectors/drawing';
import { getElementVersion } from '../../../selectors/element-version';
import { getFeature } from '../../../selectors/feature';
import { getMarker } from '../../../selectors/marker';
import { getMentionsContent } from '../../../selectors/mention';
import { getMethod } from '../../../selectors/method';
import { getMethodNode } from '../../../selectors/method-node';
import { getMethodNodeOrdinal } from '../../../selectors/invention';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency-decorators';
import { textValue } from '../../../utils/string';

// import { getPreferredElementVersion } from '../../../selectors/product';

const dispatchToActions = {};

const stateToComputed = (state, attrs) => ({
  parent: getElementParent(state, attrs.elementId),
  element: getElement(state, attrs.elementId),
  instanceOf: getComponentInstanceOf(state, attrs.componentId),
  activeFeatureId: getActiveFeature(state),
  product: getProduct(state, attrs.productId),
});

class InventionGraphSystem extends Component {
  @service redux;
  @service referencesCache;
  @service methodsCacheKonva;
  @service models;
  @service store;
  @service settings;

  fontFamily = 'Inter';
  textHeight = 75;
  spacer = 26;
  dividerHeight = 52;
  outcomePlaceholderHeight = 125;
  verticalPadding = 19.5;
  horizontalPadding = 19.5;
  borderRadius = 26;
  radius = 200;
  height = 400;
  width = 460;
  _isNovel = false;
  _isNovelAncestor = false;
  _isCollapsed = false;
  _visibleAreaIndex = 0;
  _elementIsSelected = '';
  _x = 0;
  _y = 0;
  _reachedScaleThreshold = false;

  categoryFontSize = 24;
  categoryLineHeight = 1.3;
  nameFontSize = 24;
  nameLineHeight = 1.3;

  constructor(owner, args) {
    super(owner, args);
    this.onScheduleRender = this.args.onScheduleRender;
    this.elementsLayer = this.args.elementsLayer;
    this.interactiveLayer = this.args.interactiveLayer;
  }

  @action
  didInsert() {
    this._showArrow = this.showArrow;
    this._isNovel = this.args.isNovel;
    this._isNovelAncestor = this.args.isNovelAncestor;
    this._isDisconnected = this.args.isDisconnected;
    this._isCollapsed = this.args.isCollapsed;
    this._visibleAreaIndex = this.args.visibleAreaIndex;
    this._elementIsSelected = this.elementIsSelected;
    this._elementVersionIsSelected = this.elementVersionIsSelected;
    this._isSelectedChild = this.args.isSelectedChild;
    this._x = this.args.x;
    this._y = this.args.y;
    this._reachedScaleThreshold = this.args.reachedScaleThreshold;
    this._solutionsString = this.solutionsString;
    this._referenceId = this.referenceId;
    this._referenceUpdatedAt = this.referenceUpdatedAt;
    this._name = this.name;
    this._showElementPlaceholder = this.showElementVersionPlaceholder;
    this._showElementVersion = this.showElementVersion;
    this._showElementVersionTwo = this.showElementVersionTwo;
    this._showElementVersionThree = this.showElementVersionThree;
    this._outcome = this.outcome;
    this._outcomeIsTraversed = this.outcomeIsTraversed;
    this._methodNodeOrdinal = this.methodNodeOrdinal;
    this._showOutcome = this.showOutcome;
    this._showDrawing = this.showDrawing;
    this._showDrawingPlaceholder = this.showDrawingPlaceholder;
    this._showMethodPlaceholder = this.showMethodPlaceholder;
    this._showMethod = this.showMethod;
    this._methodUpdatedAt = this.methodUpdatedAt;
    this._known = this.known;
    this._elementVersionCategory = this.elementVersionCategory;
    this._elementVersionIsSelected = this.elementVersionIsSelected;
    this.activeLayer = this.args.isSelected
      ? this.interactiveLayer
      : this.elementsLayer;
    this._isPrimaryInstance = this.args.isPrimaryInstance;
    this._isInstance = this.isInstance;
    this._successEventIsSelected = this.successEventIsSelected;
    this._darkMode = this.settings.darkMode;
    this._blackAndWhiteMode = this.settings.blackAndWhiteMode;
    this.setup();
  }

  @action
  willDestroyNode() {
    // remove events
    if (this.args.onContextClick) {
      this.elementNode.off('contextmenu');
    }
    if (this.args.onClick) {
      this.elementNode.off('click');
    }
    if (this.args.onDragStart) {
      this.elementNode.off('dragstart');
    }
    if (this.args.onDragMove) {
      this.elementNode.off('dragmove');
    }
    if (this.args.onDragEnd) {
      this.elementNode.off('dragend');
    }
    if (this.args.onMouseenter) {
      this.elementNode.off('mouseenter');
    }
    if (this.args.onMouseleave) {
      this.elementNode.off('mouseleave');
    }
    this.elementNode.destroy();
    this.onScheduleRender();
  }

  get styleNamespace() {
    return podNames['invention-graph-system-konva'];
  }

  get classNames() {
    let classNames = [this.styleNamespace];
    if (this.args.isSelected) classNames.push('is-selected');
    if (this.args.isCollapsed) classNames.push('is-collapsed');
    if (this.args.isDisconnected) classNames.push('is-disconnected');
    return classNames.join(' ');
  }

  get hasMultipleElementVersions() {
    return (
      this.args.elementVersionsList && this.args.elementVersionsList.length > 1
    );
  }

  @computed('element.{component,instanceOf}')
  get isInstance() {
    return this.element && this.element.component && this.element.instanceOf
      ? true
      : false;
  }

  @computed('args.name')
  get name() {
    return this.args.name;
  }

  @computed('element.useDefaultRequirements')
  get useDefaultRequirements() {
    return this.element && this.element.useDefaultRequirements;
  }

  @computed('element.useDefaultOutcome')
  get useDefaultOutcome() {
    return this.element && this.element.useDefaultOutcome;
  }

  // eslint-disable-next-line ember/require-computed-property-dependencies
  @computed('args.elementVersionsList.[]', 'args.{productId,elementId}')
  get showElementVersionPlaceholder() {
    const state = this.redux.getState();

    const preferredElementVersion = getPreferredElementVersionId(
      state,
      this.args.elementId,
      this.args.productId
    );

    return this.args.productId && !preferredElementVersion ? true : false;
  }

  @computed('args.category')
  get categoryName() {
    return this.args.category === 'part' ? 'Part' : 'System';
  }

  @computed('element.elementVersion')
  get parentElementVersionId() {
    return this.element && this.element.elementVersion;
  }

  @computed('parentElementVersionId')
  get parentElementVersion() {
    const state = this.redux.getState();
    return (
      this.parentElementVersionId &&
      getElementVersion(state, this.parentElementVersionId)
    );
  }

  @computed('parentElementVersion.method')
  get parentMethodId() {
    return this.parentElementVersion && this.parentElementVersion.method;
  }

  @computed('parentMethodId')
  get parentMethodModel() {
    return (
      this.parentMethodId &&
      this.store.peekRecord('method', this.parentMethodId)
    );
  }

  @computed('parentMethodModel.updatedAt')
  get parentMethodUpdatedAt() {
    return this.parentMethodModel && this.parentMethodModel.updatedAt;
  }

  @computed(
    'parentMethodId',
    'methodNodeId',
    'outcomeIsTraversed',
    'parentMethodModel.traversedNodesList.[]'
  )
  get methodNodeOrdinal() {
    const state = this.redux.getState();
    return (
      this.outcomeIsTraversed &&
      this.methodNodeId &&
      getMethodNodeOrdinal(
        state,
        this.parentMethodId,
        this.methodNodeId,
        this.parentMethodModel.traversedNodesList
      )
    );
  }

  @computed('args.elementId', 'parentMethodModel.methodNodesList.[]')
  get methodNodeId() {
    const state = this.redux.getState();
    return (
      this.parentMethodModel &&
      this.parentMethodModel.methodNodesList.find((methodNodeId) => {
        const methodNode = getMethodNode(state, methodNodeId);
        return methodNode.element === this.args.elementId;
      })
    );
  }

  @computed('parent.category')
  get isTopLevel() {
    return this.parent && this.parent.category === 'product';
  }

  @computed('args.elementId', 'parentMethodModel.traversedNodesList.[]')
  get outcomeIsTraversed() {
    const state = this.redux.getState();
    return this.parentMethodModel &&
      this.parentMethodModel.traversedNodesList.find((methodNodeId) => {
        const methodNode = getMethodNode(state, methodNodeId);
        return methodNode.element === this.args.elementId;
      })
      ? true
      : false;
  }

  @computed('outcomeIsTraversed', 'isTopLevel')
  get outcomeDashEnabled() {
    let outcomeDashEnabled = this.outcomeIsTraversed ? false : true;

    if (this.isTopLevel) {
      outcomeDashEnabled = false;
    }

    return outcomeDashEnabled;
  }

  get outcomeStrokeColor() {
    let color = this.outcomeIsTraversed
      ? this.textColor
      : this.placeholderStrokeColor;

    return color;
  }

  get outcomeFillColor() {
    let color = this.outcomeIsTraversed ? '#FFF' : this.placeholderFillColor;

    return color;
  }

  // eslint-disable-next-line ember/require-computed-property-dependencies
  @computed(
    'args.{elementVersionsList.[],preferredElementVersionsList.[],productId,elementId}'
  )
  get elementVersionId() {
    const state = this.redux.getState();
    let elementVersionId;
    if (this.args.productId) {
      elementVersionId = getPreferredElementVersionId(
        state,
        this.args.elementId,
        this.args.productId
      );
    }
    return elementVersionId;
  }

  @computed('elementVersion.known')
  get elementVersionIsKnown() {
    return this.elementVersion ? this.elementVersion.known : false;
  }

  @computed('elementVersionIsKnown')
  get elementVersionIsntKnown() {
    return !this.elementVersionIsKnown;
  }

  @computed('elementVersion.category')
  get elementVersionCategory() {
    return this.elementVersion && this.elementVersion.category;
  }
  @computed('elementVersionId', 'args.elementVersionsList.[]')
  get solutionsString() {
    return (
      this.args.elementVersionsList &&
      this.elementVersionId &&
      this.elementVersionId + this.args.elementVersionsList.join(',')
    );
  }

  @computed('elementVersion.known')
  get known() {
    return this.elementVersion && this.elementVersion.known;
  }

  @computed('elementVersionId')
  get elementVersion() {
    const state = this.redux.getState();
    return (
      this.elementVersionId && getElementVersion(state, this.elementVersionId)
    );
  }

  // get outcomeModel() {
  //   const state = this.redux.getState();
  //   const featureId = this.args.outcome;
  //   const feature = getFeature(state, featureId);
  //   return feature;
  // }

  @computed(
    'isInstance',
    'instanceOf.outcome',
    'element.outcome',
    'useDefaultOutcome'
  )
  get outcomeId() {
    let outcomeId = this.element.outcome;
    if (this.isInstance && this.useDefaultOutcome && this.instanceOf) {
      outcomeId = this.instanceOf.outcome;
    }

    return outcomeId;
  }

  @computed('outcomeId', 'activeFeatureId')
  get successEventIsSelected() {
    return this.outcomeId === this.activeFeatureId;
  }

  @computed('args.{isSelected,productId}', 'elementVersionIsSelected')
  get elementIsSelected() {
    let elementIsSelected = this.args.isSelected;

    if (this.args.productId) {
      elementIsSelected =
        this.args.isSelected && !this.elementVersionIsSelected;
    }
    return elementIsSelected;
  }

  @computed(
    'args.selectedElementVersions.[]',
    'elementVersionId',
    'args.isSelected'
  )
  get elementVersionIsSelected() {
    return this.elementVersionId &&
      // this.args.isSelected &&
      this.args.selectedElementVersions &&
      this.args.selectedElementVersions.includes(this.elementVersionId)
      ? true
      : false;
  }

  @computed('outcomeId')
  get outcomeModel() {
    const state = this.redux.getState();
    const featureId = this.outcomeId;
    const feature = getFeature(state, featureId);
    return (
      this.outcomeId &&
      (this.models.find(this.outcomeId) ||
        this.models.findOrCreate(feature.id, 'feature', feature))
    );
  }

  @computed('outcomeModel.{id,value}')
  get outcome() {
    const state = this.redux.getState();
    let value = (this.outcomeModel && this.outcomeModel.value) || '';
    if (value) {
      value = getMentionsContent(
        state,
        value,
        `feature-${this.outcomeModel.id}`,
        {
          appendOrdinal: false,
          appendElementVersion: false,
          isInput: true,
          isInteractive: false,
        }
      );
    }
    return textValue(value);
  }

  @computed('elementVersion.markersList.[]')
  get referenceId() {
    return (
      this.elementVersion &&
      this.elementVersion.markersList &&
      this.elementVersion.markersList.length &&
      this.elementVersion.markersList[0]
    );
  }

  @computed('args.{drawings,methods}', 'referenceId')
  get referenceSource() {
    let source;
    const state = this.redux.getState();
    const marker = this.referenceId && getMarker(state, this.referenceId);
    if (marker) {
      source = getDrawing(state, marker.drawing);
    }
    return source;
  }

  @computed('referenceSource.updatedAt', 'referenceId')
  get referenceUpdatedAt() {
    return (
      this.referenceId && this.referenceSource && this.referenceSource.updatedAt
    );
  }

  @computed('referenceId')
  get hasReference() {
    return this.referenceId ? true : false;
  }

  @computed('args.{elementId,nodesMap}')
  get siblings() {
    const id = this.args.elementId;
    const node = this.args.nodesMap && this.args.nodesMap[id];
    const siblings =
      node && node.parent && this.args.nodesMap[node.parent].children;
    return siblings;
  }

  @computed('args.{elementId,nodesMap}')
  get children() {
    const id = this.args.elementId;
    const node = this.args.nodesMap && this.args.nodesMap[id];
    const children = node && this.args.nodesMap[id].children;
    return children;
  }

  @computed('siblings.[]')
  get hasSiblings() {
    return this.siblings && this.siblings.length > 1;
  }

  @computed('siblings.[]', 'args.elementId')
  get isLastSibling() {
    const index = this.siblings && this.siblings.indexOf(this.args.elementId);
    const length = this.siblings && this.siblings.length;
    return length && index === length - 1;
  }

  @computed('hasSiblings', 'isLastSibling', 'siblings.length')
  get showArrow() {
    const isLoneSibling = this.siblings.length === 1;
    return isLoneSibling || (this.hasSiblings && !this.isLastSibling)
      ? true
      : false;
  }

  @computed('elementVersionId')
  get hasSolution() {
    return this.elementVersionId ? true : false;
  }

  // @computed('elementVersionId', 'args.elementVersionsList.[]')
  // get hasTwoSolutions() {
  //   return this.elementVersionId &&
  //     this.args.elementVersionsList &&
  //     this.args.elementVersionsList.length >= 2
  //     ? true
  //     : false;
  // }

  // @computed('elementVersionId', 'args.elementVersionsList.[]')
  // get hasThreeOrMoreSolutions() {
  //   return this.elementVersionId &&
  //     this.args.elementVersionsList &&
  //     this.args.elementVersionsList.length >= 3
  //     ? true
  //     : false;
  // }

  get textWidth() {
    return this.width - this.horizontalPadding * 2;
  }

  get collapsedTransform() {
    const x = 0;
    const y = this.height + 325;
    return { x, y };
  }

  get elementVersionTransform() {
    let x = 0;
    let y = 0;
    let width = this.width;
    let height = 100;
    const offset = {
      x: width / 2,
      y: 0,
    };
    return {
      x,
      y,
      width,
      height,
      offset,
    };
  }

  get elementBackgroundTransform() {
    let x = 0;
    let y = 0;
    let width = this.width + this.spacer * 2.5;
    let height = 100;
    const offset = {
      x: width / 2,
      y: 0,
    };
    return {
      x,
      y,
      width,
      height,
      offset,
    };
  }

  get sourceNodeKnownTransform() {
    const width = 50;
    const height = 50;
    const radius = width / 2;
    const offset = {
      x: radius,
      y: 16,
    };

    return { width, height, radius, offset };
  }

  get sourceBackgroundNodeTransform() {
    const radius = (this.spacer * 1.25) / 2;
    return { radius };
  }

  get jaggedLinePoints() {
    const sections = 33;
    const sectionWidth = this.width / sections;
    const sectionHeight = this.spacer / 2;
    let points = [];
    for (let i = 0; i <= sections; i++) {
      const x = sectionWidth * i;
      const y = i % 2 == 0 ? 0 : sectionHeight;
      points.push(x);
      points.push(y);
    }
    return points;
  }

  get nameTransform() {
    const height = this.textHeight;
    const width = this.width - 40;
    const offset = {
      // y: height + 26,
      y: 0,
      x: this.width / 2,
    };
    return { height, width, offset };
  }

  get dividerTransform() {
    const y = 0;
    const offset = {
      // y: height + 26,
      y: 0,
      x: this.width / 2,
    };
    return { y, offset };
  }

  get categoryTransform() {
    const height = this.textHeight;
    const width = this.width - 40;
    const offset = {
      // y: height + 42,
      y: 0,
      x: this.width / 2,
    };
    return { height, width, offset };
  }

  get outcomeTransform() {
    const x = 0;
    const y = 0;
    const width = this.width;
    const offset = {
      x: this.width / 2,
      // y: this.height / 2 - this.textHeight - this.spacer,
      y: 0,
    };
    return { x, y, width, offset };
  }

  get outcomePlaceholderTransform() {
    const x = 0;
    const y = 0;
    const width = this.width;
    const height = this.outcomePlaceholderHeight;
    const offset = {
      x: this.width / 2,
      // y: this.height / 2 - this.textHeight - this.spacer,
      y: 0,
    };
    return { x, y, width, height, offset };
  }

  get drawingTransform() {
    let rotation = 0;
    let offset = {
      x: 0,
      y: 0,
    };
    let width = this.width / 2 - this.spacer / 1.5;
    let height = width;
    let x = 0;
    let y = 0;
    return { x, y, offset, width, height, rotation };
  }

  // get drawingTransform() {
  //   let rotation = -11;
  //   let width = this.width / 2 + this.spacer / 2;
  //   let height = width + 5;
  //   let x = this.width / 3 - this.spacer;
  //   let y = height / 2;

  //   let offset = {
  //     x: width / 2,
  //     y: height / 2,
  //   };
  //   return { x, y, rotation, offset, width, height };
  // }

  // get methodTransform() {
  //   // let width = this.width / 3 + this.spacer / 2;
  //   let rotation = 11;
  //   let width = this.width / 2 + this.spacer / 2;
  //   let height = width * 1.319;
  //   let x = this.width - (this.width / 3) + this.spacer;
  //   let y = height / 2;
  //   let offset = {
  //     x: width / 2,
  //     y: height / 2,
  //   };

  //   return { x, y, offset, width, height, rotation };
  // }

  get methodTransform() {
    // let width = this.width / 3 + this.spacer / 2;
    let width = this.width / 2 - this.spacer / 1.5;
    // let height = width;
    let rotation = 0;
    let height = width * 1.319;
    let x = width + this.spacer;
    let y = 0;
    let offset = {
      x: 0,
      y: 0,
    };

    return { x, y, offset, width, height, rotation };
  }

  get methodStrokeColor() {
    let color = this.placeholderStrokeColor;

    if (this.settings.blackAndWhiteMode) {
      color = BLACK_AND_WHITE_STROKE;
    }

    return color;
  }

  @computed('elementVersion.method')
  get methodId() {
    return this.elementVersion && this.elementVersion.method;
  }

  @computed('methodId')
  get methodModel() {
    return this.methodId && this.store.peekRecord('method', this.methodId);
  }

  @computed('methodModel.updatedAt')
  get methodUpdatedAt() {
    return this.methodModel && this.methodModel.updatedAt;
  }

  @computed('methodHasDisconnectNodes')
  get methodDashEnabled() {
    return this.methodHasDisconnectNodes ? true : false;
  }

  @computed('methodModel.disconnectedNodesList.[]')
  get methodHasDisconnectNodes() {
    return this.methodModel && this.methodModel.disconnectedNodesList.length
      ? true
      : false;
  }

  @computed('methodModel.methodNodesList.[]')
  get methodHasNodes() {
    return this.methodModel && this.methodModel.methodNodesList.length > 1;
  }

  @computed('methodHasNodes')
  get showMethod() {
    return this.methodHasNodes ? true : false;
  }

  get requirements() {
    const state = this.redux.getState();
    return this.args.requirementsList.map((requirementId) => {
      const feature = getFeature(state, requirementId);
      return (
        this.models.find(requirementId) ||
        this.models.findOrCreate(feature.id, 'feature', feature)
      );
    });
  }

  @computed('requirements.@each.value', 'args.requirementsList.[]')
  get hasRequirements() {
    return this.requirements.filter((requirement) =>
      textValue(requirement.value || '')
    ).length
      ? true
      : false;
  }

  @computed('outcome')
  get hasOutcome() {
    return this.outcome && textValue(this.outcome || '') ? true : false;
  }

  @computed('hasOutcome')
  get showOutcome() {
    return this.hasOutcome;
  }

  @computed('showOutcome')
  get showOutcomePlaceholder() {
    return !this.showOutcome;
  }

  @computed('hasReference', 'elementVersionId')
  get showDrawing() {
    return this.elementVersionId && this.hasReference ? true : false;
  }

  @computed('elementVersionId', 'args.productId')
  get showElementVersion() {
    return this.args.productId && this.elementVersionId ? true : false;
  }

  @computed('showDrawing')
  get showDrawingPlaceholder() {
    return !this.showDrawing;
  }

  @computed('showMethod', 'elementVersionIsKnown', 'methodHasNodes')
  get showMethodPlaceholder() {
    const methodHasNodes = this.methodHasNodes ? true : false;
    return this.elementVersionIsKnown ? methodHasNodes : !this.showMethod;
  }

  getNameNodeY(lines) {
    const lineHeight = 35 * 1.1;
    const nodeHeight = 400;
    return nodeHeight / 2 - (lines * lineHeight) / 2;
  }

  getSolutionNameNodeY(nameNodeY, nameNodeLines) {
    const paddingTop = 10;
    const nameNodeHeight = nameNodeLines * (35 * 1.1);
    return paddingTop + nameNodeY + nameNodeHeight;
  }

  outcomeBackgroundNodeHeight(lines) {
    const headerHeight = 0;
    let height = this.outcomePlaceholderHeight;

    if (!this.hasOutcome) {
      lines = 0;
    }

    switch (lines) {
      case 1:
        height = 64;
        break;
      case 2:
        height = 96;
        break;
      case 3:
        height = 129;
        break;
      case 4:
        height = 162;
        break;
      case 5:
        height = 194;
        break;
      case 6:
        height = 227;
        break;
    }

    return height + headerHeight + 1;
  }

  get strokeColor() {
    let color = this.settings.darkMode
      ? BORDER_COLOR_LIGHT_DARK
      : BORDER_COLOR_LIGHT;

    if (this.args.isSelected) {
      color = ELEMENT_NODE_STROKE;
    }
    if (this.args.isDisconnected) {
      color = ELEMENT_NODE_GREY_STROKE;
    }
    if (this.settings.blackAndWhiteMode) {
      color = BLACK_AND_WHITE_STROKE;
    }
    return color;
  }

  get elementBackgroundStrokeColor() {
    // let color = this.strokeColor;
    let color = this.args.isSelected ? this.strokeColor : 'transparent';
    return color;
  }

  get elementBackgroundFillColor() {
    let color = this.args.isSelected ? ELEMENT_NODE_FILL : 'transparent';
    return color;
  }

  get sourceNodeFillColor() {
    let color = 'transparent';

    // if (this.args.isCollapsed) {
    //   color = this.sourceNodeStrokeColor;
    // }

    if (this.args.isSelected) {
      color = ELEMENT_NODE_FILL;

      if (this.args.isCollapsed) {
        color = ELEMENT_NODE_STROKE;
      }
    }
    return color;
  }

  get sourceNodeStrokeColor() {
    let color = this.placeholderStrokeColor;


    if (this.args.isCollapsed) {
      color = this.settings.darkMode
        ? BODY_COLOR_DARK
        : BODY_COLOR;
    }
    
    if (this.args.isSelected) {
      color = ELEMENT_NODE_STROKE;
    }

    return color;
  }

  get sourceNodeKnownFillColor() {
    // let color = this.placeholderStrokeColor;
    // return color;
    return this.elementVersionFillColor;
  }

  get sourceNodeKnownStrokeColor() {
    // let color = this.settings.darkMode
    //   ? BODY_COLOR_DARK
    //   : BODY_COLOR;

    // if (this.args.isSelected) {
    //   color = ELEMENT_NODE_STROKE;
    // }

    return this.elementVersionStrokeColor;
  }

  get strokeColorLight() {
    let color = ELEMENT_COLLAPSED_NODE_STROKE;

    if (this.args.isCollapsed && this.args.isNovelAncestor) {
      color = ELEMENT_NODE_NOVELTY_STROKE_LIGHT;
    }
    if (this.args.isDisconnected) {
      color = ELEMENT_NODE_GREY_STROKE_LIGHT;
    }
    if (this.settings.blackAndWhiteMode) {
      color = BLACK_AND_WHITE_STROKE;
    }
    return color;
  }

  get strokeColorWarning() {
    let color = WARNING_NODE_STROKE;
    return color;
  }

  get noveltyNodeVisible() {
    return (
      this.args.isNovel || (this.args.isCollapsed && this.args.isNovelAncestor)
    );
  }

  get placeholderDash() {
    return [15, 10];
  }

  get placeholderFillColor() {
    let color = this.settings.darkMode
      ? PLACEHOLDER_FILL_DARK
      : PLACEHOLDER_FILL;

    if (this.args.isSelected) {
      color = TEAL_600;
    }

    if (this.settings.blackAndWhiteMode) {
      color = BLACK_AND_WHITE_FILL;
    }

    return color;
  }

  get placeholderStrokeColor() {
    let color = this.settings.darkMode
      ? PLACEHOLDER_STROKE_DARK
      : PLACEHOLDER_STROKE;

    if (this.args.isSelected) {
      color = 'rgba(0,0,0,0.2)';
    }

    if (this.settings.blackAndWhiteMode) {
      color = BLACK_AND_WHITE_STROKE;
    }

    return color;
  }

  get drawingStrokeColor() {
    let color = this.placeholderStrokeColor;

    if (this.settings.blackAndWhiteMode) {
      color = BLACK_AND_WHITE_STROKE;
    }

    return color;
  }

  get placeholderSelectedStrokeColor() {
    let color = this.settings.darkMode
      ? PLACEHOLDER_STROKE_DARK
      : PLACEHOLDER_STROKE;

    if (this.settings.blackAndWhiteMode) {
      color = BLACK_AND_WHITE_STROKE;
    }

    return color;
  }

  get placeholderTextColor() {
    let color = this.settings.darkMode
      ? BODY_COLOR_LIGHT_DARK
      : BODY_COLOR_LIGHT;

    if (this.args.isSelected) {
      color = 'rgba(0,0,0,0.4)';
    }

    if (this.settings.blackAndWhiteMode) {
      color = BLACK_AND_WHITE_COLOR;
    }

    return color;
  }

  get outcomeArrowFill() {
    return this.settings.darkMode ? PLACEHOLDER_STROKE_DARK : '#000';
  }

  get outcomeHeaderFill() {
    return this.settings.darkMode ? '#FFF' : '#000';
  }

  get fillColor() {
    let color = ELEMENT_NODE_FILL;

    if (this.args.isDisconnected) {
      color = ELEMENT_NODE_GREY_FILL;
    }
    if (this.settings.blackAndWhiteMode) {
      color = BLACK_AND_WHITE_FILL;
    }
    return color;
  }

  get selectedNodeFill() {
    let color = ELEMENT_NODE_STROKE;

    if (this.args.isDisconnected) {
      color = ELEMENT_NODE_GREY_FILL;
    }

    return color;
  }

  get fillColorLight() {
    let color = ELEMENT_NODE_FILL_LIGHT;

    if (this.args.isDisconnected) {
      color = ELEMENT_NODE_GREY_FILL_LIGHT;
    }
    if (this.settings.blackAndWhiteMode) {
      color = BLACK_AND_WHITE_FILL;
    }
    return color;
  }

  get fillColorCollapsed() {
    let color = '#FFF';

    if (this.args.isDisconnected) {
      color = ELEMENT_NODE_GREY_FILL_LIGHT;
    }

    if (this.settings.blackAndWhiteMode) {
      color = BLACK_AND_WHITE_FILL;
    }
    return color;
  }

  get strokeColorCollapsed() {
    let color = '#000';

    if (this.args.isDisconnected) {
      color = ELEMENT_NODE_GREY_STROKE;
    }

    if (this.settings.blackAndWhiteMode) {
      color = BLACK_AND_WHITE_STROKE;
    }
    return color;
  }

  get fillColorWarning() {
    let color = WARNING_NODE_FILL;
    if (this.args.isDisconnected) {
      color = ELEMENT_NODE_GREY_FILL_LIGHT;
    }
    if (this.settings.blackAndWhiteMode) {
      color = BLACK_AND_WHITE_FILL;
    }
    return color;
  }

  @computed(
    'elementVersionCategory',
    'args.isDisconnected',
    'settings.blackAndWhiteMode'
  )
  get elementVersionFillColor() {
    return this.getElementVersionFillColor(
      this.elementVersionCategory,
      this.args.isDisconnected
    );
  }

  @computed(
    'elementVersionCategory',
    'args.isDisconnected',
    'settings.blackAndWhiteMode'
  )
  get elementVersionStrokeColor() {
    return this.getElementVersionStrokeColor(
      this.elementVersionCategory,
      this.args.isDisconnected
    );
  }

  @computed(
    'elementVersionCategory',
    'args.isDisconnected',
    'args.isSelectedChild',
    'settings.blackAndWhiteMode',
    'settings.settings.darkMode'
  )
  get elementVersionSelectedFillColor() {
    return this.getElementVersionSelectedFillColor(
      this.elementVersionCategory,
      this.args.isDisconnected
    );
  }

  getElementVersionFillColor(category, isDisconnected) {
    let color = ELEMENT_NODE_GREY_FILL;

    if (category === 'machine') {
      color = ELEMENT_VERSION_CATEGORY_MACHINE_LIGHT;
    }
    if (category === 'process') {
      color = ELEMENT_VERSION_CATEGORY_PROCESS_LIGHT;
    }
    if (category === 'article-of-manufacture') {
      color = ELEMENT_VERSION_CATEGORY_ARTICLE_LIGHT;
    }
    if (category === 'composition') {
      color = ELEMENT_VERSION_CATEGORY_COMPOSITION_LIGHT;
    }

    if (isDisconnected) {
      color = ELEMENT_NODE_GREY_FILL;
    }

    if (this.settings.blackAndWhiteMode) {
      color = BLACK_AND_WHITE_FILL;
    }
    return color;
  }

  getElementVersionSelectedFillColor(category, isDisconnected) {
    let color = ELEMENT_VERSION_NODE_STROKE;

    if (category === 'machine') {
      color = this.settings.darkMode
        ? ELEMENT_VERSION_CATEGORY_MACHINE_SELECTED_DARK
        : ELEMENT_VERSION_CATEGORY_MACHINE_SELECTED;
    }
    if (category === 'process') {
      color = this.settings.darkMode
        ? ELEMENT_VERSION_CATEGORY_PROCESS_SELECTED_DARK
        : ELEMENT_VERSION_CATEGORY_PROCESS_SELECTED;
    }
    if (category === 'article-of-manufacture') {
      color = this.settings.darkMode
        ? ELEMENT_VERSION_CATEGORY_ARTICLE_SELECTED_DARK
        : ELEMENT_VERSION_CATEGORY_ARTICLE_SELECTED;
    }
    if (category === 'composition') {
      color = this.settings.darkMode
        ? ELEMENT_VERSION_CATEGORY_COMPOSITION_SELECTED_DARK
        : ELEMENT_VERSION_CATEGORY_COMPOSITION_SELECTED;
    }

    if (isDisconnected) {
      color = this.settings.darkMode
        ? ELEMENT_NODE_SELECTED_CHILD_STROKE_DARK
        : ELEMENT_NODE_SELECTED_CHILD_STROKE;
    }

    if (this.args.isSelectedChild) {
      color = this.settings.darkMode
        ? ELEMENT_NODE_SELECTED_CHILD_STROKE_DARK
        : ELEMENT_NODE_SELECTED_CHILD_STROKE;
    }

    if (this.settings.blackAndWhiteMode) {
      color = BLACK_AND_WHITE_STROKE;
    }
    return color;
  }

  getElementVersionStrokeColor(category, isDisconnected) {
    let color = this.placeholderStrokeColor;
    // let color = ELEMENT_VERSION_NODE_STROKE;

    if (category === 'machine') {
      color = this.settings.darkMode
        ? ELEMENT_VERSION_CATEGORY_MACHINE_LIGHT
        : ELEMENT_VERSION_CATEGORY_MACHINE;
    }
    if (category === 'process') {
      color = ELEMENT_VERSION_CATEGORY_PROCESS;
      color = this.settings.darkMode
        ? ELEMENT_VERSION_CATEGORY_PROCESS_LIGHT
        : ELEMENT_VERSION_CATEGORY_PROCESS;
    }
    if (category === 'article-of-manufacture') {
      color = this.settings.darkMode
        ? ELEMENT_VERSION_CATEGORY_ARTICLE_LIGHT
        : ELEMENT_VERSION_CATEGORY_ARTICLE;
    }
    if (category === 'composition') {
      color = this.settings.darkMode
        ? ELEMENT_VERSION_CATEGORY_COMPOSITION_LIGHT
        : ELEMENT_VERSION_CATEGORY_COMPOSITION;
    }

    if (isDisconnected) {
      color = ELEMENT_NODE_GREY_STROKE;
    }
    if (this.settings.blackAndWhiteMode) {
      color = BLACK_AND_WHITE_STROKE;
    }
    return color;
  }

  get textColor() {
    let color = ELEMENT_NODE_COLOR;

    if (this.args.isDisconnected) {
      color = ELEMENT_NODE_GREY_COLOR;
    }
    if (this.settings.blackAndWhiteMode) {
      color = BLACK_AND_WHITE_COLOR;
    }
    return color;
  }

  get nameColor() {
    let color = this.settings.darkMode ? BODY_COLOR_DARK : BODY_COLOR;

    if (this.settings.blackAndWhiteMode) {
      color = BLACK_AND_WHITE_COLOR;
    }
    return color;
  }

  get categoryColor() {
    let color = this.settings.darkMode
      ? BODY_COLOR_LIGHT_DARK
      : BODY_COLOR_LIGHT;

    if (this.args.isSelected) {
      color = 'rgba(0,0,0,0.4)';
    }

    if (this.settings.blackAndWhiteMode) {
      color = BLACK_AND_WHITE_COLOR;
    }
    return color;
  }

  get headerTextColor() {
    let color = ELEMENT_NODE_HEADER_COLOR;

    if (this.args.isDisconnected) {
      color = ELEMENT_NODE_GREY_STROKE;
    }
    return color;
  }

  setup() {
    const elementNode = new Konva.Group({
      id: this.args.elementId,
      x: this.args.x,
      y: this.args.y,
      name: 'node',
      draggable: this.args.isDraggable ? true : false,
      category: 'system',
      nodeType: 'element',
      transformsEnabled: 'position',
    });

    const centerNode = new Konva.Circle({
      radius: 20,
      fill: 'pink',
      x: 0,
      y: 0,
      visible: false,
    });

    const noveltyHighlightNode = new Konva.Circle({
      radius: 60,
      fill: ELEMENT_NODE_NOVELTY_STROKE,
      visible: this.noveltyNodeVisible,
      // opacity: 0.5,
      listening: false,
      // strokeScaleEnabled: false,
      transformsEnabled: 'position',
      offset: {
        x: (-1 * this.width) / 2,
        y: this.height / 2 - 94,
      },
    });

    const noveltyDotNode = new Konva.Circle({
      radius: 20,
      fill: ELEMENT_NODE_NOVELTY_STROKE,
      visible: this.noveltyNodeVisible,
      // opacity: 0.5,
      listening: false,
      transformsEnabled: 'position',
      offset: {
        x: (-1 * this.width) / 2,
        y: this.height / 2 - 94,
      },
    });

    const elementBackgroundNode = new Konva.Rect({
      height: this.elementBackgroundTransform.height,
      width: this.elementBackgroundTransform.width,
      stroke: this.elementBackgroundStrokeColor,
      fill: this.elementBackgroundFillColor,
      strokeWidth: 1.5,
      // strokeScaleEnabled: false,
      transformsEnabled: 'position',
      visible: true,
      offset: this.elementBackgroundTransform.offset,
    });

    const sourceNode = new Konva.Group({
      name: 'element-source-node',
      x: 0,
      y: 0,
      visible: true,
    });

    const sourceBackgroundNode = new Konva.Circle({
      radius: this.sourceBackgroundNodeTransform.radius,
      x: 0,
      y: 0,
      stroke: this.sourceNodeStrokeColor,
      fill: this.sourceNodeFillColor,
      strokeWidth: 3,
      // strokeScaleEnabled: false,
      transformsEnabled: 'position',
      visible: true,
    });

    const sourceNodeKnownIcon = new Konva.Path({
      data: KNOWN_PATH,
      x: 0,
      y: 0,
      strokeWidth: 3,
      stroke: this.sourceNodeKnownStrokeColor,
      fill: this.sourceNodeKnownFillColor,
      visible: this.elementVersionIsKnown,
      offset: this.sourceNodeKnownTransform.offset
    });

    sourceNode.add(sourceBackgroundNode);

    sourceNode.add(sourceNodeKnownIcon);

    const methodPlaceholderNode = new Konva.Group({
      visible: this.showMethodPlaceholder,
      y: this.methodTransform.y,
      x: this.methodTransform.x,
      listening: true,
      offset: this.methodTransform.offset,
      rotation: this.methodTransform.rotation,
    });

    const methodPlaceholderBackgroundNode = new Konva.Rect({
      width: this.methodTransform.width,
      height: this.methodTransform.height,
      fill: this.placeholderFillColor,
      stroke: this.placeholderStrokeColor,
      hitStrokeWidth: 0,
      strokeWidth: 3,
      transformsEnabled: 'position',
      listening: true,
      dash: this.placeholderDash,
      dashEnabled: true,
    });

    const methodPlaceholderTextNode = new Konva.Text({
      width: this.methodTransform.width,
      height: this.methodTransform.height,
      align: 'center',
      verticalAlign: 'middle',
      text: 'Method',
      fontSize: 24,
      lineHeight: 1.235,
      fontFamily: this.fontFamily,
      fill: this.placeholderTextColor,
      transformsEnabled: 'position',
      listening: false,
    });
    // }).cache();

    methodPlaceholderNode.add(methodPlaceholderBackgroundNode);
    methodPlaceholderNode.add(methodPlaceholderTextNode);

    const methodNode = new Konva.Group({
      visible: this.showMethod,
      y: this.methodTransform.y,
      x: this.methodTransform.x,
      listening: true,
      offset: this.methodTransform.offset,
      rotation: this.methodTransform.rotation,
    });

    const methodBackgroundNode = new Konva.Rect({
      width: this.methodTransform.width,
      height: this.methodTransform.height,
      fill: '#FFF',
      stroke: this.methodStrokeColor,
      hitStrokeWidth: 0,
      strokeWidth: 3,
      transformsEnabled: 'position',
      listening: true,
      dash: this.placeholderDash,
      dashEnabled: this.methodDashEnabled,
    });

    const methodImageNode = new Konva.Image({
      x: 3,
      y: 3,
      width: this.methodTransform.width - 6,
      height: this.methodTransform.height - 6,
      transformsEnabled: 'position',
      listening: false,
      visible: !this._reachedScaleThreshold,
    });

    methodNode.add(methodBackgroundNode);
    methodNode.add(methodImageNode);

    const drawingPlaceholderNode = new Konva.Group({
      visible: this.showDrawingPlaceholder,
      y: this.drawingTransform.y,
      x: this.drawingTransform.x,
      listening: true,
      rotation: this.drawingTransform.rotation,
      offset: this.drawingTransform.offset,
    });

    const drawingPlaceholderBackgroundNode = new Konva.Rect({
      width: this.drawingTransform.width,
      height: this.drawingTransform.height,
      fill: this.placeholderFillColor,
      stroke: this.placeholderStrokeColor,
      hitStrokeWidth: 0,
      strokeWidth: 3,
      transformsEnabled: 'position',
      listening: true,
      dash: this.placeholderDash,
      dashEnabled: true,
    });

    const drawingPlaceholderTextNode = new Konva.Text({
      width: this.drawingTransform.width,
      height: this.drawingTransform.height,
      align: 'center',
      verticalAlign: 'middle',
      text: 'Drawing',
      fontSize: 24,
      lineHeight: 1.235,
      fontFamily: this.fontFamily,
      fill: this.placeholderTextColor,
      transformsEnabled: 'position',
      listening: false,
    });
    // }).cache();

    drawingPlaceholderNode.add(drawingPlaceholderBackgroundNode);
    drawingPlaceholderNode.add(drawingPlaceholderTextNode);

    const drawingNode = new Konva.Group({
      visible: this.showDrawing,
      y: this.drawingTransform.y,
      x: this.drawingTransform.x,
      listening: true,
      rotation: this.drawingTransform.rotation,
      offset: this.drawingTransform.offset,
    });

    const drawingStrokeNode = new Konva.Rect({
      width: this.drawingTransform.width,
      height: this.drawingTransform.height,
      stroke: this.drawingStrokeColor,
      hitStrokeWidth: 0,
      strokeWidth: 3,
      transformsEnabled: 'position',
      listening: false,
      visible: true,
    });

    const drawingBackgroundNode = new Konva.Rect({
      width: this.drawingTransform.width,
      height: this.drawingTransform.height,
      hitStrokeWidth: 0,
      fill: '#FFFFFF',
      transformsEnabled: 'position',
      listening: true,
      visible: true,
    });

    const drawingImageNode = new Konva.Image({
      width: this.drawingTransform.width - 20,
      height: this.drawingTransform.height - 20,
      transformsEnabled: 'position',
      listening: false,
      visible: !this._reachedScaleThreshold,
      offset: {
        x: -10,
        y: -10,
      },
    });

    drawingNode.add(drawingBackgroundNode);
    drawingNode.add(drawingImageNode);
    drawingNode.add(drawingStrokeNode);

    const categoryNode = new Konva.Text({
      verticalAlign: 'bottom',
      text: this.categoryName,
      // fontSize: 29,
      fontSize: 24,
      lineHeight: 1.1,
      fontFamily: this.fontFamily,
      fontStyle: '500',
      fill: this.categoryColor,
      transformsEnabled: 'position',
      listening: false,
      ellipsis: true,
      offset: this.categoryTransform.offset,
    });

    const nameNode = new Konva.Text({
      width: this.nameTransform.width,
      height: this.nameTransform.height,
      // verticalAlign: 'bottom',
      text: this.name,
      // fontSize: 29,
      fontSize: 24,
      lineHeight: 1.3,
      fontFamily: this.fontFamily,
      fontStyle: '500',
      fill: this.nameColor,
      transformsEnabled: 'position',
      listening: false,
      ellipsis: true,
      offset: this.nameTransform.offset,
    });

    const elementVersionDividerNode = new Konva.Line({
      points: this.jaggedLinePoints,
      stroke: this.placeholderStrokeColor,
      strokeWidth: 3,
      y: this.dividerTransform.y,
      offset: this.dividerTransform.offset,
    });

    const elementVersionNode = new Konva.Group({
      name: 'element-version-node',
      x: this.elementVersionTransform.x,
      nodeType: 'element-version',
      transformsEnabled: 'position',
      visible: this.showElementVersion ? true : false,
      offset: this.elementVersionTransform.offset,
    });

    elementVersionNode.add(methodNode);
    elementVersionNode.add(methodPlaceholderNode);
    elementVersionNode.add(drawingNode);
    elementVersionNode.add(drawingPlaceholderNode);

    const elementVersionPlaceholderNode = new Konva.Group({
      visible: this.showElementVersionPlaceholder,
      x: this.elementVersionTransform.x,
      y: this.elementVersionTransform.y,
      transformsEnabled: 'position',
    });

    const elementVersionPlaceholderBackgroundNode = new Konva.Rect({
      width: this.elementVersionTransform.width,
      height: this.elementVersionTransform.height,
      fill: this.placeholderFillColor,
      stroke: this.placeholderStrokeColor,
      hitStrokeWidth: 0,
      strokeWidth: 3,
      transformsEnabled: 'position',
      listening: true,
      dash: this.placeholderDash,
      dashEnabled: true,
      offset: {
        x: this.elementVersionTransform.width / 2,
        y: this.elementVersionTransform.height / 2,
      },
    });

    const elementVersionPlaceholderTextNode = new Konva.Text({
      width: this.elementVersionTransform.width,
      height: this.elementVersionTransform.height,
      align: 'center',
      verticalAlign: 'middle',
      text: 'Preferred Solution',
      fontSize: 24,
      lineHeight: 1.235,
      fontFamily: this.fontFamily,
      fill: this.placeholderTextColor,
      transformsEnabled: 'position',
      listening: false,
      offset: {
        x: this.elementVersionTransform.width / 2,
        y: this.elementVersionTransform.height / 2,
      },
    });
    // }).cache();

    elementVersionPlaceholderNode.add(elementVersionPlaceholderBackgroundNode);
    elementVersionPlaceholderNode.add(elementVersionPlaceholderTextNode);

    const outcomePlaceholderNode = new Konva.Group({
      visible: this.showOutcomePlaceholder,
      x: this.outcomePlaceholderTransform.x,
      y: this.outcomePlaceholderTransform.y,
      offset: this.outcomePlaceholderTransform.offset,
      transformsEnabled: 'position',
    });

    const outcomePlaceholderBackgroundNode = new Konva.Rect({
      width: this.outcomePlaceholderTransform.width,
      height: this.outcomePlaceholderTransform.height,
      fill: this.placeholderFillColor,
      stroke: this.placeholderStrokeColor,
      hitStrokeWidth: 0,
      strokeWidth: 3,
      transformsEnabled: 'position',
      listening: true,
      dash: this.placeholderDash,
      dashEnabled: true,
    });

    const outcomePlaceholderTextNode = new Konva.Text({
      width: this.outcomePlaceholderTransform.width,
      height: this.outcomePlaceholderTransform.height,
      align: 'center',
      verticalAlign: 'middle',
      text: 'Success Event',
      fontSize: 24,
      lineHeight: 1.235,
      fontFamily: this.fontFamily,
      fill: this.placeholderTextColor,
      transformsEnabled: 'position',
      listening: false,
    });
    // }).cache();

    outcomePlaceholderNode.add(outcomePlaceholderBackgroundNode);
    outcomePlaceholderNode.add(outcomePlaceholderTextNode);

    const outcomeNode = new Konva.Group({
      visible: this.showOutcome,
      y: this.outcomeTransform.y,
      x: this.outcomeTransform.x,
      transformsEnabled: 'position',
      offset: this.outcomeTransform.offset,
    });

    const outcomeHeaderTextNode = new Konva.Text({
      width: this.outcomeTransform.width,
      text: this.methodNodeOrdinal || '',
      fontSize: 22,
      lineHeight: 1.235,
      fontFamily: this.fontFamily,
      fill: this.outcomeHeaderFill,
      transformsEnabled: 'position',
      listening: false,
      align: 'right',
      x: 0,
      y: -28,
      visible: this.methodNodeOrdinal ? true : false,
    });

    const outcomeTextNode = new Konva.Text({
      width: this.outcomeTransform.width - 32,
      height: 200,
      ellipsis: true,
      text: textValue(this.outcome || ''),
      fontSize: 26,
      lineHeight: 1.235,
      fontFamily: this.fontFamily,
      fill: this.textColor,
      transformsEnabled: 'position',
      listening: false,
      visible: this._reachedScaleThreshold ? false : true,
      x: 22,
      y: 17,
    }).cache();

    const outcomeBackgroundNodeHeight = this.outcomeBackgroundNodeHeight(
      outcomeTextNode.textArr.length
    );

    elementVersionNode.y(
      outcomeBackgroundNodeHeight + this.elementVersionTransform.padding
    );

    const outcomeBackgroundNode = new Konva.Rect({
      width: this.outcomeTransform.width,
      height: outcomeBackgroundNodeHeight,
      fill: this.outcomeFillColor,
      stroke: this.outcomeStrokeColor,
      hitStrokeWidth: 0,
      strokeWidth: 3,
      // strokeScaleEnabled: false,
      transformsEnabled: 'position',
      listening: true,
      dash: [15, 10],
      dashEnabled: this.outcomeDashEnabled,
    });

    const outcomeArrowNode = new Konva.Group({
      transformsEnabled: 'position',
      listening: false,
      y: outcomeBackgroundNodeHeight / 2,
      x: this.outcomeTransform.width,
      visible: this.showArrow ? true : false,
      // visible: false,
    });

    const outcomeArrowArrowNode = new Konva.Arrow({
      points: [0, 0, 40, 0],
      stroke: this.outcomeArrowFill,
      hitStrokeWidth: 0,
      strokeWidth: 7,
      listening: false,
      x: 7,
    });

    outcomeArrowNode.add(outcomeArrowArrowNode);

    outcomeNode.add(outcomeBackgroundNode);
    outcomeNode.add(outcomeHeaderTextNode);
    outcomeNode.add(outcomeTextNode);
    outcomeNode.add(outcomeArrowNode);

    const collapsedNode = new Konva.Rect({
      width: this.outcomeTransform.width,
      height: 100,
      visible: this.args.isCollapsed,
      fill: this.fillColorCollapsed,
      stroke: this.strokeColorCollapsed,
      hitStrokeWidth: 0,
      strokeWidth: 3,
      x: this.outcomeTransform.x + 10,
      y: this.outcomeTransform.y + 7.5,
      transformsEnabled: 'position',
      offset: this.outcomeTransform.offset,
    });

    const collapsedTwoNode = new Konva.Rect({
      width: this.outcomeTransform.width,
      height: 100,
      visible: this.args.isCollapsed,
      fill: this.fillColorCollapsed,
      stroke: this.strokeColorCollapsed,
      hitStrokeWidth: 0,
      strokeWidth: 3,
      x: this.outcomeTransform.x + 20,
      y: this.outcomeTransform.y + 15,
      transformsEnabled: 'position',
      offset: this.outcomeTransform.offset,
    });

    const collapsedThreeNode = new Konva.Rect({
      width: this.outcomeTransform.width,
      height: 100,
      visible: this.args.isCollapsed,
      fill: this.fillColorCollapsed,
      stroke: this.strokeColorCollapsed,
      hitStrokeWidth: 0,
      strokeWidth: 3,
      x: this.outcomeTransform.x + 30,
      y: this.outcomeTransform.y + 22.5,
      transformsEnabled: 'position',
      offset: this.outcomeTransform.offset,
    });

    collapsedNode.height(outcomeBackgroundNodeHeight);
    collapsedTwoNode.height(outcomeBackgroundNodeHeight);
    collapsedThreeNode.height(outcomeBackgroundNodeHeight);

    const componentNode = new Konva.Group({
      x: 0,
      y: 0,
      transformsEnabled: 'position',
      visible: this.isInstance ? true : false,
      offset: {
        x: this.width / 2,
        y: this.outcomeTransform.offset.y,
      },
    });

    const componentNodeBackgroundNode = new Konva.Rect({
      width: 40,
      height: 30,
      fill: this.outcomeFillColor,
      hitStrokeWidth: 0,
      transformsEnabled: 'position',
      cornerRadius: 10,
      offset: {
        x: 20,
        y: 15,
      },
    });

    // const componentNodeBackgroundNode = new Konva.Circle({
    //   radius: 20,
    //   // fill: 'transparent',
    //   // stroke: 'transparent',
    //   fill: this.outcomeFillColor,
    //   // stroke: this.outcomeStrokeColor,
    //   hitStrokeWidth: 0,
    //   strokeWidth: 3,
    //   transformsEnabled: 'position',
    // });

    const componentPrimaryNode = new Konva.Path({
      data: COMPONENT_PATH,
      fill: this.outcomeStrokeColor,
      visible: this.args.isPrimaryInstance ? true : false,
      scale: {
        x: 3.5,
        y: 3.5,
      },
      offset: {
        x: 9,
        y: 7.5,
      },
    }).cache();

    const componentInstanceNode = new Konva.Path({
      data: INSTANCE_PATH,
      fill: this.outcomeStrokeColor,
      visible: this.isInstance ? true : false,
      scale: {
        x: 3.5,
        y: 3.5,
      },
      offset: {
        x: 9,
        y: 7.5,
      },
    }).cache();

    componentNode.add(componentNodeBackgroundNode);
    componentNode.add(componentPrimaryNode);
    componentNode.add(componentInstanceNode);

    // TODO opacity kills end-to-end tests
    if (ENV.environment !== 'test') {
      noveltyHighlightNode.opacity(0.5);
    }

    elementNode.add(collapsedThreeNode);
    elementNode.add(collapsedTwoNode);
    elementNode.add(collapsedNode);
    elementNode.add(elementBackgroundNode);
    elementNode.add(sourceNode);
    elementNode.add(categoryNode);
    elementNode.add(nameNode);
    elementNode.add(outcomePlaceholderNode);
    elementNode.add(outcomeNode);
    elementNode.add(elementVersionPlaceholderNode);
    elementNode.add(elementVersionDividerNode);
    elementNode.add(elementVersionNode);
    elementNode.add(noveltyHighlightNode);
    elementNode.add(noveltyDotNode);
    elementNode.add(componentNode);
    elementNode.add(centerNode);

    // add events
    if (this.args.onClick) {
      elementNode.on('click', (e) => {
        const isRightClick = e.evt ? e.evt.altKey || e.evt.button === 2 : false;
        this.args.onClick(
          this.args.elementId,
          this.args.isSelected,
          isRightClick
        );
      });
    }

    if (this.args.onContextClick) {
      elementNode.on('contextmenu', (event) => {
        this.args.onContextClick(this.args.elementId, event);
      });
    }

    if (this.args.onDragStart) {
      elementNode.on('dragstart', (event) => {
        if (this.args.actionMode) {
          event.target.stopDrag();
        }
        this.args.onDragStart(event, this.args.elementId);
      });
    }

    if (this.args.onDragMove) {
      elementNode.on('dragmove', (event) => {
        if (this.args.actionMode) {
          return event.target.stopDrag();
        }
        this.args.onDragMove(event, this.args.elementId);
      });
    }

    if (this.args.onDragMove) {
      elementNode.on('dragend', (event) => {
        if (this.args.actionMode) {
          return;
        }
        this.args.onDragEnd(event, this.args.elementId);
      });
    }

    if (this.args.onMouseenter) {
      elementNode.on('mouseenter', (event) =>
        this.args.onMouseenter(event, this.args.elementId)
      );
    }

    if (this.args.onMouseleave) {
      elementNode.on('mouseleave', (event) =>
        this.args.onMouseleave(event, this.args.elementId)
      );
    }

    this.elementsLayer.add(elementNode);

    elementNode.visible(elementNode.isClientRectOnScreen());

    this.methodPlaceholderNode = methodPlaceholderNode;
    this.methodPlaceholderTextNode = methodPlaceholderTextNode;
    this.methodPlaceholderBackgroundNode = methodPlaceholderBackgroundNode;
    this.methodNode = methodNode;
    this.methodBackgroundNode = methodBackgroundNode;
    this.methodImageNode = methodImageNode;
    this.drawingPlaceholderNode = drawingPlaceholderNode;
    this.drawingPlaceholderTextNode = drawingPlaceholderTextNode;
    this.drawingPlaceholderBackgroundNode = drawingPlaceholderBackgroundNode;
    this.drawingNode = drawingNode;
    this.drawingBackgroundNode = drawingBackgroundNode;
    this.drawingStrokeNode = drawingStrokeNode;
    this.drawingImageNode = drawingImageNode;
    this.categoryNode = categoryNode;
    this.nameNode = nameNode;
    this.collapsedNode = collapsedNode;
    this.collapsedTwoNode = collapsedTwoNode;
    this.collapsedThreeNode = collapsedThreeNode;
    this.outcomePlaceholderNode = outcomePlaceholderNode;
    this.outcomePlaceholderTextNode = outcomePlaceholderTextNode;
    this.outcomePlaceholderBackgroundNode = outcomePlaceholderBackgroundNode;
    this.outcomeNode = outcomeNode;
    this.outcomeBackgroundNode = outcomeBackgroundNode;
    this.outcomeHeaderTextNode = outcomeHeaderTextNode;
    this.outcomeTextNode = outcomeTextNode;
    this.outcomeArrowNode = outcomeArrowNode;
    this.outcomeArrowArrowNode = outcomeArrowArrowNode;
    this.elementBackgroundNode = elementBackgroundNode;
    this.sourceNode = sourceNode;
    this.sourceBackgroundNode = sourceBackgroundNode;
    this.sourceNodeKnownIcon = sourceNodeKnownIcon;
    this.noveltyHighlightNode = noveltyHighlightNode;
    this.noveltyDotNode = noveltyDotNode;
    this.elementNode = elementNode;
    this.elementVersionPlaceholderNode = elementVersionPlaceholderNode;
    this.elementVersionPlaceholderBackgroundNode =
      elementVersionPlaceholderBackgroundNode;
    this.elementVersionPlaceholderTextNode = elementVersionPlaceholderTextNode;
    this.elementVersionNode = elementVersionNode;
    this.elementVersionDividerNode = elementVersionDividerNode;
    this.componentNode = componentNode;
    this.componentNodeBackgroundNode = componentNodeBackgroundNode;
    this.componentPrimaryNode = componentPrimaryNode;
    this.componentInstanceNode = componentInstanceNode;
    // this.categoryMachineNode = categoryMachineNode;
    // this.categoryArticleNode = categoryArticleNode;
    // this.categoryProcessNode = categoryProcessNode;

    if (this.referenceId) {
      this.onUpdateReferenceImage.perform(this.referenceId);
    } else {
      this.onUpdateReferenceImage.perform(null);
    }

    if (this.methodId) {
      this.onUpdateMethodImage.perform(this.methodId);
    }

    // this.onUpdatePlaceholderImages.perform();

    this.updatePosition();
    this.onScheduleRender();
  }

  updatePosition() {
    const categoryNodeHeight = this.categoryFontSize * this.categoryLineHeight;
    const nameNodeHeight =
      this.nameNode.textArr.length * (this.nameFontSize * this.nameLineHeight);
    const nameNodeY = -1 * (nameNodeHeight + this.spacer / 2);
    const categoryNodeY = nameNodeY - categoryNodeHeight;

    const headerHeight = categoryNodeHeight + nameNodeHeight + this.spacer / 2;

    // const outcomeNodeY = nameNodeY + nameNodeHeight + this.spacer / 2;
    const outcomeNodeY = 0;
    const outcomeBackgroundNodeHeight = this.outcomeBackgroundNodeHeight(
      this.outcomeTextNode.textArr.length
    );
    const elementVersionDividerNodeY =
      outcomeNodeY + outcomeBackgroundNodeHeight + this.spacer;
    const elementVersionDividerNodeHeight = this.spacer / 2;
    const elementVersionNodeY =
      elementVersionDividerNodeY +
      elementVersionDividerNodeHeight +
      this.spacer;
    const elementVersionNodeHeight = this.showMethodPlaceholder || this.showMethod
      ? this.methodTransform.height
      : this.drawingTransform.height;
    const backgroundNodeY = categoryNodeY - this.spacer;
    const backgroundNodeHeight =
      headerHeight +
      elementVersionNodeY +
      elementVersionNodeHeight +
      this.spacer * 2.5;
    const sourceNodeY = backgroundNodeY + backgroundNodeHeight;

    this.nameNode.y(nameNodeY);
    this.categoryNode.y(categoryNodeY);
    this.outcomeNode.y(outcomeNodeY);
    this.elementVersionDividerNode.y(elementVersionDividerNodeY);
    this.elementVersionNode.y(elementVersionNodeY);
    this.elementBackgroundNode.y(backgroundNodeY);
    this.elementBackgroundNode.height(backgroundNodeHeight);
    this.sourceNode.y(sourceNodeY);
  }

  handleSelected() {
    this.updateColors();
    this.onScheduleRender();
  }

  handleDeselected() {
    this.updateColors();
    this.onScheduleRender();
  }

  updateColors() {
    // this.categoryMachineNode.fill(this.elementVersionStrokeColor);
    // this.categoryArticleNode.fill(this.elementVersionStrokeColor);
    // this.categoryProcessNode.fill(this.elementVersionStrokeColor);
    this.sourceBackgroundNode.stroke(this.sourceNodeStrokeColor);
    this.sourceBackgroundNode.fill(this.sourceNodeFillColor);

    this.nameNode.fill(this.nameColor);
    this.elementBackgroundNode.stroke(this.elementBackgroundStrokeColor);
    this.elementBackgroundNode.fill(this.elementBackgroundFillColor);
    this.outcomeBackgroundNode.stroke(this.outcomeStrokeColor);
    this.outcomeBackgroundNode.fill(this.outcomeFillColor);

    this.categoryNode.fill(this.categoryColor);

    this.outcomeTextNode.cache();
    this.outcomeTextNode.fill(this.textColor);
    this.outcomeTextNode.clearCache();

    // this.outcomeBackgroundNode.fill(this.fillColorLight);
    // this.outcomeBackgroundNode.stroke(this.textColor);
    this.collapsedNode.fill(this.fillColorCollapsed);
    this.collapsedNode.stroke(this.strokeColorCollapsed);
    this.collapsedTwoNode.fill(this.fillColorCollapsed);
    this.collapsedTwoNode.stroke(this.strokeColorCollapsed);
    this.collapsedThreeNode.fill(this.fillColorCollapsed);
    this.collapsedThreeNode.stroke(this.strokeColorCollapsed);

    this.componentNodeBackgroundNode.fill(this.outcomeFillColor);
    this.componentNodeBackgroundNode.stroke(this.outcomeStrokeColor);

    this.componentPrimaryNode.clearCache();
    this.componentPrimaryNode.fill(this.outcomeStrokeColor);
    this.componentPrimaryNode.cache();

    this.componentInstanceNode.clearCache();
    this.componentInstanceNode.fill(this.outcomeStrokeColor);
    this.componentInstanceNode.cache();

    this.methodBackgroundNode.stroke(this.methodStrokeColor);
    this.methodPlaceholderBackgroundNode.fill(this.placeholderFillColor);
    this.methodPlaceholderBackgroundNode.stroke(this.placeholderStrokeColor);
    this.methodPlaceholderTextNode.fill(this.placeholderTextColor);

    this.drawingStrokeNode.stroke(this.drawingStrokeColor);
    this.drawingPlaceholderBackgroundNode.fill(this.placeholderFillColor);
    this.drawingPlaceholderBackgroundNode.stroke(this.placeholderStrokeColor);
    this.drawingPlaceholderTextNode.fill(this.placeholderTextColor);

    this.elementVersionPlaceholderTextNode.fill(this.placeholderTextColor);
    this.elementVersionPlaceholderBackgroundNode.fill(
      this.placeholderFillColor
    );
    this.elementVersionPlaceholderBackgroundNode.stroke(
      this.placeholderStrokeColor
    );

    this.outcomePlaceholderTextNode.fill(this.placeholderTextColor);
    this.outcomePlaceholderBackgroundNode.fill(this.placeholderFillColor);
    this.outcomePlaceholderBackgroundNode.stroke(this.placeholderStrokeColor);
    this.outcomeHeaderTextNode.fill(this.outcomeHeaderFill);
    this.outcomeArrowArrowNode.stroke(this.outcomeArrowFill);
  }

  @task
  *onUpdateReferenceImage(referenceId) {
    let blobUrl;

    const state = this.redux.getState();

    if (getMarker(state, referenceId)) {
      blobUrl = yield this.referencesCache.getBlobUrl.perform(referenceId);
    }

    if (!blobUrl) {
      return;
    }

    const imageFile = new Image();

    imageFile.onload = () => {
      this.drawingImageNode.image(imageFile);
      this.drawingImageNode.cache();
      if (this.args.isDisconnected || this.settings.blackAndWhiteMode) {
        const filters = [Konva.Filters.Grayscale];
        this.drawingImageNode.filters(filters);
      }

      this.onScheduleRender();
    };

    imageFile.src = blobUrl;
  }

  @task
  *onUpdateMethodImage(methodId) {
    let blobUrl;

    const state = this.redux.getState();

    if (methodId && getMethod(state, methodId)) {
      blobUrl = yield this.methodsCacheKonva.getBlobUrl.perform(methodId);
    }

    if (!blobUrl) {
      return;
    }

    const imageFile = new Image();

    imageFile.onload = () => {
      this.methodImageNode.image(imageFile);
      this.methodImageNode.cache();

      this.onScheduleRender();
    };

    imageFile.src = blobUrl;
  }

  // @task
  // // eslint-disable-next-line require-yield
  // *onUpdatePlaceholderImages() {
  //   let blobUrl = `./assets/img/placeholder-light.jpg`;

  //   const imageFile = new Image();

  //   imageFile.onload = () => {
  //     this.outcomePlaceholderBackgroundNode.fillPriority('pattern');
  //     this.outcomePlaceholderBackgroundNode.fillPatternImage(imageFile);

  //     this.onScheduleRender();
  //   };

  //   imageFile.src = blobUrl;
  // }

  @action
  updateVisibility() {
    this.elementNode.visible(this.elementNode.isClientRectOnScreen());
  }

  @action
  onUpdate(
    elem,
    [
      isNovel,
      isNovelAncestor,
      isDisconnected,
      isCollapsed,
      visibleAreaIndex,
      elementIsSelected,
      x,
      y,
      reachedScaleThreshold,
      solutionsString,
      hasReference,
      referenceUpdatedAt,
      name,
      showElementVersionPlaceholder,
      showElementVersion,
      outcome,
      outcomeIsTraversed,
      methodNodeOrdinal,
      showOutcome,
      showDrawing,
      showDrawingPlaceholder,
      showMethod,
      showMethodPlaceholder,
      methodUpdatedAt,
      known,
      elementVersionCategory,
      elementVersionIsSelected,
      isSelectedChild,
      isCreatingFrom,
      isCreatingTo,
      showArrow,
      isPrimaryInstance,
      isInstance,
      darkMode,
      blackAndWhiteMode,
    ]
  ) {
    let detailsChanged = false;
    let visibleAreaChanged = false;

    if (this._showElementVersionPlaceholder !== showElementVersionPlaceholder) {
      this._showElementVersionPlaceholder = showElementVersionPlaceholder;
      if (showElementVersionPlaceholder) {
        this.elementVersionPlaceholderNode.visible(true);
      } else {
        this.elementVersionPlaceholderNode.visible(false);
      }
      this.onScheduleRender();
    }

    if (this._isDisconnected !== isDisconnected) {
      this._isDisconnected = isDisconnected;
      this.updateColors();
      const filters = isDisconnected ? [Konva.Filters.Grayscale] : [];
      this.drawingImageNode.filters(filters);
      detailsChanged = true;
    }

    if (this._isNovel !== isNovel) {
      this._isNovel = isNovel;
      this.noveltyHighlightNode.visible(this.noveltyNodeVisible);
      this.noveltyDotNode.visible(this.noveltyNodeVisible);
      this.updateColors();
      detailsChanged = true;
    }

    if (this._isNovelAncestor !== isNovelAncestor) {
      this._isNovelAncestor = isNovelAncestor;
      this.noveltyHighlightNode.visible(this.noveltyNodeVisible);
      this.noveltyDotNode.visible(this.noveltyNodeVisible);
      this.updateColors();
      detailsChanged = true;
    }

    if (this._isCollapsed !== isCollapsed) {
      this._isCollapsed = isCollapsed;
      this.collapsedNode.visible(isCollapsed);
      this.collapsedTwoNode.visible(isCollapsed);
      this.collapsedThreeNode.visible(isCollapsed);

      this.noveltyHighlightNode.visible(this.noveltyNodeVisible);
      this.noveltyDotNode.visible(this.noveltyNodeVisible);
      this.updateColors();
    }

    if (this._visibleAreaIndex !== visibleAreaIndex) {
      this._visibleAreaIndex = visibleAreaIndex;
      visibleAreaChanged = true;
    }

    if (visibleAreaChanged) {
      this.updateVisibility();
    }

    if (this._elementIsSelected !== elementIsSelected) {
      this._elementIsSelected = elementIsSelected;
      if (elementIsSelected) {
        this.handleSelected();
      } else {
        this.handleDeselected();
      }
    }

    if (this._solutionsString !== solutionsString) {
      this._solutionsString = solutionsString;
      this.onScheduleRender();
    }

    if (this._hasReference !== hasReference) {
      this._hasReference = hasReference;
      if (this.referenceId) {
        this.onUpdateReferenceImage.perform(this.referenceId);
      } else {
        this.onUpdateReferenceImage.perform(null);
      }
    }

    if (this._referenceUpdatedAt !== referenceUpdatedAt) {
      this._referenceUpdatedAt = referenceUpdatedAt;
      if (this.referenceId) {
        this.onUpdateReferenceImage.perform(this.referenceId);
      } else {
        this.onUpdateReferenceImage.perform(null);
      }
    }

    if (this._name !== name) {
      detailsChanged = true;
      this._name = name;
      // this.nameNode.clearCache();
      this.nameNode.text(name);
      // const nameNodeLines = this.nameNode.textArr.length;
      // const nameNodeY = this.getNameNodeY(nameNodeLines);
      // this.nameNode.y(nameNodeY);
      // this.nameNode.cache();
    }

    if (this._showElementVersion !== showElementVersion) {
      detailsChanged = true;
      this._showElementVersion = showElementVersion;
      this.elementVersionNode.visible(this.showElementVersion);
    }

    const textOutcome = textValue(outcome || '');
    if (this._outcome !== textOutcome) {
      detailsChanged = true;
      this._outcome = textOutcome;
      this.outcomeTextNode.clearCache();
      this.outcomeTextNode.text(textOutcome);
      this.outcomeTextNode.cache();
      const outcomeHeight = this.outcomeBackgroundNodeHeight(
        this.outcomeTextNode.textArr.length
      );
      this.outcomeBackgroundNode.height(outcomeHeight);
      this.collapsedNode.height(outcomeHeight);
      this.collapsedTwoNode.height(outcomeHeight);
      this.collapsedThreeNode.height(outcomeHeight);
    }

    if (this._outcomeIsTraversed !== outcomeIsTraversed) {
      detailsChanged = true;
      this._outcomeIsTraversed = outcomeIsTraversed;
      this.outcomeBackgroundNode.fill(this.outcomeFillColor);
      this.outcomeBackgroundNode.stroke(this.outcomeStrokeColor);
      this.outcomeBackgroundNode.dashEnabled(this.outcomeDashEnabled);
    }

    if (this._methodNodeOrdinal !== methodNodeOrdinal) {
      detailsChanged = true;
      this._methodNodeOrdinal = methodNodeOrdinal;
      this.outcomeHeaderTextNode.visible(this.methodNodeOrdinal ? true : false);
      this.outcomeHeaderTextNode.text(this.methodNodeOrdinal);
    }

    if (this._showOutcome !== showOutcome) {
      this._showOutcome = showOutcome;
      this.outcomeNode.visible(this.showOutcome);
      this.outcomePlaceholderNode.visible(this.showOutcomePlaceholder);
      detailsChanged = true;
    }

    if (this._showDrawing !== showDrawing) {
      this._showDrawing = showDrawing;
      this.drawingNode.visible(this.showDrawing);
      this.onScheduleRender();
    }

    if (this._showDrawingPlaceholder !== showDrawingPlaceholder) {
      this._showDrawingPlaceholder = showDrawingPlaceholder;
      this.drawingPlaceholderNode.visible(this.showDrawingPlaceholder);
      this.onScheduleRender();
    }

    if (this._showMethod !== showMethod) {
      this._showMethod = showMethod;
      this.methodNode.visible(this.showMethod);
      this.onScheduleRender();
    }

    if (this._showMethodPlaceholder !== showMethodPlaceholder) {
      this._showMethodPlaceholder = showMethodPlaceholder;
      this.methodPlaceholderNode.visible(this.showMethodPlaceholder);
      this.onScheduleRender();
    }

    if (this._methodUpdatedAt !== methodUpdatedAt) {
      this._methodUpdatedAt = methodUpdatedAt;
      this.methodBackgroundNode.dashEnabled(this.methodDashEnabled);
      this.drawingNode.offset(this.drawingTransform.offset);
      this.drawingPlaceholderNode.offset(this.drawingTransform.offset);

      if (this.methodId) {
        this.onUpdateMethodImage.perform(this.methodId);
      }
      this.onScheduleRender();
    }

    if (this._known !== known) {
      this._known = known;
      detailsChanged = true;
      this.updateColors();
    }

    if (this._elementVersionCategory !== elementVersionCategory) {
      this._elementVersionCategory = elementVersionCategory;
      if (this.referenceId) {
        this.onUpdateReferenceImage.perform(this.referenceId);
      }
      this.updateColors();
    }

    if (this._elementVersionIsSelected !== elementVersionIsSelected) {
      this._elementVersionIsSelected = elementVersionIsSelected;
      //
    }

    if (this._isSelectedChild !== isSelectedChild) {
      this._isSelectedChild = isSelectedChild;
      detailsChanged = true;
      if (isSelectedChild) {
        //
      } else {
        //
      }
      this.updateColors();
    }

    if (this._isCreatingFrom !== isCreatingFrom) {
      this._isCreatingFrom = isCreatingFrom;
      if (isCreatingFrom) {
        if (this.showElementVersion) {
          //
        }
        this.onScheduleRender();
      } else {
        if (!this._isCreatingFrom && !this.elementVersionIsSelected) {
          //
        }
        this.onScheduleRender();
      }
    }

    if (this._isCreatingTo !== isCreatingTo) {
      this._isCreatingTo = isCreatingTo;
      if (isCreatingTo) {
        //
        this.onScheduleRender();
      } else {
        if (!this._isCreatingTo && !this._elementIsSelected) {
          //
          this.onScheduleRender();
        }
      }
    }

    if (this._x !== x || this._y !== y) {
      this._x = x;
      this._y = y;
      this.elementNode.x(x);
      this.elementNode.y(y);
      this.onScheduleRender();
    }

    if (this._reachedScaleThreshold !== reachedScaleThreshold) {
      this._reachedScaleThreshold = reachedScaleThreshold;
      detailsChanged = true;
      if (reachedScaleThreshold) {
        // this.outcomeHeaderTextNode.visible(false);
        this.outcomeTextNode.visible(false);
        this.drawingImageNode.visible(false);
        this.methodImageNode.visible(false);
        this.outcomePlaceholderTextNode.visible(false);
        this.drawingPlaceholderTextNode.visible(false);
        // this.categoryMachineNode.visible(false);
        // this.categoryArticleNode.visible(false);
        // this.categoryProcessNode.visible(false);
      } else {
        // this.outcomeHeaderTextNode.visible(true);
        this.outcomeTextNode.visible(true);
        this.drawingImageNode.visible(true);
        this.methodImageNode.visible(true);
        this.outcomePlaceholderTextNode.visible(true);
        this.drawingPlaceholderTextNode.visible(true);
        // this.categoryMachineNode.visible(
        //   this.elementVersionCategory === 'machine'
        // );
        // this.categoryArticleNode.visible(
        //   this.elementVersionCategory === 'article-of-manufacture'
        // );
        // this.categoryProcessNode.visible(
        //   this.elementVersionCategory === 'process'
        // );
      }
    }

    if (this._showArrow !== showArrow) {
      this._showArrow = showArrow;
      detailsChanged = true;
      this.outcomeArrowNode.visible(showArrow ? true : false);
    }

    if (this._isPrimaryInstance !== isPrimaryInstance) {
      this._isPrimaryInstance = isPrimaryInstance;
      this.componentPrimaryNode.visible(isPrimaryInstance);
      detailsChanged = true;
    }

    if (this._isInstance !== isInstance) {
      this._isInstance = isInstance;
      this.componentInstanceNode.visible(isInstance);
      detailsChanged = true;
    }

    if (this._darkMode !== darkMode) {
      this._darkMode = darkMode;
      this.updateColors();
      detailsChanged = true;
    }

    if (this._blackAndWhiteMode !== blackAndWhiteMode) {
      this._blackAndWhiteMode = blackAndWhiteMode;
      this.updateColors();

      if (this.reference) {
        this.onUpdateReferenceImage.perform(
          this.reference.id,
          this.reference.type
        );
      } else {
        this.onUpdateReferenceImage.perform(null);
      }
      detailsChanged = true;
    }

    if (detailsChanged) {
      this.onScheduleRender(this.activeLayer);
    }
  }
}

export default connect(
  stateToComputed,
  dispatchToActions
)(InventionGraphSystem);
