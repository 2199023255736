import { action, computed } from '@ember/object';
import {
  getSelectedImages,
  getSelectedMarkers,
} from '../../../selectors/invention-ui';

import Component from '@glimmer/component';
import { connect } from 'ember-redux';
import { getDrawing } from '../../../selectors/drawing';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';

const stateToComputed = (state, attrs) => ({
  drawing: getDrawing(state, attrs.drawingId),
  selectedImages: getSelectedImages(state),
  selectedMarkers: getSelectedMarkers(state),
});

const dispatchToActions = {};

class DrawingContext extends Component {
  @service tracking;

  get styleNamespace() {
    return podNames['drawing-context'];
  }

  get classNames() {
    let classNames = [this.styleNamespace];
    return classNames.join(' ');
  }

  @action
  onSelectImage(imageId) {
    const isSelected = this.selectedImages.includes(imageId);
    if (isSelected) {
      this.args.onDeselectImage(imageId)
    } else {
      this.args.onSelectImage(imageId)
    }
  }

  @action
  onSelectMarker(markerId) {
    const isSelected = this.selectedMarkers.includes(markerId);
    if (isSelected) {
      this.args.onDeselectMarker(markerId)
    } else {
      this.args.onSelectMarker(markerId)
    }
  }

  @action
  setOrientation(orientation) {
    this.args.onUpdateDrawing(this.args.drawingId, { orientation });
    this.tracking.trackEvent(`drawing_set_orientation_${orientation}`);
  }

  @action
  onSortImagesList(imagesList) {
    this.args.onUpdateDrawing(this.args.drawingId, { imagesList });
    this.tracking.trackEvent('drawing_sorted_images');
  }

  @action
  onSortMarkersList(markersList) {
    this.args.onUpdateDrawing(this.args.drawingId, { markersList });
    this.tracking.trackEvent('drawing_sorted_markers');
  }

  @action
  onSetType(type) {
    this.args.onUpdateDrawing(this.args.drawingId, { type });
    this.tracking.trackEvent('drawing_set_type');
  }

  @computed('selectedImages.[]')
  get selectedImageId() {
    return (
      this.selectedImages &&
      this.selectedImages.length === 1 &&
      this.selectedImages[0]
    );
  }

  @computed('selectedMarkers.[]')
  get selectedMarkerId() {
    return (
      this.selectedMarkers &&
      this.selectedMarkers.length === 1 &&
      this.selectedMarkers[0]
    );
  }

  @action
  onSetCreatingImageAsset() {
    return (
      this.args.onSetCreatingImageAsset &&
      this.args.onSetCreatingImageAsset(this.args.drawingId)
    );
  }

  @action
  onRemoveImage() {
    return (
      this.selectedImageId &&
      this.args.onRemoveSelectedDrawingItems &&
      this.args.onRemoveSelectedDrawingItems(
        this.args.drawingId,
        [this.selectedImageId],
        [],
        []
      )
    );
  }

  @action
  onRemoveMarker() {
    return (
      this.selectedMarkerId &&
      this.args.onRemoveSelectedDrawingItems &&
      this.args.onRemoveSelectedDrawingItems(
        this.args.drawingId,
        [],
        [this.selectedMarkerId],
        []
      )
    );
  }
}

export default connect(stateToComputed, dispatchToActions)(DrawingContext);
