import { action, computed } from '@ember/object';

import Component from '@glimmer/component';
import { connect } from 'ember-redux';
import { getInventionUi } from '../../../selectors/invention-ui';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';

const stateToComputed = function (state) {
  return {
    ui: getInventionUi(state),
  };
};

const dispatchToActions = {};

class LeftContextNav extends Component {
  @service data;

  navigationOptions = [{
    id: 'elements',
    name: 'Requirements',
    testId: 'explorer-nav-requirements'
  },{
    id: 'terms',
    name: 'Glossary',
    testId: 'explorer-nav-terms'
  }]

  // navigationOptions = [{
  //   id: 'elements',
  //   name: 'Requirements',
  //   testId: 'explorer-nav-requirements'
  // },{
  //   id: 'figures',
  //   name: 'Figures',
  //   testId: 'explorer-nav-figures'
  // },{
  //   id: 'terms',
  //   name: 'Glossary',
  //   testId: 'explorer-nav-terms'
  // }]

  get styleNamespace() {
    return podNames['left-context-nav'];
  }

  get classNames() {
    let classNames = [this.styleNamespace];
    return classNames.join(' ');
  }

  @computed('ui.activeContextTab')
  get activeNavigationOption() {
    return this.ui && this.ui.activeContextTab && this.getNavigationOption(this.ui.activeContextTab)
  }

  getNavigationOption(id) {
    return this.navigationOptions.find(option => option.id === id)
  }

  @action
  setActiveNavigationOption(id) {
    this.data.setActiveContextTab(id);
  }
}

export default connect(stateToComputed, dispatchToActions)(LeftContextNav);
