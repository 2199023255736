import Component from '@ember/component';
import { equal } from '@ember/object/computed';

const UiGrid = Component.extend({
  classNames: ['grid'],
  classNameBindings: [
    'isSmall',
    'isSmaller',
    'isTiny',
    'isMedium',
    'gapMedium',
    'gapLarge',
    'gapSmall'
  ],
  tagName: 'ul',

  attributeBindings: [
    'disabled',
    'ariaLabel',
    'ariaDisabled'
  ],
  size: 'normal', // small, medium, large
  isSmall: equal('size', 'small'),
  isSmaller: equal('size', 'smaller'),
  isTiny: equal('size', 'tiny'),
  isMedium: equal('size', 'medium'),
  gapSmall: equal('gap', 'small'),
  gapMedium: equal('gap', 'medium'),
  gapLarge: equal('gap', 'large'),
});

export default UiGrid;
