import { action, computed } from '@ember/object';
import { debounce, later, next } from '@ember/runloop';
import {
  deselectElement,
  deselectGraph,
  selectElement,
  updateVersionTreeCoordinates,
} from '../../../actions/invention-ui';
import { forEach, maxBy, minBy } from 'lodash';
import {
  getActiveFeature,
  getCollapsedDescendantsList,
  getCollapsedNodesList,
  getDefaultElementCategory,
  getPreviewMode,
  getSelectedChildren,
  getSelectedEdges,
  getSelectedElementVersions,
  getSelectedElements,
  getVersionTreeCoordinates,
} from '../../../selectors/invention-ui';
import {
  getDisconnectedNodesList,
  getElementsMap,
} from '../../../selectors/graph';
import { getElement, getElementsList } from '../../../selectors/element';
import {
  getElementVersion,
  getElementVersionsList,
} from '../../../selectors/element-version';

import Component from '@glimmer/component';
import ENV from '../../../config/environment';
import Konva from 'konva';
import { alias } from '@ember/object/computed';
import { batchGroupBy } from '../../../utils/redux';
import { connect } from 'ember-redux';
import { getDrawings } from '../../../selectors/drawing';
import { getMethods } from '../../../selectors/method';
import { getPrimaryInstancesList } from '../../../selectors/component';
import {
  getRootNodeId,
} from '../../../selectors/graph';
import { getTargetPoint } from '../../../utils/graph';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { updateNodeCoordinates } from '../../../actions/invention';

const dispatchToActions = {};

const stateToComputed = (state, attrs) => ({
  selectedElements: getSelectedElements(state),
  selectedElementVersions: getSelectedElementVersions(state),
  selectedEdges: getSelectedEdges(state),
  elementsList: getElementsList(state),
  elementVersionsList: getElementVersionsList(state),
  disconnectedNodesList: getDisconnectedNodesList(state),
  elementsTreeMap: getElementsMap(state, attrs.productId),
  versionTreeCoordinates: getVersionTreeCoordinates(state),
  collapsedNodesList: getCollapsedNodesList(state, attrs.productId),
  collapsedDescendantsList: getCollapsedDescendantsList(state, attrs.productId),
  methods: getMethods(state),
  drawings: getDrawings(state),
  rootNodeId: getRootNodeId(state),
  primaryInstancesList: getPrimaryInstancesList(state),
  activeFeatureId: getActiveFeature(state),
  defaultElementCategory: getDefaultElementCategory(state),
  previewMode: getPreviewMode(state),
});

class PreviewGrap extends Component {
  @service models;
  @service contextMenu;
  @service applicationState;
  @service testing;
  @service data;
  @service clipboard;
  @service notify;
  @service tracking;
  @service redux;

  container = 'graph-content';
  verticalPadding = 19.5;
  nodePadding = 25;
  tweens = [];
  elementSourceOffset = { x: 150, y: 220 };
  minScale = 0.015;
  maxScale = 1.05;

  @tracked didFirstRender = false;
  @tracked onCanvas = false;
  @tracked creatingElementVersionId = null;
  @tracked creatingElementId = null;
  @tracked hoveringElementId = null;
  @tracked hoveringElementVersionId = null;
  @tracked createMode = false;
  @tracked edgeCreateMode = false;
  @tracked stage;
  @tracked layer;
  @tracked mouseX;
  @tracked mouseY;
  @tracked stageScale;
  @tracked stageX;
  @tracked stageY;
  @tracked stageWidth = 500;
  @tracked stageHeight = 500;
  @tracked visibleAreaIndex = 0;

  @alias('elementsTreeMap') nodesMap;
  @alias('elementsViewEdges') edges;

  willDestroy() {
    this.stage.off('dragend');
    this.stage.destroy();
  }

  get styleNamespace() {
    return podNames['preview-graph'];
  }

  @computed(
    'createMode',
    'edgeCreateMode',
    'styleNamespace',
    'defaultElementCategory'
  )
  get classNames() {
    let classNames = [this.styleNamespace];
    if (this.createMode) {
      classNames.push('is-create-mode');
      classNames.push(this.defaultElementCategory);
    }
    if (this.edgeCreateMode) {
      classNames.push('is-edge-create-mode');
    }
    return classNames.join(' ');
  }

  get isElementsView() {
    return this.args.productId ? true : false;
  }

  get actionMode() {
    return this.applicationState.cmdKeyDown;
  }

  get reachedScaleThreshold() {
    return this.stageScale < 0.1045;
  }

  @computed('selectedElements.[]')
  get selectedElement() {
    return this.selectedElements.length === 1 && this.selectedElements[0];
  }

  @computed('selectedElementVersions.[]')
  get selectedElementVersion() {
    return (
      this.selectedElementVersions.length === 1 &&
      this.selectedElementVersions[0]
    );
  }

  @computed('selectedElementVersions.[]', 'selectedElements.[]')
  get selectedChildren() {
    const state = this.redux.getState();
    return getSelectedChildren(state);
  }

  // eslint-disable-next-line ember/require-computed-property-dependencies
  @computed('collapsedDescendantsList.[]')
  get collapsedDescendantsMap() {
    const collapsedDescendantsMap = {};
    this.collapsedDescendantsList.forEach(
      (id) => (collapsedDescendantsMap[id] = id)
    );
    return collapsedDescendantsMap;
  }

  // eslint-disable-next-line ember/require-computed-property-dependencies
  @computed('elementsList.[]', 'nodesMap', 'collapsedDescendantsList.[]')
  get visibleElementsList() {
    return this.elementsList.filter((id) => {
      const isVisible = !this.collapsedDescendantsList.includes(id);
      return this.nodesMap[id] && isVisible;
    });
  }

  // eslint-disable-next-line ember/require-computed-property-dependencies
  @computed('visibleElementsList.[]')
  get elements() {
    const state = this.redux.getState();
    const elements = this.visibleElementsList
      .filter((id) => {
        const element = getElement(state, id);
        return !element.component;
      })
      .map((id) => {
        const element = getElement(state, id);
        const model =
          this.models.find(id) ||
          this.models.findOrCreate(id, 'element', element);
        return model;
      });
    return elements;
  }

  // eslint-disable-next-line ember/require-computed-property-dependencies
  @computed('visibleElementsList.[]')
  get elementInstances() {
    const state = this.redux.getState();
    const instances = this.visibleElementsList
      .filter((id) => {
        const element = getElement(state, id);
        return element.instanceOf;
      })
      .map((id) => {
        const instance = getElement(state, id);
        const instanceOfId = instance.instanceOf;
        const instanceOf = getElement(state, instanceOfId);
        const elementModel =
          this.models.find(id) ||
          this.models.findOrCreate(id, 'element', instance);
        const instanceOfModel =
          this.models.find(instanceOf) ||
          this.models.findOrCreate(instanceOfId, 'element', instanceOf);
        return {
          id,
          element: elementModel,
          instanceOf: instanceOfModel,
        };
      });

    return instances;
  }

  @computed(
    'selectedElements',
    'selectedElementVersions',
    'selectedEdges',
    'args.productId'
  )
  get selectedItems() {
    let selectedItems = [];
    if (this.args.productId) {
      selectedItems = [...this.selectedElements, ...this.selectedEdges];
    } else {
      selectedItems = [
        ...this.selectedElements,
        ...this.selectedElementVersions,
        ...this.selectedEdges,
      ];
    }

    return selectedItems;
  }

  getEdge(source, target, type) {
    return {
      id: `${source.id}_${target.id}`,
      type,
      source: source.id,
      sourceType: source.type,
      sourceX: source.x,
      sourceY: source.y,
      target: target.id,
      targetType: target.type,
      targetCategory: target.category,
      targetX: target.x,
      targetY: target.y,
    };
  }

  getElementOrVersion(nodeId) {
    const state = this.redux.getState();
    return getElement(state, nodeId) || getElementVersion(state, nodeId);
  }

  @computed(
    'elementsTreeMap',
    'collapsedDescendantsMap',
    'args.undoIndex',
    'stage',
    'didFirstRender'
  )
  get elementsViewEdges() {
    return (
      (this.stage &&
        this.didFirstRender &&
        this.getEdges(this.elementsTreeMap, this.collapsedDescendantsMap)) ||
      []
    );
  }

  getEdges(treeMap, collapsedDescendantsMap) {
    const edges = [];
    forEach(treeMap, (mappedNode) => {
      const targetsList = mappedNode.children.filter(
        (nodeId) => !collapsedDescendantsMap[nodeId]
      );

      const sourceElementOrVersion = this.getElementOrVersion(mappedNode.id);

      if (sourceElementOrVersion && targetsList) {
        let source = {
          ...this.getElementOrVersion(mappedNode.id),
        };

        // offset source
        if (
          (source.category === 'system' || source.category === 'part') &&
          this.args.productId
        ) {
          const sourceNode = this.stage.findOne(`#${source.id}`);
          if (sourceNode) {
            const elementVersionNode = sourceNode.find(
              '.element-version-node'
            )[0];
            const sourceX = source.x + elementVersionNode.x();
            const sourceY = source.y + elementVersionNode.y();
            source = {
              ...source,
              // y: source.y - this.elementSourceOffset.y,
              x: sourceX,
              y: sourceY,
            };
          }
        }

        targetsList.forEach((targetId) => {
          const target = this.getElementOrVersion(targetId);
          const targetType = target.type;

          const targetPoint = getTargetPoint({
            // shape: targetType === 'element' ? 'circle' : 'rect',
            shape: 'rect',
            sourceX: source.x,
            sourceY: source.y,
            targetX: target.x,
            targetY: target.y,
            targetWidth: targetType === 'element' ? 400 : 300,
            targetHeight: targetType === 'element' ? 400 : 300,
            targetRadius: 200,
            padding: this.nodePadding,
            snapTop: true,
          });

          const _target = {
            ...target,
            x: targetPoint.x,
            y: targetPoint.y,
          };

          // const _source = {
          //   ...source,
          //   x: source.x - 150,
          //   y: source.y + 200,
          // };

          const edge =
            target.type === 'element'
              ? this.getEdge(source, _target, 'comprises')
              : this.getEdge(source, _target, 'version');
          edges.push(edge);
        });
      }
    });

    return edges;
  }

  fitStageToContainer() {
    const stage = this.stage;
    const container = document.getElementById(this.container);
    const width = container.offsetWidth;
    const height = container.offsetHeight;
    stage.width(width);
    stage.height(height);
    this.stageWidth = width;
    this.stageHeight = height;
    stage.batchDraw();
  }

  deselectAll() {
    this.redux.store.dispatch(deselectGraph());
    this.tracking.trackEvent('graph_deselected_all');
  }

  handleStageDragEnd() {
    this.tracking.trackEvent('graph_panned');
    debounce(this, this.updateTreeCoordinates, 500);
  }

  handleStageDragMove() {
    this.stageX = this.stage.x();
    this.stageY = this.stage.y();
  }

  handleWheel(e) {
    e.evt.preventDefault();
    this.contextMenu.close();
    const stage = e.target.getStage();
    const oldScale = stage.scaleX();
    const mousePointTo = {
      x: stage.getPointerPosition().x / oldScale - stage.x() / oldScale,
      y: stage.getPointerPosition().y / oldScale - stage.y() / oldScale,
    };
    const deltaY = e.evt.deltaY;
    const scaleBy = 1.17;
    let newScale = deltaY < 0 ? oldScale * scaleBy : oldScale / scaleBy;

    // contstrain
    if (newScale < this.minScale) newScale = this.minScale;
    if (newScale > this.maxScale) newScale = this.maxScale;

    const newPos = {
      x: stage.getPointerPosition().x - mousePointTo.x * newScale,
      y: stage.getPointerPosition().y - mousePointTo.y * newScale,
    };
    stage.scale({ x: newScale, y: newScale });
    stage.position(newPos);
    this.stageScale = newScale;
    this.stageX = newPos.x;
    this.stageY = newPos.y;
    stage.batchDraw();
    debounce(this, this.updateTreeCoordinates, 500);
  }

  updateTreeCoordinates() {
    const x = this.stage.x();
    const y = this.stage.y();
    const k = this.stage.scaleX();
    this.redux.store.dispatch(updateVersionTreeCoordinates({ x, y, k }));
  }

  handleBackgroundClick() {
    this.deselectAll();
  }

  @action
  setup() {
    let { x, y, k } = this.versionTreeCoordinates;

    if (!x) x = 0;
    if (!y) y = 0;
    if (!k) k = 1;

    const stage = new Konva.Stage({
      container: this.container,
      width: this.stageWidth,
      height: this.stageHeight,
      x,
      y,
      scale: {
        x: k,
        y: k,
      },
      draggable: true,
    });

    this.stageScale = k;
    this.stageX = x;
    this.stageY = y;

    stage.on('dragend', () => this.handleStageDragEnd());
    stage.on('dragmove', () => this.handleStageDragMove());

    // create up to 5 layers for better performance

    // all edge nodes
    const edgesLayer = new Konva.Layer({ name: 'edges' });
    // all element version nodes
    // const elementVersionsLayer = new Konva.Layer();
    // all element nodes
    const elementsLayer = new Konva.Layer({ name: 'elements' });
    // selected and dragging nodes
    const interactiveLayer = new Konva.Layer({ name: 'interactive' });

    // add the layers to the stage
    // stage.add(elementVersionsLayer);
    stage.add(edgesLayer);
    stage.add(elementsLayer);
    stage.add(interactiveLayer);

    // add stage listeners
    stage.on('wheel', (event) => this.handleWheel(event));
    stage.on('mouseenter', () => (this.onCanvas = true));
    stage.on('mouseleave', () => (this.onCanvas = false));
    stage.on('click', (event) => {
      if (event.target === stage) this.handleBackgroundClick(event);
    });

    stage.on('contextmenu', (e) => {
      // prevent default behavior
      e.evt.preventDefault();
      if (e.target !== stage) {
        // if we are not on empty place of the stage we will do nothing
        return;
      }
      const { clientX, clientY } = e.evt;
      const pointerPos = stage.getPointerPosition();
      const scale = stage.scaleX();
      const stageX = pointerPos.x / scale - stage.x() / scale;
      const stageY = pointerPos.y / scale - stage.y() / scale;
      this.contextMenu.open('stage', {
        targetId: null,
        x: clientX,
        y: clientY,
        stageX,
        stageY,
      });
    });

    this.edgesLayer = edgesLayer;
    this.elementsLayer = elementsLayer;
    // this.elementVersionsLayer = elementVersionsLayer;
    this.interactiveLayer = interactiveLayer;
    this.stage = stage;

    if (ENV.environment === 'test') {
      this.testing.konvaStage = this.stage;
    }

    // fit the canvas to the container
    this.fitStageToContainer();
    this.centerGraph();
  }

  centerGraph() {
    const { x, y, k } = this.versionTreeCoordinates;
    if (x === 0 && y === 0 && k === 0) {
      this.fitToScreen();
      // this.fitToNode(null, false);
    }
  }

  incrementVisibleAreaIndex() {
    this.visibleAreaIndex++;
  }

  @action
  onVisibleAreaChanged() {
    debounce(this, this.incrementVisibleAreaIndex, 10);
  }



  @action
  onElementContextClick(elementId, e) {
    e.evt.preventDefault();
    e.cancelBubble = true;
    const { clientX, clientY } = e.evt;
    const pointerPos = this.stage.getPointerPosition();
    const scale = this.stage.scaleX();
    const stageX = pointerPos.x / scale - this.stage.x() / scale;
    const stageY = pointerPos.y / scale - this.stage.y() / scale;
    this.contextMenu.open('element', {
      targetId: elementId,
      x: clientX,
      y: clientY,
      stageX,
      stageY,
    });
  }



  selectElement(_elementId, isSelected, isRightClick) {
    batchGroupBy.start();
    if (isSelected && !isRightClick) {
      this.redux.store.dispatch(deselectElement(_elementId));
    } else if (isSelected && isRightClick) {
      // do nothing
    } else {
      this.redux.store.dispatch(
        selectElement(_elementId, this.applicationState.shiftKeyDown)
      );
      this.data.setActiveFeature(null);
    }
    this.data.setActiveFeature(null);
    batchGroupBy.end();
  }

  scheduleRender(layer) {
    if (layer) {
      layer.batchDraw();
    } else {
      this.edgesLayer.batchDraw();
      this.elementsLayer.batchDraw();
      this.interactiveLayer.batchDraw();
    }

    if (!this.didFirstRender) {
      this.didFirstRender = true;
    }
  }

  updateNodeCoordinates() {
    let state = this.redux.getState();
    const stage = this.stage;
    const elements = [];
    const elementVersions = [];
    const visibleNodesList = this.visibleElementsList;

    let nodes = visibleNodesList
      .filter((id) => stage.findOne(`#${id}`))
      .map((id) => stage.findOne(`#${id}`));

    nodes.forEach((node) => {
      const id = node.id();
      const x = node.x();
      const y = node.y();
      elements.push({
        id,
        x,
        y,
      });

      const element = getElement(state, id);

      if (
        this.isElementsView &&
        element &&
        element.elementVersionsList.length === 1
      ) {
        elementVersions.push({
          id: element.elementVersionsList[0],
          x,
          y,
        });
      }
    });

    batchGroupBy.start();
    this.redux.store.dispatch(updateNodeCoordinates(elements, elementVersions));
    this.data.updateGraph();
    batchGroupBy.end();
  }

  updateEdges() {
    this.edgesLayer.find('.edge').forEach((edgeNode) => {
      const sourceNodeId = edgeNode.id().split('_')[0];
      const targetNodeId = edgeNode.id().split('_')[1];
      const sourceNode = this.stage.findOne(`#${sourceNodeId}`);
      const targetNode = this.stage.findOne(`#${targetNodeId}`);

      if (sourceNode && targetNode) {
        const sourceCategory = sourceNode.getAttr('category');
        let sourceX = sourceNode.x();
        let sourceY = sourceNode.y();

        // offset source
        if (
          (sourceCategory === 'system' || sourceCategory === 'part') &&
          this.args.productId
        ) {
          const elementVersionNode = sourceNode.find(
            '.element-version-node'
          )[0];
          sourceX = sourceX + elementVersionNode.x();
          sourceY = sourceY + elementVersionNode.y();
        }
        const targetType = targetNode.getAttr('nodeType');

        const targetPoint = getTargetPoint({
          // shape: targetType === 'element' ? 'circle' : 'rect',
          shape: 'rect',
          sourceX: sourceX,
          sourceY: sourceY,
          targetX: targetNode.x(),
          targetY: targetNode.y(),
          targetWidth: targetType === 'element' ? 400 : 300,
          targetHeight: targetType === 'element' ? 400 : 300,
          targetRadius: 200,
          padding: this.nodePadding,
          snapTop: true,
        });

        const targetX = targetPoint.x;
        const targetY = targetPoint.y;
        this.updateEdgePoints(edgeNode, sourceX, sourceY, targetX, targetY);
      }
    });

    this.edgesLayer.batchDraw();
  }

  updateEdgePoints(edgeNode, sourceX, sourceY, targetX, targetY) {
    const selectedNode = this.edgesLayer.findOne(`#select-${edgeNode.id()}`);
    const clickNode = this.edgesLayer.findOne(`#click-${edgeNode.id()}`);

    edgeNode.points([sourceX, sourceY, targetX, targetY]);
    selectedNode.points([sourceX, sourceY, targetX, targetY]);
    clickNode.points([sourceX, sourceY, targetX, targetY]);
    selectedNode.visible(selectedNode.isClientRectOnScreen());
    edgeNode.visible(edgeNode.isClientRectOnScreen());
    clickNode.visible(clickNode.isClientRectOnScreen());
  }

  moveDraggedEdges(nodeId, x, y) {
    const mappedNode = this.nodesMap[nodeId];

    const incomingEdgeId = mappedNode.parent
      ? `${mappedNode.parent}_${nodeId}`
      : null;

    const outgoingEdgeIds = mappedNode.children.map(
      (childId) => `${nodeId}_${childId}`
    );

    const noEdges = incomingEdgeId === null && outgoingEdgeIds.length === 0;

    if (noEdges) return;

    if (incomingEdgeId) {
      const incomingEdgeNode = this.edgesLayer.findOne(`#${incomingEdgeId}`);
      if (incomingEdgeNode) {
        const points = incomingEdgeNode.points();

        const edgeId = incomingEdgeNode.id();
        const targetId = edgeId.split('_')[1];
        const targetNode = this.stage.findOne(`#${targetId}`);
        const targetType = targetNode.getAttr('nodeType');
        const targetPoint = getTargetPoint({
          // shape: targetType === 'element' ? 'circle' : 'rect',
          shape: 'rect',
          sourceX: points[0],
          sourceY: points[1],
          targetX: x,
          targetY: y,
          targetWidth: targetType === 'element' ? 400 : 300,
          targetHeight: targetType === 'element' ? 400 : 300,
          targetRadius: 200,
          padding: this.nodePadding,
          snapTop: true,
        });

        const targetX = targetPoint.x;
        const targetY = targetPoint.y;

        this.updateEdgePoints(
          incomingEdgeNode,
          points[0],
          points[1],
          targetX,
          targetY
        );
      }
    }

    outgoingEdgeIds.forEach((outgoingEdgeId) => {
      const outgoingEdgeNode = this.edgesLayer.findOne(`#${outgoingEdgeId}`);
      if (outgoingEdgeNode) {
        let sourceX = x;
        let sourceY = y;
        const sourceId = outgoingEdgeId.split('_')[0];
        const sourceNode = this.stage.findOne(`#${sourceId}`);
        const sourceCategory = sourceNode.getAttr('category');
        const targetId = outgoingEdgeId.split('_')[1];
        const targetNode = this.stage.findOne(`#${targetId}`);
        const targetType = targetNode.getAttr('nodeType');

        // offset source
        if (
          (sourceCategory === 'system' || sourceCategory === 'part') &&
          this.args.productId
        ) {
          const elementVersionNode = sourceNode.find(
            '.element-version-node'
          )[0];
          sourceX = sourceX + elementVersionNode.x();
          sourceY = sourceY + elementVersionNode.y();
        }

        const targetPoint = getTargetPoint({
          // shape: targetType === 'element' ? 'circle' : 'rect',
          shape: 'rect',
          sourceX: sourceX,
          sourceY: sourceY,
          targetX: targetNode.x(),
          targetY: targetNode.y(),
          targetWidth: targetType === 'element' ? 400 : 300,
          targetHeight: targetType === 'element' ? 400 : 300,
          targetRadius: 200,
          padding: this.nodePadding,
          snapTop: true,
        });

        const targetX = targetPoint.x;
        const targetY = targetPoint.y;

        this.updateEdgePoints(
          outgoingEdgeNode,
          sourceX,
          sourceY,
          targetX,
          targetY
        );
      }
    });

    this.edgesLayer.batchDraw();
  }

  moveStage(stage, location, scale, animate = true) {
    const { x, y } = location;

    if (animate) {
      const tween = new Konva.Tween({
        duration: 0.35,
        easing: Konva.Easings.EaseInOut,
        node: stage,
        scaleX: (scale && scale.x) || 1,
        scaleY: (scale && scale.y) || 1,
        x,
        y,
        onUpdate: () => {
          this.stageX = stage.x();
          this.stageY = stage.y();
          this.stageScale = stage.scaleX();
        },
      });
      tween.play();
      stage.batchDraw();

      debounce(this, this.updateTreeCoordinates, 350);
    } else {
      stage.scale(scale);
      stage.x(x);
      stage.y(y);
      this.stageX = stage.x();
      this.stageY = stage.y();
      this.stageScale = stage.scaleX();
      this.updateTreeCoordinates;
    }
  }

  @action
  onZoomToNode() {
    const nodeId = this.applicationState.zoomToNode;
    later(() => {
      this.fitToNode(nodeId);
    }, 200);
  }

  @action
  onUndo(/*elem, [undoIndex]*/) {
    next(this, this.updateEdges);
  }

  @action
  zoomIn() {
    const stage = this.stage;
    const scaleBy = 1.07;
    const oldScale = stage.scaleX();
    let newScale = scaleBy * oldScale;

    // constrain
    if (newScale > this.maxScale) newScale = this.maxScale;

    stage.scale({ x: newScale, y: newScale });
    stage.batchDraw();
    this.stageScale = newScale;
    this.stageX = stage.x();
    this.stageY = stage.y();
    this.tracking.trackEvent('graph_zoomed_in');
    debounce(this, this.updateTreeCoordinates, 500);
  }

  @action
  zoomOut() {
    const stage = this.stage;
    const scaleBy = 0.93;
    const oldScale = stage.scaleX();
    let newScale = scaleBy * oldScale;

    // contstrain
    if (newScale < this.minScale) newScale = this.minScale;

    stage.scale({ x: newScale, y: newScale });
    this.stageScale = newScale;
    this.stageX = stage.x();
    this.stageY = stage.y();
    stage.batchDraw();
    this.tracking.trackEvent('graph_zoomed_out');
    debounce(this, this.updateTreeCoordinates, 500);
  }

  @action
  onElementClick(elementId, isSelected, isRightClick) {
    this.selectElement(elementId, isSelected, isRightClick);
  }

  @action
  onScheduleRender() {
    debounce(this, this.scheduleRender, 10);
  }

  @action
  onResize() {
    this.fitStageToContainer();
  }

  @action
  fitToNode(nodeId, animate = true) {
    const stage = this.stage;

    const nodes = stage.find('.node');
    const edges = stage.find('.edge');
    const clickEdges = stage.find('.click-edge');

    nodes.forEach((node) => node.visible(true));
    edges.forEach((edge) => edge.visible(true));
    clickEdges.forEach((edge) => edge.visible(true));

    const scale = 0.35;
    const width = stage.width();
    const height = stage.height();
    const originalScale = stage.scaleX();

    let rect = {
      height: 183.6,
      width: 183.6,
      x: -91.8,
      y: -91.8,
    };

    stage.scale({
      x: scale,
      y: scale,
    });

    if (nodeId) {
      const node = stage.findOne(`#${nodeId}`);
      rect = node.getClientRect({ relativeTo: 'stage' });
    }

    const delta = {
      x: width / 2 - (rect.x + rect.width / 2),
      y: height / 2 - (rect.y + rect.height / 2),
    };

    const x = stage.x();
    const y = stage.y();

    const stageCenter = {
      x: x + delta.x,
      y: y + delta.y,
    };

    stage.scale({
      x: originalScale,
      y: originalScale,
    });

    this.moveStage(stage, stageCenter, { x: scale, y: scale }, animate);
  }

  @computed('elements.@each.{x,y}', 'disconnectedNodesList.[]')
  get bounds() {
    const elements = this.elements.filter(
      (elementId) => !this.disconnectedNodesList.includes(elementId)
    );
    const padding = 300;
    const maxX = maxBy(elements, 'x').x + padding;
    const maxY = maxBy(elements, 'y').y + padding;
    const minX = minBy(elements, 'x').x - padding;
    const minY = minBy(elements, 'y').y - padding;
    const width = Math.abs(maxX - minX);
    const height = Math.abs(maxY - minY);
    const x = width / 2;
    const y = height / 2;

    return {
      x,
      y,
      maxX,
      maxY,
      minX,
      minY,
      width,
      height,
    };
  }

  @action
  fitToScreen() {
    const stage = this.stage;
    const padding = 50;

    const bounds = this.bounds;

    const width = stage.width();
    const height = stage.height();

    const minScale = 0.4;

    let scale = Math.min(
      width / (bounds.width + padding),
      height / (bounds.height + padding)
    );

    scale = Math.min(minScale, scale);

    stage.scale({
      x: scale,
      y: scale,
    });

    const scaledHeight = (bounds.height - 500) * scale;

    stage.x(width / 2);
    stage.y(height / 2 - scaledHeight / 2);

    stage.batchDraw();
    this.stageScale = stage.scaleX();
    this.stageX = stage.x();
    this.stageY = stage.y();

    this.tracking.trackEvent('graph_zoomed_fit');
    debounce(this, this.updateTreeCoordinates, 350);
  }

  @action
  arrange(includeDisconnectedNodes = true) {
    const stage = this.stage;
    const coordinatesMap = this.data.getCoordinatesMap(this.args.productId);

    const visibleNodesList = [...this.visibleElementsList];

    // const elementsMap = keyBy(this.visibleElementsList);

    // select shapes by name
    let nodes = visibleNodesList
      .filter((id) => stage.findOne(`#${id}`))
      .map((id) => stage.findOne(`#${id}`));

    let edges = this.edges
      .filter((edge) => stage.findOne(`#${edge.id}`))
      .map((edge) => stage.findOne(`#${edge.id}`));

    let maxDistance = 0;

    if (!includeDisconnectedNodes) {
      nodes = nodes.filter((node) => {
        return !this.disconnectedNodesList.includes(node.id());
      });
    }

    nodes.forEach((node) => {
      const nodeId = node.id();
      const coordinates = coordinatesMap[nodeId];
      if (coordinates) {
        const x = coordinates.endX;
        const y = coordinates.endY;
        const startX = node.x();
        const startY = node.y();
        var dx = startX - x;
        var dy = startY - y;
        const distance = Math.sqrt(dx * dx + dy * dy);
        maxDistance = Math.max(distance, maxDistance);
      }
    });

    let duration = 0.65;
    if (maxDistance < 5000) duration = 0.6;
    if (maxDistance < 1000) duration = 0.5;
    if (maxDistance < 750) duration = 0.4;
    if (maxDistance < 500) duration = 0.3;

    const changedNodes = {};

    // apply transition to all nodes in the array
    nodes.forEach((node) => {
      const nodeId = node.id();
      const coordinates = coordinatesMap[nodeId];
      if (coordinates) {
        const x = coordinates.endX;
        const y = coordinates.endY;
        const hasChanged =
          coordinates.endX !== node.x() || coordinates.endY !== node.y();

        if (hasChanged) {
          changedNodes[nodeId] = nodeId;
          node.x(x);
          node.y(y);
        }
      }
    });

    if (!includeDisconnectedNodes) {
      edges = edges.filter((edge) => {
        const edgeId = edge.id();
        const sourceId = edgeId.split('_')[0];
        const targetId = edgeId.split('_')[1];
        return (
          !this.disconnectedNodesList.includes(sourceId) &&
          !this.disconnectedNodesList.includes(targetId)
        );
      });
    }

    // apply transition to all edges in the array
    edges.forEach((edge) => {
      const edgeId = edge.id();
      const sourceId = edgeId.split('_')[0];
      const targetId = edgeId.split('_')[1];
      const sourceCoordinates = coordinatesMap[sourceId];
      const targetCoordinates = coordinatesMap[targetId];
      const sourceNode = this.stage.findOne(`#${sourceId}`);
      const targetNode = this.stage.findOne(`#${targetId}`);

      if (
        sourceCoordinates &&
        targetCoordinates &&
        sourceNode &&
        targetNode &&
        (changedNodes[sourceId] || changedNodes[targetId])
      ) {
        let sourceX = sourceCoordinates.endX;
        let sourceY = sourceCoordinates.endY;
        const sourceCategory = sourceNode.getAttr('category');
        const targetType = targetNode.getAttr('nodeType');

        // offset source
        if (
          (sourceCategory === 'system' || sourceCategory === 'part') &&
          this.args.productId
        ) {
          const elementVersionNode = sourceNode.find(
            '.element-version-node'
          )[0];
          sourceX = sourceX + elementVersionNode.x();
          sourceY = sourceY + elementVersionNode.y();
        }

        const targetPoint = getTargetPoint({
          // shape: targetType === 'element' ? 'circle' : 'rect',
          shape: 'rect',
          sourceX: sourceX,
          sourceY: sourceY,
          targetX: targetCoordinates.endX,
          targetY: targetCoordinates.endY,
          targetWidth: targetType === 'element' ? 400 : 300,
          targetHeight: targetType === 'element' ? 400 : 300,
          targetRadius: 200,
          padding: this.nodePadding,
          snapTop: true,
        });

        const targetX = targetPoint.x;
        const targetY = targetPoint.y;
        const points = [sourceX, sourceY, targetX, targetY];

        edge.points(points);

        const clickNode = this.edgesLayer.findOne(`#click-${edgeId}`);

        clickNode.points(points);
      }
    });

    debounce(this, this.updateNodeCoordinates, duration * 1000);
  }

  @action
  onAutoArrange() {
    // debounce(this, this.arrange, false, 300, false);
    this.arrange();
  }
}

export default connect(stateToComputed, dispatchToActions)(PreviewGrap);
