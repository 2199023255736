import { action, computed } from '@ember/object';

import Component from '@glimmer/component';
import { bool } from '@ember/object/computed';
import { connect } from 'ember-redux';
import { getTerm } from '../../../selectors/term';
import podNames from 'ember-component-css/pod-names';

const stateToComputed = (state, attrs) => ({
  term: getTerm(state, attrs.termId),
});

const dispatchToActions = {};

class PreviewContextTerm extends Component {
  get styleNamespace() {
    return podNames['preview-context-term'];
  }

  get classNames() {
    let classNames = [this.styleNamespace, 'preview-context-term'];
    return classNames.join(' ');
  }

  @computed('term.markersList.[]')
  get markersList() {
    return this.term && this.term.markersList;
  }

  @bool('markersList.length') showFigures;

  @action
  onMarkerReferenceClick(markerId) {
    if (this.args.onMarkerReferenceClick) {
      this.args.onMarkerReferenceClick(markerId);
    }
  }
}

export default connect(stateToComputed, dispatchToActions)(PreviewContextTerm);
