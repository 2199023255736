import {
  ADD_METHOD_EDGE,
  ADD_METHOD_EDGE_POINT,
  UPDATE_METHOD_EDGE,
  REMOVE_METHOD_EDGE,
} from '../actions/actionTypes';
import { addFromSchema } from '../utils/schema';
import { insertAtIndex } from '../utils/array';
import { omit } from 'lodash';

const initialState = {
  ids: [],
  entities: {},
};

const methodEdgesReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case ADD_METHOD_EDGE: {
      let { methodEdgeId, attributes } = payload;
      const id = methodEdgeId;
      const createdAt = new Date();
      const updatedAt = new Date();
      const methodEdge = addFromSchema('method-edge', {
        id,
        createdAt,
        updatedAt,
        ...attributes,
      });

      return {
        ids: [...state.ids, id],
        entities: {
          ...state.entities,
          [methodEdgeId]: methodEdge,
        },
      };
    }

    case UPDATE_METHOD_EDGE: {
      const { methodEdgeId, attributes } = payload;
      const updatedAt = new Date();
      return {
        ...state,
        entities: {
          ...state.entities,
          [methodEdgeId]: {
            ...state.entities[methodEdgeId],
            ...attributes,
            updatedAt,
          },
        },
      };
    }

    case ADD_METHOD_EDGE_POINT: {
      const { methodEdgeId, methodEdgePointId, index } = payload;
      const updatedAt = new Date();

      let methodEdgePointsList = [
        ...state.entities[methodEdgeId].methodEdgePointsList
      ];

      methodEdgePointsList = insertAtIndex(
        methodEdgePointsList,
        index,
        methodEdgePointId
      );
      
      return {
        ...state,
        entities: {
          ...state.entities,
          [methodEdgeId]: {
            ...state.entities[methodEdgeId],
            methodEdgePointsList,
            updatedAt,
          },
        },
      };
    }

    case REMOVE_METHOD_EDGE: {
      const { methodEdgeId } = payload;
      return {
        ids: state.ids.filter((id) => id !== methodEdgeId),
        entities: omit(state.entities, methodEdgeId),
      };
    }

    // case SWITCH_METHOD_EDGE_SOURCE: {
    //   const methodEdge = state.methodEdges[payload.methodEdgeId];
    //   const oldSource = methodEdge.source;
    //   const updatedOutgoingEdges = [
    //     ...state.methodNodes[payload.source].outgoingEdgesList,
    //     payload.methodEdgeId
    //   ];
    //   const uniqueOutgoingEdges = uniq(updatedOutgoingEdges);
    //   return {
    //     ...state,
    //     methodEdges: {
    //       ...state.methodEdges,
    //       [payload.methodEdgeId]: {
    //         ...state.methodEdges[payload.methodEdgeId],
    //         source: payload.source,
    //         sourcePosition: payload.sourcePosition
    //       }
    //     },
    //     methodNodes: {
    //       ...state.methodNodes,
    //       [oldSource]: {
    //         ...state.methodNodes[oldSource],
    //         outgoingEdgesList: state.methodNodes[
    //           oldSource
    //         ].outgoingEdgesList.filter(id => id !== payload.methodEdgeId)
    //       },
    //       [payload.source]: {
    //         ...state.methodNodes[payload.source],
    //         outgoingEdgesList: uniqueOutgoingEdges
    //       }
    //     }
    //   };
    // }

    /* istanbul ignore next */
    default:
      return state;
  }
};

export default methodEdgesReducer;
