import { action, computed } from '@ember/object';

import Component from '@glimmer/component';
import { batchGroupBy } from '../../../utils/redux';
import { connect } from 'ember-redux';
import { debounce } from '@ember/runloop';
import { getContentOverflowed } from '../../../utils/general';
import { getImage } from '../../../selectors/image';
import { getImagesList } from '../../../selectors/drawing';
import move from 'lodash-move';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';
import { setUpdatingImageAsset } from '../../../actions/invention-ui';
import { tracked } from '@glimmer/tracking';

const stateToComputed = (state, attrs) => ({
  image: getImage(state, attrs.imageId),
  imagesList: getImagesList(state, attrs.drawingId),
});

const dispatchToActions = {};

class ImageContext extends Component {
  @service data;
  @service tracking;
  @tracked isOverflowed = false;

  get styleNamespace() {
    return podNames['image-context'];
  }

  get classNames() {
    let classNames = [this.styleNamespace];
    if (this.isOverflowed) classNames.push('is-overflowed');
    return classNames.join(' ');
  }

  // eslint-disable-next-line ember/require-computed-property-dependencies
  @computed('image.asset')
  get assetId() {
    return this.image && this.image.asset
  }

  @action
  onAssetClick(assetId) {
    batchGroupBy.start();
    this.redux.store.dispatch(setUpdatingImageAsset(this.args.imageId));
    if (assetId) {
      this.data.selectAsset(assetId);
    }
    batchGroupBy.end();
  }

  @action
  onAddAsset() {
    batchGroupBy.start();
    this.redux.store.dispatch(setUpdatingImageAsset(this.args.imageId));
    batchGroupBy.end();
  }
  
  @action
  onGrayscale(value) {
    const grayscale = !value;
    batchGroupBy.start();
    this.data.updateImage(this.args.imageId, {grayscale})
    batchGroupBy.end();
    if (grayscale) {
      this.tracking.trackEvent('image_added_grayscale_filter');
    } else {
      this.tracking.trackEvent('image_removed_grayscale_filter');
    }
  }

  @action
  onContentResize(sb) {
    this.isOverflowed = getContentOverflowed(sb);
  }
  
  @action
  onThreshold(value) {
    const threshold = !value;
    batchGroupBy.start();
    this.data.updateImage(this.args.imageId, {threshold})
    batchGroupBy.end();
    if (threshold) {
      this.tracking.trackEvent('image_added_threshold_filter');
    } else {
      this.tracking.trackEvent('image_removed_threshold_filter');
    }
  }

  updateImage(attributes) {
    batchGroupBy.start();
    this.data.updateImage(this.args.imageId, attributes)
    batchGroupBy.end();
  }
  
  @action
  onThresholdValue(value) {
    if (value && this.image.thresholdValue !== value) {
      const thresholdValue = value;
      debounce(this, this.updateImage, {thresholdValue}, 500, true);
    }
  }

  @action
  arrangeImage(direction) {
    const originalList = this.imagesList;
    const index = originalList.indexOf(this.args.imageId);
    const canArrange =
      direction === 'forward'
        ? index < this.imagesList.length - 1
        : index > 0;
    const toIndex = direction === 'forward' ? index + 1 : index - 1;

    if (canArrange) {
      const imagesList = move(originalList, index, toIndex);
      this.data.updateDrawing(this.args.drawingId, { imagesList });
    }
  }
}

export default connect(stateToComputed, dispatchToActions)(ImageContext);
