import {
  UPDATE_PRESENTATION,
} from '../actions/actionTypes';
import { addFromSchema } from '../utils/schema';

const initialState = addFromSchema('presentation');

const presentationReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case UPDATE_PRESENTATION: {
      const { attributes } = payload;
      return {
        ...state,
        ...attributes
      };
    }

    /* istanbul ignore next */
    default:
      return state;
  }
};

export default presentationReducer;
