import {
  ADD_DRAWING,
  DESELECT_DRAWING_ITEMS,
  REMOVE_DRAWING,
  REMOVE_SELECTED_DRAWING_ITEMS,
  SORT_DRAWINGS_LIST,
  UPDATE_DRAWING,
  UPDATE_DRAWING_ITEMS
} from './actionTypes';

import uuid from 'uuid/v4';

export const addDrawing = (attributes = {}, drawingId = uuid()) => {
  return {
    type: ADD_DRAWING,
    payload: {
      drawingId,
      attributes
    },
  };
};

export const deselectAll = (drawingId) => {
  return {
    type: DESELECT_DRAWING_ITEMS,
    payload: {
      drawingId
    },
  };
};

export const removeDrawing = (
  drawingId,
  imagesList,
  markersList,
  highlightsList
) => {
  return {
    type: REMOVE_DRAWING,
    payload: {
      drawingId,
      imagesList,
      markersList,
      highlightsList
    },
  };
};

export const removeSelectedDrawingItems = (
  drawingId,
  selectedImages,
  selectedMarkers,
  selectedHighlights
) => {
  return {
    type: REMOVE_SELECTED_DRAWING_ITEMS,
    payload: {
      drawingId,
      selectedImages,
      selectedMarkers,
      selectedHighlights
    },
  };
};

export const updateDrawing = (drawingId, attributes = {}) => {
  return {
    type: UPDATE_DRAWING,
    payload: {
      drawingId,
      attributes,
    },
  };
};

export const updateDrawingItems = (images, markers, highlights) => {
  // validate attributes
  return {
    type: UPDATE_DRAWING_ITEMS,
    payload: {
      images,
      markers,
      highlights,
    },
  };
};

export const sortDrawingsList = (drawingIds) => {
  return {
    type: SORT_DRAWINGS_LIST,
    payload: {
      drawingIds
    }
  };
};
