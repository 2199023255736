export default {
  ids: [
    'invention_milestone',
    'product_milestone',
    'possession_milestone',
    'enablement_milestone',
    'anticipation_milestone',
    'obviousness_milestone',
    'specification_milestone',
    'review_milestone',
    'file_milestone',
  ],
  entities: {
    invention_milestone: {
      id: 'invention_milestone',
      path: '/tutorial/milestones/invention-overview',
      title: 'Invention Overview',
    },
    product_milestone: {
      id: 'product_milestone',
      path: '/tutorial/milestones/prototype-details',
      title: 'Prototype Details',
    },
    possession_milestone: {
      id: 'possession_milestone',
      path: '/tutorial/milestones/requirements',
      title: 'Requirements',
    },
    enablement_milestone: {
      id: 'enablement_milestone',
      path: '/tutorial/milestones/solutions',
      title: 'Solutions',
    },
    anticipation_milestone: {
      id: 'anticipation_milestone',
      path: '/tutorial/milestones/novelty-anticipation',
      title: 'Novelty / Anticipation',
    },
    obviousness_milestone: {
      id: 'obviousness_milestone',
      path: '/tutorial/milestones/novelty-obviousness',
      title: 'Novelty / Obviousness',
    },
    specification_milestone: {
      id: 'specification_milestone',
      path: '/tutorial/milestones/specification',
      title: 'Final Review',
    },
    review_milestone: {
      id: 'review_milestone',
      path: '/tutorial/milestones/final-review',
      title: 'Final Review',
    },
    file_milestone: {
      id: 'file_milestone',
      path: '/tutorial/milestones/apply',
      title: 'Apply',
    },
  },
};

