import { action, computed } from '@ember/object';
import {
  selectElement,
  selectElementVersion,
} from '../../../actions/invention-ui';

import Component from '@glimmer/component';
import { connect } from 'ember-redux';
import { elementMentionInteractiveTemplate } from '../../../utils/mentions';
import { getElementOrdinal } from '../../../selectors/invention';
import { htmlSafe } from '@ember/string';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';

const dispatchToActions = {};

const stateToComputed = null;

class UiElementName extends Component {
  @service models;

  get classNames() {
    let classNames = ['ui-element-name', this.styleNamespace];
    return classNames.join(' ');
  }

  get styleNamespace() {
    return podNames['ui-element-name'];
  }

  @computed('args.elementId')
  get elementModel() {
    return this.args.elementId && this.models.find(this.args.elementId);
  }

  @computed('args.elementVersionId')
  get elementVersionModel() {
    return (
      this.args.elementVersionId && this.models.find(this.args.elementVersionId)
    );
  }

  @computed(
    'elementModel.{id,name}',
    'args.elementId',
    'elementVersionModel.id'
  )
  get elementMention() {
    const componentId = this.args.elementId;
    const mentionedId = this.elementModel.id;
    const index = 0;
    const name = this.elementModel.name;
    const uniqueId = `${componentId}-${mentionedId}-${index}`;
    const elemnentVersionId =
      this.elementVersionModel && this.elementVersionModel.id
        ? this.elementVersionModel.id
        : '';
    return htmlSafe(
      elementMentionInteractiveTemplate(
        mentionedId,
        elemnentVersionId,
        name,
        uniqueId
      )
    );
  }

  @computed('elementModel.name')
  get elementName() {
    return this.elementModel && this.elementModel.name;
  }

  @computed('elementModel.elementVersionsList.[]', 'elementVersionModel.name')
  get elementVersionName() {
    const elementVersionName =
      this.elementModel &&
      this.elementModel.elementVersionsList.length > 1 &&
      this.elementVersionModel &&
      this.elementVersionModel.name;
    return this.elementVersionModel && elementVersionName;
  }

  // eslint-disable-next-line ember/require-computed-property-dependencies
  @computed('args.{elementId,elementVersionId}')
  get ordinal() {
    const state = this.redux.getState();
    return getElementOrdinal(
      state,
      this.args.elementId,
      this.args.elementVersionId
    );
  }

  @action
  selectElementVersion() {
    this.redux.store.dispatch(selectElementVersion(this.args.elementVersionId));
  }

  @action
  selectElement() {
    this.redux.store.dispatch(selectElement(this.args.elementId));
  }
}

export default connect(stateToComputed, dispatchToActions)(UiElementName);
