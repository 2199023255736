import Component from '@glimmer/component';
import { action } from '@ember/object';
import { connect } from 'ember-redux';
import { getMilestoneType } from '../../../selectors/type';
import { getProduct } from '../../../selectors/product';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';

const dispatchToActions = {};

const stateToComputed = (state, attrs) => ({
  product: getProduct(state, attrs.productId),
});

class MilestoneReviewCheckbox extends Component {
  @service data;
  @service tracking;

  get styleNamespace() {
    return podNames['milestone-review-checkbox'];
  }

  get classNames() {
    let classNames = [this.styleNamespace, 'milestone-review-checkbox'];
    return classNames.join(' ');
  }

  get testId() {
    return `milestone-review-checkbox-${this.args.checklistId}`;
  }

  get milestoneType() {
    return this.args.milestoneTypeId && getMilestoneType(this.args.milestoneTypeId);
  }

  // 'market', 'patentability', 'possession', 'enablement', 'anticipation', 'obviousness'
  get label() {
    return this.milestoneType && this.milestoneType.reviewAssertion;
  }

  @action
  onChange() {
    let reviewedMilestonesList = (this.product && this.product.reviewedMilestonesList) || [];
    if (this.args.isChecked) {
      reviewedMilestonesList = reviewedMilestonesList.filter(
        (milestoneTypeId) => milestoneTypeId !== this.args.milestoneTypeId
      );
      this.args.onUncheck(
        this.args.productId,
        { reviewedMilestonesList }
      );
    } else {
      reviewedMilestonesList = [...reviewedMilestonesList, this.args.milestoneTypeId];
      this.args.onCheck(
        this.args.productId,
        { reviewedMilestonesList }
      );
      this.tracking.trackEvent(`reviewed_milestone_${this.args.milestoneTypeId}`);
    }
  }
}

export default connect(stateToComputed, dispatchToActions)(MilestoneReviewCheckbox);
