import Controller from '@ember/controller';

export default class InventionController extends Controller {
    queryParams = ['name', 'inspiration', 'description', 'assets', 'enableCloudBackups'];
    name = '';
    inspiration = '';
    description = '';
    assets = null;
    enableCloudBackups = false;
}
