import Component from '@glimmer/component';
import { action } from '@ember/object';
import { alias } from '@ember/object/computed';
import { connect } from 'ember-redux';
import { getFeature } from '../../../selectors/feature';
import podNames from 'ember-component-css/pod-names';

const dispatchToActions = {};

const stateToComputed = (state, attrs) => ({
  feature: getFeature(state, attrs.featureId),
});

class FeatureRealizationPreview extends Component {

  requirementTypeIds = ['constraint', 'functional_requirement'];

  get styleNamespace() {
    return podNames['feature-realization-preview'];
  }

  get classNames() {
    let classNames = [this.styleNamespace, 'feature-realization-preview'];
    return classNames.join(' ');
  }

  @alias('feature.element') elementId;
  @alias('feature.term') termId;
  @alias('feature.type') typeId;

  get language() {
    return this.requirementTypeIds.includes(this.typeId) ? 'requirement' : 'report_list';
  }

  @action
  onClose() {
    if (this.args.onClose) {
      this.args.onClose();
    }
  }
}

export default connect(
  stateToComputed,
  dispatchToActions
)(FeatureRealizationPreview);


