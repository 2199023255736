import {
  elementMentionInteractiveTemplate,
  figureMentionInteractiveTemplate,
  termMentionInteractiveTemplate,
} from '../utils/mentions';
import {
  getDeletedReference,
  getDeletedReferencesList,
} from './deleted-reference';
import { getElement, getElements } from './element';
import { getElementOrdinal, getTermOrdinal } from './invention';
import { getPreferredElementVersionId, getProduct } from './product';

import $ from 'jquery';
import { capitalizeFirstLetter } from '../utils/string';
import { getActiveProductId } from './invention-ui';
import { getComponent } from './component';
import { getElementVersion } from './element-version';
import { getRootNodeId } from './graph';
import { getTerms } from './term';
import plur from 'plur';

export const getMentionsContent = (
  state,
  content,
  componentId,
  {
    appendElementVersion = false,
    unwrapRealizationSpans = false,
    changeClass = '',
    removeBreaks = false,
    appendOrdinal = false,
    isInput = false,
    isInteractive = true,
    toLowerCase = false,
    novelFeaturesList = [],
    isClickable = true,
    isHoverable = true,
    isContextable = true,
    // stateIsInvention = false
  } = {}
) => {
  // return content;
  const rootNodeId = getRootNodeId(state);
  const activeProductId = getActiveProductId(state);
  const product = getProduct(state, activeProductId);
  const terms = getTerms(state);
  const elements = getElements(state);
  // const features = getFeatures(state);
  const $fakeDomElement = $(`<div>${content}</div>`);
  const $domMentions = $fakeDomElement.find(`.mention`);
  const deletedReferencesList = getDeletedReferencesList(state);

  if (unwrapRealizationSpans) {
    const $domRealizationSpans = $fakeDomElement.find(`.realization-content`);
    $domRealizationSpans.contents().unwrap();
  }

  if (removeBreaks) {
    $fakeDomElement.find('br').remove();
  }

  const $domFeatureRealizations = $fakeDomElement.find(`.realization-content`);
  const $domElementSteps = $fakeDomElement.find(
    `.realization-content[data-type='element_step']`
  );
  const $domCustomSteps = $fakeDomElement.find(
    `.realization-content[data-type='custom_step']`
  );
  const $domInverseSteps = $fakeDomElement.find(
    `.realization-content[data-type='inverse_step']`
  );

  $domFeatureRealizations.each((index, domElement) => {
    const featureId = domElement.dataset.modelId;
    // const feature = features[featureId];

    if (novelFeaturesList && novelFeaturesList.includes(featureId)) {
      domElement.dataset.novel = true;
      // domElement.dataset.noveltyAmount = feature.noveltyAmount;
    }
  });

  $domElementSteps.each((index, domElement) => {
    const stepId = domElement.dataset.modelId;
    const ordinal = getElementOrdinal(state, stepId);

    if (ordinal) {
      domElement.dataset.ordinal = ordinal;
      if (appendOrdinal) {
        $(domElement).append(` <span class='ordinal'>${ordinal}</span>`);
      }
    }
  });

  $domCustomSteps.each((index, domElement) => {
    const stepId = domElement.dataset.modelId;
    const ordinal = getElementOrdinal(state, stepId);

    if (ordinal) {
      domElement.dataset.ordinal = ordinal;
      if (appendOrdinal) {
        $(domElement).append(` <span class='ordinal'>${ordinal}</span>`);
      }
    }
  });

  $domInverseSteps.each((index, domElement) => {
    const stepId = domElement.dataset.modelId;
    const ordinal = getElementOrdinal(state, stepId);

    if (ordinal) {
      domElement.dataset.ordinal = ordinal;
      if (appendOrdinal) {
        $(domElement).append(` <span class='ordinal'>${ordinal}</span>`);
      }
    }
  });

  $domMentions.each((index, domElement) => {
    const mentionedId = domElement.dataset.id;
    const elementVersionId = domElement.dataset.elementVersionId;
    const capFirstLetter = domElement.dataset.capFirstLetter;
    const uniqueId = `${componentId}-${mentionedId}-${index}`;
    const plural = domElement.dataset.plural === 'true';

    let term;
    let _element;
    let ordinal;
    let isDeleted;
    let mentionedType = domElement.dataset.type;

    // was switched to term
    if (
      mentionedType === 'element' &&
      !elements[mentionedId] &&
      terms[mentionedId]
    ) {
      mentionedType = 'term';
    }

    // was switched to element
    if (
      mentionedType === 'term' &&
      !terms[mentionedId] &&
      elements[mentionedId]
    ) {
      mentionedType = 'element';
    }

    switch (mentionedType) {
      case 'element': {
        // if element isComponent
        // forEach instance,
        //   get the preferredEmbodiment for this product
        //   if (there is more than one)
        //     (prefV1, prefV2)
        //   if (there is none)
        //     (prefV1, prefV2)

        _element =
          elements[mentionedId] || getDeletedReference(state, mentionedId);

        if (!_element) {
          return;
        }

        const elementVersionIds = _element.elementVersionsList;

        let preferredElementVersionIds = [];

        // if element is component
        if (_element.component && _element.isComponent) {
          const component = getComponent(state, _element.component);
          component &&
            component.instancesList.forEach((instanceId) => {
              const preferredElementVersionId = getPreferredElementVersionId(
                state,
                instanceId,
                activeProductId
              );
              preferredElementVersionId &&
                preferredElementVersionIds.push(preferredElementVersionId);
            });
          preferredElementVersionIds = preferredElementVersionIds.uniq();
        } else {
          const preferredElementVersionId = getPreferredElementVersionId(
            state,
            mentionedId,
            activeProductId
          );
          if (preferredElementVersionId) {
            preferredElementVersionIds = [preferredElementVersionId];
          }
        }

        let name = _element.name || '';

        // if element is instance
        if (_element.component && _element.instanceOf) {
          const instanceOf = getElement(state, _element.instanceOf);
          name = instanceOf && instanceOf.name;
        }

        // if element is instance
        if (product && _element.id === rootNodeId) {
          name = product.name;
        }

        if (toLowerCase) {
          name = name.toLowerCase();
        }

        if (plural) {
          name = plur(name);
        }

        domElement.setAttribute('data-name', name);
        domElement.setAttribute('data-type', 'element');

        if (capFirstLetter) {
          name = capitalizeFirstLetter(name);
        }

        if (
          deletedReferencesList &&
          deletedReferencesList.includes(mentionedId)
        ) {
          isDeleted = true;
          domElement.setAttribute('data-deleted', true);
        }

        // update name
        if (isInteractive && !isDeleted) {
          domElement.innerHTML = elementMentionInteractiveTemplate(
            _element.id,
            elementVersionId,
            name,
            uniqueId,
            isClickable,
            isHoverable,
            isContextable
          );
        } else {
          domElement.innerHTML = name;
        }

        // add incoming edge type
        // domElement.dataset.incomingEdgeType = element.get('incomingEdgeTypeId');
        ordinal = getElementOrdinal(state, _element.id, elementVersionId);

        if (ordinal) {
          domElement.dataset.ordinal = ordinal;
          if (appendOrdinal) {
            $(domElement).append(` <span class='ordinal'>${ordinal}</span>`);
          }
        }

        if (
          appendElementVersion &&
          activeProductId &&
          preferredElementVersionIds.length &&
          elementVersionIds.length > 1
        ) {
          const elementVersionNameSpansArray = preferredElementVersionIds.map(
            (elementVersionId) => {
              const elementVersion = getElementVersion(state, elementVersionId);
              return (
                elementVersion &&
                `<span class='mention-element-version' data-id="${elementVersionId}">${elementVersion.name}</span>`
              );
            }
          );
          if (elementVersionNameSpansArray.length) {
            $(domElement).append(
              ` <span class='mention-element-versions'>(${elementVersionNameSpansArray.join(
                ' ,'
              )})</span>`
            );
          }
        }

        break;
      }
      case 'term': {
        term = terms[mentionedId] || getDeletedReference(state, mentionedId);

        if (!term) {
          return;
        }

        let name = term.name || '';

        if (toLowerCase) {
          name = name.toLowerCase();
        }

        domElement.setAttribute('data-name', name);
        domElement.setAttribute('data-type', 'term');

        if (capFirstLetter) {
          name = capitalizeFirstLetter(name);
        }

        if (
          deletedReferencesList &&
          deletedReferencesList.includes(mentionedId)
        ) {
          isDeleted = true;
          domElement.setAttribute('data-deleted', true);
        }

        // update name
        if (isInteractive && !isDeleted) {
          domElement.innerHTML = termMentionInteractiveTemplate(
            term.id,
            name,
            uniqueId,
            isClickable,
            isHoverable,
            isContextable
          );
        } else {
          domElement.innerHTML = name;
        }

        // add incoming edge type
        // domElement.dataset.incomingEdgeType = element.get('incomingEdgeTypeId');
        ordinal = getTermOrdinal(state, term.id);

        if (ordinal) {
          domElement.dataset.ordinal = ordinal;
          if (appendOrdinal) {
            $(domElement).append(` <span class='ordinal'>${ordinal}</span>`);
          }
        }
        break;
      }
      case 'method': 
      case 'drawing': {
        let name = domElement.dataset.name;
        // update name
        if (isInteractive) {
          domElement.innerHTML = figureMentionInteractiveTemplate(
            mentionedId,
            mentionedType,
            name,
            uniqueId,
            isClickable,
            isHoverable,
            isContextable
          );
        } 

        break;
      }
    }

    if (isInput) {
      domElement.setAttribute('contenteditable', false);
      domElement.setAttribute('readonly', true);
    }

    if (changeClass) {
      domElement.setAttribute('class', changeClass);
    }
  });

  return $fakeDomElement.html();
};

// export const replaceMentionsContent = ({
//     state,
//     selector,
//     componentId,
//     unwrapRealizationSpans = false,
//     changeClass = '',
//     removeBreaks = false,
//     appendOrdinal = false,
//     isInput = false,
//     isInteractive = true,
//   }) => {
//   const elements = get(getPresentState(state), `elements`);
//   const features = get(getPresentState(state), `features`);
//   const $fakeDomElement = $(`${selector}`);
//   const $domMentions = $fakeDomElement.find(`.mention`);
//   const deletedReferencesList = getDeletedReferencesList(state);

//   if (unwrapRealizationSpans) {
//     const $domRealizationSpans = $fakeDomElement.find(`.realization-content`);
//     $domRealizationSpans.contents().unwrap();
//   }

//   if (removeBreaks) {
//     $fakeDomElement.find('br').remove();
//   }

//   const $domFeatureRealizations = $fakeDomElement.find(`.realization-content`);
//   const $domElementSteps = $fakeDomElement.find(`.realization-content[data-type='element_step']`);
//   const $domCustomSteps = $fakeDomElement.find(`.realization-content[data-type='custom_step']`);
//   const $domInverseSteps = $fakeDomElement.find(`.realization-content[data-type='inverse_step']`);

//   $domFeatureRealizations.each((index, domElement) => {
//     const featureId = domElement.dataset.modelId;
//     const feature = features[featureId];

//     if (feature && feature.novel) {
//       domElement.dataset.novel = true;
//       domElement.dataset.noveltyAmount = feature.noveltyAmount;
//     }
//   });

//   $domElementSteps.each((index, domElement) => {
//     const stepId = domElement.dataset.modelId;
//     const ordinal = getElementOrdinal(state, stepId);

//     if (ordinal) {
//       domElement.dataset.ordinal = ordinal;
//       if (appendOrdinal) {
//         $(domElement).append(` <span class='ordinal'>${ordinal}</span>`);
//       }
//     }
//   });

//   $domCustomSteps.each((index, domElement) => {
//     const stepId = domElement.dataset.modelId;
//     const ordinal = getElementOrdinal(state, stepId);

//     if (ordinal) {
//       domElement.dataset.ordinal = ordinal;
//       if (appendOrdinal) {
//         $(domElement).append(` <span class='ordinal'>${ordinal}</span>`);
//       }
//     }
//   });

//   $domInverseSteps.each((index, domElement) => {
//     const stepId = domElement.dataset.modelId;
//     const ordinal = getElementOrdinal(state, stepId);

//     if (ordinal) {
//       domElement.dataset.ordinal = ordinal;
//       if (appendOrdinal) {
//         $(domElement).append(` <span class='ordinal'>${ordinal}</span>`);
//       }
//     }
//   });

//   $domMentions.each((index, domElement) => {
//     const mentionedType = domElement.dataset.type;
//     const mentionedId = domElement.dataset.id;
//     const mentionedElementVersionId = domElement.dataset.elementVersionId;
//     const uniqueId = `${componentId}-${mentionedId}-${index}`;
//     let _element;
//     let ordinal;

//     switch (mentionedType) {
//       case 'element':
//         _element = elements[mentionedId];

//         if (!_element) {
//           return;
//         }

//         domElement.setAttribute('data-name', _element.name);

//         if (deletedReferencesList && deletedReferencesList.includes(mentionedId)) {
//           domElement.setAttribute('data-deleted', true);
//         }

//         // update name
//         if (isInteractive) {
//           domElement.innerHTML = elementMentionInteractiveTemplate(_element.id, mentionedElementVersionId, _element.name, uniqueId);
//         } else {
//           domElement.innerHTML = _element.name;
//         }

//         // add incoming edge type
//         // domElement.dataset.incomingEdgeType = element.get('incomingEdgeTypeId');
//         ordinal = getElementOrdinal(state, _element.id, mentionedElementVersionId);

//         if (ordinal) {
//           domElement.dataset.ordinal = ordinal;
//           if (appendOrdinal) {
//             $(domElement).append(` <span class='ordinal'>${ordinal}</span>`);
//           }
//         }
//         break;
//     }

//     if (isInput) {
//       domElement.setAttribute('contenteditable', false);
//       domElement.setAttribute('readonly', true);
//     }

//     if (changeClass) {
//       domElement.setAttribute('class', changeClass);
//     }
//   });

//   // return $fakeDomElement.html();
// };
