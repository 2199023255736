import { action, computed } from '@ember/object';
import {
  getElement,
  getElementName,
  getElementVersionsList,
} from '../../../selectors/element';
import {
  getPreferredElementVersion,
  getProduct,
} from '../../../selectors/product';

import Component from '@glimmer/component';
import { connect } from 'ember-redux';
import { getElementVersion } from '../../../selectors/element-version';
import { htmlSafe } from '@ember/string';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import uuid from 'uuid/v4';

const dispatchToActions = {};

const stateToComputed = (state, attrs) => ({
  product: getProduct(state, attrs.productId),
  parent: getElement(state, attrs.parent),
  element: getElement(state, attrs._elementId),
  elementName: getElementName(state, attrs._elementId),
  elementVersionsList: getElementVersionsList(state, attrs._elementId),
  elementVersion: getPreferredElementVersion(
    state,
    attrs._elementId,
    attrs.productId
  ),
  // rootNodeId: getRootNodeId(state),
});

class ElementListItem extends Component {
  @service redux;
  @tracked classNames;

  _classNames = '';
  clickCount = 0;

  depthPaddingWidth = 25;

  domElementId = `element-list-item-${uuid()}`;

  get styleNamespace() {
    return podNames['element-list-item'];
  }

  // constructor(owner, args) {
  //   super(owner, args);
  //   this.updateClassNames();
  // }
  
  getClassNames() {
    let classNames = [this.styleNamespace, 'element-list-item'];
    if (this.args.isSelected) classNames.push('is-selected');
    if (this.args.isSelectedDescendant)
      classNames.push('is-selected-descendant');
    if (this.args.isSelectedChild) classNames.push('is-selected-child');
    if (this.args.isCollapsed) classNames.push('is-collapsed');
    if (this.args.showNovelty) classNames.push('show-novelty');
    if (this.args.isNovel) classNames.push('is-novel');
    if (this.args.showNovelty && !this.args.isNovel)
      classNames.push('isnt-novel');
    if (this.args.isTotallyNovel) classNames.push('is-totally-novel');
    if (this.args.isDragging) classNames.push('is-dragging');
    if (this.args.isDragged) classNames.push('is-dragged');
    if (this.args.isDraggedOver) classNames.push('is-dragged-over');
    if (this.isInstance) classNames.push('is-component-instance');
    if (this.isPrimaryInstance)
      classNames.push('is-primary-component-instance');
    if (this.isTopLevel) classNames.push('is-top-level');
    if (this.canDropTop) classNames.push('is-dragged-over-top');
    if (this.canDropMiddle) classNames.push('is-dragged-over-middle');
    if (this.canDropBottom) classNames.push('is-dragged-over-bottom');
    if (this.isProduct) classNames.push('is-product');

    return classNames.join(' ');
  }

  updateClassNames() {
    const classNames = this.getClassNames();
    if (this._classNames !== classNames) {
      this._classNames = classNames;
      this.classNames = classNames;
    }
  }

  @action
  onUpdate() {
    this.updateClassNames();
  }

  get canDropTop() {
    return (
      this.args.isDragging &&
      this.args.isDraggedOver &&
      this.args.draggedOverArea === 'top' &&
      !this.args.isDraggedDescendant
    );
  }

  get canDropMiddle() {
    return (
      this.args.isDragging &&
      this.args.isDraggedOver &&
      this.args.draggedOverArea === 'middle' &&
      !this.args.isDraggedParent &&
      !this.args.isDraggedDescendant &&
      !this.args.isDragged
    );
  }

  get canDropBottom() {
    const hasChildren = this.args.children && this.args.children.length;
    const hasVisibleChildren = hasChildren && !this.args.isCollapsed;
    return (
      this.args.isDragging &&
      this.args.isDraggedOver &&
      this.args.draggedOverArea === 'bottom' &&
      !this.args.isDraggedDescendant &&
      !hasVisibleChildren
    );
  }

  @action
  didInsert() {
    // add dragover events
    this.updateClassNames();
    const domElement = document.getElementById(this.domElementId);

    if (domElement && this.args.isDraggable) {
      const dragoverTopElement = domElement.querySelector('.item-dragover-top');
      this._handleMouseenterTop = this.handleMouseenterTop.bind(this);
      dragoverTopElement.addEventListener(
        'mouseenter',
        this._handleMouseenterTop
      );

      const dragoverMiddleElement = domElement.querySelector(
        '.item-dragover-middle'
      );
      this._handleMouseenterMiddle = this.handleMouseenterMiddle.bind(this);
      dragoverMiddleElement.addEventListener(
        'mouseenter',
        this._handleMouseenterMiddle
      );

      const dragoverBottomElement = domElement.querySelector(
        '.item-dragover-bottom'
      );
      this._handleMouseenterBottom = this.handleMouseenterBottom.bind(this);
      dragoverBottomElement.addEventListener(
        'mouseenter',
        this._handleMouseenterBottom
      );
    }
  }

  @action
  willDestroyNode() {
    const domElement = document.getElementById(this.domElementId);
    if (domElement && this.args.isDraggable) {
      // remove dragover events
      const dragoverTopElement = domElement.querySelector('.item-dragover-top');
      dragoverTopElement.removeEventListener(
        'mouseenter',
        this._handleMouseenterTop
      );
      const dragoverBottomElement = domElement.querySelector(
        '.item-dragover-bottom'
      );
      dragoverBottomElement.removeEventListener(
        'mouseenter',
        this._handleMouseenterBottom
      );
      const dragoverMiddleElement = domElement.querySelector(
        '.item-dragover-middle'
      );
      dragoverMiddleElement.removeEventListener(
        'mouseenter',
        this._handleMouseenterMiddle
      );
    }
  }

  handleMouseenterTop(event) {
    const area = 'top';
    this.args.onDragenter(event, 'element', this.args._elementId, area);
  }

  handleMouseenterMiddle(event) {
    const area = 'middle';
    this.args.onDragenter(event, 'element', this.args._elementId, area);
  }

  handleMouseenterBottom(event) {
    const area = 'bottom';
    this.args.onDragenter(event, 'element', this.args._elementId, area);
  }

  @computed('args.depth')
  get depth() {
    return this.args.depth || 1;
  }

  @computed('depth', 'depthPaddingWidth')
  get depthPadding() {
    return (this.depth - 1) * this.depthPaddingWidth;
  }

  @computed('depthPadding')
  get depthStyle() {
    return htmlSafe(`margin-left: ${this.depthPadding}px`);
  }

  @computed('depthPadding')
  get dragoverDepthStyle() {
    return htmlSafe(`margin-left: ${this.depthPadding + 24}px`);
  }

  @computed('args.children.[]')
  get hasChildren() {
    return this.args.children && this.args.children.length;
  }

  @computed('args.isCollapsible', 'hasChildren')
  get showCaret() {
    return this.args.isCollapsible && this.hasChildren;
  }

  @computed('isProduct', 'elementName', 'product.name')
  get name() {
    return this.isProduct
      ? this.product && this.product.name
      : this.elementName;
  }

  @computed('isProduct', 'elementVersionsList.[]')
  get showElementVersionName() {
    return !this.isProduct && this.elementVersionsList.length > 1;
  }

  @computed('parent.category')
  get isTopLevel() {
    return this.parent && this.parent.category === 'product';
  }

  @computed('element.{component,instanceOf}')
  get isInstance() {
    return this.element && this.element.component && this.element.instanceOf
      ? true
      : false;
  }

  @computed('element.category')
  get isProduct() {
    return this.element && this.element.category === 'product';
  }

  @computed('element.category')
  get isPart() {
    return this.element && this.element.category === 'part';
  }

  @computed('element.category')
  get isSystem() {
    return this.element && this.element.category === 'system';
  }

  @computed('args.{_elementId,primaryInstancesList.[]}')
  get isPrimaryInstance() {
    return (
      this.args.primaryInstancesList &&
      this.args.primaryInstancesList.includes(this.args._elementId)
    );
  }

  @computed('element.elementVersion')
  get parentElementVersion() {
    const state = this.redux.getState();
    return (
      this.element &&
      this.element.elementVersion &&
      getElementVersion(state, this.element.elementVersion)
    );
  }

  @computed('elementVersion.name')
  get elementVersionName() {
    return this.elementVersion && this.elementVersion.name;
  }

  @action
  onContextMenu(event) {
    if (this.args.onContextMenu) {
      this.args.onContextMenu(event, this.args._elementId);
    }
  }

  @action
  onMousedown(event) {
    if (this.args.onMousedown) {
      this.args.onMousedown(event, this.args._elementId, 'element');
    }
  }

  @action
  onClick() {
    this.clickCount++;

    if (this.args.onClick) {
      this.args.onClick(this.args._elementId, this.args.isSelected);
    }
    setTimeout(() => {
      if (this.clickCount === 2) {
        if (this.args.onDoubleClick) {
          this.args.onDoubleClick(this.args._elementId);
        }
      }
      this.clickCount = 0;
    }, 250);
  }

  @action
  onCollapseClick() {
    if (this.args.onCollapseClick) {
      this.args.onCollapseClick(this.args._elementId);
    }
  }
}

export default connect(stateToComputed, dispatchToActions)(ElementListItem);
