import Component from '@glimmer/component';
import podNames from 'ember-component-css/pod-names';

class UiContextSectionDivider extends Component {

  get classNames() {
    let classNames = [this.styleNamespace];
    return classNames.join(' ');
  }

  get styleNamespace() {
    return podNames['ui-context-section-divider'];
  }

}

export default UiContextSectionDivider;
