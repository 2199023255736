import Component from '@glimmer/component';
import { action } from '@ember/object';
import podNames from 'ember-component-css/pod-names';

class MilestoneListItem extends Component {
  // label
  // isCollapsible
  // isCollapsed
  // isDisabled
  // isChecked
  // onToggleCollapsed

  get classNames() {
    let classNames = [this.styleNamespace, 'milestone-list-item'];
    if (this.args.hasBorder) classNames.push('has-border');
    if (this.args.isActive) classNames.push('is-active');
    if (this.isLocked) classNames.push('is-locked');
    return classNames.join(' ');
  }

  get styleNamespace() {
    return podNames['milestone-list-item'];
  }

  get isLocked() {
    let isLocked = true;

    if (!this.args.prerequisite) {
      isLocked = false;
    }

    if (
      this.args.prerequisite &&
      this.args.assertedMilestonesList.includes(this.args.prerequisite)
    ) {
      isLocked = false;
    }

    if (this.args.isChecked) {
      isLocked = false;
    }

    return isLocked;
  }

  @action
  onClick() {
    this.args.onClick(this.args.milestoneTypeId);
  }
}

export default MilestoneListItem;
