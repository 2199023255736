import {
  ADD_METHOD_EDGE_POINT,
  UPDATE_METHOD_EDGE_POINT,
  REMOVE_METHOD_EDGE_POINT,
} from '../actions/actionTypes';
import { addFromSchema } from '../utils/schema';
import { omit } from 'lodash';

const initialState = {
  ids: [],
  entities: {},
};

const methodEdgePointsReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {

    case ADD_METHOD_EDGE_POINT: {
      let { methodEdgePointId, attributes } = payload;
      const id = methodEdgePointId;
      const createdAt = new Date();
      const updatedAt = new Date();
      const methodEdgePoint = addFromSchema('method-edge-point', {
        id,
        createdAt,
        updatedAt,
        ...attributes,
      });

      return {
        ids: [...state.ids, id],
        entities: {
          ...state.entities,
          [methodEdgePointId]: methodEdgePoint,
        },
      };
    }

    case UPDATE_METHOD_EDGE_POINT: {
      const { methodEdgePointId, attributes } = payload;
      const updatedAt = new Date();
      return {
        ...state,
        entities: {
          ...state.entities,
          [methodEdgePointId]: {
            ...state.entities[methodEdgePointId],
            ...attributes,
            updatedAt,
          },
        },
      };
    }

    case REMOVE_METHOD_EDGE_POINT: {
      const { methodEdgePointId } = payload;
      return {
        ids: state.ids.filter((id) => id !== methodEdgePointId),
        entities: omit(state.entities, methodEdgePointId),
      };
    }

    /* istanbul ignore next */
    default:
      return state;
  }
};

export default methodEdgePointsReducer;
