import Component from '@ember/component';
import { computed } from "@ember/object";

export default Component.extend({
  classNames: ['checkbox'],
  classNameBindings: ['noLabel', 'isNovel', 'isRadio', 'checked'],
  size: 'normal',
  align: 'left',
  checked: true,
  testId: '',
  isCircle: false,
  isRadio: false,
  disabled: false,
  type: 'checkbox',
  isNovel: false,

  noLabel: computed.not('label'),

  checkboxId: computed('elementId', function () {
    return `${this.elementId}-checkbox`;
  }),

  sizeClass: computed('size', function () {
    return `is-${this.size}`;
  }),

  classes: computed('align', 'isCircle', 'size', 'sizeClass', function () {
    const align  = this.align === 'right' ? 'is-rtl' : '';
    const size  = this.sizeClass;
    const shape  = this.isCircle ? 'is-circle' : '';
    return `is-checkradio ${size} ${align} ${shape} has-background-color`;
  }),

  actions: {
    onChange() {
      if (this.onChange) {
        this.onChange(this.checked);
      }
    }
  }
});
