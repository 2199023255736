import {
  ADD_METHOD_NODE,
  UPDATE_METHOD_NODE,
  UPDATE_METHOD_NODE_ELEMENT,
  REMOVE_METHOD_NODE
} from './actionTypes';

import uuid from 'uuid/v4';

export const updateMethodNodeElement = (
  methodId,
  methodNodeId,
  elementId,
  elementVersionId,
  stepId,
  inverseStepId
) => {
  return {
    type: UPDATE_METHOD_NODE_ELEMENT,
    payload: {
      methodNodeId,
      methodId,
      elementId,
      elementVersionId,
      stepId,
      inverseStepId
    }
  };
};

export const addMethodNode = (
  attributes,
  methodNodeId = uuid()
) => {

  return {
    type: ADD_METHOD_NODE,
    payload: {
      attributes,
      methodNodeId
    }
  };
};

export const updateMethodNode = (methodNodeId, attributes) => {
  return {
    type: UPDATE_METHOD_NODE,
    payload: {
      methodNodeId,
      attributes
    }
  };
};



export const removeMethodNode = (methodNodeId) => {
  return {
    type: REMOVE_METHOD_NODE,
    payload: {
      methodNodeId
    },
  };
};