import {
  ADD_ELEMENT,
  ADD_REQUIREMENT,
  UPDATE_ELEMENT,
  UPDATE_ELEMENT_AND_ELEMENT_VERSION,
  UPDATE_NODE_COORDINATES,
  REMOVE_ELEMENT,
  REMOVE_REQUIREMENT,
} from '../actions/actionTypes';
import { addFromSchema } from '../utils/schema';
import { forEach, omit } from 'lodash';

const initialState = {
  ids: [],
  entities: {},
};

const elementsReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case ADD_ELEMENT: {
      let { elementId, attributes } = payload;
      const id = elementId;
      const createdAt = new Date();
      const updatedAt = new Date();
      const element = addFromSchema('element', {
        id,
        createdAt,
        updatedAt,
        ...attributes,
      });

      return {
        ids: [...state.ids, id],
        entities: {
          ...state.entities,
          [elementId]: element,
        },
      };
    }

    case ADD_REQUIREMENT: {
      const { elementId, requirementId } = payload;
      const updatedAt = new Date();
      return {
        ...state,
        entities: {
          ...state.entities,
          [elementId]: {
            ...state.entities[elementId],
            requirementsList: [
              ...state.entities[elementId].requirementsList,
              requirementId,
            ],
            updatedAt,
          },
        },
      };
    }

    case REMOVE_REQUIREMENT: {
      const { elementId, requirementId } = payload;
      const updatedAt = new Date();
      return {
        ...state,
        entities: {
          ...state.entities,
          [elementId]: {
            ...state.entities[elementId],
            requirementsList: state.entities[elementId].requirementsList.filter(
              (id) => id !== requirementId
            ),
            updatedAt,
          },
        },
      };
    }

    case UPDATE_ELEMENT: {
      const { elementId, attributes } = payload;
      const updatedAt = new Date();
      return {
        ...state,
        entities: {
          ...state.entities,
          [elementId]: {
            ...state.entities[elementId],
            ...attributes,
            updatedAt,
          },
        },
      };
    }

    case UPDATE_ELEMENT_AND_ELEMENT_VERSION: {
      const { elementId, elementAttributes } = payload;
      const updatedAt = new Date();
      return {
        ...state,
        entities: {
          ...state.entities,
          [elementId]: {
            ...state.entities[elementId],
            ...elementAttributes,
            updatedAt,
          },
        },
      };
    }

    case UPDATE_NODE_COORDINATES: {
      const { elements } = payload;

      const entities = {
        ...state.entities,
      };

      const updatedAt = new Date();

      forEach(elements, (element) => {
        entities[element.id] = {
          ...entities[element.id],
          x: element.x,
          y: element.y,
          updatedAt,
        };
      });

      return {
        ...state,
        entities,
      };
    }

    case REMOVE_ELEMENT: {
      const { elementId } = payload;
      return {
        ids: state.ids.filter((id) => id !== elementId),
        entities: omit(state.entities, elementId),
      };
    }

    /* istanbul ignore next */
    default:
      return state;
  }
};

export default elementsReducer;
