import {
  getActiveElementVersionListItem,
  getShowingElementVersionSelector,
} from '../../../selectors/invention-ui';
import { getComponent, getComponentInstanceOf } from '../../../selectors/component';
import {
  selectElement,
  selectElementAndElementVersion,
} from '../../../actions/invention-ui';

import Component from '@glimmer/component';
import { action } from '@ember/object';
import { computed } from '@ember/object';
import { connect } from 'ember-redux';
import { getContentOverflowed } from '../../../utils/general';
import { getElement } from '../../../selectors/element';
import { getPreferredElementVersionId } from '../../../selectors/product';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { updateElement } from '../../../actions/element';

const dispatchToActions = {};

const stateToComputed = (state, attrs) => ({
  element: getElement(state, attrs.elementId),
  instanceOf: getComponentInstanceOf(state, attrs.componentId),
  activeElementVersionListItem: getActiveElementVersionListItem(state),
  showingElementVersionSelector: getShowingElementVersionSelector(state),
  preferredElementVersionId: getPreferredElementVersionId(
    state,
    attrs.elementId,
    attrs.productId
  ),
});

class ElementContext extends Component {
  @service tracking;
  @tracked isOverflowed = false;

  get styleNamespace() {
    return podNames['element-instance-context'];
  }

  get classNames() {
    let classNames = [this.styleNamespace, 'element-instance-context'];
    if (this.isOverflowed) classNames.push('is-overflowed');
    return classNames.join(' ');
  }

  @computed('args.isPrimaryInstance')
  get isntPrimaryInstance() {
    return !this.args.isPrimaryInstance;
  }

  @computed('instanceOf.elementVersionsList.[]')
  get elementVersionsList() {
    return this.instanceOf && this.instanceOf.elementVersionsList;
  }

  @computed('elementVersionsList.[]')
  get canRemoveElementVersion() {
    return this.elementVersionsList && this.elementVersionsList.length > 1;
  }

  @computed('element.useDefaultRequirements')
  get useDefaultRequirements() {
    return this.element && this.element.useDefaultRequirements;
  }

  @computed('element.useDefaultOutcome')
  get useDefaultOutcome() {
    return this.element && this.element.useDefaultOutcome;
  }

  @action
  onContentResize(sb) {
    this.isOverflowed = getContentOverflowed(sb);
  }

  @action
  removeElementVersion() {
    if (
      this.activeElementVersionListItem &&
      this.args.onRemoveSelectedTreeItems
    ) {
      this.args.onRemoveSelectedTreeItems(
        [],
        [this.activeElementVersionListItem],
        []
      );
      this.tracking.trackEvent('instance_context_removed_solution');
    }
  }

  @action
  createElementVersion() {
    if (this.args.onCreateElementVersion) {
      this.args.onCreateElementVersion(this.args.elementId);
      this.tracking.trackEvent('instance_context_added_solution');
    }
  }

  @action
  toggleUseDefaultRequirements() {
    const useDefaultRequirements = !this.useDefaultRequirements;
    this.redux.store.dispatch(
      updateElement(this.args.elementId, {
        useDefaultRequirements,
      })
    );

    if (useDefaultRequirements) {
      this.tracking.trackEvent('instance_context_use_default_requirements');
    } else {
      this.tracking.trackEvent('instance_context_use_custom_requirements');
    }
  }

  @action
  toggleUseDefaultOutcome() {
    const useDefaultOutcome = !this.useDefaultOutcome;

    this.redux.store.dispatch(
      updateElement(this.args.elementId, {
        useDefaultOutcome,
      })
    );



    if (useDefaultOutcome) {
      this.tracking.trackEvent('instance_context_use_default_success_event');
    } else {
      this.tracking.trackEvent('instance_context_use_custom_success_event');
    }
  }

  @action
  onViewMainInstance() {
    if (this.args.onZoomToNode) {
      const state = this.redux.getState();
      const component = getComponent(state, this.element.component);
      const primaryInstanceId = component.primaryInstance;

      this.redux.store.dispatch(selectElement(primaryInstanceId, false));

      // this.args.onZoomToNode(primaryInstanceId);
    }
  }

  @action
  onSelectElementVersion(elementVersionId) {
    if (this.args.productId) {
      this.args.onSetPreferredElementVersion(
        this.args.elementId,
        elementVersionId
      );
    } else {
      this.redux.store.dispatch(
        selectElementAndElementVersion(this.args.elementId, elementVersionId)
      );
    }
  }
}

export default connect(stateToComputed, dispatchToActions)(ElementContext);
