import { ADD_DELETED_REFERENCE, REMOVE_DELETED_REFERENCE } from '../actions/actionTypes';
import { omit } from 'lodash';

const initialState = {
  ids: [],
  entities: {},
};

const deletedReferencesReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case REMOVE_DELETED_REFERENCE: {
      let { referenceId } = payload;

      return {
        ids: state.ids.filter((id) => id !== referenceId),
        entities: omit(state.entities, referenceId),
      };
    }

    case ADD_DELETED_REFERENCE: {
      let { referenceId, attributes } = payload;
      const id = referenceId;
      const deletedAt = new Date();

      const deletedReference = {
        id,
        ...attributes,
        deletedAt,
      };

      return {
        ids: [...state.ids, id],
        entities: {
          ...state.entities,
          [id]: deletedReference,
        },
      };
    }

    /* istanbul ignore next */
    default:
      return state;
  }
};

export default deletedReferencesReducer;
