import Component from '@glimmer/component';
import { alias } from '@ember/object/computed';
import { connect } from 'ember-redux';
import { getPreferredElementVersion } from '../../../selectors/product';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';

const stateToComputed = (state, attrs) => ({
  elementVersion: getPreferredElementVersion(
    state,
    attrs.elementId,
    attrs.productId
  ),
});

const dispatchToActions = {};

class ElementMarkerReferences extends Component {
  @service redux;

  get styleNamespace() {
    return podNames['element-marker-references'];
  }

  get classNames() {
    let classNames = [this.styleNamespace, 'element-marker-references'];
    return classNames.join(' ');
  }

  @alias('elementVersion.id')
  elementVersionId;

  @alias('elementVersion.markersList')
  markersList;

}

export default connect(
  stateToComputed,
  dispatchToActions
)(ElementMarkerReferences);
