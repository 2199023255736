import { action, computed } from '@ember/object';

import Component from '@glimmer/component';
import { connect } from 'ember-redux';
import { getActiveFeature } from '../../../selectors/invention-ui';
import { getElementVersion } from '../../../selectors/element-version';
import { getFeature } from '../../../selectors/feature';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';

const dispatchToActions = {};

const stateToComputed = (state, attrs) => ({
  elementVersion: getElementVersion(state, attrs.elementVersionId),
  activeFeatureId: getActiveFeature(state),
});

class ElementVersionContextComprisesFeatures extends Component {
  @service tracking;
  @service redux;

  get classNames() {
    let classNames = [
      'element-version-context-comprises-features',
      this.styleNamespace,
    ];
    return classNames.join(' ');
  }

  get styleNamespace() {
    return podNames['element-version-context-comprises-features'];
  }

  @computed('elementVersion.comprisesList')
  get comprisesList() {
    return this.elementVersion && this.elementVersion.comprisesList;
  }

  @computed('activeFeatureId', 'comprisesList.[]')
  get canRemoveElement() {
    return (
      this.comprisesList && this.comprisesList.includes(this.activeFeatureId)
    );
  }

  @action
  createElement() {
    if (this.args.onCreateElement) {
      this.args.onCreateElement(
        this.args.elementVersionId,
        undefined,
        undefined,
        true
      );
      this.tracking.trackEvent('solution_context_added_system');
    }
  }

  @action
  removeElement() {
    const state = this.redux.getState();
    const activeFeature = getFeature(state, this.activeFeatureId);
    if (this.canRemoveElement && activeFeature) {
      const elementId = activeFeature.value;
      if (this.args.onRemoveSelectedTreeItems) {
        this.tracking.trackEvent('solution_context_removed_system');

        this.args.onRemoveSelectedTreeItems([elementId], [], []);
      }
    }
  }

  @action
  onSortComprisesList(comprisesList) {
    if (this.args.onSortComprisesList) {
      this.args.onSortComprisesList(this.args.elementVersionId, comprisesList)
    }
  }
}

export default connect(
  stateToComputed,
  dispatchToActions
)(ElementVersionContextComprisesFeatures);
