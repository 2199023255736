import $ from 'jquery';
import Component from '@ember/component';
import { and } from '@ember/object/computed';

const UiListItem = Component.extend({
  classNames: ['list-item'],
  classNameBindings: ['isInteractive', 'autoHeight', 'isElementVersion', 'isSelected:is-active'],
  isInteractive: true,
  autoHeight: false,
  isActive: false,
  isHoverable: false,
  isHovering: true,
  isElementVersion: false,
  isSelected: and('isActive', 'isInteractive'),

  didInsertElement() {
    this._super(...arguments);
    this.addEventListeners();
  },

  willDestroyElement() {
    this._super(...arguments);
    this.removeEventListeners();
  },

  // onMouseEnter() {
  //   if (!this.isHoverable) return;
  //   this.set('isHovering', true);
  // },

  // onMouseLeave() {
  //   if (!this.isHoverable) return;
  //   // this.set('isHovering', false);
  // },

  addEventListeners() {
    // if (this.isHoverable) {
    //   $(`#${this.elementId}`).on('mouseenter', () => this.onMouseEnter());
    //   $(`#${this.elementId}`).on('mouseleave', () => this.onMouseLeave());
    // }
    if (this.onClick) {
      $(`#${this.elementId} .clickable`).on('click', (event) => this.onClick(event))
    }
    if (this.onDoubleClick) {
      $(`#${this.elementId} .clickable`).on('dblclick', (event) => this.onDoubleClick(event))
    }
  },

  removeEventListeners() {
    if (this.isHoverable) {
      $(`#${this.elementId}`).off('mouseenter');
      $(`#${this.elementId}`).off('mouseleave');
    }
    if (this.onClick) {
      $(`#${this.elementId} .clickable`).off('click')
    }
    if (this.onDoubleClick) {
      $(`#${this.elementId} .clickable`).off('dblclick')
    }
  }
});

export default UiListItem;
