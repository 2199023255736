import Component from '@glimmer/component';
import { computed } from '@ember/object';
import { connect } from 'ember-redux';
import { getActiveProduct } from '../../../selectors/invention-ui';
import podNames from 'ember-component-css/pod-names';

const dispatchToActions = {};

const stateToComputed = (state) => ({
  product: getActiveProduct(state)
});

class ProductName extends Component {

  get styleNamespace() {
    return podNames['product-name'];
  }

  get classNames() {
    let classNames = [this.styleNamespace];
    return classNames.join(' ');
  }

  @computed('product.name')
  get name() {
    return this.product && this.product.name || 'All Products'
  }
}

export default connect(
  stateToComputed,
  dispatchToActions
)(ProductName);
