import { action, computed } from '@ember/object';
import { alias, bool } from '@ember/object/computed';
import { getTermRealization, termMentionContentTemplate } from '../../../utils/realization';

import Component from '@glimmer/component';
import { capitalizeFirstLetter } from '../../../utils/string';
import { connect } from 'ember-redux';
import { getFeaturesList } from '../../../selectors/feature';
import { getTerm } from '../../../selectors/term';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

const dispatchToActions = {};

const stateToComputed = (state, attrs) => ({
  term: getTerm(state, attrs.termId),
  featuresList: getFeaturesList(state),
});

class PatentSpecificationTerm extends Component {
  @service models;
  @service redux;

  @tracked realization;

  get classNames() {
    let classNames = ['patent-specification-term', this.styleNamespace];
    return classNames.join(' ');
  }

  get styleNamespace() {
    return podNames['patent-specification-term'];
  }

  @alias('term.definitionsList') definitionsList;

  @bool('definitionsList.length') hasRealization;

  @computed('args.termId', 'term.name')
  get nameRealization() {
    const name = capitalizeFirstLetter(this.term.name);
    console.log('name', name)
    const termMention = termMentionContentTemplate(name, this.args.termId);
    return `${termMention}`;
  }

  @computed('definitionsList.[]')
  get featureModels() {
    return this.definitionsList.map((featureId) => this.models.find(featureId));
  }

  @computed('featureModels.@each.value')
  get featureModelsValue() {
    return this.featureModels.map((featureModel) => featureModel && featureModel.value).join('')
  }

  @action
  didInsert() {
    this.realization = this.getRealization();
  }

  @action
  onUpdate() {
    this.realization = this.getRealization();
  }
  
  getRealization() {
    const state = this.redux.getState();
    const models = this.models;
    const termId = this.args.termId;
    return (
      this.term &&
      getTermRealization({
        state,
        models,
        termId,
      })
    );
  }
}

export default connect(
  stateToComputed,
  dispatchToActions
)(PatentSpecificationTerm);
