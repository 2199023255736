import Component from '@glimmer/component';
import { connect } from 'ember-redux';
import podNames from 'ember-component-css/pod-names';
import FeatureTypes from '../../../constants/types/features';

const stateToComputed = null;

const dispatchToActions = {};

class DefinitionsAddButton extends Component {

  get styleNamespace() {
    return podNames['definitions-add-button'];
  }

  get classNames() {
    let classNames = [this.styleNamespace, 'definitions-add-button'];
    return classNames.join(' ');
  }

  get types() {
    return ['definition', 'analogs', 'detail'].map(featureId => FeatureTypes[featureId])
  }
}

export default connect(stateToComputed, dispatchToActions)(DefinitionsAddButton);
