import { getProduct, getProducts, getProductsList } from '../../../selectors/product';

import Component from '@glimmer/component';
import { computed } from '@ember/object';
import { connect } from 'ember-redux';
import { getSelectedProductId } from '../../../selectors/invention-ui';
import podNames from 'ember-component-css/pod-names';

const stateToComputed = (state, /*attrs*/) => ({
  productsList: getProductsList(state),
  productsMap: getProducts(state),
  selectedProductId: getSelectedProductId(state),
});

const dispatchToActions = {};

class ProductSelector extends Component {

  allProductsLabel = 'All Products';

  get styleNamespace() {
    return podNames['product-selector'];
  }

  @computed('selectedProductId', 'args.activeProductId', 'styleNamespace')
  get classNames() {
    let classNames = [this.styleNamespace, 'product-selector'];
    if (this.selectedProductId === this.args.activeProductId) classNames.push('is-selected');
    return classNames.join(' ');
  }

  // eslint-disable-next-line ember/require-computed-property-dependencies
  @computed('args.activeProductId', 'productsMap', 'selectedProductId')
  get activeProduct() {
    const state = this.redux.getState();
    return this.args.activeProductId && getProduct(state, this.args.activeProductId);
  }

  // eslint-disable-next-line ember/require-computed-property-dependencies
  @computed('activeProduct', 'productsMap', 'selectedProductId')
  get activeProductLabel() {
    return this.activeProduct ? this.activeProduct.name : this.allProductsLabel
  }

  // eslint-disable-next-line ember/require-computed-property-dependencies
  @computed('productsMap', 'productsList.[]')
  get products() {
    const state = this.redux.getState();
    return this.productsList.map(productId => getProduct(state, productId));
  }
}

export default connect(stateToComputed, dispatchToActions)(ProductSelector);