import Component from '@ember/component';
import { connect } from 'ember-redux';
import { setShowingImageUploadModal } from '../../../actions/invention-ui';

const dispatchToActions = function(dispatch) {
  return {
    hideModal: () =>
      dispatch(
        setShowingImageUploadModal(this.drawingId, false)
      )
  };
};

// const stateToComputed = (state, attrs) => ({
  // drawing: getDrawing(state, attrs.drawingId),
  // drawingsList: getDrawingsList(state)
// });

const ImageUploadModal = Component.extend({
  attributeBindings: ['testId:data-test-id'],
  testId: null,
  actions: {
    uploadImage(dropped) {
      const file = dropped.files ? dropped.files[0] : dropped.blob;
      this.onFileUpload(file);
    },
    getExternalImage(url, file) {
      this.onFileUpload(file, url);
    }
  }
});

export default connect(
  null,
  dispatchToActions
)(ImageUploadModal);
