import Service from '@ember/service';
import TimeMe from 'timeme.js';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class SessionTime extends Service {
  @service data;
  @service redux;

  @tracked disclosureId = null;

  setup(disclosureId) {
    this.disclosureId = disclosureId;
    TimeMe.initialize({
      currentPageName: disclosureId, // current page
      idleTimeoutInSeconds: 30, // seconds
    });

    TimeMe.callWhenUserLeaves(() => this.update());
  }

  update(startTimer = false) {
    if (!this.disclosureId) return;
    let sessionTime = TimeMe.getTimeOnPageInSeconds(this.disclosureId);
    if (sessionTime) {
      sessionTime = Math.floor(sessionTime);
      this.data.updateSessionTime(sessionTime);
      TimeMe.resetRecordedPageTime(this.disclosureId);
      if (startTimer) {
        TimeMe.startTimer();
      }
    }
  }

  clear() {
    this.disclosureId = null;
  }
}
