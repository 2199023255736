import Component from '@glimmer/component';
import { action } from '@ember/object';
import { connect } from 'ember-redux';
import { getTerm } from '../../../selectors/term';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';

const stateToComputed = (state, attrs) => ({
  term: getTerm(state, attrs.termId),
});

const dispatchToActions = {};

class TermContextMenu extends Component {
  @service contextMenu;

  get styleNamespace() {
    return podNames['term-context-menu'];
  }

  get classNames() {
    let classNames = [this.styleNamespace, 'term-context-menu'];
    return classNames.join(' ');
  }

  @action
  onConvertTermToPart() {
    if (this.args.onConvertTermToElement) {
      this.args.onConvertTermToElement(this.args.termId, 'part');
    }
    this.contextMenu.close();
  }

  @action
  onConvertTermToSystem() {
    if (this.args.onConvertTermToElement) {
      this.args.onConvertTermToElement(this.args.termId, 'system');
    }
    this.contextMenu.close();
  }

  @action
  onRemoveTerm() {
    if (this.args.onRemoveTerm) {
      this.args.onRemoveTerm(this.args.termId);
    }
    this.contextMenu.close();
  }
}

export default connect(stateToComputed, dispatchToActions)(TermContextMenu);
