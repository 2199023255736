import Component from '@glimmer/component';
import { connect } from 'ember-redux';
import podNames from 'ember-component-css/pod-names';

const dispatchToActions = {};

const stateToComputed = null;

class MarkerContextType extends Component {
  nameSort = ['name'];

  get classNames() {
    let classNames = ['marker-context-type', this.styleNamespace];
    return classNames.join(' ');
  }

  get styleNamespace() {
    return podNames['marker-context-type'];
  }
}

export default connect(
  stateToComputed,
  dispatchToActions
)(MarkerContextType);
