import {
  ADD_DRAWING,
  ADD_ELEMENT,
  ADD_ELEMENT_VERSION,
  ADD_INVENTION,
  ADD_MARKER,
  ADD_METHOD_EDGE,
  ADD_METHOD_NODE,
  ADD_NOTE,
  CLEAR_SELECTION,
  DESELECT_ALL_INVENTION,
  DESELECT_DRAWING,
  DESELECT_DRAWING_ITEMS,
  DESELECT_EDGE,
  DESELECT_ELEMENT,
  DESELECT_ELEMENT_VERSION,
  DESELECT_IMAGE,
  DESELECT_MARKER,
  DESELECT_METHOD_ITEMS,
  DESELECT_METHOD_NODE,
  DESELECT_TERM,
  HIDE_COMPARISON,
  HIDE_EXPLORER,
  HIDE_NOTES,
  MULTI_SELECT_EDGE,
  MULTI_SELECT_ELEMENT,
  MULTI_SELECT_ELEMENT_VERSION,
  MULTI_SELECT_IMAGE,
  MULTI_SELECT_MARKER,
  MULTI_SELECT_METHOD_EDGE,
  MULTI_SELECT_METHOD_NODE,
  REMOVE_DRAWING,
  REMOVE_ELEMENT,
  REMOVE_METHOD,
  REMOVE_NOTE,
  REMOVE_SELECTED_DRAWING_ITEMS,
  REMOVE_TERM,
  RESET_NAVIGATION,
  SELECT_ASSET,
  SELECT_COMPARISON,
  SELECT_CUSTOMER,
  SELECT_DRAWING,
  SELECT_EDGE,
  SELECT_ELEMENT,
  SELECT_ELEMENT_AND_ELEMENT_VERSION,
  SELECT_ELEMENT_VERSION,
  SELECT_IMAGE,
  SELECT_MARKER,
  SELECT_METHOD_EDGE,
  SELECT_METHOD_EDGE_POINT,
  SELECT_METHOD_NODE,
  SELECT_PRIOR_ART,
  SELECT_PRODUCT,
  SELECT_REFERENCE,
  SELECT_REVIEW,
  SELECT_TERM,
  SET_ACTIVE_COMPARISON,
  SET_ACTIVE_COMPARISON_ELEMENT,
  SET_ACTIVE_CONTEXT_TAB,
  SET_ACTIVE_DRAWING,
  SET_ACTIVE_ELEMENT_VERSION_LIST_ITEM,
  SET_ACTIVE_FEATURE,
  SET_ACTIVE_INVENTION_SUMMARY_EDITOR_SECTION,
  SET_ACTIVE_METHOD,
  SET_ACTIVE_MILESTONE_CONTEXT,
  SET_ACTIVE_NOTE,
  SET_ACTIVE_PATENTABILITY_SECTION,
  SET_ACTIVE_PATENT_SPECIFICATION_EDITOR_SECTION,
  SET_ACTIVE_PRIOR_ART,
  SET_ACTIVE_PRODUCT,
  SET_ACTIVE_PRODUCT_VIEW,
  SET_ACTIVE_REVIEW,
  SET_ACTIVE_VIEW,
  SET_COLLAPSED_PROBLEM,
  SET_COLLAPSED_SOLUTION,
  SET_CREATING_ASSET,
  SET_CREATING_COMPARISON,
  SET_CREATING_IMAGE_ASSET,
  SET_CREATING_NOTE,
  SET_CREATING_REVIEW,
  SET_DEFAULT_ELEMENT_CATEGORY,
  SET_DEFAULT_MARKER_CATEGORY,
  SET_DEFAULT_METHOD_NODE_TYPE,
  SET_EDITING_ASSET,
  SET_EDITING_COMPARISON,
  SET_EDITING_NOTE,
  SET_EDITING_PRODUCT,
  SET_EDITING_REVIEW,
  SET_EXPLORER_WIDTH,
  SET_FIGURE_TYPE,
  SET_METHOD_REALIZATION_PREVIEW_MODE,
  SET_PATENT_SPECIFICATION_PREVIEW_MODE,
  SET_PREVIEW_MODE,
  SET_REVIEW_MODE,
  SET_SHOWING_ASSETS,
  SET_SHOWING_COMPARISON_MATRIX,
  SET_SHOWING_CUSTOMERS,
  SET_SHOWING_DRAWING_SELECTION_MODAL,
  SET_SHOWING_ELEMENT_VERSION_SELECTOR,
  SET_SHOWING_FIGURES,
  SET_SHOWING_GET_STARTED,
  SET_SHOWING_HISTORY_VERSION_CREATE_MODAL,
  SET_SHOWING_IMAGE_UPLOAD_MODAL,
  SET_SHOWING_INVENTION_SUMMARY_EDITOR,
  SET_SHOWING_PATENTABILITY,
  SET_SHOWING_PATENT_SPECIFICATION,
  SET_SHOWING_PATENT_SPECIFICATION_EDITOR,
  SET_SHOWING_PRIOR_ARTS,
  SET_SHOWING_PRODUCTS,
  SET_SHOWING_PRODUCT_CHECKLIST,
  SET_SHOWING_SEARCH,
  SET_SHOWING_SETTINGS,
  SET_UPDATING_IMAGE_ASSET,
  SET_UPDATING_PRIOR_ART_ASSET,
  SET_UPDATING_PRODUCT_ASSET,
  SET_UPDATING_PRODUCT_CUSTOMERS,
  SET_UPDATING_PRODUCT_PRIOR_ART,
  SHOW_COMPARISON,
  SHOW_EXPLORER,
  SHOW_NOTES,
  SWITCH_COMPRISES_RELATIONSHIP,
  UPDATE_COLLAPSED_NODES,
  UPDATE_DRAWING,
  UPDATE_ELEMENT,
  UPDATE_ELEMENT_COLUMNS_DATA,
  UPDATE_ELEMENT_VERSION,
  UPDATE_FIGURES,
  UPDATE_GRAPH,
  UPDATE_MARKER,
  UPDATE_METHOD,
  UPDATE_METHOD_EDGE,
  UPDATE_METHOD_NODE,
  UPDATE_NAVIGATION_STACK,
  UPDATE_NAVIGATION_STACK_SELECTED_ITEMS,
  UPDATE_VERSION_TREE_COORDINATES,
} from '../actions/actionTypes';

import { addFromSchema } from '../utils/schema';
import { selectReference } from '../utils/redux';

const numberingActions = [
  ADD_DRAWING,
  ADD_ELEMENT,
  ADD_ELEMENT_VERSION,
  ADD_MARKER,
  ADD_METHOD_EDGE,
  ADD_METHOD_NODE,
  REMOVE_DRAWING,
  REMOVE_ELEMENT,
  REMOVE_METHOD,
  REMOVE_SELECTED_DRAWING_ITEMS,
  SWITCH_COMPRISES_RELATIONSHIP,
  UPDATE_DRAWING,
  UPDATE_ELEMENT,
  UPDATE_ELEMENT_VERSION,
  UPDATE_FIGURES,
  UPDATE_MARKER,
  UPDATE_METHOD,
  UPDATE_METHOD_EDGE,
  UPDATE_METHOD_NODE,
  UPDATE_GRAPH,
];

const selectOneThing = (state, key, value, valueIsArray = true) => {
  const updatedState = {
    ...state,
    selectedMarkers: [],
    selectedElements: [],
    selectedTerms: [],
    selectedElementVersions: [],
    selectedEdges: [],
    selectedImages: [],
    selectedMethodNodes: [],
    selectedMethodEdges: [],
    selectedMethodEdgePoints: [],
    activeFeature: null,
    activeElementVersionListItem: null,
    selectedProducts: [],
    showingFigures: false,
    showingSearch: false,
  };

  if (valueIsArray) {
    updatedState[key] = [value];
  } else {
    updatedState[key] = value;
  }

  return JSON.parse(JSON.stringify(updatedState));
};

const selectMultipleThings = (state, arrayOfThings) => {
  const updatedState = {
    ...state,
    selectedMarkers: [],
    selectedElements: [],
    selectedTerms: [],
    selectedElementVersions: [],
    selectedEdges: [],
    selectedImages: [],
    selectedMethodNodes: [],
    selectedMethodEdges: [],
    selectedMethodEdgePoints: [],
    activeFeature: null,
    selectedProducts: [],
    showingElementVersionSelector: false,
  };

  arrayOfThings.forEach((thing) => {
    updatedState[thing.key] = [thing.value];
  });

  return JSON.parse(JSON.stringify(updatedState));
};

const deselectOneThing = (state, key, value) => {
  const updatedState = {
    ...state,
    [key]: state[key].filter((_value) => _value !== value),
  };

  return JSON.parse(JSON.stringify(updatedState));
};

const initialState = addFromSchema('invention-ui');

const inventionUiReducer = (state = initialState, action) => {
  const { type, payload } = action;

  if (numberingActions.includes(type)) {
    state = {
      ...state,
      numberingIndex:
        typeof state.numberingIndex === 'number' ? state.numberingIndex + 1 : 0,
    };
  }

  switch (type) {
    case CLEAR_SELECTION: {
      return {
        ...state,
        selectedMarkers: [],
        selectedElements: [],
        selectedTerms: [],
        selectedElementVersions: [],
        selectedEdges: [],
        selectedImages: [],
        selectedMethodNodes: [],
        selectedMethodEdges: [],
        selectedMethodEdgePoints: [],
        activeFeature: null,
        activeElementVersionListItem: null,
        selectedProducts: [],
        showingElementVersionSelector: false,
      };
    }

    case UPDATE_COLLAPSED_NODES: {
      let { productId, collapsedNodesList, collapsedDescendantsList } = payload;
      const productCollapsedNodes = state.collapsedNodes[productId] || {};
      return {
        ...state,
        collapsedNodes: {
          ...state.collapsedNodes,
          [productId]: {
            ...productCollapsedNodes,
            collapsedNodesList,
            collapsedDescendantsList,
          },
        },
      };
    }

    case ADD_INVENTION: {
      const { noteId } = payload;
      return {
        ...state,
        activeNote: noteId,
      };
    }

    case UPDATE_NAVIGATION_STACK: {
      const { navigationStack } = payload;
      return {
        ...state,
        navigationStack,
      };
    }

    case UPDATE_NAVIGATION_STACK_SELECTED_ITEMS: {
      const {
        selectedElements,
        selectedElementVersions,
        selectedTerms,
        selectedImages,
        selectedMarkers,
        selectedMethodNodes,
        selectedMethodEdges,
        selectedMethodEdgePoints,
        showingFigures,
      } = payload;

      return {
        ...state,
        selectedElements,
        selectedElementVersions,
        selectedTerms,
        selectedImages,
        selectedMarkers,
        selectedMethodNodes,
        selectedMethodEdges,
        selectedMethodEdgePoints,
        showingFigures,
      };
    }

    case SELECT_REFERENCE: {
      const { referenceId, referenceType, figureId, figureType } = payload;
      return {
        ...state,
        ...selectReference(
          state,
          referenceId,
          referenceType,
          figureId,
          figureType
        ),
      };
    }

    case UPDATE_ELEMENT_COLUMNS_DATA: {
      const { data } = payload;
      return {
        ...state,
        elementColumnsData: data,
      };
    }

    case REMOVE_SELECTED_DRAWING_ITEMS: {
      return {
        ...state,
        activeFeature: null,
        selectedMarkers: [],
        selectedImages: [],
        selectedElements: [],
        selectedElementVersions: [],
        selectedEdges: [],
        selectedMethodNodes: [],
        selectedMethodEdges: [],
        selectedMethodEdgePoints: [],
      };
    }

    case SET_SHOWING_FIGURES: {
      const { value } = payload;
      return selectOneThing(state, 'showingFigures', value, false);
    }

    case SET_SHOWING_SEARCH: {
      const { value } = payload;
      return selectOneThing(state, 'showingSearch', value, false);
    }

    case SET_SHOWING_GET_STARTED: {
      const { value } = payload;
      return {
        ...state,
        showingGetStarted: value,
      };
    }

    case SET_SHOWING_SETTINGS: {
      const { value } = payload;
      return {
        ...state,
        showingSettings: value,
      };
    }

    case SET_SHOWING_PATENT_SPECIFICATION_EDITOR: {
      const { value } = payload;
      return {
        ...state,
        showingPatentSpecificationEditor: value,
      };
    }

    case SET_ACTIVE_PATENT_SPECIFICATION_EDITOR_SECTION: {
      const { value } = payload;
      return {
        ...state,
        activePatentSpecificationEditorSection: value,
      };
    }

    case SET_SHOWING_INVENTION_SUMMARY_EDITOR: {
      const { value } = payload;
      return {
        ...state,
        showingInventionSummaryEditor: value,
      };
    }

    case SET_ACTIVE_INVENTION_SUMMARY_EDITOR_SECTION: {
      const { value } = payload;
      console.log('v4', value)
      return {
        ...state,
        activeInventionSummaryEditorSection: value,
      };
    }

    case SET_SHOWING_ELEMENT_VERSION_SELECTOR: {
      const { value } = payload;
      return {
        ...state,
        showingElementVersionSelector: value,
      };
    }

    // case ADD_METHOD_NODE: {
    //   const { methodNodeId } = payload;
    //   return {
    //     ...state,
    //     selectedElements: [],
    //     selectedElementVersions: [],
    //     selectedMethodNodes: [methodNodeId],
    //     selectedMethodEdges: [],
    //     selectedMethodEdgePoints: [],
    //   };
    // }

    case SET_REVIEW_MODE: {
      const { reviewMode } = payload;
      return {
        ...state,
        reviewMode,
      };
    }

    case SET_COLLAPSED_PROBLEM: {
      const { value } = payload;
      return {
        ...state,
        collapsedProblem: value,
      };
    }

    case SET_COLLAPSED_SOLUTION: {
      const { value } = payload;
      return {
        ...state,
        collapsedSolution: value,
      };
    }

    case SET_DEFAULT_ELEMENT_CATEGORY: {
      const { value } = payload;
      return {
        ...state,
        defaultElementCategory: value,
      };
    }

    case SET_DEFAULT_MARKER_CATEGORY: {
      const { value } = payload;
      return {
        ...state,
        defaultMarkerCategory: value,
      };
    }

    case SET_DEFAULT_METHOD_NODE_TYPE: {
      const { value } = payload;
      return {
        ...state,
        defaultMethodNodeType: value,
      };
    }

    case SET_PATENT_SPECIFICATION_PREVIEW_MODE: {
      const { value } = payload;
      return {
        ...state,
        previewMode: value,
      };
    }

    case SET_ACTIVE_CONTEXT_TAB: {
      const { activeTab } = payload;
      return {
        ...state,
        activeContextTab: activeTab,
      };
    }

    case SET_ACTIVE_VIEW: {
      const { activeView } = payload;
      return {
        ...state,
        activeView,
      };
    }

    case SET_FIGURE_TYPE: {
      const { figureType } = payload;
      return {
        ...state,
        figureType,
      };
    }

    case SHOW_NOTES: {
      return {
        ...state,
        showingNotes: true,
      };
    }
    case HIDE_NOTES: {
      return {
        ...state,
        showingNotes: false,
      };
    }
    case SHOW_EXPLORER: {
      return {
        ...state,
        showingExplorer: true,
      };
    }
    case HIDE_EXPLORER: {
      return {
        ...state,
        showingExplorer: false,
      };
    }
    case SHOW_COMPARISON: {
      return {
        ...state,
        showingComparison: true,
      };
    }
    case HIDE_COMPARISON: {
      return {
        ...state,
        showingComparison: false,
      };
    }
    case REMOVE_NOTE: {
      const { activeNote } = payload;
      return {
        ...state,
        activeNote,
      };
    }
    case UPDATE_VERSION_TREE_COORDINATES: {
      const { attributes } = payload;
      return {
        ...state,
        versionTreeCoordinates: {
          ...state.versionTreeCoordinates,
          x: attributes.x,
          y: attributes.y,
          k: attributes.k,
        },
      };
    }
    case SET_EXPLORER_WIDTH: {
      const { width } = payload;
      return {
        ...state,
        explorerWidth: width,
      };
    }
    case SET_PREVIEW_MODE: {
      const { previewMode } = payload;
      return {
        ...state,
        previewMode: previewMode,
      };
    }
    case SET_METHOD_REALIZATION_PREVIEW_MODE: {
      const { previewMode } = payload;
      return {
        ...state,
        methodRealizationPreviewMode: previewMode,
      };
    }
    case SET_SHOWING_IMAGE_UPLOAD_MODAL: {
      const { value } = payload;
      return {
        ...state,
        showingImageUploadModal: value,
      };
    }
    case SET_SHOWING_PRIOR_ARTS: {
      const { value } = payload;
      return {
        ...state,
        showingPriorArts: value,
      };
    }
    case SET_SHOWING_CUSTOMERS: {
      const { value } = payload;
      return {
        ...state,
        showingCustomers: value,
      };
    }
    case SET_SHOWING_PATENTABILITY: {
      const { value } = payload;
      return {
        ...state,
        showingPatentability: value,
      };
    }
    case SET_ACTIVE_PATENTABILITY_SECTION: {
      const { value } = payload;
      return {
        ...state,
        activePatentabilitySection: value,
      };
    }
    case SET_SHOWING_PATENT_SPECIFICATION: {
      const { value } = payload;
      return {
        ...state,
        showingPatentSpecification: value,
      };
    }
    case SET_SHOWING_ASSETS: {
      const { value } = payload;
      return {
        ...state,
        showingAssets: value,
      };
    }

    case SET_SHOWING_PRODUCT_CHECKLIST: {
      const { value } = payload;
      return {
        ...state,
        showingProductChecklist: value,
      };
    }
    case SET_SHOWING_PRODUCTS: {
      const { value } = payload;
      return {
        ...state,
        showingProducts: value,
      };
    }

    case SET_SHOWING_COMPARISON_MATRIX: {
      const { value } = payload;
      return {
        ...state,
        showingComparisonMatrix: value,
      };
    }

    case SET_SHOWING_DRAWING_SELECTION_MODAL: {
      const { value } = payload;
      return {
        ...state,
        showingDrawingSelectionModal: value,
      };
    }

    case SET_SHOWING_HISTORY_VERSION_CREATE_MODAL: {
      const { value } = payload;
      return {
        ...state,
        showingHistoryVersionCreateModal: value,
      };
    }

    case SET_UPDATING_PRIOR_ART_ASSET: {
      return {
        ...state,
        updatingPriorArtAsset: payload.priorArtId,
      };
    }

    case SET_CREATING_IMAGE_ASSET: {
      return {
        ...state,
        creatingImageAsset: payload.drawingId,
      };
    }

    case SET_UPDATING_IMAGE_ASSET: {
      return {
        ...state,
        updatingImageAsset: payload.imageId,
      };
    }

    case SET_UPDATING_PRODUCT_ASSET: {
      return {
        ...state,
        updatingProductAsset: payload.productId,
      };
    }

    case SET_UPDATING_PRODUCT_PRIOR_ART: {
      return {
        ...state,
        updatingProductPriorArt: payload.value,
      };
    }

    case SET_UPDATING_PRODUCT_CUSTOMERS: {
      return {
        ...state,
        updatingProductCustomers: payload.value,
      };
    }

    case SET_ACTIVE_PRODUCT: {
      return {
        ...state,
        activeProduct: payload.productId,
      };
    }

    case SET_ACTIVE_PRODUCT_VIEW: {
      return {
        ...state,
        activeProductView: payload.value,
      };
    }

    case SET_ACTIVE_MILESTONE_CONTEXT: {
      return {
        ...state,
        activeProductChecklistView: payload.value,
      };
    }

    case SET_ACTIVE_COMPARISON: {
      return {
        ...state,
        activeComparison: payload.comparisonId,
      };
    }

    case SET_ACTIVE_COMPARISON_ELEMENT: {
      return {
        ...state,
        activeComparisonElement: payload.elementId,
      };
    }

    case SET_ACTIVE_PRIOR_ART: {
      return {
        ...state,
        activePriorArt: payload.priorArtId,
      };
    }

    case SET_ACTIVE_REVIEW: {
      return {
        ...state,
        activeReview: payload.reviewId,
      };
    }

    case SET_CREATING_ASSET: {
      return {
        ...state,
        creatingAsset: payload.value,
      };
    }

    case SET_EDITING_ASSET: {
      return {
        ...state,
        editingAsset: payload.assetId,
      };
    }

    case SET_CREATING_COMPARISON: {
      return {
        ...state,
        creatingComparison: payload.value,
      };
    }

    case SET_CREATING_REVIEW: {
      return {
        ...state,
        creatingReview: payload.value,
      };
    }

    case SET_ACTIVE_METHOD: {
      return {
        ...state,
        activeMethod: payload.methodId,
        activeDrawing: null,
        activeFeature: null,
        selectedElements: [],
        selectedElementVersions: [],
        selectedMethodNodes: [],
        selectedMethodEdges: [],
        selectedMethodEdgePoints: [],
      };
    }

    case ADD_NOTE:
    case SET_ACTIVE_NOTE: {
      return {
        ...state,
        activeNote: payload.noteId,
        editingNote: null,
        creatingNote: false,
      };
    }

    case SET_CREATING_NOTE: {
      return {
        ...state,
        creatingNote: payload.value,
      };
    }

    case SET_EDITING_NOTE: {
      return {
        ...state,
        editingNote: payload.noteId,
      };
    }

    case SET_EDITING_COMPARISON: {
      return {
        ...state,
        editingComparison: payload.comparisonId,
      };
    }

    case SET_EDITING_PRODUCT: {
      return {
        ...state,
        editingProduct: payload.productId,
      };
    }

    case SET_EDITING_REVIEW: {
      return {
        ...state,
        editingReview: payload.reviewId,
      };
    }

    // case REMOVE_FEATURE: {
    //   return {
    //     ...state,
    //     activeFeature: null,
    //   };
    // }

    case REMOVE_TERM: {
      const { termId } = payload;
      return {
        ...state,
        selectedTerms: state.selectedTerms.filter((id) => id !== termId),
      };
    }

    case RESET_NAVIGATION: {
      return {
        ...state,
        activeDrawing: null,
        activeFeature: null,
        selectedMarkers: [],
        selectedImages: [],
        activeMethod: null,
        selectedMethodNodes: [],
        selectedMethodEdges: [],
      };
    }

    case SET_ACTIVE_DRAWING: {
      const { drawingId } = payload;
      return {
        ...state,
        activeFeature: null,
        activeMethod: null,
        activeDrawing: drawingId,
        selectedMarkers: [],
        selectedImages: [],
        // selectedElements: [],
        // selectedElementVersions: [],
      };
    }

    case SET_ACTIVE_ELEMENT_VERSION_LIST_ITEM: {
      const { elementVersionId } = payload;
      return {
        ...state,
        activeElementVersionListItem: elementVersionId,
      };
    }

    case REMOVE_DRAWING: {
      const { drawingId } = payload;
      const activeDrawingId = state.activeDrawing;
      if (activeDrawingId && activeDrawingId === drawingId) {
        return {
          ...state,
          activeDrawing: null,
          selectedMarkers: [],
          selectedImages: [],
        };
      } else {
        return {
          ...state,
        };
      }
    }

    case REMOVE_METHOD: {
      const { methodId } = payload;
      const activeMethodId = state.activeMethod;
      if (activeMethodId && activeMethodId === methodId) {
        return {
          ...state,
          activeMethod: null,
          selectedMethodNodes: [],
          selectedMethodEdges: [],
          selectedMethodEdgePoints: [],
        };
      } else {
        return {
          ...state,
        };
      }
    }

    case SELECT_ASSET: {
      const { assetId } = payload;
      return {
        ...state,
        selectedAssets: assetId ? [assetId] : [],
      };
    }

    case SELECT_COMPARISON: {
      const { comparisonId } = payload;
      return {
        ...state,
        selectedComparisons: comparisonId ? [comparisonId] : [],
      };
    }

    case SELECT_REVIEW: {
      const { reviewId } = payload;
      return {
        ...state,
        selectedReviews: reviewId ? [reviewId] : [],
      };
    }

    case SELECT_PRIOR_ART: {
      const { priorArtId } = payload;
      return {
        ...state,
        selectedPriorArts: priorArtId ? [priorArtId] : [],
      };
    }

    case SELECT_CUSTOMER: {
      const { customerId } = payload;
      return {
        ...state,
        selectedCustomers: customerId ? [customerId] : [],
      };
    }

    case SELECT_MARKER: {
      const { markerId } = payload;
      return selectOneThing(state, 'selectedMarkers', markerId);
    }

    case REMOVE_ELEMENT: {
      return {
        ...state,
        activeFeature: null,
        selectedElements: [],
      };
    }
    case SELECT_METHOD_NODE: {
      return selectOneThing(state, 'selectedMethodNodes', payload.methodNodeId);
    }
    case SELECT_METHOD_EDGE: {
      return selectOneThing(state, 'selectedMethodEdges', payload.methodEdgeId);
    }
    case SELECT_METHOD_EDGE_POINT: {
      return selectOneThing(
        state,
        'selectedMethodEdgePoints',
        payload.methodEdgePointId
      );
    }
    case DESELECT_METHOD_ITEMS: {
      return {
        ...state,
        selectedMethodNodes: [],
        selectedMethodEdges: [],
        selectedMethodEdgePoints: [],
        selectedTerms: [],
        selectedElements: [],
        selectedElementVersions: [],
        activeFeature: null,
      };
    }

    case SELECT_IMAGE: {
      const { imageId } = payload;
      return selectOneThing(state, 'selectedImages', imageId);
    }
    case MULTI_SELECT_MARKER: {
      const { markerId } = payload;
      return {
        ...state,
        activeFeature: null,
        selectedMarkers: state.selectedMarkers.includes(markerId)
          ? [...state.selectedMarkers]
          : [...state.selectedMarkers, markerId],
      };
    }
    case MULTI_SELECT_METHOD_NODE: {
      const { methodNodeId } = payload;
      return {
        ...state,
        activeFeature: null,
        selectedMethodNodes: state.selectedMethodNodes.includes(methodNodeId)
          ? [...state.selectedMethodNodes]
          : [...state.selectedMethodNodes, methodNodeId],
      };
    }
    case MULTI_SELECT_METHOD_EDGE: {
      const { methodEdgeId } = payload;
      return {
        ...state,
        activeFeature: null,
        selectedMethodEdges: state.selectedMethodEdges.includes(methodEdgeId)
          ? [...state.selectedMethodEdges]
          : [...state.selectedMethodEdges, methodEdgeId],
      };
    }
    case MULTI_SELECT_IMAGE: {
      const { imageId } = payload;
      return {
        ...state,
        activeFeature: null,
        selectedElements: [],
        selectedElementVersions: [],
        selectedImages: state.selectedImages.includes(imageId)
          ? [...state.selectedImages]
          : [...state.selectedImages, imageId],
      };
    }

    case SELECT_PRODUCT: {
      const { productId } = payload;
      return {
        ...state,
        selectedProducts: productId ? [productId] : []
      };
    }

    case DESELECT_IMAGE: {
      return deselectOneThing(state, 'selectedImages', payload.imageId);
    }

    case SELECT_DRAWING: {
      const { drawingId } = payload;
      return {
        ...state,
        selectedDrawings: [drawingId],
      };
    }

    case DESELECT_DRAWING: {
      return deselectOneThing(state, 'selectedDrawings', payload.drawingId);
    }
    case DESELECT_MARKER: {
      return deselectOneThing(state, 'selectedMarkers', payload.markerId);
    }
    case DESELECT_METHOD_NODE: {
      return deselectOneThing(
        state,
        'selectedMethodNodes',
        payload.methodNodeId
      );
    }
    case DESELECT_DRAWING_ITEMS: {
      return {
        ...state,
        activeFeature: null,
        selectedMarkers: [],
        selectedImages: [],
        selectedTerms: [],
        selectedElements: [],
        selectedElementVersions: [],
        selectedEdges: [],
        selectedMethodNodes: [],
        selectedMethodEdges: [],
        selectedMethodEdgePoints: [],
      };
    }
    case DESELECT_ALL_INVENTION: {
      return {
        ...state,
        activeFeature: null,
        selectedProducts: [],
        selectedEdges: [],
        selectedElements: [],
        selectedElementVersions: [],
        selectedTerms: [],
        selectedMarkers: [],
        selectedMethodNodes: [],
      };
    }
    case SELECT_ELEMENT: {
      const { elementId } = payload;
      return selectOneThing(state, 'selectedElements', elementId);
    }
    case SELECT_ELEMENT_VERSION: {
      const { elementVersionId } = payload;
      return selectOneThing(state, 'selectedElementVersions', elementVersionId);
    }
    case SELECT_ELEMENT_AND_ELEMENT_VERSION: {
      const { elementId, elementVersionId } = payload;
      return selectMultipleThings(state, [
        {
          key: 'selectedElementVersions',
          value: elementVersionId,
        },
        {
          key: 'selectedElements',
          value: elementId,
        },
      ]);
    }
    case SELECT_EDGE: {
      const { edge } = payload;
      return selectOneThing(state, 'selectedEdges', edge);
    }
    case SET_ACTIVE_FEATURE: {
      const { featureId } = payload;
      return {
        ...state,
        activeFeature: featureId,
      };
    }
    case MULTI_SELECT_ELEMENT: {
      const { elementId } = payload;
      return {
        ...state,
        selectedElements: state.selectedElements.includes(elementId)
          ? [...state.selectedElements]
          : [...state.selectedElements, elementId],
        // selectedElementVersions: [], // TODO: Does commenting this out break something?
      };
    }
    case MULTI_SELECT_ELEMENT_VERSION: {
      const { elementVersionId } = payload;
      return {
        ...state,
        selectedElementVersions: state.selectedElementVersions.includes(
          elementVersionId
        )
          ? [...state.selectedElementVersions]
          : [...state.selectedElementVersions, elementVersionId],
        // selectedElements: [], // TODO: Does commenting this out break something?
      };
    }
    case MULTI_SELECT_EDGE: {
      const { edge } = payload;
      return {
        ...state,
        selectedEdges: state.selectedEdges.find((_edge) => _edge.id === edge.id)
          ? [...state.selectedEdges]
          : [...state.selectedEdges, edge],
      };
    }

    case SELECT_TERM: {
      const { termId } = payload;
      return selectOneThing(state, 'selectedTerms', termId);
    }
    case DESELECT_TERM: {
      return deselectOneThing(state, 'selectedTerms', payload.termId);
    }
    case DESELECT_ELEMENT: {
      return deselectOneThing(state, 'selectedElements', payload.elementId);
    }
    case DESELECT_ELEMENT_VERSION: {
      return deselectOneThing(
        state,
        'selectedElementVersions',
        payload.elementVersionId
      );
    }
    case DESELECT_EDGE: {
      return deselectOneThing(state, 'selectedEdges', payload.edge.id);
    }

    /* istanbul ignore next */
    default:
      return state;
  }
};

export default inventionUiReducer;
