import Component from '@glimmer/component';
import { action } from '@ember/object';
import { batchGroupBy } from '../../../utils/redux';
import { connect } from 'ember-redux';
import { getAssetsList } from '../../../selectors/asset';
import { getInventionUi } from '../../../selectors/invention-ui';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

const stateToComputed = (state) => ({
  assetsList: getAssetsList(state),
  ui: getInventionUi(state),
});
const dispatchToActions = {};

class AssetsModal extends Component {
  @service data;
  @service tracking;

  @tracked product;
  @tracked asset;

  get styleNamespace() {
    return podNames['assets-modal'];
  }

  get classNames() {
    let classNames = [this.styleNamespace, 'assets-modal'];
    return classNames.join(' ');
  }

  @action
  didInsert() {
    this.tracking.trackEvent('opened_assets');
  }

  @action
  hideModal() {
    batchGroupBy.start();
    this.data.setShowingAssets(false);
    batchGroupBy.end();
    this.tracking.trackEvent('closed_assets');
  }

  @action
  onSelectAsset(asset) {
    this.asset = asset;
  }

  @action
  createComparison() {
    
  }
}

export default connect(stateToComputed, dispatchToActions)(AssetsModal);
