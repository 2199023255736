import {
  ADD_HIGHLIGHT,
  REMOVE_DRAWING,
  REMOVE_HIGHLIGHT,
  REMOVE_SELECTED_DRAWING_ITEMS,
  UPDATE_HIGHLIGHT,
} from '../actions/actionTypes';

import { addFromSchema } from '../utils/schema';
import { omit } from 'lodash';

const initialState = {
  ids: [],
  entities: {},
};

const highlightsReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case ADD_HIGHLIGHT: {
      let { highlightId, attributes } = payload;
      const id = highlightId;
      const createdAt = new Date();
      const updatedAt = new Date();
      const highlight = addFromSchema('highlight', {
        ...attributes,
        id,
        createdAt,
        updatedAt,
      });

      console.log('h3', highlight)

      return {
        ids: [...state.ids, id],
        entities: {
          ...state.entities,
          [highlightId]: highlight,
        },
      };
    }

    case UPDATE_HIGHLIGHT: {
      const { highlightId, attributes } = payload;
      const updatedAt = new Date();
      return {
        ...state,
        entities: {
          ...state.entities,
          [highlightId]: {
            ...state.entities[highlightId],
            ...attributes,
            updatedAt,
          },
        },
      };
    }

    case REMOVE_HIGHLIGHT: {
      const { highlightId } = payload;
      return {
        ids: state.ids.filter((id) => id !== highlightId),
        entities: omit(state.entities, highlightId),
      };
    }

    case REMOVE_SELECTED_DRAWING_ITEMS: {
      const { selectedHighlights } = payload;

      return {
        ids: state.ids.filter((id) => !selectedHighlights.includes(id)),
        entities: omit(state.entities, selectedHighlights),
      };
    }

    case REMOVE_DRAWING: {
      const { highlightsList } = payload;

      return {
        ids: state.ids.filter((id) => !highlightsList.includes(id)),
        entities: omit(state.entities, highlightsList),
      };
    }

    /* istanbul ignore next */
    default:
      return state;
  }
};

export default highlightsReducer;
