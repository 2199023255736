import {
  ADD_DRAWING,
  REMOVE_DRAWING,
  REMOVE_SELECTED_DRAWING_ITEMS,
  SORT_DRAWINGS_LIST,
  UPDATE_DRAWING,
} from '../actions/actionTypes';

import { addFromSchema } from '../utils/schema';
import { omit } from 'lodash';

const initialState = {
  ids: [],
  entities: {},
};

const drawingsReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case ADD_DRAWING: {
      let { drawingId, attributes } = payload;
      const id = drawingId;
      const createdAt = new Date();
      const updatedAt = new Date();
      const drawing = addFromSchema('drawing', {
        id,
        ...attributes,
        createdAt,
        updatedAt,
      });
      return {
        ids: [...state.ids, id],
        entities: {
          ...state.entities,
          [drawingId]: drawing,
        },
      };
    }

    case REMOVE_DRAWING: {
      const { drawingId } = payload;
      return {
        ids: state.ids.filter((id) => id !== drawingId),
        entities: omit(state.entities, drawingId),
      };
    }

    case UPDATE_DRAWING: {
      const { drawingId, attributes } = payload;
      const updatedAt = new Date();
      return {
        ...state,
        entities: {
          ...state.entities,
          [drawingId]: {
            ...state.entities[drawingId],
            ...attributes,
            updatedAt,
          },
        },
      };
    }

    case SORT_DRAWINGS_LIST: {
      const { drawingIds } = payload;
      return {
        ...state,
        ids: drawingIds
      };
    }

    case REMOVE_SELECTED_DRAWING_ITEMS: {
      const {
        drawingId,
        selectedImages,
        selectedMarkers,
      } = payload;

      const updatedAt = new Date();

      return {
        ...state,
        entities: {
          ...state.entities,
          [drawingId]: {
            ...state.entities[drawingId],
            imagesList: state.entities[drawingId].imagesList.filter(
              (id) => !selectedImages.includes(id)
            ),
            markersList: state.entities[drawingId].markersList.filter(
              (id) => !selectedMarkers.includes(id)
            ),
            updatedAt,
          },
        },
      };
    }

    /* istanbul ignore next */
    default:
      return state;
  }
};

export default drawingsReducer;
