import {
  ADD_NOTE,
  UPDATE_NOTE,
  REMOVE_NOTE,
  SORT_NOTES_LIST
} from '../actions/actionTypes';
import { addFromSchema } from '../utils/schema';
import { omit } from 'lodash';

const initialState = {
  ids: [],
  entities: {},
};

const notesReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case ADD_NOTE: {
      let { noteId, attributes } = payload;
      const id = noteId;
      attributes = attributes || {};
      const createdAt = new Date();
      const updatedAt = new Date();
      const note = addFromSchema('note', {
        id,
        createdAt,
        updatedAt,
        ...attributes,
      });

      return {
        ids: [...state.ids, id],
        entities: {
          ...state.entities,
          [noteId]: note,
        },
      };
    }

    case UPDATE_NOTE: {
      const { noteId, attributes } = payload;
      const updatedAt = new Date();
      return {
        ...state,
        entities: {
          ...state.entities,
          [noteId]: {
            ...state.entities[noteId],
            ...attributes,
            updatedAt,
          },
        },
      };
    }

    case REMOVE_NOTE: {
      const { noteId } = payload;
      return {
        ids: state.ids.filter((id) => id !== noteId),
        entities: omit(state.entities, noteId),
      };
    }

    case SORT_NOTES_LIST: {
      const { noteIds } = payload;
      return {
        ...state,
        ids: noteIds
      };
    }

    /* istanbul ignore next */
    default:
      return state;
  }
};

export default notesReducer;
