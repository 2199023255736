import { action, computed } from '@ember/object';

import Component from '@glimmer/component';
import { connect } from 'ember-redux';
import { getPriorArt } from '../../../selectors/prior-art';
import podNames from 'ember-component-css/pod-names';

const stateToComputed = (state, attrs) => ({
  priorArt: getPriorArt(state, attrs.priorArtId),
});

const dispatchToActions = {};

class PriorArtListItem extends Component {

  showMenu = true;

  get styleNamespace() {
    return podNames['prior-art-list-item'];
  }

  get classNames() {
    let classNames = [this.styleNamespace, 'prior-art-list-item'];
    if (this.args.isSelected) classNames.push('is-selected');
    return classNames.join(' ');
  }

  @computed('priorArt.name')
  get name() {
    return this.priorArt && this.priorArt.name ? this.priorArt.name : 'New Prior Art'; 
  }

  @action
  onClick() {
    if (this.args.onClick) this.args.onClick(this.args.priorArtId);
  }

}

export default connect(stateToComputed, dispatchToActions)(PriorArtListItem);
