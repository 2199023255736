import { ADD_REVIEW, UPDATE_REVIEW, REMOVE_REVIEW, SORT_REVIEWS_LIST} from './actionTypes';

export const addReview = (attributes, reviewId) => {
  return {
    type: ADD_REVIEW,
    payload: {
      reviewId,
      attributes
    },
  };
};

export const updateReview = (reviewId, attributes = {}) => {
  return {
    type: UPDATE_REVIEW,
    payload: {
      reviewId,
      attributes,
    },
  };
};

export const removeReview = (reviewId) => {
  return {
    type: REMOVE_REVIEW,
    payload: {
      reviewId
    },
  };
};

export const sortPriorArtsList = (reviewIds) => {
  return {
    type: SORT_REVIEWS_LIST,
    payload: {
      reviewIds,
    },
  };
};
