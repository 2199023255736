import { getPresentState } from '../utils/redux';
import { get } from '@ember/object';

export const getReview = (state, reviewId) =>
  get(getPresentState(state), `reviews.entities.${reviewId}`);

export const getReviewsList = (state) =>
  get(getPresentState(state), `reviews.ids`);

export const getReviews = (state) =>
  get(getPresentState(state), `reviews.entities`);

export const getReviewByProductAndUser = (state, productId, userId) => {
  const reviewIds = getReviewsList(state);
  return reviewIds.find((reviewId) => {
    const review = getReview(state, reviewId);
    return review.product === productId && review.user === userId;
  });
};
