import { action, computed } from '@ember/object';

import Component from '@glimmer/component';
import { connect } from 'ember-redux';
import { getAsset } from '../../../selectors/asset';
import { htmlSafe } from '@ember/string';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

const stateToComputed = (state, attrs) => ({
  asset: getAsset(state, attrs.assetId),
});

const dispatchToActions = {};

class Asset extends Component {

  @service assets;

  @tracked blobUrl;

  get styleNamespace() {
    return podNames['asset'];
  }

  get classNames() {
    let classNames = [this.styleNamespace, 'asset-list-item'];
    if (this.args.isSelected) classNames.push('is-selected');
    return classNames.join(' ');
  }

  @computed('asset.name')
  get name() {
    return this.asset && this.asset.name ? this.asset.name : 'New Prior Art'; 
  }

  @computed('blobUrl')
  get backgroundImageStyle() {
    return htmlSafe(`background-image: url(${this.blobUrl})`); 
    // return htmlSafe(`background-image: url(http://localhost:3030/storage/${this.args.assetId}?type=assets)`); 
  }

  @action
  onClick() {
    if (this.args.onClick) this.args.onClick(this.args.assetId);
  }

  @action
  async setBlobUrl() {
    this.blobUrl = null;
    const blobUrl = await this.assets.getAssetUrl(this.args.assetId);
    this.blobUrl = blobUrl;
  }
}

export default connect(stateToComputed, dispatchToActions)(Asset);
