import { ActionCreators } from 'redux-undo';
import Route from '@ember/routing/route';
import { addGraph } from '../../actions/graph';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency-decorators';
import { waitForProperty } from 'ember-concurrency';

export default class InventionRoute extends Route {
  @service session;
  @service sessionManager;
  @service redux;
  @service import;
  @service models;
  @service importLegacy;
  @service data;
  @service usersDb;
  @service notifications;


  @task
  *findOrCreateInvention(inventionId, additionalData) {
    yield waitForProperty(this, 'redux.bootstrapped', true);
    const invention = this.redux.getState().present.invention;

    // new invention
    if (invention && !invention.id) {
      yield this.data.addInvention(inventionId, additionalData);
      this.usersDb.addInvention(inventionId);
      this.notifications.clearAll();
    } else {
      yield this.data.registerMeta(inventionId);
    }

    const state = this.redux.getState();
    this.redux.store.dispatch(addGraph(state));
    this.redux.store.dispatch(ActionCreators.clearHistory());
  }

  async model(params) {
    this.models.clearCache();

    let additionalData;

    if (params.name) {
      additionalData = {
        name: params.name,
        description: params.description,
        inspiration: params.inspiration,
        enableCloudBackups: params.enableCloudBackups,
        assets: params.assets
          ? params.assets.split(',')
          : [],
      };
    }
    await this.redux.setup(params.invention_id);
    await this.findOrCreateInvention.perform(params.invention_id, additionalData);
    return params.invention_id;
  }

  setupController(controller, model) {
    super.setupController(controller, model);
    controller.set('name', null);
    controller.set('description', null);
    controller.set('inspiration', null);
    controller.set('assets', null);
    controller.set('enableCloudBackups', null);
  }
}
