import {
  BLACK_AND_WHITE_COLOR,
  BLACK_AND_WHITE_FILL,
  BLACK_AND_WHITE_STROKE,
  ELEMENT_COLLAPSED_NODE_STROKE,
  ELEMENT_NODE_COLOR,
  ELEMENT_NODE_FILL,
  ELEMENT_NODE_FILL_LIGHT,
  ELEMENT_NODE_GREY_COLOR,
  ELEMENT_NODE_GREY_FILL,
  ELEMENT_NODE_GREY_FILL_LIGHT,
  ELEMENT_NODE_GREY_STROKE,
  ELEMENT_NODE_GREY_STROKE_LIGHT,
  ELEMENT_NODE_HEADER_COLOR,
  ELEMENT_NODE_NOVELTY_FILL_LIGHT,
  ELEMENT_NODE_NOVELTY_STROKE,
  ELEMENT_NODE_NOVELTY_STROKE_LIGHT,
  ELEMENT_NODE_STROKE,
  ELEMENT_VERSION_CATEGORY_ARTICLE,
  ELEMENT_VERSION_CATEGORY_ARTICLE_LIGHT,
  ELEMENT_VERSION_CATEGORY_COMPOSITION,
  ELEMENT_VERSION_CATEGORY_COMPOSITION_LIGHT,
  ELEMENT_VERSION_CATEGORY_MACHINE,
  ELEMENT_VERSION_CATEGORY_MACHINE_LIGHT,
  ELEMENT_VERSION_CATEGORY_PROCESS,
  ELEMENT_VERSION_CATEGORY_PROCESS_LIGHT,
  ELEMENT_VERSION_NODE_STROKE,
  PLACEHOLDER_FILL,
  PLACEHOLDER_FILL_DARK,
  PLACEHOLDER_STROKE,
  PLACEHOLDER_STROKE_DARK,
  PLACEHOLDER_TEXT,
  PLACEHOLDER_TEXT_DARK,
  WARNING_NODE_FILL,
  WARNING_NODE_STROKE,
} from '../../../constants/colors';
import { COMPONENT_PATH, INSTANCE_PATH } from '../../../constants/icon-paths';
import { action, computed } from '@ember/object';
import {
  getActiveFeature,
  getShowingElementVersionSelector,
} from '../../../selectors/invention-ui';
import {
  getPreferredElementVersionId,
  getProduct,
} from '../../../selectors/product';

/* eslint-disable ember/use-brace-expansion */
import Component from '@glimmer/component';
import ENV from '../../../config/environment';
import Konva from 'konva';
import { connect } from 'ember-redux';
import { getComponentInstanceOf } from '../../../selectors/component';
import { getDrawing } from '../../../selectors/drawing';
import { getElement } from '../../../selectors/element';
import { getElementVersion } from '../../../selectors/element-version';
import { getFeature } from '../../../selectors/feature';
import { getMarker } from '../../../selectors/marker';
import { getMentionsContent } from '../../../selectors/mention';
import { getMethod } from '../../../selectors/method';
import { getMethodNode } from '../../../selectors/method-node';
import { getMethodNodeOrdinal } from '../../../selectors/invention';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency-decorators';
import { textValue } from '../../../utils/string';

// import { getPreferredElementVersion } from '../../../selectors/product';

const dispatchToActions = {};

const stateToComputed = (state, attrs) => ({
  element: getElement(state, attrs.elementId),
  instanceOf: getComponentInstanceOf(state, attrs.componentId),
  activeFeatureId: getActiveFeature(state),
  showingElementVersionSelector: getShowingElementVersionSelector(state),
  product: getProduct(state, attrs.productId),
});

class InventionGraphElement extends Component {
  @service redux;
  @service referencesCache;
  @service figuresCache;
  @service models;
  @service store;
  @service settings;

  fontFamily = 'Inter';
  verticalPadding = 19.5;
  horizontalPadding = 19.5;
  borderRadius = 26;
  radius = 200;
  height = 400;
  width = 400;
  _isNovel = false;
  _isNovelAncestor = false;
  _isCollapsed = false;
  _visibleAreaIndex = 0;
  _elementIsSelected = '';
  _x = 0;
  _y = 0;
  _reachedScaleThreshold = false;

  constructor(owner, args) {
    super(owner, args);
    this.onScheduleRender = this.args.onScheduleRender;
    this.elementsLayer = this.args.elementsLayer;
    this.interactiveLayer = this.args.interactiveLayer;
  }

  @action
  didInsert() {
    this._showArrow = this.showArrow;
    this._isNovel = this.args.isNovel;
    this._isNovelAncestor = this.args.isNovelAncestor;
    this._isDisconnected = this.args.isDisconnected;
    this._isCollapsed = this.args.isCollapsed;
    this._visibleAreaIndex = this.args.visibleAreaIndex;
    this._elementIsSelected = this.elementIsSelected;
    this._elementVersionIsSelected = this.elementVersionIsSelected;
    this._elementVersionPlaceholderIsSelected =
      this.elementVersionPlaceholderIsSelected;
    this._isSelectedChild = this.args.isSelectedChild;
    this._x = this.args.x;
    this._y = this.args.y;
    this._reachedScaleThreshold = this.args.reachedScaleThreshold;
    this._solutionsString = this.solutionsString;
    this._referenceId = this.referenceId;
    this._referenceUpdatedAt = this.referenceUpdatedAt;
    this._name = this.name;
    this._elementVersionName = this.elementVersionName;
    this._showElementPlaceholder = this.showElementVersionPlaceholder;
    this._showElementVersion = this.showElementVersion;
    this._showElementVersionTwo = this.showElementVersionTwo;
    this._showElementVersionThree = this.showElementVersionThree;
    this._outcome = this.outcome;
    this._outcomeIsTraversed = this.outcomeIsTraversed;
    this._methodNodeOrdinal = this.methodNodeOrdinal;
    this._showOutcome = this.showOutcome;
    this._showDrawing = this.showDrawing;
    this._showDrawingPlaceholder = this.showDrawingPlaceholder;
    this._showMethod = this.showMethod;
    this._methodUpdatedAt = this.methodUpdatedAt;
    this._known = this.known;
    this._elementVersionCategory = this.elementVersionCategory;
    this._elementVersionIsSelected = this.elementVersionIsSelected;
    this.activeLayer = this.args.isSelected
      ? this.interactiveLayer
      : this.elementsLayer;
    this._isPrimaryInstance = this.args.isPrimaryInstance;
    this._isInstance = this.isInstance;
    this._successEventIsSelected = this.successEventIsSelected;
    this._darkMode = this.settings.darkMode;
    this._blackAndWhiteMode = this.settings.blackAndWhiteMode;
    this.setup();
  }

  @action
  willDestroyNode() {
    // remove events
    if (this.args.onContextClick) {
      this.elementBackgroundNode.off('contextmenu');
    }
    if (this.args.onElementVersionContextClick) {
      this.elementVersionNode.off('contextmenu');
    }
    if (this.args.onClick) {
      this.elementBackgroundNode.off('click');
    }
    if (this.args.onDragStart) {
      this.elementNode.off('dragstart');
    }
    if (this.args.onDragMove) {
      this.elementNode.off('dragmove');
    }
    if (this.args.onDragEnd) {
      this.elementNode.off('dragend');
    }
    if (this.args.onMouseenter) {
      this.elementNode.off('mouseenter');
    }
    if (this.args.onMouseleave) {
      this.elementNode.off('mouseleave');
    }
    if (this.args.onElementVersionPlaceholderClick) {
      this.elementVersionPlaceholderNode.off('click');
    }
    if (this.args.onElementVersionClick) {
      this.elementVersionBackgroundNode.off('click');
      this.knownElementVersionBackgroundNode.off('click');
    }
    if (this.args.onSuccessEventClick) {
      this.outcomeNode.off('click');
      this.outcomePlaceholderNode.off('click');
    }
    if (this.args.onDrawingClick) {
      this.drawingNode.off('click');
      this.drawingPlaceholderNode.off('click');
    }
    if (this.args.onMethodClick) {
      this.methodNode.off('click');
    }
    this.elementNode.destroy();
    this.onScheduleRender();
  }

  get styleNamespace() {
    return podNames['invention-graph-element-konva'];
  }

  get classNames() {
    let classNames = [this.styleNamespace];
    if (this.args.isSelected) classNames.push('is-selected');
    if (this.args.isCollapsed) classNames.push('is-collapsed');
    if (this.args.isDisconnected) classNames.push('is-disconnected');
    return classNames.join(' ');
  }

  get hasMultipleElementVersions() {
    return (
      this.args.elementVersionsList && this.args.elementVersionsList.length > 1
    );
  }

  @computed('element.{component,instanceOf}')
  get isInstance() {
    return this.element && this.element.component && this.element.instanceOf
      ? true
      : false;
  }

  @computed('args.name')
  get name() {
    return this.args.name;
  }

  @computed('element.useDefaultRequirements')
  get useDefaultRequirements() {
    return this.element && this.element.useDefaultRequirements;
  }

  @computed('element.useDefaultOutcome')
  get useDefaultOutcome() {
    return this.element && this.element.useDefaultOutcome;
  }

  // eslint-disable-next-line ember/require-computed-property-dependencies
  @computed('args.elementVersionsList.[]', 'args.{productId,elementId}')
  get showElementVersionPlaceholder() {
    const state = this.redux.getState();

    const preferredElementVersion = getPreferredElementVersionId(
      state,
      this.args.elementId,
      this.args.productId
    );

    return this.args.productId && !preferredElementVersion ? true : false;
  }

  @computed('element.elementVersion')
  get parentElementVersionId() {
    return this.element && this.element.elementVersion;
  }

  @computed('parentElementVersionId')
  get parentElementVersion() {
    const state = this.redux.getState();
    return (
      this.parentElementVersionId &&
      getElementVersion(state, this.parentElementVersionId)
    );
  }

  @computed('parentElementVersion.method')
  get parentMethodId() {
    return this.parentElementVersion && this.parentElementVersion.method;
  }

  @computed('parentMethodId')
  get parentMethodModel() {
    return (
      this.parentMethodId &&
      this.store.peekRecord('method', this.parentMethodId)
    );
  }

  @computed('parentMethodModel.updatedAt')
  get parentMethodUpdatedAt() {
    return this.parentMethodModel && this.parentMethodModel.updatedAt;
  }

  @computed(
    'parentMethodId',
    'methodNodeId',
    'outcomeIsTraversed',
    'parentMethodModel.traversedNodesList.[]'
  )
  get methodNodeOrdinal() {
    const state = this.redux.getState();
    return (
      this.outcomeIsTraversed &&
      this.methodNodeId &&
      getMethodNodeOrdinal(
        state,
        this.parentMethodId,
        this.methodNodeId,
        this.parentMethodModel.traversedNodesList
      )
    );
  }

  @computed('args.elementId', 'parentMethodModel.methodNodesList.[]')
  get methodNodeId() {
    const state = this.redux.getState();
    return (
      this.parentMethodModel &&
      this.parentMethodModel.methodNodesList.find((methodNodeId) => {
        const methodNode = getMethodNode(state, methodNodeId);
        return methodNode.element === this.args.elementId;
      })
    );
  }

  @computed('args.elementId', 'parentMethodModel.traversedNodesList.[]')
  get outcomeIsTraversed() {
    const state = this.redux.getState();
    return this.parentMethodModel &&
      this.parentMethodModel.traversedNodesList.find((methodNodeId) => {
        const methodNode = getMethodNode(state, methodNodeId);
        return methodNode.element === this.args.elementId;
      })
      ? true
      : false;
  }

  @computed('outcomeIsTraversed')
  get outcomeDashEnabled() {
    let outcomeDashEnabled = this.outcomeIsTraversed ? false : true;
    return outcomeDashEnabled;
  }

  get outcomeStrokeColor() {
    return this.textColor;
  }

  get outcomeFillColor() {
    return '#FFFFFF';
  }

  // eslint-disable-next-line ember/require-computed-property-dependencies
  @computed(
    'args.{elementVersionsList.[],preferredElementVersionsList.[],productId,elementId}'
  )
  get elementVersionId() {
    const state = this.redux.getState();
    let elementVersionId;
    if (this.args.productId) {
      elementVersionId = getPreferredElementVersionId(
        state,
        this.args.elementId,
        this.args.productId
      );
    }
    return elementVersionId;
  }

  @computed('elementVersion.known')
  get elementVersionIsKnown() {
    return this.elementVersion ? this.elementVersion.known : false;
  }

  @computed('elementVersionIsKnown')
  get elementVersionIsntKnown() {
    return !this.elementVersionIsKnown;
  }

  @computed('elementVersion.category')
  get elementVersionCategory() {
    return this.elementVersion && this.elementVersion.category;
  }

  @computed('elementVersionTwo.category')
  get elementVersionTwoCategory() {
    return this.elementVersionTwo && this.elementVersionTwo.category;
  }

  @computed('elementVersionThree.category')
  get elementVersionThreeCategory() {
    return this.elementVersionThree && this.elementVersionThree.category;
  }

  @computed('elementVersionId', 'args.elementVersionsList.[]')
  get solutionsString() {
    return (
      this.args.elementVersionsList &&
      this.elementVersionId &&
      this.elementVersionId + this.args.elementVersionsList.join(',')
    );
  }

  @computed('elementVersion.known')
  get known() {
    return this.elementVersion && this.elementVersion.known;
  }

  @computed('elementVersionId')
  get elementVersion() {
    const state = this.redux.getState();
    return (
      this.elementVersionId && getElementVersion(state, this.elementVersionId)
    );
  }

  @computed('args.elementVersionsList', 'elementVersionId')
  get elementVersionTwo() {
    const state = this.redux.getState();
    const elementVersionsList = this.args.elementVersionsList.filter(
      (elementVersionId) => elementVersionId !== this.elementVersionId
    );
    const elementVersionTwoId = elementVersionsList[0];
    return elementVersionTwoId && getElementVersion(state, elementVersionTwoId);
  }

  @computed('elementVersionTwo', 'args.productId')
  get showElementVersionTwo() {
    return this.args.productId && this.elementVersionTwo ? true : false;
  }

  @computed('args.elementVersionsList', 'elementVersionId')
  get elementVersionThree() {
    const state = this.redux.getState();
    const elementVersionsList = this.args.elementVersionsList.filter(
      (elementVersionId) => elementVersionId !== this.elementVersionId
    );
    const elementVersionThreeId = elementVersionsList[1];
    return (
      elementVersionThreeId && getElementVersion(state, elementVersionThreeId)
    );
  }

  @computed('elementVersionThree', 'args.productId')
  get showElementVersionThree() {
    return this.args.productId && this.elementVersionThree ? true : false;
  }

  @computed('elementVersion.name')
  get elementVersionName() {
    // return this.elementVersion ? `(${this.elementVersion.name})` : '';
    return this.elementVersion ? this.elementVersion.name : '';
  }

  // get outcomeModel() {
  //   const state = this.redux.getState();
  //   const featureId = this.args.outcome;
  //   const feature = getFeature(state, featureId);
  //   return feature;
  // }

  @computed(
    'isInstance',
    'instanceOf.outcome',
    'element.outcome',
    'useDefaultOutcome'
  )
  get outcomeId() {
    let outcomeId = this.element.outcome;
    if (this.isInstance && this.useDefaultOutcome && this.instanceOf) {
      outcomeId = this.instanceOf.outcome;
    }

    return outcomeId;
  }

  @computed('outcomeId', 'activeFeatureId')
  get successEventIsSelected() {
    return this.outcomeId === this.activeFeatureId;
  }

  @computed(
    'args.{isSelected,productId}',
    'successEventIsSelected',
    'elementVersionIsSelected',
    'showingElementVersionSelector'
  )
  get elementIsSelected() {
    let elementIsSelected =
      this.args.isSelected && !this.successEventIsSelected;

    if (this.args.productId) {
      elementIsSelected =
        this.args.isSelected &&
        !this.successEventIsSelected &&
        !this.elementVersionIsSelected &&
        !this.showingElementVersionSelector;
    }
    return elementIsSelected;
  }

  @computed('args.isSelected', 'showingElementVersionSelector')
  get elementVersionPlaceholderIsSelected() {
    return this.args.isSelected && this.showingElementVersionSelector;
  }

  @computed(
    'args.selectedElementVersions.[]',
    'elementVersionId',
    'args.isSelected'
  )
  get elementVersionIsSelected() {
    return this.elementVersionId &&
      // this.args.isSelected &&
      this.args.selectedElementVersions &&
      this.args.selectedElementVersions.includes(this.elementVersionId)
      ? true
      : false;
  }

  @computed('outcomeId')
  get outcomeModel() {
    const state = this.redux.getState();
    const featureId = this.outcomeId;
    const feature = getFeature(state, featureId);
    return (
      this.outcomeId &&
      (this.models.find(this.outcomeId) ||
        this.models.findOrCreate(feature.id, 'feature', feature))
    );
  }

  @computed('outcomeModel.{id,value}')
  get outcome() {
    const state = this.redux.getState();
    let value = (this.outcomeModel && this.outcomeModel.value) || '';
    if (value) {
      value = getMentionsContent(
        state,
        value,
        `feature-${this.outcomeModel.id}`,
        {
          appendOrdinal: false,
          appendElementVersion: false,
          isInput: true,
          isInteractive: false,
        }
      );
    }
    return textValue(value);
  }

  @computed('elementVersion.markersList.[]')
  get referenceId() {
    return (
      this.elementVersion &&
      this.elementVersion.markersList &&
      this.elementVersion.markersList.length &&
      this.elementVersion.markersList[0]
    );
  }

  @computed('args.{drawings,methods}', 'referenceId')
  get referenceSource() {
    let source;
    const state = this.redux.getState();
    const marker = this.referenceId && getMarker(state, this.referenceId);
    if (marker) {
      source = getDrawing(state, marker.drawing);
    }
    return source;
  }

  @computed('referenceSource.updatedAt', 'referenceId')
  get referenceUpdatedAt() {
    return (
      this.referenceId && this.referenceSource && this.referenceSource.updatedAt
    );
  }

  @computed('referenceId')
  get hasReference() {
    return this.referenceId ? true : false;
  }

  @computed('args.{elementId,nodesMap}')
  get siblings() {
    const id = this.args.elementId;
    const node = this.args.nodesMap && this.args.nodesMap[id];
    const siblings =
      node && node.parent && this.args.nodesMap[node.parent].children;
    return siblings;
  }

  @computed('siblings.[]')
  get hasSiblings() {
    return this.siblings && this.siblings.length > 1;
  }

  @computed('siblings.[]', 'args.elementId')
  get isLastSibling() {
    const index = this.siblings && this.siblings.indexOf(this.args.elementId);
    const length = this.siblings && this.siblings.length;
    return length && index === length - 1;
  }

  @computed('hasSiblings', 'isLastSibling', 'siblings.length')
  get showArrow() {
    const isLoneSibling = this.siblings.length === 1;
    return (isLoneSibling || (this.hasSiblings && !this.isLastSibling))
      ? true
      : false;
  }

  @computed('elementVersionId')
  get hasSolution() {
    return this.elementVersionId ? true : false;
  }

  // @computed('elementVersionId', 'args.elementVersionsList.[]')
  // get hasTwoSolutions() {
  //   return this.elementVersionId &&
  //     this.args.elementVersionsList &&
  //     this.args.elementVersionsList.length >= 2
  //     ? true
  //     : false;
  // }

  // @computed('elementVersionId', 'args.elementVersionsList.[]')
  // get hasThreeOrMoreSolutions() {
  //   return this.elementVersionId &&
  //     this.args.elementVersionsList &&
  //     this.args.elementVersionsList.length >= 3
  //     ? true
  //     : false;
  // }

  get textWidth() {
    return this.width - this.horizontalPadding * 2;
  }

  get elementVersionTextWidth() {
    return this.elementVersionTransform.width - this.horizontalPadding;
  }

  get collapsedTransform() {
    const x = 0;
    const y = this.height + 325;
    return { x, y };
  }

  get elementVersionTransform() {
    let x = -150;
    let y = 221;
    let width = 260;
    let height = 260;
    return {
      x,
      y,
      width,
      height,
    };
  }

  get elementVersionTwoTransform() {
    let x = -10;
    let y = -10;
    let width = 240;
    let height = 240;
    let rotation = -11;
    return {
      x,
      y,
      width,
      height,
      rotation,
    };
  }
  get elementVersionThreeTransform() {
    let x = -10;
    let y = -10;
    let width = 240;
    let height = 240;
    let rotation = -22;
    return {
      x,
      y,
      width,
      height,
      rotation,
    };
  }

  @computed('showMethod', 'elementVersionTransform.width')
  get drawingTransform() {
    let x = 0;
    let y = 40;
    let rotation = 0;
    let offset = {
      x: 0,
      y: 0,
    };
    let width = 210;
    let height = 277;

    if (this.showMethod) {
      x = 75;
      y = 48;
      rotation = 11;
      offset = {
        x: this.elementVersionTransform.width / 2 - 60,
        y: 0,
      };
    }
    return { x, y, rotation, offset, width, height };
  }

  get outcomeTransform() {
    const x = 20;
    const y = this.height / 2 / 2 - 10;
    const width = 320;
    return { x, y, width };
  }

  get outcomePlaceholderTransform() {
    const x = 20;
    const y = this.height / 2 / 2;
    const width = 320;
    const height = 125;
    return { x, y, width, height };
  }

  get methodTransform() {
    let x = -5;
    let y = 35;
    let rotation = -11;
    let offset = {
      x: this.elementVersionTransform.width / 2 + 80,
      y: 0,
    };
    let width = 210;
    let height = 277;
    return { x, y, rotation, offset, width, height };
  }

  get methodStrokeColor() {
    return this.placeholderStrokeColor;
  }

  @computed('elementVersion.method')
  get methodId() {
    return this.elementVersion && this.elementVersion.method;
  }

  @computed('methodId')
  get methodModel() {
    return this.methodId && this.store.peekRecord('method', this.methodId);
  }

  @computed('methodModel.updatedAt')
  get methodUpdatedAt() {
    return this.methodModel && this.methodModel.updatedAt;
  }

  @computed('methodHasDisconnectNodes')
  get methodDashEnabled() {
    return this.methodHasDisconnectNodes ? true : false;
  }

  @computed('methodModel.methodNodesList.[]')
  get methodHasNodes() {
    return this.methodModel && this.methodModel.methodNodesList.length > 1;
  }

  @computed('methodModel.disconnectedNodesList.[]')
  get methodHasDisconnectNodes() {
    return this.methodModel && this.methodModel.disconnectedNodesList.length
      ? true
      : false;
  }

  @computed('methodHasNodes')
  get showMethod() {
    return this.methodHasNodes ? true : false;
  }

  get requirements() {
    const state = this.redux.getState();
    return this.args.requirementsList.map((requirementId) => {
      const feature = getFeature(state, requirementId);
      return (
        this.models.find(requirementId) ||
        this.models.findOrCreate(feature.id, 'feature', feature)
      );
    });
  }

  @computed('requirements.@each.value', 'args.requirementsList.[]')
  get hasRequirements() {
    return this.requirements.filter((requirement) =>
      textValue(requirement.value || '')
    ).length
      ? true
      : false;
  }

  @computed('outcome')
  get hasOutcome() {
    return this.outcome && textValue(this.outcome || '') ? true : false;
  }

  @computed('hasOutcome')
  get showOutcome() {
    return this.hasOutcome;
  }

  @computed('showOutcome')
  get showOutcomePlaceholder() {
    return !this.showOutcome;
  }

  @computed('hasReference', 'elementVersionId')
  get showDrawing() {
    return this.elementVersionId && this.hasReference ? true : false;
  }

  @computed('elementVersionId', 'args.productId')
  get showElementVersion() {
    return this.args.productId && this.elementVersionId ? true : false;
  }

  @computed('elementVersionId', 'showDrawing', 'elementVersionCategory')
  get showDrawingPlaceholder() {
    let showDrawingPlaceholder =
      this.elementVersionId && !this.showDrawing ? true : false;
    if (this.elementVersionCategory === 'process') {
      showDrawingPlaceholder = false;
    }

    return showDrawingPlaceholder;
  }

  getNameNodeY(lines) {
    const lineHeight = 35 * 1.1;
    const nodeHeight = 400;
    return nodeHeight / 2 - (lines * lineHeight) / 2;
  }

  getSolutionNameNodeY(nameNodeY, nameNodeLines) {
    const paddingTop = 10;
    const nameNodeHeight = nameNodeLines * (35 * 1.1);
    return paddingTop + nameNodeY + nameNodeHeight;
  }

  outcomeBackgroundNodeHeight(lines) {
    const headerHeight = 0;
    let height = 190;
    switch (lines) {
      case 1:
        height = 60;
        break;
      case 2:
        height = 92;
        break;
      case 3:
        height = 125;
        break;
      case 4:
        height = 158;
        break;
      case 5:
        height = 190;
        break;
    }

    return height + headerHeight + 1;
  }

  get strokeColor() {
    let color = ELEMENT_NODE_STROKE;

    if (this.args.isDisconnected) {
      color = ELEMENT_NODE_GREY_STROKE;
    }
    if (this.settings.blackAndWhiteMode) {
      color = BLACK_AND_WHITE_STROKE;
    }
    return color;
  }

  get strokeColorLight() {
    let color = ELEMENT_COLLAPSED_NODE_STROKE;

    if (this.args.isCollapsed && this.args.isNovelAncestor) {
      color = ELEMENT_NODE_NOVELTY_STROKE_LIGHT;
    }
    if (this.args.isDisconnected) {
      color = ELEMENT_NODE_GREY_STROKE_LIGHT;
    }
    if (this.settings.blackAndWhiteMode) {
      color = BLACK_AND_WHITE_STROKE;
    }
    return color;
  }

  get strokeColorWarning() {
    let color = WARNING_NODE_STROKE;
    return color;
  }

  get noveltyNodeVisible() {
    return (
      this.args.isNovel || (this.args.isCollapsed && this.args.isNovelAncestor)
    );
  }

  get placeholderDash() {
    return [15, 10];
  }

  get placeholderFillColor() {
    let color = this.settings.darkMode
      ? PLACEHOLDER_FILL_DARK
      : PLACEHOLDER_FILL;

    if (this.settings.blackAndWhiteMode) {
      color = BLACK_AND_WHITE_FILL;
    }

    return color;
  }

  get placeholderStrokeColor() {
    let color = this.settings.darkMode
      ? PLACEHOLDER_STROKE_DARK
      : PLACEHOLDER_STROKE;

    if (this.settings.blackAndWhiteMode) {
      color = BLACK_AND_WHITE_STROKE;
    }

    return color;
  }

  get drawingStrokeColor() {
    let color = this.settings.darkMode ? '#777' : '#777';

    if (this.settings.blackAndWhiteMode) {
      color = BLACK_AND_WHITE_STROKE;
    }

    return color;
  }

  get placeholderSelectedStrokeColor() {
    let color = this.settings.darkMode
      ? PLACEHOLDER_STROKE_DARK
      : PLACEHOLDER_STROKE;

    if (this.settings.blackAndWhiteMode) {
      color = BLACK_AND_WHITE_STROKE;
    }

    return color;
  }

  get placeholderTextColor() {
    let color = this.settings.darkMode
      ? PLACEHOLDER_TEXT_DARK
      : PLACEHOLDER_TEXT;

    if (this.settings.blackAndWhiteMode) {
      color = BLACK_AND_WHITE_COLOR;
    }

    return color;
  }

  get outcomeArrowFill() {
    return this.settings.darkMode ? PLACEHOLDER_STROKE_DARK : '#000';
  }

  get outcomeHeaderFill() {
    return '#FFF';
  }

  get fillColor() {
    let color = ELEMENT_NODE_FILL;

    if (this.args.isDisconnected) {
      color = ELEMENT_NODE_GREY_FILL;
    }
    if (this.settings.blackAndWhiteMode) {
      color = BLACK_AND_WHITE_FILL;
    }
    return color;
  }

  get selectedNodeFill() {
    let color = ELEMENT_NODE_STROKE;

    if (this.args.isDisconnected) {
      color = ELEMENT_NODE_GREY_FILL;
    }

    return color;
  }

  get fillColorLight() {
    let color = ELEMENT_NODE_FILL_LIGHT;
    
    if (this.args.isDisconnected) {
      color = ELEMENT_NODE_GREY_FILL_LIGHT;
    }
    if (this.settings.blackAndWhiteMode) {
      color = BLACK_AND_WHITE_FILL;
    }
    return color;
  }

  get fillColorCollapsed() {
    let color = ELEMENT_NODE_FILL;

    if (this.args.isNovelAncestor) {
      color = ELEMENT_NODE_NOVELTY_FILL_LIGHT;
    }
    if (this.args.isDisconnected) {
      color = ELEMENT_NODE_GREY_FILL_LIGHT;
    }
    if (this.settings.blackAndWhiteMode) {
      color = BLACK_AND_WHITE_FILL;
    }
    return color;
  }

  get fillColorWarning() {
    let color = WARNING_NODE_FILL;
    if (this.args.isDisconnected) {
      color = ELEMENT_NODE_GREY_FILL_LIGHT;
    }
    if (this.settings.blackAndWhiteMode) {
      color = BLACK_AND_WHITE_FILL;
    }
    return color;
  }

  @computed(
    'elementVersionCategory',
    'args.isDisconnected',
    'settings.blackAndWhiteMode'
  )
  get elementVersionFillColor() {
    return this.getElementVersionFillColor(
      this.elementVersionCategory,
      this.args.isDisconnected
    );
  }

  @computed(
    'elementVersionTwoCategory',
    'args.isDisconnected',
    'settings.blackAndWhiteMode'
  )
  get elementVersionTwoFillColor() {
    return this.getElementVersionFillColor(
      this.elementVersionTwoCategory,
      this.args.isDisconnected
    );
  }

  @computed(
    'elementVersionThreeCategory',
    'args.isDisconnected',
    'settings.blackAndWhiteMode'
  )
  get elementVersionThreeFillColor() {
    return this.getElementVersionFillColor(
      this.elementVersionThreeCategory,
      this.args.isDisconnected
    );
  }

  @computed(
    'elementVersionCategory',
    'args.isDisconnected',
    'settings.blackAndWhiteMode'
  )
  get elementVersionStrokeColor() {
    return this.getElementVersionStrokeColor(
      this.elementVersionCategory,
      this.args.isDisconnected
    );
  }

  @computed(
    'elementVersionTwoCategory',
    'args.isDisconnected',
    'settings.blackAndWhiteMode'
  )
  get elementVersionTwoStrokeColor() {
    return this.getElementVersionStrokeColor(
      this.elementVersionTwoCategory,
      this.args.isDisconnected
    );
  }

  @computed(
    'elementVersionThreeCategory',
    'args.isDisconnected',
    'settings.blackAndWhiteMode'
  )
  get elementVersionThreeStrokeColor() {
    return this.getElementVersionStrokeColor(
      this.elementVersionThreeCategory,
      this.args.isDisconnected
    );
  }

  getElementVersionFillColor(category, isDisconnected) {
    let color = ELEMENT_NODE_GREY_FILL;

    if (category === 'machine') {
      color = ELEMENT_VERSION_CATEGORY_MACHINE_LIGHT;
    }
    if (category === 'process') {
      color = ELEMENT_VERSION_CATEGORY_PROCESS_LIGHT;
    }
    if (category === 'article-of-manufacture') {
      color = ELEMENT_VERSION_CATEGORY_ARTICLE_LIGHT;
    }
    if (category === 'composition') {
      color = ELEMENT_VERSION_CATEGORY_COMPOSITION_LIGHT;
    }

    if (isDisconnected) {
      color = ELEMENT_NODE_GREY_FILL;
    }
    if (this.settings.blackAndWhiteMode) {
      color = BLACK_AND_WHITE_FILL;
    }
    return color;
  }

  getElementVersionStrokeColor(category, isDisconnected) {
    let color = ELEMENT_VERSION_NODE_STROKE;

    if (category === 'machine') {
      color = ELEMENT_VERSION_CATEGORY_MACHINE;
    }
    if (category === 'process') {
      color = ELEMENT_VERSION_CATEGORY_PROCESS;
    }
    if (category === 'article-of-manufacture') {
      color = ELEMENT_VERSION_CATEGORY_ARTICLE;
    }
    if (category === 'composition') {
      color = ELEMENT_VERSION_CATEGORY_COMPOSITION;
    }

    if (isDisconnected) {
      color = ELEMENT_NODE_GREY_STROKE;
    }
    if (this.settings.blackAndWhiteMode) {
      color = BLACK_AND_WHITE_STROKE;
    }
    return color;
  }

  get textColor() {
    let color = ELEMENT_NODE_COLOR;

    if (this.args.isDisconnected) {
      color = ELEMENT_NODE_GREY_COLOR;
    }
    if (this.settings.blackAndWhiteMode) {
      color = BLACK_AND_WHITE_COLOR;
    }
    return color;
  }

  get headerTextColor() {
    let color = ELEMENT_NODE_HEADER_COLOR;

    if (this.args.isDisconnected) {
      color = ELEMENT_NODE_GREY_STROKE;
    }
    return color;
  }

  setup() {
    const elementNode = new Konva.Group({
      id: this.args.elementId,
      x: this.args.x,
      y: this.args.y,
      name: 'node',
      draggable: this.args.isDraggable ? true : false,
      category: 'system',
      nodeType: 'element',
      transformsEnabled: 'position',
    });

    const selectedNode = new Konva.Circle({
      id: `select-${this.args.elementId}`,
      radius: this.radius,
      fill: this.selectedNodeFill,
      stroke: this.selectedNodeFill,
      hitStrokeWidth: 0,
      strokeWidth: 26,
      visible: this.elementIsSelected ? true : false,
      listening: false,
      strokeScaleEnabled: false,
      transformsEnabled: 'position',
    });

    const selectedChildNode = new Konva.Circle({
      radius: this.radius,
      fill: this.selectedNodeFill,
      stroke: this.selectedNodeFill,
      hitStrokeWidth: 0,
      strokeWidth: 12,
      visible: this.args.isSelectedChild ? true : false,
      // opacity: 0.5,
      listening: false,
      strokeScaleEnabled: false,
      transformsEnabled: 'position',
    });

    const noveltyHighlightNode = new Konva.Circle({
      radius: 60,
      fill: ELEMENT_NODE_NOVELTY_STROKE,
      visible: this.noveltyNodeVisible,
      // opacity: 0.5,
      listening: false,
      // strokeScaleEnabled: false,
      transformsEnabled: 'position',
      offset: {
        x: (-1 * this.width) / 2 + 30,
        y: this.height / 2 - 40,
      },
    });

    const noveltyDotNode = new Konva.Circle({
      radius: 20,
      fill: ELEMENT_NODE_NOVELTY_STROKE,
      visible: this.noveltyNodeVisible,
      // opacity: 0.5,
      listening: false,
      transformsEnabled: 'position',
      offset: {
        x: (-1 * this.width) / 2 + 30,
        y: this.height / 2 - 40,
      },
    });

    const elementBackgroundNode = new Konva.Circle({
      radius: this.radius,
      fill: this.fillColor,
      stroke: this.strokeColor,
      hitStrokeWidth: 0,
      strokeWidth: 7,
      transformsEnabled: 'position',
      visible: true,
    });

    const methodNode = new Konva.Group({
      visible: this.showMethod,
      y: this.methodTransform.y,
      x: this.methodTransform.x,
      listening: true,
      rotation: this.methodTransform.rotation,
      offset: this.methodTransform.offset,
    });

    const methodBackgroundNode = new Konva.Rect({
      width: this.methodTransform.width,
      height: this.methodTransform.height,
      fill: '#FFF',
      stroke: this.methodStrokeColor,
      hitStrokeWidth: 0,
      strokeWidth: 3,
      transformsEnabled: 'position',
      listening: true,
      dash: this.placeholderDash,
      dashEnabled: this.methodDashEnabled,
    });

    const methodImageNode = new Konva.Image({
      x: 3,
      y: 3,
      width: this.methodTransform.width - 6,
      height: this.methodTransform.height - 6,
      transformsEnabled: 'position',
      listening: false,
      visible: !this._reachedScaleThreshold,
    });

    methodNode.add(methodBackgroundNode);
    methodNode.add(methodImageNode);

    const drawingPlaceholderNode = new Konva.Group({
      visible: this.showDrawingPlaceholder,
      y: this.drawingTransform.y,
      x: this.drawingTransform.x,
      listening: true,
      rotation: this.drawingTransform.rotation,
      offset: this.drawingTransform.offset,
    });

    const drawingPlaceholderBackgroundNode = new Konva.Rect({
      width: this.drawingTransform.width,
      height: this.drawingTransform.height,
      fill: this.placeholderFillColor,
      stroke: this.placeholderStrokeColor,
      hitStrokeWidth: 0,
      strokeWidth: 3,
      transformsEnabled: 'position',
      listening: true,
      dash: this.placeholderDash,
      dashEnabled: true,
    });

    const drawingPlaceholderTextNode = new Konva.Text({
      width: this.drawingTransform.width,
      height: this.drawingTransform.height,
      align: 'center',
      verticalAlign: 'middle',
      text: 'Drawing',
      fontSize: 24,
      lineHeight: 1.235,
      fontFamily: this.fontFamily,
      fill: this.placeholderTextColor,
      transformsEnabled: 'position',
      listening: false,
    });
    // }).cache();

    drawingPlaceholderNode.add(drawingPlaceholderBackgroundNode);
    drawingPlaceholderNode.add(drawingPlaceholderTextNode);

    const drawingNode = new Konva.Group({
      visible: this.showDrawing,
      y: this.drawingTransform.y,
      x: this.drawingTransform.x,
      listening: true,
      rotation: this.drawingTransform.rotation,
      offset: this.drawingTransform.offset,
    });

    const drawingStrokeNode = new Konva.Rect({
      width: this.drawingTransform.width,
      height: this.drawingTransform.height,
      stroke: this.drawingStrokeColor,
      hitStrokeWidth: 0,
      strokeWidth: 3,
      transformsEnabled: 'position',
      listening: false,
      visible: true,
    });

    const drawingBackgroundNode = new Konva.Rect({
      width: this.drawingTransform.width,
      height: this.drawingTransform.height,
      hitStrokeWidth: 0,
      fill: '#FFFFFF',
      transformsEnabled: 'position',
      listening: true,
      visible: true,
    });

    const drawingImageNode = new Konva.Image({
      width: this.drawingTransform.width,
      height: this.drawingTransform.height,
      transformsEnabled: 'position',
      listening: false,
      visible: !this._reachedScaleThreshold,
    });

    drawingNode.add(drawingBackgroundNode);
    drawingNode.add(drawingImageNode);
    drawingNode.add(drawingStrokeNode);

    const nameNode = new Konva.Text({
      width: this.textWidth,
      height: 400,
      align: 'center',
      // verticalAlign: 'middle',
      text: this.name,
      fontSize: 35,
      lineHeight: 1.1,
      fontFamily: this.fontFamily,
      fontStyle: '500',
      fill: this.textColor,
      transformsEnabled: 'position',
      listening: false,
      offset: {
        y: 200,
        x: this.textWidth / 2,
      },
    });

    const nameNodeY = this.getNameNodeY(nameNode.textArr.length);

    nameNode.y(nameNodeY);

    const elementVersionNode = new Konva.Group({
      x: this.elementVersionTransform.x,
      y: this.elementVersionTransform.y,
      nodeType: 'element-version',
      transformsEnabled: 'position',
      visible: this.showElementVersion ? true : false,
    });

    const elementVersionSelectedNode = new Konva.Rect({
      width: this.elementVersionTransform.width,
      height: this.elementVersionTransform.height,
      fill: this.elementVersionFillColor,
      stroke: this.elementVersionStrokeColor,
      hitStrokeWidth: 0,
      strokeWidth: 18,
      visible:
        this.elementVersionIsntKnown && this.elementVersionIsSelected
          ? true
          : false,
      listening: false,
      strokeScaleEnabled: false,
      transformsEnabled: 'position',
      offset: {
        x: this.elementVersionTransform.width / 2,
        y: this.elementVersionTransform.height / 2,
      },
    });

    const knownElementVersionSelectedNode = new Konva.RegularPolygon({
      radius: this.elementVersionTransform.width / 2,
      fill: this.elementVersionFillColor,
      stroke: this.elementVersionStrokeColor,
      hitStrokeWidth: 0,
      sides: 8,
      rotation: 22.5,
      strokeWidth: 18,
      visible:
        this.elementVersionIsKnown && this.elementVersionIsSelected
          ? true
          : false,
      listening: false,
      strokeScaleEnabled: false,
    });

    const elementVersionBackgroundNode = new Konva.Rect({
      width: this.elementVersionTransform.width,
      height: this.elementVersionTransform.height,
      fill: this.elementVersionFillColor,
      stroke: this.elementVersionStrokeColor,
      hitStrokeWidth: 0,
      strokeWidth: 6,
      transformsEnabled: 'position',
      visible: this.elementVersionIsntKnown,
      offset: {
        x: this.elementVersionTransform.width / 2,
        y: this.elementVersionTransform.height / 2,
      },
    });

    const knownElementVersionBackgroundNode = new Konva.RegularPolygon({
      radius: this.elementVersionTransform.width / 2,
      fill: this.elementVersionFillColor,
      stroke: this.elementVersionStrokeColor,
      sides: 8,
      rotation: 22.5,
      strokeWidth: 7,
      visible: this.elementVersionIsKnown,
    });

    const elementVersionTwoNode = new Konva.Rect({
      x: this.elementVersionTwoTransform.x,
      y: this.elementVersionTwoTransform.y,
      width: this.elementVersionTwoTransform.width,
      height: this.elementVersionTwoTransform.height,
      fill: this.elementVersionTwoFillColor,
      stroke: this.elementVersionTwoStrokeColor,
      hitStrokeWidth: 0,
      strokeWidth: 6,
      rotation: this.elementVersionTwoTransform.rotation,
      // transformsEnabled: 'position',
      visible: this.showElementVersionTwo,
      offset: {
        x: this.elementVersionTwoTransform.width / 2,
        y: this.elementVersionTwoTransform.height / 2,
      },
    });

    const elementVersionThreeNode = new Konva.Rect({
      x: this.elementVersionThreeTransform.x,
      y: this.elementVersionThreeTransform.y,
      width: this.elementVersionThreeTransform.width,
      height: this.elementVersionThreeTransform.height,
      fill: this.elementVersionThreeFillColor,
      stroke: this.elementVersionThreeStrokeColor,
      hitStrokeWidth: 0,
      strokeWidth: 6,
      rotation: this.elementVersionThreeTransform.rotation,
      // transformsEnabled: 'position',
      visible: this.showElementVersionThree,
      offset: {
        x: this.elementVersionThreeTransform.width / 2,
        y: this.elementVersionThreeTransform.height / 2,
      },
    });

    const elementVersionNameNode = new Konva.Text({
      width: this.elementVersionTextWidth,
      height: 60,
      align: 'center',
      verticalAlign: 'middle',
      ellipsis: true,
      text: this.elementVersionName,
      fontSize: 27,
      fontStyle: '500',
      lineHeight: 1.1,
      fontFamily: this.fontFamily,
      fill: this.textColor,
      transformsEnabled: 'position',
      listening: false,
      y: this.elementVersionTransform.height / 2 - 30,
      offset: {
        y: this.elementVersionTransform.height / 2,
        x: this.elementVersionTextWidth / 2,
      },
    });

    elementVersionNode.add(elementVersionThreeNode);
    elementVersionNode.add(elementVersionTwoNode);
    elementVersionNode.add(elementVersionSelectedNode);
    elementVersionNode.add(knownElementVersionSelectedNode);
    elementVersionNode.add(elementVersionBackgroundNode);
    elementVersionNode.add(knownElementVersionBackgroundNode);
    elementVersionNode.add(elementVersionNameNode);
    elementVersionNode.add(methodNode);
    elementVersionNode.add(drawingNode);
    elementVersionNode.add(drawingPlaceholderNode);

    const elementVersionPlaceholderNode = new Konva.Group({
      visible: this.showElementVersionPlaceholder,
      x: this.elementVersionTransform.x,
      y: this.elementVersionTransform.y,
      // rotation: 4,
      transformsEnabled: 'position',
    });

    const elementVersionPlaceholderSelectedNode = new Konva.Rect({
      width: this.elementVersionTransform.width,
      height: this.elementVersionTransform.height,
      fill: this.placeholderFillColor,
      stroke: this.placeholderSelectedStrokeColor,
      hitStrokeWidth: 0,
      strokeWidth: 18,
      visible: this.elementVersionPlaceholderIsSelected,
      // opacity: 0.5,
      listening: false,
      strokeScaleEnabled: false,
      transformsEnabled: 'position',
      offset: {
        x: this.elementVersionTransform.width / 2,
        y: this.elementVersionTransform.height / 2,
      },
    });

    const elementVersionPlaceholderBackgroundNode = new Konva.Rect({
      width: this.elementVersionTransform.width,
      height: this.elementVersionTransform.height,
      fill: this.placeholderFillColor,
      stroke: this.placeholderStrokeColor,
      hitStrokeWidth: 0,
      strokeWidth: 3,
      transformsEnabled: 'position',
      listening: true,
      dash: this.placeholderDash,
      dashEnabled: true,
      offset: {
        x: this.elementVersionTransform.width / 2,
        y: this.elementVersionTransform.height / 2,
      },
    });

    const elementVersionPlaceholderTextNode = new Konva.Text({
      width: this.elementVersionTransform.width,
      height: this.elementVersionTransform.height,
      align: 'center',
      verticalAlign: 'middle',
      text: 'Preferred Solution',
      fontSize: 24,
      lineHeight: 1.235,
      fontFamily: this.fontFamily,
      fill: this.placeholderTextColor,
      transformsEnabled: 'position',
      listening: false,
      offset: {
        x: this.elementVersionTransform.width / 2,
        y: this.elementVersionTransform.height / 2,
      },
    });
    // }).cache();

    elementVersionPlaceholderNode.add(elementVersionPlaceholderSelectedNode);
    elementVersionPlaceholderNode.add(elementVersionPlaceholderBackgroundNode);
    elementVersionPlaceholderNode.add(elementVersionPlaceholderTextNode);

    const outcomePlaceholderNode = new Konva.Group({
      visible: this.showOutcomePlaceholder,
      x: this.outcomePlaceholderTransform.x,
      y: this.outcomePlaceholderTransform.y,
      // rotation: 4,
      transformsEnabled: 'position',
    });

    const outcomePlaceholderSelectedNode = new Konva.Rect({
      width: this.outcomePlaceholderTransform.width,
      height: this.outcomePlaceholderTransform.height,
      fill: this.placeholderFillColor,
      stroke: this.placeholderSelectedStrokeColor,
      hitStrokeWidth: 0,
      strokeWidth: 18,
      visible: this.successEventIsSelected ? true : false,
      // opacity: 0.5,
      listening: false,
      strokeScaleEnabled: false,
      transformsEnabled: 'position',
    });

    const outcomePlaceholderBackgroundNode = new Konva.Rect({
      width: this.outcomePlaceholderTransform.width,
      height: this.outcomePlaceholderTransform.height,
      fill: this.placeholderFillColor,
      stroke: this.placeholderStrokeColor,
      hitStrokeWidth: 0,
      strokeWidth: 3,
      transformsEnabled: 'position',
      listening: true,
      dash: this.placeholderDash,
      dashEnabled: true,
    });

    const outcomePlaceholderTextNode = new Konva.Text({
      width: this.outcomePlaceholderTransform.width,
      height: this.outcomePlaceholderTransform.height,
      align: 'center',
      verticalAlign: 'middle',
      text: 'Success Event',
      fontSize: 24,
      lineHeight: 1.235,
      fontFamily: this.fontFamily,
      fill: this.placeholderTextColor,
      transformsEnabled: 'position',
      listening: false,
    });
    // }).cache();

    outcomePlaceholderNode.add(outcomePlaceholderSelectedNode);
    outcomePlaceholderNode.add(outcomePlaceholderBackgroundNode);
    outcomePlaceholderNode.add(outcomePlaceholderTextNode);

    const outcomeNode = new Konva.Group({
      visible: this.showOutcome,
      y: this.outcomeTransform.y,
      x: this.outcomeTransform.x,
      transformsEnabled: 'position',
    });

    const outcomeHeaderTextNode = new Konva.Text({
      width: this.outcomeTransform.width,
      text: this.methodNodeOrdinal || '',
      fontSize: 22,
      lineHeight: 1.235,
      fontFamily: this.fontFamily,
      fill: this.outcomeHeaderFill,
      transformsEnabled: 'position',
      listening: false,
      align: 'right',
      x: 0,
      y: -32,
      visible: this.methodNodeOrdinal ? true : false,
    });

    const outcomeTextNode = new Konva.Text({
      width: this.outcomeTransform.width - 25,
      height: 170,
      ellipsis: true,
      text: textValue(this.outcome || ''),
      fontSize: 26,
      lineHeight: 1.235,
      fontFamily: this.fontFamily,
      fill: this.textColor,
      transformsEnabled: 'position',
      listening: false,
      visible: this._reachedScaleThreshold ? false : true,
      x: 20,
      y: 14,
    }).cache();

    const outcomeBackgroundNodeHeight = this.outcomeBackgroundNodeHeight(
      outcomeTextNode.textArr.length
    );

    const outcomeBackgroundNode = new Konva.Rect({
      width: this.outcomeTransform.width,
      height: outcomeBackgroundNodeHeight,
      fill: this.outcomeFillColor,
      stroke: this.outcomeStrokeColor,
      hitStrokeWidth: 0,
      strokeWidth: 3,
      transformsEnabled: 'position',
      listening: true,
      dash: [15, 10],
      dashEnabled: this.outcomeDashEnabled,
    });

    const outcomeArrowNode = new Konva.Group({
      transformsEnabled: 'position',
      listening: false,
      y: outcomeBackgroundNodeHeight / 2,
      x: this.outcomeTransform.width,
      visible: this.showArrow ? true : false,
      // visible: false,
    });

    const outcomeArrowArrowNode = new Konva.Arrow({
      points: [0, 0, 40, 0],
      stroke: this.outcomeArrowFill,
      hitStrokeWidth: 0,
      // rotation: -4,
      strokeWidth: 7,
      listening: false,
      x: 7,
    });

    outcomeArrowNode.add(outcomeArrowArrowNode);

    const outcomeSelectedNode = new Konva.Rect({
      width: this.outcomeTransform.width,
      height: outcomeBackgroundNodeHeight,
      fill: this.strokeColor,
      stroke: this.strokeColor,
      hitStrokeWidth: 0,
      strokeWidth: 18,
      visible: this.successEventIsSelected ? true : false,
      // opacity: 0.5,
      listening: false,
      strokeScaleEnabled: false,
      transformsEnabled: 'position',
    });

    outcomeNode.add(outcomeSelectedNode);
    outcomeNode.add(outcomeBackgroundNode);
    outcomeNode.add(outcomeHeaderTextNode);
    outcomeNode.add(outcomeTextNode);
    outcomeNode.add(outcomeArrowNode);

    const collapsedNode = new Konva.Circle({
      visible: this.args.isCollapsed,
      x: 15,
      y: 7.5,
      radius: this.radius,
      fill: this.fillColorCollapsed,
      stroke: this.strokeColorLight,
      hitStrokeWidth: 0,
      strokeWidth: 7,
      transformsEnabled: 'position',
    });

    const collapsedTwoNode = new Konva.Circle({
      visible: this.args.isCollapsed,
      x: 30,
      y: 15,
      radius: this.radius,
      fill: this.fillColorCollapsed,
      stroke: this.strokeColorLight,
      hitStrokeWidth: 0,
      strokeWidth: 7,
      transformsEnabled: 'position',
    });

    const collapsedThreeNode = new Konva.Circle({
      visible: this.args.isCollapsed,
      x: 45,
      y: 22.5,
      radius: this.radius,
      fill: this.fillColorCollapsed,
      stroke: this.strokeColorLight,
      hitStrokeWidth: 0,
      strokeWidth: 7,
      transformsEnabled: 'position',
    });

    const componentNode = new Konva.Group({
      x: 0,
      y: 0,
      transformsEnabled: 'position',
      visible: this.isInstance ? true : false,
      offset: {
        x: this.width / 2 - 60,
        y: this.height / 2 - 40,
      },
    });

    const componentNodeBackgroundNode = new Konva.Circle({
      radius: 34,
      fill: this.fillColorLight,
      stroke: this.strokeColor,
      hitStrokeWidth: 0,
      strokeWidth: 7,
      transformsEnabled: 'position',
    });

    const componentPrimaryNode = new Konva.Path({
      data: COMPONENT_PATH,
      fill: this.strokeColor,
      visible: this.args.isPrimaryInstance ? true : false,
      scale: {
        x: 3.5,
        y: 3.5,
      },
      offset: {
        x: 9,
        y: 7.5,
      },
    }).cache();

    const componentInstanceNode = new Konva.Path({
      data: INSTANCE_PATH,
      fill: this.strokeColor,
      visible: this.isInstance ? true : false,
      scale: {
        x: 3.5,
        y: 3.5,
      },
      offset: {
        x: 9,
        y: 7.5,
      },
    }).cache();

    componentNode.add(componentNodeBackgroundNode);
    componentNode.add(componentPrimaryNode);
    componentNode.add(componentInstanceNode);

    // TODO opacity kills end-to-end tests
    if (ENV.environment !== 'test') {
      selectedNode.opacity(0.5);
      selectedChildNode.opacity(0.35);
      elementVersionPlaceholderSelectedNode.opacity(0.5);
      elementVersionSelectedNode.opacity(0.5);
      knownElementVersionSelectedNode.opacity(0.5);
      noveltyHighlightNode.opacity(0.5);
      outcomeSelectedNode.opacity(0.5);
      outcomePlaceholderSelectedNode.opacity(0.5);
    }

    elementNode.add(selectedNode);
    elementNode.add(selectedChildNode);
    elementNode.add(collapsedThreeNode);
    elementNode.add(collapsedTwoNode);
    elementNode.add(collapsedNode);
    elementNode.add(elementBackgroundNode);
    elementNode.add(nameNode);
    elementNode.add(outcomePlaceholderNode);
    elementNode.add(outcomeNode);
    elementNode.add(elementVersionPlaceholderNode);
    elementNode.add(elementVersionNode);
    elementNode.add(noveltyHighlightNode);
    elementNode.add(noveltyDotNode);
    elementNode.add(componentNode);

    // add events
    if (this.args.onClick) {
      elementBackgroundNode.on('click', (e) => {
        const isRightClick = e.evt.altKey || e.evt.button === 2;
        this.args.onClick(
          this.args.elementId,
          this.args.isSelected,
          isRightClick
        );
      });
    }

    if (this.args.onContextClick) {
      elementBackgroundNode.on('contextmenu', (event) => {
        this.args.onContextClick(this.args.elementId, event);
      });
    }

    if (this.args.onDragStart) {
      elementNode.on('dragstart', (event) => {
        if (this.args.actionMode) {
          event.target.stopDrag();
        }
        this.args.onDragStart(event, this.args.elementId);
      });
    }

    if (this.args.onDragMove) {
      elementNode.on('dragmove', (event) => {
        if (this.args.actionMode) {
          return event.target.stopDrag();
        }
        this.args.onDragMove(event, this.args.elementId);
      });
    }

    if (this.args.onDragMove) {
      elementNode.on('dragend', (event) => {
        if (this.args.actionMode) {
          return;
        }
        this.args.onDragEnd(event, this.args.elementId);
      });
    }

    if (this.args.onMouseenter) {
      elementNode.on('mouseenter', (event) =>
        this.args.onMouseenter(event, this.args.elementId)
      );
    }

    if (this.args.onMouseleave) {
      elementNode.on('mouseleave', (event) =>
        this.args.onMouseleave(event, this.args.elementId)
      );
    }

    if (this.args.onElementVersionPlaceholderClick) {
      elementVersionPlaceholderNode.on('click', () =>
        this.args.onElementVersionPlaceholderClick(
          this.args.elementId,
          this.elementVersionId
        )
      );
    }

    this.elementsLayer.add(elementNode);

    elementNode.visible(elementNode.isClientRectOnScreen());

    this.methodNode = methodNode;
    this.methodBackgroundNode = methodBackgroundNode;
    this.methodImageNode = methodImageNode;
    this.drawingPlaceholderNode = drawingPlaceholderNode;
    this.drawingPlaceholderTextNode = drawingPlaceholderTextNode;
    this.drawingPlaceholderBackgroundNode = drawingPlaceholderBackgroundNode;
    this.drawingNode = drawingNode;
    // this.knownElementVersionSelectedNode = knownElementVersionSelectedNode;
    this.drawingBackgroundNode = drawingBackgroundNode;
    // this.knownElementVersionBackgroundNode = knownElementVersionBackgroundNode;
    this.drawingStrokeNode = drawingStrokeNode;
    this.drawingImageNode = drawingImageNode;
    this.nameNode = nameNode;
    this.collapsedNode = collapsedNode;
    this.collapsedTwoNode = collapsedTwoNode;
    this.collapsedThreeNode = collapsedThreeNode;
    this.outcomePlaceholderNode = outcomePlaceholderNode;
    this.outcomePlaceholderTextNode = outcomePlaceholderTextNode;
    this.outcomePlaceholderSelectedNode = outcomePlaceholderSelectedNode;
    this.outcomePlaceholderBackgroundNode = outcomePlaceholderBackgroundNode;
    this.outcomeNode = outcomeNode;
    this.outcomeBackgroundNode = outcomeBackgroundNode;
    this.outcomeSelectedNode = outcomeSelectedNode;
    this.outcomeHeaderTextNode = outcomeHeaderTextNode;
    this.outcomeTextNode = outcomeTextNode;
    this.outcomeArrowNode = outcomeArrowNode;
    this.outcomeArrowArrowNode = outcomeArrowArrowNode;
    this.elementBackgroundNode = elementBackgroundNode;
    this.selectedNode = selectedNode;
    this.selectedChildNode = selectedChildNode;
    this.noveltyHighlightNode = noveltyHighlightNode;
    this.noveltyDotNode = noveltyDotNode;
    this.elementNode = elementNode;
    this.elementVersionPlaceholderNode = elementVersionPlaceholderNode;
    this.elementVersionPlaceholderSelectedNode =
      elementVersionPlaceholderSelectedNode;
    this.elementVersionPlaceholderBackgroundNode =
      elementVersionPlaceholderBackgroundNode;
    this.elementVersionPlaceholderTextNode = elementVersionPlaceholderTextNode;
    this.elementVersionTwoNode = elementVersionTwoNode;
    this.elementVersionThreeNode = elementVersionThreeNode;
    this.elementVersionNode = elementVersionNode;
    this.elementVersionNameNode = elementVersionNameNode;
    this.elementVersionBackgroundNode = elementVersionBackgroundNode;
    this.knownElementVersionBackgroundNode = knownElementVersionBackgroundNode;
    this.elementVersionSelectedNode = elementVersionSelectedNode;
    this.knownElementVersionSelectedNode = knownElementVersionSelectedNode;
    this.componentNode = componentNode;
    this.componentNodeBackgroundNode = componentNodeBackgroundNode;
    this.componentPrimaryNode = componentPrimaryNode;
    this.componentInstanceNode = componentInstanceNode;
    // this.categoryMachineNode = categoryMachineNode;
    // this.categoryArticleNode = categoryArticleNode;
    // this.categoryProcessNode = categoryProcessNode;

    if (this.referenceId) {
      this.onUpdateReferenceImage.perform(this.referenceId);
    } else {
      this.onUpdateReferenceImage.perform(null);
    }

    if (this.methodId) {
      this.onUpdateMethodImage.perform(this.methodId);
    }

    // this.onUpdatePlaceholderImages.perform();

    this.onScheduleRender();
  }

  handleSelected() {
    this.selectedNode.visible(this.elementIsSelected ? true : false);
    this.onScheduleRender();
  }

  handleDeselected() {
    this.selectedNode.visible(false);
    this.onScheduleRender();
  }

  updateColors() {
    this.selectedNode.stroke(this.strokeColor);
    this.elementBackgroundNode.fill(this.fillColor);
    this.elementBackgroundNode.stroke(this.strokeColor);

    this.elementVersionBackgroundNode.fill(this.elementVersionFillColor);
    this.elementVersionBackgroundNode.stroke(this.elementVersionStrokeColor);

    this.elementVersionTwoNode.fill(this.elementVersionTwoFillColor);
    this.elementVersionTwoNode.stroke(this.elementVersionTwoStrokeColor);

    this.elementVersionThreeNode.fill(this.elementVersionThreeFillColor);
    this.elementVersionThreeNode.stroke(this.elementVersionThreeStrokeColor);

    this.elementVersionSelectedNode.fill(this.elementVersionFillColor);
    this.elementVersionSelectedNode.stroke(this.elementVersionStrokeColor);

    this.knownElementVersionBackgroundNode.fill(this.elementVersionFillColor);
    this.knownElementVersionBackgroundNode.stroke(
      this.elementVersionStrokeColor
    );

    this.knownElementVersionSelectedNode.fill(this.elementVersionFillColor);
    this.knownElementVersionSelectedNode.stroke(this.elementVersionStrokeColor);

    // this.categoryMachineNode.fill(this.elementVersionStrokeColor);
    // this.categoryArticleNode.fill(this.elementVersionStrokeColor);
    // this.categoryProcessNode.fill(this.elementVersionStrokeColor);

    this.nameNode.fill(this.textColor);

    this.outcomeTextNode.cache();
    this.outcomeTextNode.fill(this.textColor);
    this.outcomeTextNode.clearCache();

    this.outcomeSelectedNode.stroke(this.strokeColor);
    this.outcomeSelectedNode.fill(this.strokeColor);

    // this.outcomeBackgroundNode.fill(this.fillColorLight);
    // this.outcomeBackgroundNode.stroke(this.textColor);
    this.collapsedNode.fill(this.fillColorCollapsed);
    this.collapsedNode.stroke(this.strokeColorLight);
    this.collapsedTwoNode.fill(this.fillColorCollapsed);
    this.collapsedTwoNode.stroke(this.strokeColorLight);
    this.collapsedThreeNode.fill(this.fillColorCollapsed);
    this.collapsedThreeNode.stroke(this.strokeColorLight);

    this.componentNodeBackgroundNode.fill(this.fillColorLight);
    this.componentNodeBackgroundNode.stroke(this.strokeColor);

    this.componentPrimaryNode.clearCache();
    this.componentPrimaryNode.fill(this.strokeColor);
    this.componentPrimaryNode.cache();

    this.componentInstanceNode.clearCache();
    this.componentInstanceNode.fill(this.strokeColor);
    this.componentInstanceNode.cache();

    this.methodBackgroundNode.stroke(this.methodStrokeColor);

    this.drawingStrokeNode.stroke(this.drawingStrokeColor);
    this.drawingPlaceholderBackgroundNode.fill(this.placeholderFillColor);
    this.drawingPlaceholderBackgroundNode.stroke(this.placeholderStrokeColor);
    this.drawingPlaceholderTextNode.fill(this.placeholderTextColor);

    this.elementVersionPlaceholderSelectedNode.fill(this.placeholderFillColor);
    this.elementVersionPlaceholderSelectedNode.stroke(
      this.placeholderStrokeColor
    );
    this.elementVersionPlaceholderTextNode.fill(this.placeholderTextColor);
    this.elementVersionPlaceholderBackgroundNode.fill(
      this.placeholderFillColor
    );
    this.elementVersionPlaceholderBackgroundNode.stroke(
      this.placeholderStrokeColor
    );

    this.outcomePlaceholderSelectedNode.fill(this.placeholderFillColor);
    this.outcomePlaceholderSelectedNode.stroke(this.placeholderStrokeColor);

    this.outcomePlaceholderTextNode.fill(this.placeholderTextColor);
    this.outcomePlaceholderBackgroundNode.fill(this.placeholderFillColor);
    this.outcomePlaceholderBackgroundNode.stroke(this.placeholderStrokeColor);
    this.outcomeHeaderTextNode.fill(this.outcomeHeaderFill);
    this.outcomeArrowArrowNode.stroke(this.placeholderStrokeColor);
  }

  @task
  *onUpdateReferenceImage(referenceId) {
    let blobUrl;

    const state = this.redux.getState();

    if (getMarker(state, referenceId)) {
      blobUrl = yield this.referencesCache.getBlobUrl.perform(referenceId);
    }

    if (!blobUrl) {
      return;
    }

    const imageFile = new Image();

    imageFile.onload = () => {
      this.drawingImageNode.image(imageFile);
      this.drawingImageNode.cache();
      if (this.args.isDisconnected || this.settings.blackAndWhiteMode) {
        const filters = [Konva.Filters.Grayscale];
        this.drawingImageNode.filters(filters);
      }

      this.onScheduleRender();
    };

    imageFile.src = blobUrl;
  }

  @task
  *onUpdateMethodImage(methodId) {
    let blobUrl;

    const state = this.redux.getState();

    if (methodId && getMethod(state, methodId)) {
      blobUrl = yield this.figuresCache.getBlobUrl(methodId);
    }

    if (!blobUrl) {
      return;
    }

    const imageFile = new Image();

    imageFile.onload = () => {
      this.methodImageNode.image(imageFile);
      this.methodImageNode.cache();

      this.onScheduleRender();
    };

    imageFile.src = blobUrl;
  }

  // @task
  // // eslint-disable-next-line require-yield
  // *onUpdatePlaceholderImages() {
  //   let blobUrl = `./assets/img/placeholder-light.jpg`;

  //   const imageFile = new Image();

  //   imageFile.onload = () => {
  //     this.outcomePlaceholderBackgroundNode.fillPriority('pattern');
  //     this.outcomePlaceholderBackgroundNode.fillPatternImage(imageFile);

  //     this.onScheduleRender();
  //   };

  //   imageFile.src = blobUrl;
  // }

  @action
  updateVisibility() {
    this.elementNode.visible(this.elementNode.isClientRectOnScreen());
  }

  @action
  onUpdate(
    elem,
    [
      isNovel,
      isNovelAncestor,
      isDisconnected,
      isCollapsed,
      visibleAreaIndex,
      elementIsSelected,
      x,
      y,
      reachedScaleThreshold,
      solutionsString,
      hasReference,
      referenceUpdatedAt,
      name,
      elementVersionName,
      showElementVersionPlaceholder,
      showElementVersion,
      showElementVersionTwo,
      showElementVersionThree,
      outcome,
      outcomeIsTraversed,
      methodNodeOrdinal,
      showOutcome,
      showDrawing,
      showDrawingPlaceholder,
      showMethod,
      methodUpdatedAt,
      known,
      elementVersionCategory,
      elementVersionIsSelected,
      elementVersionPlaceholderIsSelected,
      isSelectedChild,
      isCreatingFrom,
      isCreatingTo,
      showArrow,
      isPrimaryInstance,
      isInstance,
      successEventIsSelected,
      darkMode,
      blackAndWhiteMode,
    ]
  ) {
    let detailsChanged = false;
    let visibleAreaChanged = false;

    if (this._showElementVersionPlaceholder !== showElementVersionPlaceholder) {
      this._showElementVersionPlaceholder = showElementVersionPlaceholder;
      if (showElementVersionPlaceholder) {
        this.elementVersionPlaceholderNode.visible(true);
      } else {
        this.elementVersionPlaceholderNode.visible(false);
      }
      this.onScheduleRender();
    }

    if (this._isDisconnected !== isDisconnected) {
      this._isDisconnected = isDisconnected;
      this.updateColors();
      const filters = isDisconnected ? [Konva.Filters.Grayscale] : [];
      this.drawingImageNode.filters(filters);
      detailsChanged = true;
    }

    if (this._isNovel !== isNovel) {
      this._isNovel = isNovel;
      this.noveltyHighlightNode.visible(this.noveltyNodeVisible);
      this.noveltyDotNode.visible(this.noveltyNodeVisible);
      this.updateColors();
      detailsChanged = true;
    }

    if (this._isNovelAncestor !== isNovelAncestor) {
      this._isNovelAncestor = isNovelAncestor;
      this.noveltyHighlightNode.visible(this.noveltyNodeVisible);
      this.noveltyDotNode.visible(this.noveltyNodeVisible);
      this.updateColors();
      detailsChanged = true;
    }

    if (this._isCollapsed !== isCollapsed) {
      this._isCollapsed = isCollapsed;
      this.collapsedNode.visible(isCollapsed);
      this.collapsedTwoNode.visible(isCollapsed);
      this.collapsedThreeNode.visible(isCollapsed);

      this.noveltyHighlightNode.visible(this.noveltyNodeVisible);
      this.noveltyDotNode.visible(this.noveltyNodeVisible);
      this.updateColors();
    }

    if (this._visibleAreaIndex !== visibleAreaIndex) {
      this._visibleAreaIndex = visibleAreaIndex;
      visibleAreaChanged = true;
    }

    if (visibleAreaChanged) {
      this.updateVisibility();
    }

    if (this._elementIsSelected !== elementIsSelected) {
      this._elementIsSelected = elementIsSelected;
      if (elementIsSelected) {
        this.handleSelected();
      } else {
        this.handleDeselected();
      }
    }

    if (this._solutionsString !== solutionsString) {
      this._solutionsString = solutionsString;
      this.onScheduleRender();
    }

    if (this._hasReference !== hasReference) {
      this._hasReference = hasReference;
      if (this.referenceId) {
        this.onUpdateReferenceImage.perform(this.referenceId);
      } else {
        this.onUpdateReferenceImage.perform(null);
      }
    }

    if (this._referenceUpdatedAt !== referenceUpdatedAt) {
      this._referenceUpdatedAt = referenceUpdatedAt;
      if (this.referenceId) {
        this.onUpdateReferenceImage.perform(this.referenceId);
      } else {
        this.onUpdateReferenceImage.perform(null);
      }
    }

    if (this._name !== name) {
      detailsChanged = true;
      this._name = name;
      // this.nameNode.clearCache();
      this.nameNode.text(name);
      const nameNodeLines = this.nameNode.textArr.length;
      const nameNodeY = this.getNameNodeY(nameNodeLines);
      this.nameNode.y(nameNodeY);
      // this.nameNode.cache();
    }

    if (this._elementVersionName !== elementVersionName) {
      detailsChanged = true;
      this._elementVersionName = elementVersionName;

      // this.elementVersionNameNode.clearCache();
      this.elementVersionNameNode.text(elementVersionName);
      // this.elementVersionNameNode.cache();
    }

    if (this._showElementVersion !== showElementVersion) {
      detailsChanged = true;
      this._showElementVersion = showElementVersion;
      this.elementVersionNode.visible(this.showElementVersion);
    }

    if (this._showElementVersionTwo !== showElementVersionTwo) {
      detailsChanged = true;
      this._showElementVersionTwo = showElementVersionTwo;

      this.elementVersionTwoNode.visible(this.showElementVersionTwo);
    }

    if (this._showElementVersionThree !== showElementVersionThree) {
      detailsChanged = true;
      this._showElementVersionThree = showElementVersionThree;

      this.elementVersionThreeNode.visible(this.showElementVersionThree);
    }

    const textOutcome = textValue(outcome || '');
    if (this._outcome !== textOutcome) {
      detailsChanged = true;
      this._outcome = textOutcome;
      this.outcomeTextNode.clearCache();
      this.outcomeTextNode.text(textOutcome);
      this.outcomeTextNode.cache();
      const outcomeHeight = this.outcomeBackgroundNodeHeight(
        this.outcomeTextNode.textArr.length
      );
      this.outcomeBackgroundNode.height(outcomeHeight);
      this.outcomeSelectedNode.height(outcomeHeight);
    }

    if (this._outcomeIsTraversed !== outcomeIsTraversed) {
      detailsChanged = true;
      this._outcomeIsTraversed = outcomeIsTraversed;
      this.outcomeBackgroundNode.fill(this.outcomeFillColor);
      this.outcomeBackgroundNode.stroke(this.outcomeStrokeColor);
      this.outcomeBackgroundNode.dashEnabled(this.outcomeDashEnabled);
    }

    if (this._methodNodeOrdinal !== methodNodeOrdinal) {
      detailsChanged = true;
      this._methodNodeOrdinal = methodNodeOrdinal;
      this.outcomeHeaderTextNode.visible(this.methodNodeOrdinal ? true : false);
      this.outcomeHeaderTextNode.text(this.methodNodeOrdinal);
    }

    if (this._showOutcome !== showOutcome) {
      this._showOutcome = showOutcome;
      this.outcomeNode.visible(this.showOutcome);
      this.outcomePlaceholderNode.visible(this.showOutcomePlaceholder);
      detailsChanged = true;
    }

    if (this._showDrawing !== showDrawing) {
      this._showDrawing = showDrawing;
      this.drawingNode.visible(this.showDrawing);
      this.onScheduleRender();
    }

    if (this._showDrawingPlaceholder !== showDrawingPlaceholder) {
      this._showDrawingPlaceholder = showDrawingPlaceholder;
      this.drawingPlaceholderNode.visible(this.showDrawingPlaceholder);
      this.onScheduleRender();
    }

    if (this._showMethod !== showMethod) {
      this._showMethod = showMethod;
      this.methodNode.visible(this.showMethod);
      this.onScheduleRender();
    }

    if (this._methodUpdatedAt !== methodUpdatedAt) {
      this._methodUpdatedAt = methodUpdatedAt;
      this.methodBackgroundNode.dashEnabled(this.methodDashEnabled);
      this.drawingNode.rotation(this.drawingTransform.rotation);
      this.drawingNode.offset(this.drawingTransform.offset);
      this.drawingPlaceholderNode.rotation(this.drawingTransform.rotation);
      this.drawingPlaceholderNode.offset(this.drawingTransform.offset);

      if (this.methodId) {
        this.onUpdateMethodImage.perform(this.methodId);
      }
      this.onScheduleRender();
    }

    if (this._known !== known) {
      this._known = known;
      detailsChanged = true;
      this.elementVersionBackgroundNode.visible(this.elementVersionIsntKnown);
      this.elementVersionSelectedNode.visible(
        this.elementVersionIsntKnown && elementVersionIsSelected
      );
      this.knownElementVersionBackgroundNode.visible(
        this.elementVersionIsKnown
      );
      this.knownElementVersionSelectedNode.visible(
        this.elementVersionIsKnown && elementVersionIsSelected
      );
    }

    if (this._elementVersionCategory !== elementVersionCategory) {
      this._elementVersionCategory = elementVersionCategory;
      if (this.referenceId) {
        this.onUpdateReferenceImage.perform(this.referenceId);
      }
      this.updateColors();
    }

    if (this._elementVersionIsSelected !== elementVersionIsSelected) {
      this._elementVersionIsSelected = elementVersionIsSelected;

      this.elementVersionSelectedNode.visible(
        this.elementVersionIsntKnown && this.elementVersionIsSelected
          ? true
          : false
      );

      this.knownElementVersionSelectedNode.visible(
        this.elementVersionIsKnown && this.elementVersionIsSelected
          ? true
          : false
      );
    }

    if (
      this._elementVersionPlaceholderIsSelected !==
      elementVersionPlaceholderIsSelected
    ) {
      this._elementVersionPlaceholderIsSelected =
        elementVersionPlaceholderIsSelected;

      this.elementVersionPlaceholderSelectedNode.visible(
        this.elementVersionPlaceholderIsSelected
      );
    }

    if (this._isSelectedChild !== isSelectedChild) {
      this._isSelectedChild = isSelectedChild;
      if (isSelectedChild) {
        this.selectedChildNode.visible(true);
      } else {
        this.selectedChildNode.visible(false);
      }
    }

    if (this._isCreatingFrom !== isCreatingFrom) {
      this._isCreatingFrom = isCreatingFrom;
      if (isCreatingFrom) {
        if (this.showElementVersion) {
          this.elementVersionSelectedNode.visible(
            this.elementVersionIsntKnown ? true : false
          );

          this.knownElementVersionSelectedNode.visible(
            this.elementVersionIsKnown ? true : false
          );
        } else {
          this.selectedNode.visible(true);
        }
        this.onScheduleRender();
      } else {
        if (!this._isCreatingFrom && !this.elementIsSelected) {
          this.selectedNode.visible(false);
        }
        if (!this._isCreatingFrom && !this.elementVersionIsSelected) {
          this.elementVersionSelectedNode.visible(false);

          this.knownElementVersionSelectedNode.visible(false);
        }
        this.onScheduleRender();
      }
    }

    if (this._isCreatingTo !== isCreatingTo) {
      this._isCreatingTo = isCreatingTo;
      if (isCreatingTo) {
        this.selectedNode.visible(true);
        this.onScheduleRender();
      } else {
        if (!this._isCreatingTo && !this._elementIsSelected) {
          this.selectedNode.visible(false);
          this.onScheduleRender();
        }
      }
    }

    if (this._x !== x || this._y !== y) {
      this._x = x;
      this._y = y;
      this.elementNode.x(x);
      this.elementNode.y(y);
      this.onScheduleRender();
    }

    if (this._reachedScaleThreshold !== reachedScaleThreshold) {
      this._reachedScaleThreshold = reachedScaleThreshold;
      detailsChanged = true;
      if (reachedScaleThreshold) {
        // this.outcomeHeaderTextNode.visible(false);
        this.outcomeTextNode.visible(false);
        this.drawingImageNode.visible(false);
        this.methodImageNode.visible(false);
        this.outcomePlaceholderTextNode.visible(false);
        this.drawingPlaceholderTextNode.visible(false);
        // this.categoryMachineNode.visible(false);
        // this.categoryArticleNode.visible(false);
        // this.categoryProcessNode.visible(false);
      } else {
        // this.outcomeHeaderTextNode.visible(true);
        this.outcomeTextNode.visible(true);
        this.drawingImageNode.visible(true);
        this.methodImageNode.visible(true);
        this.outcomePlaceholderTextNode.visible(true);
        this.drawingPlaceholderTextNode.visible(true);
        // this.categoryMachineNode.visible(
        //   this.elementVersionCategory === 'machine'
        // );
        // this.categoryArticleNode.visible(
        //   this.elementVersionCategory === 'article-of-manufacture'
        // );
        // this.categoryProcessNode.visible(
        //   this.elementVersionCategory === 'process'
        // );
      }
    }

    if (this._showArrow !== showArrow) {
      this._showArrow = showArrow;
      detailsChanged = true;
      this.outcomeArrowNode.visible(showArrow ? true : false);
    }

    if (this._isPrimaryInstance !== isPrimaryInstance) {
      this._isPrimaryInstance = isPrimaryInstance;
      this.componentPrimaryNode.visible(isPrimaryInstance);
      detailsChanged = true;
    }

    if (this._isInstance !== isInstance) {
      this._isInstance = isInstance;
      this.componentInstanceNode.visible(isInstance);
      detailsChanged = true;
    }

    if (this._successEventIsSelected !== successEventIsSelected) {
      this._successEventIsSelected = successEventIsSelected;
      this.outcomeSelectedNode.visible(successEventIsSelected);
      this.outcomePlaceholderSelectedNode.visible(successEventIsSelected);
      detailsChanged = true;
    }

    if (this._darkMode !== darkMode) {
      this._darkMode = darkMode;
      this.updateColors();
      detailsChanged = true;
    }

    if (this._blackAndWhiteMode !== blackAndWhiteMode) {
      this._blackAndWhiteMode = blackAndWhiteMode;
      this.updateColors();

      if (this.reference) {
        this.onUpdateReferenceImage.perform(
          this.reference.id,
          this.reference.type
        );
      } else {
        this.onUpdateReferenceImage.perform(null);
      }
      detailsChanged = true;
    }

    if (detailsChanged) {
      this.onScheduleRender(this.activeLayer);
    }
  }
}

export default connect(
  stateToComputed,
  dispatchToActions
)(InventionGraphElement);
