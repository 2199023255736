import { action, computed } from '@ember/object';
import { cancel, later } from '@ember/runloop';

import Component from '@glimmer/component';
import { connect } from 'ember-redux';
// import { batchGroupBy } from '../../../utils/redux';
import { getElement } from '../../../selectors/element';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';

const stateToComputed = () => ({});

const dispatchToActions = {};

class InventionGraphContextMenu extends Component {
  @service contextMenu;
  @service clipboard;
  @service redux;
  @service tracking;

  get styleNamespace() {
    return podNames['invention-graph-context-menu'];
  }

  get classNames() {
    let classNames = [this.styleNamespace, 'invention-graph-context-menu'];
    return classNames.join(' ');
  }

  @action
  didInsert() {
    this.tracking.trackEvent('opened_graph_context_menu');
  }

  calculateNestedPosition(trigger, menu) {
    const  windowHeight = window.innerHeight;
    const  windowWidth = window.innerWidth;
    const menuHeight = menu.getBoundingClientRect().height;
    const menuWidth = menu.getBoundingClientRect().width;

    let { top, left, width, height } = trigger.getBoundingClientRect();

    let style = {
      position: 'absolute',
      left: left + width,
      top: top,
    };

    if ((top + height + menuHeight) > windowHeight) {
      const bottom = windowHeight - (top + height);
      style.top = 'auto';
      style.bottom = `${bottom}px`;
    }

    if ((left + width + menuWidth) > windowWidth) {
      style.left = left - menuWidth;
    }

    return { style };
  }

  @computed('clipboard.elementId')
  get clipboardElementIsInstance() {
    const state = this.redux.getState();
    const element =
      this.clipboard.elementId && getElement(state, this.clipboard.elementId);
    return element && element.instanceOf ? true : false;
  }

  @computed('clipboardElementIsInstance')
  get canPasteInstance() {
    return this.clipboardElementIsInstance;
  }

  @computed('clipboard.instancesList.[]')
  get canPasteInstances() {
    return this.clipboard.instancesList && this.clipboard.instancesList.length ? true : false;
  }

  @computed('canPasteInstance')
  get cantPasteInstance() {
    return !this.canPasteInstance;
  }

  @computed('clipboard.elementId')
  get canPasteShallowElementDuplicate() {
    return this.clipboard.elementId ? true : false;
  }

  @computed('canPasteShallowElementDuplicate')
  get cantPasteShallowElementDuplicate() {
    return !this.canPasteShallowElementDuplicate;
  }

  @action
  onPasteInstance() {
    if (this.canPasteInstance) {
      if (this.args.onPasteInstance) {
        const x = this.contextMenu.stageX;
        const y = this.contextMenu.stageY;
        this.tracking.trackEvent('graph_context_menu_pasted_instance');
        this.args.onPasteInstance(null, this.clipboard.elementId, x, y);
      }
    }
    this.contextMenu.close();
  }

  @action
  onPasteInstances() {
    if (this.canPasteInstances) {
      if (this.args.onPasteInstances) {
        const x = this.contextMenu.stageX;
        const y = this.contextMenu.stageY;
        this.tracking.trackEvent('graph_context_menu_pasted_instances');
        this.args.onPasteInstances(null, this.clipboard.instancesList, x, y);
      }
    }
    this.contextMenu.close();
  }

  @action
  onPasteShallowElementDuplicate() {
    if (this.canPasteShallowElementDuplicate) {
      if (this.args.onPasteShallowElementDuplicate) {
        const x = this.contextMenu.stageX;
        const y = this.contextMenu.stageY;
        this.tracking.trackEvent('graph_context_menu_pasted_duplicate');
        this.args.onPasteShallowElementDuplicate(
          null,
          this.clipboard.elementId,
          x,
          y
        );
      }
    }
    this.contextMenu.close();
  }

  @action
  onCreateParentlessElement(category) {
    const x = this.contextMenu.stageX;
    const y = this.contextMenu.stageY;
    this.tracking.trackEvent('graph_context_menu_created_system');
    this.args.onCreateParentlessElement(x, y, category);
    this.contextMenu.close();
  }

  prevent(e) {
    return e.stopImmediatePropagation();
  }

  @action
  open(dropdown) {
    if (this.closeTimer) {
      cancel(this.closeTimer);
      this.closeTimer = null;
    } else {
      dropdown.actions.open();
    }
  }

  @action
  closeLater(dropdown) {
    this.closeTimer = later(() => {
      this.closeTimer = null;
      dropdown.actions.close();
    }, 200);
  }

  @action
  onNestedMouseenter() {
    cancel(this.closeTimer);
  }

  @action
  onNestedMouseleave(dropdown) {
    this.closeTimer = later(() => {
      this.closeTimer = null;
      dropdown.actions.close();
    }, 200);
  }
}

export default connect(
  stateToComputed,
  dispatchToActions
)(InventionGraphContextMenu);
