import Component from '@ember/component';
import { next } from '@ember/runloop';
import { select, event as d3Event } from 'd3-selection';

const DrawingArtboard = Component.extend({
  tagName: 'g',
  classNames: ['pd-artboard'],
  classNameBindings: ['isSelected', 'displayMode', 'previewMode:preview', 'artboardDraggedOver:dragged-over'],
  x: 0,
  y: 0,
  width: 400,
  height: 400,
  disableListeners: false,
  selector: '',
  artboardDraggedOver: false,

  addListeners() {
    if (this.disableListeners) {
      return;
    }

    this.artboard.on('click', () => {
      /* istanbul ignore next */
      if (d3Event.defaultPrevented) return; // dragged
      this.handleArtboardClick(d3Event);
    });

    this.artboard.on('dragenter', () => {
      this.set('artboardDraggedOver', true);
    });

    this.artboard.on('dragleave', () => {
      this.set('artboardDraggedOver', false);
    });

    this.artboard.on('drop', () => {
      next(() => {
        this.set('artboardDraggedOver', false);
      })
    });

  },

  removeListeners() {
    if (this.disableListeners) {
      return;
    }

    this.artboard.on('click', null);
    this.artboard.on('dragenter', null);
    this.artboard.on('dragleave', null);
    this.artboard.on('drop', null);

  },

  didInsertElement() {
    this._super(...arguments);
    this.setup();
    this.addListeners();
  },

  willDestroyElement() {
    this._super(...arguments);
    this.removeListeners();
  },

  setup() {
    const artboard = select(`#artboard${this.selector}`);
    this.set('artboard', artboard);
  },

  handleArtboardClick(event) {
    if (this.onClick) {
      this.onClick(event);
    }
  },

  dragDistance: 0,
  dragThresholdReached: false,
  dragThreshold: 30,

  handleDragStart() {
    this.set('dragThresholdReached', false);
    this.set('dragDistance', 0);
    this.set('pointerEvents', true);
  },

  handleDrag() {
    const dx = d3Event.dx;
    const dy = d3Event.dy;
    const distance = Math.sqrt(dx * dx + dy * dy);

    this.incrementProperty('dragDistance', distance);
    const distanceReached = this.dragDistance > this.dragThreshold;

    if (distanceReached && !this.dragThresholdReached) {
      this.set('dragThresholdReached', true);
    }

    if (this.dragThresholdReached) {
    this.set('pointerEvents', false);
      d3Event.sourceEvent.preventDefault();
      d3Event.sourceEvent.stopPropagation();
    }
  },

  handleDragEnd() {
    if (!this.dragThresholdReached) {
      this.handleArtboardClick();
    }
  },
});

export default DrawingArtboard;
