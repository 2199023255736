import { action, computed } from '@ember/object';
import { cancel, later } from '@ember/runloop';
import {
  getSelectedEdges,
  getSelectedElementVersions,
  getSelectedElements,
} from '../../../selectors/invention-ui';

import Component from '@glimmer/component';
import { connect } from 'ember-redux';
import { getElement } from '../../../selectors/element';
import { getElementVersion } from '../../../selectors/element-version';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';

const stateToComputed = (state, attrs) => ({
  elementVersion: getElementVersion(state, attrs.elementVersionId),
});

const dispatchToActions = {};

class ElementVersionContextMenu extends Component {
  @service contextMenu;
  @service clipboard;
  @service applicationState;
  @service redux;

  get styleNamespace() {
    return podNames['element-version-context-menu'];
  }

  get classNames() {
    let classNames = [this.styleNamespace, 'element-version-context-menu'];
    return classNames.join(' ');
  }

  calculateNestedPosition(trigger, menu) {
    const windowHeight = window.innerHeight;
    const windowWidth = window.innerWidth;
    const menuHeight = menu.getBoundingClientRect().height;
    const menuWidth = menu.getBoundingClientRect().width;

    let { top, left, width, height } = trigger.getBoundingClientRect();

    let style = {
      position: 'absolute',
      left: left + width,
      top: top,
    };

    if (top + height + menuHeight > windowHeight) {
      const bottom = windowHeight - (top + height);
      style.top = 'auto';
      style.bottom = `${bottom}px`;
    }

    if (left + width + menuWidth > windowWidth) {
      style.left = left - menuWidth;
    }

    return { style };
  }

  @computed('elementVersion.element')
  get parentElement() {
    const state = this.redux.getState();
    return this.elementVersion.element && getElement(state, this.elementVersion.element);
  }

  @computed('clipboard.elementId')
  get clipboardElementIsInstance() {
    const state = this.redux.getState();
    const element =
      this.clipboard.elementId && getElement(state, this.clipboard.elementId);
    return element && element.instanceOf ? true : false;
  }

  @computed('clipboardElementIsInstance')
  get canPasteInstance() {
    return this.clipboardElementIsInstance;
  }

  @computed('clipboard.instancesList.[]')
  get canPasteInstances() {
    return this.clipboard.instancesList.length;
  }

  @computed('parentElement.elementVersionsList.[]')
  get cantDelete() {
    return this.parentElement && this.parentElement.elementVersionsList.length === 1;
  }


  @computed('canPasteInstance')
  get cantPasteInstance() {
    return !this.canPasteInstance;
  }

  @computed('canPasteInstances')
  get cantPasteInstances() {
    return !this.canPasteInstances;
  }

  @computed(
    'cantPasteInstances',
    'cantPasteInstance',
    'cantPasteShallowElementDuplicate'
  )
  get cantPaste() {
    return (
      this.cantPasteInstances &&
      this.cantPasteInstance &&
      this.cantPasteShallowElementDuplicate
    );
  }

  @computed('clipboard.elementVersionId')
  get canPasteSolutionProperties() {
    return this.clipboard.elementVersionId ? true : false;
  }

  @computed('canPasteSolutionProperties')
  get cantPasteSolutionProperties() {
    return !this.canPasteSolutionProperties;
  }

  @computed('cantPasteSystemProperties', 'cantPasteSolutionProperties')
  get cantPasteProperties() {
    return this.cantPasteSolutionProperties;
  }

  // TODO: only allow creating children on primary or non-instances?
  // @computed('isPrimaryInstance', 'isntInstance')
  // get canCreateElement() {
  // const isPrimaryInstanceOrNotAnInstance =
  //   this.isPrimaryInstance || this.isntInstance;
  // return isPrimaryInstanceOrNotAnInstance;
  // }

  get canCreateElement() {
    return true;
  }

  @computed('canCreateElement')
  get cantCreateElement() {
    return !this.canCreateElement;
  }

  @computed('clipboard.elementId')
  get canPasteShallowElementDuplicate() {
    return this.clipboard.elementId ? true : false;
  }

  @computed('canPasteShallowElementDuplicate')
  get cantPasteShallowElementDuplicate() {
    return !this.canPasteShallowElementDuplicate;
  }

  @action
  onPasteInstance() {
    if (this.canPasteInstance) {
      if (this.args.onPasteInstance) {
        this.args.onPasteInstance(
          this.args.elementVersionId,
          this.clipboard.elementId
        );
      }
    }
    this.contextMenu.close();
  }

  @action
  onPasteInstances() {
    if (this.canPasteInstances) {
      if (this.args.onPasteInstances) {
        this.args.onPasteInstances(
          this.args.elementVersionId,
          this.clipboard.instancesList
        );
      }
    }
    this.contextMenu.close();
  }

  @action
  onPasteShallowElementDuplicate() {
    if (this.canPasteShallowElementDuplicate) {
      if (this.args.onPasteShallowElementDuplicate) {
        const x = this.contextMenu.stageX;
        const y = this.contextMenu.stageY;
        this.args.onPasteShallowElementDuplicate(
          this.args.elementVersionId,
          this.clipboard.elementId,
          x,
          y
        );
      }
    }
    this.contextMenu.close();
  }

  @action
  onCopy() {
    if (this.args.onCopyElementVersion) {
      this.args.onCopyElementVersion(this.args.elementVersionId);
    }
    this.contextMenu.close();
  }

  @action
  onCreateElement() {
    if (this.canCreateElement) {
      if (this.args.onCreateElement) {
        this.args.onCreateElement(this.args.elementVersionId);
      }
    }
    this.contextMenu.close();
  }

  @action
  onRemoveSelectedTreeItems() {
    const state = this.redux.getState();
    const selectedElements = getSelectedElements(state);
    const selectedElementVersions = getSelectedElementVersions(state);
    const selectedEdges = getSelectedEdges(state);
    if (this.args.onRemoveSelectedTreeItems) {
      this.args.onRemoveSelectedTreeItems(
        selectedElements,
        selectedElementVersions,
        selectedEdges
      );
    }
    this.contextMenu.close();
  }

  @action
  onPasteSolutionProperties(type) {
    if (this.canPasteSolutionProperties) {
      if (this.args.onPasteSolutionProperties) {
        this.args.onPasteSolutionProperties(
          this.clipboard.elementVersionId,
          this.args.elementVersionId,
          type
        );
      }
    }
    this.contextMenu.close();
  }

  prevent(e) {
    return e.stopImmediatePropagation();
  }

  @action
  open(dropdown) {
    if (this[`closeTimer_${dropdown.uniqueId}`]) {
      cancel(this[`closeTimer_${dropdown.uniqueId}`]);
      this[`closeTimer_${dropdown.uniqueId}`] = null;
    } else {
      dropdown.actions.open();
    }
  }

  @action
  closeLater(dropdown) {
    this[`closeTimer_${dropdown.uniqueId}`] = later(() => {
      this[`closeTimer_${dropdown.uniqueId}`] = null;
      dropdown.actions.close();
    }, 10);
  }

  @action
  onNestedMouseenter(dropdown) {
    cancel(this[`closeTimer_${dropdown.uniqueId}`]);
  }

  @action
  onNestedMouseleave(dropdown) {
    this[`closeTimer_${dropdown.uniqueId}`] = later(() => {
      this[`closeTimer_${dropdown.uniqueId}`] = null;
      dropdown.actions.close();
    }, 200);
  }
}

export default connect(
  stateToComputed,
  dispatchToActions
)(ElementVersionContextMenu);
