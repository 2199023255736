import Component from '@glimmer/component';
import { action } from '@ember/object';
import { connect } from 'ember-redux';
import { getActivePatentSpecificationEditorSection } from '../../../selectors/invention-ui';
import { getPatentSpecification } from '../../../selectors/patent-specification';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';

const stateToComputed = (state) => ({
  patentSpecification: getPatentSpecification(state),
  activeSection: getActivePatentSpecificationEditorSection(state),
});
const dispatchToActions = {};

class PatentSpecificationEditor extends Component {
  @service tracking;

  get styleNamespace() {
    return podNames['patent-specification-editor'];
  }

  get classNames() {
    let classNames = [this.styleNamespace, 'patent-specification-editor'];
    return classNames.join(' ');
  }

  @action
  didInsert() {
    this.tracking.trackEvent('opened_patent_specification');
  }

  @action
  hideModal() {
    this.args.onSetShowingPatentSpecificationEditor(false);
    this.tracking.trackEvent('closed_patent_specification');
  }

  @action
  onSelect(value) {
    this.args.onSetActivePatentSpecificationEditorSection(value);
  }

  

  @action
  onSetType() {
    const type =
      this.patentSpecification.type === 'provisional'
        ? 'nonprovisional'
        : 'provisional';
    this.args.onUpdatePatentSpecification(null, { type });
  }

  @action
  onSetNarrativeType(narrativeType) {
    this.args.onSetNarrativeType(narrativeType);
  }

  @action
  onShowFundingSources() {
    const showFundingSources = !this.patentSpecification.showFundingSources;
    this.args.onUpdatePatentSpecification(null, { showFundingSources });
  }

  @action
  onShowCrossReference() {
    const showCrossReference = !this.patentSpecification.showCrossReference;
    this.args.onUpdatePatentSpecification(null, { showCrossReference });
  }

  @action
  onShowBoilerplate() {
    const showBoilerplate = !this.patentSpecification.showBoilerplate;
    this.args.onUpdatePatentSpecification(null, { showBoilerplate });
  }
}

export default connect(
  stateToComputed,
  dispatchToActions
)(PatentSpecificationEditor);
