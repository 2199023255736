import Component from '@glimmer/component';
import { action } from '@ember/object';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';

class DashboardMenu extends Component {
  @service settings;
  @service session;
  @service sessionManager;

  get styleNamespace() {
    return podNames['dashboard-menu'];
  }

  get classNames() {
    let classNames = [this.styleNamespace, 'dashboard-menu'];
    return classNames.join(' ');
  }

  get experimental() {
    return this.sessionManager.user && this.sessionManager.user.experimental;
  }

  @action
  importDive(file) {
    this.dd && this.dd.actions.close();
    this.args.onImportDisclosure(file);
  }

  @action
  signOut() {
    this.session.invalidate();
  }
  @action
  addDisclosure() {
    this.args.onAddDisclosure();
  }
  @action
  toggleDarkMode() {
    this.settings.toggleDarkMode();
  }
  @action
  toggleBlackAndWhiteMode() {
    this.settings.toggleBlackAndWhiteMode();
  }
}

export default DashboardMenu;
