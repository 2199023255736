import { action, computed } from '@ember/object';
import {
  getPreviewLanguage,
  getPreviewNumbering,
  getShowNovelty,
  getShowWarnings,
} from '../../../selectors/settings';

import Component from '@glimmer/component';
import { connect } from 'ember-redux';
import {
  getPatentSpecification
} from '../../../selectors/patent-specification';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

const dispatchToActions = {};

const stateToComputed = (state) => ({
  previewLanguage: getPreviewLanguage(state),
  previewNumbering: getPreviewNumbering(state),
  showNovelty: getShowNovelty(state),
  showWarnings: getShowWarnings(state),
  patentSpecification: getPatentSpecification(state),
});

class SettingsContext extends Component {
  @service applicationState;

  @tracked switched;

  get classNames() {
    let classNames = [this.styleNamespace, 'settings-context'];
    return classNames.join(' ');
  }

  get styleNamespace() {
    return podNames['settings-context'];
  }

  warningOptions = [
    {
      id: 'show',
      name: 'Show',
      testId: 'show-warnings-button',
    },
    {
      id: 'hide',
      name: 'Hide',
      testId: 'hide-warnings-button',
    },
  ];

  noveltyOptions = [
    {
      id: 'show',
      name: 'Show',
      testId: 'show-novelty-button',
    },
    {
      id: 'hide',
      name: 'Hide',
      testId: 'hide-novelty-button',
    },
  ];

  // blackAndWhiteOptions = [
  //   {
  //     id: 'on',
  //     name: 'On',
  //     testId: 'black-and-white-on-button'',
  //   },
  //   {
  //     id: 'off',
  //     name: 'Off',
  //     testId: 'black-and-white-off-button',
  //   },
  // ];

  languageOptions = [
    {
      id: 'report',
      name: 'Report',
      testId: 'language-report-button',
    },
    {
      id: 'patent-specification',
      name: 'Specification',
      testId: 'language-patent-specification-button',
    },
  ];

  numberingOptions = [
    {
      id: 'report',
      name: 'Report',
      testId: 'numbering-report-button',
    },
    {
      id: 'patent-specification',
      name: 'Specification',
      testId: 'numbering-patent-specification-button',
    },
  ];

  @computed('showWarnings')
  get showWarningsId() {
    return this.showWarnings ? 'show' : 'hide';
  }

  @computed('showNovelty')
  get showNoveltyId() {
    return this.showNovelty ? 'show' : 'hide';
  }

  // @computed('showNovelty')
  // get blackAndWhiteModeId() {
  //   return this.blackAndWhiteMode ? 'on' : 'off';
  // }

  @action
  onSetShowWarnings() {
    const showWarnings = !this.showWarnings;
    this.args.onUpdateSettings({ showWarnings });
  }

  @action
  onSetShowNovelty() {
    const showNovelty = !this.showNovelty;
    this.args.onUpdateSettings({ showNovelty });
  }

  @action
  onSetNarrativeType(narrativeType) {
    this.args.onSetNarrativeType(narrativeType);
  }

  @action
  onSetPreviewLanguage(value) {
    this.args.onUpdateSettings({ previewLanguage: value });
  }

  @action
  onSetPreviewNumbering(value) {
    this.args.onUpdateSettings({ previewNumbering: value });
  }
}

export default connect(stateToComputed, dispatchToActions)(SettingsContext);
