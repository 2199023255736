import Component from '@ember/component';
import { not, or } from '@ember/object/computed';
import { connect } from 'ember-redux';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { getImageBlobFromURL } from '../../../utils/image';

const dispatchToActions = {};

const ImageExternalUrl = Component.extend({
  assets: service(),
  imageId: null,
  externalUrl: null,
  _externalUrl: null,
  hasNoExternalUrl: not('_externalUrl'),
  submitDisabled: or('hasNoExternalUrl', 'loadingExternalUrl'),
  loadingExternalUrl: false,
  externalUrlSuccess: false,
  externalUrlError: false,

  init() {
    this._super(...arguments);
    this.set('_externalUrl', this.externalUrl);
  },

  getExternalFile: task(function*(url) {
    try {
      this.setProperties({
        externalUrlSuccess: false,
        externalUrlError: false,
        loadingExternalUrl: true
      });

      const blob = yield getImageBlobFromURL(url);
      this.set('externalUrlSuccess', true);
      this.onBlob(url, blob);

    } catch (err) {
      this.set('externalUrlError', true);
    } finally {
      this.set('loadingExternalUrl', false);
    }
  }),

  actions: {
    submitExternal() {
      const url = this._externalUrl;
      this.getExternalFile.perform(url);
    }
  }
});

export default connect(
  null,
  dispatchToActions
)(ImageExternalUrl);
