import Component from '@glimmer/component';
import { action } from '@ember/object';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

class HistoryVersion extends Component {
  // @service data;
  // @service redux;
  @service tracking;
  @tracked activeVersionId;
  @tracked activeVersion;

  get styleNamespace() {
    return podNames['history-version'];
  }

  get classNames() {
    let classNames = [this.styleNamespace, 'history-version'];
    return classNames.join(' ');
  }

  get mostRecentVersion() {
    return this.args.instance && this.args.instance.mostRecentVersion
  }

  @action
  didInsert() {
    
  }
}

export default HistoryVersion;
