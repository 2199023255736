import { filter, max } from 'lodash';

import { groupByActionTypes } from 'redux-undo'
import uuid from 'uuid/v4';

export const batchGroupBy = {
  _group: null,
  start(group = uuid()) {
    this._group = group
  },
  end() {
    this._group = null
  },
  init(rawActions = []) {
    const defaultGroupBy = groupByActionTypes(rawActions)
    return (action) => this._group || defaultGroupBy(action)
  }
};

export const getPresentState = (state) => state.present;
export const getInventionState = (state) => state.present.invention;
export const getUiState = (state) => state.present.ui;
export const getSettingsState = (state) => state.present.settings;
export const getPatentSpecificationState = (state) =>  state.present.patentSpecification;

export const selectReference = (ui, referenceId, referenceType, figureId) => {
  let updatedUi = {
    ...ui,
  };

  updatedUi = {
    ...updatedUi,
    activeMethod: null,
    activeDrawing: null,
    activeFeature: null,
    selectedMarkers: [],
    selectedElements: [],
    selectedElementVersions: [],
    selectedEdges: [],
    selectedImages: [],
    selectedMethodNodes: [],
    selectedMethodEdges: [],
  };

  switch (referenceType) {
    case 'marker': {
      updatedUi = {
        ...updatedUi,
        activeDrawing: figureId,
        selectedMarkers: [referenceId],
      };
      break;
    }
    case 'method': {
      updatedUi = {
        ...updatedUi,
        activeMethod: figureId,
      };
      break;
    }
    case 'method-node': {
      updatedUi = {
        ...updatedUi,
        activeMethod: figureId,
        selectedMethodNodes: [referenceId],
      };
      break;
    }
  }

  return updatedUi;
};


export const getModelAttributeIncrement = (
  models,
  attribute,
  defaultValue,
  type
) => {
  const defaultIncrement = 1;
  const integers = filter(models, (model) =>
    type ? model.type === type : true
  )
    .filter(
      (model) => 
        model[attribute].indexOf(defaultValue) >= 0 &&
        model[attribute].match(/\d+/g)
    )
    .map((model) => parseInt(model[attribute].match(/\d+/g)));
  const maxInteger = max(integers);
  return maxInteger ? maxInteger + 1 : defaultIncrement;
};
