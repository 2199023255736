export const ADD_ASSET = 'ADD_ASSET';
export const ADD_COMPARISON = 'ADD_COMPARISON';
export const ADD_COMPONENT = 'ADD_COMPONENT';
export const ADD_CUSTOMER = 'ADD_CUSTOMER';
export const ADD_DELETED_REFERENCE = 'ADD_DELETED_REFERENCE';
export const ADD_DRAWING = 'ADD_DRAWING';
export const ADD_ELEMENT = 'ADD_ELEMENT';
export const ADD_ELEMENT_VERSION = 'ADD_ELEMENT_VERSION';
export const ADD_FEATURE = 'ADD_FEATURE';
export const ADD_FIGURES = 'ADD_FIGURES';
export const ADD_GRAPH = 'ADD_GRAPH';
export const ADD_HIGHLIGHT = 'ADD_HIGHLIGHT';
export const ADD_IMAGE = 'ADD_IMAGE';
export const ADD_INVENTION = 'ADD_INVENTION';
export const ADD_MARKER = 'ADD_MARKER';
export const ADD_METHOD = 'ADD_METHOD';
export const ADD_METHOD_EDGE = 'ADD_METHOD_EDGE';
export const ADD_METHOD_EDGE_POINT = 'ADD_METHOD_EDGE_POINT';
export const ADD_METHOD_NODE = 'ADD_METHOD_NODE';
export const ADD_METHOD_STEP = 'ADD_METHOD_STEP';
export const ADD_NOTE = 'ADD_NOTE';
export const ADD_PREVIEW_ASSET = 'ADD_PREVIEW_ASSET';
export const ADD_PRIOR_ART = 'ADD_PRIOR_ART';
export const ADD_PRODUCT = 'ADD_PRODUCT';
export const ADD_REVIEW = 'ADD_REVIEW';
export const ADD_TERM = 'ADD_TERM';
export const ADD_VERSION = 'ADD_VERSION';
export const CLEAR_SELECTION = 'CLEAR_SELECTION';
export const DESELECT_ALL_INVENTION = 'DESELECT_ALL_INVENTION';
export const DESELECT_DRAWING = 'DESELECT_DRAWING';
export const DESELECT_DRAWING_ITEMS = 'DESELECT_DRAWING_ITEMS';
export const DESELECT_EDGE = 'DESELECT_EDGE';
export const DESELECT_ELEMENT = 'DESELECT_ELEMENT';
export const DESELECT_ELEMENT_VERSION = 'DESELECT_ELEMENT_VERSION';
export const DESELECT_IMAGE = 'DESELECT_IMAGE';
export const DESELECT_MARKER = 'DESELECT_MARKER';
export const DESELECT_METHOD_EDGE = 'DESELECT_METHOD_EDGE';
export const DESELECT_METHOD_EDGE_POINT = 'DESELECT_METHOD_EDGE_POINT';
export const DESELECT_METHOD_ITEMS = 'DESELECT_METHOD_ITEMS';
export const DESELECT_METHOD_NODE = 'DESELECT_METHOD_NODE';
export const DESELECT_TERM = 'DESELECT_TERM';
export const HIDE_EXPLORER = 'HIDE_EXPLORER';
export const HIDE_NOTES = 'HIDE_NOTES';
export const IMPORT_INVENTION = 'IMPORT_INVENTION';
export const MULTI_SELECT_EDGE = 'MULTI_SELECT_EDGE';
export const MULTI_SELECT_ELEMENT = 'MULTI_SELECT_ELEMENT';
export const MULTI_SELECT_ELEMENT_VERSION = 'MULTI_SELECT_ELEMENT_VERSION';
export const MULTI_SELECT_IMAGE = 'MULTI_SELECT_IMAGE';
export const MULTI_SELECT_MARKER = 'MULTI_SELECT_MARKER';
export const MULTI_SELECT_METHOD_EDGE = 'MULTI_SELECT_METHOD_EDGE';
export const MULTI_SELECT_METHOD_EDGE_POINT = 'MULTI_SELECT_METHOD_EDGE_POINT';
export const MULTI_SELECT_METHOD_NODE = 'MULTI_SELECT_METHOD_NODE';
export const MULTI_SELECT_TERM = 'MULTI_SELECT_TERM';
export const PULL_NAVIGATION = 'PULL_NAVIGATION';
export const PUSH_NAVIGATION = 'PUSH_NAVIGATION';
export const REMOVE_ASSET = 'REMOVE_ASSET';
export const REMOVE_COMPARISON = 'REMOVE_COMPARISON';
export const REMOVE_COMPONENT = 'REMOVE_COMPONENT';
export const REMOVE_COMPRISES_RELATIONSHIP = 'REMOVE_COMPRISES_RELATIONSHIP';
export const REMOVE_CUSTOMER = 'REMOVE_CUSTOMER';
export const REMOVE_DELETED_REFERENCE = 'REMOVE_DELETED_REFERENCE';
export const REMOVE_DRAWING = 'REMOVE_DRAWING';
export const REMOVE_ELEMENT = 'REMOVE_ELEMENT';
export const REMOVE_ELEMENT_VERSION = 'REMOVE_ELEMENT_VERSION';
export const REMOVE_FEATURE = 'REMOVE_FEATURE';
export const REMOVE_FEATURES = 'REMOVE_FEATURES';
export const REMOVE_HIGHLIGHT = 'REMOVE_HIGHLIGHT';
export const REMOVE_METHOD = 'REMOVE_METHOD';
export const REMOVE_METHOD_EDGE = 'REMOVE_METHOD_EDGE';
export const REMOVE_METHOD_EDGE_POINT = 'REMOVE_METHOD_EDGE_POINT';
export const REMOVE_METHOD_NODE= 'REMOVE_METHOD_NODE';
export const REMOVE_NOTE = 'REMOVE_NOTE';
export const REMOVE_PREVIEW_ASSET = 'REMOVE_PREVIEW_ASSET';
export const REMOVE_PRIOR_ART = 'REMOVE_PRIOR_ART';
export const REMOVE_PRODUCT = 'REMOVE_PRODUCT';
export const REMOVE_REVIEW = 'REMOVE_REVIEW';
export const REMOVE_SELECTED_DRAWING_ITEMS = 'REMOVE_SELECTED_DRAWING_ITEMS';
export const REMOVE_TERM = 'REMOVE_TERM';
export const RESET_NAVIGATION = 'RESET_NAVIGATION';
export const SELECT_ASSET = 'SELECT_ASSET';
export const SELECT_COMPARISON = 'SELECT_COMPARISON';
export const SELECT_CUSTOMER = 'SELECT_CUSTOMER';
export const SELECT_DRAWING = 'SELECT_DRAWING';
export const SELECT_EDGE = 'SELECT_EDGE';
export const SELECT_ELEMENT = 'SELECT_ELEMENT';
export const SELECT_ELEMENT_AND_ELEMENT_VERSION = 'SELECT_ELEMENT_AND_ELEMENT_VERSION';
export const SELECT_ELEMENT_VERSION = 'SELECT_ELEMENT_VERSION';
export const SELECT_IMAGE = 'SELECT_IMAGE';
export const SELECT_MARKER = 'SELECT_MARKER';
export const SELECT_METHOD_EDGE = 'SELECT_METHOD_EDGE';
export const SELECT_METHOD_EDGE_POINT = 'SELECT_METHOD_EDGE_POINT';
export const SELECT_METHOD_NODE = 'SELECT_METHOD_NODE';
export const SELECT_PRIOR_ART = 'SELECT_PRIOR_ART';
export const SELECT_PRODUCT = 'SELECT_PRODUCT';
export const SELECT_REFERENCE = 'SELECT_REFERENCE';
export const SELECT_REVIEW = 'SELECT_REVIEW';
export const SELECT_TERM = 'SELECT_TERM';
export const SET_ACTIVE_COMPARISON = 'SET_ACTIVE_COMPARISON';
export const SET_ACTIVE_COMPARISON_ELEMENT = 'SET_ACTIVE_COMPARISON_ELEMENT';
export const SET_ACTIVE_CONTEXT_TAB = 'SET_ACTIVE_CONTEXT_TAB';
export const SET_ACTIVE_DRAWING = 'SET_ACTIVE_DRAWING';
export const SET_ACTIVE_ELEMENT_VERSION_LIST_ITEM = 'SET_ACTIVE_ELEMENT_VERSION_LIST_ITEM';
export const SET_ACTIVE_FEATURE = 'SET_ACTIVE_FEATURE';
export const SET_ACTIVE_INVENTION = 'SET_ACTIVE_INVENTION';
export const SET_ACTIVE_INVENTION_SUMMARY_EDITOR_SECTION = 'SET_ACTIVE_INVENTION_SUMMARY_EDITOR_SECTION';
export const SET_ACTIVE_METHOD = 'SET_ACTIVE_METHOD';
export const SET_ACTIVE_NOTE = 'SET_ACTIVE_NOTE';
export const SET_ACTIVE_PATENTABILITY_SECTION = 'SET_ACTIVE_PATENTABILITY_SECTION';
export const SET_ACTIVE_PRIOR_ART = 'SET_ACTIVE_PRIOR_ART';
export const SET_ACTIVE_PRODUCT = 'SET_ACTIVE_PRODUCT';
export const SET_ACTIVE_MILESTONE_CONTEXT = 'SET_ACTIVE_MILESTONE_CONTEXT';
export const SET_ACTIVE_PRODUCT_VIEW = 'SET_ACTIVE_PRODUCT_VIEW';
export const SET_ACTIVE_REVIEW = 'SET_ACTIVE_REVIEW';
export const SET_ACTIVE_VIEW = 'SET_ACTIVE_VIEW';
export const SET_COLLAPSED_PROBLEM = 'SET_COLLAPSED_PROBLEM';
export const SET_COLLAPSED_SOLUTION = 'SET_COLLAPSED_SOLUTION';
export const SET_CREATING_ASSET = 'SET_CREATING_ASSET';
export const SET_CREATING_COMPARISON = 'SET_CREATING_COMPARISON';
export const SET_CREATING_NOTE = 'SET_CREATING_NOTE';
export const SET_CREATING_REVIEW = 'SET_CREATING_REVIEW';
export const SET_DEFAULT_ELEMENT_CATEGORY = 'SET_DEFAULT_ELEMENT_CATEGORY';
export const SET_DEFAULT_MARKER_CATEGORY = 'SET_DEFAULT_MARKER_CATEGORY';
export const SET_DEFAULT_METHOD_NODE_TYPE = 'SET_DEFAULT_METHOD_NODE_TYPE';
export const SET_EDITING_ASSET= 'SET_EDITING_ASSET';
export const SET_EDITING_COMPARISON= 'SET_EDITING_COMPARISON';
export const SET_EDITING_NOTE = 'SET_EDITING_NOTE';
export const SET_EDITING_PRODUCT= 'SET_EDITING_PRODUCT';
export const SET_EDITING_REVIEW = 'SET_EDITING_REVIEW';
export const SET_EXPLORER_WIDTH = 'SET_EXPLORER_WIDTH';
export const SET_FIGURE_TYPE = 'SET_FIGURE_TYPE';
export const SET_PREVIEW_MODE = 'SET_PREVIEW_MODE';
export const SET_METHOD_REALIZATION_PREVIEW_MODE = 'SET_METHOD_REALIZATION_PREVIEW_MODE';
export const SET_PATENT_SPECIFICATION_PREVIEW_MODE = 'SET_PATENT_SPECIFICATION_PREVIEW_MODE';
export const SET_CREATING_IMAGE_ASSET = 'SET_CREATING_IMAGE_ASSET';
export const SET_UPDATING_IMAGE_ASSET = 'SET_UPDATING_IMAGE_ASSET';
export const SET_UPDATING_PRODUCT_ASSET = 'SET_UPDATING_PRODUCT_ASSET';
export const SET_UPDATING_PRODUCT_CUSTOMERS = 'SET_UPDATING_PRODUCT_CUSTOMERS';
export const SET_UPDATING_PRODUCT_PRIOR_ART = 'SET_UPDATING_PRODUCT_PRIOR_ART';
export const SET_UPDATING_PRIOR_ART_ASSET = 'SET_UPDATING_PRIOR_ART_ASSET';
export const SET_PREFERRED_VERSION = 'SET_PREFERRED_VERSION';
export const SET_REVIEW_MODE = 'SET_REVIEW_MODE';
export const SET_ROOT_NODE = 'SET_ROOT_NODE';
export const SET_SHOWING_ASSETS = 'SET_SHOWING_ASSETS';
export const SET_SHOWING_COMPARISON_MATRIX = 'SET_SHOWING_COMPARISON_MATRIX';
export const SET_SHOWING_DRAWING_SELECTION_MODAL = 'SET_SHOWING_DRAWING_SELECTION_MODAL';
export const SET_SHOWING_ELEMENT_VERSION_SELECTOR = 'SET_SHOWING_ELEMENT_VERSION_SELECTOR';
export const SET_SHOWING_FIGURES = 'SET_SHOWING_FIGURES';
export const SET_SHOWING_GET_STARTED = 'SET_SHOWING_GET_STARTED';
export const SET_SHOWING_HISTORY_VERSION_CREATE_MODAL = 'SET_SHOWING_HISTORY_VERSION_CREATE_MODAL';
export const SET_SHOWING_IMAGE_UPLOAD_MODAL = 'SET_SHOWING_IMAGE_UPLOAD_MODAL';
export const SET_SHOWING_INVENTION_SUMMARY_EDITOR = 'SET_SHOWING_INVENTION_SUMMARY_EDITOR';
export const SET_SHOWING_PATENT_SPECIFICATION = 'SET_SHOWING_PATENT_SPECIFICATION';
export const SET_SHOWING_PATENTABILITY = 'SET_SHOWING_PATENTABILITY';
export const SET_SHOWING_PRIOR_ARTS = 'SET_SHOWING_PRIOR_ARTS';
export const SET_SHOWING_CUSTOMERS = 'SET_SHOWING_CUSTOMERS';
export const SET_SHOWING_PRODUCT_CHECKLIST = 'SET_SHOWING_PRODUCT_CHECKLIST';
export const SET_SHOWING_PRODUCTS = 'SET_SHOWING_PRODUCTS';
export const SET_SHOWING_SEARCH = 'SET_SHOWING_SEARCH';
export const SET_SHOWING_SETTINGS = 'SET_SHOWING_SETTINGS';
export const SHOW_EXPLORER = 'SHOW_EXPLORER';
export const SHOW_NOTES = 'SHOW_NOTES';
export const SORT_ASSETS_LIST = 'SORT_ASSETS_LIST';
export const SORT_CUSTOMERS_LIST = 'SORT_CUSTOMERS_LIST';
export const SORT_COMPARISONS_LIST = 'SORT_COMPARISONS_LIST';
export const SORT_COMPONENTS_LIST = 'SORT_COMPONENTS_LIST';
export const SORT_DRAWINGS_LIST = 'SORT_DRAWINGS_LIST';
export const SORT_NOTES_LIST = 'SORT_NOTES_LIST';
export const SORT_PRIOR_ARTS_LIST = 'SORT_PRIOR_ARTS_LIST';
export const SORT_PRODUCTS_LIST = 'SORT_PRODUCTS_LIST';
export const SORT_REVIEWS_LIST = 'SORT_REVIEWS_LIST';
export const SORT_TERMS_LIST = 'SORT_TERMS_LIST';
export const UPDATE_ASSET = 'UPDATE_ASSET';
export const UPDATE_COLLAPSED_NODES = 'UPDATE_COLLAPSED_NODES';
export const UPDATE_COMPARISON = 'UPDATE_COMPARISON';
export const UPDATE_COMPONENT = 'UPDATE_COMPONENT';
export const UPDATE_CUSTOMER = 'UPDATE_CUSTOMER';
export const UPDATE_DRAWING = 'UPDATE_DRAWING';
export const UPDATE_DRAWING_ITEMS = 'UPDATE_DRAWING_ITEMS';
export const UPDATE_ELEMENT = 'UPDATE_ELEMENT';
export const UPDATE_ELEMENT_AND_ELEMENT_VERSION = 'UPDATE_ELEMENT_AND_ELEMENT_VERSION';
export const UPDATE_ELEMENT_COLUMNS_DATA = 'UPDATE_ELEMENT_COLUMNS_DATA';
export const UPDATE_ELEMENT_VERSION = 'UPDATE_ELEMENT_VERSION';
export const UPDATE_FEATURE = 'UPDATE_FEATURE';
export const UPDATE_FIGURES = 'UPDATE_FIGURES';
export const UPDATE_GRAPH = 'UPDATE_GRAPH';
export const UPDATE_HIGHLIGHT = 'UPDATE_HIGHLIGHT';
export const UPDATE_IMAGE = 'UPDATE_IMAGE';
export const UPDATE_INVENTION = 'UPDATE_INVENTION';
export const UPDATE_INVENTION_NAME = 'UPDATE_INVENTION_NAME';
export const UPDATE_MARKER = 'UPDATE_MARKER';
export const UPDATE_META = 'UPDATE_META';
export const UPDATE_METHOD = 'UPDATE_METHOD';
export const UPDATE_METHOD_EDGE = 'UPDATE_METHOD_EDGE';
export const UPDATE_METHOD_EDGE_POINT = 'UPDATE_METHOD_EDGE_POINT';
export const UPDATE_METHOD_ELEMENT = 'UPDATE_METHOD_ELEMENT';
export const UPDATE_METHOD_IGNORE_UNDO = 'UPDATE_METHOD_IGNORE_UNDO';
export const UPDATE_METHOD_ITEMS = 'UPDATE_METHOD_ITEMS';
export const UPDATE_METHOD_NODE = 'UPDATE_METHOD_NODE';
export const UPDATE_METHOD_NODE_ELEMENT = 'UPDATE_METHOD_NODE_ELEMENT';
export const UPDATE_METHOD_STEP = 'UPDATE_METHOD_STEP';
export const UPDATE_NODE_COORDINATES = 'UPDATE_NODE_COORDINATES';
export const UPDATE_NOTE = 'UPDATE_NOTE';
export const UPDATE_ORPHAN_NODES_LIST = 'UPDATE_ORPHAN_NODES_LIST';
export const UPDATE_PATENT_SPECIFICATION = 'UPDATE_PATENT_SPECIFICATION';
export const UPDATE_PRESENTATION = 'UPDATE_PRESENTATION';
export const UPDATE_PRIOR_ART = 'UPDATE_PRIOR_ART';
export const UPDATE_PRODUCT = 'UPDATE_PRODUCT';
export const UPDATE_REVIEW = 'UPDATE_REVIEW';
export const UPDATE_SETTINGS = 'UPDATE_SETTINGS';
export const UPDATE_NAVIGATION_STACK = 'UPDATE_NAVIGATION_STACK';
export const UPDATE_NAVIGATION_STACK_SELECTED_ITEMS = 'UPDATE_NAVIGATION_STACK_SELECTED_ITEMS';
export const UPDATE_TERM = 'UPDATE_TERM';
export const UPDATE_VERSION_TREE_COORDINATES = 'UPDATE_VERSION_TREE_COORDINATES';
export const SET_ACTIVE_PATENT_SPECIFICATION_EDITOR_SECTION = 'SET_ACTIVE_PATENT_SPECIFICATION_EDITOR_SECTION';
export const SET_SHOWING_PATENT_SPECIFICATION_EDITOR = 'SET_SHOWING_PATENT_SPECIFICATION_EDITOR';

