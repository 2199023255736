export default {
  attributes: {
    id: {
      type: 'uuid',
      defaultValue: null
    },
    type: {
      type: 'type',
      defaultValue: null
    },
    value: {
      type: 'any',
      defaultValue: null
    },
    count: {
      type: 'any',
      defaultValue: null
    },
    term: {
      type: 'uuid',
      defaultValue: null
    },
    element: {
      type: 'uuid',
      defaultValue: null
    },
    elementVersion: {
      type: 'uuid',
      defaultValue: null
    },
    novel: {
      type: 'boolean',
      defaultValue: false
    },
    noveltyAmount: {
      type: 'number',
      defaultValue: 1
    },
    noveltyDescription: {
      type: 'string',
      defaultValue: ''
    },
    enablement: {
      type: 'boolean',
      defaultValue: false
    },
    enablementAmount: {
      type: 'number',
      defaultValue: 1
    },
    enablementDescription: {
      type: 'string',
      defaultValue: ''
    },
    createdAt: {
      type: 'date'
    }
  }
};
