import { get } from '@ember/object';
import { getElement } from './element';
import { getElementVersion } from './element-version';
import { getPresentState } from '../utils/redux';
import { getTerm } from './term';

export const getMarker = (state, markerId) => get(getPresentState(state), `markers.entities.${markerId}`);
export const getMarkers = (state) => get(getPresentState(state), `markers.entities`);
export const getMarkersList = (state) => get(getPresentState(state), `markers.ids`);
export const getMarkerElement = (state, markerId) => {
    const marker = getMarker(state, markerId)
    return marker && marker.element && getElement(state, marker.element)
}
export const getMarkerElementVersion = (state, markerId) => {
    const marker = getMarker(state, markerId)
    return marker && marker.elementVersion && getElementVersion(state, marker.elementVersion)
}
export const getMarkerTerm = (state, markerId) => {
    const marker = getMarker(state, markerId)
    return marker && marker.term && getTerm(state, marker.term)
}
