import { get } from '@ember/object';
import { getElementRequirementsList } from './element';
import { getPresentState } from '../utils/redux';

export const getElementVersion = (state, elementVersionId) =>
  get(getPresentState(state), `elementVersions.entities.${elementVersionId}`);

export const getElementVersions = (state) =>
  get(getPresentState(state), `elementVersions.entities`);

export const getElementVersionsList = (state) =>
  get(getPresentState(state), `elementVersions.ids`);

export const getElementVersionMethodId = (state, elementVersionId) => {
  const elementVersion = getElementVersion(state, elementVersionId);
  return elementVersion ? elementVersion.method : null;
};

export const getElementVersionRequirementsList = (state, elementVersionId) => {
  const elementVersion = getElementVersion(state, elementVersionId);
  return (
    elementVersion &&
    elementVersion.element &&
    getElementRequirementsList(state, elementVersion.element)
  );
};
