import { action, computed } from '@ember/object';

import Component from '@glimmer/component';
import { connect } from 'ember-redux';
import { getActiveFeature } from '../../../selectors/invention-ui';
import { getElementVersion } from '../../../selectors/element-version';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';

const dispatchToActions = {};

const stateToComputed = (state, attrs) => ({
  activeFeatureId: getActiveFeature(state),
  elementVersion: getElementVersion(state, attrs.elementVersionId),
});

class ElementVersionContextFeatures extends Component {
  @service tracking;

  get styleNamespace() {
    return podNames['element-version-context-features'];
  }

  get classNames() {
    let classNames = [this.styleNamespace, 'element-version-context-features'];
    return classNames.join(' ');
  }

  @computed('elementVersion.featuresList', 'activeFeatureId')
  get hasActive() {
    return (
      this.activeFeatureId &&
      this.elementVersion.featuresList.includes(this.activeFeatureId)
    );
  }

  @action
  removeFeature(featureId) {
    if (this.args.onRemoveFeature) {
      this.args.onRemoveFeature(this.args.elementVersionId, featureId);
    }
  }

  @action
  createFeature(type) {
    if (this.args.onAddFeature) {
      this.args.onAddFeature(this.args.elementVersionId, type);
    }
  }

  @action
  onSortFeaturesList(featuresList) {
    if (this.args.onUpdateElementVersion) {
      this.args.onUpdateElementVersion(this.args.elementVersionId, {
        featuresList,
      });
    }
  }
}

export default connect(
  stateToComputed,
  dispatchToActions
)(ElementVersionContextFeatures);
