import { action, computed } from '@ember/object';

import Component from '@glimmer/component';
import { connect } from 'ember-redux';
import { getDrawing } from '../../../selectors/drawing';
import { getFigureNumber } from '../../../selectors/figure';
import { getFiguresList } from '../../../selectors/invention';
import { getPreviewNumbering } from '../../../selectors/settings';
import { getSelectedMarkers } from '../../../selectors/invention-ui';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';

const stateToComputed = (state, attrs) => ({
  drawing: getDrawing(state, attrs.drawingId),
  figuresList: getFiguresList(state),
  selectedMarkers: getSelectedMarkers(state),
  figureNum: getFigureNumber(state, attrs.drawingId),
  previewNumbering: getPreviewNumbering(state),
});

const dispatchToActions = {};

class DrawingContext extends Component {
  @service tracking;

  get styleNamespace() {
    return podNames['preview-context-drawing'];
  }

  get classNames() {
    let classNames = [this.styleNamespace];
    return classNames.join(' ');
  }

  @action
  onSelectMarker(markerId) {
    const isSelected = this.selectedMarkers.includes(markerId);
    if (isSelected) {
      this.args.onDeselectMarker(markerId);
    } else {
      this.args.onSelectMarker(markerId);
    }
  }

  @computed('selectedMarkers.[]')
  get selectedMarkerId() {
    return (
      this.selectedMarkers &&
      this.selectedMarkers.length === 1 &&
      this.selectedMarkers[0]
    );
  }
}

export default connect(stateToComputed, dispatchToActions)(DrawingContext);
