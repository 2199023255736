const Handlebars = window.Handlebars;

///////////////////////////////////////////////
// svgX,
// svgY,
// svgWidth,
// svgHeight,
// artboardWidth,
// artboardHeight,
// artboardX,
// artboardY,
// images: {
//   x,
//   y,
//   width,
//   height,
//   src
// }
// markers: {
//   line,
//   showArrow,
//   labelX,
//   labelY,
//   labelText,
// }
// edges: {
//   halfLine,
//   showLabel,
//   label,
//   labelX,
//   labelY
// }
// nodes: {
//   isConditional
//   conditionalPolygonPoints
//   x
//   y
//   width
//   height
//   ordinal
//   value
// }
///////////////////////////////////////////////

const template = `
<svg class="drawing-template" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="{{svgX}} {{svgY}} {{svgWidth}} {{svgHeight}}">
  <defs>
    <style type="text/css">

      .drawing-template .marker .marker-label {
        pointer-events: none;
        display: inline-flex;
      }

      .drawing-template .marker .marker-label.orient-left .node-content {
        text-align: right;
      }

      .drawing-template .marker .marker-label.orient-top .node-content {
        align-items: flex-end;
        text-align: center;
      }

      .drawing-template .marker .marker-label.orient-bottom .node-content {
        text-align: center;
      }

      .drawing-template .marker.is-collapsed .marker-label .node-content {
        text-decoration: underline;
      }

      .drawing-template .marker path.spline {
        fill: none;
        stroke: #000;
        stroke-width: 2px;
      }

      .drawing-template .point-arrow {
        fill: #000;
      }

      .drawing-template .spline-point,
      .drawing-template .end-point-dot {
        visibility: hidden;
      }

      .drawing-template .node-content {
        height: 100%;
        display: flex;
        cursor: default;
        line-height: 2rem;
        font-size: 1.5rem;
        user-select: none;
        -webkit-user-select: none;
        font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
      }

      .drawing-template .node-content .inner {
        width: 100%;
      }

      .drawing-template .marker .no-leadline {
        text-decoration: underline;
      }

      .drawing-template .marker .marker-label {
        background-color: transparent;
      }

      .drawing-template .marker .marker-label-text {
        font-size: 24px;
        text-anchor: middle;
      }

      .drawing-template .highlight-preview-svg .highlight-path-outline {
        stroke: #3cbebf;
        stroke-width: 2;
        fill: transparent;
      }

      .drawing-template .highlight-preview-svg .highlight-path {
        stroke: transparent;
        fill: #3cbebf;
        fill-opacity: 0.075;
      }

      .drawing-template .highlight-preview-svg.is-mask .highlight-path {
        fill: #fff;
        fill-opacity: 1;
      }

      .drawing-template .highlight-preview-svg.is-mask .highlight-path-outline {
        stroke: none;
        stroke-width: 0;
      }

      .drawing-template .opaque-images-container {
        opacity: 0.15;
      }

      .drawing-template .method-edge .label-text {
        fill: #000;
        font-size: 19.5px;
      }
      
      .drawing-template .method-edge .edge-label-background {
        fill: #fff;
        stroke: #000;
        stroke-width: 2px;
      }

      .drawing-template .method-edge {
        font-family: "Helvetica", "Arial", sans-serif;
      }

      .drawing-template .method-edge .half-path {
        stroke: #000;
        stroke-width: 2px;
        pointer-events: none;
      }

      .drawing-template .method-edge .edge-arrow {
        fill: #000;
      }

      .drawing-template .method-edge .label-content {
        line-height: 1.25;
        user-select: none;
        pointer-events: none;
        line-height: 29px;
        font-size: 19.5px;
        width: 100%;
        height: 100%;
        padding-left: 9.5px;
      }

      .drawing-template .method-edge .label-content .source-position-w {
        text-align: right;
        padding-left: 0;
        padding-right: 9.5px;
      }

      .drawing-template .method-edge .label-content .source-position-s {
        padding-top: 5px;
      }

      .drawing-template .method-node {
        stroke: #000;
        stroke-width: 1.5px;
      }

      .drawing-template .step-text {
        font-weight: 400;
        stroke: transparent;
        stroke-width: 0;
        font-family: "Helvetica", "Arial", sans-serif; 
        font-size: 20px;
      }

      .drawing-template .ordinal-text {
        font-weight: 400;
        stroke: transparent;
        stroke-width: 0;
        font-family: "Helvetica", "Arial", sans-serif; 
        font-size: 20px;
      }

      .drawing-template .method-node .conditional-shape {
        stroke: #000;
        stroke-width: 2px;
        fill: transparent;
      }

      .drawing-template .method-node .step-rect {
        fill: #fff;
      }

      .drawing-template .method-node .label-container {
        color: #000;
        font-size: 19.5px;
        text-align: right;
      }

      .drawing-template .method-node .node-content-container {
        overflow: visible;
      }

      .drawing-template .method-node .node-content {
        line-height: 1.25;
        user-select: none;
        pointer-events: none;
        line-height: 29px;
        font-size: 19.5px;
        padding: 20px;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        color: #000 !important;
      }

      .drawing-template .method-node .mention,
      .drawing-template .method-node .mention a {
        color: #000 !important;
      }

      .drawing-template .method-node.is-conditional {
        stroke: transparent;
        stroke-width: 1.5px;
      }

      .drawing-template .method-node.is-conditional .label-container {
        text-align: center;
      }

      .drawing-template .method-node.is-conditional .node-content {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: 0;
      }
    </style>
    <marker id="point-arrow" class="point-arrow" viewBox="0 -5 10 10" refX="4" markerWidth="6.0" markerHeight="6.0" orient="auto">
      <path d="M0,-5L10,0L0,5"></path>
    </marker>
    <marker id="edge-arrow" class="edge-arrow" viewBox="0 -5 10 10" refX="5" markerWidth="5.5" markerHeight="5.5" orient="auto">
      <path d="M0,-5L10,0L0,5"></path>
    </marker>
    <mask id="artboard-mask">
      <rect width="{{artboardWidth}}" height="{{artboardHeight}}" fill="#ffffff" transform="translate({{artboardX}}, {{artboardY}})" />
    </mask>
    {{#if isHighlightReference}}
      <mask id="highlight-mask">
        <rect 
          width="2000" 
          height="2000" 
          x="-1000" 
          y="-1000" 
          fill="#fff" 
          fill-opacity="0"
        />
        {{#each highlights}}
          <g class="highlight-preview-svg is-mask">
            <path vector-effect="non-scaling-stroke" class="highlight-path-outline" d="{{this.path}}"></path>
            <g mask="url(#artboard-mask)">
              <path class="highlight-path" d="{{this.path}}"></path>
            </g>
          </g>
        {{/each}}
      </mask>
    {{/if}}
  </defs>
  <filter id="grayscale">
    <feColorMatrix type="saturate" values="0" />
  </filter>


  <rect width="{{artboardWidth}}" height="{{artboardHeight}}" fill="#ffffff" stroke="#ff0000" stroke-width="10" transform="translate({{artboardX}}, {{artboardY}})" />
  <g class="drawing-contents">
    {{#if isHighlightReference}}
      <g class="opaque-images-container" >
          <g class="image-preview-svg">
            <g mask="url(#artboard-mask)">
              <g class="image-group">
                <image xlink:href="{{backgroundImage}}" x="{{artboardX}}" y="{{artboardY}}" data-test-id="image-svg-image" class="svg-image" width="{{artboardWidth}}" height="{{artboardHeight}}" viewBox="0 0 {{artboardWidth}} {{artboardHeight}}"
                  preserveAspectRatio="xMinYMin meet"></image>
              </g>
            </g>
          </g>
      </g>
        <g class="image-preview-svg">
          <g mask="url(#highlight-mask)">
            <g class="image-group" transform="translate({{this.x}}, {{this.y}})">
            <image xlink:href="{{backgroundImage}}" x="{{artboardX}}" y="{{artboardY}}" data-test-id="image-svg-image" class="svg-image" width="{{artboardWidth}}" height="{{artboardHeight}}" viewBox="0 0 {{artboardWidth}} {{artboardHeight}}"
              preserveAspectRatio="xMinYMin meet"></image>
            </g>
          </g>
        </g>
    {{else}}
      <g class="image-preview-svg">
        <g mask="url(#artboard-mask)">
          <image xlink:href="{{backgroundImage}}" x="{{artboardX}}" y="{{artboardY}}" data-test-id="image-svg-image" class="svg-image" width="{{artboardWidth}}" height="{{artboardHeight}}" viewBox="0 0 {{artboardWidth}} {{artboardHeight}}"
            preserveAspectRatio="xMinYMin meet"></image>
        </g>
      </g>
    {{/if}}
    {{#each edges}}
      <g class="method-edge">
        <path class="half-path" marker-end="url(#edge-arrow)" fill="none" d="{{this.halfLine}}"></path>
        {{#if this.showLabel}}
          <g 
            class="edge-label" 
            transform="translate({{this.labelX}}, {{this.labelY}})">
              <circle class="edge-label-background" r="15" cy="-1"></circle>
              <g transform="translate(0, {{this.labelTextOffsetY}})">
                <text class="label-text" text-anchor="middle" >
                  {{#each this.labelValues}}
                    <tspan x="0" y="{{this.y}}">{{this.value}}</tspan>
                  {{/each}}
                </text>
              </g>
          </g>
        {{/if}}
      </g>
    {{/each}}
    
    {{#each nodes}}
      <g class="method-node">
        {{#if this.isConditional}}
          <polygon class="conditional-shape" points="{{this.conditionalPolygonPoints}}" />
        {{else}}
          <g transform="translate({{this.x}}, {{this.y}})">
            <rect
              class="step-rect"
              rx="{{this.cornerRadius}}"
              ry="{{this.cornerRadius}}"
              width="{{this.width}}"
              height="{{this.height}}">
            </rect>
          </g>
        {{/if}}
        <g mask="url(#artboard-mask)">
          <g
            class="step-group"
            transform="translate({{this.x}}, {{this.y}})">
            <g transform="translate({{this.valuesX}}, {{this.valuesY}})">
              <text class="step-text" text-anchor="middle" transform="translate(0, -{{valuesHeight}})">
                {{#each this.values}}
                  <tspan x="0" y="{{this.y}}">{{this.value}}</tspan>
                {{/each}}
              </text>
            </g>
            {{#if this.ordinal}}
              <text y="-6" class="ordinal-text">{{this.ordinal}}</text>
            {{/if}}
          </g>
        </g>
      </g>
    {{/each}}
    {{#if isDrawingPreview}}
      {{#each markers}}
        <g class="marker marker-preview-svg natural has-lead-line">
          <g class="points-and-line">
            {{#if this.showArrow}}
              <path class="link spline visible-spline" marker-end="url(#point-arrow)" d="{{this.line}}">
              </path>
            {{else}}
              <path class="link spline visible-spline" d="{{this.line}}">
              </path>
            {{/if}}

            <g class="marker-label" transform="translate({{this.labelX}}, {{this.labelY}})">
              <text x="75" class="marker-label-text">{{this.labelText}}</text>
            </g>
          </g>
        </g>
      {{/each}}
    {{/if}}
    {{#if isMarkerReference}}
      {{#if marker}}
          <g class="marker marker-reference marker-preview-svg natural has-lead-line">
            <defs>
              <marker id="point-arrow" class="point-arrow" viewBox="0 -5 10 10" refX="4" markerWidth="6.0" markerHeight="6.0" orient="auto">
                <path d="M0,-5L10,0L0,5"></path>
              </marker>
            </defs>

            <g class="points-and-line">
              {{#if marker.showArrow}}
                <path class="link spline visible-spline" marker-end="url(#point-arrow)" d="{{marker.line}}">
                </path>
              {{else}}
                <path class="link spline visible-spline" d="{{marker.line}}">
                </path>
              {{/if}}

              <g class="marker-label" transform="translate({{marker.labelX}}, {{marker.labelY}})">
                <text x="75" class="marker-label-text">{{marker.labelText}}</text>
              </g>
            </g>
          </g>
      {{/if}}

      {{#if isHighlightReference}}
        {{#each highlights}}
          <g class="highlight-preview-svg">
            <path vector-effect="non-scaling-stroke" class="highlight-path-outline" d="{{this.path}}"></path>
            <g mask="url(#artboard-mask)">
              <path class="highlight-path" d="{{this.path}}"></path>
            </g>
          </g>
        {{/each}}
      {{/if}}
    {{/if}}
  </g>
</svg>
`

const compiledTemplate = Handlebars.compile(template);

export default compiledTemplate;
