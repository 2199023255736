import Component from '@glimmer/component';
import podNames from 'ember-component-css/pod-names';

class UiContextContainerHeader extends Component {
  get classNames() {
    let classNames = [this.styleNamespace, 'ui-context-container-header'];
    return classNames.join(' ');
  }

  get styleNamespace() {
    return podNames['ui-context-container-header'];
  }
}

export default UiContextContainerHeader;
