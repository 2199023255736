import Component from '@glimmer/component';
import podNames from 'ember-component-css/pod-names';

class UiGridOverlap extends Component {
  get styleNamespace() {
    return podNames['ui-grid-overlap'];
  }

  get classNames() {
    let classNames = [this.styleNamespace, 'ui-grid-overlap'];
    return classNames.join(' ');
  }

}

export default UiGridOverlap;
