/* eslint-disable ember/no-mixins */
import ApplicationRouteMixin from 'ember-simple-auth/mixins/application-route-mixin';
import ENV from '../../config/environment';
import Route from '@ember/routing/route';
import { inject as service } from "@ember/service";

export default Route.extend(ApplicationRouteMixin, {
    session: service(),
    sessionManager: service(),
    routeAfterAuthentication: 'dashboard',
    sessionInvalidated() {
      this._super(...arguments);
      this._unloadCurrentUser();
      this.transitionTo(ENV.SIGN_IN_ROUTE);
      this.set('sessionManager.user', null);
    },
    _unloadCurrentUser() {
      return this.set('sessionManager.user', null);
    },
  });
