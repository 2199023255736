import Component from '@glimmer/component';
import { action } from '@ember/object';
import podNames from 'ember-component-css/pod-names';
import { tracked } from '@glimmer/tracking';
import uuid from 'uuid/v4';

class UiSortableList extends Component {
  domElementId = `ui-sortable-list-${uuid()}`;
  itemClass = '';

  @tracked switched = false;

  constructor(owner, args) {
    super(owner, args);
    this.itemClass = this.args.itemClass || 'list-item';
  }

  get styleNamespace() {
    return podNames['ui-sortable-list'];
  }

  get classNames() {
    let classNames = [this.styleNamespace, 'sortable-list'];
    return classNames.join(' ');
  }

  @action
  onSort() {
    const nodeList = document.querySelectorAll(
      `#${this.domElementId} .${this.itemClass}`
    );
    const nodeArray = Array.from(nodeList);
    const ids = nodeArray.map((node) => node.dataset.id);
    if (this.args.onSort) {
      this.args.onSort(ids);
    }
  }

  @action
  toggleSwitched() {
    this.switched = !this.switched;
  }
}

export default UiSortableList;
