import Component from '@glimmer/component';
import { action } from '@ember/object';
import { connect } from 'ember-redux';
import { inject as service } from '@ember/service';
import Konva from 'konva';
import podNames from 'ember-component-css/pod-names';
import { getImage } from '../../../selectors/image';
import { task } from 'ember-concurrency-decorators';

const dispatchToActions = {};

const stateToComputed = (state, attrs) => ({
  image: getImage(state, attrs.imageId),
});

class DrawingImagePreviewKonva extends Component {
  @service assets;
  @service imageFiltersCache;
  @service redux;
  
  constructor(owner, args) {
    super(owner, args);
    this.onScheduleRender = this.args.onScheduleRender;
    this._index = this.args.index + 100;
  }

  @action
  didInsert() {
    this.setup();
    this._updatedAt = this.image.updatedAt;
  }

  @action
  willDestroyNode() {
    this.imageNode.destroy();
    this.onScheduleRender();
  }

  get styleNamespace() {
    return podNames['drawing-image-preview-konva'];
  }

  get classNames() {
    let classNames = [this.styleNamespace];
    return classNames.join(' ');
  }

  setup() {
    const imageNode = new Konva.Group({
      id: this.args.imageId,
      nodeType: 'image',
      x: this.image.x,
      y: this.image.y,
      rotation: this.image.rotation ? this.image.rotation : 0,
      zIndex: this.args.index + 100
    });

    const assetNode = new Konva.Image({
      width: this.image.width,
      height: this.image.height,
    });

    this.args.layer.add(imageNode);

    imageNode.add(assetNode);

    this.imageNode = imageNode;
    this.assetNode = assetNode;

    this.onUpdateReferenceImage.perform();

    this.onScheduleRender();
  }

  @task
  *onUpdateReferenceImage() {
    const blobUrl = yield this.imageFiltersCache.getBlobUrl.perform(this.args.imageId);

    if (!blobUrl) {
      return;
    }

    const assetFile = new Image();

    assetFile.onload = () => {
      this.assetNode.clearCache();
      this.assetNode.image(assetFile);
      this.assetNode.cache();
      // this.assetNode.filters([ThresholdFilter]);
      // this.assetNode.threshold(50);
      this.onScheduleRender(this.args.layer);
    };

    assetFile.src = blobUrl;
  }

  @action
  onUpdate(elem, [index, updatedAt]) {
    let detailsChanged = false;


    if (this._index !== index) {
      this._index = index;
      this.imageNode.zIndex(this.args.index  + 100)
      detailsChanged = true;
    }

    if (this._updatedAt !== updatedAt) {
      this._updatedAt = updatedAt;
      this.onUpdateReferenceImage.perform();
      detailsChanged = true;
    }

    if (detailsChanged) {
      this.onScheduleRender(this.args.layer);
    }
  }
}

export default connect(
  stateToComputed,
  dispatchToActions
)(DrawingImagePreviewKonva);
