import Model from '../utils/model';
import Service from '@ember/service';

export default Service.extend({
  cache: null,

  init() {
    this._super(...arguments);
    this.set('cache', {});
  },

  clearCache() {
    this.set('cache', {});
  },

  createModel(id, type, data) {
    return Model.create({
      ...data
    });
  },

  find(modelId) {
    return this.cache[modelId];
  },

  update(modelId, data) {
    let model;
    if (this.cache[modelId]) {
      model = this.cache[modelId];
      model.setProperties({ ...data });
    }
  },

  overwrite(modelId, type, data) {
    const model = this.createModel(modelId, type, data);
    this.cache[modelId] = model;
  },

  findOrCreate(modelId, type, data) {
    let model;
    if (this.cache[modelId]) {
      model = this.cache[modelId];
      // model.setProperties(data);
    } else {
      model = this.createModel(modelId, type, { ...data });
      this.cache[modelId] = model;
    }
    return model;
  }
});
