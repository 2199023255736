import Component from '@glimmer/component';
import { computed } from '@ember/object';
import { connect } from 'ember-redux';
import { getElement } from '../../../selectors/element';
import { getMethod } from '../../../selectors/method';

const dispatchToActions = {};

const stateToComputed = (state, attrs) => ({
  method: getMethod(state, attrs.methodId),
  element: getElement(state, attrs.elementId),
});

class MethodElementName extends Component {

  @computed('element.name')
  get elementName() {
    return this.element && this.element.name;
  }

}

export default connect(stateToComputed, dispatchToActions)(MethodElementName);
