import {
  METHOD_NODE_COLOR,
  METHOD_NODE_FILL,
  METHOD_NODE_STROKE,
  METHOD_NODE_STROKE_DISCONNECTED,
  PRIMARY_COLOR,
} from '../../../constants/colors';
import { action, computed } from '@ember/object';
import { alias, bool } from '@ember/object/computed';
import {
  getMethodNode,
  getMethodNodeElement,
  getMethodNodeFeatureId,
  getMethodNodeLabel,
  getSubmethodId,
} from '../../../selectors/method-node';

import Component from '@glimmer/component';
import { connect } from 'ember-redux';
import { getFeature } from '../../../selectors/feature';
import { getMethodNodeOrdinal } from '../../../selectors/invention';
import { getSelectedElements } from '../../../selectors/invention-ui';
import { getStepNode } from '../../../utils/method-graph';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';
import uuid from 'uuid/v4';

const dispatchToActions = {};

const stateToComputed = (state, attrs) => ({
  methodNode: getMethodNode(state, attrs.methodNodeId),
  submethodId: getSubmethodId(state, attrs.methodNodeId, attrs.productId),
  element: getMethodNodeElement(state, attrs.methodNodeId),
  featureId: getMethodNodeFeatureId(state, attrs.methodNodeId),
  selectedElements: getSelectedElements(state),
});

class MethodNode extends Component {
  @service store;
  @service redux;
  @service models;
  @service tracking;
  @service applicationState;
  @service settings;

  constructor(owner, args) {
    super(owner, args);
    this.onScheduleRender = this.args.onScheduleRender;
  }

  @action
  didInsert() {
    this.setup();
    this._updatedAt = this.methodNode.updatedAt;
    this._isSelected = this.args.isSelected;
    this._isCreatingSource = this.args.isCreatingSource;
    this._isDisconnected = this.args.isDisconnected;
    this._stepNumber = this.stepNumber;
    this._showStepNumber = this.showStepNumber;
    this._showOrdinal = this.showOrdinal;
    this._showElementNode = this.showElementNode;
    this._showSubmethod = this.showSubmethod;
    this._canTransform = this.args.canTransform;
    this._methodNodeX = this.methodNode.x;
    this._methodNodeY = this.methodNode.y;
    this._label = this.label;
    this._elementName = this.elementName;
    this._methodAutoArrangeIndex = this.methodAutoArrangeIndex;
    this._undoIndex = this.undoIndex;
    this._index = this.args.index + 100;
  }

  @action
  willDestroyNode() {
    this.backgroundNode.off('click');
    this.elementLabelNode.off('click');
    this.containerNode.destroy();
    this.onScheduleRender();
  }

  get styleNamespace() {
    return podNames['method-node-konva'];
  }

  get classNames() {
    let classNames = [this.styleNamespace];
    if (this.args.isSelected) {
      classNames.push('is-selected');
    } else {
      classNames.push('isnt-selected');
    }
    return classNames.join(' ');
  }

  stepNumberRadius = 20;
  stepNumberSpacing = 30;
  stepNumberFontSize = 20;
  ordinalHeight = 19;
  ordinalFontSize = 18;
  strokeWidth = 2;
  fontFamily = 'Inter';
  fontSize = 18;
  elementFontSize = 15;
  elementLabelHeight = 19;
  domElementId = `method-node-konva-${uuid()}`;
  disconnectedDash = [15, 10];

  @bool('args.onDragEnd') isDraggable;
  @bool('element.id') isElementNode;
  @bool('stepNumber') hasStepNumber;
  @bool('ordinal') hasOrdinal;
  @bool('submethodHasNodes') showSubmethod;
  @alias('methodNode.x') x;
  @alias('methodNode.y') y;
  @alias('methodNode.width') width;
  @alias('methodNode.height') height;
  @alias('methodNode.type') type;
  @alias('element.name') elementName;
  @alias('element.id') elementId;
  @alias('applicationState.methodAutoArrangeIndex') methodAutoArrangeIndex;
  @alias('applicationState.undoIndex') undoIndex;

  @computed('args.isDisconnected')
  get dashEnabled() {
    return this.args.isDisconnected ? true : false;
  }

  @computed('isElementNode', 'args.showElementNode')
  get showElementNode() {
    return this.isElementNode && this.args.showElementNode ? true : false;
  }

  @computed('hasStepNumber', 'args.showStepNumber')
  get showStepNumber() {
    return this.hasStepNumber && this.args.showStepNumber ? true : false;
  }

  @computed('hasOrdinal', 'args.showOrdinal')
  get showOrdinal() {
    return this.hasOrdinal && this.args.showOrdinal ? true : false;
  }

  @computed('methodNode.id')
  get methodNodeModel() {
    return (
      this.methodNode &&
      this.models.findOrCreate(
        this.methodNode.id,
        'method-node',
        this.methodNode
      )
    );
  }

  @computed('featureId')
  get featureModel() {
    const state = this.redux.getState();
    return (
      this.featureId &&
      this.models.findOrCreate(
        this.featureId,
        'feature',
        getFeature(state, this.featureId)
      )
    );
  }

  @computed(
    'args.{isStartNode,methodNodeId}',
    'domElementId',
    'featureModel.value'
  )
  get label() {
    const state = this.redux.getState();
    return getMethodNodeLabel(
      state,
      this.args.methodNodeId,
      this.featureModel,
      this.domElementId,
      this.args.isStartNode
    );
  }

  get elementLabelColor() {
    return this.args.isSelected ? PRIMARY_COLOR : METHOD_NODE_COLOR;
  }

  get labelColor() {
    return METHOD_NODE_COLOR;
  }

  get elementLabelFillColor() {
    return 'transparent';
  }

  get backgroundFillColor() {
    return 'transparent';
  }

  get backgroundStrokeColor() {
    let color = METHOD_NODE_STROKE;

    if (this.args.isDisconnected) {
      color = METHOD_NODE_STROKE_DISCONNECTED;
    }

    if (this.args.isSelected) {
      color = PRIMARY_COLOR;
    }

    if (this.args.isCreatingSource) {
      color = PRIMARY_COLOR;
    }
    return color;
  }

  get ordinalColor() {
    return METHOD_NODE_COLOR;
  }

  get stepNumberColor() {
    return this.args.isSelected ? PRIMARY_COLOR : METHOD_NODE_COLOR;
  }

  get stepNumberFillColor() {
    return METHOD_NODE_FILL;
  }

  get stepNumberStrokeColor() {
    return this.args.isSelected ? PRIMARY_COLOR : METHOD_NODE_COLOR;
  }

  get elementLabelY() {
    return -1 * this.elementLabelHeight - this.strokeWidth - 1;
  }

  get ordinalY() {
    return -1 * this.ordinalHeight - this.strokeWidth - 1;
  }

  @computed('submethodId')
  get submethodModel() {
    return (
      this.submethodId && this.store.peekRecord('method', this.submethodId)
    );
  }

  @computed('submethodModel.methodNodesList.[]')
  get submethodHasNodes() {
    return (
      this.submethodModel && this.submethodModel.methodNodesList.length > 1
    );
  }

  @computed('args.{methodId,methodNodeId}')
  get methodModel() {
    return this.store.peekRecord('method', this.args.methodId);
  }

  @computed('methodModel.ungroupedStepsList.[]', 'args.methodNodeId')
  get stepNumber() {
    const step =
      this.methodModel &&
      this.methodModel.ungroupedStepsList.find(
        (step) => step.modelId === this.args.methodNodeId
      );
    return step && step.number;
  }

  @computed('methodModel.traversedNodesList.[]', 'args.{methodId,methodNodeId}')
  get ordinal() {
    const state = this.redux.getState();
    return (
      this.methodModel &&
      getMethodNodeOrdinal(
        state,
        this.args.methodId,
        this.args.methodNodeId,
        this.methodModel.traversedNodesList
      )
    );
  }

  setup() {
    const step = {
      id: this.args.methodNodeId,
      index: this.args.index,
      isStartNode: this.args.isStartNode,
      isDisconnected: this.args.isDisconnected,
      ordinal: this.ordinal,
      label: this.label,
      elementName: this.elementName,
      stepNumber: this.stepNumber,
      submethodHasNodes: this.submethodHasNodes,
      x: this.x,
      y: this.y,
      width: this.width,
      height: 30,
    };

    const {
      containerNode,
      backgroundNode,
      submethodNode,
      labelNode,
      elementLabelNode,
      stepNumberNode,
      ordinalNode,
      ordinalTextNode,
      stepNumberBackgroundNode,
      stepNumberTextNode,
      elementLabelBackgroundNode,
      elementLabelTextNode,
    } = getStepNode(step, {
      isDraggable: false,
      showSubmethod: this.showSubmethod,
      showElementNode: this.showElementNode,
      showStepNumber: this.showStepNumber,
      showOrdinal: this.showOrdinal,
      blackAndWhiteMode: this.settings.blackAndWhiteMode
    });

    // add events
    if (this.args.onClick) {
      backgroundNode.on('click', () => {
        this.args.onClick(this.args.methodNodeId, this.args.isSelected, this.type);
      });
      elementLabelNode.on('click', () =>
        this.args.onClick(this.args.methodNodeId, this.args.isSelected, this.type)
      );
    }

    this.args.layer.add(containerNode);

    containerNode.add(submethodNode);
    containerNode.add(backgroundNode);
    containerNode.add(labelNode);
    containerNode.add(elementLabelNode);
    containerNode.add(stepNumberNode);
    containerNode.add(ordinalNode);

    this.containerNode = containerNode;
    this.submethodNode = submethodNode;
    this.backgroundNode = backgroundNode;
    this.labelNode = labelNode;
    this.elementLabelNode = elementLabelNode;
    this.elementLabelBackgroundNode = elementLabelBackgroundNode;
    this.elementLabelTextNode = elementLabelTextNode;
    this.stepNumberNode = stepNumberNode;
    this.stepNumberTextNode = stepNumberTextNode;
    this.stepNumberBackgroundNode = stepNumberBackgroundNode;
    this.ordinalNode = ordinalNode;
    this.ordinalTextNode = ordinalTextNode;

    this.updateHeight();
    this.updateColors();

    this.onScheduleRender();
  }

  updateColors() {
    this.submethodNode.stroke(this.backgroundStrokeColor);
    this.backgroundNode.stroke(this.backgroundStrokeColor);
    this.stepNumberTextNode.fill(this.stepNumberColor);
    this.stepNumberBackgroundNode.stroke(this.stepNumberStrokeColor);
    this.elementLabelTextNode.fill(this.elementLabelColor);
  }

  updateHeight() {
    const height = this.labelNode.height();
    this.submethodNode.height(height);
    this.backgroundNode.height(height);
    this.methodNodeModel.setProperties({ height });
  }

  updatePosition() {
    this.containerNode.x(this.methodNode.x);
    this.containerNode.y(this.methodNode.y);

    this._methodNodeX = this.methodNode.x;
    this._methodNodeY = this.methodNode.y;

    this.submethodNode.width(this.methodNode.width);
    this.backgroundNode.width(this.methodNode.width);
    this.labelNode.width(this.methodNode.width);
    this.elementLabelTextNode.width(
      this.methodNode.width - this.stepNumberSpacing
    );
    this.elementLabelBackgroundNode.width(this.methodNode.width);

    this.submethodNode.height(this.labelNode.height());
    this.backgroundNode.height(this.labelNode.height());
  }

  @action
  onUpdate(elem, [isSelected, showStepNumber, showOrdinal, showElementNode]) {
    let detailsChanged = false;

    if (this._isSelected !== isSelected) {
      this._isSelected = isSelected;
      this.updateColors();
      detailsChanged = true;
    }

    if (this._showStepNumber !== showStepNumber) {
      this._showStepNumber = showStepNumber;
      this.stepNumberNode.visible(this.showStepNumber);
      detailsChanged = true;
    }

    if (this._showOrdinal !== showOrdinal) {
      this._showOrdinal = showOrdinal;
      this.ordinalNode.visible(this.showOrdinal);
      detailsChanged = true;
    }

    if (this._showElementNode !== showElementNode) {
      this._showElementNode = showElementNode;
      this.elementLabelNode.visible(this.showElementNode);
      detailsChanged = true;
    }

    if (detailsChanged) {
      this.onScheduleRender(this.args.layer);
    }
  }
}

export default connect(stateToComputed, dispatchToActions)(MethodNode);
