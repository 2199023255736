import Component from '@glimmer/component';
import podNames from 'ember-component-css/pod-names';

class UiListToken extends Component {
  get classNames() {
    let classNames = [this.styleNamespace, 'ui-list-token'];
    return classNames.join(' ');
  }

  get styleNamespace() {
    return podNames['ui-list-token'];
  }

}

export default UiListToken;
