import { action, computed } from '@ember/object';
import {
  getMarkerNumber,
  getMarkerOrdinal,
} from '../../../selectors/invention';
import {
  mentionContentTemplate,
  termMentionContentTemplate,
} from '../../../utils/realization';

import Component from '@glimmer/component';
import { alias } from '@ember/object/computed';
import { connect } from 'ember-redux';
import { getElementName } from '../../../selectors/element';
import { getMarker } from '../../../selectors/marker';
import { getMentionsContent } from '../../../selectors/mention';
import { getTermName } from '../../../selectors/term';
import { htmlSafe } from '@ember/string';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';
import uuid from 'uuid/v4';

const dispatchToActions = {};

const stateToComputed = (state, attrs) => ({
  marker: getMarker(state, attrs.markerId),
  number: getMarkerNumber(state, attrs.markerId),
  ordinal: getMarkerOrdinal(state, attrs.markerId),
});

class MarkerListItem extends Component {
  @service redux;

  domElementId = `preview-marker-item-${uuid()}`;

  get styleNamespace() {
    return podNames['preview-marker-item'];
  }

  get classNames() {
    let classNames = [this.styleNamespace, 'preview-marker-item'];
    if (this.args.isSelected) classNames.push('is-selected');
    return classNames.join(' ');
  }

  @alias('marker.category') category;
  @alias('marker.type') type;

  @computed('category', 'type')
  get categoryName() {
    let name;
    switch (this.category) {
      case 'system':
        name = 'System';
        break;
      case 'part':
        name = 'Part';
        break;
    }

    if (this.type === 'term') {
      name = 'Term';
    }

    return name;
  }

  @computed('domElementId', 'marker.{element,elementVersion,term}')
  get mentionsValue() {
    const state = this.redux.getState();
    let mentionsValue;

    if (this.marker.element) {
      const elementName = getElementName(state, this.marker.element);
      mentionsValue = mentionContentTemplate(
        elementName,
        this.marker.element,
        this.marker.elementVersion
      );
    }

    if (this.marker.term) {
      const termName = getTermName(state, this.marker.term);
      mentionsValue = termMentionContentTemplate(termName, this.marker.term);
    }

    if (mentionsValue) {
      mentionsValue = getMentionsContent(
        state,
        mentionsValue,
        this.domElementId,
        {
          appendOrdinal: false,
          appendElementVersion: true,
          isInput: false,
          isInteractive: true,
          isClickable: false,
        }
      );

      mentionsValue = htmlSafe(mentionsValue);
    }
    return mentionsValue;
  }

  @action
  onClick() {
    if (this.args.onClick) {
      this.args.onClick(this.args.markerId, this.args.isSelected);
    }
  }
}

export default connect(stateToComputed, dispatchToActions)(MarkerListItem);
