// import { batchGroupBy } from '../../../utils/redux';

import { action, computed } from '@ember/object';
import { cancel, later } from '@ember/runloop';
import {
  elementMentionTemplate,
  sanitizeMentions,
  termMentionTemplate,
  tributeElementMentionTemplate,
  tributeTermMentionTemplate,
} from '../../../utils/mentions';
import { getElement, getElementsList } from '../../../selectors/element';
import { getTerm, getTermsList } from '../../../selectors/term';
import { removeSelection, replaceSelection } from '../../../utils/selection';

import Component from '@glimmer/component';
import FuzzySearch from 'fz-search';
import { connect } from 'ember-redux';
import { getElementVersion } from '../../../selectors/element-version';
import { isTextEqual } from '../../../utils/string';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';
// import { sortBy } from 'lodash';
import { tracked } from '@glimmer/tracking';
import uuid from 'uuid/v4';

const stateToComputed = (state) => ({
  elementsList: getElementsList(state),
  termsList: getTermsList(state),
});

const dispatchToActions = {};

class UiContentMentionableContextMenu extends Component {
  @service redux;
  @service contextMenu;
  @service documentSelection;
  @service tracking;

  @tracked searchResults = [];

  get styleNamespace() {
    return podNames['ui-content-mentionable-context-menu'];
  }

  get classNames() {
    let classNames = [
      this.styleNamespace,
      'ui-content-mentionable-context-menu',
    ];
    return classNames.join(' ');
  }

  calculateNestedPosition(trigger, menu) {
    const windowHeight = window.innerHeight;
    const windowWidth = window.innerWidth;
    const menuHeight = menu.getBoundingClientRect().height;
    const menuWidth = menu.getBoundingClientRect().width;

    let { top, left, width, height } = trigger.getBoundingClientRect();

    let style = {
      position: 'absolute',
      left: left + width,
      top: top,
    };

    if (top + height + menuHeight > windowHeight) {
      const bottom = windowHeight - (top + height);
      style.top = 'auto';
      style.bottom = `${bottom}px`;
    }

    if (left + width + menuWidth > windowWidth) {
      style.left = left - menuWidth;
    }

    return { style };
  }

  @action
  didInsert() {
    this.search();
    this.tracking.trackEvent('opened_mentionable_context_menu');
  }

  get searchResultsWithTemplates() {
    return this.searchResults.map((item) => {
      let template;
      switch (item.type) {
        case 'term':
          template = tributeTermMentionTemplate(item.id, item.name);
          break;
        case 'element':
          template = tributeElementMentionTemplate(
            item.id,
            item.elementVersionNames,
            item.name
          );
          break;
      }
      return {
        ...item,
        template,
      };
    });
  }

  getUniqueId(domElementId, elementId) {
    const numberOfMentions = document.querySelectorAll(
      `#${domElementId} .mention`
    ).length;
    return `${domElementId}-${elementId}-${numberOfMentions}`;
  }

  search() {
    if (this.selectedTextIsHTML) {
      return;
    }

    const searcher = new FuzzySearch({
      source: this.collection,
      keys: ['searchText'],
      token_query_min_length: 0,
      token_field_min_length: 0,
      thresh_include: 7.0,
    });

    const results = searcher.search(this.selectedText);

    // const sortedResults = sortBy(results, ['searchTextLength', 'searchText']);
    // const filteredResults = sortedResults.filter((result, index) => index < 7);
    const filteredResults = results.filter((result, index) => index < 7);

    this.searchResults = filteredResults;
  }

  @computed('termsList.[]')
  get terms() {
    const state = this.redux.getState();
    return this.termsList.map((termId) => {
      return getTerm(state, termId);
    });
  }

  @computed('elementsList.[]')
  get elements() {
    const state = this.redux.getState();
    return this.elementsList.map((elementId) => {
      return getElement(state, elementId);
    });
  }

  @computed('contextMenu.domElementId')
  get domElementId() {
    return this.contextMenu.domElementId;
  }

  @computed('contextMenu.modelId')
  get modelId() {
    return this.contextMenu.modelId;
  }

  @computed('contextMenu.modelType')
  get modelType() {
    return this.contextMenu.modelType;
  }

  @computed('contextMenu.modelAttr')
  get modelAttr() {
    return this.contextMenu.modelAttr;
  }

  @computed('documentSelection.selectedText')
  get selectedText() {
    return this.documentSelection.selectedText;
  }

  @computed('documentSelection.selectedHtml')
  get selectedHtml() {
    return this.documentSelection.selectedHtml;
  }

  @computed('selectedText', 'searchResults.@each.name')
  get selectedTextIsUnique() {
    return !this.searchResults.find((_element) =>
      isTextEqual(_element.name, this.selectedText)
    );
  }

  @computed('selectedHtml')
  get selectedTextIsHTML() {
    return /<\/?[a-z][\s\S]*>/i.test(this.selectedHtml);
  }

  @computed('selectedTextIsUnique', 'selectedTextIsHTML')
  get cantCreate() {
    return !this.selectedTextIsUnique || this.selectedTextIsHTML;
  }

  // @computed('searchResults.[]', 'selectedTextIsHTML')
  // get cantReference() {
  //   return !this.searchResults.length || this.selectedTextIsHTML;
  // }

  @computed('elements.[]', 'terms.[]')
  get collection() {
    const state = this.redux.getState();
    const typedElements = this.elements.map((element) => {
      const elementVersionNames = element.elementVersionsList
        .map((elementVersionId) => {
          const elementVersion = getElementVersion(state, elementVersionId);
          return elementVersion.name;
        })
        .filter((name) => name.indexOf('Solution') === -1);
      const searchText = `${element.name} ${elementVersionNames.join(' ')}`;
      return {
        ...element,
        searchText,
        searchTextLength: (element.name && element.name.length) || 0,
        elementVersionNames,
        type: 'element',
      };
    });
    const typedTerms = this.terms.map((term) => {
      return {
        ...term,
        searchText: term.name,
        searchTextLength: (term.name && term.name.length) || 0,
        type: 'term',
      };
    });
    return [...typedElements, ...typedTerms];
  }

  @action
  onReference(reference) {
    if (this.args.onReferenceElement && this.args.onReferenceTerm) {
      const { id, name, type } = reference;
      const domElementId = this.domElementId;
      const modelId = this.modelId;
      const modelType = this.modelType;
      const modelAttr = this.modelAttr;
      const mentionDomElementId = this.getUniqueId(domElementId, id);

      if (type === 'element') {
        const template = elementMentionTemplate(
          id,
          null,
          name,
          mentionDomElementId
        );
        replaceSelection(template);
      }

      if (type === 'term') {
        const template = termMentionTemplate(id, name, mentionDomElementId);
        replaceSelection(template);
      }

      removeSelection();

      let value = document.querySelector(
        `#${domElementId} .medium-editor-element`
      ).innerHTML;

      value = sanitizeMentions(value);

      if (type === 'element') {
        this.args.onReferenceElement(modelId, modelType, modelAttr, value);
        this.tracking.trackEvent('mentionable_referenced_system');
      }

      if (type === 'term') {
        this.args.onReferenceTerm(modelId, modelType, modelAttr, value);
        this.tracking.trackEvent('mentionable_referenced_term');
      }
    }
    this.contextMenu.close();
  }

  @action
  onRemoveSelectedTreeItems() {}

  @action
  onAddElement(category) {
    if (this.args.onCreateElementFromText) {
      const elementName = this.selectedText;
      const elementId = uuid();
      const modelId = this.modelId;
      const modelType = this.modelType;
      const modelAttr = this.modelAttr;
      const domElementId = this.domElementId;

      const mentionDomElementId = this.getUniqueId(domElementId, elementId);

      replaceSelection(
        elementMentionTemplate(
          elementId,
          null,
          elementName,
          mentionDomElementId
        )
      );

      removeSelection();

      let value = document.querySelector(
        `#${domElementId} .medium-editor-element`
      ).innerHTML;

      value = sanitizeMentions(value);

      this.args.onCreateElementFromText(
        elementName,
        modelId,
        modelType,
        modelAttr,
        value,
        elementId,
        null,
        category
        // termId
      );
      this.tracking.trackEvent('mentionable_created_system');
    }

    this.contextMenu.close();
  }

  @action
  onAddTerm() {
    if (this.args.onCreateElementFromText) {
      const termName = this.selectedText;
      const termId = uuid();
      const modelId = this.modelId;
      const modelType = this.modelType;
      const modelAttr = this.modelAttr;
      const domElementId = this.domElementId;

      const mentionDomElementId = this.getUniqueId(domElementId, termId);

      const template = termMentionTemplate(
        termId,
        termName,
        mentionDomElementId
      );
      replaceSelection(template);
      removeSelection();

      let value = document.querySelector(
        `#${domElementId} .medium-editor-element`
      ).innerHTML;

      value = sanitizeMentions(value);

      this.args.onCreateElementFromText(
        termName,
        modelId,
        modelType,
        modelAttr,
        value,
        null,
        termId
      );
      this.tracking.trackEvent('mentionable_created_term');
    }
    this.contextMenu.close();
  }

  prevent(e) {
    return e.stopImmediatePropagation();
  }

  @action
  open(dropdown) {
    if (this[`closeTimer_${dropdown.uniqueId}`]) {
      cancel(this[`closeTimer_${dropdown.uniqueId}`]);
      this[`closeTimer_${dropdown.uniqueId}`] = null;
    } else {
      dropdown.actions.open();
    }
  }

  @action
  closeLater(dropdown) {
    this[`closeTimer_${dropdown.uniqueId}`] = later(() => {
      this[`closeTimer_${dropdown.uniqueId}`] = null;
      dropdown.actions.close();
    }, 10);
  }

  @action
  onNestedMouseenter(dropdown) {
    cancel(this[`closeTimer_${dropdown.uniqueId}`]);
  }

  @action
  onNestedMouseleave(dropdown) {
    this[`closeTimer_${dropdown.uniqueId}`] = later(() => {
      this[`closeTimer_${dropdown.uniqueId}`] = null;
      dropdown.actions.close();
    }, 200);
  }
}

export default connect(
  stateToComputed,
  dispatchToActions
)(UiContentMentionableContextMenu);
