import { action, computed } from '@ember/object';
import {
  getIsPreferred,
  getPreferredElementVersionId,
  getPreferredElementVersionsList,
} from '../../../selectors/product';

import Component from '@glimmer/component';
import { connect } from 'ember-redux';
import podNames from 'ember-component-css/pod-names';
import { selectElementVersion } from '../../../actions/invention-ui';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import uuid from 'uuid/v4';

const dispatchToActions = {};

const stateToComputed = (state, attrs) => ({
  preferredElementVersionsList: getPreferredElementVersionsList(
    state,
    attrs.productId
  ),
});

class InstanceElementVersionItemsList extends Component {
  @service data;
  @tracked switched = false;
  uniqueId = uuid();

  get styleNamespace() {
    return podNames['element-version-items-list'];
  }

  get classNames() {
    let classNames = [
      this.styleNamespace,
      'instance-element-version-items-list',
    ];
    return classNames.join(' ');
  }

  // eslint-disable-next-line ember/require-computed-property-dependencies
  @computed('args.{elementId,productId}')
  get hasPreferred() {
    const state = this.redux.getState();
    return getPreferredElementVersionId(
      state,
      this.args.elementId,
      this.args.productId
    )
      ? true
      : false;
  }

  @action
  setPreferredElementVersion(elementVersionId) {
    if (this.args.onSetPreferredElementVersion) {
      let state = this.redux.getState();
      let elementId = this.args.elementId;
      const isPreferred = getIsPreferred(
        state,
        elementId,
        elementVersionId,
        this.args.productId
      );

      if (isPreferred) {
        this.args.onSetPreferredElementVersion(
          elementId,
          null,
          this.args.productId
        );
      } else {
        this.args.onSetPreferredElementVersion(
          elementId,
          elementVersionId,
          this.args.productId
        );
      }
    }
  }

  @action
  select(elementVersionId) {
    this.redux.store.dispatch(selectElementVersion(elementVersionId));
  }
}
export default connect(
  stateToComputed,
  dispatchToActions
)(InstanceElementVersionItemsList);
