import Route from '@ember/routing/route';
// import { inject as service } from '@ember/service';

export default class HistoryRoute extends Route {
  // @service session;
  // @service sessionManager;
  // @service data;

  async model(params) {
    return this.store.findRecord('instance', params.instance_id)
  }
}
