import Component from '@glimmer/component';
import { computed } from '@ember/object';
import { connect } from 'ember-redux';
import podNames from 'ember-component-css/pod-names';
import { getComparison } from '../../../selectors/comparison';

const stateToComputed = (state, attrs) => ({
  comparison: getComparison(state, attrs.comparisonId),
});

const dispatchToActions = {};

class ComparisonNoveltyCell extends Component {
  get styleNamespace() {
    return podNames['comparison-novelty-cell'];
  }

  // eslint-disable-next-line ember/require-computed-property-dependencies
  @computed('isNovel', 'isSomewhatNovel')
  get classNames() {
    let classNames = [this.styleNamespace, 'comparison-novelty-cell'];
    if (this.isSomewhatNovel) classNames.push('is-somewhat-novel');
    if (this.isNovel) classNames.push('is-novel');
    return classNames.join(' ');
  }

  @computed('comparison.featuresList.[]')
  get featuresList() {
    return this.comparison && this.comparison.featuresList;
  }

  @computed('featuresList.[]', 'args.featureId')
  get isSomewhatNovel() {
    return this.featuresList && this.featuresList.includes(this.args.featureId);
  }

  // eslint-disable-next-line ember/require-computed-property-dependencies
  @computed('args.{comparisonsList.[],featureId}')
  get isNovel() {
    const state = this.redux.getState();
    const novelComparsionsList = [];
    this.args.comparisonsList.forEach((comparisonId) => {
      const comparison = getComparison(state, comparisonId);
      if (comparison.featuresList.includes(this.args.featureId)) {
        novelComparsionsList.push(comparisonId);
      }
    });
    return novelComparsionsList.length === this.args.comparisonsList.length;
  }

  @computed('isSomewhatNovel', 'isNovel')
  get isntNovel() {
    return !this.isSomewhatNovel && !this.isNovel;
  }
}

export default connect(
  stateToComputed,
  dispatchToActions
)(ComparisonNoveltyCell);
