import Component from '@glimmer/component';
import { connect } from 'ember-redux';
import podNames from 'ember-component-css/pod-names';

const dispatchToActions = {};

const stateToComputed = null;

class DrawingContextType extends Component {

  get classNames() {
    let classNames = ['drawing-context-type', this.styleNamespace];
    return classNames.join(' ');
  }

  get styleNamespace() {
    return podNames['drawing-context-type'];
  }
}

export default connect(
  stateToComputed,
  dispatchToActions
)(DrawingContextType);
