import Component from '@glimmer/component';
import podNames from 'ember-component-css/pod-names';

class UiContextSectionList extends Component {

  get styleNamespace() {
    return podNames['ui-context-section-list'];
  }

  get classNames() {
    let classNames = [this.styleNamespace, 'ui-context-section-list'];
    return classNames.join(' ');
  }
}

export default UiContextSectionList;
