import { BLACK_AND_WHITE_STROKE, HIGHLIGHT_FILL, HIGHLIGHT_STROKE } from '../../../constants/colors';
import { maxBy, minBy } from 'lodash';

import Component from '@glimmer/component';
import Konva from 'konva';
import { action } from '@ember/object';
import { connect } from 'ember-redux';
import { getHighlight } from '../../../selectors/highlight';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';
import uuid from 'uuid/v4';

const dispatchToActions = {};

const stateToComputed = (state, attrs) => ({
  highlight: getHighlight(state, attrs.highlightId),
});

class HighlightReference extends Component {
  @service settings;
  container = `highlight-reference-${uuid()}`;

  get classNames() {
    let classNames = [this.styleNamespace, 'highlight-reference'];
    return classNames.join(' ');
  }

  get styleNamespace() {
    return podNames['highlight-reference'];
  }

  willDestroy() {
    this.stage.destroy();
  }

  @action
  didInsert() {
    this.setup();
  }

  @action
  setup() {
    const stage = new Konva.Stage({
      container: this.container,
    });

    const layer = new Konva.Layer({ name: 'main' });

    stage.add(layer);

    const highlightNode = this.getHighlightNode(this.highlight);

    layer.add(highlightNode);

    this.stage = stage;
    this.highlightNode = highlightNode;

    this.fitStageToContainer();
    this.fitToScreen();
  }

  fitStageToContainer() {
    const stage = this.stage;
    const container = document.getElementById(this.container);
    const width = container.offsetWidth;
    const height = container.offsetHeight;
    stage.width(width);
    stage.height(height);
    stage.x(width / 2);
    stage.y(height / 2);
    stage.draw();
  }

  fitToScreen() {
    const stage = this.stage;
    const padding = 150;

    const bounds = this.bounds;

    const width = stage.width();
    const height = stage.height();

    let scale = Math.min(
      width / (bounds.width + padding),
      height / (bounds.height + padding)
    );

    stage.scale({
      x: scale,
      y: scale,
    });
    stage.offset({
      x: bounds.x,
      y: bounds.y,
    });

    const strokeWidth = 2;
    this.highlightNode.strokeWidth(Math.floor(strokeWidth / scale));

    stage.draw();
  }

  get bounds() {
    const pointsArray = this.highlight.points.split(' ').map((pointString) => {
      const pointArray = pointString.split(',');
      const x = Number(pointArray[0]);
      const y = Number(pointArray[1]);
      return { x, y };
    });

    const top = minBy(pointsArray, (point) => point.y).y;
    const left = minBy(pointsArray, (point) => point.x).x;
    const right = maxBy(pointsArray, (point) => point.x).x;
    const bottom = maxBy(pointsArray, (point) => point.y).y;

    const width = Math.abs(right - left);
    const height = Math.abs(bottom - top);
    const x = left + width / 2;
    const y = top + height / 2;

    return {
      top,
      bottom,
      left,
      right,
      width,
      height,
      x,
      y,
    };
  }

  getHighlightNode(highlight) {
    const points = [];
    const pointsArray = highlight.points.split(' ').map((pointString) => {
      const pointArray = pointString.split(',');
      const x = Number(pointArray[0]);
      const y = Number(pointArray[1]);
      return { x, y };
    });
    pointsArray.forEach((point) => {
      points.push(point.x);
      points.push(point.y);
    });

    const lineNode = new Konva.Line({
      points: points,
      fill: this.settings.blackAndWhiteMode ? 'transparent' : HIGHLIGHT_FILL,
      stroke: this.settings.blackAndWhiteMode ? BLACK_AND_WHITE_STROKE : HIGHLIGHT_STROKE,
      strokeWidth: 14,
      closed: true,
    });

    return lineNode;
  }
}

export default connect(stateToComputed, dispatchToActions)(HighlightReference);
