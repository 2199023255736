import { getDrawing, getDrawingsList } from '../selectors/drawing';
import {
  getElementsMap,
  getMethodsList,
  getOrphanNodesList,
  getRootNodeId,
} from '../selectors/graph';
import {
  getPreferredElementVersion,
  getPreferredElementVersionId,
  getPreferredProductId,
  getProductsList
} from '../selectors/product';

import { getElement } from '../selectors/element';
import { getElementVersion } from '../selectors/element-version';
import { getFiguresList } from '../selectors/figure';
import { getMarker } from '../selectors/marker';
import { getMethod } from '../selectors/method';
import moize from 'moize';

export const getFigures = (state) => {
  const breadth = getBreadthFigures(state);
  const depth = {
    figuresMap: {},
    figuresList: [],
    products: {},
  };
  const custom = getCustomFigures(state);
  return {
    breadth,
    depth,
    custom,
  };
};

export const getCustomFigures = (state) => {
  let figuresMap = {};
  let figuresList = [];
  let products = {};
  let sequence = 1;

  const drawingsList = getDrawingsList(state);
  const methodsList = getMethodsList(state);

  drawingsList.forEach((drawingId) => {
    figuresMap[drawingId] = {
      id: drawingId,
      type: 'drawing',
      sequence,
    };
    figuresList.push({
      id: drawingId,
      type: 'drawing',
      sequence,
    });
    sequence++;
  });

  methodsList.forEach((methodId) => {
    figuresMap[methodId] = {
      id: methodId,
      type: 'method',
      sequence,
    };
    figuresList.push({
      id: methodId,
      type: 'method',
      sequence,
    });
    sequence++;
  });

  return { figuresMap, figuresList, products };
};

export const getBreadthFigures = (state) => {
  let figuresMap = {};
  let figuresList = [];
  let products = {};
  let sequence = 1;

  const productsList = getProductsList(state);

  productsList.forEach((productId) => {
    const productFigures = getProductFigures(state, productId);

    products[productId] = {
      figuresList: productFigures.figuresList,
      figuresMap: productFigures.figuresMap,
    };

    productFigures.figuresList.forEach((figure) => {
      if (!figuresMap[figure.id]) {
        figuresMap[figure.id] = {
          id: figure.id,
          type: figure.type,
          sequence,
        };
        figuresList.push({
          id: figure.id,
          type: figure.type,
          sequence,
        });
        sequence++;
      }
    });
  });

  return { figuresMap, figuresList, products };
};

const getElementVersionDrawings = (state, elementVersionId) => {
  const elementVersion = getElementVersion(state, elementVersionId);
  const markersList = elementVersion.markersList || [];
  return markersList.map((markerId) => {
    const marker = markerId && getMarker(state, markerId);
    return marker && marker.drawing;
  });
};

const getElementVersionMethod = (state, elementVersionId) => {
  const elementVersion = getElementVersion(state, elementVersionId);
  const methodId = elementVersion && elementVersion.method;
  const method = methodId && getMethod(state, methodId);
  const hasNodes = method && method.methodNodesList.length > 1;
  return hasNodes ? methodId : undefined;
};

export const getProductFigures = (state, productId) => {
  const elementsMap = getElementsMap(state, productId);
  const orphanNodesList = getOrphanNodesList(state);
  const orphanDescendants = [];
  orphanNodesList.forEach((orphanNodeId) => {
    const orphanRootNode = elementsMap[orphanNodeId];
    if (orphanRootNode) {
      orphanDescendants.push(orphanRootNode.id);
      orphanRootNode.descendants.forEach((id) => orphanDescendants.push(id));
    }
  });
  const rootNodeId = getRootNodeId(state);
  const rootDescendants =
    (elementsMap[rootNodeId] && elementsMap[rootNodeId].descendants) || [];
  // const rootChildren =
  //   (elementsMap[rootNodeId] && elementsMap[rootNodeId].children) || [];
  const elementsList = [rootNodeId, ...rootDescendants, ...orphanDescendants];

  let figuresList = [];
  let figuresMap = {};
  let visited = {};
  let sequence = 1;
  let additionalDrawings = [];

  elementsList.forEach((elementId) => {
    const node = elementsMap[elementId];

    const elementVersionId = getPreferredElementVersionId(
      state,
      elementId,
      productId
    );

    if (elementVersionId && !visited[elementVersionId]) {
      if (node.category === 'system') {
        const methodId = getElementVersionMethod(state, elementVersionId);

        if (methodId && !figuresMap[methodId]) {
          figuresMap[methodId] = {
            id: methodId,
            type: 'method',
            sequence,
          };

          figuresList.push({
            id: methodId,
            type: 'method',
            sequence,
          });

          sequence++;
        }
      }

      const drawingIds = getElementVersionDrawings(state, elementVersionId);

      drawingIds.forEach((drawingId, index) => {
        if (index) {
          additionalDrawings.push(drawingId);
        } else {
          if (!figuresMap[drawingId]) {
            figuresMap[drawingId] = {
              id: drawingId,
              type: 'drawing',
              sequence,
            };

            figuresList.push({
              id: drawingId,
              type: 'drawing',
              sequence,
            });
            sequence++;
          }
        }
      });
    }
  });

  additionalDrawings = additionalDrawings.uniq();

  additionalDrawings.forEach((drawingId) => {
    if (!figuresMap[drawingId]) {
      figuresMap[drawingId] = {
        id: drawingId,
        type: 'drawing',
        sequence,
      };

      figuresList.push({
        id: drawingId,
        type: 'drawing',
        sequence,
      });
      sequence++;
    }
  });

  return { figuresList, figuresMap };
};


const _buildMappedFiguresList = (updatedAt, narrativeType, state) => {
  const visited = {};
  const map = {};
  const preferredProductId = getPreferredProductId(state);
  const figuresList = getFiguresList(state);

  figuresList.forEach((figure) => {
    // add the figure to the map
    map[figure.id] = {
      ...figure,
      label: `FIG. ${figure.sequence}`,
      domElementId: `figure-${figure.id}`,
      elementVersionsList: [],
    };

    if (figure.type === 'method') {
      const method = getMethod(state, figure.id);

      // first describe the method's parent element version
      if (!visited[method.elementVersion]) {
        map[figure.id].elementVersionsList.push(method.elementVersion);
        visited[method.elementVersion] = figure.id;
      }

      const elementVersion = getElementVersion(state, method.elementVersion);

      const elementsList =
        (elementVersion && elementVersion.elementsList) || [];

      // the loop through the method's child elements
      elementsList.forEach((elementId) => {
        const element = getElement(state, elementId);

        if (element.elementVersionsList.length) {
          // first, if the preferredElementVersion is childless, describe it here
          const preferredElementVersion = getPreferredElementVersion(
            state,
            elementId,
            preferredProductId
          );

          if (
            preferredElementVersion &&
            preferredElementVersion.elementsList.length === 0
          ) {
            if (!visited[preferredElementVersion.id]) {
              map[figure.id].elementVersionsList.push(
                preferredElementVersion.id
              );
              visited[preferredElementVersion.id] = figure.id;
            }
          }

          // then loop through the remaining elementVersions and if they are
          // childless too, describe them here
          const elementVersionsList =
            (element && element.elementVersionsList) || [];

          elementVersionsList
            .filter(
              (elementVersionId) =>
                preferredElementVersion &&
                elementVersionId !== preferredElementVersion.id
            )
            .forEach((elementVersionId) => {
              if (!visited[elementVersionId]) {
                map[figure.id].elementVersionsList.push(elementVersionId);
                visited[elementVersionId] = figure.id;
              }
            });
        }
      });
    }

    if (figure.type === 'drawing') {
      const drawing = getDrawing(state, figure.id);

      // the loop through the drawing's markers
      drawing.markersList.forEach((markerId) => {
        const marker = getMarker(state, markerId);

        if (marker.element && marker.elementVersion) {
          // describe the elementVersion
          if (!visited[marker.elementVersion]) {
            map[figure.id].elementVersionsList.push(marker.elementVersion);
            visited[marker.elementVersion] = figure.id;
          }
        }
      });
    }
  });

  const mappedFiguresList = figuresList.map((figure) => map[figure.id]);

  return mappedFiguresList;
};

const buildMappedFiguresListSerializer = (args) => {
  return [`${args[0]} ${args[1]}`];
};

export const buildMappedFiguresList = moize.serializeWith(
  buildMappedFiguresListSerializer
)(_buildMappedFiguresList);
