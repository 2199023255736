import {
  METHOD_NODE_COLOR,
  METHOD_NODE_FILL,
  METHOD_NODE_STROKE,
  METHOD_NODE_STROKE_DISCONNECTED,
  PRIMARY_COLOR,
} from '../../../constants/colors';
import { action, computed } from '@ember/object';
import { alias, bool } from '@ember/object/computed';
import {
  getMethodEdge,
  getMethodEdgeSource,
  getMethodEdgeTarget,
} from '../../../selectors/method-edge';
import {
  getPath,
  getPathArrowProperties,
  getPathCenterPoint,
  getPathOffCenterPoint,
} from '../../../utils/graph';

import Component from '@glimmer/component';
import { connect } from 'ember-redux';
import { getEdgeNode } from '../../../utils/method-graph';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import uuid from 'uuid/v4';
import { values } from 'lodash';

const dispatchToActions = {};

const stateToComputed = (state, attrs) => ({
  methodEdge: getMethodEdge(state, attrs.methodEdgeId),
  sourceNode: getMethodEdgeSource(state, attrs.methodEdgeId),
  targetNode: getMethodEdgeTarget(state, attrs.methodEdgeId),
});

class MethodEdge extends Component {
  @service store;
  @service redux;
  @service models;
  @service tracking;
  @service applicationState;
  @service settings;

  constructor(owner, args) {
    super(owner, args);
    this.onScheduleRender = this.args.onScheduleRender;
  }

  @action
  didInsert() {
    this.setup();
    this._updatedAt = this.methodEdge.updatedAt;
    this._isSelected = this.args.isSelected;
    this._isDisconnected = this.args.isDisconnected;
    this._type = this.type;
    this._curve = this.curve;
    this._sourcePosition = this.sourcePosition;
    this._targetPosition = this.targetPosition;
    this._boundsString = this.boundsString;
    this._methodAutoArrangeIndex = this.methodAutoArrangeIndex;
    this._undoIndex = this.undoIndex;
  }

  @action
  willDestroyNode() {
    this.containerNode.off('click');
    this.containerNode.destroy();
    this.onScheduleRender();
  }

  get styleNamespace() {
    return podNames['method-edge'];
  }

  get classNames() {
    let classNames = [this.styleNamespace];
    if (this.args.isSelected) {
      classNames.push('is-selected');
    } else {
      classNames.push('isnt-selected');
    }
    return classNames.join(' ');
  }

  rendered = false;
  arrowRadius = 7;
  stepNumberRadius = 20;
  stepNumberFontSize = 20;
  labelRadius = 15;
  labelFontSize = 20;
  ordinalHeight = 24;
  ordinalFontSize = 18;
  strokeWidth = 2;
  fontFamily = 'Inter';
  fontSize = 18;
  domElementId = `method-node-edge-${uuid()}`;

  @tracked suggestedBendPoints = [];
  @tracked isHovered = false;

  @bool('element.id') isElementNode;
  @bool('stepNumber') hasStepNumber;
  @bool('ordinal') hasOrdinal;
  @alias('methodEdge.type') type;
  @alias('methodEdge.curve') curve;
  @alias('methodEdge.bendPoints') bendPoints;
  @alias('methodEdge.sourcePosition') sourcePosition;
  @alias('methodEdge.targetPosition') targetPosition;
  @alias('applicationState.methodAutoArrangeIndex') methodAutoArrangeIndex;
  @alias('applicationState.undoIndex') undoIndex;

  @computed('sourceNode.id')
  get sourceNodeModel() {
    return (
      this.sourceNode &&
      this.models.findOrCreate(
        this.sourceNode.id,
        'method-node',
        this.sourceNode
      )
    );
  }

  @computed('targetNode.id')
  get targetNodeModel() {
    return (
      this.targetNode &&
      this.models.findOrCreate(
        this.targetNode.id,
        'method-node',
        this.targetNode
      )
    );
  }

  getLine(hasTargetPadding = false) {
    let source = this.sourceNodeModel;
    const sourcePosition = this.sourcePosition;
    const target = this.targetNodeModel;
    const targetPosition = this.targetPosition;
    const bendPoints = this.bendPointsArray;
    const curve = this.curve;
    return getPath(
      source,
      sourcePosition,
      target,
      targetPosition,
      bendPoints,
      curve,
      null,
      hasTargetPadding
    );
  }

  @computed('bendPoints')
  get bendPointsArray() {
    return this.bendPoints ? values(this.bendPoints) : [];
  }

  @computed('type')
  get showLabelNode() {
    return this.type === 'conditional';
  }

  @computed('hasStepNumber', 'args.showStepNumber')
  get showStepNumber() {
    return this.hasStepNumber && this.args.showStepNumber ? true : false;
  }

  @computed('hasOrdinal', 'args.showOrdinal')
  get showOrdinal() {
    return this.hasOrdinal && this.args.showOrdinal ? true : false;
  }

  get strokeColor() {
    let color = METHOD_NODE_STROKE;

    if (this.args.isDisconnected) {
      color = METHOD_NODE_STROKE_DISCONNECTED;
    }

    if (this.args.isSelected) {
      color = PRIMARY_COLOR;
    }
    return color;
  }

  get stepNumberColor() {
    return this.args.isSelected ? PRIMARY_COLOR : METHOD_NODE_COLOR;
  }

  get stepNumberFillColor() {
    return METHOD_NODE_FILL;
  }

  get stepNumberStrokeColor() {
    return this.args.isSelected ? PRIMARY_COLOR : METHOD_NODE_COLOR;
  }

  get labelColor() {
    return this.args.isSelected ? PRIMARY_COLOR : METHOD_NODE_COLOR;
  }

  get labelFillColor() {
    return METHOD_NODE_FILL;
  }

  get labelStrokeColor() {
    return 'transparent';
  }

  @computed('type')
  get labelText() {
    let text = '';
    if (this.type === 'conditional') {
      text = 'if';
    }
    if (this.type === 'alternative') {
      text = 'alt';
    }

    return text;
  }

  @computed('args.{methodId,methodEdgeId}')
  get methodModel() {
    return this.store.peekRecord('method', this.args.methodId);
  }

  @computed('methodModel.ungroupedStepsList.[]', 'args.methodEdgeId')
  get stepNumber() {
    const step =
      this.methodModel &&
      this.methodModel.ungroupedStepsList.find(
        (step) => step.modelId === this.args.methodEdgeId
      );
    return step && step.number;
  }

  // TODO this doesn't work
  @computed('methodModel.ungroupedStepsList.[]', 'args.methodEdgeId')
  get ordinal() {
    const step =
      this.methodModel &&
      this.methodModel.ungroupedStepsList.find(
        (step) => step.modelId === this.args.methodEdgeId
      );
    return step && step.ordinal;
  }

  setup() {
    const edge = {
      id: this.args.methodEdgeId,
      type: this.type,
      path: '',
      centerPoint: { x: 0, y: 0 },
      offCenterPoint: { x: 0, y: 0 },
      arrowX: 0,
      arrowY: 0,
      arrowRotation: 0,
      stepNumber: this.stepNumber,
      ordinal: this.ordinal,
    };

    const {
      containerNode,
      lineNode,
      halfLineNode,
      arrowNode,
      labelNode,
      labelBackgroundNode,
      labelTextNode,
      stepNumberNode,
      stepNumberBackgroundNode,
      stepNumberTextNode,
      ordinalNode,
      ordinalTextNode,
    } = getEdgeNode(edge, {
      showStepNumber: this.showStepNumber,
      showOrdinal: this.showOrdinal,
      blackAndWhiteMode: this.settings.blackAndWhiteMode
    });

    // const pathNode = new Konva.Path({
    //   id: `${this.args.methodEdgeId}-path`,
    //   visible: false,
    //   nodeType: 'methodEdge',
    //   data: this.methodEdge.path,
    //   // data: this.methodEdge.path,
    //   stroke: 'pink',
    //   strokeWidth: this.strokeWidth,
    //   listening: false,
    // });

    // add events
    if (this.args.onClick) {
      containerNode.on('click', () =>
        this.args.onClick(this.args.methodEdgeId, this.args.isSelected)
      );
    }

    this.args.layer.add(containerNode);

    // containerNode.add(pathNode);

    this.containerNode = containerNode;
    this.halfLineNode = halfLineNode;
    this.lineNode = lineNode;
    this.arrowNode = arrowNode;
    this.labelNode = labelNode;
    this.labelBackgroundNode = labelBackgroundNode;
    this.labelTextNode = labelTextNode;
    this.stepNumberNode = stepNumberNode;
    this.stepNumberTextNode = stepNumberTextNode;
    this.stepNumberBackgroundNode = stepNumberBackgroundNode;
    this.ordinalNode = ordinalNode;
    this.ordinalTextNode = ordinalTextNode;
    // this.pathNode = pathNode;

    this.updatePosition();
    this.updateColors();
    this.onScheduleRender();
  }

  updateColors() {
    this.halfLineNode.stroke(this.strokeColor);
    this.arrowNode.fill(this.strokeColor);
    this.stepNumberTextNode.fill(this.stepNumberColor);
    this.labelTextNode.fill(this.labelColor);
    this.stepNumberBackgroundNode.stroke(this.stepNumberStrokeColor);
  }

  updatePosition() {
    if (!this.sourceNode || !this.targetNode) {
      return;
    }
    const line = this.getLine();
    const halfLine = this.getLine(true);
    const centerPoint = getPathCenterPoint(halfLine);
    const offCenterPoint = getPathOffCenterPoint(halfLine);

    this.stepNumberNode.x(
      this.showLabelNode ? offCenterPoint.x : centerPoint.x
    );
    this.stepNumberNode.y(
      this.showLabelNode ? offCenterPoint.y : centerPoint.y
    );
    this.labelNode.x(centerPoint.x);
    this.labelNode.y(centerPoint.y);
    this.lineNode.data(line);
    this.halfLineNode.data(halfLine);
    const { x, y, rotation } = getPathArrowProperties(halfLine);
    this.arrowNode.rotation(rotation);
    this.arrowNode.x(x);
    this.arrowNode.y(y);
  }

  onUpdatedAt() {
    this.updatePosition();
  }

  @action
  onUpdate(
    elem,
    [
      isSelected,
      showStepNumber,
      showOrdinal,
    ]
  ) {
    let detailsChanged = false;

    if (this._isSelected !== isSelected) {
      this._isSelected = isSelected;
      this.updateColors();
      detailsChanged = true;
    }

    if (this._showStepNumber !== showStepNumber) {
      this._showStepNumber = showStepNumber;
      this.stepNumberNode.visible(this.showStepNumber);
      detailsChanged = true;
    }

    if (this._showOrdinal !== showOrdinal) {
      this._showOrdinal = showOrdinal;
      this.ordinalNode.visible(this.showOrdinal);
      detailsChanged = true;
    }

    if (detailsChanged) {
      this.onScheduleRender(this.args.layer);
    }
  }
}

export default connect(stateToComputed, dispatchToActions)(MethodEdge);
