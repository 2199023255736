import Component from '@ember/component';
import { computed } from '@ember/object';
import { connect } from 'ember-redux';
import { getMarker } from '../../../selectors/marker';

const dispatchToActions = {};

const stateToComputed = (state, attrs) => ({
  marker: getMarker(state, attrs.referenceId),
});

const ReferenceItem = Component.extend({
  classNames: ['reference-item'],
  drawingId: null,
  tagName: 'li',
  // eslint-disable-next-line ember/require-computed-macros
  figureId: computed('marker.drawing', function () {
    return this.marker && this.marker.drawing;
  }),
  figureType: 'drawing',
});

export default connect(stateToComputed, dispatchToActions)(ReferenceItem);
