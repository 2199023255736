import { action, computed } from '@ember/object';
import {
  deselectElementVersion,
  selectElementVersion,
} from '../../../actions/invention-ui';
import {
  getCollapsedDescendantsList,
  getCollapsedNodesList,
  getElementColumnWidths,
  getElementColumnsList,
  getInventionUi,
  getSelectedChildren,
  getSelectedDescendants,
  getSelectedElementVersions,
  getSelectedElements,
  getVisibleElementColumnsList,
} from '../../../selectors/invention-ui';
import {
  getElementVersionsMap,
  getElementsMap,
  getGraphIndex,
  getOrphanNodesList,
  getRootNodeId,
} from '../../../selectors/graph';
import {
  getPreferredElementVersionId,
  getProduct,
} from '../../../selectors/product';

import Component from '@glimmer/component';
import { connect } from 'ember-redux';
import { getElement } from '../../../selectors/element';
import { getElementVersion } from '../../../selectors/element-version';
import { getPrimaryInstancesList } from '../../../selectors/component';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import uuid from 'uuid/v4';

const dispatchToActions = {};

const stateToComputed = (state, attrs) => ({
  inventionUi: getInventionUi(state),
  graphIndex: getGraphIndex(state),
  elementsTreeMap: getElementsMap(state, attrs.productId),
  solutionsTreeMap: getElementVersionsMap(state, attrs.productId),
  rootNodeId: getRootNodeId(state),
  orphanNodesList: getOrphanNodesList(state),
  collapsedNodesList: getCollapsedNodesList(state, attrs.productId),
  collapsedDescendantsList: getCollapsedDescendantsList(state, attrs.productId),
  selectedElements: getSelectedElements(state),
  selectedElementVersions: getSelectedElementVersions(state),
  activeProduct: getProduct(state, attrs.productId),
  primaryInstancesList: getPrimaryInstancesList(state),
  elementColumnsList: getElementColumnsList(state),
  visibleElementColumnsList: getVisibleElementColumnsList(state),
  elementColumnWidths: getElementColumnWidths(state),
});

class ProductRequirementsContextTwo extends Component {
  @service models;
  @service data;
  @service applicationState;
  @service tracking;
  @service redux;

  @tracked switched = false;
  @tracked searchQuery;
  @tracked searchResults;
  @tracked isMousedown = false;
  @tracked isDragging = false;
  @tracked draggedId;
  @tracked draggedDescendants = [];
  @tracked draggedType;
  @tracked draggedOverId;
  @tracked draggedOverArea;
  @tracked mouseCoordinatesX = 0;
  @tracked mouseCoordinatesY = 0;

  dragDistanceThreshold = 15;

  domElementId = `element-table-${uuid()}`;

  get styleNamespace() {
    return podNames['element-table'];
  }

  get classNames() {
    let classNames = [this.styleNamespace, 'element-table'];
    return classNames.join(' ');
  }

  @action
  didInsert() {
    this._handleMouseup = this.handleMouseup.bind(this);
    window.addEventListener('mouseup', this._handleMouseup);

    this._handleMousemove = this.handleMousemove.bind(this);
    window.addEventListener('mousemove', this._handleMousemove);

    const domElement = document.getElementById(this.domElementId);
    if (domElement) {
      this._handleMouseleave = this.handleMouseleave.bind(this);
      domElement.addEventListener('mouseleave', this._handleMouseleave);
    }
  }

  @action
  willDestroyNode() {
    window.removeEventListener('mouseup', this._handleMouseup);
    const domElement = document.getElementById(this.domElementId);
    if (domElement) {
      domElement.removeEventListener('mouseleave', this._handleMouseleave);
    }
  }

  startDrag() {
    if (this.draggedId && this.draggedType) {
      // set application state dragging
      const node = this.nodesMap[this.draggedId];
      this.isDragging = true;
      this.draggedDescendants = (node && node.descendants) || [];
    }
  }

  resetDrag() {
    this.isDragging = false;
    this.draggedId = null;
    this.draggedDescendants = [];
    this.draggedType = null;
    this.draggedOverId = null;
    this.draggedOverArea = null;
    this.isMousedown = false;
    this.mouseCoordinatesX = 0;
    this.mouseCoordinatesY = 0;
  }

  @action
  onMousedown(event, id, type) {
    this.data.selectElement(id);
    this.draggedId = id;
    this.draggedType = type;
    this.isMousedown = true;
    this.mouseCoordinatesX = event.clientX || 0;
    this.mouseCoordinatesY = event.clientY || 0;
  }

  handleMousemove(event) {
    // this.dragDistance =
    // const dragDistanceThresholdReached = this.dragDistance > this.dragDistanceThreshold;

    // const dx = d3Event.dx;
    // const dy = d3Event.dy;
    // const distance = Math.sqrt(dx * dx + dy * dy);

    // this.incrementProperty('dragDistance', distance);

    if (this.isMousedown) {
      const dx = event.clientX - this.mouseCoordinatesX;
      const dy = event.clientY - this.mouseCoordinatesY;
      const distance = Math.sqrt(dx * dx + dy * dy);
      const dragDistanceThresholdReached =
        distance > this.dragDistanceThreshold;
      if (dragDistanceThresholdReached) {
        this.startDrag();
      }
    }
  }

  handleMouseleave() {
    this.draggedOverId = null;
    this.draggedOverArea = null;
  }

  handleMouseup() {
    if (this.isDragging) {
      this.handleDrop();
    }
    this.resetDrag();
  }

  handleDrop() {
    const draggedNode = this.draggedId && this.nodesMap[this.draggedId];
    const droppedNode = this.draggedOverId && this.nodesMap[this.draggedOverId];
    const droppedNodeArea = this.draggedOverArea;
    if (draggedNode && droppedNode && droppedNodeArea) {
      const canDrop = this.canDrop(draggedNode, droppedNode, droppedNodeArea);
      if (canDrop) {
        this.args.onDropNode(
          draggedNode.id,
          draggedNode.type,
          droppedNode.id,
          droppedNode.type,
          droppedNodeArea
        );
      } 
    }
  }
  canDrop(draggedNode, droppedNode, droppedNodeArea) {
    const isSameNode = draggedNode.id === droppedNode.id;
    const isSameCategory = draggedNode.category === droppedNode.category;
    const isSameType = draggedNode.type === droppedNode.type;
    const droppedNodeIsDescendant =
      draggedNode.descendants &&
      draggedNode.descendants.includes(droppedNode.id);
    const isDraggedParent = draggedNode.parent === droppedNode.id;
    const droppedNodeIsCollapsed = !this.collapsedDescendantsList.includes(
      droppedNode.id
    );
    const hasChildren = droppedNode.children && droppedNode.children.length;
    const hasVisibleChildren = hasChildren && !droppedNodeIsCollapsed;

    let canDrop =
      isSameType && isSameCategory && !droppedNodeIsDescendant && !isSameNode;

    if (canDrop && droppedNodeArea) {
      switch (droppedNodeArea) {
        case 'top':
          canDrop = true;
          break;
        case 'middle':
          canDrop = !isDraggedParent;
          break;
        case 'bottom':
          canDrop = !hasVisibleChildren && !isDraggedParent;
          break;
      }
    }

    return canDrop;
  }

  @computed('activeProduct.name')
  get activeProductLabel() {
    return this.activeProduct ? this.activeProduct.name : 'All Products';
  }

  @computed('applicationState.shiftKeyDown')
  get shiftKeyDown() {
    return this.applicationState.shiftKeyDown;
  }

  @computed('shiftKeyDown')
  get multiSelectMode() {
    return this.shiftKeyDown;
  }

  @computed('inventionUi.activeContextTab')
  get activeContextTab() {
    return this.inventionUi.activeContextTab;
  }

  @computed('inventionUi.reviewMode')
  get reviewMode() {
    return this.inventionUi.reviewMode;
  }

  // eslint-disable-next-line ember/require-computed-property-dependencies
  @computed('selectedElements.[]', 'selectedElementVersions.[]')
  get selectedDescendants() {
    const state = this.redux.getState();
    return getSelectedDescendants(state);
  }

  @computed('selectedElements.[]', 'selectedElementVersions.[]')
  get selectedChildren() {
    const state = this.redux.getState();
    return getSelectedChildren(state);
  }

  // eslint-disable-next-line ember/require-computed-property-dependencies
  @computed('graphIndex', 'args.productId')
  get nodesMap() {
    return this.args.productId ? this.elementsTreeMap : this.solutionsTreeMap;
  }

  // eslint-disable-next-line ember/require-computed-property-dependencies
  @computed('nodesMap', 'collapsedDescendantsList.[]')
  get nodesFromInvention() {
    const state = this.redux.getState();

    const rootNode = this.nodesMap[this.rootNodeId];

    const allNodes = [this.rootNodeId, ...rootNode.descendants];

    const visibleDescendants = allNodes.filter(
      (nodeId) => !this.collapsedDescendantsList.includes(nodeId)
    );
    const mappedNodes = visibleDescendants.map((nodeId) => {
      const node = this.nodesMap[nodeId];
      // console.log('node', node)
      const type = node.type;
      const model = this.getModel(state, nodeId, type);
      let instanceOfModel;
      let preferredElementVersionModel;
      switch (type) {
        case 'element':
          if (model.instanceOf) {
            instanceOfModel = this.getModel(state, model.instanceOf, type);
          }

          if (this.args.productId) {
            const preferredElementVersionId = getPreferredElementVersionId(
              state,
              nodeId,
              this.args.productId
            );

            preferredElementVersionModel = this.getModel(
              state,
              preferredElementVersionId,
              'element-version'
            );
          }
          break;
      }
      return {
        id: node.id,
        depth: node.depth,
        type,
        model,
        category: model.category,
        instanceOfModel,
        preferredElementVersionModel,
        parent: node.parent,
        children: node.children,
      };
    });

    const depthFilteredNodes = mappedNodes.filter((node) => node.depth > 1);
    return depthFilteredNodes;
  }

  // eslint-disable-next-line ember/require-computed-property-dependencies
  @computed('nodesMap', 'collapsedDescendantsList.[]')
  get environmentNodes() {
    const state = this.redux.getState();
    const mappedNodes = [];

    this.orphanNodesList.forEach((orphanNodeId) => {
      const orphanNode = this.nodesMap[orphanNodeId];
      let descendants = orphanNode && orphanNode.descendants;

      const allNodes = [orphanNodeId, ...descendants];

      const visibleDescendants = allNodes.filter(
        (nodeId) => !this.collapsedDescendantsList.includes(nodeId)
      );

      visibleDescendants.forEach((nodeId) => {
        const node = this.nodesMap[nodeId];
        const type = node.type;
        const model = this.getModel(state, nodeId, type);
        let instanceOfModel;
        let preferredElementVersionModel;
        switch (type) {
          case 'element':
            if (model.instanceOf) {
              instanceOfModel = this.getModel(state, model.instanceOf, type);
            }

            if (this.args.productId) {
              const preferredElementVersionId = getPreferredElementVersionId(
                state,
                nodeId,
                this.args.productId
              );

              preferredElementVersionModel = this.getModel(
                state,
                preferredElementVersionId,
                'element-version'
              );
            }
            break;
        }
        mappedNodes.push({
          id: node.id,
          depth: node.depth,
          type,
          category: model.category,
          model,
          instanceOfModel,
          preferredElementVersionModel,
          parent: node.parent,
          children: node.children,
        });
      });
    });

    return mappedNodes;
  }

  // eslint-disable-next-line ember/require-computed-property-dependencies
  @computed('searchResults.[]', 'nodesMap')
  get mappedSearchResults() {
    const state = this.redux.getState();
    const mappedNodes = this.searchResults
      .filter((node) => {
        const model = this.getModel(state, node.id, node.type);
        return model ? true : false;
      })
      .map((node) => {
        const type = node.type;
        const model = this.getModel(state, node.id, type);
        let instanceOfModel;
        if (model.instanceOf) {
          instanceOfModel = this.getModel(state, model.instanceOf, type);
        }
        return {
          id: node.id,
          type,
          model,
          category: model.category,
          instanceOfModel,
        };
      });
    return mappedNodes;
  }

  getModel(state, id, type) {
    let model;
    switch (type) {
      case 'element': {
        // let element = getElement(state, id);
        model = getElement(state, id);
        // model =
        //   this.models.find(element.id) ||
        //   this.models.findOrCreate(element.id, 'element', element);
        break;
      }
      case 'element-version':
        model = getElementVersion(state, id);
        // model =
        //   this.models.find(id) ||
        //   this.models.findOrCreate(
        //     id,
        //     'element-version',
        //     getElementVersion(state, id)
        //   );
        break;
    }
    return model;
  }

  @action
  createElement(category) {
    this.args.onCreateParentlessElement(null, null, category);
    this.tracking.trackEvent('explorer_created_system');
  }

  @action
  zoomToNode(nodeId) {
    if (this.args.onZoomToNode) {
      this.args.onZoomToNode(nodeId);
      this.tracking.trackEvent('explorer_zoomed_to_node');
    }
  }

  @action
  collapseNode(nodeId) {
    if (this.args.onCollapseNode) {
      this.args.onCollapseNode(nodeId);
    }
  }

  @action
  selectElementVersion(elementVersionId, isSelected) {
    let dispatch;
    if (isSelected) {
      if (this.multiSelectMode) {
        dispatch = deselectElementVersion(elementVersionId);
      }
    } else {
      dispatch = selectElementVersion(elementVersionId, this.multiSelectMode);
      this.tracking.trackEvent('explorer_selected_system');
    }

    if (dispatch) {
      this.redux.store.dispatch(dispatch);
    }
  }

  @action
  onSearch(searchQuery, searchResults) {
    this.searchQuery = searchQuery;
    this.searchResults = searchResults;
  }

  @action
  onDragover(event, type, id, area) {
    this.draggedOverId = id;
    this.draggedOverArea = area;
  }

  @action
  onToggleCollapsedProducts() {}
}

export default connect(
  stateToComputed,
  dispatchToActions
)(ProductRequirementsContextTwo);
