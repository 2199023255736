import { UPDATE_PATENT_SPECIFICATION } from '../actions/actionTypes';
import { addFromSchema } from '../utils/schema';

const initialState = addFromSchema('patent-specification');

const patentSpecificationReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case UPDATE_PATENT_SPECIFICATION: {
      const { attributes } = payload;
      return {
        ...state,
        ...attributes,
      };
    }

    /* istanbul ignore next */
    default:
      return state;
  }
};

export default patentSpecificationReducer;
