import Component from '@glimmer/component';
import FeatureTypes from '../../../constants/types/features';
import { computed } from '@ember/object';
import { connect } from 'ember-redux';
import { getFeature } from '../../../selectors/feature';
import { hasValue } from '../../../utils/realization';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';

const dispatchToActions = {};

const stateToComputed = null;

class PreviewFeatureItem extends Component {
  @service redux;

  showHeader = true;
  
  get styleNamespace() {
    return podNames['preview-feature-item'];
  }

  @computed('styleNamespace', 'hasValue')
  get classNames() {
    let classNames = [this.styleNamespace, 'preview-feature-item'];
    if (this.hasValue) classNames.push('has-value');
    return classNames.join(' ');
  }

  @computed('args.{featuresList,featureId}')
  get featuresList() {
    return this.args.featureId
      ? [this.args.featureId]
      : this.args.featuresList || [];
  }

  @computed('featuresList.[]')
  get feature() {
    const state = this.redux.getState();
    return this.featuresList[0] && getFeature(state, this.featuresList[0]);
  }

  @computed('feature.value')
  get hasValue() {
    return this.feature && hasValue(this.feature.value);
  }

  @computed('feature.type')
  get type() {
    return this.feature && FeatureTypes[this.feature.type];
  }
}

export default connect(stateToComputed, dispatchToActions)(PreviewFeatureItem);
