export default {
  attributes: {
    rootNode: {
      type: 'object',
      defaultValue: {}
    },
    orphanNodesList: {
      type: 'array',
      defaultValue: []
    },
    disconnectedNodesList: {
      type: 'array',
      defaultValue: []
    },
    collapsedNodesList: {
      type: 'array',
      defaultValue: []
    },
    collapsedDescendantsList: {
      type: 'array',
      defaultValue: []
    },
    methodsList: {
      type: 'array',
      defaultValue: []
    },
    methodsMap: {
      type: 'object',
      defaultValue: {}
    },
    elementsMap: {
      type: 'object',
      defaultValue: {}
    },
    elementVersionsMap: {
      type: 'object',
      defaultValue: {}
    },
    products: {
      type: 'object',
      defaultValue: {}
    },
    index: {
      type: 'number',
      defaultValue: 0
    },
  }
};

