import Component from '@glimmer/component';
import { action } from '@ember/object';
import { connect } from 'ember-redux';
import { getTerm } from '../../../selectors/term';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';

const stateToComputed = (state, attrs) => ({
  term: getTerm(state, attrs.termId),
});

const dispatchToActions = {};

class DrawingContextMenu extends Component {
  @service contextMenu;

  get styleNamespace() {
    return podNames['term-context-menu'];
  }

  get classNames() {
    let classNames = [this.styleNamespace, 'drawing-context-menu'];
    return classNames.join(' ');
  }

  @action
  onRemoveDrawing() {
    if (this.args.onRemoveDrawing) {
      this.args.onRemoveDrawing(this.args.drawingId);
    }
    this.contextMenu.close();
  }

  @action
  onDuplicateDrawing(withMarkers = false) {
    if (this.args.onDuplicateDrawing) {
      this.args.onDuplicateDrawing(this.args.drawingId, withMarkers);
    }
    this.contextMenu.close();
  }

  
}

export default connect(stateToComputed, dispatchToActions)(DrawingContextMenu);
