import { action, computed } from '@ember/object';

import Component from '@glimmer/component';
import ENV from '../../../config/environment';
import { batchGroupBy } from '../../../utils/redux';
import { connect } from 'ember-redux';
import { debounce } from '@ember/runloop';
import { getElement } from '../../../selectors/element';
import { getNameExists } from '../../../selectors/invention';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { updateElement } from '../../../actions/element';

const stateToComputed = (state, attrs) => ({
  element: getElement(state, attrs.elementId),
});

const dispatchToActions = {};

class ElementNameEditor extends Component {
  @service models;
  @service applicationState;
  @service tracking;

  @tracked switched = false;
  @tracked isUnique = true;
  @tracked uniqueErrorName = '';
  @tracked _name = '';

  _elementId = '';

  get styleNamespace() {
    return podNames['element-name-editor'];
  }

  get classNames() {
    let classNames = [this.styleNamespace];
    return classNames.join(' ');
  }

  @action
  onInsert() {
    this._name = this.name;
    this._elementId = this.args.elementId;
  }

  @action
  onUndo() {
    this.switched = !this.switched;
    this.updateName();
  }

  @action
  onElementChange(elem, [elementId]) {
    if (elementId !== this._elementId) {
      this._elementId = elementId;
      this.switched = !this.switched;
      this.updateName();
    }
  }

  updateName() {
    const state = this.redux.getState();
    const element = getElement(state, this.args.elementId);
    this._name = element.name;
  }

  debounceUpdate(args) {
    const name = args.name;
    batchGroupBy.start();
    this.redux.store.dispatch(updateElement(this.args.elementId, { name }));
    batchGroupBy.end();
    this.tracking.trackEvent('system_context_updated_name');
  }

  getIsUnique(name) {
    const state = this.redux.store.getState();
    const elementNameExists = getNameExists(state, this.args.elementId, name);
    return !elementNameExists;
  }

  @computed('element', 'args.elementId')
  get elementModel() {
    return this.models.findOrCreate(
      this.args.elementId,
      'element',
      this.element
    );
  }

  @computed('isUnique')
  get isntUnique() {
    return !this.isUnique;
  }

  @computed('element.name')
  get name() {
    return this.element && this.element.name;
  }

  @action
  onDebounceEdit(name) {
    if (!name) {
      // do nothing
    } else if (!this.getIsUnique(name)) {
      this.isUnique = false;
      this.uniqueErrorName = name;
    }else {
      this.isUnique = true;
      this.elementModel.set('name', name);
      debounce(this, this.debounceUpdate, { name }, ENV.DEBOUNCE_MS);
    }
  }
}

export default connect(stateToComputed, dispatchToActions)(ElementNameEditor);
