import { all, task } from 'ember-concurrency';
import { keyBy, sortBy } from 'lodash';

import JSZip from 'jszip';
import Service from '@ember/service';
import { addFromSchema } from '../utils/schema';
import { inject as service } from '@ember/service';
import uuid from 'uuid/v4';

const updateMentions = (value) => {
  const container = document.createElement('div');
  container.innerHTML = value;
  const spansLength = Number(container.getElementsByTagName('span').length);

  for (let i = 0; i < spansLength; i++) {
    const span = container.getElementsByTagName('span')[0];
    const elementId = span.dataset.modelId;
    const elementName = span.innerHTML;

    if (elementId) {
      const mention = document.createElement('mention');
      mention.setAttribute('class', 'mention');
      mention.innerHTML = elementName;
      mention.dataset.type = 'element';
      mention.dataset.id = elementId;
      mention.dataset.name = elementName;
      span.parentNode.replaceChild(mention, span);
    }
  }

  return container.innerHTML;
};

const walkNode = (node, legacyInvention, nodes = []) => {
  const allEdges = legacyInvention.data.partsEdge;
  const outgoingEdges = allEdges.filter((edge) => edge.source === node.id);
  const children = outgoingEdges.map((edge) => edge.target).uniq();
  nodes.push({
    ...node,
    children,
  });
  children.forEach((childNodeId) => {
    const child = legacyInvention.data.partsNode.find(
      (partsNode) => partsNode.id === childNodeId
    );
    child.parent = node.id;
    walkNode(child, legacyInvention, nodes);
  });
  return nodes;
};

const getElementFromNode = (node, _elem) => {

  const element = {
    id: _elem.id,
    name: _elem._value  || '',
    outcome: _elem.methodStep ? updateMentions(_elem.methodStep) : '',
    _requirements: _elem.requirements,
    x: node.x,
    y: node.y,
    elementVersion: _elem.parent,
    elementVersionsList: _elem.children,
    createdAt: new Date(),
  };

  return addFromSchema('element', element);
};

const getElementVersionFromNode = (node, _elem) => {
  const elementVersion = {
    id: _elem.id,
    name: _elem._value || '',
    inventing: _elem.isInventing || false,
    _limitations: _elem.limitations || [],
    x: node.x,
    y: node.y,
    element: _elem.parent,
    elementsList: _elem.children,
    createdAt: new Date(),
  };

  return addFromSchema('element-version', elementVersion);
};

const getNodesAsElements = (nodes, legacyInvention, rootElementVersion) => {
  return nodes.map((node) => {
    let _elem = legacyInvention.data.element.find(
      (element) => element.id === node.element || element.id === node.id
    );
    if (!_elem && node.isTreeRoot) {
      _elem = rootElementVersion;
    }
    const childElements = node.children
      .map((childId) => {
        const partsNode = legacyInvention.data.partsNode.find(
          (partsNode) => partsNode.id === childId
        );
        return partsNode.element;
      })
      .uniq();
    const parentElement =
      node.parent &&
      legacyInvention.data.partsNode.find(
        (partsNode) => partsNode.id === node.parent
      ).element;
    _elem.children = childElements;
    _elem.parent = parentElement;
    let nodesAsElement;
    
    switch (_elem.typeId) {
      case 'part':
        nodesAsElement = getElementFromNode(node, _elem, legacyInvention);
        break;
      case 'invention':
        nodesAsElement = getElementVersionFromNode(
          node,
          _elem,
          legacyInvention
        );
        break;
      case 'embodiment':
        nodesAsElement = getElementVersionFromNode(
          node,
          _elem,
          legacyInvention
        );
        break;
    }
    return nodesAsElement;
  });
};

const parseElements = (_elements, invention) => {
  let updatedInvention = {
    ...invention,
  };

  let requirements = {};
  let requirementsList = [];
  let elementsList = [];
  let preferredElementVersionsList = [];

  const elements = _elements.map((element) => {
    elementsList.push(element.id);
    const preferredElementVersionId = element.elementVersionsList[0];
    if (preferredElementVersionId) {
      preferredElementVersionsList.push(preferredElementVersionId);
    }

    if (element._requirements && element._requirements.length) {
      const elementRequirementsList = [];
      element._requirements.forEach((_requirement) => {
        requirements[_requirement.id] = addFromSchema('requirement', {
          id: _requirement.id,
          name: updateMentions(_requirement.name),
          element: element.id,
          createdAt: new Date(),
        });

        requirementsList.push(_requirement.id);
        elementRequirementsList.push(_requirement.id);
      });

      delete element._requirements;

      return {
        ...element,
        requirementsList: elementRequirementsList,
      };
    } else {
      return element;
    }
  });

  updatedInvention = {
    ...updatedInvention,
    requirementsList,
    elementsList,
    requirements,
    preferredElementVersionsList,
    elements: keyBy(elements, 'id'),
  };

  return updatedInvention;
};

const getFeatureType = (typeId) => {
  // const otherTypeIds = ['cyo', 'configured', 'adapted', 'analog', 'dimensions', 'measurements'];
  const sameTypeIds = [
    'definition',
    'general',
    'absent',
    'position',
    'attachment',
    'interaction',
    'material',
    'shape',
  ];

  if (sameTypeIds.includes(typeId)) {
    return typeId;
  }

  if (typeId === 'configured' || typeId === 'adapted') {
    return 'function';
  }

  if (typeId === 'cyo') {
    return 'general';
  }

  if (typeId === 'analog') {
    return 'analogs';
  }

  if (typeId === 'dimensions' || typeId === 'measurements') {
    return 'measurement';
  }

  return 'general';
};

const parseElementVersions = (_elementVersions, invention, legacyInvention) => {
  let updatedInvention = {
    ...invention,
  };

  let features = {};
  let featuresList = [];
  let elementVersionsList = [];

  const elementVersions = _elementVersions.map((elementVersion) => {
    elementVersionsList.push(elementVersion.id);

    if (!elementVersion.name) {
      elementVersion.name = 'Solution 1';
    }

    if (elementVersion._limitations && elementVersion._limitations.length) {
      const elementFeaturesList = [];
      elementVersion._limitations.forEach((limitationId) => {
        const _limitation = legacyInvention.data.limitation.find(
          (limitation) => limitation.id === limitationId
        );
        const typeId = _limitation.typeId;
        _limitation.values.forEach((limitationValueId) => {
          const limitationValue = legacyInvention.data.limitationValue.find(
            (limitationValue) => limitationValue.id === limitationValueId
          );

          if (typeId !== 'comprises') {
            let feature = {
              id: limitationValue.id,
              type: getFeatureType(typeId),
              element: elementVersion.element,
              elementVersion: elementVersion.id,
              value: updateMentions(limitationValue._value),
              novel: limitationValue.innovation ? true : false,
              noveltyAmount: limitationValue.innovation
                ? limitationValue.innovation
                : 1,
              noveltyDescription: updateMentions(
                limitationValue.innovationExplanation
              ),
              enablement: limitationValue.designAround ? true : false,
              enablementAmount: limitationValue.designAround
                ? limitationValue.designAround
                : 1,
              enablementDescription: updateMentions(
                limitationValue.designAroundExplanation
              ),
              createdAt: new Date(),
            };

            feature = addFromSchema('feature', feature);
            featuresList.push(feature.id);
            features[feature.id] = feature;
            elementFeaturesList.push(feature.id);
          }
        });
      });

      delete elementVersion._limitations;

      return {
        ...elementVersion,
        featuresList: elementFeaturesList,
      };
    } else {
      return elementVersion;
    }
  });

  updatedInvention = {
    ...updatedInvention,
    featuresList,
    elementVersionsList,
    features,
    elementVersions: keyBy(elementVersions, 'id'),
  };

  return updatedInvention;
};

const getBaseInvention = () => {
  const createdAt = new Date();
  let invention = addFromSchema('invention', {});
  const noteId = uuid();
  const patentSpecificationId = uuid();

  const patentSpecification = {
    id: patentSpecificationId,
    createdAt,
  };

  const note = {
    id: noteId,
    createdAt,
  };

  invention = {
    ...invention,
    patentSpecification: patentSpecification.id,
    notes: {
      ...invention.notes,
      [note.id]: addFromSchema('note', note),
    },
    patentSpecifications: {
      ...invention.patentSpecifications,
      [patentSpecification.id]: addFromSchema(
        'patent-specification',
        patentSpecification
      ),
    },
    notesList: [note.id],
    imagesList: [],
    images: {},
  };

  return invention;
};

const getInventionElement = (
  inventionId,
  inventionName,
  rootElementVersionId
) => {
  const id = uuid();

  return addFromSchema('element', {
    id,
    name: inventionName,
    type: 'element',
    elementVersionsList: [rootElementVersionId],
    createdAt: new Date(),
    x: 0,
    y: 0,
    parent: inventionId,
  });
};

const addComprisesFeature = (
  invention,
  sourceElementVersionId,
  targetElementId
) => {
  const featureId = uuid();

  const sourceElementVersion =
    invention.elementVersions[sourceElementVersionId];

  sourceElementVersion.featuresList = [
    ...sourceElementVersion.featuresList,
    featureId,
  ];

  const comprisesFeatureAttributes = {
    type: 'comprises',
    value: targetElementId,
    element: sourceElementVersion.element,
    elementVersion: sourceElementVersion.id,
  };

  invention.featuresList = [...invention.featuresList, featureId];
  invention.features = {
    ...invention.features,
    [featureId]: {
      id: featureId,
      ...comprisesFeatureAttributes,
    },
  };

  return {
    ...invention,
  };
};

const addComprisesFeatures = (invention) => {
  invention.elementVersionsList.forEach((elementVersionId) => {
    const elementVersion = invention.elementVersions[elementVersionId];
    elementVersion.elementsList.forEach((elementId) => {
      invention = addComprisesFeature(invention, elementVersionId, elementId);
    });
  });
  return invention;
};

const addDrawings = (invention, legacyInvention, assetMap) => {
  let updatedInvention = {
    ...invention,
  };

  const legacyDrawings = legacyInvention.data.invention[0].figures.map(
    (figureId) => {
      return legacyInvention.data.figure.find(
        (figure) => figure.id === figureId
      );
    }
  );
  const sortedLegacyDrawings = sortBy(legacyDrawings, 'sequence');

  const figuresList = sortedLegacyDrawings.map((drawing) => {
    return {
      id: drawing.id,
      type: 'drawing',
    };
  });

  const drawingsList = sortedLegacyDrawings.map((drawing) => {
    return drawing.id;
  });

  const drawingsArray = sortedLegacyDrawings.map((legacyDrawing) => {
    const createdAt = new Date();
    const drawing = {
      id: legacyDrawing.id,
      description: updateMentions(legacyDrawing.description),
      viewAngle: updateMentions(legacyDrawing.viewAngle),
      orientation: legacyDrawing.orientation,
      x: legacyDrawing.x,
      y: legacyDrawing.y,
      k: legacyDrawing.k,
      createdAt,
      updatedAt: createdAt,
    };
    return addFromSchema('drawing', drawing);
  });

  const drawings = keyBy(drawingsArray, 'id');

  updatedInvention = {
    ...updatedInvention,
    drawings,
    drawingsList,
    figuresList,
    markersList: [],
  };

  sortedLegacyDrawings.forEach((legacyDrawing) => {
    const markersList = legacyDrawing.markers;
    updatedInvention = parseMarkers(
      updatedInvention,
      legacyInvention,
      legacyDrawing.id,
      markersList
    );
    const imagesList = legacyDrawing.images;
    updatedInvention = parseImages(
      updatedInvention,
      legacyInvention,
      legacyDrawing.id,
      imagesList,
      assetMap
    );
  });

  return updatedInvention;
};

const parseMarkers = (invention, legacyInvention, drawingId, markersList) => {
  markersList.forEach((markerId) => {
    const legacyMarker = legacyInvention.data.marker.find(
      (_marker) => _marker.id === markerId
    );
    const legacyMarkerElementisElement = invention.elements[legacyMarker.element] ? true : false;
    const legacyMarkerElementisElementVersion = invention.elementVersions[legacyMarker.element] ? true : false;
    // const elementVersionId = legacyMarker.element;
    // const element = invention.elementVersions[elementVersionId]
    //   ? invention.elementVersions[elementVersionId].element
    //   : null;

    let elementId;
    let elementVersionId;

    if (legacyMarkerElementisElement) {
      const element = invention.elements[legacyMarker.element];
      elementVersionId = element.elementVersionsList[0] || null;
      elementId = element.id;
    }

    if (legacyMarkerElementisElementVersion) {
      const elementVersion = invention.elementVersions[legacyMarker.element];
      elementVersionId = elementVersion.id;
      elementId = elementVersion.element || null;
    }

    const attributes = {
      id: markerId,
      element: elementId,
      drawing: drawingId,
      elementVersion: elementVersionId,
      curve: legacyMarker.curve,
      pointStyle: legacyMarker.pointStyle,
      hasLeadLine: legacyMarker.hasLeadLine,
      startX: legacyMarker.startPointX,
      startY: legacyMarker.startPointY,
      midX: legacyMarker.midPointX,
      midY: legacyMarker.midPointY,
      endX: legacyMarker.endPointX,
      endY: legacyMarker.endPointY,
      labelOrientation: legacyMarker.autoOrientLabel
        ? 'auto'
        : legacyMarker.labelOrientation,
      createdAt: new Date(),
    };

    const marker = addFromSchema('marker', attributes);

    invention.markersList = [...invention.markersList, marker.id];
    
    invention.markers = {
      ...invention.markers,
      [marker.id]: marker,
    };

    invention.drawings[drawingId] = {
      ...invention.drawings[drawingId],
      markersList: [...invention.drawings[drawingId].markersList, marker.id],
    };

    if (elementVersionId && invention.elementVersions[elementVersionId]) {
      const referencesList = invention.elementVersions[elementVersionId].referencesList || [];
      invention.elementVersions[elementVersionId] = {
        ...invention.elementVersions[elementVersionId],
        referencesList: [
          ...referencesList,
          {
            id: marker.id,
            type: 'marker',
          },
        ],
      };
    }
  });

  

  return {
    ...invention,
  };
};

const parseImages = (
  invention,
  legacyInvention,
  drawingId,
  imagesList,
  assetMap
) => {
  imagesList.forEach((imageId) => {
    const legacyImage = legacyInvention.data.image.find(
      (_image) => _image.id === imageId
    );

    const assetId = assetMap[imageId].assetId;

    const attributes = {
      id: imageId,
      drawing: drawingId,
      name: legacyImage.name,
      x: legacyImage.x,
      y: legacyImage.y,
      fileWidth: legacyImage.fileWidth,
      fileHeight: legacyImage.fileHeight,
      width: legacyImage.width,
      height: legacyImage.height,
      asset: assetId,
      createdAt: new Date(),
    };

    const image = addFromSchema('image', attributes);

    invention.imagesList = [...invention.imagesList, image.id];
    invention.images = {
      ...invention.images,
      [image.id]: image,
    };
    invention.drawings[drawingId] = {
      ...invention.drawings[drawingId],
      imagesList: [...invention.drawings[drawingId].imagesList, image.id],
    };
  });

  return {
    ...invention,
  };
};

export default Service.extend({
  assets: service(),
  redux: service(),
  usersDb: service(),
  worker: service(),
  invention: null,
  schemaVersion: 7,

  parseGraph(legacyInvention, assetMap) {
    let baseInvention = getBaseInvention();
    const rootId = legacyInvention.data.partsTree[0].rootNode;

    const rootNode = legacyInvention.data.partsNode.find(
      (partsNode) => partsNode.id === rootId
    );

    let rootElementVersion = legacyInvention.data.element.find(
      (elem) => elem.id === rootNode.element
    );

    if (!rootElementVersion) {
      rootElementVersion = {
        id: uuid(),
        typeId: 'invention',
        name: legacyInvention.data.invention[0].name || '',
        _value: legacyInvention.data.invention[0].name || '',
      }
    }


    const inventionId = uuid();
    // add id, name, root element, patentSpecification attrs
    let invention = {
      ...baseInvention,
      id: inventionId,
      name: legacyInvention.data.invention[0].name,
      // element: inventionElement.id,
      patentSpecificationsList: [baseInvention.patentSpecification],
      patentSpecifications: {
        ...baseInvention.patentSpecifications,
        [baseInvention.patentSpecification]: {
          ...baseInvention.patentSpecifications[
            baseInvention.patentSpecification
          ],
          summary: legacyInvention.data.invention[0].summary,
          abstract: legacyInvention.data.invention[0].abstract,
          background: legacyInvention.data.invention[0].background,
        },
      },
    };

    let nodesWithChildren = walkNode(rootNode, legacyInvention);
    const orphanNodeIds = legacyInvention.data.partsTree[0].nodes.filter(
      (nodeId) => {
        return !nodesWithChildren.find((node) => node.id === nodeId);
      }
    );

    orphanNodeIds.forEach((nodeId) => {
      const orphanNode = legacyInvention.data.partsNode.find(
        (partsNode) => partsNode.id === nodeId
      );
      const orphanNodeWithChildren = walkNode(orphanNode, legacyInvention);
      nodesWithChildren = nodesWithChildren.concat(orphanNodeWithChildren);
    });

    nodesWithChildren = nodesWithChildren.uniqBy((node) => node.id);

    const nodesAsElements = getNodesAsElements(
      nodesWithChildren,
      legacyInvention,
      rootElementVersion
    ).filter(node => node)


    const _elements = nodesAsElements.filter(
      (_elem) => _elem.type === 'element'
    );
    const _elementVersions = nodesAsElements.filter(
      (_elem) => _elem.type === 'element-version'
    );

    // parseElements
    invention = parseElements(_elements, invention);

    // parseElementVersions
    invention = parseElementVersions(
      _elementVersions,
      invention,
      legacyInvention
    );

    // add comprises features
    invention = addComprisesFeatures(invention);

    // add drawings
    invention = addDrawings(invention, legacyInvention, assetMap);

    const inventionElement = getInventionElement(
      inventionId,
      invention.name,
      rootElementVersion.id
    );

    invention = {
      ...invention,
      element: inventionElement.id,
      elementsList: [inventionElement.id, ...invention.elementsList],
      elements: {
        [inventionElement.id]: inventionElement,
        ...invention.elements,
      },
      elementVersions: {
        ...invention.elementVersions,
        [rootElementVersion.id]: {
          ...invention.elementVersions[rootElementVersion.id],
          element: inventionElement.id,
        },
      },
    };

    // parseComprisesFeatures

    // parseClaims

    // deletedReferencesList?

    // update tree map
    // invention = updateTreeMap(invention);

    return invention;
  },

  parseState(invention) {
    const createdAt = new Date();
    const state = {
      meta: addFromSchema('meta', {
        schemaVersion: this.schemaVersion,
      }),
      invention: {
        id: invention.id,
        name: invention.name,
        figuresList: invention.figuresList,
        preferredElementVersionsList: invention.preferredElementVersionsList,
        createdAt: invention.createdAt || createdAt,
        updatedAt: createdAt,
      },
      elements: {
        ids: invention.elementsList,
        entities: invention.elements,
      },
      requirements: {
        ids: invention.requirementsList,
        entities: invention.requirements,
      },
      elementVersions: {
        ids: invention.elementVersionsList,
        entities: invention.elementVersions,
      },
      features: {
        ids: invention.featuresList,
        entities: invention.features,
      },
      drawings: {
        ids: invention.drawingsList,
        entities: invention.drawings,
      },
      methods: {
        ids: [],
        entities: {},
      },
      methodNodes: {
        ids: [],
        entities: {},
      },
      methodEdges: {
        ids: [],
        entities: {},
      },
      methodEdgePoints: {
        ids: [],
        entities: {},
      },
      images: {
        ids: invention.imagesList || [],
        entities: invention.images || {},
      },
      markers: {
        ids: invention.markersList,
        entities: invention.markers,
      },
      notes: {
        ids: invention.notesList,
        entities: invention.notes,
      },
      patentSpecifications: {
        ids: invention.patentSpecificationsList,
        entities: invention.patentSpecifications,
      },
      ui: addFromSchema('invention-ui'),
      graph: {
        rootNode: invention.element,
        orphanNodesList: [],
        disconnectedNodesList: [],
        collapsedNodesList: [],
        collapsedDescendantsList: [],
        novelElementVersionsList: [],
        novelElementVersionsTree: [],
        treeMap: {},
        elementVersionsMap: {},
        createdAt,
        updatedAt: createdAt,
      },
    };

    return state;
  },

  parseAssetMap: task(function* (legacyInvention) {
    const assetMap = {};
    const images = legacyInvention.data.image || [];
    let i = 0;
    while (i < images.length) {
      const image = images[i];
      const assetId = uuid();
      const attachment = image.attachments[Object.keys(image.attachments)[0]];
      const dataString = attachment.data;
      const mimeType = attachment.content_type;
      let byteString = atob(dataString);

      // write the bytes of the string to an ArrayBuffer
      let ab = new ArrayBuffer(byteString.length);

      // create a view into the buffer
      let ia = new Uint8Array(ab);

      // set the bytes of the buffer to the correct values
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      const blob = new Blob([ab], { type: mimeType });
      assetMap[image.id] = {
        imageId: image.id,
        assetId,
      };
      yield this.assets.addAsset(assetId, blob);
      i++;
    }

    return assetMap;
  }),

  migrateLegacyInvention: task(function* (legacyInvention) {
    const assetMap = yield this.parseAssetMap.perform(legacyInvention);
    const invention = this.parseGraph(legacyInvention, assetMap);
    const state = this.parseState(invention);
    return state;
  }),

  updateInventionFile: task(function* (file) {
    const legacyInvention = yield this.parseZip.perform(file);
    const updatedInvention = yield this.migrateLegacyInvention.perform(
      legacyInvention
    );

    return updatedInvention;
  }),

  extractModelData: task(function* (type, zipEntry, importData) {
    let content = yield zipEntry.async('string');
    // content = content.replace(/\s/g, "")
    if (content) {
      const data = JSON.parse(content);
      importData['data'][type] = data;
    }
  }),

  parseZip: task(function* (file) {
    const data = {};
    // data['meta'] = {};
    data['data'] = {};
    const zip = yield JSZip.loadAsync(file);
    // const metaFile = zip.file('meta.json');

    // let meta = yield metaFile.async('string');
    // meta = JSON.parse(meta);

    const tasks = [];

    zip.folder('data').forEach((relativePath, zipEntry) => {
      const type = zipEntry.name
        .replace('.json', '')
        .replace('data/', '')
        .camelize();
      tasks.push(this.extractModelData.perform(type, zipEntry, data));
    });

    yield all(tasks);

    return data;
  }),
});
