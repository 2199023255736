import {
  defaultArtboardHeight,
  defaultArtboardWidth,
} from '../constants/settings';
import {
  getAutoLabelOrientation,
  getLabelCoords,
  getMarkerPath,
  getMethodEdgePath,
  parseCurve,
} from '../utils/drawing';
import { getElementOrdinal, getMethodNodeOrdinal, getTermOrdinal } from '../selectors/invention';
import {
  getHandleCoordinates,
  getSourcePoint,
  getTargetPoint,
} from '../utils/graph';
import { getMethodNode, getMethodNodeFeatureId } from '../selectors/method-node';
import { textSplit, textWrap } from 'd3plus-text';

import Service from '@ember/service';
import { blobUrlToBase64JPEG } from '../utils/image';
import drawingTemplate from '../constants/templates/drawing';
import { getFeature } from '../selectors/feature';
import { getFigure } from '../selectors/invention';
import { getImage } from '../selectors/image';
import { getMarker } from '../selectors/marker';
import { getMentionsContent } from '../selectors/mention';
import { getMethodEdge } from '../selectors/method-edge';
import { getMethodEdgePoint } from '../selectors/method-edge-point';
import moment from 'moment';
import pointsAtLength from 'point-at-length';
import { reverse } from 'lodash';
import { inject as service } from '@ember/service';
import { textValue } from '../utils/string';
import { tracked } from '@glimmer/tracking';

export default class FiguresCache extends Service {
  @service assets;
  @service redux;
  @service store;

  @tracked blobUrlCache;
  @tracked dataUrlCache;

  constructor(owner, args) {
    super(owner, args);
    this.blobUrlCache = {};
    this.dataUrlCache = {};
  }

  getDimensions(figure) {
    const { orientation } = figure;
    const artboardWidth =
      orientation === 'portrait' ? defaultArtboardWidth : defaultArtboardHeight;
    const artboardHeight =
      orientation === 'portrait' ? defaultArtboardHeight : defaultArtboardWidth;
    const artboardX = (-1 * artboardWidth) / 2;
    const artboardY = (-1 * artboardHeight) / 2;
    const svgWidth = artboardWidth;
    const svgHeight = artboardHeight;
    const svgX = artboardX;
    const svgY = artboardY;

    return {
      artboardWidth,
      artboardHeight,
      artboardX,
      artboardY,
      svgWidth,
      svgHeight,
      svgX,
      svgY,
    };
  }

  async getImageData(image) {
    const { x, y, width, height, asset } = image;
    const imageAsset = asset;
    const src = await this.assets.getAssetDataUrl(imageAsset);
    return {
      x,
      y,
      width,
      height,
      src,
    };
  }

  getMarkerData(marker) {
    const state = this.redux.getState();
    const elementOrdinal = marker.element &&
    marker.elementVersion &&
    getElementOrdinal(state, marker.element, marker.elementVersion);
    const termOrdinal = marker.term &&
    getTermOrdinal(state, marker.term);
    const ordinal = marker.type === 'element' ? elementOrdinal : termOrdinal;
      
    const { pointStyle, curve, startX, startY, midX, midY, endX, endY } =
      marker;
    const line = getMarkerPath({
      curve: parseCurve(curve),
      startX,
      startY,
      midX,
      midY,
      endX,
      endY,
    });

    let labelOrientation = marker.labelOrientation;

    if (labelOrientation === 'auto') {
      labelOrientation = getAutoLabelOrientation({
        startX,
        startY,
        endX,
        endY,
        midX,
        midY,
      });
    }

    const labelWidth = 150;
    const labelHeight = 20;
    const startRadius = 20;
    const labelMargin = -10;

    const labelCoords = getLabelCoords({
      orientation: labelOrientation,
      labelWidth,
      labelHeight,
      startX,
      startY,
      radius: startRadius,
      margin: labelMargin,
    });
    if (labelOrientation === 'bottom') {
      labelCoords.y += 20;
    }
    const labelX = labelCoords.x;
    const labelY = labelCoords.y;
    const labelText = ordinal || '';
    const showArrow = pointStyle === 'arrow';

    return {
      line,
      showArrow,
      labelX,
      labelY,
      labelText,
    };
  }

  async getFigure(figureId, preview = true) {
    const state = this.redux.getState();
    const figure = getFigure(state, figureId);
    const isDrawing = figure.type === 'drawing';
    const isMethod = figure.type === 'method';
    const imagesList = isDrawing ? [...figure.imagesList] : [];
    const markersList = isDrawing ? [...figure.markersList] : [];
    const reverseImagesList = reverse(imagesList);
    const methodNodesList = isMethod ? figure.methodNodesList : [];
    const methodEdgesList = isMethod ? figure.methodEdgesList : [];

    const methodModel = this.store.peekRecord('method', figureId);

    const nodes = methodNodesList.map((methodNodeId) =>
      this.getMethodNodeData(getMethodNode(state, methodNodeId), figureId, methodModel.traversedNodesList)
    );

    const edges = methodEdgesList.map((methodEdgeId) =>
      this.getMethodEdgeData(getMethodEdge(state, methodEdgeId))
    );

    const images = await Promise.all(
      reverseImagesList.map(async (imageId) => {
        return await this.getImageData(getImage(state, imageId), preview);
      })
    );
    const markers = markersList.map((markerId) =>
      this.getMarkerData(getMarker(state, markerId))
    );
    const {
      artboardX,
      artboardY,
      artboardWidth,
      artboardHeight,
      svgX,
      svgY,
      svgWidth,
      svgHeight,
    } = this.getDimensions(figure);

    const data = {
      svgX,
      svgY,
      svgWidth,
      svgHeight,
      artboardWidth,
      artboardHeight,
      artboardX,
      artboardY,
      images,
      markers,
      nodes,
      edges,
      isDrawingPreview: true,
      isMarkerReference: false,
      isHighlightReference: false,
    };
    return drawingTemplate(data);
  }

  async cacheUrls(figureId) {
    const state = this.redux.getState();
    const figure = getFigure(state, figureId);
    const updatedAt = figure.updatedAt || new Date();
    const svg = await this.getFigure(figureId);
    const blob = new Blob([svg], { type: 'image/svg+xml' });
    const blobUrl = URL.createObjectURL(blob);
    this.blobUrlCache[figureId] = {
      blobUrl,
      updatedAt,
    };
  }

  async getBlobUrl(figureId) {
    let blobUrl;
    const state = this.redux.getState();
    const figure = getFigure(state, figureId);

    if (!figure) {
      return;
    }

    const updatedAt = figure.updatedAt;

    if (
      this.blobUrlCache[figureId] &&
      moment(updatedAt).isSame(this.blobUrlCache[figureId].updatedAt)
    ) {
      blobUrl = this.blobUrlCache[figureId].blobUrl;
    } else {
      try {
        const svg = await this.getFigure(figureId);
        const blob = new Blob([svg], { type: 'image/svg+xml' });
        blobUrl = URL.createObjectURL(blob);
        this.blobUrlCache[figureId] = {
          blobUrl,
          updatedAt,
        };
      } catch (err) {
        console.error(err);
      }
    }
    return blobUrl;
  }

  async getDataUrl(figureId) {
    let dataUrl;
    const state = this.redux.getState();
    const figure = getFigure(state, figureId);
    const updatedAt = figure.updatedAt;
    if (
      this.dataUrlCache[figureId] &&
      moment(updatedAt).isSame(this.dataUrlCache[figureId].updatedAt)
    ) {
      dataUrl = this.dataUrlCache[figureId].dataUrl;
    } else {
      try {
        const svg = await this.getFigure(figureId, false);
        const blob = new Blob([svg], { type: 'image/svg+xml' });
        const blobUrl = URL.createObjectURL(blob);
        const { svgWidth, svgHeight } = this.getDimensions(figure);
        dataUrl = await blobUrlToBase64JPEG(blobUrl, svgWidth * 2, svgHeight * 2);
        this.dataUrlCache[figureId] = {
          dataUrl,
          updatedAt,
        };
      } catch (err) {
        console.error(err);
      }
    }
    return dataUrl;
  }

  getConditionalPolygonPoints(x, y, width, height) {
    const { NORTH, SOUTH, EAST, WEST } = getHandleCoordinates(x, y, width, height, 0, 0);
    return `${NORTH.x},${NORTH.y} ${EAST.x},${EAST.y} ${SOUTH.x},${SOUTH.y} ${WEST.x},${WEST.y}`;
  }

  
  getMethodNodeData(methodNode, methodId, traversedNodesList = []) {
    const state = this.redux.getState();
    const { type, x, y, width, height } = methodNode;
    const cornerRadius = type === 'start' ? height / 2 : 0;
    const centerX = width / 2;
    const ordinal = getMethodNodeOrdinal(state, methodId, methodNode.id, traversedNodesList);
    const isConditional = type === 'conditional';
    const conditionalPolygonPoints = this.getConditionalPolygonPoints(
      x,
      y,
      width,
      height
    );

    const useLabel = type === 'start';
    const featureId = getMethodNodeFeatureId(state, methodNode.id)
    const featureValue = featureId && getFeature(state, featureId).value;
    let label = useLabel ? methodNode.label : featureValue;
    label = getMentionsContent(state, label, `method-node-${methodNode.id}`, {
      appendOrdinal: false,
      appendElementVersion: false,
      isInput: true,
      isInteractive: false,
    });
    label =  textValue(label);
    const padding = 40;
    const fontSize = 19.5;
    const fontFamily = [
      'Roboto',
      'Inter',
      'HelveticaNeue',
      'Helvetica',
      'Arial',
      'sans-serif',
    ];
    const fontWeight = '400';
    const lineHeight = 29;
    const textWidth = isConditional ? width * 0.75 - padding : width - padding;
    const wrapper = textWrap()
      .fontFamily(fontFamily)
      .fontSize(fontSize)
      .fontWeight(fontWeight)
      .width(textWidth)
      .split(textSplit);
    const lines = wrapper(label).lines;
    const values = lines.map((line, index) => {
      return {
        y: index * lineHeight,
        value: line,
      };
    });
    const valuesHeight = (lineHeight * values.length) / 2;
    const valuesX = width / 2;
    const valuesY = 1 + padding / 2 + height / 2;

    return {
      isConditional,
      conditionalPolygonPoints,
      x,
      y,
      width,
      height,
      cornerRadius,
      centerX,
      ordinal,
      values,
      valuesHeight,
      valuesX,
      valuesY,
    };
  }

  getAllPoints(sourceX, sourceY, targetX, targetY, pointsArray) {
    const sourcePoint = {
      x: sourceX,
      y: sourceY,
      type: 'source',
    };
    const targetPoint = {
      x: targetX,
      y: targetY,
      type: 'target',
    };

    let points = [sourcePoint];

    pointsArray.forEach((point) =>
      points.push({ x: point.x, y: point.y, type: 'control' })
    );

    points.push(targetPoint);

    points = points.map((point) => {
      const distance = 0;
      return {
        ...point,
        distance,
      };
    });

    return points;
  }

  getEdgeCoordinates(sourceNode, targetNode, sourcePosition, targetPosition) {
    const sourceHandleCoordinates = getHandleCoordinates(
      sourceNode.x,
      sourceNode.y,
      sourceNode.width,
      sourceNode.height,
      0,
      0
    );

    const targetHandleCoordinates = getHandleCoordinates(
      targetNode.x,
      targetNode.y,
      targetNode.width,
      targetNode.height,
      0,
      0
    );
    const sourceX = sourceHandleCoordinates[sourcePosition].x;
    const sourceY = sourceHandleCoordinates[sourcePosition].y;
    const targetX = targetHandleCoordinates[targetPosition].x;
    const targetY = targetHandleCoordinates[targetPosition].y;

    return {
      sourceX,
      sourceY,
      targetX,
      targetY,
    };
  }

  getHalfLine(methodEdge, sourceNode, targetNode) {
    const state = this.redux.getState();
    const { sourcePosition, targetPosition, methodEdgePointsList, curve } =
      methodEdge;

    const pointsArray = methodEdgePointsList.map((methodEdgePointId) => {
      return getMethodEdgePoint(state, methodEdgePointId);
    });

    const { sourceX, sourceY, targetX, targetY } = this.getEdgeCoordinates(
      sourceNode,
      targetNode,
      sourcePosition,
      targetPosition
    );

    const allPoints = this.getAllPoints(
      sourceX,
      sourceY,
      targetX,
      targetY,
      pointsArray
    );

    const pointsWithoutSource = allPoints.filter(
      (point) => point.type !== 'source'
    );

    const closestPointToSource = pointsWithoutSource[0];

    const pointsWithoutTarget = allPoints.filter(
      (point) => point.type !== 'target'
    );

    const closestPointToTarget =
      pointsWithoutTarget[pointsWithoutTarget.length - 1];

    const sourcePoint = getSourcePoint({
      sourceRadius: 10,
      sourceX: sourceX,
      sourceY: sourceY,
      targetX: closestPointToSource.x,
      targetY: closestPointToSource.y,
    });

    const targetPoint = getTargetPoint({
      shape: 'circle',
      targetRadius: 10,
      sourceX: closestPointToTarget.x,
      sourceY: closestPointToTarget.y,
      targetX: targetX,
      targetY: targetY,
    });

    const halfLine = getMethodEdgePath({
      curve: parseCurve(curve),
      sourceX: sourcePoint.x,
      sourceY: sourcePoint.y,
      pointsArray: pointsArray,
      targetX: targetPoint.x,
      targetY: targetPoint.y,
    });

    return halfLine;
  }

  // edges: {
  //   halfLine,
  //   showLabel,
  //   label,
  //   labelX,
  //   labelY
  // }

  getMethodEdgeData(methodEdge) {
    const state = this.redux.getState();
    const { source, target, type } = methodEdge;
    const sourceNode = getMethodNode(state, source);
    const targetNode = getMethodNode(state, target);
    const halfLine = this.getHalfLine(methodEdge, sourceNode, targetNode);
    const pts = pointsAtLength(halfLine);
    const ptsLength = pts.length();
    const labelPoint = pts.at(ptsLength / 2);
    const labelWidth = 120;
    const label = type === 'conditional' ? 'if' : '';
    const fontSize = 19.5;
    const fontFamily = [
      'Roboto',
      'Inter',
      'HelveticaNeue',
      'Helvetica',
      'Arial',
      'sans-serif',
    ];
    const fontWeight = '400';
    const lineHeight = 29;
    const wrapper = textWrap()
      .fontFamily(fontFamily)
      .fontSize(fontSize)
      .fontWeight(fontWeight)
      .width(labelWidth)
      .split(textSplit);
    const lines = wrapper(label).lines;
    const labelValues = lines.map((line, index) => {
      return {
        y: index * lineHeight,
        value: line,
      };
    });
    const labelHeight = lineHeight * labelValues.length;
    const labelOffsetX = (-1 * labelWidth) / 2;
    const labelOffsetY = (-1 * labelHeight) / 2;
    const labelTextOffsetY = labelOffsetY + 20.5;
    const labelX = labelPoint[0];
    const labelY = labelPoint[1];
    const showLabel = type === 'conditional';

    return {
      halfLine,
      showLabel,
      labelValues,
      labelX,
      labelY,
      labelOffsetX,
      labelOffsetY,
      labelTextOffsetY,
      labelWidth,
      labelHeight,
    };
  }
}
