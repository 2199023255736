import { action, computed } from '@ember/object';

import Component from '@glimmer/component';
import { connect } from 'ember-redux';
import { getActiveProductId } from '../../../selectors/invention-ui';
import { getElement } from '../../../selectors/element';
// import { getElementVersion } from '../../../selectors/element-version';
import { getPreferredElementVersionId } from '../../../selectors/product';
import { getTerm } from '../../../selectors/term';
import podNames from 'ember-component-css/pod-names';
import { sanitizeMentions } from '../../../utils/mentions';
import { inject as service } from '@ember/service';

const stateToComputed = (state, attrs) => ({
  element: getElement(state, attrs.elementId),
  term: getTerm(state, attrs.termId),
  activeProductId: getActiveProductId(state),
});

const dispatchToActions = {};

class UiMentionContextMenu extends Component {
  @service contextMenu;
  @service redux;
  @service tracking;

  get styleNamespace() {
    return podNames['ui-mention-context-menu'];
  }

  get classNames() {
    let classNames = [this.styleNamespace, 'ui-mention-context-menu'];
    return classNames.join(' ');
  }

  @action
  didInsert() {
    this.tracking.trackEvent('opened_mention_context_menu');
  }

  @computed('activeProductId')
  get isProductsView() {
    return this.activeProductId ? true : false;
  }

  @computed('element.category')
  get elementLabel() {
    return this.element.category === 'part' ? 'View Part' : 'View System';
  }

  @computed('activeProductId', 'args.elementId')
  get preferredElementVersionId() {
    const state = this.redux.getState();
    return (
      this.activeProductId &&
      getPreferredElementVersionId(
        state,
        this.args.elementId,
        this.activeProductId
      )
    );
  }

  @computed('element.elementVersionsList.[]')
  get elementVersionsList() {
    return this.element && this.element.elementVersionsList;
  }

  @computed('term')
  get isTerm() {
    return this.term ? true : false;
  }

  @computed('args.termId')
  get canSelectTerm() {
    return this.args.termId ? true : false;
  }

  @computed('canSelectTerm')
  get cantSelectTerm() {
    return !this.canSelectTerm;
  }

  @computed('args.elementId')
  get canSelectElement() {
    return this.args.elementId ? true : false;
  }

  @computed('canSelectElement')
  get cantSelectElement() {
    return !this.canSelectElement;
  }

  @computed('preferredElementVersionId')
  get canSelectElementVerision() {
    return this.preferredElementVersionId ? true : false;
  }

  @computed('canSelectElementVerision')
  get cantSelectElementVerision() {
    return !this.canSelectElementVerision;
  }

  @computed('contextMenu.domElementId')
  get contentMentionable() {
    const mentionA = document.getElementById(this.contextMenu.domElementId);
    const contentMentionable =
      mentionA && mentionA.closest('.medium-editor-element');
    return contentMentionable;
  }

  @computed('contentMentionable')
  get isContentMentionable() {
    return this.contentMentionable ? true : false;
  }

  @computed('isContentMentionable')
  get canUnwrapMention() {
    return this.isContentMentionable;
  }

  @computed('canUnwrapMention')
  get cantUnwrapMention() {
    return !this.canUnwrapMention;
  }

  @computed('isContentMentionable')
  get canDeleteMention() {
    return this.isContentMentionable;
  }

  @computed('canDeleteMention')
  get cantDeleteMention() {
    return !this.canDeleteMention;
  }

  @action
  onSelectTerm() {
    if (this.args.onSelectTerm) {
      this.args.onSelectTerm(this.args.termId);
    }
    this.tracking.trackEvent('mention_context_viewed_term');
    this.contextMenu.close();
  }
  @action
  onSelectElement() {
    if (this.args.onSelectElement) {
      this.args.onSelectElement(this.args.elementId);
    }
    this.tracking.trackEvent('mention_context_menu_viewed_system');
    this.contextMenu.close();
  }
  @action
  onSelectElementVersion() {
    if (this.args.onSelectElementVersion) {
      this.args.onSelectElementVersion(this.preferredElementVersionId);
    }
    this.tracking.trackEvent('mention_context_menu_viewed_solution');
    this.contextMenu.close();
  }

  @action
  onUnwrapMention() {
    if (this.args.onUnwrapMention && this.canUnwrapMention) {
      const contentMentionable = this.contentMentionable;
      const containerId = contentMentionable.dataset.containerId;
      const containerType = contentMentionable.dataset.containerType;
      const domElementId = this.contextMenu.domElementId;

      const div = document.createElement('div');
      div.innerHTML = contentMentionable.innerHTML;

      // get the mention <a> element
      const mentionA = div.querySelector(`#${domElementId}`);

      // get its parent <mention> element
      const mention = mentionA.closest('.mention');

      if (mention) {
        // get the mentioned name
        const mentionName = mention.dataset.name;

        // replace the <mention> with the name
        mention.replaceWith(mentionName);

        let containerValue = div.innerHTML;
        containerValue = sanitizeMentions(containerValue);

        // update everything
        this.args.onUnwrapMention(containerType, containerId, containerValue);
        this.tracking.trackEvent('mention_context_menu_unwrapped_mention');
      }
    }
    this.contextMenu.close();
  }

  @action
  onDeleteMention() {
    if (this.args.onDeleteMention && this.canDeleteMention) {
      const contentMentionable = this.contentMentionable;
      const containerId = contentMentionable.dataset.containerId;
      const containerType = contentMentionable.dataset.containerType;
      const domElementId = this.contextMenu.domElementId;

      const div = document.createElement('div');
      div.innerHTML = contentMentionable.innerHTML;

      // get the mention <a> element
      const mentionA = div.querySelector(`#${domElementId}`);

      // get its parent <mention> element
      const mention = mentionA.closest('.mention');

      if (mention) {
        // replace the <mention> with the nothing
        mention.replaceWith('');

        let containerValue = div.innerHTML;
        containerValue = sanitizeMentions(containerValue);

        // update everything
        this.args.onDeleteMention(containerType, containerId, containerValue);
        this.tracking.trackEvent('mention_context_menu_removed_mention');
      }
    }
    this.contextMenu.close();
  }
}

export default connect(
  stateToComputed,
  dispatchToActions
)(UiMentionContextMenu);
