import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import podNames from "ember-component-css/pod-names";

export default class SignInPage extends Component {
  @tracked showingActivation = false;
  @tracked email = '';

  get styleNamespace() {
    return podNames['sign-in-page'];
  }

  @action
  onActivationSent(email) {
    this.showingActivation = true;
    this.email = email;
  }

  @action
  onResend() {
    this.showingActivation = false;
  }
}
