export default {
  comprises: {
    id: 'comprises',
    name: 'Comprises',
    inputType: 'element',
    editable: false,
    canNovel: true,
    defaultValue: {
      amount: 'one', // one_plus, plurality, custom
      element: '',
    },
    instructions: '',
    requirementInstructions: '',
    hint: '',
    requirementHint: '',
    placeholder: '',
    examples: [''],
  },
  analogs: {
    id: 'analogs',
    name: 'Example',
    inputType: 'content-mentionable-array',
    editable: true,
    canNovel: false,
    defaultValue: '',
    instructions: 'For example ...',
    requirementInstructions: '',
    hint: 'For example ...',
    requirementHint: '',
    placeholder: 'e.g. rubber cement, masking tape, etc.',
    examples: ['e.g. rubber cement, masking tape, etc.'],
  },
  element_step: {
    id: 'element_step',
    name: 'Success Event',
    inputType: 'content-mentionable',
    editable: true,
    canNovel: true,
    defaultValue: '',
    instructions: 'Describe the outcome',
    requirementInstructions: 'Describe the outcome.',
    hint: 'Success occurs when ...',
    requirementHint: '',
    placeholder: 'e.g. ice cream temperature is determined',
    examples: [
      'e.g. water enters the canal',
      'e.g. ice cream temperature is determined',
    ],
  },
  step: {
    id: 'step',
    name: 'Step',
    inputType: 'content-mentionable',
    editable: true,
    canNovel: true,
    defaultValue: '',
    instructions: 'Custom steps are used to explain a system process',
    requirementInstructions: '',
    hint: '',
    requirementHint: '',
    placeholder: 'e.g. cards are dealt',
    examples: ['e.g. cards are dealt', 'e.g. the car runs out of gas'],
  },
  custom_step: {
    id: 'custom_step',
    name: 'Custom Step',
    inputType: 'content-mentionable',
    editable: true,
    canNovel: true,
    defaultValue: '',
    instructions: 'Explain an intermediary action',
    requirementInstructions: '',
    hint: 'Custom Step',
    requirementHint: '',
    placeholder: 'e.g. cards are dealt',
    examples: ['e.g. cards are dealt', 'e.g. the car runs out of gas'],
  },
  inverse_step: {
    id: 'inverse_step',
    name: 'Inverse Step',
    inputType: 'content-mentionable',
    editable: true,
    canNovel: true,
    defaultValue: '',
    instructions: 'Describe the inverse of the conditional',
    requirementInstructions: '',
    hint: '',
    requirementHint: '',
    placeholder: 'e.g. cards are not dealt',
    examples: [
      'e.g. cards are not dealt',
      'e.g. the car does not run out of gas',
    ],
  },
  function: {
    id: 'function',
    name: 'Function',
    inputType: 'content-mentionable',
    editable: true,
    canNovel: true,
    defaultValue: '',
    instructions: 'Describe what the system is configured to do',
    requirementInstructions:
      'Describe what the system <strong><em>must be able to do</em></strong>.',
    hint: 'Configured to ...',
    requirementHint: 'The system must be able to',
    placeholder: 'e.g. automatically feed someone cereal',
    examples: ['e.g. climb a tree in under 30 seconds'],
  },
  functional_requirement: {
    id: 'functional_requirement',
    name: 'Required Function',
    inputType: 'content-mentionable',
    editable: true,
    canNovel: true,
    defaultValue: '',
    instructions: 'Describe what the solution must do',
    requirementInstructions:
      'Describe what the solution <strong><em>must do</em></strong>.',
    hint: 'Must be able to ...',
    requirementHint: 'The system must be able to',
    placeholder: 'e.g. feed someone cereal',
    examples: ['e.g. detect bugs on a windshield'],
  },

  constraint: {
    id: 'constraint',
    name: 'Constraint',
    inputType: 'content-mentionable',
    editable: true,
    canNovel: true,
    defaultValue: '',
    instructions: 'Describe what the system must be',
    requirementInstructions:
      'Describe what the system <strong><em>must be able to do</em></strong>.',
    hint: 'Must ...',
    requirementHint: 'The system must be configured to',
    placeholder: 'e.g. be lightweight',
    examples: ['e.g. be lightweight'],
  },
  material: {
    id: 'material',
    name: 'Material',
    inputType: 'content-mentionable',
    editable: true,
    canNovel: true,
    defaultValue: '',
    instructions: 'Describe a material composition',
    requirementInstructions:
      'Describe a material that the system <strong><em>must be composed of</em></strong>.',
    hint: 'Composed of or contains ...',
    requirementHint: 'The system must be composed of',
    placeholder: 'e.g. plastic',
    examples: ['e.g. plastic', 'e.g. PVC', 'e.g. nylon', 'e.g. foam rubber'],
  },
  shape: {
    id: 'shape',
    name: 'Shape',
    inputType: 'content-mentionable',
    editable: true,
    canNovel: true,
    defaultValue: '',
    instructions: 'Describe the form the system takes',
    requirementInstructions:
      'Describe the shape or form that the system <strong><em>must be take</em></strong>.',
    hint: 'Shaped like ...',
    requirementHint: 'The system must be shaped like',
    placeholder: 'e.g. an oblong rectangle',
    examples: ['e.g. an oblong rectangle'],
  },
  definition: {
    id: 'definition',
    name: 'Definition',
    inputType: 'content-mentionable',
    editable: true,
    canNovel: false,
    defaultValue: '',
    instructions: 'Defined as ...',
    requirementInstructions:
      'Give the system a <strong><em>mandatory definition</em></strong>',
    hint: 'Defined as ...',
    requirementHint: 'The system must be defined as',
    placeholder: 'e.g. a tool that is swung to deliver an impact',
    examples: ['e.g. a tool that is swung to deliver an impact'],
  },
  position: {
    id: 'position',
    name: 'Position',
    inputType: 'content-mentionable',
    editable: true,
    canNovel: true,
    defaultValue: '',
    instructions:
      'Describe how the system is positioned relative to other systems',
    requirementInstructions:
      'Describe how the system <strong><em>must be positioned</em></strong> relative to other system.',
    hint: 'Is positioned ...',
    requirementHint: 'The system must be positioned',
    placeholder: 'e.g. proximal to the latch',
    examples: ['e.g. proximal to the latch'],
  },
  interaction: {
    id: 'interaction',
    name: 'Interaction',
    inputType: 'content-mentionable',
    editable: true,
    canNovel: true,
    defaultValue: '',
    instructions: 'Describe how the system interacts with other systems',
    requirementInstructions:
      'Describe how the system <strong><em>must interact</em></strong> with other system.',
    hint: 'Interacts with ...',
    requirementHint: 'The system must interact with',
    placeholder: 'e.g. the toaster coil by heating it',
    examples: ['e.g. the toaster coil by heating it'],
  },
  attachment: {
    id: 'attachment',
    name: 'Attachment',
    inputType: 'content-mentionable',
    editable: true,
    canNovel: true,
    defaultValue: '',
    instructions: 'Describe how the system attaches to other systems',
    requirementInstructions:
      'Describe how the system <strong><em>must attach</em></strong> to other systems.',
    hint: 'Attaches to ...',
    requirementHint: 'The system must attach to',
    placeholder: 'e.g. the handle with epoxy',
    examples: ['e.g. the handle with epoxy'],
  },
  general: {
    id: 'general',
    name: 'General',
    inputType: 'content-mentionable',
    editable: true,
    canNovel: true,
    defaultValue: '',
    instructions: 'Describe a custom feature of the system',
    requirementInstructions:
      'Describe a custom feature the system <strong><em>must have</em></strong>.',
    hint: 'The system ...',
    requirementHint: 'The system must',
    placeholder: 'e.g. your custom feature',
    examples: ['e.g. your custom feature'],
  },
  detail: {
    id: 'detail',
    name: 'Detail',
    inputType: 'content-mentionable',
    editable: true,
    canNovel: true,
    defaultValue: '',
    instructions: 'Add any additonal details...',
    requirementInstructions: '',
    hint: 'In some examples ...',
    requirementHint: '',
    placeholder: 'e.g. any other details',
    examples: ['e.g. any other details'],
  },
  absent: {
    id: 'absent',
    name: 'Absent',
    inputType: 'content-mentionable',
    editable: true,
    canNovel: true,
    defaultValue: [],
    instructions:
      'Create an alternative version of your product, that is capable of working, but does not use this item',
    requirementInstructions: '',
    hint: 'e.g. another type of dessert could replace ice cream',
    requirementHint: '',
    placeholder: 'e.g. another type of dessert could replace ice cream',
    examples: ['e.g. another type of dessert could replace ice cream'],
  },
  measurement: {
    id: 'measurement',
    name: 'Measurement',
    inputType: 'form',
    editable: true,
    canNovel: true,
    defaultValue: {
      measurement_type: '',
      measurement_operator: 'equal-to',
      measurement_value: '',
    },
    instructions: 'Describe a measurement of the system',
    requirementInstructions:
      'Describe a measurement the system <strong><em>must have</em></strong>.',
    hint: 'Measurement',
    requirementHint: 'e.g. length, width, electrical resistance',
    examples: [
      {
        measurement_type: 'e.g. length',
        measurement_operator: 'equal-to',
        measurement_value: 'e.g. 20 feet',
      },
      {
        measurement_type: 'e.g. volume',
        measurement_operator: 'equal-to',
        measurement_value: 'e.g. 10 cubic meters',
      },
      {
        measurement_type: 'e.g. electrical resistance',
        measurement_operator: 'equal-to',
        measurement_value: 'e.g. 13.59 ohms',
      },
    ],

    inputs: {
      measurement_type: {
        id: 'measurement_type',
        name: 'Type',
        hint: 'e.g. length, width, electrical resistance',
        placeholder: 'e.g. length, width, electrical resistance',
        inputType: 'content-mentionable',
      },
      measurement_operator: {
        id: 'measurement_operator',
        name: 'Operator',
        inputType: 'select',
        options: [
          {
            id: 'equal-to',
            name: 'equals',
            iconType: 'mdi',
            icon: 'mdi-equal',
          },
          {
            id: 'approximately-equal-to',
            name: 'approximately equals',
            iconType: 'mdi',
            icon: 'mdi-approximately-equal',
          },
          {
            id: 'less-than',
            name: 'less than',
            iconType: 'mdi',
            icon: 'mdi-less-than',
          },
          {
            id: 'less-than-or-equal',
            name: 'less than or equal to',
            iconType: 'mdi',
            icon: 'mdi-less-than-or-equal',
          },
          {
            id: 'greater-than',
            name: 'greater than',
            iconType: 'mdi',
            icon: 'mdi-greater-than',
          },
          {
            id: 'greater-than-or-equal',
            name: 'greater than or equal to',
            iconType: 'mdi',
            icon: 'mdi-greater-than-or-equal',
          },
        ],
      },
      measurement_value: {
        id: 'measurement_value',
        name: 'Value',
        placeholder: 'e.g. 20 feet, 10 cubic meters, 13.59 ohms',
        inputType: 'content-mentionable',
      },
    },
  },
};
