import Component from '@glimmer/component';
import podNames from 'ember-component-css/pod-names';
import { exists } from '../../../utils/general';

class UiContextSectionContent extends Component {
  constructor() {
    super(...arguments); // super must be called first.
    this.hasPaddingTop = this.args.hasPaddingTop || false;
    this.hasPaddingBottom = this.args.hasPaddingBottom || false;
    this.hasHorizontalPadding = exists(this.args.hasHorizontalPadding, true);
    this.hasHorizontalPaddingOffset = exists(this.args.hasHorizontalPaddingOffset, true);
    this.isPaddingless = this.args.isPaddingless || false;
    this.hasMinHeight = this.args.hasMinHeight || false;
    this.disabled = this.args.disabled || false;
  }

  get classNames() {
    let classNames = [this.styleNamespace];
    if (this.hasPaddingTop) classNames.push('has-padding-top');
    if (this.hasPaddingBottom) classNames.push('has-padding-bottom');
    if (this.hasHorizontalPadding) classNames.push('has-horizontal-padding');
    if (this.hasHorizontalPaddingOffset) classNames.push('has-horizontal-padding-offset');
    if (this.isPaddingless) classNames.push('is-paddingless');
    if (this.hasMinHeight) classNames.push('has-min-height');
    if (this.disabled) classNames.push('disabled');
    return classNames.join(' ');
  }

  get styleNamespace() {
    return podNames['ui-context-section-content'];
  }
}

export default UiContextSectionContent;
