import {
  ADD_CUSTOMER,
  REMOVE_CUSTOMER,
  SORT_CUSTOMERS_LIST,
  UPDATE_CUSTOMER,
} from './actionTypes';

import uuid from 'uuid/v4';

export const addCustomer = (attributes = {}, customerId = uuid()) => {
  return {
    type: ADD_CUSTOMER,
    payload: {
      customerId,
      attributes,
    },
  };
};

export const removeCustomer = (customerId) => {
  return {
    type: REMOVE_CUSTOMER,
    payload: {
      customerId,
    },
  };
};

export const updateCustomer = (customerId, attributes = {}) => {
  return {
    type: UPDATE_CUSTOMER,
    payload: {
      customerId,
      attributes,
    },
  };
};


export const sortCustomersList = (customerIds) => {
  return {
    type: SORT_CUSTOMERS_LIST,
    payload: {
      customerIds,
    },
  };
};