import Component from '@ember/component';
import { equal } from '@ember/object/computed';
// import $ from 'jquery';

const UiItem = Component.extend({
  classNames: ['item'],
  classNameBindings: ['isInteractive', 'isSmall', 'hasBorderRadius', 'isSelected', 'isBorderless'],
  isSelected: false,
  isHoverable: false,
  isHovering: true,
  isInteractive: true,
  hasBorderRadius: true,
  size: 'normal', // small, medium, large
  isSmall: equal('size', 'small'),

  // didInsertElement() {
  //   this._super(...arguments);
  //   this.addEventListeners();
  // },

  // willDestroyElement() {
  //   this._super(...arguments);
  //   this.removeEventListeners();
  // },

  // onMouseEnter() {
  //   if (!this.isHoverable) return;
  //   this.set('isHovering', true);
  // },

  // onMouseLeave() {
  //   if (!this.isHoverable) return;
  //   this.set('isHovering', false);
  // },

  // addEventListeners() {
  //   if (this.isHoverable) {
  //     $(`#${this.elementId}`).on('mouseenter', () => this.onMouseEnter());
  //     $(`#${this.elementId}`).on('mouseleave', () => this.onMouseLeave());
  //   }
  // },

  // removeEventListeners() {
  //   if (this.isHoverable) {
  //     $(`#${this.elementId}`).off('mouseenter');
  //     $(`#${this.elementId}`).off('mouseleave');
  //   }
  // }
});

export default UiItem;
