import { action, computed } from '@ember/object';
import { cancel, later } from '@ember/runloop';
import {
  hideExplorer,
  setReviewMode,
  showExplorer,
} from '../../../actions/invention-ui';

import { ActionCreators } from 'redux-undo';
import Component from '@glimmer/component';
import ENV from '../../../config/environment';
import { batchGroupBy } from '../../../utils/redux';
import { connect } from 'ember-redux';
import { getInventionId } from '../../../selectors/invention';
import { getInventionUi } from '../../../selectors/invention-ui';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

const stateToComputed = (state) => ({
  inventionUi: getInventionUi(state),
  inventionId: getInventionId(state),
});

const dispatchToActions = {};

class InventionMenu extends Component {
  @service sessionTime;
  @service export;
  @service data;
  @service router;
  @service session;
  @service sessionManager;
  @service settings;
  @service patentSpecificationDocx;
  @service figuresExportPdf;
  @service redux;
  @service tracking;

  @tracked exportingFigures;

  showDebug = ENV.environment === 'development';

  get styleNamespace() {
    return podNames['invention-menu'];
  }

  calculateNestedPosition(trigger) {
    let { top, left, width } = trigger.getBoundingClientRect();
    let style = {
      left: left + width,
      top: top,
    };

    return { style };
  }

  @computed('sessionManager.user.experimental')
  get experimental() {
    return this.sessionManager.user && this.sessionManager.user.experimental;
  }

  @computed('inventionUi.activeProduct')
  get activeProductId() {
    return this.inventionUi.activeProduct;
  }

  @computed('inventionUi.patentSpecificationPreview')
  get patentSpecificationPreview() {
    return this.inventionUi.patentSpecificationPreview;
  }

  @computed('inventionUi.showingExplorer')
  get showingExplorer() {
    return this.inventionUi.showingExplorer;
  }

  @computed('inventionUi.reviewMode')
  get reviewMode() {
    return this.inventionUi.reviewMode;
  }

  @action
  onMenuClick() {
    this.tracking.trackEvent('opened_disclosure_menu');
  }

  @action
  toggleShowingExplorer() {
    if (this.showingExplorer) {
      this.redux.store.dispatch(hideExplorer());-
      this.tracking.trackEvent('disclosure_menu_closed_explorer');
    } else {
      this.redux.store.dispatch(showExplorer());
      this.tracking.trackEvent('disclosure_menu_opened_explorer');
    }
  }

  @action
  saveAs() {
    this.sessionTime.update();
    const state = this.redux.getState();
    this.export.exportZip(state.present);
    this.tracking.trackEvent('disclosure_menu_saved_dive');
  }

  @action
  openVersionCreate() {
    this.data.setShowingHistoryVersionCreateModal(true);
    this.tracking.trackEvent('disclosure_menu_opened_save_version');
  }

  @action
  viewHistory() {
    this.tracking.trackEvent('disclosure_menu_viewed_version_history');
    this.router.transitionTo('history', this.inventionId);
  }

  @action
  undo() {
    if ('activeElement' in document) document.activeElement.blur();
    this.redux.store.dispatch(ActionCreators.undo());
    this.tracking.trackEvent('disclosure_menu_undo');
  }

  @action
  redo() {
    if ('activeElement' in document) document.activeElement.blur();
    this.redux.store.dispatch(ActionCreators.redo());
    this.tracking.trackEvent('disclosure_menu_redo');
  }

  @action
  signOut() {
    this.tracking.trackEvent('logged_out');
    this.tracking.reset();
    this.session.invalidate();
  }

  @action
  backToInventions() {
    this.tracking.trackEvent('disclosure_menu_back_to_disclosures');
    this.router.transitionTo('dashboard');
  }

  @action
  exportSpecification() {
    this.patentSpecificationDocx.export(this.redux.getState());
    this.tracking.trackEvent('disclosure_menu_exported_specification');
  }

  @action
  exportFigures() {
    this.figuresExportPdf.export(this.redux.getState());
    this.tracking.trackEvent('disclosure_menu_exported_figures');
  }

  @action
  toggleDarkMode() {
    this.settings.toggleDarkMode();
    this.tracking.trackEvent('disclosure_menu_toggled_theme');
  }

  @action
  toggleBlackAndWhiteMode() {
    this.settings.toggleBlackAndWhiteMode();
  }

  @action
  toggleReviewMode() {
    this.redux.store.dispatch(setReviewMode(!this.reviewMode));
  }

  @action
  showPresentationPreview() {
    batchGroupBy.start();
    this.data.showPresentation();
    batchGroupBy.end();
  }

  @action
  showCustomers() {
    batchGroupBy.start();
    this.data.setShowingCustomers(true);
    batchGroupBy.end();
    this.tracking.trackEvent('disclosure_menu_opened_prior_art');
  }

  @action
  showPriorArts() {
    batchGroupBy.start();
    this.data.setShowingPriorArts(true);
    batchGroupBy.end();
    this.tracking.trackEvent('disclosure_menu_opened_prior_art');
  }

  @action
  showAssets() {
    batchGroupBy.start();
    this.data.setShowingAssets(true);
    batchGroupBy.end();
    this.tracking.trackEvent('disclosure_menu_opened_assets');
  }



  
  @action
  showSettings() {
    batchGroupBy.start();
    this.data.setShowingSettings(true);
    batchGroupBy.end();
  }

  
  @action
  showAllProducts() {
    batchGroupBy.start();
    this.data.setActiveProduct(null);
    batchGroupBy.end();
    this.tracking.trackEvent('disclosure_menu_opened_all_products');
  }
  
  @action
  showComparisonMatrix() {
    batchGroupBy.start();
    this.data.setShowingComparisonMatrix(true);
    batchGroupBy.end();
    this.tracking.trackEvent('disclosure_menu_opened_prior_art_comparison');
  }
  
  prevent(e) {
    return e.stopImmediatePropagation();
  }

  @action
  open(dropdown) {
    if (this.closeTimer) {
      cancel(this.closeTimer);
      this.closeTimer = null;
    } else {
      dropdown.actions.open();
    }
  }

  @action
  closeLater(dropdown) {
    this.closeTimer = later(() => {
      this.closeTimer = null;
      dropdown.actions.close();
    }, 200);
  }

  @action
  onNestedMouseenter() {
    cancel(this.closeTimer);
  }

  @action
  onNestedMouseleave(dropdown) {
    this.closeTimer = later(() => {
      this.closeTimer = null;
      dropdown.actions.close();
    }, 200);
  }

}

export default connect(stateToComputed, dispatchToActions)(InventionMenu);
