import Component from '@glimmer/component';
import { connect } from 'ember-redux';
import { htmlSafe } from '@ember/string';
import podNames from 'ember-component-css/pod-names';

const dispatchToActions = {};

const stateToComputed = null;

class ElementTableHeaderRow extends Component {
  get styleNamespace() {
    return podNames['element-table-header-row'];
  }
  get classNames() {
    let classNames = [this.styleNamespace, 'element-table-header-row'];
    return classNames.join(' ');
  }

  get gridStyle() {
    return htmlSafe(
      // `grid-template-columns: repeat(${this.columnWidths}, minmax(200px, 1fr));`
      `grid-template-columns: ${this.args.columnWidths};`
    );
  }
}

export default connect(
  stateToComputed,
  dispatchToActions
)(ElementTableHeaderRow);
