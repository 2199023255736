export default [
  {
    name: 'Method Description',
    conditions: {
      type: {
        is: 'method-description'
      },
      subject: {
        is: 'figure'
      },
    },
    event: {
      template: `Figure {{{sequence}}} illustrates {{{indefinite view_angle}}} view that shows
        {{{figure_description}}}, according to an embodiment of the present disclosure.`,
      required: ['sequence', 'view_angle', 'figure_description']
    }
  }
]
