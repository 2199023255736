import Component from '@glimmer/component';
import { action, computed } from '@ember/object';
import { htmlSafe } from '@ember/string';
import { connect } from 'ember-redux';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';

import {
  getShowingExplorer,
  getExplorerWidth,
} from '../../../selectors/invention-ui';

const stateToComputed = (state) => ({
  showingExplorer: getShowingExplorer(state),
  explorerWidth: getExplorerWidth(state),
});

const dispatchToActions = {};

class PatentSpecification extends Component {
  @service data;
  @service tracking;

  get classNames() {
    let classNames = ['patent-specification', this.styleNamespace];
    return classNames.join(' ');
  }

  get styleNamespace() {
    return podNames['patent-specification'];
  }

  @computed('showingExplorer', 'explorerWidth')
  get leftWidth() {
    return this.showingExplorer ? this.explorerWidth : 0;
  }

  @computed('leftWidth')
  get leftStyle() {
    return htmlSafe(`width:${this.leftWidth}px;`);
  }

  @action
  didInsert() {
    this.tracking.trackEvent('opened_patent_specification');
  }

  @action
  willDestroyNode() {
    this.tracking.trackEvent('closed_patent_specification');
  }
}

export default connect(stateToComputed, dispatchToActions)(PatentSpecification);
