import moment from 'moment';

moment.locale('en', {
  relativeTime: {
    future: 'in %s',
    past: '%s',
    s: 'Now',
    m: '1m',
    mm: '%dm',
    h: '1h',
    hh: '%dh',
    d: '1d',
    dd: '%dd',
    M: '1mon',
    MM: '%dmon',
    y: '1y',
    yy: '%dy',
  },
});
export const exists = (value, defaultValue) =>
  typeof value !== 'undefined' ? value : defaultValue;

export const getContentOverflowed = (sb) => {
  let isOverflowed = false;
  const contentHeight = sb.contentEl && sb.contentEl.offsetHeight;
  const wrapperHeight = sb.contentWrapperEl && sb.contentWrapperEl.offsetHeight;

  if (contentHeight && wrapperHeight) {
    isOverflowed = contentHeight > wrapperHeight;
  }

  return isOverflowed;
};
