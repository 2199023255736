import Component from '@glimmer/component';
import ENV from '../../../config/environment';
import { action } from '@ember/object';
import { getDocumentationType } from '../../../selectors/type';
import { inject as service } from '@ember/service';

class UiDocumentationLink extends Component {
  @service tracking;

  baseUrl = ENV.DOCS_URL;

  get classNames() {
    let classNames = ['ui-documentation-link'];
    return classNames.join(' ');
  }

  get url() {
    return `${this.baseUrl}${this.path}`;
  }

  get type() {
    return (
      this.args.documentationTypeId &&
      getDocumentationType(this.args.documentationTypeId)
    );
  }

  get path() {
    return (this.type && this.type.path) || '';
  }

  @action
  onClick(e) {
    e.preventDefault();
    this.tracking.trackEvent(`clicked_milestone_tutorial_${this.args.milestoneTypeId}`)
    window.open(this.url, '_blank');
  }
}

export default UiDocumentationLink;