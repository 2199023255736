
import Component from '@glimmer/component';
import { computed } from '@ember/object';
import podNames from 'ember-component-css/pod-names';
import { connect } from 'ember-redux';
import { getTerm } from '../../../selectors/term';
import { getTermOrdinal } from '../../../selectors/invention';

const dispatchToActions = {};

const stateToComputed = (state, attrs) => ({
  term: getTerm(state, attrs.termId),
  ordinal: getTermOrdinal(state, attrs.termId)
});

class MarkerTermName extends Component {
  get classNames() {
    let classNames = ['marker-term-name', this.styleNamespace];
    return classNames.join(' ');
  }

  get styleNamespace() {
    return podNames['marker-term-name'];
  }

  @computed('term.name')
  get termName() {
    return this.term && this.term.name;
  }
}

export default connect(
  stateToComputed,
  dispatchToActions
)(MarkerTermName);

