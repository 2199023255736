import { getElement, getElementsList } from '../selectors/element';
import { getFeature, getFeaturesList } from '../selectors/feature';
import { getTerm, getTermsList } from '../selectors/term';

import FeatureTypes from '../constants/types/features';
import Service from '@ember/service';
import { getElementVersion } from '../selectors/element-version';
import { getMentionsContent } from '../selectors/mention';
import { getPrimaryInstancesList } from '../selectors/component';
import { inject as service } from '@ember/service';
import { textValue } from '../utils/string';
import { tracked } from '@glimmer/tracking';

export default class Search extends Service {
  @service redux;

  @tracked query = '';
  @tracked results = '';
  @tracked library = [];

  build() {
    const state = this.redux.getState();
    const primaryInstancesList = getPrimaryInstancesList(state);
    const library = [];

    // add elements
    getElementsList(state)
      .map((elementId) => getElement(state, elementId))
      .filter((element) => !element.isComponent)
      .forEach((element) => {
        let name = element.name;
        let category = element.category;
        let isInstance = false;
        let isPrimaryInstance = false;
        const instanceOf = element.component && element.instanceOf;
        let hasMultipleVersions = element.elementVersionsList.length > 1;
        if (instanceOf) {
          const instanceOf = getElement(state, element.instanceOf);
          hasMultipleVersions = instanceOf.elementVersionsList.length > 1;
          isInstance = true;
          isPrimaryInstance = primaryInstancesList.includes(element.id);
          name = instanceOf.name;
          category = instanceOf.category;
        }
        element.elementVersionsList.forEach((elementVersionId) => {
          const elementVersion = getElementVersion(state, elementVersionId);
          const elementVersionName = hasMultipleVersions
            ? elementVersion.name
            : '';

          const item = this.getSearchItem({
            modelType: 'element',
            modelId: element.id,
            text: name,
            elementVersionId,
            elementVersionName,
            isInstance,
            isPrimaryInstance,
            instanceOf,
            category,
          });
          library.push(item);
        });
      });

    // add terms
    getTermsList(state)
      .map((termId) => getTerm(state, termId))
      .forEach((term) => {
        let name = term.name;

        const item = this.getSearchItem({
          modelType: 'term',
          modelId: term.id,
          text: name,
        });
        library.push(item);
      });

    // add terms
    getFeaturesList(state)
      .map((featureId) => getFeature(state, featureId))
      .filter((feature) => feature && feature.type !== 'comprises')
      .filter((feature) => feature.value && typeof feature.value === 'string')
      .forEach((feature) => {
        let value = feature.value;
        value = getMentionsContent(state, value, 'feature', {
          appendOrdinal: false,
          appendElementVersion: false,
          isInput: false,
          isInteractive: false,
          novelFeaturesList: [],
        });

        value = textValue(value);

        const featureType = FeatureTypes[feature.type];
        const featureTypeHint = featureType && featureType.hint;

        const item = this.getSearchItem({
          modelType: 'feature',
          modelId: feature.id,
          text: value,
          featureTypeId: feature.type,
          featureTypeHint,
        });
        library.push(item);
      });

    this.library = library;
  }

  matcher(value = '', text = '') {
    return value.toUpperCase().includes(text.toUpperCase()) ? true : false;
  }

  getSearchItem(data = {}) {
    return {
      modelType: null,
      modelId: null,
      text: '',
      elementVersionId: null,
      elementVersionName: '',
      isInstance: false,
      isPrimaryInstance: false,
      instanceOf: null,
      category: null,
      featureType: null,
      featureTypeHint: null,
      ...data,
    };
  }

  update(query) {
    this.query = query;

    this.results = this.library.filter((result) => {
      return this.matcher(result.text, query);
    });
  }

  clear() {
    this.query = '';
    this.results = [];
  }
}
