import { action, computed } from '@ember/object';

import Component from '@glimmer/component';
import { connect } from 'ember-redux';
import { getFigureNumber } from '../../../selectors/figure';
import { getMarker } from '../../../selectors/marker';
import podNames from 'ember-component-css/pod-names';

const dispatchToActions = {};

const stateToComputed = null;

class ElementVersionContextDrawings extends Component {
  get styleNamespace() {
    return podNames['element-version-context-drawings'];
  }

  get classNames() {
    let classNames = [this.styleNamespace, 'element-version-context-drawings'];
    return classNames.join(' ');
  }

  @computed('args.markersList.[]')
  get firstReferenceId() {
    return this.args.markersList && this.args.markersList[0];
  }

  // eslint-disable-next-line ember/require-computed-property-dependencies
  @computed('firstReferenceId')
  get marker() {
    const state = this.redux.getState();
    return this.firstReferenceId && getMarker(state, this.firstReferenceId);
  }

  @computed('marker.drawing')
  get drawingId() {
    return this.marker && this.marker.drawing;
  }
  
  // eslint-disable-next-line ember/require-computed-property-dependencies
  @computed('drawingId', 'firstReferenceId', 'args.markersList.[]')
  get num() {
    const state = this.redux.getState();
    return getFigureNumber(state, this.drawingId)
  }

  @action
  next() {
    const markersList = [...this.args.markersList];
    const fromIndex = 0;
    const toIndex = 1;
    const ref = markersList[fromIndex];
    markersList.splice(fromIndex, 1);
    markersList.splice(toIndex, 0, ref);

    if (this.args.onUpdateReferencesList) {
      this.args.onUpdateReferencesList([...markersList])
    }
    
  }

  @action
  prev() {
    const markersList = [...this.args.markersList];
    const fromIndex = 0;
    const toIndex = markersList.length - 1;
    const ref = markersList[fromIndex];
    markersList.splice(fromIndex, 1);
    markersList.splice(toIndex, 0, ref);

    if (this.args.onUpdateReferencesList) {
      this.args.onUpdateReferencesList([...markersList])
    }
    
  }
}
export default connect(
  stateToComputed,
  dispatchToActions
)(ElementVersionContextDrawings);
