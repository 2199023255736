/* eslint-disable ember/no-observers */
import Component from '@ember/component';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { alias } from '@ember/object/computed';
import { parseCurve, getHighlightPath } from '../../../utils/drawing';
import { connect } from 'ember-redux';
import { minBy } from 'lodash';
import { getHighlight } from '../../../selectors/highlight';

const dispatchToActions = {};

const stateToComputed = (state, attrs) => ({
  highlight: getHighlight(state, attrs.highlightId)
});

const HighlightPreviewSvg = Component.extend({
  tagName: 'g',
  classNames: ['highlight-preview-svg'],
  classNameBindings: [
    'isMask',
    'isClip',
    'hideHighlight',
    'isNovel'
  ],
  showLabel: false,
  hideHighlight: false,
  index: 0,
  models: service(),
  artboardMaskId: 'artboard-mask',
  isMask: false,
  isClip: false,
  curve: alias('highlight.curve'),
  sequence: computed('index', function() {
    return this.index + 1
  }),
  isNovel: false,

  highlightModel: computed('highlight', 'highlightId', function() {
    return this.highlight && this.models.findOrCreate(
      this.highlightId,
      'highlight',
      this.highlight
    );
  }),

  points: alias('highlightModel.points'),

  pointsArray: computed('points', function() {
    return this.points && this.points.split(' ').map(pointString => {
      const pointArray = pointString.split(',');
      const x = pointArray[0];
      const y = pointArray[1];
      return {x, y};
    })
  }),

  northHighlightPoint: computed('highlightPoints.@each.y', function() {
    return minBy(this.highlightPoints, 'y');
  }),

  line: computed(
    'curve',
    'pointsArray.{@each.x,@each.y}',
    function() {
      return getHighlightPath({
        curve: parseCurve(this.curve),
        pointsArray: this.pointsArray
      });
    }
  ),

});

export default connect(
  stateToComputed,
  dispatchToActions
)(HighlightPreviewSvg);
