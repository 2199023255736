export default {
  attributes: {
    name: {
      type: 'string',
      defaultValue: ''
    },
    description: {
      type: 'string',
      defaultValue: ''
    },
    count: {
      type: 'number',
      defaultValue: 0
    },
    revenue: {
      type: 'number',
      defaultValue: 0
    }
  }
};

