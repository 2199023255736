export const getSelectionHtml = () => {
  let html = '';
  /* istanbul ignore else */
  if (typeof window.getSelection != 'undefined') {
    const sel = window.getSelection();
    if (sel.rangeCount) {
      const container = document.createElement('div');
      for (var i = 0, len = sel.rangeCount; i < len; ++i) {
        container.appendChild(sel.getRangeAt(i).cloneContents());
      }
      html = container.innerHTML;
    }
  } else if (typeof document.selection != 'undefined') {
    if (document.selection.type == 'Text') {
      html = document.selection.createRange().htmlText;
    }
  }

  return html;
};

export const getSelectionRect = () => {
  const selection = window.getSelection();
  const range = selection && selection.rangeCount && selection.getRangeAt && selection.getRangeAt(0);
  const rect = range
    ? range.getBoundingClientRect()
    : {
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        width: 0,
        height: 0
      };

  return rect;
};

// https://stackoverflow.com/questions/37786707/deselect-contenteditable-element
export const removeSelection = () => {
  /* istanbul ignore else */
  if (window.getSelection) {
    /* istanbul ignore else */
    if (window.getSelection().empty) {
      // Chrome
      window.getSelection().empty();

    } else if (window.getSelection().removeAllRanges) {
      // Firefox
      window.getSelection().removeAllRanges();
    }
  } else if (document.selection) {
    // IE?
    document.selection.empty();
  }
};

export const replaceSelection = html => {
  let range;
  /* istanbul ignore else */
  if (window.getSelection && window.getSelection().getRangeAt) {
    range = window.getSelection().getRangeAt(0);
    range.deleteContents();
    const div = document.createElement('div');
    div.innerHTML = html;
    const frag = document.createDocumentFragment();
    let child;
    while ((child = div.firstChild)) {
      frag.appendChild(child);
    }
    range.insertNode(frag);
  } else if (document.selection && document.selection.createRange) {
    range = document.selection.createRange();
    range.pasteHTML(html);
  }
};

// export const getSelectionCharacterOffsetWithin = (element) => {
//     var start = 0;
//     var end = 0;
//     var doc = element.ownerDocument || element.document;
//     var win = doc.defaultView || doc.parentWindow;
//     var sel;
//     if (typeof win.getSelection != "undefined") {
//         sel = win.getSelection();
//         if (sel.rangeCount > 0) {
//             var range = win.getSelection().getRangeAt(0);
//             var preCaretRange = range.cloneRange();
//             preCaretRange.selectNodeContents(element);
//             preCaretRange.setEnd(range.startContainer, range.startOffset);
//             start = preCaretRange.toString().length;
//             preCaretRange.setEnd(range.endContainer, range.endOffset);
//             end = preCaretRange.toString().length;
//         }
//     } else if ( (sel = doc.selection) && sel.type != "Control") {
//         var textRange = sel.createRange();
//         var preCaretTextRange = doc.body.createTextRange();
//         preCaretTextRange.moveToElementText(element);
//         preCaretTextRange.setEndPoint("EndToStart", textRange);
//         start = preCaretTextRange.text.length;
//         preCaretTextRange.setEndPoint("EndToEnd", textRange);
//         end = preCaretTextRange.text.length;
//     }
//     return { start: start, end: end };
// }

// export const replaceRangeInString = (domElement, string = '', html) => {
// 	const { start, end } = getSelectionCharacterOffsetWithin(domElement);
//   return string.substring(0, start) + html + string.substring(end)
// };

// const isOrContains = (node, container) => {
//   while (node) {
//     if (node === container) {
//       return true;
//     }
//     node = node.parentNode;
//   }
//   return false;
// };

// https://stackoverflow.com/questions/8339857/how-to-know-if-selected-text-is-inside-a-specific-div
// export const elementContainsSelection = el => {
//   let sel;
//   if (window.getSelection) {
//     sel = window.getSelection();
//     if (sel.rangeCount > 0) {
//       for (let i = 0; i < sel.rangeCount; ++i) {
//         if (!isOrContains(sel.getRangeAt(i).commonAncestorContainer, el)) {
//           return false;
//         }
//       }
//       return true;
//     }
//   } else if ((sel = document.selection) && sel.type != 'Control') {
//     return isOrContains(sel.createRange().parentElement(), el);
//   }
//   return false;
// };
