import { action, computed } from '@ember/object';

import Component from '@glimmer/component';
import { connect } from 'ember-redux';
import { getComparison } from '../../../selectors/comparison';
import { getPriorArtsList } from '../../../selectors/prior-art';
import { getProduct } from '../../../selectors/product';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';

const dispatchToActions = {};

const stateToComputed = (state, attrs) => ({
  priorArtsList: getPriorArtsList(state),
  product: getProduct(state, attrs.productId),
});

class DrawingSelectionModal extends Component {
  @service tracking;
  @service redux;

  get styleNamespace() {
    return podNames['prior-arts-selection-modal'];
  }

  get classNames() {
    let classNames = [this.styleNamespace, 'prior-arts-selection-modal'];
    return classNames.join(' ');
  }

  @computed('product.comparisonsList.[]')
  get comparedPriorArtsList() {
    const state = this.redux.getState();
    return (
      this.product &&
      this.product.comparisonsList &&
      this.product.comparisonsList.map((comparisonId) => {
        const comparison = getComparison(state, comparisonId);
        return comparison && comparison.priorArt;
      })
    );
  }

  @action
  didInsert() {
    this.tracking.trackEvent('opened_prior_arts_selection_modal');
  }

  @action
  hideModal() {
    if (this.args.onHide) {
      this.args.onHide(false);
    }
  }

  @action
  onSetShowingPriorArts() {
    this.args.onSetShowingPriorArts(true);
  }

  @action
  onPriorArtItemClick(priorArtId) {
    console.log('priorArtId', priorArtId)
    const isCompared =
      this.comparedPriorArtsList &&
      this.comparedPriorArtsList.includes(priorArtId);
    if (!isCompared) {
      this.args.onAddComparison({
        product: this.args.productId,
        priorArt: priorArtId,
      });
    }
  }
}

export default connect(
  stateToComputed,
  dispatchToActions
)(DrawingSelectionModal);
