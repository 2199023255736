export default {
  attributes: {
    id: {
      type: 'uuid',
      defaultValue: null
    },
    name: {
      type: 'string',
      defaultValue: ''
    },
    externalUrl: {
      type: 'string',
      defaultValue: ''
    },
    fileType: {
      type: 'string',
      defaultValue: ''
    },
    width: {
      type: 'number',
      defaultValue: 0
    },
    height: {
      type: 'number',
      defaultValue: 0
    },
    createdAt: {
      type: 'date'
    },
    updatedAt: {
      type: 'date'
    }
  }
}
