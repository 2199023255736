import Component from '@glimmer/component';
import { action } from '@ember/object';
import { connect } from 'ember-redux';
import { getHighlight } from '../../../selectors/highlight';
import podNames from 'ember-component-css/pod-names';

const stateToComputed = (state, attrs) => ({
  highlight: getHighlight(state, attrs.highlightId),
});

const dispatchToActions = {};

class HighlightListItem extends Component {
  get styleNamespace() {
    return podNames['highlight-list-item'];
  }

  get classNames() {
    let classNames = [this.styleNamespace, 'highlight-list-item'];
    return classNames.join(' ');
  }

  @action
  onClick() {
    if (this.args.onClick) {
      this.args.onClick(this.args.imageId);
    }
  }
}

export default connect(stateToComputed, dispatchToActions)(HighlightListItem);
