import Component from '@glimmer/component';
import podNames from 'ember-component-css/pod-names';

export default class ProductViewHeader extends Component {

  get styleNamespace() {
    return podNames['product-view-header'];
  }

  get classNames() {
    let classNames = [this.styleNamespace, 'product-view-header'];
    return classNames.join(' ');
  }
}
