import { BLACK_AND_WHITE_STROKE, HIGHLIGHT_FILL, HIGHLIGHT_STROKE } from '../../../constants/colors';

import Component from '@glimmer/component';
import Konva from 'konva';
import { action } from '@ember/object';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';

class DrawingHighlightCreatePointKonva extends Component {
  @service settings;
  constructor(owner, args) {
    super(owner, args);
    this.onScheduleRender = this.args.onScheduleRender;
  }

  @action
  didInsert() {
    this.setup();
  }

  willDestroy() {
    this.pointNode.off('click');
    this.pointNode.destroy();
    this.onScheduleRender();
  }

  get styleNamespace() {
    return podNames['drawing-highlight-create-point-konva'];
  }

  get classNames() {
    let classNames = [this.styleNamespace];
    return classNames.join(' ');
  }

  get strokeColor() {
    let color = HIGHLIGHT_STROKE;
    if (this.settings.blackAndWhiteMode) {
      color = BLACK_AND_WHITE_STROKE;
    }
    return color;
  }

  get fillColor() {
    let color = HIGHLIGHT_FILL;
    if (this.settings.blackAndWhiteMode) {
      color = 'transparent';
    }
    return color;
  }

  get isStartPoint() {
    return !this.args.index;
  }

  setup() {
    const pointNode = new Konva.Circle({
      id: this.isStartPoint ? 'highlight-start-point' : `highlight-point-${this.args.index}`,
      name: 'highlight-point',
      x: this.args.x,
      y: this.args.y,
      transformsEnabled: 'position',
      fill: this.fillColor,
      stroke: this.strokeColor,
      strokeWidth: 2,
      radius: this.isStartPoint ? 12 : 5,
    });

    // add events
    pointNode.on('click', () => {
      if (this.args.onClick && this.isStartPoint)
        this.args.onClick({ x: this.args.x, y: this.args.y });
    });

    this.args.layer.add(pointNode);
    this.pointNode = pointNode;

    this.onScheduleRender();
  }
}

export default DrawingHighlightCreatePointKonva;
