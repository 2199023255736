import Component from '@glimmer/component';
import { computed } from '@ember/object';
import { connect } from 'ember-redux';
import { getActiveProductId } from '../../../selectors/invention-ui';
import { getElement } from '../../../selectors/element';
import { getMethodNode } from '../../../selectors/method-node';
import { getPreferredElementVersion } from '../../../selectors/product';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

const stateToComputed = (state, attrs) => ({
  methodNode: getMethodNode(state, attrs.methodNodeId),
  viaMethodNode: getMethodNode(state, attrs.viaMethodNodeId),
  activeProductId: getActiveProductId(state)
});

const dispatchToActions = {};

class MethodPresentationStep extends Component {
  @service redux;
  @service tracking;
  @tracked isOverflowed = false;

  get styleNamespace() {
    return podNames['method-presentation-step'];
  }

  get classNames() {
    let classNames = [this.styleNamespace];
    return classNames.join(' ');
  }

  @computed('methodNode.element')
  get element() {
    const state = this.redux.getState();
    return (
      this.methodNode && getElement(state, this.methodNode.element)
    );
  }

  @computed('element.id', 'activeProductId')
  get elementVersion() {
    const state = this.redux.getState();
    return this.element && getPreferredElementVersion(state, this.element.id, this.activeProductId)
  }

  @computed('element.id')
  get elementName() {
    return this.element && `<mention class="mention" data-type="element" data-id="${this.element.id}"></mention>`;
  }

  @computed(
    'elementVersion.markersList'
  )
  get referencesList() {
    return this.elementVersion && this.elementVersion.markersList;
  }
}

export default connect(stateToComputed, dispatchToActions)(MethodPresentationStep);
