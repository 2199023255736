// https://stackoverflow.com/questions/63893601/how-can-i-do-a-threshold-effect-with-canvas
export const ThresholdFilter = function (
  imageData,
  light = [255, 255, 255],
  dark = [0, 0, 0]
) {
  const threshold = (this && this.threshold()) || 100;
  let d = imageData.data,
    i = 0,
    l = d.length;
  while (l-- > 0) {
    const v = d[i] * 0.2126 + d[i + 1] * 0.7152 + d[i + 2] * 0.0722;
    [d[i], d[i + 1], d[i + 2]] = v >= threshold ? light : dark;
    i += 4;
  }
  return imageData;
};
