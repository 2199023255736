export default [
  {
    "name": "patent_specification_preferred_version / absent / 1",
    "conditions": {
      "type": {
        "is": "absent"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "patent_specification_preferred_version"
      },
      "absent_count": {
        "equal": 1
      }
    },
    "event": {
      "template": "In some embodiments, if the {{{element}}} is absent then {{{absent_0}}}.",
      "required": [
        "element",
        "absent_0"
      ]
    }
  },
  {
    "name": "patent_specification_preferred_version / absent / 2",
    "conditions": {
      "type": {
        "is": "absent"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "patent_specification_preferred_version"
      },
      "absent_count": {
        "equal": 2
      }
    },
    "event": {
      "template": "In some embodiments, if the {{{element}}} is absent then {{{absent_0}}} or {{{absent_1}}}.",
      "required": [
        "element",
        "absent_0",
        "absent_1"
      ]
    }
  },
  {
    "name": "patent_specification_preferred_version / absent / 2+",
    "conditions": {
      "type": {
        "is": "absent"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "patent_specification_preferred_version"
      },
      "absent_count": {
        "greater": 2
      }
    },
    "event": {
      "template": "In some embodiments, if the {{{element}}} is absent then {{#each absent_collection as |absent index|}}{{#if @first}}{{{absent}}}{{else if @last}} or {{{absent}}}{{else}}, {{{absent}}}{{/if}}{{/each}}.",
      "required": [
        "element",
        "absent_collection"
      ]
    }
  },
  {
    "name": "report_list / absent / 1",
    "conditions": {
      "type": {
        "is": "absent"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "report_list"
      },
      "absent_count": {
        "equal": 1
      }
    },
    "event": {
      "template": "If the {{{element}}} is absent then {{{absent_0}}}.",
      "required": [
        "element",
        "absent_0"
      ]
    }
  },
  {
    "name": "report_list / absent / 2",
    "conditions": {
      "type": {
        "is": "absent"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "report_list"
      },
      "absent_count": {
        "equal": 2
      }
    },
    "event": {
      "template": "If the {{{element}}} is absent then {{{absent_0}}} and {{{absent_1}}}.",
      "required": [
        "element",
        "absent_0",
        "absent_1"
      ]
    }
  },
  {
    "name": "report_list / absent / 2+",
    "conditions": {
      "type": {
        "is": "absent"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "report_list"
      },
      "absent_count": {
        "greater": 2
      }
    },
    "event": {
      "template": "If the {{{element}}} is absent then {{#each absent_collection as |absent index|}}{{#if @first}}{{{absent}}}{{else if @last}} and {{{absent}}}{{else}}, {{{absent}}}{{/if}}{{/each}}.",
      "required": [
        "element",
        "absent_collection"
      ]
    }
  },
  {
    "name": "report_list_abbreviated / absent / 1",
    "conditions": {
      "type": {
        "is": "absent"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "report_list_abbreviated"
      },
      "absent_count": {
        "equal": 1
      }
    },
    "event": {
      "template": "if the {{{element}}} is absent then {{{absent_0}}}.",
      "required": [
        "element",
        "absent_0"
      ]
    }
  },
  {
    "name": "report_list_abbreviated / absent / 2",
    "conditions": {
      "type": {
        "is": "absent"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "report_list_abbreviated"
      },
      "absent_count": {
        "equal": 2
      }
    },
    "event": {
      "template": "if the {{{element}}} is absent then {{{absent_0}}} and {{{absent_1}}}.",
      "required": [
        "element",
        "absent_0",
        "absent_1"
      ]
    }
  },
  {
    "name": "report_list_abbreviated / absent / 2+",
    "conditions": {
      "type": {
        "is": "absent"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "report_list_abbreviated"
      },
      "absent_count": {
        "greater": 2
      }
    },
    "event": {
      "template": "if the {{{element}}} is absent then {{#each absent_collection as |absent index|}}{{#if @first}}{{{absent}}}{{else if @last}} and {{{absent}}}{{else}}, {{{absent}}}{{/if}}{{/each}}.",
      "required": [
        "element",
        "absent_collection"
      ]
    }
  },
  {
    "name": "requirement / absent / 1",
    "conditions": {
      "type": {
        "is": "absent"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "requirement"
      },
      "absent_count": {
        "equal": 1
      }
    },
    "event": {
      "template": "",
      "required": [
        "element",
        "absent_0"
      ]
    }
  },
  {
    "name": "requirement_abbreviated / absent / 1",
    "conditions": {
      "type": {
        "is": "absent"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "requirement_abbreviated"
      },
      "absent_count": {
        "equal": 1
      }
    },
    "event": {
      "template": "",
      "required": [
        "absent_0"
      ]
    }
  },
  {
    "name": "patent_specification_preferred_version / analogs / 1",
    "conditions": {
      "type": {
        "is": "analogs"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "patent_specification_preferred_version"
      },
      "analogs_count": {
        "equal": 1
      }
    },
    "event": {
      "template": "Examples of the {{{element}}} may include {{{analogs_0}}}.",
      "required": [
        "element",
        "analogs_0"
      ]
    }
  },
  {
    "name": "patent_specification_preferred_version / analogs / 2",
    "conditions": {
      "type": {
        "is": "analogs"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "patent_specification_preferred_version"
      },
      "analogs_count": {
        "equal": 2
      }
    },
    "event": {
      "template": "Examples of the {{{element}}} may include {{{analogs_0}}} and {{{analogs_1}}}.",
      "required": [
        "element",
        "analogs_0",
        "analogs_1"
      ]
    }
  },
  {
    "name": "patent_specification_preferred_version / analogs / 2+",
    "conditions": {
      "type": {
        "is": "analogs"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "patent_specification_preferred_version"
      },
      "analogs_count": {
        "greater": 2
      }
    },
    "event": {
      "template": "Examples of the {{{element}}} may include {{#each analogs_collection as |analog index|}}{{#if @first}}{{{analog}}}{{else if @last}} and {{{analog}}}{{else}}, {{{analog}}}{{/if}}{{/each}}.",
      "required": [
        "element",
        "analogs_collection"
      ]
    }
  },
  {
    "name": "report_list / analogs / 1",
    "conditions": {
      "type": {
        "is": "analogs"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "report_list"
      },
      "analogs_count": {
        "equal": 1
      }
    },
    "event": {
      "template": "Examples of {{{element}}} include {{{analogs_0}}}.",
      "required": [
        "element",
        "analogs_0"
      ]
    }
  },
  {
    "name": "report_list / analogs / 2",
    "conditions": {
      "type": {
        "is": "analogs"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "report_list"
      },
      "analogs_count": {
        "equal": 2
      }
    },
    "event": {
      "template": "Examples of {{{element}}} include {{{analogs_0}}} and {{{analogs_1}}}.",
      "required": [
        "element",
        "analogs_0",
        "analogs_1"
      ]
    }
  },
  {
    "name": "report_list / analogs / 2+",
    "conditions": {
      "type": {
        "is": "analogs"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "report_list"
      },
      "analogs_count": {
        "greater": 2
      }
    },
    "event": {
      "template": "Examples of {{{element}}} include {{#each analogs_collection as |analog index|}}{{#if @first}}{{{analog}}}{{else if @last}} and {{{analog}}}{{else}}, {{{analog}}}{{/if}}{{/each}}.",
      "required": [
        "element",
        "analogs_collection"
      ]
    }
  },
  {
    "name": "report_list_abbreviated / analogs / 1",
    "conditions": {
      "type": {
        "is": "analogs"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "report_list_abbreviated"
      },
      "analogs_count": {
        "equal": 1
      }
    },
    "event": {
      "template": "Examples include {{{analogs_0}}}.",
      "required": [
        "element",
        "analogs_0"
      ]
    }
  },
  {
    "name": "report_list_abbreviated / analogs / 2",
    "conditions": {
      "type": {
        "is": "analogs"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "report_list_abbreviated"
      },
      "analogs_count": {
        "equal": 2
      }
    },
    "event": {
      "template": "Examples include {{{analogs_0}}} and {{{analogs_1}}}.",
      "required": [
        "element",
        "analogs_0",
        "analogs_1"
      ]
    }
  },
  {
    "name": "report_list_abbreviated / analogs / 2+",
    "conditions": {
      "type": {
        "is": "analogs"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "report_list_abbreviated"
      },
      "analogs_count": {
        "greater": 2
      }
    },
    "event": {
      "template": "Examples include {{#each analogs_collection as |analog index|}}{{#if @first}}{{{analog}}}{{else if @last}} and {{{analog}}}{{else}}, {{{analog}}}{{/if}}{{/each}}.",
      "required": [
        "element",
        "analogs_collection"
      ]
    }
  },
  {
    "name": "requirement / analogs / 1",
    "conditions": {
      "type": {
        "is": "analogs"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "requirement"
      },
      "analogs_count": {
        "equal": 1
      }
    },
    "event": {
      "template": "{{{element}}} must be interchangable with {{{analogs_0}}}.",
      "required": [
        "element",
        "analogs_0"
      ]
    }
  },
  {
    "name": "requirement_abbreviated / analogs / 1",
    "conditions": {
      "type": {
        "is": "analogs"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "requirement_abbreviated"
      },
      "analogs_count": {
        "equal": 1
      }
    },
    "event": {
      "template": "must be interchangable with {{{analogs_0}}}",
      "required": [
        "analogs_0"
      ]
    }
  },
  {
    "name": "patent_specification_preferred_version / attachment / 1",
    "conditions": {
      "type": {
        "is": "attachment"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "patent_specification_preferred_version"
      },
      "attachment_count": {
        "equal": 1
      }
    },
    "event": {
      "template": "In some embodiments, the {{{element}}} may be coupled with {{{attachment_0}}}.",
      "required": [
        "element",
        "attachment_0"
      ]
    }
  },
  {
    "name": "patent_specification_preferred_version / attachment / 2",
    "conditions": {
      "type": {
        "is": "attachment"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "patent_specification_preferred_version"
      },
      "attachment_count": {
        "equal": 2
      }
    },
    "event": {
      "template": "In some embodiments, the {{{element}}} may be coupled with {{{attachment_0}}} or {{{attachment_1}}}.",
      "required": [
        "element",
        "attachment_0",
        "attachment_1"
      ]
    }
  },
  {
    "name": "patent_specification_preferred_version / attachment / 2+",
    "conditions": {
      "type": {
        "is": "attachment"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "patent_specification_preferred_version"
      },
      "attachment_count": {
        "greater": 2
      }
    },
    "event": {
      "template": "In some embodiments, the {{{element}}} may be coupled with {{#each attachment_collection as |attachment index|}}{{#if @first}}{{{attachment}}}{{else if @last}} or {{{attachment}}}{{else}}, {{{attachment}}}{{/if}}{{/each}}.",
      "required": [
        "element",
        "attachment_collection"
      ]
    }
  },
  {
    "name": "report_list / attachment / 1",
    "conditions": {
      "type": {
        "is": "attachment"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "report_list"
      },
      "attachment_count": {
        "equal": 1
      }
    },
    "event": {
      "template": "The {{{element}}} is attached to {{{attachment_0}}}.",
      "required": [
        "element",
        "attachment_0"
      ]
    }
  },
  {
    "name": "report_list / attachment / 2",
    "conditions": {
      "type": {
        "is": "attachment"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "report_list"
      },
      "attachment_count": {
        "equal": 2
      }
    },
    "event": {
      "template": "The {{{element}}} is attached to {{{attachment_0}}} and {{{attachment_1}}}.",
      "required": [
        "element",
        "attachment_0",
        "attachment_1"
      ]
    }
  },
  {
    "name": "report_list / attachment / 2+",
    "conditions": {
      "type": {
        "is": "attachment"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "report_list"
      },
      "attachment_count": {
        "greater": 2
      }
    },
    "event": {
      "template": "The {{{element}}} is attached to {{#each attachment_collection as |attachment index|}}{{#if @first}}{{{attachment}}}{{else if @last}} and {{{attachment}}}{{else}}, {{{attachment}}}{{/if}}{{/each}}.",
      "required": [
        "element",
        "attachment_collection"
      ]
    }
  },
  {
    "name": "report_list_abbreviated / attachment / 1",
    "conditions": {
      "type": {
        "is": "attachment"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "report_list_abbreviated"
      },
      "attachment_count": {
        "equal": 1
      }
    },
    "event": {
      "template": "Is attached to {{{attachment_0}}}.",
      "required": [
        "element",
        "attachment_0"
      ]
    }
  },
  {
    "name": "report_list_abbreviated / attachment / 2",
    "conditions": {
      "type": {
        "is": "attachment"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "report_list_abbreviated"
      },
      "attachment_count": {
        "equal": 2
      }
    },
    "event": {
      "template": "Is attached to {{{attachment_0}}} and {{{attachment_1}}}.",
      "required": [
        "element",
        "attachment_0",
        "attachment_1"
      ]
    }
  },
  {
    "name": "report_list_abbreviated / attachment / 2+",
    "conditions": {
      "type": {
        "is": "attachment"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "report_list_abbreviated"
      },
      "attachment_count": {
        "greater": 2
      }
    },
    "event": {
      "template": "Is attached to {{#each attachment_collection as |attachment index|}}{{#if @first}}{{{attachment}}}{{else if @last}} and {{{attachment}}}{{else}}, {{{attachment}}}{{/if}}{{/each}}.",
      "required": [
        "element",
        "attachment_collection"
      ]
    }
  },
  {
    "name": "requirement / attachment / 1",
    "conditions": {
      "type": {
        "is": "attachment"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "requirement"
      },
      "attachment_count": {
        "equal": 1
      }
    },
    "event": {
      "template": "The {{{element}}} must attach to {{{attachment_0}}}.",
      "required": [
        "element",
        "attachment_0"
      ]
    }
  },
  {
    "name": "requirement_abbreviated / attachment / 1",
    "conditions": {
      "type": {
        "is": "attachment"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "requirement_abbreviated"
      },
      "attachment_count": {
        "equal": 1
      }
    },
    "event": {
      "template": "must attach to {{{attachment_0}}}",
      "required": [
        "attachment_0"
      ]
    }
  },
  {
    "name": "patent_specification_preferred_version / comprises / 1",
    "conditions": {
      "type": {
        "is": "comprises"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "patent_specification_preferred_version"
      },
      "comprises_count": {
        "equal": 1
      }
    },
    "event": {
      "template": "In some embodiments, the {{{element}}} may include {{{comprises_0}}}.",
      "required": [
        "element",
        "comprises_0"
      ]
    }
  },
  {
    "name": "patent_specification_preferred_version / comprises / 2",
    "conditions": {
      "type": {
        "is": "comprises"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "patent_specification_preferred_version"
      },
      "comprises_count": {
        "equal": 2
      }
    },
    "event": {
      "template": "In some embodiments, the {{{element}}} may include {{{comprises_0}}} and {{{comprises_1}}}.",
      "required": [
        "element",
        "comprises_0",
        "comprises_1"
      ]
    }
  },
  {
    "name": "patent_specification_preferred_version / comprises / 2+",
    "conditions": {
      "type": {
        "is": "comprises"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "patent_specification_preferred_version"
      },
      "comprises_count": {
        "greater": 2
      }
    },
    "event": {
      "template": "In some embodiments, the {{{element}}} may include {{#each comprises_collection as |comprises index|}}{{#if @first}}{{{comprises}}}{{else if @last}}; and {{{comprises}}}{{else}}; {{{comprises}}}{{/if}}{{/each}}.",
      "required": [
        "element",
        "comprises_collection"
      ]
    }
  },
  {
    "name": "report_list / comprises / 1",
    "conditions": {
      "type": {
        "is": "comprises"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "report_list"
      },
      "comprises_count": {
        "equal": 1
      }
    },
    "event": {
      "template": "The {{{element}}} includes {{{comprises_0}}}.",
      "required": [
        "element",
        "comprises_0"
      ]
    }
  },
  {
    "name": "report_list / comprises / 2",
    "conditions": {
      "type": {
        "is": "comprises"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "report_list"
      },
      "comprises_count": {
        "equal": 2
      }
    },
    "event": {
      "template": "The {{{element}}} includes {{{comprises_0}}} and {{{comprises_1}}}.",
      "required": [
        "element",
        "comprises_0",
        "comprises_1"
      ]
    }
  },
  {
    "name": "report_list / comprises / 2+",
    "conditions": {
      "type": {
        "is": "comprises"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "report_list"
      },
      "comprises_count": {
        "greater": 2
      }
    },
    "event": {
      "template": "The {{{element}}} includes {{#each comprises_collection as |comprises index|}}{{#if @first}}{{{comprises}}}{{else if @last}} and {{{comprises}}}{{else}}, {{{comprises}}}{{/if}}{{/each}}.",
      "required": [
        "element",
        "comprises_collection"
      ]
    }
  },
  {
    "name": "report_list_abbreviated / comprises / 1",
    "conditions": {
      "type": {
        "is": "comprises"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "report_list_abbreviated"
      },
      "comprises_count": {
        "equal": 1
      }
    },
    "event": {
      "template": "Includes {{{comprises_0}}}.",
      "required": [
        "element",
        "comprises_0"
      ]
    }
  },
  {
    "name": "report_list_abbreviated / comprises / 2",
    "conditions": {
      "type": {
        "is": "comprises"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "report_list_abbreviated"
      },
      "comprises_count": {
        "equal": 2
      }
    },
    "event": {
      "template": "Includes {{{comprises_0}}} and {{{comprises_1}}}.",
      "required": [
        "element",
        "comprises_0",
        "comprises_1"
      ]
    }
  },
  {
    "name": "report_list_abbreviated / comprises / 2+",
    "conditions": {
      "type": {
        "is": "comprises"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "report_list_abbreviated"
      },
      "comprises_count": {
        "greater": 2
      }
    },
    "event": {
      "template": "Includes {{#each comprises_collection as |comprises index|}}{{#if @first}}{{{comprises}}}{{else if @last}} and {{{comprises}}}{{else}}, {{{comprises}}}{{/if}}{{/each}}.",
      "required": [
        "element",
        "comprises_collection"
      ]
    }
  },
  {
    "name": "requirement / comprises / 1",
    "conditions": {
      "type": {
        "is": "comprises"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "requirement"
      },
      "comprises_count": {
        "equal": 1
      }
    },
    "event": {
      "template": "The {{{element}}} must have the subsystem {{{comprises_0}}}.",
      "required": [
        "element",
        "comprises_0"
      ]
    }
  },
  {
    "name": "requirement_abbreviated / comprises / 1",
    "conditions": {
      "type": {
        "is": "comprises"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "requirement_abbreviated"
      },
      "comprises_count": {
        "equal": 1
      }
    },
    "event": {
      "template": "must have the subsystem {{{comprises_0}}}",
      "required": [
        "comprises_0"
      ]
    }
  },
  {
    "name": "patent_specification_preferred_version / constraint / 1",
    "conditions": {
      "type": {
        "is": "constraint"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "patent_specification_preferred_version"
      },
      "constraint_count": {
        "equal": 1
      }
    },
    "event": {
      "template": "In some embodiments, the {{{element}}} may be configured to be {{{constraint_0}}}.",
      "required": [
        "element",
        "constraint_0"
      ]
    }
  },
  {
    "name": "patent_specification_preferred_version / constraint / 2",
    "conditions": {
      "type": {
        "is": "constraint"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "patent_specification_preferred_version"
      },
      "constraint_count": {
        "equal": 2
      }
    },
    "event": {
      "template": "In some embodiments, the {{{element}}} may be configured to be {{{constraint_0}}} and {{{constraint_1}}}.",
      "required": [
        "element",
        "constraint_0",
        "constraint_1"
      ]
    }
  },
  {
    "name": "patent_specification_preferred_version / constraint / 2+",
    "conditions": {
      "type": {
        "is": "constraint"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "patent_specification_preferred_version"
      },
      "constraint_count": {
        "greater": 2
      }
    },
    "event": {
      "template": "In some embodiments, the {{{element}}} may be configured to be {{#each constraint_collection as | constraint index|}}{{#if @first}}{{{constraint}}}{{else if @last}} and {{{constraint}}}{{else}}, {{{constraint}}}{{/if}}{{/each}}.",
      "required": [
        "element",
        "constraint_collection"
      ]
    }
  },
  {
    "name": "report_list / constraint / 1",
    "conditions": {
      "type": {
        "is": "constraint"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "report_list"
      },
      "constraint_count": {
        "equal": 1
      }
    },
    "event": {
      "template": "The {{{element}}} is configured to be {{{constraint_0}}}.",
      "required": [
        "element",
        "constraint_0"
      ]
    }
  },
  {
    "name": "report_list / constraint / 2",
    "conditions": {
      "type": {
        "is": "constraint"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "report_list"
      },
      "constraint_count": {
        "equal": 2
      }
    },
    "event": {
      "template": "The {{{element}}} is configured to be {{{constraint_0}}} and {{{constraint_1}}}.",
      "required": [
        "element",
        "constraint_0",
        "constraint_1"
      ]
    }
  },
  {
    "name": "report_list / constraint / 2+",
    "conditions": {
      "type": {
        "is": "constraint"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "report_list"
      },
      "constraint_count": {
        "greater": 2
      }
    },
    "event": {
      "template": "The {{{element}}} is configured to be {{#each constraint_collection as |constraint index|}}{{#if @first}}{{{constraint}}}{{else if @last}} and {{{constraint}}}{{else}}, {{{constraint}}}{{/if}}{{/each}}.",
      "required": [
        "element",
        "constraint_collection"
      ]
    }
  },
  {
    "name": "report_list_abbreviated / constraint / 1",
    "conditions": {
      "type": {
        "is": "constraint"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "report_list_abbreviated"
      },
      "constraint_count": {
        "equal": 1
      }
    },
    "event": {
      "template": "Configured to be {{{constraint_0}}}.",
      "required": [
        "element",
        "constraint_0"
      ]
    }
  },
  {
    "name": "report_list_abbreviated / constraint / 2",
    "conditions": {
      "type": {
        "is": "constraint"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "report_list_abbreviated"
      },
      "constraint_count": {
        "equal": 2
      }
    },
    "event": {
      "template": "Configured to be {{{constraint_0}}} and {{{constraint_1}}}.",
      "required": [
        "element",
        "constraint_0",
        "constraint_1"
      ]
    }
  },
  {
    "name": "report_list_abbreviated / constraint / 2+",
    "conditions": {
      "type": {
        "is": "constraint"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "report_list_abbreviated"
      },
      "constraint_count": {
        "greater": 2
      }
    },
    "event": {
      "template": "Configured to be {{#each constraint_collection as |constraint index|}}{{#if @first}}{{{constraint}}}{{else if @last}} and {{{constraint}}}{{else}}, {{{constraint}}}{{/if}}{{/each}}.",
      "required": [
        "element",
        "constraint_collection"
      ]
    }
  },
  {
    "name": "requirement / constraint / 1",
    "conditions": {
      "type": {
        "is": "constraint"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "requirement"
      },
      "constraint_count": {
        "equal": 1
      }
    },
    "event": {
      "template": "The {{{element}}} must be {{{constraint_0}}}.",
      "required": [
        "element",
        "constraint_0"
      ]
    }
  },
  {
    "name": "requirement_abbreviated / constraint / 1",
    "conditions": {
      "type": {
        "is": "constraint"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "requirement_abbreviated"
      },
      "constraint_count": {
        "equal": 1
      }
    },
    "event": {
      "template": "Must be {{{constraint_0}}}",
      "required": [
        "constraint_0"
      ]
    }
  },
  {
    "name": "patent_specification_preferred_version / custom_step / 1",
    "conditions": {
      "type": {
        "is": "custom_step"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "patent_specification_preferred_version"
      },
      "custom_step_count": {
        "equal": 1
      }
    },
    "event": {
      "template": "{{{custom_step_0}}}",
      "required": [
        "custom_step_0"
      ]
    }
  },
  {
    "name": "report_list / custom_step / 1",
    "conditions": {
      "type": {
        "is": "custom_step"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "report_list"
      },
      "custom_step_count": {
        "equal": 1
      }
    },
    "event": {
      "template": "{{{custom_step_0}}}",
      "required": [
        "custom_step_0"
      ]
    }
  },
  {
    "name": "report_list_abbreviated / custom_step / 1",
    "conditions": {
      "type": {
        "is": "custom_step"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "report_list_abbreviated"
      },
      "custom_step_count": {
        "equal": 1
      }
    },
    "event": {
      "template": "{{{custom_step_0}}}",
      "required": [
        "custom_step_0"
      ]
    }
  },
  {
    "name": "requirement / custom_step / 1",
    "conditions": {
      "type": {
        "is": "custom_step"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "requirement"
      },
      "custom_step_count": {
        "equal": 1
      }
    },
    "event": {
      "template": "The {{{element}}} must have the step \"{{{custom_step_0}}}.\"",
      "required": [
        "element",
        "custom_step_0"
      ]
    }
  },
  {
    "name": "requirement_abbreviated / custom_step / 1",
    "conditions": {
      "type": {
        "is": "custom_step"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "requirement_abbreviated"
      },
      "custom_step_count": {
        "equal": 1
      }
    },
    "event": {
      "template": "must have the step \"{{{custom_step_0}}}\"",
      "required": [
        "custom_step_0"
      ]
    }
  },
  {
    "name": "patent_specification_preferred_version / definition / 1",
    "conditions": {
      "type": {
        "is": "definition"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "patent_specification_preferred_version"
      },
      "definition_count": {
        "equal": 1
      }
    },
    "event": {
      "template": "{{{cap element}}} may be defined as {{{definition_0}}}.",
      "required": [
        "element",
        "definition_0"
      ]
    }
  },
  {
    "name": "patent_specification_preferred_version / definition / 2",
    "conditions": {
      "type": {
        "is": "definition"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "patent_specification_preferred_version"
      },
      "definition_count": {
        "equal": 2
      }
    },
    "event": {
      "template": "{{{cap element}}} may be defined as {{{definition_0}}} or {{{definition_1}}}.",
      "required": [
        "element",
        "definition_0",
        "definition_1"
      ]
    }
  },
  {
    "name": "patent_specification_preferred_version / definition / 2+",
    "conditions": {
      "type": {
        "is": "definition"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "patent_specification_preferred_version"
      },
      "definition_count": {
        "greater": 2
      }
    },
    "event": {
      "template": "{{{cap element}}} may be defined as {{#each definition_collection as |definition index|}}{{#if @first}}{{{definition}}}{{else if @last}} or {{{definition}}}{{else}}, {{{definition}}}{{/if}}{{/each}}.",
      "required": [
        "element",
        "definition_collection"
      ]
    }
  },
  {
    "name": "report_list / definition / 1",
    "conditions": {
      "type": {
        "is": "definition"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "report_list"
      },
      "definition_count": {
        "equal": 1
      }
    },
    "event": {
      "template": "{{{element}}} is defined as {{{definition_0}}}.",
      "required": [
        "element",
        "definition_0"
      ]
    }
  },
  {
    "name": "report_list / definition / 2",
    "conditions": {
      "type": {
        "is": "definition"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "report_list"
      },
      "definition_count": {
        "equal": 2
      }
    },
    "event": {
      "template": "{{{element}}} is defined as {{{definition_0}}} or {{{definition_1}}}.",
      "required": [
        "element",
        "definition_0",
        "definition_1"
      ]
    }
  },
  {
    "name": "report_list / definition / 2+",
    "conditions": {
      "type": {
        "is": "definition"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "report_list"
      },
      "definition_count": {
        "greater": 2
      }
    },
    "event": {
      "template": "{{{element}}} is defined as {{#each definition_collection as |definition index|}}{{#if @first}}{{{definition}}}{{else if @last}} or {{{definition}}}{{else}}, {{{definition}}}{{/if}}{{/each}}.",
      "required": [
        "element",
        "definition_collection"
      ]
    }
  },
  {
    "name": "report_list_abbreviated / definition / 1",
    "conditions": {
      "type": {
        "is": "definition"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "report_list_abbreviated"
      },
      "definition_count": {
        "equal": 1
      }
    },
    "event": {
      "template": "Defined as {{{definition_0}}}.",
      "required": [
        "element",
        "definition_0"
      ]
    }
  },
  {
    "name": "report_list_abbreviated / definition / 2",
    "conditions": {
      "type": {
        "is": "definition"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "report_list_abbreviated"
      },
      "definition_count": {
        "equal": 2
      }
    },
    "event": {
      "template": "Defined as {{{definition_0}}} or {{{definition_1}}}.",
      "required": [
        "element",
        "definition_0",
        "definition_1"
      ]
    }
  },
  {
    "name": "report_list_abbreviated / definition / 2+",
    "conditions": {
      "type": {
        "is": "definition"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "report_list_abbreviated"
      },
      "definition_count": {
        "greater": 2
      }
    },
    "event": {
      "template": "Defined as {{#each definition_collection as |definition index|}}{{#if @first}}{{{definition}}}{{else if @last}} or {{{definition}}}{{else}}, {{{definition}}}{{/if}}{{/each}}.",
      "required": [
        "element",
        "definition_collection"
      ]
    }
  },
  {
    "name": "requirement / definition / 1",
    "conditions": {
      "type": {
        "is": "definition"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "requirement"
      },
      "definition_count": {
        "equal": 1
      }
    },
    "event": {
      "template": "{{{element}}} must be defined as {{{definition_0}}}.",
      "required": [
        "element",
        "definition_0"
      ]
    }
  },
  {
    "name": "requirement_abbreviated / definition / 1",
    "conditions": {
      "type": {
        "is": "definition"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "requirement_abbreviated"
      },
      "definition_count": {
        "equal": 1
      }
    },
    "event": {
      "template": "must be defined as {{{definition_0}}}",
      "required": [
        "definition_0"
      ]
    }
  },
  {
    "name": "patent_specification_preferred_version / detail / 1",
    "conditions": {
      "type": {
        "is": "detail"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "patent_specification_preferred_version"
      },
      "detail_count": {
        "equal": 1
      }
    },
    "event": {
      "template": "In some examples, {{{detail_0}}}.",
      "required": [
        "element",
        "detail_0"
      ]
    }
  },
  {
    "name": "patent_specification_preferred_version / detail / 2",
    "conditions": {
      "type": {
        "is": "detail"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "patent_specification_preferred_version"
      },
      "detail_count": {
        "equal": 2
      }
    },
    "event": {
      "template": "In some examples, {{{detail_0}}} or {{{detail_1}}}.",
      "required": [
        "element",
        "detail_0",
        "detail_1"
      ]
    }
  },
  {
    "name": "patent_specification_preferred_version / detail / 2+",
    "conditions": {
      "type": {
        "is": "detail"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "patent_specification_preferred_version"
      },
      "detail_count": {
        "greater": 2
      }
    },
    "event": {
      "template": "In some examples, {{#each detail_collection as |detail index|}}{{#if @first}}{{{detail}}}{{else if @last}} or {{{detail}}}{{else}}, {{{detail}}}{{/if}}{{/each}}.",
      "required": [
        "element",
        "detail_collection"
      ]
    }
  },
  {
    "name": "report_list / detail / 1",
    "conditions": {
      "type": {
        "is": "detail"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "report_list"
      },
      "detail_count": {
        "equal": 1
      }
    },
    "event": {
      "template": "In some examples, {{{detail_0}}}.",
      "required": [
        "element",
        "detail_0"
      ]
    }
  },
  {
    "name": "report_list / detail / 2",
    "conditions": {
      "type": {
        "is": "detail"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "report_list"
      },
      "detail_count": {
        "equal": 2
      }
    },
    "event": {
      "template": "In some examples, {{{detail_0}}} and {{{detail_1}}}.",
      "required": [
        "element",
        "detail_0",
        "detail_1"
      ]
    }
  },
  {
    "name": "report_list / detail / 2+",
    "conditions": {
      "type": {
        "is": "detail"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "report_list"
      },
      "detail_count": {
        "greater": 2
      }
    },
    "event": {
      "template": "In some examples, {{#each detail_collection as |detail index|}}{{#if @first}}{{{detail}}}{{else if @last}} and {{{detail}}}{{else}}, {{{detail}}}{{/if}}{{/each}}.",
      "required": [
        "element",
        "detail_collection"
      ]
    }
  },
  {
    "name": "report_list_abbreviated / detail / 1",
    "conditions": {
      "type": {
        "is": "detail"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "report_list_abbreviated"
      },
      "detail_count": {
        "equal": 1
      }
    },
    "event": {
      "template": "In some examples, {{{detail_0}}}.",
      "required": [
        "element",
        "detail_0"
      ]
    }
  },
  {
    "name": "report_list_abbreviated / detail / 2",
    "conditions": {
      "type": {
        "is": "detail"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "report_list_abbreviated"
      },
      "detail_count": {
        "equal": 2
      }
    },
    "event": {
      "template": "In some examples, {{{detail_0}}} and {{{detail_1}}}.",
      "required": [
        "element",
        "detail_0",
        "detail_1"
      ]
    }
  },
  {
    "name": "report_list_abbreviated / detail / 2+",
    "conditions": {
      "type": {
        "is": "detail"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "report_list_abbreviated"
      },
      "detail_count": {
        "greater": 2
      }
    },
    "event": {
      "template": "In some examples, {{#each detail_collection as |detail index|}}{{#if @first}}{{{detail}}}{{else if @last}} and {{{detail}}}{{else}}, {{{detail}}}{{/if}}{{/each}}.",
      "required": [
        "element",
        "detail_collection"
      ]
    }
  },
  {
    "name": "requirement / detail / 1",
    "conditions": {
      "type": {
        "is": "detail"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "requirement"
      },
      "detail_count": {
        "equal": 1
      }
    },
    "event": {
      "template": "{{{detail_0}}}.",
      "required": [
        "element",
        "detail_0"
      ]
    }
  },
  {
    "name": "requirement_abbreviated / detail / 1",
    "conditions": {
      "type": {
        "is": "detail"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "requirement_abbreviated"
      },
      "detail_count": {
        "equal": 1
      }
    },
    "event": {
      "template": "{{{detail_0}}}",
      "required": [
        "detail_0"
      ]
    }
  },
  {
    "name": "patent_specification_preferred_version / element_step / 1",
    "conditions": {
      "type": {
        "is": "element_step"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "patent_specification_preferred_version"
      },
      "element_step_count": {
        "equal": 1
      }
    },
    "event": {
      "template": "{{{element_step_0}}} via the {{{element}}}",
      "required": [
        "element",
        "element_step_0"
      ]
    }
  },
  {
    "name": "report_list / element_step / 1",
    "conditions": {
      "type": {
        "is": "element_step"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "report_list"
      },
      "element_step_count": {
        "equal": 1
      }
    },
    "event": {
      "template": "{{{element_step_0}}} via the {{{element}}}",
      "required": [
        "element",
        "element_step_0"
      ]
    }
  },
  {
    "name": "report_list_abbreviated / element_step / 1",
    "conditions": {
      "type": {
        "is": "element_step"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "report_list_abbreviated"
      },
      "element_step_count": {
        "equal": 1
      }
    },
    "event": {
      "template": "{{{element_step_0}}}",
      "required": [
        "element",
        "element_step_0"
      ]
    }
  },
  {
    "name": "requirement / element_step / 1",
    "conditions": {
      "type": {
        "is": "element_step"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "requirement"
      },
      "element_step_count": {
        "equal": 1
      }
    },
    "event": {
      "template": "The {{{element}}} must result in {{{element_step_0}}}.",
      "required": [
        "element",
        "element_step_0"
      ]
    }
  },
  {
    "name": "requirement_abbreviated / element_step / 1",
    "conditions": {
      "type": {
        "is": "element_step"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "requirement_abbreviated"
      },
      "element_step_count": {
        "equal": 1
      }
    },
    "event": {
      "template": "Success occurs when {{{element_step_0}}}",
      "required": [
        "element_step_0"
      ]
    }
  },
  {
    "name": "patent_specification_preferred_version / function / 1",
    "conditions": {
      "type": {
        "is": "function"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "patent_specification_preferred_version"
      },
      "function_count": {
        "equal": 1
      }
    },
    "event": {
      "template": "In some embodiments, the {{{element}}} may be configured to {{{function_0}}}.",
      "required": [
        "element",
        "function_0"
      ]
    }
  },
  {
    "name": "patent_specification_preferred_version / function / 2",
    "conditions": {
      "type": {
        "is": "function"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "patent_specification_preferred_version"
      },
      "function_count": {
        "equal": 2
      }
    },
    "event": {
      "template": "In some embodiments, the {{{element}}} may be configured to {{{function_0}}} and {{{function_1}}}.",
      "required": [
        "element",
        "function_0",
        "function_1"
      ]
    }
  },
  {
    "name": "patent_specification_preferred_version / function / 2+",
    "conditions": {
      "type": {
        "is": "function"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "patent_specification_preferred_version"
      },
      "function_count": {
        "greater": 2
      }
    },
    "event": {
      "template": "In some embodiments, the {{{element}}} may be configured to {{#each function_collection as |function index|}}{{#if @first}}{{{function}}}{{else if @last}} and {{{function}}}{{else}}, {{{function}}}{{/if}}{{/each}}.",
      "required": [
        "element",
        "function_collection"
      ]
    }
  },
  {
    "name": "report_list / function / 1",
    "conditions": {
      "type": {
        "is": "function"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "report_list"
      },
      "function_count": {
        "equal": 1
      }
    },
    "event": {
      "template": "The {{{element}}} is configured to {{{function_0}}}.",
      "required": [
        "element",
        "function_0"
      ]
    }
  },
  {
    "name": "report_list / function / 2",
    "conditions": {
      "type": {
        "is": "function"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "report_list"
      },
      "function_count": {
        "equal": 2
      }
    },
    "event": {
      "template": "The {{{element}}} is configured to {{{function_0}}} and {{{function_1}}}.",
      "required": [
        "element",
        "function_0",
        "function_1"
      ]
    }
  },
  {
    "name": "report_list / function / 2+",
    "conditions": {
      "type": {
        "is": "function"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "report_list"
      },
      "function_count": {
        "greater": 2
      }
    },
    "event": {
      "template": "The {{{element}}} is configured to {{#each function_collection as |function index|}}{{#if @first}}{{{function}}}{{else if @last}} and {{{function}}}{{else}}, {{{function}}}{{/if}}{{/each}}.",
      "required": [
        "element",
        "function_collection"
      ]
    }
  },
  {
    "name": "report_list_abbreviated / function / 1",
    "conditions": {
      "type": {
        "is": "function"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "report_list_abbreviated"
      },
      "function_count": {
        "equal": 1
      }
    },
    "event": {
      "template": "Configured to {{{function_0}}}.",
      "required": [
        "element",
        "function_0"
      ]
    }
  },
  {
    "name": "report_list_abbreviated / function / 2",
    "conditions": {
      "type": {
        "is": "function"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "report_list_abbreviated"
      },
      "function_count": {
        "equal": 2
      }
    },
    "event": {
      "template": "Configured to {{{function_0}}} and {{{function_1}}}.",
      "required": [
        "element",
        "function_0",
        "function_1"
      ]
    }
  },
  {
    "name": "report_list_abbreviated / function / 2+",
    "conditions": {
      "type": {
        "is": "function"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "report_list_abbreviated"
      },
      "function_count": {
        "greater": 2
      }
    },
    "event": {
      "template": "Configured to {{#each function_collection as |function index|}}{{#if @first}}{{{function}}}{{else if @last}} and {{{function}}}{{else}}, {{{function}}}{{/if}}{{/each}}.",
      "required": [
        "element",
        "function_collection"
      ]
    }
  },
  {
    "name": "requirement / function / 1",
    "conditions": {
      "type": {
        "is": "function"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "requirement"
      },
      "function_count": {
        "equal": 1
      }
    },
    "event": {
      "template": "The {{{element}}} must be able to {{{function_0}}}.",
      "required": [
        "element",
        "function_0"
      ]
    }
  },
  {
    "name": "requirement_abbreviated / function / 1",
    "conditions": {
      "type": {
        "is": "function"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "requirement_abbreviated"
      },
      "function_count": {
        "equal": 1
      }
    },
    "event": {
      "template": "must be able to {{{function_0}}}",
      "required": [
        "function_0"
      ]
    }
  },
  {
    "name": "patent_specification_preferred_version / functional_requirement / 1",
    "conditions": {
      "type": {
        "is": "functional_requirement"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "patent_specification_preferred_version"
      },
      "functional_requirement_count": {
        "equal": 1
      }
    },
    "event": {
      "template": "In some embodiments, the {{{element}}} may be configured to {{{functional_requirement_0}}}.",
      "required": [
        "element",
        "functional_requirement_0"
      ]
    }
  },
  {
    "name": "patent_specification_preferred_version / functional_requirement / 2",
    "conditions": {
      "type": {
        "is": "functional_requirement"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "patent_specification_preferred_version"
      },
      "functional_requirement_count": {
        "equal": 2
      }
    },
    "event": {
      "template": "In some embodiments, the {{{element}}} may be configured to {{{functional_requirement_0}}} and {{{functional_requirement_1}}}.",
      "required": [
        "element",
        "functional_requirement_0",
        "functional_requirement_1"
      ]
    }
  },
  {
    "name": "patent_specification_preferred_version / functional_requirement / 2+",
    "conditions": {
      "type": {
        "is": "functional_requirement"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "patent_specification_preferred_version"
      },
      "functional_requirement_count": {
        "greater": 2
      }
    },
    "event": {
      "template": "In some embodiments, the {{{element}}} may be configured to {{#each functional_requirement_collection as |functional_requirement index|}}{{#if @first}}{{{functional_requirement}}}{{else if @last}} and {{{functional_requirement}}}{{else}}, {{{functional_requirement}}}{{/if}}{{/each}}.",
      "required": [
        "element",
        "functional_requirement_collection"
      ]
    }
  },
  {
    "name": "report_list / functional_requirement / 1",
    "conditions": {
      "type": {
        "is": "functional_requirement"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "report_list"
      },
      "functional_requirement_count": {
        "equal": 1
      }
    },
    "event": {
      "template": "The {{{element}}} is configured to {{{functional_requirement_0}}}.",
      "required": [
        "element",
        "functional_requirement_0"
      ]
    }
  },
  {
    "name": "report_list / functional_requirement / 2",
    "conditions": {
      "type": {
        "is": "functional_requirement"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "report_list"
      },
      "functional_requirement_count": {
        "equal": 2
      }
    },
    "event": {
      "template": "The {{{element}}} is configured to {{{functional_requirement_0}}} and {{{functional_requirement_1}}}.",
      "required": [
        "element",
        "functional_requirement_0",
        "functional_requirement_1"
      ]
    }
  },
  {
    "name": "report_list / functional_requirement / 2+",
    "conditions": {
      "type": {
        "is": "functional_requirement"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "report_list"
      },
      "functional_requirement_count": {
        "greater": 2
      }
    },
    "event": {
      "template": "The {{{element}}} is configured to {{#each functional_requirement_collection as |functional_requirement index|}}{{#if @first}}{{{functional_requirement}}}{{else if @last}} and {{{functional_requirement}}}{{else}}, {{{functional_requirement}}}{{/if}}{{/each}}.",
      "required": [
        "element",
        "functional_requirement_collection"
      ]
    }
  },
  {
    "name": "report_list_abbreviated / functional_requirement / 1",
    "conditions": {
      "type": {
        "is": "functional_requirement"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "report_list_abbreviated"
      },
      "functional_requirement_count": {
        "equal": 1
      }
    },
    "event": {
      "template": "Configured to {{{functional_requirement_0}}}.",
      "required": [
        "element",
        "functional_requirement_0"
      ]
    }
  },
  {
    "name": "report_list_abbreviated / functional_requirement / 2",
    "conditions": {
      "type": {
        "is": "functional_requirement"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "report_list_abbreviated"
      },
      "functional_requirement_count": {
        "equal": 2
      }
    },
    "event": {
      "template": "Configured to {{{functional_requirement_0}}} and {{{functional_requirement_1}}}.",
      "required": [
        "element",
        "functional_requirement_0",
        "functional_requirement_1"
      ]
    }
  },
  {
    "name": "report_list_abbreviated / functional_requirement / 2+",
    "conditions": {
      "type": {
        "is": "functional_requirement"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "report_list_abbreviated"
      },
      "functional_requirement_count": {
        "greater": 2
      }
    },
    "event": {
      "template": "Configured to {{#each functional_requirement_collection as |functional_requirement index|}}{{#if @first}}{{{functional_requirement}}}{{else if @last}} and {{{functional_requirement}}}{{else}}, {{{functional_requirement}}}{{/if}}{{/each}}.",
      "required": [
        "element",
        "functional_requirement_collection"
      ]
    }
  },
  {
    "name": "requirement / functional_requirement / 1",
    "conditions": {
      "type": {
        "is": "functional_requirement"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "requirement"
      },
      "functional_requirement_count": {
        "equal": 1
      }
    },
    "event": {
      "template": "The {{{element}}} must be able to {{{functional_requirement_0}}}.",
      "required": [
        "element",
        "functional_requirement_0"
      ]
    }
  },
  {
    "name": "requirement_abbreviated / functional_requirement / 1",
    "conditions": {
      "type": {
        "is": "functional_requirement"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "requirement_abbreviated"
      },
      "functional_requirement_count": {
        "equal": 1
      }
    },
    "event": {
      "template": "Must be able to {{{functional_requirement_0}}}",
      "required": [
        "functional_requirement_0"
      ]
    }
  },
  {
    "name": "patent_specification_preferred_version / general / 1",
    "conditions": {
      "type": {
        "is": "general"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "patent_specification_preferred_version"
      },
      "general_count": {
        "equal": 1
      }
    },
    "event": {
      "template": "In some embodiments, the {{{element}}} {{{general_0}}}.",
      "required": [
        "element",
        "general_0"
      ]
    }
  },
  {
    "name": "patent_specification_preferred_version / general / 2",
    "conditions": {
      "type": {
        "is": "general"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "patent_specification_preferred_version"
      },
      "general_count": {
        "equal": 2
      }
    },
    "event": {
      "template": "In some embodiments, the {{{element}}} {{{general_0}}} or {{{general_1}}}.",
      "required": [
        "element",
        "general_0",
        "general_1"
      ]
    }
  },
  {
    "name": "patent_specification_preferred_version / general / 2+",
    "conditions": {
      "type": {
        "is": "general"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "patent_specification_preferred_version"
      },
      "general_count": {
        "greater": 2
      }
    },
    "event": {
      "template": "In some embodiments, the {{{element}}} {{#each general_collection as |general index|}}{{#if @first}}{{{general}}}{{else if @last}} or {{{general}}}{{else}}, {{{general}}}{{/if}}{{/each}}.",
      "required": [
        "element",
        "general_collection"
      ]
    }
  },
  {
    "name": "report_list / general / 1",
    "conditions": {
      "type": {
        "is": "general"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "report_list"
      },
      "general_count": {
        "equal": 1
      }
    },
    "event": {
      "template": "The {{{element}}} {{{general_0}}}.",
      "required": [
        "element",
        "general_0"
      ]
    }
  },
  {
    "name": "report_list / general / 2",
    "conditions": {
      "type": {
        "is": "general"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "report_list"
      },
      "general_count": {
        "equal": 2
      }
    },
    "event": {
      "template": "The {{{element}}} {{{general_0}}} and {{{general_1}}}.",
      "required": [
        "element",
        "general_0",
        "general_1"
      ]
    }
  },
  {
    "name": "report_list / general / 2+",
    "conditions": {
      "type": {
        "is": "general"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "report_list"
      },
      "general_count": {
        "greater": 2
      }
    },
    "event": {
      "template": "The {{{element}}} {{#each general_collection as |general index|}}{{#if @first}}{{{general}}}{{else if @last}} and {{{general}}}{{else}}, {{{general}}}{{/if}}{{/each}}.",
      "required": [
        "element",
        "general_collection"
      ]
    }
  },
  {
    "name": "report_list_abbreviated / general / 1",
    "conditions": {
      "type": {
        "is": "general"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "report_list_abbreviated"
      },
      "general_count": {
        "equal": 1
      }
    },
    "event": {
      "template": "{{{general_0}}}.",
      "required": [
        "element",
        "general_0"
      ]
    }
  },
  {
    "name": "report_list_abbreviated / general / 2",
    "conditions": {
      "type": {
        "is": "general"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "report_list_abbreviated"
      },
      "general_count": {
        "equal": 2
      }
    },
    "event": {
      "template": "{{{general_0}}} and {{{general_1}}}.",
      "required": [
        "element",
        "general_0",
        "general_1"
      ]
    }
  },
  {
    "name": "report_list_abbreviated / general / 2+",
    "conditions": {
      "type": {
        "is": "general"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "report_list_abbreviated"
      },
      "general_count": {
        "greater": 2
      }
    },
    "event": {
      "template": "{{#each general_collection as |general index|}}{{#if @first}}{{{general}}}{{else if @last}} and {{{general}}}{{else}}, {{{general}}}{{/if}}{{/each}}.",
      "required": [
        "element",
        "general_collection"
      ]
    }
  },
  {
    "name": "requirement / general / 1",
    "conditions": {
      "type": {
        "is": "general"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "requirement"
      },
      "general_count": {
        "equal": 1
      }
    },
    "event": {
      "template": "The {{{element}}} must {{{general_0}}}.",
      "required": [
        "element",
        "general_0"
      ]
    }
  },
  {
    "name": "requirement_abbreviated / general / 1",
    "conditions": {
      "type": {
        "is": "general"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "requirement_abbreviated"
      },
      "general_count": {
        "equal": 1
      }
    },
    "event": {
      "template": "must {{{general_0}}}",
      "required": [
        "general_0"
      ]
    }
  },
  {
    "name": "patent_specification_preferred_version / interaction / 1",
    "conditions": {
      "type": {
        "is": "interaction"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "patent_specification_preferred_version"
      },
      "interaction_count": {
        "equal": 1
      }
    },
    "event": {
      "template": "In some embodiments, the {{{element}}} may interact with {{{interaction_0}}}.",
      "required": [
        "element",
        "interaction_0"
      ]
    }
  },
  {
    "name": "patent_specification_preferred_version / interaction / 2",
    "conditions": {
      "type": {
        "is": "interaction"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "patent_specification_preferred_version"
      },
      "interaction_count": {
        "equal": 2
      }
    },
    "event": {
      "template": "In some embodiments, the {{{element}}} may interact in multiple ways: it may interact with {{{interaction_0}}} and it also may interact with {{{interaction_1}}}.",
      "required": [
        "element",
        "interaction_0",
        "interaction_1"
      ]
    }
  },
  {
    "name": "patent_specification_preferred_version / interaction / 2+",
    "conditions": {
      "type": {
        "is": "interaction"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "patent_specification_preferred_version"
      },
      "interaction_count": {
        "greater": 2
      }
    },
    "event": {
      "template": "In some embodiments, the {{{element}}} may interact in multiple ways: it may interact with {{#each interaction_collection as |interaction index|}}{{#if @first}}{{{interaction}}}{{else if @last}} and it may interact with {{{interaction}}}{{else}}, it also may interact with {{{interaction}}}{{/if}}{{/each}}.",
      "required": [
        "element",
        "interaction_collection"
      ]
    }
  },
  {
    "name": "report_list / interaction / 1",
    "conditions": {
      "type": {
        "is": "interaction"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "report_list"
      },
      "interaction_count": {
        "equal": 1
      }
    },
    "event": {
      "template": "The {{{element}}} interacts with {{{interaction_0}}}.",
      "required": [
        "element",
        "interaction_0"
      ]
    }
  },
  {
    "name": "report_list / interaction / 2",
    "conditions": {
      "type": {
        "is": "interaction"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "report_list"
      },
      "interaction_count": {
        "equal": 2
      }
    },
    "event": {
      "template": "The {{{element}}} interacts with {{{interaction_0}}} and also interacts with {{{interaction_1}}}.",
      "required": [
        "element",
        "interaction_0",
        "interaction_1"
      ]
    }
  },
  {
    "name": "report_list / interaction / 2+",
    "conditions": {
      "type": {
        "is": "interaction"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "report_list"
      },
      "interaction_count": {
        "greater": 2
      }
    },
    "event": {
      "template": "The {{{element}}} interacts with {{#each interaction_collection as |interaction index|}}{{#if @first}}{{{interaction}}}{{else if @last}} and also interacts with {{{interaction}}}{{else}}, {{{interaction}}}{{/if}}{{/each}}.",
      "required": [
        "element",
        "interaction_collection"
      ]
    }
  },
  {
    "name": "report_list_abbreviated / interaction / 1",
    "conditions": {
      "type": {
        "is": "interaction"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "report_list_abbreviated"
      },
      "interaction_count": {
        "equal": 1
      }
    },
    "event": {
      "template": "Interacts with {{{interaction_0}}}.",
      "required": [
        "element",
        "interaction_0"
      ]
    }
  },
  {
    "name": "report_list_abbreviated / interaction / 2",
    "conditions": {
      "type": {
        "is": "interaction"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "report_list_abbreviated"
      },
      "interaction_count": {
        "equal": 2
      }
    },
    "event": {
      "template": "Interacts with {{{interaction_0}}} and also interacts with {{{interaction_1}}}.",
      "required": [
        "element",
        "interaction_0",
        "interaction_1"
      ]
    }
  },
  {
    "name": "report_list_abbreviated / interaction / 2+",
    "conditions": {
      "type": {
        "is": "interaction"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "report_list_abbreviated"
      },
      "interaction_count": {
        "greater": 2
      }
    },
    "event": {
      "template": "Interacts with {{#each interaction_collection as |interaction index|}}{{#if @first}}{{{interaction}}}{{else if @last}} and also interacts with {{{interaction}}}{{else}}, {{{interaction}}}{{/if}}{{/each}}.",
      "required": [
        "element",
        "interaction_collection"
      ]
    }
  },
  {
    "name": "requirement / interaction / 1",
    "conditions": {
      "type": {
        "is": "interaction"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "requirement"
      },
      "interaction_count": {
        "equal": 1
      }
    },
    "event": {
      "template": "The {{{element}}} must interact with {{{interaction_0}}}.",
      "required": [
        "element",
        "interaction_0"
      ]
    }
  },
  {
    "name": "requirement_abbreviated / interaction / 1",
    "conditions": {
      "type": {
        "is": "interaction"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "requirement_abbreviated"
      },
      "interaction_count": {
        "equal": 1
      }
    },
    "event": {
      "template": "must interact with {{{interaction_0}}}",
      "required": [
        "interaction_0"
      ]
    }
  },
  {
    "name": "patent_specification_preferred_version / inverse_step / 1",
    "conditions": {
      "type": {
        "is": "inverse_step"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "patent_specification_preferred_version"
      },
      "inverse_step_count": {
        "equal": 1
      }
    },
    "event": {
      "template": "{{{inverse_step_0}}}",
      "required": [
        "inverse_step_0"
      ]
    }
  },
  {
    "name": "report_list / inverse_step / 1",
    "conditions": {
      "type": {
        "is": "inverse_step"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "report_list"
      },
      "inverse_step_count": {
        "equal": 1
      }
    },
    "event": {
      "template": "{{{inverse_step_0}}}",
      "required": [
        "inverse_step_0"
      ]
    }
  },
  {
    "name": "report_list_abbreviated / inverse_step / 1",
    "conditions": {
      "type": {
        "is": "inverse_step"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "report_list_abbreviated"
      },
      "inverse_step_count": {
        "equal": 1
      }
    },
    "event": {
      "template": "{{{inverse_step_0}}}",
      "required": [
        "inverse_step_0"
      ]
    }
  },
  {
    "name": "requirement / inverse_step / 1",
    "conditions": {
      "type": {
        "is": "inverse_step"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "requirement"
      },
      "inverse_step_count": {
        "equal": 1
      }
    },
    "event": {
      "template": "The {{{element}}} must have the step \"{{{inverse_step_0}}}.\"",
      "required": [
        "element",
        "inverse_step_0"
      ]
    }
  },
  {
    "name": "requirement_abbreviated / inverse_step / 1",
    "conditions": {
      "type": {
        "is": "inverse_step"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "requirement_abbreviated"
      },
      "inverse_step_count": {
        "equal": 1
      }
    },
    "event": {
      "template": "must have the step \"{{{inverse_step_0}}}\"",
      "required": [
        "inverse_step_0"
      ]
    }
  },
  {
    "name": "patent_specification_preferred_version / material / 1",
    "conditions": {
      "type": {
        "is": "material"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "patent_specification_preferred_version"
      },
      "material_count": {
        "equal": 1
      }
    },
    "event": {
      "template": "In some embodiments, the {{{element}}} may contain the material {{{material_0}}}.",
      "required": [
        "element",
        "material_0"
      ]
    }
  },
  {
    "name": "patent_specification_preferred_version / material / 2",
    "conditions": {
      "type": {
        "is": "material"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "patent_specification_preferred_version"
      },
      "material_count": {
        "equal": 2
      }
    },
    "event": {
      "template": "In some embodiments, the {{{element}}} may contain the material {{{material_0}}} or {{{material_1}}}.",
      "required": [
        "element",
        "material_0",
        "material_1"
      ]
    }
  },
  {
    "name": "patent_specification_preferred_version / material / 2+",
    "conditions": {
      "type": {
        "is": "material"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "patent_specification_preferred_version"
      },
      "material_count": {
        "greater": 2
      }
    },
    "event": {
      "template": "In some embodiments, the {{{element}}} may contain the materials {{#each material_collection as |material index|}}{{#if @first}}{{{material}}}{{else if @last}} or {{{material}}}{{else}}, {{{material}}}{{/if}}{{/each}}.",
      "required": [
        "element",
        "material_collection"
      ]
    }
  },
  {
    "name": "report_list / material / 1",
    "conditions": {
      "type": {
        "is": "material"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "report_list"
      },
      "material_count": {
        "equal": 1
      }
    },
    "event": {
      "template": "The {{{element}}} is composed of (or contains) the material {{{material_0}}}.",
      "required": [
        "element",
        "material_0"
      ]
    }
  },
  {
    "name": "report_list / material / 2",
    "conditions": {
      "type": {
        "is": "material"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "report_list"
      },
      "material_count": {
        "equal": 2
      }
    },
    "event": {
      "template": "The {{{element}}} is composed of (or contains) the materials {{{material_0}}} and {{{material_1}}}.",
      "required": [
        "element",
        "material_0",
        "material_1"
      ]
    }
  },
  {
    "name": "report_list / material / 2+",
    "conditions": {
      "type": {
        "is": "material"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "report_list"
      },
      "material_count": {
        "greater": 2
      }
    },
    "event": {
      "template": "The {{{element}}} is composed of (or contains) the materials {{#each material_collection as |material index|}}{{#if @first}}{{{material}}}{{else if @last}} and {{{material}}}{{else}}, {{{material}}}{{/if}}{{/each}}.",
      "required": [
        "element",
        "material_collection"
      ]
    }
  },
  {
    "name": "report_list_abbreviated / material / 1",
    "conditions": {
      "type": {
        "is": "material"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "report_list_abbreviated"
      },
      "material_count": {
        "equal": 1
      }
    },
    "event": {
      "template": "Composed of (or contains) the material {{{material_0}}}.",
      "required": [
        "element",
        "material_0"
      ]
    }
  },
  {
    "name": "report_list_abbreviated / material / 2",
    "conditions": {
      "type": {
        "is": "material"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "report_list_abbreviated"
      },
      "material_count": {
        "equal": 2
      }
    },
    "event": {
      "template": "Composed of (or contains) the materials {{{material_0}}} and {{{material_1}}}.",
      "required": [
        "element",
        "material_0",
        "material_1"
      ]
    }
  },
  {
    "name": "report_list_abbreviated / material / 2+",
    "conditions": {
      "type": {
        "is": "material"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "report_list_abbreviated"
      },
      "material_count": {
        "greater": 2
      }
    },
    "event": {
      "template": "Composed of (or contains) the materials {{#each material_collection as |material index|}}{{#if @first}}{{{material}}}{{else if @last}} and {{{material}}}{{else}}, {{{material}}}{{/if}}{{/each}}.",
      "required": [
        "element",
        "material_collection"
      ]
    }
  },
  {
    "name": "requirement / material / 1",
    "conditions": {
      "type": {
        "is": "material"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "requirement"
      },
      "material_count": {
        "equal": 1
      }
    },
    "event": {
      "template": "The {{{element}}} must be composed of (or contain) the material {{{material_0}}}.",
      "required": [
        "element",
        "material_0"
      ]
    }
  },
  {
    "name": "requirement_abbreviated / material / 1",
    "conditions": {
      "type": {
        "is": "material"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "requirement_abbreviated"
      },
      "material_count": {
        "equal": 1
      }
    },
    "event": {
      "template": "must be composed of (or contain) the material {{{material_0}}}",
      "required": [
        "material_0"
      ]
    }
  },
  {
    "name": "patent_specification_preferred_version / position / 1",
    "conditions": {
      "type": {
        "is": "position"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "patent_specification_preferred_version"
      },
      "position_count": {
        "equal": 1
      }
    },
    "event": {
      "template": "In some embodiments, the {{{element}}} may be positioned {{{position_0}}}.",
      "required": [
        "element",
        "position_0"
      ]
    }
  },
  {
    "name": "patent_specification_preferred_version / position / 2",
    "conditions": {
      "type": {
        "is": "position"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "patent_specification_preferred_version"
      },
      "position_count": {
        "equal": 2
      }
    },
    "event": {
      "template": "In some embodiments, the {{{element}}} may be positioned {{{position_0}}} or {{{position_1}}}.",
      "required": [
        "element",
        "position_0",
        "position_1"
      ]
    }
  },
  {
    "name": "patent_specification_preferred_version / position / 2+",
    "conditions": {
      "type": {
        "is": "position"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "patent_specification_preferred_version"
      },
      "position_count": {
        "greater": 2
      }
    },
    "event": {
      "template": "In some embodiments, the {{{element}}} may be positioned {{#each position_collection as |position index|}}{{#if @first}}{{{position}}}{{else if @last}} or {{{position}}}{{else}}, {{{position}}}{{/if}}{{/each}}.",
      "required": [
        "element",
        "position_collection"
      ]
    }
  },
  {
    "name": "report_list / position / 1",
    "conditions": {
      "type": {
        "is": "position"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "report_list"
      },
      "position_count": {
        "equal": 1
      }
    },
    "event": {
      "template": "The {{{element}}} is positioned {{{position_0}}}.",
      "required": [
        "element",
        "position_0"
      ]
    }
  },
  {
    "name": "report_list / position / 2",
    "conditions": {
      "type": {
        "is": "position"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "report_list"
      },
      "position_count": {
        "equal": 2
      }
    },
    "event": {
      "template": "The {{{element}}} is positioned {{{position_0}}} and {{{position_1}}}.",
      "required": [
        "element",
        "position_0",
        "position_1"
      ]
    }
  },
  {
    "name": "report_list / position / 2+",
    "conditions": {
      "type": {
        "is": "position"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "report_list"
      },
      "position_count": {
        "greater": 2
      }
    },
    "event": {
      "template": "The {{{element}}} is positioned {{#each position_collection as |position index|}}{{#if @first}}{{{position}}}{{else if @last}} and {{{position}}}{{else}}, {{{position}}}{{/if}}{{/each}}.",
      "required": [
        "element",
        "position_collection"
      ]
    }
  },
  {
    "name": "report_list_abbreviated / position / 1",
    "conditions": {
      "type": {
        "is": "position"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "report_list_abbreviated"
      },
      "position_count": {
        "equal": 1
      }
    },
    "event": {
      "template": "Positioned {{{position_0}}}.",
      "required": [
        "element",
        "position_0"
      ]
    }
  },
  {
    "name": "report_list_abbreviated / position / 2",
    "conditions": {
      "type": {
        "is": "position"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "report_list_abbreviated"
      },
      "position_count": {
        "equal": 2
      }
    },
    "event": {
      "template": "Positioned {{{position_0}}} and {{{position_1}}}.",
      "required": [
        "element",
        "position_0",
        "position_1"
      ]
    }
  },
  {
    "name": "report_list_abbreviated / position / 2+",
    "conditions": {
      "type": {
        "is": "position"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "report_list_abbreviated"
      },
      "position_count": {
        "greater": 2
      }
    },
    "event": {
      "template": "Positioned {{#each position_collection as |position index|}}{{#if @first}}{{{position}}}{{else if @last}} and {{{position}}}{{else}}, {{{position}}}{{/if}}{{/each}}.",
      "required": [
        "element",
        "position_collection"
      ]
    }
  },
  {
    "name": "requirement / position / 1",
    "conditions": {
      "type": {
        "is": "position"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "requirement"
      },
      "position_count": {
        "equal": 1
      }
    },
    "event": {
      "template": "The {{{element}}} must be positioned {{{position_0}}}.",
      "required": [
        "element",
        "position_0"
      ]
    }
  },
  {
    "name": "requirement_abbreviated / position / 1",
    "conditions": {
      "type": {
        "is": "position"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "requirement_abbreviated"
      },
      "position_count": {
        "equal": 1
      }
    },
    "event": {
      "template": "must be positioned {{{position_0}}}",
      "required": [
        "position_0"
      ]
    }
  },
  {
    "name": "patent_specification_preferred_version / shape / 1",
    "conditions": {
      "type": {
        "is": "shape"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "patent_specification_preferred_version"
      },
      "shape_count": {
        "equal": 1
      }
    },
    "event": {
      "template": "In some embodiments, the {{{element}}} may be shaped like {{{shape_0}}}.",
      "required": [
        "element",
        "shape_0"
      ]
    }
  },
  {
    "name": "patent_specification_preferred_version / shape / 2",
    "conditions": {
      "type": {
        "is": "shape"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "patent_specification_preferred_version"
      },
      "shape_count": {
        "equal": 2
      }
    },
    "event": {
      "template": "In some embodiments, the {{{element}}} may be shaped like {{{shape_0}}} or {{{shape_1}}}.",
      "required": [
        "element",
        "shape_0",
        "shape_1"
      ]
    }
  },
  {
    "name": "patent_specification_preferred_version / shape / 2+",
    "conditions": {
      "type": {
        "is": "shape"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "patent_specification_preferred_version"
      },
      "shape_count": {
        "greater": 2
      }
    },
    "event": {
      "template": "In some embodiments, the {{{element}}} may be shaped like {{#each shape_collection as |shape index|}}{{#if @first}}{{{shape}}}{{else if @last}} or {{{shape}}}{{else}}, {{{shape}}}{{/if}}{{/each}}.",
      "required": [
        "element",
        "shape_collection"
      ]
    }
  },
  {
    "name": "report_list / shape / 1",
    "conditions": {
      "type": {
        "is": "shape"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "report_list"
      },
      "shape_count": {
        "equal": 1
      }
    },
    "event": {
      "template": "The {{{element}}} is shaped like {{{shape_0}}}.",
      "required": [
        "element",
        "shape_0"
      ]
    }
  },
  {
    "name": "report_list / shape / 2",
    "conditions": {
      "type": {
        "is": "shape"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "report_list"
      },
      "shape_count": {
        "equal": 2
      }
    },
    "event": {
      "template": "The {{{element}}} is shaped like {{{shape_0}}} or {{{shape_1}}}.",
      "required": [
        "element",
        "shape_0",
        "shape_1"
      ]
    }
  },
  {
    "name": "report_list / shape / 2+",
    "conditions": {
      "type": {
        "is": "shape"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "report_list"
      },
      "shape_count": {
        "greater": 2
      }
    },
    "event": {
      "template": "The {{{element}}} is shaped like {{#each shape_collection as |shape index|}}{{#if @first}}{{{shape}}}{{else if @last}} or {{{shape}}}{{else}}, {{{shape}}}{{/if}}{{/each}}.",
      "required": [
        "element",
        "shape_collection"
      ]
    }
  },
  {
    "name": "report_list_abbreviated / shape / 1",
    "conditions": {
      "type": {
        "is": "shape"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "report_list_abbreviated"
      },
      "shape_count": {
        "equal": 1
      }
    },
    "event": {
      "template": "Shaped like {{{shape_0}}}.",
      "required": [
        "element",
        "shape_0"
      ]
    }
  },
  {
    "name": "report_list_abbreviated / shape / 2",
    "conditions": {
      "type": {
        "is": "shape"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "report_list_abbreviated"
      },
      "shape_count": {
        "equal": 2
      }
    },
    "event": {
      "template": "Shaped like {{{shape_0}}} or {{{shape_1}}}.",
      "required": [
        "element",
        "shape_0",
        "shape_1"
      ]
    }
  },
  {
    "name": "report_list_abbreviated / shape / 2+",
    "conditions": {
      "type": {
        "is": "shape"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "report_list_abbreviated"
      },
      "shape_count": {
        "greater": 2
      }
    },
    "event": {
      "template": "Shaped like {{#each shape_collection as |shape index|}}{{#if @first}}{{{shape}}}{{else if @last}} or {{{shape}}}{{else}}, {{{shape}}}{{/if}}{{/each}}.",
      "required": [
        "element",
        "shape_collection"
      ]
    }
  },
  {
    "name": "requirement / shape / 1",
    "conditions": {
      "type": {
        "is": "shape"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "requirement"
      },
      "shape_count": {
        "equal": 1
      }
    },
    "event": {
      "template": "The {{{element}}} must be shaped like {{{shape_0}}}.",
      "required": [
        "element",
        "shape_0"
      ]
    }
  },
  {
    "name": "requirement_abbreviated / shape / 1",
    "conditions": {
      "type": {
        "is": "shape"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "requirement_abbreviated"
      },
      "shape_count": {
        "equal": 1
      }
    },
    "event": {
      "template": "must be shaped like {{{shape_0}}}",
      "required": [
        "shape_0"
      ]
    }
  },
  {
    "name": "patent_specification_preferred_version / step / 1",
    "conditions": {
      "type": {
        "is": "step"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "patent_specification_preferred_version"
      },
      "step_count": {
        "equal": 1
      }
    },
    "event": {
      "template": "{{{step_0}}}",
      "required": [
        "step_0"
      ]
    }
  },
  {
    "name": "report_list / step / 1",
    "conditions": {
      "type": {
        "is": "step"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "report_list"
      },
      "step_count": {
        "equal": 1
      }
    },
    "event": {
      "template": "{{{step_0}}}",
      "required": [
        "step_0"
      ]
    }
  },
  {
    "name": "report_list_abbreviated / step / 1",
    "conditions": {
      "type": {
        "is": "step"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "report_list_abbreviated"
      },
      "step_count": {
        "equal": 1
      }
    },
    "event": {
      "template": "{{{step_0}}}",
      "required": [
        "step_0"
      ]
    }
  },
  {
    "name": "requirement / step / 1",
    "conditions": {
      "type": {
        "is": "step"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "requirement"
      },
      "step_count": {
        "equal": 1
      }
    },
    "event": {
      "template": "The {{{element}}} must have the step \"{{{step_0}}}.\"",
      "required": [
        "element",
        "step_0"
      ]
    }
  },
  {
    "name": "requirement_abbreviated / step / 1",
    "conditions": {
      "type": {
        "is": "step"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "requirement_abbreviated"
      },
      "step_count": {
        "equal": 1
      }
    },
    "event": {
      "template": "must have the step \"{{{step_0}}}\"",
      "required": [
        "step_0"
      ]
    }
  },
  {
    "name": "patent_specification_preferred_version / measurement / 1 / equal-to",
    "conditions": {
      "type": {
        "is": "measurement"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "patent_specification_preferred_version"
      },
      "measurement_count": {
        "equal": 1
      },
      "measurement_operator": {
        "is": "equal-to"
      }
    },
    "event": {
      "template": "In some embodiments, the {{{element}}} may have {{{indefinite measurement_type_0}}} of {{{measurement_value_0}}}.",
      "required": [
        "element",
        "measurement_type_0",
        "measurement_value_0"
      ]
    }
  },
  {
    "name": "report_list_abbreviated / measurement / 1 / equal-to",
    "conditions": {
      "type": {
        "is": "measurement"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "report_list_abbreviated"
      },
      "measurement_count": {
        "equal": 1
      },
      "measurement_operator": {
        "is": "equal-to"
      }
    },
    "event": {
      "template": "{{{indefinite measurement_type_0 true}}} of {{{measurement_value_0}}}.",
      "required": [
        "element",
        "measurement_type_0",
        "measurement_value_0"
      ]
    }
  },
  {
    "name": "report_list / measurement / 1 / equal-to",
    "conditions": {
      "type": {
        "is": "measurement"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "report_list"
      },
      "measurement_count": {
        "equal": 1
      },
      "measurement_operator": {
        "is": "equal-to"
      }
    },
    "event": {
      "template": "The {{{element}}} has {{{indefinite measurement_type_0}}} of {{{measurement_value_0}}}.",
      "required": [
        "element",
        "measurement_type_0",
        "measurement_value_0"
      ]
    }
  },
  {
    "name": "patent_specification_preferred_version / measurement / 1 / approximately-equal-to",
    "conditions": {
      "type": {
        "is": "measurement"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "patent_specification_preferred_version"
      },
      "measurement_count": {
        "equal": 1
      },
      "measurement_operator": {
        "is": "approximately-equal-to"
      }
    },
    "event": {
      "template": "In some embodiments, the {{{element}}} may have {{{indefinite measurement_type_0}}} of approximately {{{measurement_value_0}}}.",
      "required": [
        "element",
        "measurement_type_0",
        "measurement_value_0"
      ]
    }
  },
  {
    "name": "report_list_abbreviated / measurement / 1 / approximately-equal-to",
    "conditions": {
      "type": {
        "is": "measurement"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "report_list_abbreviated"
      },
      "measurement_count": {
        "equal": 1
      },
      "measurement_operator": {
        "is": "approximately-equal-to"
      }
    },
    "event": {
      "template": "{{{indefinite measurement_type_0 true}}} of approximately {{{measurement_value_0}}}.",
      "required": [
        "element",
        "measurement_type_0",
        "measurement_value_0"
      ]
    }
  },
  {
    "name": "report_list / measurement / 1 / approximately-equal-to",
    "conditions": {
      "type": {
        "is": "measurement"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "report_list"
      },
      "measurement_count": {
        "equal": 1
      },
      "measurement_operator": {
        "is": "approximately-equal-to"
      }
    },
    "event": {
      "template": "The {{{element}}} has {{{indefinite measurement_type_0}}} of approximately {{{measurement_value_0}}}.",
      "required": [
        "element",
        "measurement_type_0",
        "measurement_value_0"
      ]
    }
  },
  {
    "name": "patent_specification_preferred_version / measurement / 1 / less-than",
    "conditions": {
      "type": {
        "is": "measurement"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "patent_specification_preferred_version"
      },
      "measurement_count": {
        "equal": 1
      },
      "measurement_operator": {
        "is": "less-than"
      }
    },
    "event": {
      "template": "In some embodiments, the {{{element}}} may have {{{indefinite measurement_type_0}}} less than {{{measurement_value_0}}}.",
      "required": [
        "element",
        "measurement_type_0",
        "measurement_value_0"
      ]
    }
  },
  {
    "name": "report_list_abbreviated / measurement / 1 / less-than",
    "conditions": {
      "type": {
        "is": "measurement"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "report_list_abbreviated"
      },
      "measurement_count": {
        "equal": 1
      },
      "measurement_operator": {
        "is": "less-than"
      }
    },
    "event": {
      "template": "{{{indefinite measurement_type_0 true}}} less than {{{measurement_value_0}}}.",
      "required": [
        "element",
        "measurement_type_0",
        "measurement_value_0"
      ]
    }
  },
  {
    "name": "report_list / measurement / 1 / less-than",
    "conditions": {
      "type": {
        "is": "measurement"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "report_list"
      },
      "measurement_count": {
        "equal": 1
      },
      "measurement_operator": {
        "is": "less-than"
      }
    },
    "event": {
      "template": "The {{{element}}} has {{{indefinite measurement_type_0}}} less than {{{measurement_value_0}}}.",
      "required": [
        "element",
        "measurement_type_0",
        "measurement_value_0"
      ]
    }
  },
  {
    "name": "patent_specification_preferred_version / measurement / 1 / less-than-or-equal",
    "conditions": {
      "type": {
        "is": "measurement"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "patent_specification_preferred_version"
      },
      "measurement_count": {
        "equal": 1
      },
      "measurement_operator": {
        "is": "less-than-or-equal"
      }
    },
    "event": {
      "template": "In some embodiments, the {{{element}}} may have {{{indefinite measurement_type_0}}} less than or equal to {{{measurement_value_0}}}.",
      "required": [
        "element",
        "measurement_type_0",
        "measurement_value_0"
      ]
    }
  },
  {
    "name": "report_list_abbreviated / measurement / 1 / less-than-or-equal",
    "conditions": {
      "type": {
        "is": "measurement"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "report_list_abbreviated"
      },
      "measurement_count": {
        "equal": 1
      },
      "measurement_operator": {
        "is": "less-than-or-equal"
      }
    },
    "event": {
      "template": "{{{indefinite measurement_type_0 true}}} less than or equal to {{{measurement_value_0}}}.",
      "required": [
        "element",
        "measurement_type_0",
        "measurement_value_0"
      ]
    }
  },
  {
    "name": "report_list / measurement / 1 / less-than-or-equal",
    "conditions": {
      "type": {
        "is": "measurement"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "report_list"
      },
      "measurement_count": {
        "equal": 1
      },
      "measurement_operator": {
        "is": "less-than-or-equal"
      }
    },
    "event": {
      "template": "The {{{element}}} has {{{indefinite measurement_type_0}}} less than or equal to {{{measurement_value_0}}}.",
      "required": [
        "element",
        "measurement_type_0",
        "measurement_value_0"
      ]
    }
  },
  {
    "name": "patent_specification_preferred_version / measurement / 1 / greater-than",
    "conditions": {
      "type": {
        "is": "measurement"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "patent_specification_preferred_version"
      },
      "measurement_count": {
        "equal": 1
      },
      "measurement_operator": {
        "is": "greater-than"
      }
    },
    "event": {
      "template": "In some embodiments, the {{{element}}} may have {{{indefinite measurement_type_0}}} greater than {{{measurement_value_0}}}.",
      "required": [
        "element",
        "measurement_type_0",
        "measurement_value_0"
      ]
    }
  },
  {
    "name": "report_list_abbreviated / measurement / 1 / greater-than",
    "conditions": {
      "type": {
        "is": "measurement"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "report_list_abbreviated"
      },
      "measurement_count": {
        "equal": 1
      },
      "measurement_operator": {
        "is": "greater-than"
      }
    },
    "event": {
      "template": "{{{indefinite measurement_type_0 true}}} greater than {{{measurement_value_0}}}.",
      "required": [
        "element",
        "measurement_type_0",
        "measurement_value_0"
      ]
    }
  },
  {
    "name": "report_list / measurement / 1 / greater-than",
    "conditions": {
      "type": {
        "is": "measurement"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "report_list"
      },
      "measurement_count": {
        "equal": 1
      },
      "measurement_operator": {
        "is": "greater-than"
      }
    },
    "event": {
      "template": "The {{{element}}} has {{{indefinite measurement_type_0}}} greater than {{{measurement_value_0}}}.",
      "required": [
        "element",
        "measurement_type_0",
        "measurement_value_0"
      ]
    }
  },
  {
    "name": "patent_specification_preferred_version / measurement / 1 / greater-than-or-equal",
    "conditions": {
      "type": {
        "is": "measurement"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "patent_specification_preferred_version"
      },
      "measurement_count": {
        "equal": 1
      },
      "measurement_operator": {
        "is": "greater-than-or-equal"
      }
    },
    "event": {
      "template": "In some embodiments, the {{{element}}} may have {{{indefinite measurement_type_0}}} greater than or equal to {{{measurement_value_0}}}.",
      "required": [
        "element",
        "measurement_type_0",
        "measurement_value_0"
      ]
    }
  },
  {
    "name": "report_list_abbreviated / measurement / 1 / greater-than-or-equal",
    "conditions": {
      "type": {
        "is": "measurement"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "report_list_abbreviated"
      },
      "measurement_count": {
        "equal": 1
      },
      "measurement_operator": {
        "is": "greater-than-or-equal"
      }
    },
    "event": {
      "template": "{{{indefinite measurement_type_0 true}}} greater than or equal to {{{measurement_value_0}}}.",
      "required": [
        "element",
        "measurement_type_0",
        "measurement_value_0"
      ]
    }
  },
  {
    "name": "report_list / measurement / 1 / greater-than-or-equal",
    "conditions": {
      "type": {
        "is": "measurement"
      },
      "subject": {
        "is": "feature"
      },
      "language": {
        "is": "report_list"
      },
      "measurement_count": {
        "equal": 1
      },
      "measurement_operator": {
        "is": "greater-than-or-equal"
      }
    },
    "event": {
      "template": "The {{{element}}} has {{{indefinite measurement_type_0}}} greater than or equal to {{{measurement_value_0}}}.",
      "required": [
        "element",
        "measurement_type_0",
        "measurement_value_0"
      ]
    }
  }
]