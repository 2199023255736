import { cancel, later } from '@ember/runloop';

import Component from '@glimmer/component';
import { action } from '@ember/object';
import { connect } from 'ember-redux';
import { getElementColumn } from '../../../selectors/invention-ui';
import podNames from 'ember-component-css/pod-names';

const dispatchToActions = {};

const stateToComputed = (state, attrs) => ({
  column: getElementColumn(state, attrs.columnId),
});

class ElementTableHeaderCell extends Component {
  get styleNamespace() {
    return podNames['element-table-header-cell'];
  }

  get classNames() {
    let classNames = [this.styleNamespace, 'element-table-header-cell'];
    return classNames.join(' ');
  }

  get cantWrap() {
    return !this.column.wrappable;
  }

  @action
  onMoveLeft() {}

  @action
  onMoveRight() {}

  @action
  onHide() {
    
  }

  @action
  onSetSize(width) {
    if (this.args.onUpdateElementColumn) {
      this.args.onUpdateElementColumn(this.args.columnId, { width })
    }
  }

  @action
  onToggleWrap() {
    if (this.args.onUpdateElementColumn) {
      const wrap = !this.column.wrap;
      this.args.onUpdateElementColumn(this.args.columnId, { wrap })
    }
  }

  prevent(e) {
    return e.stopImmediatePropagation();
  }

  @action
  open(dropdown) {
    if (this.closeTimer) {
      cancel(this.closeTimer);
      this.closeTimer = null;
    } else {
      dropdown.actions.open();
    }
  }

  @action
  closeLater(dropdown) {
    this.closeTimer = later(() => {
      this.closeTimer = null;
      dropdown.actions.close();
    }, 200);
  }

  @action
  onNestedMouseenter() {
    cancel(this.closeTimer);
  }

  @action
  onNestedMouseleave(dropdown) {
    this.closeTimer = later(() => {
      this.closeTimer = null;
      dropdown.actions.close();
    }, 200);
  }

  calculateNestedPosition(trigger) {
    let { top, left, width } = trigger.getBoundingClientRect();
    let style = {
      left: left + width,
      top: top,
    };

    return { style };
  }
}

export default connect(
  stateToComputed,
  dispatchToActions
)(ElementTableHeaderCell);
