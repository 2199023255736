import ENV from '../config/environment';
import Service from '@ember/service';
import localforage from 'localforage';

const dbPrefix = ENV.DB_PREFIX || '';
const dbName =
  ENV.environment === 'test' ? 'patentdive_test' : `${dbPrefix}_inventions`;

const db = localforage.createInstance({
  name: dbName,
});

export default Service.extend({
  db,

  async getInvention(inventionId) {
    const invention = await this.db.getItem(inventionId);
    return invention;
  },
});
