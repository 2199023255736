import { getPresentState } from '../utils/redux';
import { get } from '@ember/object';

export const getAsset = (state, assetId) =>
  get(getPresentState(state), `assets.entities.${assetId}`);
  
export const getAssets = (state) =>
  get(getPresentState(state), `assets.entities`);

export const getAssetsList = (state) => get(getPresentState(state), `assets.ids`);
