import { UPDATE_PATENT_SPECIFICATION } from './actionTypes';

export const updatePatentSpecification = (attributes) => {
  return {
    type: UPDATE_PATENT_SPECIFICATION,
    payload: {
      attributes,
    },
  };
};
