import Component from '@glimmer/component';
import podNames from "ember-component-css/pod-names";

export default class UiFormInput extends Component {
  get size() {
    return this.args.size || 'normal';
  }

  get styleNamespace() {
    return podNames['ui-form-input'];
  }
}
