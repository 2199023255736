import Component from '@glimmer/component';
import { action } from '@ember/object';
import { connect } from 'ember-redux';
import { getProduct } from '../../../selectors/product';
import podNames from 'ember-component-css/pod-names';

const stateToComputed = (state, attrs) => ({
  product: getProduct(state, attrs.productId),
});

const dispatchToActions = {};

class ExplorerProductNavItem extends Component {
  get styleNamespace() {
    return podNames['explorer-product-nav-item'];
  }

  get classNames() {
    let classNames = [this.styleNamespace];
    if (this.args.isActive) classNames.push('is-active');
    return classNames.join(' ');
  }

  @action
  onMousedown() {
    this.args.onSetActiveProduct(this.args.productId);
  }

  @action
  onRemoveMousedown(e) {
    e.stopPropagation();
  }

  @action
  onRemoveClick() {
    this.args.onRemoveProduct(this.args.productId);
  }
}

export default connect(
  stateToComputed,
  dispatchToActions
)(ExplorerProductNavItem);
