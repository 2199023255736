import Model, { attr } from '@ember-data/model';

export default class ElementModel extends Model {
  @attr description;
  @attr viewAngle;
  @attr orientation;
  @attr size;
  @attr x;
  @attr y;
  @attr k;
  @attr imagesList;
  @attr markersList;
  @attr highlightsList;
  @attr createdAt;
}
