import { alias, or } from '@ember/object/computed';
// eslint-disable-next-line ember/no-observers
import { computed, observer } from '@ember/object';
import { countBy, omit } from 'lodash';
import {
  deselectElement,
  hideScratchpad,
  selectElement,
  selectElementVersion,
  selectMarker,
  selectTerm,
  setActiveContextTab,
  showNotes,
} from '../../../actions/invention-ui';
import {
  getActiveNavigationStack,
  getCollapsedNodesList,
  getInventionUi,
  getSelectedClaims,
  getSelectedEdges,
  getSelectedElementId,
  getSelectedElementVersions,
  getSelectedElements,
  getSelectedTermId,
  getSelectedTerms,
} from '../../../selectors/invention-ui';
import {
  getComparison,
  getComparisonsList,
} from '../../../selectors/comparison';
import {
  getComponent,
  getPrimaryInstancesList,
} from '../../../selectors/component';
import {
  getElement,
  getElements,
  getElementsList,
} from '../../../selectors/element';
import {
  getElementVersion,
  getElementVersions,
  getElementVersionsList,
} from '../../../selectors/element-version';
import {
  getElementVersionsMap,
  getElementsMap,
  getRootNodeId,
} from '../../../selectors/graph';
import {
  getFeature,
  getFeatures,
  getFeaturesList,
} from '../../../selectors/feature';
import {
  getInvention,
  getInventionPatentSpecification,
} from '../../../selectors/invention';
import { task, timeout } from 'ember-concurrency';

/* eslint-disable ember/no-observers */
import Component from '@ember/component';
import ENV from '../../../config/environment';
import { batchGroupBy } from '../../../utils/redux';
import { connect } from 'ember-redux';
import { getDisclosureId } from '../../../selectors/meta';
import { getDrawingsList } from '../../../selectors/drawing';
import { getHighlights } from '../../../selectors/highlight';
import { getMarker } from '../../../selectors/marker';
import { getSettings } from '../../../selectors/settings';
import { inject as service } from '@ember/service';
import { setRootNode } from '../../../actions/graph';
import uuid from 'uuid/v4';

const dispatchToActions = {};

const stateToComputed = (state, attrs) => ({
  invention: getInvention(state, attrs.inventionId),
  settings: getSettings(state),
  inventionUi: getInventionUi(state),
  elementsTreeMap: getElementsMap(state),
  elementVersionsMap: getElementVersionsMap(state),
  elementVersionsList: getElementVersionsList(state),
  elementsList: getElementsList(state),
  featuresList: getFeaturesList(state),
  highlights: getHighlights(state),
  features: getFeatures(state),
  elements: getElements(state),
  elementVersions: getElementVersions(state),
  rootNodeId: getRootNodeId(state),
  selectedEdges: getSelectedEdges(state),
  selectedTerms: getSelectedTerms(state),
  selectedTermId: getSelectedTermId(state),
  selectedClaims: getSelectedClaims(state),
  selectedElements: getSelectedElements(state),
  selectedElementId: getSelectedElementId(state),
  selectedElementVersions: getSelectedElementVersions(state),
  inventionPatentSpecificationId: getInventionPatentSpecification(state),
  comparisonsList: getComparisonsList(state),
  primaryInstancesList: getPrimaryInstancesList(state),
  activeNavigationStack: getActiveNavigationStack(state),
  disclosureId: getDisclosureId(state),
});

const InventionWorkArea = Component.extend({
  classNames: ['work-area'],
  inventionId: null,
  releaseVersion: ENV.releaseVersion,
  isProduction: ENV.environment === 'production',
  data: service(),
  applicationState: service(),
  contextMenu: service(),
  models: service(),
  redux: service(),
  clipboard: service(),
  notify: service(),
  tracking: service(),
  patentSpecificationDocx: service(),
  figuresExportPdf: service(),
  undoIndex: alias('applicationState.undoIndex'),
  popoverOffset: 20,
  popoverSide: 'top-start',
  autoArrangeIndex: alias('applicationState.autoArrangeIndex'),
  focusedInputModelId: alias('applicationState.focusedInputModelId'),
  focusedInputModelType: alias('applicationState.focusedInputModelType'),
  focusedInputModelAttr: alias('applicationState.focusedInputModelAttr'),
  focusedInputFeatureType: alias('applicationState.focusedInputFeatureType'),
  activeView: alias('inventionUi.activeView'),
  activeContextTab: alias('inventionUi.activeContextTab'),
  activeDrawing: alias('inventionUi.activeDrawing'),
  activeProduct: alias('inventionUi.activeProduct'),
  previewMode: alias('inventionUi.previewMode'),
  showingHistoryVersionCreateModal: alias(
    'inventionUi.showingHistoryVersionCreateModal'
  ),
  showingPatentSpecificationEditor: alias('inventionUi.showingPatentSpecificationEditor'),
  creatingImageAsset: alias('inventionUi.creatingImageAsset'),
  updatingImageAsset: alias('inventionUi.updatingImageAsset'),
  updatingPriorArtAsset: alias('inventionUi.updatingPriorArtAsset'),
  showingAssets: alias('inventionUi.showingAssets'),
  showingComparisonMatrix: alias('inventionUi.showingComparisonMatrix'),
  showingPriorArts: alias('inventionUi.showingPriorArts'),
  showingCustomers: alias('inventionUi.showingCustomers'),
  showingPatentability: alias('inventionUi.showingPatentability'),
  updatingProductPriorArt: alias('inventionUi.updatingProductPriorArt'),
  updatingProductCustomers: alias('inventionUi.updatingProductCustomers'),
  showingProductChecklist: alias('inventionUi.showingProductChecklist'),
  showingProducts: alias('inventionUi.showingProducts'),
  showingInventionSummaryEditor: alias(
    'inventionUi.showingInventionSummaryEditor'
  ),
  showingPatentSpecificationDetailedDescription: alias(
    'inventionUi.showingPatentSpecificationDetailedDescription'
  ),
  showingPatentSpecificationClaims: alias(
    'inventionUi.showingPatentSpecificationClaims'
  ),
  showingPatentSpecificationAbstract: alias(
    'inventionUi.showingPatentSpecificationAbstract'
  ),
  showingSettings: alias('inventionUi.showingSettings'),
  showNovelty: alias('settings.showNovelty'),
  selectedProducts: alias('inventionUi.selectedProducts'),
  activeFeature: alias('inventionUi.activeFeature'),

  selectedEdge: computed('selectedEdges.[]', function () {
    return this.selectedEdges.length === 1 && this.selectedEdges[0];
  }),
  activeVersion: alias('inventionUi.activeVersion'),
  showingDrawings: alias('inventionUi.showingDrawings'),
  showingNotes: alias('inventionUi.showingNotes'),
  showingComparison: alias('inventionUi.showingComparison'),
  showingDrawingSelectionModal: alias(
    'inventionUi.showingDrawingSelectionModal'
  ),
  activeMethod: alias('inventionUi.activeMethod'),
  showingPatentSpecification: alias('inventionUi.showingPatentSpecification'),
  showingImageUploadModal: alias('inventionUi.showingImageUploadModal'),
  showingExplorer: alias('inventionUi.showingExplorer'),
  showingFigures: alias('inventionUi.showingFigures'),
  showingSearch: alias('inventionUi.showingSearch'),
  cmdKeyDown: alias('applicationState.cmdKeyDown'),
  shiftKeyDown: alias('applicationState.shiftKeyDown'),
  showBackButton: or('activeDrawing', 'activeMethod'),

  popperOptions: Object.freeze({
    modifiers: {
      preventOverflow: {
        escapeWithReference: false,
      },
    },
  }),

  featureModels: computed('featuresList.[]', function () {
    const featureModels =
      this.featuresList &&
      this.featuresList.map((featureId) => {
        return this.models.find(featureId);
      });
    return featureModels;
  }),

  novelFeatureModels: computed('featureModels.@each.novel', function () {
    return this.featureModels.filter((featureModel) => featureModel.novel);
  }),

  selectedElementId: computed('selectedElements.[]', function () {
    return this.selectedElements.length === 1 && this.selectedElements[0];
  }),

  selectedElement: computed('selectedElementId', function () {
    const state = this.redux.getState();
    return this.selectedElementId && getElement(state, this.selectedElementId);
  }),

  selectedElementModel: computed('selectedElementId', function () {
    const state = this.redux.getState();
    const element = getElement(state, this.selectedElementId);
    return (
      this.models.find(this.selectedElementId) ||
      this.models.findOrCreate(this.selectedElementId, 'element', element)
    );
  }),

  selectedProductId: computed('selectedProducts.[]', function () {
    return this.selectedProducts.length === 1 && this.selectedProducts[0];
  }),

  comparisons: computed(
    'comparisonsList.[]',
    'activeProduct',
    'showNovelty',
    function () {
      const state = this.redux.getState();
      return this.showNovelty && this.activeProduct
        ? this.comparisonsList
            .map((comparisonId) => getComparison(state, comparisonId))
            .filter(
              (comparison) =>
                comparison.product && comparison.product === this.activeProduct
            )
        : [];
    }
  ),

  comparisonsCount: alias('comparisons.length'),

  novelFeaturesList: computed('comparisons.@each.featuresList', function () {
    const state = this.redux.getState();
    let featuresList = [];

    this.comparisons.forEach((comparison) => {
      // TODO need to clean up comparison featuresList when features are deleted
      const filteredFeaturesList = comparison.featuresList.filter((featureId) =>
        getFeature(state, featureId)
      );
      featuresList = [...featuresList, ...filteredFeaturesList];
    });
    return featuresList;
  }),

  novelElementsList: computed('novelFeaturesList.[]', function () {
    const state = this.redux.getState();
    const novelElementsList = this.novelFeaturesList.map((featureId) => {
      const feature = getFeature(state, featureId);
      return feature.element;
    });

    return novelElementsList.uniq();
  }),

  // eslint-disable-next-line ember/require-computed-property-dependencies
  totallyNovelFeaturesList: computed(
    'comparisonsCount',
    'novelFeaturesList.[]',
    function () {
      const novelOccurences = countBy(this.novelFeaturesList);
      const comparisonsCount = this.comparisonsCount;
      return this.novelFeaturesList
        .filter((featureId) => novelOccurences[featureId] === comparisonsCount)
        .uniq();
    }
  ),

  totallyNovelElementsList: computed(
    'comparisonsCount',
    'totallyNovelFeaturesList.[]',
    function () {
      const state = this.redux.getState();
      const totallyNovelElementsList = this.totallyNovelFeaturesList.map(
        (featureId) => {
          const feature = getFeature(state, featureId);
          return feature.element;
        }
      );
      return totallyNovelElementsList;
    }
  ),

  novelAncestorsList: computed(
    'novelElementsList.[]',
    'elementsTreeMap',
    'activeProduct',
    function () {
      const state = this.redux.getState();
      let novelAncestorsList = [];
      if (this.novelElementsList) {
        const elementsTreeMap = getElementsMap(state, this.activeProduct);
        this.novelElementsList.uniq().forEach((elementId) => {
          const node = elementsTreeMap[elementId];
          if (node && node.ancestors.length) {
            const ancestors = node.ancestors.filter((id) => id !== node.id);
            novelAncestorsList = [...novelAncestorsList, ...ancestors];
          }
        });
      }
      const uniqAncestors = novelAncestorsList
        .filter((id) => id !== 'root')
        .uniq();
      return uniqAncestors;
    }
  ),

  selectedElementVersionId: computed('selectedElementVersions.[]', function () {
    return (
      this.selectedElementVersions.length === 1 &&
      this.selectedElementVersions[0]
    );
  }),

  selectedClaimId: computed('selectedClaims.[]', function () {
    return this.selectedClaims.length === 1 && this.selectedClaims[0];
  }),

  selectedTermId: computed('selectedTerms.[]', function () {
    return this.selectedTerms.length === 1 && this.selectedTerms[0];
  }),

  selectedImages: alias('inventionUi.selectedImages'),
  selectedMarkers: alias('inventionUi.selectedMarkers'),
  selectedImageId: computed(
    'selectedImages.[]',
    'selectedMarkers.[]',
    function () {
      return this.selectedImages.length === 1 &&
        this.selectedMarkers.length === 0
        ? this.selectedImages[0]
        : null;
    }
  ),
  selectedMarkerId: computed(
    'selectedImages.[]',
    'selectedMarkers.[]',
    function () {
      return this.selectedMarkers.length === 1 &&
        this.selectedImages.length === 0
        ? this.selectedMarkers[0]
        : null;
    }
  ),
  selectedMethodNodes: alias('inventionUi.selectedMethodNodes'),
  selectedMethodEdges: alias('inventionUi.selectedMethodEdges'),
  selectedMethodNodeId: computed('selectedMethodNodes.[]', function () {
    return this.selectedMethodNodes && this.selectedMethodNodes.length === 1
      ? this.selectedMethodNodes[0]
      : null;
  }),
  selectedMethodEdgeId: computed('selectedMethodEdges.[]', function () {
    return this.selectedMethodEdges && this.selectedMethodEdges.length === 1
      ? this.selectedMethodEdges[0]
      : null;
  }),

  updateModels() {
    const elementVersionModels = [];
    const elementModels = [];

    this.featuresList.forEach((featureId) => {
      this.models.findOrCreate(featureId, 'feature', this.features[featureId]);
    });

    this.elementVersionsList.forEach((elementVersionId) => {
      const model = this.models.findOrCreate(
        elementVersionId,
        'elementVersion',
        this.elementVersions[elementVersionId]
      );

      elementVersionModels.push(model);
    });

    this.elementsList.forEach((elementId) => {
      const model = this.models.findOrCreate(
        elementId,
        'element',
        this.elements[elementId]
      );
      elementModels.push(model);
    });

    this.setProperties({ elementVersionModels, elementModels });
  },

  // eslint-disable-next-line ember/no-observers
  modelsObserver: observer(
    'elementVersions',
    'elements',
    'features',
    function () {
      if (
        this &&
        this.featuresList &&
        this.elementsList &&
        this.elementVersionsList
      ) {
        this.updateModels();
      }
    }
  ),

  init() {
    this._super(...arguments);
    this.set('keyboardActivated', true);
    this.updateModels();
  },

  didInsertElement() {
    this._super(...arguments);
    this.onMentionClick = this.handleMentionClick.bind(this);
    this.onMentionMouseenter = this.handleMentionMouseenter.bind(this);
    this.onMentionMouseleave = this.handleMentionMouseleave.bind(this);
    this.onMentionContextmenu = this.handleMentionContextmenu.bind(this);
    window.addEventListener('mention-click', this.onMentionClick, false);
    window.addEventListener(
      'mention-contextmenu',
      this.onMentionContextmenu,
      false
    );
    window.addEventListener(
      'mention-mouseenter',
      this.onMentionMouseenter,
      false
    );
    window.addEventListener(
      'mention-mouseleave',
      this.onMentionMouseleave,
      false
    );
  },

  willDestroyElement() {
    this._super(...arguments);
    window.removeEventListener('mention-click', this.onMentionClick, false);
    window.removeEventListener(
      'mention-mouseenter',
      this.onMentionMouseenter,
      false
    );
    window.removeEventListener(
      'mention-mouseleave',
      this.onMentionMouseleave,
      false
    );
  },

  handleMentionClick(e) {
    e.stopPropagation();
    const { type, id } = e.detail;

    this.setProperties({
      popoverId: null,
      popoverType: null,
      popoverElementVersionId: null,
      popoverDomElementId: null,
      hoveringPopover: false,
    });

    batchGroupBy.start();
    switch (type) {
      case 'element': {
        const state = this.redux.getState();
        const element = getElement(state, id);
        let elementId = id;

        if (element && element.component && element.isComponent) {
          const component = getComponent(state, element.component);
          elementId = component.primaryInstance;
        }

        if (this.isGraphView) {
          // this.data.zoomToNode(elementId, true);
          this.redux.store.dispatch(selectElement(elementId));
        } else {
          this.redux.store.dispatch(selectElement(id));
        }
        this.tracking.trackEvent('clicked_system_mention');
        break;
      }
      case 'term':
        this.redux.store.dispatch(selectTerm(id));
        this.tracking.trackEvent('clicked_term_mention');
        break;
      case 'method':
        this.data.stackNavigationPush('method', 'method', id);
        break;
      case 'drawing':
        this.data.stackNavigationPush('drawing', 'drawing', id);
        break;
    }

    this.data.setShowingComparisonMatrix(false);

    batchGroupBy.end();
  },

  handleMentionMouseenter(e) {
    e.stopPropagation();
    const { type, id, domElementId, elementVersionId } = e.detail;
    this.set('hoveringPopover', true);
    this.openPopover.perform(type, id, domElementId, elementVersionId);
  },

  handleMentionMouseleave(e) {
    e.stopPropagation();
    this.set('hoveringPopover', false);
    this.closePopover.perform();
  },

  handleMentionContextmenu(e) {
    e.preventDefault();
    e.stopPropagation();

    const { id, domElementId, type } = e.detail;
    const domElement = document.getElementById(domElementId);
    const rect = domElement.getBoundingClientRect(domElement);
    this.contextMenu.open('mention', {
      targetId: id,
      type,
      x: rect.left,
      y: rect.top,
      domElementId,
    });

    return false;
  },

  openPopover: task(function* (
    popoverType,
    popoverId,
    popoverDomElementId,
    popoverElementVersionId,
    delay = 750
  ) {
    if (!this.popoverId) {
      yield timeout(delay);
    } else {
      yield timeout(50);
    }

    if (popoverId !== this.popoverId) {
      this.toggleProperty('popoverToggle');
    }

    // const domElement = document.getElementById(popoverDomElementId);

    let popoverOffset = this.popoverOffset;
    let popoverSide = this.popoverSide;

    // if (domElement) {
    //   const rightContentContainer = domElement.closest(
    //     '.right-context-container'
    //   );
    //   if (rightContentContainer) {
    //     const domRect = domElement.getBoundingClientRect();
    //     const containerRect = rightContentContainer.getBoundingClientRect();

    //     const offsetLeft = domRect.left - containerRect.left;
    //     popoverOffset = offsetLeft;
    //     popoverSide = 'left-start';
    //   }
    // }

    if (
      this.hoveringPopover &&
      popoverDomElementId &&
      !this.contextMenu.isActive
    ) {
      this.setProperties({
        popoverId,
        popoverType,
        popoverDomElementId,
        popoverElementVersionId,
        popoverOffset,
        popoverSide,
      });

      if (popoverType === 'term') {
        this.tracking.trackEvent('opened_term_popover');
      } else if (popoverType === 'drawing') {
        this.tracking.trackEvent('opened_drawing_popover');
      } else if (popoverType === 'method') {
        this.tracking.trackEvent('opened_method_popover');
      } else {
        this.tracking.trackEvent('opened_system_popover');
      }
    }
  }).restartable(),

  closePopover: task(function* () {
    yield timeout(500);

    if (!this.hoveringPopover) {
      this.setProperties({
        popoverId: null,
        popoverType: null,
        popoverElementVersionId: null,
        popoverDomElementId: null,
        popoverOffset: 0,
        popoverSide: 'top-start',
      });
    }
  }).restartable(),

  // For now cant nest any instances
  // TODO make this so cant nest instances of itself within it self
  canAddComprisesRelationship(sourceElementVersionId, targetElementId) {
    const state = this.redux.getState();
    let targetDescendants =
      this.elementVersionsMap[targetElementId].descendants;
    targetDescendants = [...targetDescendants, targetElementId];

    const targetDescendantIsInstance = targetDescendants.find((nodeId) => {
      const element = getElement(state, nodeId);
      return element && element.instanceOf;
    })
      ? true
      : false;

    let sourceAncestors =
      this.elementVersionsMap[sourceElementVersionId].ancestors;

    sourceAncestors = [...sourceAncestors, sourceElementVersionId];
    const sourceAncestorIsInstance = sourceAncestors.find((nodeId) => {
      const element = getElement(state, nodeId);
      return element && element.instanceOf;
    })
      ? true
      : false;

    return (
      (!targetDescendantIsInstance && !sourceAncestorIsInstance) ||
      (targetDescendantIsInstance && !sourceAncestorIsInstance) ||
      (sourceAncestorIsInstance && !targetDescendantIsInstance)
    );
  },

  closePopoverNow() {
    this.setProperties({
      popoverId: null,
      popoverType: null,
      popoverElementVersionId: null,
      popoverDomElementId: null,
    });
  },

  // updateContainerAttributes(containerType, containerId, containerValue) {
  //   const state = this.redux.getState();

  //   switch (containerType) {
  //     case 'drawing-description':
  //       this.data.updateDrawing(containerId, {
  //         description: containerValue,
  //       });
  //       break;
  //     case 'drawing-view-angle':
  //       this.data.updateDrawing(containerId, {
  //         viewAngle: containerValue,
  //       });
  //       break;
  //     case 'product-summary':
  //       this.redux.store.dispatch(
  //         updateProduct(containerId, { summary: containerValue })
  //       );
  //       break;
  //     case 'feature-value':
  //       this.redux.store.dispatch(
  //         updateFeature(containerId, { value: containerValue })
  //       );
  //       break;
  //     case 'feature-value-measurement-type': {
  //       const feature = getFeature(state, containerId);
  //       const _value = {
  //         ...feature.value,
  //         measurement_type: containerValue,
  //       };
  //       this.redux.store.dispatch(
  //         updateFeature(containerId, { value: _value })
  //       );
  //       break;
  //     }
  //     case 'feature-value-measurement-value': {
  //       const feature = getFeature(state, containerId);
  //       const _value = {
  //         ...feature.value,
  //         measurement_value: containerValue,
  //       };
  //       this.redux.store.dispatch(
  //         updateFeature(containerId, { value: _value })
  //       );
  //       break;
  //     }
  //     case 'patent-specification-summary':
  //       this.data.updatePatentSpecification({
  //         summary: containerValue,
  //       });
  //       break;
  //     case 'patent-specification-background':
  //       this.data.updatePatentSpecification({
  //         background: containerValue,
  //       });
  //       break;
  //     case 'patent-specification-boilerplate':
  //       this.data.updatePatentSpecification({
  //         boilerplate: containerValue,
  //       });
  //       break;
  //     case 'patent-specification-invention-fields':
  //       this.data.updatePatentSpecification({
  //         inventionFields: containerValue,
  //       });
  //       break;
  //     case 'patent-specification-abstract':
  //       this.data.updatePatentSpecification({
  //         abstract: containerValue,
  //       });
  //       break;
  //     case 'note-value':
  //       this.redux.store.dispatch(
  //         updateNote(containerId, {
  //           value: containerValue,
  //         })
  //       );
  //       break;
  //   }
  // },

  updateModelAttributes(modelId, modelType, modelAttr, value) {
    const attributes = {
      [modelAttr]: value,
    };
    switch (modelType) {
      case 'drawing':
        this.data.updateDrawing(modelId, attributes);
        break;
      case 'product':
        this.data.updateProduct(modelId, attributes);
        break;
      case 'feature':
        this.data.updateFeature(modelId, attributes);
        break;
      case 'patent-specification':
        this.data.updatePatentSpecification(attributes);
        break;
      case 'note':
        this.data.updateNote(modelId, attributes);
        break;
      // case 'feature-value-measurement-type': {
      //   const feature = getFeature(state, containerId);
      //   const _value = {
      //     ...feature.value,
      //     measurement_type: containerValue,
      //   };
      //   this.redux.store.dispatch(
      //     updateFeature(containerId, { value: _value })
      //   );
      //   break;
      // }
      // case 'feature-value-measurement-value': {
      //   const feature = getFeature(state, containerId);
      //   const _value = {
      //     ...feature.value,
      //     measurement_value: containerValue,
      //   };
      //   this.redux.store.dispatch(
      //     updateFeature(containerId, { value: _value })
      //   );
      //   break;
      // }
    }
  },

  actions: {
    onOpenContextMenu() {
      this.closePopoverNow();
    },

    toggleDebug() {
      if (this.onToggleDebug) {
        this.onToggleDebug();
      }
    },

    toggleShowingExplorer() {
      if (this.showingExplorer) {
        this.data.hideExplorer();
      } else {
        this.data.showExplorer();
      }
    },

    onPopoverMouseenter(/*popoverTermId, popoverDomElementId*/) {
      this.set('hoveringPopover', true);
    },

    onPopoverMouseleave() {
      this.set('hoveringPopover', false);
      this.closePopover.perform();
    },

    onHide() {
      this.setProperties({
        popoverId: null,
        popoverType: null,
        popoverElementVersionId: null,
        popoverDomElementId: null,
      });
    },
    toggleScratchpad() {
      if (this.showingNotes) {
        this.redux.store.dispatch(hideScratchpad(this.inventionId));
      } else {
        this.redux.store.dispatch(showNotes(this.inventionId));
      }
    },

    onRemoveTerm(termId) {
      batchGroupBy.start();
      this.data.removeTerm(termId);
      batchGroupBy.end();
    },

    onAddTerm() {
      batchGroupBy.start();
      const termId = this.data.addTerm();
      this.data.selectTerm(termId);
      batchGroupBy.end();
    },

    onUpdateTerm(termId, attributes = {}) {
      batchGroupBy.start();
      this.data.updateTerm(termId, attributes);
      batchGroupBy.end();
    },

    onAddTermDefinition(termId, type) {
      batchGroupBy.start();
      const featureId = this.data.addTermDefinition(termId, type);
      this.data.setActiveFeature(featureId);
      batchGroupBy.end();
    },

    onRemoveTermDefinition(termId, featureId) {
      batchGroupBy.start();
      this.data.removeTermDefinition(termId, featureId);
      this.data.setActiveFeature(null);
      batchGroupBy.end();
    },

    onTermContextMenu(event, termId) {
      event.preventDefault();
      this.data.selectTerm(termId);

      this.contextMenu.open('term', {
        targetId: termId,
        x: event.clientX,
        y: event.clientY,
      });
    },

    onDrawingContextMenu(event, drawingId) {
      event.preventDefault();

      this.contextMenu.open('drawing', {
        targetId: drawingId,
        x: event.clientX,
        y: event.clientY,
      });
    },

    onRemoveTerms(termsList) {
      batchGroupBy.start();
      this.data.removeTerms(termsList);
      batchGroupBy.end();
    },

    onRemoveSelectedTreeItems(
      selectedElements,
      selectedElementVersions,
      selectedEdges
    ) {
      let state = this.redux.getState();

      // filter any lone elementVersions
      selectedElementVersions = selectedElementVersions.filter(
        (elementVersionId) => {
          const elementVersion = getElementVersion(state, elementVersionId);
          const element =
            elementVersion.element && getElement(state, elementVersion.element);
          const hasSiblings = element && element.elementVersionsList.length > 1;
          const hasParent = element ? true : false;
          return hasParent ? hasSiblings : true;
        }
      );

      // warn if any descendants are primary component instances
      // let descendantsArePrimaryInstances = false;
      let primaryInstancesList = [];
      const selectedNodes = [...selectedElements, ...selectedElementVersions];
      selectedNodes.forEach((nodeId) => {
        const descendantsList = this.elementVersionsMap[nodeId]
          ? [nodeId, ...this.elementVersionsMap[nodeId].instanceDescendants]
          : [];
        const descendantPrimaryInstancesList = descendantsList.filter(
          (descendantId) => {
            const descendant = this.elementVersionsMap[descendantId];
            return (
              descendant &&
              descendant.type === 'element' &&
              descendant.isInstance &&
              this.primaryInstancesList.includes(descendantId)
            );
          }
        );
        primaryInstancesList = [
          ...primaryInstancesList,
          ...descendantPrimaryInstancesList,
        ];
      });

      primaryInstancesList = primaryInstancesList.uniq();

      if (primaryInstancesList.length) {
        const componentsText =
          primaryInstancesList.length === 1
            ? 'a component'
            : `(${primaryInstancesList.length}) components`;
        this.notify.warning(
          `You're trying to delete ${componentsText}, you need to detach any components before deleting them`
        );
        return;
      }

      batchGroupBy.start();

      this.data.removeTreeItems(
        selectedElements,
        selectedElementVersions,
        selectedEdges
      );

      this.data.setActiveElementVersionListItem(null);

      // if (shouldDeselectGraph) {
      //   this.redux.store.dispatch(deselectGraph());
      // }

      this.data.updateGraph();

      batchGroupBy.end();

      this.autoArrangeIndex++;
    },

    onToggleCollapsedNode(nodeId) {
      batchGroupBy.start();
      this.data.toggleCollapsedNode(nodeId);
      batchGroupBy.end();
      this.autoArrangeIndex++;
    },

    onCollapseNode(nodeId) {
      batchGroupBy.start();
      this.data.addCollapsedNode(nodeId, this.activeProduct);
      batchGroupBy.end();
      this.autoArrangeIndex++;
    },

    onExpandNode(nodeId) {
      batchGroupBy.start();
      this.data.removeCollapsedNode(nodeId);
      batchGroupBy.end();
      this.autoArrangeIndex++;
    },

    onCollapseChildren(nodesList) {
      const state = this.redux.getState();
      const collapsedNodesList = getCollapsedNodesList(
        state,
        this.activeProduct
      );
      nodesList = nodesList.filter(
        (nodeId) => !collapsedNodesList.includes(nodeId)
      );
      batchGroupBy.start();
      nodesList.forEach((nodeId) =>
        this.data.addCollapsedNode(nodeId, this.activeProduct)
      );
      batchGroupBy.end();
      this.autoArrangeIndex++;
    },

    onExpandChildren(nodesList) {
      batchGroupBy.start();
      nodesList.forEach((nodeId) =>
        this.data.removeCollapsedNode(nodeId, this.activeProduct)
      );
      batchGroupBy.end();
      this.autoArrangeIndex++;
    },

    onDropNode(draggedId, draggedType, droppedId, droppedType, droppedArea) {
      batchGroupBy.start();
      this.data.dropNode(
        draggedId,
        draggedType,
        droppedId,
        droppedType,
        droppedArea
      );
      this.data.updateGraph();
      batchGroupBy.end();

      this.autoArrangeIndex++;
    },

    onAddVersionRelationship(sourceElementId, targetElementVersionId) {
      batchGroupBy.start();
      this.data.addVersionRelationship(sourceElementId, targetElementVersionId);
      this.data.updateGraph();
      batchGroupBy.end();

      this.autoArrangeIndex++;
    },

    onSwitchVersionRelationship(sourceElementId, targetElementVersionId) {
      batchGroupBy.start();
      this.data.switchVersionRelationship(
        sourceElementId,
        targetElementVersionId
      );
      this.data.updateGraph();
      batchGroupBy.end();

      this.autoArrangeIndex++;
    },

    onAddComprisesRelationship(sourceElementVersionId, targetElementId) {
      if (
        !this.canAddComprisesRelationship(
          sourceElementVersionId,
          targetElementId
        )
      ) {
        this.notify.warning(
          `Component instances can't have child or descendant instances`
        );
        return;
      }
      let state = this.redux.getState();

      // get sequence
      const targetElement = getElement(state, targetElementId);
      const targetX = targetElement.x;
      const sequence = this.data.getElementSequenceFromX(
        sourceElementVersionId,
        targetX
      );

      batchGroupBy.start();

      this.data.addComprisesRelationship(
        sourceElementVersionId,
        targetElementId,
        sequence
      );

      this.data.updateGraph();

      batchGroupBy.end();

      this.autoArrangeIndex++;
    },

    onSwitchComprisesRelationship(sourceElementVersionId, targetElementId) {
      if (
        !this.canAddComprisesRelationship(
          sourceElementVersionId,
          targetElementId
        )
      ) {
        this.notify.warning(
          `Component instances can't have child or descendant instances`
        );
        return;
      }
      batchGroupBy.start();
      this.data.switchComprisesRelationship(
        sourceElementVersionId,
        targetElementId
      );

      this.data.updateGraph();

      batchGroupBy.end();

      this.autoArrangeIndex++;
    },

    onCopyElement(elementId, elementVersionId) {
      this.clipboard.copyElement(elementId, elementVersionId);
    },

    onCopyInstances(elementsList) {
      this.clipboard.copyInstances(elementsList);
    },

    onCopyElementVersion(elementVersionId) {
      this.clipboard.copyElementVersion(elementVersionId);
    },

    onZoomToNode(nodeId, select = true) {
      batchGroupBy.start();
      this.data.zoomToNode(nodeId, select);
      batchGroupBy.end();
      // later(() => {
      this.applicationState.zoomToNode = nodeId;
      this.applicationState.zoomToNodeIndex++;
      // })
    },

    onPasteInstance(elementVersionId, instanceId, x, y) {
      batchGroupBy.start();

      this.data.pasteInstance(elementVersionId, instanceId, x, y);

      this.data.updateGraph();

      batchGroupBy.end();

      this.autoArrangeIndex++;
    },

    onPasteInstances(elementVersionId, instancesList = [], x, y) {
      batchGroupBy.start();
      instancesList.forEach((instanceId, index) => {
        let instanceX = x;
        let instanceY = y;
        if (index && !elementVersionId) {
          instanceX = x + (Math.random() * 200 - 400);
          instanceY = y + (Math.random() * 200 - 400);
        }
        this.data.pasteInstance(
          elementVersionId,
          instanceId,
          instanceX,
          instanceY
        );
      });

      this.data.updateGraph();

      batchGroupBy.end();

      this.autoArrangeIndex++;
    },

    onPasteShallowElementDuplicate(elementVersionId, elementId, x, y) {
      batchGroupBy.start();

      this.data.pasteShallowElementDuplicate(elementVersionId, elementId, x, y);

      this.data.updateGraph();

      batchGroupBy.end();

      this.autoArrangeIndex++;
    },

    onPasteShallowElementVersionDuplicate(elementId, elementVersionId, x, y) {
      batchGroupBy.start();

      this.data.pasteShallowElementVersionDuplicate(
        elementId,
        elementVersionId,
        x,
        y
      );

      this.data.updateGraph();

      batchGroupBy.end();

      this.autoArrangeIndex++;
    },

    onPasteSystemProperties(sourceElementId, targetElementId, type) {
      batchGroupBy.start();
      this.data.pasteSystemProperties(sourceElementId, targetElementId, type);
      batchGroupBy.end();
    },

    onPasteSolutionProperties(
      sourceElementVersionId,
      targetElementVersionId,
      type
    ) {
      batchGroupBy.start();
      this.data.pasteSolutionProperties(
        sourceElementVersionId,
        targetElementVersionId,
        type
      );
      batchGroupBy.end();
    },

    onSetPreferredElementVersion(elementId, elementVersionId, productId) {
      productId = productId || this.activeProduct;
      if (productId) {
        batchGroupBy.start();
        this.data.setPreferredElementVersion(
          this.activeProduct,
          elementId,
          elementVersionId
        );

        this.data.updateGraph();

        // this.redux.store.dispatch(
        //   selectElementVersion(elementVersionId)
        // );

        // this.redux.store.dispatch(
        //   selectElementAndElementVersion(elementId, elementVersionId)
        // );

        batchGroupBy.end();
      }

      this.autoArrangeIndex++;
    },

    onSetElementAsRoot(elementId) {
      batchGroupBy.start();
      this.redux.store.dispatch(setRootNode(elementId, 'element'));
      this.data.updateGraph();
      batchGroupBy.end();
    },

    onSetAsPrimaryInstance(elementId) {
      batchGroupBy.start();
      this.data.setAsPrimaryInstance(elementId);
      this.data.updateGraph();
      batchGroupBy.end();

      this.autoArrangeIndex++;
    },

    onCreateComponent(elementId) {
      batchGroupBy.start();
      this.data.addComponent(elementId);
      batchGroupBy.end();
    },

    onSetElementCategory(elementId, category) {
      batchGroupBy.start();
      this.data.updateElement(elementId, { category });
      this.data.updateGraph();
      batchGroupBy.end();
    },

    onDetachComponentInstance(elementId) {
      let state = this.redux.getState();
      const element = getElement(state, elementId);
      const component = getComponent(state, element.component);
      const isPrimary = component.primaryInstance === elementId;
      const additionalInstances = component.instancesList.filter(
        (id) => id !== elementId
      );
      const hasAdditionalInstances = additionalInstances.length ? true : false;

      if (isPrimary && hasAdditionalInstances) {
        const instanceText =
          additionalInstances.length === 1 ? 'instance' : 'instances';
        this.notify.warning(
          `Component has (${additionalInstances.length}) ${instanceText}, you need to delete any instances before detaching this component`
        );
        return;
      }

      batchGroupBy.start();

      if (isPrimary) {
        this.data.detachMainComponentInstance(elementId);
      } else {
        this.data.detachComponentInstance(elementId);
      }

      this.data.updateGraph();

      batchGroupBy.end();
    },

    onConvertElementToTerm(elementId) {
      batchGroupBy.start();
      const termId = this.data.convertElementToTerm(elementId);

      this.redux.store.dispatch(setActiveContextTab('terms'));
      this.redux.store.dispatch(selectTerm(termId));

      this.data.updateGraph();

      batchGroupBy.end();

      this.autoArrangeIndex++;
    },

    onConvertElementToSystem(elementId) {
      batchGroupBy.start();
      this.data.convertElementToSystem(elementId);
      this.data.updateGraph();
      batchGroupBy.end();
    },

    onConvertElementToPart(elementId) {
      batchGroupBy.start();
      this.data.convertElementToPart(elementId);
      this.data.updateGraph();
      batchGroupBy.end();
    },

    onConvertTermToElement(termId, category) {
      batchGroupBy.start();

      const elementId = this.data.convertTermToElement(termId, category);

      this.redux.store.dispatch(selectElement(elementId));
      this.redux.store.dispatch(setActiveContextTab('elements'));

      this.data.updateGraph();

      batchGroupBy.end();

      this.autoArrangeIndex++;
    },

    onElementDragEnd(elementId, x, y) {
      let state = this.redux.getState();
      const element = getElement(state, elementId);
      const parentElementVersionId = element.elementVersion;

      const productId = this.activeProduct;

      batchGroupBy.start();

      this.data.updateElementCoords(elementId, x, y, productId);

      this.tracking.trackEvent('graph_dragged_system');

      if (parentElementVersionId) {
        this.data.updateElementsListSort(
          elementId,
          x,
          y,
          parentElementVersionId
        );
        this.autoArrangeIndex++;
        this.tracking.trackEvent('graph_sorted_systems');
      }

      batchGroupBy.end();
    },

    onCreateElement(
      elementVersionId,
      x,
      y,
      autoArrange = true,
      selectAfterCreate = false
    ) {
      batchGroupBy.start();

      const elementId = this.data.addElement(
        {
          x,
          y,
        },
        elementVersionId
      );

      if (selectAfterCreate) {
        this.redux.store.dispatch(selectElement(elementId));
      }

      this.data.updateGraph();

      batchGroupBy.end();

      if (autoArrange) this.autoArrangeIndex++;

      // this.autoArrangeIndex++;
    },

    onSortComprisesList(elementVersionId, comprisesList) {
      const state = this.redux.getState();
      const elementsList = comprisesList.map((featureId) => {
        const feature = getFeature(state, featureId);
        return feature && feature.value.element
      });
      batchGroupBy.start();

      this.data.updateElementVersion(elementVersionId, {
        elementsList,
        comprisesList,
      });
      this.data.updateGraph();
      batchGroupBy.end();
      this.autoArrangeIndex++;
    },

    onReferenceElement(modelId, modelType, modelAttr, value) {
      this.updateModelAttributes(modelId, modelType, modelAttr, value);
      this.applicationState.updatedFeaturesIndex++;
    },

    onReferenceTerm(modelId, modelType, modelAttr, value) {
      this.updateModelAttributes(modelId, modelType, modelAttr, value);
      this.applicationState.updatedFeaturesIndex++;
    },

    onUnwrapMention(modelId, modelType, modelAttr, value) {
      console.log('cc', modelId, modelType, modelAttr, value)
      this.updateModelAttributes(modelId, modelType, modelAttr, value);
      this.applicationState.updatedFeaturesIndex++;
    },

    onDeleteMention(modelId, modelType, modelAttr, value) {
      this.updateModelAttributes(modelId, modelType, modelAttr, value);
      this.applicationState.updatedFeaturesIndex++;
    },

    onSelectFeature(featureId) {
      const state = this.redux.getState();
      const feature = getFeature(state, featureId);

      batchGroupBy.start();
      if (feature) {
        switch (feature.type) {
          case 'custom_step': {
            this.data.selectCustomStep(featureId);
            break;
          }
          default: {
            if (feature.element) {
              let elementId = feature.element;
              const element = getElement(state, elementId);

              if (element && element.component && element.isComponent) {
                const component = getComponent(state, element.component);
                elementId = component.primaryInstance;
              }
              this.data.selectElement(elementId);
            } else if (feature.term) {
              this.data.selectTerm(feature.term);
            }
          }
        }
      }
      batchGroupBy.end();
    },

    onSelectTerm(termId, isSelected) {
      batchGroupBy.start();
      this.data.selectTerm(termId, isSelected);
      batchGroupBy.end();
    },

    onSortTerms(termsList) {
      batchGroupBy.start();
      this.data.sortTermsList(termsList);
      batchGroupBy.end();
    },

    onAlphabetizeTerms() {
      batchGroupBy.start();
      this.data.alphabetizeTerms();
      batchGroupBy.end();
    },

    onSelectElement(elementId, isSelected) {
      if (isSelected) {
        this.redux.store.dispatch(deselectElement(elementId));
      } else {
        this.redux.store.dispatch(selectElement(elementId));
      }
    },

    onSelectElementVersion(elementVersionId) {
      this.redux.store.dispatch(selectElementVersion(elementVersionId));
    },

    onSelectMethodNode(methodNodeId, isSelected, type) {
      if (isSelected) {
        this.data.deselectMethodNode(methodNodeId);
      } else {
        if (type !== 'start') {
          this.data.selectMethodNode(methodNodeId);
        }
      }
    },

    onSelectMethodEdge(methodEdgeId, isSelected) {
      if (isSelected) {
        this.data.deselectMethodEdge(methodEdgeId);
      } else {
        this.data.selectMethodEdge(methodEdgeId);
      }
    },

    onSelectMethodEdgePoint(bendPointId, isSelected) {
      if (isSelected) {
        this.data.deselectMethodEdgePoint(bendPointId);
      } else {
        this.data.selectMethodEdgePoint(bendPointId);
      }
    },

    onCreateElementFromText(
      name,
      modelId,
      modelType,
      modelAttr,
      value,
      elementId,
      termId,
      category
    ) {
      batchGroupBy.start();

      if (termId) {
        this.data.addTerm(
          {
            name,
          },
          termId
        );
      } else if (elementId) {
        this.data.addElement(
          {
            name,
            category,
          },
          null,
          elementId
        );
      }

      this.updateModelAttributes(modelId, modelType, modelAttr, value);

      this.data.updateGraph();

      batchGroupBy.end();

      // if (termId) {
      //   this.notify.info(
      //     `Added the term '${name}' to the glossary`
      //   );
      // } else {
      //   this.notify.info(
      //     `Added the system '${name}' to the canvas`
      //   );
      // }

      this.applicationState.updatedFeaturesIndex++;
    },

    onCreateElementVersion(
      elementId,
      x,
      y,
      selectAfterCreate = false,
      autoArrange = true
    ) {
      let state = this.redux.getState();

      const element = getElement(state, elementId);

      if (element && element.component && element.instanceOf) {
        elementId = element.instanceOf;
      }

      const category =
        element && element.category === 'system' ? 'process' : 'machine';

      batchGroupBy.start();

      const elementVersionId = this.data.addElementVersion(
        { x, y, category },
        elementId
      );

      // if (this.activeProduct) {
      //   this.data.setPreferredElementVersion(
      //     this.activeProduct,
      //     elementId,
      //     elementVersionId
      //   );

      //   // this.redux.store.dispatch(
      //   //   selectElementAndElementVersion(elementId, elementVersionId)
      //   // );

      //   this.redux.store.dispatch(selectElementVersion(elementVersionId));
      // }

      this.data.setActiveElementVersionListItem(elementVersionId);

      if (selectAfterCreate) {
        this.redux.store.dispatch(selectElementVersion(elementVersionId));
      }

      this.data.updateGraph();

      batchGroupBy.end();

      if (autoArrange) this.autoArrangeIndex++;
      // this.autoArrangeIndex++;
    },

    onCreateElementVersionFromInstance(instanceId) {
      let state = this.redux.getState();

      const instance = getElement(state, instanceId);
      const elementId = instance.instanceOf;
      const instanceOf = getElement(state, elementId);
      const category =
        instanceOf && instanceOf.category === 'system' ? 'process' : 'machine';

      batchGroupBy.start();

      const elementVersionId = this.data.addElementVersion(
        { category },
        elementId
      );

      // if (this.activeProduct) {
      //   this.data.setPreferredElementVersion(
      //     this.activeProduct,
      //     instanceId,
      //     elementVersionId
      //   );

      //   // this.redux.store.dispatch(
      //   //   selectElementAndElementVersion(instanceId, elementVersionId)
      //   // );

      //   this.redux.store.dispatch(selectElementVersion(elementVersionId));
      // }

      this.data.setActiveElementVersionListItem(elementVersionId);

      this.data.updateGraph();

      batchGroupBy.end();

      this.autoArrangeIndex++;
    },

    onCreateParentlessElement(x, y, category) {
      batchGroupBy.start();

      const elementId = this.data.addElement({ x, y, category });

      this.redux.store.dispatch(selectElement(elementId));

      this.data.updateGraph();

      batchGroupBy.end();
    },

    onSelectProduct(productId) {
      batchGroupBy.start();
      this.data.selectProduct(productId);
      batchGroupBy.end();
      this.tracking.trackEvent('selected_product');
    },

    onMarkerSetElement(
      drawingId,
      markerId,
      elementId,
      elementVersionId,
      category
    ) {
      batchGroupBy.start();

      // remove existing marker references
      this.data.removeMarkerReferences(markerId);

      this.data.updateMarker(markerId, {
        category,
        element: elementId,
        elementVersion: elementVersionId,
      });

      this.data.updateDrawing(drawingId, {});

      this.data.addElementVersionMarker(elementVersionId, markerId);

      this.tracking.trackEvent('marker_set_element');

      batchGroupBy.end();
    },

    onMarkerRemoveReference(drawingId, markerId) {
      batchGroupBy.start();

      // remove existing marker references
      this.data.removeMarkerReferences(markerId);

      this.data.updateMarker(markerId, {
        term: null,
        element: null,
        elementVersion: null,
        product: null,
      });

      this.data.updateDrawing(drawingId, {});

      batchGroupBy.end();
    },

    onMarkerSetTerm(drawingId, markerId, termId) {
      batchGroupBy.start();

      // remove existing marker references
      this.data.removeMarkerReferences(markerId);

      this.data.updateMarker(markerId, {
        term: termId,
        category: 'term',
        type: 'term',
        element: null,
        elementVersion: null,
        product: null,
      });

      this.data.addTermMarker(termId, markerId);
      this.tracking.trackEvent('marker_set_term');

      this.data.updateDrawing(drawingId, {});

      batchGroupBy.end();
    },

    onMarkerCreateElement(name, markerId, drawingId, category) {
      let state = this.redux.getState();

      batchGroupBy.start();

      const elementId = uuid();

      this.data.addElement({ name, category }, null, elementId);

      this.data.updateGraph();

      state = this.redux.getState();

      const element = getElement(state, elementId);

      const elementVersionId = element.elementVersionsList[0];

      this.data.removeMarkerReferences(markerId);

      this.data.updateMarker(markerId, {
        element: elementId,
        elementVersion: elementVersionId,
      });

      this.data.updateDrawing(drawingId, {});

      this.redux.store.dispatch(selectMarker(markerId));

      if (elementVersionId) {
        this.data.addElementVersionMarker(elementVersionId, markerId);
      }

      batchGroupBy.end();
    },

    onMarkerCreateTerm(name, markerId, drawingId) {
      batchGroupBy.start();

      const termId = uuid();

      this.data.addTerm({ name }, termId);

      this.data.removeMarkerReferences(markerId);

      this.data.updateMarker(markerId, {
        element: null,
        elementVersion: null,
        term: termId,
      });

      this.data.updateDrawing(drawingId, {});

      this.redux.store.dispatch(selectMarker(markerId));

      this.data.addTermMarker(termId, markerId);

      batchGroupBy.end();
    },

    autoArrange() {},

    onCreateImageAsset(assetId) {
      const drawingId = this.creatingImageAsset;
      batchGroupBy.start();
      this.data.addImageFromAsset(drawingId, assetId);
      batchGroupBy.end();
      this.tracking.trackEvent('created_image');
    },

    onUpdateImageAsset(assetId) {
      const imageId = this.updatingImageAsset;
      batchGroupBy.start();
      this.data.updateImageAsset(imageId, assetId);
      batchGroupBy.end();
      this.tracking.trackEvent('image_updated_asset');
    },

    onUpdateImage(imageId, attributes = {}) {
      batchGroupBy.start();
      this.data.updateImage(imageId, attributes);
      batchGroupBy.end();
    },

    onAddFeature(elementVersionId, featureType) {
      batchGroupBy.start();
      const featureId = this.data.addFeature(elementVersionId, featureType);
      this.data.setActiveFeature(featureId);
      batchGroupBy.end();
      this.tracking.trackEvent('solution_context_added_feature');
    },

    onRemoveFeature(elementVersionId, featureId) {
      batchGroupBy.start();
      this.data.removeFeature(elementVersionId, featureId);
      this.data.setActiveFeature(null);
      batchGroupBy.end();
      this.tracking.trackEvent('solution_context_removed_feature');
    },

    onSortDrawingsList(drawingsList) {
      batchGroupBy.start();
      this.data.sortDrawingsList(drawingsList);
      batchGroupBy.end();
    },

    onAddConstraint(elementVersionId) {
      batchGroupBy.start();
      const featureId = this.data.addConstraint(elementVersionId, 'constraint');
      this.data.setActiveFeature(featureId);
      batchGroupBy.end();
      this.tracking.trackEvent('solution_context_added_user_requirement');
    },

    onRemoveConstraint(elementVersionId, featureId) {
      batchGroupBy.start();
      this.data.removeConstraint(elementVersionId, featureId);
      this.data.setActiveFeature(null);
      batchGroupBy.end();
      this.tracking.trackEvent('solution_context_removed_user_requirement');
    },

    onAddDefinition(elementVersionId, type) {
      batchGroupBy.start();
      const featureId = this.data.addDefinition(elementVersionId, type);
      this.data.setActiveFeature(featureId);
      batchGroupBy.end();
      this.tracking.trackEvent('solution_context_added_definition');
    },

    onRemoveDefinition(elementVersionId, featureId) {
      batchGroupBy.start();
      this.data.removeDefinition(elementVersionId, featureId);
      this.data.setActiveFeature(null);
      batchGroupBy.end();
      this.tracking.trackEvent('solution_context_removed_user_definition');
    },

    onAddRequirement(elementId, type) {
      batchGroupBy.start();
      const featureId = this.data.addRequirement(elementId, type);
      this.data.setActiveFeature(featureId);
      batchGroupBy.end();
      this.tracking.trackEvent('added_requirement');
    },

    onRemoveRequirement(elementId, featureId) {
      batchGroupBy.start();
      this.data.removeRequirement(elementId, featureId);
      this.data.setActiveFeature(null);
      batchGroupBy.end();
      this.tracking.trackEvent('removed_requirement');
    },

    onUpdateElementVersion(elementVersionId, attributes) {
      batchGroupBy.start();
      this.data.updateElementVersion(elementVersionId, attributes);
      batchGroupBy.end();
    },

    onUpdateElement(elementId, attributes) {
      batchGroupBy.start();
      this.data.updateElement(elementId, attributes);
      batchGroupBy.end();
    },

    onSetCategoryGroup(elementId, categoryGroup) {
      batchGroupBy.start();
      this.data.updateElement(elementId, { categoryGroup });
      batchGroupBy.end();
    },

    onAddHighlight(drawingId, markerId, attributes = {}) {
      batchGroupBy.start();
      this.data.addHighlight(drawingId, markerId, attributes);
      batchGroupBy.end();
    },

    onUpdateHighlight(markerId, highlightId, attributes = {}) {
      batchGroupBy.start();
      this.data.updateHighlight(markerId, highlightId, attributes);
      batchGroupBy.end();
    },

    onUpdateMarker(drawingId, markerId, attributes = {}) {
      batchGroupBy.start();
      this.data.updateMarker(markerId, attributes);
      this.data.updateDrawing(drawingId, {});
      batchGroupBy.end();
    },

    onRemoveHighlight(drawingId, markerId, highlightId) {
      batchGroupBy.start();
      this.data.removeHighlight(drawingId, markerId, highlightId);
      batchGroupBy.end();
    },

    onSetMarkerType(markerId, type, category) {
      batchGroupBy.start();
      this.data.setMarkerType(markerId, type, category);
      this.data.setDefaultMarkerCategory(category);
      batchGroupBy.end();
    },

    setActiveDrawing(drawingId) {
      batchGroupBy.start();
      this.data.stackNavigationPush('drawing', 'drawing', drawingId);
      batchGroupBy.end();
    },

    setActiveMethod(methodId) {
      batchGroupBy.start();
      this.data.stackNavigationPush('method', 'method', methodId);
      batchGroupBy.end();
    },

    setShowingPatentSpecification(value) {
      batchGroupBy.start();
      this.data.setShowingPatentSpecification(value);
      batchGroupBy.end();
    },

    onMarkerReferenceClick(markerId) {
      const state = this.redux.getState();
      batchGroupBy.start();
      if (markerId) {
        const marker = getMarker(state, markerId);
        const drawingId = marker && marker.drawing;
        this.data.stackNavigationPush('drawing', 'drawing', drawingId);
        this.data.selectMarker(markerId);
      } else {
        const drawingsList = getDrawingsList(state);
        if (!drawingsList.length) {
          const drawingId = this.data.addDrawing();
          this.tracking.trackEvent('created_drawing');
          this.data.stackNavigationPush('drawing', 'drawing', drawingId);
          const selectedElementId = getSelectedElementId(state);
          if (selectedElementId) {
            this.data.selectElement(selectedElementId);
          }
        } else {
          this.data.setShowingDrawingSelectionModal(true);
        }
      }
      batchGroupBy.end();
    },

    onUpdateFeature(featureId, attributes) {
      batchGroupBy.start();
      this.data.updateFeature(featureId, attributes);
      batchGroupBy.end();
    },

    onCreateMarker(drawingId, attributes) {
      batchGroupBy.start();
      const markerId = this.data.addMarker(drawingId, attributes);
      this.data.selectMarker(markerId);
      this.data.updateFigures();
      batchGroupBy.end();
      this.tracking.trackEvent('created_marker');
    },

    onCreateMarkers(drawingId, markersData) {
      batchGroupBy.start();
      markersData.forEach((attributes) => {
        if (attributes.name && attributes.type === 'term') {
          const termId = uuid();
          const name = attributes.name;
          this.data.addTerm({ name }, termId);
          attributes = omit(attributes, 'name');
          attributes.term = termId;
        }

        if (attributes.name && attributes.type === 'element') {
          const elementId = uuid();
          const elementVersionId = uuid();
          const name = attributes.name;
          const category = attributes.category;
          this.data.addElement(
            { name, category },
            null,
            elementId,
            {},
            elementVersionId
          );
          attributes = omit(attributes, 'name');
          attributes.element = elementId;
          attributes.elementVersion = elementVersionId;
        }

        this.data.addMarker(drawingId, attributes);
      });

      this.data.updateGraph();
      this.data.updateFigures();

      batchGroupBy.end();
      this.tracking.trackEvent('created_bulk_markers');
    },

    onAddProduct() {
      batchGroupBy.start();
      const productId = this.data.addProduct();
      this.data.setActiveProduct(productId);
      batchGroupBy.end();
      this.tracking.trackEvent('added_product');
    },

    onRemoveProduct(productId) {
      batchGroupBy.start();
      this.data.removeProduct(productId);
      batchGroupBy.end();
      this.tracking.trackEvent('removed_product');
    },

    onSetActiveProduct(productId) {
      batchGroupBy.start();
      this.data.setActiveProduct(productId);
      batchGroupBy.end();
      this.tracking.trackEvent('set_active_product');
    },

    onSetActiveProductSection(value) {
      batchGroupBy.start();
      this.data.setActiveProductView(value);
      batchGroupBy.end();
      this.tracking.trackEvent('set_active_product_view');
    },

    onAssertProductChecklist(productId, attributes = {}) {
      batchGroupBy.start();
      this.data.updateProduct(productId, attributes);
      // this.data.setActiveMilestoneContext(productChecklistView);
      batchGroupBy.end();
      this.tracking.trackEvent('asserted_product_checklist');
    },

    onSetActiveMilestoneContext(value) {
      batchGroupBy.start();
      this.data.setActiveMilestoneContext(value);
      this.tracking.trackEvent(`set_active_milestone_context_${value}`)
      
      batchGroupBy.end();
    },

    onUpdateElementColumn(columnId, attributes = {}) {
      batchGroupBy.start();
      this.data.updateElementColumn(columnId, attributes);
      batchGroupBy.end();
      this.tracking.trackEvent('set_column_size');
    },

    onExportSpecification() {
      this.patentSpecificationDocx.export(this.redux.getState());
      this.tracking.trackEvent('disclosure_menu_exported_specification');
    },

    onExportFigures() {
      this.figuresExportPdf.export(this.redux.getState());
      this.tracking.trackEvent('disclosure_menu_exported_figures');
    },

    onSetPreviewMode(value) {
      this.data.setPreviewMode(value);
      if (value) {
        this.tracking.trackEvent('entered_preview_mode');
      } else {
        this.tracking.trackEvent('left_preview_mode');
      }
    },

    onSetShowingFigures(value) {
      this.data.setShowingFigures(value);
      if (value) {
        this.tracking.trackEvent('opened_figures');
      } else {
        this.tracking.trackEvent('closed_figures');
      }
    },

    onSetShowingSearch(value) {
      this.data.setShowingSearch(value);
      if (value) {
        this.tracking.trackEvent('opened_search');
      } else {
        this.tracking.trackEvent('closed_search');
      }
    },

    onUpdateProduct(productId, attributes = {}) {
      this.data.updateProduct(productId, attributes);
      this.tracking.trackEvent('updated_product');
    },

    onUpdateDrawing(drawingId, attributes = {}) {
      this.data.updateDrawing(drawingId, attributes);
      this.tracking.trackEvent('updated_drawing');
    },

    onUpdateMethod(methodId, attributes = {}) {
      this.data.updateMethod(methodId, attributes);
      this.tracking.trackEvent('updated_method');
    },

    onUpdateMethodNode(methodId, methodNodeId, attributes = {}) {
      batchGroupBy.start();
      this.data.updateMethodNode(methodNodeId, attributes);
      this.data.updateMethod(methodId, {});
      batchGroupBy.end();
      this.tracking.trackEvent('updated_method_node');
    },

    onUpdateMethodEdge(methodId, methodEdgeId, attributes = {}) {
      batchGroupBy.start();
      this.data.updateMethodEdge(methodEdgeId, attributes);
      this.data.updateMethod(methodId, {});
      batchGroupBy.end();
      this.tracking.trackEvent('updated_method_edge');
    },

    onUpdatePatentSpecification(id, attributes = {}) {
      this.data.updatePatentSpecification(attributes);
    },

    onSetNarrativeType(narrativeType) {
      this.data.updatePatentSpecification({ narrativeType });
      this.data.updateFigures();
      this.tracking.trackEvent('updated_narrative_type');
    },

    onSetShowingProductChecklist(value = true) {
      batchGroupBy.start();
      this.data.setShowingProductChecklist(value);
      this.data.setShowingGetStarted(false);
      batchGroupBy.end();
    },

    onSetCreatingImageAsset(drawingId) {
      batchGroupBy.start();
      this.data.setCreatingImageAsset(drawingId);
      batchGroupBy.end();
    },

    onRemoveSelectedDrawingItems(
      drawingId,
      imagesList = [],
      markersList = [],
      highlightsList = []
    ) {
      batchGroupBy.start();
      this.data.removeSelectedDrawingItems(
        drawingId,
        imagesList,
        markersList,
        highlightsList
      );
      batchGroupBy.end();
      this.tracking.trackEvent('drawing_removed_selected');
    },

    onRemoveSelectedMethodItems(
      methodId,
      methodNodesList = [],
      methodEdgesList = [],
      methodEdgePointsList = [],
      elementsList = []
    ) {
      batchGroupBy.start();

      // remove the elements and element method nodes
      this.data.removeTreeItems(elementsList);

      this.data.removeMethodItems(
        methodId,
        methodNodesList,
        methodEdgesList,
        methodEdgePointsList
      );

      this.data.deselectMethodItems(methodId);

      this.data.updateGraph();

      batchGroupBy.end();

      this.autoArrangeIndex++;

      this.tracking.trackEvent('method_removed_selected');
    },

    onSetActiveMethod(methodId) {
      batchGroupBy.start();
      this.data.stackNavigationPush('method', 'method', methodId);
      batchGroupBy.end();
    },

    onSelectDrawing(drawingId) {
      batchGroupBy.start();
      this.data.selectDrawing(drawingId);
      batchGroupBy.end();
    },

    onDeselectDrawing(drawingId) {
      batchGroupBy.start();
      this.data.deselectDrawing(drawingId);
      batchGroupBy.end();
    },

    onAddDrawing() {
      batchGroupBy.start();
      const drawingId = this.data.addDrawing();
      this.data.setShowingDrawingSelectionModal(false);
      this.data.stackNavigationPush('drawing', 'drawing', drawingId);
      this.tracking.trackEvent('created_drawing')
      const state = this.redux.getState();
      const isFirst = getDrawingsList(state).length === 1;
      if (isFirst) {
        this.tracking.trackEvent('created_first_drawing')
      }
      batchGroupBy.end();
    },

    onSetShowingPriorArts(value = true, priorArtId) {
      batchGroupBy.start();
      this.data.setShowingPriorArts(value);
      console.log('p', priorArtId)
      if (priorArtId) {
        this.data.selectPriorArt(priorArtId);
      }
      batchGroupBy.end();
    },

    onAddPriorArt(attributes) {
      batchGroupBy.start();
      const priorArtId = this.data.addPriorArt(attributes);
      this.data.selectPriorArt(priorArtId, attributes);
      batchGroupBy.end();
      this.tracking.trackEvent('created_prior_art');
    },

    onUpdatePriorArt(priorArtId, attributes) {
      batchGroupBy.start();
      this.data.updatePriorArt(priorArtId, attributes);
      batchGroupBy.end();
      this.tracking.trackEvent('updated_prior_art');
    },

    onRemovePriorArt(priorArtId) {
      batchGroupBy.start();
      this.data.removePriorArt(priorArtId);
      batchGroupBy.end();
      this.tracking.trackEvent('removed_prior_art');
    },

    onSelectPriorArt(priorArtId) {
      batchGroupBy.start();
      this.data.selectPriorArt(priorArtId);
      batchGroupBy.end();
      this.tracking.trackEvent('selected_prior_art');
    },

    onSortPriorArtsList(priorArtsList) {
      batchGroupBy.start();
      this.data.sortPriorArtsList(priorArtsList);
      batchGroupBy.end();
      this.tracking.trackEvent('sorted_prior_arts_list');
    },

    onSetUpdatingProductPriorArt(value = false) {
      batchGroupBy.start();
      this.data.setUpdatingProductPriorArt(value);
      batchGroupBy.end();
    },

    onAddCustomer(attributes) {
      batchGroupBy.start();
      const customerId = this.data.addCustomer(attributes);
      this.data.selectCustomer(customerId, attributes);
      batchGroupBy.end();
      this.tracking.trackEvent('created_customer');
    },

    onUpdateCustomer(customerId, attributes) {
      batchGroupBy.start();
      this.data.updateCustomer(customerId, attributes);
      batchGroupBy.end();
      this.tracking.trackEvent('updated_customer');
    },

    onRemoveCustomer(customerId) {
      batchGroupBy.start();
      this.data.removeCustomer(customerId);
      batchGroupBy.end();
      this.tracking.trackEvent('removed_customer');
    },

    onSelectCustomer(customerId) {
      batchGroupBy.start();
      this.data.selectCustomer(customerId);
      batchGroupBy.end();
      this.tracking.trackEvent('selected_customer');
    },

    onSortCustomersList(priorArtsList) {
      batchGroupBy.start();
      this.data.sortCustomersList(priorArtsList);
      batchGroupBy.end();
      this.tracking.trackEvent('sorted_customers_list');
    },

    onSetUpdatingProductCustomers(value = false) {
      batchGroupBy.start();
      this.data.setUpdatingProductCustomers(value);
      batchGroupBy.end();
    },

    onAddProductCustomer() {
      batchGroupBy.start();
      this.data.addCustomer();
      this.data.setShowingCustomers(true);
      batchGroupBy.end();
    },

    onAddProductPriorArt() {
      batchGroupBy.start();
      this.data.addPriorArt();
      this.data.setShowingPriorArts(true);
      batchGroupBy.end();
    },

    onSetShowingCustomers(value = false, customerId) {
      batchGroupBy.start();
      this.data.setShowingCustomers(value);
      if (customerId) {
        this.data.selectCustomer(customerId);
      }
      batchGroupBy.end();
    },

    onSetShowingPatentability(value = false) {
      batchGroupBy.start();
      this.data.setShowingPatentability(value);
      batchGroupBy.end();
    },

    onSetActivePatentabilitySection(value) {
      batchGroupBy.start();
      this.data.setActivePatentabilitySection(value);
      batchGroupBy.end();
    },

    onAddComparison(attributes, comparisonId) {
      batchGroupBy.start();
      this.data.addComparison(attributes, comparisonId);
      batchGroupBy.end();
    },

    onRemoveComparison(comparisonId) {
      batchGroupBy.start();
      this.data.removeComparison(comparisonId);
      batchGroupBy.end();
    },

    onSortComparisonsList(productId, comparisonsList) {
      batchGroupBy.start();
      this.data.updateProduct(productId, { comparisonsList });
      batchGroupBy.end();
      this.tracking.trackEvent('product_sorted_prior_art_comparisons');
    },

    onSetShowingComparisonMatrix() {
      batchGroupBy.start();
      this.data.setShowingComparisonMatrix(true);
      batchGroupBy.end();
      this.tracking.trackEvent('product_opened_prior_art_comparison');
    },

    onShowPatentSpecification() {
      batchGroupBy.start();
      this.data.stackNavigationPush(
        'patent-specification',
        'patent-specification',
        null
      );
      batchGroupBy.end();
    },

    onRemoveDrawing(drawingId) {
      batchGroupBy.start();
      this.data.stackNavigationFilter('drawing', drawingId);
      this.data.removeDrawing(drawingId);
      this.tracking.trackEvent('removed_drawing');
      batchGroupBy.end();
    },

    onDuplicateDrawing(sourceDrawingId, withMarkers) {
      batchGroupBy.start();
      const drawingId = this.data.duplicateDrawing(
        sourceDrawingId,
        withMarkers
      );
      this.data.stackNavigationPush('drawing', 'drawing', drawingId);
      this.tracking.trackEvent('duplicated_drawing');
      batchGroupBy.end();
    },

    onSetActiveDrawing(drawingId) {
      batchGroupBy.start();
      this.data.stackNavigationPush('drawing', 'drawing', drawingId);
      batchGroupBy.end();
    },

    onDrawingsModalSetActiveDrawing(drawingId) {
      const state = this.redux.getState();
      const selectedElementId = getSelectedElementId(state);
      const selectedTermId = getSelectedTermId(state);

      batchGroupBy.start();
      this.data.setShowingDrawingSelectionModal(false);
      this.data.stackNavigationPush('drawing', 'drawing', drawingId);
      if (selectedElementId) {
        this.data.selectElement(selectedElementId);
      }
      if (selectedTermId) {
        this.data.selectTerm(selectedTermId);
      }
      batchGroupBy.end();
    },

    onSetDefaultMarkerCategory(category) {
      batchGroupBy.start();
      this.data.setDefaultMarkerCategory(category);
      if (this.selectedTermId) {
        this.data.deselectTerm(this.selectedTermId);
      }
      if (this.selectedElementId) {
        this.data.deselectElement(this.selectedElementId);
      }
      batchGroupBy.end();
    },

    onSetShowingDrawingSelectionModal(value) {
      batchGroupBy.start();
      this.data.setShowingDrawingSelectionModal(value);
      batchGroupBy.end();
    },

    onDrawingsModalAdd() {
      batchGroupBy.start();
      const drawingId = this.data.addDrawing();
      this.tracking.trackEvent('created_drawing');
      this.data.setShowingDrawingSelectionModal(false);
      this.data.stackNavigationPush('drawing', 'drawing', drawingId);
      batchGroupBy.end();
    },

    onStackNavigationPush(routeName, modelType, modelId) {
      batchGroupBy.start();
      this.data.stackNavigationPush(routeName, modelType, modelId);
      batchGroupBy.end();
    },

    onStackNavigationPop() {
      batchGroupBy.start();
      this.data.stackNavigationPop();
      batchGroupBy.end();
    },

    onStackNavigationReset() {
      batchGroupBy.start();
      this.data.stackNavigationReset();
      batchGroupBy.end();
    },

    onUpdateMethodLayout(methodId, layout, offsetX, offsetY, artboardWidth) {
      batchGroupBy.start();
      this.data.updateMethodLayout(
        methodId,
        layout,
        offsetX,
        offsetY,
        artboardWidth
      );
      batchGroupBy.end();
    },

    onSelectImage(imageId, multiSelectMode) {
      batchGroupBy.start();
      this.data.selectImage(imageId, multiSelectMode);
      batchGroupBy.end();
    },

    onDeselectImage(imageId) {
      batchGroupBy.start();
      this.data.deselectImage(imageId);
      batchGroupBy.end();
    },

    onSelectMarker(markerId, multiSelectMode) {
      batchGroupBy.start();
      this.data.selectMarker(markerId, multiSelectMode);
      batchGroupBy.end();
    },

    onDeselectMarker(markerId) {
      batchGroupBy.start();
      this.data.deselectMarker(markerId);
      batchGroupBy.end();
    },

    onDeselectDrawingItems(drawingId) {
      batchGroupBy.start();
      this.data.deselectDrawingItems(drawingId);
      batchGroupBy.end();
    },

    onDeselectMethodItems(methodId) {
      batchGroupBy.start();
      this.data.deselectMethodItems(methodId);
      batchGroupBy.end();
    },

    onUpdateDrawingItems(drawingId, images, markers) {
      batchGroupBy.start();
      this.data.updateDrawingItems(drawingId, images, markers);
      batchGroupBy.end();
    },

    onAddImageFromAsset(drawingId, parsedAssets) {
      batchGroupBy.start();
      const assetIds = this.data.addAssets(parsedAssets);
      this.data.addImageFromAsset(drawingId, assetIds[0]);
      batchGroupBy.end();
    },

    onAddMethodEdge(attributes, methodId, methodEdgeId) {
      batchGroupBy.start();
      this.data.addMethodEdge(attributes, methodId, methodEdgeId);
      this.autoArrangeIndex++;
      this.tracking.trackEvent('created_method_edge');
      batchGroupBy.end();
    },

    onAddMethodNode(attributes, methodId, parentElementVersionId) {
      batchGroupBy.start();
      const { x, y, type } = attributes;
      if (type === 'element') {
        const elementId = uuid();
        const elementVersionId = uuid();
        const category = 'system';

        this.data.addElement(
          { category },
          null,
          elementId,
          {},
          elementVersionId
        );

        const { methodNodeId } = this.data.addComprisesRelationship(
          parentElementVersionId,
          elementId
        );

        this.data.updateMethodNode(methodNodeId, { x, y });
        this.data.updateMethod(methodId, {});
        this.data.updateGraph();
        this.data.selectMethodNode(methodNodeId);
        this.autoArrangeIndex++;
      } else {
        const methodNodeId = uuid();
        this.data.addMethodNode(attributes, methodId, methodNodeId);
        this.data.updateMethod(methodId, {});
        this.data.selectMethodNode(methodNodeId);
      }
      this.tracking.trackEvent('created_method_node');
      batchGroupBy.end();
    },

    onSetShowingSettings(value) {
      batchGroupBy.start();
      this.data.setShowingSettings(value);
      batchGroupBy.end();
    },

    onUpdateSettings(attributes) {
      batchGroupBy.start();
      this.data.updateSettings(attributes);
      batchGroupBy.end();
    },

    onSetActiveFeature(featureId) {
      batchGroupBy.start();
      this.data.setActiveFeature(featureId);
      batchGroupBy.end();
    },

    onSetShowingPatentSpecificationEditor(value) {
      batchGroupBy.start();
      console.log('he')
      this.data.setShowingPatentSpecificationEditor(value);
      batchGroupBy.end();
    },


    onSetActivePatentSpecificationEditorSection(value) {
      batchGroupBy.start();
      this.data.setActivePatentSpecificationEditorSection(value);
      batchGroupBy.end();
    },

    onSetShowingInventionSummaryEditor(value, section) {
      batchGroupBy.start();
      this.data.setShowingInventionSummaryEditor(value);
      if (section) {
        this.data.setActiveInventionSummaryEditorSection(section);
      }
      batchGroupBy.end();
    },

    onSetActiveInventionSummaryEditorSection(value) {
      batchGroupBy.start();
      this.data.setActiveInventionSummaryEditorSection(value);
      this.tracking.trackEvent(`set_active_invention_overview_context_${value}`)
      batchGroupBy.end();
    },
  },
});

export default connect(stateToComputed, dispatchToActions)(InventionWorkArea);
