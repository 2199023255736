import {
  ADD_METHOD_NODE,
  UPDATE_METHOD_NODE,
  REMOVE_METHOD_NODE,
  UPDATE_METHOD_ITEMS,
} from '../actions/actionTypes';
import { addFromSchema } from '../utils/schema';
import { omit } from 'lodash';

const initialState = {
  ids: [],
  entities: {},
};

const methodNodesReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case ADD_METHOD_NODE: {
      let {
        attributes,
        methodNodeId
      } = payload;
      const id = methodNodeId;
      const createdAt = new Date();
      const updatedAt = new Date();
      const methodNode = addFromSchema('method-node', {
        id,
        ...attributes,
        createdAt,
        updatedAt,
      });
      return {
        ids: [...state.ids, id],
        entities: {
          ...state.entities,
          [methodNodeId]: methodNode,
        },
      };
    }

    case UPDATE_METHOD_ITEMS: {
      const { methodNodes } = payload;
      let methodNodeUpdates = {};
      const updatedAt = new Date();

      methodNodes.forEach((methodNode) => {
        methodNodeUpdates[methodNode.id] = {
          ...state.entities[methodNode.id],
          ...methodNode.attributes,
          updatedAt,
        };
      });

      return {
        ...state,
        entities: {
          ...state.entities,
          ...methodNodeUpdates,
        },
      };
    }

    case UPDATE_METHOD_NODE: {
      const { methodNodeId, attributes } = payload;
      const updatedAt = new Date();
      // TODO Why am I doing this nonsense?
      const _state = JSON.stringify({
        ...state,
        entities: {
          ...state.entities,
          [methodNodeId]: {
            ...state.entities[methodNodeId],
            ...attributes,
            updatedAt,
          },
        },
      })

      return JSON.parse(_state);
    }

    case REMOVE_METHOD_NODE: {
      const { methodNodeId } = payload;
      return {
        ids: state.ids.filter((id) => id !== methodNodeId),
        entities: omit(state.entities, methodNodeId),
      };
    }

    // case ADD_METHOD_EDGE: {
    //   const incomingEdgesList = [
    //     ...state.methodNodes[payload.methodEdge.target].incomingEdgesList,
    //     payload.methodEdge.id
    //   ];
    //   const outgoingEdgesList = [
    //     ...state.methodNodes[payload.methodEdge.source].outgoingEdgesList,
    //     payload.methodEdge.id
    //   ];

    //   return {
    //     ...state,
    //     methodEdges: {
    //       ...state.methodEdges,
    //       [payload.methodEdge.id]: addFromSchema(
    //         'method-edge',
    //         payload.methodEdge
    //       )
    //     },
    //     methodNodes: {
    //       ...state.methodNodes,
    //       [payload.methodEdge.target]: {
    //         ...state.methodNodes[payload.methodEdge.target],
    //         incomingEdgesList: uniq(incomingEdgesList)
    //       },
    //       [payload.methodEdge.source]: {
    //         ...state.methodNodes[payload.methodEdge.source],
    //         outgoingEdgesList: uniq(outgoingEdgesList)
    //       }
    //     },
    //     methods: {
    //       ...state.methods,
    //       [payload.methodId]: {
    //         ...state.methods[payload.methodId],
    //         methodEdgesList: [
    //           payload.methodEdge.id,
    //           ...state.methods[payload.methodId].methodEdgesList
    //         ],
    //         updatedAt: new Date()
    //       }
    //     }
    //   };
    // }

    /* istanbul ignore next */
    default:
      return state;
  }
};

export default methodNodesReducer;
