export default {
  attributes: {
    id: {
      type: 'uuid',
      defaultValue: null
    },
    asset: {
      type: 'uuid',
      defaultValue: null
    },
    createdAt: {
      type: 'date'
    },
    updatedAt: {
      type: 'date'
    }
  }
}
