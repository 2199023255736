import {
  ADD_HIGHLIGHT,
  REMOVE_HIGHLIGHT,
  UPDATE_HIGHLIGHT,
} from './actionTypes';

export const addHighlight = (markerId, attributes, highlightId) => {
  return {
    type: ADD_HIGHLIGHT,
    payload: {
      markerId,
      attributes,
      highlightId
    }
  };
};

export const removeHighlight = (markerId, highlightId) => {
  return {
    type: REMOVE_HIGHLIGHT,
    payload: {
      markerId,
      highlightId
    }
  };
};

export const updateHighlight = (highlightId, attributes) => {
  return {
    type: UPDATE_HIGHLIGHT,
    payload: {
      highlightId,
      attributes
    }
  };
};

