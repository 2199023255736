import { action, computed } from '@ember/object';
import {
  getActiveNavigationStack,
  getInventionUi,
} from '../../../selectors/invention-ui';

import Component from '@glimmer/component';
import { connect } from 'ember-redux';
import { getDrawingsList } from '../../../selectors/drawing';
import { getFiguresList } from '../../../selectors/figure';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';

const stateToComputed = (state) => ({
  inventionUi: getInventionUi(state),
  activeNavigationStack: getActiveNavigationStack(state),
  drawingsList: getDrawingsList(state),
  figuresList: getFiguresList(state),
});

const dispatchToActions = {};

class FiguresContext extends Component {
  @service data;
  @service tracking;

  viewOptions = [
    {
      id: 'drawings',
      name: 'Drawings',
      testId: 'drawing-edit-button',
    },
    {
      id: 'methods',
      name: 'Methods',
      testId: 'drawing-preview-button',
    },
  ];

  get styleNamespace() {
    return podNames['figures-context'];
  }

  get classNames() {
    let classNames = [this.styleNamespace, 'figures-context'];
    return classNames.join(' ');
  }

  @computed('activeNavigationStack.{modelType,modelId}')
  get activeDrawingId() {
    return (
      this.activeNavigationStack.modelType === 'drawing' &&
      this.activeNavigationStack.modelId
    );
  }

  @computed('activeNavigationStack.{modelType,modelId}')
  get activeMethodId() {
    return (
      this.activeNavigationStack.modelType === 'method' &&
      this.activeNavigationStack.modelId
    );
  }

  @computed('inventionUi.figureType', 'args.figureType')
  get figureType() {
    return this.args.figureType
      ? this.args.figureType
      : this.inventionUi && this.inventionUi.figureType;
  }

  @action
  removeDrawing(drawingId) {
    if (this.args.onRemoveDrawing) {
      this.args.onRemoveDrawing(drawingId);
    }
  }

  @action
  addDrawing() {
    if (this.args.onAddDrawing) {
      this.args.onAddDrawing();
    }
  }

  @action
  selectDrawing(drawingId) {
    this.args.onSetActiveDrawing(drawingId);
  }

  @action
  selectMethod(methodId) {
    this.args.onSetActiveMethod(methodId);
  }

  @action
  onSetFigureType(type) {
    this.data.setFigureType(type);
  }
}

export default connect(stateToComputed, dispatchToActions)(FiguresContext);
