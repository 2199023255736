import { action, computed } from '@ember/object';
import {
  getSelectedMethodEdges,
  getSelectedMethodNodes,
} from '../../../selectors/invention-ui';

import Component from '@glimmer/component';
import { connect } from 'ember-redux';
import { getMethod } from '../../../selectors/method';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';
import { union } from '@ember/object/computed';

const dispatchToActions = {};

const stateToComputed = (state, attrs) => ({
  method: getMethod(state, attrs.methodId),
  selectedMethodNodes: getSelectedMethodNodes(state),
  selectedMethodEdges: getSelectedMethodEdges(state),
});

class MethodStepsRealization extends Component {
  @service store;
  @service redux;
  @service models;

  get classNames() {
    let classNames = [this.styleNamespace, 'method-steps-list'];
    return classNames.join(' ');
  }

  get styleNamespace() {
    return podNames['method-steps-list'];
  }

  @computed('args.methodId')
  get methodModel() {
    return this.store.peekRecord('method', this.args.methodId);
  }

  @union('selectedMethodNodes', 'selectedMethodEdges')
  selectedMethodItems;

  @computed('args.previewMode')
  get language() {
    return this.args.previewMode
      ? 'patent_specification_preferred_version'
      : 'presentation_list';
  }

  @action
  onClick(modelId, modelType, isSelected) {
    if (this.args.onSelectMethodNode && modelType === 'method-node') {
      this.args.onSelectMethodNode(modelId, isSelected);
    } else if (this.args.onSelectMethodEdge && modelType === 'method-edge') {
      this.args.onSelectMethodEdge(modelId, isSelected);
    }
  }
}

export default connect(
  stateToComputed,
  dispatchToActions
)(MethodStepsRealization);
