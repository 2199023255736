import { helper } from '@ember/component/helper';

function isPreferred(args) {
  let [elementId, elementVersionId, preferredElementVersionsList] = args;
  const isPreferred = preferredElementVersionsList.find(
    (obj) =>
      obj.elementVersion === elementVersionId && obj.element === elementId
  )
    ? true
    : false;
  return isPreferred;
}

export default helper(isPreferred);
