export default {
  attributes: {
    type: {
      type: 'type',
      defaultValue: 'provisional'
    },
    title: {
      type: 'string',
      defaultValue: ''
    },
    inventors: {
      type: 'string',
      defaultValue: ''
    },
    crossReference: {
      type: 'string',
      defaultValue: ''
    },
    fundingSources: {
      type: 'string',
      defaultValue: ''
    },
    inventionFields: {
      type: 'string',
      defaultValue: ''
    },
    background: {
      type: 'string',
      defaultValue: ''
    },
    summary: {
      type: 'string',
      defaultValue: ''
    },
    abstract: {
      type: 'string',
      defaultValue: ''
    },
    boilerplate: {
      type: 'string',
      defaultValue: ''
    },
    blacklist: {
      type: 'array',
      defaultValue: []
    },
    narrativeType: {
      type: 'string',
      defaultValue: 'custom'
    },
    glossaryPlacement: {
      type: 'string',
      defaultValue: 'before' // after, inline
    },
    showFundingSources: {
      type: 'boolean',
      defaultValue: false
    },
    showCrossReference: {
      type: 'boolean',
      defaultValue: false
    },
    showBoilerplate: {
      type: 'boolean',
      defaultValue: false
    },
  }
}

