import Component from '@glimmer/component';
import { connect } from 'ember-redux';
import { getMethodNode } from '../../../selectors/method-node';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';

const stateToComputed = (state, attrs) => ({
  methodNode: getMethodNode(state, attrs.methodNodeId)
});

const dispatchToActions = {};

class MethodNodeContext extends Component {
  @service tracking;

  get styleNamespace() {
    return podNames['preview-context-method-node'];
  }

  get classNames() {
    let classNames = [this.styleNamespace];
    return classNames.join(' ');
  }
}

export default connect(stateToComputed, dispatchToActions)(MethodNodeContext);
