import {
  ADD_METHOD,
  REMOVE_METHOD,
  UPDATE_METHOD,
  UPDATE_METHOD_IGNORE_UNDO,
} from './actionTypes';

import uuid from 'uuid/v4';

export const addMethod = (attributes, methodId = uuid()) => {
  return {
    type: ADD_METHOD,
    payload: {
      methodId,
      attributes,
    },
  };
};

export const removeMethod = (
  methodId,
  methodNodesList,
  methodEdgesList,
  methodEdgePointsList,
  featuresList
) => {

  // remove references 

  return {
    type: REMOVE_METHOD,
    payload: {
      methodId,
      methodNodesList,
      methodEdgesList,
      methodEdgePointsList,
      featuresList,
    },
  };
};

export const updateMethod = (
  methodId,
  attributes = {},
  ignoreUndo = false
) => {
  const actionType = ignoreUndo ? UPDATE_METHOD_IGNORE_UNDO : UPDATE_METHOD;
  return {
    type: actionType,
    payload: {
      methodId,
      attributes,
    },
  };
};

