import Component from '@glimmer/component';
import { action } from '@ember/object';
import { connect } from 'ember-redux';
import { getActiveProductId } from '../../../selectors/invention-ui';
import { getProductsList } from '../../../selectors/product';
import { htmlSafe } from '@ember/string';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';

const stateToComputed = (state) => ({
  productsList: getProductsList(state),
  activeProductId: getActiveProductId(state),
});

const dispatchToActions = {};

class ExplorerProductsNav extends Component {
  @service data;

  actionsWidth = 40;

  get styleNamespace() {
    return podNames['explorer-products-nav'];
  }

  get classNames() {
    let classNames = [this.styleNamespace, 'explorer-products-nav'];
    return classNames.join(' ');
  }

  get tabsMaxWidthStyle() {
    const width = this.args.width - this.actionsWidth;
    return htmlSafe(`max-width: ${width}px;`)
  }

  @action
  onSetActiveProduct() {
    
  }

  @action
  onRemoveProduct(id) {
    if (this.args.onRemoveProduct && this.productsList.length > 1) {
      this.args.onRemoveProduct(id);
    }
  }
}

export default connect(stateToComputed, dispatchToActions)(ExplorerProductsNav);
