import {
  BLACK_AND_WHITE_STROKE,
  EDGE_CATEGORY_ARTICLE,
  EDGE_CATEGORY_ARTICLE_DARK,
  EDGE_CATEGORY_COMPOSITION,
  EDGE_CATEGORY_COMPOSITION_DARK,
  EDGE_CATEGORY_MACHINE,
  EDGE_CATEGORY_MACHINE_DARK,
  EDGE_CATEGORY_PROCESS,
  EDGE_CATEGORY_PROCESS_DARK,
  ELEMENT_EDGE_STROKE,
  TERM_EDGE_STROKE,
  GRAY_700,
  DARKBLUE_250,
  WHITE,
  BLACK,
} from '../../../constants/colors';
import { action, computed } from '@ember/object';
import { alias } from '@ember/object/computed';

import Component from '@glimmer/component';
import Konva from 'konva';
import { connect } from 'ember-redux';
import { getElement } from '../../../selectors/element';
import { getFeature } from '../../../selectors/feature';
import { getElementVersion } from '../../../selectors/element-version';
import { getPreferredElementVersion } from '../../../selectors/product';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';

const dispatchToActions = {};

const stateToComputed = (state, attrs) => ({
  targetElement:
    attrs.targetType === 'element' && getElement(state, attrs.targetId),
  feature: attrs.featureId && getFeature(state, attrs.featureId),
  targetElementVersion:
    attrs.targetType === 'element-version' &&
    getElementVersion(state, attrs.targetId),
  sourceElement:
    attrs.sourceType === 'element' && getElement(state, attrs.sourceId),
  sourceElementVersion:
    attrs.sourceType === 'element-version'
      ? getElementVersion(state, attrs.sourceId)
      : attrs.productId &&
        getPreferredElementVersion(state, attrs.sourceId, attrs.productId),
});

class InventionGraphEdge extends Component {
  @service settings;
  @service redux;

  _visibleAreaIndex = 0;
  _isSelected = '';
  _isPreffered = '';
  _isNovel = '';
  _isDisconnected = '';
  _targetX;
  _targetY;
  _sourceX;
  _sourceY;
  _targetCategory;
  _sourceCategory;

  constructor(owner, args) {
    super(owner, args);
    this.onScheduleRender = this.args.onScheduleRender;
    this.layer = this.args.layer;
  }

  @action
  willDestroyNode() {
    this.selectedNode.off('click');
    this.selectedNode.destroy();
    this.edgeNode.destroy();
    this.onScheduleRender();
  }

  @action
  didInsert() {
    this._visibleAreaIndex = this.args.visibleAreaIndex;
    this._isSelected = this.args.isSelected;
    this._isPreffered = this.args.isPreffered;
    this._isNovel = this.args.isNovel;
    this._isDisconnected = this.args.isDisconnected;
    this._isCollapsed = this.args.isCollapsed;
    this._sourceCategory = this.sourceCategory;
    this._sourceType = this.args.sourceType;
    this._targetType = this.args.targetType;
    this._amountType = this.amountType;
    this._customLabel = this.customLabel;
    this._targetCategory = this.targetCategory;
    this._blackAndWhiteMode = this.settings.blackAndWhiteMode;
    this._darkMode = this.settings.darkMode;
    this.setup();
  }

  get styleNamespace() {
    return podNames['invention-graph-edge-konva'];
  }

  get classNames() {
    let classNames = [this.styleNamespace];
    if (this.args.isDisconnected) classNames.push('is-disconnected');
    if (this.args.isSelected) classNames.push('is-selected');
    return classNames.join(' ');
  }

  get sourceId() {
    return this.args.edgeId.split('_')[0];
  }

  get targetId() {
    return this.args.edgeId.split('_')[1];
  }

  @alias('feature.value.amount') amountType;
  @alias('feature.value.custom') customLabel;

  @computed('amountType')
  get showLabel() {
    return this.amountType && this.amountType !== 'one' ? true : false;
  }

  @computed('amountType', 'customLabel')
  get labelText() {
    let text = '';

    switch (this.amountType) {
      case 'one-plus':
        text = 'one or more';
        break;
      case 'plurality':
        text = 'plurality of';
        break;
      case 'custom':
        text = this.customLabel;
        break;
    }

    return text;
  }

  @computed('targetElementVersion.category', 'args.productId')
  get targetCategory() {
    return this.targetElementVersion && this.targetElementVersion.category;
  }

  @computed('sourceElementVersion.element')
  get sourceElementVersionElement() {
    const state = this.redux.getState();
    return (
      this.sourceElementVersion &&
      getElement(state, this.sourceElementVersion.element)
    );
  }

  @computed(
    'sourceElementVersion.category',
    'args.productId',
    'sourceElementVersionElement.category'
  )
  get sourceCategory() {
    let category =
      this.sourceElementVersion && this.sourceElementVersion.category;
    if (
      this.sourceElementVersionElement &&
      this.sourceElementVersionElement.category === 'product'
    ) {
      category = 'product';
    }
    return category;
  }

  @computed(
    'sourceCategory',
    'args.isDisconnected',
    'settings.{blackAndWhiteMode,darkMode}'
  )
  get edgeColor() {
    let color = ELEMENT_EDGE_STROKE;

    if (this.sourceCategory === 'machine') {
      color = this.settings.darkMode
        ? EDGE_CATEGORY_MACHINE_DARK
        : EDGE_CATEGORY_MACHINE;
    }
    if (this.sourceCategory === 'process') {
      color = this.settings.darkMode
        ? EDGE_CATEGORY_PROCESS_DARK
        : EDGE_CATEGORY_PROCESS;
    }
    if (this.sourceCategory === 'article-of-manufacture') {
      color = this.settings.darkMode
        ? EDGE_CATEGORY_ARTICLE_DARK
        : EDGE_CATEGORY_ARTICLE;
    }
    if (this.sourceCategory === 'composition') {
      color = this.settings.darkMode
        ? EDGE_CATEGORY_COMPOSITION_DARK
        : EDGE_CATEGORY_COMPOSITION;
    }

    // if (this.args.isNovel) {
    //   color = NOVELTY_EDGE_STROKE;
    // }

    if (this.args.isDisconnected) {
      color = TERM_EDGE_STROKE;
    }

    if (this.settings.blackAndWhiteMode) {
      color = BLACK_AND_WHITE_STROKE;
    }

    return color;
  }

  @computed('settings.{blackAndWhiteMode,darkMode}')
  get labelBackgroundColor() {
    let color = this.settings.darkMode ? DARKBLUE_250 : GRAY_700;

    if (this.settings.blackAndWhiteMode) {
      color = WHITE;
    }

    return color;
  }

  @computed('settings.{blackAndWhiteMode,darkMode}')
  get labelTextColor() {
    let color = this.settings.darkMode ? WHITE : BLACK;

    if (this.settings.blackAndWhiteMode) {
      color = BLACK;
    }

    return color;
  }

  setup() {
    const edgeNode = new Konva.Arrow({
      id: this.args.edgeId,
      fill: this.edgeColor,
      stroke: this.edgeColor,
      edgeId: this.args.edgeId,
      name: 'edge',
      strokeWidth: 8,
      listening: false,
      dash: [15, 10],
      dashEnabled: this.args.targetType === 'element-version',
      transformsEnabled: 'position',
      points: [
        this.args.sourceX,
        this.args.sourceY,
        this.args.targetX,
        this.args.targetY,
      ],
    });

    const clickNode = new Konva.Arrow({
      id: `click-${this.args.edgeId}`,
      fill: 'transparent',
      stroke: 'transparent',
      edgeId: this.args.edgeId,
      name: 'click-edge',
      strokeWidth: 40,
      transformsEnabled: 'position',
      points: [
        this.args.sourceX,
        this.args.sourceY,
        this.args.targetX,
        this.args.targetY,
      ],
    });

    const selectedNode = new Konva.Arrow({
      id: `select-${this.args.edgeId}`,
      fill: this.edgeColor,
      stroke: this.edgeColor,
      strokeWidth: 13,
      opacity: 0.5,
      name: 'select-edge',
      edgeId: this.args.edgeId,
      strokeScaleEnabled: false,
      listening: false,
      visible: this.args.isSelected ? true : false,
      transformsEnabled: 'position',
      points: [
        this.args.sourceX,
        this.args.sourceY,
        this.args.targetX,
        this.args.targetY,
      ],
    });

    const labelX = (this.args.targetX + this.args.sourceX) * 0.5;
    const labelY = (this.args.targetY + this.args.sourceY) * 0.5;
    const labelWidth = 180;
    const labelHeight = 40;

    const labelNode = new Konva.Group({
      id: `label-${this.args.edgeId}`,
      x: labelX,
      y: labelY,
      transformsEnabled: 'position',
      visible: this.showLabel,
    });

    const labelBackgroundNode = new Konva.Rect({
      width: labelWidth,
      height: labelHeight,
      fill: this.labelBackgroundColor,
      listening: false,
      transformsEnabled: 'position',
      offset: {
        x: labelWidth / 2,
        y: labelHeight / 2,
      },
    });

    const labelTextNode = new Konva.Text({
      width: labelWidth,
      height: labelHeight,
      align: 'center',
      verticalAlign: 'middle',
      text: this.labelText,
      fontSize: 24,
      fontStyle: '500',
      lineHeight: 1.235,
      fontFamily: this.fontFamily,
      fill: this.labelTextColor,
      transformsEnabled: 'position',
      listening: false,
      offset: {
        x: labelWidth / 2,
        y: labelHeight / 2,
      },
    });

    labelNode.add(labelBackgroundNode);
    labelNode.add(labelTextNode);
    // const noveltyHighlightNode = new Konva.Arrow({
    //   fill: NOVELTY_EDGE_STROKE,
    //   stroke: NOVELTY_EDGE_STROKE,
    //   strokeWidth: 9,
    //   opacity: 0.5,
    //   strokeScaleEnabled: false,
    //   listening: false,
    //   visible: this.args.isNovel ? true : false,
    //   transformsEnabled: 'position',
    //   points: [
    //     this.args.sourceX,
    //     this.args.sourceY,
    //     this.args.targetX,
    //     this.args.targetY,
    //   ],
    // });

    // add events
    clickNode.on('click', () => {
      if (this.args.onClick)
        this.args.onClick(this.args.edgeId, this.args.isSelected);
    });

    // this.layer.add(noveltyHighlightNode);
    this.layer.add(selectedNode);
    this.layer.add(edgeNode);
    this.layer.add(labelNode);
    this.layer.add(clickNode);

    this.edgeNode = edgeNode;
    this.selectedNode = selectedNode;
    this.labelNode = labelNode;
    this.labelBackgroundNode = labelBackgroundNode;
    this.labelTextNode = labelTextNode;
    // this.noveltyHighlightNode = noveltyHighlightNode;
    this.clickNode = clickNode;

    this.onScheduleRender();
  }

  updateColors() {
    this.edgeNode.fill(this.edgeColor);
    this.edgeNode.stroke(this.edgeColor);
    this.selectedNode.fill(this.edgeColor);
    this.selectedNode.stroke(this.edgeColor);
    this.labelBackgroundNode.fill(this.labelBackgroundColor);
    this.labelTextNode.fill(this.labelTextColor);
  }

  @action
  onUpdate(
    elem,
    [
      targetX,
      targetY,
      sourceX,
      sourceY,
      sourceCategory,
      sourceType,
      targetType,
      visibleAreaIndex,
      isSelected,
      isNovel,
      isDisconnected,
      isCollapsed,
      amountType,
      customLabel,
      targetCategory,
      blackAndWhiteMode,
      darkMode,
    ]
  ) {
    let shouldDraw = false;

    if (
      this._targetX !== targetX ||
      this._targetY !== targetY ||
      this._sourceX !== sourceX ||
      this._sourceY !== sourceY
    ) {
      this._targetX = targetX;
      this._targetY = targetY;
      this._sourceX = sourceX;
      this._sourceY = sourceY;

      this.edgeNode.points([sourceX, sourceY, targetX, targetY]);
      this.selectedNode.points([sourceX, sourceY, targetX, targetY]);
      // this.noveltyHighlightNode.points([sourceX, sourceY, targetX, targetY]);
      this.clickNode.points([sourceX, sourceY, targetX, targetY]);

      const labelX = (targetX + sourceX) * 0.5;
      const labelY = (targetY + sourceY) * 0.5;
      this.labelNode.x(labelX);
      this.labelNode.y(labelY);

      shouldDraw = true;
    }

    if (this._sourceCategory !== sourceCategory) {
      this._sourceCategory = sourceCategory;
      this.updateColors();
      shouldDraw = true;
    }

    if (this._sourceType !== sourceType) {
      this._sourceType = sourceType;
      this.updateColors();
      shouldDraw = true;
    }

    if (this._targetType !== targetType) {
      this._targetType = targetType;
      this.updateColors();
      shouldDraw = true;
    }

    if (this._targetCategory !== targetCategory) {
      this._targetCategory = targetCategory;
      this.updateColors();
      shouldDraw = true;
    }

    if (this._isNovel !== isNovel) {
      this._isNovel = isNovel;
      this.updateColors();
      // this.noveltyHighlightNode.visible(isNovel)
      shouldDraw = true;
    }

    if (this._isDisconnected !== isDisconnected) {
      this._isDisconnected = isDisconnected;
      this.updateColors();
      shouldDraw = true;
    }

    if (this._isCollapsed !== isCollapsed) {
      this._isCollapsed = isCollapsed;
      console.log('isCollapsed', isCollapsed);
      if (isCollapsed) {
        this.selectedNode.visible(false);
        this.edgeNode.visible(false);
        this.clickNode.visible(false);
        // shouldDraw = true;
        this.layer.draw();
      }
    }

    let visibleAreaChanged = false;

    if (this._visibleAreaIndex !== visibleAreaIndex) {
      this._visibleAreaIndex = visibleAreaIndex;
      visibleAreaChanged = true;
      shouldDraw = true;
    }

    if (visibleAreaChanged) {
      this.selectedNode.visible(
        this.args.isSelected ? this.selectedNode.isClientRectOnScreen() : false
      );
      this.edgeNode.visible(this.edgeNode.isClientRectOnScreen());
      this.clickNode.visible(this.clickNode.isClientRectOnScreen());
      // this.noveltyHighlightNode.visible(this.args.isNovel && this.edgeNode.isClientRectOnScreen())
      shouldDraw = true;
    }

    if (this._isSelected !== isSelected) {
      this._isSelected = isSelected;
      if (isSelected) {
        this.selectedNode.visible(true);
      } else {
        this.selectedNode.visible(false);
      }
      shouldDraw = true;
    }

    if (this._amountType !== amountType) {
      this._amountType = amountType;
      this.labelNode.visible(this.showLabel);
      this.labelTextNode.text(this.labelText);
      shouldDraw = true;
    }

    if (this._customLabel !== customLabel) {
      this._customLabel = customLabel;
      this.labelTextNode.text(this.labelText);
      shouldDraw = true;
    }

    if (this._blackAndWhiteMode !== blackAndWhiteMode) {
      this._blackAndWhiteMode = blackAndWhiteMode;
      this.updateColors();
      shouldDraw = true;
    }

    if (this._darkMode !== darkMode) {
      this._darkMode = darkMode;
      this.updateColors();
      shouldDraw = true;
    }

    if (shouldDraw) this.layer.batchDraw();
  }
}

export default connect(stateToComputed, dispatchToActions)(InventionGraphEdge);
