import {
  curveBasis,
  curveBundle,
  curveCardinal,
  curveCatmullRom,
  curveLinear,
  curveNatural,
  curveStep,
  curveStepAfter,
  curveStepBefore,
  line as d3Line,
} from 'd3-shape';

import { insertAtIndex } from './array';

// import { defaultArtboardWidth, defaultArtboardHeight } from '../constants/settings';

export const getAutoLabelOrientation = ({ startY = 0, endY = 0, midY = 0 }) => {
  let orientation = 'top';

  if (startY > endY || startY > midY) {
    orientation = 'bottom';
  }

  if (startY <= endY || startY <= midY) {
    orientation = 'top';
  }

  return orientation;
};

export const getLabelCoords = ({
  orientation,
  labelWidth,
  labelHeight,
  startX,
  startY,
  radius,
  margin,
}) => {
  let offsetX = 0;
  let offsetY = 0;

  switch (orientation) {
    case 'right':
      offsetX = radius + margin;
      offsetY = (5 + radius / 2) * -1;
      break;
    case 'left':
      offsetX = (radius + (margin + labelWidth)) * -1;
      offsetY = (5 + radius / 2) * -1;
      break;
    case 'top':
      offsetX = (-1 * labelWidth) / 2;
      offsetY = (labelHeight + margin + radius) * -1;
      break;
    case 'bottom':
      offsetX = (-1 * labelWidth) / 2;
      offsetY = radius + margin;
      break;
  }

  return {
    x: startX + offsetX,
    y: startY + offsetY,
  };
};

export const getLabelClass = (leadline) => {
  return leadline ? 'node-label' : 'node-label no-leadline';
};

export const parseCurve = (curve) => {
  let d3Curve;
  switch (curve) {
    case 'basis':
      d3Curve = curveBasis;
      break;
    case 'bundle':
      d3Curve = curveBundle;
      break;
    case 'cardinal':
      d3Curve = curveCardinal;
      break;
    case 'catmull-rom':
      d3Curve = curveCatmullRom;
      break;
    case 'linear':
      d3Curve = curveLinear;
      break;
    case 'natural':
      // d3Curve = curveNatural;
      d3Curve = curveCatmullRom;

      // yup
      // curveCatmullRom
      // curveBasis

      // nope
      //curveCardinal nope
      break;
    case 'step':
      d3Curve = curveStep;
      break;
    case 'step-after':
      d3Curve = curveStepAfter;
      break;
    case 'step-before':
      d3Curve = curveStepBefore;
      break;
    /* istanbul ignore next */
    default:
      d3Curve = curveNatural;
  }

  return d3Curve;
};

export const getMarkerPath = ({
  curve,
  startX,
  startY,
  midX,
  midY,
  endX,
  endY,
}) => {
  const data = [
    [startX, startY],
    [midX, midY],
    [endX, endY],
  ];

  const line = d3Line().curve(curve);

  return line(data);
};

export const getMethodEdgePath = ({
  curve,
  sourceX,
  sourceY,
  targetX,
  targetY,
  draggingSuggestedBendPoint,
  pointsArray = [],
}) => {
  let data = [[sourceX, sourceY]];
  
  pointsArray.forEach((point) => data.push([point.x, point.y]));

  data.push([targetX, targetY]);

  if (draggingSuggestedBendPoint && draggingSuggestedBendPoint.index) {
    data = insertAtIndex(data, draggingSuggestedBendPoint.index, [
      draggingSuggestedBendPoint.x,
      draggingSuggestedBendPoint.y,
    ]);
  }
  const line = d3Line().curve(curve);

  return line(data);
};

export const getHighlightPath = ({ curve, pointsArray = [] }) => {
  let data = [];
  pointsArray.forEach((point) => data.push([point.x, point.y]));
  const line = d3Line().curve(curve);
  return line(data);
};

// export const getMethodSVG = ({
//     method,
//     methodNodes,
//     methodEdges
//   }) => {
//   const orientation = method.orientation;
//   const artboardWidth = orientation === 'portrait' ? defaultArtboardWidth : defaultArtboardHeight;
//   const artboardHeight = orientation === 'portrait' ? defaultArtboardHeight : defaultArtboardWidth;
//   const artboardX = -1 * artboardWidth / 2;
//   const artboardY = -1 * artboardHeight / 2;
//   const styles = getMethodStyles();
//   const xmlTag = '<?xml version="1.0" encoding="utf-8"?>';
//   const doctype = xmlTag + '<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">';
//
//   const methodNodeEls = methodNodes.map(methodNode => getMethodNodeSVG(methodNode));
//   const methodEdgeEls = methodEdges.map(methodEdge => getMethodEdgeSVG(methodEdge));
//
//   const svg = `<svg viewBox="${artboardX} ${artboardY} ${artboardWidth} ${artboardHeight}">
//                   <defs>
//                     ${styles}
//                   </defs>
//                   <rect class="artboard" x="${artboardX}" y="${artboardY}" width="${artboardWidth}" height="${artboardHeight}"/>
//                   <g class="nodes-and-edges">
//                     ${methodNodeEls}
//                     ${methodEdgeEls}
//                   </g>
//               </svg>`;
//
//   const node = $(svg)[0];
//   const serialized = new XMLSerializer().serializeToString(node);
//   const blob = new Blob([doctype + serialized], {
//     type: 'image/svg+xml;charset=utf-8'
//   });
//
//   return blob;
// }

// export const getDrawingSVG = ({
//     drawing,
//     markers,
//     images,
//     grayscale = true
//   }) => {
//   const orientation = drawing.orientation;
//   const artboardWidth = orientation === 'portrait' ? defaultArtboardWidth : defaultArtboardHeight;
//   const artboardHeight = orientation === 'portrait' ? defaultArtboardHeight : defaultArtboardWidth;
//   const artboardX = -1 * artboardWidth / 2;
//   const artboardY = -1 * artboardHeight / 2;
//   const styles = getDrawingStyles();
//   const xmlTag = '<?xml version="1.0" encoding="utf-8"?>';
//   const doctype = xmlTag + '<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">';
//
//   const imageEls = images.map(image => getImageSVG(image, grayscale));
//   const markerEls = markers.map(marker => getMarkerSVG(marker));
//
//   const svg = `<svg viewBox="${artboardX} ${artboardY} ${artboardWidth} ${artboardHeight}">
//                   <defs>
//                     ${styles}
//                     <marker id="point-arrow" class="point-arrow" viewBox="0 -5 10 10" refX="4"
//                       markerWidth="6.0" markerHeight="6.0" orient="auto">
//                       <path d="M0,-5L10,0L0,5"></path>
//                     </marker>
//                   </defs>
//                   <filter id="grayscale">
//                     <feColorMatrix type="saturate" values="0"/>
//                   </filter>
//                   <pattern id="placeholder-pattern" class="missing-image-pattern"
//                          x="10" y="10" width="30" height="30"
//                          patternUnits="userSpaceOnUse"
//                          patternTransform="rotate(35)"
//                         >
//                     <circle cx="20" cy="20" r="10" fill="#d1d1d1"/>
//                   </pattern>
//                   <rect class="artboard" x="${artboardX}" y="${artboardY}" width="${artboardWidth}" height="${artboardHeight}"/>
//                   <g class="figure">
//                       ${imageEls}
//                       ${markerEls}
//                   </g>
//               </svg>`;
//
//   const node = $(svg)[0];
//   const serialized = new XMLSerializer().serializeToString(node);
//   const blob = new Blob([doctype + serialized], {
//     type: 'image/svg+xml;charset=utf-8'
//   });
//
//   return blob;
// }

// export const getImageSVG = (image, grayscale) => {
//   const { x, y, width, height, dataUrl } = image;
//
//   let filter = '';
//
//   if (grayscale) {
//     filter = ` filter="url(#grayscale)" `;
//   }
//
//   const imageEl = `<image xlink:href="${dataUrl}"
//       x="0" y="0"
//       ${filter}
//       width="${width}" height="${height}" viewBox="0 0 ${width} ${height}"
//    preserveAspectRatio="xMinYMin meet"/>`;
//
//   const placeholderEl = `<rect fill="url(#placeholder-pattern)" width="${width}" height="${height}"></rect>`;
//
//   const svgEl = dataUrl ? imageEl : placeholderEl;
//
//   return `<g class="image-group"  transform="translate(${x}, ${y})">
//               ${svgEl}
//           </g>`;
// }
//
// export const getMarkerSVG = (marker) => {
//   const { ordinal, startX, startY, midX, midY, endX, endY, pointStyle } = marker;
//   const startRadius = 20;
//   const labelMargin = -10;
//   const labelWidth = 250;
//   const labelHeight = 150;
//   const curve = parseCurve(marker.curve);
//   const pointArrow = pointStyle === 'arrow';
//   const markerEnd = pointArrow ? `marker-end="url(#point-arrow)"` : '';
//
//   const orientation = marker.labelOrientation === 'auto' ?
//     getAutoLabelOrientation({
//       startX,
//       startY,
//       midX,
//       midY,
//       endX,
//       endY
//     }) :
//     marker.labelOrientation;
//
//   const leadline = marker.hasLeadLine;
//   const labelClass = getLabelClass(marker.hasLeadLine);
//   const labelCoords = getLabelCoords({
//     orientation,
//     labelWidth,
//     labelHeight,
//     startX,
//     startY,
//     radius: startRadius,
//     margin: labelMargin
//   });
//
//   const labelX = labelCoords.x;
//   const labelY = labelCoords.y;
//
//   const path = getMarkerPath({
//     curve,
//     startX,
//     startY,
//     midX,
//     midY,
//     endX,
//     endY
//   });
//
//   const pathElement = pointStyle === 'arrow' ?
//       `<path
//         class="link spline"
//         marker-end="url(#point-arrow)"
//         d=${path}>
//       </path>`
//     : `<path
//         class="link spline visible-spline"
//         d=${path}>
//        </path>`;
//
//   const svg = `<g class="marker">
//             <g class="points-and-line">
//               ${pathElement}
//               <g class="marker-label orient-${orientation}" transform="translate(${labelX}, ${labelY})">
//                 <foreignObject x="0" y="0" width="${labelWidth}" height="${labelHeight}">
//                   <div class="node-content">
//                     <div class="inner">
//                       <div class="${labelClass}">
//                           <div class="marker-label">
//                             ${ordinal}
//                           </div>
//                       </div>
//                     </div>
//                   </div>
//                 </foreignObject>
//               </g>
//             </g>
//           </g>`;
//   return svg;
// }
//
// const getDrawingStyles = () => {
//   return `<style type="text/css">
//
//           .artboard { fill: #fff; }
//
//           .marker .marker-label {
//             pointer-events: none;
//             display: inline-flex;
//           }
//
//           .marker .marker-label.orient-left .node-content {
//             text-align: right;
//           }
//
//           .marker .marker-label.orient-top .node-content {
//             align-items: flex-end;
//             text-align: center;
//           }
//
//           .marker .marker-label.orient-bottom .node-content {
//             text-align: center;
//           }
//
//           .marker.is-collapsed .marker-label .node-content {
//             text-decoration: underline;
//           }
//
//           .marker path.spline {
//             fill: none;
//             stroke: #000;
//             stroke-width: 2px;
//           }
//
//           .point-arrow {
//             fill: #000;
//           }
//
//           .spline-point, .end-point-dot {
//             visibility: hidden;
//           }
//
//           .node-content {
//             height: 100%;
//             display: flex;
//             cursor: default;
//             line-height: 2rem;
//             font-size: 1.5rem;
//             user-select: none;
//             -webkit-user-select: none;
//             font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
//           }
//
//           .node-content .inner {
//             width: 100%;
//           }
//
//           .marker .no-leadline {
//             text-decoration: underline;
//           }
//
//           .marker .marker-label {
//             background-color: transparent;
//           }
//
//           </style>`;
// }
