import Component from '@glimmer/component';
import podNames from 'ember-component-css/pod-names';

class DisclosurePreviewElement extends Component {
  width = 400;
  height = 380;

  get styleNamespace() {
    return podNames['disclosure-preview-element'];
  }

  get classNames() {
    let classNames = [this.styleNamespace, 'disclosure-preview-element'];
    if (this.args.isDisconnected) classNames.push('is-disconnected');
    return classNames.join(' ');
  }

  get x() {
    return (this.args.element && this.args.element.x) || 0;
  }

  get y() {
    return (this.args.element && this.args.element.y) || 0;
  }

  get offsetX() {
    return (-1 * this.width) / 2;
  }

  get offsetY() {
    return (-1 * this.height) / 2;
  }

  get transform() {
    return `translate(${this.x}, ${this.y})`;
  }

  get offsetTransform() {
    return `translate(${this.offsetX}, ${this.offsetY})`;
  }
}

export default DisclosurePreviewElement;
