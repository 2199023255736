import {
  UPDATE_NOTE,
  REMOVE_NOTE,
  ADD_NOTE,
  SORT_NOTES_LIST
} from './actionTypes';

import uuid from 'uuid/v4';

export const updateNote = (noteId, attributes) => {
  return {
    type: UPDATE_NOTE,
    payload: {
      noteId,
      attributes
    }
  };
};

export const addNote = (attributes, noteId = uuid()) => {
  return {
    type: ADD_NOTE,
    payload: {
      attributes,
      noteId
    }
  };
};

export const removeNote = (noteId, notesList = []) => {

  const noteIndex = notesList.indexOf(noteId);
  const previousNoteId = notesList[noteIndex - 1];
  const nextNoteId = notesList[noteIndex + 1];

  let activeNote = previousNoteId || nextNoteId;

  if (!activeNote) activeNote = null;

  return {
    type: REMOVE_NOTE,
    payload: {
      noteId,
      activeNote
    }
  };
};

export const sortNotesList = (noteIds) => {
  return {
    type: SORT_NOTES_LIST,
    payload: {
      noteIds
    }
  };
};
