import { action, computed } from '@ember/object';

import Component from '@glimmer/component';
import { connect } from 'ember-redux';
import { getActiveFeature } from '../../../selectors/invention-ui';
import { getElementVersion } from '../../../selectors/element-version';
import podNames from 'ember-component-css/pod-names';

const stateToComputed = (state, attrs) => ({
  activeFeatureId: getActiveFeature(state),
  elementVersion: getElementVersion(state, attrs.elementVersionId),
});

const dispatchToActions = {};

class ElementVersionContextDefinitions extends Component {
  get styleNamespace() {
    return podNames['elementVersion-context-definitions'];
  }

  @computed('elementVersion.definitionsList', 'activeFeatureId')
  get hasActive() {
    return (
      this.activeFeatureId &&
      this.elementVersion.definitionsList.includes(this.activeFeatureId)
    );
  }

  @action
  removeDefinition(featureId) {
    this.args.onRemoveDefinition(this.args.elementVersionId, featureId);
  }

  @action
  createDefinition(type) {
    this.args.onAddDefinition(this.args.elementVersionId, type);
  }

  @action
  onSortDefinitionsList(definitionsList) {
    this.args.onUpdateElementVersion(this.args.elementVersionId, {
      definitionsList,
    });
  }
}

export default connect(
  stateToComputed,
  dispatchToActions
)(ElementVersionContextDefinitions);
