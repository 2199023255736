import { action, computed } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import {
  getElementsMap,
  getOrphanNodesList,
  getRootNodeId,
} from '../../../selectors/graph';

import Component from '@glimmer/component';
import { connect } from 'ember-redux';
import { getElement } from '../../../selectors/element';
import {
  getInventionUi,
  getActiveProductId,
} from '../../../selectors/invention-ui';
import {
  getProduct,
  getPreferredElementVersion,
} from '../../../selectors/product';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';

const dispatchToActions = {};

const stateToComputed = (state, attrs) => ({
  inventionUi: getInventionUi(state),
  elementsTreeMap: getElementsMap(state, attrs.productId),
  rootNodeId: getRootNodeId(state),
  orphanNodesList: getOrphanNodesList(state),
  product: getProduct(state, attrs.productId),
});

class ComparisonElementsContext extends Component {
  @service data;
  @service applicationState;

  @tracked nodesFromInvention = [];
  @tracked collapsedNodesList = [];

  get styleNamespace() {
    return podNames['comparison-elements-context'];
  }

  get classNames() {
    let classNames = [this.styleNamespace, 'comparison-elements-context'];
    return classNames.join(' ');
  }

  @action
  didInsert() {
    const state = this.redux.getState();
    this.nodesFromInvention = this.getNodesFromInvention(state);
  }

  getNodesFromInvention(state, productId) {
    productId = productId || getActiveProductId(state);
    const nodesMap = getElementsMap(state, productId);

    const rootNodeId = getRootNodeId(state);
    const rootNode = nodesMap[rootNodeId];
    const allNodes = [rootNodeId, ...rootNode.descendants];

    const mappedNodes = allNodes.map((nodeId) => {
      const node = nodesMap[nodeId];
      const type = node?.type;

      let model;
      let instanceOfModel;
      let preferredElementVersionModel;

      model = getElement(state, nodeId);

      if (model.instanceOf) {
        instanceOfModel = getElement(state, model.instanceOf);
      }

      if (productId) {
        preferredElementVersionModel = getPreferredElementVersion(
          state,
          nodeId,
          productId
        );
      }

      return {
        id: node.id,
        depth: node.depth,
        type,
        model,
        category: model.category,
        instanceOfModel,
        preferredElementVersionModel,
        parent: node.parent,
        children: node.children,
      };
    });

    return mappedNodes;
  }

  @computed('elementsTreeMap')
  get nodesMap() {
    return this.elementsTreeMap;
  }

  @computed('args.activeElementId', 'nodesMap')
  get currentNodeDescendants() {
    return (
      this.args.activeElementId &&
      this.nodesMap[this.args.activeElementId] &&
      this.nodesMap[this.args.activeElementId].descendants
    );
  }

  @action
  selectElement(node) {
    const elementId = node.category === 'product' ? null : node.id;
    this.args.onSelectElement(elementId);
  }

  @action
  onViewAll() {
    this.args.onSelectElement(null);
  }
}

export default connect(
  stateToComputed,
  dispatchToActions
)(ComparisonElementsContext);
