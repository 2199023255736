import Component from '@glimmer/component';
import { connect } from 'ember-redux';
import podNames from 'ember-component-css/pod-names';
import FeatureTypes from '../../../constants/types/features';
import { elementFeaturesList } from '../../../constants/settings';

const stateToComputed = null;

const dispatchToActions = {};

class FeaturesAddButton extends Component {

  get styleNamespace() {
    return podNames['features-add-button'];
  }

  get classNames() {
    let classNames = [this.styleNamespace, 'features-add-button'];
    return classNames.join(' ');
  }

  get types() {
    return elementFeaturesList.map(featureId => FeatureTypes[featureId])
  }
}

export default connect(stateToComputed, dispatchToActions)(FeaturesAddButton);
