import Component from '@glimmer/component';
import { action, computed } from '@ember/object';
import { alias } from '@ember/object/computed';
import { connect } from 'ember-redux';
import { getFeature } from '../../../selectors/feature';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';

const dispatchToActions = {};

const stateToComputed = (state, attrs) => ({
  feature: getFeature(state, attrs.featureId),
});

class ComprisesContext extends Component {
  @service tracking;
  @service data;
  @service models;
  @service redux;

  language = 'patent_specification_preferred_version';

  // type = 'comprises';

  // comprisesOptions = [
  //   {
  //     id: 'comprises',
  //     name: 'Comprises',
  //     testId: 'comprises',
  //   },
  //   {
  //     id: 'consists',
  //     name: 'Consists of',
  //     testId: 'consists',
  //   },
  //   {
  //     id: 'custom',
  //     name: 'Custom',
  //     testId: 'custom',
  //   },
  // ];

  amountOptions = [
    {
      id: 'one',
      name: 'One',
      testId: 'one',
    },
    {
      id: 'one-plus',
      name: 'One Plus',
      testId: 'one-plus',
    },
    {
      id: 'plurality',
      name: 'Plurality',
      testId: 'plurality',
    },
    {
      id: 'custom',
      name: 'Custom',
      testId: 'custom',
    },
  ];

  get classNames() {
    let classNames = ['comprises-context', this.styleNamespace];
    return classNames.join(' ');
  }

  get styleNamespace() {
    return podNames['comprises-context'];
  }

  @alias('feature.elementVersion') sourceElementVersionId;
  @alias('feature.value.element') targetElementId;
  // @alias('feature.value.comprises_type') type;
  @alias('feature.value.comprises_value') customValue;

  @computed('args.featureId')
  get featureModel() {
    const state = this.redux.getState();
    return (
      this.args.featureId &&
      this.models.findOrCreate(
        this.args.featureId,
        'feature',
        getFeature(state, this.args.featureId)
      )
    );
  }

  @action
  onUpdateAmount(amount) {
    const plural = amount === 'one' ? false : true;
    const value = {
      ...this.feature.value,
      amount,
      plural
    };
    this.args.onUpdateFeature(this.args.featureId, { value });
    this.featureModel.set('value', value);
  }

  @action
  onCustomChange(custom) {
    const value = {
      ...this.feature.value,
      custom,
    };

    this.args.onUpdateFeature(this.args.featureId, { value });
    this.featureModel.set('value', value);
  }
}

export default connect(stateToComputed, dispatchToActions)(ComprisesContext);
