import {
  ADD_METHOD,
  REMOVE_METHOD,
  UPDATE_METHOD,
} from '../actions/actionTypes';

import { addFromSchema } from '../utils/schema';
import { omit } from 'lodash';

const initialState = {
  ids: [],
  entities: {},
};

const methodsReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case ADD_METHOD: {
      let { methodId, attributes } = payload;
      const id = methodId;
      const createdAt = new Date();
      const updatedAt = new Date();
      const method = addFromSchema('method', {
        id,
        ...attributes,
        createdAt,
        updatedAt,
      });
      return {
        ids: [...state.ids, id],
        entities: {
          ...state.entities,
          [methodId]: method,
        },
      };
    }

    case REMOVE_METHOD: {
      const { methodId } = payload;
      return {
        ids: state.ids.filter((id) => id !== methodId),
        entities: omit(state.entities, methodId),
      };
    }

    case UPDATE_METHOD: {
      const { methodId, attributes } = payload;
      const updatedAt = new Date();
      return {
        ...state,
        entities: {
          ...state.entities,
          [methodId]: {
            ...state.entities[methodId],
            ...attributes,
            updatedAt,
          },
        },
      };
    }

    /* istanbul ignore next */
    default:
      return state;
  }
};

export default methodsReducer;
