import { action, computed } from '@ember/object';
import {
  getComponent,
  getPrimaryInstancesList,
} from '../../../selectors/component';

import Component from '@glimmer/component';
import { connect } from 'ember-redux';
import {
  getActiveFeature,
} from '../../../selectors/invention-ui';
import { getElement } from '../../../selectors/element';
import { getElementVersion } from '../../../selectors/element-version';
import podNames from 'ember-component-css/pod-names';
import { selectElement } from '../../../actions/invention-ui';
import { inject as service } from '@ember/service';
import { setActiveFeature } from '../../../actions/feature';
import { tracked } from '@glimmer/tracking';

const dispatchToActions = {};

const stateToComputed = (state, attrs) => ({
  activeFeatureId: getActiveFeature(state),
  element: getElement(state, attrs.elementId),
  elementVersion: getElementVersion(state, attrs.elementVersionId),
  primaryInstancesList: getPrimaryInstancesList(state),
});

class ElementVersionContextContent extends Component {
  @service redux;
  @service tracking;
  @tracked showingFeaturePrompts = false;
  @tracked isOverflowed = false;

  get styleNamespace() {
    return podNames['element-version-context-content'];
  }

  get classNames() {
    let classNames = [this.styleNamespace, 'element-version-context-content'];
    return classNames.join(' ');
  }

  @computed('element.instanceOf')
  get elementIsInstance() {
    return this.element && this.element.instanceOf ? true : false;
  }

  @computed('elementIsInstance', 'primaryInstancesList.[]', 'args.elementId')
  get elementIsPrimaryInstance() {
    return (
      this.elementIsInstance &&
      this.primaryInstancesList.includes(this.args.elementId)
    );
  }

  @computed('elementVersion.element')
  get elementVersionsList() {
    const state = this.redux.getState();
    const elementId = this.elementVersion && this.elementVersion.element;
    const element = elementId && getElement(state, elementId);
    return (element && element.elementVersionsList) || [];
  }

  @computed('elementVersionsList.[]')
  get canRemove() {
    return this.elementVersionsList.length > 1;
  }

  @computed('args.elementVersionId', 'elementVersion.elementsList.[]')
  get elementsList() {
    return this.elementVersion && this.elementVersion.elementsList;
  }

  @computed('args.elementVersionId', 'elementVersion.category')
  get category() {
    return this.elementVersion && this.elementVersion.category;
  }

  @computed('args.elementId', 'element.category')
  get elementCategory() {
    return this.element && this.element.category;
  }

  @computed('elementCategory')
  get showMethod() {
    return this.elementCategory === 'system';
  }

  @action
  toggleShowingFeaturePrompts() {
    this.showingFeaturePrompts = !this.showingFeaturePrompts;
    if (this.activeFeatureId) this.redux.store.dispatch(setActiveFeature(null));
  }

  @action
  setAmountType(amountType) {
    this.args.onUpdateElementVersion(this.args.elementVersionId, { amountType })
  }

  @action
  setDesign(type) {
    const known = type === 'known' ? true : false;
    this.args.onUpdateElementVersion(this.args.elementVersionId, { known })

    if (known) {
      this.tracking.trackEvent('solution_context_set_not_designing');
    } else {
      this.tracking.trackEvent('solution_context_set_designing');
    }
  }

  @action
  setCategory(category) {
    this.args.onUpdateElementVersion(this.args.elementVersionId, { category })

    switch (category) {
      case 'machine':
        this.tracking.trackEvent('solution_context_set_type_machine');
        break;
      case 'process':
        this.tracking.trackEvent('solution_context_set_type_process');

        break;
      case 'article-of-manufacture':
        this.tracking.trackEvent('solution_context_set_type_article');

        break;
      case 'composition':
        this.tracking.trackEvent('solution_context_set_type_composition');
        break;
    }
  }

  @action
  onMarkerReferenceClick(markerId) {
    if (this.args.onMarkerReferenceClick) {
      this.args.onMarkerReferenceClick(markerId);
    }
    this.tracking.trackEvent('solution_context_clicked_drawing');
  }

  @action
  onUpdateMarkersList(markersList = []) {
    this.args.onUpdateElementVersion(this.args.elementVersionId, { markersList })
    this.tracking.trackEvent('solution_context_changed_drawing_reference');
  }

  @action
  onSelectElement() {
    let elementId = this.args.elementId;

    if (!elementId) {
      const state = this.redux.getState();
      elementId = this.elementVersion.element;
      const element = getElement(state, elementId);
      if (element.isComponent) {
        const component = getComponent(state, element.component);
        elementId = component.primaryInstance;
      }
    }
    this.redux.dispatch(selectElement(elementId));
  }

}

export default connect(
  stateToComputed,
  dispatchToActions
)(ElementVersionContextContent);
