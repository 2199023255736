import {
  ADD_COMPARISON,
  REMOVE_COMPARISON,
  UPDATE_COMPARISON,
  SORT_COMPARISONS_LIST,
} from './actionTypes';
import uuid from 'uuid/v4';

export const addComparison = (attributes = {}, comparisonId = uuid()) => {
  return {
    type: ADD_COMPARISON,
    payload: {
      comparisonId,
      attributes,
    },
  };
};

export const removeComparison = (comparisonId) => {
  return {
    type: REMOVE_COMPARISON,
    payload: {
      comparisonId,
    },
  };
};

export const updateComparison = (comparisonId, attributes = {}) => {
  return {
    type: UPDATE_COMPARISON,
    payload: {
      comparisonId,
      attributes,
    },
  };
};



export const sortComparisonsList = (comparisonIds) => {
  return {
    type: SORT_COMPARISONS_LIST,
    payload: {
      comparisonIds,
    },
  };
};
