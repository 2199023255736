export default {
  attributes: {
    id: {
      type: 'uuid',
      defaultValue: null,
    },
    name: {
      type: 'string',
      defaultValue: 'Untitled Invention',
    },
    user: {
      type: 'uuid',
      defaultValue: null,
    },
    inspiration: {
      type: 'string',
      defaultValue: '',
    },
    figuresList: {
      type: 'array',
      defaultValue: [],
    },
  },
};
