import { action, computed } from '@ember/object';

import Component from '@glimmer/component';
import { connect } from 'ember-redux';
import { getActiveFeature } from '../../../selectors/invention-ui';
import { getElementVersion } from '../../../selectors/element-version';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';

const dispatchToActions = {};

const stateToComputed = (state, attrs) => ({
  activeFeatureId: getActiveFeature(state),
  elementVersion: getElementVersion(state, attrs.elementVersionId),
});

class ElementVersionContextConstraints extends Component {
  @service tracking;

  get styleNamespace() {
    return podNames['element-version-context-constraints'];
  }

  get classNames() {
    let classNames = [
      this.styleNamespace,
      'element-version-context-constraints',
    ];
    return classNames.join(' ');
  }

  @computed('elementVersion.constraintsList', 'activeFeatureId')
  get hasActive() {
    return (
      this.activeFeatureId &&
      this.elementVersion.constraintsList.includes(this.activeFeatureId)
    );
  }

  @action
  removeConstraint(featureId) {
    this.args.onRemoveConstraint(this.args.elementVersionId, featureId);
  }

  @action
  createConstraint() {
    this.args.onAddConstraint(this.args.elementVersionId);
  }

  @action
  onSortConstraintsList(constraintsList) {
    this.args.onUpdateElementVersion(this.args.elementVersionId, {
      constraintsList,
    });
  }
}

export default connect(
  stateToComputed,
  dispatchToActions
)(ElementVersionContextConstraints);
