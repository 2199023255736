import { getComponent, getComponentInstanceOf } from './component';
import { getInventionState, getPresentState } from '../utils/redux';

import { filter } from 'lodash';
import { get } from '@ember/object';
import { getElementsMap } from './graph';

export const getElement = (state, elementId) =>
  get(getPresentState(state), `elements.entities.${elementId}`);

export const getElements = (state) =>
  get(getPresentState(state), `elements.entities`);

// export const getElements = (state) => {
//   const elements = getElementsList(state).map(elementId => getElement(state, elementId));
//   return keyBy(elements, 'id');
// };

export const getElementsList = (state) =>
  get(getPresentState(state), `elements.ids`);

export const getElementParent = (state, elementId) => {
  const elementsMap = getElementsMap(state);
  return (
    elementsMap &&
    elementsMap[elementId] &&
    getElement(state, elementsMap[elementId].parent)
  );
};

export const getElementsListArray = (state) => {
  const elementsList = getElementsList(state);
  return (
    elementsList &&
    elementsList.map((_elementId) => {
      return getElement(state, _elementId);
    })
  );
};

export const getElementFeatures = (state, elementId) => {
  const features = get(getInventionState(state), `features`);
  return filter(features, (feature) => feature.element === elementId);
};

export const getElementName = (state, elementId) => {
  const element = getElement(state, elementId);

  let name = null;

  if (!element) {
    return name;
  }

  if (element.component && element.instanceOf) {
    const instanceOf = getElement(state, element.instanceOf);
    name = instanceOf.name;
  } else {
    name = element.name;
  }

  return name;
};

export const getElementVersionsList = (state, elementId) => {
  const element = getElement(state, elementId);

  let elementVersionsList = [];

  if (!element) {
    return elementVersionsList;
  }

  if (element.component && element.instanceOf) {
    const instanceOf = getElement(state, element.instanceOf);
    elementVersionsList = instanceOf.elementVersionsList;
  } else {
    elementVersionsList = element.elementVersionsList;
  }

  return elementVersionsList;
};

export const getElementInstanceOf = (state, elementId) => {
  let instanceOf;

  const element = getElement(state, elementId);

  if (element && element.component) {
    instanceOf = getComponentInstanceOf(state, element.component);
  }

  return instanceOf;
};

export const getElementOutcomeId = (state, elementId) => {
  const element = getElement(state, elementId);
  let outcomeId = element && element.outcome;

  if (element && element.component && !element.useDefaultOutcome) {
    const instanceOf = getComponentInstanceOf(state, element.component);
    outcomeId = instanceOf ? instanceOf.outcome : null;
  }

  return outcomeId;
};

export const getElementElementVersionsList = (state, elementId) => {
  let elementVersionsList = [];
  const element = getElement(state, elementId);
  elementVersionsList = element && element.elementVersionsList;

  if (element && element.component) {
    const instanceOf = getComponentInstanceOf(state, element.component);
    elementVersionsList = instanceOf ? instanceOf.elementVersionsList : [];
  }

  return elementVersionsList;
};

export const getElementRequirementsList = (state, elementId) => {
  const element = getElement(state, elementId);

  let requirementsList = [];

  if ((element.component && element.isComponent) || element.instanceOf) {
    const component = getComponent(state, element.component);
    const instanceOf = getElement(state, component.element);
    requirementsList = [...requirementsList, ...instanceOf.requirementsList];
    const instancesList = component.instancesList;
    instancesList.forEach((instanceId) => {
      const instance = getElement(state, instanceId);
      if (instance && !instance.useDefaultRequirements) {
        requirementsList = [...requirementsList, ...instance.requirementsList];
      }
    });
  } else {
    requirementsList = element.requirementsList;
  }

  return requirementsList;
};
