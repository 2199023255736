import Component from '@glimmer/component';
import { action } from '@ember/object';
import { connect } from 'ember-redux';
import { getDefaultMarkerCategory } from '../../../selectors/invention-ui';
import podNames from 'ember-component-css/pod-names';

const dispatchToActions = {};

const stateToComputed = (state) => ({
  defaultMarkerCategory: getDefaultMarkerCategory(state),
});

class DrawingCreateBulk extends Component {
  get styleNamespace() {
    return podNames['drawing-create-bulk'];
  }

  get classNames() {
    let classNames = [this.styleNamespace];
    return classNames.join(' ');
  }

  get canRemoveItem() {
    return (
      this.args.selectedBulkCreateItemId &&
      this.args.bulkCreateItemsList.length > 1
    );
  }

  @action
  deselectAll() {
    this.args.onSelectBulkCreateItem(null);
  }
}

export default connect(stateToComputed, dispatchToActions)(DrawingCreateBulk);
