import {
  ADD_INVENTION,
  IMPORT_INVENTION,
  SELECT_REFERENCE,
  UPDATE_INVENTION,
  UPDATE_INVENTION_NAME,
  UPDATE_NODE_COORDINATES
} from './actionTypes';

import uuid from 'uuid/v4';

export const addInvention = (inventionId = uuid(), attributes = {}) => {
  return {
    type: ADD_INVENTION,
    payload: {
      inventionId,
      attributes
    }
  };
};

export const importInvention = (invention) => {
  return {
    type: IMPORT_INVENTION,
    payload: {
      invention
    }
  };
};

export const updateInvention = (attributes = {}) => {
  // validate attributes
  return {
    type: UPDATE_INVENTION,
    payload: {
      attributes
    }
  };
};
export const updateInventionName = (name, elementId) => {
  return {
    type: UPDATE_INVENTION_NAME,
    payload: {
      elementId,
      name
    }
  };
};

export const selectReference = (referenceId, referenceType, figureId, figureType) => {
  // validate attributes
  return {
    type: SELECT_REFERENCE,
    payload: {
      referenceId,
      referenceType,
      figureId,
      figureType
    }
  };
};

export const updateNodeCoordinates = (elements, elementVersions) => {
  // validate attributes
  return {
    type: UPDATE_NODE_COORDINATES,
    payload: {
      elements,
      elementVersions
    }
  };
};

