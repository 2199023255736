import { getProduct, getProductPatentabilityStatus } from '../../../selectors/product';
import { getReview, getReviewsList } from '../../../selectors/review';

import Component from '@glimmer/component';
import { action } from '@ember/object';
import { computed } from '@ember/object';
import { connect } from 'ember-redux';
import { getContentOverflowed } from '../../../utils/general';
import { getCustomersList } from '../../../selectors/customer';
import { getInventionUi } from '../../../selectors/invention-ui';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

const dispatchToActions = {};

const stateToComputed = (state, attrs) => ({
  product: getProduct(state, attrs.productId),
  reviewsList: getReviewsList(state),
  customersList: getCustomersList(state),
  ui: getInventionUi(state),
  patentabilityStatus: getProductPatentabilityStatus(state, attrs.productId),
});

class ProductContext extends Component {
  @service tracking;
  @tracked isOverflowed = false;

  get styleNamespace() {
    return podNames['product-context'];
  }

  get classNames() {
    let classNames = [this.styleNamespace, 'product-context'];
    if (this.isOverflowed) classNames.push('is-overflowed');
    return classNames.join(' ');
  }

  // eslint-disable-next-line ember/require-computed-property-dependencies
  @computed('reviewsList', 'args.productId')
  get productReviewsList() {
    const state = this.redux.getState();
    return this.reviewsList.filter((reviewId) => {
      const review = getReview(state, reviewId);
      return review.product === this.args.productId;
    });
  }

  @action
  setUpdatingProductPriorArt() {
    if (this.args.onSetUpdatingProductPriorArt) {
      this.args.onSetUpdatingProductPriorArt(true);
    }
  }

  @action
  setUpdatingProductCustomers() {
    if (this.args.onSetUpdatingProductCustomers) {
      this.args.onSetUpdatingProductCustomers(true);
    }
  }

  @action
  onAddProductCustomer() {
    if (this.args.onAddProductCustomer) {
      this.args.onAddProductCustomer(this.args.productId);
    }
  }

  @action
  onAddProductPriorArt() {
    if (this.args.onAddProductPriorArt) {
      this.args.onAddProductPriorArt(this.args.productId);
    }
  }

  @action
  onReviewClick() {}

  @action
  onShowComparisonMatrix() {
    if (this.args.onSetShowingComparisonMatrix) {
      this.args.onSetShowingComparisonMatrix(true);
    }
  }


  @action
  onSetShowingCustomers() {
    if (this.args.onSetShowingCustomers) {
      this.args.onSetShowingCustomers(true);
    }
  }

  @action
  onSortComparisonsList(comparisonsList) {
    if (this.args.onSortComparisonsList) {
      this.args.onSortComparisonsList(this.args.productId, comparisonsList);
    }
  }

  @action
  onSortCustomersList(customersList) {
    if (this.args.onUpdateProduct) {
      this.args.onUpdateProduct(this.args.productId, { customersList});
    }
  }

  @action
  onComparisonClick(comparisonId, priorArtId) {
    if (this.args.onSetShowingPriorArt) {
      this.args.onSetShowingPriorArt(true, priorArtId);
    }
  }

  @action
  onCustomerClick(customerId) {
    if (this.args.onSetShowingCustomers) {
      this.args.onSetShowingCustomers(true, customerId);
    }
  }

  @action
  onPatentabilityClick() {
    if (this.args.onSetShowingPatentability) {
      this.args.onSetShowingPatentability(true);
    }
  }

  @action
  onPriorArtClick(priorArtId) {
    if (this.args.onSetShowingPriorArts) {
      this.args.onSetShowingPriorArts(true, priorArtId);
    }
  }

  @action
  onSetShowingPriorArts() {
    if (this.args.onSetShowingPriorArts) {
      this.args.onSetShowingPriorArts(true);
    }
  }

  @action
  onContentResize(sb) {
    this.isOverflowed = getContentOverflowed(sb);
  }

  @action
  onUpdateProductName(productId, attributes) {
    this.args.onUpdateProduct(productId, attributes);
    this.tracking.trackEvent('updated_product_name')
  }
}

export default connect(stateToComputed, dispatchToActions)(ProductContext);
