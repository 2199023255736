export default [
  {
    "id": "asserted_milestone_invention",
    "category": "Milestones",
    "source": "app"
  },
  {
    "id": "asserted_milestone_version",
    "category": "Milestones",
    "source": "app"
  },
  {
    "id": "created_drawing",
    "category": "Drawings",
    "source": "app"
  },
  {
    "id": "visited_login",
    "category": "Auth",
    "source": "app"
  },
  {
    "id": "accepted_terms",
    "category": "Auth",
    "source": "app"
  },
  {
    "id": "login_requested",
    "category": "Auth",
    "source": "app"
  },
  {
    "id": "login_request_denied",
    "category": "Auth",
    "source": "app"
  },
  {
    "id": "login_token_expired",
    "category": "Auth",
    "source": "app"
  },
  {
    "id": "logged_in",
    "category": "Auth",
    "source": "app"
  },
  {
    "id": "logged_out",
    "category": "Auth",
    "source": "app"
  },
  {
    "id": "visited_disclosures",
    "category": "Disclosures",
    "source": "app"
  },
  {
    "id": "imported_inventing_game",
    "category": "Disclosures",
    "source": "app"
  },
  {
    "id": "imported_legacy",
    "category": "Disclosures",
    "source": "app"
  },
  {
    "id": "imported_disclosure",
    "category": "Disclosures",
    "source": "app"
  },
  {
    "id": "opened_create_disclosure",
    "category": "Disclosures",
    "source": "app"
  },
  {
    "id": "closed_create_disclosure",
    "category": "Disclosures",
    "source": "app"
  },
  {
    "id": "create_disclosure_enabled_cloud_backups",
    "category": "Disclosures",
    "source": "app"
  },
  {
    "id": "create_disclosure_added_images",
    "category": "Disclosures",
    "source": "app"
  },
  {
    "id": "create_disclosure_added_description",
    "category": "Disclosures",
    "source": "app"
  },
  {
    "id": "created_disclosure",
    "category": "Disclosures",
    "source": "app"
  },
  {
    "id": "removed_disclosure",
    "category": "Disclosures",
    "source": "app"
  },
  {
    "id": "selected_disclosure",
    "category": "Disclosures",
    "source": "app"
  },
  {
    "id": "visited_disclosure",
    "category": "Disclosure",
    "source": "app"
  },
  {
    "id": "undo",
    "category": "Disclosure",
    "source": "app"
  },
  {
    "id": "redo",
    "category": "Disclosure",
    "source": "app"
  },
  {
    "id": "opened_explorer",
    "category": "Explorer",
    "source": "app"
  },
  {
    "id": "closed_explorer",
    "category": "Explorer",
    "source": "app"
  },
  {
    "id": "resized_explorer",
    "category": "Explorer",
    "source": "app"
  },
  {
    "id": "explorer_viewed_systems",
    "category": "Explorer",
    "source": "app"
  },
  {
    "id": "explorer_searched_systems",
    "category": "Explorer",
    "source": "app"
  },
  {
    "id": "explorer_cleared_search",
    "category": "Explorer",
    "source": "app"
  },
  {
    "id": "explorer_selected_system",
    "category": "Explorer",
    "source": "app"
  },
  {
    "id": "explorer_selected_solution",
    "category": "Explorer",
    "source": "app"
  },
  {
    "id": "explorer_created_system",
    "category": "Explorer",
    "source": "app"
  },
  {
    "id": "explorer_zoomed_to_node",
    "category": "Explorer",
    "source": "app"
  },
  {
    "id": "explorer_expanded_node",
    "category": "Explorer",
    "source": "app"
  },
  {
    "id": "explorer_collapsed_node",
    "category": "Explorer",
    "source": "app"
  },
  {
    "id": "explorer_viewed_figures",
    "category": "Explorer",
    "source": "app"
  },
  {
    "id": "explorer_filtered_figures_drawings",
    "category": "Explorer",
    "source": "app"
  },
  {
    "id": "explorer_filtered_figures_methods",
    "category": "Explorer",
    "source": "app"
  },
  {
    "id": "explorer_created_drawing",
    "category": "Explorer",
    "source": "app"
  },
  {
    "id": "explorer_selected_drawing",
    "category": "Explorer",
    "source": "app"
  },
  {
    "id": "explorer_removed_drawing",
    "category": "Explorer",
    "source": "app"
  },
  {
    "id": "explorer_sorted_drawings",
    "category": "Explorer",
    "source": "app"
  },
  {
    "id": "explorer_viewed_glossary",
    "category": "Explorer",
    "source": "app"
  },
  {
    "id": "explorer_created_term",
    "category": "Explorer",
    "source": "app"
  },
  {
    "id": "explorer_selected_term",
    "category": "Explorer",
    "source": "app"
  },
  {
    "id": "explorer_removed_term",
    "category": "Explorer",
    "source": "app"
  },
  {
    "id": "explorer_sorted_terms",
    "category": "Explorer",
    "source": "app"
  },
  {
    "id": "opened_disclosure_details",
    "category": "Disclosure Details",
    "source": "app"
  },
  {
    "id": "closed_disclosure_details",
    "category": "Disclosure Details",
    "source": "app"
  },
  {
    "id": "updated_disclosure_name",
    "category": "Disclosure Details",
    "source": "app"
  },
  {
    "id": "opened_comments",
    "category": "Comments",
    "source": "app"
  },
  {
    "id": "closed_comments",
    "category": "Comments",
    "source": "app"
  },
  {
    "id": "selected_comment",
    "category": "Comments",
    "source": "app"
  },
  {
    "id": "created_comment",
    "category": "Comments",
    "source": "app"
  },
  {
    "id": "edited_comment",
    "category": "Comments",
    "source": "app"
  },
  {
    "id": "removed_comment",
    "category": "Comments",
    "source": "app"
  },
  {
    "id": "resolved_comment",
    "category": "Comments",
    "source": "app"
  },
  {
    "id": "unresolved_comment",
    "category": "Comments",
    "source": "app"
  },
  {
    "id": "opened_invention_overview",
    "category": "Main Nav",
    "source": "app"
  },
  {
    "id": "closed_invention_overview",
    "category": "Main Nav",
    "source": "app"
  },
  {
    "id": "updated_invention_title",
    "category": "Invention Overview",
    "source": "app"
  },
  {
    "id": "updated_invention_description",
    "category": "Invention Overview",
    "source": "app"
  },
  {
    "id": "opened_disclosure_menu",
    "category": "Disclosure Menu",
    "source": "app"
  },
  {
    "id": "disclosure_menu_back_to_disclosures",
    "category": "Disclosure Menu",
    "source": "app"
  },
  {
    "id": "disclosure_menu_closed_explorer",
    "category": "Disclosure Menu",
    "source": "app"
  },
  {
    "id": "disclosure_menu_opened_explorer",
    "category": "Disclosure Menu",
    "source": "app"
  },
  {
    "id": "disclosure_menu_saved_dive",
    "category": "Disclosure Menu",
    "source": "app"
  },
  {
    "id": "disclosure_menu_opened_save_version",
    "category": "Disclosure Menu",
    "source": "app"
  },
  {
    "id": "disclosure_menu_viewed_version_history",
    "category": "Disclosure Menu",
    "source": "app"
  },
  {
    "id": "disclosure_menu_exported_specification",
    "category": "Disclosure Menu",
    "source": "app"
  },
  {
    "id": "disclosure_menu_exported_figures",
    "category": "Disclosure Menu",
    "source": "app"
  },
  {
    "id": "disclosure_menu_opened_products",
    "category": "Disclosure Menu",
    "source": "app"
  },
  {
    "id": "disclosure_menu_opened_prior_art",
    "category": "Disclosure Menu",
    "source": "app"
  },
  {
    "id": "disclosure_menu_opened_reviews",
    "category": "Disclosure Menu",
    "source": "app"
  },
  {
    "id": "disclosure_menu_opened_assets",
    "category": "Disclosure Menu",
    "source": "app"
  },
  {
    "id": "disclosure_menu_toggled_novelty",
    "category": "Disclosure Menu",
    "source": "app"
  },
  {
    "id": "disclosure_menu_opened_all_products",
    "category": "Disclosure Menu",
    "source": "app"
  },
  {
    "id": "disclosure_menu_opened_prior_art_comparison",
    "category": "Disclosure Menu",
    "source": "app"
  },
  {
    "id": "disclosure_menu_opened_specification",
    "category": "Disclosure Menu",
    "source": "app"
  },
  {
    "id": "disclosure_menu_undo",
    "category": "Disclosure Menu",
    "source": "app"
  },
  {
    "id": "disclosure_menu_redo",
    "category": "Disclosure Menu",
    "source": "app"
  },
  {
    "id": "disclosure_menu_toggled_theme",
    "category": "Disclosure Menu",
    "source": "app"
  },
  {
    "id": "graph_entered_create_mode",
    "category": "Graph",
    "source": "app"
  },
  {
    "id": "graph_left_create_mode",
    "category": "Graph",
    "source": "app"
  },
  {
    "id": "graph_arranged_nodes",
    "category": "Graph",
    "source": "app"
  },
  {
    "id": "graph_panned",
    "category": "Graph",
    "source": "app"
  },
  {
    "id": "graph_zoomed_fit",
    "category": "Graph",
    "source": "app"
  },
  {
    "id": "graph_zoomed_in",
    "category": "Graph",
    "source": "app"
  },
  {
    "id": "graph_zoomed_out",
    "category": "Graph",
    "source": "app"
  },
  {
    "id": "graph_create_mode_clicked_system",
    "category": "Graph",
    "source": "app"
  },
  {
    "id": "graph_created_comprises_relationship",
    "category": "Graph",
    "source": "app"
  },
  {
    "id": "graph_create_mode_clicked_solution",
    "category": "Graph",
    "source": "app"
  },
  {
    "id": "graph_created_solution_relationship",
    "category": "Graph",
    "source": "app"
  },
  {
    "id": "graph_created_parentless_system",
    "category": "Graph",
    "source": "app"
  },
  {
    "id": "graph_created_attached_system",
    "category": "Graph",
    "source": "app"
  },
  {
    "id": "graph_created_attached_solution",
    "category": "Graph",
    "source": "app"
  },
  {
    "id": "graph_deselected_all",
    "category": "Graph",
    "source": "app"
  },
  {
    "id": "graph_selected_root",
    "category": "Graph",
    "source": "app"
  },
  {
    "id": "graph_selected_system",
    "category": "Graph",
    "source": "app"
  },
  {
    "id": "graph_multiselected_system",
    "category": "Graph",
    "source": "app"
  },
  {
    "id": "graph_deselected_system",
    "category": "Graph",
    "source": "app"
  },
  {
    "id": "graph_clicked_solution_placeholder",
    "category": "Graph",
    "source": "app"
  },
  {
    "id": "graph_selected_solution",
    "category": "Graph",
    "source": "app"
  },
  {
    "id": "graph_deselected_solution",
    "category": "Graph",
    "source": "app"
  },
  {
    "id": "graph_clicked_success_event",
    "category": "Graph",
    "source": "app"
  },
  {
    "id": "graph_clicked_method",
    "category": "Graph",
    "source": "app"
  },
  {
    "id": "graph_clicked_drawing",
    "category": "Graph",
    "source": "app"
  },
  {
    "id": "graph_selected_edge",
    "category": "Graph",
    "source": "app"
  },
  {
    "id": "graph_deselected_edge",
    "category": "Graph",
    "source": "app"
  },
  {
    "id": "graph_removed_selected",
    "category": "Graph",
    "source": "app"
  },
  {
    "id": "graph_attached_system",
    "category": "Graph",
    "source": "app"
  },
  {
    "id": "graph_attempted_to_create_from_instance",
    "category": "Graph",
    "source": "app"
  },
  {
    "id": "graph_sorted_systems",
    "category": "Graph",
    "source": "app"
  },
  {
    "id": "graph_dragged_system",
    "category": "Graph",
    "source": "app"
  },
  {
    "id": "opened_graph_context_menu",
    "category": "Graph Context Menu",
    "source": "app"
  },
  {
    "id": "graph_context_menu_created_system",
    "category": "Graph Context Menu",
    "source": "app"
  },
  {
    "id": "graph_context_menu_pasted_instance",
    "category": "Graph Context Menu",
    "source": "app"
  },
  {
    "id": "graph_context_menu_pasted_instances",
    "category": "Graph Context Menu",
    "source": "app"
  },
  {
    "id": "graph_context_menu_pasted_duplicate",
    "category": "Graph Context Menu",
    "source": "app"
  },
  {
    "id": "opened_system_context_menu",
    "category": "System Contect Menu",
    "source": "app"
  },
  {
    "id": "system_context_menu_created_component",
    "category": "System Contect Menu",
    "source": "app"
  },
  {
    "id": "system_context_menu_detached_component",
    "category": "System Contect Menu",
    "source": "app"
  },
  {
    "id": "system_context_menu_set_as_main_instance",
    "category": "System Contect Menu",
    "source": "app"
  },
  {
    "id": "system_context_menu_viewed_main_instance",
    "category": "System Contect Menu",
    "source": "app"
  },
  {
    "id": "system_context_menu_converted_to_term",
    "category": "System Contect Menu",
    "source": "app"
  },
  {
    "id": "system_context_menu_set_root",
    "category": "System Contect Menu",
    "source": "app"
  },
  {
    "id": "system_context_menu_added_system",
    "category": "System Contect Menu",
    "source": "app"
  },
  {
    "id": "system_context_menu_added_solution",
    "category": "System Contect Menu",
    "source": "app"
  },
  {
    "id": "system_context_menu_set_preferred_solution",
    "category": "System Contect Menu",
    "source": "app"
  },
  {
    "id": "system_context_menu_copied",
    "category": "System Contect Menu",
    "source": "app"
  },
  {
    "id": "system_context_menu_copied_instances",
    "category": "System Contect Menu",
    "source": "app"
  },
  {
    "id": "system_context_menu_pasted_instance",
    "category": "System Contect Menu",
    "source": "app"
  },
  {
    "id": "system_context_menu_pasted_instances",
    "category": "System Contect Menu",
    "source": "app"
  },
  {
    "id": "system_context_menu_pasted_duplicate",
    "category": "System Contect Menu",
    "source": "app"
  },
  {
    "id": "system_context_menu_pasted_solution_duplicate",
    "category": "System Contect Menu",
    "source": "app"
  },
  {
    "id": "system_context_menu_pasted_system_properties",
    "category": "System Contect Menu",
    "source": "app"
  },
  {
    "id": "system_context_menu_pasted_solution_properties",
    "category": "System Contect Menu",
    "source": "app"
  },
  {
    "id": "system_context_menu_removed_selected",
    "category": "System Contect Menu",
    "source": "app"
  },
  {
    "id": "system_context_added_solution",
    "category": "Solution Context",
    "source": "app"
  },
  {
    "id": "system_context_set_preferred_solution",
    "category": "Solution Context",
    "source": "app"
  },
  {
    "id": "system_context_removed_solution",
    "category": "Solution Context",
    "source": "app"
  },
  {
    "id": "system_context_updated_name",
    "category": "Solution Context",
    "source": "app"
  },
  {
    "id": "system_context_added_function",
    "category": "Solution Context",
    "source": "app"
  },
  {
    "id": "system_context_removed_function",
    "category": "Solution Context",
    "source": "app"
  },
  {
    "id": "solution_context_updated_name",
    "category": "Solution Context",
    "source": "app"
  },
  {
    "id": "solution_context_set_type_machine",
    "category": "Solution Context",
    "source": "app"
  },
  {
    "id": "solution_context_set_type_process",
    "category": "Solution Context",
    "source": "app"
  },
  {
    "id": "solution_context_set_type_article",
    "category": "Solution Context",
    "source": "app"
  },
  {
    "id": "solution_context_set_type_composition",
    "category": "Solution Context",
    "source": "app"
  },
  {
    "id": "solution_context_set_designing",
    "category": "Solution Context",
    "source": "app"
  },
  {
    "id": "solution_context_set_not_designing",
    "category": "Solution Context",
    "source": "app"
  },
  {
    "id": "solution_context_clicked_drawing",
    "category": "Solution Context",
    "source": "app"
  },
  {
    "id": "solution_context_clicked_method",
    "category": "Solution Context",
    "source": "app"
  },
  {
    "id": "solution_context_changed_drawing_reference",
    "category": "Solution Context",
    "source": "app"
  },
  {
    "id": "solution_context_added_user_requirement",
    "category": "Solution Context",
    "source": "app"
  },
  {
    "id": "solution_context_removed_user_requirement",
    "category": "Solution Context",
    "source": "app"
  },
  {
    "id": "solution_context_added_feature",
    "category": "Solution Context",
    "source": "app"
  },
  {
    "id": "solution_context_removed_feature",
    "category": "Solution Context",
    "source": "app"
  },
  {
    "id": "solution_context_added_definition",
    "category": "Solution Context",
    "source": "app"
  },
  {
    "id": "solution_context_removed_definition",
    "category": "Solution Context",
    "source": "app"
  },
  {
    "id": "solution_context_added_system",
    "category": "Solution Context",
    "source": "app"
  },
  {
    "id": "solution_context_removed_system",
    "category": "Solution Context",
    "source": "app"
  },
  {
    "id": "solution_context_sorted_systems",
    "category": "Solution Context",
    "source": "app"
  },
  {
    "id": "term_context_updated_name",
    "category": "Term Context",
    "source": "app"
  },
  {
    "id": "term_context_added_definition",
    "category": "Term Context",
    "source": "app"
  },
  {
    "id": "term_context_removed_definition",
    "category": "Term Context",
    "source": "app"
  },
  {
    "id": "term_context_converted_to_system",
    "category": "Term Context",
    "source": "app"
  },
  {
    "id": "instance_context_added_solution",
    "category": "Instance Context",
    "source": "app"
  },
  {
    "id": "instance_context_removed_solution",
    "category": "Instance Context",
    "source": "app"
  },
  {
    "id": "instance_context_use_default_requirements",
    "category": "Instance Context",
    "source": "app"
  },
  {
    "id": "instance_context_use_custom_requirements",
    "category": "Instance Context",
    "source": "app"
  },
  {
    "id": "instance_context_use_default_success_event",
    "category": "Instance Context",
    "source": "app"
  },
  {
    "id": "instance_context_use_custom_success_event",
    "category": "Instance Context",
    "source": "app"
  },
  {
    "id": "updated_feature",
    "category": "Feature",
    "source": "app"
  },
  {
    "id": "opened_drawing",
    "category": "Drawing",
    "source": "app"
  },
  {
    "id": "closed_drawing",
    "category": "Drawing",
    "source": "app"
  },
  {
    "id": "drawing_panned",
    "category": "Drawing",
    "source": "app"
  },
  {
    "id": "drawing_zoomed_fit",
    "category": "Drawing",
    "source": "app"
  },
  {
    "id": "drawing_zoomed_in",
    "category": "Drawing",
    "source": "app"
  },
  {
    "id": "drawing_zoomed_out",
    "category": "Drawing",
    "source": "app"
  },
  {
    "id": "drawing_set_orientation_landscape",
    "category": "Drawing",
    "source": "app"
  },
  {
    "id": "drawing_set_orientation_portrait",
    "category": "Drawing",
    "source": "app"
  },
  {
    "id": "drawing_updated_view_angle",
    "category": "Drawing",
    "source": "app"
  },
  {
    "id": "drawing_updated_description",
    "category": "Drawing",
    "source": "app"
  },
  {
    "id": "drawing_sorted_markers",
    "category": "Drawing",
    "source": "app"
  },
  {
    "id": "drawing_sorted_images",
    "category": "Drawing",
    "source": "app"
  },
  {
    "id": "drawing_removed_selected",
    "category": "Drawing",
    "source": "app"
  },
  {
    "id": "drawing_entered_preview_mode",
    "category": "Drawing",
    "source": "app"
  },
  {
    "id": "drawing_left_preview_mode",
    "category": "Drawing",
    "source": "app"
  },
  {
    "id": "drawing_entered_marker_create_mode",
    "category": "Drawing",
    "source": "app"
  },
  {
    "id": "drawing_left_marker_create_mode",
    "category": "Drawing",
    "source": "app"
  },
  {
    "id": "drawing_entered_highlight_create_mode",
    "category": "Drawing",
    "source": "app"
  },
  {
    "id": "drawing_left_highlight_create_mode",
    "category": "Drawing",
    "source": "app"
  },
  {
    "id": "drawing_deselected_all",
    "category": "Drawing",
    "source": "app"
  },
  {
    "id": "created_marker",
    "category": "Marker",
    "source": "app"
  },
  {
    "id": "selected_marker",
    "category": "Marker",
    "source": "app"
  },
  {
    "id": "marker_dragged",
    "category": "Marker",
    "source": "app"
  },
  {
    "id": "marker_dragged_mid_point",
    "category": "Marker",
    "source": "app"
  },
  {
    "id": "marker_dragged_start_point",
    "category": "Marker",
    "source": "app"
  },
  {
    "id": "marker_dragged_end_point",
    "category": "Marker",
    "source": "app"
  },
  {
    "id": "marker_created_system",
    "category": "Marker",
    "source": "app"
  },
  {
    "id": "marker_created_term",
    "category": "Marker",
    "source": "app"
  },
  {
    "id": "marker_set_type_term",
    "category": "Marker",
    "source": "app"
  },
  {
    "id": "marker_set_type_system",
    "category": "Marker",
    "source": "app"
  },
  {
    "id": "marker_set_term",
    "category": "Marker",
    "source": "app"
  },
  {
    "id": "marker_set_system",
    "category": "Marker",
    "source": "app"
  },
  {
    "id": "marker_set_label_orientation",
    "category": "Marker",
    "source": "app"
  },
  {
    "id": "marker_set_point_style_line",
    "category": "Marker",
    "source": "app"
  },
  {
    "id": "marker_set_point_style_arrow",
    "category": "Marker",
    "source": "app"
  },
  {
    "id": "marker_set_lead_line_curved",
    "category": "Marker",
    "source": "app"
  },
  {
    "id": "marker_set_lead_line_linear",
    "category": "Marker",
    "source": "app"
  },
  {
    "id": "marker_collapsed_lead_line",
    "category": "Marker",
    "source": "app"
  },
  {
    "id": "marker_label_selected_system",
    "category": "Marker",
    "source": "app"
  },
  {
    "id": "marker_label_selected_term",
    "category": "Marker",
    "source": "app"
  },
  {
    "id": "created_highlight",
    "category": "Highlight",
    "source": "app"
  },
  {
    "id": "highlight_added_point",
    "category": "Highlight",
    "source": "app"
  },
  {
    "id": "removed_highlight",
    "category": "Highlight",
    "source": "app"
  },
  {
    "id": "highlight_dragged",
    "category": "Highlight",
    "source": "app"
  },
  {
    "id": "opened_assets",
    "category": "Assets",
    "source": "app"
  },
  {
    "id": "closed_assets",
    "category": "Assets",
    "source": "app"
  },
  {
    "id": "created_asset",
    "category": "Assets",
    "source": "app"
  },
  {
    "id": "selected_asset",
    "category": "Assets",
    "source": "app"
  },
  {
    "id": "removed_asset",
    "category": "Assets",
    "source": "app"
  },
  {
    "id": "created_image",
    "category": "Image",
    "source": "app"
  },
  {
    "id": "selected_image",
    "category": "Image",
    "source": "app"
  },
  {
    "id": "image_resized",
    "category": "Image",
    "source": "app"
  },
  {
    "id": "image_dragged",
    "category": "Image",
    "source": "app"
  },
  {
    "id": "image_added_grayscale_filter",
    "category": "Image",
    "source": "app"
  },
  {
    "id": "image_removed_grayscale_filter",
    "category": "Image",
    "source": "app"
  },
  {
    "id": "image_added_threshold_filter",
    "category": "Image",
    "source": "app"
  },
  {
    "id": "image_removed_threshold_filter",
    "category": "Image",
    "source": "app"
  },
  {
    "id": "image_updated_asset",
    "category": "Image",
    "source": "app"
  },
  {
    "id": "opened_method",
    "category": "Method",
    "source": "app"
  },
  {
    "id": "closed_method",
    "category": "Method",
    "source": "app"
  },
  {
    "id": "method_panned",
    "category": "Method",
    "source": "app"
  },
  {
    "id": "method_zoomed_fit",
    "category": "Method",
    "source": "app"
  },
  {
    "id": "method_zoomed_in",
    "category": "Method",
    "source": "app"
  },
  {
    "id": "method_zoomed_out",
    "category": "Method",
    "source": "app"
  },
  {
    "id": "method_set_orientation_landscape",
    "category": "Method",
    "source": "app"
  },
  {
    "id": "method_set_orientation_portrait",
    "category": "Method",
    "source": "app"
  },
  {
    "id": "method_removed_selected",
    "category": "Method",
    "source": "app"
  },
  {
    "id": "method_entered_method_node_create_mode",
    "category": "Method",
    "source": "app"
  },
  {
    "id": "method_left_method_node_create_mode",
    "category": "Method",
    "source": "app"
  },
  {
    "id": "method_created_method_node",
    "category": "Method",
    "source": "app"
  },
  {
    "id": "method_deselected_all",
    "category": "Method",
    "source": "app"
  },
  {
    "id": "method_entered_preview_mode",
    "category": "Method",
    "source": "app"
  },
  {
    "id": "method_left_preview_mode",
    "category": "Method",
    "source": "app"
  },
  {
    "id": "selected_method_edge",
    "category": "Method",
    "source": "app"
  },
  {
    "id": "created_method_edge",
    "category": "Method",
    "source": "app"
  },
  {
    "id": "created_method_edge_from_start",
    "category": "Method",
    "source": "app"
  },
  {
    "id": "created_method_edge_point",
    "category": "Method",
    "source": "app"
  },
  {
    "id": "selected_method_node",
    "category": "Method",
    "source": "app"
  },
  {
    "id": "method_node_dragged",
    "category": "Method",
    "source": "app"
  },
  {
    "id": "method_node_resized",
    "category": "Method",
    "source": "app"
  },
  {
    "id": "method_node_clicked_system",
    "category": "Method",
    "source": "app"
  },
  {
    "id": "selected_method_edge_point",
    "category": "Method",
    "source": "app"
  },
  {
    "id": "method_edge_set_type_sequential",
    "category": "Method",
    "source": "app"
  },
  {
    "id": "method_edge_set_type_conditional",
    "category": "Method",
    "source": "app"
  },
  {
    "id": "method_edge_set_line_curve",
    "category": "Method",
    "source": "app"
  },
  {
    "id": "method_edge_moved_source_point",
    "category": "Method",
    "source": "app"
  },
  {
    "id": "method_edge_moved_target_target",
    "category": "Method",
    "source": "app"
  },
  {
    "id": "method_edge_dragged_control_point",
    "category": "Method",
    "source": "app"
  },
  {
    "id": "opened_system_popover",
    "category": "Mentions",
    "source": "app"
  },
  {
    "id": "clicked_system_mention",
    "category": "Mentions",
    "source": "app"
  },
  {
    "id": "opened_term_popover",
    "category": "Mentions",
    "source": "app"
  },
  {
    "id": "clicked_term_mention",
    "category": "Mentions",
    "source": "app"
  },
  {
    "id": "opened_mentionable_context_menu",
    "category": "Mentions",
    "source": "app"
  },
  {
    "id": "mentionable_created_term",
    "category": "Mentions",
    "source": "app"
  },
  {
    "id": "mentionable_created_system",
    "category": "Mentions",
    "source": "app"
  },
  {
    "id": "mentionable_referenced_term",
    "category": "Mentions",
    "source": "app"
  },
  {
    "id": "mentionable_referenced_system",
    "category": "Mentions",
    "source": "app"
  },
  {
    "id": "mentionable_referenced_tribute",
    "category": "Mentions",
    "source": "app"
  },
  {
    "id": "opened_mention_context_menu",
    "category": "Mentions",
    "source": "app"
  },
  {
    "id": "mention_context_menu_removed_mention",
    "category": "Mentions",
    "source": "app"
  },
  {
    "id": "mention_context_menu_unwrapped_mention",
    "category": "Mentions",
    "source": "app"
  },
  {
    "id": "mention_context_menu_viewed_term",
    "category": "Mentions",
    "source": "app"
  },
  {
    "id": "mention_context_menu_viewed_system",
    "category": "Mentions",
    "source": "app"
  },
  {
    "id": "mention_context_menu_viewed_solution",
    "category": "Mentions",
    "source": "app"
  },
  {
    "id": "opened_milestones_checklist",
    "category": "Milestones",
    "source": "app"
  },
  {
    "id": "closed_milestones_checklist",
    "category": "Milestones",
    "source": "app"
  },
  {
    "id": "visited_milestone_invention",
    "category": "Milestones",
    "source": "app"
  },
  {
    "id": "visited_milestone_product",
    "category": "Milestones",
    "source": "app"
  },
  {
    "id": "visited_milestone_possession",
    "category": "Milestones",
    "source": "app"
  },
  {
    "id": "visited_milestone_enablement",
    "category": "Milestones",
    "source": "app"
  },
  {
    "id": "visited_milestone_anticipation",
    "category": "Milestones",
    "source": "app"
  },
  {
    "id": "visited_milestone_obviousness",
    "category": "Milestones",
    "source": "app"
  },
  {
    "id": "visited_milestone_specification",
    "category": "Milestones",
    "source": "app"
  },
  {
    "id": "visited_milestone_review",
    "category": "Milestones",
    "source": "app"
  },
  {
    "id": "visited_milestone_file",
    "category": "Milestones",
    "source": "app"
  },
  {
    "id": "opened_patent_specification",
    "category": "Patent Specification",
    "source": "app"
  },
  {
    "id": "closed_patent_specification",
    "category": "Patent Specification",
    "source": "app"
  },
  {
    "id": "patent_specification_exported_specification",
    "category": "Patent Specification",
    "source": "app"
  },
  {
    "id": "patent_specification_exported_figures",
    "category": "Patent Specification",
    "source": "app"
  },
  {
    "id": "patent_specification_opened_preview",
    "category": "Patent Specification",
    "source": "app"
  },
  {
    "id": "patent_specification_closed_preview",
    "category": "Patent Specification",
    "source": "app"
  },
  {
    "id": "patent_specification_updated_title",
    "category": "Patent Specification",
    "source": "app"
  },
  {
    "id": "patent_specification_updated_inventors",
    "category": "Patent Specification",
    "source": "app"
  },
  {
    "id": "patent_specification_updated_fields",
    "category": "Patent Specification",
    "source": "app"
  },
  {
    "id": "patent_specification_updated_background",
    "category": "Patent Specification",
    "source": "app"
  },
  {
    "id": "patent_specification_updated_summary",
    "category": "Patent Specification",
    "source": "app"
  },
  {
    "id": "patent_specification_updated_abstract",
    "category": "Patent Specification",
    "source": "app"
  },
  {
    "id": "updated_patent_specification",
    "category": "Patent Specification",
    "source": "app"
  },
  {
    "id": "opened_products",
    "category": "Products",
    "source": "app"
  },
  {
    "id": "closed_products",
    "category": "Products",
    "source": "app"
  },
  {
    "id": "sorted_products",
    "category": "Products",
    "source": "app"
  },
  {
    "id": "added_product",
    "category": "Products",
    "source": "app"
  },
  {
    "id": "duplicated_product",
    "category": "Products",
    "source": "app"
  },
  {
    "id": "removed_product",
    "category": "Products",
    "source": "app"
  },
  {
    "id": "selected_product",
    "category": "Products",
    "source": "app"
  },
  {
    "id": "set_active_product",
    "category": "Products",
    "source": "app"
  },
  {
    "id": "product_updated_name",
    "category": "Product",
    "source": "app"
  },
  {
    "id": "product_updated_summary",
    "category": "Product",
    "source": "app"
  },
  {
    "id": "product_created_prior_art_comparison",
    "category": "Product",
    "source": "app"
  },
  {
    "id": "product_removed_prior_art_comparison",
    "category": "Product",
    "source": "app"
  },
  {
    "id": "product_sorted_prior_art_comparisons",
    "category": "Product",
    "source": "app"
  },
  {
    "id": "product_opened_prior_art_comparison",
    "category": "Product",
    "source": "app"
  },
  {
    "id": "product_sorted_prior_art_comparison",
    "category": "Product",
    "source": "app"
  },
  {
    "id": "opened_prior_arts",
    "category": "Prior Art",
    "source": "app"
  },
  {
    "id": "closed_prior_arts",
    "category": "Prior Art",
    "source": "app"
  },
  {
    "id": "created_prior_art",
    "category": "Prior Art",
    "source": "app"
  },
  {
    "id": "removed_prior_art",
    "category": "Prior Art",
    "source": "app"
  },
  {
    "id": "opened_prior_art_comparison",
    "category": "Prior Art",
    "source": "app"
  },
  {
    "id": "closed_prior_art_comparison",
    "category": "Prior Art",
    "source": "app"
  },
  {
    "id": "prior_art_comparison_added_novel_feature",
    "category": "Prior Art",
    "source": "app"
  },
  {
    "id": "prior_art_comparison_removed_novel_feature",
    "category": "Prior Art",
    "source": "app"
  }
]