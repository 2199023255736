import { UPDATE_SETTINGS } from './actionTypes';

export const updateSettings = (attributes = {}) => {
  return {
    type: UPDATE_SETTINGS,
    payload: {
      attributes,
    },
  };
};
