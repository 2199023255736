import {
  ADD_GRAPH,
  SET_ROOT_NODE,
  UPDATE_GRAPH,
  UPDATE_ORPHAN_NODES_LIST,
} from '../actions/actionTypes';

import { addFromSchema } from '../utils/schema';

const initialState = addFromSchema('graph');

const graphReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {

    case UPDATE_ORPHAN_NODES_LIST: {
      let { orphanNodesList } = payload;
      return {
        ...state,
        orphanNodesList
      };
    }

    case SET_ROOT_NODE: {
      let { nodeId, nodeType } = payload;

      return {
        ...state,
        rootNode: {
          id: nodeId,
          type: nodeType,
        }
      };
    }

    case ADD_GRAPH:
    case UPDATE_GRAPH: {
      const {
        elementsMap,
        elementVersionsMap,
        // orphanNodesList,
        disconnectedNodesList,
        methodsList,
        methodsMap,
        products,
        index
      } = payload;
      const createdAt = new Date();
      const updatedAt = new Date();
      return {
        ...state,
        index,
        elementsMap,
        elementVersionsMap,
        // orphanNodesList,
        disconnectedNodesList,
        methodsList,
        methodsMap,
        products,
        createdAt,
        updatedAt,
      };
    }

    /* istanbul ignore next */
    default:
      return state;
  }
};

export default graphReducer;
