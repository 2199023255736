import {
  ADD_COMPONENT,
  REMOVE_COMPONENT,
  SORT_COMPONENTS_LIST,
  UPDATE_COMPONENT,
} from '../actions/actionTypes';

import { addFromSchema } from '../utils/schema';
import { omit } from 'lodash';

const initialState = {
  ids: [],
  entities: {},
};

const componentsReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case ADD_COMPONENT: {
      let { componentId, attributes } = payload;
      const id = componentId;
      const createdAt = new Date();
      const updatedAt = new Date();
      const component = addFromSchema('component', {
        ...attributes,
        id,
        createdAt,
        updatedAt,
      });

      return {
        ids: [...state.ids, id],
        entities: {
          ...state.entities,
          [componentId]: component,
        },
      };
    }

    case UPDATE_COMPONENT: {
      const { componentId, attributes } = payload;
      const updatedAt = new Date();
      return {
        ...state,
        entities: {
          ...state.entities,
          [componentId]: {
            ...state.entities[componentId],
            ...attributes,
            updatedAt,
          },
        },
      };
    }

    case REMOVE_COMPONENT: {
      const { componentId } = payload;
      return {
        ids: state.ids.filter((id) => id !== componentId),
        entities: omit(state.entities, componentId),
      };
    }

    case SORT_COMPONENTS_LIST: {
      const { componentIds } = payload;
      return {
        ...state,
        ids: componentIds,
      };
    }

    /* istanbul ignore next */
    default:
      return state;
  }
};

export default componentsReducer;
