import {
  ADD_FIGURES,
  UPDATE_FIGURES,
} from '../actions/actionTypes';

import { addFromSchema } from '../utils/schema';

const initialState = addFromSchema('figures');

const figuresReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case ADD_FIGURES:
    case UPDATE_FIGURES: {
      const {
        breadth,
        depth,
        custom
      } = payload;
      const updatedAt = new Date();
      return {
        breadth,
        depth,
        custom,
        updatedAt,
      };
    }

    /* istanbul ignore next */
    default:
      return state;
  }
};

export default figuresReducer;
