import { action, computed } from '@ember/object';
import { alias, union } from '@ember/object/computed';
import {
  getElementVersion,
  getElementVersionRequirementsList,
} from '../../../selectors/element-version';
import { getFeature, getFeaturesList } from '../../../selectors/feature';

import Component from '@glimmer/component';
import { connect } from 'ember-redux';
import { getElementVersionRealizations } from '../../../utils/realization';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

const stateToComputed = (state, attrs) => ({
  elementVersion: getElementVersion(state, attrs.elementVersionId),
  featuresList: getFeaturesList(state),
  requirementsList: getElementVersionRequirementsList(
    state,
    attrs.elementVersionId
  ),
});

const dispatchToActions = {};

class ElementVersionRealization extends Component {
  @service models;
  @service redux;
  @tracked realization;

  @alias('elementVersion.definitionsList') definitionsList;
  @alias('elementVersion.detailsList') detailsList;
  @alias('elementVersion.featuresList') featuresList;
  @alias('elementVersion.examplesList') examplesList;
  @alias('elementVersion.comprisesList') comprisesList;
  @alias('elementVersion.constraintsList') constraintsList;

  @union(
    'definitionsList',
    'detailsList',
    'featuresList',
    'examplesList',
    'comprisesList',
    'requirementsList',
    'constraintsList'
  )
  descriptorsList;

  @computed('descriptorsList.[]')
  get featureModels() {
    const state = this.redux.getState();
    return this.descriptorsList.map((featureId) => {
      const feature = getFeature(state, featureId);
      return this.models.findOrCreate(featureId, 'feature', feature);
    });
  }

  @computed('featureModels.@each.value')
  get featureModelsValue() {
    return this.featureModels
      .map((featureModel) => featureModel && featureModel.value)
      .join('');
  }

  @action
  didInsert() {
    this.realization = this.getRealization();
  }

  @action
  onUpdate() {
    this.realization = this.getRealization();
  }

  getRealization() {
    const state = this.redux.getState();
    const realizedFeatures = {};
    const elementId = this.elementVersion && this.elementVersion.element;
    const elementVersionId = this.args.elementVersionId;
    const models = this.models;
    return (
      this.elementVersion &&
      getElementVersionRealizations({
        state,
        models,
        elementId,
        elementVersionId,
        elementVersionIndex: 0,
        realizedFeatures,
        languageCategory: 'patent_specification',
      }).join(' ')
    );
  }
}

export default connect(
  stateToComputed,
  dispatchToActions
)(ElementVersionRealization);
