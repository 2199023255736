import { curveLinear, line as d3Line } from 'd3-shape';
import { filter, forEach, keyBy, values } from 'lodash';
import { getComponents, getPrimaryInstancesList } from '../selectors/component';
import { getElement, getElements } from '../selectors/element';
import {
  getElementVersion,
  getElementVersions,
} from '../selectors/element-version';
import { getMethodEdgePath, parseCurve } from './drawing';
// import { textValue } from './string';
import { getOrphanNodesList, getRootNodeId } from '../selectors/graph';
import { getProduct, getProductsList } from '../selectors/product';

import { flextree } from 'd3-flextree';
import { getDrawingsList } from '../selectors/drawing';
import { getFeatures } from '../selectors/feature';
import { getMethod } from '../selectors/method';
import { svgPathProperties } from 'svg-path-properties';
import uuid from 'uuid/v4';

export const getPathSuggestedBendPoints = (path) => {
  const pathProps = new svgPathProperties(path);
  const parts = pathProps.getParts();
  const points = parts.map((part) => {
    const length = part.length;
    const point = part.getPointAtLength(length / 2);
    return {
      id: `suggested-bend-point-${uuid()}`,
      ...point,
    };
  });
  return points;
};

export const getPathCenterPoint = (path) => {
  const pathProps = new svgPathProperties(path);
  const totalLength = pathProps.getTotalLength();
  const centerPoint = pathProps.getPointAtLength(totalLength / 2);
  return centerPoint;
};

export const getPathOffCenterPoint = (path) => {
  const pathProps = new svgPathProperties(path);
  const totalLength = pathProps.getTotalLength();
  const centerPoint = pathProps.getPointAtLength(totalLength / 2.5);
  return centerPoint;
};

export const getPathArrowProperties = (path) => {
  const pathProps = new svgPathProperties(path);
  const totalLength = pathProps.getTotalLength();
  const endPoint = pathProps.getPointAtLength(totalLength);
  const offsetPoint = pathProps.getPointAtLength(Math.floor(totalLength - 20));

  const dx = endPoint.x - offsetPoint.x;
  const dy = endPoint.y - offsetPoint.y;

  let radians = Math.atan(dy / dx);
  radians += ((endPoint.x >= offsetPoint.x ? 90 : -90) * Math.PI) / 180;
  const degrees = radians * (180 / Math.PI);

  return {
    x: endPoint.x,
    y: endPoint.y,
    rotation: degrees,
  };
};

export const getGraph = (state) => {
  const orphanNodesList = getOrphanNodesList(state);
  const elementsMap = getElementsMap(state, orphanNodesList);
  const elementVersionsMap = getElementVersionsMap(state, orphanNodesList);
  const disconnectedNodesList = parseDisconnectedNodesList(
    state,
    elementVersionsMap
  );
  const { methodsList, methodsMap } = getMethodsMap(
    state,
    elementVersionsMap,
    orphanNodesList
  );

  return {
    // orphanNodesList,
    elementsMap,
    elementVersionsMap,
    disconnectedNodesList,
    methodsList,
    methodsMap,
  };
};

export const getProductGraphs = (state) => {
  const products = {};
  const productsList = getProductsList(state);
  productsList.forEach((productId) => {
    products[productId] = getProductGraph(state, productId);
  });
  return products;
};

export const getProductGraph = (state, productId) => {
  const product = getProduct(state, productId);
  const preferredElementVersionsList = product.preferredElementVersionsList;
  const preferredElementVersionsMap = keyBy(
    preferredElementVersionsList,
    (obj) => {
      return `${obj.element}_${obj.elementVersion}`;
    }
  );
  const orphanNodesList = getOrphanNodesList(state);
  const elementsMap = getElementsMap(
    state,
    orphanNodesList,
    preferredElementVersionsMap
  );

  const elementVersionsMap = getElementVersionsMap(
    state,
    orphanNodesList,
    preferredElementVersionsMap
  );

  return {
    elementsMap,
    elementVersionsMap,
  };

  // return {
  //   orphanNodesList,
  //   elementsMap,
  //   elementVersionsMap,
  //   disconnectedNodesList,
  //   methodsList,
  //   methodsMap,
  // }
};

export const parseOrphanNodesList = (state) => {
  const rootNodeId = getRootNodeId(state);
  let elements = getElements(state);
  elements = values(elements);
  const orphanElementIds = elements
    .filter((element) => !element.isComponent)
    .filter((element) => element.id)
    .filter((element) => !element.deletedAt)
    .filter((element) => !element.elementVersion && element.id !== rootNodeId)
    .map((element) => element.id);

  let elementVersions = getElementVersions(state);
  elementVersions = values(elementVersions);
  const orphanElementVersionIds = elementVersions
    .filter((elementVersion) => elementVersion.id)
    .filter((elementVersion) => !elementVersion.deletedAt)
    .filter((elementVersion) => !elementVersion.element)
    .map((elementVersion) => elementVersion.id);

  return orphanElementIds.concat(orphanElementVersionIds);
};

export const getCoordinatesMap = ({
  state,
  rootElement,
  elements,
  elementVersions,
  orphanNodesList,
  collapsedNodesList,
  activeView,
  preferredElementVersionsMap,
}) => {
  const rootNode = {
    id: 'root',
    type: 'element-version',
    x: 0,
    y: -592,
    elementsList: [rootElement.id, ...orphanNodesList],
  };

  const updatedInvention = {
    elements,
    elementVersions: {
      ...elementVersions,
      [rootNode.id]: rootNode,
    },
    collapsedNodesList,
    preferredElementVersionsMap,
    primaryInstancesList: getPrimaryInstancesList(state),
    components: getComponents(state),
    features: getFeatures(state)
  };

  return getCoordinateNodes(
    rootNode.id,
    rootNode.x,
    rootNode.y,
    activeView,
    updatedInvention
  );
};

export const parseDisconnectedNodesList = (state, nodesMap) => {
  const graphRoot = getRootNodeId(state);
  const disconnectedNodesList = filter(nodesMap, (node) => {
    return node.id !== 'root' && !node.ancestors.includes(graphRoot);
  }).map((node) => node.id);
  return disconnectedNodesList;
};


// TODO: Delete this?
export const getMethodsMap = (state, elementVersionsMap, orphanNodesList) => {
  const drawingsList = getDrawingsList(state);
  const drawingsCount = (drawingsList && drawingsList.length) || 0;
  const rootNodeId = getRootNodeId(state);
  const rootNode = elementVersionsMap[rootNodeId];
  const descendants = rootNode.descendants;
  const nestedElementVersionsList = [];

  descendants.forEach((nodeId) => {
    const node = elementVersionsMap[nodeId];
    if (node.type === 'element-version') {
      nestedElementVersionsList.push(nodeId);
    }
  });

  orphanNodesList.forEach((orphanNodeId) => {
    const rootNode = elementVersionsMap[orphanNodeId];
    const descendants = (rootNode && rootNode.descendants) || [];

    descendants.forEach((nodeId) => {
      const node = elementVersionsMap[nodeId];
      if (node.type === 'element-version') {
        nestedElementVersionsList.push(nodeId);
      }
    });
  });

  const methodsList = nestedElementVersionsList
    .map((elementVersionId) => {
      const elementVersion = getElementVersion(state, elementVersionId);
      return elementVersion.method;
    })
    .filter((methodId) => {
      const method = getMethod(state, methodId);
      const elementId = method && method.element;
      const element = elementId && getElement(state, elementId);
      const isSystem = element && element.category === 'system';
      const hasNodes = method && method.methodNodesList.length > 1;
      return isSystem && hasNodes;
    });

  let methodsMap = methodsList.map((methodId, index) => {
    const sequence = drawingsCount + index + 1;
    return {
      id: methodId,
      sequence,
    };
  });

  methodsMap = keyBy(methodsMap, 'id');

  return {
    methodsList,
    methodsMap,
  };
};

export const getElementsMap = (
  state,
  orphanNodesList,
  preferredElementVersionsMap = null
) => {
  const graphRoot = getRootNodeId(state);
  const elements = getElements(state);
  const elementVersions = getElementVersions(state);
  const features = getFeatures(state);
  const rootNode = {
    id: 'root',
    type: 'element-version',
    elementsList: [graphRoot, ...orphanNodesList],
  };
  const updatedInvention = {
    preferredElementVersionsMap,
    elements,
    elementVersions: {
      ...elementVersions,
      [rootNode.id]: rootNode,
    },
    primaryInstancesList: getPrimaryInstancesList(state),
    components: getComponents(state),
    features
  };

  const nodesMap = getElementsMapNodes(
    rootNode.id,
    updatedInvention,
    graphRoot
  );

  return nodesMap;
};

export const getElementVersionsMap = (
  state,
  orphanNodesList,
  preferredElementVersionsMap = null
) => {
  const graphRoot = getRootNodeId(state);
  const elements = getElements(state);
  const elementVersions = getElementVersions(state);
  const features = getFeatures(state);
  const rootNode = {
    id: 'root',
    type: 'element-version',
    elementsList: [graphRoot, ...orphanNodesList],
  };
  const updatedInvention = {
    preferredElementVersionsMap,
    elements,
    elementVersions: {
      ...elementVersions,
      [rootNode.id]: rootNode,
    },
    primaryInstancesList: getPrimaryInstancesList(state),
    components: getComponents(state),
    features
  };

  return getElementVersionsMapNodes(rootNode.id, updatedInvention, graphRoot);
};

const walkNode = (node, nodesMap, nodes = []) => {
  nodes.push(node.id);
  node.children.forEach((childNodeId) => {
    walkNode(nodesMap[childNodeId], nodesMap, nodes);
  });
  return nodes;
};

const walkNodeInstances = (node, nodesMap, nodes = []) => {
  nodes.push(node.id);
  if (!nodesMap[node.id].isInstance) {
    node.children.forEach((childNodeId) => {
      walkNodeInstances(nodesMap[childNodeId], nodesMap, nodes);
    });
  }
  return nodes;
};

export const getElementsMapNodes = (rootNodeId, invention, graphRoot) => {
  const inventionElementId = graphRoot;
  let nodes = {};

  const tree = getTree(rootNodeId, invention);

  tree.each((node) => {
    const id = node.data.id;
    const type = node.data.type;
    const category = node.data.category;
    const isInstance = node.data.instanceOf ? true : false;
    const instanceOf = (isInstance && node.data.instanceOf) || null;
    const comprisesList = node.data.comprisesList || [];
    const comprisesMap = node.data.comprisesMap || [];
    const depth = node.depth;
    const ancestors = node
      .ancestors()
      .map((flexNode) => flexNode.data.id)
      .filter((nodeId) => nodeId !== node.id)
      .uniq();
    const connected = ancestors.includes(inventionElementId);
    const children = node.children
      ? node.children.map((flexNode) => flexNode.data.id).uniq()
      : [];
    const parent = node.parent && node.parent.data.id;
    nodes = {
      ...nodes,
      [id]: {
        id,
        type,
        category,
        isInstance,
        instanceOf,
        depth,
        connected,
        ancestors,
        parent,
        children,
        comprisesList,
        comprisesMap,
      },
    };
  });

  // update descendants
  forEach(nodes, (node) => {
    node.descendants = walkNode(node, nodes).filter(
      (nodeId) => nodeId !== node.id
    );
    node.instanceDescendants = walkNodeInstances(node, nodes).filter(
      (nodeId) => nodeId !== node.id
    );
  });

  return nodes;
};

export const getElementVersionsMapNodes = (
  rootNodeId,
  invention,
  graphRoot
) => {
  const inventionElementId = graphRoot;
  let nodes = {};

  const tree = getRelationshipTree(rootNodeId, invention);

  tree.each((node) => {
    const id = node.data.id;
    const type = node.data.type;
    const isInstance = node.data.instanceOf ? true : false;
    const instanceOf = (isInstance && node.data.instanceOf) || null;
    const depth = node.depth;
    const ancestors = node
      .ancestors()
      .map((flexNode) => flexNode.data.id)
      .filter((nodeId) => nodeId !== node.id)
      .uniq();
    const connected = ancestors.includes(inventionElementId);
    const children = node.children
      ? node.children.map((flexNode) => flexNode.data.id)
      : [];
    const parent = node.parent && node.parent.data.id;
    nodes = {
      ...nodes,
      [id]: {
        id,
        type,
        isInstance,
        instanceOf,
        depth,
        connected,
        ancestors,
        parent,
        children,
      },
    };
  });

  // update descendants
  forEach(nodes, (node) => {
    node.descendants = walkNode(node, nodes).filter(
      (nodeId) => nodeId !== node.id
    );
    node.instanceDescendants = walkNodeInstances(node, nodes).filter(
      (nodeId) => nodeId !== node.id
    );
  });

  return nodes;
};

export const getCoordinateNodes = (
  rootNodeId,
  rootNodeX,
  rootNodeY,
  activeView,
  invention
) => {
  const nodes = {};
  const offsetX = rootNodeX;
  const offsetY = rootNodeY;
  const tree =
    activeView === 'solutions'
      ? getRelationshipTree(rootNodeId, invention)
      : getTree(rootNodeId, invention);

  let first = true;
  tree.each((node) => {
    let x;
    let y;

    if (first) {
      x = offsetX;
      y = offsetY;
      first = false;
    } else {
      x = offsetX + node.x;
      y = offsetY + node.y;
    }

    const startX = node.data._x;
    const startY = node.data._y;
    const height = node.data._height;

    const _node = {
      id: node.data.id,
      startX,
      startY,
      endX: x,
      endY: y,
      height,
    };

    nodes[_node.id] = _node;
  });

  return nodes;
};

const getNodeWidth = (node) => {
  let width = 350;

  if (node?.type === 'element') {
    width = node.category && node.category === 'system' ? 480 : 480;
    // width = 540;
  }

  return width;
};

const _getHierarchyNode = (id, invention) => {
  const preferredElementVersionsMap = invention.preferredElementVersionsMap;
  const node = invention.elementVersions[id] || invention.elements[id];

  const width = getNodeWidth(node);

  const height = 780;

  let childrenList;
  let comprisesList;
  let comprisesMap;
  let elementVersionsList;
  let category = node.category;

  if (node.type === 'element' && preferredElementVersionsMap) {
    let childElementVersionId;
    elementVersionsList = node.elementVersionsList;

    // if its a primary component instance, use the component element's elementVersionsList
    if (
      node.instanceOf &&
      node.component &&
      invention.primaryInstancesList &&
      invention.primaryInstancesList.includes(id)
    ) {
      const instanceOf = invention.elements[node.instanceOf];
      elementVersionsList = instanceOf.elementVersionsList;
      category = instanceOf.category;
    }

    if (elementVersionsList.length === 1) {
      childElementVersionId = elementVersionsList[0];
    } else {
      childElementVersionId = elementVersionsList.find(
        (elementVersionId) =>
          preferredElementVersionsMap[`${node.id}_${elementVersionId}`]
      );
      // childElementVersionId =
      //   node.elementVersionsList.find(
      //     (elementVersionId) => preferredElementVersionsMap[elementVersionId]
      //   ) || node.elementVersionsList[0];
    }

    const childElementVersion =
      childElementVersionId && invention.elementVersions[childElementVersionId];

    childrenList = childElementVersion ? childElementVersion.elementsList : [];
    comprisesList = childElementVersion ? childElementVersion.comprisesList : [];
    comprisesMap = _getComprisesMap(invention, comprisesList);
  }

  if (node.type === 'element' && !preferredElementVersionsMap) {
    elementVersionsList = node.elementVersionsList;

    // if its a primary component instance, use the component element's elementVersionsList
    if (
      node.instanceOf &&
      node.component &&
      invention.primaryInstancesList &&
      invention.primaryInstancesList.includes(id)
    ) {
      const instanceOf = invention.elements[node.instanceOf];
      elementVersionsList = instanceOf.elementVersionsList;
      category = instanceOf.category;
    }

    if (node.elementVersionsList.length === 1) {
      const elementVersion =
        invention.elementVersions[node.elementVersionsList[0]];
      childrenList = elementVersion.elementsList;
    }
    if (node.elementVersionsList.length > 1) {
      childrenList = node.elementVersionsList;
    }
    if (node.elementVersionsList.length === 0) {
      childrenList = [];
    }
  }

  if (node.type === 'element-version') {
    childrenList = node.elementsList || [];
    comprisesList = node.comprisesList || [];
    comprisesMap = _getComprisesMap(invention, comprisesList);
  }

  childrenList = childrenList.filter((id) => id);

  return {
    id: node.id,
    type: node.type,
    category,
    isInstance: node.isInstance,
    instanceOf: node.instanceOf,
    _x: node.x,
    _y: node.y,
    _height: height,
    childrenList,
    comprisesList,
    comprisesMap,
    children: [],
    size: [width, height],
  };
};

const _getComprisesMap = (invention, comprisesList) => {
  const arr = comprisesList.map(featureId => {
    const feature = invention.features[featureId];
    const element = feature.value.element;
    return {
      featureId,
      element
    }
  });
  return keyBy(arr, 'element');
}

const _getHierarchyData = (node, invention) => {
  if (
    !invention.collapsedNodesList ||
    !invention.collapsedNodesList.includes(node.id)
  ) {
    node.childrenList.forEach((childId) => {
      const child = _getHierarchyNode(childId, invention);
      node.children.push(child);
      _getHierarchyData(child, invention);
    });
  }
  return node;
};

const _getRelationshipHierarchyNode = (id, invention) => {
  const preferredElementVersionsMap = invention.preferredElementVersionsMap;
  const node = invention.elementVersions[id] || invention.elements[id];
  const width = getNodeWidth(node);
  const height = node.type === 'element' ? 475 : 780;

  let childrenList;
  let elementVersionsList;

  if (node.type === 'element' && preferredElementVersionsMap) {
    let childElementVersionId;
    elementVersionsList = node.elementVersionsList;

    // if its a primary component instance, use the component element's elementVersionsList
    if (
      node.instanceOf &&
      node.component &&
      invention.primaryInstancesList &&
      invention.primaryInstancesList.includes(id)
    ) {
      const instanceOf = invention.elements[node.instanceOf];
      elementVersionsList = instanceOf.elementVersionsList;
    }

    if (elementVersionsList.length === 1) {
      childElementVersionId = elementVersionsList[0];
    } else {
      // childElementVersionId =
      //   node.elementVersionsList.find(
      //     (elementVersionId) => preferredElementVersionsMap[elementVersionId]
      //   ) || node.elementVersionsList[0];
      childElementVersionId = elementVersionsList.find(
        (elementVersionId) =>
          preferredElementVersionsMap[`${node.id}_${elementVersionId}`]
      );
    }

    childrenList = childElementVersionId ? [childElementVersionId] : [];
  } else if (node.type === 'element' && !preferredElementVersionsMap) {
    elementVersionsList = node.elementVersionsList;

    // if its a primary component instance, use the component element's elementVersionsList
    if (
      node.instanceOf &&
      node.component &&
      invention.primaryInstancesList &&
      invention.primaryInstancesList.includes(id)
    ) {
      const instanceOf = invention.elements[node.instanceOf];
      elementVersionsList = instanceOf.elementVersionsList;
    }
    childrenList = elementVersionsList;
  } else if (node.type === 'element-version') {
    childrenList = node.elementsList;
  }
  childrenList = childrenList.filter((id) => id);

  return {
    id: node.id,
    type: node.type,
    isInstance: node.isInstance,
    instanceOf: node.instanceOf,
    _x: node.x,
    _y: node.y,
    childrenList,
    children: [],
    size: [width, height],
  };
};

const _getRelationshipHierarchyData = (node, invention) => {
  if (
    !invention.collapsedNodesList ||
    !invention.collapsedNodesList.includes(node.id)
  ) {
    node.childrenList.forEach((childId) => {
      const child = _getRelationshipHierarchyNode(childId, invention);
      node.children.push(child);
      _getRelationshipHierarchyData(child, invention);
    });
  }
  return node;
};

const getTreeSpacing = (nodeA, nodeB) => {
  const nodeAParentId = nodeA && nodeA.parent && nodeA.parent.data.id;
  const nodeBParentId = nodeB && nodeB.parent && nodeB.parent.data.id;
  const differentParents =
    nodeAParentId && nodeBParentId && nodeAParentId !== nodeBParentId;
  return differentParents ? 200 : 0;
};

export const getTree = (rootNodeId, invention) => {
  const rootNode = _getHierarchyNode(rootNodeId, invention);
  const layout = flextree({
    spacing: getTreeSpacing,
  });
  const data = _getHierarchyData(rootNode, invention);
  const hierarchy = layout.hierarchy(data);
  const tree = layout(hierarchy);
  return tree;
};

export const getRelationshipTree = (rootNodeId, invention) => {
  const rootNode = _getRelationshipHierarchyNode(rootNodeId, invention);
  const layout = flextree({
    spacing: getTreeSpacing,
  });
  const data = _getRelationshipHierarchyData(rootNode, invention);
  const hierarchy = layout.hierarchy(data);
  const tree = layout(hierarchy);
  return tree;
};

export const getLengthForPoint = (p, pathProperties) => {
  let pathLength = pathProperties.getTotalLength();
  let precision = 25;
  let division = pathLength / precision;
  let theRecord = pathLength;
  let theSegment;
  for (let i = 0; i < precision; i++) {
    // get a point on the path for thia distance
    let _p = pathProperties.getPointAtLength(i * division);
    // get the distance between the new point _p and the point p
    let theDistance = pointDistance(_p.x, _p.y, p.x, p.y);
    if (theDistance < theRecord) {
      // if the distance is smaller than the record set the new record
      theRecord = theDistance;
      theSegment = i;
    }
  }
  return theSegment * division;
};

// export const getPointAtBlend = (sourceX, sourceY, targetX, targetY, blend) => {
//   const x = sourceX + blend * (targetX - sourceX);
//   const y = sourceY + blend * (targetY - sourceY);
//   return {
//     x,
//     y,
//   };
// };

export const getHandleCoordinates = (
  x,
  y,
  width,
  height,
  handleWidth = 15,
  handleHeight = 15
) => {
  return {
    label: {
      x: x + width / 2 - handleWidth / 2,
      y: y - 10,
    },
    NORTH: {
      x: x + width / 2 - handleWidth / 2,
      y: y - handleHeight / 2,
    },
    NORTHEAST: {
      x: x + width - handleWidth / 2,
      y: y - handleHeight / 2,
    },
    NORTHWEST: {
      x: x - handleWidth / 2,
      y: y - handleHeight / 2,
    },
    EAST: {
      x: x + width - handleWidth / 2,
      y: y + height / 2 - handleHeight / 2,
    },
    SOUTHEAST: {
      x: x + width - handleWidth / 2,
      y: y + height - handleHeight / 2,
    },
    SOUTH: {
      x: x + width / 2 - handleWidth / 2,
      y: y + height - handleHeight / 2,
    },
    SOUTHWEST: {
      x: x - handleWidth / 2,
      y: y + height - handleHeight / 2,
    },
    WEST: {
      x: x - handleWidth / 2,
      y: y + height / 2 - handleHeight / 2,
    },
  };
};

export const getPath = (
  source,
  sourcePos,
  target,
  targetPos,
  bendPoints = [],
  curve,
  draggingSuggestedBendPoint,
  hasTargetPadding = false
) => {
  const sourceHandleCoordinates = getHandleCoordinates(
    source.x,
    source.y,
    source.width,
    source.height,
    0,
    0
  );
  const sourceX = sourceHandleCoordinates[sourcePos].x;
  const sourceY = sourceHandleCoordinates[sourcePos].y;
  const targetHandleCoordinates = getHandleCoordinates(
    target.x,
    target.y,
    target.width,
    target.height,
    0,
    0
  );

  let targetX = targetHandleCoordinates[targetPos].x;
  let targetY = targetHandleCoordinates[targetPos].y;

  if (hasTargetPadding) {
    const closestPointToTarget = bendPoints.length
      ? bendPoints[bendPoints.length - 1]
      : { x: sourceX, y: sourceY };

    const targetPoint = getTargetPoint({
      shape: 'circle',
      targetRadius: 18,
      sourceX: closestPointToTarget.x,
      sourceY: closestPointToTarget.y,
      targetX: targetX,
      targetY: targetY,
    });

    targetX = targetPoint.x;
    targetY = targetPoint.y;
  }

  return getMethodEdgePath({
    curve: parseCurve(curve),
    sourceX,
    sourceY,
    draggingSuggestedBendPoint,
    pointsArray: bendPoints,
    targetX,
    targetY,
  });
};

// export const getTextHeight = ({
//   content,
//   width,
//   fontSize = '13px',
//   lineHeight = '20px',
//   fontWeight = '400',
// }) => {
//   const textMetrics = TextMetrics.init({
//     fontSize,
//     lineHeight,
//     fontFamily:
//       '-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Avenir, PingFang SC, Helvetica Neue, Helvetica',
//     fontWeight,
//     width,
//   });
//   return textMetrics.height(content);
// };

// export const getContentHeight = (content, width, paddingTop, paddingBottom) => {
//   return (
//     getTextHeight({
//       content,
//       width,
//       fontSize: '19.5px',
//       lineHeight: '29px',
//     }) +
//     paddingTop +
//     paddingBottom
//   );
// };

// export const getElementNameHeight = (content, width) => {
//   return getTextHeight({
//     content,
//     width,
//     fontSize: '40px',
//     lineHeight: '44px',
//     fontWeight: '600',
//   });
// };

// export const getMethodNodeHeight = ({
//   width = 300,
//   minHeight = 69,
//   nodePaddingTop = 20,
//   nodePaddingBottom = 20,
//   nodePaddingLeft = 20,
//   nodePaddingRight = 20,
//   content = '',
// } = {}) => {
//   let height = nodePaddingTop + nodePaddingBottom;
//   const contentWidth = width - nodePaddingLeft - nodePaddingRight;
//   const contentHeight = getTextHeight({
//     content,
//     width: contentWidth,
//     fontSize: '19.5px',
//     lineHeight: '29px',
//   });

//   height += contentHeight;

//   return Math.max(height, minHeight);
// };

// export const getElementNodeHeight = ({
//   width = 400,
//   hasElementVersion = false,
//   // footerHeight = 58.5,
//   footerHeight = 10,
//   nodePaddingLeft = 26,
//   nodePaddingRight = 26,
//   headerHeight = 28,
//   contentSectionPaddingBottom = 16.25,
//   contentMinHeight = 38,
//   contentPadding = 0,
//   elementNamePadding = {
//     top: 19.5,
//     bottom: 19.5,
//   },
//   name = '',
//   requirements = [],
//   outcome,
//   showFooter = true,
//   showElementName = true,
//   showRequirements = true,
//   showOutcome = true,
// } = {}) => {
//   let height = 0;
//   const contentWidth = width - nodePaddingLeft - nodePaddingRight;

//   if (hasElementVersion) {
//     height += 290;
//   }

//   if (showElementName) {
//     height += elementNamePadding.top;
//     height += elementNamePadding.bottom;
//     const elementNameHeight = getElementNameHeight(name, contentWidth);
//     height += elementNameHeight;
//   }

//   if (showOutcome) {
//     height += headerHeight;
//     height += contentSectionPaddingBottom;
//     const outcomeHeight = getContentHeight(
//       outcome,
//       contentWidth,
//       contentPadding,
//       contentPadding
//     );
//     height += outcomeHeight;
//   }

//   if (showFooter) {
//     height += footerHeight;
//   }

//   return height;
// };

// export const getDagreLayout = (
//   nodes,
//   edges,
//   defaultWidth = 600,
//   defaultHeight = 600
// ) => {
//   const nodesObj = {};
//   const edgesObj = {};
//   const graph = new dagre.graphlib.Graph({
//     rankdir: 'LR',
//     compound: true,
//     directed: true,
//     multigraph: true,
//     ranker: 'tight-tree'
//   });
//
//   // Set an object for the graph label
//   graph.setGraph({});
//
//   // Default to assigning a new object as a label for each new edge.
//   graph.setDefaultEdgeLabel(function() {
//     return {};
//   });
//   // Add nodes to the graph. The first argument is the node id. The second is
//   // metadata about the node. In this case we're going to add labels to each of
//   // our nodes.
//   nodes.forEach((node, index) => {
//     let width = node.width || defaultWidth;
//     let height = node.height || defaultHeight;
//
//     // if (node.type === 'system') {
//     //   width = 400;
//     // }
//
//     graph.setNode(node.id, {
//       order: index,
//       rank: index,
//       width: width,
//       height: height
//     });
//     const parent = edges.find(edge => edge.target === node.id);
//     if (parent) {
//       graph.setParent(node.id, parent.source + '-group');
//     } else {
//       graph.setParent(node.id, 'invention-group');
//     }
//   });
//
//   edges.forEach(edge => {
//     // const minlen = edge.sourceType === 'system' && edge.sourceChildren === 1 ? 1 : 3;
//     // const minlen = edge.sourceType === 'system'  ? 1 : 4;
//     const minlen = 3;
//     graph.setEdge(edge.source, edge.target, { minlen });
//   });
//
//   dagre.layout(graph);
//
//   const graphWidth = graph.graph().width;
//
//   graph
//     .nodes()
//     .filter(node => graph.node(node))
//     .forEach(node => {
//       const graphNode = graph.node(node);
//       graphNode.x = graphNode.x - graphWidth / 2;
//       graphNode.y = graphNode.y - graphNode.height / 2;
//       nodesObj[node] = graphNode;
//     });
//   graph
//     .edges()
//     .filter(edge => graph.edge(edge))
//     .forEach(edge => {
//       const graphEdge = graph.edge(edge);
//       // graphNode.x = graphNode.x - graphWidth / 2;
//       // graphNode.y = graphNode.y - graphNode.height / 2;
//       // const points = graphEdge.points.map(point => {
//       //   return {
//       //     x: point.x - graphWidth / 2,
//       //     y: point.y - 600 / 2,
//       //   }
//       // })
//       edgesObj[`${edge.v}-${edge.w}`] = {
//         id: `${edge.v}-${edge.w}`,
//         ...graphEdge,
//         // points,
//         source: edge.v,
//         target: edge.w
//       };
//     });
//
//   return {
//     nodes: nodesObj,
//     edges: edgesObj
//   };
// };

export const getShapePath = ({
  shape,
  shapeAttributes = {},
  width = 0,
  height = 0,
}) => {
  switch (shape) {
    // case 'circle':
    // return _getCirclePath({
    //   r: Math.max(width, height),
    // });
    case 'rect':
      return _getRectPath({
        width,
        height,
        rx: shapeAttributes.rx || 0,
        ry: shapeAttributes.ry || 0,
      });
  }

  return Error(`That shape isn't supported or is missing`);
};

// const _getCirclePath = ({ r, cx = 0, cy = 0 }) => {
//   const kappa = 0.551784;
//   const cd = r * kappa; // Control distance.

//   const d = [
//     'M',
//     cx,
//     cy - r, // Move to the first point.
//     'C',
//     cx + cd,
//     cy - r,
//     cx + r,
//     cy - cd,
//     cx + r,
//     cy, // I. Quadrant.
//     'C',
//     cx + r,
//     cy + cd,
//     cx + cd,
//     cy + r,
//     cx,
//     cy + r, // II. Quadrant.
//     'C',
//     cx - cd,
//     cy + r,
//     cx - r,
//     cy + cd,
//     cx - r,
//     cy, // III. Quadrant.
//     'C',
//     cx - r,
//     cy - cd,
//     cx - cd,
//     cy - r,
//     cx,
//     cy - r, // IV. Quadrant.
//     'Z',
//   ].join(' ');

//   return d;
// };

const _getRectPath = function ({
  width = 0,
  height = 0,
  rx = 0,
  ry = 0,
  x = 0,
  y = 0,
}) {
  const topRx = Math.min(rx, width / 2);
  const bottomRx = Math.min(rx, width / 2);
  const topRy = Math.min(ry, height / 2);
  const bottomRy = Math.min(ry, height / 2);

  let d;

  if (topRx || bottomRx || topRy || bottomRy) {
    // d = `M ${x} ${y + topRy} v ${height - topRy - bottomR}`
    d = [
      'M',
      x,
      y + topRy,
      'v',
      height - topRy - bottomRy,
      'a',
      bottomRx,
      bottomRy,
      0,
      0,
      0,
      bottomRx,
      bottomRy,
      'h',
      width - 2 * bottomRx,
      'a',
      bottomRx,
      bottomRy,
      0,
      0,
      0,
      bottomRx,
      -bottomRy,
      'v',
      -(height - bottomRy - topRy),
      'a',
      topRx,
      topRy,
      0,
      0,
      0,
      -topRx,
      -topRy,
      'h',
      -(width - 2 * topRx),
      'a',
      topRx,
      topRy,
      0,
      0,
      0,
      -topRx,
      topRy,
      'Z',
    ];
  } else {
    d = ['M', x, y, 'H', x + width, 'V', y + height, 'H', x, 'V', y, 'Z'];
  }

  return d.join(' ');
};

export const samplePath = (path, interval = 1) => {
  const pathNode = document.createElementNS(
    'http://www.w3.org/2000/svg',
    'path'
  );
  pathNode.setAttribute('d', path);
  const length = pathNode.getTotalLength();
  const samples = [];
  let distance = 0;
  let sample;
  while (distance < length) {
    sample = pathNode.getPointAtLength(distance);
    samples.push({ x: sample.x, y: sample.y, distance: distance });
    distance += interval;
  }
  return samples;
};

export const pointDistance = (x1, y1, x2, y2) => {
  return Math.sqrt(Math.pow(x1 - x2, 2) + Math.pow(y1 - y2, 2));
};

export const getEdgeLine = (points) => {
  const pointsArray = points.map(Object.values);
  const line = d3Line().curve(curveLinear);
  return line(pointsArray);
};

export const getClosestPointAlongPath = ({
  samples = [],
  sourceX = 0,
  sourceY = 0,
  targetX = 0,
  targetY = 0,
  targetWidth = 0,
  targetHeight = 0,
}) => {
  let spot;
  let minDistance = Infinity;
  let closestSamples = [];
  let i, sample, gp, centerDistance, refDistance, distance;

  for (i = 0; i < samples.length; i++) {
    sample = samples[i];
    // Convert the sample point in the local coordinate system to the global coordinate system.
    gp = {};
    gp.x = sample.x + targetX - targetWidth / 2;
    gp.y = sample.y + targetY - targetHeight / 2;
    sample = gp;
    centerDistance = pointDistance(sample.x, sample.y, targetX, targetY);
    // Penalize a higher distance to the reference point by 10%.
    // This gives better results. This is due to
    // inaccuracies introduced by rounding errors and getPointAtLength() returns.
    refDistance = pointDistance(sample.x, sample.y, sourceX, sourceY);
    refDistance = refDistance * 1.1;
    distance = centerDistance + refDistance;

    if (distance < minDistance) {
      minDistance = distance;
      closestSamples = [
        {
          sample: sample,
          refDistance: refDistance,
        },
      ];
    }
  }

  if (closestSamples[0]) {
    spot = closestSamples[0].sample;
  }
  return spot;
};

export const getPointAtDistance = (x, y, angle, distance) => {
  const result = {};
  result.x = Math.round(Math.cos(angle) * distance + x);
  result.y = Math.round(Math.sin(angle) * distance + y);
  return result;
};

// export const getSourceOffset = (
//   nodeCategory,
//   stage,
//   source,
//   target,
//   nodeWidth
// ) => {
//   const edgeId = `${source.id}_${target.id}`;
//   const edge = stage.findOne(`#${edgeId}`);
//   const sourceNode = stage.findOne(`#${source.id}`);

//   let offset = 0;

//       if (edge && sourceNode) {
//         const elementVersionNode = sourceNode.find(
//           '.element-version-node'
//         )[0];
//         offset = elementVersionNode.y();
//       } else {
//         const offsetTextNode
//         const elementVersionNode = sourceNode.find(
//           '.element-version-node'
//         )[0];
//         const sourceY = source.y + elementVersionNode.y();
//         source = {
//           ...source,
//           // y: source.y - this.elementSourceOffset.y,
//           y: sourceY,
//         };
//       }
//       }
// };

export const getSourcePoint = ({
  sourceRadius = 60,
  sourceX,
  sourceY,
  targetX,
  targetY,
}) => {
  const angle = Math.atan2(targetY - sourceY, targetX - sourceX);
  return getPointAtDistance(sourceX, sourceY, angle, sourceRadius);
};

export const getTargetPoint = ({
  shape = 'rect',
  sourceX,
  sourceY,
  targetX,
  targetY,
  targetWidth,
  targetHeight,
  targetRadius = 10,
  padding = 0,
  snapTop = false,
}) => {
  if (shape === 'rect') {
    const shapePath = getShapePath({
      width: targetWidth + padding * 2,
      height: targetHeight + padding * 2,
      shape,
    });

    const samples = samplePath(shapePath, 4);

    const point = getClosestPointAlongPath({
      samples,
      sourceX,
      sourceY,
      targetX,
      targetY,
      targetWidth: targetWidth + padding * 2,
      targetHeight: targetHeight + padding * 2,
    });

    if (snapTop && point.y < targetY) {
      point.x = targetX;
      point.y = targetY - (targetHeight + padding * 2) / 2;
      // point.y = (targetY + padding * 2)/2;
    }
    return point;
  } else if (shape === 'circle') {
    const xtraPadding = -1 * (targetRadius + 10) - padding;
    const angle = Math.atan2(targetY - sourceY, targetX - sourceX);
    return getPointAtDistance(targetX, targetY, angle, xtraPadding);
  }
};
