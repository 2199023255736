import { action, computed } from '@ember/object';
import {
  getActiveNavigationStack,
  getActiveProductId,
  getExplorerWidth,
  getInventionUi,
  getPreviewMode,
  getShowingExplorer,
} from '../../../selectors/invention-ui';

import Component from '@glimmer/component';
import { connect } from 'ember-redux';
import { htmlSafe } from '@ember/string';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';
import { setActiveContextTab } from '../../../actions/invention-ui';
import { tracked } from '@glimmer/tracking';

const dispatchToActions = {};

const stateToComputed = function (state) {
  return {
    showingExplorer: getShowingExplorer(state),
    explorerWidth: getExplorerWidth(state),
    activeProductId: getActiveProductId(state),
    activeNavigationStack: getActiveNavigationStack(state),
    ui: getInventionUi(state),
    previewMode: getPreviewMode(state),
  };
};

class Explorer extends Component {
  @service data;
  @service applicationState;
  @service settings;

  @tracked width = 340;

  defaultWidth = 340;
  maxWidth = 600;

  get styleNamespace() {
    return podNames['explorer'];
  }

  @computed('showingExplorer', 'styleNamespace')
  get classNames() {
    let classNames = [this.styleNamespace, 'ui-left-context-container'];
    if (!this.showingExplorer) classNames.push('is-hidden');
    return classNames.join(' ');
  }

  get minWidth() {
    return this.settings.blackAndWhiteMode ? 240 : 340;
  }

  @action
  didInsert() {
    this.width = this.explorerWidth || this.defaultWidth;
  }

  @action
  onUndo() {
    this.width = this.explorerWidth;
  }

  @computed('showingExplorer', 'width')
  get widthStyle() {
    const width = this.showingExplorer ? this.width : 0;
    return htmlSafe(`width: ${width}px;`);
  }

  @computed('activeNavigationStack.{modelType,modelId}')
  get activeDrawingId() {
    return (
      this.activeNavigationStack &&
      this.activeNavigationStack.modelType === 'drawing' &&
      this.activeNavigationStack.modelId
    );
  }

  @computed('activeNavigationStack.{modelType,modelId}')
  get activeMethodId() {
    return (
      this.activeNavigationStack &&
      this.activeNavigationStack.modelType === 'method' &&
      this.activeNavigationStack.modelId
    );
  }

  @computed('activeNavigationStack.modelType')
  get activePatentSpecification() {
    return this.activeNavigationStack &&
      this.activeNavigationStack.modelType === 'patent-specification'
      ? true
      : false;
  }

  @action
  onResize(x) {
    let width = x;

    if (x < this.minWidth) {
      width = this.minWidth;
    }

    if (x > this.maxWidth) {
      width = this.maxWidth;
    }

    this.width = width;
  }

  @action
  onResizeEnd() {
    this.data.setExplorerWidth(this.width);
  }

  @computed('ui.activeContextTab')
  get activeTab() {
    return this.ui && this.ui.activeContextTab;
  }

  @computed('ui.showingExplorer')
  get showingExplorer() {
    return this.ui && this.ui.showingExplorer;
  }

  @action
  setActiveTab(activeTab) {
    this.redux.store.dispatch(setActiveContextTab(activeTab));
  }
}

export default connect(stateToComputed, dispatchToActions)(Explorer);
