import { getElement, getElementName } from '../../../selectors/element';

import Component from '@glimmer/component';
import { computed } from '@ember/object';
import { connect } from 'ember-redux';
import { getFeature } from '../../../selectors/feature';
import { mentionContentTemplate } from '../../../utils/realization';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';

const dispatchToActions = {};

const stateToComputed = (state, attrs) => ({
  feature: getFeature(state, attrs.featureId),
});

class FeatureValueComprises extends Component {
  @service redux;

  get styleNamespace() {
    return podNames['feature-value-comprises'];
  }

  get classNames() {
    let classNames = [this.styleNamespace];
    return classNames.join(' ');
  }

  @computed('args.value.element')
  get comprisesElement() {
    const state = this.redux.getState();
    return getElement(state, this.args.value.element);
  }

  @computed('comprisesElement.{id,name}', 'args.value')
  get mentionsValue() {
    const state = this.redux.getState();
    const elementName = getElementName(state, this.args.value);
    return mentionContentTemplate(
      elementName,
      this.comprisesElement.id,
      null
    );
  }
}

export default connect(
  stateToComputed,
  dispatchToActions
)(FeatureValueComprises);
