import { ActionCreators } from 'redux-undo';
import Route from '@ember/routing/route';
import { getActiveProductId } from '../../selectors/invention-ui';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency-decorators';
import { waitForProperty } from 'ember-concurrency';

export default class InventionRoute extends Route {
  @service session;
  @service sessionManager;
  @service redux;
  @service import;
  @service models;
  @service importLegacy;
  @service data;
  @service usersDb;
  @service notifications;
  @service tracking;


  @task
  *findOrCreateInvention(inventionId) {
    yield waitForProperty(this, 'redux.bootstrapped', true);
    const invention = this.redux.getState().present.invention;

    // new invention
    if (invention && !invention.id) {
      yield this.data.addInvention(inventionId);
      this.usersDb.addInvention(inventionId);
      this.tracking.trackEvent('created_disclosure');
      this.notifications.clearAll();
    } else {
      yield this.data.registerMeta(inventionId);
    }
    let  state = this.redux.getState();

    this.data.addGraph();
    this.data.addFigures();
    
    // auto arrange
    const productId = getActiveProductId(state);
    this.data.arrangeSeq(productId);

    this.redux.store.dispatch(ActionCreators.clearHistory());
  }

  async model(params) {
    this.models.clearCache();
    await this.redux.setup(params.invention_id);
    await this.findOrCreateInvention.perform(params.invention_id);
    return params.invention_id;
  }

  setupController(controller, model) {
    super.setupController(controller, model);
    controller.set('name', null);
    controller.set('description', null);
    controller.set('inspiration', null);
    controller.set('assets', null);
    controller.set('productName', null);
    // controller.set('enableCloudBackups', null);
  }
}
