import { getGraph, getProductGraphs } from '../../../utils/graph';

import Component from '@glimmer/component';
import ENV from '../../../config/environment';
import { action } from '@ember/object';
import axios from 'axios';
import { dasherize } from '@ember/string';
import fileDownload from 'js-file-download';
import { getInvention } from '../../../selectors/invention';
import podNames from 'ember-component-css/pod-names';
import { restartableTask } from 'ember-concurrency-decorators';
import { inject as service } from '@ember/service';
import { timeout } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';

class HistoryModal extends Component {
  // @service data;
  // @service redux;
  @service router;
  @service import;
  @service tracking;
  @service sessionManager;
  @service migrations;
  @service worker;
  @tracked activeVersionId;
  @tracked activeVersion;
  @tracked activeState;
  @tracked loadingActiveVersion;

  get styleNamespace() {
    return podNames['history-modal'];
  }

  get classNames() {
    let classNames = [this.styleNamespace, 'history-modal'];
    return classNames.join(' ');
  }

  get versions() {
    return this.args.instance && this.args.instance.versions;
  }

  get namedVersions() {
    return this.args.instance && this.args.instance.namedVersions;
  }

  get instanceCreatedAt() {
    return this.args.instance && this.args.instance.createdAt;
  }

  get mostRecentVersionId() {
    return (
      this.args.instance &&
      this.args.instance.mostRecentVersion &&
      this.args.instance.mostRecentVersion.id
    );
  }

  @restartableTask
  *setActiveVersion(versionId) {
    this.activeVersionId = versionId;
    this.activeState = null;
    this.loadingActiveVersion = true;
    yield timeout(200);
    this.activeVersion = this.versions.find(({ id }) => id === versionId);
    // const parseWorker = yield this.worker.open('json-parse');
    // const parsedState = yield parseWorker.postMessage(version.state);
    // parseWorker.terminate();

    const parsedState = yield this.loadVersionState(versionId);

    const {
      orphanNodesList,
      elementsMap,
      elementVersionsMap,
      disconnectedNodesList,
      methodsList,
      methodsMap,
    } = getGraph(parsedState);

    const products = getProductGraphs(parsedState);
    const migratedState = this.migrations.migrate(parsedState.present);
    const state = {
      ...parsedState,
      present: {
        ...migratedState,
        graph: {
          ...migratedState.graph,
          orphanNodesList,
          elementsMap,
          elementVersionsMap,
          disconnectedNodesList,
          methodsList,
          methodsMap,
          products,
        },
      },
    };

    this.activeState = state;

    this.loadingActiveVersion = false;
  }

  async loadVersionState(versionId) {
    try {
      const res = await axios({
        method: 'GET',
        url: ENV.IDENTITY_API_URL + `/storage/${versionId}?type=versions`,
        headers: {
          Authorization: this.sessionManager.accessToken,
        },
      });

      return res.data;
    } catch (err) {
      console.error(err);
    }
  }

  // async deleteVersion(versionId) {
  //   try {
  //     await axios({
  //       method: 'DELETE',
  //       url: ENV.IDENTITY_API_URL + `/versions/${versionId}`,
  //       headers: {
  //         Authorization: this.sessionManager.accessToken,
  //       },
  //     });
  //     this.args.instance.reload();

  //     if (this.mostRecentVersionId) {
  //       this.setActiveVersion.perform(this.mostRecentVersionId);
  //     }
  //   } catch (err) {
  //     console.error(err);
  //   }
  // }

  @action
  didInsert() {
    if (this.mostRecentVersionId) {
      this.setActiveVersion.perform(this.mostRecentVersionId);
    }
  }

  @action
  hideModal() {
    this.router.transitionTo('invention', this.args.instanceId);
  }

  @action
  onVersionSelect(versionId) {
    this.setActiveVersion.perform(versionId);
  }

  @action
  async onVersionDownload(versionId) {
    const state = this.activeState;
    const versionName = this.activeVersion.name || '';
    const invention = getInvention(state);
    const inventionName = (invention && invention.name) || '';
    const fileName = `${dasherize(inventionName)} (${dasherize(versionName)})`;

    // Uninstalled mirage to deal with this
    // https://github.com/miragejs/ember-cli-mirage/issues/1915
    // could / should reinstall it and use the window.nativeFetch 
    // initializer workaround (bc mirage overwrites fetch)
    const response = await fetch(ENV.IDENTITY_API_URL + `/download-version`, {
      method: 'POST',
      responseType: 'blob',
      body: JSON.stringify({versionId}),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': this.sessionManager.accessToken,
      },
    });

    const arrayBuffer = await response.arrayBuffer();
    var blob = new Blob([arrayBuffer], {type: 'application/zip'});
    fileDownload(blob, `${fileName}.dive`);
  }

  // @action
  // removeVersion(versionId) {
  //   // this.setActiveVersion.perform(versionId);
  //   this.deleteVersion(versionId);
  // }
}

export default HistoryModal;
