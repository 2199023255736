import {
  ADD_PRODUCT,
  REMOVE_PRODUCT,
  SORT_PRODUCTS_LIST,
  UPDATE_PRODUCT
} from '../actions/actionTypes';

import { addFromSchema } from '../utils/schema';
import { omit } from 'lodash';

const initialState = {
  ids: [],
  entities: {},
};

const productsReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case ADD_PRODUCT: {
      let { productId, attributes } = payload;
      const id = productId;
      const createdAt = new Date();
      const updatedAt = new Date();
      const product = addFromSchema('product', {
        ...attributes,
        id,
        createdAt,
        updatedAt,
      });

      return {
        ids: [...state.ids, id],
        entities: {
          ...state.entities,
          [productId]: product,
        },
      };
    }

    case UPDATE_PRODUCT: {
      const { productId, attributes } = payload;
      const updatedAt = new Date();
      return {
        ...state,
        entities: {
          ...state.entities,
          [productId]: {
            ...state.entities[productId],
            ...attributes,
            updatedAt,
          },
        },
      };
    }

    case REMOVE_PRODUCT: {
      const { productId } = payload;
      return {
        ids: state.ids.filter((id) => id !== productId),
        entities: omit(state.entities, productId),
      };
    }

    case SORT_PRODUCTS_LIST: {
      const { productIds } = payload;
      return {
        ...state,
        ids: productIds
      };
    }

    /* istanbul ignore next */
    default:
      return state;
  }
};

export default productsReducer;
