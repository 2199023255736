import {
  ADD_METHOD_EDGE,
  UPDATE_METHOD_EDGE,
  REMOVE_METHOD_EDGE
} from './actionTypes';
import uuid from 'uuid/v4';

export const addMethodEdge = (
  attributes,
  methodEdgeId = uuid()
) => {
  return {
    type: ADD_METHOD_EDGE,
    payload: {
      methodEdgeId,
      attributes,
    },
  };
};

export const updateMethodEdge = (methodEdgeId, attributes) => {
  return {
    type: UPDATE_METHOD_EDGE,
    payload: {
      methodEdgeId,
      attributes,
    },
  };
};


export const removeMethodEdge = (methodEdgeId) => {
  return {
    type: REMOVE_METHOD_EDGE,
    payload: {
      methodEdgeId
    },
  };
};
