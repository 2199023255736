import Component from '@glimmer/component';
import { action } from '@ember/object';
import { connect } from 'ember-redux';
import { getDefaultMethodNodeType } from '../../../selectors/invention-ui';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';
// import { tracked } from '@glimmer/tracking';

const dispatchToActions = {};

const stateToComputed = (state) => ({
  defaultMethodNodeType: getDefaultMethodNodeType(state),
});

class MethodCreateOptions extends Component {
  @service data;

  // @tracked bulkCreateMode = false;

  get styleNamespace() {
    return podNames['method-create-options'];
  }

  get classNames() {
    let classNames = [this.styleNamespace];
    return classNames.join(' ');
  }

  @action
  onSetType(type) {
    this.data.setDefaultMethodNodeType(type);
  }
}

export default connect(stateToComputed, dispatchToActions)(MethodCreateOptions);
