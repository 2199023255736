import Component from '@glimmer/component';
import { alias } from '@ember/object/computed';
import { computed } from '@ember/object';
import { connect } from 'ember-redux';
import { getDrawing } from '../../../selectors/drawing';
import { getDrawingLegendRealization } from '../../../utils/realization';
import { getFigureNumber } from '../../../selectors/figure';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';

const dispatchToActions = {};
const stateToComputed = (state, attrs) => ({
  drawing: getDrawing(state, attrs.drawingId),
  sequence: getFigureNumber(state, attrs.drawingId),
});

class DrawingLegendRealization extends Component {
  @service models;
  @service applicationState;
  @service redux;

  language = 'patent_specification_preferred_version';

  get styleNamespace() {
    return podNames['drawing-legend-realization'];
  }

  get classNames() {
    let classNames = [this.styleNamespace, 'drawing-legend-realization'];
    return classNames.join(' ');
  }

  didInsert() {
    this.language = this.args.language || this.language;
  }

  get focusedId() {
    return this.applicationState.focusedInputModelId;
  }

  get focusedAttr() {
    return this.applicationState.focusedInputModelAttr;
  }

  get focusedType() {
    return this.applicationState.focusedInputModelType;
  }

  get isFocused() {
    return (
      this.focusedId &&
      this.focusedId === this.args.figureId
    );
  }

  viewAngleExample = 'e.g. perspective';
  descriptionExample = 'e.g. the ice cream sandwich in a melted state';

  @computed('drawing', 'args.drawingId')
  get drawingModel() {
    return (
      this.models.find(this.args.drawingId) ||
      this.models.findOrCreate(this.args.drawingId, 'drawing', this.drawing)
    );
  }

  @alias('drawingModel.description') description;
  @alias('drawingModel.viewAngle') viewAngle;

  @computed(
    'description',
    'descriptionExample',
    'args.drawingId',
    'elementId',
    'elementVersionId',
    'isFocused',
    'language',
    'sequence',
    'viewAngle',
    'viewAngleExample'
  )
  get realization() {
    const state = this.redux.getState();
    return getDrawingLegendRealization({
      state,
      sequence: this.sequence,
      drawingId: this.args.drawingId,
      description: this.description,
      viewAngle: this.viewAngle,
      descriptionExample: this.descriptionExample,
      viewAngleExample: this.viewAngleExample,
      language: this.language,
    });
  }
}

export default connect(
  stateToComputed,
  dispatchToActions
)(DrawingLegendRealization);
