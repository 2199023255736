import Component from '@ember/component';
import { equal } from '@ember/object/computed';

const UiIcon = Component.extend({
  classNames: ['icon'],
  classNameBindings: ['type', 'icon', 'isSmall', 'isMedium', 'isLarge'],
  tagName: 'i',
  type: 'pd-icon',
  icon: '',
  size: 'normal', // small, medium, large
  isLarge: equal('size', 'large'),
  isMedium: equal('size', 'medium'),
  isSmall: equal('size', 'small'),
});

export default UiIcon;
