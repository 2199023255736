import Service from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class Settings extends Service {
  @tracked darkMode = false;
  @tracked blackAndWhiteMode = false;

  toggleDarkMode() {
    if (this.darkMode) {
      document.body.classList.remove('dark-mode');
      this.darkMode = false;
    } else {
      document.body.classList.add('dark-mode');
      this.darkMode = true;
    }
  }

  toggleBlackAndWhiteMode() {
    if (this.blackAndWhiteMode) {
      document.body.classList.remove('black-and-white-mode');
      this.blackAndWhiteMode = false;
    } else {
      document.body.classList.add('black-and-white-mode');
      this.blackAndWhiteMode = true;
    }
  }

}
