import { action, computed } from '@ember/object';

import Component from '@glimmer/component';
import FeatureTypes from '../../../constants/types/features';
import { connect } from 'ember-redux';
import { getElement } from '../../../selectors/element';
import { getFeature } from '../../../selectors/feature';
import { getFeatureRealization } from '../../../utils/realization';
import { getTerm } from '../../../selectors/term';
import { once } from '@ember/runloop';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

const dispatchToActions = {};

const stateToComputed = (state, attrs) => ({
  element: getElement(state, attrs.elementId),
  term: getTerm(state, attrs.termId),
});

class FeatureRealization extends Component {
  @service models;
  @service applicationState;

  @tracked realization = '';

  defaultLanguage = 'requirement';

  get focusedId() {
    return this.applicationState.focusedInputModelId;
  }

  get isFocused() {
    return (
      this.args.featureId &&
      this.focusedId &&
      this.focusedId === this.args.featureId
    );
  }

  @action
  didInsert() {
    this.updateRealization();
  }

  @action
  onUpdate() {
    once(this, 'updateRealization');
  }

  get featuresList() {
    return this.args.featureId
      ? [this.args.featureId]
      : this.args.featuresList || [];
  }

  get featureModels() {
    const state = this.redux.getState();
    return this.featuresList.map((featureId) => {
      const feature = getFeature(state, featureId);
      return (
        this.models.find(featureId) ||
        this.models.findOrCreate(featureId, 'feature', feature)
      );
    });
  }

  // eslint-disable-next-line ember/use-brace-expansion
  @computed('featureModels.@each.value')
  get featureModelValues() {
    return (
      this.featureModels &&
      this.featureModels.map((featureModel) =>
        typeof featureModel.value === 'object'
          ? JSON.stringify(featureModel.value)
          : featureModel.value
      )
    );
  }

  get termModel() {
    return (
      this.args.termId &&
      (this.models.find(this.args.termId) ||
        this.models.findOrCreate(this.args.termId, 'term', this.term))
    );
  }

  get elementModel() {
    return (
      this.args.elementId &&
      (this.models.find(this.args.elementId) ||
        this.models.findOrCreate(this.args.elementId, 'element', this.element))
    );
  }

  get type() {
    const type = this.featureModels[0] && this.featureModels[0].type;
    return type && FeatureTypes[type];
  }

  get typeId() {
    return this.type && this.type.id;
  }

  get language() {
    return this.args.language || this.defaultLanguage;
  }

  updateRealization() {
    this.realization = this.getRealization();
  }

  getRealization() {
    const state = this.redux.getState();

    let realization = '';
    let featuresList = this.args.featureId
      ? [this.args.featureId]
      : this.args.featuresList || [];

    if (this.args.filterEmpty) {
      featuresList = featuresList.filter((featureId) => {
        const feature = getFeature(state, featureId);
        return feature && feature.value;
      });
    }

    if (featuresList.length) {
      realization =
        this.typeId &&
        getFeatureRealization({
          state,
          featuresList,
          featureModels: this.featureModels,
          featureTypeId: this.typeId,
          elementId: this.args.elementId,
          elementModel: this.elementModel,
          termId: this.args.termId,
          termModel: this.termModel,
          language: this.language,
        });
    }

    return realization;
  }
}

export default connect(stateToComputed, dispatchToActions)(FeatureRealization);
