import Component from '@glimmer/component';
import podNames from 'ember-component-css/pod-names';

class FeatureValueEmpty extends Component {
  get styleNamespace() {
    return podNames['feature-value-empty'];
  }

  get classNames() {
    let classNames = [this.styleNamespace];
    return classNames.join(' ');
  }
}

export default FeatureValueEmpty;
