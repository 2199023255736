import { BLACK_AND_WHITE_STROKE, HIGHLIGHT_FILL, HIGHLIGHT_STROKE } from '../../../constants/colors';
import { action, computed } from '@ember/object';

import Component from '@glimmer/component';
import Konva from 'konva';
import { connect } from 'ember-redux';
import { getHighlight } from '../../../selectors/highlight';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';

const dispatchToActions = {};

const stateToComputed = (state, attrs) => ({
  highlight: getHighlight(state, attrs.highlightId),
});

class DrawingHighlightKonva extends Component {
  @service tracking;
  @service settings;
  tension = 1;

  constructor(owner, args) {
    super(owner, args);
    this.onScheduleRender = this.args.onScheduleRender;
  }

  @action
  didInsert() {
    this.setup();
    this._blackAndWhiteMode = this.settings.blackAndWhiteMode;
  }

  @action
  willDestroyNode() {
    this.lineNode.off('click');
    this.lineNode.off('dragstart');
    this.lineNode.off('dragmove');
    this.lineNode.off('dragend');
    this.lineNode.destroy();
    this.onScheduleRender();
  }

  get styleNamespace() {
    return podNames['drawing-highlight-konva'];
  }

  get classNames() {
    let classNames = [this.styleNamespace];
    return classNames.join(' ');
  }

  @computed('highlight.curve')
  get isCurved() {
    return this.highlight && this.highlight.curve === 'natural';
  }

  get fillColor() {
    let color = HIGHLIGHT_FILL;
    if (this.settings.blackAndWhiteMode) {
      color = 'transparent';
    }

    return color;
  }

  get strokeColor() {
    let color = HIGHLIGHT_STROKE;
    if (this.settings.blackAndWhiteMode) {
      color = BLACK_AND_WHITE_STROKE;
    }

    return color;
  }

  @computed('highlight.points')
  get points() {
    const points = [];
    const pointsArray = this.highlight.points.split(' ').map((pointString) => {
      const pointArray = pointString.split(',');
      const x = pointArray[0];
      const y = pointArray[1];
      return { x, y };
    });
    pointsArray.forEach((point) => {
      points.push(point.x);
      points.push(point.y);
    });
    return points;
  }

  setup() {
    const lineNode = new Konva.Line({
      id: this.args.highlightId,
      points: this.points,
      fill: this.fillColor,
      stroke: this.strokeColor,
      strokeWidth: 4,
      closed: true,
      bezier: this.isCurved ? true : false,
      tension: this.isCurved ? 0.00001 : 0,
      draggable: this.args.onDragEnd ? true : false,
    });

    // add events
    if (this.args.onClick) {
      lineNode.on('click', () => this.args.onClick());
    }

    this.args.layer.add(lineNode);
    this.lineNode = lineNode;

    if (this.args.onDragEnd) {
      this.lineNode.on('dragend', (event) => {
        // const dx = this.lineNode.x();
        // const dy = this.lineNode.y();
        const { x, y } = event.target.attrs;

        const pointsArray = this.highlight.points
          .split(' ')
          .map((pointString) => {
            const pointArray = pointString.split(',');
            const newX = Math.floor(Number(pointArray[0]) + x);
            const newY = Math.floor(Number(pointArray[1]) + y);
            return `${newX},${newY}`;
          });

        const points = pointsArray.join(' ');
        this.args.onDragEnd(this.highlight.marker, this.args.highlightId, {
          points,
        });

        this.tracking.trackEvent('highlight_dragged');
      });
    }

    this.onScheduleRender();
  }

  onUpdatedAt() {
    this.lineNode.x(0);
    this.lineNode.y(0);
    this.lineNode.points(this.points);
  }

  updateColors() {
    this.lineNode.fill(this.fillColor);
    this.lineNode.stroke(this.strokeColor);
  }

  @action
  onUpdate(elem, [points, isCurved, updatedAt, blackAndWhiteMode]) {
    let detailsChanged = false;

    if (points) {
      this.lineNode.points(this.points);
      detailsChanged = true;
    }

    if (this._isCurved !== isCurved) {
      this._isCurved = isCurved;
      this.lineNode.bezier(this.isCurved ? true : false);
      this.lineNode.tension(this.isCurved ? this.tension : 0);
      detailsChanged = true;
    }

    if (this._updatedAt !== updatedAt) {
      this._updatedAt = updatedAt;

      this.onUpdatedAt();

      detailsChanged = true;
    }

    if (this._blackAndWhiteMode !== blackAndWhiteMode) {
      this._blackAndWhiteMode = blackAndWhiteMode;
      this.updateColors();
      detailsChanged = true;
    }

    if (detailsChanged) {
      this.onScheduleRender(this.args.layer);
    }
  }
}

export default connect(
  stateToComputed,
  dispatchToActions
)(DrawingHighlightKonva);
