import { getPresentState } from '../utils/redux';
import { get } from '@ember/object';

export const getDrawingsList = (state) => get(getPresentState(state), `drawings.ids`);

export const getDrawings = (state) => get(getPresentState(state), `drawings.entities`);

export const getDrawing = (state, drawingId) => get(getPresentState(state), `drawings.entities.${drawingId}`);

export const getImagesList = (state, drawingId) => get(getPresentState(state), `drawings.entities.${drawingId}.imagesList`);

export const getMarkersList = (state, drawingId) => get(getPresentState(state), `drawings.entities.${drawingId}.markersList`);

export const getAllImagesList = (state) => {
  const drawingIds = get(getPresentState(state), `drawings.ids`);
  const drawings = drawingIds.map((drawingId) => getDrawing(state, drawingId));
  const imagesListArrays = drawings.map((drawing) => drawing.imagesList);
  const imagesList = [...new Set([].concat(...imagesListArrays))];
  return imagesList;
};
