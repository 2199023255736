import Component from '@ember/component';
import { connect } from 'ember-redux';
import { getMarker } from '../../../selectors/marker';

const dispatchToActions = {};

const stateToComputed = (state, attrs) => ({
  marker: getMarker(state, attrs.referenceId),
});

const ReferencePreview = Component.extend({
  classNames: ['reference-preview'],
  switched: false,
  isNovel: false,
  autoScale: true,
  didUpdateAttrs() {
    this._super(...arguments);
    this.toggleProperty('switched');
  },

});

export default connect(stateToComputed, dispatchToActions)(ReferencePreview);
