import { task, timeout } from 'ember-concurrency';

import ENV from '../config/environment';
import ReduxService from 'ember-redux/services/redux';
import { getInventionId } from '../selectors/invention';
import { isEmpty } from 'lodash';
import localForage from 'localforage';
import reducers from '../reducers/index';
//app/services/redux.js
import redux from 'redux';
import { inject as service } from '@ember/service';
import { typeOf } from '@ember/utils';

// import { uiActionTypes } from '../actions/invention-ui';

const dbPrefix = ENV.DB_PREFIX || '';
const dbName =
  ENV.environment === 'test' ? 'patentdive_test' : `${dbPrefix}_inventions`;
const storage = localForage.createInstance({
  name: dbName,
});

// let VERSION_CHANGED = false;

// const watcherMiddleware = (/*store*/) => {
//   return (next) => (action) => {
//     const actionType = action.type;
//     if (!uiActionTypes.includes(actionType)) {
//       VERSION_CHANGED = true;
//     }
//     next(action);
//   };
// };

// const { createStore, applyMiddleware } = redux;
const { createStore } = redux;

export default ReduxService.extend({
  migrations: service(),
  worker: service(),
  data: service(),
  emberStore: service('store'),
  reducers,
  bootstrapped: false,
  storage,
  stateIndex: 0,
  versionChanged: false,

  async setup(key) {
    try {
      const reducers = this.reducers;
      const state = await this.loadState(key);
      let present = state.present;

      if (!isEmpty(state.present)) {
        present = this.migrations.migrate(state.present);
      }

      const migratedState = {
        ...state,
        present,
      };
      this.store = this.makeStoreInstanceTwo({
        reducers,
        key,
        state: migratedState,
      });
    } catch (err) {
      console.error(err);
    }
  },

  async loadState(key) {
    const state = await this.storage.getItem(key);
    if (state) {
      const past = [];
      const future = [];
      const parsed = JSON.parse(state);
      const present =
        typeOf(parsed.present) === 'string'
          ? JSON.parse(parsed.present)
          : parsed.present;
      return {
        past,
        present,
        future,
      };
    } else {
      return {
        past: [],
        present: {},
        future: [],
      };
    }
  },

  cleanGraph(state) {
    return {
      ...state,
      graph: {
        ...state.graph,
        collapsedDescendantsList: [],
        elementsMap: {},
        elementVersionsMap: {},
        // orphanNodesList: [],
        disconnectedNodesList: [],
        methodsList: [],
        methodsMap: {},
        products: {},
      },
    };
  },

  // async getInstance(instanceId) {
  //   let instance = this.emberStore.peekRecord('instance', instanceId);
  //   if (!instance) {
  //     instance = await this.emberStore.findRecord('instance', instanceId);
  //   }
  //   return instance;
  // },

  getStringifiedState: task(function* () {
    const state = this.getState();
    const present = state.present;
    const cleaned = this.cleanGraph(present);
    const invention = {
      past: [],
      present: cleaned,
      future: [],
    };
    const worker = yield this.worker.open('json-stringify');
    const stringifiedState = yield worker.postMessage(invention);
    worker.terminate();
    return stringifiedState;
  }),

  debounceSaveState: task(function* () {
    yield timeout(500);
    const key = this.key;
    // const state = this.getState();
    const stringifiedState = yield this.getStringifiedState.perform();
    yield this.storage.setItem(key, stringifiedState);

    // if (VERSION_CHANGED) {
    //   VERSION_CHANGED = false;

    //   if (remoteStorage) {
    //     this.data.addVersionToCloud(inventionId, stringifiedState);
    //   }
    // }

    // const inventionId = getInventionId(state)
    // this.data.autoSaveToCloud(inventionId, state, stringifiedState);
  }).restartable(),

  // eslint-disable-next-line require-yield
  incrementStateIndex: task(function* () {
    this.incrementProperty('stateIndex');
  }),

  onStateChange() {
    this.incrementStateIndex.perform();
    this.debounceSaveState.perform();
  },

  makeStoreInstanceTwo({ key, state }) {
    if (!key) {
      return false;
    }

    if (this.unsubscribe) {
      this.unsubscribe();
    }

    this.setProperties({
      key,
      bootstrapped: false,
    });

    // const composedEnhancers = compose(enhancers);
    // const enhancer = applyMiddleware(watcherMiddleware);
    // const store = createStore(reducers, state, enhancer);
    const store = createStore(reducers, state);
    this.unsubscribe = store.subscribe(() => {
      this.onStateChange();
    });

    this.set('bootstrapped', true);

    return store;
  },

  purgeState() {
    this.storage.clear();
  },
});
