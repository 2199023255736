// import * as Sentry from '@sentry/browser';

import ENV from '../../config/environment';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class AuthorizedRoute extends Route.extend() {
  @service session;
  @service sessionManager;

  beforeModel(transition) {
    this.session.requireAuthentication(transition, ENV.SIGN_IN_ROUTE);
    return this.loadUser();
  }

  async loadUser() {
    try {
      await this.sessionManager.loadUser();
    } catch (err) {
      console.error(err)
      // Sentry.captureException(err);
      this.session.invalidate();
      throw err;
    }
  }
}
