export default {
  attributes: {
    depth: {
      type: 'object',
      defaultValue: {}
    },
    breadth: {
      type: 'object',
      defaultValue: {}
    },
    custom: {
      type: 'object',
      defaultValue: {}
    },
    updatedAt: {
      type: 'date'
    }
  }
};

// each breadth, depth, custom, contains
// figuresList: {
//   type: 'array',
//   defaultValue: []
// },
// figuresMap: {
//   type: 'object',
//   defaultValue: {}
// },
// products: {
//   type: 'object',
//   defaultValue: {}
// },