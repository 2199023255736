import Component from '@glimmer/component';
import { action } from '@ember/object';
import highlightText from '@brianmcallister/highlight-text';
import { htmlSafe } from '@ember/string';
import podNames from 'ember-component-css/pod-names';
import uuid from 'uuid/v4';

class SearchResultItem extends Component {
  templateDomElementId = `search-template-${uuid()}`;

  get styleNamespace() {
    return podNames['search-result-item'];
  }

  get classNames() {
    let classNames = [this.styleNamespace, 'search-result-item'];
    return classNames.join(' ');
  }

  // this only works on text strings, not html strings
  // html strings is a different and harder problem involving ranges
  // seems like a pain memory-wise
  get highlightedText() {
    const text = this.args.text || '';
    const query = this.args.query || '';
    const highlightedText = highlightText(text, [query]);
    return htmlSafe(highlightedText);
  }

  @action
  onClick() {
    if (this.args.onSelect) {
      this.args.onSelect(this.args.modelType, this.args.modelId, this.args.result)
    }
  }
}

export default SearchResultItem;
