import {
  ADD_IMAGE,
  UPDATE_IMAGE,
  UPDATE_DRAWING_ITEMS,
  REMOVE_SELECTED_DRAWING_ITEMS,
  REMOVE_DRAWING,
} from '../actions/actionTypes';
import { addFromSchema } from '../utils/schema';
import { omit } from 'lodash';

const initialState = {
  ids: [],
  entities: {},
};

const imagesReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case ADD_IMAGE: {
      let { imageId, drawingId, attributes } = payload;
      const id = imageId;
      const createdAt = new Date();
      const updatedAt = new Date();
      const drawing = drawingId;
      const image = addFromSchema('image', {
        id,
        drawing,
        createdAt,
        updatedAt,
        ...attributes,
      });

      return {
        ids: [...state.ids, id],
        entities: {
          ...state.entities,
          [imageId]: image,
        },
      };
    }

    case UPDATE_IMAGE: {
      const { imageId, attributes } = payload;
      const updatedAt = new Date();
      return {
        ...state,
        entities: {
          ...state.entities,
          [imageId]: {
            ...state.entities[imageId],
            ...attributes,
            updatedAt,
          },
        },
      };
    }

    case UPDATE_DRAWING_ITEMS: {
      const { images } = payload;
      let imageUpdates = {};
      const updatedAt = new Date();

      images.forEach((image) => {
        imageUpdates[image.id] = {
          ...state.entities[image.id],
          ...image.attributes,
          updatedAt,
        };
      });

      return {
        ...state,
        entities: {
          ...state.entities,
          ...imageUpdates,
        },
      };
    }

    case REMOVE_SELECTED_DRAWING_ITEMS: {
      const { selectedImages } = payload;

      return {
        ids: state.ids.filter((id) => !selectedImages.includes(id)),
        entities: omit(state.entities, selectedImages),
      };
    }

    case REMOVE_DRAWING: {
      const { imagesList } = payload;

      return {
        ids: state.ids.filter((id) => !imagesList.includes(id)),
        entities: omit(state.entities, imagesList),
      };
    }

    /* istanbul ignore next */
    default:
      return state;
  }
};

export default imagesReducer;
