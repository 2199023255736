import Component from '@glimmer/component';
import podNames from "ember-component-css/pod-names";

export default class ExpiredPage extends Component {

  get styleNamespace() {
    return podNames['expired-page'];
  }

}
