export default {
  attributes: {
    id: {
      type: 'uuid',
      defaultValue: null
    },
    term: {
      type: 'uuid',
      defaultValue: null
    },
    name: {
      type: 'string',
      defaultValue: ''
    },
    // features
    definitionsList: {
      type: 'array',
      defaultValue: []
    },
    // drawing refs
    markersList: {
      type: 'array',
      defaultValue: []
    },
    createdAt: {
      type: 'date'
    }
  }
}
