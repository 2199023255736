import Component from '@glimmer/component';
import podNames from 'ember-component-css/pod-names';

class PatentSpecificationExportDropdown extends Component {
  get classNames() {
    let classNames = [
      'patent-specification-export-dropdown',
      this.styleNamespace,
    ];
    return classNames.join(' ');
  }

  get styleNamespace() {
    return podNames['patent-specification-export-dropdown'];
  }
}

export default PatentSpecificationExportDropdown;
