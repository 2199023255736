import Component from '@glimmer/component';
import { action } from '@ember/object';
import { connect } from 'ember-redux';
import { htmlSafe } from '@ember/string';
import podNames from 'ember-component-css/pod-names';

const stateToComputed = null;

const dispatchToActions = {};

class UiModalListItem extends Component {
  depthPaddingWidth = 25;

  get styleNamespace() {
    return podNames['ui-modal-list-item'];
  }

  get classNames() {
    let classNames = [this.styleNamespace, 'ui-modal-list-item'];
    if (this.args.isStrong) classNames.push('is-strong');
    if (this.args.isSelected) classNames.push('is-selected');
    return classNames.join(' ');
  }

  get depth() {
    return this.args.depth || 1;
  }

  get depthPadding() {
    return (this.depth - 1) * this.depthPaddingWidth;
  }

  get depthStyle() {
    const style = this.depthPadding
      ? `margin-left: ${this.depthPadding}px`
      : '';
    return htmlSafe(style);
  }

  @action
  onClick() {
    if (this.args.onClick) {
      this.args.onClick();
    }
  }
}

export default connect(stateToComputed, dispatchToActions)(UiModalListItem);
