import Component from '@glimmer/component';
import { action } from '@ember/object';
import { batchGroupBy } from '../../../utils/redux';
import { connect } from 'ember-redux';
import { getProduct } from '../../../selectors/product';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';

const stateToComputed = (state, attrs) => ({
  product: getProduct(state, attrs.productId),
});
const dispatchToActions = {};

class ComparisonModal extends Component {

  @service data;
  @service tracking;

  get styleNamespace() {
    return podNames['comparison-modal'];
  }

  get classNames() {
    let classNames = [this.styleNamespace, 'comparison-modal'];
    return classNames.join(' ');
  }

  @action
  didInsert() {
    this.tracking.trackEvent('opened_prior_art_comparison');
  }

  @action
  hideModal() {
    batchGroupBy.start();
    this.data.setShowingComparisonMatrix(false);
    batchGroupBy.end();
    this.tracking.trackEvent('closed_prior_art_comparison');
  }

}

export default connect(stateToComputed, dispatchToActions)(ComparisonModal);
