import { action, computed } from '@ember/object';
import {
  getIsPreferred,
  getPreferredElementVersionId,
  getPreferredElementVersionsList,
} from '../../../selectors/product';

import Component from '@glimmer/component';
import { connect } from 'ember-redux';
import { getActiveElementVersionListItem } from '../../../selectors/invention-ui';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';

const dispatchToActions = {};

const stateToComputed = (state, attrs) => ({
  preferredElementVersionsList: getPreferredElementVersionsList(
    state,
    attrs.productId
  ),
  activeElementVersionListItem: getActiveElementVersionListItem(state),
});

class ElementVersionSelector extends Component {
  @service redux;
  @service data;

  get classNames() {
    let classNames = [this.styleNamespace, 'element-version-selector'];
    return classNames.join(' ');
  }

  get styleNamespace() {
    return podNames['element-version-selector'];
  }

  // eslint-disable-next-line ember/require-computed-property-dependencies
  @computed('args.productId', 'elementId')
  get hasPreferred() {
    const state = this.redux.getState();
    return getPreferredElementVersionId(
      state,
      this.args.elementId,
      this.args.productId
    )
      ? true
      : false;
  }

  @action
  selectListItem(elementVersionId, isSelected) {
    elementVersionId = isSelected ? null : elementVersionId;
    this.data.setActiveElementVersionListItem(elementVersionId)
  }

  @action
  setPreferredElementVersion(elementVersionId) {
    if (this.args.onSetPreferredElementVersion) {
      let state = this.redux.getState();
      let elementId = this.args.elementId;
      const isPreferred = getIsPreferred(
        state,
        elementId,
        elementVersionId,
        this.args.productId
      );

      if (isPreferred) {
        this.args.onSetPreferredElementVersion(
          elementId,
          null,
          this.args.productId
        );
      } else {
        this.args.onSetPreferredElementVersion(
          elementId,
          elementVersionId,
          this.args.productId
        );
      }
    }
  }
}

export default connect(
  stateToComputed,
  dispatchToActions
)(ElementVersionSelector);
