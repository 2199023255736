import {
  getFigure,
  getFiguresList,
} from '../selectors/invention';

import Service from '@ember/service';
import { getInvention } from '../selectors/invention';
import { getMethodsList } from '../selectors/graph';
import { getPatentSpecification } from '../selectors/patent-specification';
import { saveAs } from 'file-saver';
import { inject as service } from '@ember/service';
import { underscore } from '@ember/string';

export default class FiguresExportPdf extends Service {
  @service methodsCacheKonva;
  @service figuresCacheKonva;
  @service worker;

  async export(state) {
    try {
      const title = this.getSpecificationTitle(state);
      const fileName = underscore(title);
      const pages = await this.getPages(state);
      const blob = await this.worker.postMessage('figures-export', { pages });
      saveAs(blob, `${fileName}.pdf`);
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  async getPages(state) {
    try {
      const figuresList = getFiguresList(state);
      const methodsList = getMethodsList(state);
      const pages = [];

      let index = 0;

      for (let i = 0; i < figuresList.length; i++) {
        const figureObj = figuresList[i];
        const figure = getFigure(state, figureObj.id);
        const image = await this.figuresCacheKonva.getDataUrl(figure.id);
        index++;
        pages.push({
          label: `FIG. ${index}`,
          orientation: figure.orientation,
          image,
        });
      }


      for (let j = 0; j < methodsList.length; j++) {
        const methodId = methodsList[j];
        const figure = getFigure(state, methodId);
        const image = await this.methodsCacheKonva.getDataUrl(figure.id);
        index++;
        pages.push({
          label: `FIG. ${index}`,
          orientation: figure.orientation,
          image,
        });
      }

      return pages;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  getSpecificationTitle(state) {
    const patentSpecification = getPatentSpecification(state);
    const invention = getInvention(state);

    const { title } = patentSpecification;

    return title || invention.name;
  }
}
