import {
  ADD_ELEMENT_VERSION,
  REMOVE_ELEMENT_VERSION,
  UPDATE_ELEMENT_AND_ELEMENT_VERSION,
  UPDATE_ELEMENT_VERSION,
  UPDATE_NODE_COORDINATES,
} from '../actions/actionTypes';
import { forEach, omit } from 'lodash';

import { addFromSchema } from '../utils/schema';

const initialState = {
  ids: [],
  entities: {},
};

const elementVersionsReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case ADD_ELEMENT_VERSION: {
      let { elementVersionId, attributes } = payload;
      const id = elementVersionId;
      const createdAt = new Date();
      const updatedAt = new Date();
      const elementVersion = addFromSchema('element-version', {
        id,
        createdAt,
        updatedAt,
        ...attributes,
      });

      return {
        ids: [...state.ids, id],
        entities: {
          ...state.entities,
          [elementVersionId]: elementVersion,
        },
      };
    }

    case UPDATE_ELEMENT_VERSION: {
      const { elementVersionId, attributes } = payload;
      const updatedAt = new Date();
      return {
        ...state,
        entities: {
          ...state.entities,
          [elementVersionId]: {
            ...state.entities[elementVersionId],
            ...attributes,
            updatedAt,
          },
        },
      };
    }

    case UPDATE_ELEMENT_AND_ELEMENT_VERSION: {
      const { elementVersionId, elementVersionAttributes } = payload;
      const updatedAt = new Date();
      return {
        ...state,
        entities: {
          ...state.entities,
          [elementVersionId]: {
            ...state.entities[elementVersionId],
            ...elementVersionAttributes,
            updatedAt,
          },
        },
      };
    }

    case UPDATE_NODE_COORDINATES: {
      const { elementVersions } = payload;

      const entities = {
        ...state.entities,
      };

      const updatedAt = new Date();

      forEach(elementVersions, (elementVersion) => {
        entities[elementVersion.id] = {
          ...entities[elementVersion.id],
          x: elementVersion.x,
          y: elementVersion.y,
          updatedAt,
        };
      });

      return {
        ...state,
        entities,
      };
    }

    case REMOVE_ELEMENT_VERSION: {
      const { elementVersionId } = payload;
      return {
        ids: state.ids.filter((id) => id !== elementVersionId),
        entities: omit(state.entities, elementVersionId),
      };
    }

    /* istanbul ignore next */
    default:
      return state;
  }
};

export default elementVersionsReducer;
