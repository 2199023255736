import { getFeature, getFeatures } from '../../../selectors/feature';
import {
  getIsPreferred,
  getPreferredElementVersionsList,
} from '../../../selectors/product';

import Component from '@glimmer/component';
import { computed } from '@ember/object';
import { connect } from 'ember-redux';
import { getElementRequirementsList } from '../../../selectors/element';
import { getElementVersion } from '../../../selectors/element-version';
import { htmlSafe } from '@ember/string';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';

const stateToComputed = (state, attrs) => ({
  elementVersion: getElementVersion(state, attrs.elementVersionId),
  allFeatures: getFeatures(state),
  preferredElementVersionsList: getPreferredElementVersionsList(state),
});

const dispatchToActions = {};

class ElementVersionRealization extends Component {
  @service models;

  get styleNamespace() {
    return podNames['element-version-realization'];
  }

  get classNames() {
    return [this.styleNamespace, 'element-version-realization'].join(' ');
  }

  @computed('featuresList.[]', 'features.@each.id')
  get groupedFeatures() {
    const groups = [];
    const singularFeatureTypes = ['measurement'];
    let index = 0;
    this.features.forEach((feature) => {
      if (singularFeatureTypes.includes(feature.type)) {
        groups[index] = {
          type: feature.type,
          featuresList: [feature.id],
        };
        index++;
      } else if (!groups[index]) {
        groups[index] = {
          type: feature.type,
          featuresList: [feature.id],
        };
      } else if (groups[index] && groups[index].type === feature.type) {
        groups[index] = {
          ...groups[index],
          featuresList: [...groups[index].featuresList, feature.id],
        };
      } else {
        index++;
        groups[index] = {
          type: feature.type,
          featuresList: [feature.id],
        };
      }
    });

    return groups;
  }

  // eslint-disable-next-line ember/require-computed-property-dependencies
  @computed('featuresList.[]', 'allFeatures')
  get features() {
    const state = this.redux.getState();
    return this.featuresList
      ? this.featuresList
          .filter((featureId) => getFeature(state, featureId))
          .map((featureId) => getFeature(state, featureId))
      : [];
  }

  // eslint-disable-next-line ember/require-computed-property-dependencies
  @computed('elementModel.requirementsList.[]', 'args.elementVersionId')
  get requirementsList() {
    const state = this.redux.getState();
    return (
      this.elementModel &&
      getElementRequirementsList(state, this.elementModel.id)
    );
  }

  @computed('elementVersion.featuresList.[]', 'args.elementVersionId')
  get featuresList() {
    return this.elementVersion && this.elementVersion.featuresList;
  }

  // eslint-disable-next-line ember/require-computed-property-dependencies
  @computed('elementVersion.definitionsList.[]')
  get definitionsList() {
    const state = this.redux.getState();
    return (
      this.elementVersion &&
      this.elementVersion.definitionsList.filter((featureId) => {
        const feature = getFeature(state, featureId);
        return feature && feature.type === 'definition';
      })
    );
  }

  // eslint-disable-next-line ember/require-computed-property-dependencies
  @computed('elementVersion.definitionsList.[]')
  get examplesList() {
    const state = this.redux.getState();
    return (
      this.elementVersion &&
      this.elementVersion.definitionsList.filter((featureId) => {
        const feature = getFeature(state, featureId);
        return feature && feature.type === 'analogs';
      })
    );
  }

  // eslint-disable-next-line ember/require-computed-property-dependencies
  @computed('elementVersion.definitionsList.[]')
  get detailsList() {
    const state = this.redux.getState();
    return (
      this.elementVersion &&
      this.elementVersion.definitionsList.filter((featureId) => {
        const feature = getFeature(state, featureId);
        return feature && feature.type === 'detail';
      })
    );
  }

  @computed('elementVersion.comprisesList.[]', 'args.elementVersionId')
  get comprisesList() {
    return this.elementVersion && this.elementVersion.comprisesList;
  }

  @computed('elementVersion.constraintsList.[]', 'args.elementVersionId')
  get constraintsList() {
    return this.elementVersion && this.elementVersion.constraintsList;
  }

  @computed('definitionsList.[]')
  get hasDefinitions() {
    return this.definitionsList && this.definitionsList.length;
  }

  @computed('detailsList.[]')
  get hasDetails() {
    return this.detailsList && this.detailsList.length;
  }

  @computed('examplesList.[]')
  get hasExamples() {
    return this.examplesList && this.examplesList.length;
  }

  @computed('comprisesList.[]')
  get hasComprises() {
    return this.comprisesList && this.comprisesList.length;
  }

  @computed('requirementsList.[]')
  get hasRequirements() {
    return this.requirementsList && this.requirementsList.length;
  }

  @computed('constraintsList.[]')
  get hasConstraints() {
    return this.constraintsList && this.constraintsList.length;
  }

  @computed('args.elementId')
  get elementModel() {
    return this.args.elementId && this.models.find(this.args.elementId);
  }

  @computed('args.elementVersionId')
  get elementVersionModel() {
    return (
      this.args.elementVersionId && this.models.find(this.args.elementVersionId)
    );
  }

  get featureOutputType() {
    return this.args.featureOutputType || 'paragraph';
  }

  // eslint-disable-next-line ember/require-computed-property-dependencies
  @computed(
    'preferredElementVersionsList.[]',
    'args.{elementId,elementVersionId}'
  )
  get isPreferred() {
    const state = this.redux.getState();
    const isPreferred = getIsPreferred(
      state,
      this.args.elementId,
      this.args.elementVersionId
    );
    return isPreferred;
  }

  @computed('isPreferred')
  get specificationLanguage() {
    // return this.isPreferred
    //   ? 'patent_specification_preferred_version'
    //   : 'patent_specification_alt_version';
    return this.isPreferred
      ? 'patent_specification_preferred_version'
      : 'patent_specification_preferred_version';
  }

  get _language() {
    return this.args.language === 'patent_specification_preferred_version'
      ? this.specificationLanguage
      : this.args.language;
  }

  get showElementName() {
    return this.args.showElementName || false;
  }

  @computed(
    'elementModel.{elementVersionsList,name}',
    'elementVersionModel.name'
  )
  get elementName() {
    const elementName = this.elementModel && this.elementModel.name;
    const elementVersionName =
      this.elementVersionModel && this.elementVersionModel.name;
    const elementVersionsList =
      this.elementModel && this.elementModel.elementVersionsList;

    let value = '<span class="no-element">No element</span>';

    if (elementName && elementVersionsList.length === 1) {
      value = `${elementName}`;
    } else if (elementName && elementVersionsList.length > 1) {
      value = `${elementName} <span class="element-version-name">/ ${elementVersionName}</span>`;
    }

    return htmlSafe(value);
  }
}

export default connect(
  stateToComputed,
  dispatchToActions
)(ElementVersionRealization);
