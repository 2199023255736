import Application from '@ember/application';
// import { InitSentryForEmber } from '@sentry/ember';
import Resolver from 'ember-resolver';
import config from 'patentdive-data/config/environment';
import loadInitializers from 'ember-load-initializers';
import mixpanel from 'mixpanel-browser';

// InitSentryForEmber({
//   beforeBreadcrumb: (breadcrumb) => {
//     if (breadcrumb.category === 'console') {
//       return null;
//     }
//     return breadcrumb;
//   },
// });
mixpanel.init(config.MIXPANEL_TOKEN);

export default class App extends Application {
  modulePrefix = config.modulePrefix;
  podModulePrefix = config.podModulePrefix;
  Resolver = Resolver;
}

loadInitializers(App, config.modulePrefix);
