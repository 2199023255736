import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import axios from 'axios';
import validator from 'validator';
import ENV from '../../../config/environment';
import podNames from "ember-component-css/pod-names";

export default class SignInCheckEmail extends Component {
  @tracked sendSuccess = false;
  @tracked didValidate = false;
  @tracked serverError;

  get styleNamespace() {
    return podNames['sign-in-check-email'];
  }

  get email() {
    return this.args.email || '';
  }

  get isValid() {
    return this.email && validator.isEmail(this.email);
  }

  get validationError() {
    return this.didValidate && !this.isValid;
  }
  @action
  onResend() {
    this.args.onResend()
  }

  @action
  async getActivationLink() {
    this.didValidate = true;
    this.sendSuccess = false;

    if (!this.isValid) {
      return;
    }

    try {
      await axios({
        method: 'POST',
        url: ENV.IDENTITY_API_URL + '/activation',
        data: {
          email: this.email,
        }
      });

      this.sendSuccess = true;
      // this.get('tracking').trackEvent('dashboard:requested_activation_link');
    } catch (error) {
      this.serverError = error.response.data;
    }
  }

}
