import Model, { attr } from '@ember-data/model';

const getInitials = function(fullName) {
  const charArray = fullName.replace(/[^a-zA-Z- ]/g, "").match(/\b\w/g);
  return charArray.join('');
};

export default class UserInfoModel extends Model {
  @attr('string') firstName;
  @attr('string') lastName;

  get fullName() {
    return `${this.firstName} ${this.lastName}`
  }

  get initials() {
    return getInitials(this.fullName);
  }

}
