import Component from '@glimmer/component';
import podNames from 'ember-component-css/pod-names';

class ElementVersionContextSection extends Component {

  get classNames() {
    let classNames = [this.styleNamespace, 'element-version-context-section'];
    if (this.args.isCollapsed) classNames.push('is-collapsed');
    if (this.args.hasBorder) classNames.push('has-border');
    return classNames.join(' ');
  }

  get styleNamespace() {
    return podNames['element-version-context-section'];
  }
}

export default ElementVersionContextSection;
