import Model, { attr } from '@ember-data/model';

import { computed } from '@ember/object';
import { getMethodTraversal } from '../utils/method';
import { inject as service } from '@ember/service';

export default class MethodModel extends Model {
  @service redux;
  
  @attr updatedAt;

  @computed('updatedAt', 'id')
  get methodTraversal() {
    const state = this.redux.getState();
    return getMethodTraversal(state, this.id)
  }

  @computed('methodTraversal.methodNodesList.[]')
  get methodNodesList() {
    return this.methodTraversal && this.methodTraversal.methodNodesList 
  }

  @computed('methodTraversal.methodEdgesList.[]')
  get methodEdgesList() {
    return this.methodTraversal && this.methodTraversal.methodEdgesList 
  }

  @computed('methodTraversal.allTraversedNodesList.[]')
  get allTraversedNodesList() {
    return this.methodTraversal && this.methodTraversal.allTraversedNodesList 
  }

  @computed('methodTraversal.traversedNodesList.[]')
  get traversedNodesList() {
    return this.methodTraversal && this.methodTraversal.traversedNodesList 
  }

  @computed('methodTraversal.traversedEdgesList.[]')
  get traversedEdgesList() {
    return this.methodTraversal && this.methodTraversal.traversedEdgesList 
  }

  @computed('methodTraversal.disconnectedNodesList.[]')
  get disconnectedNodesList() {
    return this.methodTraversal && this.methodTraversal.disconnectedNodesList 
  }

  @computed('methodTraversal.disconnectedEdgesList.[]')
  get disconnectedEdgesList() {
    return this.methodTraversal && this.methodTraversal.disconnectedEdgesList 
  }

  @computed('methodTraversal.groupedStepsList.[]')
  get groupedStepsList() {
    return this.methodTraversal && this.methodTraversal.groupedStepsList 
  }

  @computed('methodTraversal.ungroupedStepsList.[]')
  get ungroupedStepsList() {
    return this.methodTraversal && this.methodTraversal.ungroupedStepsList 
  }
}

