import RSVP from 'rsvp';
import { readAndCompressImage } from 'browser-image-resizer';

export const blobUrlToBase64Png = (blobUrl, width, height) => {
  return new Promise((resolve) => {
    const img = document.createElement('img');
    img.onload = function () {
      document.body.appendChild(img);
      const canvas = document.createElement('canvas');
      document.body.removeChild(img);
      canvas.width = width;
      canvas.height = height;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
      try {
        const data = canvas.toDataURL('image/png');
        resolve(data);
      } catch (e) {
        resolve(null);
      }
    };
    img.src = blobUrl;
  });
};

export const blobUrlToBase64JPEG = (blobUrl, width, height) => {
  return new Promise((resolve) => {
    const img = document.createElement('img');
    img.onload = function () {
      document.body.appendChild(img);
      const canvas = document.createElement('canvas');
      document.body.removeChild(img);
      canvas.width = width;
      canvas.height = height;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
      try {
        const data = canvas.toDataURL('image/jpeg');
        resolve(data);
      } catch (e) {
        resolve(null);
      }
    };
    img.src = blobUrl;
  });
};

export const parseImageFile = async (file, artboardWidth, artboardHeight) => {
  const name = file.name;
  const type = file.type;
  const resizedFile = await resizeAndCompress({
    file: file,
    mimeType: type,
    maxWidth: artboardWidth * 2,
    maxHeight: artboardHeight * 2,
  });

  console.log({
    file: file,
    mimeType: type,
    maxWidth: artboardWidth * 2,
    maxHeight: artboardHeight * 2,
  })

  // const previewFile = await resizeAndCompress({
  //   file: file,
  //   mimeType: 'jpg',
  //   maxWidth: artboardWidth / 2,
  //   maxHeight: artboardHeight / 2,
  // });

  const fileDimensions = await getImageDimensions(resizedFile);
  const fileWidth = fileDimensions.width;
  const fileHeight = fileDimensions.height;


  console.log('fileDimensions', fileDimensions)
  console.log('fileWidth', fileWidth)
  console.log('fileHeight', fileHeight)

  const imageDimensions = getScaledImageDimensions({
    imageWidth: fileWidth,
    imageHeight: fileHeight,
    containerWidth: artboardWidth,
    containerHeight: artboardHeight,
  });

  const { width, height } = imageDimensions;

  return {
    attributes: {
      name,
      fileName: name,
      fileType: type,
      fileWidth,
      fileHeight,
      width,
      height,
      x: (-1 * width) / 2,
      y: (-1 * height) / 2,
    },
    blob: resizedFile,
    // previewBlob: previewFile,
  };
};

export const getImageBlobFromURL = (url) => {
  return new RSVP.Promise((resolve, reject) => {
    const img = new Image();
    const c = document.createElement('canvas');
    const ctx = c.getContext('2d');
    const random = Math.floor(Math.random() * 9999999);
    const link = url.indexOf('?') > 0 ? '&' : '?';
    url = `${url}${link}rnd=${random}`;

    img.onload = function () {
      c.width = this.naturalWidth; // update canvas size to match image
      c.height = this.naturalHeight;
      ctx.drawImage(this, 0, 0); // draw in image
      c.toBlob(
        function (blob) {
          resolve(blob);
        },
        'image/png',
        1
      );
    };

    /* istanbul ignore next */
    img.onerror = function (err) {
      reject(err);
    };

    img.crossOrigin = 'anonymous'; // if from different origin
    img.src = url;
  });
};

// getImageDimensions(path)
// Loads an image from a path and resolves its dimensions
export const getImageDimensions = (file) => {
  return new RSVP.Promise((resolve, reject) => {
    const img = new Image();
    const url = URL.createObjectURL(file);

    img.onload = function () {
      resolve({
        width: this.width,
        height: this.height,
      });
    };

    /* istanbul ignore next */
    img.onerror = function (err) {
      reject(err);
    };

    img.src = url;
  });
};

export const getCanvasBlob = (
  canvas,
  mimeType = 'image/jpeg',
  quality = 0.95
) => {
  return new Promise(function (resolve) {
    canvas.toBlob(
      function (blob) {
        resolve(blob);
      },
      mimeType,
      quality
    );
  });
};

export const getDataURL = (file) => {
  return new RSVP.Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.addEventListener(
      'load',
      () => {
        resolve(reader.result);
      },
      false
    );

    reader.addEventListener('error', (err) => reject(err));

    if (file) {
      reader.readAsDataURL(file);
    }
  });
};

export const getDataUri = (url) => {
  return new Promise((resolve, reject) => {
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const reader = new FileReader();
        reader.onload = function () {
          resolve(this.result);
        };
        reader.readAsDataURL(blob);
      })
      .catch((err) => reject(err));
  });
};

// export const containImage = (srcWidth, srcHeight, maxWidth, maxHeight) => {
//   const ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);
//   return {
//     width: Math.round(srcWidth * ratio * 100) / 100,
//     height: Math.round(srcHeight * ratio * 100) / 100,
//   };
// }

export const dataURLtoBlob = (dataurl) => {
  var arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
};

// export const svgToPng = ({
//   svgDataUrl,
//   width = 1000,
//   height = 1000,
//   type = 'dataUri',
//   rotate = false,
// } = {}) =>  {
//   return new RSVP.Promise((resolve, reject) => {
//     const img = document.createElement('img');
//     img.setAttribute('crossOrigin', 'Anonymous');
//
//     img.crossOrigin = 'anonymous';
//
//     img.onload = function(event) {
//       const canvas = document.createElement('canvas');
//       canvas.backgroundColor = 'red';
//       let ctx;
//
//       if (rotate) {
//         canvas.setAttribute('width', height);
//         canvas.setAttribute('height', width);
//         ctx = canvas.getContext('2d');
//         // ctx.fillStyle = "blue";
//         // ctx.fillRect(0, 0, canvas.width, canvas.height);
//
//         const cw = canvas.width;
//         const ch = canvas.height;
//
//         ctx.translate(cw, ch / cw);
//         ctx.rotate(0.5 * Math.PI);
//         ctx.drawImage(img, 0, 0, width, height);
//       } else {
//         canvas.setAttribute('width', width);
//         canvas.setAttribute('height', height);
//         ctx = canvas.getContext('2d');
//         ctx.drawImage(img, 0, 0, width, height);
//       }
//
//       if (type === 'dataUri') {
//         const pngUrl = canvas.toDataURL('image/png');
//         return resolve(pngUrl);
//       }
//
//       if (type === 'blob') {
//         canvas.toBlob(blob => {
//           return resolve(blob);
//         });
//       }
//     };
//
//     img.src = svgDataUrl;
//   });
// }

export const resizeAndCompress = ({
  file,
  quality = 0.85,
  mimeType /* 'image/jpeg' */,
  maxWidth /* 1200 */,
  maxHeight /* 1200 */,
  autoRotate = true,
  debug = true,
}) => {
  const options = {
    quality: quality,
    maxWidth: maxWidth,
    maxHeight: maxHeight,
    autoRotate: autoRotate,
    debug: debug,
    mimeType: mimeType,
  };

  return readAndCompressImage(file, options);
};

export const getScaledImageDimensions = ({
  containerWidth,
  containerHeight,
  imageWidth,
  imageHeight,
  padding = 160,
}) => {
  const imageRatio = imageWidth / imageHeight;

  let width;
  let height;

  if (imageRatio >= 1) {
    // landscape
    width = containerWidth - padding;
    height = width / imageRatio;
    if (height > containerHeight) {
      height = containerHeight;
      width = containerHeight * imageRatio;
    }
  } else {
    // portrait
    height = containerHeight - padding;
    width = height * imageRatio;
    if (width > containerWidth) {
      width = containerWidth;
      height = containerWidth / imageRatio;
    }
  }

  return {
    width: width,
    height: height,
  };
};

// export const getImgInfo = (img) => {
//   return new RSVP.Promise((resolve, reject) => {
//     img.onload = function() {
//       resolve({
//         width: img.width,
//         height: img.height,
//         src: img.src,
//       });
//     };
//
//     img.onerror = function(err) {
//       reject(err);
//     };
//   });
// }
