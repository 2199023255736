import { action, computed } from '@ember/object';

import Component from '@glimmer/component';
import { connect } from 'ember-redux';
import { getCustomer } from '../../../selectors/customer';
import podNames from 'ember-component-css/pod-names';

const stateToComputed = (state, attrs) => ({
  customer: getCustomer(state, attrs.customerId),
});

const dispatchToActions = {};

class CustomerListItem extends Component {

  showMenu = true;

  get styleNamespace() {
    return podNames['customer-list-item'];
  }

  get classNames() {
    let classNames = [this.styleNamespace, 'customer-list-item'];
    if (this.args.isSelected) classNames.push('is-selected');
    return classNames.join(' ');
  }

  @computed('customer.name')
  get name() {
    return this.customer && this.customer.name ? this.customer.name : 'New Customer'; 
  }

  @action
  onClick() {
    if (this.args.onClick) this.args.onClick(this.args.customerId);
  }

}

export default connect(stateToComputed, dispatchToActions)(CustomerListItem);
