export default [
  {
    'id': 'dashboard:signed_up',
    'name': 'Signed up',
    'description': 'User signed up on the marketing site'
  },
  {
    'id': 'dashboard:signed_in',
    'name': 'Signed in on site',
    'description': 'User signed in on the marketing site'
  },
  {
    'id': 'dashboard:signed_out',
    'name': 'Signed out on site',
    'description': 'User signed out on the marketing site'
  },
  {
    'id': 'dashboard:sent_password_reset',
    'name': 'Forgot password',
    'description': 'User forgot password'
  },
  {
    'id': 'dashboard:reset_password',
    'name': 'Reset password',
    'description': 'User reset password'
  },
  {
    'id': 'dashboard:failed_auth',
    'name': 'Failed auth on site',
    'description': 'Failed authentication attempt on site'
  },
  {
    'id': 'dashboard:downloaded_app',
    'name': 'Downloaded app',
    'description': 'Download the app from the marketing site'
  },
  {
    'id': 'dashboard:verified_email',
    'name': '',
    'description': ''
  },
  {
    'id': 'dashboard:resent_email_verification',
    'name': '',
    'description': ''
  },
  {
    'id': 'dashboard:verify_email_invalid_token',
    'name': '',
    'description': ''
  },
  {
    'id': 'dashboard:verify_email_token_expired',
    'name': '',
    'description': ''
  },
  {
    'id': 'dashboard:created_subscription',
    'name': '',
    'description': ''
  },
  {
    'id': 'dashboard:canceled_subscription',
    'name': '',
    'description': ''
  },
  {
    'id': 'dashboard:beta_requested',
    'name': '',
    'description': ''
  },
  {
    'id': 'dashboard:beta_accepted_terms',
    'name': '',
    'description': ''
  },
  {
    'id': 'dashboard:downloaded_beta_app',
    'name': '',
    'description': ''
  },
];
