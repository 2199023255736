import {
  CLEAR_SELECTION,
  DESELECT_ALL_INVENTION,
  DESELECT_DRAWING,
  DESELECT_DRAWING_ITEMS,
  DESELECT_EDGE,
  DESELECT_ELEMENT,
  DESELECT_ELEMENT_VERSION,
  DESELECT_IMAGE,
  DESELECT_MARKER,
  DESELECT_METHOD_EDGE,
  DESELECT_METHOD_EDGE_POINT,
  DESELECT_METHOD_ITEMS,
  DESELECT_METHOD_NODE,
  DESELECT_TERM,
  HIDE_EXPLORER,
  HIDE_NOTES,
  MULTI_SELECT_EDGE,
  MULTI_SELECT_ELEMENT,
  MULTI_SELECT_ELEMENT_VERSION,
  MULTI_SELECT_IMAGE,
  MULTI_SELECT_MARKER,
  MULTI_SELECT_METHOD_EDGE,
  MULTI_SELECT_METHOD_EDGE_POINT,
  MULTI_SELECT_METHOD_NODE,
  RESET_NAVIGATION,
  SELECT_ASSET,
  SELECT_COMPARISON,
  SELECT_CUSTOMER,
  SELECT_DRAWING,
  SELECT_EDGE,
  SELECT_ELEMENT,
  SELECT_ELEMENT_AND_ELEMENT_VERSION,
  SELECT_ELEMENT_VERSION,
  SELECT_IMAGE,
  SELECT_MARKER,
  SELECT_METHOD_EDGE,
  SELECT_METHOD_EDGE_POINT,
  SELECT_METHOD_NODE,
  SELECT_PRIOR_ART,
  SELECT_PRODUCT,
  SELECT_REVIEW,
  SELECT_TERM,
  SET_ACTIVE_COMPARISON,
  SET_ACTIVE_COMPARISON_ELEMENT,
  SET_ACTIVE_CONTEXT_TAB,
  SET_ACTIVE_DRAWING,
  SET_ACTIVE_ELEMENT_VERSION_LIST_ITEM,
  SET_ACTIVE_INVENTION_SUMMARY_EDITOR_SECTION,
  SET_ACTIVE_METHOD,
  SET_ACTIVE_MILESTONE_CONTEXT,
  SET_ACTIVE_NOTE,
  SET_ACTIVE_PATENTABILITY_SECTION,
  SET_ACTIVE_PATENT_SPECIFICATION_EDITOR_SECTION,
  SET_ACTIVE_PRIOR_ART,
  SET_ACTIVE_PRODUCT,
  SET_ACTIVE_PRODUCT_VIEW,
  SET_ACTIVE_REVIEW,
  SET_ACTIVE_VIEW,
  SET_COLLAPSED_PROBLEM,
  SET_COLLAPSED_SOLUTION,
  SET_CREATING_ASSET,
  SET_CREATING_COMPARISON,
  SET_CREATING_IMAGE_ASSET,
  SET_CREATING_NOTE,
  SET_CREATING_REVIEW,
  SET_DEFAULT_ELEMENT_CATEGORY,
  SET_DEFAULT_MARKER_CATEGORY,
  SET_DEFAULT_METHOD_NODE_TYPE,
  SET_EDITING_ASSET,
  SET_EDITING_COMPARISON,
  SET_EDITING_NOTE,
  SET_EDITING_PRODUCT,
  SET_EDITING_REVIEW,
  SET_EXPLORER_WIDTH,
  SET_FIGURE_TYPE,
  SET_METHOD_REALIZATION_PREVIEW_MODE,
  SET_PATENT_SPECIFICATION_PREVIEW_MODE,
  SET_PREVIEW_MODE,
  SET_REVIEW_MODE,
  SET_SHOWING_ASSETS,
  SET_SHOWING_COMPARISON_MATRIX,
  SET_SHOWING_CUSTOMERS,
  SET_SHOWING_DRAWING_SELECTION_MODAL,
  SET_SHOWING_ELEMENT_VERSION_SELECTOR,
  SET_SHOWING_FIGURES,
  SET_SHOWING_GET_STARTED,
  SET_SHOWING_HISTORY_VERSION_CREATE_MODAL,
  SET_SHOWING_IMAGE_UPLOAD_MODAL,
  SET_SHOWING_INVENTION_SUMMARY_EDITOR,
  SET_SHOWING_PATENTABILITY,
  SET_SHOWING_PATENT_SPECIFICATION,
  SET_SHOWING_PATENT_SPECIFICATION_EDITOR,
  SET_SHOWING_PRIOR_ARTS,
  SET_SHOWING_PRODUCTS,
  SET_SHOWING_PRODUCT_CHECKLIST,
  SET_SHOWING_SEARCH,
  SET_SHOWING_SETTINGS,
  SET_UPDATING_IMAGE_ASSET,
  SET_UPDATING_PRIOR_ART_ASSET,
  SET_UPDATING_PRODUCT_ASSET,
  SET_UPDATING_PRODUCT_CUSTOMERS,
  SET_UPDATING_PRODUCT_PRIOR_ART,
  SHOW_EXPLORER,
  SHOW_NOTES,
  UPDATE_COLLAPSED_NODES,
  UPDATE_ELEMENT_COLUMNS_DATA,
  UPDATE_NAVIGATION_STACK,
  UPDATE_NAVIGATION_STACK_SELECTED_ITEMS,
  UPDATE_VERSION_TREE_COORDINATES,
} from './actionTypes';

import { assert } from '@ember/debug';

export const updateCollapsedNodes = (
  productId,
  collapsedNodesList,
  collapsedDescendantsList
) => {
  return {
    type: UPDATE_COLLAPSED_NODES,
    payload: {
      productId,
      collapsedNodesList,
      collapsedDescendantsList,
    },
  };
};

export const setReviewMode = (reviewMode) => {
  return {
    type: SET_REVIEW_MODE,
    payload: {
      reviewMode,
    },
  };
};

export const updateElementColumnsData = (data) => {
  return {
    type: UPDATE_ELEMENT_COLUMNS_DATA,
    payload: {
      data,
    },
  };
};

export const updateNavigationStack = (navigationStack = []) => {
  return {
    type: UPDATE_NAVIGATION_STACK,
    payload: {
      navigationStack,
    },
  };
};

export const updateNavigationStackSelectedItems = (payload) => {
  return {
    type: UPDATE_NAVIGATION_STACK_SELECTED_ITEMS,
    payload,
  };
};

export const setCollapsedProblem = (value) => {
  return {
    type: SET_COLLAPSED_PROBLEM,
    payload: {
      value,
    },
  };
};
export const setCollapsedSolution = (value) => {
  return {
    type: SET_COLLAPSED_SOLUTION,
    payload: {
      value,
    },
  };
};
export const setDefaultElementCategory = (value) => {
  return {
    type: SET_DEFAULT_ELEMENT_CATEGORY,
    payload: {
      value,
    },
  };
};
export const setDefaultMarkerCategory = (value) => {
  return {
    type: SET_DEFAULT_MARKER_CATEGORY,
    payload: {
      value,
    },
  };
};

export const setDefaultMethodNodeType = (value) => {
  return {
    type: SET_DEFAULT_METHOD_NODE_TYPE,
    payload: {
      value,
    },
  };
};

export const setPatentSpecificationPreviewMode = (value) => {
  return {
    type: SET_PATENT_SPECIFICATION_PREVIEW_MODE,
    payload: {
      value,
    },
  };
};

export const selectMethodEdge = (
  methodEdgeId,
  /* istanbul ignore next */
  multiSelectMode = false
) => {
  return {
    type: multiSelectMode ? MULTI_SELECT_METHOD_EDGE : SELECT_METHOD_EDGE,
    payload: {
      methodEdgeId,
    },
  };
};

export const deselectMethodEdge = (methodEdgeId) => {
  return {
    type: DESELECT_METHOD_EDGE,
    payload: {
      methodEdgeId,
    },
  };
};

export const selectMethodEdgePoint = (
  methodEdgePointId,
  /* istanbul ignore next */
  multiSelectMode = false
) => {
  return {
    type: multiSelectMode
      ? MULTI_SELECT_METHOD_EDGE_POINT
      : SELECT_METHOD_EDGE_POINT,
    payload: {
      methodEdgePointId,
    },
  };
};

export const deselectMethodEdgePoint = (methodEdgePointId) => {
  return {
    type: DESELECT_METHOD_EDGE_POINT,
    payload: {
      methodEdgePointId,
    },
  };
};

export const deselectAllMethod = () => {
  return {
    type: DESELECT_METHOD_ITEMS,
    payload: {},
  };
};

export const deselectMethodItems = () => {
  return {
    type: DESELECT_METHOD_ITEMS,
    payload: {},
  };
};

export const selectImage = (
  imageId,
  /* istanbul ignore next */
  multiSelectMode = false
) => {
  return {
    type: multiSelectMode ? MULTI_SELECT_IMAGE : SELECT_IMAGE,
    payload: {
      imageId,
    },
  };
};

export const deselectImage = (imageId) => {
  return {
    type: DESELECT_IMAGE,
    payload: {
      imageId,
    },
  };
};

export const updateVersionTreeCoordinates = (attributes = {}) => {
  const actionType = UPDATE_VERSION_TREE_COORDINATES;
  return {
    type: actionType,
    payload: {
      attributes,
    },
  };
};

export const setFigureType = (figureType) => {
  return {
    type: SET_FIGURE_TYPE,
    payload: {
      figureType,
    },
  };
};

export const setUpdatingPriorArtAsset = (priorArtId) => {
  return {
    type: SET_UPDATING_PRIOR_ART_ASSET,
    payload: {
      priorArtId,
    },
  };
};

export const setUpdatingProductAsset = (productId) => {
  return {
    type: SET_UPDATING_PRODUCT_ASSET,
    payload: {
      productId,
    },
  };
};

export const setUpdatingProductPriorArt = (value) => {
  return {
    type: SET_UPDATING_PRODUCT_PRIOR_ART,
    payload: {
      value,
    },
  };
};

export const setUpdatingProductCustomers = (value) => {
  return {
    type: SET_UPDATING_PRODUCT_CUSTOMERS,
    payload: {
      value,
    },
  };
};

export const setCreatingImageAsset = (drawingId) => {
  return {
    type: SET_CREATING_IMAGE_ASSET,
    payload: {
      drawingId,
    },
  };
};

export const setUpdatingImageAsset = (imageId) => {
  return {
    type: SET_UPDATING_IMAGE_ASSET,
    payload: {
      imageId,
    },
  };
};

export const setActiveContextTab = (activeTab) => {
  const routeNames = ['elements', 'figures', 'products', 'terms'];

  assert(`${activeTab} is not a valid tab`, routeNames.includes(activeTab));

  return {
    type: SET_ACTIVE_CONTEXT_TAB,
    payload: {
      activeTab,
    },
  };
};

export const setActiveView = (activeView) => {
  const views = ['graph', 'specification', 'presentation'];

  assert(`${activeView} is not a valid tab`, views.includes(activeView));

  return {
    type: SET_ACTIVE_VIEW,
    payload: {
      activeView,
    },
  };
};

export const setShowingComparisonMatrix = (value) => {
  return {
    type: SET_SHOWING_COMPARISON_MATRIX,
    payload: { value },
  };
};

export const setShowingHistoryVersionCreateModal = (value) => {
  return {
    type: SET_SHOWING_HISTORY_VERSION_CREATE_MODAL,
    payload: { value },
  };
};

export const showNotes = () => {
  return {
    type: SHOW_NOTES,
    payload: {},
  };
};

export const showExplorer = () => {
  return {
    type: SHOW_EXPLORER,
    payload: {},
  };
};

export const hideExplorer = () => {
  return {
    type: HIDE_EXPLORER,
    payload: {},
  };
};

export const hideScratchpad = () => {
  return {
    type: HIDE_NOTES,
    payload: {},
  };
};

export const setPreviewMode = (previewMode = false) => {
  return {
    type: SET_PREVIEW_MODE,
    payload: {
      previewMode,
    },
  };
};

export const setMethodRealizationPreviewMode = (
  inventionId,
  previewMode = false
) => {
  return {
    type: SET_METHOD_REALIZATION_PREVIEW_MODE,
    payload: {
      inventionId,
      previewMode,
    },
  };
};

export const clearSelection = () => {
  return {
    type: CLEAR_SELECTION,
    payload: {},
  };
};

export const deselectDrawingItems = (drawingId) => {
  return {
    type: DESELECT_DRAWING_ITEMS,
    payload: {
      drawingId,
    },
  };
};

export const deselectGraph = () => {
  return {
    type: DESELECT_ALL_INVENTION,
    payload: {},
  };
};

export const resetNavigation = (inventionId) => {
  return {
    type: RESET_NAVIGATION,
    payload: {
      inventionId,
    },
  };
};

export const setShowingElementVersionSelector = (value = false) => {
  return {
    type: SET_SHOWING_ELEMENT_VERSION_SELECTOR,
    payload: {
      value,
    },
  };
};

export const setShowingFigures = (value) => {
  return {
    type: SET_SHOWING_FIGURES,
    payload: {
      value,
    },
  };
};

export const setShowingSearch = (value) => {
  return {
    type: SET_SHOWING_SEARCH,
    payload: {
      value,
    },
  };
};

export const setShowingGetStarted = (value) => {
  return {
    type: SET_SHOWING_GET_STARTED,
    payload: {
      value,
    },
  };
};

export const setShowingImageUploadModal = (drawingId, value = false) => {
  return {
    type: SET_SHOWING_IMAGE_UPLOAD_MODAL,
    payload: {
      drawingId,
      value,
    },
  };
};
export const setShowingAssets = (value) => {
  return {
    type: SET_SHOWING_ASSETS,
    payload: {
      value,
    },
  };
};
export const setShowingPatentSpecification = (value) => {
  return {
    type: SET_SHOWING_PATENT_SPECIFICATION,
    payload: {
      value,
    },
  };
};
export const setShowingPriorArts = (value) => {
  return {
    type: SET_SHOWING_PRIOR_ARTS,
    payload: {
      value,
    },
  };
};
export const setShowingCustomers = (value) => {
  return {
    type: SET_SHOWING_CUSTOMERS,
    payload: {
      value,
    },
  };
};

export const setShowingPatentability = (value) => {
  return {
    type: SET_SHOWING_PATENTABILITY,
    payload: {
      value,
    },
  };
};

export const setActivePatentabilitySection = (value) => {
  return {
    type: SET_ACTIVE_PATENTABILITY_SECTION,
    payload: {
      value,
    },
  };
};

export const setShowingProducts = (value) => {
  return {
    type: SET_SHOWING_PRODUCTS,
    payload: {
      value,
    },
  };
};

export const setShowingProductChecklist = (value) => {
  return {
    type: SET_SHOWING_PRODUCT_CHECKLIST,
    payload: {
      value,
    },
  };
};

export const setActiveNote = (inventionId, noteId) => {
  return {
    type: SET_ACTIVE_NOTE,
    payload: {
      inventionId,
      noteId,
    },
  };
};

export const setEditingNote = (noteId) => {
  return {
    type: SET_EDITING_NOTE,
    payload: {
      noteId,
    },
  };
};

export const setCreatingNote = (value) => {
  return {
    type: SET_CREATING_NOTE,
    payload: {
      value,
    },
  };
};

export const setActiveProduct = (productId) => {
  return {
    type: SET_ACTIVE_PRODUCT,
    payload: {
      productId,
    },
  };
};

export const setActiveMilestoneContext = (value) => {
  return {
    type: SET_ACTIVE_MILESTONE_CONTEXT,
    payload: {
      value,
    },
  };
};

export const setActiveProductView = (value) => {
  return {
    type: SET_ACTIVE_PRODUCT_VIEW,
    payload: {
      value,
    },
  };
};

export const setActivePriorArt = (priorArtId) => {
  return {
    type: SET_ACTIVE_PRIOR_ART,
    payload: {
      priorArtId,
    },
  };
};

export const setActiveReview = (reviewId) => {
  return {
    type: SET_ACTIVE_REVIEW,
    payload: {
      reviewId,
    },
  };
};

export const setCreatingAsset = (value) => {
  return {
    type: SET_CREATING_ASSET,
    payload: {
      value,
    },
  };
};

export const setEditingAsset = (assetId) => {
  return {
    type: SET_EDITING_ASSET,
    payload: {
      assetId,
    },
  };
};

export const setActiveComparison = (comparisonId) => {
  return {
    type: SET_ACTIVE_COMPARISON,
    payload: {
      comparisonId,
    },
  };
};

export const setActiveComparisonElement = (elementId) => {
  return {
    type: SET_ACTIVE_COMPARISON_ELEMENT,
    payload: {
      elementId,
    },
  };
};

export const setCreatingComparison = (value) => {
  return {
    type: SET_CREATING_COMPARISON,
    payload: {
      value,
    },
  };
};

export const setCreatingReview = (value) => {
  return {
    type: SET_CREATING_REVIEW,
    payload: {
      value,
    },
  };
};

export const setEditingReview = (reviewId) => {
  return {
    type: SET_EDITING_REVIEW,
    payload: {
      reviewId,
    },
  };
};

export const setEditingProduct = (productId) => {
  return {
    type: SET_EDITING_PRODUCT,
    payload: {
      productId,
    },
  };
};

export const setEditingComparison = (comparisonId) => {
  return {
    type: SET_EDITING_COMPARISON,
    payload: {
      comparisonId,
    },
  };
};


export const setShowingDrawingSelectionModal = (value) => {
  return {
    type: SET_SHOWING_DRAWING_SELECTION_MODAL,
    payload: {
      value,
    },
  };
};

export const setShowingSettings = (value) => {
  return {
    type: SET_SHOWING_SETTINGS,
    payload: {
      value,
    },
  };
};

export const setActiveDrawing = (drawingId) => {
  return {
    type: SET_ACTIVE_DRAWING,
    payload: {
      drawingId,
    },
  };
};

export const setActiveElementVersionListItem = (elementVersionId) => {
  return {
    type: SET_ACTIVE_ELEMENT_VERSION_LIST_ITEM,
    payload: {
      elementVersionId,
    },
  };
};

export const setActiveMethod = (methodId) => {
  return {
    type: SET_ACTIVE_METHOD,
    payload: {
      methodId,
    },
  };
};

export const selectMethodNode = (
  methodNodeId,
  /* istanbul ignore next */
  multiSelectMode = false
) => {
  return {
    type: multiSelectMode ? MULTI_SELECT_METHOD_NODE : SELECT_METHOD_NODE,
    payload: {
      methodNodeId,
    },
  };
};

export const deselectDrawing = (drawingId) => {
  return {
    type: DESELECT_DRAWING,
    payload: {
      drawingId,
    },
  };
};
export const selectDrawing = (drawingId) => {
  return {
    type: SELECT_DRAWING,
    payload: {
      drawingId,
    },
  };
};

export const deselectMethodNode = (methodNodeId) => {
  return {
    type: DESELECT_METHOD_NODE,
    payload: {
      methodNodeId,
    },
  };
};

export const selectElement = (
  elementId,
  /* istanbul ignore next */
  multiSelectMode = false
) => {
  return {
    type: multiSelectMode ? MULTI_SELECT_ELEMENT : SELECT_ELEMENT,
    payload: {
      elementId,
    },
  };
};

export const selectElementVersion = (
  elementVersionId,
  /* istanbul ignore next */
  multiSelectMode = false
) => {
  return {
    type: multiSelectMode
      ? MULTI_SELECT_ELEMENT_VERSION
      : SELECT_ELEMENT_VERSION,
    payload: {
      elementVersionId,
    },
  };
};

export const selectElementAndElementVersion = (elementId, elementVersionId) => {
  return {
    type: SELECT_ELEMENT_AND_ELEMENT_VERSION,
    payload: {
      elementId,
      elementVersionId,
    },
  };
};

export const selectEdge = (
  edge,
  /* istanbul ignore next */
  multiSelectMode = false
) => {
  return {
    type: multiSelectMode ? MULTI_SELECT_EDGE : SELECT_EDGE,
    payload: {
      edge,
    },
  };
};

export const selectTerm = (termId) => {
  return {
    type: SELECT_TERM,
    payload: {
      termId,
    },
  };
};

export const selectMarker = (
  markerId,
  /* istanbul ignore next */
  multiSelectMode = false
) => {
  return {
    type: multiSelectMode ? MULTI_SELECT_MARKER : SELECT_MARKER,
    payload: {
      markerId,
    },
  };
};

export const deselectMarker = (markerId) => {
  return {
    type: DESELECT_MARKER,
    payload: {
      markerId,
    },
  };
};

export const selectProduct = (productId) => {
  return {
    type: SELECT_PRODUCT,
    payload: {
      productId,
    },
  };
};

export const selectPriorArt = (priorArtId) => {
  return {
    type: SELECT_PRIOR_ART,
    payload: {
      priorArtId,
    },
  };
};

export const selectCustomer = (customerId) => {
  return {
    type: SELECT_CUSTOMER,
    payload: {
      customerId,
    },
  };
};

export const selectAsset = (assetId) => {
  return {
    type: SELECT_ASSET,
    payload: {
      assetId,
    },
  };
};

export const selectComparison = (comparisonId) => {
  return {
    type: SELECT_COMPARISON,
    payload: {
      comparisonId,
    },
  };
};

export const selectReview = (reviewId) => {
  return {
    type: SELECT_REVIEW,
    payload: {
      reviewId,
    },
  };
};

export const deselectTerm = (termId) => {
  return {
    type: DESELECT_TERM,
    payload: {
      termId,
    },
  };
};

export const deselectElement = (elementId) => {
  return {
    type: DESELECT_ELEMENT,
    payload: {
      elementId,
    },
  };
};

export const deselectElementVersion = (elementVersionId) => {
  return {
    type: DESELECT_ELEMENT_VERSION,
    payload: {
      elementVersionId,
    },
  };
};

export const deselectEdge = (edge) => {
  return {
    type: DESELECT_EDGE,
    payload: {
      edge,
    },
  };
};

export const setExplorerWidth = (width) => {
  return {
    type: SET_EXPLORER_WIDTH,
    payload: {
      width,
    },
  };
};

export const setActivePatentSpecificationEditorSection = (value) => {
  return {
    type: SET_ACTIVE_PATENT_SPECIFICATION_EDITOR_SECTION,
    payload: {
      value,
    },
  };
};

export const setShowingPatentSpecificationEditor = (value) => {
  return {
    type: SET_SHOWING_PATENT_SPECIFICATION_EDITOR,
    payload: {
      value,
    },
  };
};

export const setActiveInventionSummaryEditorSection = (value) => {
  return {
    type: SET_ACTIVE_INVENTION_SUMMARY_EDITOR_SECTION,
    payload: {
      value,
    },
  };
};

export const setShowingInventionSummaryEditor = (value) => {
  return {
    type: SET_SHOWING_INVENTION_SUMMARY_EDITOR,
    payload: {
      value,
    },
  };
};

export const uiActionTypes = [
  'UPDATE_META',
  'ADD_GRAPH',
  '@@redux-undo/CLEAR_HISTORY',
  'DESELECT_ALL_INVENTION',

  'DESELECT_DRAWING_ITEMS',
  'DESELECT_EDGE',
  'DESELECT_ELEMENT',
  'DESELECT_ELEMENT_VERSION',
  'DESELECT_IMAGE',
  'DESELECT_MARKER',
  'DESELECT_METHOD_EDGE',
  'DESELECT_METHOD_EDGE_POINT',
  'DESELECT_METHOD_ITEMS',
  'DESELECT_METHOD_NODE',
  'DESELECT_TERM',
  'HIDE_EXPLORER',
  'HIDE_NOTES',
  'MULTI_SELECT_EDGE',
  'MULTI_SELECT_ELEMENT',
  'MULTI_SELECT_ELEMENT_VERSION',
  'MULTI_SELECT_IMAGE',
  'MULTI_SELECT_MARKER',
  'MULTI_SELECT_METHOD_EDGE',
  'MULTI_SELECT_METHOD_EDGE_POINT',
  'MULTI_SELECT_METHOD_NODE',
  'RESET_NAVIGATION',
  'SELECT_ASSET',
  'SELECT_COMPARISON',
  'SELECT_CUSTOMER',
  'SELECT_EDGE',
  'SELECT_ELEMENT',
  'SELECT_ELEMENT_AND_ELEMENT_VERSION',
  'SELECT_ELEMENT_VERSION',
  'SELECT_IMAGE',
  'SELECT_MARKER',
  'SELECT_METHOD_EDGE',
  'SELECT_METHOD_EDGE_POINT',
  'SELECT_METHOD_NODE',
  'SELECT_PRIOR_ART',
  'SELECT_PRODUCT',
  'SELECT_REVIEW',
  'SELECT_REFERENCE',
  'SELECT_TERM',
  'SET_ACTIVE_COMPARISON',
  'SET_ACTIVE_COMPARISON_ELEMENT',
  'SET_ACTIVE_CONTEXT_TAB',
  'SET_ACTIVE_DRAWING',
  'SET_ACTIVE_ELEMENT_VERSION_LIST_ITEM',
  'SET_ACTIVE_FEATURE',
  'SET_ACTIVE_METHOD',
  'SET_ACTIVE_NOTE',
  'SET_ACTIVE_PATENTABILITY_SECTION',
  'SET_ACTIVE_PRIOR_ART',
  'SET_ACTIVE_PRODUCT',
  'SET_ACTIVE_PRODUCT_VIEW',
  'SET_ACTIVE_REVIEW',
  'SET_COLLAPSED_SOLUTION',
  'SET_COLLAPSED_PROBLEM',
  'SET_CREATING_ASSET',
  'SET_CREATING_COMPARISON',
  'SET_CREATING_IMAGE_ASSET',
  'SET_CREATING_NOTE',
  'SET_CREATING_REVIEW',
  'SET_EDITING_ASSET',
  'SET_EDITING_COMPARISON',
  'SET_EDITING_NOTE',
  'SET_EDITING_PRODUCT',
  'SET_EDITING_REVIEW',
  'SET_EXPLORER_WIDTH',
  'SET_FIGURE_TYPE',
  'SET_PREVIEW_MODE',
  'SET_METHOD_REALIZATION_PREVIEW_MODE',
  'SET_PATENT_SPECIFICATION_PREVIEW_MODE',
  'SET_REVIEW_MODE',
  'SET_SHOWING_ASSETS',
  'SET_SHOWING_COMPARISON_MATRIX',
  'SET_SHOWING_CUSTOMERS',
  'SET_SHOWING_DRAWING_SELECTION_MODAL',
  'SET_SHOWING_ELEMENT_VERSION_SELECTOR',
  'SET_SHOWING_HISTORY_VERSION_CREATE_MODAL',
  'SET_SHOWING_IMAGE_UPLOAD_MODAL',
  'SET_SHOWING_INVENTION_SUMMARY_EDITOR',
  'SET_ACTIVE_INVENTION_SUMMARY_EDITOR_SECTION',
  'SET_SHOWING_PATENT_SPECIFICATION',
  'SET_SHOWING_PATENTABILITY',
  'SET_SHOWING_PRIOR_ARTS',
  'SET_SHOWING_PRODUCTS',
  'SET_UPDATING_IMAGE_ASSET',
  'SET_UPDATING_PRIOR_ART_ASSET',
  'SET_UPDATING_PRODUCT_ASSET',
  'SET_UPDATING_PRODUCT_PRIOR_ART',
  'SHOW_EXPLORER',
  'SHOW_NOTES',
  'UPDATE_VERSION_TREE_COORDINATES',
  'SET_ACTIVE_PATENT_SPECIFICATION_EDITOR_SECTION',
  'SET_SHOWING_PATENT_SPECIFICATION_EDITOR',
];
