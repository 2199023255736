import Component from '@glimmer/component';
import { computed } from '@ember/object';
import { connect } from 'ember-redux';
import { getElement } from '../../../selectors/element';
import { getElementRequirementsList } from '../../../selectors/element';
import { getElementVersion } from '../../../selectors/element-version';
import { getMethod } from '../../../selectors/method';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

const stateToComputed = (state, attrs) => ({
  method: getMethod(state, attrs.methodId),
});

const dispatchToActions = {};

class MethodPresentation extends Component {
  @service redux;
  @service tracking;
  @service store;
  @tracked isOverflowed = false;

  get styleNamespace() {
    return podNames['method-presentation'];
  }

  get classNames() {
    let classNames = [this.styleNamespace];
    return classNames.join(' ');
  }

  @computed('method.elementVersion')
  get elementVersion() {
    const state = this.redux.getState();
    return this.method && getElementVersion(state, this.method.elementVersion);
  }

  @computed('elementVersion.element')
  get element() {
    const state = this.redux.getState();
    return (
      this.elementVersion && getElement(state, this.elementVersion.element)
    );
  }

  @computed('element.{id,requirementsList.[]}')
  get requirementsList() {
    const state = this.redux.getState();
    return (
      this.element &&
      getElementRequirementsList(state, this.element.id)
    );
  }

  @computed('element.id')
  get elementName() {
    return this.element && `<mention class="mention" data-type="element" data-id="${this.element.id}"></mention>`;
  }

  @computed(
    'elementVersion.markersList'
  )
  get referencesList() {
    return this.elementVersion && this.elementVersion.markersList;
  }

  @computed(
    'method.id'
  )
  get methodModel() {
    return this.method && this.store.peekRecord('method', this.method.id);
  }

  @computed(
    'methodModel.ungroupedStepsList'
  )
  get ungroupedStepsList() {
    return this.methodModel && this.methodModel.ungroupedStepsList
  }
}

export default connect(stateToComputed, dispatchToActions)(MethodPresentation);
