import { action, computed } from '@ember/object';

import Component from '@glimmer/component';
import { connect } from 'ember-redux';
import { getMarker } from '../../../selectors/marker';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

const dispatchToActions = {};

const stateToComputed = (state, attrs) => ({
  marker: getMarker(state, attrs.markerId),
});

class MarkerContext extends Component {
  @service tracking;
  @tracked selectedHighlightId = null;
  @tracked hasSelectedItem = false;

  get classNames() {
    let classNames = ['marker-context', this.styleNamespace];
    return classNames.join(' ');
  }

  get styleNamespace() {
    return podNames['marker-context'];
  }

  reset() {}

  @computed('marker.endX')
  get endX() {
    return (this.marker && this.marker.endX) || 0;
  }

  @computed('marker.endY')
  get endY() {
    return (this.marker && this.marker.endY) || 0;
  }

  @computed('marker.curve')
  get curve() {
    return this.marker && this.marker.curve;
  }

  @computed('marker.highlightsList')
  get highlightsList() {
    return this.marker && this.marker.highlightsList;
  }

  @computed('marker.labelOrientation')
  get labelOrientation() {
    return this.marker && this.marker.labelOrientation;
  }

  @computed('marker.pointStyle')
  get pointStyle() {
    return this.marker && this.marker.pointStyle;
  }

  @computed('marker.hasLeadLine')
  get hasLeadLine() {
    return this.marker && this.marker.hasLeadLine;
  }

  @computed('marker.type')
  get type() {
    return this.marker && this.marker.type;
  }

  @computed('marker.category')
  get category() {
    return this.marker && this.marker.category;
  }

  @computed('marker.{element,term}')
  get showsModelId() {
    return this.marker.element || this.marker.term;
  }

  @computed('category', 'type')
  get categoryName() {
    let name;
    switch (this.category) {
      case 'system':
        name = 'System';
        break;
      case 'part':
        name = 'Part';
        break;
    }

    if (this.type === 'term') {
      name = 'Term';
    }

    return name;
  }

  @action
  onSetElement(elementId, elementVersionId, category) {
    this.args.onMarkerSetElement(
      this.args.drawingId,
      this.args.markerId,
      elementId,
      elementVersionId,
      category
    );
  }

  @action
  onSetTerm(termId) {
    this.args.onMarkerSetTerm(this.args.drawingId, this.args.markerId, termId);
  }

  @action
  onRemoveReference() {
    this.hasSelectedItem = false;
    this.args.onMarkerRemoveReference(this.args.drawingId, this.args.markerId);
  }

  @action
  setType(type, category) {
    this.args.onSetMarkerType(this.args.markerId, type, category);

    if (type === 'element') {
      this.tracking.trackEvent('marker_set_type_system');
    }

    if (type === 'term') {
      this.tracking.trackEvent('marker_set_type_term');
    }
  }

  @action
  onAddElement(name, category) {
    this.args.onMarkerCreateElement(
      name,
      this.args.markerId,
      this.drawingId,
      category
    );
    this.tracking.trackEvent('marker_created_system');
  }

  @action
  onAddTerm(name) {
    this.args.onMarkerCreateTerm(name, this.args.markerId, this.drawingId);
    this.tracking.trackEvent('marker_created_term');
  }

  // @action
  // createAndSetProduct(name) {
  //   // this.onMarkerCreateTerm(name, this.args.markerId, this.drawingId);
  //   // this.tracking.trackEvent('marker_created_term');
  // }

  @action
  setCurve(curve) {
    this.args.onUpdateMarker(this.args.drawingId, this.args.markerId, {
      curve,
    });

    if (curve === 'linear') {
      this.tracking.trackEvent('marker_set_lead_line_linear');
    } else {
      this.tracking.trackEvent('marker_set_lead_line_curved');
    }
  }

  @action
  setLabelOrientation(labelOrientation) {
    this.args.onUpdateMarker(this.args.drawingId, this.args.markerId, {
      labelOrientation,
    });

    this.tracking.trackEvent('marker_set_label_orientation');
  }

  @action
  setPointStyle(pointStyle) {
    this.args.onUpdateMarker(this.args.drawingId, this.args.markerId, {
      pointStyle,
    });

    if (pointStyle === 'arrow') {
      this.tracking.trackEvent('marker_set_point_style_arrow');
    } else {
      this.tracking.trackEvent('marker_set_point_style_line');
    }
  }

  @action
  collapseLeadLine() {
    const x = this.endX;
    const y = this.endY;

    this.args.onUpdateMarker(this.args.drawingId, this.args.markerId, {
      hasLeadLine: false,
      midX: x,
      midY: y,
      endX: x,
      endY: y,
      startX: x,
      startY: y,
    });

    this.tracking.trackEvent('marker_collapsed_lead_line');
  }

  @action
  onSelectItem(markerId, isSelected) {
    this.selectedHighlightId = null;
    if (isSelected) {
      this.hasSelectedItem = false;
    } else {
      this.hasSelectedItem = true;
    }
  }

  @action
  selectHighlight(highlightId, isSelected) {
    this.hasSelectedItem = false;
    if (isSelected) {
      this.selectedHighlightId = null;
    } else {
      this.selectedHighlightId = highlightId;
    }
  }

  @action
  onRemoveHighlight(highlightId) {
    if (this.args.onRemoveHighlight) {
      this.args.onRemoveHighlight(
        this.args.drawingId,
        this.args.markerId,
        highlightId
      );
    }
    this.selectedHighlightId = null;
  }

  @action
  onUpdate(elem, [markerId]) {
    if (this._markerId !== markerId) {
      this._markerId = markerId;
      this.hasSelectedItem = false;
      this.selectedHighlightId = null;
    }
  }
}

export default connect(stateToComputed, dispatchToActions)(MarkerContext);
