import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { connect } from 'ember-redux';
import podNames from 'ember-component-css/pod-names';
import FeatureTypes from '../../../constants/types/features';

const stateToComputed = null;

const dispatchToActions = {};

class FeatureTypePrompt extends Component {
  @tracked featuresCount = 0;

  get styleNamespace() {
    return podNames['feature-type-prompt'];
  }

  get classNames() {
    let classNames = [this.styleNamespace, 'feature-type-prompt'];
    return classNames.join(' ');
  }

  get type() {
    return this.args.typeId && FeatureTypes[this.args.typeId];
  }

  // eslint-disable-next-line ember/require-computed-property-dependencies
  // @computed('elementVersion.featuresList.[]', 'args.typeId')
  // get featuresCount() {
  //   const state = this.redux.getState();
  //   return (
  //     this.elementVersion &&
  //     this.elementVersion.featuresList.filter((featureId) => {
  //       const feature = getFeature(state, featureId);
  //       return feature && feature.type === this.args.typeId;
  //     }).length
  //   );
  // }

  get featuresBadge() {
    return this.featuresCount ? `${this.featuresCount}` : null;
  }

  @action
  onAddFeature() {
    this.featuresCount++;
    this.args.onAddFeature(this.args.typeId)
  }
}

export default connect(stateToComputed, dispatchToActions)(FeatureTypePrompt);
