export default {
  attributes: {
    id: {
      type: 'uuid',
      defaultValue: null
    },
    name: {
      type: 'string',
      defaultValue: 'Untitled Invention'
    },
    element: {
      type: 'uuid',
      defaultValue: null
    },
    figuresList: {
      type: 'array',
      defaultValue: []
    },
    preferredElementVersionsList: {
      type: 'array',
      defaultValue: []
    },
  }
};
