import Component from '@glimmer/component';
import { action } from '@ember/object';
import { htmlSafe } from '@ember/string';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';
import uuid from 'uuid/v4';

class UiContextMenu extends Component {
  @service contextMenu;

  domElementId = `context-menu-${uuid()}`;

  popperOptions = Object.freeze({
    modifiers: {
      preventOverflow: {
        escapeWithReference: false,
      },
    },
  });

  get styleNamespace() {
    return podNames['ui-context-menu'];
  }

  get classNames() {
    let classNames = [this.styleNamespace, 'ui-context-menu'];
    if (this.contextMenu.isActive) classNames.push('is-active');
    return classNames.join(' ');
  }

  get positionStyle() {
    const x = this.contextMenu.x;
    const y = this.contextMenu.y;
    return htmlSafe(`top: ${y}px; left: ${x}px;`);
  }

  @action
  onActive(isActive) {
    if (isActive && this.args.onOpen) {
      this.args.onOpen(this.contextMenu.source)
    }
  }
}

export default UiContextMenu;
