import { computed, get, getProperties, set } from '@ember/object';

import $ from 'jquery';
import { A } from '@ember/array';
import Component from '@ember/component';
import MediumEditor from 'medium-editor';
import { allHMTLTags } from '../../../utils/mentions';
import { isPresent } from '@ember/utils';

const defaultOptions = {};

const optionsList = [
  'delay',
  'disableReturn',
  'disableEditing',
  'extensions',
  'spellcheck',
  'targetBlank',
  'toolbar',
  'anchorPreview',
  'placeholder',
  'imageDragging',
  'elementsContainer'
];

const mediumEditorEvents = [
  // custom events
  'addElement',
  'blur',
  'editableInput',
  'externalInteraction',
  'focus',
  'removeElement',
  // toolbar custom events
  'hideToolbar',
  'positionToolbar',
  'positionedToolbar',
  'showToolbar',
  // proxied custom events
  'editableClick',
  'editableBlur',
  'editableKeypress',
  'editableKeyup',
  'editableKeydown',
  'editableKeydownEnter',
  'editableKeydownTab',
  'editableKeydownDelete',
  'editableKeydownSpace',
  'editableMouseover',
  'editableDrag',
  'editableDrop',
  'editablePaste'
];

// const onPaste = function(str) {
//   return str.replace(/<(?:br|\/div|\/p)>/g, '\n').replace(/<.*?>/g, '');
// };

const MediumEditorComponent = Component.extend({
  attributeBindings: [
    'placeholder:data-placeholder',
    // 'nullPlaceholder:data-placeholder'
  ],
  placeholder: null,
  nullPlaceholder: ' ',
  forcePlainText: false,
  unwrapTags: computed('disableReturn', function() {
    const unwrapTags = this.disableReturn ? allHMTLTags : allHMTLTags.filter(tag => tag !== 'p');
    return unwrapTags;
  }),

  toolbarContainer: '.ember-application',

  elementsContainer: computed('toolbarContainer', function() {
    const selector = this.toolbarContainer;
    const domNode = $(selector)[0];
    return domNode;
  }),

  value: null,
  onChange: null,
  _skipNextOnChangeTrigger: false,

  editableKeydownDelete() {
    // this.fireOnChange();
  },

  didInsertElement() {
    this._super(...arguments);
    this._initEditor();
  },

  willDestroyElement() {
    this._super(...arguments);
    this._destroyEditor();
  },

  didUpdateAttrs() {
    this._super(...arguments);
    this._setContent(this._editor);
  },

  _initEditor() {
    let editor = new MediumEditor(this.element, this._getOptions());
    this._setContent(editor);
    this._subscribeToEvents(editor);

    set(this, '_editor', editor);
  },

  _subscribeToEvents(editor) {
    mediumEditorEvents.forEach(event => {
      let handler = get(this, event);
      if (typeof handler === 'function') {
        editor.subscribe(event, handler.bind(this));
      }
    });

    this._subscribeToOnChange(editor);
  },

  _subscribeToOnChange(editor) {
    let onChangeHandler = this.onChange;

    if (typeof onChangeHandler === 'function') {
      let handler = () => {
        let newValue = editor.getContent();
        let isUpdated = this._prevValue !== newValue;
        let skipNextOnChangeTrigger = get(this, `_skipNextOnChangeTrigger`);

        if (isUpdated && !skipNextOnChangeTrigger) {
          set(this, '_prevValue', newValue);
          onChangeHandler(newValue);
        }
        set(this, `_skipNextOnChangeTrigger`, false);
      };

      editor.subscribe('editableInput', handler);
    }
  },

  _setContent(editor) {
    let value = this.value;
    set(this, '_prevValue', value || '');

    editor.saveSelection();
    editor.setContent(value);
    editor.restoreSelection();

    document.getElementById(this.elementId).blur();
    // this.preRenderCleanup(this.get('figures'), this.get('elements'), editor);
  },


  _getOptions() {
    let filteredOptions = optionsList.map(option =>
      isPresent(get(this, option)) ? option : null
    );
    filteredOptions = A(filteredOptions).compact();

    let collectedOptions = getProperties(this, filteredOptions);
    let optionsHash = this.options;

    return Object.assign({}, defaultOptions, optionsHash, collectedOptions);
  },

  _destroyEditor() {
    let editor = this._editor;
    if (isPresent(editor)) {
      editor.destroy();
    }
  },

  // This function adds a space to the end of the contenteditable
  // when creating with MediumButton in order to fix the chrome
  // cursor weirdness

  // updateSelectionAfterMediumCreate() {
  //   const elementId = this.get('elementId');
  //   const mediumNode = document.getElementById(elementId);
  //   const childNodes = mediumNode.childNodes;
  //   const firstChild = childNodes[0];
  //
  //   let lastSib = firstChild;
  //
  //   if (!lastSib) {
  //     return;
  //   }
  //
  //   // get the last node in the contenteditable
  //   while (lastSib.nextSibling != null) {
  //     lastSib = lastSib.nextSibling;
  //   }
  //
  //   // if the last node is an empty text node
  //   if (lastSib.nodeType === 3 && !lastSib.nodeValue) {
  //     // add a space to solve the contenteditable death by cursor
  //     lastSib.nodeValue = '\u00A0';
  //
  //     // place the cursor at the end
  //     // const range = document.createRange();
  //     // range.selectNodeContents(mediumNode);
  //     // range.collapse(false);
  //     // const sel = window.getSelection();
  //     // sel.removeAllRanges();
  //     // sel.addRange(range);
  //   }
  // },

  // fireOnChange(value) {
  //   let onChangeHandler = get(this, 'onChange');
  //
  //   if (typeof onChangeHandler === 'function') {
  //     let newValue =
  //       value && value.length ? value : get(this, '_editor').getContent();
  //     let oldLen = get(this, '_prevValue').length;
  //     let newLen = newValue.length;
  //     // let skipNextOnChangeTrigger = get(this, `_skipNextOnChangeTrigger`);
  //     if (oldLen !== newLen) {
  //       set(this, '_prevValue', newValue);
  //       this.incrementProperty('changeIndex');
  //       onChangeHandler(newValue);
  //     }
  //     set(this, `_skipNextOnChangeTrigger`, false);
  //   }
  // },

  options: computed('forcePlainText', 'unwrapTags', function() {
    return {
      extensions: {},
      toolbar: false,
      paste: {
        forcePlainText: this.forcePlainText,
        cleanPastedHTML: true,
        cleanReplacements: [],
        cleanAttrs: ['style'],
        cleanTags: [],
        // unwrapTags: ['p', 'div', 'ul', 'li'],
        unwrapTags: this.unwrapTags
      }
    };
  })
});

MediumEditorComponent.reopenClass({ positionalParams: ['value'] });

export default MediumEditorComponent;
