import Service from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class ContextMenu extends Service {
  @tracked isActive = false;
  @tracked isSwitched = false;
  @tracked x = 0;
  @tracked y = 0;
  @tracked stageX = 0;
  @tracked stageY = 0;
  @tracked source = '';
  @tracked targetId = null;
  @tracked domElementId = null;
  @tracked containerId = null;
  @tracked containerType = null;
  @tracked modelId = null;
  @tracked modelType = null;
  @tracked modelAttr = null;

  open(
    source,
    {
      targetId,
      x,
      y,
      stageX,
      stageY,
      domElementId,
      containerId,
      containerType,
      modelId,
      modelType,
      modelAttr,
    } = {}
  ) {
    this.x = x || 0;
    this.y = y || 0;
    this.stageX = stageX || 0;
    this.stageY = stageY || 0;
    this.isSwitched = !this.isSwitched;
    this.isActive = true;
    this.source = source;
    this.targetId = targetId || null;
    this.domElementId = domElementId || null;
    this.containerId = containerId || null;
    this.containerType = containerType || null;
    this.modelId = modelId || null;
    this.modelType = modelType || null;
    this.modelAttr = modelAttr || null;
  }

  close() {
    this.isActive = false;
    this.x = 0;
    this.y = 0;
    this.stageX = 0;
    this.stageY = 0;
    this.source = '';
    this.targetId = null;
    this.domElementId = null;
    this.containerId = null;
    this.containerType = null;
  }
}
