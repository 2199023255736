import { blobUrlToBase64JPEG, getCanvasBlob } from '../utils/image';
// import { ThresholdFilter } from '../utils/filters';
import {
  defaultArtboardHeight,
  defaultArtboardWidth,
} from '../constants/settings';
import { enqueueTask, task } from 'ember-concurrency-decorators';
import { getElementOrdinal, getTermOrdinal } from '../selectors/invention';

import ENV from '../config/environment';
import Konva from 'konva';
import Service from '@ember/service';
import { getAutoLabelOrientation } from '../utils/drawing';
import { getFigure } from '../selectors/invention';
import { getImage } from '../selectors/image';
import { getMarker } from '../selectors/marker';
import { getNumberingIndex } from '../selectors/invention-ui';
import moment from 'moment';
import { reverse } from 'lodash';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class FiguresCache extends Service {
  @service worker;
  @service imageFiltersCache;
  @service assets;
  @service redux;

  @tracked blobUrlCache;
  @tracked dataUrlCache;
  @tracked cachingWorkersCreated = false;

  isTest = ENV.environment === 'test';

  maxWorkers = 4;
  workerIndex = 0;

  constructor(owner, args) {
    super(owner, args);
    this.blobUrlCache = {};
    this.dataUrlCache = {};
  }

  // async createWorkers() {
  //   return
  //   if (!this.cachingWorkersCreated && !this.isTest) {
  //     for (let i = 0; i < this.maxWorkers; i++) {
  //       this['cachingWorker' + i] = await this.worker.open(
  //         'konva-reference-image'
  //       );
  //     }
  //     this.cachingWorkersCreated = true;
  //   }
  // }

  // terminateWorkers() {
  //   if (this.cachingWorkersCreated) {
  //     for (let i = 0; i < this.maxWorkers; i++) {
  //       if (this['cachingWorker' + i]) {
  //         console.log('terminating worker' + ' ' + i);
  //         this['cachingWorker' + i].terminate();
  //       }
  //     }
  //   }
  // }

  getArtboardDimensions(orientation) {
    const artboardWidth =
      orientation === 'portrait' ? defaultArtboardWidth : defaultArtboardHeight;
    const artboardHeight =
      orientation === 'portrait' ? defaultArtboardHeight : defaultArtboardWidth;

    return {
      artboardWidth,
      artboardHeight,
    };
  }

  getStageDimensions(orientation) {
    const stageWidth =
      orientation === 'portrait' ? defaultArtboardWidth : defaultArtboardHeight;
    const stageHeight =
      orientation === 'portrait' ? defaultArtboardHeight : defaultArtboardWidth;

    return {
      stageWidth,
      stageHeight,
    };
  }

  getImageElement(blobUrl) {
    return new Promise((resolve, reject) => {
      const assetFile = new Image();

      assetFile.onload = () => {
        resolve(assetFile);
      };

      assetFile.onerror = () => {
        reject('Error building image element');
      };
      assetFile.src = blobUrl;
    });
  }

  async getImageNode(image) {
    const { x, y, width, height } = image;
    const assetNode = new Konva.Image({
      x,
      y,
      width,
      height,
      rotation: image.rotation ? image.rotation : 0,
    });
    const imageElement = await this.getImageElement(image.blobUrl);

    assetNode.image(imageElement);
    // assetNode.cache();
    // assetNode.filters([ThresholdFilter]);
    // assetNode.threshold(50);

    return assetNode;
  }

  getLabelOffset({
    nodeHeight,
    labelWidth,
    startRadius,
    labelOrientation,
    startY,
    midY,
    endY,
  }) {
    const padding = 13;
    const orientation =
      labelOrientation === 'auto'
        ? getAutoLabelOrientation({
            startY,
            endY,
            midY,
          })
        : labelOrientation;

    let offset;

    switch (orientation) {
      case 'top':
        offset = {
          x: labelWidth / 2,
          y: padding + startRadius + nodeHeight,
        };
        break;
      case 'bottom':
        offset = {
          x: labelWidth / 2,
          y: -1 * (padding + startRadius),
        };
        break;
      case 'left':
        offset = {
          x: padding + startRadius + labelWidth,
          y: nodeHeight / 2,
        };
        break;
      case 'right':
        offset = {
          x: -1 * (padding + startRadius),
          y: nodeHeight / 2,
        };
        break;
    }
    return offset;
  }

  getMarkerLabelNodeHeight(lines) {
    let height = 20;
    switch (lines) {
      case 1:
        height = 20;
        break;
      case 2:
        height = 45;
        break;
      case 3:
        height = 70;
        break;
      case 4:
        height = 95;
        break;
      case 5:
        height = 120;
        break;
      case 6:
        height = 145;
        break;
    }
    return height;
  }

  getMarkerNode(marker) {
    const {
      pointStyle,
      curve,
      startX,
      startY,
      midX,
      midY,
      endX,
      endY,
      labelOrientation,
      ordinal,
    } = marker;

    const label = ordinal;
    const hasArrow = pointStyle === 'arrow';
    const arrowWidth = 15;
    const arrowLength = 20;
    const isCurved = curve !== 'linear';
    const tension = 0.5;
    const hasLabel = label ? true : false;
    const labelWidth = 240;
    const startRadius = 0;
    const fontFamily = 'Inter';
    const fontSize = 20;

    const markerNode = new Konva.Group({
      id: marker.id,
    });

    const arrowNode = new Konva.Arrow({
      points: [startX, startY, midX, midY, endX, endY],
      pointerWidth: hasArrow ? arrowWidth : 0,
      pointerLength: hasArrow ? arrowLength : 0,
      bezier: isCurved ? true : false,
      tension: isCurved ? tension : 0,
      fill: '#000000',
      stroke: '#000000',
      strokeWidth: 2,
    });

    const labelNode = new Konva.Group({
      visible: hasLabel,
      x: startX,
      y: startY,
    });

    const labelTextNode = new Konva.Text({
      width: labelWidth,
      ellipsis: true,
      text: label,
      align: 'center',
      fontSize: fontSize,
      lineHeight: 1.235,
      fontFamily: fontFamily,
      fill: '#000000',
      padding: 0,
      listening: false,
    });

    const nodeHeight = this.getMarkerLabelNodeHeight(
      labelTextNode.textArr.length
    );

    labelNode.offset(
      this.getLabelOffset({
        nodeHeight,
        labelWidth,
        startRadius,
        labelOrientation,
        startY,
        midY,
        endY,
      })
    );
    labelNode.add(labelTextNode);

    markerNode.add(arrowNode);
    markerNode.add(labelNode);

    return markerNode;
  }

  getMarkerOrdinal(marker) {
    const state = this.redux.getState();
    const elementOrdinal =
      marker.element &&
      marker.elementVersion &&
      getElementOrdinal(state, marker.element, marker.elementVersion);
    const termOrdinal = marker.term && getTermOrdinal(state, marker.term);
    const ordinal = marker.type === 'element' ? elementOrdinal : termOrdinal;
    return ordinal;
  }

  async getCanvasBlob(data) {
    const {
      stageWidth,
      stageHeight,
      scale,
      boundsX,
      boundsY,
      artboardWidth,
      artboardHeight,
      images,
      highlights,
      markers,
    } = data;
    const container = document.createElement('div');

    const stage = new Konva.Stage({
      container,
      x: stageWidth / 2,
      y: stageHeight / 2,
      width: stageWidth,
      height: stageHeight,
      scaleX: scale,
      scaleY: scale,
      offset: {
        x: boundsX,
        y: boundsY,
      },
    });

    const layer = new Konva.Layer({ name: 'images' });

    stage.add(layer);

    const group = new Konva.Group({
      x: stageWidth / 2,
      y: stageHeight / 2,
      offset: {
        x: stageWidth / 2,
        y: stageHeight / 2,
      },
    });

    layer.add(group);

    const artboardNode = new Konva.Rect({
      name: 'artboard',
      x: 0,
      y: 0,
      fill: '#FFFFFF',
      width: artboardWidth,
      height: artboardHeight,
      offset: {
        x: artboardWidth / 2,
        y: artboardHeight / 2,
      },
    });

    group.add(artboardNode);

    const imageNodes = await Promise.all(
      images.map(async (image) => {
        return await this.getImageNode(image);
      })
    );
    imageNodes.forEach((imageNode) => group.add(imageNode));

    const highlightNodes = highlights.map((highlight) => {
      return this.getHighlightNode(highlight, scale);
    });
    highlightNodes.forEach((highlightNode) => group.add(highlightNode));

    const markerNodes = markers.map((marker) => {
      return this.getMarkerNode(marker);
    });
    markerNodes.forEach((markerNode) => group.add(markerNode));

    // const circleNode = new Konva.Circle({
    //   name: 'circle',
    //   x: boundsX,
    //   y: boundsY,
    //   fill: '#FF0000',
    //   radius: 20,
    // });
    // group.add(circleNode);

    const canvas = stage._toKonvaCanvas()._canvas;
    const blob = await getCanvasBlob(canvas);

    stage.destroy();

    return blob;
  }

  @enqueueTask({ maxConcurrency: 6 })
  *getFigureBlob(figureId) {
    const state = this.redux.getState();

    const scale = 1;
    const boundsX = 0;
    const boundsY = 0;

    const figure = getFigure(state, figureId);
    const imagesList = [...figure.imagesList];
    const markersList = [...figure.markersList];
    const reverseImagesList = reverse(imagesList);
    const orientation = figure.orientation;

    const { stageWidth, stageHeight } = this.getStageDimensions(orientation);
    const { artboardWidth, artboardHeight } =
      this.getArtboardDimensions(orientation);

    const markers = markersList.map((markerId) => {
      const marker = getMarker(state, markerId);
      const ordinal = this.getMarkerOrdinal(marker);
      return {
        ...marker,
        ordinal,
      };
    });
    const highlights = [];
    const images = [];

    for (let i = 0; i < reverseImagesList.length; i++) {
      const imageId = reverseImagesList[i];
      const image = getImage(state, imageId);

      if (image.asset) {
        const blob = yield this.imageFiltersCache.getBlob.perform(imageId);
        const blobUrl = yield this.imageFiltersCache.getBlobUrl.perform(imageId);
  
        images.push({
          ...image,
          blob,
          blobUrl,
        });
      }
    }

    const data = {
      stageWidth,
      stageHeight,
      scale,
      artboardWidth,
      artboardHeight,
      boundsX,
      boundsY,
      images,
      highlights,
      markers,
    };

    const canOffscreenCanvas = typeof OffscreenCanvas !== 'undefined';

    let blob;

    if (canOffscreenCanvas) {
      
      this.workerIndex++;
      
      if (this.workerIndex >= this.maxWorkers) {
        this.workerIndex = 0;
      }

      let worker;

      if (this[`workerInstance${this.workerIndex}`]) {
        worker = this[`workerInstance${this.workerIndex}`];
      } else {
        worker = yield this.worker.open('konva-reference-image');
        this[`workerInstance${this.workerIndex}`] = worker;
      }

      blob = yield worker.postMessage(data);

      if (this.isTest) {
        worker.terminate();
        this[`workerInstance${this.workerIndex}`] = null;
      }
    } else {      
      blob = yield this.getCanvasBlob(data);
    }

    return blob;
  }

  async cacheUrls(figureId) {
    const state = this.redux.getState();
    const figure = getFigure(state, figureId);
    const numberingIndex = getNumberingIndex(state);
    const updatedAt = figure.updatedAt || new Date();
    const blob = await this.getFigureBlob.perform(figureId);

    // this.dataUrlCache[referenceId] = {
    //   dataUrl,
    //   updatedAt,
    // };

    const blobUrl = URL.createObjectURL(blob);

    this.blobUrlCache[figureId] = {
      blobUrl,
      updatedAt,
      numberingIndex,
    };
  }

  @task
  *getBlobUrl(figureId) {
    const state = this.redux.getState();
    const figure = getFigure(state, figureId);
    const numberingIndex = getNumberingIndex(state);
    const updatedAt = figure.updatedAt;

    let blobUrl;

    if (
      this.blobUrlCache[figureId] &&
      moment(updatedAt).isSame(this.blobUrlCache[figureId].updatedAt)
      && numberingIndex === this.blobUrlCache[figureId].numberingIndex
    ) {
      blobUrl = this.blobUrlCache[figureId].blobUrl;
    } else {
      try {
        const blob = yield this.getFigureBlob.perform(figureId);
        blobUrl = URL.createObjectURL(blob);
        this.blobUrlCache[figureId] = {
          blobUrl,
          updatedAt,
          numberingIndex
        };
      } catch (err) {
        console.error(err);
      }
    }

    return blobUrl;
  }

  async getDataUrl(figureId) {
    const state = this.redux.getState();
    const figure = getFigure(state, figureId);
    const updatedAt = figure.updatedAt;

    let dataUrl;

    if (
      this.dataUrlCache[figureId] &&
      moment(updatedAt).isSame(this.dataUrlCache[figureId].updatedAt)
    ) {
      dataUrl = this.dataUrlCache[figureId].dataUrl;
    } else {
      try {
        const blob = await this.getFigureBlob.perform(figureId);
        const blobUrl = URL.createObjectURL(blob);
        const { artboardWidth, artboardHeight } = this.getArtboardDimensions(
          figure.orientation
        );

        dataUrl = await blobUrlToBase64JPEG(
          blobUrl,
          artboardWidth,
          artboardHeight
        );

        this.dataUrlCache[figureId] = {
          dataUrl,
          updatedAt,
        };
      } catch (err) {
        console.error(err);
      }
    }
    return dataUrl;
  }
}
