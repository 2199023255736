import Component from '@glimmer/component';
import { action } from '@ember/object';
import { connect } from 'ember-redux';
import { htmlSafe } from '@ember/string';
import podNames from 'ember-component-css/pod-names';

const dispatchToActions = {};

const stateToComputed = null;

class ElementVersionListItemTwo extends Component {
  // args:
  // depth
  // elementVersionId
  // name
  // showMenu
  // isNested
  // isPreferred
  // showPreferredButton
  // isCollapsible

  showCheck = true;

  depthPaddingWidth = 10;

  constructor() {
    super(...arguments); // super must be called first.
    this.depth = this.args.depth || 1;
    this.showMenu = this.args.showMenu || false;
    this.isNested = this.args.isNested || false;
    this.showPreferredButton = this.args.showPreferredButton || false;
    this.isPreferred = this.args.isPreferred || false;
    this.isCollapsible = this.args.isCollapsible;
  }

  get classNames() {
    let classNames = [this.styleNamespace];
    if (this.isNested) classNames.push('is-nested');
    if (this.args.isSelected) classNames.push('is-selected');
    if (this.args.isSelectedDescendant) classNames.push('is-selected-descendant');
    if (this.args.isCollapsed) classNames.push('is-collapsed');
    return classNames.join(' ');
  }

  get styleNamespace() {
    return podNames['element-version-list-item-two'];
  }

  get depthPadding() {
    return (this.depth - 1) * this.depthPaddingWidth;
  }

  get depthStyle() {
    return htmlSafe(`margin-left: ${this.depthPadding}px`);
  }

  get hasChildren() {
    return this.args.children && this.args.children.length;
  }

  get showCaret() {
    return this.isCollapsible && this.hasChildren;
  }

  @action
  onClick() {
    if (this.args.onClick) {
      this.args.onClick(this.args.elementVersionId, this.args.isSelected);
    }
  }

  @action
  onDoubleClick() {
    if (this.args.onDoubleClick) {
      this.args.onDoubleClick(this.args.elementVersionId);
    }
  }

  @action
  onCollapseClick() {
    if (this.args.onCollapseClick) {
      this.args.onCollapseClick(this.args.elementVersionId);
    }
  }

  @action
  onRemove() {
    if (this.args.onRemove) {
      this.args.onRemove(this.args.elementVersionId);
    }
  }

  @action
  onRemoveAll() {
    if (this.args.onRemoveAll) {
      this.args.onRemoveAll(this.args.elementVersionId);
    }
  }

  @action
  onPreferredClick() {
    if (this.args.onPreferredClick) {
      this.args.onPreferredClick(this.args.elementVersionId, this.args.isPreferred)
    }
  }
}

export default connect(
  stateToComputed,
  dispatchToActions
)(ElementVersionListItemTwo);
