import Component from '@glimmer/component';
import podNames from 'ember-component-css/pod-names';

class MilestoneStatus extends Component {
  get classNames() {
    let classNames = [this.styleNamespace, 'milestone-status'];
    if (this.args.milestoneTypeId) classNames.push(`is-${this.args.milestoneTypeId}`);
    if (this.args.isLocked) classNames.push('is-locked');
    if (this.args.isNext) classNames.push('is-next');
    if (this.args.isChecked) classNames.push('is-checked');
    if (this.args.isReviewed) classNames.push('is-reviewed');
    if (this.args.hasBorder) classNames.push('has-border');
    return classNames.join(' ');
  }

  get styleNamespace() {
    return podNames['milestone-status'];
  }


}

export default MilestoneStatus;
