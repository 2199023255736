export default {
  attributes: {
    id: {
      type: 'uuid',
      defaultValue: null
    },
    drawing: {
      type: 'uuid',
      defaultValue: null
    },
    name: {
      type: 'string',
      defaultValue: ''
    },
    width: {
      type: 'number',
      defaultValue: 0
    },
    height: {
      type: 'number',
      defaultValue: 0
    },
    x: {
      type: 'number',
      defaultValue: 0
    },
    y: {
      type: 'number',
      defaultValue: 0
    },
    asset: {
      type: 'uuid',
      defaultValue: null
    },
    filters: {
      type: 'boolean',
      defaultValue: false
    },
    grayscale: {
      type: 'boolean',
      defaultValue: true
    },
    threshold: {
      type: 'boolean',
      defaultValue: false
    },
    thresholdValue: {
      type: 'number',
      defaultValue: 100
    },
    createdAt: {
      type: 'date'
    }
  }
}
