import Component from '@glimmer/component';
import podNames from 'ember-component-css/pod-names';

class InventionPreloadProgress extends Component {
  constructor() {
    super(...arguments);
  }

  get classNames() {
    let classNames = [this.styleNamespace];
    return classNames.join(' ');
  }

  get styleNamespace() {
    return podNames['invention-preload-progress'];
  }

}

export default InventionPreloadProgress;
