import {
  ADD_COMPARISON,
  UPDATE_COMPARISON,
  REMOVE_COMPARISON,
  SORT_COMPARISONS_LIST,
} from '../actions/actionTypes';
import { addFromSchema } from '../utils/schema';
import { omit } from 'lodash';

const initialState = {
  ids: [],
  entities: {},
};

const comparisonsReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case ADD_COMPARISON: {
      let { comparisonId, attributes } = payload;
      const id = comparisonId;
      const createdAt = new Date();
      const updatedAt = new Date();
      const comparison = addFromSchema('comparison', {
        ...attributes,
        id,
        createdAt,
        updatedAt,
      });

      return {
        ids: [...state.ids, id],
        entities: {
          ...state.entities,
          [comparisonId]: comparison,
        },
      };
    }

    case UPDATE_COMPARISON: {
      const { comparisonId, attributes } = payload;
      const updatedAt = new Date();
      return {
        ...state,
        entities: {
          ...state.entities,
          [comparisonId]: {
            ...state.entities[comparisonId],
            ...attributes,
            updatedAt,
          },
        },
      };
    }

    case REMOVE_COMPARISON: {
      const { comparisonId } = payload;
      return {
        ids: state.ids.filter((id) => id !== comparisonId),
        entities: omit(state.entities, comparisonId),
      };
    }

    case SORT_COMPARISONS_LIST: {
      const { comparisonIds } = payload;
      return {
        ...state,
        ids: comparisonIds,
      };
    }

    /* istanbul ignore next */
    default:
      return state;
  }
};

export default comparisonsReducer;
