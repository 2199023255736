import Service from '@ember/service';
import { computed } from '@ember/object';
import { getSelectionHtml, getSelectionRect } from '../utils/selection';
import $ from 'jquery';

export default Service.extend({
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  width: 0,
  height: 0,
  boundingRect: null,
  contentMentionable: null,
  contentMentionableExtentsion: null,
  selectedHtml: '',
  selectedText: '',

  popperTarget: computed('selectedText', 'boundingRect', function() {
    return this.selectedText ? this.boundingRect : null;
  }),

  updateSelection() {
    const selectedHtml = getSelectionHtml();
    const selectionRect = getSelectionRect();
    const { top, left, right, bottom, width, height } = selectionRect;
    const boundingRect = {
      id: 'selection-bounding-rect',
      getBoundingClientRect() {
        return selectionRect;
      }
    };
    const selectedText = $(`<div>${selectedHtml}</div>`)
      .text()
      .trim();

    this.setProperties({
      top,
      left,
      right,
      bottom,
      width,
      height,
      selectedHtml,
      selectedText,
      boundingRect
    });
  }
});
