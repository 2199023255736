import { action, computed } from '@ember/object';
import {
  getIsPreferred,
  getPreferredElementVersionId,
  getPreferredElementVersionsList,
} from '../../../selectors/product';

import Component from '@glimmer/component';
import { connect } from 'ember-redux';
import { getActiveElementVersionListItem } from '../../../selectors/invention-ui';
import podNames from 'ember-component-css/pod-names';
import { selectElementVersion } from '../../../actions/invention-ui';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { updateElement } from '../../../actions/element';
import uuid from 'uuid/v4';

const dispatchToActions = {};

const stateToComputed = (state, attrs) => ({
  preferredElementVersionsList: getPreferredElementVersionsList(
    state,
    attrs.productId
  ),
  activeElementVersionListItem: getActiveElementVersionListItem(state)
});

class ElementVersionItemsList extends Component {
  @service data;
  @service tracking;
  @tracked switched = false;
  uniqueId = uuid();

  get styleNamespace() {
    return podNames['element-version-items-list'];
  }

  get classNames() {
    let classNames = [this.styleNamespace, 'element-version-items-list'];
    return classNames.join(' ');
  }

  get domElementId() {
    return `element-version-list-${this.args.elementId}-${this.uniqueId}`;
  }

  // eslint-disable-next-line ember/require-computed-property-dependencies
  @computed('args.productId', 'elementId')
  get hasPreferred() {
    const state = this.redux.getState();
    return getPreferredElementVersionId(
      state,
      this.args.elementId,
      this.args.productId
    )
      ? true
      : false;
  }

  @action
  setPreferredElementVersion(elementVersionId) {
    if (this.args.onSetPreferredElementVersion) {
      let state = this.redux.getState();
      let elementId = this.args.elementId;
      const isPreferred = getIsPreferred(
        state,
        elementId,
        elementVersionId,
        this.args.productId
      );

      this.tracking.trackEvent('system_context_set_preferred_solution')

      if (isPreferred) {
        this.args.onSetPreferredElementVersion(
          elementId,
          null,
          this.args.productId
        );
      } else {
        this.args.onSetPreferredElementVersion(
          elementId,
          elementVersionId,
          this.args.productId
        );
      }
      
    }
  }

  @action
  select(elementVersionId) {
    this.redux.store.dispatch(selectElementVersion(elementVersionId));
  }

  @action
  selectListItem(elementVersionId, isSelected) {
    elementVersionId = isSelected ? null : elementVersionId;
    this.data.setActiveElementVersionListItem(elementVersionId)
  }

  @action
  sortList() {
    const nodelist = document.querySelectorAll(
      `#${this.domElementId} .element-version-list-item`
    );
    const elementVersions = Array.from(nodelist);
    const elementVersionsList = elementVersions.map(
      (elementVersion) => elementVersion.dataset.id
    );

    if (this.args.instanceOfId) {
      this.redux.store.dispatch(
        updateElement(this.args.instanceOfId, { elementVersionsList })
      );
    } else {
      this.redux.store.dispatch(
        updateElement(this.args.elementId, { elementVersionsList })
      );
    }
  }

  @action
  toggleSwitched() {
    this.switched = !this.switched;
  }
}
export default connect(
  stateToComputed,
  dispatchToActions
)(ElementVersionItemsList);
