import { action, computed } from '@ember/object';
import { getMilestoneType, getMilestoneTypes } from '../../../selectors/type';

import Component from '@glimmer/component';
import { alias } from '@ember/object/computed';
import { connect } from 'ember-redux';
import { getActiveProductChecklistView } from '../../../selectors/invention-ui';
import { getProduct } from '../../../selectors/product';
import podNames from 'ember-component-css/pod-names';
import { tracked } from '@glimmer/tracking';

const dispatchToActions = {};

const stateToComputed = (state, attrs) => ({
  activeProductChecklistView: getActiveProductChecklistView(state),
  product: getProduct(state, attrs.productId),
});

class MilestonesContext extends Component {
  @tracked switched;

  @alias('product.assertedMilestonesList')
  assertedMilestonesList;

  @alias('product.reviewedMilestonesList')
  reviewedMilestonesList;

  milestonesList = getMilestoneTypes();

  @computed('product.name', 'milestonesList')
  get milestones() {
    return this.milestonesList.map((milestoneId) => {
      let milestone = getMilestoneType(milestoneId);
      if (milestone.id === 'product') {
        milestone = {
          ...milestone,
          subtitle: this.product && this.product.name ? this.product.name : '',
        };
      }
      return milestone;
    });
  }

  get classNames() {
    let classNames = [this.styleNamespace, 'milestones-context'];
    return classNames.join(' ');
  }

  get styleNamespace() {
    return podNames['milestones-context'];
  }

  get onDeckMilestone() {
    const assertedMilestonesList = this.assertedMilestonesList.filter(
      (milestoneId) => this.milestonesList.includes(milestoneId)
    );
    return this.milestones[assertedMilestonesList.length];
  }

  get nextMilestoneId() {
    const milestonesList = getMilestoneTypes();
    const activeIndex = milestonesList.indexOf(this.activeProductChecklistView);
    return activeIndex > -1 && milestonesList[activeIndex + 1];
  }

  @action
  onAssertProductChecklist(productId, attributes, milestoneTypeId) {
    const currentChecklistIndex = this.milestones.findIndex(
      (milestone) => milestone.id === milestoneTypeId
    );
    const nextChecklistId =
      this.milestones[currentChecklistIndex + 1] &&
      this.milestones[currentChecklistIndex + 1].id;
    this.args.onAssertProductChecklist(productId, attributes, nextChecklistId);
  }
}

export default connect(stateToComputed, dispatchToActions)(MilestonesContext);
