import { bind, once } from '@ember/runloop';
import { bool, equal } from '@ember/object/computed';

import $ from 'jquery';
import Component from '@ember/component';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';

const UiTextInput = Component.extend({
  tracking: service(),
  applicationState: service(),
  classNameBindings: [
    'isFocused',
    'hasTopMargin',
    'hasBottomMargin',
    'disabled',
    'isDisabled',
    'hideBar',
    'isSmall',
    'isMedium',
    'isLarge',
    'isInput',
    'isContenteditable',
    'isDate',
    'isTextarea',
    'hasLeftLabel',
    'hasBorder',
    'isFullHeight',
    'isIndented',
  ],
  value: '',
  name: '',
  placeholder: '',
  label: '',
  instructions: '',
  isFullHeight: false,
  hasBorder: false,
  isFocused: false,
  hideBar: false,
  disabled: false,
  autoFocus: false,
  selectAll: false,
  inputType: 'text',
  disableReturn: false,
  leftLabel: '',
  type: 'input',
  ariaLabel: '',
  ariaRequired: 'true',
  step: 1,
  ariaDisabled: computed('disabled', function () {
    return String(this.disabled);
  }),
  hasTopMargin: false,
  hasBottomMargin: true,
  size: 'normal', // small, medium, large
  isInput: equal('type', 'input'),
  isTextarea: equal('type', 'textarea'),
  isContentMentionable: equal('type', 'content-mentionable'),
  isContenteditable: equal('type', 'contenteditable'),
  isDate: equal('type', 'date'),
  isLarge: equal('size', 'large'),
  isMedium: equal('size', 'medium'),
  isSmall: equal('size', 'small'),
  hasLeftLabel: bool('leftLabel'),

  didInsertElement() {
    this._super(...arguments);
    this.set('ready', true);
    if (this.disableReturn) {
      $(`#${this.elementId} .textarea`).on(
        'keypress',
        bind(this, this.disableReturnKey)
      );
    }
  },

  willDestroyElement() {
    this._super(...arguments);
    $(`#${this.elementId} .textarea`).off(
      'keypress',
      bind(this, this.disableReturnKey)
    );

    once(this, 'updateFocusedDomElement');
  },

  disableReturnKey(e) {
    if (e.keyCode === 13) {
      e.preventDefault();
      this.onEnter(e);
    }
  },

  toggleFocus() {
    this.toggleProperty('isFocused');
  },

  updateFocusedDomElement() {
    if (document.activeElement && document.activeElement.dataset.modelId) {
      this.applicationState.set(
        'focusedInputModelId',
        document.activeElement.dataset.modelId
      );
      this.applicationState.set(
        'focusedInputModelType',
        document.activeElement.dataset.modelType
      );
      this.applicationState.set(
        'focusedInputModelAttr',
        document.activeElement.dataset.modelAttr
      );
      this.applicationState.set(
        'focusedInputFeatureType',
        document.activeElement.dataset.featureType
      );
    } else {
      this.applicationState.set('focusedInputModelId', null);
      this.applicationState.set('focusedInputModelType', null);
      this.applicationState.set('focusedInputModelAttr', null);
      this.applicationState.set('focusedInputFeatureType', null);
    }
  },

  actions: {
    onFocus(val) {
      // this.toggleProperty('isFocused');
      // if (!this.isFocused) {
      //   this.set('isFocused', true);
      // }
      once(this, 'updateFocusedDomElement');
      if (this.onFocus) {
        this.onFocus(val);
      }
    },
    onEnter(val) {
      if (this.onEnter) {
        this.onEnter(val);
      }
    },
    onBlur(val) {
      once(this, 'updateFocusedDomElement');
      if (this.onBlur) {
        this.onBlur(val);
      }
    },
    onInput(event) {
      if (this.onInput) {
        this.onInput(event.target.value);
      }
    },
    onChange(val) {
      if (this.onChange) {
        this.onChange(val);
      }
    },
    onKeyup(e) {
      e.stopPropagation();

      if (this.onChange) {
        this.onChange(e.target.value);
      }
    },
    onTributeElement(value) {
      if (this.onTributeElement) {
        this.onTributeElement(value);
        this.tracking.trackEvent('mentionable_referenced_tribute');
      }
    },
  },
});

export default UiTextInput;
