/* eslint-disable no-useless-catch */
import Service from '@ember/service';
import localforage from 'localforage';
import ENV from '../config/environment';

const dbPrefix = ENV.DB_PREFIX || '';
const dbName =
  ENV.environment === 'test' ? 'patentdive_test' : `${dbPrefix}_assets`;

const getDataUrl = (blob) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => resolve(reader.result);
  });
};

export default Service.extend({
  blobCache: null,
  blobUrlCache: null,
  dataUrlCache: null,

  init() {
    this._super(...arguments);
    this.store = localforage.createInstance({
      name: dbName,
    });
    this.set('blobCache', {});
    this.set('blobUrlCache', {});
    this.set('dataUrlCache', {});
  },

  async addAsset(id, blob) {
    let dataUrl = await getDataUrl(blob);
    // dataUrl = dataUrl.replace('application/octet-stream', 'image/png');
    const asset = {
      blob,
      dataUrl
    };
    await this.store.setItem(id, asset);
  },

  async getAsset(id, type = 'blob') {
    const asset = await this.store.getItem(id);
    return type === 'blob' ? asset.blob : asset.dataUrl;
  },

  async getAssetWithKey(id) {
    const asset = await this.store.getItem(id);
    return {
      id,
      blob: asset.blob,
      // dataUrl: asset.dataUrl,
    };
  },

  async cacheUrls(assetId) {
    this.setProperties({
      blobCache: {},
      blobUrlCache: {},
      dataUrlCache: {}
    });
    const stored = await this.store.getItem(assetId);
    const blobUrl = window.URL.createObjectURL(stored.blob);
    const dataUrl = stored.dataUrl;
    this.blobCache[assetId] = stored.blob;
    this.blobUrlCache[assetId] = blobUrl;
    this.dataUrlCache[assetId] = dataUrl;
  },

  async getAssetBlob(assetId) {
    let asset;
    if (this.blobCache[assetId]) {
      asset = this.blobCache[assetId];
    } else {
      try {
        const stored = await this.store.getItem(assetId);
        asset = stored.blob;
        this.blobCache[assetId] = asset;
      } catch (err) {
        throw err;
      }
    }
    return asset;
  },

  async getAssetUrl(assetId) {
    let asset;
    if (this.blobUrlCache[assetId]) {
      asset = this.blobUrlCache[assetId];
    } else {
      try {
        const stored = await this.store.getItem(assetId);
        asset = window.URL.createObjectURL(stored.blob);
        this.blobUrlCache[assetId] = asset;
      } catch (err) {
        throw err;
      }
    }
    return asset;
  },

  async getAssetDataUrl(assetId) {
    let dataUrl;
    if (this.dataUrlCache[assetId]) {
      dataUrl = this.dataUrlCache[assetId];
    } else {
      try {
        const asset = await this.store.getItem(assetId);
        dataUrl = asset.dataUrl;
        this.dataUrlCache[assetId] = dataUrl;
      } catch (err) {
        throw err;
      }
    }
    return dataUrl;
  },
});
