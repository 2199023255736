import Component from '@glimmer/component';
import { computed } from '@ember/object';
import { connect } from 'ember-redux';
import { getMethod } from '../../../selectors/method';
import { getElementVersion } from '../../../selectors/element-version';
import { getElement} from '../../../selectors/element';

const dispatchToActions = {};

const stateToComputed = (state, attrs) => ({
  method: getMethod(state, attrs.methodId),
  element: getElement(state, attrs.elementId),
  elementVersion: getElementVersion(state, attrs.elementVersionId),
});

class MethodElementVersionName extends Component {

  @computed('elementVersion.name', 'element.elementVersionsList.[]')
  get elementVersionName() {
    return this.elementVersion && this.element && this.element.elementVersionsList.length > 1 ? this.elementVersion.name : '';
  }

}

export default connect(stateToComputed, dispatchToActions)(MethodElementVersionName);
