import { action, computed } from '@ember/object';
import { getElementsMap, getRootNodeId } from '../../../selectors/graph';
import {
  getPreferredElementVersionId,
  getProduct,
} from '../../../selectors/product';

import Component from '@glimmer/component';
import { connect } from 'ember-redux';
import { getElement } from '../../../selectors/element';
import { getElementVersion } from '../../../selectors/element-version';
import { getFeature } from '../../../selectors/feature';
import { getInventionUi } from '../../../selectors/invention-ui';
import { getPriorArt } from '../../../selectors/prior-art';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';
// import { batchGroupBy } from '../../../utils/redux';
import { tracked } from '@glimmer/tracking';

const dispatchToActions = {};

const stateToComputed = (state, attrs) => ({
  inventionUi: getInventionUi(state),
  elementsMap: getElementsMap(state, attrs.productId),
  rootNodeId: getRootNodeId(state, attrs.productId),
  product: getProduct(state, attrs.productId),
  element: getElement(state, attrs.elementId),
  elementVersion: getElementVersion(state, attrs.elementVersionId),
});

class ComparisonElementContext extends Component {
  @service data;

  @tracked switched;
  @tracked activeFeatureType = 'requirements';

  get classNames() {
    let classNames = [this.styleNamespace, 'comparison-element-context'];
    if (this.args.showAll) classNames.push('show-all');
    return classNames.join(' ');
  }

  get styleNamespace() {
    return podNames['comparison-element-context'];
  }

  // eslint-disable-next-line ember/require-computed-property-dependencies
  @computed('elementVersionId')
  get elementVersion() {
    const state = this.redux.getState();
    return getElementVersion(state, this.elementVersionId);
  }

  // eslint-disable-next-line ember/require-computed-property-dependencies
  @computed('args.productId')
  get elementsMap() {
    const state = this.redux.getState();
    return this.args.productId
      ? getElementsMap(state, this.args.productId)
      : {};
  }

  // eslint-disable-next-line ember/require-computed-property-dependencies
  @computed('productId', 'rootNodeId', 'elementsMap')
  get nodes() {
    const state = this.redux.getState();
    const rootNode = this.elementsMap[this.rootNodeId];
    const descendants = rootNode
      ? [this.rootNodeId, ...rootNode.descendants]
      : [];
    const nodes = descendants
      .filter((id) => id !== this.rootNodeId)
      .map((id) => {
        const node = this.elementsMap[id];
        const { depth } = node;
        const element = getElement(state, id);
        const elementVersionId = getPreferredElementVersionId(
          state,
          element.id,
          this.productId
        );
        const elementVersion =
          elementVersionId && getElementVersion(state, elementVersionId);

        return {
          id,
          depth,
          element,
          elementVersion,
        };
      });

    return nodes;
  }

  // eslint-disable-next-line ember/require-computed-property-dependencies
  @computed('args.{showAll,productId}')
  get rows() {
    const rows = [];
    if (this.args.showAll) {
      this.nodes.forEach((node) =>
        this.addAllElementRows(
          rows,
          node.element,
          node.elementVersion,
          node.depth
        )
      );
    } else {
      this.addElementRows(rows, this.element, this.elementVersion);
    }

    return rows;
  }

  addAllElementRows(rows, element, elementVersion, depth) {
    const state = this.redux.getState();

    const elementRow = {};
    elementRow[this.args.productId] = element.name;
    elementRow.rowType = 'element';
    elementRow.elementId = element && element.id;
    elementRow.elementVersionId = elementVersion && elementVersion.id;
    elementRow.depth = depth;
    rows.push(elementRow);

    element.requirementsList.forEach((featureId) => {
      const row = {};
      const feature = getFeature(state, featureId);
      row[this.args.productId] = feature.value;
      row.rowType = 'feature';
      row.isRequirement = true;
      row.featureId = featureId;
      row.featureValue = feature.value;
      row.language = 'requirement_abbreviated';
      row.depth = depth;
      rows.push(row);
    });

    if (element.category === 'system') {
        const requiredEvent = getFeature(state, element.outcome);
        const requiredEventRow = {};
        requiredEventRow[this.args.productId] = requiredEvent.value;
        requiredEventRow.rowType = 'feature';
        requiredEventRow.featureId = requiredEvent.id;
        requiredEventRow.featureValue = requiredEvent.value ? `Success occurs when ${requiredEvent.value}` : null;
        requiredEventRow.language = 'report_list_abbreviated';
        requiredEventRow.depth = depth;
        rows.push(requiredEventRow);
      }

    if (elementVersion) {
      elementVersion.constraintsList.forEach((featureId) => {
        const row = {};
        const feature = getFeature(state, featureId);
        row[this.args.productId] = feature.value;
        row.rowType = 'feature';
        row.isRequirement = false;
        row.featureId = featureId;
        row.featureValue = feature.value;
        row.language = 'requirement_abbreviated';
        row.depth = depth;

        rows.push(row);
      });

      elementVersion.featuresList.forEach((featureId) => {
        const row = {};
        const feature = getFeature(state, featureId);
        row[this.args.productId] = feature.value;
        row.rowType = 'feature';
        row.isRequirement = false;
        row.featureId = featureId;
        row.featureValue = feature.value;
        row.language = 'report_list_abbreviated';
        row.depth = depth;

        rows.push(row);
      });

      elementVersion.definitionsList.forEach((featureId) => {
        const row = {};
        const feature = getFeature(state, featureId);
        row[this.args.productId] = feature.value;
        row.rowType = 'feature';
        row.isRequirement = false;
        row.featureId = featureId;
        row.featureValue = feature.value;
        row.language = 'report_list_abbreviated';
        row.depth = depth;

        rows.push(row);
      });
    }
  }

  addElementRows(rows, element, elementVersion) {
    const state = this.redux.getState();

    const functionalRequirementsRow = {};
    functionalRequirementsRow[this.args.productId] = 'Required Functions';
    functionalRequirementsRow.rowType = 'feature-type-header';
    functionalRequirementsRow.title = 'Required Functions';
    rows.push(functionalRequirementsRow);

    element.requirementsList.forEach((featureId) => {
      const row = {};
      const feature = getFeature(state, featureId);
      row[this.args.productId] = feature.value;
      row.rowType = 'feature';
      row.featureId = featureId;
      row.featureValue = feature.value;
      row.language = 'requirement_abbreviated';
      rows.push(row);
    });

    if (!element.requirementsList.length) {
      const missingRow = {};
      missingRow[this.args.productId] = 'No functional requirements';
      missingRow.rowType = 'missing-feature';
      missingRow.title = 'No functional requirements';
      rows.push(missingRow);
    }

    if (elementVersion) {
      if (element.category === 'system') {
        const requiredEventHeaderRow = {};
        requiredEventHeaderRow[this.args.productId] = 'Required Event';
        requiredEventHeaderRow.rowType = 'feature-type-header';
        requiredEventHeaderRow.title = 'Required Event';
        rows.push(requiredEventHeaderRow);

        const requiredEvent = getFeature(state, element.outcome);
        const requiredEventRow = {};
        requiredEventRow[this.args.productId] = requiredEvent.value;
        requiredEventRow.rowType = 'feature';
        requiredEventRow.featureId = requiredEvent.id;
        requiredEventRow.featureValue = requiredEvent.value;
        requiredEventRow.language = 'report_list_abbreviated';
        rows.push(requiredEventRow);
      }

      if (elementVersion.constraintsList.length) {
        const contraintsRow = {};
        contraintsRow[this.args.productId] = 'User Requirements';
        contraintsRow.rowType = 'feature-type-header';
        contraintsRow.title = 'User Requirements';
        rows.push(contraintsRow);

        elementVersion.constraintsList.forEach((featureId) => {
          const row = {};
          const feature = getFeature(state, featureId);
          row[this.args.productId] = feature.value;
          row.rowType = 'feature';
          row.featureId = featureId;
          row.featureValue = feature.value;
          row.language = 'requirement_abbreviated';

          rows.push(row);
        });
      }

      if (elementVersion.featuresList.length) {
        const featuresRow = {};
        featuresRow[this.args.productId] = 'Features';
        featuresRow.rowType = 'feature-type-header';
        featuresRow.title = 'Features';
        rows.push(featuresRow);

        elementVersion.featuresList.forEach((featureId) => {
          const row = {};
          const feature = getFeature(state, featureId);
          row[this.args.productId] = feature.value;
          row.rowType = 'feature';
          row.isRequirement = false;
          row.featureId = featureId;
          row.featureValue = feature.value;
          row.language = 'report_list_abbreviated';

          rows.push(row);
        });
      }

      if (elementVersion.definitionsList.length) {
        const definitionsRow = {};
        definitionsRow[this.args.productId] = 'Definitions';
        definitionsRow.rowType = 'feature-type-header';
        definitionsRow.title = 'Definitions';
        rows.push(definitionsRow);

        elementVersion.definitionsList.forEach((featureId) => {
          const row = {};
          const feature = getFeature(state, featureId);
          row[this.args.productId] = feature.value;
          row.rowType = 'feature';
          row.isRequirement = false;
          row.featureId = featureId;
          row.featureValue = feature.value;
          row.language = 'report_list_abbreviated';
  
          rows.push(row);
        });
      }

    }
  }

  // eslint-disable-next-line ember/require-computed-property-dependencies
  @computed('args.productId')
  get columns() {
    const state = this.redux.getState();
    let columns = [];
    if (this.args.productId) {
      columns = [
        {
          name: this.product.name,
          valuePath: this.args.productId,
          columnType: 'product',
          productId: this.args.productId,
          minWidth: 350,
          isFixed: 'left',
        },
      ];

      this.args.comparisons.forEach((comparison) => {
        const priorArt = getPriorArt(state, comparison.priorArt);
        columns.push({
          name: priorArt.name,
          valuePath: priorArt.id,
          columnType: 'prior-art',
          priorArtId: priorArt.id,
          comparisonId: comparison.id,
          minWidth: 130,
        });
      });
    }
    return columns;
  }

  @action
  updateComparison(featureId, comparisonId, isNovel) {
    if (this.args.onUpdateComparison) {
      this.args.onUpdateComparison(featureId, comparisonId, isNovel);
    }
  }
}

export default connect(
  stateToComputed,
  dispatchToActions
)(ComparisonElementContext);
