import Component from '@ember/component';
import { computed } from '@ember/object';
import { equal } from '@ember/object/computed';

const UiButton = Component.extend({
  classNames: ['button'],
  classNameBindings: [
    'isA',
    'isFab',
    'isText',
    'fabLabel',
    'isActive',
    'isPrimary',
    'isReview',
    'isNovel',
    'isDanger',
    'isWarning',
    'isLarge',
    'isMedium',
    'isTiny',
    'isSmall',
    'isLarge',
    'isEnlarged',
    'isFullWidth',
    'isContain',
    'isTransparent',
    'isTransparentHover',
    'isInteractive',
    'isWorkarea',
    'disabled',
    'hasCaret',
    'hasIconLeft',
    'isCreate',
    'translateOnHover',
    'isLoading'
  ],
  attributeBindings: [
    'disabled',
    'ariaLabel',
    'ariaDisabled'
  ],
  tagName: 'button',
  isFab: false,
  fabLabel : false,
  size: 'normal', // small, medium, large
  hasCaret: false,
  disabled: false,
  isFullWidth: false,
  isTransparent: false,
  isTransparentHover: false,
  isPrimary: false,
  isDanger: false,
  isFile: false,
  isActive: false,
  isInteractive: true,
  hasIconLeft: false,
  isCreate: false,
  translateOnHover: false,
  isA: equal('tagName', 'a'),
  isEnlarged: equal('size', 'enlarged'),
  isLarge: equal('size', 'large'),
  isMedium: equal('size', 'medium'),
  isSmall: equal('size', 'small'),
  isTiny: equal('size', 'tiny'),
  isContain: equal('size', 'contain'),
  ariaLabel: '',
  ariaDisabled: computed('disabled', function() {
    return String(this.disabled);
  }),
  tooltip: '',
  tooltipSide: null,
  
});

export default UiButton;
