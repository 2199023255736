import Component from '@glimmer/component';
import { connect } from 'ember-redux';
import podNames from 'ember-component-css/pod-names';

const dispatchToActions = {};

const stateToComputed = null;

class ElementVersionContextCategory extends Component {
  nameSort = ['name'];

  get classNames() {
    let classNames = ['element-version-context-category', this.styleNamespace];
    return classNames.join(' ');
  }

  get styleNamespace() {
    return podNames['element-version-context-category'];
  }
}

export default connect(
  stateToComputed,
  dispatchToActions
)(ElementVersionContextCategory);
