import {
  ADD_PRODUCT,
  REMOVE_PRODUCT,
  SORT_PRODUCTS_LIST,
  UPDATE_PRODUCT,
} from './actionTypes';

import uuid from 'uuid/v4';

export const addProduct = (attributes = {}, productId = uuid()) => {
  return {
    type: ADD_PRODUCT,
    payload: {
      productId,
      attributes,
    },
  };
};

export const removeProduct = (productId) => {
  return {
    type: REMOVE_PRODUCT,
    payload: {
      productId,
    },
  };
};

export const updateProduct = (productId, attributes = {}) => {
  return {
    type: UPDATE_PRODUCT,
    payload: {
      productId,
      attributes,
    },
  };
};

export const sortProductsList = (productIds) => {
  return {
    type: SORT_PRODUCTS_LIST,
    payload: {
      productIds,
    },
  };
};

