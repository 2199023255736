import { action, computed } from '@ember/object';

import Component from '@glimmer/component';
import { connect } from 'ember-redux';
import { getAsset } from '../../../selectors/asset';
import { htmlSafe } from '@ember/string';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

const stateToComputed = (state, attrs) => ({
  asset: getAsset(state, attrs.assetId),
});

const dispatchToActions = {};

class AssetListItem extends Component {
  @service assets;

  @tracked blobUrl;

  get styleNamespace() {
    return podNames['asset-list-item'];
  }

  get classNames() {
    let classNames = [this.styleNamespace, 'asset-list-item'];
    if (this.args.isSelected) classNames.push('is-selected');
    if (this.args.isInUse) classNames.push('is-in-use');
    return classNames.join(' ');
  }

  @computed('asset.name')
  get name() {
    return this.asset && this.asset.name; 
  }

  @computed('blobUrl')
  get backgroundImageStyle() {
    return htmlSafe(`background-image: url(${this.blobUrl})`); 
  }

  @action
  onClick() {
    if (this.args.onClick) this.args.onClick(this.args.assetId);
  }

  @action
  async setBlobUrl() {
    const blobUrl = this.asset && await this.assets.getAssetUrl(this.args.assetId);
    this.blobUrl = blobUrl;
  }

}

export default connect(stateToComputed, dispatchToActions)(AssetListItem);
