import { getPresentState } from '../utils/redux';
import { get } from '@ember/object';

export const getNote = (state, noteId) =>
  get(getPresentState(state), `notes.entities.${noteId}`);
  
export const getNotes = (state) =>
  get(getPresentState(state), `notes.entities`);

export const getNotesList = (state) => get(getPresentState(state), `notes.ids`);
