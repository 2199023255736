import { action, computed } from '@ember/object';

import Component from '@glimmer/component';
import { connect } from 'ember-redux';
import { getDeletedReferencesList } from '../../../selectors/deleted-reference';
import { getFeature } from '../../../selectors/feature';
import { getTerm } from '../../../selectors/term';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';
import uuid from 'uuid/v4';

const stateToComputed = (state, attrs) => ({
  deletedReferencesList: getDeletedReferencesList(state),
  term: getTerm(state, attrs.termId),
});

const dispatchToActions = {};

class ElementPopover extends Component {
  @service() redux;

  language = 'report_list_abbreviated';

  constructor() {
    super(...arguments);
    this.popoverDomElementId = this.args.popoverDomElementId;
    this.domElementId = uuid();
  }

  get styleNamespace() {
    return podNames['term-popover'];
  }

  get classNames() {
    let classNames = [this.styleNamespace, 'term-popover'];
    if (this.isDeleted) classNames.push('is-deleted');
    return classNames.join(' ');
  }

  @computed('args.termId', 'deletedReferencesList.[]')
  get isDeleted() {
    return (
      this.deletedReferencesList &&
      this.deletedReferencesList.includes(this.args.termId)
    );
  }

  @computed('term.markersList')
  get referencesList() {
    return this.term && this.term.markersList;
  }

  @computed('referencesList.[]')
  get firstReference() {
    return this.referencesList[0];
  }

  // eslint-disable-next-line ember/require-computed-property-dependencies
  @computed('term.definitionsList.[]')
  get definitionsList() {
    const state = this.redux.getState();
    return this.term && this.term.definitionsList.filter(featureId => {
      const feature = getFeature(state, featureId);
      return feature && feature.type === 'definition';
    });
  }

  @action
  didInsert() {
    const domElement = document.getElementById(this.domElementId);
    this._handleMouseenter = this.handleMouseenter.bind(this);
    this._handleMouseleave = this.handleMouseleave.bind(this);
    domElement.addEventListener('mouseenter', this._handleMouseenter, false);
    domElement.addEventListener('mouseleave', this._handleMouseleave, false);
  }

  willDestroy() {
    super.willDestroy(...arguments);
    const domElement = document.getElementById(this.domElementId);
    domElement &&
      domElement.removeEventListener(
        'mouseenter',
        this._handleMouseenter,
        false
      );
    domElement &&
      domElement.removeEventListener(
        'mouseleave',
        this._handleMouseleave,
        false
      );
  }

  handleMouseenter() {
    this.args.onMouseenter &&
      this.args.onMouseenter(
        this.args.elementId,
        this.args.elementVersionId,
        this.popoverDomElementId
      );
  }

  handleMouseleave() {
    this.args.onMouseleave && this.args.onMouseleave();
  }
}

export default connect(stateToComputed, dispatchToActions)(ElementPopover);
