import Component from '@glimmer/component';
import { action } from '@ember/object';
import { connect } from 'ember-redux';
import podNames from 'ember-component-css/pod-names';
import { getAsset } from '../../../selectors/asset';
import uuid from 'uuid/v4';

const stateToComputed = (state, attrs) => ({
  asset: getAsset(state, attrs.assetId),
});

const dispatchToActions = {};

class AssetUpload extends Component {
  domElementId = `asset-upload-${uuid()}`;
  get styleNamespace() {
    return podNames['asset-upload'];
  }

  @action
  onAddFiles(dropped) {
    const file = dropped.files ? dropped.files[0] : dropped.blob;
    this.args.onFileUpload(file);
  }

  @action
  getExternalImage(url, file) {
    this.args.onFileUpload(file, url);
  }
}

export default connect(stateToComputed, dispatchToActions)(AssetUpload);
