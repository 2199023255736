import Component from '@glimmer/component';
import { computed } from '@ember/object';
import { connect } from 'ember-redux';
import { getMethod } from '../../../selectors/method';
import { getMethodRealization } from '../../../utils/method';
import { inject as service } from '@ember/service';

const dispatchToActions = {};

const stateToComputed = (state, attrs) => ({
  method: getMethod(state, attrs.methodId),
});

class MethodRealization extends Component {
  @service store;

  @computed('args.methodId')
  get methodModel() {
    return this.store.peekRecord('method', this.args.methodId);
  }

  // eslint-disable-next-line ember/require-computed-property-dependencies
  @computed(
    'methodModel.{groupedStepsList.[],traversedNodesList.[]}',
    'args.methodId'
  )
  get realization() {
    const state = this.redux.getState();
    const realization = getMethodRealization(
      state,
      this.args.methodId,
      this.methodModel.groupedStepsList,
      this.methodModel.traversedNodesList,
      this.methodModel.traversedEdgesList
    );
    return realization;
  }
}

export default connect(stateToComputed, dispatchToActions)(MethodRealization);
