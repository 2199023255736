import { UPDATE_SETTINGS } from '../actions/actionTypes';
import { addFromSchema } from '../utils/schema';

const initialState = addFromSchema('settings');

const settingsReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case UPDATE_SETTINGS: {
      const { attributes } = payload;
      return {
        ...state,
        ...attributes,
      };
    }
    /* istanbul ignore next */
    default:
      return state;
  }
};

export default settingsReducer;
