export const getRandomFromArray = arr => arr[Math.floor(Math.random() * arr.length)];

export const insertAtIndex = (arr, index, newItem) => [
  // part of the array before the specified index
  ...arr.slice(0, index),
  // inserted item
  newItem,
  // part of the array after the specified index
  ...arr.slice(index)
]

export const insertBeforeOrAfter = (arr, newItem, refItem, direction = 'before') => {
  const refIndex = arr.indexOf(refItem);
  const index = direction === 'before' ? refIndex : refIndex + 1;
  return insertAtIndex(arr, index, newItem)
}

export const findClosestSmallestIndex = (val, arr) => {
  // sort array min to max
  const sortedArray = [...arr].sort(function (a, b) {  return a - b;  });

  // find closest smallest number in sortedArray
  const number = sortedArray.reverse().find(e => e <= val);

  // get the index from the original array
  const index = arr.indexOf(number);

  return index;
}