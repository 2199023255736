import {
  BLACK_AND_WHITE_STROKE,
  MARKER_LABEL_FILL,
  MARKER_NUMBER_FILL,
  MARKER_OPAQUE_STROKE,
  MARKER_POINT_FILL,
  MARKER_POINT_SELECTED_FILL,
  MARKER_SELECTED_LABEL_FILL,
  MARKER_SELECTED_NUMBER_FILL,
  MARKER_SELECTED_STROKE,
  MARKER_SELECTED_TEXT_COLOR,
  MARKER_STROKE,
  MARKER_TEXT_COLOR,
} from '../../../constants/colors';
import { action, computed } from '@ember/object';
import {
  getMarker,
  getMarkerElement,
  getMarkerElementVersion,
  getMarkerTerm,
} from '../../../selectors/marker';

import Component from '@glimmer/component';
import Konva from 'konva';
import { connect } from 'ember-redux';
import { getAutoLabelOrientation } from '../../../utils/drawing';
import { getDeletedElementsList } from '../../../selectors/invention';
import { getMarkerNumber } from '../../../selectors/invention';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

const dispatchToActions = {};

const stateToComputed = (state, attrs) => ({
  deletedElementsList: getDeletedElementsList(state),
  marker: getMarker(state, attrs.markerId),
  element: getMarkerElement(state, attrs.markerId),
  elementVersion: getMarkerElementVersion(state, attrs.markerId),
  term: getMarkerTerm(state, attrs.markerId),
  number: getMarkerNumber(state, attrs.markerId),
});

class DrawingMarkerKonva extends Component {
  @service assets;
  @service redux;
  @service tracking;
  @service settings;

  numberRadius = 20;
  numberFontSize = 20;
  startRadius = 20;
  endPointRadius = 3;
  midRadius = 10;
  endRadius = 10;
  arrowWidth = 15;
  arrowLength = 20;
  tension = 0.5;
  fontFamily = 'Inter';
  fontSize = 18;
  labelWidth = 240;

  @tracked startX;
  @tracked startY;
  @tracked midX;
  @tracked midY;
  @tracked endX;
  @tracked endY;
  @tracked _x;
  @tracked _y;

  constructor(owner, args) {
    super(owner, args);
    this.onScheduleRender = this.args.onScheduleRender;
  }

  @action
  didInsert() {
    this._updatedAt = this.marker.updatedAt;
    this._isSelected = this.args.isSelected;
    this._isLabelSelected = this.isLabelSelected;
    this._isMultiselected = this.args.isMultiselected;
    this._number = this.number;
    this._x = 0;
    this._y = 0;
    this.startX = this.marker.startX;
    this.startY = this.marker.startY;
    this.midX = this.marker.midX;
    this.midY = this.marker.midY;
    this.endX = this.marker.endX;
    this.endY = this.marker.endY;
    this.setup();
  }

  @action
  willDestroyNode() {
    this.markerNode.off('click');
    this.markerNode.off('dragstart');
    this.markerNode.off('dragmove');
    this.markerNode.off('dragend');
    this.markerNode.destroy();
    this.onScheduleRender();
  }

  get styleNamespace() {
    return podNames['drawing-marker-konva'];
  }

  get classNames() {
    let classNames = [this.styleNamespace];
    if (this.args.isSelected) {
      classNames.push('is-selected');
    } else {
      classNames.push('isnt-selected');
    }
    return classNames.join(' ');
  }

  @computed('marker.element', 'args.selectedElements.[]')
  get isElementSelected() {
    return (
      this.marker &&
      this.marker.element &&
      this.args.selectedElements.includes(this.marker.element)
    );
  }

  @computed('marker.elementVersion', 'args.selectedElementVersions.[]')
  get isElementVersionSelected() {
    return (
      this.marker &&
      this.marker.elementVersion &&
      this.args.selectedElementVersions.includes(this.marker.elementVersion)
    );
  }

  @computed('marker.term', 'args.selectedTerms.[]')
  get isTermSelected() {
    return (
      this.marker &&
      this.marker.term &&
      this.args.selectedTerms.includes(this.marker.term)
    );
  }

  @computed('isElementSelected', 'isElementVersionSelected', 'isTermSelected')
  get isLabelSelected() {
    return (
      this.isElementSelected ||
      this.isElementVersionSelected ||
      this.isTermSelected
    );
  }

  @computed('marker.hasLeadLine')
  get hasLeadLine() {
    return this.marker && this.marker.hasLeadLine;
  }

  @computed('startX', 'startY', 'midX', 'midY', 'endX', 'endY')
  get isCollapsed() {
    return (
      this.startX === this.midX &&
      this.startY === this.midY &&
      this.startX === this.endX &&
      this.startY === this.endY
    );
  }

  @computed('marker.pointStyle', 'hasLeadLine')
  get hasArrow() {
    return (
      this.marker && this.marker.pointStyle === 'arrow' && this.hasLeadLine
    );
  }

  @computed('marker.curve')
  get isCurved() {
    return this.marker && this.marker.curve === 'natural';
  }

  @computed('marker.labelOrientation')
  get labelOrientation() {
    return this.marker && this.marker.labelOrientation;
  }

  labelBackgroundNodeHeight(lines) {
    let height = 40;
    switch (lines) {
      case 1:
        height = 40;
        break;
      case 2:
        height = 65;
        break;
      case 3:
        height = 90;
        break;
      case 4:
        height = 115;
        break;
      case 5:
        height = 140;
        break;
      case 6:
        height = 165;
        break;
    }
    return height;
  }

  get strokeColor() {
    let color = MARKER_STROKE;

    if (this.args.isSelected) {
      color = MARKER_SELECTED_STROKE;
    }

    if (this.settings.blackAndWhiteMode) {
      color = BLACK_AND_WHITE_STROKE;
    }

    return color;
  }

  get pointFill() {
    let color = MARKER_POINT_FILL;

    if (this.args.isSelected) {
      color = MARKER_POINT_SELECTED_FILL;
    }

    return color;
  }

  get textColor() {
    let color = MARKER_TEXT_COLOR;

    if (this.isLabelSelected) {
      color = MARKER_SELECTED_TEXT_COLOR;
    }

    if (this.settings.blackAndWhiteMode) {
      color = BLACK_AND_WHITE_STROKE;
    }

    return color;
  }

  get numberTextColor() {
    let color = MARKER_TEXT_COLOR;

    if (this.args.isSelected) {
      color = MARKER_SELECTED_TEXT_COLOR;
    }

    if (this.settings.blackAndWhiteMode) {
      color = BLACK_AND_WHITE_STROKE;
    }

    return color;
  }
  get labelFillColor() {
    let color = MARKER_LABEL_FILL;

    if (this.isLabelSelected) {
      color = MARKER_SELECTED_LABEL_FILL;
    }

    if (this.settings.blackAndWhiteMode) {
      color = 'transparent';
    }

    return color;
  }

  get numberStrokeColor() {
    let color = MARKER_STROKE;

    if (this.args.isSelected) {
      color = MARKER_SELECTED_STROKE;
    }

    return color;
  }

  get numberFillColor() {
    let color = MARKER_NUMBER_FILL;

    if (this.args.isSelected) {
      color = MARKER_SELECTED_NUMBER_FILL;
    }

    return color;
  }

  get selectedFill() {
    let color = MARKER_OPAQUE_STROKE;

    if (this.settings.blackAndWhiteMode) {
      color = 'rgba(0,0,0,0.5)';
    }

    return color;
  }

  @computed(
    'element.{name,elementVersionsList.[]}',
    'elementVersion.name',
    'term.name'
  )
  get label() {
    let label = '';
    if (this.element) {
      label = this.element.name;

      if (this.element.elementVersionsList.length > 1) {
        label += this.elementVersion ? `\n(${this.elementVersion.name})` : '';
      }
    }
    if (this.term) {
      label = this.term.name;
    }
    return label;
  }

  @computed('label')
  get hasLabel() {
    return this.label ? true : false;
  }

  getLabelOffset(nodeHeight) {
    const padding = 13;
    const startY = this.startY;
    const midY = this.midY;
    const endY = this.endY;
    const orientation =
      this.labelOrientation === 'auto'
        ? getAutoLabelOrientation({
            startY,
            endY,
            midY,
          })
        : this.labelOrientation;

    let offset;

    switch (orientation) {
      case 'top':
        offset = {
          x: this.labelWidth / 2,
          y: padding + this.startRadius + nodeHeight,
        };
        break;
      case 'bottom':
        offset = {
          x: this.labelWidth / 2,
          y: -1 * (padding + this.startRadius),
        };
        break;
      case 'left':
        offset = {
          x: padding + this.startRadius + this.labelWidth,
          y: nodeHeight / 2,
        };
        break;
      case 'right':
        offset = {
          x: -1 * (padding + this.startRadius),
          y: nodeHeight / 2,
        };
        break;
    }
    return offset;
  }

  get strokeWidth() {
    return this.settings.blackAndWhiteMode ? 3 : 2;
  }

  setup() {
    const markerNode = new Konva.Group({
      id: this.args.markerId,
      nodeType: 'marker',
      draggable: true,
    });

    const startNode = new Konva.Group({
      id: `start-${this.args.markerId}`,
      name: 'start',
      x: this.marker.startX,
      y: this.marker.startY,
      draggable: true,
    });

    const startNodeBackground = new Konva.Circle({
      radius: this.startRadius,
      stroke: this.strokeColor,
      strokeWidth: this.strokeWidth,
      fill: this.pointFill,
    });

    startNode.add(startNodeBackground);

    const midNode = new Konva.Circle({
      id: `mid-${this.args.markerId}`,
      name: 'mid',
      radius: this.midRadius,
      stroke: this.strokeColor,
      strokeWidth: this.strokeWidth,
      fill: this.pointFill,
      x: this.marker.midX,
      y: this.marker.midY,
      draggable: true,
    });

    const endNode = new Konva.Circle({
      id: `end-${this.args.markerId}`,
      name: 'end',
      radius: this.endRadius,
      stroke: this.strokeColor,
      strokeWidth: this.strokeWidth,
      fill: this.pointFill,
      x: this.marker.endX,
      y: this.marker.endY,
      draggable: true,
    });

    const ghostNode = new Konva.Line({
      id: `ghost-${this.args.markerId}`,
      points: [
        this.startX,
        this.startY,
        this.midX,
        this.midY,
        this.endX,
        this.endY,
      ],
      stroke: 'transparent',
      strokeWidth: 30,
      bezier: this.isCurved ? true : false,
      tension: this.isCurved ? this.tension : 0,
    });

    const arrowNode = new Konva.Arrow({
      points: [
        this.startX,
        this.startY,
        this.midX,
        this.midY,
        this.endX,
        this.endY,
      ],
      pointerWidth: this.hasArrow ? this.arrowWidth : 0,
      pointerLength: this.hasArrow ? this.arrowLength : 0,
      bezier: this.isCurved ? true : false,
      tension: this.isCurved ? this.tension : 0,
      fill: this.strokeColor,
      stroke: this.strokeColor,
      strokeWidth: this.strokeWidth,
    });

    const selectedNode = new Konva.Arrow({
      points: [
        this.startX,
        this.startY,
        this.midX,
        this.midY,
        this.endX,
        this.endY,
      ],
      pointerWidth: this.hasArrow ? this.arrowWidth : 0,
      pointerLength: this.hasArrow ? this.arrowLength : 0,
      bezier: this.isCurved ? true : false,
      tension: this.isCurved ? this.tension : 0,
      fill: this.selectedFill,
      stroke: this.selectedFill,
      strokeWidth: 12,
      visible: this.args.isSelected ? true : false,
    });

    const labelNode = new Konva.Group({
      id: `label-${this.args.markerId}`,
      visible: this.hasLabel,
      x: this.startX,
      y: this.startY,
      transformsEnabled: 'position',
    });

    const labelTextNode = new Konva.Text({
      width: this.labelWidth,
      ellipsis: true,
      text: this.label,
      align: 'center',
      fontSize: this.fontSize,
      fontStyle: this.settings.blackAndWhiteMode ? '500' : '400',
      lineHeight: 1.235,
      fontFamily: this.fontFamily,
      fill: this.textColor,
      transformsEnabled: 'position',
      strokeScaleEnabled: false,
      padding: 7,
      listening: false,
    });

    const labelBackgroundNodeHeight = this.labelBackgroundNodeHeight(
      labelTextNode.textArr.length
    );

    const labelBackgroundNode = new Konva.Rect({
      width: this.labelWidth,
      height: labelBackgroundNodeHeight,
      fill: this.labelFillColor,
      transformsEnabled: 'position',
    });

    labelNode.offset(this.getLabelOffset(labelBackgroundNode.height()));

    labelNode.add(labelBackgroundNode);
    labelNode.add(labelTextNode);

    const numberNode = new Konva.Group({
      visible: this.number ? true : false,
      transformsEnabled: 'position',
      listening: false
    });

    const numberTextNode = new Konva.Text({
      x: -1 * this.numberRadius,
      y: -1 * this.numberRadius + 1,
      width: this.numberRadius * 2,
      height: this.numberRadius * 2,
      text: this.number,
      align: 'center',
      verticalAlign: 'middle',
      fontSize: this.numberFontSize,
      lineHeight: 1.235,
      fontFamily: this.fontFamily,
      fontStyle: '500',
      fill: this.numberTextColor,
      transformsEnabled: 'position',
      listening: false,
    });

    const numberBackgroundNode = new Konva.Circle({
      radius: this.numberRadius,
      fill: this.numberFillColor,
      stroke: this.numberStrokeColor,
      strokeWidth: this.strokeWidth,
      transformsEnabled: 'position',
    });

    numberNode.add(numberBackgroundNode);
    numberNode.add(numberTextNode);
    startNode.add(numberNode);

    this.args.layer.add(markerNode);

    markerNode.add(selectedNode);
    markerNode.add(arrowNode);
    markerNode.add(ghostNode);
    markerNode.add(endNode);
    markerNode.add(midNode);
    markerNode.add(startNode);
    markerNode.add(labelNode);
    // markerNode.add(endPointNode);

    this.markerNode = markerNode;
    this.selectedNode = selectedNode;
    this.ghostNode = ghostNode;
    this.startNode = startNode;
    this.startNodeBackground = startNodeBackground;
    this.midNode = midNode;
    this.endNode = endNode;
    // this.endPointNode = endPointNode;
    this.arrowNode = arrowNode;
    this.numberNode = numberNode;
    this.numberTextNode = numberTextNode;
    this.numberBackgroundNode = numberBackgroundNode;
    this.labelNode = labelNode;
    this.labelTextNode = labelTextNode;
    this.labelBackgroundNode = labelBackgroundNode;

    this.addEvents();
    this.onScheduleRender();
  }

  addEvents() {
    // add events
    this.ghostNode.on('click', () => {
      if (this.args.onClick) {
        this.args.onClick(this.args.markerId, this.args.isSelected);
        this.markerNode.moveToTop();
      }
    });

    this.startNode.on('click', () => {
      if (this.args.onClick) {
        this.args.onClick(this.args.markerId, this.args.isSelected);
        this.markerNode.moveToTop();
      }
    });

    this.midNode.on('click', () => {
      if (this.args.onClick) {
        this.args.onClick(this.args.markerId, this.args.isSelected);
        this.markerNode.moveToTop();
      }
    });

    this.endNode.on('click', () => {
      if (this.args.onClick) {
        this.args.onClick(this.args.markerId, this.args.isSelected);
        this.markerNode.moveToTop();
      }
    });

    this.labelNode.on('click', () => {
      if (this.args.onLabelClick) {
        this.markerNode.moveToTop();
        this.args.onLabelClick(
          this.marker.element,
          this.marker.elementVersion,
          this.marker.term
        );
      }
    });

    if (this.args.onPointDragEnd) {
      this.startNode.on('dragstart', () => {
        this.markerNode.moveToTop();
      });
      this.startNode.on('dragmove', (event) => {
        const { x, y } = event.target.attrs;
        this.startX = x;
        this.startY = y;
        this.endX = this.endNode.x();
        this.endY = this.endNode.y();

        this.labelNode.x(x);
        this.labelNode.y(y);
        this.labelNode.offset(
          this.getLabelOffset(this.labelBackgroundNode.height())
        );

        if (!this.hasLeadLine) {
          let blend = 0.5;
          this.midX = this.startX + blend * (this.endX - this.startX);
          this.midY = this.startY + blend * (this.endY - this.startY);
          this.midNode.x(this.midX);
          this.midNode.y(this.midY);
        } else {
          this.midX = this.midNode.x();
          this.midY = this.midNode.y();
        }
        this.updatePaths();
      });
      this.startNode.on('dragend', () => {
        const startX = this.startX;
        const startY = this.startY;
        const midX = this.midX;
        const midY = this.midY;
        const hasLeadLine = !this.isCollapsed;
        this.args.onPointDragEnd(this.args.markerId, {
          startX,
          startY,
          midX,
          midY,
          hasLeadLine,
        });
        this.tracking.trackEvent('marker_dragged_start_point');
      });
      this.midNode.on('dragstart', () => {
        this.markerNode.moveToTop();
      });
      this.midNode.on('dragmove', (event) => {
        const { x, y } = event.target.attrs;
        this.midX = x;
        this.midY = y;
        this.endX = this.endNode.x();
        this.endY = this.endNode.y();
        this.startX = this.startNode.x();
        this.startY = this.startNode.y();
        this.updatePaths();
      });
      this.midNode.on('dragend', () => {
        const midX = this.midX;
        const midY = this.midY;
        const hasLeadLine = !this.isCollapsed;
        this.args.onPointDragEnd(this.args.markerId, {
          midX,
          midY,
          hasLeadLine,
        });
        this.tracking.trackEvent('marker_dragged_mid_point');
      });
      this.endNode.on('dragstart', () => {
        this.markerNode.moveToTop();
      });
      this.endNode.on('dragmove', (event) => {
        const { x, y } = event.target.attrs;
        this.endX = x;
        this.endY = y;
        this.midX = this.midNode.x();
        this.midY = this.midNode.y();
        this.startX = this.startNode.x();
        this.startY = this.startNode.y();
        this.updatePaths();
      });
      this.endNode.on('dragend', () => {
        const endX = this.endX;
        const endY = this.endY;
        const hasLeadLine = !this.isCollapsed;
        this.args.onPointDragEnd(this.args.markerId, {
          endX,
          endY,
          hasLeadLine,
        });
        this.tracking.trackEvent('marker_dragged_end_point');
      });
    }

    if (this.args.onDragEnd) {
      this.markerNode.on('dragstart', () => {
        this.markerNode.moveToTop();
        this._x = 0;
        this._y = 0;
      });
      this.markerNode.on('dragmove', (event) => {
        const { x, y } = event.target.attrs;
        const dx = x - this._x;
        const dy = y - this._y;
        this._x = x;
        this._y = y;

        this.startX = this.startX + dx;
        this.startY = this.startY + dy;
        this.midX = this.midX + dx;
        this.midY = this.midY + dy;
        this.endX = this.endX + dx;
        this.endY = this.endY + dy;

        if (this.args.isSelected && this.args.onMultiselectDrag) {
          this.args.onMultiselectDrag(dx, dy, this.args.markerId);
        }
      });

      this.markerNode.on('dragend', () => {
        if (this.args.isSelected && this.args.isMultiselected) {
          this.args.onMultiselectDragEnd();
        } else {
          this.args.onDragEnd(this.args.markerId, {
            startX: this.startX,
            startY: this.startY,
            midX: this.midX,
            midY: this.midY,
            endX: this.endX,
            endY: this.endY,
          });
          this.tracking.trackEvent('marker_dragged');
        }
      });
    }
  }

  updateColors() {
    this.startNodeBackground.fill(this.pointFill);
    this.startNodeBackground.stroke(this.strokeColor);
    this.midNode.fill(this.pointFill);
    this.midNode.stroke(this.strokeColor);
    this.endNode.fill(this.pointFill);
    this.endNode.stroke(this.strokeColor);
    this.arrowNode.fill(this.strokeColor);
    this.arrowNode.stroke(this.strokeColor);
    this.labelTextNode.fill(this.textColor);
    this.labelBackgroundNode.fill(this.labelFillColor);
    this.selectedNode.fill(this.selectedFill);
    this.selectedNode.stroke(this.selectedFill);
    this.numberTextNode.fill(this.numberTextColor);
    this.numberBackgroundNode.fill(this.numberFillColor);
    this.numberBackgroundNode.stroke(this.numberStrokeColor);
  }

  updateCoords() {
    this.startNode.x(this.startX);
    this.startNode.y(this.startY);
    this.onScheduleRender(this.args.layer);
  }

  updatePaths() {
    const points = [
      this.startX,
      this.startY,
      this.midX,
      this.midY,
      this.endX,
      this.endY,
    ];
    this.ghostNode.points(points);
    this.arrowNode.points(points);
    this.selectedNode.points(points);
    this.onScheduleRender(this.args.layer);
  }

  updateLabel() {
    this.labelNode.visible(this.hasLabel);
    this.labelTextNode.text(this.label);

    const labelBackgroundNodeHeight = this.labelBackgroundNodeHeight(
      this.labelTextNode.textArr.length
    );
    this.labelBackgroundNode.height(labelBackgroundNodeHeight);
    this.labelNode.offset(this.getLabelOffset(labelBackgroundNodeHeight));
  }

  onUpdatedAt() {
    const points = [
      this.marker.startX,
      this.marker.startY,
      this.marker.midX,
      this.marker.midY,
      this.marker.endX,
      this.marker.endY,
    ];
    this.ghostNode.points(points);
    this.arrowNode.points(points);
    this.selectedNode.points(points);

    this.startNode.x(this.marker.startX);
    this.startNode.y(this.marker.startY);
    this.midNode.x(this.marker.midX);
    this.midNode.y(this.marker.midY);
    this.endNode.x(this.marker.endX);
    this.endNode.y(this.marker.endY);

    this.startX = this.marker.startX;
    this.startY = this.marker.startY;
    this.midX = this.marker.midX;
    this.midY = this.marker.midY;
    this.endX = this.marker.endX;
    this.endY = this.marker.endY;

    this.arrowNode.bezier(this.isCurved ? true : false);
    this.arrowNode.tension(this.isCurved ? this.tension : 0);
    this.selectedNode.bezier(this.isCurved ? true : false);
    this.selectedNode.tension(this.isCurved ? this.tension : 0);
    this.ghostNode.bezier(this.isCurved ? true : false);
    this.ghostNode.tension(this.isCurved ? this.tension : 0);

    this.arrowNode.pointerWidth(this.hasArrow ? this.arrowWidth : 0);
    this.arrowNode.pointerLength(this.hasArrow ? this.arrowLength : 0);
    this.selectedNode.pointerWidth(this.hasArrow ? this.arrowWidth : 0);
    this.selectedNode.pointerLength(this.hasArrow ? this.arrowLength : 0);

    this.labelNode.x(this.startX);
    this.labelNode.y(this.startY);
    this.labelNode.offset(
      this.getLabelOffset(this.labelBackgroundNode.height())
    );

    this.markerNode.x(0);
    this.markerNode.y(0);
  }

  @action
  onUpdate(
    elem,
    [isSelected, isLabelSelected, label, updatedAt, blackAndWhiteMode, number]
  ) {
    let detailsChanged = false;

    if (this._isSelected !== isSelected) {
      this._isSelected = isSelected;

      this.updateColors();
      this.selectedNode.visible(this.args.isSelected ? true : false);

      detailsChanged = true;
    }

    if (this._isLabelSelected !== isLabelSelected) {
      this._isLabelSelected = isLabelSelected;

      this.updateColors();
      // this.selectedNode.visible(this.args.isSelected ? true : false);

      detailsChanged = true;
    }

    if (this._label !== label) {
      this._label = label;

      this.updateLabel();

      detailsChanged = true;
    }

    if (this._number !== number) {
      this._number = number;

      if (this.number) {
        this.numberTextNode.text(this.number);
        this.numberNode.visible(true);
      } else {
        this.numberNode.visible(false);
      }

      detailsChanged = true;
    }

    if (this._updatedAt !== updatedAt) {
      this._updatedAt = updatedAt;

      this.onUpdatedAt();

      detailsChanged = true;
    }

    if (this._blackAndWhiteMode !== blackAndWhiteMode) {
      this._blackAndWhiteMode = blackAndWhiteMode;
      this.updateColors();
      detailsChanged = true;
    }

    if (detailsChanged) {
      this.onScheduleRender(this.args.layer);
    }
  }
}

export default connect(stateToComputed, dispatchToActions)(DrawingMarkerKonva);
