/* eslint-disable ember/no-observers */
import { computed, observer } from '@ember/object';
import {
  elementMentionTemplate,
  sanitizeMentions,
  termMentionTemplate,
  tributeElementMentionTemplate,
  tributeTermMentionTemplate,
} from '../../../utils/mentions';
import { getElement, getElementsList } from '../../../selectors/element';
import { getTerm, getTermsList } from '../../../selectors/term';

import $ from 'jquery';
import Component from '@ember/component';
import FuzzySearch from 'fz-search';
import { alias } from '@ember/object/computed';
import { connect } from 'ember-redux';
// import { focusAndPlaceCaretAtEnd, focusAndSelectAll } from '../../../utils/form';
import { focusAndPlaceCaretAtEnd } from '../../../utils/form';
import { getElementVersion } from '../../../selectors/element-version';
import { getInvention } from '../../../selectors/invention';
import { getMentionsContent } from '../../../selectors/mention';
import { inject as service } from '@ember/service';

const dispatchToActions = {};

const stateToComputed = (state, attrs) => ({
  elements: getElementsList(state).map((_elementId) => {
    return getElement(state, _elementId);
  }),
  terms: getTermsList(state).map((termId) => {
    return getTerm(state, termId);
  }),
  invention: getInvention(state, attrs.inventionId),
});

const UiContentMentionable = Component.extend({
  classNames: ['content-mentionable'],
  value: '',
  placeholder: null,
  disableReturn: true,
  autoFocus: false,
  redux: service(),
  documentSelection: service(),
  contextMenu: service(),
  selectedText: alias('documentSelection.selectedText'),
  selectedTextIsShort: computed('selectedText.length', function () {
    return this.selectedText && this.selectedText.split(' ').length < 6;
  }),
  popperTarget: alias('documentSelection.popperTarget'),
  collection: computed('elements', 'terms', function () {
    const state = this.redux.getState();
    const typedElements = this.elements
      .filter((element) => !element.instanceOf)
      .map((element) => {
        const elementVersionNames = element.elementVersionsList.map(
          (elementVersionId) => {
            const elementVersion = getElementVersion(state, elementVersionId);
            return elementVersion.name;
          }
        );
        const searchText = `${element.name} ${elementVersionNames.join(' ')}`;
        return {
          ...element,
          searchText,
          elementVersionNames,
          type: 'element',
        };
      });
    const typedTerms = this.terms.map((term) => {
      return {
        ...term,
        searchText: term.name,
        type: 'term',
      };
    });
    return [...typedElements, ...typedTerms];
  }),

  init() {
    this._super(...arguments);
    const searcher = new FuzzySearch({
      source: this.collection,
      keys: ['searchText'],
      token_query_min_length: 0,
      token_field_min_length: 0,
    });
    this.set('searcher', searcher);
    this.set('tributeOptions', this.getTributeOptions());
    this.setMentionsValue();
  },

  onContextMenu(event) {
    event.preventDefault();
    if (this.selectedText) {
      this.contextMenu.open('content-mentionable', {
        x: event.clientX,
        y: event.clientY,
        containerType: this.containerType,
        containerId: this.containerId,
        modelId: this.modelId,
        modelType: this.modelType,
        modelAttr: this.modelAttr,
        domElementId: this.elementId,
      });
    }
  },

  // prevents selectionchange event firing with control click context menu on mac
  onMouseup(event) {
    if (this.contextMenu.isActive) {
      event.preventDefault();
    }
  },

  // click outside close when focused
  onMousedown() {
    if (this.contextMenu.isActive) {
      this.contextMenu.close();
    }
  },

  willDestroyElement() {
    this._super(...arguments);
    $(`#${this.elementId} .textarea`).off('contextmenu');
    $(`#${this.elementId} .textarea`).off('mousedown');
    $(`#${this.elementId} .textarea`).off('mouseup');
  },

  didInsertElement() {
    this._super(...arguments);
    const textarea = $(`#${this.elementId} .textarea`)[0];
    $(`#${this.elementId} .textarea`).on('contextmenu', (event) =>
      this.onContextMenu(event)
    );
    $(`#${this.elementId} .textarea`).on('mousedown', (event) =>
      this.onMousedown(event)
    );
    $(`#${this.elementId} .textarea`).on('mouseup', (event) =>
      this.onMouseup(event)
    );
    if (this.autoFocus && textarea) {
      if (this.selectAll) {
        // focusAndSelectAll(textarea);
      } else {
        focusAndPlaceCaretAtEnd(textarea);
      }
    }
  },
  // eslint-disable-next-line ember/no-observers
  elementsObserver: observer('elements.@each.name', 'modelId', function () {
    this.setMentionsValue();
  }),

  setMentionsValue() {
    const state = this.redux.store.getState();
    const _value = getMentionsContent(state, this.value, this.elementId, {
      isInput: true,
      appendElementVersion: true,
    });
    this.set('_value', _value);
  },

  getMenuItemTemplate(item) {
    const type = item.original.type;
    let innerTemplate;
    switch (type) {
      case 'term':
        innerTemplate = tributeTermMentionTemplate(
          item.original.id,
          item.original.name
        );
        break;
      case 'element':
        innerTemplate = tributeElementMentionTemplate(
          item.original.id,
          item.original.elementVersionNames,
          item.original.name
        );
        break;
    }
    return `<div data-test-id="hash-option" data-name="${item.original.name}" replace="${item.original.name}">
               ${innerTemplate}
              </div>`;
  },

  getTributeOptions() {
    return {
      menuContainer: document.getElementById('main-app'),
      menuItemLimit: 10,
      // positionMenu: false,
      trigger: '#',
      selectClass: 'highlight',
      selectTemplate: (item) => {
        const type = item.original.type;
        const domElementId = this.getUniqueId(item.original.id);
        return type === 'element'
          ? elementMentionTemplate(
              item.original.id,
              null,
              item.original.name,
              domElementId
            )
          : termMentionTemplate(
              item.original.id,
              item.original.name,
              domElementId
            );
      },
      menuItemTemplate: this.getMenuItemTemplate,

      // column to search against in the object (accepts function or string)
      lookup: 'searchText',

      // column that contains the content to insert by default
      fillAttr: 'searchText',

      // REQUIRED: array of objects to match
      // values: this.collection,
      values: (text, cb) => {
        const results = this.search(text);
        cb(results);
      },

      // specify whether a space is allowed in the middle of mentions
      allowSpaces: true,

      // specify whether the menu should be positioned.  Set to false and use in conjuction with menuContainer to create an inline menu
      // positionMenu: false
    };
  },

  search(query = '') {
    return this.searcher.search(query);
  },

  hasFocus: computed(
    'documentSelection.contentMentionable',
    'elementId',
    function () {
      const activeContentMentionable =
        this.documentSelection.contentMentionable;
      return activeContentMentionable
        ? activeContentMentionable.id === this.elementId
        : false;
    }
  ),

  showPopover: false,
  // showPopover: and('selectedText', 'selectedTextIsShort', 'hasFocus'),

  getUniqueId(_elementId) {
    const numberOfMentions = document.querySelectorAll(
      `#${this.elementId} .mention`
    ).length;
    return `${this.elementId}-${_elementId}-${numberOfMentions}`;
  },

  actions: {
    onChange(val) {
      val = sanitizeMentions(val);
      this.onChange(val);
    },

    onBlur(event, domElement) {
      if (this.onBlur) {
        this.onBlur(domElement.innerHTML);
      }
    },

    onFocus(event, domElement) {
      if (!this._value) {
        const textarea = $(`#${this.elementId} .textarea`)[0];
        focusAndPlaceCaretAtEnd(textarea);
      }
      if (this.onFocus) {
        this.onFocus(domElement.innerHTML);
      }
    },

    onEnter(event, domElement) {
      if (this.onEnter) {
        this.onEnter(domElement.innerHTML);
      }
    },

    onDelete(event) {
      // event.preventDefault();
      event.stopPropagation();
    },

    onTributeReplaced() {
      const updatedValue = document.querySelector(
        `#${this.elementId} .medium-editor-element`
      ).innerHTML;

      if (this.onTribute) {
        this.onTribute(sanitizeMentions(updatedValue));
      }
    },
  },
});

export default connect(
  stateToComputed,
  dispatchToActions
)(UiContentMentionable);
