import { action, computed } from '@ember/object';

import Component from '@glimmer/component';
import { connect } from 'ember-redux';
import { getFigureNumber } from '../../../selectors/figure';
import { getMarker } from '../../../selectors/marker';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';

const dispatchToActions = {};

const stateToComputed = (state, attrs) => ({
  marker: getMarker(state, attrs.markerId),
});

class PreviewDrawingItem extends Component {
  @service redux;
  get styleNamespace() {
    return podNames['preview-drawing-item'];
  }

  get classNames() {
    let classNames = [this.styleNamespace, 'preview-drawing-item'];
    return classNames.join(' ');
  }

  @computed('marker.drawing')
  get drawingId() {
    return this.marker && this.marker.drawing;
  }

  @computed('drawingId', 'args.markerId')
  get num() {
    const state = this.redux.getState();
    return getFigureNumber(state, this.drawingId);
  }

  @action
  onClick() {
    if (this.args.onClick) {
      this.args.onClick(this.args.markerId);
    }
  }
}
export default connect(stateToComputed, dispatchToActions)(PreviewDrawingItem);
