const Handlebars = window.Handlebars;

///////////////////////////////////////////////
// svgX,
// svgY,
// svgWidth,
// svgHeight,
// artboardWidth,
// artboardHeight,
// artboardX,
// artboardY,
// edges: {
//   halfLine,
//   showLabel,
//   label,
//   labelX,
//   labelY
// }
// nodes: {
//   isConditional
//   conditionalPolygonPoints
//   x
//   y
//   width
//   height
//   ordinal
//   value
// }
///////////////////////////////////////////////

const template = `
<svg class="method-template" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="{{svgX}} {{svgY}} {{svgWidth}} {{svgHeight}}">
  <defs>
    <style type="text/css">
      .method-template .method-edge {
        font-family: "Helvetica", "Arial", sans-serif;
      }

      .method-template .method-edge .half-path {
        stroke: #000;
        stroke-width: 2px;
        pointer-events: none;
      }

      .method-template .method-edge .marker-arrow {
        fill: #000;
      }

      .method-template .method-edge .label-content {
        line-height: 1.25;
        user-select: none;
        pointer-events: none;
        line-height: 29px;
        font-size: 19.5px;
        width: 100%;
        height: 100%;
        padding-left: 9.5px;
      }

      .method-template .method-edge .label-content .source-position-w {
        text-align: right;
        padding-left: 0;
        padding-right: 9.5px;
      }

      .method-template .method-edge .label-content .source-position-s {
        padding-top: 5px;
      }

      .method-template .method-node {
        stroke: #000;
        stroke-width: 1.5px;
      }

      .method-template .step-text {
        font-weight: 400;
        stroke: transparent;
        stroke-width: 0;
        font-family: "Helvetica", "Arial", sans-serif; 
        font-size: 20px;
      }

      .method-template .ordinal-text {
        stroke: transparent;
        stroke-width: 0;
        font-size: 24px;
      }

      .method-template .method-node .conditional-shape {
        stroke: #000;
        stroke-width: 2px;
        fill: transparent;
      }

      .method-template .method-node .step-rect {
        fill: transparent;
      }

      .method-template .method-node .label-container {
        color: #000;
        font-size: 19.5px;
        text-align: right;
      }

      .method-template .method-node .node-content-container {
        overflow: visible;
      }

      .method-template .method-node .node-content {
        line-height: 1.25;
        user-select: none;
        pointer-events: none;
        line-height: 29px;
        font-size: 19.5px;
        padding: 20px;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        color: #000 !important;
      }

      .method-template .method-node .mention,
      .method-template .method-node .mention a {
        color: #000 !important;
      }

      .method-template .method-node.is-conditional {
        stroke: transparent;
        stroke-width: 1.5px;
      }

      .method-template .method-node.is-conditional .label-container {
        text-align: center;
      }

      .method-template .method-node.is-conditional .node-content {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: 0;
      }

      .method-template .artboard {
        fill: transparent;
        stroke: none;
        stroke-width: 0;
      }
    </style>
    <mask id="artboard-mask">
      <rect width="{{artboardWidth}}" height="{{artboardHeight}}" fill="#ffffff" transform="translate({{artboardX}}, {{artboardY}})" />
    </mask>
    <marker id="marker-arrow" class="marker-arrow" viewBox="0 -5 10 10" refX="5" markerWidth="5.5" markerHeight="5.5" orient="auto">
      <path d="M0,-5L10,0L0,5"></path>
    </marker>
  </defs>
  <filter id="grayscale">
    <feColorMatrix type="saturate" values="0" />
  </filter>

  <rect width="{{artboardWidth}}" height="{{artboardHeight}}" fill="#ffffff" transform="translate({{artboardX}}, {{artboardY}})" />
  <g class="drawing-contents">
    {{#each edges}}
      <g class="method-edge">
        <path class="half-path" marker-end="url(#marker-arrow)" fill="none" d="{{this.halfLine}}"></path>
        {{#if this.showLabel}}
          <g>
            <text class="step-text" transform="translate({{this.labelX}}, {{this.labelY}})">{{this.label}}</text>
          </g>
        {{/if}}
      </g>
    {{/each}}
    
    {{#each nodes}}
      <g class="method-node">
        {{#if this.isConditional}}
          <polygon class="conditional-shape" points="{{this.conditionalPolygonPoints}}" />
        {{else}}
          <g transform="translate({{this.x}}, {{this.y}})">
            <rect
              class="step-rect"
              width="{{this.width}}"
              height="{{this.height}}">
            </rect>
          </g>
        {{/if}}
        <g mask="url(#artboard-mask)">
          <g
            class="step-group"
            transform="translate({{this.x}}, {{this.y}})">

            <g transform="translate({{this.valuesX}}, {{this.valuesY}})">
              <text class="step-text" text-anchor="middle" transform="translate(0, -{{valuesHeight}})">
                {{#each this.values}}
                  <tspan x="0" y="{{this.y}}">{{this.value}}</tspan>
                {{/each}}
              </text>
            </g>
          </g>
        </g>
        {{#if this.ordinal}}
          {{#if this.isConditional}}
            <g
              class="ordinal"
              transform="translate({{this.x}}, {{this.y}})">
              <text class="ordinal-text" x="{{this.centerX}}" y="-5" text-anchor="middle">{{this.ordinal}}</text>
            </g>
          {{else}}
            <g
              class="ordinal"
              transform="translate({{this.x}}, {{this.y}})">
              <text class="ordinal-text" x="{{this.width}}" y="-5" text-anchor="end">{{this.ordinal}}</text>
            </g>
          {{/if}}
        {{/if}}
      </g>
    {{/each}}
  </g>
</svg>
`

const compiledTemplate = Handlebars.compile(template);

export default compiledTemplate;
