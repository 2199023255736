import Component from '@ember/component';
import { equal } from '@ember/object/computed';

const UiNotification = Component.extend({
  classNames: ['notification'],
  classNameBindings: ['isDanger', 'isWarning', 'isSmall'],
  isDanger: false,
  isWarning: false,
  size: 'normal', // small, medium, large
  isSmall: equal('size', 'small'),
});

export default UiNotification;
