import {
  getDrawingLegendRealization,
  getElementVersionRealizations,
  getMethodLegendRealization,
  getTermRealization,
} from '../utils/realization';

import Service from '@ember/service';
import docx from 'docx';
import { getDrawing } from '../selectors/drawing';
import { getElementVersion } from '../selectors/element-version';
import { getMappedFiguresList } from '../selectors/figure';
import { getMentionsContent } from '../selectors/mention';
import { getMethodRealization } from '../utils/method';
import { getPatentSpecification } from '../selectors/patent-specification';
import { getTerm } from '../selectors/term';
import { getTermsList } from '../selectors/term';
import { isEmptyOrSpaces } from '../utils/string';
import { saveAs } from 'file-saver';
import { inject as service } from '@ember/service';
import { underscore } from '@ember/string';

export default class PatentSpecificationDocx extends Service {
  @service store;

  export(state, options) {
    options = {
      numberParagraphs: true,
      ...options,
    };

    const patentSpecification = getPatentSpecification(state);
    const title = patentSpecification && patentSpecification.title || 'Untitled Invention';
    const fileName = underscore(title);
    const paragraphs = this.getDocumentParagraphs(state);

    const doc = new docx.Document(
      {
        title: 'Specification',
        description: 'PatentDive patent specification export',
        styles: {
          default: {
            heading1: {
              run: {
                size: 24,
                bold: true,
                allCaps: true,
              },
              paragraph: {
                alignment: docx.AlignmentType.CENTER,
                spacing: {
                  line: 240,
                  after: 0,
                },
              },
            },
            heading3: {
              run: {
                size: 24,
                bold: true,
                allCaps: true,
              },
              paragraph: {
                spacing: {
                  line: 240,
                  after: 240,
                },
              },
            },
          },
          paragraphStyles: [
            {
              id: 'content',
              name: 'Content',
              basedOn: 'Normal',
              next: 'Normal',
              run: {
                font: 'Times New Roman',
                size: 24,
              },
              paragraph: {
                spacing: {
                  line: 380,
                  after: 240,
                },
              },
            },
          ],
        },
      },
      {
        top: 1080, // 3/4 inch
        right: 1440, // 1 inch
        bottom: 1080, // 1 inch
        left: 1440, // 1 inch
        // http://officeopenxml.com/WPSectionPgMar.php
      }
    );

    let paragraphIndex = 1;

    const children = paragraphs.map((paragraph) => {
      let child;
      let { type, content, numbered } = paragraph;
    
      let contentArray;
      if (options.numberParagraphs && numbered) {
        const paragraphNumber = this.getLeadingZeros(paragraphIndex) + '   ';
        contentArray = [
          new docx.TextRun({
            text: paragraphNumber,
            bold: true,
          }),
          new docx.TextRun(content),
        ];
        paragraphIndex++;
      } else {
        contentArray = [new docx.TextRun(content)];
      }
      switch (type) {
        case 'title':
          child = new docx.Paragraph({
            children: contentArray,
            heading: docx.HeadingLevel.HEADING_1,
          });
          break;
        case 'header':
          child = new docx.Paragraph({
            children: contentArray,
            heading: docx.HeadingLevel.HEADING_3,
          });
          break;
        case 'content':
          child = new docx.Paragraph({
            children: contentArray,
            style: 'content',
          });
          break;
      }
      return child;
    });

    doc.addSection({
      children,
    });



    console.log('doc', doc)

    docx.Packer.toBlob(doc).then((blob) => {
      // saveAs from FileSaver will download the file
      saveAs(blob, `${fileName}.docx`);
    });
  }

  getDocumentParagraphs(state) {
    const headerParagraphs = this.getHeaderParagraphs(state);
    const titleParagraphs = this.getTitleParagraphs(state);
    const crossReferenceParagraphs = this.getCrossReferenceParagraphs(state);
    const fundingSourcesParagraphs = this.getFundingSourcesParagraphs(state);
    const inventorsParagraphs = this.getInventorsParagraphs(state);
    const inventionFieldsParagraphs = this.getInventionFieldsParagraphs(state);
    const backgroundParagraphs = this.getBackgroundParagraphs(state);
    const summaryParagraphs = this.getSummaryParagraphs(state);
    const figureLegendParagraphs = this.getFigureLegendParagraphs(state);

    const detailedDescriptionParagraphs =
      this.getDetailedDescriptionParagraphs(state);

    const abstractParagraphs = this.getAbstractParagraphs(state);

    return [
      ...headerParagraphs,
      ...titleParagraphs,
      ...crossReferenceParagraphs,
      ...fundingSourcesParagraphs,
      ...inventorsParagraphs,
      ...inventionFieldsParagraphs,
      ...backgroundParagraphs,
      ...summaryParagraphs,
      ...figureLegendParagraphs,
      ...detailedDescriptionParagraphs,
      ...abstractParagraphs,
    ];
  }

  getHeaderParagraphs(state) {
    const patentSpecification = getPatentSpecification(state);
    const type =
      (patentSpecification && patentSpecification.label) || 'provisional';
    const label = type === 'provisional' ? 'Provisional' : 'Nonprovisional';
    return [
      {
        type: 'title',
        content: 'United States Patent and Trademark Office',
      },
      {
        type: 'title',
        content: `${label} Patent Application`,
      },
      {
        type: 'title',
        content: '',
      },
    ];
  }

  getTitleParagraphs(state) {
    const patentSpecification = getPatentSpecification(state);
    const title = (patentSpecification && patentSpecification.title) || '';
    return [
      {
        type: 'header',
        content: 'Title',
      },
      {
        type: 'content',
        content: title.toUpperCase(),
      },
    ];
  }

  getCrossReferenceParagraphs(state) {
    const patentSpecification = getPatentSpecification(state);
    const showCrossReference =
      patentSpecification && patentSpecification.showCrossReference;
    const crossReference =
      (patentSpecification && patentSpecification.crossReference) || '';
    const content = getMentionsContent(
      state,
      crossReference,
      'cross-reference',
      { toLowerCase: true }
    );

    return showCrossReference
      ? [
          {
            type: 'header',
            content: 'Cross-Reference to Related Applications',
          },
          {
            type: 'content',
            content: content,
          },
        ]
      : [];
  }

  getBoilerplateParagraphs(state) {
    const patentSpecification = getPatentSpecification(state);
    const showBoilerplate =
      patentSpecification && patentSpecification.showBoilerplate;
    const boilerplate =
      (patentSpecification && patentSpecification.boilerplate) || '';
    const content = getMentionsContent(
      state,
      boilerplate,
      'detailed-description',
      {
        toLowerCase: true,
        appendOrdinal: true,
      }
    );
    const paragraphs = this.splitParagraphs(content);
    console.log('paragraphsX', paragraphs)
    const contentParagraphs = [];
    paragraphs.forEach((paragraph) => {
      contentParagraphs.push({
        type: 'content',
        content: this.sanitize(paragraph),
        numbered: true,
      });
    });
    return showBoilerplate ? contentParagraphs : [];
  }

  getFundingSourcesParagraphs(state) {
    const patentSpecification = getPatentSpecification(state);
    const showFundingSources =
      patentSpecification && patentSpecification.showFundingSources;
    const fundingSources =
      (patentSpecification && patentSpecification.fundingSources) || '';
    const content = getMentionsContent(
      state,
      fundingSources,
      'cross-reference',
      { toLowerCase: true }
    );

    return showFundingSources
      ? [
          {
            type: 'header',
            content:
              'Statement Regarding Federally Sponsored Research or Development',
          },
          {
            type: 'content',
            content: content,
          },
        ]
      : [];
  }

  getInventorsParagraphs(state) {
    const patentSpecification = getPatentSpecification(state);
    const inventors =
      (patentSpecification && patentSpecification.inventors) || '';
    const inventorsArray = inventors
      .split(',')
      .filter((inventor) => !isEmptyOrSpaces(inventor));
    const inventorsString = inventorsArray.join(', ');
    const plural = inventorsArray.length > 1 ? 's' : '';
    return [
      {
        type: 'header',
        content: `Inventor${plural}`,
      },
      {
        type: 'content',
        content: inventorsString,
      },
    ];
  }

  getInventionFieldsParagraphs(state) {
    const patentSpecification = getPatentSpecification(state);
    const inventionFields =
      (patentSpecification && patentSpecification.inventionFields) || '';
    const content = getMentionsContent(
      state,
      inventionFields,
      'invention-fields',
      { toLowerCase: true }
    );
    const paragraphs = this.splitParagraphs(content);
    const contentParagraphs = [];
    paragraphs.forEach((paragraph) => {
      contentParagraphs.push({
        type: 'content',
        content: this.sanitize(paragraph),
        numbered: true,
      });
    });
    return [
      {
        type: 'header',
        content: `Fields of the Invention`,
      },
      ...contentParagraphs,
    ];
  }

  getBackgroundParagraphs(state) {
    const patentSpecification = getPatentSpecification(state);
    const background =
      (patentSpecification && patentSpecification.background) || '';
    const content = getMentionsContent(state, background, 'background', {
      toLowerCase: true,
    });
    const paragraphs = this.splitParagraphs(content);
    const contentParagraphs = [];
    paragraphs.forEach((paragraph) => {
      contentParagraphs.push({
        type: 'content',
        content: this.sanitize(paragraph),
        numbered: true,
      });
    });
    return [
      {
        type: 'header',
        content: `Background`,
      },
      ...contentParagraphs,
    ];
  }

  getSummaryParagraphs(state) {
    const patentSpecification = getPatentSpecification(state);
    const summary = (patentSpecification && patentSpecification.summary) || '';
    const content = getMentionsContent(state, summary, 'summary', {
      toLowerCase: true,
    });
    const paragraphs = this.splitParagraphs(content);
    const contentParagraphs = [];
    paragraphs.forEach((paragraph) => {
      contentParagraphs.push({
        type: 'content',
        content: this.sanitize(paragraph),
        numbered: true,
      });
    });
    return [
      {
        type: 'header',
        content: `Summary`,
      },
      ...contentParagraphs,
    ];
  }

  getMethodRealizationParagraph(state, methodId) {
    const methodModel = this.store.peekRecord('method', methodId);
    const groupedStepsList = methodModel.groupedStepsList;
    const traversedNodesList = methodModel.traversedNodesList;
    const traversedEdgesList = methodModel.traversedEdgesList;

    const realization = getMethodRealization(
      state,
      methodId,
      groupedStepsList,
      traversedNodesList,
      traversedEdgesList
    );

    let content = getMentionsContent(
      state,
      realization,
      'detailed-description',
      {
        toLowerCase: true,
        appendOrdinal: true,
      }
    );

    content = this.sanitize(content);

    return {
      type: 'content',
      content: content,
      numbered: true,
    };
  }

  getTermParagraph(state, termId) {
    const term = getTerm(state, termId);

    const hasDescriptors = term.definitionsList.length ? true : false;

    const realization = hasDescriptors
      ? getTermRealization({
          state,
          termId,
        })
      : term.name;

    let content = getMentionsContent(
      state,
      realization,
      'detailed-description',
      { toLowerCase: true, appendOrdinal: true }
    );

    content = this.sanitize(content);

    return {
      type: 'content',
      content: content,
      numbered: true,
    };
  }

  getElementVersionParagraph(state, elementVersionId) {

    const elementVersion = getElementVersion(state, elementVersionId);
    const realizedFeatures = {};
    const elementId = elementVersion && elementVersion.element;
    const realizations = getElementVersionRealizations({
      state,
      elementId,
      elementVersionId,
      elementVersionIndex: 0,
      realizedFeatures,
      languageCategory: 'patent_specification',
    })

    const realization = realizations.join(' ');

    let content = getMentionsContent(
      state,
      realization,
      'detailed-description',
      {
        toLowerCase: true,
        appendOrdinal: true,
      }
    );

   content = this.sanitize(content);

    return {
      type: 'content',
      content: content,
      numbered: true,
    };
  }

  getFigureLegendParagraphs(state) {
    const mappedFiguresList = getMappedFiguresList(state);

    const figureLegendParagraphs = mappedFiguresList.map((figure) => {
      return this.getFigureLegendParagraph(state, figure, false);
    });

    const paragraphs = [
      {
        type: 'header',
        content: `Brief Description of the Drawings`,
      },
      ...figureLegendParagraphs,
    ];

    return paragraphs;
  }

  getFigureLegendParagraph(state, figure, appendOrdinal = false) {
    const figureId = figure.id;
    const figureType = figure.type;
    const sequence = figure.sequence;
    let content = '';
    let paragraph;

    if (figureType === 'method') {
      content = getMethodLegendRealization({
        state,
        sequence,
        methodId: figureId,
      });
    }

    if (figureType === 'drawing') {
      const drawing = getDrawing(state, figureId);
      const description = drawing && drawing.description;
      const viewAngle = drawing && drawing.viewAngle;
      const descriptionExample = `e.g. the ice cream sandwich in a melted state`;
      const viewAngleExample = `e.g. perspective`;
      content = getDrawingLegendRealization({
        state,
        sequence,
        drawingId: figureId,
        description,
        viewAngle,
        descriptionExample,
        viewAngleExample,
      });
    }

    content = getMentionsContent(state, content, 'figure-legend', {
      toLowerCase: true,
      appendOrdinal,
    });

    content = this.sanitize(content);

    if (content) {
      paragraph = {
        type: 'content',
        content,
        numbered: true,
      };
    } else {
      paragraph = {
        type: 'warning',
        content,
        numbered: true,
      };
    }

    return paragraph;
  }

  getDetailedDescriptionParagraphs(state) {
    const termsList = getTermsList(state);
    const mappedFiguresList = getMappedFiguresList(state);

    const paragraphs = [
      {
        type: 'header',
        content: `Detailed Description of the Invention`,
      },
    ];

    // boilerplate
    const boilerplateParagraphs = this.getBoilerplateParagraphs(state);
    boilerplateParagraphs.forEach((paragraph) => {
      paragraphs.push(paragraph);
    });

    // glossary
    termsList.forEach((termId) => {
      const termParagraph = this.getTermParagraph(state, termId);
      paragraphs.push(termParagraph);
    });

    // figures
    mappedFiguresList.forEach((figure) => {
      const figureLegendParagraph = this.getFigureLegendParagraph(
        state,
        figure,
        true
      );
      paragraphs.push(figureLegendParagraph);

      if (figure.type === 'method') {
        const methodRealizationParagraph = this.getMethodRealizationParagraph(
          state,
          figure.id
        );
        paragraphs.push(methodRealizationParagraph);
      }

      figure.elementVersionsList.forEach((elementVersionId) => {
        const elementVersionParagraph = this.getElementVersionParagraph(
          state,
          elementVersionId
        );

        if (elementVersionParagraph.content) {
          paragraphs.push(elementVersionParagraph);
        }
      });
    });

    return paragraphs;
  }

  getAbstractParagraphs(state) {
    const patentSpecification = getPatentSpecification(state);
    const abstract =
      (patentSpecification && patentSpecification.abstract) || '';
    const content = getMentionsContent(state, abstract, 'abstract');
    const paragraphs = this.splitParagraphs(content);
    const contentParagraphs = [];
    paragraphs.forEach((paragraph) => {
      contentParagraphs.push({
        type: 'content',
        content: this.sanitize(paragraph),
        numbered: true,
      });
    });
    return [
      {
        type: 'header',
        content: `ABSTRACT`,
      },
      ...contentParagraphs,
    ];
  }

  splitParagraphs(string) {
    const doc = new DOMParser().parseFromString(string, 'text/html');
    const arr = [...doc.body.childNodes].map((child) => child.textContent);
    return arr;
  }

  sanitize(string) {
    const doc = new DOMParser().parseFromString(
      `<div>${string}</div>`,
      'text/html'
    );
    const arr = [...doc.body.childNodes].map((child) => child.textContent);

    let text = arr.join('');

    // remove multiple spaces
    text = text.replace(/&nbsp;/g, ' ');
    text = text.replace(/\s\s+/g, ' ');
    // remove spaces before commas, and other punctuation
    text = text.replace(/\s+([.,;!?":])/g, '$1');
    // remove leading spaces
    text = text.replace(/^\s+/g, '');

    return text;
  }

  getLeadingZeros(number) {
    const digits = number.toString().length;
    switch (digits) {
      case 1:
        number = `000${number}`;
        break;
      case 2:
        number = `00${number}`;
        break;
      case 3:
        number = `0${number}`;
        break;
      case 4:
        number = `${number}`;
        break;
    }
    return `[${number}]`;
  }
}
