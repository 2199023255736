export default {
  attributes: {
    id: {
      type: 'uuid',
      defaultValue: null
    },
    name: {
      type: 'string',
      defaultValue: ''
    },
    type: {
      type: 'type',
      defaultValue: 'element'
    },
    category: {
      type: 'type',
      defaultValue: 'system' // 'part' or 'product'
    },
    categoryGroup: {
      type: 'type',
      defaultValue: '' // 'manufacturing' or 'use'
    },
    elementVersion: {
      type: 'uuid',
      defaultValue: null
    },
    x: {
      type: 'number',
      defaultValue: 0
    },
    y: {
      type: 'number',
      defaultValue: 0
    },
    outcome: {
      type: 'string',
      defaultValue: ''
    },
    requirementsList: {
      type: 'array',
      defaultValue: []
    },
    elementVersionsList: {
      type: 'array',
      defaultValue: []
    },
    component: {
      type: 'uuid',
      defaultValue: null
    },
    instanceOf: {
      type: 'uuid',
      defaultValue: null
    },
    isComponent: {
      type: 'boolean',
      defaultValue: false
    },
    useDefaultRequirements: {
      type: 'boolean',
      defaultValue: true
    },
    useDefaultOutcome: {
      type: 'boolean',
      defaultValue: true
    },
    createdAt: {
      type: 'date'
    },
  }
};




