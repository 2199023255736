import { ADD_FIGURES, UPDATE_FIGURES } from './actionTypes';

import { getFigures } from '../utils/figures';

export const addFigures = (state) => {
  const { breadth, depth, custom } = getFigures(state);

  return {
    type: ADD_FIGURES,
    payload: {
      breadth,
      depth,
      custom,
    },
  };
};

export const updateFigures = (state) => {
  const { breadth, depth, custom } = getFigures(state);
  return {
    type: UPDATE_FIGURES,
    payload: {
      breadth,
      depth,
      custom,
    },
  };
};
