// import {
//   selectElementAndElementVersion,
//   selectElementVersion,
// } from '../../../actions/invention-ui';

import {
  getActiveElementVersionListItem,
  getInventionUi,
} from '../../../selectors/invention-ui';

import Component from '@glimmer/component';
import { action } from '@ember/object';
import { computed } from '@ember/object';
import { connect } from 'ember-redux';
import { getContentOverflowed } from '../../../utils/general';
import { getElement } from '../../../selectors/element';
import { getPreferredElementVersion } from '../../../selectors/product';
import { getRootNodeId } from '../../../selectors/graph';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

const dispatchToActions = {};

const stateToComputed = (state, attrs) => ({
  element: getElement(state, attrs.elementId),
  rootNodeId: getRootNodeId(state),
  ui: getInventionUi(state),
  activeElementVersionListItem: getActiveElementVersionListItem(state),
  elementVersion: getPreferredElementVersion(
    state,
    attrs.elementId,
    attrs.productId
  ),
});

class ElementContext extends Component {
  @service data;
  @service tracking;
  @tracked isOverflowed = false;

  get styleNamespace() {
    return podNames['element-context'];
  }

  get classNames() {
    let classNames = [this.styleNamespace, 'element-context'];
    if (this.isOverflowed) classNames.push('is-overflowed');
    return classNames.join(' ');
  }

  @computed('rootNodeId', 'args.elementId')
  get isRoot() {
    return this.rootNodeId === this.args.elementId;
  }

  @computed('element.category')
  get showSuccessEvent() {
    return this.element && this.element.category === 'system';
  }

  @computed('ui.collapsedProblem')
  get isCollapsedProblem() {
    return this.ui && this.ui.collapsedProblem ? true : false;
  }

  @computed('ui.collapsedSolution')
  get isCollapsedSolution() {
    return this.ui && this.ui.collapsedSolution ? true : false;
  }

  @computed('ui.showingElementVersionSelector', 'elementVersion')
  get isShowingElementVersionSelector() {
    let showing =
      this.ui && this.ui.showingElementVersionSelector ? true : false;
    if (!this.elementVersion) {
      showing = true;
    }
    return showing;
  }

  @action
  onContentResize(sb) {
    this.isOverflowed = getContentOverflowed(sb);
  }

  @action
  onToggleCollapsedProblem() {
    this.data.setCollapsedProblem(!this.isCollapsedProblem);
  }

  @action
  onToggleCollapsedSolution() {
    this.data.setCollapsedSolution(!this.isCollapsedSolution);
  }

  @action
  onToggleShowingElementVersionSelector() {
    this.data.setShowingElementVersionSelector(
      !this.isShowingElementVersionSelector
    );
  }

  @action
  onRemoveElementVersion() {
    if (
      this.activeElementVersionListItem &&
      this.args.onRemoveSelectedTreeItems
    ) {
      this.args.onRemoveSelectedTreeItems(
        [],
        [this.activeElementVersionListItem],
        []
      );
      this.tracking.trackEvent('element_context_removed_solution');
    }
  }

  @action
  onCreateElementVersion() {
    if (this.args.onCreateElementVersion) {
      this.args.onCreateElementVersion(this.args.elementId);
      this.tracking.trackEvent('element_context_added_solution');
    }
  }

  @action
  onSetCategory(category) {
    if (this.args.onSetElementCategory) {
      this.args.onSetElementCategory(this.args.elementId, category);
    }
  }
}

export default connect(stateToComputed, dispatchToActions)(ElementContext);
