export default [
  {
    name: 'Drawing Description',
    conditions: {
      type: {
        is: 'figure-description'
      },
      subject: {
        is: 'drawing'
      },
      language: {
        is: 'patent_specification_preferred_version'
      }
    },
    event: {
      template: `{{{figure_name}}} illustrates {{{indefinite view_angle}}} view that shows
        {{{figure_description}}}, according to an embodiment of the present disclosure.`,
      required: ['figure_name', 'view_angle', 'figure_description']
    }
  },
  {
    name: 'Drawing Description - Report',
    conditions: {
      type: {
        is: 'figure-description'
      },
      subject: {
        is: 'drawing'
      },
      language: {
        is: 'report_list'
      }
    },
    event: {
      template: `{{{figure_name}}} is {{{indefinite view_angle}}} view that shows
        {{{figure_description}}}.`,
      required: ['figure_name', 'view_angle', 'figure_description']
    }
  },
  {
    name: 'Method Description',
    conditions: {
      type: {
        is: 'figure-description'
      },
      subject: {
        is: 'method'
      },
      language: {
        is: 'patent_specification_preferred_version'
      }
    },
    event: {
      template: `{{{figure_name}}} is a flowchart illustrating a process wherein {{{outcome}}} according to an embodiment of the present disclosure.`,
      required: ['figure_name', 'outcome']
    }
  },
  {
    name: 'Method Description - Report',
    conditions: {
      type: {
        is: 'figure-description'
      },
      subject: {
        is: 'method'
      },
      language: {
        is: 'report_list'
      }
    },
    event: {
      template: `{{{figure_name}}} is a flowchart illustrating a process wherein {{{outcome}}}.`,
      required: ['figure_name', 'outcome']
    }
  }
]
