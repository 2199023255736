import { blobUrlToBase64JPEG, getCanvasBlob } from '../utils/image';
// import { ThresholdFilter } from '../utils/filters';
import {
  defaultArtboardHeight,
  defaultArtboardWidth,
} from '../constants/settings';
import { enqueueTask, task } from 'ember-concurrency-decorators';
import { getEdgeNode, getStepNode } from '../utils/method-graph';
import {
  getMethodNodeLabel,
  getMethodNodeWithHeight,
  getSubmethodId,
} from '../selectors/method-node';
import {
  getPath,
  getPathArrowProperties,
  getPathCenterPoint,
  getPathOffCenterPoint,
} from '../utils/graph';

import ENV from '../config/environment';
import Konva from 'konva';
import Service from '@ember/service';
import { getMethod } from '../selectors/method';
import { getMethodEdge } from '../selectors/method-edge';
import { getMethodNodeOrdinal } from '../selectors/invention';
import { getNumberingIndex } from '../selectors/invention-ui';
import moment from 'moment';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { values } from 'lodash';

export default class FiguresCache extends Service {
  @service worker;
  @service imageFiltersCache;
  @service assets;
  @service redux;
  @service store;

  @tracked blobUrlCache;
  @tracked dataUrlCache;
  @tracked cachingWorkersCreated = false;

  isTest = ENV.environment === 'test';

  maxWorkers = 4;
  workerIndex = 0;

  constructor(owner, args) {
    super(owner, args);
    this.blobUrlCache = {};
    this.dataUrlCache = {};
  }

  // async createWorkers() {
  //   return
  //   if (!this.cachingWorkersCreated && !this.isTest) {
  //     for (let i = 0; i < this.maxWorkers; i++) {
  //       this['cachingWorker' + i] = await this.worker.open(
  //         'konva-reference-image'
  //       );
  //     }
  //     this.cachingWorkersCreated = true;
  //   }
  // }

  // terminateWorkers() {
  //   if (this.cachingWorkersCreated) {
  //     for (let i = 0; i < this.maxWorkers; i++) {
  //       if (this['cachingWorker' + i]) {
  //         console.log('terminating worker' + ' ' + i);
  //         this['cachingWorker' + i].terminate();
  //       }
  //     }
  //   }
  // }

  getArtboardDimensions(orientation) {
    const artboardWidth =
      orientation === 'portrait' ? defaultArtboardWidth : defaultArtboardHeight;
    const artboardHeight =
      orientation === 'portrait' ? defaultArtboardHeight : defaultArtboardWidth;

    return {
      artboardWidth,
      artboardHeight,
    };
  }

  getStageDimensions(orientation) {
    const stageWidth =
      orientation === 'portrait' ? defaultArtboardWidth : defaultArtboardHeight;
    const stageHeight =
      orientation === 'portrait' ? defaultArtboardHeight : defaultArtboardWidth;

    return {
      stageWidth,
      stageHeight,
    };
  }

  async getOnscreenCanvasBlob(data) {
    const {
      stageWidth,
      stageHeight,
      scale,
      boundsX,
      boundsY,
      artboardWidth,
      artboardHeight,
      edges,
      steps,
    } = data;
    const container = document.createElement('div');

    const stage = new Konva.Stage({
      container,
      x: stageWidth / 2,
      y: stageHeight / 2,
      width: stageWidth,
      height: stageHeight,
      scaleX: scale,
      scaleY: scale,
      offset: {
        x: boundsX,
        y: boundsY,
      },
    });

    const layer = new Konva.Layer({ name: 'images' });

    stage.add(layer);

    const group = new Konva.Group({
      x: stageWidth / 2,
      y: stageHeight / 2,
      offset: {
        x: stageWidth / 2,
        y: stageHeight / 2,
      },
    });

    layer.add(group);

    const artboardNode = new Konva.Rect({
      name: 'artboard',
      x: 0,
      y: 0,
      fill: '#FFFFFF',
      width: artboardWidth,
      height: artboardHeight,
      offset: {
        x: artboardWidth / 2,
        y: artboardHeight / 2,
      },
    });

    group.add(artboardNode);

    const edgeNodes = edges.map((edge) => {
      return getEdgeNode(edge);
    });
    edgeNodes.forEach(({ containerNode }) => group.add(containerNode));

    const stepNodes = steps.map((step) => {
      return getStepNode(step);
    });
    stepNodes.forEach(({ containerNode }) => group.add(containerNode));

    const canvas = stage._toKonvaCanvas()._canvas;
    const blob = await getCanvasBlob(canvas);

    stage.destroy();

    return blob;
  }

  getStepNumber(methodModel, stepModelId) {
    const step =
      methodModel &&
      methodModel.ungroupedStepsList.find(
        (step) => step.modelId === stepModelId
      );
    return step && step.number;
  }

  // TODO this doesn't work
  getStepOrdinal(methodModel, stepModelId) {
    const step =
      methodModel &&
      methodModel.ungroupedStepsList.find(
        (step) => step.modelId === stepModelId
      );
    return step && step.ordinal;
  }

  getMethodEdgeData(state, methodEdgeId, methodModel) {
    const methodEdge = getMethodEdge(state, methodEdgeId);
    const ordinal = this.getStepOrdinal(methodModel, methodEdgeId);
    const stepNumber = this.getStepNumber(methodModel, methodEdgeId);
    const isDisconnected =
      methodModel.disconnectedEdgesList.includes(methodEdgeId);
    const type = methodEdge.type;
    const source = getMethodNodeWithHeight(state, methodEdge.source);
    const sourcePosition = methodEdge.sourcePosition;
    const target = getMethodNodeWithHeight(state, methodEdge.target);
    const targetPosition = methodEdge.targetPosition;
    const bendPoints = values(methodEdge.bendPoints);
    const curve = methodEdge.curve;

    const path = getPath(
      source,
      sourcePosition,
      target,
      targetPosition,
      bendPoints,
      curve,
      null,
      true
    );
    const centerPoint = getPathCenterPoint(path);
    const offCenterPoint = getPathOffCenterPoint(path);
    const arrowProperties = getPathArrowProperties(path);
    const arrowX = arrowProperties.x;
    const arrowY = arrowProperties.y;
    const arrowRotation = arrowProperties.rotation;

    return {
      ...methodEdge,
      type,
      ordinal,
      stepNumber,
      isDisconnected,
      path,
      centerPoint,
      offCenterPoint,
      arrowX,
      arrowY,
      arrowRotation,
    };
  }

  getMethodNodeData(state, methodNodeId, methodModel) {
    const methodNode = getMethodNodeWithHeight(state, methodNodeId);
    const id = methodNodeId;
    const isDisconnected =
      methodModel.disconnectedNodesList.includes(methodNodeId);
    const index = 2;
    const ordinal = getMethodNodeOrdinal(
      state,
      methodModel.id,
      methodNodeId,
      methodModel.traversedNodesList
    );
    const isStartNode = methodNode.type === 'start';
    const label = getMethodNodeLabel(
      state,
      methodNodeId,
      null,
      null,
      isStartNode
    );
    const submethodId = getSubmethodId(state, methodNodeId);
    const submethodModel =
      submethodId && this.store.peekRecord('method', submethodId);
    const submethodHasNodes =
      submethodModel && submethodModel.methodNodesList.length > 1
        ? true
        : false;
    // const elementName = getMethodNodeElementName(state, methodNodeId);
    const elementName = 'getMethodNodeElementName(state, methodNodeId)';
    const stepNumber = this.getStepNumber(methodModel, methodNodeId);
    const x = methodNode.x;
    const y = methodNode.y;
    const width = methodNode.width;
    const height = methodNode.height;

    return {
      id,
      index,
      isStartNode,
      isDisconnected,
      submethodHasNodes,
      ordinal,
      label,
      elementName,
      stepNumber,
      x,
      y,
      width,
      height,
    };
  }

  @enqueueTask({ maxConcurrency: 6 })
  *getOffscreenCanvasBlob(methodId) {
    const state = this.redux.getState();

    const scale = 1;
    const boundsX = 0;
    const boundsY = 0;

    const method = getMethod(state, methodId);
    const methodModel = this.store.peekRecord('method', methodId);
    const methodEdgesList = [...method.methodEdgesList];
    const methodNodesList = [...method.methodNodesList];
    const orientation = method.orientation;

    const { stageWidth, stageHeight } = this.getStageDimensions(orientation);
    const { artboardWidth, artboardHeight } =
      this.getArtboardDimensions(orientation);
    const edges = methodEdgesList.map((methodEdgeId) =>
      this.getMethodEdgeData(state, methodEdgeId, methodModel)
    );

    const steps = methodNodesList.map((methodNodeId) =>
      this.getMethodNodeData(state, methodNodeId, methodModel)
    );

    const data = {
      stageWidth,
      stageHeight,
      scale,
      artboardWidth,
      artboardHeight,
      boundsX,
      boundsY,
      edges,
      steps,
    };

    const canOffscreenCanvas = typeof OffscreenCanvas !== 'undefined';

    let blob;

    if (canOffscreenCanvas) {
      this.workerIndex++;

      if (this.workerIndex >= this.maxWorkers) {
        this.workerIndex = 0;
      }

      let worker;

      if (this[`workerInstance${this.workerIndex}`]) {
        worker = this[`workerInstance${this.workerIndex}`];
      } else {
        worker = yield this.worker.open('konva-reference-method');
        this[`workerInstance${this.workerIndex}`] = worker;
      }

      blob = yield worker.postMessage(data);

      if (this.isTest) {
        worker.terminate();
        this[`workerInstance${this.workerIndex}`] = null;
      }
    } else {
      blob = yield this.getOnscreenCanvasBlob(data);
    }

    return blob;
  }

  async cacheUrls(methodId) {
    const state = this.redux.getState();
    const method = getMethod(state, methodId);
    const updatedAt = method.updatedAt || new Date();
    const numberingIndex = getNumberingIndex(state);
    const blob = await this.getOffscreenCanvasBlob.perform(methodId);

    // this.dataUrlCache[referenceId] = {
    //   dataUrl,
    //   updatedAt,
    // };

    const blobUrl = URL.createObjectURL(blob);

    this.blobUrlCache[methodId] = {
      blobUrl,
      updatedAt,
      numberingIndex,
    };
  }

  @task
  *getBlobUrl(methodId) {
    const state = this.redux.getState();
    const method = getMethod(state, methodId);
    const updatedAt = method.updatedAt;
    const numberingIndex = getNumberingIndex(state);

    let blobUrl;

    if (
      this.blobUrlCache[methodId] &&
      moment(updatedAt).isSame(this.blobUrlCache[methodId].updatedAt) &&
      numberingIndex === this.blobUrlCache[methodId].numberingIndex
    ) {
      blobUrl = this.blobUrlCache[methodId].blobUrl;
    } else {
      try {
        const blob = yield this.getOffscreenCanvasBlob.perform(methodId);
        blobUrl = URL.createObjectURL(blob);
        this.blobUrlCache[methodId] = {
          blobUrl,
          updatedAt,
        };
      } catch (err) {
        console.error(err);
      }
    }

    return blobUrl;
  }

  async getDataUrl(methodId) {
    const state = this.redux.getState();
    const method = getMethod(state, methodId);
    const updatedAt = method.updatedAt;

    let dataUrl;

    if (
      this.dataUrlCache[methodId] &&
      moment(updatedAt).isSame(this.dataUrlCache[methodId].updatedAt)
    ) {
      dataUrl = this.dataUrlCache[methodId].dataUrl;
    } else {
      try {
        const blob = await this.getOffscreenCanvasBlob.perform(methodId);
        const blobUrl = URL.createObjectURL(blob);
        const { artboardWidth, artboardHeight } = this.getArtboardDimensions(
          method.orientation
        );

        dataUrl = await blobUrlToBase64JPEG(
          blobUrl,
          artboardWidth,
          artboardHeight
        );

        this.dataUrlCache[methodId] = {
          dataUrl,
          updatedAt,
        };
      } catch (err) {
        console.error(err);
      }
    }
    return dataUrl;
  }
}
