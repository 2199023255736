import Component from '@glimmer/component';
import { connect } from 'ember-redux';
import podNames from 'ember-component-css/pod-names';
import { getProduct } from '../../../selectors/product';

const stateToComputed = (state, attrs) => ({
  product: getProduct(state, attrs.productId),
});

const dispatchToActions = {};

class ComparisonProductHeadCell extends Component {

  get styleNamespace() {
    return podNames['comparison-product-head-cell'];
  }

  get classNames() {
    let classNames = [this.styleNamespace, 'comparison-product-head-cell'];
    return classNames.join(' ');
  }

}

export default connect(
  stateToComputed,
  dispatchToActions
)(ComparisonProductHeadCell);
