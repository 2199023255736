import {
  ADD_INVENTION,
  SET_SHOWING_IMAGE_UPLOAD_MODAL,
  UPDATE_META,
  UPDATE_METHOD_IGNORE_UNDO,
  UPDATE_VERSION_TREE_COORDINATES,
} from '../actions/actionTypes';
import undoable, { excludeAction } from 'redux-undo';

import assets from './assets';
import { batchGroupBy } from '../utils/redux';
import { combineReducers } from 'redux';
import comparisons from './comparisons';
import components from './components';
import customers from './customers';
import deletedReferences from './deleted-references';
import drawings from './drawings';
import elementVersions from './element-versions';
import elements from './elements';
import features from './features';
import figures from './figures';
import graph from './graph';
import highlights from './highlights';
import images from './images';
import invention from './invention';
import markers from './markers';
import meta from './meta';
import methodEdgePoints from './method-edge-points';
import methodEdges from './method-edges';
import methodNodes from './method-nodes';
import methods from './methods';
import notes from './notes';
import patentSpecification from './patent-specification';
import presentation from './presentation';
import priorArts from './prior-arts';
import products from './products';
import reviews from './reviews';
import settings from './settings';
import terms from './terms';
import ui from './invention-ui';

const index = combineReducers({
  meta,
  settings,
  ui,
  invention,
  figures,
  presentation,
  products,
  components,
  elements,
  elementVersions,
  terms,
  features,
  drawings,
  images,
  markers,
  highlights,
  methods,
  methodNodes,
  methodEdges,
  methodEdgePoints,
  notes,
  customers,
  priorArts,
  comparisons,
  patentSpecification,
  graph,
  deletedReferences,
  reviews,
  assets,
});

export default undoable(index, {
  filter: excludeAction([
    UPDATE_META,
    UPDATE_VERSION_TREE_COORDINATES,
    UPDATE_METHOD_IGNORE_UNDO,
    SET_SHOWING_IMAGE_UPLOAD_MODAL,
    ADD_INVENTION,
  ]),
  limit: 99,
  groupBy: batchGroupBy.init(),
});
