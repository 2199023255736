import Component from '@glimmer/component';
import { connect } from 'ember-redux';
import { getFeature } from '../../../selectors/feature';
import podNames from 'ember-component-css/pod-names';

const stateToComputed = (state, attrs) => ({
  feature: getFeature(state, attrs.featureId),
});

const dispatchToActions = {};

class PreviewContextComprises extends Component {
  language = 'patent_specification_preferred_version';

  get styleNamespace() {
    return podNames['preview-context-comprises'];
  }

  get classNames() {
    let classNames = [this.styleNamespace, 'preview-context-comprises'];
    return classNames.join(' ');
  }
}

export default connect(
  stateToComputed,
  dispatchToActions
)(PreviewContextComprises);
