import Component from '@glimmer/component';
import { connect } from 'ember-redux';
import { getTerm } from '../../../selectors/term';
import podNames from 'ember-component-css/pod-names';

const stateToComputed = (state, attrs) => ({
  term: getTerm(state, attrs.termId),
});

const dispatchToActions = {};

class PreviewRealizationTerm extends Component {
  get styleNamespace() {
    return podNames['preview-realization-term'];
  }

  get classNames() {
    let classNames = [this.styleNamespace, 'preview-realization-term'];
    return classNames.join(' ');
  }
}

export default connect(
  stateToComputed,
  dispatchToActions
)(PreviewRealizationTerm);
