import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import ENV from '../../config/environment';


export default class DashboardRoute extends Route {
  @service session;
  @service sessionManager;
  @service usersDb;

  get userId() {
    return this.sessionManager.userId;
  }

  async model() {
    await this.usersDb.setup(this.userId);
  }

  beforeModel(transition) {
    this.session.requireAuthentication(transition, ENV.SIGN_IN_ROUTE);
  }
}
