import { get } from '@ember/object';
import { getElement } from './element';
import { getMethodNode } from './method-node';
import { getPresentState } from '../utils/redux';

export const getMethodsList = (state) =>
  get(getPresentState(state), `methods.ids`);

export const getMethod = (state, methodId) => {
  return get(getPresentState(state), `methods.entities.${methodId}`);
};

export const getMethods = (state) =>
  get(getPresentState(state), `methods.entities`);

export const getMethodIndex = (state, methodId) => {
  const methodsList = get(getPresentState(state), `methods.ids`);
  return methodsList.indexOf(methodId);
};

export const getMethodStartNodeId = (state, methodId) => {
  const method = getMethod(state, methodId);
  const methodNodesList =
    method && method.methodNodesList ? method.methodNodesList : [];
  return methodNodesList.find((methodNodeId) => {
    const methodNode = getMethodNode(state, methodNodeId);
    return methodNode && methodNode.type === 'start';
  });
};

export const getMethodOutcomeId = (state, methodId) => {
  const method = getMethod(state, methodId);
  const elementId =
    method && method.element;
    const element = elementId && getElement(state, elementId)
  return element && element.outcome;
};

