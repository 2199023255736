import {
  ADD_MARKER,
  UPDATE_MARKER,
} from './actionTypes';

import uuid from 'uuid/v4';

export const addMarker = (attributes, markerId = uuid()) => {
  return {
    type: ADD_MARKER,
    payload: {
      markerId,
      attributes,
    },
  };
};

export const updateMarker = (markerId, attributes) => {
  return {
    type: UPDATE_MARKER,
    payload: {
      markerId,
      attributes,
    },
  };
};

