import { UPDATE_METHOD_EDGE_POINT, ADD_METHOD_EDGE_POINT, REMOVE_METHOD_EDGE_POINT } from './actionTypes';
import uuid from 'uuid/v4';

export const addMethodEdgePoint = (methodId, methodEdgeId, x, y, index) => {
  const methodEdgePointId = uuid();

  const attributes = {
    x,
    y,
    methodEdge: methodEdgeId,
  };

  return {
    type: ADD_METHOD_EDGE_POINT,
    payload: {
      methodId,
      methodEdgeId,
      methodEdgePointId,
      attributes,
      index,
    },
  };
};

export const updateMethodEdgePoint = (
  methodEdgePointId,
  attributes
) => {
  return {
    type: UPDATE_METHOD_EDGE_POINT,
    payload: {
      methodEdgePointId,
      attributes,
    },
  };
};

export const removeMethodEdgePoint = (methodEdgePointId) => {
  return {
    type: REMOVE_METHOD_EDGE_POINT,
    payload: {
      methodEdgePointId
    },
  };
};
