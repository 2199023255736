import {
  BLACK_AND_WHITE_STROKE,
  HIGHLIGHT_FILL,
  HIGHLIGHT_STROKE,
} from '../../../constants/colors';
import { action, computed } from '@ember/object';

import Component from '@glimmer/component';
import Konva from 'konva';
import { connect } from 'ember-redux';
import { getHighlight } from '../../../selectors/highlight';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';

const dispatchToActions = {};

const stateToComputed = (state, attrs) => ({
  highlight: getHighlight(state, attrs.highlightId),
});

class PreviewHighlight extends Component {
  @service tracking;
  @service settings;
  tension = 1;

  constructor(owner, args) {
    super(owner, args);
    this.onScheduleRender = this.args.onScheduleRender;
  }

  @action
  didInsert() {
    this.setup();
    this._blackAndWhiteMode = this.settings.blackAndWhiteMode;
  }

  @action
  willDestroyNode() {
    this.lineNode.destroy();
    this.onScheduleRender();
  }

  get styleNamespace() {
    return podNames['preview-highlight'];
  }

  get classNames() {
    let classNames = [this.styleNamespace];
    return classNames.join(' ');
  }

  @computed('highlight.curve')
  get isCurved() {
    return this.highlight && this.highlight.curve === 'natural';
  }

  get fillColor() {
    let color = HIGHLIGHT_FILL;
    if (this.settings.blackAndWhiteMode) {
      color = 'transparent';
    }

    return color;
  }

  get strokeColor() {
    let color = HIGHLIGHT_STROKE;
    if (this.settings.blackAndWhiteMode) {
      color = BLACK_AND_WHITE_STROKE;
    }

    return color;
  }

  @computed('highlight.points')
  get points() {
    const points = [];
    const pointsArray = this.highlight.points.split(' ').map((pointString) => {
      const pointArray = pointString.split(',');
      const x = pointArray[0];
      const y = pointArray[1];
      return { x, y };
    });
    pointsArray.forEach((point) => {
      points.push(point.x);
      points.push(point.y);
    });
    return points;
  }

  setup() {
    const lineNode = new Konva.Line({
      id: this.args.highlightId,
      points: this.points,
      fill: this.fillColor,
      stroke: this.strokeColor,
      strokeWidth: 4,
      closed: true,
      bezier: this.isCurved ? true : false,
      tension: this.isCurved ? 0.00001 : 0,
      listening: false,
    });

    this.args.layer.add(lineNode);
    this.lineNode = lineNode;

    this.onScheduleRender();
  }

  @action
  onUpdate(elem, [blackAndWhiteMode]) {
    let detailsChanged = false;

    if (this._blackAndWhiteMode !== blackAndWhiteMode) {
      this._blackAndWhiteMode = blackAndWhiteMode;
      this.updateColors();
      detailsChanged = true;
    }

    if (detailsChanged) {
      this.onScheduleRender(this.args.layer);
    }
  }
}

export default connect(stateToComputed, dispatchToActions)(PreviewHighlight);
