import { action, computed } from '@ember/object';

import Component from '@glimmer/component';
import { connect } from 'ember-redux';
import { getCustomersList } from '../../../selectors/customer';
import { getInventionUi } from '../../../selectors/invention-ui';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

const dispatchToActions = {};

const stateToComputed = (state) => ({
  inventionUi: getInventionUi(state),
  customersList: getCustomersList(state),
});

class CustomersContext extends Component {
  @service applicationState;

  @tracked switched;

  get classNames() {
    let classNames = [this.styleNamespace, 'customers-context'];
    return classNames.join(' ');
  }

  get styleNamespace() {
    return podNames['customers-context'];
  }

  @computed('applicationState.undoIndex')
  get undoIndex() {
    return this.applicationState.undoIndex;
  }

  @computed('customersList.[]')
  get firstCustomer() {
    return (
      this.customersList && this.customersList.length && this.customersList[0]
    );
  }

  @computed(
    'inventionUi.selectedCustomers.[]',
    'firstCustomer',
    'creatingCustomer'
  )
  get selectedCustomerId() {
    let selectedCustomerId =
      (this.inventionUi &&
        this.inventionUi.selectedCustomers &&
        this.inventionUi.selectedCustomers[0]) ||
      this.firstCustomer;

    return this.creatingCustomer ? null : selectedCustomerId;
  }

  @action
  onUpdate() {
    this.switched = !this.switched;
  }

  @action
  onAddCustomer() {
    this.args.onAddCustomer();
  }

  @action
  onRemoveCustomer(customerId) {
    this.args.onRemoveCustomer(customerId);
  }
}

export default connect(stateToComputed, dispatchToActions)(CustomersContext);
