import Component from '@glimmer/component';
import { action } from '@ember/object';
import { getDataURL } from '../../../utils/image';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import uuid from 'uuid/v4';

export default class DisclosureCreateModal extends Component {
  @service assets;

  // @tracked enableCloudBackups = false;
  @tracked drawings = [];
  @tracked name = '';
  // @tracked description = '';
  @tracked productName = '';
  @tracked didValidate = false;
  @tracked isLoading = false;

  get styleNamespace() {
    return podNames['disclosure-create-modal'];
  }

  get classNames() {
    let classNames = [this.styleNamespace, 'disclosure-create-modal'];
    return classNames.join(' ');
  }

  get invalidProps() {
    return {
      name: !this.name
    };
  }

  get isValid() {
    return Object.keys(this.invalidProps).every((validation) => {
      return !this.invalidProps[validation];
    });
  }

  get isntValid() {
    return !this.isValid;
  }

  get nameIsntValid() {
    return this.didValidate && this.invalidProps.name;
  }

  @action
  hideModal() {
    this.args.onClose();
  }

  @action
  async onFileAdd(file) {
    const id = uuid();
    const blob = file.blob;
    file.queue.remove(file);
    const fileName = blob.name;
    const url = URL.createObjectURL(blob);
    const uri = await getDataURL(blob);
    const drawing = { id, url, uri, fileName };
    await this.assets.addAsset(id, blob);
    this.drawings.pushObject(drawing);
  }

  @action
  submit() {
    this.didValidate = true;
    if (this.isValid) {
      const data = {
        name: this.name,
        // description: this.description,
        productName: this.productName,
        // enableCloudBackups: this.enableCloudBackups,
        assets: this.drawings.map(drawing => drawing.id)
      }
      this.isLoading = true;
      this.args.onCreate(data);
    }
  }

  @action
  onRemoveDrawing(id) {
    this.drawings = this.drawings.filter((drawing) => drawing.id !== id);
  }

  @action
  updateName(name) {
    this.name = name;
  }

  // @action
  // updateDescription(description) {
  //   this.description = description;
  // }

  @action
  updateProductName(name) {
    this.productName = name;
  }

  // @action
  // onCloudBackupsChange() {
  //   this.enableCloudBackups = !this.enableCloudBackups;
  // }
}
