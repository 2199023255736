import {
  ADD_ASSET,
  UPDATE_ASSET,
  REMOVE_ASSET,
  SORT_ASSETS_LIST,
} from '../actions/actionTypes';
import { addFromSchema } from '../utils/schema';
import { omit } from 'lodash';

const initialState = {
  ids: [],
  entities: {},
};

const assetReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case ADD_ASSET: {
      let { assetId, attributes } = payload;
      const id = assetId;
      const createdAt = new Date();
      const updatedAt = new Date();
      const asset = addFromSchema('asset', {
        ...attributes,
        id,
        createdAt,
        updatedAt,
      });

      return {
        ids: [...state.ids, id],
        entities: {
          ...state.entities,
          [assetId]: asset,
        },
      };
    }

    case UPDATE_ASSET: {
      const { assetId, attributes } = payload;
      const updatedAt = new Date();
      return {
        ...state,
        entities: {
          ...state.entities,
          [assetId]: {
            ...state.entities[assetId],
            ...attributes,
            updatedAt,
          },
        },
      };
    }

    case REMOVE_ASSET: {
      const { assetId } = payload;
      return {
        ids: state.ids.filter((id) => id !== assetId),
        entities: omit(state.entities, assetId),
      };
    }

    case SORT_ASSETS_LIST: {
      const { assetIds } = payload;
      return {
        ...state,
        ids: assetIds,
      };
    }

    /* istanbul ignore next */
    default:
      return state;
  }
};

export default assetReducer;
