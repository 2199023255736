import {
  BLACK_AND_WHITE_STROKE,
  EDGE_CATEGORY_ARTICLE,
  EDGE_CATEGORY_ARTICLE_DARK,
  EDGE_CATEGORY_COMPOSITION,
  EDGE_CATEGORY_COMPOSITION_DARK,
  EDGE_CATEGORY_MACHINE,
  EDGE_CATEGORY_MACHINE_DARK,
  EDGE_CATEGORY_PROCESS,
  EDGE_CATEGORY_PROCESS_DARK,
  ELEMENT_EDGE_STROKE,
} from '../../../constants/colors';

import Component from '@glimmer/component';
import Konva from 'konva';
import { action } from '@ember/object';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';

class InventionGraphEdge extends Component {
  @service settings;

  _isShowing = '';

  constructor(owner, args) {
    super(owner, args);
    this.onScheduleRender = this.args.onScheduleRender;
    this.layer = this.args.layer;
    this._isShowing = this.args.isShowing;
    this.setup();
  }

  willDestroy() {
    this.edgeNode.destroy();
    // this.onScheduleRender();
  }

  get styleNamespace() {
    return podNames['ui-create-mode-arrow-canvas'];
  }

  get classNames() {
    let classNames = [this.styleNamespace];
    return classNames.join(' ');
  }

  setup() {
    const edgeNode = new Konva.Arrow({
      stroke: ELEMENT_EDGE_STROKE,
      id: 'create-mode-arrow',
      fill: ELEMENT_EDGE_STROKE,
      name: 'arrow',
      strokeWidth: 9,
      listening: false,
      transformsEnabled: 'position',
      visible: false,
    });

    this.layer.add(edgeNode);
    this.edgeNode = edgeNode;
  }

  getElementVersionStrokeColor(category) {
    let color = ELEMENT_EDGE_STROKE;

    if (category === 'machine') {
      color = this.settings.darkMode
        ? EDGE_CATEGORY_MACHINE_DARK
        : EDGE_CATEGORY_MACHINE;
    }
    if (category === 'process') {
      color = this.settings.darkMode
        ? EDGE_CATEGORY_PROCESS_DARK
        : EDGE_CATEGORY_PROCESS;
    }
    if (category === 'article-of-manufacture') {
      color = this.settings.darkMode
        ? EDGE_CATEGORY_ARTICLE_DARK
        : EDGE_CATEGORY_ARTICLE;
    }
    if (category === 'composition') {
      color = this.settings.darkMode
        ? EDGE_CATEGORY_COMPOSITION_DARK
        : EDGE_CATEGORY_COMPOSITION;
    }

    if (this.settings.blackAndWhiteMode) {
      color = BLACK_AND_WHITE_STROKE;
    }
    return color;
  }

  @action
  onUpdate(elem, [isShowing]) {
    let detailsChanged = false;

    if (this._isShowing !== isShowing) {
      this._isShowing = isShowing;
      detailsChanged = true;

      if (isShowing) {
        this.edgeNode.fill(
          this.getElementVersionStrokeColor(this.args.category)
        );
        this.edgeNode.stroke(
          this.getElementVersionStrokeColor(this.args.category)
        );
        this.edgeNode.visible(true);
      } else {
        this.edgeNode.visible(false);
      }
    }

    if (detailsChanged) {
      this.layer.batchDraw();
    }
  }
}

export default InventionGraphEdge;
