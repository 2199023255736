import Component from '@glimmer/component';
import { action } from '@ember/object';
import podNames from 'ember-component-css/pod-names';

class UiSelectInput extends Component {
  get classNames() {
    let classNames = [this.styleNamespace, 'ui-select-input'];
    return classNames.join(' ');
  }

  get styleNamespace() {
    return podNames['ui-select-input'];
  }

  @action
  handlePressKey(e, sb) {
    sb.activateOptionForKeyCode(e.keyCode);

    if (!sb.isOpen) {
      sb.selectActiveOption();
    }
  }

  @action
  handlePressUp(e, sb) {
    e.preventDefault();
    sb.activatePreviousOption();
  }

  @action
  handlePressDown(e, sb) {
    e.preventDefault();
    sb.activateNextOption();
    sb.open();
  }

  @action
  close(e, sb) {
    sb.close();
  }

  @action
  select(value, sb) {
    this.args.onSelect(value);
    sb.close();
  }

}

export default UiSelectInput;
