import {
  BLACK_AND_WHITE_STROKE,
  METHOD_NODE_STROKE,
  METHOD_NODE_STROKE_DISCONNECTED,
  PRIMARY_COLOR,
  PRIMARY_COLOR_OPAQUEST,
} from '../../../constants/colors';
import { alias, bool } from '@ember/object/computed';

import Component from '@glimmer/component';
import Konva from 'konva';
import { action } from '@ember/object';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';

class MethodBendPoint extends Component {
  @service applicationState;
  @service settings;

  constructor(owner, args) {
    super(owner, args);
    this.onScheduleRender = this.args.onScheduleRender;
  }

  @action
  didInsert() {
    this.setup();
    this._isVisible = this.args.isVisible;
    this._edgeIsSelected = this.args.edgeIsSelected;
    this._isSelected = this.args.isSelected;
    this._isDisconnected = this.args.isDisconnected;
    this._x = this.x;
    this._y = this.y;
    this._isHovered = false;
    this._undoIndex = this.undoIndex;
  }

  @action
  willDestroyNode() {
    this.containerNode.off('click');
    this.containerNode.off('mouseenter');
    this.containerNode.off('mouseleave');
    this.containerNode.off('dragstart');
    this.containerNode.off('dragmove');
    this.containerNode.off('dragend');
    this.containerNode.destroy();
    this.onScheduleRender();
  }

  get styleNamespace() {
    return podNames['method-bend-point'];
  }

  get classNames() {
    let classNames = [this.styleNamespace, 'method-bend-point'];
    if (this.args.isSelected) {
      classNames.push('is-selected');
    } else {
      classNames.push('isnt-selected');
    }
    return classNames.join(' ');
  }

  @bool('args.onDragEnd') isDraggable;

  @alias('applicationState.undoIndex') undoIndex;

  get strokeColor() {
    let color = METHOD_NODE_STROKE;

    if (this.args.isDisconnected) {
      color = METHOD_NODE_STROKE_DISCONNECTED;
    }

    if (this.args.isSelected) {
      color = PRIMARY_COLOR;
    }

    if (this.args.edgeIsSelected) {
      color = PRIMARY_COLOR;
    }

    if (this.settings.blackAndWhiteMode) {
      color = BLACK_AND_WHITE_STROKE;
    }
    return color;
  }

  get hoveredStrokeColor() {
    let color = 'transparent';

    if (this._isHovered) {
      color = PRIMARY_COLOR_OPAQUEST;
    }

    if (this.args.isSelected) {
      color = PRIMARY_COLOR_OPAQUEST;
    }

    return color;
  }

  get fillColor() {
    let color = '#FFFFFF';
    return color;
  }

  setup() {
    const containerNode = new Konva.Group({
      nodeType: 'methodEdgePoint',
      x: this.args.x,
      y: this.args.y,
      visible: this.args.isVisible,
      draggable: true,
    });

    const touchNode = new Konva.Circle({
      strokeScaleEnabled: false,
      fill: 'transparent',
      listening: true,
      radius: 10,
    });

    const strokeNode = new Konva.Circle({
      nodeType: 'methodEdgePoint',
      stroke: this.hoveredStrokeColor,
      strokeWidth: 8,
      strokeScaleEnabled: false,
      fill: 'transparent',
      listening: true,
      radius: 6,
    });

    const pointNode = new Konva.Circle({
      nodeType: 'methodEdgePoint',
      stroke: this.strokeColor,
      strokeWidth: 2,
      fill: this.fillColor,
      listening: true,
      radius: 6,
    });

    this.args.layer.add(containerNode);

    containerNode.add(touchNode);
    containerNode.add(strokeNode);
    containerNode.add(pointNode);

    this.containerNode = containerNode;
    this.strokeNode = strokeNode;
    this.pointNode = pointNode;

    if (this.args.onDragStart) {
      containerNode.on('dragstart', () => {
        this.args.onDragStart(this.args.bendPointId);
      });
    }

    if (this.args.onDrag) {
      containerNode.on('dragmove', () => {
        const x = containerNode.x();
        const y = containerNode.y();
        this.args.onDrag(this.args.bendPointId, x, y);
      });
    }

    if (this.args.onDragEnd) {
      containerNode.on('dragend', () => {
        const x = containerNode.x();
        const y = containerNode.y();
        this.args.onDragEnd(this.args.bendPointId, x, y);
      });
    }

    if (this.args.onClick) {
      containerNode.on('click', () =>
        this.args.onClick(this.args.bendPointId, this.args.isSelected)
      );
    }

    containerNode.on('mouseenter', () => {
      this._isHovered = true;
      this.updateColors();
    });

    containerNode.on('mouseleave', () => {
      this._isHovered = false;
      this.updateColors();
    });

    this.onScheduleRender();
  }

  updateColors() {
    this.strokeNode.stroke(this.hoveredStrokeColor);
    this.pointNode.stroke(this.strokeColor);
    this.pointNode.fill(this.fillColor);
  }

  updatePosition() {
    this.containerNode.x(this.args.x);
    this.containerNode.y(this.args.y);
  }

  @action
  onUpdate(
    elem,
    [isVisible, edgeIsSelected, isSelected, isDisconnected, x, y, undoIndex]
  ) {
    let detailsChanged = false;

    if (this._isVisible !== isVisible) {
      this._isVisible = isVisible;
      this.containerNode.visible(isVisible);
      detailsChanged = true;
    }

    if (this._edgeIsSelected !== edgeIsSelected) {
      this._edgeIsSelected = edgeIsSelected;
      this.updateColors();
      detailsChanged = true;
    }

    if (this._isSelected !== isSelected) {
      this._isSelected = isSelected;
      this.updateColors();
      detailsChanged = true;
    }

    if (this._isDisconnected !== isDisconnected) {
      this._isDisconnected = isDisconnected;
      this.updateColors();
      detailsChanged = true;
    }

    if (this._x !== x || this._y !== y) {
      this._x = x;
      this._y = y;
      this.updatePosition();
      detailsChanged = true;
    }

    if (this._undoIndex !== undoIndex && (this._x !== x || this._y !== y)) {
      this._undoIndex = undoIndex;
      this.updatePosition();
      detailsChanged = true;
    }

    if (detailsChanged) {
      this.onScheduleRender(this.args.layer);
    }
  }
}

export default MethodBendPoint;
