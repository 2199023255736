import { max, min } from 'lodash';

import Component from '@glimmer/component';
import { get } from '@ember/object';
import podNames from 'ember-component-css/pod-names';

class DisclosurePreview extends Component {
  get styleNamespace() {
    return podNames['disclosure-preview'];
  }

  get classNames() {
    let classNames = [this.styleNamespace, 'disclosure-preview'];
    return classNames.join(' ');
  }

  get elementsList() {
    return get(this.args.state, 'elements.ids') || [];
  }

  get elements() {
    return get(this.args.state, 'elements.entities') || {};
  }

  get orphanNodesList() {
    return get(this.args.state, 'graph.orphanNodesList') || [];
  }

  get nodes() {
    return this.elementsList.map(
      (elementId) => this.elements[elementId]
    );
  }

  get artboardPadding() {
    return this.nodes.length === 1 ? 750 : 320;
  }

  get minX() {
    const xValues = this.nodes.length ? this.nodes.mapBy('x') : [0];
    return min(xValues) - this.artboardPadding;
  }

  get minY() {
    const yValues = this.nodes.length ? this.nodes.mapBy('y') : [0];
    return min(yValues) - this.artboardPadding;
  }

  get maxX() {
    const xValues = this.nodes.length ? this.nodes.mapBy('x') : [0];
    return max(xValues) + this.artboardPadding;
  }

  get maxY() {
    const yValues = this.nodes.length ? this.nodes.mapBy('y') : [0];
    return max(yValues) + this.artboardPadding;
  }

  get artboardWidth() {
    return Math.abs(this.maxX - this.minX);
  }

  get artboardHeight() {
    return Math.abs(this.maxY - this.minY);
  }

  get artboardX() {
    return this.minX;
  }

  get artboardY() {
    return this.minY;
  }

  get viewBox() {
    return `${this.artboardX} ${this.artboardY} ${this.artboardWidth} ${this.artboardHeight}`;
  }
}

export default DisclosurePreview;
