import { getFeatureRealization, hasValue } from '../../../utils/realization';

import Component from '@glimmer/component';
import FeatureTypes from '../../../constants/types/features';
import { action } from '@ember/object';
import { connect } from 'ember-redux';
import { getElement } from '../../../selectors/element';
import { getFeature } from '../../../selectors/feature';
import { getTerm } from '../../../selectors/term';
import { once } from '@ember/runloop';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

const dispatchToActions = {};

const stateToComputed = (state, attrs) => ({
  element: getElement(state, attrs.elementId),
  term: getTerm(state, attrs.termId),
});

class PreviewFeatureRealization extends Component {
  @service models;
  @service applicationState;

  @tracked realization = '';

  @action
  didInsert() {
    this.updateRealization();
  }

  @action
  onUpdate() {
    once(this, 'updateRealization');
  }

  get featuresList() {
    return this.args.featureId
      ? [this.args.featureId]
      : this.args.featuresList || [];
  }

  get feature() {
    const state = this.redux.getState();
    return this.featuresList[0] && getFeature(state, this.featuresList[0])
  }

  get type() {
    const type = this.feature && this.feature.type;
    return type && FeatureTypes[type];
  }

  get typeId() {
    return this.type && this.type.id;
  }

  updateRealization() {
    this.realization = this.getRealization();
  }

  getRealization() {
    const state = this.redux.getState();

    let realization = '';
    let featuresList = this.args.featureId
      ? [this.args.featureId]
      : this.args.featuresList || [];

    if (this.args.filterEmpty) {
      featuresList = featuresList.filter((featureId) => {
        const feature = getFeature(state, featureId);
        return feature && hasValue(feature.value);
      });
    }

    if (featuresList.length) {
      realization =
        this.typeId &&
        getFeatureRealization({
          state,
          featuresList,
          featureTypeId: this.typeId,
          elementId: this.args.elementId,
          termId: this.args.termId,
          language: this.args.language,
        });
    }

    return realization;
  }
}

export default connect(
  stateToComputed,
  dispatchToActions
)(PreviewFeatureRealization);
