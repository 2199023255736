import { action, computed } from '@ember/object';

import Component from '@glimmer/component';
import { connect } from 'ember-redux';
import { getActiveFeature } from '../../../selectors/invention-ui';
import { getTerm } from '../../../selectors/term';
import podNames from 'ember-component-css/pod-names';

const stateToComputed = (state, attrs) => ({
  activeFeatureId: getActiveFeature(state),
  term: getTerm(state, attrs.termId),
});

const dispatchToActions = {};

class TermContextDefinitions extends Component {

  get styleNamespace() {
    return podNames['term-context-definitions'];
  }

  @computed('term.definitionsList', 'activeFeatureId')
  get hasActive() {
    return (
      this.activeFeatureId &&
      this.term.definitionsList.includes(this.activeFeatureId)
    );
  }

  @action
  removeDefinition(featureId) {
    this.args.onRemoveTermDefinition(this.args.termId, featureId);
  }

  @action
  createDefinition(type) {
    this.args.onAddTermDefinition(this.args.termId, type);
  }

  @action
  onSortDefinitionsList(definitionsList) {
    this.args.onUpdateTerm(this.args.termId, { definitionsList });
  }
}

export default connect(
  stateToComputed,
  dispatchToActions
)(TermContextDefinitions);
