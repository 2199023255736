import {
  ADD_FEATURE,
  UPDATE_FEATURE,
  REMOVE_FEATURE,
  REMOVE_FEATURES,
} from '../actions/actionTypes';
import { addFromSchema } from '../utils/schema';
import { omit } from 'lodash';

const initialState = {
  ids: [],
  entities: {},
};

const featuresReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case ADD_FEATURE: {
      let { featureId, attributes } = payload;
      const id = featureId;
      const createdAt = new Date();
      const updatedAt = new Date();
      const feature = addFromSchema('feature', {
        id,
        ...attributes,
        createdAt,
        updatedAt,
      });

      return {
        ids: [...state.ids, id],
        entities: {
          ...state.entities,
          [featureId]: feature,
        },
      };
    }

    case UPDATE_FEATURE: {
      const { featureId, attributes } = payload;
      const updatedAt = new Date();

      return {
        ...state,
        entities: {
          ...state.entities,
          [featureId]: {
            ...state.entities[featureId],
            ...attributes,
            updatedAt,
          },
        },
      };
    }

    case REMOVE_FEATURE: {
      const { featureId } = payload;
      return {
        ids: state.ids.filter((id) => id !== featureId),
        entities: omit(state.entities, featureId),
      };
    }

    case REMOVE_FEATURES:  {
      const { featuresList } = payload;
      return {
        ids: state.ids.filter((id) => !featuresList.includes(id)),
        entities: omit(state.entities, featuresList),
      };
    }

    /* istanbul ignore next */
    default:
      return state;
  }
};

export default featuresReducer;
