import Component from '@glimmer/component';
import { connect } from 'ember-redux';
import { getMarker } from '../../../selectors/marker';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';

const stateToComputed = (state, attrs) => ({
  marker: getMarker(state, attrs.markerId),
});

const dispatchToActions = {};

class MarkerContext extends Component {
  @service tracking;

  get styleNamespace() {
    return podNames['preview-context-marker'];
  }

  get classNames() {
    let classNames = [this.styleNamespace];
    return classNames.join(' ');
  }

}

export default connect(stateToComputed, dispatchToActions)(MarkerContext);
