import Service from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class ApplicationState extends Service {
  @tracked undoIndex = 0;
  @tracked autoArrangeIndex = 0;
  @tracked methodAutoArrangeIndex = 0;
  @tracked focusedDomElement = null;
  @tracked focusedInputModelId = null;
  @tracked focusedInputModelType = null;
  @tracked focusedInputModelAttr = null;
  @tracked focusedInputFeatureType = null;
  @tracked shiftKeyDown = false;
  @tracked cmdKeyDown = false;
  @tracked collapseFirstVersion = true;
  @tracked zoomToNodeIndex = 0;
  @tracked zoomToNode = null;
  @tracked presentationZoomToNodeIndex = 0;
  @tracked presentationZoomToNode = null;
  @tracked updatedFeaturesIndex = 0;
  @tracked isExplorerDragging = false;
}