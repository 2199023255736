export default {
  attributes: {
    inventionsList: {
      type: 'array',
      defaultValue: []
    },
    archivedInventionsList: {
      type: 'array',
      defaultValue: []
    }
  }
}
