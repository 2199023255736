import Component from '@ember/component';
import { connect } from 'ember-redux';
// import { ActionCreators } from 'redux-undo';
// import { inject as service } from '@ember/service';

const dispatchToActions = function(/*dispatch*/) {
  return {
    // undo: () => dispatch(ActionCreators.undo()),
    // redo: () => dispatch(ActionCreators.redo()),
  };
};

const DebugWorkArea = Component.extend({
  classNames: ['work-area'],
  // redux: service(),
  // actions: {
  //   purge() {
  //     this.redux.purgeState();
  //   },
  // }
});

export default connect(null, dispatchToActions)(DebugWorkArea);
