import { action, computed } from '@ember/object';

import Component from '@glimmer/component';
import { connect } from 'ember-redux';
import { getElementVersion } from '../../../selectors/element-version';
import { htmlSafe } from '@ember/string';
import podNames from 'ember-component-css/pod-names';

const dispatchToActions = {};

const stateToComputed = (state, attrs) => ({
  elementVersion: getElementVersion(state, attrs.elementVersionId),
});

class ElementVersionListItem extends Component {
  // args:
  // depth
  // _elementId
  // name
  // showMenu
  // isCollapsible

  depthPaddingWidth = 10;

  constructor() {
    super(...arguments); // super must be called first.
    this.depth = this.args.depth || 1;
    this.showMenu = this.args.showMenu || false;
    this.isCollapsible = this.args.isCollapsible;
  }

  get styleNamespace() {
    return podNames['element-version-list-item'];
  }

  @computed('args.{isSelected,isPreferred,isNested}', 'styleNamespace', 'elementVersion.category')
  get classNames() {
    let classNames = [this.styleNamespace, 'element-version-list-item'];
    if (this.args.isSelected) classNames.push('is-selected');
    if (this.args.isPreferred) classNames.push('is-preferred');
    if (this.args.isNested) classNames.push('is-nested');
    if (this.elementVersion && this.elementVersion.category === 'machine') classNames.push('is-machine');
    if (this.elementVersion && this.elementVersion.category === 'process') classNames.push('is-process');
    if (this.elementVersion && this.elementVersion.category === 'article-of-manufacture') classNames.push('is-article-of-manufacture');
    if (this.elementVersion && this.elementVersion.category === 'composition') classNames.push('is-composition');

    return classNames.join(' ');
  }

  get depthPadding() {
    return (this.depth - 1) * this.depthPaddingWidth;
  }

  get depthStyle() {
    return htmlSafe(`margin-left: ${this.depthPadding}px`);
  }

  get hasChildren() {
    return this.args.children && this.args.children.length;
  }

  get showCaret() {
    return this.isCollapsible && this.hasChildren;
  }

  @action
  onBackgroundClick() {
    if (this.args.onBackgroundClick) {
      this.args.onBackgroundClick(this.args.elementVersionId, this.args.isSelected);
    }
  }

  @action
  onClick() {
    if (this.args.onClick) {
      this.args.onClick(this.args.elementVersionId, this.args.isSelected);
    }
  }

  @action
  onPreferredClick() {
    if (this.args.onPreferredClick) {
      this.args.onPreferredClick(
        this.args.elementVersionId
      );
    }
  }
}
export default connect(
  stateToComputed,
  dispatchToActions
)(ElementVersionListItem);
