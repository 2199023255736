import {
  ADD_ELEMENT,
  REMOVE_ELEMENT,
  UPDATE_ELEMENT,
  UPDATE_ELEMENT_AND_ELEMENT_VERSION,
} from './actionTypes';

import uuid from 'uuid/v4';

export const addElement = (
  attributes,
  elementId = uuid()
) => {
  return {
    type: ADD_ELEMENT,
    payload: {
      elementId,
      attributes
    },
  };
};


export const updateElement = (elementId, attributes) => {
  return {
    type: UPDATE_ELEMENT,
    payload: {
      elementId,
      attributes,
    },
  };
};
export const updateElementAndElementVersion = (
  elementId,
  elementAttributes,
  elementVersionId,
  elementVersionAttributes
) => {
  return {
    type: UPDATE_ELEMENT_AND_ELEMENT_VERSION,
    payload: {
      elementId,
      elementAttributes,
      elementVersionId,
      elementVersionAttributes,
    },
  };
};

export const removeElement = (elementId, name) => {
  return {
    type: REMOVE_ELEMENT,
    payload: {
      elementId,
      name,
    },
  };
};
