import ENV from '../config/environment';

export default {
  attributes: {
    disclosure: {
      type: 'uuid',
      defaultValue: null,
    },
    instance: {
      type: 'uuid',
      defaultValue: null,
    },
    user: {
      type: 'uuid',
      defaultValue: null,
    },
    sessionTime: {
      type: 'number',
      defaultValue: 0,
    },
    releaseVersion: {
      type: 'string',
      defaultValue: ENV.releaseVersion,
    },
    schemaVersion: {
      type: 'number',
      defaultValue: ENV.schemaVersion,
    },
  },
};