import { action, computed } from '@ember/object';
import {
  getDefaultMarkerCategory,
  getSelectedElementId,
  getSelectedTermId,
} from '../../../selectors/invention-ui';
import { getElement, getElementName } from '../../../selectors/element';

import Component from '@glimmer/component';
import { connect } from 'ember-redux';
import { getTerm } from '../../../selectors/term';
import { or } from '@ember/object/computed';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

const dispatchToActions = {};

const stateToComputed = (state) => ({
  defaultMarkerCategory: getDefaultMarkerCategory(state),
  selectedElementId: getSelectedElementId(state),
  selectedTermId: getSelectedTermId(state),
});

class DrawingCreateOptions extends Component {
  @service redux;

  @tracked bulkCreateMode = false;

  @or('selectedElement', 'selectedTermId') selectedItem;

  @computed('selectedElementId')
  get selectedElement() {
    const state = this.redux.getState();
    return this.selectedElementId && getElement(state, this.selectedElementId);
  }

  @computed('selectedTermId')
  get selectedTerm() {
    const state = this.redux.getState();
    return this.selectedTermId && getTerm(state, this.selectedTermId);
  }

  @computed('selectedElement.id')
  get selectedElementName() {
    const state = this.redux.getState();
    return (
      this.selectedElement && getElementName(state, this.selectedElement.id)
    );
  }

  get styleNamespace() {
    return podNames['drawing-create-options'];
  }

  get classNames() {
    let classNames = [this.styleNamespace];
    return classNames.join(' ');
  }

  @action
  onSelectCategory(category) {
    this.args.onSetDefaultMarkerCategory(category);
  }
}

export default connect(
  stateToComputed,
  dispatchToActions
)(DrawingCreateOptions);
