import RESTAdapter from '@ember-data/adapter/rest';
import { inject as service } from '@ember/service';
import { computed } from '@ember/object';
import ENV from '../config/environment';

export default class ApplicationAdapter extends RESTAdapter {
  @service session;
  host = ENV.IDENTITY_API_URL;

  // eslint-disable-next-line ember/use-brace-expansion
  @computed('session.data.authenticated.access_token', 'session.isAuthenticated')
  get headers() {
    const headers = {};
    if (this.session.isAuthenticated) {
      headers[
        'Authorization'
      ] = `${this.session.data.authenticated.access_token}`;
    }
    return headers;
  }
}
