import Component from '@glimmer/component';
import podNames from 'ember-component-css/pod-names';
import { connect } from 'ember-redux';
import { htmlSafe } from '@ember/string';
const dispatchToActions = {};

const stateToComputed = null;

class ComparisonFeatureTypeCell extends Component {

  depthPaddingWidth = 12;

  get styleNamespace() {
    return podNames['comparison-feature-type-cell'];
  }

  get classNames() {
    let classNames = [this.styleNamespace, 'feature-type-cell', 'product-cell'];
    return classNames.join(' ');
  }

  get depthPadding() {
    return this.args.depth && (this.args.depth - 1) * this.depthPaddingWidth;
  }

  get depthStyle() {
    return this.args.depth ? htmlSafe(`margin-left: ${this.depthPadding}px`) : '';
  }
}

export default connect(stateToComputed, dispatchToActions)(ComparisonFeatureTypeCell);
