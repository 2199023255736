import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import getCmdKey from 'ember-keyboard/utils/get-cmd-key';

export default class KeydownPoll extends Component {
  @service applicationState;

  _cmdKey = `${getCmdKey()}Key`;

  constructor() {
    super(...arguments); // super must be called first.
    this._mousemoveListener = this.checkForKeydowns.bind(this);
  }

  checkForKeydowns(e) {

    if (!e) e = window.event;

    // if the cmd key is not down, but we're still in cmdKeyDown mode for some reason
    // then turn off cmdKeyDown
		// if (!e[this._cmdKey] && this.applicationState.cmdKeyDown) {
		// 	this.applicationState.set('cmdKeyDown', false);
		// }
		if (!e.altKey && this.applicationState.cmdKeyDown) {
			this.applicationState.set('cmdKeyDown', false);
		}

    // if the shift key is not down, but we're still in shiftKeyDown mode for some reason
    // then turn off shiftKeyDown
		if (!e.shiftKey && this.applicationState.shiftKeyDown) {
			this.applicationState.set('shiftKeyDown', false);
		}

    // if the alt key is not down, but we're still in altKeyDown mode for some reason
    // then turn off altKeyDown
		// if (!e.altKey && this.applicationState.altKeyDown) {
		// 	this.applicationState.set('altKeyDown', false);
		// }
  }

  @action
  didInsert() {
    window.addEventListener('mousemove', this._mousemoveListener);
  }

  willDestroy() {
    super.willDestroy(...arguments);
    window.removeEventListener('mousemove', this._mousemoveListener);
  }

}
