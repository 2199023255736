import { action, computed } from '@ember/object';

import Component from '@glimmer/component';
import { connect } from 'ember-redux';
import { getTerm } from '../../../selectors/term';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';

const stateToComputed = (state, attrs) => ({
  term: getTerm(state, attrs.termId),
});

const dispatchToActions = {};

class TermListItem extends Component {
  @service models;
  @service applicationState;

  showMenu = true;

  get styleNamespace() {
    return podNames['term-list-item'];
  }

  get classNames() {
    let classNames = [this.styleNamespace, 'term-list-item'];
    return classNames.join(' ');
  }

  @computed('applicationState.shiftKeyDown')
  get shiftKeyDown() {
    return this.applicationState.shiftKeyDown;
  }

  @computed('shiftKeyDown')
  get multiSelectMode() {
    return this.shiftKeyDown;
  }

  @computed('termModel.name')
  get name() {
    return this.termModel.name;
  }

  @computed('args.termId', 'term')
  get termModel() {
    return (
      this.models.find(this.args.termId) ||
      this.models.findOrCreate(this.args.termId, 'term', this.term)
    );
  }

  @action
  onContextMenu(event) {
    if (this.args.onContextMenu) {
      this.args.onContextMenu(event, this.args.termId);
    }
  }

  @action
  onClick() {
    if (this.args.onSelect) {
      this.args.onSelect(this.args.termId, this.args.isSelected);
    }
  }
}

export default connect(stateToComputed, dispatchToActions)(TermListItem);
