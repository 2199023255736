import Component from '@ember/component';
import FeatureTypes from '../../../constants/types/features';
import { computed } from '@ember/object';
import { connect } from 'ember-redux';
import { getFeature } from '../../../selectors/feature';
import podNames from 'ember-component-css/pod-names';

const dispatchToActions = {};

const stateToComputed = (state, attrs) => ({
  feature: getFeature(state, attrs.featureId),
});

class FeatureValueMeasurement extends Component {
  get styleNamespace() {
    return podNames['feature-value-measurement'];
  }

  get classNames() {
    let classNames = [this.styleNamespace];
    return classNames.join(' ');
  }

  @computed('feature.type')
  get type() {
    return this.feature && FeatureTypes[this.feature.type];
  }

  @computed('type.inputs.measurement_operator.options')
  get options() {
    return (
      this.type &&
      this.type.inputs &&
      this.type.inputs.measurement_operator.options
    );
  }

  @computed('feature.value.measurement_type')
  get measurementType() {
    return (
      this.feature && this.feature.value && this.feature.value.measurement_type
    );
  }

  @computed('feature.value.measurement_value')
  get measurementValue() {
    return (
      this.feature && this.feature.value && this.feature.value.measurement_value
    );
  }

  @computed('feature.value.measurement_operator', 'options.[]')
  get measurementOperator() {
    const options = this.options || [];
    return options.find(
      (option) => option.id === this.feature.value.measurement_operator
    );
  }
}
export default connect(
  stateToComputed,
  dispatchToActions
)(FeatureValueMeasurement);
