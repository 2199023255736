import Component from '@ember/component';

const InventionNameEditor = Component.extend({
  name: '',

  selectAll() {
    const input = document.getElementById(`invention-${this.elementId}`);
    input.select();
  },

  didInsertElement() {
    this._super(...arguments);
    this.selectAll();
  },

  actions: {
    submit() {
      const input = document.getElementById(`invention-${this.elementId}`);
      this.onEdit(input.value);
    }
  }
});

export default InventionNameEditor;
