export default {
  attributes: {
    name: {
      type: 'string',
      defaultValue: ''
    },
    productName: {
      type: 'string',
      defaultValue: ''
    },
    asset: {
      type: 'uuid',
      defaultValue: ''
    },
    problem: {
      type: 'string',
      defaultValue: ''
    },
    solution: {
      type: 'string',
      defaultValue: ''
    },
    checklist: {
      type: 'array',
      defaultValue: [] // ['market', 'patentability', 'possession', 'enablement', 'anticipation', 'obviousness', 'review', 'file']
    },
    assertedMilestonesList: {
      type: 'array',
      defaultValue: [] // ['market', 'patentability', 'possession', 'enablement', 'anticipation', 'obviousness', 'review', 'file']
    },
    reviewedMilestonesList: {
      type: 'array',
      defaultValue: [] // ['market', 'patentability', 'possession', 'enablement', 'anticipation', 'obviousness', 'review', 'file']
    },
    preferredElementVersionsList: {
      type: 'array',
      defaultValue: []
    },
    customersList: {
      type: 'array',
      defaultValue: []
    },
    comparisonsList: {
      type: 'array',
      defaultValue: []
    },
    patentability: {
      type: 'object',
      defaultValue: {}
    },
    method: {
      type: 'uuid',
      defaultValue: null
    },
    elementsList: {
      type: 'array',
      defaultValue: []
    },
    comprisesList: {
      type: 'array',
      defaultValue: []
    },
    markersList: {
      type: 'array',
      defaultValue: []
    },
  }
};

