import Component from '@glimmer/component';
import { action } from '@ember/object';
import podNames from 'ember-component-css/pod-names';

class UiDateInput extends Component {
  
  get styleNamespace() {
    return podNames['ui-date-input'];
  }

  get classNames() {
    let classNames = [this.styleNamespace, 'ui-date-input'];
    return classNames.join(' ');
  }

  @action 
  onInput(e) {
    if (e.target.value) {
      this.args.onChange(e.target.value);
    }
  }
}

export default UiDateInput;
