import {
  UPDATE_META,
} from './actionTypes';

export const updateMeta = (attributes) => {
  return {
    type: UPDATE_META,
    payload: {
      attributes
    }
  };
};
