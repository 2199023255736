import Component from '@glimmer/component';
import podNames from 'ember-component-css/pod-names';

class ProductsContextPopover extends Component {
  get styleNamespace() {
    return podNames['products-context-popover'];
  }

  get classNames() {
    let classNames = [this.styleNamespace, 'products-context-popover'];
    return classNames.join(' ');
  }
}

export default ProductsContextPopover;
