export default {
  ids: [
    'conception',
    'invention_proof',
    'patent_types',
    'invention_categories',
    'prior_art_market',
    'prior_art_patented',
    'prior_art_published',
    'public_disclosure',
  ],
  entities: {
    conception: {
      id: 'conception',
      question: 'Did you conceive of the idea?',
      answers: [1],
      options: [
        {
          id: 1,
          label: 'Yes, I believe I conceived of the idea',
        },
        {
          id: 2,
          label: "No, it's not my idea",
          warning:
            'Your reviewer may ask for more details about this potential issue. If you do not have a reviewer, you should seek out a registered patent practitioner in your area to get more information',
        },
        {
          id: 3,
          label: "I'm not sure",
          warning:
            'Your reviewer may ask for more details about this potential issue. If you do not have a reviewer, you should seek out a registered patent practitioner in your area to get more information',
        },
      ],
    },
    invention_proof: {
      id: 'invention_proof',
      question:
        'Can you describe your invention well enough for someone else to make and use it?',
      answers: [1],
      options: [
        {
          id: 1,
          label: 'Yes, I think so',
        },
        {
          id: 2,
          label: "No, I can't describe my invention well enough",
          warning:
            'Your reviewer may ask for more details about this potential issue. If you do not have a reviewer, you should seek out a registered patent practitioner in your area to get more information',
        },
        {
          id: 3,
          label: "I'm not sure",
          warning:
            'Your reviewer may ask for more details about this potential issue. If you do not have a reviewer, you should seek out a registered patent practitioner in your area to get more information',
        },
      ],
    },
    patent_types: {
      id: 'patent_types',
      question: 'Is your invention something functional?',
      answers: [1],
      options: [
        {
          id: 1,
          label: "Yes, it's functional",
        },
        {
          id: 2,
          label: "No, it's non-functional",
          warning:
            'Your reviewer may ask for more details about this potential issue. If you do not have a reviewer, you should seek out a registered patent practitioner in your area to get more information',
        },
        {
          id: 3,
          label: "No, it's a plant",
          warning:
            "It's very impressive that you invented a plant, but unfortunately this app is only for functional inventions",
        },
        {
          id: 4,
          label: "I'm not sure",
          warning:
            'Your reviewer may ask for more details about this potential issue. If you do not have a reviewer, you should seek out a registered patent practitioner in your area to get more information',
        },
      ],
    },
    invention_categories: {
      id: 'invention_categories',
      question: 'Is your invention one of the allowable invention categories?',
      answers: [1],
      options: [
        {
          id: 1,
          label: 'Yes, I think so',
        },
        {
          id: 2,
          label: 'No',
          warning:
            'Your reviewer may ask for more details about this potential issue. If you do not have a reviewer, you should seek out a registered patent practitioner in your area to get more information',
        },
        {
          id: 3,
          label: "I'm not sure",
          warning:
            'Your reviewer may ask for more details about this potential issue. If you do not have a reviewer, you should seek out a registered patent practitioner in your area to get more information',
        },
      ],
    },
    prior_art_market: {
      id: 'prior_art_market',
      question:
        'Does the exact same thing as your invention already exist in the market?',
      answers: [1],
      options: [
        {
          id: 1,
          label: 'Not that I know of',
        },
        {
          id: 2,
          label: "Yes, it's already on the market",
          warning:
            'Your reviewer may ask for more details about this potential issue. If you do not have a reviewer, you should seek out a registered patent practitioner in your area to get more information',
        },
        {
          id: 3,
          label: "I'm not sure",
          warning:
            'Your reviewer may ask for more details about this potential issue. If you do not have a reviewer, you should seek out a registered patent practitioner in your area to get more information',
        },
      ],
    },
    prior_art_patented: {
      id: 'prior_art_patented',
      question:
        'Has the exact same thing as your invention been patented in the US or another country?',
      answers: [1],
      options: [
        {
          id: 1,
          label: 'Not that I know of',
        },
        {
          id: 2,
          label: "Yes, it's been patented",
          warning:
            'Your reviewer may ask for more details about this potential issue. If you do not have a reviewer, you should seek out a registered patent practitioner in your area to get more information',
        },
        {
          id: 3,
          label: "I'm not sure",
          warning:
            'Your reviewer may ask for more details about this potential issue. If you do not have a reviewer, you should seek out a registered patent practitioner in your area to get more information',
        },
      ],
    },
    prior_art_published: {
      id: 'prior_art_published',
      question:
        'Has the exact same thing as your invention been described by someone other than you in any publication, journal, magazine, blog, etc.?',
      answers: [1],
      options: [
        {
          id: 1,
          label: 'Not that I know of',
        },
        {
          id: 2,
          label: "Yes, it's been described publicly by someone else",
          warning:
            'Your reviewer may ask for more details about this potential issue. If you do not have a reviewer, you should seek out a registered patent practitioner in your area to get more information',
        },
        {
          id: 3,
          label: "I'm not sure",
          warning:
            'Your reviewer may ask for more details about this potential issue. If you do not have a reviewer, you should seek out a registered patent practitioner in your area to get more information',
        },
      ],
    },
    public_disclosure: {
      id: 'public_disclosure',
      question: 'Have you publicly disclosed your invention?',
      answers: [1, 2],
      options: [
        {
          id: 1,
          label: 'No, I don\'t believe I publicly disclosed it',
        },
        {
          id: 2,
          label: 'Yes, I think so, but less than one year ago',
        },
        {
          id: 3,
          label: 'Yes, I think I publicly disclosed it more than one year ago',
          warning:
            'Your reviewer may ask for more details about this potential issue. If you do not have a reviewer, you should seek out a registered patent practitioner in your area to get more information',
        },

        {
          id: 4,
          label: "I'm not sure",
          warning:
            'Your reviewer may ask for more details about this potential issue. If you do not have a reviewer, you should seek out a registered patent practitioner in your area to get more information',
        },
      ],
    },
  },
};
