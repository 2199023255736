import Component from '@glimmer/component';
import podNames from 'ember-component-css/pod-names';
import { connect } from 'ember-redux';
import {
  getElement,
} from '../../../selectors/element';

const dispatchToActions = {};

const stateToComputed = (state, attrs) => ({
  element: getElement(state, attrs.elementId),
});

class ElementOutcomeEditor extends Component {

  get classNames() {
    let classNames = ['element-outcome-editor-two', this.styleNamespace];
    // if (this.isTest) classNames.push('is-test');
    return classNames.join(' ');
  }

  get styleNamespace() {
    return podNames['element-outcome-editor-two'];
  }
}

export default connect(stateToComputed, dispatchToActions)(ElementOutcomeEditor);

