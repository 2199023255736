export default {
  attributes: {
    id: {
      type: 'uuid',
      defaultValue: null
    },
    visitedList: {
      type: 'array',
      defaultValue: []
    },
    possessionList: {
      type: 'array',
      defaultValue: []
    },
    possessionIssuesList: {
      type: 'array',
      defaultValue: []
    },
    enablementList: {
      type: 'array',
      defaultValue: []
    },
    enablementIssuesList: {
      type: 'array',
      defaultValue: []
    },
    product: {
      type: 'uuid',
      defaultValue: null
    },
    activeNode: {
      type: 'uuid',
      defaultValue: null
    },
    user: {
      type: 'uuid',
      defaultValue: null
    },
    createdAt: {
      type: 'date'
    },
    updatedAt: {
      type: 'date'
    },
  }
};