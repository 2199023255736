import { enqueueTask, task } from 'ember-concurrency-decorators';
import {
  getActiveProductId,
  getInventionUi,
  getNavigationStack,
  getSelectedProducts,
} from '../../../selectors/invention-ui';
import { getAssetsList, getReferencesList } from '../../../selectors/invention';
import { keyResponder, onKey } from 'ember-keyboard';
import { timeout, waitForProperty } from 'ember-concurrency';

import { ActionCreators } from 'redux-undo';
import Component from '@glimmer/component';
import ENV from '../../../config/environment';
import { action } from '@ember/object';
import { connect } from 'ember-redux';
import { debounce } from '@ember/runloop';
import { getDrawingsList } from '../../../selectors/drawing';
import { getMethodsList as getGraphMethodsList } from '../../../selectors/graph';
import { getImagesList } from '../../../selectors/image';
import { getMethodsList } from '../../../selectors/method';
import { getPresentation } from '../../../selectors/presentation';
import { getProduct } from '../../../selectors/product';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

// import getCmdKey from 'ember-keyboard/utils/get-cmd-key';

const dispatchToActions = {};

const stateToComputed = (state) => ({
  inventionUi: getInventionUi(state),
  presentation: getPresentation(state),
  activeProductId: getActiveProductId(state),
  selectedProducts: getSelectedProducts(state),
});

@keyResponder
class MainApplication extends Component {
  @service data;
  @service store;
  @service redux;
  @service assets;
  @service imageFiltersCache;
  @service figuresCache;
  @service figuresCacheKonva;
  @service methodsCacheKonva;
  @service referencesCache;
  @service documentSelection;
  @service sessionManager;
  @service applicationState;
  @service contextMenu;
  @service settings;
  @service tracking;

  @tracked debug = false;
  @tracked isPreloading = true;
  @tracked imagesPreloadedCount = 0;
  @tracked imagesPreloadedTotal;
  @tracked imagesPreloaded = false;
  @tracked imagesProgress = 0;
  @tracked mentionsPreloadedCount = 0;
  @tracked mentionsPreloadedTotal;
  @tracked mentionsPreloadedTotal;
  @tracked mentionsPreloaded = false;
  @tracked mentionsProgress = 0;
  @tracked drawingsPreloadedCount = 0;
  @tracked drawingsPreloadedTotal;
  @tracked drawingsPreloaded = false;
  @tracked drawingsProgress = 0;
  @tracked methodsPreloadedCount = 0;
  @tracked methodsPreloadedTotal;
  @tracked methodsPreloaded = false;
  @tracked methodsProgress = 0;
  @tracked referencesPreloadedCount = 0;
  @tracked referencesPreloadedTotal;
  @tracked referencesPreloaded = false;
  @tracked referencesProgress = 0;
  @tracked imageFiltersPreloadedCount = 0;
  @tracked imageFiltersPreloadedTotal;
  @tracked imageFiltersPreloaded = false;
  @tracked imageFiltersProgress = 0;

  constructor() {
    super(...arguments); // super must be called first.
    this.handleMouseDown = this._handleMouseDown.bind(this);
    this.handleMatchMediaChange = this._handleMatchMediaChange.bind(this);
    this.isTest = ENV.environment === 'test';
    this.mentionsPreloadedTotal = 100;
  }

  // willDestroy() {
  //   console.log('destroying app');
  //   this.imageFiltersCache.terminateWorkers();
  //   this.referencesCache.terminateWorkers();
  //   this.figuresCacheKonva.terminateWorkers();
  // }

  @action
  didInsert() {
    this.preload.perform();
    this.tracking.trackEvent('visited_disclosure');
  }

  @onKey('cmd+KeyZ', { event: 'keydown' })
  handleUndo(keyboardEvent) {
    keyboardEvent.preventDefault();
    this.contextMenu.close();
    this.redux.store.dispatch(ActionCreators.undo());
    this.applicationState.incrementProperty('undoIndex');
    this.tracking.trackEvent('undo');
    this.updateModels();
  }

  @onKey('cmd+shift+KeyZ', { event: 'keydown' })
  handleRedo(keyboardEvent) {
    keyboardEvent.preventDefault();
    this.redux.store.dispatch(ActionCreators.redo());
    this.applicationState.decrementProperty('undoIndex');
    this.tracking.trackEvent('redo');
    this.updateModels();
  }

  @onKey('_all', { event: 'keydown' })
  handleKeyDown(keyboardEvent) {
    // const cmdKey = `${getCmdKey()}Key`;
    if (keyboardEvent.key === 'Alt' || keyboardEvent.altKey) {
      this.applicationState.cmdKeyDown = true;
    }

    if (keyboardEvent.shiftKey) {
      this.applicationState.shiftKeyDown = true;
    }
  }

  @onKey('_all', { event: 'keyup' })
  handleKeyUp(/*keyboardEvent*/) {
    // const cmdKey = `${getCmdKey()}Key`;
    // const cmdKey = `altKey`;

    // if (keyboardEvent.key !== 'Alt') {
    // this.applicationState.cmdKeyDown = false;
    // }

    // if (!keyboardEvent.shiftKey) {
    //   this.applicationState.shiftKeyDown = false;
    // }

    this.applicationState.cmdKeyDown = false;
    this.applicationState.shiftKeyDown = false;
  }

  cleanData() {
    const state = this.redux.getState();

    // bug: deleting active product
    // if active product is deleted, set active product to null
    if (this.activeProductId && !getProduct(state, this.activeProductId)) {
      this.data.setActiveProduct(null);
    }

    // bug: deleting active product
    // if selected product is deleted, set selected products to []
    if (
      this.selectedProducts.length &&
      this.selectedProducts[0] &&
      !getProduct(state, this.selectedProducts[0])
    ) {
      this.data.selectProduct(null);
    }

    // make sure the stack navigation is working
    const navigationStack = getNavigationStack(state);

    if (!navigationStack || navigationStack.length === 0) {
      this.data.stackNavigationReset();
    }

    this.redux.store.dispatch(ActionCreators.clearHistory());
  }

  cleanUpModels() {
    this.store
      .peekAll('method')
      .forEach((model) => this.store.unloadRecord(model));
  }

  createModels() {
    const state = this.redux.getState();
    const methodsList = getMethodsList(state);
    methodsList.forEach((methodId) => this.data.createMethodModel(methodId));
  }

  updateModels() {
    const state = this.redux.getState();
    const methodsList = getMethodsList(state);
    methodsList.forEach((methodId) => this.data.updateMethodModel(methodId));
  }

  @task
  *preload() {
    this.cleanData();
    this.cleanUpModels();
    this.createModels();
    // yield this.preloadImages.perform();
    // yield waitForProperty(this, 'imagesPreloaded', true);
    // yield this.preloadImageFilters.perform();
    // yield waitForProperty(this, 'imageFiltersPreloaded', true);
    yield this.preloadMentions.perform();
    yield waitForProperty(this, 'mentionsPreloaded', true);
    console.log('Preloaded mentions');
    yield this.preloadDrawings.perform();
    yield waitForProperty(this, 'drawingsPreloaded', true);
    console.log('Preloaded drawings');
    yield this.preloadMethods.perform();
    yield waitForProperty(this, 'methodsPreloaded', true);
    console.log('Preloaded methods');
    // yield this.preloadReferences.perform();
    // yield waitForProperty(this, 'referencesPreloaded', true);
    if (ENV.environment !== 'test') {
      yield timeout(500);
    }
    this.isPreloading = false;
  }

  @task
  // eslint-disable-next-line require-yield
  *preloadImages() {
    const state = this.redux.getState();
    const assetsList = getAssetsList(state);
    if (assetsList.length) {
      this.imagesPreloadedTotal = assetsList.length;
      for (let i = 0; i < assetsList.length; i++) {
        this.preloadImage.perform(assetsList[i]);
      }
    } else {
      this.imagesProgress = 100;
      this.imagesPreloaded = true;
    }
  }

  @enqueueTask({ maxConcurrency: 8 })
  *preloadImage(assetId) {
    // yield timeout(Math.random()* 25 + 25);
    yield this.assets.cacheUrls(assetId);
    this.imagesPreloadedCount = this.imagesPreloadedCount + 1;
    this.imagesProgress =
      (100 * this.imagesPreloadedCount) / this.imagesPreloadedTotal;
    if (this.imagesPreloadedCount >= this.imagesPreloadedTotal) {
      this.imagesPreloaded = true;
    }
  }

  @task
  // eslint-disable-next-line require-yield
  *preloadMentions() {
    let i = 0;
    while (i < this.mentionsPreloadedTotal) {
      this.preloadMention.perform();
      i++;
    }
  }

  @enqueueTask({ maxConcurrency: 8 })
  *preloadMention() {
    if (ENV.environment !== 'test') {
      yield timeout(Math.random() * 25 + 25);
    }
    this.mentionsPreloadedCount = this.mentionsPreloadedCount + 1;
    this.mentionsProgress =
      (100 * this.mentionsPreloadedCount) / this.mentionsPreloadedTotal;
    if (this.mentionsPreloadedCount >= this.mentionsPreloadedTotal) {
      this.mentionsPreloaded = true;
    }
  }

  @task
  // eslint-disable-next-line require-yield
  *preloadDrawings() {
    const state = this.redux.getState();
    const drawingsList = getDrawingsList(state);
    // yield this.figuresCacheKonva.createWorkers();
    if (drawingsList.length) {
      this.drawingsPreloadedTotal = drawingsList.length;
      for (let i = 0; i < drawingsList.length; i++) {
        this.preloadDrawing.perform(drawingsList[i]);
      }
    } else {
      this.drawingsProgress = 100;
      this.drawingsPreloaded = true;
    }
  }

  @enqueueTask({ maxConcurrency: 8 })
  *preloadDrawing(drawingId) {
    if (ENV.environment !== 'test') {
      yield timeout(10);
    }
    yield this.figuresCacheKonva.cacheUrls(drawingId);
    this.drawingsPreloadedCount = this.drawingsPreloadedCount + 1;
    this.drawingsProgress =
      (100 * this.drawingsPreloadedCount) / this.drawingsPreloadedTotal;
    if (this.drawingsPreloadedCount >= this.drawingsPreloadedTotal) {
      this.drawingsPreloaded = true;
    }
  }

  @task
  // eslint-disable-next-line require-yield
  *preloadMethods() {
    const state = this.redux.getState();
    const methodsList = getGraphMethodsList(state);
    if (methodsList.length) {
      this.methodsPreloadedTotal = methodsList.length;
      for (let i = 0; i < methodsList.length; i++) {
        this.preloadMethod.perform(methodsList[i]);
      }
    } else {
      this.methodsProgress = 100;
      this.methodsPreloaded = true;
    }
  }

  @enqueueTask({ maxConcurrency: 5 })
  *preloadMethod(methodId) {
    if (ENV.environment !== 'test') {
      yield timeout(10);
    }
    // yield this.figuresCache.cacheUrls(methodId);
    yield this.methodsCacheKonva.cacheUrls(methodId);
    this.methodsPreloadedCount = this.methodsPreloadedCount + 1;
    this.methodsProgress =
      (100 * this.methodsPreloadedCount) / this.methodsPreloadedTotal;
    if (this.methodsPreloadedCount >= this.methodsPreloadedTotal) {
      this.methodsPreloaded = true;
    }
  }

  @task
  // eslint-disable-next-line require-yield
  *preloadReferences() {
    const state = this.redux.getState();
    const referencesList = getReferencesList(state);
    // yield this.referencesCache.createWorkers();
    if (referencesList.length) {
      this.referencesPreloadedTotal = referencesList.length;
      for (let i = 0; i < referencesList.length; i++) {
        this.preloadReference.perform(referencesList[i]);
      }
    } else {
      this.referencesProgress = 100;
      this.referencesPreloaded = true;
    }
  }

  @enqueueTask({ maxConcurrency: 8 })
  *preloadReference(referenceId) {
    yield timeout(10);
    yield this.referencesCache.cacheUrls(referenceId);
    this.referencesPreloadedCount = this.referencesPreloadedCount + 1;
    this.referencesProgress =
      (100 * this.referencesPreloadedCount) / this.referencesPreloadedTotal;
    if (this.referencesPreloadedCount >= this.referencesPreloadedTotal) {
      this.referencesPreloaded = true;
    }
  }

  @task
  // eslint-disable-next-line require-yield
  *preloadImageFilters() {
    const state = this.redux.getState();
    const imagesList = getImagesList(state);
    // yield this.imageFiltersCache.createWorkers();
    if (imagesList.length) {
      this.imageFiltersPreloadedTotal = imagesList.length;
      for (let i = 0; i < imagesList.length; i++) {
        // this.preloadImageFilter.perform(imagesList[i]);
      }
    } else {
      this.imageFiltersProgress = 100;
      this.imageFiltersPreloaded = true;
    }
  }

  @enqueueTask({ maxConcurrency: 8 })
  *preloadImageFilter(imageId) {
    yield timeout(10);
    yield this.imageFiltersCache.cacheUrls(imageId);
    this.imageFiltersPreloadedCount = this.imageFiltersPreloadedCount + 1;
    this.imageFiltersProgress =
      (100 * this.imageFiltersPreloadedCount) / this.imageFiltersPreloadedTotal;
    if (this.imageFiltersPreloadedCount >= this.imageFiltersPreloadedTotal) {
      this.imageFiltersPreloaded = true;
    }
  }

  get progress() {
    const factor = 0.3333;
    // return (
    //   this.imagesProgress * factor +
    //   this.imageFiltersProgress * factor +
    //   this.mentionsProgress * factor +
    //   this.drawingsProgress * factor +
    //   this.methodsProgress * factor +
    //   this.referencesProgress * factor
    // );
    return (
      this.mentionsProgress * factor +
      this.drawingsProgress * factor +
      this.methodsProgress * factor
    );
  }

  get classNames() {
    let classNames = ['main-application', this.styleNamespace];
    if (this.isTest) classNames.push('is-test');
    return classNames.join(' ');
  }

  get styleNamespace() {
    return podNames['main-application'];
  }

  updateDocumentSelection() {
    this.documentSelection.updateSelection();
  }

  @action
  setupMatchMedia() {
    const darkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
    this.settings.darkMode = darkMode;

    window
      .matchMedia('(prefers-color-scheme: dark)')
      .addEventListener('change', this.handleMatchMediaChange);
  }

  @action
  tearDownMatchMedia() {
    window
      .matchMedia('(prefers-color-scheme: dark)')
      .removeEventListener('change', this.handleMatchMediaChange);
  }

  @action
  setupDocumentSelection() {
    document.onselectionchange = () =>
      debounce(this, this.updateDocumentSelection, 350);
    document
      .getElementById('main-app')
      .addEventListener('mousedown', this.handleMouseDown);
    // window.addEventListener('resize', this.onUpdateSelection);
  }

  @action
  tearDownDocumentSelection() {
    document.onselectionchange = null;
    document.getElementById('main-app') &&
      document
        .getElementById('main-app')
        .removeEventListener('mousedown', this.handleMouseDown);
    // window.removeEventListener('resize', this.onUpdateSelection);
  }

  // set which content-mentionable (or extentsion) was clicked if any
  _handleMouseDown(event) {
    const domElement = event.target;
    const contentMentionable = domElement.closest('.content-mentionable');
    const contentMentionableExtension = domElement.closest(
      '.content-mentionable-extension'
    );
    this.documentSelection.setProperties({
      contentMentionable,
      contentMentionableExtension,
    });
  }

  // handle theme changes
  _handleMatchMediaChange(event) {
    const darkMode = event.matches ? true : false;
    this.settings.darkMode = darkMode;
  }

  @action
  toggleDebug() {
    this.debug = !this.debug;
  }
}

export default connect(stateToComputed, dispatchToActions)(MainApplication);
