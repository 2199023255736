export const GEAR_PATH = `M15.3,7.9c0-0.1,0-0.3,0-0.4c0-0.1,0-0.3,0-0.4l-1.8-0.4c0-0.3-0.1-0.5-0.2-0.8l1.3-1.3
c-0.1-0.2-0.2-0.4-0.4-0.7l-1.8,0.5c-0.2-0.2-0.4-0.4-0.6-0.6l0.5-1.8c-0.2-0.1-0.4-0.3-0.7-0.4l-1.3,1.3C10.3,3,10.1,3,9.8,2.9
L9.4,1.2c-0.1,0-0.3,0-0.4,0c-0.1,0-0.3,0-0.4,0L8.2,2.9C7.9,3,7.7,3,7.4,3.1L6.2,1.8C5.9,1.9,5.7,2.1,5.5,2.2L6,3.9
C5.8,4.1,5.6,4.3,5.4,4.5L3.7,4C3.6,4.2,3.4,4.4,3.3,4.7l1.3,1.3C4.5,6.2,4.5,6.4,4.4,6.7L2.7,7.1c0,0.1,0,0.3,0,0.4
c0,0.1,0,0.3,0,0.4l1.8,0.4c0,0.3,0.1,0.5,0.2,0.8l-1.3,1.3c0.1,0.2,0.2,0.4,0.4,0.7l1.8-0.5c0.2,0.2,0.4,0.4,0.6,0.6l-0.5,1.8
c0.2,0.1,0.4,0.3,0.7,0.4l1.3-1.3C7.7,12,7.9,12,8.2,12.1l0.4,1.8c0.1,0,0.3,0,0.4,0c0.1,0,0.3,0,0.4,0l0.4-1.8
c0.3,0,0.5-0.1,0.8-0.2l1.3,1.3c0.2-0.1,0.4-0.2,0.7-0.4L12,11.1c0.2-0.2,0.4-0.4,0.6-0.6l1.8,0.5c0.1-0.2,0.3-0.4,0.4-0.7l-1.3-1.3
c0.1-0.2,0.2-0.5,0.2-0.8L15.3,7.9z M9,11.5c-2.2,0-4-1.8-4-4c0-2.2,1.8-4,4-4s4,1.8,4,4C13,9.7,11.2,11.5,9,11.5z`;

export const CHAIR_PATH = `M11.7,8.8l1.6,5.1h-0.8l-2.1-4.5L6,9.1l-1.1,4.8H4.1l0.5-5l0.2-1.4l5.5,0.3L13,1.1h0.8L11.7,8.8L11.7,8.8z`;

export const PROCESS_PATH = `M10.5,5.5V2.4H3.4v7.2h4.1v3.1h7.2V5.5H10.5z M4.4,8.5V3.4h5.2v2.1h-2v3.1H4.4z M13.7,11.6H8.5V9.5v-1V6.5h1h1h3.1V11.6z`;
export const INSTANCE_PATH = `M14.3,9.6C14,10.4,12,11.3,9,11.3s-5-0.9-5.3-1.7C3.5,9.5,3.2,9.3,3,9.1c0,0.1-0.1,0.2-0.1,0.4c0,1.4,2.7,2.6,6.1,2.6
s6.1-1.1,6.1-2.6c0-0.1,0-0.2-0.1-0.4C14.8,9.3,14.5,9.5,14.3,9.6z M14.5,7.5c-0.1,0.1-0.2,0.1-0.2,0.1c0,0.1-0.1,0.3-0.3,0.4
c-0.2,0.2-0.4,0.3-0.7,0.5c-1,0.4-2.5,0.8-4.3,0.8S5.7,8.9,4.8,8.5C4.5,8.4,4.2,8.2,4,8C3.9,7.9,3.8,7.8,3.7,7.6
c-0.1,0-0.2-0.1-0.2-0.1C3.3,7.4,3.1,7.3,3,7.1c0,0.1-0.1,0.2-0.1,0.4S3,7.7,3,7.9c0.1,0.2,0.2,0.4,0.4,0.6C3.6,8.7,3.8,8.8,4,9
c1.1,0.7,2.9,1.1,5,1.1s3.9-0.4,5-1.1c0.2-0.1,0.4-0.3,0.6-0.5c0.2-0.2,0.3-0.4,0.4-0.6c0-0.1,0.1-0.2,0.1-0.4s0-0.2-0.1-0.4
C14.9,7.3,14.7,7.4,14.5,7.5z M3.4,6.5C3.6,6.7,3.8,6.8,4,7c0.1,0,0.2,0,0.2,0.1c0.3,0.2,0.6,0.3,1,0.4c0.5,0.2,1,0.3,1.6,0.4
C7.5,8,8.2,8.1,9,8.1s1.5-0.1,2.2-0.2c0.6-0.1,1.1-0.2,1.6-0.4c0.4-0.1,0.7-0.3,1-0.4C13.8,7,13.9,7,14,7c0.2-0.1,0.4-0.3,0.6-0.5
c0.2-0.2,0.3-0.4,0.4-0.6c0-0.1,0.1-0.2,0.1-0.4c0-1.4-2.7-2.6-6.1-2.6S2.9,4.1,2.9,5.5c0,0.1,0,0.2,0.1,0.4
C3.1,6.1,3.2,6.3,3.4,6.5z M9,3.7c3,0,5,0.9,5.3,1.7l0.1,0.1c0,0.2-0.1,0.4-0.3,0.5c-0.2,0.2-0.4,0.3-0.7,0.5
c-0.2,0.1-0.3,0.1-0.5,0.2C12.5,6.9,12,7,11.4,7.1C10.6,7.2,9.8,7.3,9,7.3S7.4,7.2,6.8,7.1S5.7,6.9,5.3,6.7C5.1,6.6,4.9,6.6,4.8,6.5
C4.5,6.4,4.2,6.2,4,6C3.8,5.9,3.7,5.7,3.7,5.5c0,0,0-0.1,0.1-0.1C4,4.6,6,3.7,9,3.7z`;

export const COMPONENT_PATH = `M15,9.1c0.1,0.2,0.1,0.3,0.1,0.4c0,1.5-2.7,2.6-6.1,2.6s-6.1-1.2-6.1-2.6C2.9,9.3,3,9.2,3,9.1c0.2,0.2,0.5,0.4,0.7,0.5
c1.4,0.8,3.4,1.2,5.3,1.2c2,0,4-0.4,5.3-1.2C14.5,9.5,14.8,9.3,15,9.1z M14.3,7.6C12.9,8.4,10.9,8.8,9,8.8S5.1,8.4,3.7,7.6
C3.5,7.5,3.2,7.3,3,7.1c0,0.1-0.1,0.2-0.1,0.4c0,1.4,2.7,2.6,6.1,2.6s6.1-1.1,6.1-2.6c0-0.1,0-0.2-0.1-0.4
C14.8,7.3,14.5,7.5,14.3,7.6z M9,8.1c3.4,0,6.1-1.1,6.1-2.6S12.4,2.9,9,2.9S2.9,4.1,2.9,5.5S5.6,8.1,9,8.1z`;

export const KNOWN_PATH = `M50,14.6v20.7L35.4,50H14.6L0,35.4V14.6L14.6,0h20.7L50,14.6z`;
