import Component from '@ember/component';
import { equal } from '@ember/object/computed';

const UiModal = Component.extend({
  classNames: ['modal'],
  classNameBindings: [
    'isLarge',
    'isNormal',
    'isMedium',
    'isSmall',
    'isTiny',
    'isFullscreen',
    'isOverlay',
    'isDoubleOverlay',
    'isAskew',
    'isFixed'
  ],
  size: 'medium', // small, medium, large, fullscreen
  isFullscreen: equal('size', 'fullscreen'),
  isLarge: equal('size', 'large'),
  isMedium: equal('size', 'medium'),
  isNormal: equal('size', 'normal'),
  isSmall: equal('size', 'small'),
  isTiny: equal('size', 'tiny'),
});

export default UiModal;
