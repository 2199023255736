import { BLACK_AND_WHITE_STROKE, PRIMARY_COLOR } from '../../../constants/colors';

import Component from '@glimmer/component';
import Konva from 'konva';
import { action } from '@ember/object';
import { connect } from 'ember-redux';
import { getImage } from '../../../selectors/image';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency-decorators';

const dispatchToActions = {};

const stateToComputed = (state, attrs) => ({
  image: getImage(state, attrs.imageId),
});

class DrawingImageKonva extends Component {
  @service assets;
  @service imageFiltersCache;
  @service redux;
  @service tracking;
  @service settings;
  
  constructor(owner, args) {
    super(owner, args);
    this.onScheduleRender = this.args.onScheduleRender;
  }

  @action
  didInsert() {
    this.setup();
    this._updatedAt = this.image.updatedAt;
    this._isSelected = this.args.isSelected;
    this._canTransform = this.args.canTransform;
    this._imageX = this.image.x;
    this._imageY = this.image.y;
    this._asset = this.image.asset;
    this._index = this.args.index + 100;
  }

  @action
  willDestroyNode() {
    this.imageNode.off('click');
    this.imageNode.destroy();
    this.transformer.destroy();
    this.onScheduleRender();
  }

  get styleNamespace() {
    return podNames['drawing-image-konva'];
  }

  get classNames() {
    let classNames = [this.styleNamespace];
    if (this.args.isSelected) {
      classNames.push('is-selected');
    } else {
      classNames.push('isnt-selected');
    }
    return classNames.join(' ');
  }

  setup() {
    const imageNode = new Konva.Group({
      id: this.args.imageId,
      nodeType: 'image',
      x: this.image.x,
      y: this.image.y,
      rotation: this.image.rotation ? this.image.rotation : 0,
      draggable: this.args.isSelected,
      zIndex: this.args.index + 100,
    });

    const assetNode = new Konva.Image({
      width: this.image.width,
      height: this.image.height,
    });

    const multiSelectNode = new Konva.Rect({
      width: this.image.width,
      height: this.image.height,
      stroke: PRIMARY_COLOR,
      strokeWidth: 1,
      strokeScaleEnabled: false,
      listening: false,
      visible: this.args.isSelected && this.args.isMultiselected ? true : false,
    });

    // add events
    imageNode.on('click', () => {
      if (this.args.onClick)
        this.args.onClick(this.args.imageId, this.args.isSelected);
    });

    if (this.args.onTransformEnd) {
      imageNode.on('transformend', () => {
        const width = imageNode.scaleX() * this.image.width;
        const height = imageNode.scaleY() * this.image.height;
        const x = imageNode.x();
        const y = imageNode.y();
        const attributes = {
          x,
          y,
          width,
          height,
        };

        let rotation = imageNode.rotation();

        if (rotation) {
          attributes['rotation'] = rotation;
          this.tracking.trackEvent('image_rotated');
        }
        this.args.onTransformEnd(this.args.imageId, attributes);

        imageNode.scaleX(1);
        imageNode.scaleY(1);
        this.tracking.trackEvent('image_resized');
      });
    }

    if (this.args.onMultiselectDrag) {
      imageNode.on('dragmove', () => {
        if (this.args.isSelected && this.args.isMultiselected) {
          const x = imageNode.x();
          const y = imageNode.y();
          const dx = x - this._imageX;
          const dy = y - this._imageY;
          this._imageX = x;
          this._imageY = y;

          this.args.onMultiselectDrag(dx, dy, this.args.imageId);
          // return event.target.stopDrag();
        }
      });
    }

    if (this.args.onDragEnd) {
      imageNode.on('dragstart', () => {
        if (!this.args.isSelected) {
          imageNode.stopDrag();
        }
      });
      imageNode.on('dragend', () => {
        const x = imageNode.x();
        const y = imageNode.y();
        this._imageX = x;
        this._imageY = y;
        if (this.args.isSelected && this.args.isMultiselected) {
          this.args.onMultiselectDragEnd();
        } else {
          this.args.onDragEnd(this.args.imageId, { x, y });
          this.tracking.trackEvent('image_dragged');
        }
      });
    }

    const transformer = new Konva.Transformer({
      anchorStroke: this.settings.blackAndWhiteMode ? BLACK_AND_WHITE_STROKE :  PRIMARY_COLOR,
      borderStroke: this.settings.blackAndWhiteMode ? BLACK_AND_WHITE_STROKE :  PRIMARY_COLOR,
      visible: this.args.isSelected,
      zIndex: this.args.index + 200,
    });

    this.args.layer.add(imageNode);
    this.args.layer.add(transformer);

    imageNode.add(assetNode);
    imageNode.add(multiSelectNode);

    // by default select all shapes
    transformer.nodes([imageNode]);

    this.imageNode = imageNode;
    this.assetNode = assetNode;
    this.multiSelectNode = multiSelectNode;
    this.transformer = transformer;

    this.onUpdateReferenceImage.perform();

    this.onScheduleRender();
  }

  onUpdatedAt() {
    this.imageNode.x(this.image.x);
    this.imageNode.y(this.image.y);

    this._imageX = this.image.x;
    this._imageY = this.image.y;

    this.imageNode.rotation(this.image.rotation);

    this.assetNode.clearCache();
    this.assetNode.width(this.image.width);
    this.assetNode.height(this.image.height);
    this.assetNode.cache({ pixelRatio: 2 });

    this.multiSelectNode.width(this.image.width);
    this.multiSelectNode.height(this.image.height);

    this.transformer.forceUpdate();
  }

  @task
  *onUpdateReferenceImage() {
    const blobUrl = yield this.imageFiltersCache.getBlobUrl.perform(this.args.imageId);

    if (!blobUrl) {
      return;
    }

    console.log('blobUrl', blobUrl)

    const assetFile = new Image();

    assetFile.onload = () => {
      this.assetNode.clearCache();
      this.assetNode.image(assetFile);
      this.assetNode.cache({ pixelRatio: 2 });
      // this.assetNode.filters([ThresholdFilter]);
      // this.assetNode.threshold(50);
      this.onScheduleRender(this.args.layer);
    };

    assetFile.src = blobUrl;
  }

  @action
  onUpdate(elem, [isSelected, canTransform, index, updatedAt]) {
    let detailsChanged = false;

    if (this._canTransform !== canTransform) {
      this._canTransform = canTransform;

      this.transformer.visible(canTransform);

      detailsChanged = true;
    }

    if (this._isSelected !== isSelected) {
      this._isSelected = isSelected;

      this.multiSelectNode.visible(isSelected);
      this.imageNode.draggable(isSelected);

      detailsChanged = true;
    }

    // if (this._isMultiselected !== isMultiselected) {
    //   this._isMultiselected = isMultiselected;

    //   this.multiSelectNode.visible(isMultiselected);

    //   detailsChanged = true;
    // }

    if (this._index !== index) {
      this._index = index;
      this.imageNode.zIndex(this.args.index + 100);
      this.transformer.zIndex(this.args.index + 200);
      detailsChanged = true;
    }

    if (this._updatedAt !== updatedAt) {
      this._updatedAt = updatedAt;

      this.onUpdateReferenceImage.perform();
      this.onUpdatedAt();

      detailsChanged = true;
    }

    if (detailsChanged) {
      this.onScheduleRender(this.args.layer);
    }
  }
}

export default connect(stateToComputed, dispatchToActions)(DrawingImageKonva);
