import Controller from '@ember/controller';

export default class InventionController extends Controller {
    // queryParams = ['name', 'inspiration', 'description', 'assets', 'enableCloudBackups'];
    // queryParams = ['name', 'inspiration', 'description', 'assets', 'productName'];
    // name = '';
    // productName = '';
    // inspiration = '';
    // description = '';
    // assets = null;
    // enableCloudBackups = false;
}
