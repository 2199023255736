import { alias, bool } from '@ember/object/computed';
import {
  getElement,
  getElementElementVersionsList,
  getElementInstanceOf,
  getElementOutcomeId,
} from '../../../selectors/element';

/* eslint-disable ember/use-brace-expansion */
import Component from '@glimmer/component';
import { computed } from '@ember/object';
import { connect } from 'ember-redux';
import { getFeature } from '../../../selectors/feature';
import { getMentionsContent } from '../../../selectors/mention';
import { getPreferredElementVersion } from '../../../selectors/product';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';
import { textValue } from '../../../utils/string';

const dispatchToActions = {};

const stateToComputed = (state, attrs) => ({
  element: getElement(state, attrs.elementId),
  instanceOf: getElementInstanceOf(state, attrs.elementId),
  outcomeId: getElementOutcomeId(state, attrs.elementId),
  elementVersionsList: getElementElementVersionsList(state, attrs.elementId),
  preferredElementVersion: getPreferredElementVersion(
    state,
    attrs.elementId,
    attrs.productId
  ),
});

class InventionGraphElement extends Component {
  @service redux;
  @service models;

  get styleNamespace() {
    return podNames['invention-graph-element-konva'];
  }

  get classNames() {
    let classNames = [this.styleNamespace, 'invention-graph-element-konva'];
    return classNames.join(' ');
  }

  @bool('instanceOf.id') isInstance;
  @bool('args.productId') hasActiveProduct;
  @bool('preferredElementVersion.id') hasPreferredElementVersion;
  @alias('hasActiveProduct') showElementVersion;

  @computed('args.{elementId,primaryInstancesList}', 'isInstance')
  get isPrimaryInstance() {
    return (
      this.isInstance &&
      this.args.primaryInstancesList.includes(this.args.elementId)
    );
  }

  @computed('element.category', 'instanceOf.category', 'isInstance')
  get category() {
    return (
      this.isInstance ? this.instanceOf.category : this.element.category
    );
  }

  @computed('isInstance', 'elementModel.name', 'instanceOfModel.name', 'element.name', 'instanceOf.name')
  get name() {
    return this.isInstance
      ? this.instanceOfModel && this.instanceOfModel.name
      : this.elementModel && this.elementModel.name;
  }

  @computed('element', 'args.elementId')
  get elementModel() {
    return (
      this.element &&
      (this.models.find(this.args.elementId) ||
        this.models.findOrCreate(this.args.elementId, 'element', this.element))
    );
  }

  @computed('isInstance', 'instanceOf.id')
  get instanceOfModel() {
    return (
      this.isInstance &&
      this.instanceOf &&
      (this.models.find(this.instanceOf.id) ||
        this.models.findOrCreate(
          this.instanceOf.id,
          'element',
          this.instanceOf
        ))
    );
  }

  @computed('outcomeId')
  get outcomeModel() {
    const state = this.redux.getState();
    return (
      this.outcomeId &&
      (this.models.find(this.outcomeId) ||
        this.models.findOrCreate(
          this.outcomeId,
          'feature',
          getFeature(state, this.outcomeId)
        ))
    );
  }

  @computed('outcomeModel.{id,value}')
  get outcome() {
    const state = this.redux.getState();
    let value = (this.outcomeModel && this.outcomeModel.value) || '';
    if (value) {
      value = getMentionsContent(
        state,
        value,
        `feature-${this.outcomeModel.id}`,
        {
          appendOrdinal: false,
          appendElementVersion: false,
          isInput: true,
          isInteractive: false,
        }
      );
    }
    return textValue(value);
  }
}

export default connect(
  stateToComputed,
  dispatchToActions
)(InventionGraphElement);
