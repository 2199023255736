import { ADD_DELETED_REFERENCE, REMOVE_DELETED_REFERENCE } from './actionTypes';

export const removeDeletedReference = (referenceId) => {
  return {
    type: REMOVE_DELETED_REFERENCE,
    payload: {
      referenceId
    },
  };
};

export const addDeletedReference = (referenceId, attributes = {}) => {
  return {
    type: ADD_DELETED_REFERENCE,
    payload: {
      referenceId,
      attributes
    },
  };
};

