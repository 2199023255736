import Component from '@glimmer/component';
import { action } from '@ember/object';
import { batchGroupBy } from '../../../utils/redux';
import { connect } from 'ember-redux';
import { getInventionUi } from '../../../selectors/invention-ui';
import { getProductsList } from '../../../selectors/product';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

const stateToComputed = (state) => ({
  productsList: getProductsList(state),
  ui: getInventionUi(state),
});
const dispatchToActions = {};

class ProductsModal extends Component {
  @tracked product;
  @tracked product;

  @service data;
  @service tracking;

  get styleNamespace() {
    return podNames['products-modal'];
  }

  get classNames() {
    let classNames = [this.styleNamespace, 'products-modal'];
    return classNames.join(' ');
  }

  @action
  didInsert() {
    this.tracking.trackEvent('opened_products');
  }

  @action
  hideModal() {
    batchGroupBy.start();
    this.data.setShowingProducts(false);
    batchGroupBy.end();
    this.tracking.trackEvent('closed_products');
  }

  @action
  onSelectProduct(product) {
    this.product = product;
  }

  @action
  createComparison() {}
}

export default connect(stateToComputed, dispatchToActions)(ProductsModal);
