import Component from '@ember/component';
import ENV from '../../../config/environment';

export default Component.extend({
  tagName: 'a',
  attributeBindings: ['testId:data-test-id', 'url:data-url'],
  testId: 'external-link',
  url: '',

  click(e) {
    e.preventDefault();
    const framework = ENV.FRAMEWORK;

    switch (framework) {
      case 'web': {
        if (ENV.environment === 'test') {
          return;
        }
        window.open(this.url, '_blank');
        break;
      }
      // case 'electron': {
      //   // eslint-disable-next-line no-undef
      //   const { shell } = requireNode('electron');
      //   shell.openExternal(this.url);
      //   break;
      // }
    }
  }
});
