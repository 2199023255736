import Component from '@glimmer/component';
import { action } from '@ember/object';
import podNames from 'ember-component-css/pod-names';

class UiToken extends Component {
  constructor() {
    super(...arguments); // super must be called first.
    this.isInfo = this.args.type === 'info';
    this.isPrimary = this.args.type === 'primary';
    this.isDanger = this.args.type === 'danger';
    this.isWarning = this.args.type === 'warning';
    this.isSuccess = this.args.type === 'success';
    this.size = this.args.size || 'default';
    this.isInteractive = this.args.onClick || false;
  }

  get classNames() {
    let classNames = [this.styleNamespace, 'ui-token'];
    if (this.isInfo) classNames.push('is-info');
    if (this.isPrimary) classNames.push('is-primary');
    if (this.isDanger) classNames.push('is-danger');
    if (this.isWarning) classNames.push('is-warning');
    if (this.isSuccess) classNames.push('is-success');
    if (this.size === 'small') classNames.push('is-small');
    if (this.size === 'large') classNames.push('is-large');
    if (this.isInteractive) classNames.push('is-interactive');
    return classNames.join(' ');
  }

  get styleNamespace() {
    return podNames['ui-token'];
  }

  @action 
  onClick() {
    if (this.args.onClick) {
      this.args.onClick();
    }
  }

}

export default UiToken;