import { action, computed } from '@ember/object';
import {
  getComponent,
  getPrimaryInstancesList,
} from '../../../selectors/component';
import {
  getElementsMap,
  getRootNodeId,
} from '../../../selectors/graph';

import Component from '@glimmer/component';
import { connect } from 'ember-redux';
import {
  getCollapsedNodesList,
} from '../../../selectors/invention-ui';
import { getElement } from '../../../selectors/element';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';

const stateToComputed = (state, attrs) => ({
  element: getElement(state, attrs.elementId),
  rootNodeId: getRootNodeId(state),
  primaryInstancesList: getPrimaryInstancesList(state),
  elementsMap: getElementsMap(state, attrs.productId),
  collapsedNodesList: getCollapsedNodesList(state, attrs.productId),
});

const dispatchToActions = {};

class ElementContextMenu extends Component {
  @service contextMenu;
  @service redux;
  @service tracking;

  get styleNamespace() {
    return podNames['preview-element-context-menu'];
  }

  get classNames() {
    let classNames = [this.styleNamespace, 'element-context-menu'];
    return classNames.join(' ');
  }

  @action
  didInsert() {
    this.tracking.trackEvent('opened_system_context_menu');
  }

  @computed('element.instanceOf')
  get isInstance() {
    return this.element && this.element.instanceOf ? true : false;
  }

  @computed('collapsedNodesList.[]', 'args.elementId')
  get isCollapsed() {
    return this.collapsedNodesList.includes(this.args.elementId);
  }

  @computed('element.instanceOf', 'primaryInstancesList.[]', 'args.elementId')
  get isPrimaryInstance() {
    return (
      this.element &&
      this.element.instanceOf &&
      this.primaryInstancesList.includes(this.args.elementId)
    );
  }

  @computed('args.elementId', 'elementsMap')
  get elementChildren() {
    return (
      this.elementsMap &&
      this.elementsMap[this.args.elementId] &&
      this.elementsMap[this.args.elementId].children
    );
  }

  @action
  onViewMainInstance() {
    if (this.args.onZoomToNode) {
      const state = this.redux.getState();
      const component = getComponent(state, this.element.component);
      const primaryInstanceId = component.primaryInstance;
      this.tracking.trackEvent('system_context_menu_viewed_main_instance');
      this.args.onZoomToNode(primaryInstanceId);
    }
    this.contextMenu.close();
  }

  @action
  onExpandNode() {
    if (this.args.onExpandNode) {
      this.args.onExpandNode(this.args.elementId);
    }
    this.contextMenu.close();
  }

  @action
  onCollapseNode() {
    if (this.args.onCollapseNode) {
      this.args.onCollapseNode(this.args.elementId);
    }
    this.contextMenu.close();
  }

  @action
  onCollapseChildren() {
    if (
      this.elementChildren &&
      this.elementChildren.length &&
      this.args.onCollapseChildren
    ) {
      const collapsibleChildren = this.elementChildren.filter((nodeId) => {
        return (
          this.elementsMap[nodeId] && this.elementsMap[nodeId].children.length
        );
      });
      this.args.onCollapseChildren(collapsibleChildren);
    }
    this.contextMenu.close();
  }
}

export default connect(stateToComputed, dispatchToActions)(ElementContextMenu);
