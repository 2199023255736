export default [
  {
      id: 'custom',
      name: 'Custom',
      featureTypesList: [ 'general' ]
  },
  {
      id: 'functional',
      name: 'Functional',
      featureTypesList: [ 'function' ]
  },
  {
      id: 'relational',
      name: 'Relational',
      featureTypesList: [ 'attachment', 'interaction', 'position' ]
  },
  {
      id: 'characteristical',
      name: 'Characteristic',
      featureTypesList: [ 'measurement',  'material', 'shape' ]
  }
];
