import Component from '@glimmer/component';
import { action } from '@ember/object';
import { alias } from '@ember/object/computed';
import { connect } from 'ember-redux';
import { getMethodEdge } from '../../../selectors/method-edge';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';

const dispatchToActions = {};

const stateToComputed = (state, attrs) => ({
  methodEdge: getMethodEdge(state, attrs.methodEdgeId),
});

class MethodEdgeContext extends Component {
  @service tracking;
  @service data;
  @service models;

  positionOptions = [
    {
      id: 'NORTH',
      name: 'North',
      testId: 'edge-context-north',
    },
    {
      id: 'SOUTH',
      name: 'South',
      testId: 'edge-context-south',
    },
    {
      id: 'EAST',
      name: 'East',
      testId: 'edge-context-east',
    },
    {
      id: 'WEST',
      name: 'West',
      testId: 'edge-context-west',
    },
  ];

  edgeTypeOptions = [
    {
      id: 'sequential',
      name: 'Sequential',
      testId: 'edge-context-sequential',
    },
    {
      id: 'conditional',
      name: 'Conditional',
      testId: 'edge-context-conditional',
    },
    {
      id: 'alternative',
      name: 'Alternative',
      testId: 'edge-context-alternative',
    },
  ];

  get classNames() {
    let classNames = ['method-edge-context', this.styleNamespace];
    return classNames.join(' ');
  }

  get styleNamespace() {
    return podNames['method-edge-context'];
  }

  @alias('methodEdge.method') methodId;
  @alias('methodEdge.curve') curve;
  @alias('methodEdge.type') type;
  @alias('methodEdge.sourcePosition') sourcePosition;
  @alias('methodEdge.targetPosition') targetPosition;

  @action
  setCurve(curve) {
    this.args.onUpdateMethodEdge(this.methodId, this.args.methodEdgeId, {
      curve,
    });

    this.tracking.trackEvent('method_edge_set_line_curve');
  }

  @action
  onUpdateEdgeType(type) {
    this.args.onUpdateMethodEdge(this.methodId, this.args.methodEdgeId, {
      type,
    });

    if (type === 'sequential') {
      this.tracking.trackEvent('method_edge_set_type_sequential');
    } else {
      this.tracking.trackEvent('method_edge_set_type_conditional');
    }
  }
  @action
  onUpdateSourcePosition(sourcePosition) {
    this.args.onUpdateMethodEdge(this.methodId, this.args.methodEdgeId, {
      sourcePosition,
    });
  }

  @action
  onUpdateTargetPosition(targetPosition) {
    this.args.onUpdateMethodEdge(this.methodId, this.args.methodEdgeId, {
      targetPosition,
    });
  }
}

export default connect(stateToComputed, dispatchToActions)(MethodEdgeContext);
