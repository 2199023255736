import { action, computed } from '@ember/object';
import {
  getMethodNode,
  getMethodNodeFeatureId,
  getSubmethodId,
} from '../../../selectors/method-node';

import Component from '@glimmer/component';
import { batchGroupBy } from '../../../utils/redux';
import { connect } from 'ember-redux';
import { debounce } from '@ember/runloop';
import { getElement } from '../../../selectors/element';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { updateMethod } from '../../../actions/method';
import { updateMethodNode } from '../../../actions/method-node';

const stateToComputed = (state, attrs) => ({
  methodNode: getMethodNode(state, attrs.methodNodeId),
  submethodId: getSubmethodId(state, attrs.methodNodeId, attrs.productId),
  featureId: getMethodNodeFeatureId(state, attrs.methodNodeId),
});

const dispatchToActions = {};

class MethodNodeContext extends Component {
  @tracked isOverflowed = false;
  @service redux;
  @service models;
  @tracked _label;

  constructor() {
    super(...arguments);
    this.setInputValues();
  }

  get styleNamespace() {
    return podNames['method-node-context'];
  }

  get classNames() {
    let classNames = [this.styleNamespace];
    if (this.isOverflowed) classNames.push('is-overflowed');
    return classNames.join(' ');
  }

  updateMethodNode(attrs = {}) {
    batchGroupBy.start();
    this.redux.store.dispatch(updateMethodNode(this.args.methodNodeId, attrs));
    this.redux.store.dispatch(updateMethod(this.methodId, {}));
    batchGroupBy.end();
  }

  @computed('args.methodNodeId', 'methodNode')
  get methodNodeModel() {
    return (
      this.models.find(this.args.methodNodeId) ||
      this.models.findOrCreate(
        this.args.methodNodeId,
        'methodNode',
        this.methodNode
      )
    );
  }

  @computed('methodNode.type')
  get type() {
    return this.methodNode && this.methodNode.type;
  }

  @computed('type')
  get typeLabel() {
    let label;
    switch (this.type) {
      case 'start':
        label = 'Start Node';
        break;
      case 'custom':
        label = 'Custom Step';
        break;
      case 'element':
        label = 'System Step';
        break;
    }

    return label;
  }

  @computed('methodNode.element')
  get elementId() {
    return this.methodNode && this.methodNode.element;
  }

  @computed('type')
  get isStart() {
    return this.type === 'start';
  }

  @computed('methodNode.element')
  get isElement() {
    return this.methodNode && this.methodNode.element ? true : false;
  }

  @computed('element.{component,instanceOf}')
  get elementIsInstance() {
    return this.element && this.element.component && this.element.instanceOf
      ? true
      : false;
  }

  @computed('methodNode.element')
  get element() {
    const state = this.redux.getState();
    return (
      this.methodNode &&
      this.methodNode.element &&
      getElement(state, this.methodNode.element)
    );
  }

  @computed('elementIsInstance', 'element.instanceOf')
  get instanceOf() {
    const state = this.redux.getState();
    return this.elementIsInstance && getElement(state, this.element.instanceOf);
  }

  @computed('element.id', 'elementIsInstance', 'instanceOf')
  get elementName() {
    const element = this.elementIsInstance ? this.instanceOf : this.element;
    return `<mention class="mention" data-type="element" data-id="${element.id}"></mention>`;
  }

  @action
  setInputValues() {
    const state = this.redux.getState();
    const methodNode = getMethodNode(state, this.args.methodNodeId);
    this._label = methodNode.label;
  }

  @action
  onLabelChange(label) {
    if (this.methodNodeModel.label !== label) {
      this.methodNodeModel.set('label', label);
      debounce(this, this.updateMethodNode, { label }, 400);
    }
  }
}

export default connect(stateToComputed, dispatchToActions)(MethodNodeContext);
