import {
  ADD_PRIOR_ART,
  REMOVE_PRIOR_ART,
  SORT_PRIOR_ARTS_LIST,
  UPDATE_PRIOR_ART,
} from './actionTypes';

import uuid from 'uuid/v4';

export const addPriorArt = (attributes = {}, priorArtId = uuid()) => {
  return {
    type: ADD_PRIOR_ART,
    payload: {
      priorArtId,
      attributes,
    },
  };
};

export const removePriorArt = (priorArtId) => {
  return {
    type: REMOVE_PRIOR_ART,
    payload: {
      priorArtId,
    },
  };
};

export const updatePriorArt = (priorArtId, attributes = {}) => {
  return {
    type: UPDATE_PRIOR_ART,
    payload: {
      priorArtId,
      attributes,
    },
  };
};


export const sortPriorArtsList = (priorArtIds) => {
  return {
    type: SORT_PRIOR_ARTS_LIST,
    payload: {
      priorArtIds,
    },
  };
};