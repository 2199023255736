import Component from '@glimmer/component';
import { action } from '@ember/object';
import { connect } from 'ember-redux';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';

const stateToComputed = null;
const dispatchToActions = {};

class SettingsModal extends Component {
  @service tracking;

  get styleNamespace() {
    return podNames['settings-modal'];
  }

  get classNames() {
    let classNames = [this.styleNamespace, 'settings-modal'];
    return classNames.join(' ');
  }

  @action
  didInsert() {
    this.tracking.trackEvent('opened_settings');
  }

  @action
  hideModal() {
    this.args.onSetShowingSettings(false);
    this.tracking.trackEvent('closed_settings');
  }

}

export default connect(stateToComputed, dispatchToActions)(SettingsModal);
