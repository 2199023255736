export default {
  attributes: {
    id: {
      type: 'uuid',
      defaultValue: null
    },
    type: {
      type: 'string',
      defaultValue: 'method' 
    },
    orientation: {
      type: 'enum',
      values: ['portrait', 'landscape'],
      defaultValue: 'portrait'
    },
    size: {
      type: 'string',
      defaultValue: 'us-letter'
    },
    // method only
    element: {
      type: 'uuid',
      defaultValue: ''
    },
    // method only
    elementVersion: {
      type: 'uuid',
      defaultValue: ''
    },
    methodNodesList: {
      type: 'array',
      defaultValue: []
    },
    methodEdgesList: {
      type: 'array',
      defaultValue: []
    },
    createdAt: {
      type: 'date'
    },
    updatedAt: {
      type: 'date'
    }
  }
}
