import Component from '@glimmer/component';
import podNames from 'ember-component-css/pod-names';

class PreviewRealizationElement extends Component {
  get styleNamespace() {
    return podNames['preview-realization-element'];
  }

  get classNames() {
    let classNames = [this.styleNamespace, 'preview-realization-element'];
    return classNames.join(' ');
  }
}

export default PreviewRealizationElement;
