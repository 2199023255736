import { get } from '@ember/object';
import { getPresentState } from '../utils/redux';

export const getCustomer = (state, customerId) =>
  get(getPresentState(state), `customers.entities.${customerId}`);
  
export const getCustomers = (state) =>
  get(getPresentState(state), `customers.entities`);

export const getCustomersList = (state) => get(getPresentState(state), `customers.ids`);
