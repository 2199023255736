import { BLACK_AND_WHITE_STROKE, HIGHLIGHT_STROKE } from '../../../constants/colors';

import Component from '@glimmer/component';
import Konva from 'konva';
import { action } from '@ember/object';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';

class DrawingHighlightCreateLineKonva extends Component {
  @service settings;

  constructor(owner, args) {
    super(owner, args);
    this.onScheduleRender = this.args.onScheduleRender;
  }

  @action
  didInsert() {
    this.setup();
    this._blackAndWhiteMode = this.settings.blackAndWhiteMode;
  }

  willDestroy() {
    this.lineNode.off('click');
    this.lineNode.destroy();
    this.onScheduleRender();
  }

  get styleNamespace() {
    return podNames['drawing-highlight-create-line-konva'];
  }

  get classNames() {
    let classNames = [this.styleNamespace];
    return classNames.join(' ');
  }

  get strokeColor() {
    let color = HIGHLIGHT_STROKE;
    if (this.settings.blackAndWhiteMode) {
      color = BLACK_AND_WHITE_STROKE;
    }
    return color;
  }

  get isStartPoint() {
    return !this.args.index;
  }

  get points() {
    const points = [];
    this.args.points.forEach(point => {
      points.push(point.x)
      points.push(point.y)
    })
    return points;
  }

  updateColors() {
    this.lineNode.stroke(this.strokeColor);
  }

  setup() {
    const lineNode = new Konva.Line({
      points: this.points,
      stroke: this.strokeColor,
      strokeWidth: 4,
    });

    // add events
    lineNode.on('click', () => {
      if (this.args.onClick && this.isStartPoint) this.args.onClick();
    });

    this.args.layer.add(lineNode);
    this.lineNode = lineNode;

    this.onScheduleRender();
  }

  @action
  onUpdate(elem, [points, blackAndWhiteMode]) {
    let detailsChanged = false;

    if (points) {
      this.lineNode.points(this.points);
      detailsChanged = true;
    }

    if (this._blackAndWhiteMode !== blackAndWhiteMode) {
      this._blackAndWhiteMode = blackAndWhiteMode;
      this.updateColors();
      detailsChanged = true;
    }

    if (detailsChanged) {
      this.onScheduleRender(this.args.layer);
    }
  }

}

export default DrawingHighlightCreateLineKonva;
