import { getPresentState } from '../utils/redux';
import { get } from '@ember/object';

export const getComparison = (state, comparisonId) =>
  get(getPresentState(state), `comparisons.entities.${comparisonId}`);
  
export const getComparisons = (state) =>
  get(getPresentState(state), `comparisons.entities`);

export const getComparisonsList = (state) => get(getPresentState(state), `comparisons.ids`);
