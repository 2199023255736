import Component from '@glimmer/component';
import podNames from 'ember-component-css/pod-names';

export default class ProductViewPage extends Component {

  get styleNamespace() {
    return podNames['product-view-page'];
  }

  get classNames() {
    let classNames = [this.styleNamespace, 'product-view-page'];
    return classNames.join(' ');
  }
}
