import {
  ADD_HIGHLIGHT,
  ADD_MARKER,
  REMOVE_DRAWING,
  REMOVE_HIGHLIGHT,
  REMOVE_SELECTED_DRAWING_ITEMS,
  UPDATE_DRAWING_ITEMS,
  UPDATE_MARKER,
} from '../actions/actionTypes';

import { addFromSchema } from '../utils/schema';
import { omit } from 'lodash';

const initialState = {
  ids: [],
  entities: {},
};

const markersReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case ADD_MARKER: {
      let { markerId, attributes } = payload;
      const id = markerId;
      const createdAt = new Date();
      const updatedAt = new Date();
      const marker = addFromSchema('marker', {
        ...attributes,
        id,
        createdAt,
        updatedAt,
      });

      return {
        ids: [...state.ids, id],
        entities: {
          ...state.entities,
          [markerId]: marker,
        },
      };
    }

    case UPDATE_MARKER: {
      const { markerId, attributes } = payload;
      const updatedAt = new Date();
      return {
        ...state,
        entities: {
          ...state.entities,
          [markerId]: {
            ...state.entities[markerId],
            ...attributes,
            updatedAt,
          },
        },
      };
    }

    case ADD_HIGHLIGHT: {
      const { markerId, highlightId } = payload;
      const updatedAt = new Date();
      return {
        ...state,
        entities: {
          ...state.entities,
          [markerId]: {
            ...state.entities[markerId],
            highlightsList: [
              ...state.entities[markerId].highlightsList,
              highlightId,
            ],
            updatedAt,
          },
        },
      };
    }
    
    case REMOVE_HIGHLIGHT: {
      const { markerId, highlightId } = payload;
      const updatedAt = new Date();
      return {
        ...state,
        entities: {
          ...state.entities,
          [markerId]: {
            ...state.entities[markerId],
            highlightsList: state.entities[markerId].highlightsList.filter(id => id !== highlightId),
            updatedAt,
          },
        },
      };
    }

    case UPDATE_DRAWING_ITEMS: {
      const { markers } = payload;
      let markerUpdates = {};
      const updatedAt = new Date();

      markers.forEach((marker) => {
        markerUpdates[marker.id] = {
          ...state.entities[marker.id],
          ...marker.attributes,
          updatedAt,
        };
      });

      return {
        ...state,
        entities: {
          ...state.entities,
          ...markerUpdates,
        },
      };
    }

    case REMOVE_SELECTED_DRAWING_ITEMS: {
      const { selectedMarkers } = payload;

      return {
        ids: state.ids.filter((id) => !selectedMarkers.includes(id)),
        entities: omit(state.entities, selectedMarkers),
      };
    }

    case REMOVE_DRAWING: {
      const { markersList } = payload;

      return {
        ids: state.ids.filter((id) => !markersList.includes(id)),
        entities: omit(state.entities, markersList),
      };
    }

    /* istanbul ignore next */
    default:
      return state;
  }
};

export default markersReducer;
