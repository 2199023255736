export default {
  attributes: {
    id: {
      type: 'uuid',
      defaultValue: null
    },
    name: {
      type: 'string',
      defaultValue: ''
    },
    type: {
      type: 'type',
      defaultValue: 'sequential' // conditional
    },
    curve: {
      type: 'string',
      defaultValue: 'linear'
    },
    path: {
      type: 'string',
      defaultValue: ''
    },
    label: {
      type: 'string',
      defaultValue: ''
    },
    bendPoints: {
      type: 'object',
      defaultValue: {}
    },
    methodEdgePointsList: {
      type: 'array',
      defaultValue: []
    },
    source: {
      type: 'uuid',
      defaultValue: null
    },
    sourcePosition: {
      type: 'string',
      defaultValue: ''
    },
    target: {
      type: 'uuid',
      defaultValue: null
    },
    targetPosition: {
      type: 'string',
      defaultValue: ''
    },
    method: {
      type: 'uuid',
      defaultValue: null
    },
    createdAt: {
      type: 'date'
    }
  }
}
