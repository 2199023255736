import { action, computed } from '@ember/object';
import {
  getElement,
  getElementRequirementsList,
} from '../../../selectors/element';

import Component from '@glimmer/component';
import { connect } from 'ember-redux';
import { getActiveProductId } from '../../../selectors/invention-ui';
import { getComponent } from '../../../selectors/component';
import { getDeletedReferencesList } from '../../../selectors/deleted-reference';
import { getElementVersion } from '../../../selectors/element-version';
import { getFeature } from '../../../selectors/feature';
import { getPreferredElementVersionId } from '../../../selectors/product';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';
import uuid from 'uuid/v4';

const stateToComputed = (state, attrs) => ({
  deletedReferencesList: getDeletedReferencesList(state),
  element: getElement(state, attrs.elementId),
  activeProductId: getActiveProductId(state),
});

const dispatchToActions = {};

class ElementPopover extends Component {
  @service redux;

  language = 'report_list_abbreviated';

  constructor() {
    super(...arguments);
    this.popoverDomElementId = this.args.popoverDomElementId;
    this.domElementId = uuid();
  }

  get styleNamespace() {
    return podNames['element-popover'];
  }

  get classNames() {
    let classNames = [this.styleNamespace, 'element-popover'];
    if (this.isDeleted) classNames.push('is-deleted');
    return classNames.join(' ');
  }

  @computed('args.elementId', 'deletedReferencesList.[]')
  get isDeleted() {
    return (
      this.deletedReferencesList &&
      this.deletedReferencesList.includes(this.args.elementId)
    );
  }

  @computed('element.elementVersionsList.[]')
  get elementVersionsList() {
    return this.element && this.element.elementVersionsList;
  }

  @computed('elementVersionsList.[]', 'activeProductId', 'args.elementId')
  get elementVersionId() {
    const state = this.redux.getState();
    const preferredElementVersionId = getPreferredElementVersionId(
      state,
      this.args.elementId,
      this.activeProductId
    );
    return preferredElementVersionId;
  }

  @computed('elementVersionId')
  get elementVersion() {
    const state = this.redux.getState();
    return (
      this.elementVersionId && getElementVersion(state, this.elementVersionId)
    );
  }

  @computed('element.{component,instanceOf,name}')
  get elementName() {
    const state = this.redux.getState();
    let name = this.element.name;
    if (this.element.component && this.element.instanceOf) {
      const instanceOf = getElement(state, this.element.instanceOf);
      name = instanceOf && instanceOf.name;
    }
    return name;
  }

  @computed(
    'activeProductId',
    'args.elementId',
    'element.{component,isComponent}'
  )
  get preferredElementVersionIds() {
    const state = this.redux.getState();
    let preferredElementVersionIds = [];
    if (this.element.component && this.element.isComponent) {
      const component = getComponent(state, this.element.component);
      component &&
        component.instancesList.forEach((instanceId) => {
          const preferredElementVersionId = getPreferredElementVersionId(
            state,
            instanceId,
            this.activeProductId
          );
          preferredElementVersionId &&
            preferredElementVersionIds.push(preferredElementVersionId);
        });
      preferredElementVersionIds = preferredElementVersionIds.uniq();
    } else {
      const preferredElementVersionId = getPreferredElementVersionId(
        state,
        this.args.elementId,
        this.activeProductId
      );
      if (preferredElementVersionId) {
        preferredElementVersionIds = [preferredElementVersionId];
      }
    }
    return preferredElementVersionIds;
  }
  @computed('preferredElementVersionIds.[]')
  get preferredElementVersions() {
    const state = this.redux.getState();
    return this.preferredElementVersionIds.map((elementVersionId) =>
      getElementVersion(state, elementVersionId)
    );
  }
  @computed('preferredElementVersions.[]')
  get preferredElementVersionNames() {
    return this.preferredElementVersions
      .map((elementVersion) => elementVersion.name)
      .join(', ');
  }

  @computed(
    'activeProductId',
    'elementVersionsList.[]',
    'preferredElementVersionIds'
  )
  get markersList() {
    const state = this.redux.getState();
    let markersList = [];
    if (this.activeProductId) {
      this.preferredElementVersionIds.forEach((elementVersionId) => {
        const elementVersion = getElementVersion(state, elementVersionId);
        markersList = markersList.concat(elementVersion.markersList);
      });
    } else {
      this.elementVersionsList.forEach((elementVersionId) => {
        const elementVersion = getElementVersion(state, elementVersionId);
        markersList = markersList.concat(elementVersion.markersList);
      });
    }
    return markersList;
  }

  @computed('markersList.[]')
  get firstReference() {
    return this.markersList[0];
  }

  @computed('args.elementId')
  get requirementsList() {
    const state = this.redux.getState();
    return getElementRequirementsList(state, this.args.elementId);
  }

  @computed('elementVersion.constraintsList.[]')
  get constraintsList() {
    return this.elementVersion && this.elementVersion.constraintsList;
  }

  @computed('elementVersion.featuresList.[]')
  get examplesList() {
    const state = this.redux.getState();
    return (
      this.elementVersion &&
      this.elementVersion.featuresList.filter((featureId) => {
        const feature = getFeature(state, featureId);
        return feature.type === 'analogs';
      })
    );
  }

  @computed('elementVersion.featuresList.[]')
  get definitionsList() {
    const state = this.redux.getState();
    return (
      this.elementVersion &&
      this.elementVersion.featuresList.filter((featureId) => {
        const feature = getFeature(state, featureId);
        return feature.type === 'definition';
      })
    );
  }

  @action
  didInsert() {
    const domElement = document.getElementById(this.domElementId);
    this._handleMouseenter = this.handleMouseenter.bind(this);
    this._handleMouseleave = this.handleMouseleave.bind(this);
    domElement.addEventListener('mouseenter', this._handleMouseenter, false);
    domElement.addEventListener('mouseleave', this._handleMouseleave, false);
  }

  willDestroy() {
    super.willDestroy(...arguments);
    const domElement = document.getElementById(this.domElementId);
    domElement &&
      domElement.removeEventListener(
        'mouseenter',
        this._handleMouseenter,
        false
      );
    domElement &&
      domElement.removeEventListener(
        'mouseleave',
        this._handleMouseleave,
        false
      );
  }

  handleMouseenter() {
    this.args.onMouseenter &&
      this.args.onMouseenter(
        this.args.elementId,
        this.args.elementVersionId,
        this.popoverDomElementId
      );
  }

  handleMouseleave() {
    this.args.onMouseleave && this.args.onMouseleave();
  }
}

export default connect(stateToComputed, dispatchToActions)(ElementPopover);
