export default {
  attributes: {
    id: {
      type: 'uuid',
      defaultValue: null
    },
    method: {
      type: 'uuid',
      defaultValue: ''
    },
    element: {
      type: 'uuid',
      defaultValue: ''
    },
    feature: {
      type: 'uuid',
      defaultValue: ''
    },
    type: {
      type: 'type',
      defaultValue: 'custom' // element or start
    },
    label: {
      type: 'string',
      defaultValue: ''
    },
    incomingEdgesList: {
      type: 'array',
      defaultValue: []
    },
    outgoingEdgesList: {
      type: 'array',
      defaultValue: []
    },
    x: {
      type: 'number',
      defaultValue: 0
    },
    y: {
      type: 'number',
      defaultValue: 0
    },
    width: {
      type: 'number',
      defaultValue: 250
    },
    height: {
      type: 'number',
      defaultValue: 150
    },
    createdAt: {
      type: 'date'
    }
  }
}
