import Component from '@glimmer/component';
import { computed } from '@ember/object';
import { connect } from 'ember-redux';
import { getPatentSpecification } from '../../../selectors/patent-specification';
import podNames from 'ember-component-css/pod-names';

const dispatchToActions = {};

const stateToComputed = (state) => ({
  patentSpecification: getPatentSpecification(state)
});

class InventionName extends Component {

  get styleNamespace() {
    return podNames['invention-name'];
  }

  get classNames() {
    let classNames = [this.styleNamespace];
    return classNames.join(' ');
  }

  @computed('patentSpecification.title')
  get name() {
    return this.patentSpecification && this.patentSpecification.title || 'Untitled Invention'
  }
}

export default connect(
  stateToComputed,
  dispatchToActions
)(InventionName);
