import Component from '@glimmer/component';
import { action } from '@ember/object';
import { connect } from 'ember-redux';
import { getInventionId } from '../../../selectors/invention';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

const stateToComputed = (state) => ({
  inventionId: getInventionId(state),
});

const dispatchToActions = {};

class HistoryVersionCreateModal extends Component {
  @service store;
  @service tracking;
  @service data;
  @service redux;

  @tracked name;
  @tracked isLoading;
  @tracked hasError;

  get styleNamespace() {
    return podNames['history-version-create-modal'];
  }

  get classNames() {
    let classNames = [this.styleNamespace, 'history-version-create-modal'];
    return classNames.join(' ');
  }

  @action
  hideModal() {
    this.data.setShowingHistoryVersionCreateModal(false);
  }

  @action
  async onCreate() {
    const state = this.redux.getState();
    this.isLoading = true;
    this.hasError = false;
    try {
      // get the state as a string
      const stringifiedState = await this.redux.getStringifiedState.perform();

      // save it to remote
      await this.data.addVersionToCloud(
        this.inventionId,
        stringifiedState,
        this.name
      );

      // save any unsaved assets to remote
      await this.data.addAssetsToCloud(this.inventionId, state);

      // close modal
      this.data.setShowingHistoryVersionCreateModal(false);
    } catch (err) {
      this.isLoading = false;
      this.hasError = true;
    }
  }

  @action
  onNameChange(value) {
    if (value) this.name = value;
  }
}

export default connect(
  stateToComputed,
  dispatchToActions
)(HistoryVersionCreateModal);
