import Component from '@glimmer/component';
import { connect } from 'ember-redux';
import { getMethodsList } from '../../../selectors/graph';
import podNames from 'ember-component-css/pod-names';

const dispatchToActions = {};

const stateToComputed = (state) => ({
  methodsList: getMethodsList(state),
});

class MethodsList extends Component {
  get classNames() {
    let classNames = [this.styleNamespace, 'methods-list'];
    return classNames.join(' ');
  }

  get styleNamespace() {
    return podNames['methods-list'];
  }
}

export default connect(stateToComputed, dispatchToActions)(MethodsList);
