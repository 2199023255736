import {
  ADD_REVIEW,
  UPDATE_REVIEW,
  REMOVE_REVIEW,
  SORT_REVIEWS_LIST
} from '../actions/actionTypes';
import { addFromSchema } from '../utils/schema';
import { omit } from 'lodash';

const initialState = {
  ids: [],
  entities: {},
};

const reviewsReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case ADD_REVIEW: {
      let { reviewId, attributes } = payload;
      const id = reviewId;
      const createdAt = new Date();
      const updatedAt = new Date();
      const review = addFromSchema('review', {
        id,
        createdAt,
        updatedAt,
        ...attributes,
      });

      return {
        ids: [...state.ids, id],
        entities: {
          ...state.entities,
          [reviewId]: review,
        },
      };
    }

    case UPDATE_REVIEW: {
      const { reviewId, attributes } = payload;
      const updatedAt = new Date();
      return {
        ...state,
        entities: {
          ...state.entities,
          [reviewId]: {
            ...state.entities[reviewId],
            ...attributes,
            updatedAt,
          },
        },
      };
    }

    case REMOVE_REVIEW: {
      const { reviewId } = payload;
      return {
        ids: state.ids.filter((id) => id !== reviewId),
        entities: omit(state.entities, reviewId),
      };
    }

    case SORT_REVIEWS_LIST: {
      const { reviewIds } = payload;
      return {
        ...state,
        ids: reviewIds,
      };
    }

    /* istanbul ignore next */
    default:
      return state;
  }
};

export default reviewsReducer;
