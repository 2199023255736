import $ from 'jquery';
import uuid from 'uuid/v4';

export const textValue = (string) => {
  if (typeof string !== 'string') return '';
  string = string.replace(/&nbsp;/g, ' ');
  string = string.replace(/\s\s+/g, ' ');
  string = string.trim();
  return $(`<div>${string}</div>`).text();
}

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const removeEmptyTags = (string, tagName = 'p') => {
  const div = document.createElement('div');
  div.innerHTML = string;
  div.querySelectorAll(tagName).forEach(element => {
    const html = element.innerHTML.trim();
    if (!html || html === '<br>' || html.toLowerCase() == "&nbsp;") {
        element.parentNode.removeChild(element);
    }
  })
  string = div.innerHTML;
  return string;
}

export const addClassToTags = (string, className = '', tagName = 'p') => {
  const div = document.createElement('div');
  div.innerHTML = string;
  div.querySelectorAll(tagName).forEach(element => {
    element.classList.add(className)
  })
  string = div.innerHTML;
  return string;
}


export const isEmptyOrSpaces = (str) => {
    return str === null || str.match(/^ *$/) !== null;
}

export const isTextEqual = (string1, string2) => {
  const trimmed1 = string1.replace(/\s+/g,'');
  const trimmed2 = string2.replace(/\s+/g,'');
  return trimmed1.localeCompare(trimmed2, undefined, { sensitivity: 'base' }) === 0;
}

export const replaceAll = (str, source, target) => {
  const re = new RegExp(source,"g");
  return str.replace(re, target);
}

export const replaceAllInList = (str, sources, targets) => {
  let tokenIndex;
  if (sources.length === targets.length) {
    for (tokenIndex = 0; tokenIndex < targets.length; tokenIndex++) {
      const target = targets[tokenIndex];
      const source = sources[tokenIndex];
			str = replaceAll(str, source, target); // this replaceAll fn kills chrome on import of large files need to chunk it
      // str = str;
    }
  }
  return str;
}

export const getUUIDs = (numberOfIds = 1) => {
  let ids = []

  while (ids.length < numberOfIds) {
    ids.push(uuid());
  }

  return ids;
}
