import {
  ADD_TERM,
  REMOVE_TERM,
  SORT_TERMS_LIST,
  UPDATE_TERM,
} from './actionTypes';

import uuid from 'uuid/v4';

export const addTerm = (attributes, termId = uuid()) => {
  return {
    type: ADD_TERM,
    payload: {
      termId,
      attributes
    }
  };
};

export const removeTerm = (termId) => {
  return {
    type: REMOVE_TERM,
    payload: {
      termId,
    }
  };
};

export const sortTermsList = (termIds) => {
  return {
    type: SORT_TERMS_LIST,
    payload: {
      termIds
    }
  };
};

export const updateTerm = (termId, attributes) => {
  return {
    type: UPDATE_TERM,
    payload: {
      termId,
      attributes
    }
  };
};

