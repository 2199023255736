import Component from '@glimmer/component';
import podNames from 'ember-component-css/pod-names';

class ExplorerToggle extends Component {
  get styleNamespace() {
    return podNames['explorer-toggle'];
  }

  get classNames() {
    let classNames = [this.styleNamespace];
    return classNames.join(' ');
  }
}

export default ExplorerToggle;