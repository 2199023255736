import {
  ADD_FEATURE,
  REMOVE_FEATURE,
  REMOVE_FEATURES,
  SET_ACTIVE_FEATURE,
  UPDATE_FEATURE,
} from './actionTypes';

import FeatureTypes from '../constants/types/features';
import uuid from 'uuid/v4';

export const addFeature = (
  attributes,
  featureId = uuid()
) => {
  attributes.value = attributes.value || FeatureTypes[attributes.type].defaultValue;
  return {
    type: ADD_FEATURE,
    payload: {
      attributes,
      featureId
    },
  };
};

export const updateFeature = (featureId, attributes) => {
  return {
    type: UPDATE_FEATURE,
    payload: {
      featureId,
      attributes,
    },
  };
};

export const removeFeature = (featureId) => {
  return {
    type: REMOVE_FEATURE,
    payload: {
      featureId
    },
  };
};

export const removeFeatures = (featuresList) => {
  return {
    type: REMOVE_FEATURES,
    payload: {
      featuresList
    },
  };
};

export const setActiveFeature = (featureId) => {
  return {
    type: SET_ACTIVE_FEATURE,
    payload: {
      featureId
    },
  };
};

