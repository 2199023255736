import { action, computed } from '@ember/object';
import {
  getProduct,
  getProductPatentabilityStatus,
} from '../../../selectors/product';

import Component from '@glimmer/component';
import { batchGroupBy } from '../../../utils/redux';
import { connect } from 'ember-redux';
import { getActivePatentabilitySection } from '../../../selectors/invention-ui';
import { getPatentabilitySections } from '../../../selectors/type';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';

const stateToComputed = (state, attrs) => ({
  status: getProductPatentabilityStatus(state, attrs.productId),
  sections: getPatentabilitySections(state),
  activeSection: getActivePatentabilitySection(state),
  product: getProduct(state, attrs.productId),
});
const dispatchToActions = {};

class PatentabilityModal extends Component {
  @service data;
  @service tracking;

  lastSection = 'public_disclosure';

  get styleNamespace() {
    return podNames['patentability-modal'];
  }

  get classNames() {
    let classNames = [this.styleNamespace, 'patentability-modal'];
    return classNames.join(' ');
  }

  @action
  didInsert() {
    this.tracking.trackEvent('opened_patentability');
    const incompleteList = this.status.incomplete;
    let section;
    if (incompleteList.length) {
      section = incompleteList[0];
      if (section.indexOf('prior_art') > -1) {
        section = 'prior_art';
      }
    } else {
      section = 'conception';
    }

    this.args.onSetActivePatentabilitySection(section);
  }

  @action
  hideModal() {
    batchGroupBy.start();
    this.data.setShowingPatentability(false);
    batchGroupBy.end();
    this.tracking.trackEvent('closed_patentability');
  }

  @action
  onSelect(value) {
    this.args.onSetActivePatentabilitySection(value);
  }

  @action
  onUpdatePatentability(patentabilityId, value) {
    const patentability = {
      ...this.product.patentability,
      [patentabilityId]: {
        value,
      },
    };
    this.args.onUpdateProduct(this.args.productId, { patentability });
  }

  @computed('sections.[]', 'activeSection')
  get nextSection() {
    const sectionsList = this.sections.map((section) => section.id);
    const currentIndex = sectionsList.indexOf(this.activeSection);
    const nextSection = sectionsList[currentIndex + 1] || sectionsList[0];
    return nextSection;
  }

  @computed('sections.[]', 'status.completed.[]')
  get completedSections() {
    return this.sections
      .filter((section) => {
        return section.typesList.every((typeId) =>
          this.status.completed.includes(typeId)
        );
      })
      .map((section) => section.id);
  }

  @computed('sections.[]', 'status.warnings.[]')
  get warningSections() {
    return this.sections
      .filter((section) => {
        return section.typesList.find((typeId) =>
          this.status.warnings.includes(typeId)
        );
      })
      .map((section) => section.id);
  }

  @computed('status.warnings.[]')
  get priorArtWarnings() {
    return this.status.warnings.filter((typeId) => {
      return [
        'prior_art_market',
        'prior_art_patented',
        'prior_art_published',
      ].includes(typeId);
    });
  }

  @action
  onNext() {
    this.args.onSetActivePatentabilitySection(this.nextSection);
  }
}

export default connect(stateToComputed, dispatchToActions)(PatentabilityModal);
