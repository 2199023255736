import Route from '@ember/routing/route';
import { detect } from 'detect-browser';
import { inject as service } from '@ember/service';

export default class ActivationRoute extends Route {
  @service session;
  @service sessionManager;
  @service tracking;
  @service router;

  beforeModel() {
    this.session.prohibitAuthentication('dashboard');
  }

  async model(params) {
    const token = params.token;
    const browser = detect();

    if (browser && browser.name !== 'chrome') {
      return this.router.transitionTo('sign-in');
    }

    if (token) {
      await this.sessionManager.activate(token);
    } else {
      console.log('this', this)
      console.log('router', this.router)
      this.router.transitionTo('expired');
    }
    return;
  }
}
