import Component from '@ember/component';
import { computed } from '@ember/object';
import { alias, equal, not } from '@ember/object/computed';
import { connect } from 'ember-redux';
import { htmlSafe } from '@ember/string';
import { getMarker } from '../../../selectors/marker';
import { getDeletedElementsList } from '../../../selectors/invention';
import {
  getAutoLabelOrientation,
  getLabelCoords,
  getLabelClass,
  parseCurve,
  getMarkerPath,
} from '../../../utils/drawing';

const dispatchToActions = {};

const stateToComputed = (state, attrs) => ({
  deletedElementsList: getDeletedElementsList(state),
  marker: getMarker(state, attrs.markerId),
});

const MarkerPreviewSvg = Component.extend({
  tagName: 'g',
  classNames: ['marker', 'marker-preview-svg'],
  classNameBindings: [
    'curve',
    'isCollapsed',
    'hasLeadLine',
    'hasPointArrow',
    'hasNoLeadLine',
    'elementIsDeleted',
    'isPresentation',
    'isActive',
    'isNovel',
  ],

  didUpdateAttrs() {
    this._super(...arguments);
    if (this.onUpdate) {
      this.onUpdate();
    }
  },

  elementIsDeleted: computed(
    '_elementId',
    'deletedElementsList.[]',
    function () {
      return (
        this.deletedElementsList &&
        this.deletedElementsList.includes(this._elementId)
      );
    }
  ),

  hasPointArrow: equal('marker.pointStyle', 'arrow'),
  labelWidth: 250,
  labelHeight: 150,

  isCollapsed: computed(
    'startX',
    'startY',
    'midX',
    'midY',
    'endX',
    'endY',
    function () {
      return (
        this.startX === this.midX &&
        this.startY === this.midY &&
        this.startX === this.endX &&
        this.startY === this.endY
      );
    }
  ),

  marker: null,
  pointStyle: alias('marker.pointStyle'),
  _elementId: alias('marker.element'),
  termId: alias('marker.term'),
  elementVersionId: alias('marker.elementVersion'),
  startX: alias('marker.startX'),
  startY: alias('marker.startY'),
  midX: alias('marker.midX'),
  midY: alias('marker.midY'),
  endX: alias('marker.endX'),
  endY: alias('marker.endY'),
  isMultiselected: false,
  hasLeadLine: alias('marker.hasLeadLine'),
  hasNoLeadLine: not('hasLeadLine'),
  curve: alias('marker.curve'),
  startRadius: 20,
  labelMargin: -10,
  labelType: 'ordinal',
  isPresentation: false,
  isActive: false,

  labelOrientation: computed(
    'marker.labelOrientation',
    'autoLabelOrientation',
    function () {
      return this.marker && this.marker.labelOrientation === 'auto'
        ? this.autoLabelOrientation
        : this.marker.labelOrientation;
    }
  ),

  autoLabelOrientation: computed(
    'endX',
    'endY',
    'midX',
    'midY',
    'startX',
    'startY',
    function () {
      return getAutoLabelOrientation({
        startX: this.startX,
        startY: this.startY,
        endX: this.endX,
        endY: this.endY,
        midX: this.midX,
        midY: this.midY,
      });
    }
  ),

  labelCoords: computed(
    'labelHeight',
    'labelMargin',
    'labelOrientation',
    'labelWidth',
    'startRadius',
    'startX',
    'startY',
    function () {
      return getLabelCoords({
        orientation: this.labelOrientation,
        labelWidth: this.labelWidth,
        labelHeight: this.labelHeight,
        startX: this.startX,
        startY: this.startY,
        margin: this.labelMargin,
        radius: this.startRadius,
      });
    }
  ),

  labelX: alias('labelCoords.x'),

  labelY: alias('labelCoords.y'),

  labelClass: computed('hasLeadLine', function () {
    const labelClass = getLabelClass(this.hasLeadLine);
    return htmlSafe(labelClass);
  }),

  line: computed(
    'curve',
    'startX',
    'startY',
    'midX',
    'midY',
    'endX',
    'endY',
    function () {
      return getMarkerPath({
        curve: parseCurve(this.curve),
        startX: this.startX,
        startY: this.startY,
        midX: this.midX,
        midY: this.midY,
        endX: this.endX,
        endY: this.endY,
      });
    }
  ),

  actions: {
    onLabelClick() {
      if (this.onLabelClick) this.onLabelClick(this.markerId, this.elementVersionId, this._elementId)
    }
  }
});

export default connect(stateToComputed, dispatchToActions)(MarkerPreviewSvg);
