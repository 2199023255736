import { action, computed } from '@ember/object';
import { getElement, getElementsList } from '../../../selectors/element';
import { getTerm, getTermsList } from '../../../selectors/term';

import Component from '@glimmer/component';
import { connect } from 'ember-redux';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { union } from '@ember/object/computed';
import uuid from 'uuid/v4';

const dispatchToActions = {};

const stateToComputed = (state) => ({
  elementsList: getElementsList(state),
  termsList: getTermsList(state),
});

class MarkerSelectItem extends Component {
  @service redux;
  @service data;

  @tracked value = '';
  @tracked results = [];
  @tracked filteredResults = [];
  @tracked isFocused = false;

  _markerId;
  searcher;
  domElementId = `marker-select-item-${uuid()}`;

  get styleNamespace() {
    return podNames['marker-select-item'];
  }

  get classNames() {
    let classNames = [this.styleNamespace];
    if (this.args.isSelected) classNames.push('is-selected');
    return classNames.join(' ');
  }

  matcher(value = '', text = '') {
    return value.toUpperCase().startsWith(text.toUpperCase()) ? true : false;
  }

  @action
  didInsert() {
    this._markerId = this.args.markerId;
    if (!this.args.modelId) {
      const textarea = document.querySelector(
        `#${this.domElementId} .textarea`
      );
      textarea.focus();
    }
  }

  @action
  onUpdate(elem, [markerId]) {
    if (this._markerId !== markerId) {
      this._markerId = markerId;
      this.results = [];
      this.filteredResults = [];
      this.value = '';
    }
  }

  @computed('args.{category,type}')
  get categoryName() {
    let name;
    switch (this.args.category) {
      case 'system':
        name = 'System';
        break;
      case 'part':
        name = 'Part';
        break;
    }

    if (this.args.type === 'term') {
      name = 'Term';
    }

    return name;
  }

  @computed('args.{category,type}')
  get resultType() {
    let resultType;
    switch (this.args.category) {
      case 'system':
        resultType = 'system';
        break;
      case 'part':
        resultType = 'part';
        break;
    }

    if (this.args.type === 'term') {
      resultType = 'term';
    }

    return resultType;
  }

  get placeholder() {
    let placeholder;
    switch (this.args.category) {
      case 'part':
        placeholder = 'Type to find or add a part';
        break;
      case 'system':
        placeholder = 'Type to find or add a system';
        break;
    }

    if (this.args.type === 'term') {
      placeholder = 'Type to find or add a term';
    }
    return placeholder;
  }

  @union('terms', 'elements') options;

  @computed('elementsList.[]', 'args.category', 'resultType')
  get elements() {
    const state = this.redux.getState();
    return this.elementsList
      .filter((elementId) => {
        const element = getElement(state, elementId);
        return (
          element &&
          !element.instanceOf &&
          element.category === this.args.category
        );
      })
      .map((elementId) => {
        const element = getElement(state, elementId) || {};
        return {
          ...element,
          resultType: this.resultType,
        };
      });
  }

  @computed('termsList.[]', 'args.category', 'resultType')
  get terms() {
    const state = this.redux.getState();
    return this.termsList.map((termId) => {
      const term = getTerm(state, termId) || {};
      return {
        ...term,
        resultType: this.resultType,
      };
    });
  }

  @action
  onCreateResult() {
    if (this.args.type === 'element') {
      this.args.onAddElement(this.value, this.args.category);
    }

    if (this.args.type === 'term') {
      this.args.onAddTerm(this.value);
    }
  }

  @action
  onSelectResult(resultId, resultType, resultElementVersion, category) {
    if (resultType === 'element') {
      this.args.onSetElement(resultId, resultElementVersion, category);
    }

    if (resultType === 'term') {
      this.args.onSetTerm(resultId);
    }
  }

  @action
  onChange(name) {
    const results = [];
    const matches = this.options.filter((option) => {
      return this.matcher(option.name, name);
    });

    matches.forEach((match) => {
      if (match.type === 'element') {
        match.elementVersionsList.forEach((elementVersionId) => {
          results.push({
            id: match.id,
            type: 'element',
            elementVersionId,
          });
        });
      } else {
        results.push({
          id: match.id,
          type: 'term',
        });
      }
    });

    this.value = name;
    this.results = results;
    console.log('results', results);
    console.log('this.args.type', this.args.type);
    this.filteredResults = results.filter(
      (result) => result.type === this.args.type
    );
    // this.filteredResults = results
    // this.args.onUpdate(this.args.bulkCreateItemId, { name });
  }

  @action
  onEnter() {
    if (this.args.onEnter) {
      this.args.onEnter(this.args.bulkCreateItemId);
    }
  }

  @action
  onFocus() {
    this.isFocused = true;
  }

  @action
  onBlur() {
    this.isFocused = false;
  }
}

export default connect(stateToComputed, dispatchToActions)(MarkerSelectItem);
