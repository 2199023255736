import Model, { attr } from '@ember-data/model';

export default class ElementModel extends Model {
  @attr name;
  @attr type;
  @attr elementVersion;
  @attr x;
  @attr y;
  @attr outcome;
  @attr requirementsList;
  @attr elementVersionsList;
  @attr createdAt;
}
