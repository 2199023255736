import Component from '@glimmer/component';
import { computed } from '@ember/object';
import { connect } from 'ember-redux';
import { getMethod } from '../../../selectors/method';
import { getMethodGroupRealization } from '../../../utils/method';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';

const dispatchToActions = {};

const stateToComputed = (state, attrs) => ({
  method: getMethod(state, attrs.methodId),
});

class MethodStepsRealization extends Component {
  @service store;

  get classNames() {
    let classNames = [this.styleNamespace, 'method-steps-realization'];
    return classNames.join(' ');
  }

  get styleNamespace() {
    return podNames['method-steps-realization'];
  }

  @computed('args.methodId')
  get methodModel() {
    return this.store.peekRecord('method', this.args.methodId);
  }

  // eslint-disable-next-line ember/require-computed-property-dependencies
  @computed(
    'methodModel.{groupedStepsList.[],traversedNodesList.[]}',
    'args.methodId'
  )
  get methodGroupRealizations() {
    const state = this.redux.getState();
    return this.methodModel.ungroupedStepsList.map((step, index) => {
      return getMethodGroupRealization(
        state,
        this.args.methodId,
        [step],
        this.methodModel.traversedNodesList,
        this.methodModel.traversedEdgesList,
        index
      );
    });
  }
}

export default connect(
  stateToComputed,
  dispatchToActions
)(MethodStepsRealization);
