import EmberRouter from '@ember/routing/router';
import config from 'patentdive-data/config/environment';

export default class Router extends EmberRouter {
  location = config.locationType;
  rootURL = config.rootURL;
}

Router.map(function() {
  this.route('sign-in');
  this.route('activation', { path: 'activation/:token' });
  this.route('expired');
  this.route('authorized', { path: '/', resetNamespace: true }, function() {
    this.route('dashboard', { path: '/', resetNamespace: true });
    this.route('invention', { path: '/:invention_id', resetNamespace: true });
    this.route('history', { path: '/history/:instance_id', resetNamespace: true }, function() {
      this.route('state', { path: '/:state_id', resetNamespace: true });
    });
  });
});
