import ENV from '../config/environment';
import Service from '@ember/service';
import migrations from '../migrations';
import { inject as service } from '@ember/service';

export default class Migrations extends Service {
  schemaVersion = ENV.schemaVersion;
  minVersion = 7;

  @service notifications;
  @service sessionManager;

  migrate(state, toVersion = ENV.schemaVersion) {
    let fromVersion = state.meta
      ? state.meta.schemaVersion
      : state.schemaVersion;
    
      console.log('fromVersion', fromVersion, 'toVersion', toVersion)

    // TODO: Throw import error here b/c schemaVersion was not found
    if (!fromVersion) {
      this.notifications.error('That file version is too old', {
        autoClear: true,
      });
      throw 'schemaVersion was not found';
    }

    // TODO: Throw import error here b/c schemaVersion is under minimum schema
    if (fromVersion < this.minVersion) {
      this.notifications.error('That file version is too old', {
        autoClear: true,
      });
      throw `schemaVersion ${fromVersion} is below the mimimum schemaVersion ${this.minVersion}`;
    }

    const direction = this.upOrDown(fromVersion, toVersion);
    if (direction === 'same') {
      return state;
    }
    const currentMigration = migrations.find(
      (migration) =>
        migration[direction === 'up' ? 'from' : 'to'] === fromVersion
    );

    if (!currentMigration) {
      return state;
    }

    const newSchema = currentMigration[direction](state, this.sessionManager.userId);
    return this.migrate(newSchema, toVersion);
  }

  upOrDown(fromVersion, toVersion) {
    if (fromVersion < toVersion) {
      return 'up';
    }
    if (fromVersion > toVersion) {
      return 'down';
    }
    return 'same';
  }
}
