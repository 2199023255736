import Konva from 'konva';

export const getEdgeNode = (edge, options = {}) => {
  const defaultOptions = {
    arrowRadius: 7,
    stepNumberRadius: 20,
    stepNumberFontSize: 20,
    stepNumberStrokeWidth: options.blackAndWhiteMode ? 3 : 2,
    labelRadius: 15,
    labelFontSize: 20,
    strokeWidth: options.blackAndWhiteMode ? 3 : 2,
    fontFamily: 'Inter',
    fontSize: 18,
    showStepNumber: false,
    stepNumberColor: '#000',
    stepNumberFillColor: '#FFF',
    stepNumberStrokeColor: '#000',
    labelFillColor: '#FFF',
    labelStrokeColor: 'transparent',
    strokeColor: '#000000',
    labelColor: '#000000',
    showOrdinal: false,
    ordinalFontSize: 18,
    ordinalColor: '#000000',
    ...options,
  };

  const {
    arrowRadius,
    stepNumberRadius,
    stepNumberFontSize,
    stepNumberStrokeWidth,
    labelRadius,
    labelFontSize,
    showOrdinal,
    ordinalColor,
    ordinalFontSize,
    strokeWidth,
    fontFamily,
    showStepNumber,
    stepNumberColor,
    stepNumberFillColor,
    stepNumberStrokeColor,
    labelFillColor,
    labelStrokeColor,
    strokeColor,
    labelColor,
  } = defaultOptions;

  // + ordinal
  const {
    id,
    type,
    path,
    centerPoint,
    offCenterPoint,
    arrowX,
    arrowY,
    arrowRotation,
    stepNumber,
    ordinal,
  } = edge;
  
  const showLabelNode = type === 'conditional' || type === 'alternative' ? true : false;
  let labelText = '';
  if (type === 'conditional') labelText = 'if';
  if (type === 'alternative') labelText = 'alt';

  const containerNode = new Konva.Group({
    nodeType: 'methodEdge',
  });

  const lineNode = new Konva.Path({
    id: `${id}-line`,
    nodeType: 'methodEdge',
    stroke: 'transparent',
    data: path,
    strokeWidth: 20,
    listening: true,
  });

  const halfLineNode = new Konva.Path({
    id: `${id}-visible-line`,
    nodeType: 'methodEdge',
    data: path,
    stroke: strokeColor,
    strokeWidth: strokeWidth,
    listening: false,
  });

  const arrowNode = new Konva.RegularPolygon({
    radius: arrowRadius,
    x: arrowX,
    y: arrowY,
    rotation: arrowRotation,
    fill: strokeColor,
    sides: 3,
    listening: false,
  });

  const labelNode = new Konva.Group({
    visible: showLabelNode,
    x: centerPoint.x,
    y: centerPoint.y,
    transformsEnabled: 'position',
    listening: false,
  });

  const labelTextNode = new Konva.Text({
    x: -1 * labelRadius,
    y: -1 * labelRadius + 1,
    width: labelRadius * 2,
    height: labelRadius * 2,
    text: labelText,
    align: 'center',
    verticalAlign: 'middle',
    fontSize: labelFontSize,
    lineHeight: 1.235,
    fontFamily: fontFamily,
    fontWeight: '500',
    fill: labelColor,
    transformsEnabled: 'position',
    listening: false,
  });

  const labelBackgroundNode = new Konva.Circle({
    radius: labelRadius,
    fill: labelFillColor,
    stroke: labelStrokeColor,
    transformsEnabled: 'position',
  });

  labelNode.add(labelBackgroundNode);
  labelNode.add(labelTextNode);

  const stepNumberNode = new Konva.Group({
    visible: showStepNumber,
    x: showLabelNode ? offCenterPoint.x : centerPoint.x,
    y: showLabelNode ? offCenterPoint.y : centerPoint.y,
    transformsEnabled: 'position',
  });

  const stepNumberTextNode = new Konva.Text({
    x: -1 * stepNumberRadius,
    y: -1 * stepNumberRadius + 1,
    width: stepNumberRadius * 2,
    height: stepNumberRadius * 2,
    text: stepNumber,
    align: 'center',
    verticalAlign: 'middle',
    fontSize: stepNumberFontSize,
    lineHeight: 1.235,
    fontFamily: fontFamily,
    fontWeight: '500',
    fill: stepNumberColor,
    transformsEnabled: 'position',
    listening: false,
  });

  const stepNumberBackgroundNode = new Konva.Circle({
    radius: stepNumberRadius,
    fill: stepNumberFillColor,
    stroke: stepNumberStrokeColor,
    strokeWidth: stepNumberStrokeWidth,
    transformsEnabled: 'position',
  });

  stepNumberNode.add(stepNumberBackgroundNode);
  stepNumberNode.add(stepNumberTextNode);

  const ordinalNode = new Konva.Group({
    visible: showOrdinal,
    x: showLabelNode ? offCenterPoint.x : centerPoint.x,
    y: showLabelNode ? offCenterPoint.y : centerPoint.y,
    transformsEnabled: 'position',
  });

  const ordinalTextNode = new Konva.Text({
    width: 100,
    height: ordinalFontSize,
    text: ordinal,
    fontSize: ordinalFontSize,
    lineHeight: 1.235,
    fontFamily: fontFamily,
    fontStyle: '500',
    fill: ordinalColor,
    transformsEnabled: 'position',
    listening: false,
  });

  ordinalNode.add(ordinalTextNode);

  containerNode.add(lineNode);
  containerNode.add(halfLineNode);
  containerNode.add(arrowNode);
  containerNode.add(labelNode);
  containerNode.add(stepNumberNode);
  containerNode.add(ordinalNode);

  return {
    containerNode,
    lineNode,
    halfLineNode,
    arrowNode,
    labelNode,
    labelBackgroundNode,
    labelTextNode,
    stepNumberNode,
    stepNumberBackgroundNode,
    stepNumberTextNode,
    ordinalNode,
    ordinalTextNode,
  };
};

export const getStepNode = (step, options = {}) => {
  const defaultOptions = {
    isDraggable: false,
    showSubmethod: true,
    showOrdinal: true,
    showStepNumber: false,
    showElementNode: false,
    disconnectedDash: [15, 10],
    strokeWidth: options.blackAndWhiteMode ? 3 : 2,
    backgroundStrokeColor: '#000000',
    backgroundFillColor: '#FFFFFF',
    ordinalHeight: 19,
    ordinalY: -24, // -1 * ordinalHeight - strokeWidth - 1
    ordinalFontSize: 20,
    fontFamily: 'Inter',
    ordinalColor: '#000000',
    stepNumberSpacing: 30,
    stepNumberColor: '#0000000',
    stepNumberRadius: 20,
    stepNumberFillColor: '#FFFFFF',
    stepNumberStrokeColor: '#0000000',
    stepNumberFontSize: 20,
    stepNumberStrokeWidth: options.blackAndWhiteMode ? 3 : 2,
    elementLabelColor: '#0000000',
    elementLabelFillColor: 'transparent',
    elementLabelHeight: 19,
    elementFontSize: 15,
    elementLabelY: -22, // -1 * elementLabelHeight - strokeWidth - 1
    labelColor: '#0000000',
    fontSize: 20,
    ...options,
  };

  const {
    isDraggable,
    showSubmethod,
    disconnectedDash,
    strokeWidth,
    backgroundStrokeColor,
    backgroundFillColor,
    showOrdinal,
    ordinalY,
    ordinalFontSize,
    fontFamily,
    ordinalColor,
    stepNumberSpacing,
    stepNumberColor,
    stepNumberRadius,
    stepNumberFillColor,
    stepNumberStrokeColor,
    stepNumberFontSize,
    stepNumberStrokeWidth,
    showStepNumber,
    showElementNode,
    elementLabelColor,
    elementLabelFillColor,
    elementLabelHeight,
    elementFontSize,
    elementLabelY,
    labelColor,
    fontSize,
  } = defaultOptions;

  const {
    id,
    index,
    isStartNode,
    isDisconnected,
    submethodHasNodes,
    ordinal,
    label,
    elementName,
    stepNumber,
    x,
    y,
    width,
    height,
  } = step;

  const dashEnabled = isDisconnected ? true : false;

  const containerNode = new Konva.Group({
    id: id,
    nodeType: 'methodNode',
    x: x,
    y: y,
    draggable: isDraggable,
    zIndex: index + 100,
    // opacity: 0.5
  });

  const submethodNode = new Konva.Rect({
    id: `${id}-submethod`,
    nodeType: 'methodNode',
    cornerRadius: isStartNode ? 100 : 0,
    width: width,
    height: height,
    fill: backgroundFillColor,
    stroke: backgroundStrokeColor,
    strokeWidth: strokeWidth,
    dash: disconnectedDash,
    dashEnabled: dashEnabled,
    listening: true,
    visible: submethodHasNodes && showSubmethod ? true : false,
    offset: {
      x: -5,
      y: -5,
    },
  });

  const backgroundNode = new Konva.Rect({
    id: `${id}-background`,
    nodeType: 'methodNode',
    cornerRadius: isStartNode ? 100 : 0,
    width: width,
    height: height,
    fill: backgroundFillColor,
    stroke: backgroundStrokeColor,
    strokeWidth: strokeWidth,
    dash: disconnectedDash,
    dashEnabled: dashEnabled,
    listening: true,
  });

  const labelNode = new Konva.Text({
    id: `${id}-label`,
    width: width,
    padding: 14,
    text: label,
    align: 'center',
    fontSize: fontSize,
    lineHeight: 1.4,
    fontFamily: fontFamily,
    fill: labelColor,
    transformsEnabled: 'position',
    listening: false,
  });

  const elementLabelNode = new Konva.Group({
    visible: showElementNode,
    x: 0,
    y: elementLabelY,
    transformsEnabled: 'position',
  });

  const elementLabelTextNode = new Konva.Text({
    x: stepNumberSpacing,
    width: width - stepNumberSpacing,
    height: elementFontSize,
    ellipsis: true,
    text: elementName,
    align: 'right',
    fontSize: elementFontSize,
    lineHeight: 1.235,
    fontFamily: fontFamily,
    fill: elementLabelColor,
    transformsEnabled: 'position',
    listening: false,
  });

  const elementLabelBackgroundNode = new Konva.Rect({
    width: width,
    height: elementLabelHeight,
    fill: elementLabelFillColor,
    transformsEnabled: 'position',
  });

  elementLabelNode.add(elementLabelBackgroundNode);
  elementLabelNode.add(elementLabelTextNode);

  const stepNumberNode = new Konva.Group({
    visible: showStepNumber,
    x: 0,
    y: 0,
    transformsEnabled: 'position',
  });

  const stepNumberTextNode = new Konva.Text({
    x: -1 * stepNumberRadius,
    y: -1 * stepNumberRadius + 1,
    width: stepNumberRadius * 2,
    height: stepNumberRadius * 2,
    text: stepNumber,
    align: 'center',
    verticalAlign: 'middle',
    fontSize: stepNumberFontSize,
    lineHeight: 1.235,
    fontFamily: fontFamily,
    fontWeight: '500',
    fill: stepNumberColor,
    transformsEnabled: 'position',
    listening: false,
  });

  const stepNumberBackgroundNode = new Konva.Circle({
    radius: stepNumberRadius,
    fill: stepNumberFillColor,
    stroke: stepNumberStrokeColor,
    strokeWidth: stepNumberStrokeWidth,
    transformsEnabled: 'position',
  });

  stepNumberNode.add(stepNumberBackgroundNode);
  stepNumberNode.add(stepNumberTextNode);

  const ordinalNode = new Konva.Group({
    visible: showOrdinal,
    x: 0,
    y: ordinalY,
    transformsEnabled: 'position',
  });

  const ordinalTextNode = new Konva.Text({
    width: width,
    height: ordinalFontSize,
    text: ordinal,
    fontSize: ordinalFontSize,
    lineHeight: 1.235,
    fontFamily: fontFamily,
    fontStyle: '500',
    align: 'right',
    fill: ordinalColor,
    transformsEnabled: 'position',
    listening: false,
  });

  ordinalNode.add(ordinalTextNode);

  const transformNode = new Konva.Rect({
    width: width,
    fill: 'transparent',
    listening: true,
  });

  containerNode.add(transformNode);
  containerNode.add(submethodNode);
  containerNode.add(backgroundNode);
  containerNode.add(labelNode);
  containerNode.add(elementLabelNode);
  containerNode.add(stepNumberNode);
  containerNode.add(ordinalNode);

  return {
    containerNode,
    backgroundNode,
    submethodNode,
    labelNode,
    elementLabelNode,
    stepNumberNode,
    ordinalNode,
    ordinalTextNode,
    stepNumberBackgroundNode,
    stepNumberTextNode,
    elementLabelBackgroundNode,
    elementLabelTextNode,
    transformNode,
  };
};
