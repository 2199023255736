import Model, { attr } from '@ember-data/model';

const getInitials = function(fullName) {
  const charArray = fullName.replace(/[^a-zA-Z- ]/g, "").match(/\b\w/g);
  return charArray.join('');
};

export default class UserModel extends Model {
  @attr('string') email;
  @attr('string') firstName;
  @attr('string') lastName;
  @attr('boolean') experimental;

  get fullName() {
    return `${this.firstName} ${this.lastName}`
  }

  get initials() {
    return getInitials(this.fullName);
  }

}


// import { attr } from '@ember-data/model';
// import Model from '@ember-data/model';
// import { computed } from "@ember/object";
// import { and, equal, alias } from "@ember/object/computed";
//
// const getInitials = function(fullName) {
//   const charArray = fullName.replace(/[^a-zA-Z- ]/g, "").match(/\b\w/g);
//   return charArray.join('');
// };
//
// export default Model.extend({
//   firstName: attr('string'),
//   lastName: attr('string'),
//   email: attr('string'),
//   phone: attr('string'),
//   agreed: attr('boolean'),
//   agreedAt: attr('date'),
//   betaAgreed: attr('boolean'),
//   betaAgreedAt: attr('date'),
//   betaStatus: attr('string'),
//   isVerified: attr('boolean'),
//   status: attr('string'),
//   password: attr('string'),
//   accountId: attr('string'),
//
//   isBetaApproved: equal('betaStatus', 'approved'),
//   isBetaPending: equal('betaStatus', 'pending'),
//   isBetaAgreed: alias('betaAgreed'),
//   isBeta: and('isBetaApproved', 'isBetaAgreed'),
//
//   isLicensed: computed('account.status', function() {
//     const status = this.account && this.account.status;
//     return status === 'subscribed';
//   }),
//
//   displayName: computed('firstName', 'lastName', 'email', function() {
//     return this.firstName && this.lastName ? `${this.firstName} ${this.lastName}` : this.email;
//   }),
//
//   fullName: computed('firstName', 'lastName', function() {
//     return `${this.firstName} ${this.lastName}`;
//   }),
//
//   initials: computed('fullName', function() {
//     return getInitials(this.fullName);
//   }),
//
// });
