export default {
  attributes: {
    product: {
      type: 'uuid',
      defaultValue: null
    },
    priorArt: {
      type: 'uuid',
      defaultValue: null
    },
    featuresList: {
      type: 'array',
      defaultValue: []
    },
    activeNode: {
      type: 'uuid',
      defaultValue: null
    },
    createdAt: {
      type: 'date'
    },
    updatedAt: {
      type: 'date'
    },
  }
};