import Component from '@glimmer/component';
import ENV from '../../../config/environment';
import { action } from '@ember/object';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import uuid from 'uuid/v4';

class DashboardPage extends Component {
  @service usersDb;
  @service session;
  @service sessionManager;
  @service router;
  @service import;
  @service export;
  @service tracking;

  @tracked showingCreateModal = false;
  @tracked exiting = false;

  releaseVersion = ENV.releaseVersion;
  isProduction = ENV.environment === 'production';

  get styleNamespace() {
    return podNames['dashboard-page'];
  }

  get classNames() {
    let classNames = [this.styleNamespace, 'dashboard-page'];
    return classNames.join(' ');
  }

  @action
  didInsert() {
    this.tracking.trackEvent('visited_disclosures');
  }

  get disclosuresList() {
    return this.usersDb.inventionsList;
  }

  get sessionUser() {
    return this.sessionManager.user;
  }

  @action
  onAddDisclosure() {
    const disclosureId = uuid();
    this.tracking.trackEvent('clicked_create_disclosure');
    this.router.transitionTo('invention', disclosureId);
  }

  @action
  onSignOut() {
    this.tracking.trackEvent('logged_out');
    this.tracking.reset();
    this.session.invalidate();
  }

  @action
  onRemoveDisclosure(disclosureId) {
    this.usersDb.removeInvention(disclosureId);
    this.tracking.trackEvent('removed_disclosure', {
      disclosureId,
    });
  }

  @action
  onSelectDisclosure(disclosureId) {
    this.tracking.trackEvent('selected_disclosure');
    this.router.transitionTo('invention', disclosureId);
  }

  @action
  onSaveAs(state) {
    if (state) {
      this.export.exportZip(state);
      this.tracking.trackEvent('disclosure_item_saved_dive');
    }
  }

  @action
  async onImportDisclosure(file) {
    const fileType = await this.import.parseFileType.perform(file.blob);

    let queryParams = {};
    let disclosureId;

    if (!fileType) {
      disclosureId = await this.import.importZip.perform(file.blob);
    }

    if (fileType === 'legacy') {
      disclosureId = await this.import.importZip.perform(file.blob, true);
      this.tracking.trackEvent('imported_legacy');
    }

    if (fileType === 'inventing-game') {
      const data = await this.import.importInventingGame.perform(file.blob);
      const { name, description, inspiration, assets } = data;
      disclosureId = data.inventionId;
      this.tracking.trackEvent('imported_inventing_game');
      queryParams = { name, description, inspiration, assets };
    }

    this.tracking.trackEvent('imported_disclosure');

    this.router.transitionTo('invention', disclosureId, {
      queryParams,
    });

    return fileType;
  }
}

export default DashboardPage;
