import Component from '@glimmer/component';
import { alias } from '@ember/object/computed';
import { connect } from 'ember-redux';
import { getMilestoneTypes } from '../../../selectors/type';
import { getProduct } from '../../../selectors/product';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';

const dispatchToActions = {};

const stateToComputed = (state, attrs) => ({
  product: getProduct(state, attrs.productId),
});

class MilestonesProgress extends Component {
  @service data;
  @service tracking;

  sectionSpacing = 4;
  radius = 30;
  thickness = 8;

  get styleNamespace() {
    return podNames['milestones-progress'];
  }

  get classNames() {
    let classNames = [this.styleNamespace, 'milestones-progress'];
    return classNames.join(' ');
  }

  @alias('product.assertedMilestonesList')
  assertedMilestonesList;

  @alias('product.reviewedMilestonesList')
  reviewedMilestonesList;

  milestonesList = getMilestoneTypes();

  get nextMilestoneId() {
    const assertedMilestonesList = this.assertedMilestonesList.filter(
      (milestoneId) => this.milestonesList.includes(milestoneId)
    );
    return this.milestonesList[assertedMilestonesList.length];
  }
}

export default connect(stateToComputed, dispatchToActions)(MilestonesProgress);
