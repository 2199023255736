import Component from '@glimmer/component';
import { computed, action } from '@ember/object';
import { htmlSafe } from '@ember/string';
import podNames from 'ember-component-css/pod-names';
import { connect } from 'ember-redux';
import {
  selectTerm,
} from '../../../actions/invention-ui';
import { inject as service } from '@ember/service';
import uuid from 'uuid/v4';
import { termMentionInteractiveTemplate } from '../../../utils/mentions';
import { getTerm } from '../../../selectors/term';
const dispatchToActions = {};

const stateToComputed = (state, attrs) => ({
  term: getTerm(state, attrs.termId),
});

class UiTermName extends Component {
  @service models;

  domElementId = `ui-term-name-${uuid()}`;

  get classNames() {
    let classNames = ['ui-term-name', this.styleNamespace];
    return classNames.join(' ');
  }

  get styleNamespace() {
    return podNames['ui-term-name'];
  }

  @computed(
    'termName',
    'args.termId',
    'domElementId'
  )
  get termMention() {
    const name = this.termName;
    return htmlSafe(
      termMentionInteractiveTemplate(
        this.args.termId,
        name,
        this.domElementId
      )
    );
  }

  @computed('term.name')
  get termName() {
    return this.term && this.term.name;
  }

  @action
  selectTerm() {
    this.redux.store.dispatch(selectTerm(this.args.termId));
  }
}

export default connect(stateToComputed, dispatchToActions)(UiTermName);
