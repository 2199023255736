import Component from '@ember/component';
import { connect } from 'ember-redux';

const dispatchToActions = {};

const stateToComputed = null;

const ReferenceAddPopover = Component.extend({
  inventionId: null,

  actions: {
    addDrawing() {
      // this.redux.dispatch(
      //   selectReference(this.inventionId, referenceId, referenceType)
      // );
    },
    addMethod() {
      // this.redux.dispatch(
      //   selectReference(this.inventionId, referenceId, referenceType)
      // );
    },
    selectFigures() {
      // this.redux.dispatch(
      //   selectReference(this.inventionId, referenceId, referenceType)
      // );
    },
  }
});

export default connect(
  stateToComputed,
  dispatchToActions
)(ReferenceAddPopover);
