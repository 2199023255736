import { omit, uniqBy } from 'lodash';

import ENV from '../config/environment';
import { addFromSchema } from '../utils/schema';
import { getRandomMethodNodeCoords } from '../utils/method';
import uuid from 'uuid/v4';

const addFeature = (state, attributes = {}, featureId) => {
  featureId = featureId || uuid();
  state.features.ids = [...state.features.ids, featureId];
  state.features.entities[featureId] = addFromSchema('feature', {
    id: featureId,
    ...attributes,
  });

  return featureId;
};

const addElement = (
  state,
  attributes = {},
  elementVersionAttributes = {},
  elementId,
  elementVersionId
) => {
  elementId = elementId || uuid();
  elementVersionId = elementVersionId || uuid();
  const outcomeId = uuid();
  const requirementId = uuid();

  state.elements.ids = [...state.elements.ids, elementId];
  state.elements.entities[elementId] = addFromSchema('element', {
    id: elementId,
    outcome: outcomeId,
    requirementsList: [requirementId],
    elementVersionsList: [elementVersionId],
    ...attributes,
  });

  elementVersionAttributes = {
    ...elementVersionAttributes,
    element: elementId,
  };

  addElementVersion(state, elementVersionAttributes, elementVersionId);

  // create outcome
  addFeature(
    state,
    {
      type: 'element_step',
      value: '',
      element: elementId,
    },
    outcomeId
  );

  // create  requirement
  addFeature(
    state,
    {
      type: 'functional_requirement',
      value: '',
      element: elementId,
    },
    requirementId
  );

  return elementId;
};

const addElementVersion = (state, attributes = {}, elementVersionId) => {
  elementVersionId = elementVersionId || uuid();
  const methodId = uuid();

  state.elementVersions.ids = [...state.elementVersions.ids, elementVersionId];
  state.elementVersions.entities[elementVersionId] = addFromSchema(
    'element-version',
    {
      id: elementVersionId,
      method: methodId,
      name: 'Solution 1',
      ...attributes,
    }
  );

  addMethod(
    state,
    { element: attributes.element, elementVersion: elementVersionId },
    methodId
  );

  return elementVersionId;
};

const addMethod = (state, attributes = {}, methodId) => {
  methodId = methodId || uuid();
  const methodNodeId = uuid();

  state.methods.ids = [...state.methods.ids, methodId];
  state.methods.entities[methodId] = addFromSchema('method', {
    id: methodId,
    methodNodesList: [methodNodeId],
    ...attributes,
  });

  const methodNodeAttributes = {
    method: methodId,
    type: 'start',
    label: 'START',
    x: -60,
    y: -510,
    width: 100,
    height: 50,
  };

  addMethodNode(state, methodNodeAttributes, methodNodeId);

  return methodId;
};

const addMethodNode = (state, attributes = {}, methodNodeId) => {
  methodNodeId = methodNodeId || uuid();

  state.methodNodes.ids = [...state.methodNodes.ids, methodNodeId];
  state.methodNodes.entities[methodNodeId] = addFromSchema('method-node', {
    id: methodNodeId,
    ...attributes,
  });

  return methodNodeId;
};

const addComprisesRelationship = (
  state,
  sourceElementVersionId,
  targetElementId
) => {
  const comprisesFeatureId = uuid();
  const sourceElementVersion =
    state.elementVersions.entities[sourceElementVersionId];
  const methodId = sourceElementVersion.method;
  const targetElement = state.elements.entities[targetElementId];
  const outcomeFeatureId = targetElement.outcome;
  const sourceElementVersionElementId = sourceElementVersion.element;

  const comprisesFeatureAttributes = {
    type: 'comprises',
    value: targetElementId,
    element: sourceElementVersionElementId,
    elementVersion: sourceElementVersionId,
  };

  addFeature(state, comprisesFeatureAttributes, comprisesFeatureId);

  const methodNodeAttributes = {
    method: methodId,
    type: 'element',
    element: targetElementId,
    feature: outcomeFeatureId,
  };

  addMethodNode(state, methodNodeAttributes);

  let elementsList = [...sourceElementVersion.elementsList, targetElementId];

  state.elementVersions.entities[sourceElementVersionId] = {
    ...sourceElementVersion,
    elementsList,
    comprisesList: [...sourceElementVersion.comprisesList, comprisesFeatureId],
  };

  state.elements.entities[targetElementId] = {
    ...targetElement,
    elementVersion: sourceElementVersionId,
  };
};

export default [
  {
    from: 7,
    to: 8,
    up: (state) => {
      return {
        ...state,
        meta: {
          ...state.meta,
          schemaVersion: 8,
          releaseVersion: ENV.releaseVersion,
        },
      };
    },
    down: (/*state*/) => {},
  },
  {
    from: 8,
    to: 9,
    up: (state) => {
      return {
        ...state,
        graph: {
          ...state.graph,
          collapsedNodesList: [],
        },
        meta: {
          ...state.meta,
          schemaVersion: 9,
          releaseVersion: ENV.releaseVersion,
        },
      };
    },
    down: (/*state*/) => {},
  },
  {
    from: 9,
    to: 10,
    up: (state) => {
      return {
        ...state,
        ui: {
          ...state.ui,
          collapsedNodesList: [],
        },
        meta: {
          ...state.meta,
          schemaVersion: 10,
          releaseVersion: ENV.releaseVersion,
        },
      };
    },
    down: (/*state*/) => {},
  },
  {
    from: 10,
    to: 11,
    up: (state) => {
      return {
        ...state,
        graph: {
          ...state.graph,
          collapsedNodesList: [],
          collapsedDescendantsList: [],
        },
        meta: {
          ...state.meta,
          schemaVersion: 11,
          releaseVersion: ENV.releaseVersion,
        },
      };
    },
    down: (/*state*/) => {},
  },
  {
    from: 11,
    to: 12,
    up: (state) => {
      let updatedState = {
        ...state,
      };

      updatedState.elements.ids.forEach((id) => {
        updatedState.elements.entities[id] = {
          ...updatedState.elements.entities[id],
          category: updatedState.elements.entities[id].type,
        };
      });

      updatedState.elementVersions.ids.forEach((id) => {
        updatedState.elementVersions.entities[id] = {
          ...updatedState.elementVersions.entities[id],
          category: updatedState.elementVersions.entities[id].type,
          definitionsList: [],
          examplesList: [],
        };
      });

      return {
        ...updatedState,
        meta: {
          ...updatedState.meta,
          schemaVersion: 12,
          releaseVersion: ENV.releaseVersion,
        },
      };
    },
    down: (/*state*/) => {},
  },
  {
    from: 12,
    to: 13,
    up: (state) => {
      let updatedState = {
        ...state,
      };

      updatedState.elementVersions.ids.forEach((id) => {
        updatedState.elementVersions.entities[id] = {
          ...updatedState.elementVersions.entities[id],
          stepsList: [],
        };
      });

      return {
        ...updatedState,
        meta: {
          ...updatedState.meta,
          schemaVersion: 13,
          releaseVersion: ENV.releaseVersion,
        },
      };
    },
    down: (/*state*/) => {},
  },
  {
    from: 13,
    to: 14,
    up: (state) => {
      let updatedState = {
        ...state,
      };

      updatedState.drawings.ids.forEach((id) => {
        updatedState.drawings.entities[id] = {
          ...updatedState.drawings.entities[id],
          type: 'drawing',
          element: null,
          elementVersion: null,
        };
      });

      return {
        ...updatedState,
        meta: {
          ...updatedState.meta,
          schemaVersion: 14,
          releaseVersion: ENV.releaseVersion,
        },
      };
    },
    down: (/*state*/) => {},
  },
  {
    from: 14,
    to: 15,
    up: (state) => {
      let updatedState = {
        ...state,
      };

      updatedState.methodNodes.ids.forEach((id) => {
        const stepId = updatedState.methodNodes.entities[id].step;
        const step = updatedState.features.entities[stepId];
        const label = step && step.value ? step.value : '';

        let methodNode = {
          ...updatedState.methodNodes.entities[id],
          label: label,
        };

        methodNode = omit(methodNode, [
          'stepType',
          'element',
          'elementVersion',
          'step',
          'inverseStep',
        ]);

        updatedState.methodNodes.entities[id] = methodNode;
      });

      return {
        ...updatedState,
        meta: {
          ...updatedState.meta,
          schemaVersion: 15,
          releaseVersion: ENV.releaseVersion,
        },
      };
    },
    down: (/*state*/) => {},
  },
  {
    from: 15,
    to: 16,
    up: (state) => {
      let updatedState = {
        ...state,
      };

      updatedState.methods.ids.forEach((id) => {
        const method = {
          ...updatedState.methods.entities[id],
          markersList: [],
          imagesList: [],
        };
        updatedState.methods.entities[id] = method;
      });

      updatedState.drawings.ids.forEach((id) => {
        let drawing = updatedState.drawings.entities[id];
        drawing = omit(drawing, ['type', 'element', 'elementVersion']);
        updatedState.drawings.entities[id] = drawing;
      });

      return {
        ...updatedState,
        meta: {
          ...updatedState.meta,
          schemaVersion: 16,
          releaseVersion: ENV.releaseVersion,
        },
      };
    },
    down: (/*state*/) => {},
  },
  {
    from: 16,
    to: 17,
    up: (state) => {
      let updatedState = {
        ...state,
      };

      updatedState.drawings.ids.forEach((id) => {
        let drawing = updatedState.drawings.entities[id];
        drawing = {
          ...drawing,
          type: 'drawing',
          element: null,
          elementVersion: null,
          methodNodesList: [],
          methodEdgesList: [],
        };
        updatedState.drawings.entities[id] = drawing;
      });

      return {
        ...updatedState,
        meta: {
          ...updatedState.meta,
          schemaVersion: 17,
          releaseVersion: ENV.releaseVersion,
        },
      };
    },
    down: (/*state*/) => {},
  },
  {
    from: 17,
    to: 18,
    up: (state) => {
      let updatedState = {
        ...state,
      };

      updatedState.invention.figuresList =
        updatedState.invention.figuresList.map((figure) => {
          return {
            ...figure,
            type: 'drawing',
          };
        });

      updatedState.methods.ids.forEach((id) => {
        const method = updatedState.methods.entities[id];
        const drawingId = method.id;
        updatedState.drawings.ids = [...updatedState.drawings.ids, drawingId];
        updatedState.drawings.entities[drawingId] = addFromSchema('drawing', {
          id: drawingId,
          type: 'method',
          methodNodesList: method.methodNodesList,
          methodEdgesList: method.methodEdgesList,
          element: method.element,
          elementVersion: method.elementVersion,
          orientation: method.orientation,
        });

        method.methodNodesList.forEach((id) => {
          let methodNode = updatedState.methodNodes.entities[id];
          methodNode = {
            ...methodNode,
            drawing: drawingId,
          };
          methodNode = omit(methodNode, ['method']);
          updatedState.methodNodes.entities[id] = methodNode;
        });

        method.methodEdgesList.forEach((id) => {
          let methodEdge = updatedState.methodEdges.entities[id];
          methodEdge = {
            ...methodEdge,
            drawing: drawingId,
          };
          methodEdge = omit(methodEdge, ['method']);
          updatedState.methodEdges.entities[id] = methodEdge;
        });
      });

      updatedState.elementVersions.ids.forEach((id) => {
        let elementVersion = updatedState.elementVersions.entities[id];
        const referencesList = elementVersion.referencesList
          ? elementVersion.referencesList.filter((ref) => ref.type === 'marker')
          : [];
        elementVersion = {
          ...elementVersion,
          referencesList,
        };
        updatedState.elementVersions.entities[id] = elementVersion;
      });

      updatedState.methods = {
        ids: [],
        entities: {},
      };

      return {
        ...updatedState,
        meta: {
          ...updatedState.meta,
          schemaVersion: 18,
          releaseVersion: ENV.releaseVersion,
        },
      };
    },
    down: (/*state*/) => {},
  },
  {
    from: 18,
    to: 19,
    up: (state) => {
      let updatedState = {
        ...state,
      };

      updatedState.markers.ids.forEach((id) => {
        let marker = updatedState.markers.entities[id];
        marker = {
          ...marker,
          // type: 'drawing',
          // step: null,
        };
        updatedState.markers.entities[id] = marker;
      });

      return {
        ...updatedState,
        meta: {
          ...updatedState.meta,
          schemaVersion: 19,
          releaseVersion: ENV.releaseVersion,
        },
      };
    },
    down: (/*state*/) => {},
  },
  {
    from: 19,
    to: 20,
    up: (state) => {
      let updatedState = {
        ...state,
      };
      updatedState.markers.ids.forEach((id) => {
        let marker = updatedState.markers.entities[id];
        marker = {
          ...marker,
          // process: null,
        };
        updatedState.markers.entities[id] = marker;
      });

      return {
        ...updatedState,
        meta: {
          ...updatedState.meta,
          schemaVersion: 20,
          releaseVersion: ENV.releaseVersion,
        },
      };
    },
    down: (/*state*/) => {},
  },
  {
    from: 20,
    to: 21,
    up: (state) => {
      let updatedState = {
        ...state,
      };
      updatedState.elements.ids.forEach((id) => {
        let element = updatedState.elements.entities[id];
        const featureId = uuid();
        updatedState.features.ids = [...updatedState.features.ids, featureId];
        updatedState.features.entities[featureId] = addFromSchema('feature', {
          id: featureId,
          type: 'element_step',
          value: element.outcome,
          element: element.id,
        });
        element = {
          ...element,
          outcome: featureId,
        };
        updatedState.elements.entities[id] = element;
      });

      return {
        ...updatedState,
        meta: {
          ...updatedState.meta,
          schemaVersion: 21,
          releaseVersion: ENV.releaseVersion,
        },
      };
    },
    down: (/*state*/) => {},
  },
  {
    from: 21,
    to: 22,
    up: (state) => {
      let updatedState = {
        ...state,
      };
      updatedState.elementVersions.ids.forEach((id) => {
        const elementSteps = [];
        const elementVersion = updatedState.elementVersions.entities[id];
        elementVersion.elementsList.forEach((id) => {
          const element = updatedState.elements.entities[id];
          elementSteps.push(element.outcome);
        });
        updatedState.elementVersions.entities[id] = {
          ...updatedState.elementVersions.entities[id],
          stepsList: [
            ...updatedState.elementVersions.entities[id].stepsList,
            ...elementSteps,
          ],
        };
      });

      return {
        ...updatedState,
        meta: {
          ...updatedState.meta,
          schemaVersion: 22,
          releaseVersion: ENV.releaseVersion,
        },
      };
    },
    down: (/*state*/) => {},
  },
  {
    from: 22,
    to: 23,
    up: (state) => {
      let updatedState = {
        ...state,
      };
      updatedState = omit(updatedState, ['methods', 'terms', 'definitions']);

      return {
        ...updatedState,
        meta: {
          ...updatedState.meta,
          schemaVersion: 23,
          releaseVersion: ENV.releaseVersion,
        },
      };
    },
    down: (/*state*/) => {},
  },
  {
    from: 23,
    to: 24,
    up: (state) => {
      let updatedState = {
        ...state,
      };

      const graph = {
        ...updatedState.graph,
        rootNode: {
          id: updatedState.graph.rootNode,
          type: 'element',
        },
      };

      return {
        ...updatedState,
        graph,
        meta: {
          ...updatedState.meta,
          schemaVersion: 24,
          releaseVersion: ENV.releaseVersion,
        },
      };
    },
    down: (/*state*/) => {},
  },
  {
    from: 24,
    to: 25,
    up: (state) => {
      let updatedState = {
        ...state,
      };

      updatedState.elementVersions.ids.forEach((id) => {
        const elementVersion = updatedState.elementVersions.entities[id];
        if (elementVersion.name === 'Version 1') {
          elementVersion.name = 'Solution 1';
        }
        if (elementVersion.name === 'Version 2') {
          elementVersion.name = 'Solution 2';
        }
        if (elementVersion.name === 'Version 3') {
          elementVersion.name = 'Solution 3';
        }
        updatedState.elementVersions.entities[id] = elementVersion;
      });

      return {
        ...updatedState,
        meta: {
          ...updatedState.meta,
          schemaVersion: 25,
          releaseVersion: ENV.releaseVersion,
        },
      };
    },
    down: (/*state*/) => {},
  },
  {
    from: 25,
    to: 26,
    up: (state) => {
      let updatedState = {
        ...state,
      };

      updatedState.methodEdges.ids.forEach((id) => {
        const methodEdge = updatedState.methodEdges.entities[id];
        const sourceNode = updatedState.methodNodes.entities[methodEdge.source];
        methodEdge.label =
          sourceNode.type === 'conditional' ? methodEdge.decision : '';
        updatedState.methodEdges.entities[id] = methodEdge;
      });

      return {
        ...updatedState,
        meta: {
          ...updatedState.meta,
          schemaVersion: 26,
          releaseVersion: ENV.releaseVersion,
        },
      };
    },
    down: (/*state*/) => {},
  },
  {
    from: 26,
    to: 27,
    up: (state) => {
      let updatedState = {
        ...state,
      };

      updatedState.methodEdges.ids.forEach((id) => {
        const methodEdge = updatedState.methodEdges.entities[id];
        const sourceNode = updatedState.methodNodes.entities[methodEdge.source];
        methodEdge.label =
          sourceNode.type === 'conditional' ? methodEdge.decision : '';
        updatedState.methodEdges.entities[id] = methodEdge;
      });

      return {
        ...updatedState,
        meta: {
          ...updatedState.meta,
          schemaVersion: 27,
          releaseVersion: ENV.releaseVersion,
        },
      };
    },
    down: (/*state*/) => {},
  },
  {
    from: 27,
    to: 28,
    up: (state) => {
      let updatedState = {
        ...state,
      };

      updatedState.elementVersions.ids.forEach((id) => {
        const elementVersion = updatedState.elementVersions.entities[id];
        if (elementVersion.name === null) {
          elementVersion.name = 'Solution 1';
        }
      });

      return {
        ...updatedState,
        meta: {
          ...updatedState.meta,
          schemaVersion: 28,
          releaseVersion: ENV.releaseVersion,
        },
      };
    },
    down: (/*state*/) => {},
  },
  {
    from: 28,
    to: 29,
    up: (state) => {
      let updatedState = {
        ...state,
      };

      updatedState.elementVersions.ids.forEach((id) => {
        const elementVersion = updatedState.elementVersions.entities[id];
        const comprisesList = elementVersion.featuresList.filter((id) => {
          const feature = updatedState.features.entities[id];
          return feature.type === 'comprises';
        });
        const featuresList = elementVersion.featuresList.filter((id) => {
          const feature = updatedState.features.entities[id];
          return feature.type !== 'comprises';
        });
        elementVersion.comprisesList = comprisesList;
        elementVersion.featuresList = featuresList;
      });

      return {
        ...updatedState,
        meta: {
          ...updatedState.meta,
          schemaVersion: 29,
          releaseVersion: ENV.releaseVersion,
        },
      };
    },
    down: (/*state*/) => {},
  },
  {
    from: 29,
    to: 30,
    up: (state) => {
      return {
        ...state,
        ui: {
          ...state.ui,
          showingExplorer: false,
        },
        meta: {
          ...state.meta,
          schemaVersion: 30,
          releaseVersion: ENV.releaseVersion,
        },
      };
    },
    down: (/*state*/) => {},
  },
  {
    from: 30,
    to: 31,
    up: (state) => {
      return {
        ...state,
        ui: {
          ...state.ui,
          activeWorkArea:
            state.ui.activeWorkArea === 'invention'
              ? 'elements'
              : state.ui.activeWorkArea,
        },
        meta: {
          ...state.meta,
          schemaVersion: 31,
          releaseVersion: ENV.releaseVersion,
        },
      };
    },
    down: (/*state*/) => {},
  },
  {
    from: 31,
    to: 32,
    up: (state) => {
      let updatedState = {
        ...state,
      };

      updatedState.drawings.ids.forEach((id) => {
        const drawing = updatedState.drawings.entities[id];
        drawing.type = 'drawing';
      });

      return {
        ...updatedState,
        meta: {
          ...state.meta,
          schemaVersion: 32,
          releaseVersion: ENV.releaseVersion,
        },
      };
    },
    down: (/*state*/) => {},
  },
  {
    from: 32,
    to: 33,
    up: (state) => {
      return {
        ...state,
        ui: {
          ...state.ui,
          figureType: 'drawings',
        },
        meta: {
          ...state.meta,
          schemaVersion: 33,
          releaseVersion: ENV.releaseVersion,
        },
      };
    },
    down: (/*state*/) => {},
  },
  {
    from: 33,
    to: 34,
    up: (state) => {
      let updatedState = {
        ...state,
      };

      // // remove all method nodes
      // updatedState.methodNodes = {
      //   ids: [],
      //   entities: {},
      // };

      // // remove all method edges
      // updatedState.methodEdges = {
      //   ids: [],
      //   entities: {},
      // };

      // // remove all method edge points
      // updatedState.methodEdgePoints = {
      //   ids: [],
      //   entities: {},
      // };

      // remove all figures of type method
      const methodDrawings = [];
      updatedState.invention.figuresList.forEach((figure) => {
        const isMethod =
          updatedState.drawings.entities[figure.id] &&
          updatedState.drawings.entities[figure.id].methodNodesList &&
          updatedState.drawings.entities[figure.id].methodNodesList.length;
        if (isMethod) methodDrawings.push(figure.id);
      });
      updatedState.invention.figuresList =
        updatedState.invention.figuresList.filter(
          (figure) => !methodDrawings.includes(figure.id)
        );

      // remove relevant method nodes and edges
      let removeMethodNodesList = [];
      let removeMethodEdgesList = [];
      methodDrawings.forEach((drawingId) => {
        const drawing = updatedState.drawings.entities[drawingId];
        removeMethodNodesList = removeMethodNodesList.concat(
          drawing.methodNodesList
        );
        removeMethodEdgesList = removeMethodEdgesList.concat(
          drawing.methodEdgesList
        );
      });
      updatedState.methodNodes.ids = updatedState.methodNodes.ids.filter(
        (methodNodeId) => !removeMethodNodesList.includes(methodNodeId)
      );
      updatedState.methodNodes.entities = omit(
        updatedState.methodNodes.entities,
        removeMethodNodesList
      );

      updatedState.methodEdges.ids = updatedState.methodEdges.ids.filter(
        (methodEdgeId) => !removeMethodEdgesList.includes(methodEdgeId)
      );
      updatedState.methodEdges.entities = omit(
        updatedState.methodEdges.entities,
        removeMethodEdgesList
      );

      // remove all drawings of type method
      updatedState.drawings.ids = updatedState.drawings.ids.filter(
        (drawingId) => !methodDrawings.includes(drawingId)
      );
      updatedState.drawings.entities = omit(
        updatedState.drawings.entities,
        methodDrawings
      );

      // remove element methodNodesList methodEdgesList attrs from drawings
      updatedState.drawings.ids.forEach((id) => {
        let drawing = updatedState.drawings.entities[id];
        drawing = omit(drawing, [
          'element',
          'methodNodesList',
          'methodEdgesList',
        ]);
        updatedState.drawings.entities[id] = drawing;
      });

      // remove stepsList attrs from elementVersions
      updatedState.elementVersions.ids.forEach((id) => {
        let elementVersion = updatedState.elementVersions.entities[id];
        elementVersion = omit(elementVersion, ['stepsList']);
        updatedState.elementVersions.entities[id] = elementVersion;
      });

      // remove custom step features
      const customStepFeatures = [];
      updatedState.features.ids.forEach((featureId) => {
        let feature = updatedState.features.entities[featureId];
        if (['custom_step', 'inverse_step'].includes(feature.type))
          customStepFeatures.push(featureId);
      });
      updatedState.features.ids = updatedState.features.ids.filter(
        (featureId) => !customStepFeatures.includes(featureId)
      );
      updatedState.features.entities = omit(
        updatedState.features.entities,
        customStepFeatures
      );

      // add methods
      updatedState.methods = {
        ids: [],
        entities: {},
      };
      updatedState.elementVersions.ids.forEach((id) => {
        let elementVersion = updatedState.elementVersions.entities[id];
        const methodId = uuid();
        const methodNodesList = [];
        const disconnectedNodesList = [];

        // add start node
        const startMethodNodeId = uuid();
        const startMethodNode = addFromSchema('method-node', {
          id: startMethodNodeId,
          type: 'start',
          method: methodId,
          label: 'START',
          x: -60,
          y: -510,
          width: 100,
          height: 50,
        });
        methodNodesList.push(startMethodNodeId);
        updatedState.methodNodes.entities[startMethodNodeId] = startMethodNode;
        updatedState.methodNodes.ids = [
          ...updatedState.methodNodes.ids,
          startMethodNodeId,
        ];

        // add element nodes
        elementVersion.elementsList.forEach((elementId) => {
          const methodNodeId = uuid();
          const element = updatedState.elements.entities[elementId];
          const coords = getRandomMethodNodeCoords('portrait');
          const methodNode = addFromSchema('method-node', {
            id: methodNodeId,
            type: 'element',
            method: methodId,
            element: elementId,
            feature: element.outcome,
            x: coords.x,
            y: coords.y,
            width: 280,
            height: 70,
          });
          methodNodesList.push(methodNodeId);
          disconnectedNodesList.push(methodNodeId);
          updatedState.methodNodes.entities[methodNodeId] = methodNode;
          updatedState.methodNodes.ids = [
            ...updatedState.methodNodes.ids,
            methodNodeId,
          ];
        });

        const method = addFromSchema('method', {
          id: methodId,
          type: 'method',
          methodNodesList,
          element: elementVersion.element,
          elementVersion: elementVersion.id,
          disconnectedNodesList,
        });
        updatedState.methods.entities[methodId] = method;
        updatedState.methods.ids = [...updatedState.methods.ids, methodId];

        elementVersion = {
          ...elementVersion,
          method: methodId,
        };

        updatedState.elementVersions.entities[id] = elementVersion;
      });

      return {
        ...updatedState,
        meta: {
          ...state.meta,
          schemaVersion: 34,
          releaseVersion: ENV.releaseVersion,
        },
      };
    },
    down: (/*state*/) => {},
  },
  {
    from: 34,
    to: 35,
    up: (state) => {
      let updatedState = {
        ...state,
      };

      updatedState.elementVersions.ids.forEach((id) => {
        const elementVersion = updatedState.elementVersions.entities[id];
        elementVersion.contraintsList = [];
      });

      return {
        ...updatedState,
        ui: {
          ...updatedState.ui,
          reviewMode: false,
        },
        meta: {
          ...updatedState.meta,
          schemaVersion: 35,
          releaseVersion: ENV.releaseVersion,
        },
      };
    },
    down: (/*state*/) => {},
  },
  {
    from: 35,
    to: 36,
    up: (state) => {
      let updatedState = {
        ...state,
      };

      updatedState.elementVersions.ids.forEach((id) => {
        let elementVersion = updatedState.elementVersions.entities[id];
        elementVersion.constraintsList = [];
        elementVersion = omit(elementVersion, ['contraintsList']);
        updatedState.elementVersions.entities[id] = elementVersion;
      });

      return {
        ...updatedState,
        meta: {
          ...updatedState.meta,
          schemaVersion: 36,
          releaseVersion: ENV.releaseVersion,
        },
      };
    },
    down: (/*state*/) => {},
  },
  {
    from: 36,
    to: 37,
    up: (state) => {
      let updatedState = {
        ...state,
      };

      updatedState.elements.ids.forEach((id) => {
        let element = updatedState.elements.entities[id];
        element.requirementsList.forEach((requirementId) => {
          const requirement = updatedState.requirements.entities[requirementId];
          updatedState.features.ids = [
            ...updatedState.features.ids,
            requirementId,
          ];
          updatedState.features.entities[requirementId] = addFromSchema(
            'feature',
            {
              id: requirementId,
              type: 'functional_requirement',
              value: requirement.name,
              element: element.id,
            }
          );
        });
        // updatedState.elementVersions.entities[id] = elementVersion;
      });

      updatedState = omit(updatedState, ['requirements']);

      return {
        ...updatedState,
        meta: {
          ...updatedState.meta,
          schemaVersion: 37,
          releaseVersion: ENV.releaseVersion,
        },
      };
    },
    down: (/*state*/) => {},
  },
  {
    from: 37,
    to: 38,
    up: (state) => {
      let updatedState = {
        ...state,
      };

      updatedState.graph = {
        ...updatedState.graph,
        elementsMap: updatedState.graph.treeMap,
        elementVersionsMap: updatedState.graph.relationshipTreeMap,
      };

      updatedState.graph = omit(updatedState.graph, [
        'relationshipTreeMap',
        'treeMap',
      ]);

      return {
        ...updatedState,
        meta: {
          ...state.meta,
          schemaVersion: 38,
          releaseVersion: ENV.releaseVersion,
        },
      };
    },
    down: (/*state*/) => {},
  },
  {
    from: 38,
    to: 39,
    up: (state) => {
      let updatedState = {
        ...state,
      };

      const productId = uuid();
      const product = addFromSchema('product', {
        id: productId,
        name: 'Product 1',
      });

      updatedState.products = {
        ids: [productId],
        entities: {
          [productId]: product,
        },
      };

      updatedState.ui.activeProduct = productId;

      return {
        ...updatedState,
        meta: {
          ...updatedState.meta,
          schemaVersion: 39,
          releaseVersion: ENV.releaseVersion,
        },
      };
    },
    down: (/*state*/) => {},
  },
  {
    from: 39,
    to: 40,
    up: (state) => {
      let updatedState = {
        ...state,
      };

      updatedState.graph = omit(updatedState.graph, [
        'novelElementVersionsList',
        'novelElementVersionsTree',
      ]);

      return {
        ...updatedState,
        meta: {
          ...updatedState.meta,
          schemaVersion: 40,
          releaseVersion: ENV.releaseVersion,
        },
      };
    },
    down: (/*state*/) => {},
  },
  {
    from: 40,
    to: 41,
    up: (state) => {
      let updatedState = {
        ...state,
      };

      updatedState.ui.selectedProducts = [];
      updatedState.ui.patentSpecificationPreview = false;

      return {
        ...updatedState,
        meta: {
          ...updatedState.meta,
          schemaVersion: 41,
          releaseVersion: ENV.releaseVersion,
        },
      };
    },
    down: (/*state*/) => {},
  },
  {
    from: 41,
    to: 42,
    up: (state) => {
      let updatedState = {
        ...state,
      };

      // set collapsed view to true by default
      updatedState.ui.collapsedElementVersionsMode = true;

      // add first element version as default preferred to all products
      const elementsList = updatedState.elements.ids;
      const loneElementVersionsList = [];

      elementsList.forEach((elementId) => {
        const element = updatedState.elements.entities[elementId];
        if (element && element.elementVersionsList.length === 1) {
          loneElementVersionsList.push(element.elementVersionsList[0]);
        }
      });

      updatedState.products.ids.forEach((productId) => {
        const product = updatedState.products.entities[productId];
        product.preferredElementVersionsList = [
          ...product.preferredElementVersionsList,
          ...loneElementVersionsList,
        ].uniq();

        updatedState.products.entities[productId] = product;
      });

      return {
        ...updatedState,
        meta: {
          ...updatedState.meta,
          schemaVersion: 42,
          releaseVersion: ENV.releaseVersion,
        },
      };
    },
    down: (/*state*/) => {},
  },
  {
    from: 42,
    to: 43,
    up: (state) => {
      let updatedState = {
        ...state,
      };

      updatedState = omit(updatedState, ['claims']);

      return {
        ...updatedState,
        meta: {
          ...updatedState.meta,
          schemaVersion: 43,
          releaseVersion: ENV.releaseVersion,
        },
      };
    },
    down: (/*state*/) => {},
  },
  {
    from: 43,
    to: 44,
    up: (state) => {
      let updatedState = {
        ...state,
      };

      updatedState.products.ids.forEach((productId) => {
        const product = updatedState.products.entities[productId];
        product.description = '';
        updatedState.products.entities[productId] = product;
      });

      return {
        ...updatedState,
        meta: {
          ...updatedState.meta,
          schemaVersion: 44,
          releaseVersion: ENV.releaseVersion,
        },
      };
    },
    down: (/*state*/) => {},
  },
  {
    from: 44,
    to: 45,
    up: (state) => {
      let updatedState = {
        ...state,
      };

      updatedState.products.ids.forEach((productId) => {
        let product = updatedState.products.entities[productId];
        product.summary = '';
        product = omit(product, ['description']);
        updatedState.products.entities[productId] = product;
      });

      return {
        ...updatedState,
        meta: {
          ...updatedState.meta,
          schemaVersion: 45,
          releaseVersion: ENV.releaseVersion,
        },
      };
    },
    down: (/*state*/) => {},
  },
  {
    from: 45,
    to: 46,
    up: (state) => {
      let updatedState = {
        ...state,
      };

      updatedState.elements.ids.forEach((elementId) => {
        let element = updatedState.elements.entities[elementId];
        element.elementVersionsList = element.elementVersionsList.uniq();
        updatedState.elements.entities[elementId] = element;
      });

      return {
        ...updatedState,
        meta: {
          ...updatedState.meta,
          schemaVersion: 46,
          releaseVersion: ENV.releaseVersion,
        },
      };
    },
    down: (/*state*/) => {},
  },
  {
    from: 46,
    to: 47,
    up: (state) => {
      let updatedState = {
        ...state,
      };

      updatedState.features.ids.forEach((featureId) => {
        let feature = updatedState.features.entities[featureId];
        feature.term = null;
        updatedState.features.entities[featureId] = feature;
      });

      return {
        ...updatedState,
        meta: {
          ...updatedState.meta,
          schemaVersion: 47,
          releaseVersion: ENV.releaseVersion,
        },
      };
    },
    down: (/*state*/) => {},
  },
  {
    from: 47,
    to: 48,
    up: (state) => {
      let updatedState = {
        ...state,
      };

      updatedState.markers.ids.forEach((markerId) => {
        let marker = updatedState.markers.entities[markerId];
        marker.type = 'element';
        marker.term = null;
        updatedState.markers.entities[markerId] = marker;
      });

      return {
        ...updatedState,
        meta: {
          ...updatedState.meta,
          schemaVersion: 48,
          releaseVersion: ENV.releaseVersion,
        },
      };
    },
    down: (/*state*/) => {},
  },
  {
    from: 48,
    to: 49,
    up: (state) => {
      let updatedState = {
        ...state,
      };

      if (updatedState.terms) {
        updatedState.terms.ids.forEach((termId) => {
          let term = updatedState.terms.entities[termId];
          term = omit(term, ['termsList']);
          term.referencesList = [];
          updatedState.terms.entities[termId] = term;
        });
      }

      return {
        ...updatedState,
        meta: {
          ...updatedState.meta,
          schemaVersion: 49,
          releaseVersion: ENV.releaseVersion,
        },
      };
    },
    down: (/*state*/) => {},
  },
  {
    from: 49,
    to: 50,
    up: (state) => {
      let updatedState = {
        ...state,
      };

      // fix thing where elements weren't switched on methods
      updatedState.methods.ids.forEach((methodId) => {
        let method = updatedState.methods.entities[methodId];
        if (method.elementVersion && !method.element) {
          method.element =
            updatedState.elementVersions.entities[
              method.elementVersion
            ].element;
        }
        updatedState.methods.entities[methodId] = method;
      });

      return {
        ...updatedState,
        meta: {
          ...updatedState.meta,
          schemaVersion: 50,
          releaseVersion: ENV.releaseVersion,
        },
      };
    },
    down: (/*state*/) => {},
  },
  {
    from: 50,
    to: 51,
    up: (state) => {
      let updatedState = {
        ...state,
      };

      if (!updatedState.terms) {
        updatedState.terms = {
          ids: [],
          entities: {},
        };
      }

      // add detailsList
      updatedState.terms.ids.forEach((termId) => {
        let term = updatedState.terms.entities[termId];
        term.detailsList = [];
        updatedState.terms.entities[termId] = term;
      });

      return {
        ...updatedState,
        meta: {
          ...updatedState.meta,
          schemaVersion: 51,
          releaseVersion: ENV.releaseVersion,
        },
      };
    },
    down: (/*state*/) => {},
  },
  {
    from: 50,
    to: 51,
    up: (state) => {
      let updatedState = {
        ...state,
      };

      if (!updatedState.terms) {
        updatedState.terms = {
          ids: [],
          entities: {},
        };
      }

      // add detailsList
      updatedState.terms.ids.forEach((termId) => {
        let term = updatedState.terms.entities[termId];
        term.detailsList = [];
        updatedState.terms.entities[termId] = term;
      });

      return {
        ...updatedState,
        meta: {
          ...updatedState.meta,
          schemaVersion: 51,
          releaseVersion: ENV.releaseVersion,
        },
      };
    },
    down: (/*state*/) => {},
  },
  {
    from: 51,
    to: 52,
    up: (state) => {
      let updatedState = {
        ...state,
      };

      // add add count to comprises features
      updatedState.features.ids.forEach((featureId) => {
        let feature = updatedState.features.entities[featureId];
        if (feature?.type === 'comprises') {
          feature.count = 1;
          updatedState.features.entities[feature] = feature;
        }
      });

      return {
        ...updatedState,
        meta: {
          ...updatedState.meta,
          schemaVersion: 52,
          releaseVersion: ENV.releaseVersion,
        },
      };
    },
    down: (/*state*/) => {},
  },
  {
    from: 52,
    to: 53,
    up: (state) => {
      let updatedState = {
        ...state,
      };

      // move details and examples to definitionsList
      updatedState.terms.ids.forEach((termId) => {
        let term = updatedState.terms.entities[termId];
        term.definitionsList = [
          ...term.definitionsList,
          ...term.examplesList,
          ...term.detailsList,
        ];
        updatedState.terms.entities[termId] = term;
      });

      return {
        ...updatedState,
        meta: {
          ...updatedState.meta,
          schemaVersion: 53,
          releaseVersion: ENV.releaseVersion,
        },
      };
    },
    down: (/*state*/) => {},
  },
  {
    from: 53,
    to: 54,
    up: (state) => {
      let updatedState = {
        ...state,
      };

      // move definitins,details and examples to definitionsList
      updatedState.elementVersions.ids.forEach((elementVersionId) => {
        let elementVersion =
          updatedState.elementVersions.entities[elementVersionId];

        const definitionsList = elementVersion.featuresList.filter(
          (featureId) => {
            const feature = updatedState.features.entities[featureId];
            return feature && feature.type === 'definition';
          }
        );
        const examplesList = elementVersion.featuresList.filter((featureId) => {
          const feature = updatedState.features.entities[featureId];
          return feature && feature.type === 'analogs';
        });
        const detailsList = elementVersion.featuresList.filter((featureId) => {
          const feature = updatedState.features.entities[featureId];
          return feature && feature.type === 'detail';
        });

        elementVersion.definitionsList = [
          ...definitionsList,
          ...examplesList,
          ...detailsList,
        ];
        const deletingList = [
          ...definitionsList,
          ...examplesList,
          ...detailsList,
        ];

        elementVersion.featuresList = elementVersion.featuresList.filter(
          (featureId) => {
            return !deletingList.includes(featureId);
          }
        );

        updatedState.elementVersions.entities[elementVersionId] =
          elementVersion;
      });

      return {
        ...updatedState,
        meta: {
          ...updatedState.meta,
          schemaVersion: 54,
          releaseVersion: ENV.releaseVersion,
        },
      };
    },
    down: (/*state*/) => {},
  },
  {
    from: 54,
    to: 55,
    up: (state) => {
      let updatedState = {
        ...state,
      };

      // remove duplicate references
      updatedState.elementVersions.ids.forEach((elementVersionId) => {
        let elementVersion =
          updatedState.elementVersions.entities[elementVersionId];
        const referencesList = uniqBy(elementVersion.referencesList, 'id');
        elementVersion.referencesList = referencesList;
        updatedState.elementVersions.entities[elementVersionId] =
          elementVersion;
      });

      return {
        ...updatedState,
        meta: {
          ...updatedState.meta,
          schemaVersion: 55,
          releaseVersion: ENV.releaseVersion,
        },
      };
    },
    down: (/*state*/) => {},
  },
  {
    from: 55,
    to: 56,
    up: (state) => {
      let updatedState = {
        ...state,
      };

      if (!updatedState.presentation) {
        updatedState.presentation = addFromSchema('presentation');
      }

      return {
        ...updatedState,
        meta: {
          ...updatedState.meta,
          schemaVersion: 56,
          releaseVersion: ENV.releaseVersion,
        },
      };
    },
    down: (/*state*/) => {},
  },
  {
    from: 56,
    to: 57,
    up: (state) => {
      let updatedState = {
        ...state,
      };

      updatedState.presentation.showingExplorer = false;

      return {
        ...updatedState,
        meta: {
          ...updatedState.meta,
          schemaVersion: 57,
          releaseVersion: ENV.releaseVersion,
        },
      };
    },
    down: (/*state*/) => {},
  },
  {
    from: 57,
    to: 58,
    up: (state) => {
      let updatedState = {
        ...state,
      };

      updatedState.presentation.activeContextTab = 'elements';

      return {
        ...updatedState,
        meta: {
          ...updatedState.meta,
          schemaVersion: 58,
          releaseVersion: ENV.releaseVersion,
        },
      };
    },
    down: (/*state*/) => {},
  },
  {
    from: 58,
    to: 59,
    up: (state) => {
      let updatedState = {
        ...state,
      };

      updatedState.reviews = {
        ids: [],
        entities: {},
      };

      return {
        ...updatedState,
        meta: {
          ...updatedState.meta,
          schemaVersion: 59,
          releaseVersion: ENV.releaseVersion,
        },
      };
    },
    down: (/*state*/) => {},
  },
  {
    from: 59,
    to: 60,
    up: (state) => {
      let updatedState = {
        ...state,
      };

      updatedState.reviews.ids.forEach((reviewId) => {
        let review = updatedState.reviews.entities[reviewId];
        review.possessionIssuesList = [];
        review.enablementIssuesList = [];
        updatedState.reviews.entities[reviewId] = review;
      });

      return {
        ...updatedState,
        meta: {
          ...updatedState.meta,
          schemaVersion: 60,
          releaseVersion: ENV.releaseVersion,
        },
      };
    },
    down: (/*state*/) => {},
  },
  {
    from: 60,
    to: 61,
    up: (state) => {
      let updatedState = {
        ...state,
      };

      updatedState.notes.ids.forEach((noteId) => {
        let note = updatedState.notes.entities[noteId];
        note.resolved = false;
        updatedState.notes.entities[noteId] = note;
      });

      return {
        ...updatedState,
        meta: {
          ...updatedState.meta,
          schemaVersion: 61,
          releaseVersion: ENV.releaseVersion,
        },
      };
    },
    down: (/*state*/) => {},
  },
  {
    from: 61,
    to: 62,
    up: (state) => {
      let updatedState = {
        ...state,
      };

      updatedState.ui.creatingNote = false;
      updatedState.ui.editingNote = null;

      return {
        ...updatedState,
        meta: {
          ...updatedState.meta,
          schemaVersion: 62,
          releaseVersion: ENV.releaseVersion,
        },
      };
    },
    down: (/*state*/) => {},
  },
  {
    from: 62,
    to: 63,
    up: (state, userId) => {
      let updatedState = {
        ...state,
      };

      updatedState.notes.ids.forEach((noteId) => {
        let note = updatedState.notes.entities[noteId];
        if (!note.user) {
          note.user = userId;
        }
        updatedState.notes.entities[noteId] = note;
      });

      return {
        ...updatedState,
        meta: {
          ...updatedState.meta,
          schemaVersion: 63,
          releaseVersion: ENV.releaseVersion,
        },
      };
    },
    down: (/*state*/) => {},
  },
  {
    from: 63,
    to: 64,
    up: (state) => {
      let updatedState = {
        ...state,
      };

      updatedState.ui.activeComparison = null;

      updatedState.priorArts = {
        ids: [],
        entities: {},
      };

      updatedState.comparisons = {
        ids: [],
        entities: {},
      };

      return {
        ...updatedState,
        meta: {
          ...updatedState.meta,
          schemaVersion: 64,
          releaseVersion: ENV.releaseVersion,
        },
      };
    },
    down: (/*state*/) => {},
  },
  {
    from: 64,
    to: 65,
    up: (state) => {
      let updatedState = {
        ...state,
      };

      updatedState.products.ids.forEach((productId) => {
        let product = updatedState.products.entities[productId];
        product.comparisonsList = [];
        updatedState.products.entities[productId] = product;
      });

      return {
        ...updatedState,
        meta: {
          ...updatedState.meta,
          schemaVersion: 65,
          releaseVersion: ENV.releaseVersion,
        },
      };
    },
    down: (/*state*/) => {},
  },
  {
    from: 65,
    to: 66,
    up: (state) => {
      let updatedState = {
        ...state,
      };

      updatedState.ui.creatingComparison = false;

      return {
        ...updatedState,
        meta: {
          ...updatedState.meta,
          schemaVersion: 66,
          releaseVersion: ENV.releaseVersion,
        },
      };
    },
    down: (/*state*/) => {},
  },
  {
    from: 66,
    to: 67,
    up: (state) => {
      let updatedState = {
        ...state,
      };

      updatedState.ui.showingPriorArts = false;

      return {
        ...updatedState,
        meta: {
          ...updatedState.meta,
          schemaVersion: 67,
          releaseVersion: ENV.releaseVersion,
        },
      };
    },
    down: (/*state*/) => {},
  },
  {
    from: 67,
    to: 68,
    up: (state) => {
      let updatedState = {
        ...state,
      };

      updatedState.ui.activePriorArt = null;

      return {
        ...updatedState,
        meta: {
          ...updatedState.meta,
          schemaVersion: 68,
          releaseVersion: ENV.releaseVersion,
        },
      };
    },
    down: (/*state*/) => {},
  },
  {
    from: 68,
    to: 69,
    up: (state) => {
      let updatedState = {
        ...state,
      };

      updatedState.ui.creatingReview = false;
      updatedState.ui.editingReview = null;
      updatedState.ui.editingComparison = null;

      return {
        ...updatedState,
        meta: {
          ...updatedState.meta,
          schemaVersion: 69,
          releaseVersion: ENV.releaseVersion,
        },
      };
    },
    down: (/*state*/) => {},
  },
  {
    from: 69,
    to: 70,
    up: (state) => {
      let updatedState = {
        ...state,
      };

      updatedState.ui.selectedComparisons = [];
      updatedState.ui.selectedReviews = [];
      updatedState.ui.selectedPriorArts = [];
      updatedState.ui.activeReview = null;
      updatedState.ui.creatingPriorArt = false;
      updatedState.ui.editingPriorArt = null;

      return {
        ...updatedState,
        meta: {
          ...updatedState.meta,
          schemaVersion: 70,
          releaseVersion: ENV.releaseVersion,
        },
      };
    },
    down: (/*state*/) => {},
  },
  {
    from: 70,
    to: 71,
    up: (state) => {
      let updatedState = {
        ...state,
      };

      updatedState.ui.showingReviews = false;

      return {
        ...updatedState,
        meta: {
          ...updatedState.meta,
          schemaVersion: 71,
          releaseVersion: ENV.releaseVersion,
        },
      };
    },
    down: (/*state*/) => {},
  },
  {
    from: 71,
    to: 72,
    up: (state) => {
      let updatedState = {
        ...state,
      };

      updatedState.reviews.ids.forEach((reviewId) => {
        let review = updatedState.reviews.entities[reviewId];
        review = omit(review, ['notes']);
        review.notesList = [];
        review.activeNode = null;
        updatedState.reviews.entities[reviewId] = review;
      });

      updatedState.ui.navigatorShowingExplorer = false;
      updatedState.ui.navigatorReverse = false;
      updatedState.ui.navigatorWalk = 'breadth';
      updatedState.ui.navigatorActiveContextTab = 'elements';

      return {
        ...updatedState,
        meta: {
          ...updatedState.meta,
          schemaVersion: 72,
          releaseVersion: ENV.releaseVersion,
        },
      };
    },
    down: (/*state*/) => {},
  },
  {
    from: 72,
    to: 73,
    up: (state) => {
      let updatedState = {
        ...state,
      };

      updatedState.reviews.ids.forEach((reviewId) => {
        let review = updatedState.reviews.entities[reviewId];
        review = omit(review, ['notesList']);
        updatedState.reviews.entities[reviewId] = review;
      });

      updatedState.notes.ids.forEach((noteId) => {
        let note = updatedState.notes.entities[noteId];
        note.review = null;
        updatedState.notes.entities[noteId] = note;
      });

      return {
        ...updatedState,
        meta: {
          ...updatedState.meta,
          schemaVersion: 73,
          releaseVersion: ENV.releaseVersion,
        },
      };
    },
    down: (/*state*/) => {},
  },
  {
    from: 73,
    to: 74,
    up: (state) => {
      let updatedState = {
        ...state,
      };

      updatedState.ui.showingProducts = false;
      updatedState.ui.creatingProduct = false;
      updatedState.ui.editingProduct = null;

      return {
        ...updatedState,
        meta: {
          ...updatedState.meta,
          schemaVersion: 74,
          releaseVersion: ENV.releaseVersion,
        },
      };
    },
    down: (/*state*/) => {},
  },
  {
    from: 74,
    to: 75,
    up: (state, userId) => {
      let updatedState = {
        ...state,
      };

      updatedState.ui.showingInventionEditor = false;
      if (!updatedState.invention.user) {
        updatedState.invention.user = userId;
      }

      return {
        ...updatedState,
        meta: {
          ...updatedState.meta,
          schemaVersion: 75,
          releaseVersion: ENV.releaseVersion,
        },
      };
    },
    down: (/*state*/) => {},
  },
  {
    from: 75,
    to: 76,
    up: (state) => {
      let updatedState = {
        ...state,
      };

      updatedState.ui.showingAssets = false;
      updatedState.ui.creatingAsset = false;

      return {
        ...updatedState,
        meta: {
          ...updatedState.meta,
          schemaVersion: 76,
          releaseVersion: ENV.releaseVersion,
        },
      };
    },
    down: (/*state*/) => {},
  },
  {
    from: 76,
    to: 77,
    up: (state) => {
      let updatedState = {
        ...state,
      };

      if (!updatedState.assets) {
        updatedState.assets = {
          ids: [],
          entities: {},
        };
      }
      if (!updatedState.previewAssets) {
        updatedState.previewAssets = {
          ids: [],
          entities: {},
        };
      }

      updatedState.images.ids.forEach((imageId) => {
        let image = updatedState.images.entities[imageId];

        const {
          fileName,
          fileType,
          fileWidth,
          fileHeight,
          externalUrl,
          createdAt,
          updatedAt,
          asset,
          previewAsset,
        } = image;

        updatedState.assets.ids = [...updatedState.assets.ids, asset];
        updatedState.assets.entities[asset] = addFromSchema('asset', {
          id: asset,
          name: fileName,
          fileType,
          width: fileWidth,
          height: fileHeight,
          externalUrl,
          preview: previewAsset,
          createdAt,
          updatedAt,
        });

        updatedState.previewAssets.ids = [
          ...updatedState.previewAssets.ids,
          previewAsset,
        ];
        updatedState.previewAssets.entities[asset] = addFromSchema(
          'preview-asset',
          {
            id: previewAsset,
            asset: asset,
            createdAt,
            updatedAt,
          }
        );

        image = omit(image, [
          'externalUrl',
          'fileName',
          'fileType',
          'fileWidth',
          'fileHeight',
        ]);

        updatedState.images.entities[imageId] = image;
      });

      return {
        ...updatedState,
        meta: {
          ...updatedState.meta,
          schemaVersion: 77,
          releaseVersion: ENV.releaseVersion,
        },
      };
    },
    down: (/*state*/) => {},
  },
  {
    from: 77,
    to: 78,
    up: (state) => {
      let updatedState = {
        ...state,
      };

      updatedState.ui.creatingImageAsset = null;
      updatedState.ui.updatingImageAsset = null;
      updatedState.ui.updatingProductAsset = null;
      updatedState.ui.updatingPriorArtAsset = null;

      updatedState.products.ids.forEach((productId) => {
        let product = updatedState.products.entities[productId];
        product.asset = null;
        updatedState.products.entities[productId] = product;
      });

      updatedState.priorArts.ids.forEach((priorArtId) => {
        let priorArt = updatedState.priorArts.entities[priorArtId];
        priorArt.asset = null;
        updatedState.priorArts.entities[priorArtId] = priorArt;
      });

      return {
        ...updatedState,
        meta: {
          ...updatedState.meta,
          schemaVersion: 78,
          releaseVersion: ENV.releaseVersion,
        },
      };
    },
    down: (/*state*/) => {},
  },
  {
    from: 78,
    to: 79,
    up: (state) => {
      let updatedState = {
        ...state,
      };

      updatedState.comparisons.ids.forEach((comparisonId) => {
        let comparison = updatedState.comparisons.entities[comparisonId];
        comparison = omit(comparison, [
          'uniqueList',
          'uniqueRequirementsList',
          'uniqueFeaturesList',
        ]);
        comparison.activeNode = comparison.activeNode || null;
        comparison.featuresList = [];
        updatedState.comparisons.entities[comparisonId] = comparison;
      });

      return {
        ...updatedState,
        meta: {
          ...updatedState.meta,
          schemaVersion: 79,
          releaseVersion: ENV.releaseVersion,
        },
      };
    },
    down: (/*state*/) => {},
  },
  {
    from: 79,
    to: 80,
    up: (state) => {
      let updatedState = {
        ...state,
      };

      updatedState.ui.showingComparisonMatrix = false;
      updatedState.ui.activeComparisonMatrixProduct = null;

      return {
        ...updatedState,
        meta: {
          ...updatedState.meta,
          schemaVersion: 80,
          releaseVersion: ENV.releaseVersion,
        },
      };
    },
    down: (/*state*/) => {},
  },
  {
    from: 80,
    to: 81,
    up: (state) => {
      let updatedState = {
        ...state,
      };

      updatedState.ui.showingGraphNovelty = false;

      return {
        ...updatedState,
        meta: {
          ...updatedState.meta,
          schemaVersion: 81,
          releaseVersion: ENV.releaseVersion,
        },
      };
    },
    down: (/*state*/) => {},
  },
  {
    from: 81,
    to: 82,
    up: (state) => {
      let updatedState = {
        ...state,
      };

      updatedState.products.ids.forEach((productId) => {
        const product = updatedState.products.entities[productId];
        product.activeNode = null;
        updatedState.products.entities[productId] = product;
      });

      return {
        ...updatedState,
        meta: {
          ...updatedState.meta,
          schemaVersion: 82,
          releaseVersion: ENV.releaseVersion,
        },
      };
    },
    down: (/*state*/) => {},
  },
  {
    from: 82,
    to: 83,
    up: (state) => {
      let updatedState = {
        ...state,
      };

      updatedState.ui.updatingProductPriorArt = false;

      updatedState.products.ids.forEach((productId) => {
        const product = updatedState.products.entities[productId];
        product.comparisonsList = [];
        updatedState.products.entities[productId] = product;
      });

      updatedState.comparisons = {
        ids: [],
        entities: {},
      };

      return {
        ...updatedState,
        meta: {
          ...updatedState.meta,
          schemaVersion: 83,
          releaseVersion: ENV.releaseVersion,
        },
      };
    },
    down: (/*state*/) => {},
  },
  {
    from: 83,
    to: 84,
    up: (state) => {
      let updatedState = {
        ...state,
      };

      updatedState.elementVersions.ids.forEach((elementVersionId) => {
        let elementVersion =
          updatedState.elementVersions.entities[elementVersionId];
        elementVersion.known =
          elementVersion.category === 'known' ? true : false;
        elementVersion.category = 'machine';
        updatedState.elementVersions.entities[elementVersionId] =
          elementVersion;
      });

      return {
        ...updatedState,
        meta: {
          ...updatedState.meta,
          schemaVersion: 84,
          releaseVersion: ENV.releaseVersion,
        },
      };
    },
    down: (/*state*/) => {},
  },
  {
    from: 84,
    to: 85,
    up: (state) => {
      let updatedState = {
        ...state,
      };

      updatedState.ui = omit(updatedState.ui, [
        'activeComparision',
        'activeComparisonMatrixProduct',
      ]);

      updatedState.ui.activeComparisonProduct = null;
      updatedState.ui.activeComparisonElement = null;

      return {
        ...updatedState,
        meta: {
          ...updatedState.meta,
          schemaVersion: 85,
          releaseVersion: ENV.releaseVersion,
        },
      };
    },
    down: (/*state*/) => {},
  },
  {
    from: 85,
    to: 86,
    up: (state) => {
      let updatedState = {
        ...state,
      };

      updatedState = omit(updatedState, ['previewAssets']);

      updatedState.assets.ids.forEach((assetId) => {
        let asset = updatedState.assets.entities[assetId];
        asset = omit(asset, 'preview');
        asset.filters = false;
        asset.greyscale = false;
        asset.threshold = false;
        asset.thresholdValue = 100;
        updatedState.assets.entities[assetId] = asset;
      });

      return {
        ...updatedState,
        meta: {
          ...updatedState.meta,
          schemaVersion: 86,
          releaseVersion: ENV.releaseVersion,
        },
      };
    },
    down: (/*state*/) => {},
  },
  {
    from: 86,
    to: 87,
    up: (state) => {
      let updatedState = {
        ...state,
      };

      updatedState.assets.ids.forEach((assetId) => {
        let asset = updatedState.assets.entities[assetId];
        asset = omit(
          asset,
          'filters',
          'greyscale',
          'threshold',
          'thresholdValue'
        );
        updatedState.assets.entities[assetId] = asset;
      });

      updatedState.images.ids.forEach((imageId) => {
        let image = updatedState.images.entities[imageId];
        image.filters = false;
        image.greyscale = false;
        image.threshold = false;
        image.thresholdValue = 100;
        updatedState.images.entities[imageId] = image;
      });

      return {
        ...updatedState,
        meta: {
          ...updatedState.meta,
          schemaVersion: 87,
          releaseVersion: ENV.releaseVersion,
        },
      };
    },
    down: (/*state*/) => {},
  },
  {
    from: 87,
    to: 88,
    up: (state) => {
      let updatedState = {
        ...state,
      };

      updatedState.images.ids.forEach((imageId) => {
        let image = updatedState.images.entities[imageId];

        image = omit(image, 'greyscale');
        image.grayscale = true;
        updatedState.images.entities[imageId] = image;
      });

      return {
        ...updatedState,
        meta: {
          ...updatedState.meta,
          schemaVersion: 88,
          releaseVersion: ENV.releaseVersion,
        },
      };
    },
    down: (/*state*/) => {},
  },
  {
    from: 88,
    to: 89,
    up: (state) => {
      let updatedState = {
        ...state,
      };

      updatedState.images.ids.forEach((imageId) => {
        let image = updatedState.images.entities[imageId];
        image.rotation = 0;
        updatedState.images.entities[imageId] = image;
      });

      if (!updatedState.highlights) {
        updatedState.highlights = {
          ids: [],
          entities: {},
        };
      }

      updatedState.highlights.ids.forEach((highlightId) => {
        let highlight = updatedState.highlights.entities[highlightId];
        highlight.curve = 'linear';
        updatedState.highlights.entities[highlightId] = highlight;
      });

      return {
        ...updatedState,
        meta: {
          ...updatedState.meta,
          schemaVersion: 89,
          releaseVersion: ENV.releaseVersion,
        },
      };
    },
    down: (/*state*/) => {},
  },
  {
    from: 89,
    to: 90,
    up: (state) => {
      let updatedState = {
        ...state,
      };

      updatedState.methods.ids.forEach((methodId) => {
        let method = updatedState.methods.entities[methodId];
        method = omit(method, [
          'traversedNodesList',
          'disconnectedNodesList',
          'groupedStepsList',
        ]);
        updatedState.methods.entities[methodId] = method;
      });

      return {
        ...updatedState,
        meta: {
          ...updatedState.meta,
          schemaVersion: 90,
          releaseVersion: ENV.releaseVersion,
        },
      };
    },
    down: (/*state*/) => {},
  },
  {
    from: 90,
    to: 91,
    up: (state) => {
      let updatedState = {
        ...state,
      };

      updatedState.ui.showingPatentSpecification = false;
      updatedState.ui = omit(updatedState.ui, 'patentSpecificationPreview');

      return {
        ...updatedState,
        meta: {
          ...updatedState.meta,
          schemaVersion: 91,
          releaseVersion: ENV.releaseVersion,
        },
      };
    },
    down: (/*state*/) => {},
  },
  {
    from: 91,
    to: 92,
    up: (state) => {
      let updatedState = {
        ...state,
      };

      // This is a fix for Fred's old and mismatched file
      updatedState.elements.ids.forEach((elementId) => {
        let element = updatedState.elements.entities[elementId];
        if (element) {
          element.elementVersionsList.forEach((elementVersionId) => {
            let elementVersion =
              updatedState.elementVersions.entities[elementVersionId];
            if (elementVersion) {
              if (
                !elementVersion.element ||
                elementVersion.element !== elementId
              ) {
                elementVersion = {
                  ...elementVersion,
                  element: elementId,
                };
                updatedState.elementVersions.entities[elementVersionId] =
                  elementVersion;
              }
            }
          });
        }
      });

      return {
        ...updatedState,
        meta: {
          ...updatedState.meta,
          schemaVersion: 92,
          releaseVersion: ENV.releaseVersion,
        },
      };
    },
    down: (/*state*/) => {},
  },
  {
    from: 92,
    to: 93,
    up: (state) => {
      let updatedState = {
        ...state,
      };

      updatedState.elements.ids.forEach((id) => {
        let element = updatedState.elements.entities[id];
        let requirementsList = element.requirementsList;

        if (!requirementsList.length) {
          const featureId = uuid();
          updatedState.features.ids = [...updatedState.features.ids, featureId];
          updatedState.features.entities[featureId] = addFromSchema('feature', {
            id: featureId,
            type: 'functional_requirement',
            value: '',
            element: element.id,
          });
          requirementsList = [featureId];
        }

        element = {
          ...element,
          name: element.name.replace('Element', 'System'),
          requirementsList,
        };
        updatedState.elements.entities[id] = element;
      });

      return {
        ...updatedState,
        meta: {
          ...updatedState.meta,
          schemaVersion: 93,
          releaseVersion: ENV.releaseVersion,
        },
      };
    },
    down: (/*state*/) => {},
  },
  {
    from: 93,
    to: 94,
    up: (state) => {
      let updatedState = {
        ...state,
      };
      updatedState.products.ids.forEach((productId) => {
        const product = updatedState.products.entities[productId];
        product.preferredElementVersionsList =
          product.preferredElementVersionsList
            .filter((elementVersionId) => {
              const elementVersion =
                updatedState.elementVersions.entities[elementVersionId];
              return elementVersion && elementVersion.element;
            })
            .map((elementVersionId) => {
              const elementVersion =
                updatedState.elementVersions.entities[elementVersionId];
              const elementId = elementVersion.element;
              return {
                element: elementId,
                elementVersion: elementVersionId,
              };
            });
        updatedState.products.entities[productId] = product;
      });

      return {
        ...updatedState,
        meta: {
          ...updatedState.meta,
          schemaVersion: 94,
          releaseVersion: ENV.releaseVersion,
        },
      };
    },
    down: (/*state*/) => {},
  },
  {
    from: 94,
    to: 95,
    up: (state) => {
      let updatedState = {
        ...state,
      };
      const elementsList = updatedState.elements.ids;
      updatedState.products.ids.forEach((productId) => {
        const product = updatedState.products.entities[productId];
        let preferredElementVersionsList = [];

        elementsList.forEach((elementId) => {
          const element = updatedState.elements.entities[elementId];
          const exists = product.preferredElementVersionsList.find(
            (obj) => obj.element === elementId
          )
            ? true
            : false;
          if (element && !exists) {
            preferredElementVersionsList.push({
              element: elementId,
              elementVersion:
                element.elementVersionsList.length === 1
                  ? element.elementVersionsList[0]
                  : null,
            });
          }
        });

        product.preferredElementVersionsList = [
          ...product.preferredElementVersionsList,
          preferredElementVersionsList,
        ];
        updatedState.products.entities[productId] = product;
      });

      return {
        ...updatedState,
        meta: {
          ...updatedState.meta,
          schemaVersion: 95,
          releaseVersion: ENV.releaseVersion,
        },
      };
    },
    down: (/*state*/) => {},
  },
  {
    from: 95,
    to: 96,
    up: (state) => {
      let updatedState = {
        ...state,
      };

      updatedState.elements.ids.forEach((id) => {
        updatedState.elements.entities[id] = {
          ...updatedState.elements.entities[id],
          instanceOf: null,
        };
      });

      return {
        ...updatedState,
        meta: {
          ...updatedState.meta,
          schemaVersion: 96,
          releaseVersion: ENV.releaseVersion,
        },
      };
    },
    down: (/*state*/) => {},
  },
  {
    from: 96,
    to: 97,
    up: (state) => {
      let updatedState = {
        ...state,
      };

      updatedState.elements.ids.forEach((id) => {
        let element = updatedState.elements.entities[id];
        element.component = null;
        element = omit(element, ['instanceOf']);
        updatedState.elements.entities[id] = element;
      });

      updatedState.components = {
        ids: [],
        entities: {},
      };

      return {
        ...updatedState,
        meta: {
          ...updatedState.meta,
          schemaVersion: 97,
          releaseVersion: ENV.releaseVersion,
        },
      };
    },
    down: (/*state*/) => {},
  },
  {
    from: 97,
    to: 98,
    up: (state) => {
      let updatedState = {
        ...state,
      };

      updatedState.elements.ids.forEach((id) => {
        let element = updatedState.elements.entities[id];
        element.component = null;
        element.isComponent = false;
        element.instanceOf = null;
        element.useDefaultOutcome = true;
        element.useDefaultRequirements = true;
        updatedState.elements.entities[id] = element;
      });

      return {
        ...updatedState,
        meta: {
          ...updatedState.meta,
          schemaVersion: 98,
          releaseVersion: ENV.releaseVersion,
        },
      };
    },
    down: (/*state*/) => {},
  },
  {
    from: 98,
    to: 99,
    up: (state) => {
      let updatedState = {
        ...state,
      };

      updatedState.products.ids.forEach((id) => {
        let product = updatedState.products.entities[id];
        product.markersList = [];
      });

      updatedState.terms.ids.forEach((id) => {
        let term = updatedState.terms.entities[id];
        term.markersList = term.referencesList
          ? term.referencesList.map((obj) => obj.id)
          : [];
        term = omit(term, ['referencesList']);
      });

      updatedState.elementVersions.ids.forEach((id) => {
        let elementVersion = updatedState.elementVersions.entities[id];
        elementVersion.markersList = elementVersion.referencesList
          ? elementVersion.referencesList.map((obj) => obj.id)
          : [];

        elementVersion = omit(elementVersion, ['referencesList']);
      });

      return {
        ...updatedState,
        meta: {
          ...updatedState.meta,
          schemaVersion: 99,
          releaseVersion: ENV.releaseVersion,
        },
      };
    },
    down: (/*state*/) => {},
  },
  {
    from: 99,
    to: 100,
    up: (state) => {
      let updatedState = {
        ...state,
      };

      updatedState.elements.ids.forEach((id) => {
        let element = updatedState.elements.entities[id];
        element.category = 'system';
      });

      return {
        ...updatedState,
        meta: {
          ...updatedState.meta,
          schemaVersion: 100,
          releaseVersion: ENV.releaseVersion,
        },
      };
    },
    down: (/*state*/) => {},
  },
  {
    from: 100,
    to: 101,
    up: (state) => {
      let updatedState = {
        ...state,
      };

      // find root node id
      const useElementId = updatedState.graph.rootNode.id;

      const productElementId = uuid();
      const productElementVersionId = uuid();

      const partsElementId = uuid();
      const partsElementVersionId = uuid();

      const manufactureElementId = uuid();
      const manufactureElementVersionId = uuid();

      // create a product element node
      addElement(
        updatedState,
        {
          category: 'product',
        },
        { category: 'machine' },
        productElementId,
        productElementVersionId
      );

      // update use element name
      updatedState.elements.entities[useElementId] = {
        ...updatedState.elements.entities[useElementId],
        name: 'Use',
      };

      // create a part element
      addElement(
        updatedState,
        {
          category: 'part',
          name: 'Make',
        },
        {
          category: 'machine',
        },
        partsElementId,
        partsElementVersionId
      );

      // create a manufacture element
      addElement(
        updatedState,
        {
          category: 'system',
          name: 'Manufacture',
        },
        {
          category: 'process',
        },
        manufactureElementId,
        manufactureElementVersionId
      );

      // update the preferred products
      updatedState.products.ids.forEach((productId) => {
        const product = updatedState.products.entities[productId];
        product.preferredElementVersionsList = [
          ...product.preferredElementVersionsList,
          {
            element: productElementId,
            elementVersion: productElementVersionId,
          },
          {
            element: partsElementId,
            elementVersion: partsElementVersionId,
          },
          {
            element: manufactureElementId,
            elementVersion: manufactureElementVersionId,
          },
        ].uniq();

        updatedState.products.entities[productId] = product;
      });

      // add comprises
      addComprisesRelationship(state, productElementVersionId, partsElementId);
      addComprisesRelationship(
        state,
        productElementVersionId,
        manufactureElementId
      );
      addComprisesRelationship(state, productElementVersionId, useElementId);

      // update graph rootNode
      updatedState.graph.rootNode = {
        id: productElementId,
        type: 'element',
      };

      return {
        ...updatedState,
        meta: {
          ...updatedState.meta,
          schemaVersion: 101,
          releaseVersion: ENV.releaseVersion,
        },
      };
    },
    down: (/*state*/) => {},
  },
  {
    from: 101,
    to: 102,
    up: (state) => {
      let updatedState = {
        ...state,
      };

      // add category to markers
      updatedState.markers.ids.forEach((markerId) => {
        const marker = updatedState.markers.entities[markerId];

        if (marker && marker.type === 'element') {
          marker.category = 'system';
        }

        if (marker && marker.type !== 'element') {
          marker.category = null;
        }

        updatedState.markers.entities[markerId] = marker;
      });

      return {
        ...updatedState,
        meta: {
          ...updatedState.meta,
          schemaVersion: 102,
          releaseVersion: ENV.releaseVersion,
        },
      };
    },
    down: (/*state*/) => {},
  },
  {
    from: 102,
    to: 103,
    up: (state) => {
      let updatedState = {
        ...state,
      };

      const rootNodeId = updatedState.graph.rootNode.id;
      const orphanNodesList = updatedState.elements.ids.filter((id) => {
        const element = updatedState.elements.entities[id];
        return (
          element &&
          element.id &&
          element.id !== rootNodeId &&
          !element.isComponent &&
          !element.deletedAt &&
          !element.elementVersion
        );
      });

      updatedState.graph.orphanNodesList = orphanNodesList;

      return {
        ...updatedState,
        meta: {
          ...updatedState.meta,
          schemaVersion: 103,
          releaseVersion: ENV.releaseVersion,
        },
      };
    },
    down: (/*state*/) => {},
  },
  {
    from: 103,
    to: 104,
    up: (state) => {
      let updatedState = {
        ...state,
      };

      updatedState.ui.activeView = 'graph';
      updatedState.ui = omit(updatedState.ui, 'activeWorkArea');

      return {
        ...updatedState,
        meta: {
          ...updatedState.meta,
          schemaVersion: 104,
          releaseVersion: ENV.releaseVersion,
        },
      };
    },
    down: (/*state*/) => {},
  },
  {
    from: 104,
    to: 105,
    up: (state) => {
      let updatedState = {
        ...state,
      };

      updatedState.ui.collapsedSolution = false;
      updatedState.ui.collapsedProblem = false;

      return {
        ...updatedState,
        meta: {
          ...updatedState.meta,
          schemaVersion: 105,
          releaseVersion: ENV.releaseVersion,
        },
      };
    },
    down: (/*state*/) => {},
  },
  {
    from: 105,
    to: 106,
    up: (state) => {
      let updatedState = {
        ...state,
      };

      updatedState.ui.defaultElementCategory = 'part';

      return {
        ...updatedState,
        meta: {
          ...updatedState.meta,
          schemaVersion: 106,
          releaseVersion: ENV.releaseVersion,
        },
      };
    },
    down: (/*state*/) => {},
  },
  {
    from: 106,
    to: 107,
    up: (state) => {
      let updatedState = {
        ...state,
      };

      updatedState.ui.defaultMarkerCategory = 'part';

      return {
        ...updatedState,
        meta: {
          ...updatedState.meta,
          schemaVersion: 107,
          releaseVersion: ENV.releaseVersion,
        },
      };
    },
    down: (/*state*/) => {},
  },
  {
    from: 107,
    to: 108,
    up: (state) => {
      let updatedState = {
        ...state,
      };

      // updatedState.ui.selectedBulkCreateItems = [];

      return {
        ...updatedState,
        meta: {
          ...updatedState.meta,
          schemaVersion: 108,
          releaseVersion: ENV.releaseVersion,
        },
      };
    },
    down: (/*state*/) => {},
  },
  {
    from: 108,
    to: 109,
    up: (state) => {
      let updatedState = {
        ...state,
      };

      updatedState.ui.activeProductSection = 'market';
      updatedState.ui.activeProductSubsection = null;

      return {
        ...updatedState,
        meta: {
          ...updatedState.meta,
          schemaVersion: 109,
          releaseVersion: ENV.releaseVersion,
        },
      };
    },
    down: (/*state*/) => {},
  },
  {
    from: 109,
    to: 110,
    up: (state) => {
      let updatedState = {
        ...state,
      };

      updatedState.ui.activeProductView = 'market';

      return {
        ...updatedState,
        meta: {
          ...updatedState.meta,
          schemaVersion: 110,
          releaseVersion: ENV.releaseVersion,
        },
      };
    },
    down: (/*state*/) => {},
  },
  {
    from: 110,
    to: 111,
    up: (state) => {
      let updatedState = {
        ...state,
      };

      updatedState.elementColumns = {
        ids: [],
        entities: {},
      };

      return {
        ...updatedState,
        meta: {
          ...updatedState.meta,
          schemaVersion: 111,
          releaseVersion: ENV.releaseVersion,
        },
      };
    },
    down: (/*state*/) => {},
  },
  {
    from: 111,
    to: 112,
    up: (state) => {
      let updatedState = {
        ...state,
      };

      updatedState = omit(updatedState, ['elementColumns']);

      updatedState.ui.elementColumnsList = [];
      updatedState.ui.elementColumnsData = {};
      updatedState.ui.visibleElementColumnsList = [];

      return {
        ...updatedState,
        meta: {
          ...updatedState.meta,
          schemaVersion: 112,
          releaseVersion: ENV.releaseVersion,
        },
      };
    },
    down: (/*state*/) => {},
  },
  {
    from: 112,
    to: 113,
    up: (state) => {
      let updatedState = {
        ...state,
      };

      // update product attrs
      updatedState.products.ids.forEach((productId) => {
        let product = updatedState.products.entities[productId];
        product.checklist = [];
        product.problem = '';
        product.solution = product.summary || '';
        product = omit(product, ['summary', 'activeNode', 'x', 'y']);
        updatedState.products.entities[productId] = product;
      });

      return {
        ...updatedState,
        meta: {
          ...updatedState.meta,
          schemaVersion: 113,
          releaseVersion: ENV.releaseVersion,
        },
      };
    },
    down: (/*state*/) => {},
  },
  {
    from: 113,
    to: 114,
    up: (state) => {
      let updatedState = {
        ...state,
      };

      updatedState.ui.navigationStack = [];

      return {
        ...updatedState,
        meta: {
          ...updatedState.meta,
          schemaVersion: 114,
          releaseVersion: ENV.releaseVersion,
        },
      };
    },
    down: (/*state*/) => {},
  },
  {
    from: 114,
    to: 115,
    up: (state) => {
      let updatedState = {
        ...state,
      };

      updatedState.methodEdges.ids.forEach((id) => {
        const methodEdge = updatedState.methodEdges.entities[id];

        let bendPoints = {};

        methodEdge.methodEdgePointsList.forEach((methodEdgePointId, index) => {
          const methodEdgePoint =
            updatedState.methodEdgePoints.entities[methodEdgePointId];

          bendPoints[index] = {
            x: methodEdgePoint.x,
            y: methodEdgePoint.y,
          };
        });

        updatedState.methodEdges.entities[id] = {
          ...methodEdge,
          bendPoints,
        };
      });

      return {
        ...updatedState,
        meta: {
          ...updatedState.meta,
          schemaVersion: 115,
          releaseVersion: ENV.releaseVersion,
        },
      };
    },
    down: (/*state*/) => {},
  },
  {
    from: 115,
    to: 116,
    up: (state) => {
      let updatedState = {
        ...state,
      };

      const getPosition = (pos) => {
        let newPos;
        switch (pos) {
          case 'n':
            newPos = 'NORTH';
            break;
          case 's':
            newPos = 'SOUTH';
            break;
          case 'e':
            newPos = 'EAST';
            break;
          case 'w':
            newPos = 'WEST';
            break;
        }
        return newPos;
      };

      updatedState.methodEdges.ids.forEach((id) => {
        const methodEdge = updatedState.methodEdges.entities[id];

        methodEdge.sourcePosition = getPosition(methodEdge.sourcePosition);
        methodEdge.targetPosition = getPosition(methodEdge.targetPosition);

        updatedState.methodEdges.entities[id] = methodEdge;
      });

      return {
        ...updatedState,
        meta: {
          ...updatedState.meta,
          schemaVersion: 116,
          releaseVersion: ENV.releaseVersion,
        },
      };
    },
    down: (/*state*/) => {},
  },
  {
    from: 116,
    to: 117,
    up: (state) => {
      let updatedState = {
        ...state,
      };

      updatedState.ui.activeProductChecklistView = 'market';
      updatedState.ui.showingProductChecklist = false;

      return {
        ...updatedState,
        meta: {
          ...updatedState.meta,
          schemaVersion: 117,
          releaseVersion: ENV.releaseVersion,
        },
      };
    },
    down: (/*state*/) => {},
  },
  {
    from: 117,
    to: 118,
    up: (state) => {
      let updatedState = {
        ...state,
      };

      updatedState.ui.defaultMethodNodeType = 'custom';

      return {
        ...updatedState,
        meta: {
          ...updatedState.meta,
          schemaVersion: 118,
          releaseVersion: ENV.releaseVersion,
        },
      };
    },
    down: (/*state*/) => {},
  },
  {
    from: 118,
    to: 119,
    up: (state) => {
      let updatedState = {
        ...state,
      };

      const stack = {
        routeName: 'graph',
        modelType: null,
        modelId: null,
        key: 'graph',
        selectedItems: {},
      };

      updatedState.ui.navigationStack = [stack];

      return {
        ...updatedState,
        meta: {
          ...updatedState.meta,
          schemaVersion: 119,
          releaseVersion: ENV.releaseVersion,
        },
      };
    },
    down: (/*state*/) => {},
  },
  {
    from: 119,
    to: 120,
    up: (state) => {
      let updatedState = {
        ...state,
      };

      const stack = {
        routeName: 'graph',
        modelType: null,
        modelId: null,
        key: 'graph',
        selectedItems: {},
      };

      updatedState.ui.navigationStack = [stack];

      return {
        ...updatedState,
        meta: {
          ...updatedState.meta,
          schemaVersion: 120,
          releaseVersion: ENV.releaseVersion,
        },
      };
    },
    down: (/*state*/) => {},
  },
  {
    from: 120,
    to: 121,
    up: (state) => {
      let updatedState = {
        ...state,
      };

      updatedState.customers = {
        ids: [],
        entities: {},
      };

      updatedState.ui.showingCustomers = false;
      updatedState.ui.updatingProductCustomers = false;

      updatedState.products.ids.forEach((productId) => {
        const product = updatedState.products.entities[productId];
        product.customersList = [];
        updatedState.products.entities[productId] = product;
      });

      return {
        ...updatedState,
        meta: {
          ...updatedState.meta,
          schemaVersion: 121,
          releaseVersion: ENV.releaseVersion,
        },
      };
    },
    down: (/*state*/) => {},
  },
  {
    from: 121,
    to: 122,
    up: (state) => {
      let updatedState = {
        ...state,
      };

      return {
        ...updatedState,
        meta: {
          ...updatedState.meta,
          schemaVersion: 122,
          releaseVersion: ENV.releaseVersion,
        },
      };
    },
    down: (/*state*/) => {},
  },
  {
    from: 122,
    to: 123,
    up: (state) => {
      let updatedState = {
        ...state,
      };

      return {
        ...updatedState,
        meta: {
          ...updatedState.meta,
          schemaVersion: 123,
          releaseVersion: ENV.releaseVersion,
        },
      };
    },
    down: (/*state*/) => {},
  },
  {
    from: 123,
    to: 124,
    up: (state) => {
      let updatedState = {
        ...state,
      };

      updatedState.products.ids.forEach((productId) => {
        const product = updatedState.products.entities[productId];
        product.customersList = [];
        updatedState.products.entities[productId] = product;
      });

      return {
        ...updatedState,
        meta: {
          ...updatedState.meta,
          schemaVersion: 124,
          releaseVersion: ENV.releaseVersion,
        },
      };
    },
    down: (/*state*/) => {},
  },
  {
    from: 124,
    to: 125,
    up: (state) => {
      let updatedState = {
        ...state,
      };

      const patentSpecificationId = updatedState.patentSpecifications.ids[0];
      const patentSpecification =
        updatedState.patentSpecifications.entities[patentSpecificationId];

      updatedState.patentSpecification = addFromSchema('patent-specification', {
        type: patentSpecification.type,
        title: patentSpecification.title,
        inventors: patentSpecification.inventors,
        fundingSources: patentSpecification.fundingSources,
        inventionFields: patentSpecification.inventionFields,
        background: patentSpecification.background,
        summary: patentSpecification.summary,
        abstract: patentSpecification.abstract,
      });

      updatedState = omit(updatedState, ['patentSpecifications']);

      return {
        ...updatedState,
        meta: {
          ...updatedState.meta,
          schemaVersion: 125,
          releaseVersion: ENV.releaseVersion,
        },
      };
    },
    down: (/*state*/) => {},
  },
  {
    from: 125,
    to: 126,
    up: (state) => {
      let updatedState = {
        ...state,
      };

      updatedState.patentSpecification = {
        ...updatedState.patentSpecification,
        showFundingSources: false,
        showCrossReference: false,
        crossReference: '',
      };

      return {
        ...updatedState,
        meta: {
          ...updatedState.meta,
          schemaVersion: 126,
          releaseVersion: ENV.releaseVersion,
        },
      };
    },
    down: (/*state*/) => {},
  },
  {
    from: 126,
    to: 127,
    up: (state) => {
      let updatedState = {
        ...state,
      };

      updatedState.patentSpecification = {
        ...updatedState.patentSpecification,
        showBoilerplate: false,
      };

      return {
        ...updatedState,
        meta: {
          ...updatedState.meta,
          schemaVersion: 127,
          releaseVersion: ENV.releaseVersion,
        },
      };
    },
    down: (/*state*/) => {},
  },
  {
    from: 127,
    to: 128,
    up: (state) => {
      let updatedState = {
        ...state,
      };

      updatedState.markers.ids.forEach((id) => {
        let marker = updatedState.markers.entities[id];
        if (
          marker.elementVersion &&
          !updatedState.elementVersions.entities[marker.elementVersion]
        ) {
          marker.element = null;
          marker.elementVersion = null;
          marker.term = null;
        }
        updatedState.markers.entities[id] = marker;
      });

      return {
        ...updatedState,
        meta: {
          ...updatedState.meta,
          schemaVersion: 128,
          releaseVersion: ENV.releaseVersion,
        },
      };
    },
    down: (/*state*/) => {},
  },
  {
    from: 128,
    to: 129,
    up: (state) => {
      let updatedState = {
        ...state,
      };

      updatedState.ui.collapsedNodes = {};

      return {
        ...updatedState,
        meta: {
          ...updatedState.meta,
          schemaVersion: 129,
          releaseVersion: ENV.releaseVersion,
        },
      };
    },
    down: (/*state*/) => {},
  },
  {
    from: 129,
    to: 130,
    up: (state) => {
      let updatedState = {
        ...state,
      };

      updatedState.drawings.ids.forEach((id) => {
        let drawing = updatedState.drawings.entities[id];
        drawing.type = '';
        updatedState.drawings.entities[id] = drawing;
      });

      return {
        ...updatedState,
        meta: {
          ...updatedState.meta,
          schemaVersion: 130,
          releaseVersion: ENV.releaseVersion,
        },
      };
    },
    down: (/*state*/) => {},
  },
  {
    from: 130,
    to: 131,
    up: (state) => {
      let updatedState = {
        ...state,
      };

      updatedState.ui.showingPatentability = false;
      updatedState.ui.activePatentabilitySection = 'conception';

      updatedState.products.ids.forEach((id) => {
        let product = updatedState.products.entities[id];
        product.patentability = {};
        product = omit(product, [
          'collapsedNodesList',
          'collapsedDescendantsList',
        ]);
        updatedState.products.entities[id] = product;
      });

      return {
        ...updatedState,
        meta: {
          ...updatedState.meta,
          schemaVersion: 131,
          releaseVersion: ENV.releaseVersion,
        },
      };
    },
    down: (/*state*/) => {},
  },
  {
    from: 131,
    to: 132,
    up: (state) => {
      let updatedState = {
        ...state,
      };

      updatedState.settings = {
        showWarnings: true,
        previewLanguage: 'report',
        previewNumbering: 'patent-specification',
        showNovelty: true,
        theme: 'auto',
        blackAndWhiteMode: false,
      };

      return {
        ...updatedState,
        meta: {
          ...updatedState.meta,
          schemaVersion: 132,
          releaseVersion: ENV.releaseVersion,
        },
      };
    },
    down: (/*state*/) => {},
  },
  {
    from: 132,
    to: 133,
    up: (state) => {
      let updatedState = {
        ...state,
      };

      updatedState.ui.activeProductChecklistView = 'invention';

      return {
        ...updatedState,
        meta: {
          ...updatedState.meta,
          schemaVersion: 133,
          releaseVersion: ENV.releaseVersion,
        },
      };
    },
    down: (/*state*/) => {},
  },
  {
    from: 133,
    to: 134,
    up: (state) => {
      let updatedState = {
        ...state,
      };

      updatedState.products.ids.forEach((id, index) => {
        let product = updatedState.products.entities[id];
        product.productName = product.name;
        product.name = `Version ${index + 1}`;
        updatedState.products.entities[id] = product;
      });

      return {
        ...updatedState,
        meta: {
          ...updatedState.meta,
          schemaVersion: 134,
          releaseVersion: ENV.releaseVersion,
        },
      };
    },
    down: (/*state*/) => {},
  },
  {
    from: 134,
    to: 135,
    up: (state) => {
      let updatedState = {
        ...state,
      };

      updatedState.products.ids.forEach((id) => {
        let product = updatedState.products.entities[id];
        product.assertedMilestonesList = product.checklist || [];
        product.reviewedMilestonesList = [];
        updatedState.products.entities[id] = product;
      });

      return {
        ...updatedState,
        meta: {
          ...updatedState.meta,
          schemaVersion: 135,
          releaseVersion: ENV.releaseVersion,
        },
      };
    },
    down: (/*state*/) => {},
  },
  {
    from: 135,
    to: 136,
    up: (state) => {
      let updatedState = {
        ...state,
      };

      updatedState.elementVersions.ids.forEach((id) => {
        let elementVersion = updatedState.elementVersions.entities[id];
        elementVersion.amountType = 'one';
        elementVersion.amountCustom = '';
        updatedState.elementVersions.entities[id] = elementVersion;
      });

      return {
        ...updatedState,
        meta: {
          ...updatedState.meta,
          schemaVersion: 136,
          releaseVersion: ENV.releaseVersion,
        },
      };
    },
    down: (/*state*/) => {},
  },
  {
    from: 136,
    to: 137,
    up: (state) => {
      let updatedState = {
        ...state,
      };

      updatedState.elementVersions.ids.forEach((id) => {
        let elementVersion = updatedState.elementVersions.entities[id];
        elementVersion = omit(elementVersion, ['amountType', 'amountCustom']);
        updatedState.elementVersions.entities[id] = elementVersion;
      });

      updatedState.features.ids.forEach((id) => {
        let feature = updatedState.features.entities[id];
        if (feature?.type === 'comprises') {
          feature.value = {
            element: feature.value,
            amount: 'one',
            custom: '',
            plural: false
          }
          updatedState.features.entities[id] = feature;
        }
      });

      return {
        ...updatedState,
        meta: {
          ...updatedState.meta,
          schemaVersion: 137,
          releaseVersion: ENV.releaseVersion,
        },
      };
    },
    down: (/*state*/) => {},
  },
  {
    from: 137,
    to: 138,
    up: (state) => {
      let updatedState = {
        ...state,
      };

      updatedState.elements.ids.forEach((id) => {
        let element = updatedState.elements.entities[id];
        element.categoryGroup = '';
        updatedState.elements.entities[id] = element;
      });

      return {
        ...updatedState,
        meta: {
          ...updatedState.meta,
          schemaVersion: 138,
          releaseVersion: ENV.releaseVersion,
        },
      };
    },
    down: (/*state*/) => {},
  },
];
