import {
  ELEMENT_COLLAPSED_NODE_STROKE,
  ELEMENT_NODE_COLOR,
  ELEMENT_NODE_FILL,
  ELEMENT_NODE_FILL_LIGHT,
  ELEMENT_NODE_GREY_COLOR,
  ELEMENT_NODE_GREY_FILL,
  ELEMENT_NODE_GREY_FILL_LIGHT,
  ELEMENT_NODE_GREY_STROKE,
  ELEMENT_NODE_GREY_STROKE_LIGHT,
  ELEMENT_NODE_HEADER_COLOR,
  ELEMENT_NODE_STROKE,
  ELEMENT_VERSION_CATEGORY_ARTICLE,
  ELEMENT_VERSION_CATEGORY_ARTICLE_LIGHT,
  ELEMENT_VERSION_CATEGORY_ARTICLE_LIGHTER,
  ELEMENT_VERSION_CATEGORY_COMPOSITION,
  ELEMENT_VERSION_CATEGORY_COMPOSITION_LIGHT,
  ELEMENT_VERSION_CATEGORY_COMPOSITION_LIGHTER,
  ELEMENT_VERSION_CATEGORY_MACHINE,
  ELEMENT_VERSION_CATEGORY_MACHINE_LIGHT,
  ELEMENT_VERSION_CATEGORY_MACHINE_LIGHTER,
  ELEMENT_VERSION_CATEGORY_PROCESS,
  ELEMENT_VERSION_CATEGORY_PROCESS_LIGHT,
  ELEMENT_VERSION_CATEGORY_PROCESS_LIGHTER,
  ELEMENT_VERSION_NODE_FILL_LIGHTER,
  ELEMENT_VERSION_NODE_STROKE,
  WARNING_NODE_FILL,
  WARNING_NODE_STROKE,
} from '../../../constants/colors';
import { action, computed } from '@ember/object';

/* eslint-disable ember/use-brace-expansion */
import Component from '@glimmer/component';
import ENV from '../../../config/environment';
import Konva from 'konva';
import { connect } from 'ember-redux';
import { getDrawing } from '../../../selectors/drawing';
import { getElementVersion } from '../../../selectors/element-version';
import { getFeature } from '../../../selectors/feature';
import { getMarker } from '../../../selectors/marker';
import { getMethod } from '../../../selectors/method';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency-decorators';
import { textValue } from '../../../utils/string';

// import { getPreferredElementVersion } from '../../../selectors/product';

const dispatchToActions = {};

const stateToComputed = (state, attrs) => ({
  elementVersion: getElementVersion(state, attrs.elementVersionId),
});

class InventionGraphElement extends Component {
  @service redux;
  @service referencesCache;
  @service figuresCache;
  @service models;
  @service store;

  fontFamily = 'Inter';
  verticalPadding = 19.5;
  horizontalPadding = 19.5;
  borderRadius = 26;
  radius = 200;
  height = 260;
  width = 260;
  _isCollapsed = false;
  _visibleAreaIndex = 0;
  _elementVersionIsSelected = '';
  _x = 0;
  _y = 0;
  _reachedScaleThreshold = false;

  constructor(owner, args) {
    super(owner, args);
    this.onScheduleRender = this.args.onScheduleRender;
    this.elementsLayer = this.args.elementsLayer;
    this.interactiveLayer = this.args.interactiveLayer;
  }

  @action
  didInsert() {
    this._isVisited = this.args.isVisited;
    this._isDisconnected = this.args.isDisconnected;
    this._isCollapsed = this.args.isCollapsed;
    this._visibleAreaIndex = this.args.visibleAreaIndex;
    this._elementVersionIsSelected = this.elementVersionIsSelected;
    this._x = this.args.x;
    this._y = this.args.y;
    this._reachedScaleThreshold = this.args.reachedScaleThreshold;
    this._solutionsString = this.solutionsString;
    this._referenceId = this.referenceId;
    this._referenceUpdatedAt = this.referenceUpdatedAt;
    this._name = this.args.name;
    this._elementVersionName = this.elementVersionName;
    this._showDrawing = this.showDrawing;
    this._showDrawingPlaceholder = this.showDrawingPlaceholder;
    this._showMethod = this.showMethod;
    this._methodUpdatedAt = this.methodUpdatedAt;
    this._known = this.elementVersion.known;
    this._elementVersionCategory = this.elementVersionCategory;
    this.activeLayer = this.args.isSelected
      ? this.interactiveLayer
      : this.elementsLayer;
    this.setup();
  }

  @action
  willDestroyNode() {
    // remove events
    if (this.args.onContextClick) {
      this.elementVersionNode.off('contextmenu');
    }
    if (this.args.onClick) {
      this.elementVersionBackgroundNode.off('click');
      this.knownElementVersionBackgroundNode.off('click');
    }
    if (this.args.onDragStart) {
      this.elementVersionNode.off('dragstart');
    }
    if (this.args.onDragMove) {
      this.elementVersionNode.off('dragmove');
    }
    if (this.args.onDragEnd) {
      this.elementVersionNode.off('dragend');
    }
    if (this.args.onMouseenter) {
      this.elementVersionNode.off('mouseenter');
    }
    if (this.args.onMouseleave) {
      this.elementVersionNode.off('mouseleave');
    }
    if (this.args.onElementVersionClick) {
      // this.elementVersionNode.off('click');
    }
    if (this.args.onDrawingClick) {
      this.drawingNode.off('click');
      this.drawingPlaceholderNode.off('click');
    }
    if (this.args.onMethodClick) {
      this.methodNode.off('click');
    }
    this.elementVersionNode.destroy();
    this.onScheduleRender();
  }

  get styleNamespace() {
    return podNames['invention-graph-element-konva'];
  }

  get classNames() {
    let classNames = [this.styleNamespace];
    if (this.args.isDisconnected) classNames.push('is-disconnected');
    if (this.args.isSelected) classNames.push('is-selected');
    if (this.args.isCollapsed) classNames.push('is-collapsed');
    if (this.args.isVisited) classNames.push('is-visited');
    if (this.args.isDisconnected) classNames.push('is-disconnected');
    return classNames.join(' ');
  }

  @computed('elementVersion.known')
  get elementVersionIsKnown() {
    return this.elementVersion.known ? true : false;
  }

  @computed('elementVersionIsKnown')
  get elementVersionIsntKnown() {
    return !this.elementVersionIsKnown;
  }

  @computed('elementVersion.category')
  get elementVersionCategory() {
    return this.elementVersion && this.elementVersion.category;
  }

  @computed('elementVersionId', 'args.elementVersionsList.[]')
  get solutionsString() {
    return (
      this.args.elementVersionsList &&
      this.elementVersionId &&
      this.elementVersionId + this.args.elementVersionsList.join(',')
    );
  }

  @computed('elementVersion.known')
  get known() {
    return this.elementVersion && this.elementVersion.known;
  }

  @computed('elementVersionId')
  get elementVersion() {
    const state = this.redux.getState();
    return (
      this.elementVersionId && getElementVersion(state, this.elementVersionId)
    );
  }

  @computed('elementVersion.name')
  get elementVersionName() {
    // return this.elementVersion ? `(${this.elementVersion.name})` : '';
    return this.elementVersion ? this.elementVersion.name : '';
  }

  @computed('args.isSelected')
  get elementVersionIsSelected() {
    return this.args.isSelected;
  }

  @computed('elementVersion.markersList.[]')
  get referenceId() {
    return (
      this.elementVersion &&
      this.elementVersion.markersList.length &&
      this.elementVersion.markersList[0]
    );
  }

  @computed('args.{drawings,methods}', 'referenceId')
  get referenceSource() {
    let source;
    const state = this.redux.getState();
    const marker = this.referenceId && getMarker(state, this.referenceId);
    if (marker) {
      source = getDrawing(state, marker.drawing);
    }
    return source;
  }

  @computed('referenceSource.updatedAt', 'referenceId')
  get referenceUpdatedAt() {
    return (
      this.referenceId && this.referenceSource && this.referenceSource.updatedAt
    );
  }
  @computed('referenceId')
  get hasReference() {
    return this.referenceId ? true : false;
  }
  
  @computed('args.{elementVersionId,nodesMap}')
  get siblings() {
    const id = this.args.elementVersionId;
    const node = this.args.nodesMap && this.args.nodesMap[id];
    const siblings =
      node && node.parent && this.args.nodesMap[node.parent].children;
    return siblings;
  }

  @computed('siblings.[]')
  get hasSiblings() {
    return this.siblings && this.siblings.length > 1;
  }

  @computed('siblings.[]', 'args.elementVersionId')
  get isLastSibling() {
    const index =
      this.siblings && this.siblings.indexOf(this.args.elementVersionId);
    const length = this.siblings && this.siblings.length;
    return length && index === length - 1;
  }

  @computed('elementVersionId')
  get hasSolution() {
    return this.elementVersionId ? true : false;
  }

  // @computed('elementVersionId', 'args.elementVersionsList.[]')
  // get hasTwoSolutions() {
  //   return this.elementVersionId &&
  //     this.args.elementVersionsList &&
  //     this.args.elementVersionsList.length >= 2
  //     ? true
  //     : false;
  // }

  // @computed('elementVersionId', 'args.elementVersionsList.[]')
  // get hasThreeOrMoreSolutions() {
  //   return this.elementVersionId &&
  //     this.args.elementVersionsList &&
  //     this.args.elementVersionsList.length >= 3
  //     ? true
  //     : false;
  // }

  get textWidth() {
    return this.width - this.horizontalPadding * 2;
  }

  get collapsedTransform() {
    const x = 0;
    const y = this.height + 325;
    return { x, y };
  }

  get methodTransform() {
    let x = -5;
    let y = 35;
    let rotation = -11;
    let offset = {
      x: this.width / 2 + 80,
      y: 0,
    };
    let width = 210;
    let height = 277;
    return { x, y, rotation, offset, width, height };
  }

  get methodStroke() {
    return this.placeholderStrokeColor;
  }

  @computed('elementVersion.method')
  get methodId() {
    return this.elementVersion && this.elementVersion.method;
  }

  @computed('methodId')
  get methodModel() {
    return this.methodId && this.store.peekRecord('method', this.methodId);
  }

  @computed('methodModel.updatedAt')
  get methodUpdatedAt() {
    return this.methodModel && this.methodModel.updatedAt;
  }

  @computed('methodHasDisconnectNodes')
  get methodDashEnabled() {
    return this.methodHasDisconnectNodes ? true : false;
  }

  @computed('methodModel.methodNodesList.[]')
  get methodHasNodes() {
    return this.methodModel && this.methodModel.methodNodesList.length > 1;
  }

  @computed('methodModel.disconnectedNodesList.[]')
  get methodHasDisconnectNodes() {
    return this.methodModel && this.methodModel.disconnectedNodesList.length
      ? true
      : false;
  }

  @computed('methodHasNodes')
  get showMethod() {
    return this.methodHasNodes ? true : false;
  }

  @computed('showMethod', 'height', 'width')
  get drawingTransform() {
    let x = 0;
    let y = 40;
    let rotation = 0;
    let offset = {
      x: 0,
      y: 0,
    };
    let width = 210;
    let height = 277;

    if (this.showMethod) {
      x = 75;
      y = 48;
      rotation = 11;
      offset = {
        x: this.width / 2 - 60,
        y: 0,
      };
    }
    return { x, y, rotation, offset, width, height };
  }

  referenceTranslate(index = 0) {
    const offset = index * 15;
    const x = (-1 * this.referenceWidth) / 2 + offset;
    const y = (-1 * this.referenceHeight) / 2;
    return { x, y };
  }

  get referenceOrigin() {
    const x = this.referenceWidth / 2;
    const y = this.referenceWidth / 2;
    return { x, y };
  }

  get requirements() {
    const state = this.redux.getState();
    return this.args.requirementsList.map((requirementId) => {
      const feature = getFeature(state, requirementId);
      return (
        this.models.find(requirementId) ||
        this.models.findOrCreate(feature.id, 'feature', feature)
      );
    });
  }

  @computed('requirements.@each.value', 'args.requirementsList.[]')
  get hasRequirements() {
    return this.requirements.filter((requirement) =>
      textValue(requirement.value || '')
    ).length
      ? true
      : false;
  }

  @computed('hasReference')
  get showDrawing() {
    return this.hasReference ? true : false;
  }

  @computed('showDrawing')
  get showDrawingPlaceholder() {
    return !this.showDrawing;
  }

  get strokeColor() {
    let color = ELEMENT_NODE_STROKE;

    if (this.elementVersionCategory === 'machine') {
      color = ELEMENT_VERSION_CATEGORY_MACHINE;
    }
    if (this.elementVersionCategory === 'process') {
      color = ELEMENT_VERSION_CATEGORY_PROCESS;
    }
    if (this.elementVersionCategory === 'article-of-manufacture') {
      color = ELEMENT_VERSION_CATEGORY_ARTICLE;
    }
    if (this.elementVersionCategory === 'composition') {
      color = ELEMENT_VERSION_CATEGORY_COMPOSITION;
    }

    if (this.args.isDisconnected) {
      color = ELEMENT_NODE_GREY_STROKE;
    }
    return color;
  }

  get strokeColorLight() {
    let color = ELEMENT_COLLAPSED_NODE_STROKE;

    if (this.args.isDisconnected) {
      color = ELEMENT_NODE_GREY_STROKE_LIGHT;
    }
    return color;
  }

  get strokeColorWarning() {
    let color = WARNING_NODE_STROKE;
    return color;
  }

  get placeholderDash() {
    return [15, 10];
  }

  get placeholderFillColor() {
    return '#f2f2f2';
  }

  get placeholderStrokeColor() {
    return '#777';
  }

  get placeholderSelectedStrokeColor() {
    return '#999';
  }

  get placeholderTextColor() {
    return '#666';
  }

  get fillColor() {
    let color = ELEMENT_NODE_FILL;

    if (this.elementVersionCategory === 'machine') {
      color = ELEMENT_VERSION_CATEGORY_MACHINE_LIGHT;
    }
    if (this.elementVersionCategory === 'process') {
      color = ELEMENT_VERSION_CATEGORY_PROCESS_LIGHT;
    }
    if (this.elementVersionCategory === 'article-of-manufacture') {
      color = ELEMENT_VERSION_CATEGORY_ARTICLE_LIGHT;
    }
    if (this.elementVersionCategory === 'composition') {
      color = ELEMENT_VERSION_CATEGORY_COMPOSITION_LIGHT;
    }
    if (this.args.isDisconnected) {
      color = ELEMENT_NODE_GREY_FILL;
    }
    return color;
  }

  get fillColorLight() {
    let color = ELEMENT_NODE_FILL_LIGHT;
    // if (this.elementVersionCategory === 'machine') {
    //   color = ELEMENT_VERSION_CATEGORY_MACHINE_LIGHT;
    // }
    // if (this.elementVersionCategory === 'process') {
    //   color = ELEMENT_VERSION_CATEGORY_PROCESS_LIGHT;
    // }
    // if (this.elementVersionCategory === 'article-of-manufacture') {
    //   color = ELEMENT_VERSION_CATEGORY_ARTICLE_LIGHT;
    // }

    if (this.args.isDisconnected) {
      color = ELEMENT_NODE_GREY_FILL_LIGHT;
    }
    return color;
  }

  get fillColorCollapsed() {
    let color = ELEMENT_NODE_FILL_LIGHT;

    if (this.args.isDisconnected) {
      color = ELEMENT_NODE_GREY_FILL_LIGHT;
    }
    return color;
  }

  get fillColorWarning() {
    let color = WARNING_NODE_FILL;
    if (this.args.isDisconnected) {
      color = ELEMENT_NODE_GREY_FILL_LIGHT;
    }
    return color;
  }

  get elementVersionFillColorLight() {
    let color = ELEMENT_VERSION_NODE_FILL_LIGHTER;

    if (this.elementVersionCategory === 'machine') {
      color = ELEMENT_VERSION_CATEGORY_MACHINE_LIGHTER;
    }
    if (this.elementVersionCategory === 'process') {
      color = ELEMENT_VERSION_CATEGORY_PROCESS_LIGHTER;
    }
    if (this.elementVersionCategory === 'article-of-manufacture') {
      color = ELEMENT_VERSION_CATEGORY_ARTICLE_LIGHTER;
    }
    if (this.elementVersionCategory === 'composition') {
      color = ELEMENT_VERSION_CATEGORY_COMPOSITION_LIGHTER;
    }
    if (this.args.isDisconnected) {
      color = ELEMENT_NODE_GREY_FILL_LIGHT;
    }
    return color;
  }

  get elementVersionStrokeColor() {
    let color = ELEMENT_VERSION_NODE_STROKE;

    if (this.elementVersionCategory === 'machine') {
      color = ELEMENT_VERSION_CATEGORY_MACHINE;
    }
    if (this.elementVersionCategory === 'process') {
      color = ELEMENT_VERSION_CATEGORY_PROCESS;
    }
    if (this.elementVersionCategory === 'article-of-manufacture') {
      color = ELEMENT_VERSION_CATEGORY_ARTICLE;
    }
    if (this.elementVersionCategory === 'composition') {
      color = ELEMENT_VERSION_CATEGORY_COMPOSITION;
    }

    if (this.args.isDisconnected) {
      color = ELEMENT_NODE_GREY_STROKE;
    }
    return color;
  }

  get textColor() {
    let color = ELEMENT_NODE_COLOR;
    if (this.args.isDisconnected) {
      color = ELEMENT_NODE_GREY_COLOR;
    }
    return color;
  }

  get headerTextColor() {
    let color = ELEMENT_NODE_HEADER_COLOR;
    if (this.args.isDisconnected) {
      color = ELEMENT_NODE_GREY_STROKE;
    }
    return color;
  }

  setup() {
    const elementVersionNode = new Konva.Group({
      id: this.args.elementVersionId,
      x: this.args.x,
      y: this.args.y,
      name: 'node',
      draggable: this.args.isDraggable ? true : false,
      nodeType: 'element-version',
      transformsEnabled: 'position',
    });

    const elementVersionSelectedNode = new Konva.Rect({
      width: this.width,
      height: this.height,
      fill: '#3cbebf',
      stroke: this.strokeColor,
      hitStrokeWidth: 0,
      strokeWidth: 26,
      visible:
        this.elementVersionIsntKnown && this.elementVersionIsSelected
          ? true
          : false,
      // opacity: 0.5,
      listening: false,
      strokeScaleEnabled: false,
      transformsEnabled: 'position',
      offset: {
        x: this.width / 2,
        y: this.height / 2,
      },
    });

    const knownElementVersionSelectedNode = new Konva.RegularPolygon({
      radius: this.width / 2,
      fill: this.fillColor,
      stroke: this.strokeColor,
      hitStrokeWidth: 0,
      sides: 8,
      rotation: 22.5,
      strokeWidth: 26,
      visible:
        this.elementVersionIsKnown && this.elementVersionIsSelected
          ? true
          : false,
      listening: false,
      strokeScaleEnabled: false,
    });

    const elementVersionBackgroundNode = new Konva.Rect({
      width: this.width,
      height: this.height,
      fill: this.fillColor,
      stroke: this.strokeColor,
      hitStrokeWidth: 0,
      strokeWidth: 7,
      transformsEnabled: 'position',
      visible: this.elementVersionIsntKnown,
      offset: {
        x: this.width / 2,
        y: this.height / 2,
      },
    });

    const knownElementVersionBackgroundNode = new Konva.RegularPolygon({
      radius: this.width / 2,
      fill: this.fillColor,
      stroke: this.strokeColor,
      sides: 8,
      rotation: 22.5,
      strokeWidth: 7,
      visible: this.elementVersionIsKnown,
    });

    const methodNode = new Konva.Group({
      visible: this.showMethod,
      y: this.methodTransform.y,
      x: this.methodTransform.x,
      listening: true,
      rotation: this.methodTransform.rotation,
      offset: this.methodTransform.offset,
    });

    const methodBackgroundNode = new Konva.Rect({
      width: this.methodTransform.width,
      height: this.methodTransform.height,
      // fill: this.placeholderFillColor,
      // stroke: this.placeholderStrokeColor,
      fill: '#FFF',
      stroke: this.methodStroke,
      hitStrokeWidth: 0,
      strokeWidth: 3,
      transformsEnabled: 'position',
      listening: true,
      dash: this.placeholderDash,
      dashEnabled: this.methodDashEnabled,
      fillPatternScale: {
        x: 1.25,
        y: 1.25,
      },
    });

    const methodImageNode = new Konva.Image({
      x: 3,
      y: 3,
      width: this.methodTransform.width - 6,
      height: this.methodTransform.height - 6,
      transformsEnabled: 'position',
      listening: false,
      visible: !this._reachedScaleThreshold,
    });

    methodNode.add(methodBackgroundNode);
    methodNode.add(methodImageNode);

    const drawingPlaceholderNode = new Konva.Group({
      visible: this.showDrawingPlaceholder,
      y: this.drawingTransform.y,
      x: this.drawingTransform.x,
      listening: true,
      rotation: this.drawingTransform.rotation,
      offset: this.drawingTransform.offset,
    });

    const drawingPlaceholderBackgroundNode = new Konva.Rect({
      width: this.drawingTransform.width,
      height: this.drawingTransform.height,
      fill: this.placeholderFillColor,
      stroke: this.placeholderStrokeColor,
      hitStrokeWidth: 0,
      strokeWidth: 3,
      transformsEnabled: 'position',
      listening: true,
      dash: this.placeholderDash,
      dashEnabled: true,
      fillPatternScale: {
        x: 1.25,
        y: 1.25,
      },
    });

    const drawingPlaceholderTextNode = new Konva.Text({
      width: this.drawingTransform.width,
      height: this.drawingTransform.height,
      align: 'center',
      verticalAlign: 'middle',
      text: 'Drawing',
      fontSize: 24,
      lineHeight: 1.235,
      fontFamily: this.fontFamily,
      fill: this.placeholderTextColor,
      transformsEnabled: 'position',
      listening: false,
    }).cache();

    drawingPlaceholderNode.add(drawingPlaceholderBackgroundNode);
    drawingPlaceholderNode.add(drawingPlaceholderTextNode);

    const drawingNode = new Konva.Group({
      visible: this.showDrawing,
      y: this.drawingTransform.y,
      x: this.drawingTransform.x,
      listening: true,
      rotation: this.drawingTransform.rotation,
      offset: this.drawingTransform.offset,
    });

    const drawingStrokeNode = new Konva.Rect({
      width: this.drawingTransform.width,
      height: this.drawingTransform.height,
      stroke: '#777',
      hitStrokeWidth: 0,
      strokeWidth: 3,
      transformsEnabled: 'position',
      listening: false,
      visible: true,
    });

    const drawingBackgroundNode = new Konva.Rect({
      width: this.drawingTransform.width,
      height: this.drawingTransform.height,
      hitStrokeWidth: 0,
      fill: '#FFFFFF',
      transformsEnabled: 'position',
      listening: true,
      visible: true,
    });

    const drawingImageNode = new Konva.Image({
      width: this.drawingTransform.width,
      height: this.drawingTransform.height,
      transformsEnabled: 'position',
      listening: false,
      visible: !this._reachedScaleThreshold,
    });

    drawingNode.add(drawingBackgroundNode);
    drawingNode.add(drawingImageNode);
    drawingNode.add(drawingStrokeNode);

    const nameNode = new Konva.Text({
      width: this.textWidth,
      height: 60,
      align: 'center',
      verticalAlign: 'middle',
      ellipsis: true,
      text: this.args.name,
      fontSize: 27,
      fontStyle: '500',
      lineHeight: 1.1,
      fontFamily: this.fontFamily,
      fill: this.textColor,
      transformsEnabled: 'position',
      listening: false,
      y: this.height / 2 - 30,
      offset: {
        y: this.height / 2,
        x: this.textWidth / 2,
      },
    });

    const collapsedNode = new Konva.Circle({
      visible: this.args.isCollapsed,
      x: 15,
      y: 7.5,
      radius: this.radius,
      fill: this.fillColorCollapsed,
      stroke: this.strokeColorLight,
      hitStrokeWidth: 0,
      strokeWidth: 7,
      transformsEnabled: 'position',
    });

    const collapsedTwoNode = new Konva.Circle({
      visible: this.args.isCollapsed,
      x: 30,
      y: 15,
      radius: this.radius,
      fill: this.fillColorCollapsed,
      stroke: this.strokeColorLight,
      hitStrokeWidth: 0,
      strokeWidth: 7,
      transformsEnabled: 'position',
    });

    const collapsedThreeNode = new Konva.Circle({
      visible: this.args.isCollapsed,
      x: 45,
      y: 22.5,
      radius: this.radius,
      fill: this.fillColorCollapsed,
      stroke: this.strokeColorLight,
      hitStrokeWidth: 0,
      strokeWidth: 7,
      transformsEnabled: 'position',
    });

    // TODO opacity kills end-to-end tests
    if (ENV.environment !== 'test') {
      elementVersionSelectedNode.opacity(0.5);
      knownElementVersionSelectedNode.opacity(0.5);
    }

    elementVersionNode.add(elementVersionSelectedNode);
    elementVersionNode.add(knownElementVersionSelectedNode);
    elementVersionNode.add(collapsedThreeNode);
    elementVersionNode.add(collapsedTwoNode);
    elementVersionNode.add(collapsedNode);
    elementVersionNode.add(elementVersionBackgroundNode);
    elementVersionNode.add(knownElementVersionBackgroundNode);
    elementVersionNode.add(nameNode);
    elementVersionNode.add(methodNode);
    elementVersionNode.add(drawingNode);
    elementVersionNode.add(drawingPlaceholderNode);

    // add events
    if (this.args.onClick) {
      elementVersionNode.on('click', () => {
        this.args.onClick(this.args.elementVersionId, this.args.isSelected);
      });
    }

    if (this.args.onContextClick) {
      elementVersionNode.on('contextmenu', (event) => {
        this.args.onContextClick(this.args.elementVersionId, event);
      });
    }

    if (this.args.onDragStart) {
      elementVersionNode.on('dragstart', (event) => {
        if (this.args.actionMode) {
          event.target.stopDrag();
        }
        this.args.onDragStart(event, this.args.elementVersionId);
      });
    }

    if (this.args.onDragMove) {
      elementVersionNode.on('dragmove', (event) => {
        if (this.args.actionMode) {
          return event.target.stopDrag();
        }
        this.args.onDragMove(event, this.args.elementVersionId);
      });
    }

    if (this.args.onDragMove) {
      elementVersionNode.on('dragend', (event) => {
        if (this.args.actionMode) {
          return;
        }
        this.args.onDragEnd(event, this.args.elementVersionId);
      });
    }

    if (this.args.onMouseenter) {
      elementVersionNode.on('mouseenter', (event) =>
        this.args.onMouseenter(event, this.args.elementVersionId)
      );
    }

    if (this.args.onMouseleave) {
      elementVersionNode.on('mouseleave', (event) =>
        this.args.onMouseleave(event, this.args.elementVersionId)
      );
    }

    if (this.args.onDrawingClick) {
      drawingNode.on('click', () =>
        this.args.onDrawingClick(this.elementVersionId, this.referenceId)
      );
      drawingPlaceholderNode.on('click', () =>
        this.args.onDrawingClick(this.elementVersionId, null, null)
      );
    }
    if (this.args.onMethodClick) {
      methodNode.on('click', () =>
        this.args.onMethodClick(this.elementVersionId, this.methodId)
      );
    }

    this.elementsLayer.add(elementVersionNode);

    elementVersionNode.visible(elementVersionNode.isClientRectOnScreen());

    this.methodNode = methodNode;
    this.methodBackgroundNode = methodBackgroundNode;
    this.methodImageNode = methodImageNode;
    this.drawingPlaceholderNode = drawingPlaceholderNode;
    this.drawingPlaceholderTextNode = drawingPlaceholderTextNode;
    this.drawingNode = drawingNode;
    // this.knownElementVersionSelectedNode = knownElementVersionSelectedNode;
    this.drawingBackgroundNode = drawingBackgroundNode;
    // this.knownElementVersionBackgroundNode = knownElementVersionBackgroundNode;
    this.drawingStrokeNode = drawingStrokeNode;
    this.drawingImageNode = drawingImageNode;
    this.nameNode = nameNode;
    this.collapsedNode = collapsedNode;
    this.collapsedTwoNode = collapsedTwoNode;
    this.collapsedThreeNode = collapsedThreeNode;
    this.elementVersionBackgroundNode = elementVersionBackgroundNode;
    this.knownElementVersionBackgroundNode = knownElementVersionBackgroundNode;
    this.elementVersionSelectedNode = elementVersionSelectedNode;
    this.knownElementVersionSelectedNode = knownElementVersionSelectedNode;
    this.elementVersionNode = elementVersionNode;

    if (this.referenceId) {
      this.onUpdateReferenceImage.perform(this.referenceId);
    } else {
      this.onUpdateReferenceImage.perform(null);
    }

    if (this.methodId) {
      this.onUpdateMethodImage.perform(this.methodId);
    }

    // this.onUpdatePlaceholderImages.perform();

    this.onScheduleRender();
  }

  handleSelected() {
    this.elementVersionSelectedNode.visible(
      this.elementVersionIsntKnown && this.elementVersionIsSelected
        ? true
        : false
    );
    this.knownElementVersionSelectedNode.visible(
      this.elementVersionIsKnown && this.elementVersionIsSelected ? true : false
    );
    this.onScheduleRender();
  }

  handleDeselected() {
    this.elementVersionSelectedNode.visible(false);
    this.knownElementVersionSelectedNode.visible(false);
    this.onScheduleRender();
  }

  updateColors() {
    this.elementVersionSelectedNode.stroke(this.strokeColor);
    this.knownElementVersionSelectedNode.stroke(this.strokeColor);
    this.elementVersionBackgroundNode.fill(this.fillColor);
    this.elementVersionBackgroundNode.stroke(this.strokeColor);
    this.knownElementVersionBackgroundNode.fill(this.fillColor);
    this.knownElementVersionBackgroundNode.stroke(this.strokeColor);

    // this.categoryMachineNode.fill(this.elementVersionStrokeColor);
    // this.categoryArticleNode.fill(this.elementVersionStrokeColor);
    // this.categoryProcessNode.fill(this.elementVersionStrokeColor);

    this.collapsedNode.fill(this.fillColorCollapsed);
    this.collapsedNode.stroke(this.strokeColorLight);
    this.collapsedTwoNode.fill(this.fillColorCollapsed);
    this.collapsedTwoNode.stroke(this.strokeColorLight);
    this.collapsedThreeNode.fill(this.fillColorCollapsed);
    this.collapsedThreeNode.stroke(this.strokeColorLight);
  }

  @task
  *onUpdateReferenceImage(referenceId) {
    let blobUrl;

    const state = this.redux.getState();

    if (getMarker(state, referenceId)) {
      blobUrl = yield this.referencesCache.getBlobUrl.perform(referenceId);
    }

    if (!blobUrl) {
      return;
    }

    const imageFile = new Image();

    imageFile.onload = () => {
      this.drawingImageNode.image(imageFile);
      this.drawingImageNode.cache();
      if (this.args.isDisconnected) {
        const filters = [Konva.Filters.Grayscale];
        this.drawingImageNode.filters(filters);
      }

      this.onScheduleRender();
    };

    imageFile.src = blobUrl;
  }

  @task
  *onUpdateMethodImage(methodId) {
    let blobUrl;

    const state = this.redux.getState();

    if (methodId && getMethod(state, methodId)) {
      blobUrl = yield this.figuresCache.getBlobUrl(methodId);
    }

    if (!blobUrl) {
      return;
    }

    const imageFile = new Image();

    imageFile.onload = () => {
      this.methodImageNode.image(imageFile);
      this.methodImageNode.cache();

      this.onScheduleRender();
    };

    imageFile.src = blobUrl;
  }

  @action
  updateVisibility() {
    this.elementVersionNode.visible(
      this.elementVersionNode.isClientRectOnScreen()
    );
  }

  @action
  onUpdate(
    elem,
    [
      isDisconnected,
      isCollapsed,
      visibleAreaIndex,
      elementVersionIsSelected,
      x,
      y,
      reachedScaleThreshold,
      solutionsString,
      hasReference,
      referenceUpdatedAt,
      name,
      showDrawing,
      showDrawingPlaceholder,
      showMethod,
      methodUpdatedAt,
      known,
      elementVersionCategory,
      isCreatingFrom,
      isCreatingTo,
    ]
  ) {
    let detailsChanged = false;
    let visibleAreaChanged = false;

    if (this._isDisconnected !== isDisconnected) {
      this._isDisconnected = isDisconnected;
      this.updateColors();
      const filters = isDisconnected ? [Konva.Filters.Grayscale] : [];
      this.drawingImageNode.filters(filters);
      detailsChanged = true;
    }

    if (this._isCollapsed !== isCollapsed) {
      this._isCollapsed = isCollapsed;
      this.collapsedNode.visible(isCollapsed);
      this.collapsedTwoNode.visible(isCollapsed);
      this.collapsedThreeNode.visible(isCollapsed);
      this.updateColors();
    }

    if (this._visibleAreaIndex !== visibleAreaIndex) {
      this._visibleAreaIndex = visibleAreaIndex;
      visibleAreaChanged = true;
    }

    if (visibleAreaChanged) {
      this.updateVisibility();
    }

    if (this._elementVersionIsSelected !== elementVersionIsSelected) {
      this._elementVersionIsSelected = elementVersionIsSelected;
      if (elementVersionIsSelected) {
        this.handleSelected();
      } else {
        this.handleDeselected();
      }
    }

    if (this._solutionsString !== solutionsString) {
      this._solutionsString = solutionsString;
      this.onScheduleRender();
    }

    if (this._hasReference !== hasReference) {
      this._hasReference = hasReference;
      if (this.referenceId) {
        this.onUpdateReferenceImage.perform(
          this.referenceId
        );
      } else {
        this.onUpdateReferenceImage.perform(null);
      }
    }

    if (this._referenceUpdatedAt !== referenceUpdatedAt) {
      this._referenceUpdatedAt = referenceUpdatedAt;
      if (this.referenceId) {
        this.onUpdateReferenceImage.perform(
          this.referenceId
        );
      } else {
        this.onUpdateReferenceImage.perform(null);
      }
    }

    if (this._name !== name) {
      detailsChanged = true;
      this._name = name;
      this.nameNode.text(name);
    }

    if (this._showDrawing !== showDrawing) {
      this._showDrawing = showDrawing;
      this.drawingNode.visible(this.showDrawing);
      this.onScheduleRender();
    }

    if (this._showDrawingPlaceholder !== showDrawingPlaceholder) {
      this._showDrawingPlaceholder = showDrawingPlaceholder;
      this.drawingPlaceholderNode.visible(this.showDrawingPlaceholder);
      this.onScheduleRender();
    }

    if (this._showMethod !== showMethod) {
      this._showMethod = showMethod;
      this.methodNode.visible(this.showMethod);
      this.onScheduleRender();
    }

    if (this._methodUpdatedAt !== methodUpdatedAt) {
      this._methodUpdatedAt = methodUpdatedAt;
      this.methodBackgroundNode.dashEnabled(this.methodDashEnabled);
      this.drawingNode.rotation(this.drawingTransform.rotation);
      this.drawingNode.offset(this.drawingTransform.offset);
      this.drawingPlaceholderNode.rotation(this.drawingTransform.rotation);
      this.drawingPlaceholderNode.offset(this.drawingTransform.offset);

      if (this.methodId) {
        this.onUpdateMethodImage.perform(this.methodId);
      }
      this.onScheduleRender();
    }

    if (this._known !== known) {
      this._known = known;
      detailsChanged = true;
      this.elementVersionBackgroundNode.visible(this.elementVersionIsntKnown);
      this.knownElementVersionBackgroundNode.visible(
        this.elementVersionIsKnown
      );
      this.elementVersionSelectedNode.visible(
        this.elementVersionIsntKnown && this.elementVersionIsSelected
          ? true
          : false
      );
      this.knownElementVersionSelectedNode.visible(
        this.elementVersionIsKnown && this.elementVersionIsSelected
          ? true
          : false
      );
    }

    if (this._elementVersionCategory !== elementVersionCategory) {
      this._elementVersionCategory = elementVersionCategory;
      if (this.referenceId) {
        this.onUpdateReferenceImage.perform(
          this.referenceId
        );
      }
      this.updateColors();
    }

    if (this._isCreatingFrom !== isCreatingFrom) {
      this._isCreatingFrom = isCreatingFrom;
      if (isCreatingFrom) {
        this.elementVersionSelectedNode.visible(this.elementVersionIsntKnown);
        this.knownElementVersionSelectedNode.visible(
          this.elementVersionIsKnown
        );
        this.onScheduleRender();
      } else {
        if (!this._isCreatingFrom && !this._elementVersionIsSelected) {
          this.elementVersionSelectedNode.visible(false);
          this.knownElementVersionSelectedNode.visible(false);
          this.onScheduleRender();
        }
      }
    }

    if (this._isCreatingTo !== isCreatingTo) {
      this._isCreatingTo = isCreatingTo;
      if (isCreatingTo) {
        this.elementVersionSelectedNode.visible(this.elementVersionIsntKnown);
        this.knownElementVersionSelectedNode.visible(
          this.elementVersionIsKnown
        );
        this.onScheduleRender();
      } else {
        if (!this._isCreatingTo && !this._elementVersionIsSelected) {
          this.elementVersionSelectedNode.visible(false);
          this.knownElementVersionSelectedNode.visible(false);
          this.onScheduleRender();
        }
      }
    }

    if (this._x !== x || this._y !== y) {
      this._x = x;
      this._y = y;
      this.elementVersionNode.x(x);
      this.elementVersionNode.y(y);
      this.onScheduleRender();
    }

    if (this._reachedScaleThreshold !== reachedScaleThreshold) {
      this._reachedScaleThreshold = reachedScaleThreshold;
      detailsChanged = true;
      if (reachedScaleThreshold) {
        this.drawingImageNode.visible(false);
        this.methodImageNode.visible(false);
        this.drawingPlaceholderTextNode.visible(false);
        // this.categoryMachineNode.visible(false);
        // this.categoryArticleNode.visible(false);
        // this.categoryProcessNode.visible(false);
      } else {
        this.drawingImageNode.visible(true);
        this.methodImageNode.visible(true);
        this.drawingPlaceholderTextNode.visible(true);
        // this.categoryMachineNode.visible(
        //   this.elementVersionCategory === 'machine'
        // );
        // this.categoryArticleNode.visible(
        //   this.elementVersionCategory === 'article-of-manufacture'
        // );
        // this.categoryProcessNode.visible(
        //   this.elementVersionCategory === 'process'
        // );
      }
    }

    if (detailsChanged) {
      this.onScheduleRender(this.activeLayer);
    }
  }
}

export default connect(
  stateToComputed,
  dispatchToActions
)(InventionGraphElement);
