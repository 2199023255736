import Component from '@glimmer/component';
import podNames from "ember-component-css/pod-names";

export default class UiModalFooter extends Component {

  get styleNamespace() {
    return podNames['ui-modal-footer'];
  }

  get classNames() {
    let classNames = [this.styleNamespace, 'ui-modal-footer'];
    if (this.args.isBorderless) classNames.push('is-borderless');
    return classNames.join(' ');
  }

}