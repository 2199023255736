import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { alias } from '@ember/object/computed';

const PersistGate = Component.extend({
  redux: service(),
  bootstrapped: alias('redux.bootstrapped')
});

export default PersistGate;
