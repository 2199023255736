import { action, computed } from '@ember/object';

import Component from '@glimmer/component';
import { connect } from 'ember-redux';
import { getInventionUi } from '../../../selectors/invention-ui';
import podNames from 'ember-component-css/pod-names';
import { setActiveContextTab } from '../../../actions/invention-ui';

const stateToComputed = function (state) {
  return {
    ui: getInventionUi(state),
  };
};

const dispatchToActions = {};

class LeftContext extends Component {
  get styleNamespace() {
    return podNames['left-context'];
  }

  get classNames() {
    let classNames = [this.styleNamespace];
    return classNames.join(' ');
  }

  @computed('ui.activeContextTab')
  get activeTab() {
    return this.ui && this.ui.activeContextTab;
  }

  @computed('ui.showingExplorer')
  get showingExplorer() {
    return this.ui && this.ui.showingExplorer;
  }

  @action
  setActiveTab(activeTab) {
    this.redux.store.dispatch(setActiveContextTab(activeTab));
  }
}

export default connect(stateToComputed, dispatchToActions)(LeftContext);
