import { action, computed } from '@ember/object';
import { debounce, later, next } from '@ember/runloop';
import {
  deselectEdge,
  deselectElement,
  deselectElementVersion,
  deselectGraph,
  selectEdge,
  selectElement,
  selectElementVersion,
  setShowingElementVersionSelector,
  updateVersionTreeCoordinates,
} from '../../../actions/invention-ui';
import { forEach, keyBy, maxBy, minBy } from 'lodash';
import {
  getActiveFeature,
  getCollapsedDescendantsList,
  getCollapsedNodesList,
  getContextActive,
  getContextWidth,
  getDefaultElementCategory,
  getExplorerWidth,
  getPreviewMode,
  getSelectedChildren,
  getSelectedEdges,
  getSelectedElementVersions,
  getSelectedElements,
  getShowingExplorer,
  getVersionTreeCoordinates
} from '../../../selectors/invention-ui';
import {
  getDisconnectedNodesList,
  getElementsMap,
  getGraphIndex,
} from '../../../selectors/graph';
import { getElement, getElementsList } from '../../../selectors/element';
import {
  getElementVersion,
  getElementVersionsList,
} from '../../../selectors/element-version';
import { getElementVersionsMap, getRootNodeId } from '../../../selectors/graph';
import {
  getPreferredElementVersion,
  getPreferredElementVersionId,
} from '../../../selectors/product';
import { keyResponder, onKey } from 'ember-keyboard';

import Component from '@glimmer/component';
import ENV from '../../../config/environment';
import Konva from 'konva';
import { batchGroupBy } from '../../../utils/redux';
import { connect } from 'ember-redux';
import { getDrawings } from '../../../selectors/drawing';
import { getMethods } from '../../../selectors/method';
import { getPrimaryInstancesList } from '../../../selectors/component';
import { getTargetPoint } from '../../../utils/graph';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { updateNodeCoordinates } from '../../../actions/invention';

const dispatchToActions = {};

const stateToComputed = (state, attrs) => ({
  graphIndex: getGraphIndex(state),
  selectedElements: getSelectedElements(state),
  selectedElementVersions: getSelectedElementVersions(state),
  selectedEdges: getSelectedEdges(state),
  elementsList: getElementsList(state),
  elementVersionsList: getElementVersionsList(state),
  disconnectedNodesList: getDisconnectedNodesList(state),
  elementsTreeMap: getElementsMap(state, attrs.productId),
  solutionsTreeMap: getElementVersionsMap(state, attrs.productId),
  versionTreeCoordinates: getVersionTreeCoordinates(state),
  collapsedNodesList: getCollapsedNodesList(state, attrs.productId),
  collapsedDescendantsList: getCollapsedDescendantsList(state, attrs.productId),
  methods: getMethods(state),
  drawings: getDrawings(state),
  rootNodeId: getRootNodeId(state),
  primaryInstancesList: getPrimaryInstancesList(state),
  activeFeatureId: getActiveFeature(state),
  defaultElementCategory: getDefaultElementCategory(state),
  previewMode: getPreviewMode(state),
  contextActive: getContextActive(state),
  contextWidth: getContextWidth(state),
  showingExplorer: getShowingExplorer(state),
  explorerWidth: getExplorerWidth(state),
});

@keyResponder
class InventionGraphCanvas extends Component {
  @service models;
  @service contextMenu;
  @service applicationState;
  @service testing;
  @service data;
  @service clipboard;
  @service notify;
  @service tracking;
  @service redux;

  container = 'invention-graph-content';
  verticalPadding = 19.5;
  nodePadding = 25;
  tweens = [];
  elementSourceOffset = { x: 150, y: 220 };
  minScale = 0.015;
  maxScale = 1.05;

  @tracked didFirstRender = false;
  @tracked onCanvas = false;
  @tracked creatingElementVersionId = null;
  @tracked creatingElementId = null;
  @tracked hoveringElementId = null;
  @tracked hoveringElementVersionId = null;
  @tracked createMode = false;
  @tracked edgeCreateMode = false;
  @tracked stage;
  @tracked layer;
  @tracked mouseX;
  @tracked mouseY;
  @tracked stageScale;
  @tracked stageX;
  @tracked stageY;
  @tracked stageWidth = 500;
  @tracked stageHeight = 500;
  @tracked visibleAreaIndex = 0;

  willDestroy() {
    this.stage.off('dragend');
    this.stage.destroy();
  }

  get styleNamespace() {
    return podNames['invention-graph-konva'];
  }

  @computed(
    'createMode',
    'edgeCreateMode',
    'styleNamespace',
    'defaultElementCategory'
  )
  get classNames() {
    let classNames = [this.styleNamespace];
    if (this.createMode) {
      classNames.push('is-create-mode');
      classNames.push(this.defaultElementCategory);
    }
    if (this.edgeCreateMode) {
      classNames.push('is-edge-create-mode');
    }
    return classNames.join(' ');
  }

  get isElementsView() {
    return this.args.productId ? true : false;
  }

  get isSolutionsView() {
    return this.args.productId ? false : true;
  }

  get actionMode() {
    return this.applicationState.cmdKeyDown;
  }

  get reachedScaleThreshold() {
    return this.stageScale < 0.1045;
  }

  get creatingNode() {
    const state = this.redux.getState();
    let node;
    if (this.creatingElementVersionId) {
      node = getElementVersion(state, this.creatingElementVersionId);
    }
    if (this.creatingElementId) {
      node = getElement(state, this.creatingElementId);
    }
    return node;
  }

  get creatingCategory() {
    const state = this.redux.getState();
    const type = this.creatingNode && this.creatingNode.type;
    let category;
    if (type === 'element') {
      const elementVersion = getPreferredElementVersion(
        state,
        this.creatingNode.id,
        this.args.productId
      );
      category = elementVersion && elementVersion.category;

      if (this.creatingNode.category === 'product') {
        category = 'product';
      }
    }
    if (type === 'element-version') {
      category = this.creatingNode && this.creatingNode.category;
    }
    return category;
  }

  get creatingNodeIsPrimaryInstance() {
    return (
      this.creatingNode &&
      this.creatingNode.instanceOf &&
      this.creatingNode.component &&
      this.primaryInstancesList.includes(this.creatingNode.id)
    );
  }

  get creatingToNode() {
    const state = this.redux.getState();
    let node;
    if (this.actionMode) {
      if (
        this.hoveringElementVersionId &&
        this.creatingNode &&
        this.hoveringElementVersionId !== this.creatingNode.id
      )
        node = getElementVersion(state, this.hoveringElementVersionId);
      if (
        this.hoveringElementId &&
        this.creatingNode &&
        this.hoveringElementId !== this.creatingNode.id
      )
        node = getElement(state, this.hoveringElementId);
    }
    return node;
  }

  get showingCreateModePointer() {
    let show = false;
    if (this.createMode && this.onCanvas) {
      show = true;
    }
    if (
      this.actionMode &&
      this.onCanvas &&
      (this.hoveringElementId || this.hoveringElementVersionId)
    ) {
      show = true;
    }
    return show;
  }

  get showingCreateModeArrow() {
    return (this.edgeCreateMode || this.actionMode) &&
      (this.creatingElementVersionId || this.creatingElementId) &&
      this.onCanvas
      ? true
      : false;
  }

  @computed('selectedElements.[]')
  get selectedElement() {
    return this.selectedElements.length === 1 && this.selectedElements[0];
  }

  @computed('selectedElementVersions.[]')
  get selectedElementVersion() {
    return (
      this.selectedElementVersions.length === 1 &&
      this.selectedElementVersions[0]
    );
  }

  @computed('selectedElementVersions.[]', 'selectedElements.[]')
  get selectedChildren() {
    const state = this.redux.getState();
    return getSelectedChildren(state);
  }

  // eslint-disable-next-line ember/require-computed-property-dependencies
  @computed(
    'elementsTreeMap',
    'solutionsTreeMap',
    'args.productId',
    'isSolutionsView'
  )
  get nodesMap() {
    return this.isSolutionsView ? this.solutionsTreeMap : this.elementsTreeMap;
  }

  // eslint-disable-next-line ember/require-computed-property-dependencies
  @computed('collapsedNodesList.[]')
  get collapsedNodesString() {
    return this.collapsedNodesList.join(',');
  }

  // eslint-disable-next-line ember/require-computed-property-dependencies
  @computed('collapsedDescendantsList.[]')
  get collapsedDescendantsMap() {
    const collapsedDescendantsMap = {};
    this.collapsedDescendantsList.forEach(
      (id) => (collapsedDescendantsMap[id] = id)
    );
    return collapsedDescendantsMap;
  }

  // eslint-disable-next-line ember/require-computed-property-dependencies
  @computed('elementsList.[]', 'nodesMap', 'collapsedDescendantsList.[]')
  get visibleElementsList() {
    return this.elementsList.filter((id) => {
      const isVisible = !this.collapsedDescendantsList.includes(id);
      return this.nodesMap[id] && isVisible;
    });
  }

  // eslint-disable-next-line ember/require-computed-property-dependencies
  @computed('elementVersionsList.[]', 'nodesMap', 'collapsedDescendantsList.[]')
  get visibleElementVersionsList() {
    return this.elementVersionsList.filter((id) => {
      const isVisible = !this.collapsedDescendantsList.includes(id);
      return this.nodesMap[id] && isVisible;
    });
  }

  // eslint-disable-next-line ember/require-computed-property-dependencies
  @computed('visibleElementsList.[]')
  get elements() {
    const state = this.redux.getState();
    const elements = this.visibleElementsList
      .filter((id) => {
        const element = getElement(state, id);
        return !element.component;
      })
      .map((id) => {
        const element = getElement(state, id);
        const model =
          this.models.find(id) ||
          this.models.findOrCreate(id, 'element', element);
        return model;
      });
    return elements;
  }

  // eslint-disable-next-line ember/require-computed-property-dependencies
  @computed('visibleElementsList.[]')
  get elementInstances() {
    const state = this.redux.getState();
    const instances = this.visibleElementsList
      .filter((id) => {
        const element = getElement(state, id);
        return element.instanceOf;
      })
      .map((id) => {
        const instance = getElement(state, id);
        const instanceOfId = instance.instanceOf;
        const instanceOf = getElement(state, instanceOfId);
        const elementModel =
          this.models.find(id) ||
          this.models.findOrCreate(id, 'element', instance);
        const instanceOfModel =
          this.models.find(instanceOf) ||
          this.models.findOrCreate(instanceOfId, 'element', instanceOf);
        return {
          id,
          element: elementModel,
          instanceOf: instanceOfModel,
        };
      });

    return instances;
  }

  getElement(state, id) {
    const element = getElement(state, id);
    return element;
  }

  @computed('selectedEdges.[]')
  get selectedEdgeIds() {
    return this.selectedEdges.map((edge) => edge.id);
  }

  // TODO: Check this logic when have slept
  // eslint-disable-next-line ember/require-computed-property-dependencies
  @computed('selectedEdges.[]')
  get mappedSelectedEdges() {
    // const state = this.redux.getState();
    return this.selectedEdges.map((edge) => {
      const mappedEdge = {
        ...edge,
      };

      return mappedEdge;
    });
  }

  @computed(
    'selectedElements',
    'selectedElementVersions',
    'selectedEdges',
    'args.productId'
  )
  get selectedItems() {
    let selectedItems = [];
    if (this.args.productId) {
      selectedItems = [...this.selectedElements, ...this.selectedEdges];
    } else {
      selectedItems = [
        ...this.selectedElements,
        ...this.selectedElementVersions,
        ...this.selectedEdges,
      ];
    }

    return selectedItems;
  }

  @computed('rootNodeId', 'selectedItems.[]')
  get removableItems() {
    return this.selectedItems.filter((id) => id !== this.rootNodeId);
  }

  // eslint-disable-next-line ember/require-computed-property-dependencies
  @computed('visibleElementVersionsList.[]')
  get elementVersions() {
    const state = this.redux.getState();
    return this.visibleElementVersionsList.map((id) => {
      const elementVersion = getElementVersion(state, id);
      return (
        this.models.find(id) ||
        this.models.findOrCreate(id, 'element-version', elementVersion)
      );
    });
  }

  @computed(
    'elementsViewEdges.[]',
    'solutionsViewEdges.[]',
    'isSolutionsView',
    'args.{productId,undoIndex}'
  )
  get edges() {
    return this.isSolutionsView
      ? this.solutionsViewEdges
      : this.elementsViewEdges;
  }

  getEdge(source, target, type, featureId) {
    return {
      id: `${source.id}_${target.id}`,
      type,
      featureId,
      source: source.id,
      sourceType: source.type,
      sourceX: source.x,
      sourceY: source.y,
      target: target.id,
      targetType: target.type,
      targetCategory: target.category,
      targetX: target.x,
      targetY: target.y,
    };
  }

  getElementOrVersion(nodeId) {
    const state = this.redux.getState();
    return getElement(state, nodeId) || getElementVersion(state, nodeId);
  }

  // eslint-disable-next-line ember/require-computed-property-dependencies
  @computed(
    'graphIndex',
    'collapsedNodesString',
    'args.undoIndex',
    'stage',
    'didFirstRender'
  )
  get elementsViewEdges() {
    return (
      (this.stage &&
        this.didFirstRender &&
        this.getEdges(this.elementsTreeMap, this.collapsedDescendantsMap)) ||
      []
    );
  }

  // eslint-disable-next-line ember/require-computed-property-dependencies
  @computed(
    'graphIndex',
    'collapsedNodesString',
    'args.undoIndex',
    'stage',
    'didFirstRender'
  )
  get solutionsViewEdges() {
    return (
      (this.stage &&
        this.didFirstRender &&
        this.getEdges(this.solutionsTreeMap, this.collapsedDescendantsMap)) ||
      []
    );
  }

  getEdges(treeMap, collapsedDescendantsMap) {
    const edges = [];
    forEach(treeMap, (mappedNode) => {
      const comprisesMap = mappedNode.comprisesMap || {};
      const targetsList = mappedNode.children.filter(
        (nodeId) => !collapsedDescendantsMap[nodeId]
      );

      const sourceElementOrVersion = this.getElementOrVersion(mappedNode.id);

      if (sourceElementOrVersion && targetsList) {
        let source = {
          ...this.getElementOrVersion(mappedNode.id),
        };

        // offset source
        if (
          (source.category === 'system' || source.category === 'part') &&
          this.args.productId
        ) {
          const sourceNode = this.stage.findOne(`#${source.id}`);
          if (sourceNode) {
            const elementVersionNode = sourceNode.find(
              '.element-version-node'
            )[0];
            const sourceX = source.x + elementVersionNode.x();
            const sourceY = source.y + elementVersionNode.y();

            source = {
              ...source,
              // y: source.y - this.elementSourceOffset.y,
              x: sourceX,
              y: sourceY,
            };
          }
        }

        targetsList.forEach((targetId) => {
          const target = this.getElementOrVersion(targetId);
          const targetType = target.type;

          const targetPoint = getTargetPoint({
            // shape: targetType === 'element' ? 'circle' : 'rect',
            shape: 'rect',
            sourceX: source.x,
            sourceY: source.y,
            targetX: target.x,
            targetY: target.y,
            targetWidth: targetType === 'element' ? 400 : 300,
            targetHeight: targetType === 'element' ? 400 : 300,
            targetRadius: 200,
            padding: this.nodePadding,
            snapTop: true,
          });

          const _target = {
            ...target,
            x: targetPoint.x,
            y: targetPoint.y,
          };

          const featureId = comprisesMap[targetId]
            ? comprisesMap[targetId].featureId
            : null;

          // const _source = {
          //   ...source,
          //   x: source.x - 150,
          //   y: source.y + 200,
          // };

          const edge =
            target.type === 'element'
              ? this.getEdge(source, _target, 'comprises', featureId)
              : this.getEdge(source, _target, 'version', featureId);

          edges.push(edge);
        });
      }
    });

    return edges;
  }

  fitStageToContainer() {
    const stage = this.stage;
    const container = document.getElementById(this.container);
    const width = container.offsetWidth;
    const height = container.offsetHeight;
    stage.width(width);
    stage.height(height);
    this.stageWidth = width;
    this.stageHeight = height;
    stage.batchDraw();
  }

  deselectAll() {
    this.redux.store.dispatch(deselectGraph());
    this.tracking.trackEvent('graph_deselected_all');
  }

  createElement(elementVersionId, x, y) {
    this.tracking.trackEvent('graph_created_attached_system');
    this.args.onCreateElement(elementVersionId, x, y, true, true);
  }

  createElementVersion(elementId, x, y) {
    this.tracking.trackEvent('graph_created_attached_solution');
    this.args.onCreateElementVersion(elementId, x, y, true);
  }

  createParentlessElement(x, y) {
    this.tracking.trackEvent('graph_created_parentless_system');
    this.args.onCreateParentlessElement(x, y, this.defaultElementCategory);
  }

  @onKey('Escape', { event: 'keydown' })
  handleEscDown(keyboardEvent) {
    keyboardEvent.preventDefault();
    this.leaveCreateMode();
  }

  handleStageDragStart() {
    this.contextMenu.close();
  }

  handleStageDragEnd() {
    this.tracking.trackEvent('graph_panned');
    debounce(this, this.updateTreeCoordinates, 500);
  }

  handleStageDragMove() {
    this.stageX = this.stage.x();
    this.stageY = this.stage.y();
  }

  handleWheel(e) {
    e.evt.preventDefault();
    this.contextMenu.close();
    const stage = e.target.getStage();
    const oldScale = stage.scaleX();
    const mousePointTo = {
      x: stage.getPointerPosition().x / oldScale - stage.x() / oldScale,
      y: stage.getPointerPosition().y / oldScale - stage.y() / oldScale,
    };
    const deltaY = e.evt.deltaY;
    const scaleBy = 1.17;
    let newScale = deltaY < 0 ? oldScale * scaleBy : oldScale / scaleBy;

    // contstrain
    if (newScale < this.minScale) newScale = this.minScale;
    if (newScale > this.maxScale) newScale = this.maxScale;

    const newPos = {
      x: stage.getPointerPosition().x - mousePointTo.x * newScale,
      y: stage.getPointerPosition().y - mousePointTo.y * newScale,
    };
    stage.scale({ x: newScale, y: newScale });
    stage.position(newPos);
    this.stageScale = newScale;
    this.stageX = newPos.x;
    this.stageY = newPos.y;
    stage.batchDraw();
    debounce(this, this.updateTreeCoordinates, 500);
  }

  updateTreeCoordinates() {
    const x = this.stage.x();
    const y = this.stage.y();
    const k = this.stage.scaleX();
    this.redux.store.dispatch(updateVersionTreeCoordinates({ x, y, k }));
  }

  handleMouseup(event) {
    if (this.actionMode) {
      if (
        this.hoveringElementId &&
        this.hoveringElementId !== this.creatingElementId
      ) {
        this.createModeClickElement(this.hoveringElementId);
      } else if (
        this.hoveringElementVersionId &&
        this.hoveringElementVersionId !== this.creatingElementVersionId
      ) {
        this.createModeClickElementVersion(this.hoveringElementVersionId);
      } else if (!this.hoveringElementId && !this.hoveringElementVersionId) {
        this.createFromBackground(event);
      }
    }
  }

  handleBackgroundClick(event) {
    if (this.createMode) {
      this.createFromBackground(event);
    } else if (
      this.edgeCreateMode &&
      (this.creatingElementVersionId || this.creatingElementId)
    ) {
      this.createFromBackground(event);
    } else {
      this.deselectAll();
    }
  }

  createFromBackground(event) {
    const state = this.redux.getState();
    const stage = event.target;
    const pointerPos = stage.getPointerPosition && stage.getPointerPosition();

    if (!pointerPos) {
      return;
    }
    const scale = stage.scaleX();
    const x = pointerPos.x / scale - stage.x() / scale;
    const y = pointerPos.y / scale - stage.y() / scale;

    if (this.creatingElementVersionId) {
      this.createElement(this.creatingElementVersionId, x, y);
    } else if (this.creatingElementId) {
      // if creatingElement hasOneVersion
      const creatingElement = this.creatingNode;

      // can't create from a non-primary instance (instance copy)
      if (
        creatingElement.instanceOf &&
        !this.primaryInstancesList.includes(creatingElement.id)
      ) {
        this.notify.warning(
          `You can't create from an instance copy, go to the main instance or set this as the main instance`
        );
        return this.leaveCreateMode();
      }

      if (this.args.productId) {
        const isElementsView = this.isElementsView;

        if (isElementsView) {
          const preferredElementVersionId = getPreferredElementVersionId(
            state,
            creatingElement.id,
            this.args.productId
          );

          if (preferredElementVersionId) {
            this.createElement(preferredElementVersionId, x, y);
          }
        } else {
          this.createElementVersion(this.creatingElementId, x, y);
        }
      }

      if (!this.args.productId) {
        const isElementsView = this.isElementsView;
        const hasOneVersion = creatingElement.elementVersionsList.length === 1;
        const hasMultipleVersions =
          creatingElement.elementVersionsList.length > 1;

        if (isElementsView && hasOneVersion) {
          this.createElement(creatingElement.elementVersionsList[0], x, y);
        } else if (isElementsView && hasMultipleVersions) {
          this.createElementVersion(this.creatingElementId, x, y);
        } else {
          this.createElementVersion(this.creatingElementId, x, y);
        }
      }
    } else {
      this.createParentlessElement(x, y);
    }
    this.leaveCreateMode();
  }

  @action
  setup() {
    let { x, y, k } = this.versionTreeCoordinates;

    if (!x) x = 0;
    if (!y) y = 0;
    if (!k) k = 1;

    const stage = new Konva.Stage({
      container: this.container,
      width: this.stageWidth,
      height: this.stageHeight,
      x,
      y,
      scale: {
        x: k,
        y: k,
      },
      draggable: true,
    });

    this.stageScale = k;
    this.stageX = x;
    this.stageY = y;

    stage.on('dragstart', () => this.handleStageDragStart());
    stage.on('dragend', () => this.handleStageDragEnd());
    stage.on('dragmove', () => this.handleStageDragMove());

    // create up to 5 layers for better performance

    // all edge nodes
    const edgesLayer = new Konva.Layer({ name: 'edges' });
    // all element version nodes
    // const elementVersionsLayer = new Konva.Layer();
    // all element nodes
    const elementsLayer = new Konva.Layer({ name: 'elements' });
    // selected and dragging nodes
    const interactiveLayer = new Konva.Layer({ name: 'interactive' });

    // add the layers to the stage
    // stage.add(elementVersionsLayer);
    stage.add(edgesLayer);
    stage.add(elementsLayer);
    stage.add(interactiveLayer);

    // add stage listeners
    stage.on('wheel', (event) => this.handleWheel(event));
    stage.on('mouseenter', () => (this.onCanvas = true));
    stage.on('mouseleave', () => (this.onCanvas = false));
    stage.on('mousemove', (event) => this.handleMouseMove(event));
    stage.on('click', (event) => {
      if (event.target === stage) this.handleBackgroundClick(event);
    });
    stage.on('mouseup', (event) => this.handleMouseup(event));

    stage.on('contextmenu', (e) => {
      // prevent default behavior
      e.evt.preventDefault();
      if (e.target !== stage) {
        // if we are not on empty place of the stage we will do nothing
        return;
      }
      const { clientX, clientY } = e.evt;
      const pointerPos = stage.getPointerPosition();
      const scale = stage.scaleX();
      const stageX = pointerPos.x / scale - stage.x() / scale;
      const stageY = pointerPos.y / scale - stage.y() / scale;
      this.contextMenu.open('stage', {
        targetId: null,
        x: clientX,
        y: clientY,
        stageX,
        stageY,
      });
    });

    this.edgesLayer = edgesLayer;
    this.elementsLayer = elementsLayer;
    // this.elementVersionsLayer = elementVersionsLayer;
    this.interactiveLayer = interactiveLayer;
    this.stage = stage;

    if (ENV.environment === 'test') {
      this.testing.konvaStage = this.stage;
    }

    // fit the canvas to the container
    this.fitStageToContainer();
    this.centerGraph();
  }

  centerGraph() {
    const { x, y, k } = this.versionTreeCoordinates;
    if (x === 0 && y === 0 && k === 0) {
      this.fitToScreen();
      // this.fitToNode(null, false);
    }
  }

  incrementVisibleAreaIndex() {
    this.visibleAreaIndex++;
  }

  @action
  onVisibleAreaChanged() {
    debounce(this, this.incrementVisibleAreaIndex, 10);
  }

  handleMouseMove(event) {
    this.mouseX = event.evt.layerX;
    this.mouseY = event.evt.layerY;
    if (this.showingCreateModeArrow) {
      this.updateCreateArrowCoords();
    }
  }

  updateCreateArrowCoords() {
    const sourceNodeId =
      this.creatingElementId || this.creatingElementVersionId;
    if (sourceNodeId) {
      const edgeNode = this.edgesLayer.findOne('#create-mode-arrow');
      const sourceNode = this.stage.findOne(`#${sourceNodeId}`);
      const sourceCategory = sourceNode.getAttr('category');
      const pointerPos = this.stage.getPointerPosition();
      const scale = this.stage.scaleX();

      let sourceX = sourceNode.x();
      let sourceY = sourceNode.y();

      // offset source
      if (
        (sourceCategory === 'system' || sourceCategory === 'part') &&
        this.args.productId
      ) {
        const elementVersionNode = sourceNode.find('.element-version-node')[0];
        sourceX = sourceX + elementVersionNode.x();
        sourceY = sourceY + elementVersionNode.y();
      }

      const x = pointerPos.x / scale - this.stage.x() / scale;
      const y = pointerPos.y / scale - this.stage.y() / scale;
      edgeNode.points([sourceX, sourceY, x, y]);
      this.edgesLayer.batchDraw();
    }
  }

  selectElement(_elementId, isSelected, isRightClick) {
    batchGroupBy.start();
    if (isSelected && !isRightClick) {
      if (this.applicationState.shiftKeyDown) {
        this.redux.store.dispatch(deselectElement(_elementId));
        this.tracking.trackEvent('graph_deselected_system');
      } else {
        this.redux.store.dispatch(deselectElement(_elementId));
        // this.redux.store.dispatch(
        //   selectElement(_elementId, this.applicationState.shiftKeyDown)
        // );

        // this.tracking.trackEvent('graph_multiselected_system');
      }
      this.data.setActiveFeature(null);
    } else if (isSelected && isRightClick) {
      // do nothing
    } else {
      this.redux.store.dispatch(
        selectElement(_elementId, this.applicationState.shiftKeyDown)
      );
      this.data.setActiveFeature(null);
      if (this.rootNodeId === _elementId) {
        this.tracking.trackEvent('graph_selected_product_node');
      } else {
        this.tracking.trackEvent('graph_selected_node');
      }
    }
    batchGroupBy.end();
  }

  selectElementVersionPlaceholder(_elementId) {
    batchGroupBy.start();
    this.redux.store.dispatch(
      selectElement(_elementId, this.applicationState.shiftKeyDown)
    );
    this.redux.store.dispatch(setShowingElementVersionSelector(true));
    this.data.setActiveFeature(null);
    this.tracking.trackEvent('graph_clicked_solution_placeholder');
    batchGroupBy.end();
  }

  selectElementVersion(elementVersionId, isSelected) {
    if (isSelected) {
      if (this.applicationState.shiftKeyDown) {
        this.redux.store.dispatch(deselectElementVersion(elementVersionId));
        this.tracking.trackEvent('graph_deselected_solution');
      } else {
        // dispatch = deselectGraph();
      }
    } else {
      this.redux.store.dispatch(
        selectElementVersion(
          elementVersionId,
          this.applicationState.shiftKeyDown
        )
      );
      this.tracking.trackEvent('graph_selected_solution');
    }
  }

  selectElementVersionReference(elementId, elementVersionId, isSelected) {
    batchGroupBy.start();
    if (isSelected) {
      // do nothing
    } else {
      this.redux.store.dispatch(
        selectElementVersion(
          elementVersionId,
          this.applicationState.shiftKeyDown
        )
      );
    }
    batchGroupBy.end();
  }

  selectEdge(edgeId, isSelected) {
    let dispatch;
    const _edge = this.edges.find((edge) => edge.id === edgeId);

    if (!_edge) return;

    const edge = {
      id: edgeId,
      type: _edge.type,
      source: _edge.source,
      sourceType: _edge.sourceType,
      target: _edge.target,
      targetType: _edge.targetType,
      featureId: _edge.featureId,
    };

    if (isSelected) {
      if (this.applicationState.shiftKeyDown) {
        dispatch = deselectEdge(edge);
        this.tracking.trackEvent('graph_deselected_edge');
      } else {
        dispatch = deselectGraph();
      }
    } else {
      dispatch = selectEdge(edge, this.applicationState.shiftKeyDown);
      this.tracking.trackEvent('graph_selected_edge');
    }
    this.redux.store.dispatch(dispatch);
  }

  createModeClickElement(elementId) {
    const state = this.redux.getState();
    this.tracking.trackEvent('graph_create_mode_clicked_system');
    if (this.creatingElementVersionId) {
      this.createComprisesRelationship(
        this.creatingElementVersionId,
        elementId
      );
      this.tracking.trackEvent('graph_created_comprises_relationship');
    } else if (
      this.creatingElementId &&
      this.creatingElementId !== elementId &&
      this.creatingNode
    ) {
      const creatingElement = this.creatingNode;

      // can't create from a non-primary instance (instance copy)
      if (
        creatingElement.instanceOf &&
        !this.primaryInstancesList.includes(creatingElement.id)
      ) {
        this.notify.warning(
          `You can't create from an instance copy, go to the main instance or set this as the main instance`
        );

        this.tracking.trackEvent('graph_attempted_to_create_from_instance');
        return this.leaveCreateMode();
      }

      if (this.args.productId) {
        const preferredElementVersionId = getPreferredElementVersionId(
          state,
          creatingElement.id,
          this.args.productId
        );
        if (preferredElementVersionId) {
          this.createComprisesRelationship(
            preferredElementVersionId,
            elementId
          );
          this.tracking.trackEvent('graph_created_comprises_relationship');
        }
      }
    } else {
      this.creatingElementId = elementId;
      this.updateCreateArrowCoords();
    }
  }

  createModeClickElementVersion(elementVersionId) {
    this.tracking.trackEvent('graph_create_mode_clicked_solution');
    if (this.creatingElementId) {
      this.createVersionRelationship(this.creatingElementId, elementVersionId);
      this.tracking.trackEvent('graph_created_solution_relationship');
    } else {
      this.creatingElementVersionId = elementVersionId;
      this.updateCreateArrowCoords();
    }
  }

  createComprisesRelationship(sourceElementVersionId, targetElementId) {
    const state = this.redux.getState();
    const targetElement = getElement(state, targetElementId);
    const isParentless = !targetElement.elementVersion;
    if (isParentless) {
      this.args.onAddComprisesRelationship(
        sourceElementVersionId,
        targetElementId
      );
    } else {
      this.args.onSwitchComprisesRelationship(
        sourceElementVersionId,
        targetElementId
      );
    }

    this.leaveCreateMode();
  }

  createVersionRelationship(sourceElementId, targetElementVersionId) {
    const state = this.redux.getState();
    const targetElementVersion = getElementVersion(
      state,
      targetElementVersionId
    );
    const isParentless = !targetElementVersion.element;
    if (isParentless) {
      this.args.onAddVersionRelationship(
        sourceElementId,
        targetElementVersionId
      );
    } else {
      this.args.onSwitchVersionRelationship(
        sourceElementId,
        targetElementVersionId
      );
    }
    this.leaveCreateMode();
  }

  scheduleRender(layer) {
    if (layer) {
      layer.batchDraw();
    } else {
      this.edgesLayer.batchDraw();
      this.elementsLayer.batchDraw();
      this.interactiveLayer.batchDraw();
    }

    if (!this.didFirstRender) {
      this.didFirstRender = true;
    }
  }

  updateNodeCoordinates() {
    let state = this.redux.getState();
    const stage = this.stage;
    const elementsMap = keyBy(this.elementsList);
    const elements = [];
    const elementVersions = [];
    const visibleNodesList = [
      ...this.visibleElementsList,
      ...this.visibleElementVersionsList,
    ];

    let nodes = visibleNodesList
      .filter((id) => stage.findOne(`#${id}`))
      .map((id) => stage.findOne(`#${id}`));

    nodes.forEach((node) => {
      const id = node.id();
      const type = elementsMap[id] ? 'element' : 'element-version';
      const x = node.x();
      const y = node.y();
      switch (type) {
        case 'element': {
          elements.push({
            id,
            x,
            y,
          });

          const element = getElement(state, id);

          if (
            this.isElementsView &&
            element &&
            element.elementVersionsList.length === 1
          ) {
            elementVersions.push({
              id: element.elementVersionsList[0],
              x,
              y,
            });
          }

          break;
        }
        case 'element-version': {
          elementVersions.push({
            id,
            x,
            y,
          });
          break;
        }
      }
    });

    batchGroupBy.start();
    this.redux.store.dispatch(updateNodeCoordinates(elements, elementVersions));
    this.data.updateGraph();
    batchGroupBy.end();
  }

  updateEdges() {
    this.edgesLayer.find('.edge').forEach((edgeNode) => {
      const sourceNodeId = edgeNode.id().split('_')[0];
      const targetNodeId = edgeNode.id().split('_')[1];
      const sourceNode = this.stage.findOne(`#${sourceNodeId}`);
      const targetNode = this.stage.findOne(`#${targetNodeId}`);

      if (sourceNode && targetNode) {
        const sourceCategory = sourceNode.getAttr('category');
        let sourceX = sourceNode.x();
        let sourceY = sourceNode.y();

        // offset source
        if (
          (sourceCategory === 'system' || sourceCategory === 'part') &&
          this.args.productId
        ) {
          const elementVersionNode = sourceNode.find(
            '.element-version-node'
          )[0];
          sourceX = sourceX + elementVersionNode.x();
          sourceY = sourceY + elementVersionNode.y();
        }
        const targetType = targetNode.getAttr('nodeType');

        const targetPoint = getTargetPoint({
          // shape: targetType === 'element' ? 'circle' : 'rect',
          shape: 'rect',
          sourceX: sourceX,
          sourceY: sourceY,
          targetX: targetNode.x(),
          targetY: targetNode.y(),
          targetWidth: targetType === 'element' ? 400 : 300,
          targetHeight: targetType === 'element' ? 400 : 300,
          targetRadius: 200,
          padding: this.nodePadding,
          snapTop: true,
        });

        const targetX = targetPoint.x;
        const targetY = targetPoint.y;
        this.updateEdgePoints(edgeNode, sourceX, sourceY, targetX, targetY);
      }
    });

    this.edgesLayer.batchDraw();
  }

  updateEdgePoints(edgeNode, sourceX, sourceY, targetX, targetY) {
    const selectedNode = this.edgesLayer.findOne(`#select-${edgeNode.id()}`);
    const clickNode = this.edgesLayer.findOne(`#click-${edgeNode.id()}`);
    const labelNode = this.edgesLayer.findOne(`#label-${edgeNode.id()}`);
    const labelX = (targetX + sourceX) * 0.5;
    const labelY = (targetY + sourceY) * 0.5;

    labelNode.x(labelX);
    labelNode.y(labelY);
    edgeNode.points([sourceX, sourceY, targetX, targetY]);
    selectedNode.points([sourceX, sourceY, targetX, targetY]);
    clickNode.points([sourceX, sourceY, targetX, targetY]);
    clickNode.points([sourceX, sourceY, targetX, targetY]);
    selectedNode.visible(
      this.selectedEdgeIds.includes(edgeNode.id())
        ? selectedNode.isClientRectOnScreen()
        : false
    );
    edgeNode.visible(edgeNode.isClientRectOnScreen());
    clickNode.visible(clickNode.isClientRectOnScreen());
  }

  moveDraggedEdges(nodeId, x, y) {
    const mappedNode = this.nodesMap[nodeId];

    const incomingEdgeId = mappedNode.parent
      ? `${mappedNode.parent}_${nodeId}`
      : null;

    const outgoingEdgeIds = mappedNode.children.map(
      (childId) => `${nodeId}_${childId}`
    );

    const noEdges = incomingEdgeId === null && outgoingEdgeIds.length === 0;

    if (noEdges) return;

    if (incomingEdgeId) {
      const incomingEdgeNode = this.edgesLayer.findOne(`#${incomingEdgeId}`);
      if (incomingEdgeNode) {
        const points = incomingEdgeNode.points();

        const edgeId = incomingEdgeNode.id();
        const targetId = edgeId.split('_')[1];
        const targetNode = this.stage.findOne(`#${targetId}`);
        const targetType = targetNode.getAttr('nodeType');
        const targetPoint = getTargetPoint({
          // shape: targetType === 'element' ? 'circle' : 'rect',
          shape: 'rect',
          sourceX: points[0],
          sourceY: points[1],
          targetX: x,
          targetY: y,
          targetWidth: targetType === 'element' ? 400 : 300,
          targetHeight: targetType === 'element' ? 400 : 300,
          targetRadius: 200,
          padding: this.nodePadding,
          snapTop: true,
        });

        const targetX = targetPoint.x;
        const targetY = targetPoint.y;

        this.updateEdgePoints(
          incomingEdgeNode,
          points[0],
          points[1],
          targetX,
          targetY
        );
      }
    }

    outgoingEdgeIds.forEach((outgoingEdgeId) => {
      const outgoingEdgeNode = this.edgesLayer.findOne(`#${outgoingEdgeId}`);
      if (outgoingEdgeNode) {
        let sourceX = x;
        let sourceY = y;
        const sourceId = outgoingEdgeId.split('_')[0];
        const sourceNode = this.stage.findOne(`#${sourceId}`);
        const sourceCategory = sourceNode.getAttr('category');
        const targetId = outgoingEdgeId.split('_')[1];
        const targetNode = this.stage.findOne(`#${targetId}`);
        const targetType = targetNode.getAttr('nodeType');

        // offset source
        if (
          (sourceCategory === 'system' || sourceCategory === 'part') &&
          this.args.productId
        ) {
          const elementVersionNode = sourceNode.find(
            '.element-version-node'
          )[0];
          sourceX = sourceX + elementVersionNode.x();
          sourceY = sourceY + elementVersionNode.y();
        }

        const targetPoint = getTargetPoint({
          // shape: targetType === 'element' ? 'circle' : 'rect',
          shape: 'rect',
          sourceX: sourceX,
          sourceY: sourceY,
          targetX: targetNode.x(),
          targetY: targetNode.y(),
          targetWidth: targetType === 'element' ? 400 : 300,
          targetHeight: targetType === 'element' ? 400 : 300,
          targetRadius: 200,
          padding: this.nodePadding,
          snapTop: true,
        });

        const targetX = targetPoint.x;
        const targetY = targetPoint.y;

        this.updateEdgePoints(
          outgoingEdgeNode,
          sourceX,
          sourceY,
          targetX,
          targetY
        );
      }
    });

    this.edgesLayer.batchDraw();
  }

  moveStage(stage, location, scale, animate = true) {
    const { x, y } = location;

    if (animate) {
      const tween = new Konva.Tween({
        duration: 0.35,
        easing: Konva.Easings.EaseInOut,
        node: stage,
        scaleX: (scale && scale.x) || 1,
        scaleY: (scale && scale.y) || 1,
        x,
        y,
        onUpdate: () => {
          this.stageX = stage.x();
          this.stageY = stage.y();
          this.stageScale = stage.scaleX();
        },
      });
      tween.play();
      stage.batchDraw();

      debounce(this, this.updateTreeCoordinates, 350);
    } else {
      stage.scale(scale);
      stage.x(x);
      stage.y(y);
      this.stageX = stage.x();
      this.stageY = stage.y();
      this.stageScale = stage.scaleX();
      this.updateTreeCoordinates;
    }
  }

  @action
  onZoomToNode() {
    const nodeId = this.applicationState.zoomToNode;
    later(() => {
      this.fitToNode(nodeId);
    }, 200);
  }

  @action
  onUndo(/*elem, [undoIndex]*/) {
    next(this, this.updateEdges);
  }

  @action
  onActionMode(/*elem, [actionMode]*/) {
    this.creatingElementId = null;
    this.creatingElementVersionId = null;
  }

  @action
  zoomIn() {
    const stage = this.stage;
    const scaleBy = 1.07;
    const oldScale = stage.scaleX();
    let newScale = scaleBy * oldScale;

    // constrain
    if (newScale > this.maxScale) newScale = this.maxScale;

    stage.scale({ x: newScale, y: newScale });
    stage.batchDraw();
    this.stageScale = newScale;
    this.stageX = stage.x();
    this.stageY = stage.y();
    this.tracking.trackEvent('graph_zoomed_in');
    debounce(this, this.updateTreeCoordinates, 500);
  }

  @action
  zoomOut() {
    const stage = this.stage;
    const scaleBy = 0.93;
    const oldScale = stage.scaleX();
    let newScale = scaleBy * oldScale;

    // contstrain
    if (newScale < this.minScale) newScale = this.minScale;

    stage.scale({ x: newScale, y: newScale });
    this.stageScale = newScale;
    this.stageX = stage.x();
    this.stageY = stage.y();
    stage.batchDraw();
    this.tracking.trackEvent('graph_zoomed_out');
    debounce(this, this.updateTreeCoordinates, 500);
  }

  @action
  removeSelectedItems() {
    const selectedElementVersions = this.args.productId
      ? []
      : this.selectedElementVersions;

    const selectedElements = this.selectedElements.filter(
      (id) => id !== this.rootNodeId
    );

    this.args.onRemoveSelectedTreeItems(
      selectedElements,
      selectedElementVersions,
      this.mappedSelectedEdges
    );

    this.tracking.trackEvent('graph_removed_selected');
  }

  @action
  enterCreateMode() {
    this.creatingElementVersionId = null;
    this.creatingElementId = null;
    this.createMode = true;
    this.edgeCreateMode = false;
    this.tracking.trackEvent('graph_entered_create_mode');
  }

  @action
  leaveCreateMode() {
    this.creatingElementVersionId = null;
    this.creatingElementId = null;
    this.createMode = false;
    this.edgeCreateMode = false;
    this.tracking.trackEvent('graph_left_create_mode');
  }

  @action
  enterEdgeCreateMode() {
    this.creatingElementVersionId = null;
    this.creatingElementId = null;
    this.createMode = false;
    this.edgeCreateMode = true;
    this.tracking.trackEvent('graph_entered_edge_create_mode');
  }

  @action
  leaveEdgeCreateMode() {
    this.creatingElementVersionId = null;
    this.creatingElementId = null;
    this.createMode = false;
    this.edgeCreateMode = false;
    this.tracking.trackEvent('graph_left_edge_create_mode');
  }

  @action
  onElementClick(elementId, isSelected, isRightClick) {
    if (this.createMode) {
      // do nothing
    } else if (this.edgeCreateMode) {
      this.createModeClickElement(elementId);
    } else {
      this.selectElement(elementId, isSelected, isRightClick);
    }
  }

  @action
  onElementContextClick(elementId, e) {
    e.evt.preventDefault();
    e.cancelBubble = true;
    const { clientX, clientY } = e.evt;
    const pointerPos = this.stage.getPointerPosition();
    const scale = this.stage.scaleX();
    const stageX = pointerPos.x / scale - this.stage.x() / scale;
    const stageY = pointerPos.y / scale - this.stage.y() / scale;
    this.contextMenu.open('element', {
      targetId: elementId,
      x: clientX,
      y: clientY,
      stageX,
      stageY,
    });
  }

  @action
  onEdgeClick(edgeId, isSelected) {
    this.selectEdge(edgeId, isSelected);
  }

  @action
  onElementDragStart(event, elementId) {
    if (this.actionMode) {
      if (!this.creatingElementId) {
        this.creatingElementId = elementId;

        this.updateCreateArrowCoords();
      }
    } else {
      // no need to move layers if already selected
      const isSelected = this.selectedElements.includes(elementId);
      if (isSelected) return;

      // move to interactive layer
      const elementNode = event.target;
      elementNode.moveTo(this.interactiveLayer);
      this.elementsLayer.batchDraw();
    }
  }

  @action
  onElementDragMove(event, elementId) {
    const { x, y } = event.target.attrs;
    this.moveDraggedEdges(elementId, x, y);
  }

  @action
  onElementVersionDragStart(event, elementVersionId) {
    if (this.actionMode) {
      if (!this.creatingElementVersionId) {
        this.creatingElementVersionId = elementVersionId;
        this.updateCreateArrowCoords();
      }
    } else {
      // no need to move layers if already selected
      const isSelected =
        this.selectedElementVersions.includes(elementVersionId);
      if (isSelected) return;

      // move to interactive layer
      const elementVersionNode = event.target;
      elementVersionNode.moveTo(this.interactiveLayer);
      this.elementsLayer.batchDraw();
    }
  }

  @action
  onElementDragEnd(event, elementId) {
    const { x, y } = event.target.attrs;

    this.args.onElementDragEnd(elementId, x, y);

    this.creatingElementId = null;

    // no need to move layers if already selected
    const isSelected = this.selectedElements.includes(elementId);
    if (isSelected) return;

    // move back the elements layer
    const elementNode = event.target;
    elementNode.moveTo(this.elementsLayer);
    this.elementsLayer.batchDraw();
    this.interactiveLayer.batchDraw();
  }

  @action
  onElementEnter(event, elementId) {
    this.hoveringElementVersionId = null;
    this.hoveringElementId = elementId;
  }

  @action
  onElementLeave() {
    this.hoveringElementVersionId = null;
    this.hoveringElementId = null;
  }

  @action
  onScheduleRender() {
    debounce(this, this.scheduleRender, 10);
  }

  @action
  onResize() {
    this.fitStageToContainer();
  }

  @action
  fitToNode(nodeId, animate = true) {
    const stage = this.stage;

    const nodes = stage.find('.node');
    const edges = stage.find('.edge');
    const clickEdges = stage.find('.click-edge');
    const selectEdges = stage.find('.select-edge');

    nodes.forEach((node) => node.visible(true));
    edges.forEach((edge) => edge.visible(true));
    clickEdges.forEach((edge) => edge.visible(true));
    selectEdges.forEach((edge) =>
      edge.visible(
        this.selectedEdgeIds.includes(edge.getAttr('edgeId')) ? true : false
      )
    );

    const scale = 0.35;
    const width = stage.width();
    const height = stage.height();
    const originalScale = stage.scaleX();

    let rect = {
      height: 183.6,
      width: 183.6,
      x: -91.8,
      y: -91.8,
    };

    stage.scale({
      x: scale,
      y: scale,
    });

    if (nodeId) {
      const node = stage.findOne(`#${nodeId}`);
      rect = node.getClientRect({ relativeTo: 'stage' });
    }

    const delta = {
      x: width / 2 - (rect.x + rect.width / 2),
      y: height / 2 - (rect.y + rect.height / 2),
    };

    const x = stage.x();
    const y = stage.y();

    const stageCenter = {
      x: x + delta.x,
      y: y + delta.y,
    };

    stage.scale({
      x: originalScale,
      y: originalScale,
    });

    this.moveStage(stage, stageCenter, { x: scale, y: scale }, animate);
  }

  @computed('elements.@each.{x,y}', 'disconnectedNodesList.[]')
  get bounds() {
    const elements = this.elements.filter(
      (elementId) => !this.disconnectedNodesList.includes(elementId)
    );
    const padding = 300;
    const maxX = maxBy(elements, 'x').x + padding;
    const maxY = maxBy(elements, 'y').y + padding;
    const minX = minBy(elements, 'x').x - padding;
    const minY = minBy(elements, 'y').y - padding;
    const width = Math.abs(maxX - minX);
    const height = Math.abs(maxY - minY);
    const x = width / 2;
    const y = height / 2;

    return {
      x,
      y,
      maxX,
      maxY,
      minX,
      minY,
      width,
      height,
    };
  }

  @action
  fitToScreen() {
    const stage = this.stage;
    const contextWidth = this.contextActive ? this.contextWidth : 0;
    const explorerWidth = this.showingExplorer ? this.explorerWidth : 0;
    const padding = 25;

    const bounds = this.bounds;

    const width = stage.width() - explorerWidth - contextWidth;
    const height = stage.height();


    const minScale = 0.4;

    let scale = Math.min(
      width / (bounds.width + padding),
      height / (bounds.height + padding)
    );

    scale = Math.min(minScale, scale);

    stage.scale({
      x: scale,
      y: scale,
    });

    const scaledHeight = bounds.height * scale;

    let x = explorerWidth ? width / 2 + explorerWidth : width / 2;
    x = x + 10; // padding;
    let y = height / 2 - scaledHeight / 2;
    y = y + 10; // padding;

    stage.x(x);
    stage.y(y);

    stage.batchDraw();
    this.stageScale = stage.scaleX();
    this.stageX = stage.x();
    this.stageY = stage.y();

    this.tracking.trackEvent('graph_zoomed_fit');
    debounce(this, this.updateTreeCoordinates, 350);
  }

  // @action
  // fitToScreen() {
  //   // TODO: This f's up when expand and collapse nodes
  //   // it thinks the stage width / height is the same even
  //   // though its not
  //   const stage = this.stage;
  //   const padding = 50;
  //   const nodes = stage.find('.node');
  //   const edges = stage.find('.edge');
  //   const clickEdges = stage.find('.click-edge');
  //   const selectEdges = stage.find('.select-edge');

  //   nodes.forEach((node) => node.visible(true));
  //   edges.forEach((edge) => edge.visible(true));
  //   clickEdges.forEach((edge) => edge.visible(true));
  //   selectEdges.forEach((edge) =>
  //     edge.visible(
  //       this.selectedEdgeIds.includes(edge.getAttr('edgeId')) ? true : false
  //     )
  //   );

  //   const width = stage.width();
  //   const height = stage.height();
  //   const scaleX = stage.scaleX();

  //   let rect = stage.getClientRect();

  //   let scale = Math.min(
  //     width / (rect.width + padding),
  //     height / (rect.height + padding)
  //   );

  //   const stageScale = scaleX * scale;

  //   stage.scale({
  //     x: stageScale,
  //     y: stageScale,
  //   });

  //   rect = stage.getClientRect();

  //   stage.move({
  //     x: width / 2 - (rect.x + rect.width / 2),
  //     y: height / 2 - (rect.y + rect.height / 2),
  //   });

  //   stage.batchDraw();
  //   this.stageScale = stage.scaleX();
  //   this.stageX = stage.x();
  //   this.stageY = stage.y();
  //   debounce(this, this.updateTreeCoordinates, 350);
  // }

  getZoomCoordinates({ box, nodeX, nodeY, screenWidth, screenHeight, scale }) {
    const nw = [nodeX - box.width / 2, nodeY - box.height / 2];
    const se = [nodeX + box.width / 2, nodeY + box.height / 2];
    const bounds = [nw, se];
    const x = (bounds[0][0] + bounds[1][0]) / 2;
    const y = (bounds[0][1] + bounds[1][1]) / 2;
    // const scale = 0.6638653234346579;
    scale = scale || 0.45;
    const translate = [
      screenWidth / 2 - scale * x,
      screenHeight / 2 - scale * y,
    ];

    return {
      scale,
      x: Math.floor(translate[0]),
      y: Math.floor(translate[1]),
    };
  }

  @action
  onArrange() {
    this.arrange();
    this.tracking.trackEvent('graph_arranged_nodes');
  }

  @action
  arrange(includeDisconnectedNodes = true) {
    const stage = this.stage;
    const tweens = this.tweens;
    const coordinatesMap = this.data.getCoordinatesMap(this.args.productId);

    const visibleNodesList = [
      ...this.visibleElementsList,
      ...this.visibleElementVersionsList,
    ];

    // const elementsMap = keyBy(this.visibleElementsList);
    // const elementVersionsMap = keyBy(this.visibleElementVersionsList);

    // select shapes by name
    let nodes = visibleNodesList
      .filter((id) => stage.findOne(`#${id}`))
      .map((id) => stage.findOne(`#${id}`));

    let edges = this.edges
      .filter((edge) => stage.findOne(`#${edge.id}`))
      .map((edge) => stage.findOne(`#${edge.id}`));

    // if there are currently any active tweens, destroy them
    // before creating new ones
    for (var n = 0; n < tweens.length; n++) {
      tweens[n].destroy();
    }

    let maxDistance = 0;

    if (!includeDisconnectedNodes) {
      nodes = nodes.filter((node) => {
        return !this.disconnectedNodesList.includes(node.id());
      });
    }

    nodes.forEach((node) => {
      const nodeId = node.id();
      const coordinates = coordinatesMap[nodeId];
      if (coordinates) {
        const x = coordinates.endX;
        const y = coordinates.endY;
        const startX = node.x();
        const startY = node.y();
        var dx = startX - x;
        var dy = startY - y;
        const distance = Math.sqrt(dx * dx + dy * dy);
        maxDistance = Math.max(distance, maxDistance);
      }
    });

    let duration = 0.65;
    if (maxDistance < 5000) duration = 0.6;
    if (maxDistance < 1000) duration = 0.5;
    if (maxDistance < 750) duration = 0.4;
    if (maxDistance < 500) duration = 0.3;

    const changedNodes = {};

    // apply transition to all nodes in the array
    nodes.forEach((node) => {
      const nodeId = node.id();
      const coordinates = coordinatesMap[nodeId];
      if (coordinates) {
        const x = coordinates.endX;
        const y = coordinates.endY;
        const hasChanged =
          coordinates.endX !== node.x() || coordinates.endY !== node.y();

        if (hasChanged) {
          changedNodes[nodeId] = nodeId;

          tweens.push(
            new Konva.Tween({
              node,
              duration,
              x,
              y,
              onUpdate: () => {
                node.visible(node.isClientRectOnScreen());
              },
              easing: Konva.Easings.EaseOut,
            }).play()
          );
        }
      }
    });

    if (!includeDisconnectedNodes) {
      edges = edges.filter((edge) => {
        const edgeId = edge.id();
        const sourceId = edgeId.split('_')[0];
        const targetId = edgeId.split('_')[1];
        return (
          !this.disconnectedNodesList.includes(sourceId) &&
          !this.disconnectedNodesList.includes(targetId)
        );
      });
    }

    // apply transition to all edges in the array
    edges.forEach((edge) => {
      const edgeId = edge.id();
      const sourceId = edgeId.split('_')[0];
      const targetId = edgeId.split('_')[1];
      const sourceCoordinates = coordinatesMap[sourceId];
      const targetCoordinates = coordinatesMap[targetId];
      const sourceNode = this.stage.findOne(`#${sourceId}`);
      const targetNode = this.stage.findOne(`#${targetId}`);

      if (
        sourceCoordinates &&
        targetCoordinates &&
        sourceNode &&
        targetNode &&
        (changedNodes[sourceId] || changedNodes[targetId])
      ) {
        let sourceX = sourceCoordinates.endX;
        let sourceY = sourceCoordinates.endY;
        const sourceCategory = sourceNode.getAttr('category');
        const targetType = targetNode.getAttr('nodeType');

        // offset source
        if (
          (sourceCategory === 'system' || sourceCategory === 'part') &&
          this.args.productId
        ) {
          const elementVersionNode = sourceNode.find(
            '.element-version-node'
          )[0];
          sourceX = sourceX + elementVersionNode.x();
          sourceY = sourceY + elementVersionNode.y();
        }

        const targetPoint = getTargetPoint({
          // shape: targetType === 'element' ? 'circle' : 'rect',
          shape: 'rect',
          sourceX: sourceX,
          sourceY: sourceY,
          targetX: targetCoordinates.endX,
          targetY: targetCoordinates.endY,
          targetWidth: targetType === 'element' ? 400 : 300,
          targetHeight: targetType === 'element' ? 400 : 300,
          targetRadius: 200,
          padding: this.nodePadding,
          snapTop: true,
        });

        const targetX = targetPoint.x;
        const targetY = targetPoint.y;
        const points = [sourceX, sourceY, targetX, targetY];

        tweens.push(
          new Konva.Tween({
            node: edge,
            duration,
            points,
            onUpdate: () => {
              edge.visible(edge.isClientRectOnScreen());
            },
            easing: Konva.Easings.EaseOut,
          }).play()
        );

        const labelNode = this.edgesLayer.findOne(`#label-${edgeId}`);
        const labelX = (targetX + sourceX) * 0.5;
        const labelY = (targetY + sourceY) * 0.5;

        tweens.push(
          new Konva.Tween({
            node: labelNode,
            duration,
            x: labelX,
            y: labelY,
            easing: Konva.Easings.EaseOut,
          }).play()
        );

        const selectedNode = this.edgesLayer.findOne(`#select-${edgeId}`);

        tweens.push(
          new Konva.Tween({
            node: selectedNode,
            duration,
            points,
            onUpdate: () => {
              selectedNode.visible(
                this.selectedEdgeIds.includes(edge.id())
                  ? selectedNode.isClientRectOnScreen()
                  : false
              );
            },
            easing: Konva.Easings.EaseOut,
          }).play()
        );

        const clickNode = this.edgesLayer.findOne(`#click-${edgeId}`);

        tweens.push(
          new Konva.Tween({
            node: clickNode,
            duration,
            points,
            onUpdate: () => {
              clickNode.visible(clickNode.isClientRectOnScreen());
            },
            easing: Konva.Easings.EaseOut,
          }).play()
        );
      }
    });

    debounce(this, this.updateNodeCoordinates, duration * 1000);
  }

  @action
  onAutoArrange() {
    debounce(this, this.arrange, false, 300, false);
  }
}

export default connect(
  stateToComputed,
  dispatchToActions
)(InventionGraphCanvas);
