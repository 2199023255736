import ENV from '../config/environment';
import Service from '@ember/service';
import { addFromSchema } from '../utils/schema';
import { alias } from '@ember/object/computed';
import { computed } from '@ember/object';
import localforage from 'localforage';
import { inject as service } from '@ember/service';

const dbPrefix = ENV.DB_PREFIX || '';
const dbName =
  ENV.environment === 'test' ? 'patentdive_test_users' : `${dbPrefix}_users`;

const db = localforage.createInstance({
  name: dbName,
});

export default Service.extend({
  sessionManager: service(),
  userId: alias('sessionManager.userId'),
  db,
  data: null,
  inventionsList: computed('data', 'data.inventionsList', function() {
    return this.data ? this.data.inventionsList : null;
  }),
  inventions: computed('inventionsList', function () {
    return [];
  }),

  async addInvention(inventionId) {
    try {
      const data = await this.db.getItem(this.userId);
      const updatedData = {
        ...data,
        inventionsList: [inventionId, ...data.inventionsList],
      };
      await this.db.setItem(this.userId, updatedData);
      this.set('data', updatedData);
    } catch (err) {
      console.error(err);
    }
  },

  async removeInvention(inventionId) {
    const data = await this.db.getItem(this.userId);
    const updatedData = {
      ...data,
      inventionsList: data.inventionsList.filter((id) => id !== inventionId),
    };
    await this.db.setItem(this.userId, updatedData);
    this.set('data', updatedData);
  },

  async setup(userId) {
    let userData = await this.db.getItem(userId);

    if (!userData) {
      userData = addFromSchema('user-db', {
        id: userId,
      });
      await this.db.setItem(userId, userData);
    }

    this.set('data', userData);
  },
});
