import { getPreviewMode, getSelectedTerms } from '../../../selectors/invention-ui';

import Component from '@glimmer/component';
import { action } from '@ember/object';
import { connect } from 'ember-redux';
import { deselectGraph } from '../../../actions/invention-ui';
import { getTermsList } from '../../../selectors/term';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';

const stateToComputed = (state) => ({
  selectedTerms: getSelectedTerms(state),
  previewMode: getPreviewMode(state),
  termsList: getTermsList(state),
});

const dispatchToActions = {};

class TermsContext extends Component {
  @service tracking;

  get styleNamespace() {
    return podNames['terms-context'];
  }

  get classNames() {
    let classNames = [this.styleNamespace, 'terms-context'];
    return classNames.join(' ');
  }

  @action
  addTerm() {
    this.args.onAddTerm();
    this.tracking.trackEvent('explorer_created_term');
  }

  @action
  removeSelectedTerms(termsList) {
    this.args.onRemoveTerms(termsList);
    this.tracking.trackEvent('explorer_removed_term');
  }

  @action
  alphabetizeTerms() {
    if (this.args.onAlphabetizeTerms) {
      this.args.onAlphabetizeTerms();
    }
  }

  @action
  deselectAll() {
    this.redux.store.dispatch(deselectGraph());
  }

  @action
  onSortTerms() {
    this.tracking.trackEvent('explorer_sorted_terms');
  }
}

export default connect(stateToComputed, dispatchToActions)(TermsContext);
