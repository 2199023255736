export default {
  attributes: {
    activeComparisonElement: {
      defaultValue: null,
      type: 'uuid',
    },
    activeContextTab: {
      defaultValue: 'elements',
      type: 'string',
    },
    activeDrawing: {
      defaultValue: null,
      type: 'uuid',
    },
    activeElementVersionListItem: {
      defaultValue: null,
      type: 'uuid',
    },
    activeMethod: {
      defaultValue: null,
      type: 'uuid',
    },
    activeNote: {
      defaultValue: null,
      type: 'uuid',
    },
    activePriorArt: {
      defaultValue: null,
      type: 'uuid',
    },
    activeProduct: {
      defaultValue: null,
      type: 'uuid',
    },
    activeProductChecklistView: {
      defaultValue: 'invention',
      // product, detailed-description, novelty, requirements, glossary, prior-art
      type: 'string',
    },
    activeProductView: {
      defaultValue: 'market',
      // patentability, detailed-description, novelty, requirements, glossary, prior-art
      type: 'string',
    },
    activeReview: {
      defaultValue: null,
      type: 'uuid',
    },
    activeView: {
      defaultValue: 'graph',
      // specification, presentation
      type: 'type',
    },
    collapsedNodes: {
      defaultValue: {},
      type: 'object',
    },
    collapsedProblem: {
      defaultValue: false,
      type: 'boolean',
    },
    collapsedSolution: {
      defaultValue: false,
      type: 'boolean',
    },
    creatingAsset: {
      defaultValue: false,
      type: 'boolean',
    },
    creatingComparison: {
      defaultValue: false,
      type: 'boolean',
    },
    creatingImageAsset: {
      defaultValue: null,
      type: 'uuid',
    },
    creatingNote: {
      defaultValue: false,
      type: 'boolean',
    },
    creatingPriorArt: {
      defaultValue: false,
      type: 'boolean',
    },
    creatingReview: {
      defaultValue: false,
      type: 'boolean',
    },
    defaultElementCategory: {
      defaultValue: 'part',
      // or 'system' or 'product'
      type: 'string',
    },
    defaultMarkerCategory: {
      defaultValue: 'part',
      // or 'system' or 'term'
      type: 'string',
    },
    defaultMethodNodeType: {
      defaultValue: 'custom',
      // or 'element'
      type: 'string',
    },
    editingAsset: {
      defaultValue: null,
      type: 'uuid',
    },
    editingComparison: {
      defaultValue: null,
      type: 'uuid',
    },
    editingReview: {
      defaultValue: null,
      type: 'uuid',
    },
    elementColumnsData: {
      defaultValue: {},
      type: 'object',
    },
    elementColumnsList: {
      defaultValue: [],
      type: 'array',
    },
    elementsEditorActiveView: 'enablement',
    explorerWidth: {
      defaultValue: 340,
      type: 'number',
    },
    figureType: {
      defaultValue: 'drawings',
      type: 'type',
    },
    methodRealizationPreviewMode: {
      defaultValue: false,
      type: 'boolean',
    },
    navigationStack: {
      defaultValue: [],
      type: 'array',
    },
    navigatorActiveContextTab: {
      defaultValue: 'elements',
      type: 'string',
    },
    navigatorReverse: {
      defaultValue: false,
      type: 'boolean',
    },
    navigatorShowingExplorer: {
      defaultValue: false,
      type: 'boolean',
    },
    navigatorWalk: {
      defaultValue: 'breadth',
      type: 'string',
    },
    numberingIndex: {
      defaultValue: 0,
      type: 'number',
    },
    previewMode: {
      defaultValue: false,
      type: 'boolean',
    },
    reviewMode: {
      defaultValue: false,
      type: 'boolean',
    },
    selectedAssets: {
      defaultValue: [],
      type: 'array',
    },
    selectedComparisons: {
      defaultValue: [],
      type: 'array',
    },
    selectedDrawings: {
      defaultValue: [],
      type: 'array',
    },
    selectedEdges: {
      defaultValue: [],
      type: 'array',
    },
    selectedElementNodes: {
      defaultValue: [],
      type: 'array',
    },
    selectedElements: {
      defaultValue: [],
      type: 'array',
    },
    selectedElementVersions: {
      defaultValue: [],
      type: 'array',
    },
    selectedFeatures: {
      defaultValue: [],
      type: 'array',
    },
    selectedHighlights: {
      defaultValue: [],
      type: 'array',
    },
    selectedImages: {
      defaultValue: [],
      type: 'array',
    },
    selectedMarkers: {
      defaultValue: [],
      type: 'array',
    },
    selectedMethodEdgePoints: {
      defaultValue: [],
      type: 'array',
    },
    selectedMethodEdges: {
      defaultValue: [],
      type: 'array',
    },
    selectedMethodNodes: {
      defaultValue: [],
      type: 'array',
    },
    selectedPriorArts: {
      defaultValue: [],
      type: 'array',
    },
    selectedProducts: {
      defaultValue: [],
      type: 'array',
    },
    selectedReviews: {
      defaultValue: [],
      type: 'array',
    },
    selectedSystems: {
      defaultValue: [],
      type: 'array',
    },
    selectedTerms: {
      defaultValue: [],
      type: 'array',
    },
    showingAssets: {
      defaultValue: false,
      type: 'boolean',
    },
    showingComparisonMatrix: {
      defaultValue: false,
      type: 'boolean',
    },
    showingDrawingSelectionModal: {
      defaultValue: false,
      type: 'boolean',
    },
    showingElementVersionSelector: {
      defaultValue: false,
      type: 'boolean',
    },
    showingExplorer: {
      defaultValue: false,
      type: 'boolean',
    },
    showingFigures: {
      defaultValue: false,
      type: 'boolean',
    },
    showingHistoryVersionCreateModal: {
      defaultValue: false,
      type: 'boolean',
    },
    showingImageUploadModal: {
      defaultValue: false,
      type: 'boolean',
    },
    showingNotes: {
      defaultValue: false,
      type: 'boolean',
    },
    showingCustomers: {
      defaultValue: false,
      type: 'boolean',
    },
    showingPatentSpecification: {
      defaultValue: false,
      type: 'boolean',
    },
    showingPresentation: {
      defaultValue: false,
      type: 'boolean',
    },
    showingPriorArts: {
      defaultValue: false,
      type: 'boolean',
    },
    showingProductChecklist: {
      defaultValue: false,
      type: 'boolean',
    },
    showingProducts: {
      defaultValue: false,
      type: 'boolean',
    },
    showingSearch: {
      defaultValue: false,
      type: 'boolean',
    },
    updatingImageAsset: {
      defaultValue: null,
      type: 'uuid',
    },
    updatingPriorArtAsset: {
      defaultValue: null,
      type: 'uuid',
    },
    updatingProductAsset: {
      defaultValue: null,
      type: 'uuid',
    },
    updatingProductCustomers: {
      defaultValue: false,
      type: 'boolean',
    },
    updatingProductPriorArt: {
      defaultValue: false,
      type: 'boolean',
    },
    versionTreeCoordinates: {
      defaultValue: {
        k: 0,
        x: 0,
        y: 0,
      },
      type: 'object',
    },
    visibleElementColumnsList: {
      defaultValue: [],
      type: 'array',
    },
    previewModeShowWarnings: {
      defaultValue: true,
      type: 'boolean',
    },
    previewModeLanguage: {
      defaultValue: 'report', // patent-specification
      type: 'string',
    },
    previewModeNumbering: {
      defaultValue: 'report', // patent-specification
      type: 'string',
    },
    previewModeNavigation: {
      defaultValue: 'breadth', // depth
      type: 'string',
    },
    previewModeShowNovelty: {
      defaultValue: true,
      type: 'boolean',
    },
    showingInventionSummaryEditor: {
      defaultValue: false,
      type: 'boolean',
    },
    showingPatentability: {
      defaultValue: false,
      type: 'boolean',
    },
    activePatentabilitySection: {
      defaultValue: 'conception',
      type: 'boolean',
    },
    showingPatentSpecificationEditor: {
      defaultValue: false,
      type: 'boolean',
    },
    showingPatentSpecificationDetailedDescription: {
      defaultValue: false,
      type: 'boolean',
    },
    showingPatentSpecificationClaims: {
      defaultValue: false,
      type: 'boolean',
    },
    showingPatentSpecificationAbstract: {
      defaultValue: false,
      type: 'boolean',
    },
    activeInventionSummaryEditorSection: {
      defaultValue: 'invention', // preferred-product, patentability
      type: 'string',
    },
    activePatentSpecificationEditorSection: {
      defaultValue: 'title',
      type: 'string',
    },
    showingGetStarted: {
      defaultValue: true,
      type: 'boolean',
    },
  },
};


