import Component from '@glimmer/component';
import { action, computed } from '@ember/object';
import { connect } from 'ember-redux';
import podNames from 'ember-component-css/pod-names';
import { getComparison } from '../../../selectors/comparison';
import { getProduct } from '../../../selectors/product';
import { getPriorArt } from '../../../selectors/prior-art';

const stateToComputed = (state, attrs) => ({
  comparison: getComparison(state, attrs.comparisonId),
});

const dispatchToActions = {};

class ComparisonListItem extends Component {

  get styleNamespace() {
    return podNames['comparison-list-item'];
  }

  get classNames() {
    let classNames = [this.styleNamespace, 'comparison-list-item'];
    if (this.args.isSelected) classNames.push('is-selected');
    return classNames.join(' ');
  }

  // eslint-disable-next-line ember/require-computed-property-dependencies
  @computed('comparison.product')
  get product() {
    const state = this.redux.getState();
    return this.comparison && this.comparison.product && getProduct(state, this.comparison.product);
  }

  // eslint-disable-next-line ember/require-computed-property-dependencies
  @computed('comparison.priorArt')
  get priorArt() {
    const state = this.redux.getState();
    const priorArt = this.comparison && this.comparison.priorArt && getPriorArt(state, this.comparison.priorArt);
    return priorArt;
  }

  @action
  onClick() {
    if (this.args.onClick) this.args.onClick(this.priorArt?.id);
  }
}

export default connect(stateToComputed, dispatchToActions)(ComparisonListItem);
