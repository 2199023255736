import {
  ADD_DRAWING,
  ADD_INVENTION,
  IMPORT_INVENTION,
  REMOVE_DRAWING,
  REMOVE_METHOD,
  UPDATE_INVENTION,
  UPDATE_INVENTION_NAME,
} from '../actions/actionTypes';

import { addFromSchema } from '../utils/schema';

const initialState = addFromSchema('invention');

const inventionReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case ADD_INVENTION: {
      let { inventionId, attributes } = payload;
      const id = inventionId;
      const createdAt = new Date();
      const updatedAt = new Date();
      const invention = addFromSchema('invention', {
        id,
        createdAt,
        updatedAt,
        ...attributes,
      });

      return invention;
    }

    case IMPORT_INVENTION: {
      const { invention } = payload;
      const updatedAt = new Date();
      return {
        ...invention,
        updatedAt,
      };
    }

    case UPDATE_INVENTION: {
      const { attributes } = payload;
      return {
        ...state,
        ...attributes,
      };
    }

    case UPDATE_INVENTION_NAME: {
      const { name } = payload;
      return {
        ...state,
        name,
      };
    }

    case ADD_DRAWING: {
      const { drawingId } = payload;
      return {
        ...state,
        figuresList: [
          ...state.figuresList,
          {
            id: drawingId,
            type: 'drawing',
          },
        ],
      };
    }

    case REMOVE_DRAWING: {
      const { drawingId } = payload;
      return {
        ...state,
        figuresList: state.figuresList.filter(
          (figure) => figure.id !== drawingId
        ),
      };
    }

    case REMOVE_METHOD: {
      const { methodId } = payload;
      return {
        ...state,
        figuresList: state.figuresList.filter(
          (figure) => figure.id !== methodId
        ),
      };
    }

    /* istanbul ignore next */
    default:
      return state;
  }
};
// }, {})

export default inventionReducer;
