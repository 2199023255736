import {
  ADD_ASSET,
  REMOVE_ASSET,
  UPDATE_ASSET,
  SORT_ASSETS_LIST,
} from './actionTypes';
import uuid from 'uuid/v4';

export const addAsset = (attributes = {}, assetId = uuid()) => {
  return {
    type: ADD_ASSET,
    payload: {
      assetId,
      attributes,
    },
  };
};

export const removeAsset = (assetId) => {
  return {
    type: REMOVE_ASSET,
    payload: {
      assetId,
    },
  };
};

export const updateAsset = (assetId, attributes = {}) => {
  return {
    type: UPDATE_ASSET,
    payload: {
      assetId,
      attributes,
    },
  };
};


export const sortAssetsList = (assetIds) => {
  return {
    type: SORT_ASSETS_LIST,
    payload: {
      assetIds,
    },
  };
};