import {
  ADD_COMPONENT,
  REMOVE_COMPONENT,
  SORT_COMPONENTS_LIST,
  UPDATE_COMPONENT,
} from './actionTypes';

import uuid from 'uuid/v4';

export const addComponent= (attributes = {}, componentId = uuid()) => {
  return {
    type: ADD_COMPONENT,
    payload: {
      componentId,
      attributes,
    },
  };
};

export const removeComponent= (componentId) => {
  return {
    type: REMOVE_COMPONENT,
    payload: {
      componentId,
    },
  };
};

export const updateComponent= (componentId, attributes = {}) => {
  return {
    type: UPDATE_COMPONENT,
    payload: {
      componentId,
      attributes,
    },
  };
};



export const sortComparisonsList = (componentIds) => {
  return {
    type: SORT_COMPONENTS_LIST,
    payload: {
      componentIds,
    },
  };
};
