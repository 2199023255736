import Component from '@glimmer/component';
import podNames from 'ember-component-css/pod-names';
import { exists } from '../../../utils/general';

class UiContextSection extends Component {
  constructor() {
    super(...arguments); // super must be called first.
    this.isFlex = exists(this.args.isFlex, true);
    this.isFlexed = this.args.isFlexed || false;
    this.hasPaddingBottom = this.args.hasPaddingBottom || false;
    this.isPaddingless = this.args.isPaddingless || false;
    this.isBorderless = this.args.isBorderless || false;
    this.hasBorderTop = this.args.hasBorderTop || false;
    this.hasPaddingTop = this.args.hasPaddingTop || false;
  }

  get classNames() {
    let classNames = [this.styleNamespace];
    if (this.isFlex) classNames.push('is-flex');
    if (this.isFlexed) classNames.push('is-flexed');
    if (this.hasPaddingBottom) classNames.push('has-padding-bottom');
    if (this.isPaddingless) classNames.push('is-paddingless');
    if (this.isBorderless) classNames.push('is-borderless');
    if (this.hasBorderTop) classNames.push('has-border-top');
    if (this.hasPaddingTop) classNames.push('has-padding-top');
    return classNames.join(' ');
  }

  get styleNamespace() {
    return podNames['ui-context-section'];
  }

}

export default UiContextSection;
