import Component from '@glimmer/component';
import { action } from '@ember/object';
import { connect } from 'ember-redux';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

const stateToComputed = null;

const dispatchToActions = {};

class SearchContext extends Component {
  @service data;
  @service search;

  @tracked query;

  get styleNamespace() {
    return podNames['search-context'];
  }

  get classNames() {
    let classNames = [this.styleNamespace, 'search-context'];
    return classNames.join(' ');
  }

  get systemResults() {
    return this.results.filter(
      (result) => result.modelType === 'element' && result.category === 'system'
    );
  }

  get partResults() {
    return this.results.filter(
      (result) => result.modelType === 'element' && result.category === 'part'
    );
  }

  get termResults() {
    return this.results.filter((result) => result.modelType === 'term');
  }

  get descriptorResults() {
    return this.results.filter((result) => result.modelType === 'feature');
  }

  get results() {
    return this.search.results || [];
  }

  @action
  didInsert() {
    this.search.build();
    if (this.search.query) {
      this.search.update(this.search.query);
      this.query = this.search.query;
    }
  }

  @action
  onChange(query) {
    this.search.update(query);
    this.query = query;
  }

  @action
  onClear() {
    this.search.clear();
    this.query = '';
  }

  @action
  onSelect(modelType, modelId, result) {
    console.log('result', result)
    switch (modelType) {
      case 'term':
        this.args.onSelectTerm(modelId);
        break;
      case 'element':
        this.args.onSelectElement(modelId);
        break;
      case 'feature':
        this.args.onSelectFeature(modelId);
        break;
    }
  }
}

export default connect(stateToComputed, dispatchToActions)(SearchContext);
