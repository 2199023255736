import Component from '@glimmer/component';
import { computed } from '@ember/object';
import { connect } from 'ember-redux';
import { getPresentState } from '../../../utils/redux';
import podNames from 'ember-component-css/pod-names';

const stateToComputed = (state) => ({
  state,
});

class InventionDataViewer extends Component {
  get styleNamespace() {
    return podNames['invention-data-viewer'];
  }

  get classNames() {
    let classNames = [this.styleNamespace, 'invention-data-viewer'];
    return classNames.join(' ');
  }

  @computed('state')
  get stateString() {
    return JSON.stringify(getPresentState(this.state), null, 2);
  }
}

export default connect(stateToComputed)(InventionDataViewer);
