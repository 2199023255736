import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action, computed } from '@ember/object';
import { connect } from 'ember-redux';
import podNames from 'ember-component-css/pod-names';

const stateToComputed = null;

const dispatchToActions = {};

class AssetCreate extends Component {
  @tracked _name;
  @tracked _description;
  @tracked _link;
  @tracked _conceivedAt;
  
  changedAttributes = {};

  get styleNamespace() {
    return podNames['asset-create'];
  }

  @action
  updateAsset(attrs) {
    const attributes = attrs.attributes;
    if (this.args.onUpdate)
      this.args.onUpdate(this.args.assetId, attributes);
  }

  @action
  onNameChange(name) {
    this.changedAttributes['name'] = name;
  }

  @action
  onDescriptionChange(description) {
    this.changedAttributes['description'] = description;
  }

  @action
  onLinkChange(link) {
    this.changedAttributes['link'] = link;
  }

  @action
  onConceivedAtChange(conceivedAt) {
    this.changedAttributes['conceivedAt'] = conceivedAt;
  }

  @computed('asset.type')
  get type() {
    return this.asset && this.asset.type;
  }

  @action
  onSave() {
    this.args.onSave(this.changedAttributes)
  }
}

export default connect(stateToComputed, dispatchToActions)(AssetCreate);
