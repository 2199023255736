import {
  BLACK_AND_WHITE_COLOR,
  BLACK_AND_WHITE_FILL,
  BLACK_AND_WHITE_STROKE,
  ELEMENT_COLLAPSED_NODE_STROKE,
  ELEMENT_NODE_COLOR,
  ELEMENT_NODE_FILL,
  ELEMENT_NODE_GREY_COLOR,
  ELEMENT_NODE_GREY_FILL,
  ELEMENT_NODE_GREY_STROKE,
  ELEMENT_NODE_ROOT_FILL,
  ELEMENT_NODE_ROOT_STROKE,
  ELEMENT_NODE_STROKE,
} from '../../../constants/colors';
import { action, computed } from '@ember/object';

/* eslint-disable ember/use-brace-expansion */
import Component from '@glimmer/component';
import ENV from '../../../config/environment';
import Konva from 'konva';
import { connect } from 'ember-redux';
import { getActiveFeature } from '../../../selectors/invention-ui';
import { getElement } from '../../../selectors/element';
import { getElementVersion } from '../../../selectors/element-version';
import { getPreferredElementVersionId } from '../../../selectors/product';
import { getProduct } from '../../../selectors/product';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';

const dispatchToActions = {};

const stateToComputed = (state, attrs) => ({
  product: getProduct(state, attrs.productId),
  element: getElement(state, attrs.elementId),
  activeFeatureId: getActiveFeature(state),
});

class InventionGraphProduct extends Component {
  @service redux;
  @service models;
  @service store;
  @service settings;

  fontFamily = 'Inter';
  verticalPadding = 19.5;
  horizontalPadding = 19.5;
  borderRadius = 26;
  radius = 200;
  height = 400;
  width = 400;
  _isPossessed = false;
  _isEnabled = false;
  _hasPossessionIssues = false;
  _hasEnablementIssues = false;
  _isCollapsed = false;
  _visibleAreaIndex = 0;
  _elementIsSelected = '';
  _x = 0;
  _y = 0;
  _reachedScaleThreshold = false;

  constructor(owner, args) {
    super(owner, args);
    this.onScheduleRender = this.args.onScheduleRender;
    this.elementsLayer = this.args.elementsLayer;
    this.interactiveLayer = this.args.interactiveLayer;
  }

  @action
  didInsert() {
    this._previewMode = this.args.previewMode;
    this._isVisited = this.args.isVisited;
    this._isDisconnected = this.args.isDisconnected;
    this._isCollapsed = this.args.isCollapsed;
    this._isPossessed = this.args.isPossessed;
    this._isEnabled = this.args.isEnabled;
    this._hasPossessionIssues = this.args.hasPossessionIssues;
    this._hasEnablementIssues = this.args.hasEnablementIssues;
    this._visibleAreaIndex = this.args.visibleAreaIndex;
    this._elementIsSelected = this.elementIsSelected;
    this._isSelectedChild = this.args.isSelectedChild;
    this._x = this.args.x;
    this._y = this.args.y;
    this._reachedScaleThreshold = this.args.reachedScaleThreshold;
    this._versionName = this.versionName;
    this._productName = this.productName;
    this.activeLayer = this.args.isSelected
      ? this.interactiveLayer
      : this.elementsLayer;
    this._darkMode = this.settings.darkMode;
    this._blackAndWhiteMode = this.settings.blackAndWhiteMode;
    this.setup();
  }

  @action
  willDestroyNode() {
    // remove events
    if (this.args.onContextClick) {
      this.elementBackgroundNode.off('contextmenu');
    }
    if (this.args.onClick) {
      this.elementBackgroundNode.off('click');
    }
    if (this.args.onDragStart) {
      this.elementNode.off('dragstart');
    }
    if (this.args.onDragMove) {
      this.elementNode.off('dragmove');
    }
    if (this.args.onDragEnd) {
      this.elementNode.off('dragend');
    }
    if (this.args.onMouseenter) {
      this.elementNode.off('mouseenter');
    }
    if (this.args.onMouseleave) {
      this.elementNode.off('mouseleave');
    }

    this.elementNode.destroy();
    this.onScheduleRender();
  }

  get styleNamespace() {
    return podNames['invention-graph-element-konva'];
  }

  get classNames() {
    let classNames = [this.styleNamespace];
    if (this.args.isDisconnected) classNames.push('is-disconnected');
    if (this.args.isSelected) classNames.push('is-selected');
    if (this.args.isCollapsed) classNames.push('is-collapsed');
    if (this.args.isVisited) classNames.push('is-visited');
    if (this.args.isDisconnected) classNames.push('is-disconnected');
    return classNames.join(' ');
  }

  // eslint-disable-next-line ember/require-computed-property-dependencies
  @computed(
    'args.{elementVersionsList.[],preferredElementVersionsList.[],productId,elementId}'
  )
  get elementVersionId() {
    const state = this.redux.getState();
    let elementVersionId;
    if (this.args.productId) {
      elementVersionId = getPreferredElementVersionId(
        state,
        this.args.elementId,
        this.args.productId
      );
    }
    return elementVersionId;
  }

  @computed('elementVersionId')
  get elementVersion() {
    const state = this.redux.getState();
    return (
      this.elementVersionId && getElementVersion(state, this.elementVersionId)
    );
  }

  @computed('product.name')
  get versionName() {
    return this.product ? this.product.name : '';
  }

  @computed('product.productName')
  get productName() {
    return this.product && this.product.productName ? `(${this.product.productName.trim()})` : '';
  }

  @computed('args.isSelected')
  get elementIsSelected() {
    return this.args.isSelected;
  }

  @computed('args.{elementId,nodesMap}')
  get siblings() {
    const id = this.args.elementId;
    const node = this.args.nodesMap && this.args.nodesMap[id];
    const siblings =
      node && node.parent && this.args.nodesMap[node.parent].children;
    return siblings;
  }

  @computed('siblings.[]')
  get hasSiblings() {
    return this.siblings && this.siblings.length > 1;
  }

  @computed('siblings.[]', 'args.elementId')
  get isLastSibling() {
    const index = this.siblings && this.siblings.indexOf(this.args.elementId);
    const length = this.siblings && this.siblings.length;
    return length && index === length - 1;
  }

  get textWidth() {
    return this.width - this.horizontalPadding * 2;
  }

  get collapsedTransform() {
    const x = 0;
    const y = this.height + 325;
    return { x, y };
  }

  get strokeColor() {
    let color = ELEMENT_NODE_ROOT_STROKE;
    if (this.args.isDisconnected) {
      color = ELEMENT_NODE_GREY_STROKE;
    }

    if (this.settings.blackAndWhiteMode) {
      color = BLACK_AND_WHITE_STROKE;
    }
    return color;
  }

  get strokeColorLight() {
    let color = ELEMENT_COLLAPSED_NODE_STROKE;
    if (this.settings.blackAndWhiteMode) {
      color = BLACK_AND_WHITE_STROKE;
    }
    return color;
  }
  get fillColor() {
    let color = ELEMENT_NODE_ROOT_FILL;
    if (this.settings.blackAndWhiteMode) {
      color = BLACK_AND_WHITE_FILL;
    }
    return color;
  }

  get selectedNodeFill() {
    let color = ELEMENT_NODE_STROKE;

    if (this.args.isDisconnected) {
      color = ELEMENT_NODE_GREY_FILL;
    }

    if (this.settings.blackAndWhiteMode) {
      color = BLACK_AND_WHITE_FILL;
    }

    return color;
  }

  get fillColorCollapsed() {
    let color = ELEMENT_NODE_FILL;
    if (this.settings.blackAndWhiteMode) {
      color = BLACK_AND_WHITE_FILL;
    }
    return color;
  }

  get textColor() {
    let color = ELEMENT_NODE_COLOR;

    if (this.args.isDisconnected) {
      color = ELEMENT_NODE_GREY_COLOR;
    }
    if (this.settings.blackAndWhiteMode) {
      color = BLACK_AND_WHITE_COLOR;
    }
    return color;
  }


  getProductNameY(lines, nameFontSize = 34, nameLineHeight = 1.1, padding = 12) {
    const lineHeightPx = nameLineHeight * nameFontSize
    return (lines * lineHeightPx / 2) + lineHeightPx / 2 + padding;
  }

  setup() {
    const elementNode = new Konva.Group({
      id: this.args.elementId,
      x: this.args.x,
      y: this.args.y,
      name: 'node',
      draggable: this.args.previewMode ? false : true,
      nodeType: 'element',
      category: 'product',
      transformsEnabled: 'position',
    });

    const selectedNode = new Konva.Circle({
      id: `select-${this.args.elementId}`,
      radius: this.radius,
      fill: this.selectedNodeFill,
      stroke: this.selectedNodeFill,
      hitStrokeWidth: 0,
      strokeWidth: 26,
      visible: this.elementIsSelected ? true : false,
      // opacity: 0.5,
      listening: false,
      strokeScaleEnabled: false,
      transformsEnabled: 'position',
    });

    const selectedChildNode = new Konva.Circle({
      radius: this.radius,
      fill: this.selectedNodeFill,
      stroke: this.selectedNodeFill,
      hitStrokeWidth: 0,
      strokeWidth: 12,
      visible: this.args.isSelectedChild ? true : false,
      // opacity: 0.5,
      listening: false,
      strokeScaleEnabled: false,
      transformsEnabled: 'position',
    });

    const elementBackgroundNode = new Konva.Circle({
      radius: this.radius,
      fill: this.fillColor,
      stroke: this.strokeColor,
      hitStrokeWidth: 0,
      strokeWidth: 7,
      transformsEnabled: 'position',
      visible: true,
    });

    const nameNode = new Konva.Text({
      width: this.textWidth,
      height: 400,
      align: 'center',
      verticalAlign: 'middle',
      // text: this.args.name + ' ' + this.args.elementId,
      text: this.versionName,
      fontSize: 34,
      lineHeight: 1.1,
      fontFamily: 'Inter',
      // fontFamily: 'Helvetica Neue',
      fontStyle: '500',
      fill: this.textColor,
      transformsEnabled: 'position',
      listening: false,
      offset: {
        y: 200,
        x: this.textWidth / 2,
      },
    });

    const productNameNode = new Konva.Text({
      width: this.textWidth,
      align: 'center',
      // text: this.args.name + ' ' + this.args.elementId,
      text: this.productName,
      fontSize: 26,
      lineHeight: 1.1,
      fontFamily: 'Inter',
      // fontFamily: 'Helvetica Neue',
      fill: this.textColor,
      transformsEnabled: 'position',
      listening: false,
      offset: {
        y: (26 * 1.1) / 2,
        x: this.textWidth / 2,
      },
    });

    const productNameY = this.getProductNameY(nameNode.textArr.length);
    productNameNode.y(productNameY);
    // const nameNodeLineHeightPx = 34 * 1.1;

    const collapsedNode = new Konva.Circle({
      visible: this.args.isCollapsed,
      x: 15,
      y: 7.5,
      radius: this.radius,
      fill: this.fillColorCollapsed,
      stroke: this.strokeColorLight,
      hitStrokeWidth: 0,
      strokeWidth: 7,
      transformsEnabled: 'position',
    });

    const collapsedTwoNode = new Konva.Circle({
      visible: this.args.isCollapsed,
      x: 30,
      y: 15,
      radius: this.radius,
      fill: this.fillColorCollapsed,
      stroke: this.strokeColorLight,
      hitStrokeWidth: 0,
      strokeWidth: 7,
      transformsEnabled: 'position',
    });

    const collapsedThreeNode = new Konva.Circle({
      visible: this.args.isCollapsed,
      x: 45,
      y: 22.5,
      radius: this.radius,
      fill: this.fillColorCollapsed,
      stroke: this.strokeColorLight,
      hitStrokeWidth: 0,
      strokeWidth: 7,
      transformsEnabled: 'position',
    });

    // TODO opacity kills end-to-end tests
    if (ENV.environment !== 'test') {
      selectedNode.opacity(0.5);
      selectedChildNode.opacity(0.35);
    }

    elementNode.add(selectedNode);
    elementNode.add(selectedChildNode);
    elementNode.add(collapsedThreeNode);
    elementNode.add(collapsedTwoNode);
    elementNode.add(collapsedNode);
    elementNode.add(elementBackgroundNode);
    elementNode.add(nameNode);
    elementNode.add(productNameNode);

    // add events
    if (this.args.onClick) {
      elementBackgroundNode.on('click', (e) => {
        const isRightClick = e.evt ? e.evt.altKey || e.evt.button === 2 : false;
        this.args.onClick(
          this.args.elementId,
          this.args.isSelected,
          isRightClick
        );
      });
    }

    if (this.args.onContextClick) {
      elementBackgroundNode.on('contextmenu', (event) => {
        this.args.onContextClick(this.args.elementId, event);
      });
    }

    if (this.args.onDragStart) {
      elementNode.on('dragstart', (event) => {
        if (this.args.previewMode) return false;
        if (this.args.actionMode) {
          event.target.stopDrag();
        }
        this.args.onDragStart(event, this.args.elementId);
      });
    }

    if (this.args.onDragMove) {
      elementNode.on('dragmove', (event) => {
        if (this.args.previewMode) return false;
        if (this.args.actionMode) {
          return event.target.stopDrag();
        }
        this.args.onDragMove(event, this.args.elementId);
      });
    }

    if (this.args.onDragMove) {
      elementNode.on('dragend', (event) => {
        if (this.args.previewMode) return false;
        if (this.args.actionMode) {
          return;
        }
        this.args.onDragEnd(event, this.args.elementId);
      });
    }

    if (this.args.onMouseenter) {
      elementNode.on('mouseenter', (event) => {
        if (this.args.previewMode) return false;
        this.args.onMouseenter(event, this.args.elementId);
      });
    }

    if (this.args.onMouseleave) {
      elementNode.on('mouseleave', (event) => {
        if (this.args.previewMode) return false;
        this.args.onMouseleave(event, this.args.elementId);
      });
    }

    this.elementsLayer.add(elementNode);

    elementNode.visible(elementNode.isClientRectOnScreen());

    this.nameNode = nameNode;
    this.productNameNode = productNameNode;
    this.collapsedNode = collapsedNode;
    this.collapsedTwoNode = collapsedTwoNode;
    this.collapsedThreeNode = collapsedThreeNode;
    this.elementBackgroundNode = elementBackgroundNode;
    this.selectedNode = selectedNode;
    this.selectedChildNode = selectedChildNode;
    this.elementNode = elementNode;
    // this.categoryMachineNode = categoryMachineNode;
    // this.categoryArticleNode = categoryArticleNode;
    // this.categoryProcessNode = categoryProcessNode;

    this.onScheduleRender();
  }

  handleSelected() {
    this.selectedNode.visible(this.elementIsSelected ? true : false);
    this.onScheduleRender();
  }

  handleDeselected() {
    this.selectedNode.visible(false);
    this.onScheduleRender();
  }

  updateColors() {
    this.selectedNode.stroke(this.strokeColor);
    this.elementBackgroundNode.fill(this.fillColor);
    this.elementBackgroundNode.stroke(this.strokeColor);

    this.nameNode.fill(this.textColor);
    this.productNameNode.fill(this.textColor);

    this.collapsedNode.fill(this.fillColorCollapsed);
    this.collapsedNode.stroke(this.strokeColorLight);
    this.collapsedTwoNode.fill(this.fillColorCollapsed);
    this.collapsedTwoNode.stroke(this.strokeColorLight);
    this.collapsedThreeNode.fill(this.fillColorCollapsed);
    this.collapsedThreeNode.stroke(this.strokeColorLight);
  }

  @action
  updateVisibility() {
    this.elementNode.visible(this.elementNode.isClientRectOnScreen());
  }

  @action
  onUpdate(
    elem,
    [
      previewMode,
      isCollapsed,
      visibleAreaIndex,
      elementIsSelected,
      x,
      y,
      reachedScaleThreshold,
      versionName,
      productName,
      isCreatingFrom,
      darkMode,
      blackAndWhiteMode,
    ]
  ) {
    let detailsChanged = false;
    let visibleAreaChanged = false;

    if (this._previewMode !== previewMode) {
      this._previewMode = previewMode;
      if (previewMode) {
        this.elementNode.draggable(false);
      } else {
        this.elementNode.draggable(true);
      }
      this.onScheduleRender();
    }

    if (this._isCollapsed !== isCollapsed) {
      this._isCollapsed = isCollapsed;
      this.collapsedNode.visible(isCollapsed);
      this.collapsedTwoNode.visible(isCollapsed);
      this.collapsedThreeNode.visible(isCollapsed);
      this.updateColors();
    }

    if (this._visibleAreaIndex !== visibleAreaIndex) {
      this._visibleAreaIndex = visibleAreaIndex;
      visibleAreaChanged = true;
    }

    if (visibleAreaChanged) {
      this.updateVisibility();
    }

    if (this._elementIsSelected !== elementIsSelected) {
      this._elementIsSelected = elementIsSelected;
      if (elementIsSelected) {
        this.handleSelected();
      } else {
        this.handleDeselected();
      }
    }

    if (this._versionName !== versionName) {
      detailsChanged = true;
      this._versionName = versionName;
      this.nameNode.text(versionName);
      const productNameY = this.getProductNameY(this.nameNode.textArr.length);
      this.productNameNode.y(productNameY);
    }


    if (this._productName !== productName) {
      detailsChanged = true;
      this._productName = productName;
      this.productNameNode.text(productName);
    }

    if (this._isCreatingFrom !== isCreatingFrom) {
      this._isCreatingFrom = isCreatingFrom;
      if (isCreatingFrom) {
        this.selectedNode.visible(true);
        this.onScheduleRender();
      } else {
        if (!this._isCreatingFrom && !this.elementIsSelected) {
          this.selectedNode.visible(false);
        }
        this.onScheduleRender();
      }
    }

    if (this._x !== x || this._y !== y) {
      this._x = x;
      this._y = y;
      this.elementNode.x(x);
      this.elementNode.y(y);
      this.onScheduleRender();
    }

    if (this._reachedScaleThreshold !== reachedScaleThreshold) {
      this._reachedScaleThreshold = reachedScaleThreshold;
      detailsChanged = true;
    }

    if (this._darkMode !== darkMode) {
      this._darkMode = darkMode;
      this.updateColors();
      detailsChanged = true;
    }

    if (this._blackAndWhiteMode !== blackAndWhiteMode) {
      this._blackAndWhiteMode = blackAndWhiteMode;
      this.updateColors();
      detailsChanged = true;
    }

    if (detailsChanged) {
      this.onScheduleRender(this.activeLayer);
    }
  }
}

export default connect(
  stateToComputed,
  dispatchToActions
)(InventionGraphProduct);
