import { buildMappedFiguresList } from '../utils/figures';
import { get } from '@ember/object';
import { getPatentSpecification } from './patent-specification';
import { getPresentState } from '../utils/redux';

export const getFiguresList = (state) => {
  const patentSpecification = getPatentSpecification(state);
  const narrativeType = patentSpecification.narrativeType;
  return get(getPresentState(state), `figures.${narrativeType}.figuresList`);
};

export const getFiguresMap = (state) => {
  const patentSpecification = getPatentSpecification(state);
  const narrativeType = patentSpecification.narrativeType;
  return get(getPresentState(state), `figures.${narrativeType}.figuresMap`);
};

export const getFigureNumber = (state, figureId) => {
  const figuresMap = getFiguresMap(state);
  return (
    (figuresMap && figuresMap[figureId] && figuresMap[figureId].sequence) || 0
  );
};

export const getFiguresUpdatedAt = (state) => {
  return get(getPresentState(state), `figures.updatedAt`);
};

export const getMappedFiguresList = (state) => {
  const patentSpecification = getPatentSpecification(state);
  const narrativeType = patentSpecification.narrativeType;
  const updatedAt = getFiguresUpdatedAt(state);
  return buildMappedFiguresList(updatedAt, narrativeType, state);
};
