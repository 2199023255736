import { action, computed } from '@ember/object';

import Component from '@glimmer/component';
import ENV from '../../../config/environment';
import { batchGroupBy } from '../../../utils/redux';
import { connect } from 'ember-redux';
import { debounce } from '@ember/runloop';
import { getElementVersion } from '../../../selectors/element-version';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

const stateToComputed = (state, attrs) => ({
  elementVersion: getElementVersion(state, attrs.elementVersionId),
});

const dispatchToActions = {};

class ElementVersionNameEditor extends Component {
  @service models;
  @service applicationState;
  @service tracking;
  @service data;

  @tracked switched = false;
  @tracked _name = '';

  _elementVersionId = '';

  get styleNamespace() {
    return podNames['element-version-name-editor'];
  }

  get classNames() {
    let classNames = [this.styleNamespace];
    return classNames.join(' ');
  }

  @action
  onInsert() {
    this._name = this.name;
    this._elementVersionId = this.args.elementVersionId;
  }

  @action
  onUndo() {
    this.switched = !this.switched;
    this.updateName();
  }

  @action
  onElementVersionChange(elem, [elementVersionId]) {
    if (elementVersionId !== this._elementVersionId) {
      this._elementVersionId = elementVersionId;
      this.switched = !this.switched;
      this.updateName();
    }
  }

  updateName() {
    const state = this.redux.getState();
    const element = getElementVersion(state, this.args.elementVersionId);
    this._name = element.name;
  }

  debounceUpdate(args) {
    const name = args.name;
    batchGroupBy.start();
    this.data.updateElementVersion(this.args.elementVersionId, { name });
    batchGroupBy.end();
    this.tracking.trackEvent('solution_context_updated_name');
  }

  @computed('elementVersion', 'args.elementVersionId')
  get elementVersionModel() {
    return this.models.findOrCreate(
      this.args.elementVersionId,
      'element-version',
      this.elementVersion
    );
  }

  @computed('elementVersion.name')
  get name() {
    return this.elementVersion && this.elementVersion.name;
  }

  @action
  onDebounceEdit(name) {
    if (!name) {
      // do nothing
    } else {
      this.elementVersionModel.set('name', name);
      debounce(this, this.debounceUpdate, { name }, ENV.DEBOUNCE_MS);
    }
  }
}

export default connect(stateToComputed, dispatchToActions)(ElementVersionNameEditor);
