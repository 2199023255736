import { get } from '@ember/object';
import { getPresentState } from '../utils/redux';

export const getFeature = (state, featureId) =>
  get(getPresentState(state), `features.entities.${featureId}`);



export const getFeaturesList = (state) =>
  get(getPresentState(state), `features.ids`);

export const getFeatures = (state) =>
  get(getPresentState(state), `features.entities`);

export const getComprisesFeatureFromEdge = (state, edgeId) => {
  const sourceId = edgeId.split('_')[0];
  const targetId = edgeId.split('_')[1];

  return `${sourceId} => ${targetId}`
}
  
