import ENV from '../config/environment';
import JSZip from 'jszip';
import Service from '@ember/service';
import { getMimeTypeOfFile } from '../utils/file';
import { indexOf } from 'lodash';
import { replaceAllInList } from '../utils/string';
import semver from 'semver';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import uuid from 'uuid/v4';

export default Service.extend({
  assets: service(),
  redux: service(),
  migrations: service(),
  usersDb: service(),
  worker: service(),
  importLegacy: service(),
  notifications: service(),
  invention: null,
  schemaVersion: ENV.schemaVersion,

  importZip: task(function* (file, isLegacy) {
    let disclosure;

    this.notifications.info('Importing File ...', {
      autoClear: false,
      cssClasses: 'notification-info',
    });

    if (isLegacy) {
      disclosure = yield this.importLegacy.updateInventionFile.perform(file);
    } else {
      disclosure = yield this.parseZip.perform(file);
    }

    const inventionId = disclosure.invention.id;

    const state = {
      past: [],
      present: disclosure,
      future: [],
    };

    const stringifiedState = yield this.worker.postMessage(
      'json-stringify',
      state
    );

    yield this.redux.storage.setItem(inventionId, stringifiedState);
    yield this.usersDb.addInvention(inventionId);

    this.notifications.clearAll();

    return inventionId;
  }),

  parseZip: task(function* (file) {
    const zip = yield JSZip.loadAsync(file);
    // const metaFile = zip.file('meta.json');

    // let meta = yield metaFile.async('string');
    // meta = JSON.parse(meta);

    let dataAsString;

    let dataFile = zip.file('data.json');

    dataAsString = yield dataFile.async('string');

    const dataAsJSON = JSON.parse(dataAsString);

    // migrate data 
    const migratedData = this.migrations.migrate(
      dataAsJSON,
      this.schemaVersion
    );

    const idsForReplacement = this.getIdsForReplacement(migratedData);
    const replacementIds = idsForReplacement.map(() => uuid());

    dataAsString = JSON.stringify(migratedData);

    dataAsString = replaceAllInList(
      dataAsString,
      idsForReplacement,
      replacementIds
    );
    
    const data = JSON.parse(dataAsString);
    const zipAssets = [];

    zip.folder('assets').forEach((asset) => {
      const assetId = asset.split('.')[0];
      zipAssets.push({
        id: assetId,
        fileName: asset,
      });
    });

    let i = 0;
    while (i < zipAssets.length) {
      const index = indexOf(idsForReplacement, zipAssets[i].id);
      const newId = replacementIds[index];
      const assetFile = zip.file(`assets/${zipAssets[i].fileName}`);
      const zipEntryFile = yield assetFile.async('blob');
      const mimeType = yield getMimeTypeOfFile(zipEntryFile);
      const blob = new File([zipEntryFile], zipAssets[i].fileName, {
        type: mimeType,
      });
      yield this.assets.addAsset(newId, blob);
      i++;
    }
    return data;
  }),

  getIdsForReplacement(state) {
    const inventionId = state.invention.id;
    const assetIds = state.assets.ids;
    return [inventionId, ...assetIds ];
    // return [inventionId];
  },

  importInventingGame: task(function* (file) {
    const inventionId = uuid();
    const zip = yield JSZip.loadAsync(file);

    const dataFile = zip.file('data.json');
    const dataAsString = yield dataFile.async('string');
    const data = JSON.parse(dataAsString);


    this.notifications.info('Importing File ...', {
      autoClear: false,
      cssClasses: 'notification-info',
    });

    const { name, description, inspiration } = data;

    const zipAssets = [];

    zip.folder('assets').forEach((asset) => {
      zipAssets.push({
        id: uuid(),
        fileName: asset,
      });
    });

    let i = 0;
    while (i < zipAssets.length) {
      const fileName = zipAssets[i].fileName;
      const id = zipAssets[i].id;
      const assetFile = zip.file(`assets/${fileName}`);
      const zipEntryFile = yield assetFile.async('blob');
      const mimeType = yield getMimeTypeOfFile(zipEntryFile);
      const blob = new File([zipEntryFile], fileName, {
        type: mimeType,
      });
      yield this.assets.addAsset(id, blob);
      i++;
    }

    const assets = zipAssets.map(obj => obj.id).join(',')

    return {
      inventionId,
      name,
      description,
      inspiration,
      assets,
    };
  }),
 
  parseFileType: task(function* (file) {
    let fileType;
    const zip = yield JSZip.loadAsync(file);
    const metaFile = zip.file('meta.json');

    if (!metaFile) {
      this.notifications.error('File type is not supported', {
        autoClear: true,
      });
      throw 'File type is not supported';
    }

    let meta = yield metaFile.async('string');
    meta = JSON.parse(meta);

    if (meta && meta.version) {
      const version = semver.coerce(meta.version).version;

      if (!semver.gte(version, '0.6.1')) {
        this.notifications.error('File version is not supported', {
          autoClear: true,
        });
        throw 'File version is not supported';
      }
    }

    if (meta && meta.fileVersion) {
      fileType = 'legacy';
    }

    if (meta && meta.fileType === 'inventing-game') {
      fileType = 'inventing-game';
    }

    return fileType;
  }),
});
