import Component from '@glimmer/component';
import { action } from '@ember/object';
import { connect } from 'ember-redux';
import podNames from 'ember-component-css/pod-names';
import { getProduct } from '../../../selectors/product';

const stateToComputed = (state, attrs) => ({
  product: getProduct(state, attrs.productId),
});
const dispatchToActions = {};

class ProductModal extends Component {

  get styleNamespace() {
    return podNames['product-modal'];
  }

  get classNames() {
    let classNames = [this.styleNamespace, 'product-modal'];
    return classNames.join(' ');
  }

  @action
  hideModal() {
    this.args.onClose()
  }

}

export default connect(stateToComputed, dispatchToActions)(ProductModal);
