import FileSaver from 'file-saver';
import JSZip from 'jszip';
import Service from '@ember/service';
import { dasherize } from '@ember/string';
import moment from 'moment';
import { inject as service } from '@ember/service';

export default Service.extend({
  assets: service(),

  saveAs(content, fileName) {
    return FileSaver.saveAs(content, `${fileName}.dive`);
  },

  getFileName(inventionName) {
    return dasherize(inventionName);
  },

  getFileExtension(mimeType) {
    let extension = '';

    switch (mimeType) {
      case 'image/png':
        extension = 'png';
        break;
      case 'image/jpeg':
        extension = 'jpg';
        break;
    }

    return extension;
  },

  // getIdsForReplaceMent(json) {
  //   const string = JSON.stringify(json);
  //   const v4 = new RegExp(
  //     /[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-4{1}[a-fA-F0-9]{3}-[89abAB]{1}[a-fA-F0-9]{3}-[a-fA-F0-9]{12}/g
  //   );
  //   return string.match(v4).uniq();
  // },

  getIdsForReplacement(state) {
    const inventionId = state.invention.id;
    const assetIds = state.assets.ids;
    return [inventionId, ...assetIds ];
  },

  async getImageAssets(state) {
    const assetIds = state.assets.ids;
    let allAssetIds = assetIds.filter((id) => id);
    const assets = await Promise.all(
      allAssetIds.map((assetId) => this.assets.getAssetWithKey(assetId))
    );
    const assetsWithFileName = assets.map((asset) => {
      const { id, blob } = asset;
      const extension = this.getFileExtension(blob.type);
      const fileName = `${id}.${extension}`;
      return {
        id,
        blob,
        extension,
        fileName,
      };
    });
    return assetsWithFileName;
  },

  async exportZip(presentState) {
    const zip = new JSZip();
    const timestamp = moment().format('x');
    const specification = presentState.patentSpecification;
    const inventionName = specification && specification.title || 'Untitled Invention';

    // get the array of ids that will be replaced
    const idsForReplacement = this.getIdsForReplacement(presentState);

    // write the meta file /meta.json
    const metaJson = JSON.stringify(
      {
        timestamp,
        idsForReplacement,
      },
      null,
      2
    );
    const metaBlob = new Blob([metaJson], { type: 'text/plain;charset=utf-8' });
    zip.file(`meta.json`, metaBlob, { binary: true });

    // get images and write them to an /assets dir in the zip file
    const imageAssets = await this.getImageAssets(presentState);
    imageAssets.forEach((asset) => {
      zip.folder('assets').file(asset.fileName, asset.blob, { binary: true });
    });

    const dataJson = JSON.stringify(presentState, null, 2);
    const dataBlob = new Blob([dataJson], { type: 'text/plain;charset=utf-8' });
    zip.file(`data.json`, dataBlob, { binary: true });

    const content = await zip.generateAsync({ type: 'blob' });
    const fileName = this.getFileName(inventionName);

    this.saveAs(content, fileName);
  },
});
