import {
  MARKER_LABEL_FILL,
  MARKER_NOVEL_LABEL_FILL,
  MARKER_NOVEL_TEXT_COLOR,
  MARKER_NUMBER_FILL,
  MARKER_OPAQUE_STROKE,
  MARKER_POINT_FILL,
  MARKER_POINT_SELECTED_FILL,
  MARKER_SELECTED_LABEL_FILL,
  MARKER_SELECTED_NUMBER_FILL,
  MARKER_SELECTED_STROKE,
  MARKER_SELECTED_TEXT_COLOR,
  MARKER_STROKE,
  MARKER_TEXT_COLOR,
} from '../../../constants/colors';
import { action, computed } from '@ember/object';
import {
  getDeletedElementsList,
  getMarkerNumber,
  getMarkerOrdinal,
} from '../../../selectors/invention';
import {
  getMarker,
  getMarkerElement,
  getMarkerElementVersion,
  getMarkerTerm,
} from '../../../selectors/marker';

import Component from '@glimmer/component';
import Konva from 'konva';
import { connect } from 'ember-redux';
import { getAutoLabelOrientation } from '../../../utils/drawing';
import { getNumberingIndex } from '../../../selectors/invention-ui';
import { getPreviewNumbering } from '../../../selectors/settings';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

const dispatchToActions = {};

const stateToComputed = (state, attrs) => ({
  deletedElementsList: getDeletedElementsList(state),
  marker: getMarker(state, attrs.markerId),
  element: getMarkerElement(state, attrs.markerId),
  elementVersion: getMarkerElementVersion(state, attrs.markerId),
  term: getMarkerTerm(state, attrs.markerId),
  ordinal: getMarkerOrdinal(state, attrs.markerId),
  number: getMarkerNumber(state, attrs.markerId),
  previewNumbering: getPreviewNumbering(state),
  numberingIndex: getNumberingIndex(state),
});

class PreviewDrawingMarker extends Component {
  @service assets;
  @service redux;
  @service settings;

  numberRadius = 20;
  numberFontSize = 20;
  startRadius = 20;
  endPointRadius = 3;
  midRadius = 10;
  endRadius = 10;
  arrowWidth = 15;
  arrowLength = 20;
  tension = 0.5;
  fontFamily = 'Inter';
  fontSize = 18;
  ordinalFontSize = 20;
  ordinalWidth = 100;
  labelWidth = 240;

  @tracked startX;
  @tracked startY;
  @tracked midX;
  @tracked midY;
  @tracked endX;
  @tracked endY;
  @tracked _x;
  @tracked _y;

  constructor(owner, args) {
    super(owner, args);
    this.onScheduleRender = this.args.onScheduleRender;
  }

  @action
  didInsert() {
    this._updatedAt = this.marker.updatedAt;
    this._isSelected = this.args.isSelected;
    this._previewNumbering = this.previewNumbering;
    this._numberingIndex = this.numberingIndex;
    this.startX = this.marker.startX;
    this.startY = this.marker.startY;
    this.midX = this.marker.midX;
    this.midY = this.marker.midY;
    this.endX = this.marker.endX;
    this.endY = this.marker.endY;

    this.setup();
  }

  @action
  willDestroyNode() {
    this.labelNode.off('click');
    this.ordinalNode.off('click');
    this.numberNode.off('click');
    this.ghostNode.off('click');
    this.markerNode.destroy();
    this.onScheduleRender();
  }

  get styleNamespace() {
    return podNames['preview-drawing-marker'];
  }

  get classNames() {
    let classNames = [this.styleNamespace];
    return classNames.join(' ');
  }

  @computed('marker.hasLeadLine')
  get hasLeadLine() {
    return this.marker && this.marker.hasLeadLine;
  }

  @computed('startX', 'startY', 'midX', 'midY', 'endX', 'endY')
  get isCollapsed() {
    return (
      this.startX === this.midX &&
      this.startY === this.midY &&
      this.startX === this.endX &&
      this.startY === this.endY
    );
  }

  @computed('marker.pointStyle', 'hasLeadLine')
  get hasArrow() {
    return (
      this.marker && this.marker.pointStyle === 'arrow' && this.hasLeadLine
    );
  }

  @computed('marker.curve')
  get isCurved() {
    return this.marker && this.marker.curve === 'natural';
  }

  @computed('marker.labelOrientation')
  get labelOrientation() {
    return this.marker && this.marker.labelOrientation;
  }

  labelBackgroundNodeHeight(lines) {
    let height = 40;
    switch (lines) {
      case 1:
        height = 40;
        break;
      case 2:
        height = 65;
        break;
      case 3:
        height = 90;
        break;
      case 4:
        height = 115;
        break;
      case 5:
        height = 140;
        break;
      case 6:
        height = 165;
        break;
    }
    return height;
  }

  get strokeColor() {
    let color = MARKER_STROKE;

    if (this.args.isSelected) {
      color = MARKER_SELECTED_STROKE;
    }

    return color;
  }

  get selectedFill() {
    let color = MARKER_OPAQUE_STROKE;

    if (this.settings.blackAndWhiteMode) {
      color = 'rgba(0,0,0,0.5)';
    }

    return color;
  }

  get pointFill() {
    let color = MARKER_POINT_FILL;

    if (this.args.isSelected) {
      color = MARKER_POINT_SELECTED_FILL;
    }

    return color;
  }

  get textColor() {
    let color = MARKER_TEXT_COLOR;

    if (this.args.isSelected) {
      color = MARKER_SELECTED_TEXT_COLOR;
    }

    if (this.args.isNovel) {
      color = MARKER_NOVEL_TEXT_COLOR;
    }
    return color;
  }
  get labelFillColor() {
    let color = MARKER_LABEL_FILL;

    if (this.args.isSelected) {
      color = MARKER_SELECTED_LABEL_FILL;
    }

    if (this.args.isNovel) {
      color = MARKER_NOVEL_LABEL_FILL;
    }

    return color;
  }

  get numberStrokeColor() {
    let color = MARKER_STROKE;

    if (this.args.isSelected) {
      color = MARKER_SELECTED_STROKE;
    }

    return color;
  }

  get numberFillColor() {
    let color = MARKER_NUMBER_FILL;

    if (this.args.isSelected) {
      color = MARKER_SELECTED_NUMBER_FILL;
    }

    return color;
  }

  @computed(
    'element.{name,elementVersionsList.[]}',
    'elementVersion.name',
    'term.name'
  )
  get label() {
    let label = '';
    if (this.element) {
      label = this.element.name;

      if (this.element.elementVersionsList.length > 1) {
        label += this.elementVersion ? `\n(${this.elementVersion.name})` : '';
      }
    }
    if (this.term) {
      label = this.term.name;
    }
    return label;
  }

  @computed('label')
  get hasLabel() {
    return this.label ? true : false;
  }

  getLabelOffset(nodeHeight) {
    const padding = 0;
    const startY = this.startY;
    const midY = this.midY;
    const endY = this.endY;
    const orientation =
      this.labelOrientation === 'auto'
        ? getAutoLabelOrientation({
            startY,
            endY,
            midY,
          })
        : this.labelOrientation;

    let offset;

    switch (orientation) {
      case 'top':
        offset = {
          x: this.labelWidth / 2,
          y: padding + this.startRadius + nodeHeight,
        };
        break;
      case 'bottom':
        offset = {
          x: this.labelWidth / 2,
          y: -1 * (padding + this.startRadius + 10),
        };
        break;
      case 'left':
        offset = {
          x: padding + this.startRadius + this.labelWidth,
          y: nodeHeight / 2,
        };
        break;
      case 'right':
        offset = {
          x: -1 * (padding + this.startRadius),
          y: nodeHeight / 2,
        };
        break;
    }
    return offset;
  }

  getOrdinalOffset(nodeHeight = 30) {
    const padding = 4;
    const startY = this.startY;
    const midY = this.midY;
    const endY = this.endY;
    const orientation =
      this.labelOrientation === 'auto'
        ? getAutoLabelOrientation({
            startY,
            endY,
            midY,
          })
        : this.labelOrientation;

    let offset;

    switch (orientation) {
      case 'top':
        offset = {
          x: this.ordinalWidth / 2,
          y: padding + nodeHeight,
        };
        break;
      case 'bottom':
        offset = {
          x: this.ordinalWidth / 2,
          y: -1 * padding,
        };
        break;
      case 'left':
        offset = {
          x: padding + this.ordinalWidth,
          y: nodeHeight / 2,
        };
        break;
      case 'right':
        offset = {
          x: -1 * padding,
          y: nodeHeight / 2,
        };
        break;
    }
    return offset;
  }

  get strokeWidth() {
    return this.settings.blackAndWhiteMode ? 3 : 2;
  }

  setup() {
    const markerNode = new Konva.Group({
      id: this.args.markerId,
      nodeType: 'marker',
      listening: this.args.onClick ? true : false,
    });

    const arrowNode = new Konva.Arrow({
      points: [
        this.startX,
        this.startY,
        this.midX,
        this.midY,
        this.endX,
        this.endY,
      ],
      pointerWidth: this.hasArrow ? this.arrowWidth : 0,
      pointerLength: this.hasArrow ? this.arrowLength : 0,
      bezier: this.isCurved ? true : false,
      tension: this.isCurved ? this.tension : 0,
      fill: this.strokeColor,
      stroke: this.strokeColor,
      strokeWidth: this.strokeWidth,
      listening: false,
    });

    const ghostNode = new Konva.Line({
      id: `ghost-${this.args.markerId}`,
      points: [
        this.startX,
        this.startY,
        this.midX,
        this.midY,
        this.endX,
        this.endY,
      ],
      stroke: 'transparent',
      strokeWidth: 30,
      bezier: this.isCurved ? true : false,
      tension: this.isCurved ? this.tension : 0,
    });

    const selectedNode = new Konva.Arrow({
      points: [
        this.startX,
        this.startY,
        this.midX,
        this.midY,
        this.endX,
        this.endY,
      ],
      pointerWidth: this.hasArrow ? this.arrowWidth : 0,
      pointerLength: this.hasArrow ? this.arrowLength : 0,
      bezier: this.isCurved ? true : false,
      tension: this.isCurved ? this.tension : 0,
      fill: this.selectedFill,
      stroke: this.selectedFill,
      strokeWidth: 12,
      visible: this.args.isSelected ? true : false,
    });

    const numberNode = new Konva.Group({
      visible: this.previewNumbering === 'report' ? true : false,
      x: this.startX,
      y: this.startY,
      transformsEnabled: 'position',
    });

    const numberTextNode = new Konva.Text({
      x: -1 * this.numberRadius,
      y: -1 * this.numberRadius + 1,
      width: this.numberRadius * 2,
      height: this.numberRadius * 2,
      text: this.number,
      align: 'center',
      verticalAlign: 'middle',
      fontSize: this.numberFontSize,
      lineHeight: 1.235,
      fontFamily: this.fontFamily,
      fontStyle: '500',
      fill: this.textColor,
      transformsEnabled: 'position',
      listening: false,
    });

    const numberBackgroundNode = new Konva.Circle({
      radius: this.numberRadius,
      fill: this.numberFillColor,
      stroke: this.numberStrokeColor,
      strokeWidth: this.strokeWidth,
      transformsEnabled: 'position',
    });

    numberNode.add(numberBackgroundNode);
    numberNode.add(numberTextNode);

    const ordinalNode = new Konva.Group({
      visible: this.previewNumbering === 'patent-specification' ? true : false,
      x: this.startX,
      y: this.startY,
      transformsEnabled: 'position',
      offset: this.getOrdinalOffset(),
    });

    const ordinalTextNode = new Konva.Text({
      width: this.ordinalWidth,
      ellipsis: true,
      text: this.ordinal,
      align: 'center',
      fontSize: this.ordinalFontSize,
      lineHeight: 1.235,
      fontFamily: this.fontFamily,
      fontStyle: '500',
      fill: this.textColor,
      transformsEnabled: 'position',
      strokeScaleEnabled: false,
      padding: 0,
      textDecoration: this.isCollapsed ? 'underline' : '',
    });

    ordinalNode.add(ordinalTextNode);

    const labelNode = new Konva.Group({
      visible: this.previewNumbering === 'report' ? true : false,
      x: this.startX,
      y: this.startY,
      transformsEnabled: 'position',
      listening: this.args.onClick ? true : false,
    });

    const labelTextNode = new Konva.Text({
      width: this.labelWidth,
      ellipsis: true,
      text: this.label,
      align: 'center',
      fontSize: this.fontSize,
      lineHeight: 1.235,
      fontFamily: this.fontFamily,
      fill: this.textColor,
      transformsEnabled: 'position',
      strokeScaleEnabled: false,
      padding: 7,
      textDecoration: this.isCollapsed ? 'underline' : '',
      listening: this.args.onClick ? true : false,
    });

    const labelBackgroundNodeHeight = this.labelBackgroundNodeHeight(
      labelTextNode.textArr.length
    );

    const labelBackgroundNode = new Konva.Rect({
      width: this.labelWidth,
      height: labelBackgroundNodeHeight,
      fill: this.labelFillColor,
      visible: this.args.showTextLabel ? true : false,
    });

    labelNode.offset(this.getLabelOffset(labelBackgroundNodeHeight));

    labelNode.add(labelBackgroundNode);
    labelNode.add(labelTextNode);

    this.args.layer.add(markerNode);

    markerNode.add(ghostNode);
    markerNode.add(selectedNode);
    markerNode.add(arrowNode);
    markerNode.add(labelNode);
    markerNode.add(numberNode);
    markerNode.add(ordinalNode);

    this.markerNode = markerNode;
    this.ghostNode = ghostNode;
    this.selectedNode = selectedNode;
    this.arrowNode = arrowNode;
    this.labelNode = labelNode;
    this.labelTextNode = labelTextNode;
    this.ordinalNode = ordinalNode;
    this.ordinalTextNode = ordinalTextNode;
    this.numberNode = numberNode;
    this.numberTextNode = numberTextNode;
    this.numberBackgroundNode = numberBackgroundNode;

    if (this.args.onClick) {
      this.labelNode.on('click', () => {
        this.args.onClick(this.args.markerId, this.args.isSelected);
      });
      this.ordinalNode.on('click', () => {
        this.args.onClick(this.args.markerId, this.args.isSelected);
      });
      this.numberNode.on('click', () => {
        this.args.onClick(this.args.markerId, this.args.isSelected);
      });
      this.ghostNode.on('click', () => {
        this.args.onClick(this.args.markerId, this.args.isSelected);
      });
    }

    this.onScheduleRender();
  }

  updateColors() {
    this.arrowNode.fill(this.strokeColor);
    this.arrowNode.stroke(this.strokeColor);
    this.labelTextNode.fill(this.textColor);
    this.ordinalTextNode.fill(this.textColor);
    this.selectedNode.fill(this.selectedFill);
    this.numberTextNode.fill(this.textColor);
    this.numberBackgroundNode.fill(this.numberFillColor);
    this.numberBackgroundNode.stroke(this.numberStrokeColor);
    this.selectedNode.stroke(this.selectedFill);
  }

  updateNumbering() {
    this.labelNode.visible(this.previewNumbering === 'report' ? true : false);
    this.ordinalNode.visible(
      this.previewNumbering === 'patent-specification' ? true : false
    );
    this.numberNode.visible(this.previewNumbering === 'report' ? true : false);
    this.ordinalTextNode.text(this.ordinal);
    this.numberTextNode.text(this.number);
  }

  @action
  onUpdate(elem, [isSelected, previewNumbering, numberingIndex]) {
    let detailsChanged = false;

    if (this._isSelected !== isSelected) {
      this._isSelected = isSelected;

      this.updateColors();
      this.selectedNode.visible(this.args.isSelected ? true : false);

      detailsChanged = true;
    }

    if (this._previewNumbering !== previewNumbering) {
      this._previewNumbering = previewNumbering;

      this.updateNumbering();

      detailsChanged = true;
    }

    if (this._numberingIndex !== numberingIndex) {
      this._numberingIndex = numberingIndex;

      this.updateNumbering();

      detailsChanged = true;
    }

    if (detailsChanged) {
      this.onScheduleRender(this.args.layer);
    }
  }
}

export default connect(
  stateToComputed,
  dispatchToActions
)(PreviewDrawingMarker);
