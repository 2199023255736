import {
  ELEMENT_COLLAPSED_NODE_FILL,
  ELEMENT_COLLAPSED_NODE_STROKE,
  ELEMENT_NODE_GREY_COLOR,
  ELEMENT_NODE_GREY_FILL,
  ELEMENT_NODE_GREY_FILL_LIGHT,
  ELEMENT_NODE_GREY_STROKE,
  ELEMENT_NODE_GREY_STROKE_LIGHT,
  ELEMENT_VERSION_CATEGORY_ARTICLE,
  ELEMENT_VERSION_CATEGORY_ARTICLE_LIGHT,
  ELEMENT_VERSION_CATEGORY_ARTICLE_LIGHTER,
  ELEMENT_VERSION_CATEGORY_MACHINE,
  ELEMENT_VERSION_CATEGORY_MACHINE_LIGHT,
  ELEMENT_VERSION_CATEGORY_MACHINE_LIGHTER,
  ELEMENT_VERSION_CATEGORY_PROCESS,
  ELEMENT_VERSION_CATEGORY_PROCESS_LIGHT,
  ELEMENT_VERSION_CATEGORY_PROCESS_LIGHTER,
  ELEMENT_VERSION_COLLAPSED_NODE_STROKE,
  ELEMENT_VERSION_NODE_COLOR,
  ELEMENT_VERSION_NODE_FILL,
  ELEMENT_VERSION_NODE_FILL_LIGHT,
  ELEMENT_VERSION_NODE_HEADER_COLOR,
  ELEMENT_VERSION_NODE_STROKE,
} from '../../../constants/colors';
import { action, computed } from '@ember/object';

import Component from '@glimmer/component';
import ENV from '../../../config/environment';
import Konva from 'konva';
import { getDrawing } from '../../../selectors/drawing';
import { getFeature } from '../../../selectors/feature';
import { getMarker } from '../../../selectors/marker';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency-decorators';
import { textValue } from '../../../utils/string';

class InventionGraphElementVersion extends Component {
  @service redux;
  @service models;
  @service referencesCache;

  fontFamily = 'Inter';
  verticalPadding = 19.5;
  horizontalPadding = 19.5;
  borderRadius = 26;
  width = 380;
  height = 380;
  radius = 210;
  _isRoot = false;
  _known = '';
  _isCollapsed = false;
  _visibleAreaIndex = 0;
  _name = '';
  _isSelected = '';
  _x = 0;
  _y = 0;
  _referenceId = '';
  _referencesString = '';
  _referenceUpdatedAt = '';
  _reachedScaleThreshold = false;
  _constraintValue = '';
  _isDiconnected = false;
  _category = '';

  constructor(owner, args) {
    super(owner, args);
    this.onScheduleRender = this.args.onScheduleRender;
    this.elementsLayer = this.args.elementsLayer;
    this.interactiveLayer = this.args.interactiveLayer;
    this._isDiconnected = this.args.isDiconnected;
    this._category = this.args.category;
    this._isRoot = this.args.isRoot;
    this._known = this.args.known;
    this._isCollapsed = this.args.isCollapsed;
    this._visibleAreaIndex = this.args.visibleAreaIndex;
    this._name = this.args.name;
    this._isSelected = this.args.isSelected;
    this._isCreatingFrom = this.args.isCreatingFrom;
    this._isCreatingTo = this.args.isCreatingTo;
    this._x = this.args.x;
    this._y = this.args.y;
    this._referenceId = this.referenceId;
    this._referencesString = this.referencesString;
    this._referenceUpdatedAt = this.referenceUpdatedAt;
    this._reachedScaleThreshold = this.args.reachedScaleThreshold;
    this.activeLayer = this.args.isSelected
      ? this.interactiveLayer
      : this.elementsLayer;
    this._constraintValue = this.constraintValue;
    this._constraintsCount = this.constraintsCount;
    this.setup();
  }

  willDestroy() {
    if (this.args.onContextClick) {
      this.elementBackgroundNode.off('contextmenu');
    }
    this.elementVersionNode.off('click');
    this.elementVersionNode.off('dragstart');
    this.elementVersionNode.off('dragmove');
    this.elementVersionNode.off('dragend');
    this.elementVersionNode.off('mouseenter');
    this.elementVersionNode.off('mouseleave');
    this.elementVersionNode.destroy();
    this.onScheduleRender();
  }

  get styleNamespace() {
    return podNames['invention-graph-element-version-konva'];
  }

  get classNames() {
    let classNames = [this.styleNamespace];
    if (this.args.isDisconnected) classNames.push('is-disconnected');
    if (this.args.isSelected) classNames.push('is-selected');
    if (this.args.isCollapsed) classNames.push('is-collapsed');
    return classNames.join(' ');
  }

  get elementVersion() {
    return this.models.find(this.args.elementVersionId);
  }

  @computed('args.markersList.[]')
  get referenceId() {
    return this.args.markersList.length && this.args.markersList[0];
  }

  @computed('args.{drawings,methods}', 'referenceId')
  get referenceSource() {
    let source;
    const state = this.redux.getState();
    const marker = getMarker(state, this.referenceId);
    if (marker) {
      source = getDrawing(state, marker.drawing);
    }
    return source;
  }

  @computed('referenceSource.updatedAt', 'referenceId')
  get referenceUpdatedAt() {
    return (
      this.referenceId && this.referenceSource && this.referenceSource.updatedAt
    );
  }

  @computed('args.markersList.[]')
  get referencesString() {
    return (
      this.args.markersList &&
      this.args.markersList.map((ref) => ref.id).join(',')
    );
  }

  @computed('referenceId')
  get hasReference() {
    return this.referenceId ? true : false;
  }

  @computed('args.constraintsList.[]')
  get constraintsList() {
    return this.args.constraintsList;
  }

  @computed('constraintsList.[]')
  get constraintModels() {
    const state = this.redux.getState();
    return this.constraintsList.map((featureId) => {
      const feature = getFeature(state, featureId);
      return (
        this.models.find(featureId) ||
        this.models.findOrCreate(featureId, 'feature', feature)
      );
    });
  }

  @computed('constraintModels.@each.value')
  get constraintValues() {
    return (
      this.constraintModels && this.constraintModels.map((model) => model.value)
    );
  }

  @computed('constraintsList.[]')
  get constraint() {
    return (
      this.constraintsList &&
      this.constraintsList.length &&
      this.constraintsList[0]
    );
  }

  @computed('constraintModels.[]')
  get constraintModel() {
    return (
      this.constraintModels &&
      this.constraintModels.length &&
      this.constraintModels[0]
    );
  }

  @computed('constraintModel.value')
  get constraintValue() {
    return this.constraintModel && `must be ${this.constraintModel.value}`;
  }

  get strokeColor() {
    let color = ELEMENT_VERSION_NODE_STROKE;

    if (this.args.category === 'machine') {
      color = ELEMENT_VERSION_CATEGORY_MACHINE;
    }
    if (this.args.category === 'process') {
      color = ELEMENT_VERSION_CATEGORY_PROCESS;
    }
    if (this.args.category === 'article-of-manufacture') {
      color = ELEMENT_VERSION_CATEGORY_ARTICLE;
    }

    if (this.args.isDisconnected) {
      color = ELEMENT_NODE_GREY_STROKE;
    }
    return color;
  }

  get strokeColorLight() {
    let color = ELEMENT_VERSION_COLLAPSED_NODE_STROKE;

    if (this.args.isDisconnected) {
      color = ELEMENT_NODE_GREY_STROKE_LIGHT;
    }
    return color;
  }

  // get strokeColorWarning() {
  //   let color = WARNING_NODE_STROKE;
  //   if (this.args.reviewId && !this.args.isVisited) {
  //     color = ELEMENT_NODE_NOVELTY_STROKE_LIGHT;
  //   }
  //   return color;
  // }

  get fillColor() {
    let color = ELEMENT_VERSION_NODE_FILL;

    if (this.args.category === 'machine') {
      color = ELEMENT_VERSION_CATEGORY_MACHINE_LIGHT;
    }
    if (this.args.category === 'process') {
      color = ELEMENT_VERSION_CATEGORY_PROCESS_LIGHT;
    }
    if (this.args.category === 'article-of-manufacture') {
      color = ELEMENT_VERSION_CATEGORY_ARTICLE_LIGHT;
    }

    if (this.args.isDisconnected) {
      color = ELEMENT_NODE_GREY_FILL;
    }
    return color;
  }

  get fillColorLight() {
    let color = ELEMENT_VERSION_NODE_FILL_LIGHT;

    if (this.args.category === 'machine') {
      color = ELEMENT_VERSION_CATEGORY_MACHINE_LIGHTER;
    }
    if (this.args.category === 'process') {
      color = ELEMENT_VERSION_CATEGORY_PROCESS_LIGHTER;
    }
    if (this.args.category === 'article-of-manufacture') {
      color = ELEMENT_VERSION_CATEGORY_ARTICLE_LIGHTER;
    }

    if (this.args.isDisconnected) {
      color = ELEMENT_NODE_GREY_FILL_LIGHT;
    }
    return color;
  }

  // get fillColorWarning() {
  //   let color = WARNING_NODE_FILL;
  //   if (this.args.reviewId && !this.args.isVisited) {
  //     color = ELEMENT_NODE_GREY_FILL_LIGHT;
  //   }
  //   if (this.args.isDisconnected) {
  //     color = ELEMENT_NODE_GREY_FILL_LIGHT;
  //   }
  //   return color;
  // }

  get referenceFillColor() {
    return this.referenceId ? '#ffffff' : this.fillColorLight;
  }

  get textColor() {
    let color = ELEMENT_VERSION_NODE_COLOR;

    if (this.args.isDisconnected) {
      color = ELEMENT_NODE_GREY_COLOR;
    }
    return color;
  }

  get headerTextColor() {
    let color = ELEMENT_VERSION_NODE_HEADER_COLOR;

    if (this.args.category === 'machine') {
      color = ELEMENT_VERSION_CATEGORY_MACHINE;
    }
    if (this.args.category === 'process') {
      color = ELEMENT_VERSION_CATEGORY_PROCESS;
    }
    if (this.args.category === 'article-of-manufacture') {
      color = ELEMENT_VERSION_CATEGORY_ARTICLE;
    }

    if (this.args.isDisconnected) {
      color = ELEMENT_NODE_GREY_STROKE;
    }
    return color;
  }

  get collapsedNodeFill() {
    let color = ELEMENT_COLLAPSED_NODE_FILL;

    if (this.args.category === 'machine') {
      color = ELEMENT_VERSION_CATEGORY_MACHINE_LIGHTER;
    }
    if (this.args.category === 'process') {
      color = ELEMENT_VERSION_CATEGORY_PROCESS_LIGHTER;
    }
    if (this.args.category === 'article-of-manufacture') {
      color = ELEMENT_VERSION_CATEGORY_ARTICLE_LIGHTER;
    }

    if (this.args.isDisconnected) {
      color = ELEMENT_NODE_GREY_FILL;
    }
    return color;
  }

  get collapsedNodeStroke() {
    let color = ELEMENT_COLLAPSED_NODE_STROKE;

    if (this.args.category === 'machine') {
      color = ELEMENT_VERSION_CATEGORY_MACHINE;
    }
    if (this.args.category === 'process') {
      color = ELEMENT_VERSION_CATEGORY_PROCESS;
    }
    if (this.args.category === 'article-of-manufacture') {
      color = ELEMENT_VERSION_CATEGORY_ARTICLE;
    }

    if (this.args.isDisconnected) {
      color = ELEMENT_NODE_GREY_STROKE;
    }
    return color;
  }

  constraintBackgroundNodeHeight(lines) {
    const headerHeight = 26;
    let height = 190;
    switch (lines) {
      case 1:
        height = 60;
        break;
      case 2:
        height = 92;
        break;
      case 3:
        height = 125;
        break;
      case 4:
        height = 158;
        break;
      case 5:
        height = 190;
        break;
    }
    return height + headerHeight + 1;
  }

  @computed('constraintsList.[]')
  get constraintsCount() {
    return (this.constraintsList && this.constraintsList.length) || 0;
  }

  @computed('constraintsCount')
  get hasConstraint() {
    return this.constraintsCount >= 1;
  }

  @computed('constraintsCount')
  get hasTwoConstraints() {
    return this.constraintsCount >= 2;
  }

  @computed('constraintsCount')
  get hasThreeOrMoreConstraints() {
    return this.constraintsCount >= 3;
  }

  get textWidth() {
    return this.width - this.horizontalPadding * 2;
  }

  get constraintTransform() {
    const x = 0;
    const y = this.height / 2;
    return { x, y };
  }

  get referencesTransform() {
    const x = (-1 * this.width) / 3;
    const y = this.height / 2;
    return { x, y };
  }

  referenceTranslate(index = 0) {
    const offset = index * 15;
    const x = (-1 * this.referenceWidth) / 2 + offset;
    const y = (-1 * this.referenceHeight) / 2;
    return { x, y };
  }

  get referenceOrigin() {
    const x = this.referenceWidth / 2;
    const y = this.referenceWidth / 2;
    return { x, y };
  }

  referenceRotation(index = 0) {
    let rotation = -5;
    switch (index) {
      case 1:
        rotation = 0;
        break;
      case 2:
        rotation = 5;
        break;
    }
    return rotation;
  }

  setup() {
    const elementVersionNode = new Konva.Group({
      id: this.args.elementVersionId,
      x: this.args.x,
      y: this.args.y,
      draggable: true,
      name: 'node',
      nodeType: 'element-version',
      transformsEnabled: 'position',
    });

    const selectedNode = new Konva.Rect({
      id: `select-${this.args.elementVersionId}`,
      width: this.width,
      height: this.height,
      fill: this.fillColor,
      stroke: this.strokeColor,
      strokeWidth: 26,
      visible: !this.args.known && this.args.isSelected,
      // opacity: 0.5,
      listening: false,
      strokeScaleEnabled: false,
      transformsEnabled: 'position',
      offset: {
        x: this.width / 2,
        y: this.height / 2,
      },
    });

    const knownSelectedNode = new Konva.RegularPolygon({
      radius: this.radius,
      fill: this.fillColor,
      stroke: this.strokeColor,
      strokeWidth: 26,
      visible: this.args.known && this.args.isSelected ? true : false,
      // opacity: 0.5,
      listening: false,
      sides: 8,
      rotation: 22.5,
      strokeScaleEnabled: false,
    });

    // TODO opacity kills end-to-end tests
    if (ENV.environment !== 'test') {
      selectedNode.opacity(0.5);
      knownSelectedNode.opacity(0.5);
    }
    const elementBackgroundNode = new Konva.Rect({
      width: this.width,
      height: this.height,
      fill: this.fillColor,
      stroke: this.strokeColor,
      strokeWidth: 7,
      // dash: [15, 10],
      // dashEnabled: true,
      transformsEnabled: 'position',
      visible: !this.args.known,
      offset: {
        x: this.width / 2,
        y: this.height / 2,
      },
    });

    const knownElementBackgroundNode = new Konva.RegularPolygon({
      radius: this.radius,
      fill: this.fillColor,
      stroke: this.strokeColor,
      // dash: [15, 10],
      // dashEnabled: true,
      sides: 8,
      rotation: 22.5,
      strokeWidth: 7,
      visible: this.args.known,
    });

    const referenceNode = new Konva.Group({
      visible: this.hasReference,
      y: this.referencesTransform.y / 2,
      x: this.referencesTransform.x / 2,
      transformsEnabled: 'position',
      listening: false,
      offset: {
        x: this.width / 2,
        y: 0,
      },
    });

    const referencePreviewStrokeNode = new Konva.Rect({
      width: 200,
      height: 200,
      stroke: this.strokeColor,
      strokeWidth: 6,
      transformsEnabled: 'position',
      listening: false,
    });

    const referencePreviewBackgroundNode = new Konva.Rect({
      width: 200,
      height: 200,
      stroke: this.strokeColor,
      fill: '#FFF',
      strokeWidth: 6,
      transformsEnabled: 'position',
      listening: false,
    });

    const referencePreviewImageNode = new Konva.Image({
      width: 200,
      height: 200,
      transformsEnabled: 'position',
      listening: false,
      visible: this._reachedScaleThreshold ? false : true,
    });

    referenceNode.add(referencePreviewBackgroundNode);
    referenceNode.add(referencePreviewImageNode);
    referenceNode.add(referencePreviewStrokeNode);

    const constraintNode = new Konva.Group({
      visible: this.hasConstraint,
      y: this.constraintTransform.y / 2,
      x: this.constraintTransform.x / 2,
      rotation: 5,
      // transformsEnabled: 'position',
      listening: false,
    });

    const constraintHeaderTextNode = new Konva.Text({
      width: 260,
      text: 'User Requirement',
      fontSize: 20,
      lineHeight: 1.235,
      fontFamily: this.fontFamily,
      fill: this.headerTextColor,
      transformsEnabled: 'position',
      listening: false,
      visible: this._reachedScaleThreshold ? false : true,
      x: 20,
      y: 15,
    }).cache();

    const constraintTextNode = new Konva.Text({
      width: 260,
      height: 186,
      ellipsis: true,
      text: textValue(this.constraintValue || ''),
      fontSize: 26,
      lineHeight: 1.235,
      fontFamily: this.fontFamily,
      fill: this.textColor,
      transformsEnabled: 'position',
      listening: false,
      visible: this._reachedScaleThreshold ? false : true,
      x: 20,
      y: 41,
    }).cache();

    const constraintBackgroundNodeHeight = this.constraintBackgroundNodeHeight(
      constraintTextNode.textArr.length
    );
    const constraintBackgroundNode = new Konva.Rect({
      width: 280,
      height: constraintBackgroundNodeHeight,
      fill: this.fillColorLight,
      stroke: this.strokeColor,
      strokeWidth: 6,
      transformsEnabled: 'position',
      listening: false,
    });

    constraintNode.add(constraintBackgroundNode);
    constraintNode.add(constraintHeaderTextNode);
    constraintNode.add(constraintTextNode);

    const constraintNodeTwo = new Konva.Group({
      visible: this.hasTwoConstraints,
      y: this.constraintTransform.y / 2 + 15,
      x: this.constraintTransform.x / 2,
      rotation: -1,
      // transformsEnabled: 'position',
      listening: false,
    });

    const constraintBackgroundNodeTwo = new Konva.Rect({
      width: 280,
      height: constraintBackgroundNodeHeight,
      fill: this.fillColorLight,
      stroke: this.strokeColor,
      strokeWidth: 6,
      transformsEnabled: 'position',
      listening: false,
    });

    constraintNodeTwo.add(constraintBackgroundNodeTwo);

    const nameNode = new Konva.Text({
      width: this.textWidth,
      height: this.height,
      align: 'center',
      verticalAlign: 'middle',
      text: this.args.name,
      fontSize: 35,
      lineHeight: 1.1,
      fontFamily: this.fontFamily,
      fontStyle: '500',
      fill: this.textColor,
      transformsEnabled: 'position',
      listening: false,
      offset: {
        y: this.height / 2,
        x: this.textWidth / 2,
      },
    });

    const collapsedNode = new Konva.Circle({
      visible: this.args.isCollapsed,
      x: 15,
      y: 7.5,
      radius: 200,
      fill: this.collapsedNodeFill,
      stroke: this.collapsedNodeStroke,
      strokeWidth: 7,
      // dash: [15, 15],
      // dashEnabled: false,
      transformsEnabled: 'position',
    });

    const collapsedTwoNode = new Konva.Circle({
      visible: this.args.isCollapsed,
      x: 30,
      y: 15,
      radius: 200,
      fill: this.collapsedNodeFill,
      stroke: this.collapsedNodeStroke,
      strokeWidth: 7,
      // dash: [15, 15],
      // dashEnabled: false,
      transformsEnabled: 'position',
    });

    const collapsedThreeNode = new Konva.Circle({
      visible: this.args.isCollapsed,
      x: 45,
      y: 22.5,
      radius: 200,
      fill: this.collapsedNodeFill,
      stroke: this.collapsedNodeStroke,
      strokeWidth: 7,
      // dash: [15, 15],
      // dashEnabled: false,
      transformsEnabled: 'position',
    });

    elementVersionNode.add(selectedNode);
    elementVersionNode.add(knownSelectedNode);
    elementVersionNode.add(collapsedThreeNode);
    elementVersionNode.add(collapsedTwoNode);
    elementVersionNode.add(collapsedNode);
    elementVersionNode.add(elementBackgroundNode);
    elementVersionNode.add(knownElementBackgroundNode);
    elementVersionNode.add(nameNode);
    elementVersionNode.add(constraintNodeTwo);
    elementVersionNode.add(constraintNode);
    elementVersionNode.add(referenceNode);

    // add events
    if (this.args.onContextClick) {
      elementBackgroundNode.on('contextmenu', (event) => {
        this.args.onContextClick(this.args.elementVersionId, event);
      });
    }

    elementVersionNode.on('click', () => {
      if (this.args.onClick)
        this.args.onClick(this.args.elementVersionId, this.args.isSelected);
    });
    elementVersionNode.on('dragstart', (event) => {
      if (this.args.actionMode) {
        event.target.stopDrag();
      }
      this.args.onDragStart(event, this.args.elementVersionId);
    });
    elementVersionNode.on('dragmove', (event) => {
      if (this.args.actionMode) {
        return event.target.stopDrag();
      }
      this.args.onDragMove(event, this.args.elementVersionId);
    });
    elementVersionNode.on('dragend', (event) => {
      if (this.args.actionMode) {
        return;
      }
      this.args.onDragEnd(event, this.args.elementVersionId);
    });
    elementVersionNode.on('mouseenter', (event) =>
      this.args.onMouseenter(event, this.args.elementVersionId)
    );
    elementVersionNode.on('mouseleave', (event) =>
      this.args.onMouseleave(event, this.args.elementVersionId)
    );

    this.elementsLayer.add(elementVersionNode);

    elementVersionNode.visible(elementVersionNode.isClientRectOnScreen());

    this.referenceNode = referenceNode;
    this.referencePreviewBackgroundNode = referencePreviewBackgroundNode;
    this.referencePreviewStrokeNode = referencePreviewStrokeNode;
    this.referencePreviewImageNode = referencePreviewImageNode;
    this.nameNode = nameNode;
    this.constraintNode = constraintNode;
    this.constraintHeaderTextNode = constraintHeaderTextNode;
    this.constraintTextNode = constraintTextNode;
    this.constraintBackgroundNode = constraintBackgroundNode;
    this.constraintNodeTwo = constraintNodeTwo;
    this.constraintBackgroundNodeTwo = constraintBackgroundNodeTwo;
    this.collapsedNode = collapsedNode;
    this.collapsedTwoNode = collapsedTwoNode;
    this.collapsedThreeNode = collapsedThreeNode;
    this.elementBackgroundNode = elementBackgroundNode;
    this.knownElementBackgroundNode = knownElementBackgroundNode;
    this.selectedNode = selectedNode;
    this.knownSelectedNode = knownSelectedNode;
    this.elementVersionNode = elementVersionNode;

    if (this.referenceId) {
      this.onUpdateReferenceImage.perform(this.referenceId);
    }

    this.onScheduleRender();
  }

  handleSelected() {
    // TODO Disabled this layer switch b/c of a visible flash, is it even necessary?
    // const elementVersionNode = this.elementVersionNode;
    // elementVersionNode.moveTo(this.interactiveLayer);
    // this.activeLayer = this.interactiveLayer;
    this.selectedNode.visible(!this.args.known);
    this.knownSelectedNode.visible(this.args.known);
    this.onScheduleRender();
  }

  handleDeselected() {
    // const elementVersionNode = this.elementVersionNode;
    // elementVersionNode.moveTo(this.elementsLayer);
    // this.activeLayer = this.elementsLayer;
    this.selectedNode.visible(false);
    this.knownSelectedNode.visible(false);
    this.onScheduleRender();
  }

  @action
  onRemoveReference() {
    this.referencePreviewImageNode.image(null);
    this.onScheduleRender();
  }

  @task
  *onUpdateReferenceImage(referenceId) {
    let blobUrl;
    const state = this.redux.getState();

    if (getMarker(state, referenceId)) {
      blobUrl = yield this.referencesCache.getBlobUrl.perform(referenceId);
    }

    if (!blobUrl) {
      return;
    }

    const imageFile = new Image();

    imageFile.onload = () => {
      this.referencePreviewImageNode.image(imageFile);
      this.referencePreviewImageNode.cache();

      if (this.args.isDisconnected) {
        const filters = [Konva.Filters.Grayscale];
        this.referencePreviewImageNode.filters(filters);
      }
      this.onScheduleRender();
    };

    imageFile.src = blobUrl;
  }

  @action
  updateVisibility() {
    this.elementVersionNode.visible(
      this.elementVersionNode.isClientRectOnScreen()
    );
  }

  updateColors() {
    this.selectedNode.stroke(this.strokeColor);
    this.knownSelectedNode.stroke(this.strokeColor);
    this.collapsedNode.fill(this.collapsedNodeFill);
    this.collapsedNode.stroke(this.collapsedNodeStroke);
    this.collapsedTwoNode.fill(this.collapsedNodeFill);
    this.collapsedTwoNode.stroke(this.collapsedNodeStroke);
    this.collapsedThreeNode.fill(this.collapsedNodeFill);
    this.collapsedThreeNode.stroke(this.collapsedNodeStroke);

    this.elementBackgroundNode.fill(this.fillColor);
    this.elementBackgroundNode.stroke(this.strokeColor);

    this.knownElementBackgroundNode.fill(this.fillColor);
    this.knownElementBackgroundNode.stroke(this.strokeColor);

    this.constraintBackgroundNode.fill(this.fillColorLight);
    this.constraintBackgroundNode.stroke(this.strokeColor);
    this.constraintBackgroundNodeTwo.fill(this.fillColorLight);
    this.constraintBackgroundNodeTwo.stroke(this.strokeColor);
    this.constraintHeaderTextNode.clearCache();
    this.constraintHeaderTextNode.fill(this.headerTextColor);
    this.constraintHeaderTextNode.cache();

    this.nameNode.fill(this.textColor);

    this.referencePreviewStrokeNode.stroke(this.strokeColor);
  }

  @action
  onUpdate(
    elem,
    [
      constraintsCount,
      constraintValue,
      known,
      isCollapsed,
      visibleAreaIndex,
      name,
      isSelected,
      x,
      y,
      isCreatingFrom,
      isCreatingTo,
      referenceId,
      referencesString,
      referenceUpdatedAt,
      reachedScaleThreshold,
      isDisconnected,
      category,
    ]
  ) {
    let detailsChanged = false;
    let visibleAreaChanged = false;

    if (this._known !== known) {
      this._known = known;

      this.selectedNode.visible(this.args.isSelected && !this.args.known);
      this.knownSelectedNode.visible(this.args.isSelected && this.args.known);
      this.knownElementBackgroundNode.visible(this.args.known);
      this.elementBackgroundNode.visible(!this.args.known);
    }

    if (this._isCollapsed !== isCollapsed) {
      this._isCollapsed = isCollapsed;
      this.collapsedNode.visible(isCollapsed);
      this.collapsedTwoNode.visible(isCollapsed);
      this.collapsedThreeNode.visible(isCollapsed);
    }

    if (this._visibleAreaIndex !== visibleAreaIndex) {
      this._visibleAreaIndex = visibleAreaIndex;
      visibleAreaChanged = true;
    }

    if (this._isDisconnected !== isDisconnected) {
      this._isDisconnected = isDisconnected;
      this.updateColors();
      const filters = isDisconnected ? [Konva.Filters.Grayscale] : [];
      this.referencePreviewImageNode.filters(filters);
      detailsChanged = true;
    }

    if (this._category !== category) {
      this._category = category;
      this.updateColors();
      detailsChanged = true;
    }

    if (visibleAreaChanged) {
      this.updateVisibility();
    }

    if (this._constraintsCount !== constraintsCount) {
      detailsChanged = true;
      this._constraintsCount = constraintsCount;
      this.constraintNode.visible(this.hasConstraint);
      this.constraintNodeTwo.visible(this.hasTwoConstraints);
    }

    if (this._constraintValue !== constraintValue) {
      detailsChanged = true;
      this._constraintValue = constraintValue;
      this.constraintNode.visible(this.hasConstraint);
      this.constraintNodeTwo.visible(this.hasTwoConstraints);
      this.constraintTextNode.clearCache();
      this.constraintTextNode.text(textValue(constraintValue || ''));
      this.constraintTextNode.cache();
      this.constraintBackgroundNode.height(
        this.constraintBackgroundNodeHeight(
          this.constraintTextNode.textArr.length
        )
      );
      this.constraintBackgroundNodeTwo.height(
        this.constraintBackgroundNodeHeight(
          this.constraintTextNode.textArr.length
        )
      );
    }

    if (this._isSelected !== isSelected) {
      this._isSelected = isSelected;
      if (isSelected) {
        this.handleSelected();
      } else {
        this.handleDeselected();
      }
    }

    if (this._isCreatingFrom !== isCreatingFrom) {
      this._isCreatingFrom = isCreatingFrom;
      if (isCreatingFrom) {
        this.selectedNode.visible(!this.args.known);
        this.knownSelectedNode.visible(this.args.known);
        this.onScheduleRender();
      } else {
        if (!this._isSelected) {
          this.selectedNode.visible(false);
          this.knownSelectedNode.visible(false);
          this.onScheduleRender();
        }
      }
    }

    if (this._isCreatingTo !== isCreatingTo) {
      this._isCreatingTo = isCreatingTo;
      if (isCreatingTo) {
        this.selectedNode.visible(!this.args.known);
        this.knownSelectedNode.visible(this.args.known);
        this.onScheduleRender();
      } else {
        if (!this._isCreatingTo && !this._isSelected) {
          this.selectedNode.visible(false);
          this.knownSelectedNode.visible(false);
          this.onScheduleRender();
        }
      }
    }

    if (this._x !== x || this._y !== y) {
      this._x = x;
      this._y = y;
      this.elementVersionNode.x(x);
      this.elementVersionNode.y(y);
      this.onScheduleRender();
    }

    if (this._name !== name) {
      detailsChanged = true;
      this._name = name;
      this.nameNode.clearCache();
      this.nameNode.text(name);
      this.nameNode.cache();
    }

    if (this._referencesString !== referencesString) {
      this._referencesString = referencesString;
      this.referenceNode.visible(this.hasReference);
      this.onScheduleRender();
    }

    if (this._referenceId !== referenceId) {
      this._referenceId = referenceId;
      this.onUpdateReferenceImage.perform(
        this.referenceId
      );
    }

    if (this._referenceUpdatedAt !== referenceUpdatedAt) {
      this._referenceUpdatedAt = referenceUpdatedAt;
      this.onUpdateReferenceImage.perform(
        this.referenceId
      );
    }

    if (this._reachedScaleThreshold !== reachedScaleThreshold) {
      this._reachedScaleThreshold = reachedScaleThreshold;
      detailsChanged = true;
      if (reachedScaleThreshold) {
        this.constraintHeaderTextNode.visible(false);
        this.constraintTextNode.visible(false);
        this.referencePreviewImageNode.visible(false);
      } else {
        this.constraintHeaderTextNode.visible(true);
        this.constraintTextNode.visible(true);
        this.referencePreviewImageNode.visible(true);
      }
    }

    if (detailsChanged) {
      this.onScheduleRender(this.activeLayer);
    }
  }
}

export default InventionGraphElementVersion;
