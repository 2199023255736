import {
  BLACK_AND_WHITE_STROKE,
  METHOD_NODE_COLOR,
  METHOD_NODE_FILL,
  METHOD_NODE_STROKE,
  METHOD_NODE_STROKE_DISCONNECTED,
  PRIMARY_COLOR,
} from '../../../constants/colors';
import { action, computed } from '@ember/object';
import { alias, bool } from '@ember/object/computed';
import {
  getMethodNode,
  getMethodNodeElement,
  getMethodNodeFeatureId,
  getMethodNodeLabel,
  getSubmethodId,
} from '../../../selectors/method-node';

import Component from '@glimmer/component';
import Konva from 'konva';
import { connect } from 'ember-redux';
import { getFeature } from '../../../selectors/feature';
import { getMethodNodeOrdinal } from '../../../selectors/invention';
import { getSelectedElements } from '../../../selectors/invention-ui';
import { getStepNode } from '../../../utils/method-graph';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';
import uuid from 'uuid/v4';

const dispatchToActions = {};

const stateToComputed = (state, attrs) => ({
  methodNode: getMethodNode(state, attrs.methodNodeId),
  submethodId: getSubmethodId(state, attrs.methodNodeId, attrs.productId),
  element: getMethodNodeElement(state, attrs.methodNodeId),
  featureId: getMethodNodeFeatureId(state, attrs.methodNodeId),
  selectedElements: getSelectedElements(state),
});

class MethodNode extends Component {
  @service store;
  @service redux;
  @service models;
  @service tracking;
  @service applicationState;
  @service settings;

  constructor(owner, args) {
    super(owner, args);
    this.onScheduleRender = this.args.onScheduleRender;
  }

  @action
  didInsert() {
    this.setup();
    this._updatedAt = this.methodNode.updatedAt;
    this._isSelected = this.args.isSelected;
    this._isCreatingSource = this.args.isCreatingSource;
    this._isDisconnected = this.args.isDisconnected;
    this._stepNumber = this.stepNumber;
    this._showStepNumber = this.showStepNumber;
    this._showSubmethod = this.showSubmethod;
    this._elementIsSelected = this.elementIsSelected;
    this._canTransform = this.args.canTransform;
    this._methodNodeX = this.methodNode.x;
    this._methodNodeY = this.methodNode.y;
    this._label = this.label;
    this._elementName = this.elementName;
    this._methodAutoArrangeIndex = this.methodAutoArrangeIndex;
    this._undoIndex = this.undoIndex;
    this._index = this.args.index + 100;
  }

  @action
  willDestroyNode() {
    this.backgroundNode.off('click');
    this.containerNode.off('dragmove');
    this.containerNode.off('dragend');
    this.elementLabelNode.off('click');
    this.containerNode.destroy();
    if (this.transformer) {
      this.transformer.destroy();
    }
    this.onScheduleRender();
  }

  get styleNamespace() {
    return podNames['method-node-konva'];
  }

  get classNames() {
    let classNames = [this.styleNamespace];
    if (this.args.isSelected) {
      classNames.push('is-selected');
    } else {
      classNames.push('isnt-selected');
    }
    return classNames.join(' ');
  }

  stepNumberRadius = 20;
  stepNumberSpacing = 30;
  stepNumberFontSize = 20;
  ordinalHeight = 19;
  ordinalFontSize = 18;
  strokeWidth = 2;
  fontFamily = 'Inter';
  fontSize = 18;
  elementFontSize = 15;
  elementLabelHeight = 19;
  domElementId = `method-node-konva-${uuid()}`;
  disconnectedDash = [15, 10];

  @bool('args.onDragEnd') isDraggable;
  @bool('element.id') isElementNode;
  @bool('stepNumber') hasStepNumber;
  @bool('ordinal') hasOrdinal;
  @bool('submethodHasNodes') showSubmethod;
  @alias('methodNode.x') x;
  @alias('methodNode.y') y;
  @alias('methodNode.width') width;
  @alias('methodNode.height') height;
  @alias('methodNode.type') type;
  @alias('element.name') elementName;
  @alias('element.id') elementId;
  @alias('applicationState.methodAutoArrangeIndex') methodAutoArrangeIndex;
  @alias('applicationState.undoIndex') undoIndex;

  @computed('args.isDisconnected')
  get dashEnabled() {
    return this.args.isDisconnected ? true : false;
  }

  @computed('elementId', 'selectedElements.[]')
  get elementIsSelected() {
    return (
      this.elementId &&
      this.selectedElements &&
      this.selectedElements.includes(this.elementId)
    );
  }

  @computed('isElementNode', 'args.previewMode')
  get showElementNode() {
    // return false;
    return this.isElementNode && !this.args.previewMode;
  }

  @computed('hasStepNumber', 'args.previewMode')
  get showStepNumber() {
    return this.hasStepNumber && !this.args.previewMode;
  }

  @computed('hasOrdinal', 'args.previewMode')
  get showOrdinal() {
    return this.hasOrdinal && this.args.previewMode === true;
  }

  updateModels() {
    this.models.update(this.args.methodNodeId, this.methodNode);
  }

  @computed('methodNode.id')
  get methodNodeModel() {
    return (
      this.methodNode &&
      this.models.findOrCreate(
        this.methodNode.id,
        'method-node',
        this.methodNode
      )
    );
  }

  @computed('featureId')
  get featureModel() {
    const state = this.redux.getState();
    return (
      this.featureId &&
      this.models.findOrCreate(
        this.featureId,
        'feature',
        getFeature(state, this.featureId)
      )
    );
  }

  @computed(
    'args.{isStartNode,methodNodeId}',
    'domElementId',
    'featureModel.value'
  )
  get label() {
    const state = this.redux.getState();
    return getMethodNodeLabel(
      state,
      this.args.methodNodeId,
      this.featureModel,
      this.domElementId,
      this.args.isStartNode
    );
  }

  get elementLabelColor() {
    return this.elementIsSelected ? PRIMARY_COLOR : METHOD_NODE_COLOR;
  }

  get labelColor() {
    return METHOD_NODE_COLOR;
  }

  get elementLabelFillColor() {
    return 'transparent';
  }

  get backgroundFillColor() {
    return 'transparent';
  }

  get backgroundStrokeColor() {
    let color = METHOD_NODE_STROKE;

    if (this.args.isDisconnected) {
      color = METHOD_NODE_STROKE_DISCONNECTED;
    }

    if (this.args.isSelected) {
      color = PRIMARY_COLOR;
    }

    if (this.args.isCreatingSource) {
      color = PRIMARY_COLOR;
    }

    if (this.settings.blackAndWhiteMode) {
      color = BLACK_AND_WHITE_STROKE;
    }
    return color;
  }

  get ordinalColor() {
    return METHOD_NODE_COLOR;
  }

  get stepNumberColor() {
    return this.args.isSelected ? PRIMARY_COLOR : METHOD_NODE_COLOR;
  }

  get stepNumberFillColor() {
    return METHOD_NODE_FILL;
  }

  get stepNumberStrokeColor() {
    return this.args.isSelected ? PRIMARY_COLOR : METHOD_NODE_COLOR;
  }

  get elementLabelY() {
    return -1 * this.elementLabelHeight - this.strokeWidth - 1;
  }

  get ordinalY() {
    return -1 * this.ordinalHeight - this.strokeWidth - 1;
  }

  @computed('submethodId')
  get submethodModel() {
    return (
      this.submethodId && this.store.peekRecord('method', this.submethodId)
    );
  }

  @computed('submethodModel.methodNodesList.[]')
  get submethodHasNodes() {
    return (
      this.submethodModel && this.submethodModel.methodNodesList.length > 1
    );
  }

  @computed('args.{methodId,methodNodeId}')
  get methodModel() {
    return this.store.peekRecord('method', this.args.methodId);
  }

  @computed('methodModel.ungroupedStepsList.[]', 'args.methodNodeId')
  get stepNumber() {
    const step =
      this.methodModel &&
      this.methodModel.ungroupedStepsList.find(
        (step) => step.modelId === this.args.methodNodeId
      );
    return step && step.number;
  }

  @computed('methodModel.traversedNodesList.[]', 'args.{methodId,methodNodeId}')
  get ordinal() {
    const state = this.redux.getState();
    return (
      this.methodModel &&
      getMethodNodeOrdinal(
        state,
        this.args.methodId,
        this.args.methodNodeId,
        this.methodModel.traversedNodesList
      )
    );
  }

  setup() {
    const step = {
      id: this.args.methodNodeId,
      index: this.args.index,
      isStartNode: this.args.isStartNode,
      isDisconnected: this.args.isDisconnected,
      ordinal: this.ordinal,
      label: this.label,
      elementName: this.elementName,
      stepNumber: this.stepNumber,
      submethodHasNodes: this.submethodHasNodes,
      x: this.x,
      y: this.y,
      width: this.width,
      height: 30,
    };

    const {
      containerNode,
      backgroundNode,
      submethodNode,
      labelNode,
      elementLabelNode,
      stepNumberNode,
      ordinalNode,
      ordinalTextNode,
      stepNumberBackgroundNode,
      stepNumberTextNode,
      elementLabelBackgroundNode,
      elementLabelTextNode,
      transformNode,
    } = getStepNode(step, {
      isDraggable: this.isDraggable,
      showSubmethod: this.showSubmethod,
      showElementNode: this.showElementNode,
      showStepNumber: this.showStepNumber,
      showOrdinal: this.showOrdinal,
      blackAndWhiteMode: this.settings.blackAndWhiteMode
    });

    // add events
    if (this.args.onClick) {
      backgroundNode.on('click', () => {
        this.args.onClick(
          this.args.methodNodeId,
          this.args.isSelected,
          this.type
        );
      });
    }

    if (this.isElementNode && this.args.onSelectElement) {
      elementLabelNode.on('click', () =>
        this.args.onSelectElement(this.elementId, this.elementIsSelected)
      );
    }

    if (this.args.onTransformEnd) {
      transformNode.on('transform', () => {
        const width = transformNode.scaleX() * this.methodNode.width;
        this.updateWidth(width);
        this.updateHeight();
      });

      transformNode.on('transformend', () => {
        const width = transformNode.scaleX() * this.methodNode.width;
        const x = containerNode.x() + transformNode.x();
        const y = containerNode.y();
        const attributes = {
          x,
          y,
          width,
        };

        this.args.onTransformEnd(this.args.methodNodeId, attributes);

        transformNode.scaleX(1);
        transformNode.scaleY(1);
        transformNode.width(width);
        this.tracking.trackEvent('methodNode_resized');
      });
    }

    containerNode.on('dragmove', () => {
      const x = containerNode.x();
      const y = containerNode.y();
      const dx = x - this._methodNodeX;
      const dy = y - this._methodNodeY;
      this._methodNodeX = x;
      this._methodNodeY = y;
      this.methodNodeModel.setProperties({ x, y });
      if (this.args.isSelected && this.args.isMultiselected) {
        if (this.args.onMultiselectDrag) {
          this.args.onMultiselectDrag(dx, dy, this.args.methodNodeId);
        }
      }
    });

    if (this.args.onDragEnd) {
      containerNode.on('dragend', () => {
        const x = containerNode.x();
        const y = containerNode.y();
        this._methodNodeX = x;
        this._methodNodeY = y;
        if (this.args.isSelected && this.args.isMultiselected) {
          this.args.onMultiselectDragEnd();
        } else {
          this.args.onDragEnd(this.args.methodNodeId, { x, y });
          this.tracking.trackEvent('methodNode_dragged');
        }
        this.methodNodeModel.setProperties({ x, y });
      });
    }

    this.args.layer.add(containerNode);

    containerNode.add(submethodNode);
    containerNode.add(backgroundNode);
    containerNode.add(labelNode);
    containerNode.add(elementLabelNode);
    containerNode.add(stepNumberNode);
    containerNode.add(ordinalNode);

    this.containerNode = containerNode;
    this.submethodNode = submethodNode;
    this.backgroundNode = backgroundNode;
    this.transformNode = transformNode;
    this.labelNode = labelNode;
    this.elementLabelNode = elementLabelNode;
    this.elementLabelBackgroundNode = elementLabelBackgroundNode;
    this.elementLabelTextNode = elementLabelTextNode;
    this.stepNumberNode = stepNumberNode;
    this.stepNumberTextNode = stepNumberTextNode;
    this.stepNumberBackgroundNode = stepNumberBackgroundNode;
    this.ordinalNode = ordinalNode;
    this.ordinalTextNode = ordinalTextNode;

    if (this.args.onTransformEnd) {
      const transformer = new Konva.Transformer({
        anchorStroke: PRIMARY_COLOR,
        borderStroke: PRIMARY_COLOR,
        borderStrokeWidth: 0,
        visible: this.args.isSelected,
        // zIndex: this.args.index + 200,
        rotateEnabled: false,
        enabledAnchors: ['middle-right'],
      });

      this.args.layer.add(transformer);

      transformer.nodes([transformNode]);

      this.transformer = transformer;
    }

    this.updateHeight();
    this.updateColors();

    this.onScheduleRender();
  }

  updateColors() {
    this.submethodNode.stroke(this.backgroundStrokeColor);
    this.backgroundNode.stroke(this.backgroundStrokeColor);
    this.stepNumberTextNode.fill(this.stepNumberColor);
    this.stepNumberBackgroundNode.stroke(this.stepNumberStrokeColor);
    this.elementLabelTextNode.fill(this.elementLabelColor);
  }

  updateHeight() {
    const height = this.labelNode.height();
    this.submethodNode.height(height);
    this.backgroundNode.height(height);
    this.transformNode.height(height);
    this.methodNodeModel.setProperties({ height });
  }

  updateWidth(width) {
    this.submethodNode.width(width);
    this.backgroundNode.width(width);
    this.labelNode.width(width);
    this.elementLabelTextNode.width(width - this.stepNumberSpacing);
    this.elementLabelBackgroundNode.width(width);
    this.methodNodeModel.setProperties({ width });
  }

  updateLabel() {
    this.labelNode.text(this.label);
  }

  updateElementName() {
    this.elementLabelTextNode.text(this.elementName);
  }

  updatePosition() {
    this.containerNode.x(this.methodNode.x);
    this.containerNode.y(this.methodNode.y);

    this._methodNodeX = this.methodNode.x;
    this._methodNodeY = this.methodNode.y;

    this.submethodNode.width(this.methodNode.width);
    this.backgroundNode.width(this.methodNode.width);
    this.labelNode.width(this.methodNode.width);
    this.elementLabelTextNode.width(
      this.methodNode.width - this.stepNumberSpacing
    );
    this.elementLabelBackgroundNode.width(this.methodNode.width);
    this.transformNode.width(this.methodNode.width);

    this.submethodNode.height(this.labelNode.height());
    this.backgroundNode.height(this.labelNode.height());
    this.transformNode.height(this.labelNode.height());

    this.transformer.forceUpdate();
  }

  onUpdatedAt() {
    // this.updatePosition();
  }

  @action
  onUpdate(
    elem,
    [
      isSelected,
      isCreatingSource,
      isDisconnected,
      stepNumber,
      showStepNumber,
      showSubmethod,
      elementIsSelected,
      canTransform,
      label,
      elementName,
      methodAutoArrangeIndex,
      index,
      updatedAt,
      undoIndex,
    ]
  ) {
    let detailsChanged = false;

    if (this._canTransform !== canTransform) {
      this._canTransform = canTransform;
      this.transformer.visible(canTransform);
      detailsChanged = true;
    }

    if (this._isSelected !== isSelected) {
      this._isSelected = isSelected;
      this.updateColors();
      detailsChanged = true;
    }

    if (this._isCreatingSource !== isCreatingSource) {
      this._isCreatingSource = isCreatingSource;
      this.updateColors();
      detailsChanged = true;
    }

    if (this._isDisconnected !== isDisconnected) {
      this._isDisconnected = isDisconnected;

      this.updateColors();
      this.submethodNode.dashEnabled(this.dashEnabled);
      this.backgroundNode.dashEnabled(this.dashEnabled);

      detailsChanged = true;
    }

    if (this._stepNumber !== stepNumber) {
      this._stepNumber = stepNumber;
      this.stepNumberNode.visible(this.showStepNumber);
      if (this.stepNumber) {
        this.stepNumberTextNode.text(this.stepNumber);
      }
      detailsChanged = true;
    }

    if (this._showStepNumber !== showStepNumber) {
      this._showStepNumber = showStepNumber;
      this.stepNumberNode.visible(this.showStepNumber);
      if (this.stepNumber) {
        this.stepNumberTextNode.text(this.stepNumber);
      }
      detailsChanged = true;
    }

    if (this._showSubmethod !== showSubmethod) {
      this._showSubmethod = showSubmethod;
      this.submethodNode.visible(this.showSubmethod);
      detailsChanged = true;
    }

    if (this._elementIsSelected !== elementIsSelected) {
      this._elementIsSelected = elementIsSelected;

      this.updateColors();

      detailsChanged = true;
    }

    if (this._label !== label) {
      this._label = label;
      this.updateLabel();
      this.updateHeight();
      detailsChanged = true;
    }

    if (this._elementName !== elementName) {
      this._elementName = elementName;
      this.updateElementName();
      detailsChanged = true;
    }

    if (this._methodAutoArrangeIndex !== methodAutoArrangeIndex) {
      this._methodAutoArrangeIndex = methodAutoArrangeIndex;
      this.updatePosition();
      detailsChanged = true;
    }

    if (this._index !== index) {
      this._index = index;
      this.containerNode.zIndex(this.args.index + 100);
      if (this.transformer) {
        this.transformer.zIndex(this.args.index + 200);
      }
      detailsChanged = true;
    }

    if (this._updatedAt !== updatedAt) {
      this._updatedAt = updatedAt;
      detailsChanged = true;
    }

    if (this._undoIndex !== undoIndex) {
      this._undoIndex = undoIndex;
      this.updateModels();
      this.updatePosition();
      detailsChanged = true;
    }

    if (detailsChanged) {
      this.onScheduleRender(this.args.layer);
    }
  }
}

export default connect(stateToComputed, dispatchToActions)(MethodNode);
