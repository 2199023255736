import Component from '@glimmer/component';
import podNames from 'ember-component-css/pod-names';

class UiSwitch extends Component {

  get styleNamespace() {
    return podNames['ui-switch'];
  }

  get classNames() {
    let classNames = [this.styleNamespace, 'ui-switch', 'switch'];
    return classNames.join(' ');
  }
  
}

export default UiSwitch;
