import { getElement, getElementName } from '../../../selectors/element';

import Component from '@glimmer/component';
import { action } from '@ember/object';
import { connect } from 'ember-redux';
import { getElementColumn } from '../../../selectors/invention-ui';
import { getElementVersion } from '../../../selectors/element-version';
import { htmlSafe } from '@ember/string';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';

const dispatchToActions = {};

const stateToComputed = (state, attrs) => ({
  column: getElementColumn(state, attrs.columnId),
  element: getElement(state, attrs.elementId),
  elementName: getElementName(state, attrs.elementId),
  elementVersion: getElementVersion(state, attrs.elementVersionId),
});

class ElementTableCell extends Component {
  @service redux;
  depthPadding = 20;

  language = 'report_list_abbreviated';

  get styleNamespace() {
    return podNames['element-table-cell'];
  }

  get classNames() {
    let classNames = [this.styleNamespace, 'element-table-cell'];
    if (this.args.isCollapsed) classNames.push('is-collapsed');
    if (this.args.isFirstColumn) classNames.push('is-first-column');
    if (this.column.wrappable && this.column.wrap)
      classNames.push('is-wrapped');
    return classNames.join(' ');
  }

  get depthStyle() {
    const width = this.args.depth && (this.args.depth - 2) * this.depthPadding;
    return width ? htmlSafe(`width: ${width}px`) : '';
  }

  get showCaret() {
    return this.args.isCollapsible && this.args.hasChildren;
  }

  @action
  onCollapseClick() {
    if (this.args.onCollapseNode) {
      this.args.onCollapseNode(this.args.elementId);
    }
  }
}

export default connect(stateToComputed, dispatchToActions)(ElementTableCell);
