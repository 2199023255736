import Component from '@glimmer/component';
import { connect } from 'ember-redux';
import podNames from 'ember-component-css/pod-names';
import { getPriorArt } from '../../../selectors/prior-art';

const stateToComputed = (state, attrs) => ({
  priorArt: getPriorArt(state, attrs.priorArtId),
});

const dispatchToActions = {};

class PriorArtPopover extends Component {
  get styleNamespace() {
    return podNames['prior-art-popover'];
  }
}

export default connect(stateToComputed, dispatchToActions)(PriorArtPopover);
