import Component from '@glimmer/component';
import { action } from '@ember/object';
import podNames from 'ember-component-css/pod-names';
import uuid from 'uuid/v4';

class UiFloatingContextContainer extends Component {
  domElementId = `ui-floating-context-container-${uuid()}`;

  get classNames() {
    let classNames = [this.styleNamespace, 'ui-floating-context-container'];
    return classNames.join(' ');
  }

  get styleNamespace() {
    return podNames['ui-floating-context-container'];
  }

  @action
  onContentResize() {
   //
  }
}

export default UiFloatingContextContainer;
