import Component from '@glimmer/component';
import { action } from '@ember/object';
import { connect } from 'ember-redux';
import { getCustomersList } from '../../../selectors/customer';
import { getProduct } from '../../../selectors/product';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';

const dispatchToActions = {};

const stateToComputed = (state, attrs) => ({
  customersList: getCustomersList(state),
  product: getProduct(state, attrs.productId),
});

class CustomerSelectionModal extends Component {
  @service tracking;
  @service redux;

  get styleNamespace() {
    return podNames['customers-selection-modal'];
  }

  get classNames() {
    let classNames = [this.styleNamespace, 'customers-selection-modal'];
    return classNames.join(' ');
  }

  @action
  didInsert() {
    this.tracking.trackEvent('opened_prior_arts_selection_modal');
  }

  @action
  hideModal() {
    if (this.args.onHide) {
      this.args.onHide(false);
    }
  }

  @action
  onSetShowingCustomers() {
    this.args.onSetShowingCustomers(true);
  }

  @action
  onCustomerItemClick(customerId) {
    const customersList = this.product.customersList;
    const isCustomer = customersList && customersList.includes(customerId);
    let updateCustomersList;
    if (!isCustomer) {
      updateCustomersList = [...customersList, customerId];
    } else {
      updateCustomersList = customersList.filter((id) => id !== customerId);
    }
    this.args.onUpdateProduct(this.args.productId, {
      customersList: updateCustomersList,
    });
  }
}

export default connect(
  stateToComputed,
  dispatchToActions
)(CustomerSelectionModal);
