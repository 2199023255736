import { getElement, getElementsList } from '../../../selectors/element';

import $ from 'jquery';
/* eslint-disable ember/no-observers */
import Component from '@ember/component';
import { connect } from 'ember-redux';
import { getMentionsContent } from '../../../selectors/mention';
import { htmlSafe } from '@ember/string';
import { observer } from '@ember/object';
import { inject as service } from '@ember/service';
import { textValue } from '../../../utils/string';

const dispatchToActions = {};

const stateToComputed = (state) => ({
  elements: getElementsList(state).map((_elementId) => {
    return getElement(state, _elementId);
  }),
});

const UiContentMentionable = Component.extend({
  contextMenu: service(),
  classNames: ['content-mentionable'],
  value: '',
  isInteractive: true,
  isText: false,
  appendElementVersion: false,
  appendOrdinal: false,
  toLowerCase: false,
  tagName: 'span',

  init() {
    this._super(...arguments);
    this.setMentionsValue();
  },

  onContextMenu(event) {
    event.preventDefault();
    // this.contextMenu.open('mentions-content', null, event.clientX, event.clientY);
  },

  willDestroyElement() {
    this._super(...arguments);
    $(`#${this.elementId}`).off('contextmenu');
  },

  didInsertElement() {
    this._super(...arguments);
    const textarea = $(`#${this.elementId}`)[0];
    $(`#${this.elementId}`).on('contextmenu', (event) => this.onContextMenu(event));
    if (this.autoFocus && textarea) {
      if (this.selectAll) {
        // focusAndSelectAll(textarea);
      } else {
        // focusAndPlaceCaretAtEnd(textarea);
      }
    }
  },

  // eslint-disable-next-line ember/no-observers
  elementsObserver: observer('elements.@each.name', function () {
    this.setMentionsValue();
  }),

  setMentionsValue() {
    const state = this.redux.store.getState();
    const hasMentions = this.value && this.value.indexOf('</mention>');
    let _value = this.value;
    if (hasMentions) {
      _value = getMentionsContent(state, this.value, this.elementId, {
        appendOrdinal: this.appendOrdinal,
        appendElementVersion: this.appendElementVersion,
        isInput: true,
        isInteractive: this.isInteractive,
        novelFeaturesList: this.novelFeaturesList,
        toLowerCase: this.toLowerCase,
      });
    }
    if (this.isText) {
      this.set('_value', textValue(_value));
    } else {
      this.set('_value', htmlSafe(_value));
    }
  },
});

export default connect(
  stateToComputed,
  dispatchToActions
)(UiContentMentionable);
