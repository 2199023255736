import Component from '@glimmer/component';
import ENV from '../../../config/environment';
import { action } from '@ember/object';
import axios from 'axios';
import { detect } from 'detect-browser';
import podNames from "ember-component-css/pod-names";
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import validator from 'validator';

export default class SignInForm extends Component {
  @service tracking;

  @tracked email;
  @tracked isLoading = false;
  @tracked serverError;
  @tracked didValidate = false;
  @tracked acceptedTerms = false;

  chromeLink = 'https://support.google.com/chrome/answer/95346';
  termsLink = 'https://www.patentdive.com/legal/terms-of-service';
  privacyLink = 'https://www.patentdive.com/legal/privacy-policy';
  softwareLink = 'https://www.patentdive.com/legal/software-agreement';
  confidentialityLink = 'https://www.patentdive.com/legal/confidentiality-agreement';
  

  connectionError = { message: 'Thars a problem connecting to the server' };
  browser = detect();

  get styleNamespace() {
    return podNames['sign-in-form'];
  }

  @action
  didInsert() {
    this.tracking.trackEvent('visited_login');
  }

  get isValidEmail() {
    return this.email && validator.isEmail(this.email);
  }

  get isChrome() {
    return this.browser && this.browser.name === 'chrome';
  }

  get isntChrome() {
    return this.browser && this.browser.name !== 'chrome';
  }

  get isValid() {
    return this.isValidEmail && this.acceptedTerms;
  }

  get emailValidationError() {
    return this.didValidate && !this.isValidEmail;
  }

  get acceptedTermsError() {
    return this.didValidate && !this.acceptedTerms;
  }

  @action
  toggleChecked() {
    this.acceptedTerms = !this.acceptedTerms;
  }

  @action
  async submit() {
    this.didValidate = true;

    if (this.isValid) {
      // show the loader
      this.isLoading = true;
      this.serverError = null;
      this.getActivationLink();
    }
  }

  async getActivationLink() {
    try {
      await axios({
        method: 'POST',
        url: ENV.IDENTITY_API_URL + '/activation',
        data: {
          email: this.email,
          source: ENV.ACTIVATION_SOURCE,
        }
      });
      this.tracking.trackEvent('accepted_terms', { email: this.email });
      this.tracking.trackEvent('login_requested', { email: this.email });

      if (this.args.onActivationSent) {
        this.args.onActivationSent(this.email);
      }

    } catch (error) {
      this.isLoading = false;
      // throw new Error('something')
      this.serverError = error.response ? error.response.data : this.connectionError;
      this.tracking.trackEvent('login_request_denied');
    }
  }
}
