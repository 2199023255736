import { action, computed } from '@ember/object';

import Component from '@glimmer/component';
import { connect } from 'ember-redux';
import { getProduct } from '../../../selectors/product';
import podNames from 'ember-component-css/pod-names';

const stateToComputed = (state, attrs) => ({
  product: getProduct(state, attrs.productId),
});

const dispatchToActions = {};

class ProductListItem extends Component {

  showMenu = true;

  get styleNamespace() {
    return podNames['product-list-item'];
  }

  get classNames() {
    let classNames = [this.styleNamespace, 'product-list-item'];
    if (this.args.isSelected) classNames.push('is-selected');
    return classNames.join(' ');
  }

  @computed('product.name')
  get name() {
    return this.product && this.product.name ? this.product.name : 'New Product'; 
  }

  @action
  onClick() {
    if (this.args.onClick) this.args.onClick(this.args.productId);
  }

}

export default connect(stateToComputed, dispatchToActions)(ProductListItem);
