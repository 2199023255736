import { action, computed } from '@ember/object';

import Component from '@glimmer/component';
import { connect } from 'ember-redux';
import { getDrawing } from '../../../selectors/drawing';
import { getFigureNumber } from '../../../selectors/figure';
import { htmlSafe } from '@ember/string';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency-decorators';
import { tracked } from '@glimmer/tracking';

const dispatchToActions = {};

const stateToComputed = (state, attrs) => ({
  drawing: getDrawing(state, attrs.drawingId),
  sequence: getFigureNumber(state, attrs.drawingId),
});

class DrawingItem extends Component {
  @service figuresCacheKonva;

  @tracked blobUrl;

  get classNames() {
    let classNames = [this.styleNamespace, 'figure-item', 'drawing-item'];
    if (this.isLandscape) classNames.push('is-landscape');
    if (this.args.isOpaque) classNames.push('is-opaque');
    return classNames.join(' ');
  }

  get styleNamespace() {
    return podNames['drawing-item'];
  }

  get previewImageStyles() {
    return htmlSafe(`background-image: url(${this.blobUrl})`);
  }

  @computed('drawing.orientation')
  get isLandscape() {
    return this.drawing && this.drawing.orientation === 'landscape';
  }

  @task
  *setBlobUrl(drawingId) {
    const blobUrl = yield this.figuresCacheKonva.getBlobUrl.perform(drawingId);
    this.blobUrl = blobUrl;
  }

  @action
  didInsert(/*elem, [updatedAt]*/) {
    this.setBlobUrl.perform(this.args.drawingId);
  }

  @action
  onUpdate(/*elem, [updatedAt]*/) {
    this.setBlobUrl.perform(this.args.drawingId);
  }

  @action
  onContextMenu(event) {
    if (this.args.onContextMenu) {
      this.args.onContextMenu(event, this.args.drawingId);
    }
  }

}

export default connect(stateToComputed, dispatchToActions)(DrawingItem);
