import { action, computed } from '@ember/object';

import Component from '@glimmer/component';
import { connect } from 'ember-redux';
import { getImage } from '../../../selectors/image';
import { htmlSafe } from '@ember/string';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

const stateToComputed = (state, attrs) => ({
  image: getImage(state, attrs.imageId),
});

const dispatchToActions = {};

class ImageListItem extends Component {
  @service models;
  @service assets;

  @tracked blobUrl;


  get styleNamespace() {
    return podNames['image-list-item'];
  }

  get classNames() {
    let classNames = [this.styleNamespace, 'image-list-item'];
    return classNames.join(' ');
  }

  @computed('image.asset')
  get assetId() {
    return this.image.asset;
  }

  @computed('imageModel.name')
  get name() {
    return this.imageModel.name || 'New Image';
  }

  @computed('args.imageId', 'image')
  get imageModel() {
    return this.models.find(this.args.imageId) || this.models.findOrCreate(this.args.imageId, 'image', this.image);
  }

  get backgroundImageStyle() {
    return this.blobUrl && htmlSafe(`background-image: url(${this.blobUrl})`);
  }

  @action
  async setBlobUrl() {
    if (this.assetId) {
      const blobUrl = await this.assets.getAssetUrl(this.assetId);
      this.blobUrl = blobUrl;
    }
  }

  @action
  onClick() {
    if (this.args.onClick) {
      this.args.onClick(this.args.imageId)
    }
  }
}

export default connect(stateToComputed, dispatchToActions)(ImageListItem);