import { getElement, getElementName } from './element';

import { get } from '@ember/object';
import { getFeature } from './feature';
import { getMentionsContent } from './mention';
import { getNodeHeight } from '../utils/method';
import { getPreferredElementVersion } from './product';
import { getPresentState } from '../utils/redux';
import { textValue } from '../utils/string';

export const getMethodNodeLabel = (
  state,
  methodNodeId,
  feature,
  domElementId,
  isStartNode = false,
  plainText = true
) => {
  let label = '';

  if (isStartNode) {
    const methodNode = getMethodNode(state, methodNodeId);
    label = methodNode ? methodNode.label : label;
  } else {
    feature = feature || getMethodNodeFeature(state, methodNodeId);
    label = feature.value;
    label = getMentionsContent(state, label, domElementId, {
      appendOrdinal: false,
      appendElementVersion: false,
      isInput: false,
      isInteractive: false,
      novelFeaturesList: [],
    });
    if (plainText) {
      label = textValue(label);
    }
  }

  return label;
};

export const getMethodNode = (state, methodNodeId) =>
  get(getPresentState(state), `methodNodes.entities.${methodNodeId}`);

export const getMethodNodeElement = (state, methodNodeId) => {
  const methodNode = getMethodNode(state, methodNodeId);

  let element = null;

  if (methodNode && methodNode.element) {
    element = getElement(state, methodNode.element);
    if (element.component && element.instanceOf) {
      const instanceOf = getElement(state, element.instanceOf);
      element = instanceOf;
    }
  }

  return element;
};

export const getMethodNodeFeatureId = (state, methodNodeId) => {
  const methodNode = getMethodNode(state, methodNodeId);

  let featureId = null;

  if (methodNode && methodNode.type === 'start') {
    featureId = null;
  } else if (methodNode && methodNode.type === 'custom') {
    featureId = methodNode.feature;
  } else if (methodNode && methodNode.type === 'element') {
    const element = getElement(state, methodNode.element);
    if (element.component && element.instanceOf && element.useDefaultOutcome) {
      const instanceOf = getElement(state, element.instanceOf);
      featureId = instanceOf.outcome;
    } else {
      featureId = element.outcome;
    }
  }

  return featureId;
};

export const getMethodNodeWithHeight = (state, methodNodeId) => {
  const methodNode = getMethodNode(state, methodNodeId);
  const isStartNode = methodNode.type === 'start';
  const width = methodNode.width;
  const label = getMethodNodeLabel(
    state,
    methodNodeId,
    null,
    null,
    isStartNode
  );
  const height = getNodeHeight(label, width);
  return {
    ...methodNode,
    height,
  };
};

export const getMethodNodeFeature = (state, methodNodeId) => {
  const featureId = getMethodNodeFeatureId(state, methodNodeId);
  return getFeature(state, featureId);
};

export const getMethodNodeElementName = (state, methodNodeId) => {
  const methodNode = getMethodNode(state, methodNodeId);
  let name = null;
  if (methodNode && methodNode.type === 'element') {
    name = getElementName(methodNode.element);
  }
  return name;
};

export const getSubmethodId = (state, methodNodeId, productId) => {
  const methodNode = getMethodNode(state, methodNodeId);
  const elementId = methodNode?.element;
  const elementVersion =
    elementId && getPreferredElementVersion(state, elementId, productId);
  return elementVersion?.method;
};

export const getMethodNodes = (state) =>
  get(getPresentState(state), `methodNodes.entities`);
  
export const getMethodNodesList = (state) =>
  get(getPresentState(state), `methodNodes.ids`);
