import $ from 'jquery';

// export const hasMentions = content => {
//   const $fakeDomElement = $(`<div>${content}</div>`);
//   const $domMentions = $fakeDomElement.find(`.mention`);
//   return $domMentions.length ? true : false;
// };

export const sanitizeMentions = (content) => {
  const $fakeDomElement = $(`<div>${content}</div>`);
  const $domMentions = $fakeDomElement.find(`.mention`);

  $domMentions.each((index, domElement) => {
    domElement.removeAttribute('data-ordinal');
    domElement.removeAttribute('data-deleted');
    domElement.removeAttribute('readonly');
    domElement.removeAttribute('contenteditable');
    domElement.innerHTML = domElement.innerText;
  });

  let htmlString = $fakeDomElement.html();

  if (!htmlString.endsWith(' ') || !htmlString.endsWith('&nbsp;')) {
    htmlString += ' ';
  }

  return htmlString;
};

export const elementMentionTemplate = (
  _elementId,
  elementVersionId,
  elementName,
  domElementId
) => {
  const dataElementVersionId = elementVersionId
    ? `data-element-version-id="${elementVersionId}"`
    : '';
  return `<mention class="mention" data-type="element" data-id="${_elementId}" ${dataElementVersionId} data-name="${elementName}" contenteditable="false" readonly>${elementMentionInteractiveTemplate(
    _elementId,
    elementVersionId,
    elementName,
    domElementId
  )}</mention>`;
};

export const termMentionTemplate = (termId, name, domElementId) => {
  return `<mention class="mention" data-type="term" data-id="${termId}"  data-name="${name}" contenteditable="false" readonly>${termMentionInteractiveTemplate(
    termId,
    name,
    domElementId
  )}</mention>`;
};

export const tributeElementMentionTemplate = (
  elementId,
  elementVersionNames,
  name,
  forceElementVersionNames = false
) => {
  const elementVersionNameSpansArray = elementVersionNames.map(
    (elementVersionName) => {
      return `<span class='mention-element-version'>${elementVersionName}</span>`;
    }
  );
  const elementVersionNamesString =
    forceElementVersionNames || elementVersionNames.length > 1
      ? ` <span class='mention-element-versions'>(${elementVersionNameSpansArray.join(
          ', '
        )})</span>`
      : '';

  return `<mention class="mention" data-type="element" data-id="${elementId}" data-name="${name}" contenteditable="false" readonly><a>${name}</a>${elementVersionNamesString}</mention>`;
};

export const tributeTermMentionTemplate = (termId, name) => {
  return `<mention class="mention" data-type="term" data-id="${termId}"  data-name="${name}" contenteditable="false" readonly><a>${name}</a></mention>`;
};

export const elementMentionInteractiveTemplate = (
  _elementId,
  elementVersionId,
  elementName,
  domElementId,
  isClickable = true,
  isHoverable = true,
  isContextable = false
) => {
  const dataElementVersionId = elementVersionId
    ? `elementVersionId: '${elementVersionId}',`
    : '';

  const clickAction = isClickable
    ? `onclick="window.dispatchEvent(new CustomEvent('mention-click', { bubbles: true, detail: { type: 'element', id: '${_elementId}', ${dataElementVersionId} domElementId: '${domElementId}' }}));"`
    : '';

  const contextAction = isContextable
    ? `oncontextmenu="window.dispatchEvent(new CustomEvent('mention-contextmenu', { bubbles: false, defaultPrevented: true, detail: { type: 'element', id: '${_elementId}', ${dataElementVersionId} domElementId: '${domElementId}' }}));"`
    : '';

  const hoverAction = isHoverable
    ? `onmouseenter="window.dispatchEvent(new CustomEvent('mention-mouseenter', { bubbles: true, detail: { type: 'element', id: '${_elementId}', ${dataElementVersionId} domElementId: '${domElementId}' }}));" onmouseleave="window.dispatchEvent(new CustomEvent('mention-mouseleave', { bubbles: true, detail: { type: 'element', id: '${_elementId}', ${dataElementVersionId} domElementId: '${domElementId}' }}));"`
    : '';

  return `<a id="${domElementId}" role="button" ${hoverAction} ${clickAction} ${contextAction}>${elementName}</a>`;
};

export const termMentionInteractiveTemplate = (
  termId,
  name,
  domElementId,
  isClickable = true,
  isHoverable = true,
  isContextable = false
) => {
  const clickAction = isClickable
    ? `onclick="window.dispatchEvent(new CustomEvent('mention-click', { bubbles: true, detail: { type: 'term', id: '${termId}', domElementId: '${domElementId}' }}));"`
    : '';

  const contextAction = isContextable
    ? `oncontextmenu="window.dispatchEvent(new CustomEvent('mention-contextmenu', { bubbles: false, defaultPrevented: true, detail: { type: 'term', id: '${termId}', domElementId: '${domElementId}' }}));"`
    : '';

  const hoverAction = isHoverable
    ? `onmouseenter="window.dispatchEvent(new CustomEvent('mention-mouseenter', { bubbles: true, detail: { type: 'term', id: '${termId}', domElementId: '${domElementId}' }}));" onmouseleave="window.dispatchEvent(new CustomEvent('mention-mouseleave', { bubbles: true, detail: { type: 'term', id: '${termId}', domElementId: '${domElementId}' }}));"`
    : '';

  return `<a id="${domElementId}" role="button" ${hoverAction} ${clickAction} ${contextAction}>${name}</a>`;
};

export const figureMentionInteractiveTemplate = (
  figureId,
  figureType,
  name,
  domElementId,
  isClickable = true,
  isHoverable = true,
  isContextable = false
) => {
  const clickAction = isClickable
    ? `onclick="window.dispatchEvent(new CustomEvent('mention-click', { bubbles: true, detail: { type: '${figureType}', id: '${figureId}', domElementId: '${domElementId}' }}));"`
    : '';

  const contextAction = isContextable ? '' : '';
  // const contextAction = isContextable
  //   ? `oncontextmenu="window.dispatchEvent(new CustomEvent('mention-contextmenu', { bubbles: false, defaultPrevented: true, detail: { type: '${figureType}', id: '${figureId}', domElementId: '${domElementId}' }}));"`
  //   : '';

  const hoverAction = isHoverable
    ? `onmouseenter="window.dispatchEvent(new CustomEvent('mention-mouseenter', { bubbles: true, detail: { type: '${figureType}', id: '${figureId}', domElementId: '${domElementId}' }}));" onmouseleave="window.dispatchEvent(new CustomEvent('mention-mouseleave', { bubbles: true, detail: { type: '${figureType}', id: '${figureId}', domElementId: '${domElementId}' }}));"`
    : '';

  return `<a id="${domElementId}" role="button" ${hoverAction} ${clickAction} ${contextAction}>${name}</a>`;
};

export const allHMTLTags = [
  'a',
  'abbr',
  'acronym',
  'address',
  'applet',
  'area',
  'article',
  'aside',
  'audio',
  'b',
  'base',
  'basefont',
  'bdi',
  'bdo',
  'bgsound',
  'big',
  'blink',
  'blockquote',
  'body',
  'br',
  'button',
  'canvas',
  'caption',
  'center',
  'cite',
  'code',
  'col',
  'colgroup',
  'content',
  'data',
  'datalist',
  'dd',
  'decorator',
  'del',
  'details',
  'dfn',
  'dir',
  'div',
  'dl',
  'dt',
  'element',
  'em',
  'embed',
  'fieldset',
  'figcaption',
  'figure',
  'font',
  'footer',
  'form',
  'frame',
  'frameset',
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'head',
  'header',
  'hgroup',
  'hr',
  'html',
  'i',
  'iframe',
  'img',
  'input',
  'ins',
  'isindex',
  'kbd',
  'keygen',
  'label',
  'legend',
  'li',
  'link',
  'listing',
  'main',
  'map',
  'mark',
  'marquee',
  'menu',
  'menuitem',
  'meta',
  'meter',
  'nav',
  'nobr',
  'noframes',
  'noscript',
  'object',
  'ol',
  'optgroup',
  'option',
  'output',
  'p',
  'param',
  'plaintext',
  'pre',
  'progress',
  'q',
  'rp',
  'rt',
  'ruby',
  's',
  'samp',
  'script',
  'section',
  'select',
  'shadow',
  'small',
  'source',
  'spacer',
  'span',
  'strike',
  'strong',
  'style',
  'sub',
  'summary',
  'sup',
  'table',
  'tbody',
  'td',
  'template',
  'textarea',
  'tfoot',
  'th',
  'thead',
  'time',
  'title',
  'tr',
  'track',
  'tt',
  'u',
  'ul',
  'var',
  'video',
  'wbr',
  'xmp',
];
