import Component from '@glimmer/component';
import { computed } from '@ember/object';
import { connect } from 'ember-redux';
import { getFigureNumber } from '../../../selectors/figure';
import { getMethod } from '../../../selectors/method';
import { getMethodLegendRealization } from '../../../utils/realization';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';

const dispatchToActions = {};
const stateToComputed = (state, attrs) => ({
  method: getMethod(state, attrs.methodId),
  sequence: getFigureNumber(state, attrs.methodId),
});

class MethodLegendRealization extends Component {
  @service models;
  @service redux;

  defaultLanguage = 'patent_specification_preferred_version';

  get styleNamespace() {
    return podNames['method-legend-realization'];
  }

  get classNames() {
    let classNames = [this.styleNamespace, 'method-legend-realization'];
    return classNames.join(' ');
  }

  get outcomeExample() {
    return 'e.g. water enters a canal';
  }

  get outcome() {
    return 'e.g. water enters a ear canal';
  }

  @computed('args.{language,methodId}', 'isFocused', 'defaultLanguage', 'sequence')
  get realization() {
    const language = this.args.language || this.defaultLanguage;
    const state = this.redux.getState();
    return getMethodLegendRealization({
      state,
      sequence: this.sequence,
      methodId: this.args.methodId,
      language,
    });
  }
}

export default connect(
  stateToComputed,
  dispatchToActions
)(MethodLegendRealization);
