import Component from '@glimmer/component';
import { action } from '@ember/object';
import { computed } from '@ember/object';
import { connect } from 'ember-redux';
import { getCustomer } from '../../../selectors/customer';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

const stateToComputed = (state, attrs) => ({
  customer: getCustomer(state, attrs.customerId),
});

const dispatchToActions = {};

class CustomerItem extends Component {
  @service assets;

  @tracked blobUrl;

  get styleNamespace() {
    return podNames['customer-item'];
  }

  get classNames() {
    let classNames = [this.styleNamespace, 'customer-item'];
    if (this.args.isContain) classNames.push('is-contain');
    return classNames.join(' ');
  }

  @computed('customer.number')
  get number() {
    return this.customer && this.customer.number || 0;
  }

  @computed('customer.revenue')
  get revenue() {
    return this.customer && this.customer.revenue || 0;
  }

  @computed('number', 'revenue')
  get total() {
    return Math.floor(Number(this.number) * Number(this.revenue)).toLocaleString();
  }

  @action
  onClick() {
    if (this.args.onClick) this.args.onClick(this.args.customerId);
  }

}

export default connect(stateToComputed, dispatchToActions)(CustomerItem);
