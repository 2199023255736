import {
  ADD_REQUIREMENT,
  UPDATE_REQUIREMENT,
  REMOVE_REQUIREMENT,
} from '../actions/actionTypes';
import { addFromSchema } from '../utils/schema';
import { omit } from 'lodash';

const initialState = {
  ids: [],
  entities: {},
};

const requirementsReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case ADD_REQUIREMENT: {
      let { requirementId, elementId } = payload;
      const id = requirementId;
      const createdAt = new Date();
      const updatedAt = new Date();
      const requirement = addFromSchema('requirement', {
        id,
        element: elementId,
        createdAt,
        updatedAt,
      });

      return {
        ids: [...state.ids, id],
        entities: {
          ...state.entities,
          [requirementId]: requirement,
        },
      };
    }

    case UPDATE_REQUIREMENT: {
      const { requirementId, attributes } = payload;
      const updatedAt = new Date();
      return {
        ...state,
        entities: {
          ...state.entities,
          [requirementId]: {
            ...state.entities[requirementId],
            ...attributes,
            updatedAt,
          },
        },
      };
    }

    case REMOVE_REQUIREMENT: {
      const { requirementId } = payload;
      return {
        ids: state.ids.filter((id) => id !== requirementId),
        entities: omit(state.entities, requirementId),
      };
    }

    /* istanbul ignore next */
    default:
      return state;
  }
};

export default requirementsReducer;
