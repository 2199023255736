import Component from '@glimmer/component';
import { action } from '@ember/object';
import podNames from 'ember-component-css/pod-names';

class UiHelpPopover extends Component {
  constructor() {
    super(...arguments); // super must be called first.
    this.isInfo = this.args.type === 'info';
    this.isPrimary = this.args.type === 'primary';
    this.isDanger = this.args.type === 'danger';
    this.isWarning = this.args.type === 'warning';
  }

  get classNames() {
    let classNames = [this.styleNamespace, 'ui-help-popover'];
    if (this.isInfo) classNames.push('is-info');
    if (this.isPrimary) classNames.push('is-primary');
    if (this.isDanger) classNames.push('is-danger');
    if (this.isWarning) classNames.push('is-warning');
    return classNames.join(' ');
  }

  get styleNamespace() {
    return podNames['ui-help-popover'];
  }

  @action
  onClose() {
    // console.log('leave');
  }

}

export default UiHelpPopover;
