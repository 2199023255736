import Component from '@ember/component';
import { computed } from '@ember/object';
import { alias } from '@ember/object/computed';

const InventionNodePreview = Component.extend({
  tagName: 'g',
  classNames: ['graph-node','element-node'],
  classNameBindings: ['isDisconnected'],
  attributeBindings: [
    'name:data-element-name',
    'elementVersionId:data-node-id',
    'x:data-x',
    'y:data-y',
    'transform'
  ],
  elementVersionId: null,
  elementVersion: computed('elementVersionId', 'state.elementVersions.entities', function() {
    return this.state.elementVersions.entities[this.elementVersionId];
  }),
  width: 400,
  height: 380,
  offsetX: computed('width', function() {
    return -1 * this.width / 2;
  }),
  offsetY: computed('height', function() {
    return -1 * this.height / 2;
  }),
  x: alias('elementVersion.x'),
  y: alias('elementVersion.y'),

  transform: computed('x', 'y', function() {
    return `translate(${this.x}, ${this.y})`
  }),
});

export default InventionNodePreview;
