import Component from '@glimmer/component';
import { action } from '@ember/object';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';

class UiContextMenuContent extends Component {
  @service contextMenu;

  get styleNamespace() {
    return podNames['ui-context-menu-content'];
  }

  get classNames() {
    let classNames = [this.styleNamespace, 'ui-context-menu-content'];
    return classNames.join(' ');
  }

  @action
  onClickOutside() {
    this.contextMenu.close();
  }
  
}

export default UiContextMenuContent;
