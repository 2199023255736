import { action, computed } from '@ember/object';
import { cancel, later } from '@ember/runloop';
// import { batchGroupBy } from '../../../utils/redux';
import {
  getCollapsedNodesList,
  getSelectedEdges,
  getSelectedElementVersions,
  getSelectedElements,
} from '../../../selectors/invention-ui';
import {
  getComponent,
  getPrimaryInstancesList,
} from '../../../selectors/component';
import {
  getElementVersionsMap,
  getElementsMap,
  getRootNodeId,
} from '../../../selectors/graph';

import Component from '@glimmer/component';
import { connect } from 'ember-redux';
import { getElement } from '../../../selectors/element';
import { getElementVersion } from '../../../selectors/element-version';
import { getPreferredElementVersionId } from '../../../selectors/product';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';

const stateToComputed = (state, attrs) => ({
  element: getElement(state, attrs.elementId),
  rootNodeId: getRootNodeId(state),
  primaryInstancesList: getPrimaryInstancesList(state),
  selectedElements: getSelectedElements(state),
  selectedElementVersions: getSelectedElementVersions(state),
  selectedEdges: getSelectedEdges(state),
  elementsMap: getElementsMap(state, attrs.productId),
  collapsedNodesList: getCollapsedNodesList(state, attrs.productId),
});

const dispatchToActions = {};

class ElementContextMenu extends Component {
  @service contextMenu;
  @service clipboard;
  @service applicationState;
  @service redux;
  @service tracking;

  get styleNamespace() {
    return podNames['element-context-menu'];
  }

  get classNames() {
    let classNames = [this.styleNamespace, 'element-context-menu'];
    return classNames.join(' ');
  }

  @action
  didInsert() {
    this.tracking.trackEvent('opened_system_context_menu');
  }

  calculateNestedPosition(trigger, menu) {
    const windowHeight = window.innerHeight;
    const windowWidth = window.innerWidth;
    const menuHeight = menu.getBoundingClientRect().height;
    const menuWidth = menu.getBoundingClientRect().width;

    let { top, left, width, height } = trigger.getBoundingClientRect();

    let style = {
      position: 'absolute',
      left: left + width,
      top: top,
    };

    if (top + height + menuHeight > windowHeight) {
      const bottom = windowHeight - (top + height);
      style.top = 'auto';
      style.bottom = `${bottom}px`;
    }

    if (left + width + menuWidth > windowWidth) {
      style.left = left - menuWidth;
    }

    return { style };
  }

  @computed('element.{instanceOf,elementVersionsList.[]}', 'isInstance')
  get elementVersions() {
    const state = this.redux.getState();
    let elementVersionsList = this.element.elementVersionsList;
    if (this.isInstance) {
      const instanceOf = getElement(state, this.element.instanceOf);
      elementVersionsList = instanceOf.elementVersionsList;
    }
    return elementVersionsList.map((elementVersionId) =>
      getElementVersion(state, elementVersionId)
    );
  }

  get copyText() {
    return `Copy`;
  }

  @computed('category')
  get categoryName() {
    return this.category === 'part' ? 'Part' : 'System';
  }

  @computed('element.{category,instanceOf}', 'isInstance')
  get category() {
    const state = this.redux.getState();
    let category = this.element && this.element.category;

    if (this.element && this.isInstance) {
      const instanceOf = getElement(state, this.element.instanceOf);
      category = instanceOf && instanceOf.category;
    }

    return category;
  }

  @computed('element.{categoryGroup,instanceOf}', 'isInstance')
  get categoryGroup() {
    const state = this.redux.getState();
    let categoryGroup = this.element && this.element.categoryGroup;

    if (this.element && this.isInstance) {
      const instanceOf = getElement(state, this.element.instanceOf);
      categoryGroup = instanceOf && instanceOf.categoryGroup;
    }

    return categoryGroup;
  }

  @computed('args.productId')
  get isProductsView() {
    return this.args.productId ? true : false;
  }

  @computed('isProductsView')
  get isAllProductsView() {
    return !this.isProductsView;
  }

  @computed('element.instanceOf')
  get isInstance() {
    return this.element && this.element.instanceOf ? true : false;
  }

  @computed('collapsedNodesList.[]', 'args.elementId')
  get isCollapsed() {
    return this.collapsedNodesList.includes(this.args.elementId);
  }

  @computed('selectedElements.[]', 'args.elementId')
  get allSelectedElements() {
    const allSelectedElements = [
      ...this.selectedElements,
      this.args.elementId,
    ].uniq();
    return allSelectedElements;
  }

  @computed(
    'selectedEdges.[]',
    'selectedElementVersions.[]',
    'allSelectedElements.[]'
  )
  get canCopyInstances() {
    const state = this.redux.getState();

    if (this.allSelectedElements.length === 1) {
      return false;
    }

    if (this.selectedElementVersions.length) {
      return false;
    }

    if (this.selectedEdges.length) {
      return false;
    }

    return this.allSelectedElements.every((elementId) => {
      const element = getElement(state, elementId);
      return element.instanceOf;
    });
  }

  @computed(
    'selectedEdges.[]',
    'selectedElementVersions.[]',
    'allSelectedElements.[]'
  )
  get oneElementSelected() {
    return this.allSelectedElements.length === 1 &&
      this.selectedElementVersions.length <= 1 &&
      !this.selectedEdges.length
      ? true
      : false;
  }

  @computed(
    'selectedEdges.[]',
    'selectedElementVersions.[]',
    'allSelectedElements.[]'
  )
  get isMultiSelected() {
    return this.allSelectedElements.length > 1 ||
      this.selectedElementVersions.length > 1 ||
      this.selectedEdges.length
      ? true
      : false;
  }

  @computed('oneElementSelected')
  get canCopy() {
    return this.oneElementSelected;
  }

  @computed('canCopy')
  get cantCopy() {
    return !this.canCopy;
  }

  @computed('element.instanceOf', 'primaryInstancesList.[]', 'args.elementId')
  get isPrimaryInstance() {
    return (
      this.element &&
      this.element.instanceOf &&
      this.primaryInstancesList.includes(this.args.elementId)
    );
  }

  @computed('isPrimaryInstance')
  get isntPrimaryInstance() {
    return !this.isPrimaryInstance;
  }

  @computed('clipboard.elementId')
  get clipboardElementIsInstance() {
    const state = this.redux.getState();
    const element =
      this.clipboard.elementId && getElement(state, this.clipboard.elementId);
    return element && element.instanceOf ? true : false;
  }

  @computed('args.{elementId,productId}')
  get elementVersionsMap() {
    const state = this.redux.getState();
    return getElementVersionsMap(state);
  }

  @computed('args.elementId', 'elementsMap')
  get elementChildren() {
    return (
      this.elementsMap &&
      this.elementsMap[this.args.elementId] &&
      this.elementsMap[this.args.elementId].children
    );
  }

  @computed('args.elementId', 'elementVersionsMap')
  get elementDescendants() {
    return (
      this.elementVersionsMap &&
      this.elementVersionsMap[this.args.elementId] &&
      this.elementVersionsMap[this.args.elementId].descendants
    );
  }

  @computed('elementDescendants.[]')
  get instanceDescendants() {
    const state = this.redux.getState();
    return this.elementDescendants.filter((elementId) => {
      const element = getElement(state, elementId);
      return element && element.instanceOf ? true : false;
    });
  }

  @computed('instanceDescendants.[]')
  get hasInstanceDescendants() {
    return this.instanceDescendants.length ? true : false;
  }

  @computed('args.elementId', 'elementVersionsMap')
  get elementAncestors() {
    return (
      this.elementVersionsMap &&
      this.elementVersionsMap[this.args.elementId] &&
      this.elementVersionsMap[this.args.elementId].ancestors
    );
  }

  @computed('elementAncestors.[]')
  get instanceAncestors() {
    const state = this.redux.getState();
    return this.elementAncestors.filter((elementId) => {
      const element = getElement(state, elementId);
      return element && element.instanceOf ? true : false;
    });
  }

  @computed('instanceAncestors.[]')
  get hasInstanceAncestors() {
    return this.instanceAncestors.length ? true : false;
  }

  // @computed('instanceAncestors.[]')
  // get instanceOfAncestors() {
  //   const state = this.redux.getState();
  //   return this.instanceAncestors.map((elementId) => {
  //     const element = getElement(state, elementId);
  //     const componentId = element.component;
  //     const component = getComponent(state, componentId);
  //     return component && component.element;
  //   });
  // }

  // @computed('clipboard.elementId', 'instanceOfAncestors.[]', 'args.elementId')
  // get clipboardElementIsntAncestor() {
  //   const state = this.redux.getState();
  //   const clipboardElementId = this.clipboard.elementId;
  //   const clipboardElement = getElement(state, clipboardElementId);

  //   let clipboardElementIsntAncestor = true;

  //   if (clipboardElement && clipboardElement.instanceOf) {
  //     const instanceOf = getElement(state, clipboardElement.instanceOf);
  //     clipboardElementIsntAncestor = !this.instanceOfAncestors.includes(instanceOf.id);
  //   }
  //   return clipboardElementIsntAncestor
  // }

  // TODO: is this necessary?
  // @computed('clipboard.elementId', 'elementDescendants.[]', 'args.elementId')
  // get clipboardElementIsntDescendant() {
  //   const clipboardElementId = this.clipboard.elementId;
  //   return (
  //     clipboardElementId !== this.args.elementId &&
  //     this.elementDescendants &&
  //     !this.elementDescendants.includes(clipboardElementId)
  //   );
  // }

  @computed('args.{productId,elementId}')
  get preferredElementVersionId() {
    const state = this.redux.getState();
    return (
      this.args.productId &&
      getPreferredElementVersionId(
        state,
        this.args.elementId,
        this.args.productId
      )
    );
  }

  @computed('args.productId', 'clipboard.elementId')
  get clipboardPreferredElementVersionId() {
    const state = this.redux.getState();
    return (
      this.clipboard.elementId &&
      this.args.productId &&
      getPreferredElementVersionId(
        state,
        this.clipboard.elementId,
        this.args.productId
      )
    );
  }

  @computed('element.instanceOf', 'isInstance')
  get isntInstance() {
    return this.element && !this.isInstance;
  }

  @computed(
    'args.productId',
    'clipboard.instancesList.[]',
    'hasInstanceAncestors',
    'isntInstance',
    'oneElementSelected',
    'preferredElementVersionId',
    'this.clipboard.instancesList.length'
  )
  get canPasteInstances() {
    // return (
    //   this.oneElementSelected &&
    //   this.args.productId &&
    //   this.preferredElementVersionId &&
    //   this.clipboard.instancesList.length &&
    //   !this.hasInstanceAncestors
    // );
    return (
      this.oneElementSelected &&
      this.args.productId &&
      this.preferredElementVersionId &&
      this.clipboard.instancesList.length
    );
  }

  @computed(
    'oneElementSelected',
    'isntInstance',
    'isPrimaryInstance',
    'args.productId',
    'preferredElementVersionId',
    'clipboardElementIsInstance',
    'hasInstanceAncestors'
  )
  get canPasteInstance() {
    // return (
    //   this.oneElementSelected &&
    //   this.isntInstance &&
    //   this.args.productId &&
    //   this.preferredElementVersionId &&
    //   this.clipboardElementIsInstance &&
    //   !this.hasInstanceAncestors
    //   // this.clipboardElementIsntDescendant &&
    //   // this.clipboardElementIsntAncestor
    // );
    return (
      this.oneElementSelected &&
      (this.isntInstance || this.isPrimaryInstance) &&
      this.args.productId &&
      this.preferredElementVersionId &&
      this.clipboardElementIsInstance
      // this.clipboardElementIsntDescendant &&
      // this.clipboardElementIsntAncestor
    );
  }

  @computed('cantPasteShallowSolutionDuplicate')
  get cantPaste() {
    return this.cantPasteShallowSolutionDuplicate;
  }

  @computed('clipboard.elementId', 'isPrimaryInstance', 'isntInstance')
  get canPasteSystemProperties() {
    const isPrimaryInstanceOrNotAnInstance =
      this.isPrimaryInstance || this.isntInstance;
    return this.clipboard.elementId && isPrimaryInstanceOrNotAnInstance;
  }

  @computed('canPasteSystemProperties')
  get cantPasteSystemProperties() {
    return !this.canPasteSystemProperties;
  }

  @computed('clipboard.elementVersionId')
  get canPasteSolutionProperties() {
    return this.clipboard.elementVersionId ? true : false;
  }

  @computed('canPasteSolutionProperties')
  get cantPasteSolutionProperties() {
    return !this.canPasteSolutionProperties;
  }

  @computed('cantPasteSystemProperties')
  get cantPasteProperties() {
    return this.cantPasteSystemProperties;
  }

  @computed(
    'isAllProductsView',
    'isPrimaryInstance',
    'isntInstance',
    'isMultiSelected'
  )
  get canCreateElement() {
    let canCreateElement = true;
    const isPrimaryInstanceOrNotAnInstance =
      this.isPrimaryInstance || this.isntInstance;
    canCreateElement = isPrimaryInstanceOrNotAnInstance;
    return !this.isMultiSelected && canCreateElement;
  }

  @computed(
    'isAllProductsView',
    'isPrimaryInstance',
    'isntInstance',
    'isMultiSelected'
  )
  get canCreateElementVersion() {
    let canCreateElementVersion = true;
    if (this.isAllProductsView) {
      const isPrimaryInstanceOrNotAnInstance =
        this.isPrimaryInstance || this.isntInstance;
      canCreateElementVersion = isPrimaryInstanceOrNotAnInstance;
    }
    return !this.isMultiSelected && canCreateElementVersion;
  }

  @computed('canCreateElement')
  get cantCreateElement() {
    return !this.canCreateElement;
  }

  @computed('canCreateElementVersion')
  get cantCreateElementVersion() {
    return !this.canCreateElementVersion;
  }

  @computed('element.elementVersionsList.[]')
  get elementVersionsList() {
    return this.element && this.element.elementVersionsList;
  }

  @computed('rootNodeId', 'args.elementId')
  get isRoot() {
    return this.rootNodeId === this.args.elementId;
  }

  @computed('elementVersionsList.[]')
  get elementVersionId() {
    return (
      this.elementVersionsList &&
      this.elementVersionsList.length === 1 &&
      this.elementVersionsList[0]
    );
  }

  @computed('elementVersionId', 'isRoot', 'isInstance', 'isMultiSelected')
  get canConvertToTerm() {
    // isn't an instance, isn't root and has only one solution? can convert!
    return (
      !this.isMultiSelected &&
      !this.isInstance &&
      !this.isRoot &&
      this.elementVersionId
    );
  }

  @computed('canConvertToTerm')
  get cantConvertToTerm() {
    return !this.canConvertToTerm;
  }

  @computed('elementVersionId', 'isRoot', 'isInstance', 'isMultiSelected')
  get canConvertCategory() {
    // isn't an instance, isn't root can convert!
    return !this.isMultiSelected && !this.isInstance && !this.isRoot;
  }

  @computed('canConvertCategory')
  get cantConvertCategory() {
    return !this.canConvertCategory;
  }

  @computed(
    'isRoot',
    'hasInstanceAncestors',
    'hasInstanceDescendants',
    'isMultiSelected'
  )
  get cantCreateComponent() {
    // return (
    //   this.isRoot ||
    //   this.isMultiSelected ||
    //   this.hasInstanceAncestors ||
    //   this.hasInstanceDescendants
    // );
    return this.isRoot || this.isMultiSelected;
  }

  @computed('args.elementId', 'primaryInstancesList.[]', 'oneElementSelected')
  get cantSetAsPrimaryInstance() {
    return (
      !this.oneElementSelected ||
      this.primaryInstancesList.includes(this.args.elementId)
    );
  }

  @computed('isRoot', 'isInstance', 'oneElementSelected')
  get cantSetAsRoot() {
    return this.isRoot || this.isInstance || !this.oneElementSelected;
  }

  @computed('clipboard.elementVersionId', 'isPrimaryInstance', 'isntInstance')
  get canPasteShallowSolutionDuplicate() {
    const isPrimaryInstanceOrNotAnInstance =
      this.isPrimaryInstance || this.isntInstance;
    return this.clipboard.elementVersionId && isPrimaryInstanceOrNotAnInstance
      ? true
      : false;
  }

  @computed('canPasteShallowSolutionDuplicate')
  get cantPasteShallowSolutionDuplicate() {
    return !this.canPasteShallowSolutionDuplicate;
  }

  @action
  onPasteShallowElementVersionDuplicate() {
    if (this.canPasteShallowSolutionDuplicate) {
      if (this.args.onPasteShallowElementVersionDuplicate) {
        const x = this.contextMenu.stageX;
        const y = this.contextMenu.stageY;
        this.tracking.trackEvent(
          'system_context_menu_pasted_solution_duplicate'
        );
        this.args.onPasteShallowElementVersionDuplicate(
          this.args.elementId,
          this.clipboard.elementVersionId,
          x,
          y
        );
      }
    }
    this.contextMenu.close();
  }

  @action
  onSetAsPrimaryInstance() {
    if (this.args.onSetAsPrimaryInstance) {
      this.tracking.trackEvent('system_context_menu_set_as_main_instance');
      this.args.onSetAsPrimaryInstance(this.args.elementId);
    }
    this.contextMenu.close();
  }

  @action
  onSetCategoryGroup(categoryGroup) {
    const elementId = this.isInstance ? this.element.instanceOf : this.element.id;

    if (this.categoryGroup === categoryGroup) {
      categoryGroup = '';
    }

    if (this.args.onSetCategoryGroup) {
      this.args.onSetCategoryGroup(elementId, categoryGroup);
    }
  }

  @action
  onCopy() {
    if (this.args.onCopyElement) {
      this.tracking.trackEvent('system_context_menu_copied');
      this.args.onCopyElement(this.args.elementId, this.preferredElementVersionId);
    }
    this.contextMenu.close();
  }

  @action
  onCopyInstances() {
    if (this.args.onCopyInstances) {
      this.tracking.trackEvent('system_context_menu_copied_instances');
      this.args.onCopyInstances(this.allSelectedElements);
    }
    this.contextMenu.close();
  }

  @action
  onConvertElementToTerm() {
    if (this.args.onConvertElementToTerm) {
      this.tracking.trackEvent('system_context_menu_converted_to_term');
      this.args.onConvertElementToTerm(this.args.elementId);
    }
    this.contextMenu.close();
  }

  @action
  onConvertElementToSystem() {
    if (this.args.onConvertElementToSystem) {
      this.tracking.trackEvent('system_context_menu_converted_to_system');
      this.args.onConvertElementToSystem(this.args.elementId);
    }
    this.contextMenu.close();
  }

  @action
  onConvertElementToPart() {
    if (this.args.onConvertElementToPart) {
      this.tracking.trackEvent('system_context_menu_converted_to_part');
      this.args.onConvertElementToPart(this.args.elementId);
    }
    this.contextMenu.close();
  }

  @action
  onSetElementAsRoot() {
    if (this.args.onSetElementAsRoot) {
      this.tracking.trackEvent('system_context_menu_set_root');
      this.args.onSetElementAsRoot(this.args.elementId);
    }
    this.contextMenu.close();
  }

  @action
  onCreateElementVersion() {
    if (this.canCreateElementVersion) {
      if (this.args.onCreateElementVersion) {
        const elementId = this.isInstance
          ? this.element.instanceOf
          : this.args.elementId;
        this.tracking.trackEvent('system_context_menu_added_solution');
        this.args.onCreateElementVersion(elementId);
      }
    }
    this.contextMenu.close();
  }

  @action
  onCreateChild() {
    if (this.canCreateElement) {
      if (this.args.onCreateElement) {

        const x = this.element.x;
        this.args.onCreateElement(this.preferredElementVersionId, x, true, true);
      }
    }
    this.contextMenu.close();
  }

  @action
  onCreateSibling() {
    if (this.canCreateElement) {
      if (this.args.onCreateElement) {
        const parentElementVersionId = this.element.elementVersion;
        const x = this.element.x + 50;
        const y = this.element.y;
        this.args.onCreateElement(parentElementVersionId, x, y, true, true);
      }
    }
    this.contextMenu.close();
  }

  @action
  onDetachComponentInstance() {
    if (this.args.onDetachComponentInstance) {
      this.tracking.trackEvent('system_context_menu_detached_component');
      this.args.onDetachComponentInstance(this.args.elementId);
    }
    this.contextMenu.close();
  }

  @action
  onCreateComponent() {
    if (this.args.onCreateComponent) {
      this.tracking.trackEvent('system_context_menu_created_component');
      this.args.onCreateComponent(this.args.elementId);
    }
    this.contextMenu.close();
  }

  @action
  onRemoveSelectedTreeItems() {
    const selectedElements = this.allSelectedElements;
    const selectedElementVersions = this.args.productId
      ? []
      : this.selectedElementVersions;
    const selectedEdges = this.selectedEdges;
    if (this.args.onRemoveSelectedTreeItems) {
      this.args.onRemoveSelectedTreeItems(
        selectedElements,
        selectedElementVersions,
        selectedEdges
      );
    }
    this.tracking.trackEvent('system_context_menu_removed_selected');
    this.contextMenu.close();
  }

  @action
  onPasteSystemProperties(type) {
    if (this.canPasteSystemProperties) {
      if (this.args.onPasteSystemProperties) {
        const state = this.redux.getState();
        let sourceElementId = this.clipboard.elementId;
        const sourceElement = getElement(state, this.clipboard.elementId);
        const sourceIsInstance =
          sourceElement.component && sourceElement.instanceOf ? true : false;

        if (sourceIsInstance) {
          sourceElementId = sourceElement.instanceOf;
        }

        this.tracking.trackEvent(
          'system_context_menu_pasted_system_properties'
        );
        this.args.onPasteSystemProperties(
          sourceElementId,
          this.args.elementId,
          type
        );
      }
    }
    this.contextMenu.close();
  }

  @action
  onPasteSolutionProperties(type) {
    if (this.canPasteSolutionProperties) {
      if (this.args.onPasteSolutionProperties) {
        this.args.onPasteSolutionProperties(
          this.clipboard.elementVersionId,
          this.preferredElementVersionId,
          type
        );
      }
    }
    this.contextMenu.close();
  }

  @action
  onSetPreferredElementVersion(elementVersionId) {
    if (
      this.args.onSetPreferredElementVersion &&
      this.preferredElementVersionId !== elementVersionId
    ) {
      this.tracking.trackEvent('system_context_menu_set_preferred_solution');
      this.args.onSetPreferredElementVersion(
        this.args.elementId,
        elementVersionId
      );
    }
    this.contextMenu.close();
  }

  prevent(e) {
    return e.stopImmediatePropagation();
  }

  @action
  open(dropdown) {
    if (this[`closeTimer_${dropdown.uniqueId}`]) {
      cancel(this[`closeTimer_${dropdown.uniqueId}`]);
      this[`closeTimer_${dropdown.uniqueId}`] = null;
    } else {
      dropdown.actions.open();
    }
  }

  @action
  closeLater(dropdown) {
    this[`closeTimer_${dropdown.uniqueId}`] = later(() => {
      this[`closeTimer_${dropdown.uniqueId}`] = null;
      dropdown.actions.close();
    }, 10);
  }

  @action
  onNestedMouseenter(dropdown) {
    cancel(this[`closeTimer_${dropdown.uniqueId}`]);
  }

  @action
  onNestedMouseleave(dropdown) {
    this[`closeTimer_${dropdown.uniqueId}`] = later(() => {
      this[`closeTimer_${dropdown.uniqueId}`] = null;
      dropdown.actions.close();
    }, 200);
  }

  @action
  onViewMainInstance() {
    if (this.args.onZoomToNode) {
      const state = this.redux.getState();
      const component = getComponent(state, this.element.component);
      const primaryInstanceId = component.primaryInstance;
      this.tracking.trackEvent('system_context_menu_viewed_main_instance');
      this.args.onZoomToNode(primaryInstanceId);
    }
    this.contextMenu.close();
  }

  @action
  onExpandNode() {
    if (this.args.onExpandNode) {
      this.args.onExpandNode(this.args.elementId);
    }
    this.contextMenu.close();
  }

  @action
  onCollapseNode() {
    if (this.args.onCollapseNode) {
      this.args.onCollapseNode(this.args.elementId);
    }
    this.contextMenu.close();
  }

  @action
  onCollapseChildren() {
    if (
      this.elementChildren &&
      this.elementChildren.length &&
      this.args.onCollapseChildren
    ) {
      const collapsibleChildren = this.elementChildren.filter((nodeId) => {
        return (
          this.elementsMap[nodeId] && this.elementsMap[nodeId].children.length
        );
      });
      this.args.onCollapseChildren(collapsibleChildren);
    }
    this.contextMenu.close();
  }
}

export default connect(stateToComputed, dispatchToActions)(ElementContextMenu);
