import { action, computed } from '@ember/object';

import Component from '@glimmer/component';
import { connect } from 'ember-redux';
import { getFigureNumber } from '../../../selectors/figure';
import { getMethod } from '../../../selectors/method';
import { htmlSafe } from '@ember/string';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

const dispatchToActions = {};

const stateToComputed = (state, attrs) => ({
  method: getMethod(state, attrs.methodId),
  num: getFigureNumber(state, attrs.methodId),
});

class MethodItem extends Component {
  @service methodsCacheKonva;
  @service store;

  @tracked blobUrl;

  constructor() {
    super(...arguments);
    this.showMenu = this.args.showMenu;
    this.setBlobUrl(this.args.methodId);
  }

  get classNames() {
    let classNames = [this.styleNamespace, 'figure-item', 'method-item'];
    if (this.isLandscape) classNames.push('is-landscape');
    if (this.args.isOpaque) classNames.push('is-opaque');
    return classNames.join(' ');
  }

  get styleNamespace() {
    return podNames['method-item'];
  }

  get previewImageStyles() {
    return htmlSafe(`background-image: url(${this.blobUrl})`);
  }

  @computed('args.methodId')
  get methodModel() {
    return (
      this.args.methodId && this.store.peekRecord('method', this.args.methodId)
    );
  }

  @computed('methodHasDisconnectNodes')
  get methodDashEnabled() {
    return this.methodHasDisconnectNodes ? true : false;
  }

  @computed('methodModel.methodNodesList.[]')
  get methodHasNodes() {
    return this.methodModel && this.methodModel.methodNodesList.length > 1;
  }

  @computed('methodModel.disconnectedNodesList.[]')
  get methodHasDisconnectNodes() {
    return this.methodModel && this.methodModel.disconnectedNodesList.length
      ? true
      : false;
  }

  @computed('method.orientation')
  get isLandscape() {
    return this.method && this.method.orientation === 'landscape';
  }

  async setBlobUrl(methodId) {
    const blobUrl = await this.methodsCacheKonva.getBlobUrl.perform(methodId);
    this.blobUrl = blobUrl;
  }

  @action
  didInsert(/*elem, [updatedAt]*/) {
    this.setBlobUrl(this.args.methodId);
  }

  @action
  onUpdate(/*elem, [updatedAt]*/) {
    this.setBlobUrl(this.args.methodId);
  }

  @action
  onClick(/*elem, [updatedAt]*/) {
    if (this.args.onClick) {
      this.args.onClick(this.args.methodId, 'method');
    }
  }
}

export default connect(stateToComputed, dispatchToActions)(MethodItem);
