import { action, computed } from '@ember/object';
import {
  defaultArtboardHeight,
  defaultArtboardWidth,
} from '../../../constants/settings';
import { getHighlight, getHighlights } from '../../../selectors/highlight';

import Component from '@glimmer/component';
import { connect } from 'ember-redux';
import { getDrawing } from '../../../selectors/drawing';
import { getMarker } from '../../../selectors/marker';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import uuid from 'uuid/v4';

const dispatchToActions = {};

const stateToComputed = (state, attrs) => ({
  drawing: getDrawing(state, attrs.drawingId),
  marker: getMarker(state, attrs.markerId),
  allHighlights: getHighlights(state),
});

class MarkerReference extends Component {
  @service redux;
  @service figuresCacheKonva;

  @tracked dataUrl;
  @tracked rendered;


  domElementId = `marker-reference-${uuid()}`;
  defaultArtboardWidth = defaultArtboardWidth;
  defaultArtboardHeight = defaultArtboardHeight;
  grayscale = true;
  quality = 'low';
  maskOpacity = 0;

  constructor() {
    super(...arguments);
    this.setDataUrl();
    this.padding = this.args.padding || 0;
    this.autoScale = this.args.autoScale;
  }

  get classNames() {
    let classNames = [this.styleNamespace, 'marker-reference'];
    return classNames.join(' ');
  }

  get styleNamespace() {
    return podNames['marker-reference'];
  }

  @action
  didInsert() {
    this.rendered = true;
  }

  @computed('drawing.orientation', 'args.forcePortrait')
  get orientation() {
    return this.drawing && this.args.forcePortrait
      ? 'portrait'
      : this.drawing.orientation;
  }

  @computed('domElementId')
  get artboardMaskId() {
    return `artboard-mask-${this.domElementId}`;
  }

  @computed('artboardWidth')
  get artboardX() {
    return (-1 * this.artboardWidth) / 2;
  }

  @computed('artboardHeight')
  get artboardY() {
    return (-1 * this.artboardHeight) / 2;
  }

  @computed('orientation', 'defaultArtboardWidth', 'defaultArtboardHeight')
  get artboardWidth() {
    return this.orientation === 'portrait'
      ? this.defaultArtboardWidth
      : this.defaultArtboardHeight;
  }

  @computed('orientation', 'defaultArtboardWidth', 'defaultArtboardHeight')
  get artboardHeight() {
    return this.orientation === 'portrait'
      ? this.defaultArtboardHeight
      : this.defaultArtboardWidth;
  }

  @computed('boundingBox.{x,width}')
  get markerX() {
    return this.boundingBox
      ? this.boundingBox.x + this.boundingBox.width / 2
      : 0;
  }

  @computed('boundingBox.{y,height}')
  get markerY() {
    return this.boundingBox
      ? this.boundingBox.y + this.boundingBox.height / 2
      : 0;
  }

  @computed('boundingBox.height')
  get boundingBoxHeight() {
    return this.boundingBox ? this.boundingBox.height : 0;
  }

  @computed('boundingBox.width')
  get boundingBoxWidth() {
    return this.boundingBox ? this.boundingBox.width : 0;
  }

  @computed('artboardWidth', 'scale')
  get svgWidth() {
    return this.artboardWidth * this.scale;
  }

  @computed('artboardHeight', 'scale')
  get svgHeight() {
    return this.artboardHeight * this.scale;
  }

  @computed('svgWidth', 'markerX', 'autoScale')
  get svgX() {
    const centerX = (-1 * this.svgWidth) / 2;
    return this.autoScale ? centerX + this.markerX : centerX;
  }

  @computed('svgHeight', 'markerY', 'autoScale')
  get svgY() {
    const centerY = (-1 * this.svgHeight) / 2;
    return this.autoScale ? centerY + this.markerY : centerY;
  }

  @computed('marker.highlightsList')
  get highlightsList() {
    return this.marker && this.marker.highlightsList;
  }

  @computed('highlightsList.length')
  get hasHighlights() {
    return this.highlightsList && this.highlightsList.length ? true : false;
  }

  @computed('highlightsList.[]', 'allHighlights')
  get highlights() {
    const state = this.redux.getState();
    return this.highlightsList.map((highlightId) =>
      getHighlight(state, highlightId)
    );
  }

  @computed('domElementId', 'marker', 'rendered', 'hasHighlights')
  get boundingBox() {
    const containerClass = this.hasHighlights
      ? '.highlights-container'
      : '.marker-container';
    return (
      this.rendered &&
      document
        .querySelector(`#${this.domElementId} ${containerClass}`)
        .getBBox()
    );
  }

  @computed(
    'rendered',
    'padding',
    'boundingBox.width',
    'boundingBoxHeight',
    'boundingBoxWidth',
    'artboardWidth',
    'artboardHeight'
  )
  get boundingScale() {
    const scaleX =
      (this.boundingBoxWidth + this.padding) /
      Math.min(this.artboardWidth, this.artboardHeight);
    const scaleY =
      (this.boundingBoxHeight + this.padding) /
      Math.min(this.artboardWidth, this.artboardHeight);
    const scale = Math.max(scaleX, scaleY);
    return scale;
  }

  @computed('autoScale', 'boundingScale')
  get scale() {
    return this.autoScale ? this.boundingScale : 1;
  }

  @computed('drawing.updatedAt')
  get updatedAt() {
    return this.drawing && this.drawing.updatedAt;
  }

  async setDataUrl() {
    const dataUrl = await this.figuresCacheKonva.getDataUrl(
      this.args.drawingId
    );
    this.dataUrl = dataUrl;
  }

  @action
  onUpdate(/*elem, [updatedAt]*/) {
    this.setDataUrl();
  }
}

export default connect(stateToComputed, dispatchToActions)(MarkerReference);
