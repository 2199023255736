/* eslint-disable ember/no-mixins */
/* eslint-disable ember/no-observers */
import Component from '@ember/component';
import { computed, observer } from '@ember/object';
import { alias, not } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { connect } from 'ember-redux';
import { updateInventionName } from '../../../actions/invention';
import { getInvention } from '../../../selectors/invention';
import { getRootNodeId } from '../../../selectors/graph';
import { getNameExists } from '../../../selectors/invention';
import DebounceUpdateAttributesMixin from '../../../mixins/debounce-update-attributes';

const dispatchToActions = {};

const stateToComputed = (state, attrs) => ({
  invention: getInvention(state, attrs.inventionId, attrs.inventionId),
  inventionElementId: getRootNodeId(state)
});

const InventionElementNameEditor = Component.extend(DebounceUpdateAttributesMixin, {
  _elementId: null,
  name: alias('invention.name'),
  isUnique: true,
  isntUnique: not('isUnique'),
  models: service(),

  elementModel: computed('inventionElementId', function() {
    return this.models.find(this.inventionElementId);
  }),

  inventionModel: computed('invention.name', 'inventionId', function() {
    return this.models.findOrCreate(this.inventionId, 'invention', { name: this.invention.name });
  }),

  nameObserver: observer('invention.name', function() {
    if (!this.isFocused) {
      this.set('_name', this.inventionModel.name);
    }
  }),

  init() {
    this._super(...arguments);
    this.set('_name', this.name);
  },

  onDebounceUpdateAttributes(changedAttributes) {
    this.redux.store.dispatch(updateInventionName(changedAttributes.name, this.inventionElementId));
  },

  getIsUnique(name) {
    const state = this.redux.store.getState();
    const elementNameExists = getNameExists(state, this.inventionElementId, name);
    return !elementNameExists;
  },

  actions: {
    onDebounceEdit(name) {
      if (name && name !== this.name) {
        if (this.getIsUnique(name)) {
          this.set('isUnique', true);
          this.inventionModel.set('name', name);
          this.elementModel.set('name', name);
          this.debounceUpdateAttributes.perform({ name });
        } else {
          this.set('isUnique', false);
          this.set('uniqueErrorName', name);
        }
      }
    },
    onFocus() {
      this.set('isFocused', true);
    },
    onBlur() {
      this.set('isFocused', false);
    }
  }
});

export default connect(
  stateToComputed,
  dispatchToActions
)(InventionElementNameEditor);
