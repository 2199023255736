export default {
  attributes: {
    id: {
      type: 'uuid',
      defaultValue: null
    },
    type: {
      type: 'type',
      defaultValue: 'has-element'
    },
    precisionType: {
      type: 'type',
      defaultValue: 'comprises' // 'consists-of', 'custom'
    },
    precision: {
      type: 'string',
      defaultValue: ''
    },
    amountType: {
      type: 'type',
      defaultValue: 'one' // 'plurality', 'custom'
    },
    amount: {
      type: 'type',
      defaultValue: ''
    },
    label: {
      type: 'string',
      defaultValue: ''
    },
    source: {
      type: 'uuid',
      defaultValue: null
    },
    target: {
      type: 'uuid',
      defaultValue: null
    },
    createdAt: {
      type: 'date'
    }
  }
}
