export default {
  ids: [
    'invention',
    'product',
    'possession',
    'enablement',
    'anticipation',
    'obviousness',
    'specification',
    'review',
    'file',
  ],
  entities: {
    invention: {
      id: 'invention',
      assertion: 'I have completed the invention overview',
      reviewAssertion: 'Invention overview has been reviewed',
      title: 'Invention Overview',
      subtitle: '',
      description:
        'Give your invention a title and briefly describe who its for, what it does, how it works and why its better.',
      prerequisite: null,
      documentation: 'invention_milestone',
      steps: [
        {
          name: 'Invention title was filled out',
        },
        {
          name: 'Short description was completed',
        },
        {
          name: 'Invention sketch was added',
        },
      ],
    },
    product: {
      id: 'product',
      assertion: 'I have completed the prototype details',
      reviewAssertion: 'Prototype details have been reviewed',
      title: 'Prototype Details',
      subtitle: '',
      description: 'Identify and summarize this prototype of your invention.',
      prerequisite: 'invention',
      documentation: 'product_milestone',
      steps: [
        {
          name: 'Product name was filled out',
          optional: true,
        },
        {
          name: 'Customers were identified',
        },
        {
          name: 'Patentability was completed',
        },
      ],
    },
    possession: {
      id: 'possession',
      assertion:
        'I have identified and described all requirements for this version to work',
      reviewAssertion: 'Requirements have been reviewed',
      title: 'Requirements',
      subtitle: 'Possession',
      description:
        'Break down your invention into a nested set of Part and System requirements and then identify one solution for each.',
      prerequisite: 'product',
      documentation: 'possession_milestone',
      steps: [
        { name: 'All Part and System Requirements are identified' },
        { name: 'Each Part Requirement is named' },
        { name: 'Each Part Requirement has at least one described Function' },
        { name: 'Each Part Requirement has at least one solution' },
        { name: 'Each Part Requirement is connected to the Prototype Tree' },
        { name: 'Each Part Solution type is selected' },
        { name: 'Each Part Solution is determined to be "Designing" or not' },
        {
          name: 'Each Part Solution is identified and highlighted in at least one drawing',
        },
        {
          name: 'Each designed Part Solution has correctly identified child Part Requirements',
        },
        { name: 'Each System Requirement is named' },
        { name: 'Each System Requirement has at least one described Function' },
        { name: 'Each System Requirement has a described Success Event' },
        { name: 'Each System Requirement has at least one solution' },
        { name: 'Each System Requirement is connected to the Prototype Tree' },
        { name: 'Each System Solution type is selected' },
        { name: 'Each System Solution is determined to be "Designing" or not' },
        {
          name: 'Each System Solution is identified and highlighted in at least one drawing',
          optional: true,
        },
        {
          name: 'Each designed System Solution has correctly identified child System Requirements',
        },
        {
          name: 'Each designed System Solution has a described Method flowchart',
        },
      ],
    },
    enablement: {
      id: 'enablement',
      assertion:
        'I have described a plausible solution for each Part and System requirement',
      reviewAssertion: 'Solutions have been reviewed',
      title: 'Solutions',
      subtitle: 'Enablement',
      description:
        'Describe each solution with sufficient detail such that another person skilled in the art could make and use it.',
      prerequisite: 'possession',
      documentation: 'enablement_milestone',
      steps: [
        { name: 'All Part and System solutions are described' },
        { name: "Each Solution's constraints are described" },
        { name: "Each Solution's features are described" },
        {
          name: "Each Solution's definitions are described (if not designing)",
        },
        { name: "Each Solution's examples are described (if not designing)" },
      ],
    },
    anticipation: {
      id: 'anticipation',
      assertion:
        'I believe there is no prior art with all the same requirements',
      reviewAssertion: 'Novelty / Anticipation has been reviewed',
      title: 'Novelty',
      subtitle: 'Anticipation',
      description:
        "Compare requirements with prior art to help identify a product's novelty.",
      prerequisite: 'enablement',
      documentation: 'anticipation_milestone',
      steps: [
        {
          name: 'Prior art was identified',
        },
        {
          name: 'Part and System requirements compared to prior art and no prior art exists with all the same requirements',
        },
      ],
    },
    obviousness: {
      id: 'obviousness',
      assertion: 'Each novel requirement has a designed solution',
      reviewAssertion: 'Novelty / Obviousness has been reviewed',
      title: 'Novelty',
      subtitle: 'Obviousness',
      description:
        'Combining non-designed solutions together to solve a requirement can be perceived as being obvious which can lead to a patent examiner rejection.',
      prerequisite: 'anticipation',
      documentation: 'obviousness_milestone',
      steps: [
        {
          name: 'Each novel requirement identified has a "Designed" solution',
        }
      ],
    },
    specification: {
      id: 'specification',
      assertion:
        'I have completed all required fields in the patent specification',
      reviewAssertion: 'Specification has been reviewed',
      title: 'Specification',
      subtitle: '',
      description:
        'Title your invention, describe the background, summary, inventors, and more to get ready to file your patent application.',
      prerequisite: 'obviousness',
      documentation: 'specification_milestone',
      steps: [
        {
          name: 'Invention title was added',
        },
        {
          name: 'Cross references were described',
          optional: true,
        },
        {
          name: 'Funding sources were disclosed',
          optional: true,
        },
        {
          name: 'Inventors were named',
        },
        {
          name: 'Fields of the invention were identified',
        },
        {
          name: 'Background of the invention was described',
        },
        {
          name: 'Summary of the invention was described',
        },
        {
          name: 'Narrative structure for the detailed description was chosen',
        },
        {
          name: 'Boilerplate was added',
          optional: true,
        },
        {
          name: 'Abstract was described',
        },
      ],
    },
    review: {
      id: 'review',
      assertion: 'I have completed a final review',
      reviewAssertion: '',
      title: 'Final review',
      subtitle: '',
      description:
        'Enter review mode and step through your invention to double check the content',
      prerequisite: 'specification',
      documentation: 'review_milestone',
      steps: [],
    },
    file: {
      id: 'file',
      assertion: 'I have filed a patent application',
      reviewAssertion: '',
      title: 'Apply',
      subtitle: '',
      description:
        'Export your specification and drawings and apply for a patent online at the USPTO.',
      prerequisite: 'review',
      documentation: 'file_milestone',
      steps: [],
    },
  },
};
