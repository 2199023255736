import Component from '@glimmer/component';
import { action } from '@ember/object';
import { connect } from 'ember-redux';
import { getPriorArt } from '../../../selectors/prior-art';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

const stateToComputed = (state, attrs) => ({
  priorArt: getPriorArt(state, attrs.priorArtId),
});

const dispatchToActions = {};

class PriorArtItem extends Component {
  @service assets;

  @tracked blobUrl;

  get styleNamespace() {
    return podNames['prior-art-item'];
  }

  get classNames() {
    let classNames = [this.styleNamespace, 'prior-art-item'];
    if (this.args.isContain) classNames.push('is-contain');
    return classNames.join(' ');
  }

  @action
  onClick() {
    if (this.args.onClick) this.args.onClick(this.args.priorArtId);
  }

}

export default connect(stateToComputed, dispatchToActions)(PriorArtItem);
