import Component from '@glimmer/component';
import { action } from '@ember/object';
import { connect } from 'ember-redux';
import { getMappedFiguresList } from '../../../selectors/figure';
import { getPatentSpecification } from '../../../selectors/patent-specification';
import { getPreviewMode } from '../../../selectors/invention-ui';
import { getTermsList } from '../../../selectors/term';
import { next } from '@ember/runloop';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

const stateToComputed = (state) => ({
  patentSpecification: getPatentSpecification(state),
  previewMode: getPreviewMode(state),
  termsList: getTermsList(state),
  mappedFiguresList: getMappedFiguresList(state),
});

const dispatchToActions = {};

class PatentSpecificationDocument extends Component {
  @service redux;

  language = 'patent_specification_preferred_version';

  @tracked isReady = false;


  @action
  didInsert() {
    if (this.args.scrollTop) {
      next(() => {
        const domElement = document.getElementById('patent-specification');
        if (domElement) {
          domElement.scrollTop = this.args.scrollTop;
        }
        this.isReady = true;
      });
    } else {
      this.isReady = true;
    }
  }

  get styleNamespace() {
    return podNames['patent-specification-document'];
  }

  get classNames() {
    let classNames = [this.styleNamespace, 'patent-specification-document'];
    if (this.isReady) classNames.push('is-ready')
    return classNames.join(' ');
  }
}

export default connect(
  stateToComputed,
  dispatchToActions
)(PatentSpecificationDocument);
