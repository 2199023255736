import Component from '@glimmer/component';
import { action } from '@ember/object';
import { htmlSafe } from '@ember/string';
import podNames from 'ember-component-css/pod-names';

export default class DisclosureCreateDrawingItem extends Component {

  get styleNamespace() {
    return podNames['disclosure-create-drawing-item'];
  }

  get imageStyle() {
    return this.args.url && htmlSafe(`background-image: url(${this.args.url})`);
  }

  @action
  onRemove() {
    if (this.args.onRemove) {
      this.args.onRemove(this.args.assetId);
    }
  }
}
