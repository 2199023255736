import { getPatentabilityType, getPatentabilityTypes } from './type';

import { get } from '@ember/object';
import { getElementVersion } from './element-version';
import { getPresentState } from '../utils/redux';

export const getProduct = (state, productId) =>
  get(getPresentState(state), `products.entities.${productId}`);

export const getPreferredElementVersionsList = (state, productId) => {
  productId = productId || getPreferredProductId(state);
  return productId
    ? get(
        getPresentState(state),
        `products.entities.${productId}.preferredElementVersionsList`
      )
    : [];
};

export const getPreferredElementVersionId = (state, elementId, productId) => {
  const preferredElementVersionsList = getPreferredElementVersionsList(
    state,
    productId
  );
  const preferredObj = preferredElementVersionsList.find(
    (obj) => obj.element === elementId
  );
  return preferredObj && preferredObj.elementVersion;
};

export const getPreferredElementVersion = (state, elementId, productId) => {
  const preferredElementVersionsList = getPreferredElementVersionsList(
    state,
    productId
  );
  const preferredObj = preferredElementVersionsList.find(
    (obj) => obj.element === elementId
  );
  return (
    preferredObj &&
    preferredObj.elementVersion &&
    getElementVersion(state, preferredObj.elementVersion)
  );
};

export const getIsPreferred = (
  state,
  elementId,
  elementVersionId,
  productId
) => {
  const preferredElementVersionsList = getPreferredElementVersionsList(
    state,
    productId
  );
  return preferredElementVersionsList.find(
    (obj) =>
      obj.element === elementId && obj.elementVersion === elementVersionId
  )
    ? true
    : false;
};

export const getProductPatentabilityValue = (
  state,
  productId,
  patentabilityTypeId
) => {
  const product = getProduct(state, productId);
  return (
    product &&
    product.patentability &&
    product.patentability[patentabilityTypeId] &&
    product.patentability[patentabilityTypeId].value
  );
};

export const getProductPatentabilityStatus = (state, productId) => {
  const typesList = getPatentabilityTypes();
  const completed = typesList.filter((typeId) => {
    return getProductPatentabilityValue(state, productId, typeId);
  });
  const warnings = typesList.filter((typeId) => {
    const type = getPatentabilityType(typeId);
    const value = getProductPatentabilityValue(state, productId, typeId);
    return value && type && type.answers && !type.answers.includes(value);
  });

  const isComplete = typesList.length === completed.length;
  const inProgress = completed.length && !isComplete;
  const isPatentable = isComplete && warnings.length === 0;

  const incomplete = typesList.filter((typeId) => {
    return !getProductPatentabilityValue(state, productId, typeId);
  });

  return {
    inProgress,
    isComplete,
    isPatentable,
    incomplete,
    completed,
    warnings,
  };
};

export const getPreferredProductId = (state) => {
  return get(getPresentState(state), `products.ids`)[0];
};

export const getProductsList = (state) =>
  get(getPresentState(state), `products.ids`);

export const getProducts = (state) =>
  get(getPresentState(state), `products.entities`);
