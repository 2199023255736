import Component from '@glimmer/component';
import { computed } from '@ember/object';
import { connect } from 'ember-redux';
import podNames from 'ember-component-css/pod-names';
import { getPriorArt } from '../../../selectors/prior-art';
import moment from 'moment';

const stateToComputed = (state, attrs) => ({
  priorArt: getPriorArt(state, attrs.priorArtId),
});

const dispatchToActions = {};

class PriorArt extends Component {
  get styleNamespace() {
    return podNames['prior-art'];
  }

  @computed('priorArt.conceivedAt')
  get conceivedAtText() {
    return (
      this.priorArt &&
      this.priorArt.conceivedAt &&
      moment(this.priorArt.conceivedAt).format('MMMM Do YYYY, h:mm a')
    );
  }
}

export default connect(stateToComputed, dispatchToActions)(PriorArt);
