import Component from '@glimmer/component';
import { action } from '@ember/object';
import { connect } from 'ember-redux';
import { getActiveInventionSummarySection } from '../../../selectors/invention-ui';
import { getPatentSpecification } from '../../../selectors/patent-specification';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';

const stateToComputed = (state) => ({
  // productId: getPatentSpecification(state),
  patentSpecification: getPatentSpecification(state),
  activeSection: getActiveInventionSummarySection(state),
});
const dispatchToActions = {};

class InventionSummaryEditor extends Component {
  @service tracking;

  get styleNamespace() {
    return podNames['invention-summary-editor'];
  }

  get classNames() {
    let classNames = [this.styleNamespace, 'invention-summary-editor'];
    return classNames.join(' ');
  }

  @action
  didInsert() {
    this.tracking.trackEvent('opened_invention_overview');
  }

  @action
  hideModal() {
    this.args.onSetShowingInventionSummaryEditor(false);
    this.tracking.trackEvent('closed_invention_overview');
  }

  @action
  onSelect(value) {
    this.args.onSetActiveInventionSummaryEditorSection(value);
  }

  @action
  onUpdateInventionTitle(id, attributes) {
    this.tracking.trackEvent('updated_invention_title');
    this.args.onUpdatePatentSpecification(id, attributes)
  }

  @action
  onUpdateInventionDescription(id, attributes) {
    this.tracking.trackEvent('updated_invention_description');
    this.args.onUpdatePatentSpecification(id, attributes)
  }

}

export default connect(
  stateToComputed,
  dispatchToActions
)(InventionSummaryEditor);
