import {
  ADD_IMAGE,
  UPDATE_IMAGE
} from './actionTypes';
import uuid from 'uuid/v4';

export const addImage = (
  attributes,
  imageId = uuid()
) => {
  return {
    type: ADD_IMAGE,
    payload: {
      imageId,
      attributes
    }
  };
};

export const updateImage = (imageId, attributes) => {
  return {
    type: UPDATE_IMAGE,
    payload: {
      imageId,
      attributes
    }
  };
};
