import {
  defaultArtboardHeight,
  defaultArtboardWidth,
} from '../constants/settings';
import { getFeatureRealization, realize } from '../utils/realization';
import {
  getMethodEdgeNumber,
  getMethodNodeNumber,
  getMethodNodeOrdinal,
} from '../selectors/invention';
import {
  getMethodNode,
  getMethodNodeFeatureId,
} from '../selectors/method-node';
import { keyBy, sortBy } from 'lodash';

import Konva from 'konva';
import { getElement } from '../selectors/element';
import { getFeature } from '../selectors/feature';
import { getMethod } from '../selectors/method';
import { getMethodEdge } from '../selectors/method-edge';
import { underscore } from '@ember/string';

// export const getMethodRealizations = (
//   invention,
//   methodId /*, realizedElementVersions = {}*/
// ) => {
//   const method = invention.methods[methodId];
//   const startingMethodNode = method.startingMethodNode;
//   const allMethodNodes = invention.methodNodes;
//   const allMethodEdges = invention.methodEdges;
//   const allElements = invention.elements;
//   const allFeatures = invention.features;
//   let realizations = [];

//   if (startingMethodNode) {
//     const methodRealizationGroups = getMethodRealizationGroups(
//       startingMethodNode,
//       allMethodNodes,
//       allMethodEdges,
//       allFeatures,
//       allElements
//     );
//     realizations = methodRealizationGroups.map((nodes, index) => {
//       return getMethodGroupRealization(nodes, index, invention);
//     });
//   }
//   return realizations;
// };

export const getRandomMethodNodeCoords = (
  orientation = 'portrait',
  width = 280,
  height = 70
) => {
  const artboardWidth =
    orientation === 'portrait' ? defaultArtboardWidth : defaultArtboardHeight;
  const artboardHeight =
    orientation === 'portrait' ? defaultArtboardHeight : defaultArtboardWidth;
  const paddedWidth = artboardWidth / 4;
  const paddedHeight = artboardHeight / 4;
  let x =
    Math.floor(Math.random() * paddedWidth) *
    (Math.round(Math.random()) ? 1 : -1);

  let y =
    Math.floor(Math.random() * paddedHeight) *
    (Math.round(Math.random()) ? 1 : -1);

  x = x - width / 2;
  y = y - height / 2;

  return { x, y };
};

export const getMethodRealization = (
  state,
  methodId,
  groupedStepsList,
  traversedNodesList,
  traversedEdgesList
) => {
  return groupedStepsList
    .map((groupedSteps, index) => {
      return getMethodGroupRealization(
        state,
        methodId,
        groupedSteps,
        traversedNodesList,
        traversedEdgesList,
        index,
        'patent_specification_preferred_version'
      );
    })
    .join(' ');
};

export const getMethodStepRealization = (
  state,
  methodId,
  step,
  traversedNodesList,
  traversedEdgesList,
  index,
  language = 'patent_specification_preferred_version',
  featureLanguage = 'patent_specification_preferred_version',
  text
) => {
  const query = {
    conditional_count: 0,
    sequential_count: 0,
    alternative_count: 0,
    revisited_conditional_count: 0,
    revisited_sequential_count: 0,
    revisited_alternative_count: 0,
    language,
    index,
  };

  const context = {
    sequential_collection: [],
    conditional_collection: [],
    alternative_collection: [],
    revisited_sequential_collection: [],
    revisited_conditional_collection: [],
    revisited_alternative_collection: [],
  };

  let sequential_index = 0;
  let conditional_index = 0;
  let alternative_index = 0;
  let revisited_sequential_index = 0;
  let revisited_conditional_index = 0;
  let revisited_alternative_index = 0;

  const { nodeId, viaNodeId, revisited, viaEdgeType } = step;
  const methodNode = getMethodNode(state, nodeId);
  const number = getMethodNodeNumber(nodeId, traversedNodesList);
  const viaNumber = getMethodNodeNumber(viaNodeId, traversedNodesList);
  const ordinal = getMethodNodeOrdinal(
    state,
    methodId,
    nodeId,
    traversedNodesList
  );
  const viaOrdinal =
    viaNodeId &&
    getMethodNodeOrdinal(state, methodId, viaNodeId, traversedNodesList);

  const _element = getElement(state, methodNode.element);
  const featureId = getMethodNodeFeatureId(state, methodNode.id);

  if (!text) {
    const feature = getFeature(state, featureId);
    text = getFeatureRealization({
      state,
      featuresList: [featureId],
      featureTypeId: feature.type,
      elementId: _element && _element.id,
      language: featureLanguage,
      // language: 'report_list',
    });
  }

  // Then go back to step "if the goods are got".

  const revisitedPreamble = revisited ? 'revisited_' : '';
  const underscoredTypeId = underscore(viaEdgeType);

  if (query[`${revisitedPreamble}${underscoredTypeId}_count`]) {
    query[`${revisitedPreamble}${underscoredTypeId}_count`]++;
  } else {
    query[`${revisitedPreamble}${underscoredTypeId}_count`] = 1;
  }

  const stepRealization = {
    number,
    viaNumber,
    ordinal,
    viaOrdinal,
    text,
  };

  if (viaEdgeType === 'sequential') {
    if (revisited) {
      context[`revisited_sequential_collection`].push(stepRealization);
      context[`revisited_sequential_${revisited_sequential_index}`] =
        stepRealization;
      revisited_sequential_index++;
    } else {
      context[`sequential_collection`].push(stepRealization);
      context[`sequential_${sequential_index}`] = stepRealization;
      sequential_index++;
    }
  }

  if (viaEdgeType === 'conditional') {
    if (revisited) {
      context[`revisited_conditional_collection`].push(stepRealization);
      context[`revisited_conditional_${revisited_conditional_index}`] =
        stepRealization;
      revisited_conditional_index++;
    } else {
      context[`conditional_collection`].push(stepRealization);
      context[`conditional_${conditional_index}`] = stepRealization;
      conditional_index++;
    }
  }

  if (viaEdgeType === 'alternative') {
    if (revisited) {
      context[`revisited_alternative_collection`].push(stepRealization);
      context[`revisited_alternative_${revisited_alternative_index}`] =
        stepRealization;
      revisited_alternative_index++;
    } else {
      context[`alternative_collection`].push(stepRealization);
      context[`alternative_${alternative_index}`] = stepRealization;
      alternative_index++;
    }
  }
  return realize(query, context);
};

export const getMethodGroupRealization = (
  state,
  methodId,
  groupedStepsList,
  traversedNodesList,
  traversedEdgesList,
  index,
  language = 'patent_specification_preferred_version',
  featureLanguage = 'patent_specification_preferred_version'
) => {
  const query = {
    conditional_count: 0,
    sequential_count: 0,
    alternative_count: 0,
    revisited_conditional_count: 0,
    revisited_sequential_count: 0,
    revisited_alternative_count: 0,
    language,
    index,
  };

  const context = {
    sequential_collection: [],
    conditional_collection: [],
    alternative_collection: [],
    revisited_sequential_collection: [],
    revisited_conditional_collection: [],
    revisited_alternative_collection: [],
  };

  let sequential_index = 0;
  let conditional_index = 0;
  let alternative_index = 0;
  let revisited_sequential_index = 0;
  let revisited_conditional_index = 0;
  let revisited_alternative_index = 0;

  groupedStepsList.forEach((step) => {
    const { nodeId, viaNodeId, revisited, viaEdgeType } = step;
    const methodNode = getMethodNode(state, nodeId);
    const number = getMethodNodeNumber(nodeId, traversedNodesList);
    const viaNumber = getMethodNodeNumber(viaNodeId, traversedNodesList);
    const ordinal = getMethodNodeOrdinal(
      state,
      methodId,
      nodeId,
      traversedNodesList
    );
    const viaOrdinal =
      viaNodeId &&
      getMethodNodeOrdinal(state, methodId, viaNodeId, traversedNodesList);

    const _element = getElement(state, methodNode.element);
    const featureId = getMethodNodeFeatureId(state, methodNode.id);

    const feature = getFeature(state, featureId);
    const text = getFeatureRealization({
      state,
      featuresList: [featureId],
      featureTypeId: feature.type,
      elementId: _element && _element.id,
      language: featureLanguage,
      // language: 'report_list',
    });

    // Then go back to step "if the goods are got".

    const revisitedPreamble = revisited ? 'revisited_' : '';
    const underscoredTypeId = underscore(viaEdgeType);

    if (query[`${revisitedPreamble}${underscoredTypeId}_count`]) {
      query[`${revisitedPreamble}${underscoredTypeId}_count`]++;
    } else {
      query[`${revisitedPreamble}${underscoredTypeId}_count`] = 1;
    }

    const stepRealization = {
      number,
      viaNumber,
      ordinal,
      viaOrdinal,
      text,
    };

    if (viaEdgeType === 'sequential') {
      if (revisited) {
        context[`revisited_sequential_collection`].push(stepRealization);
        context[`revisited_sequential_${revisited_sequential_index}`] =
          stepRealization;
        revisited_sequential_index++;
      } else {
        context[`sequential_collection`].push(stepRealization);
        context[`sequential_${sequential_index}`] = stepRealization;
        sequential_index++;
      }
    }

    if (viaEdgeType === 'conditional') {
      if (revisited) {
        context[`revisited_conditional_collection`].push(stepRealization);
        context[`revisited_conditional_${revisited_conditional_index}`] =
          stepRealization;
        revisited_conditional_index++;
      } else {
        context[`conditional_collection`].push(stepRealization);
        context[`conditional_${conditional_index}`] = stepRealization;
        conditional_index++;
      }
    }

    if (viaEdgeType === 'alternative') {
      if (revisited) {
        context[`revisited_alternative_collection`].push(stepRealization);
        context[`revisited_alternative_${revisited_alternative_index}`] =
          stepRealization;
        revisited_alternative_index++;
      } else {
        context[`alternative_collection`].push(stepRealization);
        context[`alternative_${alternative_index}`] = stepRealization;
        alternative_index++;
      }
    }
  });
  return realize(query, context);
};

export const getMethodTraversal = (state, methodId) => {
  const method = getMethod(state, methodId);

  const methodNodesList = method.methodNodesList;
  const methodEdgesList = method.methodEdgesList;

  const methodNodes = methodNodesList.map((methodNodeId) =>
    getMethodNode(state, methodNodeId)
  );

  const rootNode = methodNodes.find(
    (methodNode) => methodNode.type === 'start'
  );

  const methodEdges = method.methodEdgesList.map((methodEdgeId) =>
    getMethodEdge(state, methodEdgeId)
  );

  // turn the edges array into object for easier traversal
  const mappedEdges = keyBy(methodEdges, 'id');

  // map the outgoing and incoming edges onto the nodes array
  // for easier traversal.
  let mappedNodes = methodNodes.map((methodNode) => {
    let outgoingEdges = methodEdges
      .filter((methodEdge) => methodEdge.source === methodNode.id)
      .map((methodEdge) => {
        const targetX = getMethodNode(state, methodEdge.target).x;
        return {
          ...methodEdge,
          targetX,
        };
      });

    // sort the outgoing edges by their target's x value
    // so the output reads left to right
    // TODO: add some way to discover the flow of the diagram
    // based on the node positioning and sort by y or x depending
    outgoingEdges = sortBy(outgoingEdges, 'targetX');

    const incomingEdges = methodEdges.filter(
      (methodEdge) => methodEdge.target === methodNode.id
    );

    return {
      ...methodNode,
      outgoingEdges,
      incomingEdges,
      leaf: outgoingEdges.length ? false : true,
    };
  });

  // turn the nodes array into object for easier traversal
  mappedNodes = keyBy(mappedNodes, 'id');

  const { traversals } = depthTraverseMethod(
    rootNode.id,
    null,
    [],
    {},
    {},
    mappedNodes,
    mappedEdges
  );

  // remove the start node from the traversed nodes
  const traversalsWithoutStartNode = traversals.filter(
    (traversal) => traversal.viaEdgeId
  );

  const allTraversedNodesList = traversals.map(
    (traversal) => traversal.node.id
  );

  const traversedNodesList = traversalsWithoutStartNode.map(
    (traversal) => traversal.node.id
  );

  const traversedEdgesList = traversalsWithoutStartNode.map(
    (traversal) => traversal.viaEdgeId
  );

  // group the traversed nodes into steps for realization
  const groupedStepsList = getMethodStepGroups(traversalsWithoutStartNode);
  const ungroupedStepsList = getUngroupedMethodStepGroups(
    traversalsWithoutStartNode,
    traversedNodesList,
    traversedEdgesList
  );

  // get all the nodes not connected to the start node
  const disconnectedNodesList = method.methodNodesList.filter(
    (methodNodeId) =>
      !traversedNodesList.includes(methodNodeId) && methodNodeId !== rootNode.id
  );

  // get all the edges not connected to the start node
  const disconnectedEdgesList = method.methodEdgesList.filter(
    (methodEdgeId) => !traversedEdgesList.includes(methodEdgeId)
  );

  return {
    allTraversedNodesList,
    traversedNodesList,
    traversedEdgesList,
    disconnectedNodesList,
    disconnectedEdgesList,
    groupedStepsList,
    ungroupedStepsList,
    methodEdgesList,
    methodNodesList,
  };
};

export const depthTraverseMethod = (
  nodeId,
  viaEdgeId,
  traversals = [],
  visitedEdges = {},
  visitedNodes,
  allNodes,
  allEdges
) => {
  const node = allNodes[nodeId];
  const viaEdge = allEdges[viaEdgeId];
  const viaEdgeType = viaEdge ? viaEdge && viaEdge.type : null;
  const viaNodeId = viaEdge && viaEdge.source;

  const traversal = {
    id: nodeId,
    node,
    viaEdgeId,
    viaEdgeType,
    viaNodeId,
    revisited: false,
  };

  const visitedNodeId = nodeId;

  if (!visitedEdges[viaEdgeId] && visitedNodes[visitedNodeId]) {
    traversal.revisited = true;
  }

  if (!visitedEdges[viaEdgeId]) traversals.push(traversal);

  if (!visitedEdges[viaEdgeId] && !visitedNodes[visitedNodeId]) {
    if (viaEdgeId) {
      visitedEdges[viaEdgeId] = { id: viaEdgeId };
    }

    visitedNodes[visitedNodeId] = { id: visitedNodeId };

    node.outgoingEdges.forEach((edge, index) => {
      if (index && !visitedEdges[viaEdgeId]) {
        traversals.push({
          id: nodeId,
          node,
          viaEdgeType,
          viaEdgeId,
          revisited: visitedNodes[nodeId] ? true : false,
        });
      }
      depthTraverseMethod(
        edge.target,
        edge.id,
        traversals,
        visitedEdges,
        visitedNodes,
        allNodes,
        allEdges
      );
    });
  }
  return { traversals, visitedNodes, visitedEdges };
};

// export const getMethodTreeNodes = ({
//   startingMethodNode,
//   methodNodes,
//   methodEdges,
// }) => {
//   let methodTreeNodes = [];
//   if (startingMethodNode) {
//     methodTreeNodes = depthTraverseMethodNodes(
//       startingMethodNode,
//       null,
//       [],
//       {},
//       {},
//       methodNodes,
//       methodEdges
//     )
//       .nodes.filter((node) => node.element && node.elementVersion)
//       .uniqBy((node) => node.elementVersion)
//       .map((node) => node.id)
//       .uniq();
//   }
//   return methodTreeNodes;
// };

// export const depthTraverseMethodNodes = (
//   nodeId,
//   viaEdge,
//   nodes = [],
//   visitedEdges = {},
//   visitedNodes,
//   allNodes,
//   allEdges
// ) => {
//   const node = allNodes[nodeId];
//   // let viaNode, viaNodeType;

//   // if (viaEdge) {
//   // const edge = allEdges[viaEdge];
//   // const source = allNodes[edge.source];
//   // viaNode = source.id;
//   // viaNodeType = source.type;
//   // }

//   let decision;

//   if (node.outgoingEdgesList[0]) {
//     const outgoingEdge = allEdges[node.outgoingEdgesList[0]];
//     decision = outgoingEdge.decision;
//   }

//   const _node = {
//     decision,
//     id: nodeId,
//     type: node.type,
//     element: node.element,
//     elementVersion: node.elementVersion,
//     revisited: false,
//   };

//   const visitedNodeId =
//     node.type === 'conditional' ? `${nodeId}-${decision}` : nodeId;
//   // const visitedNodeId = nodeId;

//   if (!visitedEdges[viaEdge] && visitedNodes[visitedNodeId]) {
//     _node.revisited = true;
//   }

//   nodes.push(_node);

//   if (!visitedEdges[viaEdge] && !visitedNodes[visitedNodeId]) {
//     if (viaEdge) {
//       visitedEdges[viaEdge] = { id: viaEdge };
//     }

//     visitedNodes[visitedNodeId] = { id: visitedNodeId };

//     node.outgoingEdgesList.forEach((edgeId, index) => {
//       const edge = allEdges[edgeId];
//       if (index) {
//         nodes.push({
//           id: nodeId,
//           decision: edge.decision,
//           type: node.type,
//           element: node.element,
//           elementVersion: node.elementVersion,
//           revisited: visitedNodes[nodeId] ? true : false,
//         });
//       }
//       depthTraverseMethodNodes(
//         edge.target,
//         edgeId,
//         nodes,
//         visitedEdges,
//         visitedNodes,
//         allNodes,
//         allEdges
//       );
//     });
//   }
//   return { nodes, visitedNodes, visitedEdges };
// };

// const getMethodStepGroups = (traversals) => {
//   const groups = [];
//   let index = 0;
//   let previousType;
//   let previousRevisited;

//   traversals.forEach((traversal) => {
//     const _node = {
//       id: node.id,
//       type: node.type,
//       element: node.element,
//       step: node.step,
//       value: node.value,
//       revisited: node.revisited,
//     };
//     if (!groups[index]) {
//       groups[index] = {
//         type: node.type,
//         nodesList: [_node],
//       };
//       if (node.type === 'sequential') index++;
//     } else if (
//       node.type === 'conditional' &&
//       previousType === 'conditional' &&
//       !previousRevisited
//     ) {
//       groups[index] = {
//         ...groups[index],
//         nodesList: [...groups[index].nodesList, _node],
//       };
//     } else if (
//       node.type === 'sequential' &&
//       previousType === 'conditional' &&
//       !previousRevisited
//     ) {
//       groups[index] = {
//         ...groups[index],
//         nodesList: [...groups[index].nodesList, _node],
//       };
//     } else {
//       index++;
//       groups[index] = {
//         type: node.type,
//         nodesList: [_node],
//       };
//     }
//     previousType = node.type;
//     previousRevisited = node.revisited;
//   });

//   return groups.map((group) => group.nodesList);
// };

const getMethodStepGroups = (traversals) => {
  const groups = [];
  let index = 0;
  let previousType;
  let previousRevisited;

  // return [];

  traversals.forEach((traversal) => {
    const { node, viaEdgeType, viaNodeId, revisited } = traversal;
    const step = {
      viaEdgeType,
      viaNodeId,
      nodeId: node.id,
      revisited,
    };
    if (!groups[index]) {
      groups[index] = {
        stepsList: [step],
      };
      if (viaEdgeType === 'sequential' || viaEdgeType === 'alternative')
        index++;
    } else if (
      viaEdgeType === 'conditional' &&
      previousType === 'conditional' &&
      !previousRevisited
    ) {
      groups[index] = {
        ...groups[index],
        stepsList: [...groups[index].stepsList, step],
      };
    } else if (
      viaEdgeType === 'sequential' &&
      previousType === 'conditional' &&
      !previousRevisited
    ) {
      groups[index] = {
        ...groups[index],
        stepsList: [...groups[index].stepsList, step],
      };
    } else if (
      viaEdgeType === 'alternative' &&
      previousType === 'conditional' &&
      !previousRevisited
    ) {
      groups[index] = {
        ...groups[index],
        stepsList: [...groups[index].stepsList, step],
      };
    } else {
      index++;
      groups[index] = {
        stepsList: [step],
      };
    }
    previousType = node.leaf ? null : viaEdgeType;
    previousRevisited = node.revisited;
  });
  return groups.map((group) => group.stepsList);
  // return groups.map((group) => {
  //   return group.stepsList.map((step) => step.node.value);
  // });
};

export const getUngroupedMethodStepGroups = (
  traversals,
  traversedNodesList,
  traversedEdgesList
) => {
  return traversals.map((traversal) => {
    const { node, viaEdgeId, viaEdgeType, viaNodeId, revisited } = traversal;
    const nodeId = node.id;
    const modelId = revisited ? viaEdgeId : nodeId;
    const modelType = revisited ? 'method-edge' : 'method-node';
    const number =
      modelType === 'method-node'
        ? getMethodNodeNumber(nodeId, traversedNodesList)
        : getMethodEdgeNumber(viaEdgeId, traversedEdgesList);

    const step = {
      viaEdgeId,
      viaEdgeType,
      viaNodeId,
      nodeId,
      revisited,
      modelId,
      modelType,
      number,
    };

    return step;
  });
};

export const getNodeHeight = (text, width, layoutOptions = {}) => {
  const options = {
    align: 'center',
    fontSize: 18,
    fontFamily: 'Inter',
    lineHeight: 1.4,
    padding: 14,
    listening: false,
    ...layoutOptions,
  };

  const node = new Konva.Text({
    width,
    text,
    ...options,
  });

  return node.height();
};
