import { action, computed } from '@ember/object';
import {
  tributeElementMentionTemplate,
  tributeTermMentionTemplate,
} from '../../../utils/mentions';

import Component from '@glimmer/component';
import { connect } from 'ember-redux';
import { getElement } from '../../../selectors/element';
import { getElementVersion } from '../../../selectors/element-version';
import { getTerm } from '../../../selectors/term';
import { htmlSafe } from '@ember/string';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

const stateToComputed = (state, attrs) => ({
  term: attrs.resultType === 'term' && getTerm(state, attrs.resultId),
  element: attrs.resultType === 'element' && getElement(state, attrs.resultId),
  elementVersion:
    attrs.resultType === 'element' &&
    getElementVersion(state, attrs.resultElementVersion),
});

const dispatchToActions = {};

class DrawingCreateBulkSearchResult extends Component {
  @service redux;
  @service contextMenu;
  @service documentSelection;
  @service tracking;

  @tracked searchResults = [];

  get styleNamespace() {
    return podNames['drawing-create-bulk-search-result'];
  }

  get classNames() {
    let classNames = [this.styleNamespace, 'drawing-create-bulk-search-result'];
    return classNames.join(' ');
  }
  
  @computed(
    'args.resultType',
    'element.category',
  )
  get category() {
    return this.args.resultType === 'term'
      ? 'term'
      : this.element && this.element.category;
  }

  @computed(
    'args.{isInline,resultType}', 'element.{id,name}', 'elementVersion.name', 'term.{id,name}'
  )
  get resultTemplate() {
    let template;
    switch (this.args.resultType) {
      case 'term':
        template = tributeTermMentionTemplate(this.term.id, this.term.name);
        break;
      case 'element': {
        template = tributeElementMentionTemplate(
          this.element.id,
          [this.elementVersion.name],
          this.element.name,
          this.args.isInline ? false : true
        );
        break;
      }
    }
    return template;
  }
  
  @computed('resultTemplate')
  get resultTemplateHTML() {
    return this.resultTemplate && htmlSafe(this.resultTemplate);
  }

  @action
  onMousedown() {
    if (this.args.onSelect) {
      this.args.onSelect(
        this.args.resultId,
        this.args.resultType,
        this.args.resultElementVersion,
        this.category
      );
    }
  }
}

export default connect(stateToComputed, dispatchToActions)(DrawingCreateBulkSearchResult);
