import { action, computed } from '@ember/object';

import Component from '@glimmer/component';
import { connect } from 'ember-redux';
import { getInventionUi } from '../../../selectors/invention-ui';
import { getPriorArtsList } from '../../../selectors/prior-art';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

const dispatchToActions = {};

const stateToComputed = (state) => ({
  inventionUi: getInventionUi(state),
  priorArtsList: getPriorArtsList(state),
});

class PriorArtsContext extends Component {
  @service applicationState;

  @tracked switched;

  get classNames() {
    let classNames = [this.styleNamespace, 'prior-arts-context'];
    return classNames.join(' ');
  }

  get styleNamespace() {
    return podNames['prior-arts-context'];
  }

  @computed('applicationState.undoIndex')
  get undoIndex() {
    return this.applicationState.undoIndex;
  }

  @computed('priorArtsList.[]')
  get firstPriorArt() {
    return (
      this.priorArtsList && this.priorArtsList.length && this.priorArtsList[0]
    );
  }

  @computed('inventionUi.selectedPriorArts.[]', 'firstPriorArt', 'creatingPriorArt')
  get selectedPriorArtId() {
    let selectedPriorArtId =
      (this.inventionUi &&
        this.inventionUi.selectedPriorArts &&
        this.inventionUi.selectedPriorArts[0]) ||
      this.firstPriorArt;
    
    return this.creatingPriorArt ? null : selectedPriorArtId
  }

  @action
  onUpdate() {
    this.switched = !this.switched;
  }

  @action
  onAddPriorArt() {
    this.args.onAddPriorArt();
  }

  @action
  onRemovePriorArt(priorArtId) {
    this.args.onRemovePriorArt(priorArtId);
  }


}

export default connect(stateToComputed, dispatchToActions)(PriorArtsContext);
