import Component from '@glimmer/component';
import { action } from '@ember/object';
import podNames from 'ember-component-css/pod-names';

class UiContextContainerContent extends Component {
  get classNames() {
    let classNames = [this.styleNamespace, 'ui-context-container-content'];
    return classNames.join(' ');
  }

  get styleNamespace() {
    return podNames['ui-context-container-content'];
  }

  @action
  onResize() {
    if (this.args.onResize) {
      this.args.onResize();
    }
  }
}

export default UiContextContainerContent;
