import Component from '@glimmer/component';
import { action } from '@ember/object';
// import { bool } from '@ember/object/computed';
import { connect } from 'ember-redux';
import { getMappedFiguresList } from '../../../selectors/figure';
import { getPatentSpecification } from '../../../selectors/patent-specification';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

const stateToComputed = (state) => ({
  patentSpecification: getPatentSpecification(state),
  mappedFiguresList: getMappedFiguresList(state)
});

const dispatchToActions = {};

class PatentSpecificationContext extends Component {
  @service store;
  @service tracking;
  @tracked isOverflowed = false;
  scrollableComponent = null;

  get styleNamespace() {
    return podNames['preview-patent-specification-context'];
  }

  get classNames() {
    let classNames = [this.styleNamespace];
    return classNames.join(' ');
  }

  @action
  scrollTo(domElementId) {
    const containerDomElement = document.getElementById('patent-specification');
    const domElement = document.getElementById(domElementId);
    const offsetTop = domElement.offsetTop;
    const padding = 60;
    containerDomElement.scrollTop = offsetTop - padding;
  }
 
}

export default connect(stateToComputed, dispatchToActions)(PatentSpecificationContext);
