import { enqueueTask, task } from 'ember-concurrency-decorators';

import ENV from '../config/environment';
import Konva from 'konva';
import Service from '@ember/service';
import { ThresholdFilter } from '../utils/filters';
import { getAsset } from '../selectors/asset';
import { getCanvasBlob } from '../utils/image';
import { getImage } from '../selectors/image';
import moment from 'moment';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class ImageFiltersCache extends Service {
  @service worker;
  @service assets;
  @service redux;

  isTest = ENV.environment === 'test';

  @tracked blobCache;

  maxWorkers = 4;
  workerIndex = 0;

  constructor(owner, args) {
    super(owner, args);
    this.blobCache = {};
  }

  getImageElement(blobUrl) {
    return new Promise((resolve, reject) => {
      const assetFile = new Image();

      assetFile.onload = () => {
        resolve(assetFile);
      };

      assetFile.onerror = () => {
        reject('Error building image element');
      };
      assetFile.src = blobUrl;
    });
  }

  async getImageNode(image) {
    const {
      fileWidth,
      fileHeight,
      grayscale,
      threshold,
      thresholdValue,
      blobUrl,
    } = image;

    const imageNode = new Konva.Image({
      width: fileWidth,
      height: fileHeight,
    });
    const imageElement = await this.getImageElement(blobUrl);
    imageNode.image(imageElement);

    const filters = [];

    if (grayscale) {
      filters.push(Konva.Filters.Grayscale);
    }

    if (threshold) {
      filters.push(ThresholdFilter);
      imageNode.threshold(thresholdValue);
    }

    if (grayscale || threshold) {
      imageNode.cache();
      imageNode.filters(filters);
    }

    return imageNode;
  }

  async getCanvasBlob(data) {
    const { image } = data;
    const container = document.createElement('div');

    const stageWidth = image.fileWidth;
    const stageHeight = image.fileHeight;

    const stage = new Konva.Stage({
      container,
      width: stageWidth,
      height: stageHeight,
    });

    const layer = new Konva.Layer({ name: 'images' });

    stage.add(layer);

    const imageNode = await this.getImageNode(image);

    layer.add(imageNode);

    const canvas = stage._toKonvaCanvas()._canvas;
    const blob = await getCanvasBlob(canvas);

    stage.destroy();

    return blob;
  }

  checkJpegImage(base64string) {
    var src = base64string;
    var imageData = Uint8Array.from(
      atob(src.replace('data:image/jpeg;base64,', '')),
      (c) => c.charCodeAt(0)
    );
    var imageCorrupted =
      imageData[imageData.length - 1] === 217 &&
      imageData[imageData.length - 2] === 255;

    return imageCorrupted;
  }

  checkPNGImage(base64string) {
    var src = base64string;
    var imageData = Uint8Array.from(
      atob(src.replace('data:image/png;base64,', '')),
      (c) => c.charCodeAt(0)
    );
    var sequence = [0, 0, 0, 0, 73, 69, 78, 68, 174, 66, 96, 130]; // in hex:

    //check last 12 elements of array so they contains needed values
    for (var i = 12; i > 0; i--) {
      if (imageData[imageData.length - i] !== sequence[12 - i]) {
        return false;
      }
    }

    return true;
  }

  @enqueueTask({ maxConcurrency: 6 })
  *getImageBlob(_image) {
    const state = this.redux.getState();
    const asset = getAsset(state, _image.asset);
    const { width, height } = asset;
    const assetBlob = yield this.assets.getAssetBlob(_image.asset);
    const assetBlobUrl = yield this.assets.getAssetUrl(_image.asset);
    // const assetDataUrl = yield this.assets.getAssetDataUrl(_image.asset);

    const image = {
      ..._image,
      blob: assetBlob,
      blobUrl: assetBlobUrl,
      // dataUrl: assetDataUrl,
      fileWidth: width,
      fileHeight: height,
    };

    const data = {
      image,
    };

    const canOffscreenCanvas = typeof OffscreenCanvas !== 'undefined';

    let blob;

    if (canOffscreenCanvas) {
      this.workerIndex++;

      if (this.workerIndex >= this.maxWorkers) {
        this.workerIndex = 0;
      }

      let worker;

      if (this[`workerInstance${this.workerIndex}`]) {
        worker = this[`workerInstance${this.workerIndex}`];
      } else {
        worker = yield this.worker.open('konva-filter-image');
        this[`workerInstance${this.workerIndex}`] = worker;
      }

      blob = yield worker.postMessage(data);

      if (this.isTest) {
        worker.terminate();
        this[`workerInstance${this.workerIndex}`] = null;
      }
    } else {
      blob = yield this.getCanvasBlob(data);
    }

    return blob;
  }

  async cacheUrls(imageId) {
    const state = this.redux.getState();
    const image = getImage(state, imageId);
    const updatedAt = image.updatedAt || new Date();

    if (image.asset) {
      const blob = await this.getImageBlob.perform(image);

      const blobUrl = URL.createObjectURL(blob);

      this.blobCache[imageId] = {
        blob,
        blobUrl,
        updatedAt,
      };
    }
  }

  @task
  *getBlob(imageId) {
    const state = this.redux.getState();
    const image = getImage(state, imageId);
    const updatedAt = image.updatedAt;

    if (!image.asset) {
      return null;
    }

    let blob;

    if (
      this.blobCache[imageId] &&
      moment(updatedAt).isSame(this.blobCache[imageId].updatedAt)
    ) {
      blob = this.blobCache[imageId].blob;
    } else {
      try {
        blob = yield this.getImageBlob.perform(image);
        const blobUrl = URL.createObjectURL(blob);
        this.blobCache[imageId] = {
          blob,
          blobUrl,
          updatedAt,
        };
      } catch (err) {
        console.error(err);
      }
    }

    return blob;
  }

  @task
  *getBlobUrl(imageId) {
    const state = this.redux.getState();
    const image = getImage(state, imageId);

    if (!image) {
      return null;
    }

    if (!image.asset) {
      return null;
    }

    const updatedAt = image.updatedAt;

    let blobUrl;

    if (
      this.blobCache[imageId] &&
      moment(updatedAt).isSame(this.blobCache[imageId].updatedAt)
    ) {
      blobUrl = this.blobCache[imageId].blobUrl;
    } else {
      try {
        const blob = yield this.getImageBlob.perform(image);
        blobUrl = URL.createObjectURL(blob);
        this.blobCache[imageId] = {
          blob,
          blobUrl,
          updatedAt,
        };
      } catch (err) {
        console.error(err);
      }
    }

    return blobUrl;
  }
}
