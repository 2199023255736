import { action, computed } from '@ember/object';

import Component from '@glimmer/component';
import { connect } from 'ember-redux';
import { getDrawing } from '../../../selectors/drawing';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency-decorators';
import { tracked } from '@glimmer/tracking';
import uuid from 'uuid/v4';

const stateToComputed = (state, attrs) => ({
  drawing: getDrawing(state, attrs.drawingId),
});

const dispatchToActions = {};

class DrawingPopover extends Component {
  @service redux;
  @service figuresCacheKonva;
  @tracked blobUrl;

  language = 'report_list_abbreviated';

  constructor() {
    super(...arguments);
    this.popoverDomElementId = this.args.popoverDomElementId;
    this.domElementId = uuid();
    this.setBlobUrl.perform(this.args.drawingId);
  }


  get styleNamespace() {
    return podNames['drawing-popover'];
  }

  get classNames() {
    let classNames = [this.styleNamespace, 'drawing-popover'];
    if (this.isLandscape) classNames.push('is-landscape');
    return classNames.join(' ');
  }  
  
  @computed('drawing.orientation')
  get isLandscape() {
    return this.drawing && this.drawing.orientation === 'landscape';
  }

  @action
  didInsert() {
    const domElement = document.getElementById(this.domElementId);
    this._handleMouseenter = this.handleMouseenter.bind(this);
    this._handleMouseleave = this.handleMouseleave.bind(this);
    domElement.addEventListener('mouseenter', this._handleMouseenter, false);
    domElement.addEventListener('mouseleave', this._handleMouseleave, false);
  }

  willDestroy() {
    super.willDestroy(...arguments);
    const domElement = document.getElementById(this.domElementId);
    domElement &&
      domElement.removeEventListener(
        'mouseenter',
        this._handleMouseenter,
        false
      );
    domElement &&
      domElement.removeEventListener(
        'mouseleave',
        this._handleMouseleave,
        false
      );
  }

  handleMouseenter() {
    this.args.onMouseenter &&
      this.args.onMouseenter(
        this.args.elementId,
        this.args.elementVersionId,
        this.popoverDomElementId
      );
  }

  handleMouseleave() {
    this.args.onMouseleave && this.args.onMouseleave();
  }

  @task
  *setBlobUrl(drawingId) {
    const blobUrl = yield this.figuresCacheKonva.getBlobUrl.perform(drawingId);
    this.blobUrl = blobUrl;
  }
}

export default connect(stateToComputed, dispatchToActions)(DrawingPopover);
