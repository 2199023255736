import Component from '@glimmer/component';
import { action } from '@ember/object';
import podNames from 'ember-component-css/pod-names';
import { tracked } from '@glimmer/tracking';

class ReferencesSlider extends Component {
  @tracked activeIndex = 0;

  get styleNamespace() {
    return podNames['references-slider'];
  }
  
  get classNames() {
    let classNames = [this.styleNamespace, 'references-slider'];
    return classNames.join(' ');
  }

  get activeReferenceId() {
    return this.args.referencesList && this.args.referencesList[this.activeIndex];
  }

  get showNav() {
    return !this.args.hideNav && this.args.referencesList.length > 1;
  }

  @action
  onUpdate() {
    this.activeIndex = 0;
  }

  @action
  onClick() {
    if (this.args.onClick) {
      this.args.onClick(this.activeReferenceId)
    }
  }

  @action
  next() {
    this.activeIndex += 1;
    if (this.activeIndex > this.args.referencesList.length - 1) {
      this.activeIndex = 0;
    }
  }

  @action
  prev() {
    this.activeIndex -= 1;
    if (this.activeIndex < 0) {
      this.activeIndex = this.args.referencesList.length - 1;
    }
  }
}

export default ReferencesSlider;
