import { action, computed } from '@ember/object';
import {
  getActiveFeature,
  getActiveProductId,
} from '../../../selectors/invention-ui';

import Component from '@glimmer/component';
import FeatureTypes from '../../../constants/types/features';
import { connect } from 'ember-redux';
import { getFeature } from '../../../selectors/feature';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';

const dispatchToActions = {};

const stateToComputed = (state, attrs) => ({
  feature: getFeature(state, attrs.featureId),
  activeFeature: getActiveFeature(state),
  activeProductId: getActiveProductId(state),
});

class FeatureListItem extends Component {
  @service applicationState;
  @service data;

  popperOptions = Object.freeze({
    modifiers: {
      preventOverflow: {
        escapeWithReference: false,
      },
    },
  });

  get styleNamespace() {
    return podNames['feature-list-item'];
  }

  @computed('isActive', 'styleNamespace', 'args.{hideHeader,isInline}')
  get classNames() {
    let classNames = [this.styleNamespace, 'feature-list-item'];
    if (!this.args.hideHeader) classNames.push('show-header');
    if (this.isActive) classNames.push('is-active');
    if (!this.isActive) classNames.push('isnt-active');
    if (this.args.isInline) classNames.push('is-inline');
    return classNames.join(' ');
  }

  @computed('feature.type')
  get type() {
    return this.feature && FeatureTypes[this.feature.type];
  }

  @computed('activeFeature', 'args.featureId')
  get isActive() {
    return this.activeFeature === this.args.featureId;
  }

  @computed('applicationState.focusedInputModelId', 'args.featureId')
  get isFocused() {
    return this.applicationState.focusedInputModelId === this.args.featureId;
  }

  @action
  onInputClick(event) {
    if (this.isActive) {
      event.preventDefault();
      event.stopPropagation();
    }
  }

  @action
  onItemClick() {
    if (this.args.onSetActiveFeature) {
      if (this.isActive) {
        this.args.onSetActiveFeature(null);
      } else {
        this.args.onSetActiveFeature(this.args.featureId);
      }
    }
  }

  @action
  onFocus() {
    if (this.args.onSetActiveFeature) {
      if (this.isActive) {
        // do nothing
      } else {
        this.args.onSetActiveFeature(this.args.featureId);
      }
    }
  }

  @action
  onBackgroundClick() {
    if (this.args.onSetActiveFeature) {
      if (this.isActive) {
        this.args.onSetActiveFeature(null);
      } else {
        this.args.onSetActiveFeature(this.args.featureId);
      }
    }
  }

  @action
  onEnter() {
    if (this.args.onEnter) {
      if (this.args.onSetActiveFeature) {
        this.args.onSetActiveFeature(null);
      }
      this.args.onEnter(this.args.featureId, this.type);
    } else {
      if (this.args.onSetActiveFeature) {
        this.args.onSetActiveFeature(null);
      }
    }
  }

  @action
  onRemove() {
    if (this.args.onRemove) {
      this.args.onRemove(this.args.featureId);
    }
  }

  @action
  onAdd() {
    if (this.args.onRemove) {
      this.args.onAdd(this.args.featureId);
    }
  }

  @action
  onUpdateFeature(featureId, attributes) {
    this.data.updateFeature(featureId, attributes);
  }
}

export default connect(stateToComputed, dispatchToActions)(FeatureListItem);
