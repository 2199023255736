import Component from '@glimmer/component';
import { computed } from '@ember/object';
import { connect } from 'ember-redux';
import podNames from 'ember-component-css/pod-names';
import { getProduct } from '../../../selectors/product';

const stateToComputed = (state, attrs) => ({
  product: getProduct(state, attrs.productId),
});

const dispatchToActions = {};

class FeatureNoveltyIndicator extends Component {
  get styleNamespace() {
    return podNames['feature-novelty-indicator'];
  }

  // eslint-disable-next-line ember/require-computed-property-dependencies
  @computed('isTotallyNovel')
  get classNames() {
    let classNames = [this.styleNamespace, 'feature-novelty-indicator'];
    if (this.isTotallyNovel) classNames.push('is-totally-novel');
    return classNames.join(' ');
  }

  @computed('product.comparisonsList.[]')
  get comparisonsList() {
    return (this.product && this.product.comparisonsList) || [];
  }

  @computed('comparisonsList.[]', 'args.{novelFeaturesList,featureId}')
  get isTotallyNovel() {
    const novelOccurences = this.args.novelFeaturesList
      ? this.args.novelFeaturesList.filter(
          (featureId) => featureId === this.args.featureId
        )
      : [];
    return novelOccurences.length && novelOccurences.length === this.comparisonsList.length;
  }
}

export default connect(
  stateToComputed,
  dispatchToActions
)(FeatureNoveltyIndicator);
