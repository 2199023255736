import { action, computed } from '@ember/object';

import Component from '@glimmer/component';
import ENV from '../../../config/environment';
import FeatureTypes from '../../../constants/types/features';
import { batchGroupBy } from '../../../utils/redux';
import { connect } from 'ember-redux';
import { debounce } from '@ember/runloop';
import { getElement } from '../../../selectors/element';
import { getElementVersion } from '../../../selectors/element-version';
import { getFeature } from '../../../selectors/feature';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { updateFeature } from '../../../actions/feature';
import { updateMethod } from '../../../actions/method';

const stateToComputed = (state, attrs) => ({
  feature: getFeature(state, attrs.featureId),
});

const dispatchToActions = {};

class FeatureValueMeasurementEditor extends Component {
  @service models;
  @service clipboard;

  @tracked switched = false;
  @tracked isFocused = false;
  @tracked _value = '';

  get styleNamespace() {
    return podNames['feature-value-measurement-editor'];
  }

  get classNames() {
    let classNames = [this.styleNamespace];
    return classNames.join(' ');
  }

  @computed('feature.type')
  get type() {
    return this.feature && FeatureTypes[this.feature.type];
  }

  @computed('type.inputs.measurement_operator.options')
  get options() {
    return (
      this.type &&
      this.type.inputs &&
      this.type.inputs.measurement_operator.options
    );
  }

  @computed('_value.measurement_type')
  get measurementType() {
    return this._value && this._value.measurement_type;
  }

  @computed('_value.measurement_value')
  get measurementValue() {
    return this._value && this._value.measurement_value;
  }

  @computed('_value.measurement_operator', 'options.[]')
  get measurementOperator() {
    const options = this.options || [];

    const measurementOperator = (
      this._value &&
      options.find((option) => option.id === this._value.measurement_operator)
    );
      console.log('measurementOperator', measurementOperator)
    return measurementOperator;
  }

  @computed('type.inputs.measurement_type.placeholder')
  get measurementTypePlaceholder() {
    return (
      this.type &&
      this.type.inputs &&
      this.type.inputs.measurement_type.placeholder
    );
  }

  @computed('type.inputs.measurement_value.placeholder')
  get measurementValuePlaceholder() {
    return (
      this.type &&
      this.type.inputs &&
      this.type.inputs.measurement_value.placeholder
    );
  }

  @action
  onInsert() {
    this._value = this.value;
  }

  @action
  onUpdate() {
    this.switched = !this.switched;
    this.updateValue();
  }

  @computed('feature', 'args.featureId')
  get featureModel() {
    return this.models.findOrCreate(
      this.args.featureId,
      'feature',
      this.feature
    );
  }

  @computed('feature.value')
  get value() {
    return this.feature && this.feature.value;
  }

  updateValue() {
    const state = this.redux.getState();
    const feature = getFeature(state, this.args.featureId);
    this._value = 'feature.value';
    this._value = feature.value;
  }

  updateFeature(attributes) {
    batchGroupBy.start();

    this.redux.store.dispatch(updateFeature(this.args.featureId, attributes));

    if (this.feature.type === 'element_step') {
      const state = this.redux.getState();
      const element = getElement(state, this.feature.element);
      if (element && element.elementVersion) {
        const elementVersion = getElementVersion(state, element.elementVersion);
        if (elementVersion.method) {
          this.redux.store.dispatch(updateMethod(elementVersion.method, {}));
        }
      }
    }
    batchGroupBy.end();
  }

  isEqual(value1, value2) {
    return JSON.stringify(value1) === JSON.stringify(value2);
  }

  deboundUpdateFeature(args) {
    const value = args.value;
    this.updateFeature({ value });
  }

  @action
  onDebounceEdit(inputId, inputValue) {
    const value = {
      ...this.value,
      [inputId]: inputValue,
    };
    if (!this.isEqual(value, this.value)) {
      this.featureModel.set('value', value);
      debounce(this, this.deboundUpdateFeature, { value }, ENV.DEBOUNCE_MS);
    }
  }

  @action
  onEnter(value) {
    if (this.args.onEnter) {
      if (!this.isEqual(value, this.value)) {
        this.updateFeature({ value });
      }
      this.args.onEnter();
    }
  }

  @action
  onSelect(option) {
    const value = {
      ...this.feature.value,
      measurement_operator: option.id,
    };
    console.log('value', value)
    if (!this.isEqual(value, this.value)) {
      this.redux.store.dispatch(updateFeature(this.args.featureId, { value }));
      this.updateValue();
    }
  }

  @action
  onMeasurementTypeTributeElement(value) {
    const _value = {
      ...this.feature.value,
      measurement_type: value,
    };
    this.redux.store.dispatch(
      updateFeature(this.args.featureId, { value: _value })
    );
    this.updateValue();
  }
  @action
  onMeasurementValueTributeElement(value) {
    const _value = {
      ...this.feature.value,
      measurement_value: value,
    };
    this.redux.store.dispatch(
      updateFeature(this.args.featureId, { value: _value })
    );
    this.updateValue();
  }
}

export default connect(
  stateToComputed,
  dispatchToActions
)(FeatureValueMeasurementEditor);
