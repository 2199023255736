import ENV from '../config/environment';
import Service from '@ember/service';
import axios from 'axios';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class SessionManager extends Service {
  @service session;
  @service tracking;
  @service store;
  @service router;

  @tracked user;

  get userId() {
    return (
      this.session.data.authenticated && this.session.data.authenticated.user_id
    );
  }

  get accessToken() {
    return (
      this.session.data.authenticated &&
      this.session.data.authenticated.access_token
    );
  }

  async loadUser() {
    if (this.userId) {
      const user = await this.store.findRecord('user', this.userId, {
        reload: true,
      });
      this.user = user;
      this.tracking.identify(this.userId, user.email, user.fullName);
    } else {
      this.user = null;
    }

    return true;
  }

  async activate(token) {
   
    try {
      const res = await axios({
        method: 'POST',
        url: ENV.IDENTITY_API_URL + '/authentication',
        data: {
          email: null,
          password: token,
          strategy: 'magic'
        }
      });

      // authentcate session
      await this.session.authenticate('authenticator:magic', {
        user_id: res.data.user.id,
        access_token: res.data.accessToken,
      });

      this.tracking.identify(res.data.user.id, res.data.user.email);
      this.tracking.trackEvent('logged_in', { userId: res.data.user.id, email: res.data.user.email });
      
    } catch (err) {
      console.log('err');
      console.error(err);
      this.tracking.trackEvent('login_token_expired');
      this.router.transitionTo('expired');
    }
  }
}
