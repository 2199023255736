export default {
  attributes: {
    element: {
      type: 'uuid',
      defaultValue: null
    },
    primaryInstance: {
      type: 'uuid',
      defaultValue: null
    },
    instancesList: {
      type: 'array',
      defaultValue: []
    },
    createdAt: {
      type: 'date'
    },
    updatedAt: {
      type: 'date'
    },
  }
};