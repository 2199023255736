import { action, computed } from '@ember/object';
import { getAsset, getAssetsList } from '../../../selectors/asset';
import { getImage, getImagesList } from '../../../selectors/image';
import { getPriorArt, getPriorArtsList } from '../../../selectors/prior-art';
import { getProduct, getProductsList } from '../../../selectors/product';
import { sort, union } from '@ember/object/computed';

import Component from '@glimmer/component';
import { batchGroupBy } from '../../../utils/redux';
import { connect } from 'ember-redux';
import { getInventionUi } from '../../../selectors/invention-ui';
import moment from 'moment';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

const dispatchToActions = {};

const stateToComputed = (state) => ({
  inventionUi: getInventionUi(state),
  assetsList: getAssetsList(state),
  imagesList: getImagesList(state),
  productsList: getProductsList(state),
  priorArtsList: getPriorArtsList(state),
});

class AssetsContext extends Component {
  @service applicationState;
  @service data;
  @service tracking;
  @service redux;

  @tracked switched;

  get classNames() {
    let classNames = [this.styleNamespace, 'assets-context'];
    return classNames.join(' ');
  }

  get styleNamespace() {
    return podNames['assets-context'];
  }

  // eslint-disable-next-line ember/require-computed-property-dependencies
  @computed('assetsList.[]')
  get assets() {
    const state = this.redux.getState();
    return this.assetsList.map((assetId) => {
      const asset = getAsset(state, assetId);
      const timestamp = moment(asset.createdAt).format('x');
      return {
        ...asset,
        timestamp,
      };
    });
  }

  // eslint-disable-next-line ember/require-computed-property-dependencies
  @computed('sortedAssetsList.[]')
  get mappedAssetsList() {
    return this.sortedAssetsList.map((asset) => asset.id);
  }

  createdSort = ['timestamp:desc'];

  @sort('assets', 'createdSort')
  sortedAssetsList;
  

  @computed('applicationState.undoIndex')
  get undoIndex() {
    return this.applicationState.undoIndex;
  }

  @computed('assetsList.[]')
  get firstAsset() {
    return this.assetsList && this.assetsList.length && this.assetsList[0];
  }

  @computed('inventionUi.creatingAsset')
  get creatingAsset() {
    return this.inventionUi && this.inventionUi.creatingAsset;
  }

  @computed('inventionUi.editingAsset')
  get editingAssetId() {
    return this.inventionUi && this.inventionUi.editingAsset;
  }

  @computed('inventionUi.selectedAssets.[]', 'firstAsset', 'creatingAsset')
  get selectedAssetId() {
    let selectedAssetId =
      (this.inventionUi &&
        this.inventionUi.selectedAssets &&
        this.inventionUi.selectedAssets[0]) ||
      this.firstAsset;

    return this.creatingAsset ? null : selectedAssetId;
  }

  @computed('assetsInUse.[]', 'selectedAssetId')
  get canRemoveSelectedAsset() {
    return !this.assetsInUse.includes(this.selectedAssetId);
  }

  @computed('imagesList.[]')
  get imageAssets() {
    const state = this.redux.getState();
    return this.imagesList.map((imageId) => {
      const image = getImage(state, imageId);
      return image.asset;
    });
  }

  @computed('productsList.[]')
  get productAssets() {
    const state = this.redux.getState();
    return this.productsList.map((productId) => {
      const product = getProduct(state, productId);
      return product.asset;
    });
  }

  @computed('priorArtsList.[]')
  get priorArtAssets() {
    const state = this.redux.getState();
    return this.priorArtsList.find((priorArtId) => {
      const priorArt = getPriorArt(state, priorArtId);
      return priorArt.asset;
    });
  }

  @union('imageAssets', 'productAssets', 'priorArtAssets') assetsInUse;

  @computed('canRemoveSelectedAsset')
  get removeDisabled() {
    return !this.canRemoveSelectedAsset;
  }

  @action
  onUpdate() {
    this.switched = !this.switched;
  }

  @action
  addAsset(attributes) {
    batchGroupBy.start();
    this.data.addAsset(attributes);
    batchGroupBy.end();
    this.tracking.trackEvent('created_asset');
  }

  @action
  async onFileUpload(file, externalUrl) {
    const fileObj = { file, externalUrl };
    const parsedAssets = await this.data.addAssetsFromFiles([fileObj]);
    batchGroupBy.start();
    this.data.addAssets(parsedAssets);
    batchGroupBy.end();
    this.tracking.trackEvent('created_asset');
  }

  @action
  removeAsset(assetId) {
    batchGroupBy.start();
    this.data.removeAsset(assetId);
    batchGroupBy.end();
    this.tracking.trackEvent('removed_asset');
  }

  @action
  editAsset(assetId) {
    batchGroupBy.start();
    this.data.editAsset(assetId);
    batchGroupBy.end();
  }

  @action
  cancelEditAsset() {
    batchGroupBy.start();
    this.data.editAsset(null);
    batchGroupBy.end();
  }

  @action
  createAsset() {
    batchGroupBy.start();
    this.data.setCreatingAsset(true);
    batchGroupBy.end();
  }

  @action
  cancelCreateAsset() {
    batchGroupBy.start();
    this.data.setCreatingAsset(false);
    batchGroupBy.end();
  }

  @action
  updateAsset(assetId, attributes) {
    batchGroupBy.start();
    this.data.updateAsset(assetId, attributes);
    this.data.editAsset(null);
    batchGroupBy.end();
  }

  @action
  onSortAssetsList(assetsList) {
    console.log('assetsList', assetsList);
    batchGroupBy.start();
    this.data.sortAssetsList(assetsList);
    batchGroupBy.end();
  }

  @action
  selectAsset(assetId) {
    batchGroupBy.start();
    this.data.selectAsset(assetId);
    batchGroupBy.end();
    this.tracking.trackEvent('selected_asset');
  }
}

export default connect(stateToComputed, dispatchToActions)(AssetsContext);
