import Component from '@glimmer/component';
import { action } from '@ember/object';
import moment from 'moment';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';

class HistoryVersionListItem extends Component {
  // @service data;
  // @service redux;
  @service tracking;

  get styleNamespace() {
    return podNames['history-version-list-item'];
  }

  get classNames() {
    let classNames = [this.styleNamespace, 'history-version-list-item'];
    if (this.args.isSelected) classNames.push('is-selected');
    return classNames.join(' ');
  }

  msToTime(duration) {
    // let milliseconds = parseInt((duration % 1000) / 100);
    // seconds = Math.floor((duration / 1000) % 60),
    let minutes = Math.floor((duration / (1000 * 60)) % 60);
    let hours = Math.floor(duration / (1000 * 60 * 60));
    // hours = Math.floor((duration / (1000 * 60 * 60)) % 24)

    // minutes = (minutes < 10) ? "0" + minutes : minutes;
    // seconds = (seconds < 10) ? "0" + seconds : seconds;

    if (hours) {
      return `${hours}h ${minutes}m`;
    } else {
      return `${minutes}m`;
    }
  }

  get timeFromInstanceCreatedAt() {
    const createdAt = moment(this.args.createdAt);
    const instanceCreatedAt = moment(this.args.instanceCreatedAt);
    const diff = createdAt.diff(instanceCreatedAt, 'milliseconds');
    return this.msToTime(diff);
  }

  get createdAtName() {
    return (
      this.args.createdAt &&
      moment(this.args.createdAt).format('MMMM Do, h:mm a')
    );
  }

  get name() {
    return this.args.name || this.createdAtName;
  }

  get userName() {
    return (
      this.args.user && `${this.args.user.firstName} ${this.args.user.lastName}`
    );
  }

  @action
  didInsert() {}

  @action
  onClick() {
    if (this.args.onClick) {
      this.args.onClick(this.args.versionId);
    }
  }

  @action
  onVersionDownload() {
    if (this.args.onVersionDownload) {
      this.args.onVersionDownload(this.args.versionId);
    }
  }
}

export default HistoryVersionListItem;
