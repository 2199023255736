import {
  ADD_TERM,
  REMOVE_TERM,
  SORT_TERMS_LIST,
  UPDATE_TERM
} from '../actions/actionTypes';

import { addFromSchema } from '../utils/schema';
import { omit } from 'lodash';

const initialState = {
  ids: [],
  entities: {},
};

const termsReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case ADD_TERM: {
      let { termId, attributes } = payload;
      const id = termId;
      const createdAt = new Date();
      const updatedAt = new Date();
      const term = addFromSchema('term', {
        ...attributes,
        id,
        createdAt,
        updatedAt,
      });

      return {
        ids: [...state.ids, id],
        entities: {
          ...state.entities,
          [termId]: term,
        },
      };
    }

    case UPDATE_TERM: {
      const { termId, attributes } = payload;
      const updatedAt = new Date();
      return {
        ...state,
        entities: {
          ...state.entities,
          [termId]: {
            ...state.entities[termId],
            ...attributes,
            updatedAt,
          },
        },
      };
    }

    case REMOVE_TERM: {
      const { termId } = payload;
      return {
        ids: state.ids.filter((id) => id !== termId),
        entities: omit(state.entities, termId),
      };
    }

    case SORT_TERMS_LIST: {
      const { termIds } = payload;
      return {
        ...state,
        ids: termIds
      };
    }

    /* istanbul ignore next */
    default:
      return state;
  }
};

export default termsReducer;
