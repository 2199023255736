import {
  ADD_PRIOR_ART,
  UPDATE_PRIOR_ART,
  REMOVE_PRIOR_ART,
  SORT_PRIOR_ARTS_LIST,
} from '../actions/actionTypes';
import { addFromSchema } from '../utils/schema';
import { omit } from 'lodash';

const initialState = {
  ids: [],
  entities: {},
};

const priorArtReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case ADD_PRIOR_ART: {
      let { priorArtId, attributes } = payload;
      const id = priorArtId;
      const createdAt = new Date();
      const updatedAt = new Date();
      const priorArt = addFromSchema('prior-art', {
        ...attributes,
        id,
        createdAt,
        updatedAt,
      });

      return {
        ids: [...state.ids, id],
        entities: {
          ...state.entities,
          [priorArtId]: priorArt,
        },
      };
    }

    case UPDATE_PRIOR_ART: {
      const { priorArtId, attributes } = payload;
      const updatedAt = new Date();
      return {
        ...state,
        entities: {
          ...state.entities,
          [priorArtId]: {
            ...state.entities[priorArtId],
            ...attributes,
            updatedAt,
          },
        },
      };
    }

    case REMOVE_PRIOR_ART: {
      const { priorArtId } = payload;
      return {
        ids: state.ids.filter((id) => id !== priorArtId),
        entities: omit(state.entities, priorArtId),
      };
    }

    case SORT_PRIOR_ARTS_LIST: {
      const { priorArtIds } = payload;
      return {
        ...state,
        ids: priorArtIds,
      };
    }

    /* istanbul ignore next */
    default:
      return state;
  }
};

export default priorArtReducer;
