import Component from '@glimmer/component';
import podNames from 'ember-component-css/pod-names';

class UiDottedOutline extends Component {

  get styleNamespace() {
    return podNames['ui-dotted-outline'];
  }

  get classNames() {
    let classNames = [this.styleNamespace, 'ui-dotted-outline'];
    return classNames.join(' ');
  }
  
}

export default UiDottedOutline;
