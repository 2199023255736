import { get } from '@ember/object';
import { getPresentState } from '../utils/redux';

export const getPriorArt = (state, priorArtId) =>
  get(getPresentState(state), `priorArts.entities.${priorArtId}`);
  
export const getPriorArts = (state) =>
  get(getPresentState(state), `priorArts.entities`);

export const getPriorArtsList = (state) => get(getPresentState(state), `priorArts.ids`);
