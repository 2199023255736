import Component from '@glimmer/component';
import { computed } from '@ember/object';
import { connect } from 'ember-redux';
import { getFeature } from '../../../selectors/feature';
import { getFeatureRealization } from '../../../utils/realization';
import { getMethod } from '../../../selectors/method';
import { getMethodNodeFeatureId } from '../../../selectors/method-node';
import { getMethodStepRealization } from '../../../utils/method';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';

const dispatchToActions = {};

const stateToComputed = (state, attrs) => ({
  method: getMethod(state, attrs.methodId),
  featureId:
    attrs.modelType === 'method-node'
      ? getMethodNodeFeatureId(state, attrs.modelId)
      : null,
});

class MethodStepItem extends Component {
  @service models;
  @service redux;

  get classNames() {
    let classNames = [this.styleNamespace, 'method-step-item'];
    if (this.args.isSelected) classNames.push('is-selected');
    return classNames.join(' ');
  }

  get styleNamespace() {
    return podNames['method-step-item'];
  }

  @computed('featureId')
  get featureModel() {
    const state = this.redux.getState();
    return (
      this.featureId &&
      this.models.findOrCreate(
        this.featureId,
        'feature',
        getFeature(state, this.featureId)
      )
    );
  }

  @computed('featureModel.{id,value,type,element}')
  get featureRealization() {
    const state = this.redux.getState();
    return (
      this.featureModel &&
      getFeatureRealization({
        state,
        featureModels: [this.featureModel],
        featuresList: [this.featureModel.id],
        featureTypeId: this.featureModel.type,
        elementId: this.featureModel.element,
        language: 'patent_specification_preferred_version',
        // language: 'report_list',
      })
    );
  }

  @computed(
    'featureRealization',
    'args.{methodId,step,traversedNodesList,traversedEdgesList,index,language}'
  )
  get realization() {
    const state = this.redux.getState();
    const text = this.featureRealization || '';
    return getMethodStepRealization(
      state,
      this.args.methodId,
      this.args.step,
      this.args.traversedNodesList,
      this.args.traversedEdgesList,
      this.args.index,
      this.args.language,
      'patent_specification_preferred_version',
      text
    );
  }
}

export default connect(stateToComputed, dispatchToActions)(MethodStepItem);
