import { get } from '@ember/object';
import { getMethodNode } from './method-node';
import { getPresentState } from '../utils/redux';

export const getMethodEdge = (state, methodEdgeId) =>
  get(getPresentState(state), `methodEdges.entities.${methodEdgeId}`);

export const getMethodEdges = (state) =>
  get(getPresentState(state), `methodEdges.entities`);

export const getMethodEdgeSource = (state, methodEdgeId) => {
  const methodEdge = getMethodEdge(state, methodEdgeId);
  return (
    methodEdge && methodEdge.source && getMethodNode(state, methodEdge.source)
  );
};

export const getMethodEdgeTarget = (state, methodEdgeId) => {
  const methodEdge = getMethodEdge(state, methodEdgeId);
  return (
    methodEdge && methodEdge.target && getMethodNode(state, methodEdge.target)
  );
};
