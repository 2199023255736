import Component from '@glimmer/component';
import { connect } from 'ember-redux';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';

const stateToComputed = null;

const dispatchToActions = {};

class PatentSpecificationPreview extends Component {
  @service patentSpecificationDocx;

  get styleNamespace() {
    return podNames['patent-specification-preview'];
  }

  get classNames() {
    let classNames = [this.styleNamespace, 'patent-specification-preview'];
    return classNames.join(' ');
  }

  get paragraphs() {
    const state = this.redux.getState();
    let paragraphs = this.patentSpecificationDocx.getDocumentParagraphs(state);
    let paragraphIndex = 1;
    paragraphs = paragraphs.map(paragraph => {
      if (paragraph.numbered && paragraph.content) {
        const paragraphNumber = this.patentSpecificationDocx.getLeadingZeros(paragraphIndex);
        paragraph.number = paragraphNumber;
        paragraphIndex++;
      }
      return paragraph;
    })
    return paragraphs;
  }

  
}

export default connect(stateToComputed, dispatchToActions)(PatentSpecificationPreview);
