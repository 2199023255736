// import * as Sentry from '@sentry/browser';

import ENV from '../config/environment';
import Service from '@ember/service';
import { getMeta } from '../selectors/meta';
import {keys} from 'lodash';
import mixpanel from 'mixpanel-browser';
import { restartableTask } from 'ember-concurrency-decorators';
import { inject as service } from '@ember/service';
import { timeout } from 'ember-concurrency';
// import trackingEvents from '../constants/tracking-events';
import { underscore } from '@ember/string';

export default class Tracking extends Service {
  @service redux;
  @service sessionTime;

  version = ENV.releaseVersion;

  trackEvent(id = '', properties = {}) {
    // const trackingEvent = trackingEvents
    //   .filterBy('source', 'app')
    //   .findBy('id', id);

    // if (!trackingEvent) {
    //   // eslint-disable-next-line no-console
    //   return console.info('Error Tracking: cant find user event with id', id);
    // }

    // update the session time
    this.sessionTime.update(true);

    const state = this.redux.getState();
    const meta = getMeta(state);
    const data = {};

    data.disclosureId = meta.disclosure;
    data.instanceId = meta.instance;
    data.sessionTime = meta.sessionTime;
    data.version = this.version;

    // map properties if any
    let propertyKeys = keys(properties)
    if (propertyKeys.length) {
      // underscore the keys
      propertyKeys = propertyKeys.map(key => underscore(key));

      // add the properties array to the data
      data.properties = propertyKeys;

      // add each prop to the data with a value of true
      propertyKeys.forEach(key => {
        data[`property_${key}`] = true;
      })
    }

    const prefix = 'app:';
    const eventId = `${prefix}${id}`;
    mixpanel.track(eventId, data);

    if (ENV.environment === 'development') {
      // eslint-disable-next-line no-console
      console.info('Track:', eventId, data);
    }
  }

  debounceTrackEvent(id, data) {
    this._debounceTrackEvent.perform(id, data);
  }

  identify(id, email, name) {
    // identify on mixpanel
    mixpanel.identify(id);
    mixpanel.people.set_once({ $email: email, $name: name });

    // identify user for sentry (error reporting service)
    // Sentry.setUser({ id, email });
  }

  reset() {
    mixpanel.reset();
  }

  @restartableTask()
  *_debounceTrackEvent(id, data, DEBOUNCE_MS = ENV.DEBOUNCE_MS) {
    yield timeout(DEBOUNCE_MS);
    this.trackEvent(id, data);
  }
}
