import Component from '@glimmer/component';
import { action } from '@ember/object';
import podNames from 'ember-component-css/pod-names';
import { throttle } from '@ember/runloop';
import uuid from 'uuid/v4';

class UiLeftContextResizable extends Component {
  domElementId = `resizable-${uuid()}`;
  domElement = null;
  dragging = false;

  get styleNamespace() {
    return podNames['ui-left-context-resizable'];
  }

  get classNames() {
    let classNames = [this.styleNamespace];
    return classNames.join(' ');
  }

  @action
  didInsert() {
    this.onMousedown = this.handleMousedown.bind(this);
    this.onMouseup = this.handleMouseup.bind(this);
    this.onMousemove = this.handleMousemove.bind(this);

    this.domElement = document.getElementById(this.domElementId);

    this.domElement.addEventListener('mousedown', this.onMousedown)

    window.addEventListener('mouseup', this.onMouseup)
    window.addEventListener('mousemove', this.onMousemove)
  }

  willDestroy() {
    this.domElement.removeEventListener('mousedown', this.onMousedown)
    window.removeEventListener('mouseup', this.onMouseup)
    window.removeEventListener('mousemove', this.onMousemove)
  }

  handleMousedown() {
    this.dragging = true;
    document.body.style.userSelect = 'none';
    document.body.style.cursor = 'ew-resize';
  }

  handleMouseup() {

    if (this.dragging) {
      this.args.onResizeEnd();
    }
    this.dragging = false;
    document.body.style.userSelect = 'auto';
    document.body.style.cursor = 'auto';
  }

  handleMousemove(event) {
    if (this.dragging) {
      throttle(() => {
        this.args.onResize(event.clientX);
      }, 10);
    }
  }
}

export default UiLeftContextResizable;
