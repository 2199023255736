import { action, computed } from '@ember/object';
import {
  deselectElementVersion,
  selectElementVersion,
} from '../../../actions/invention-ui';
import {
  getActiveContextTab,
  getActiveProductId,
  getCollapsedDescendantsList,
  getCollapsedNodesList,
  getPreviewMode,
  getSelectedChildren,
  getSelectedDescendants,
  getSelectedElementVersions,
  getSelectedElements,
} from '../../../selectors/invention-ui';
import {
  getElementVersionsMap,
  getElementsMap,
  getGraphIndex,
  getOrphanNodesList,
  getRootNodeId,
} from '../../../selectors/graph';

import Component from '@glimmer/component';
import { connect } from 'ember-redux';
import { getElement } from '../../../selectors/element';
import { getElementVersion } from '../../../selectors/element-version';
import { getPreferredElementVersion } from '../../../selectors/product';
import { getPrimaryInstancesList } from '../../../selectors/component';
import podNames from 'ember-component-css/pod-names';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import uuid from 'uuid/v4';

const dispatchToActions = {};

const stateToComputed = (state, attrs) => ({
  activeContextTab: getActiveContextTab(state),
  graphIndex: getGraphIndex(state),
  collapsedNodesList: getCollapsedNodesList(state, attrs.productId),
  selectedElements: getSelectedElements(state),
  selectedElementVersions: getSelectedElementVersions(state),
  primaryInstancesList: getPrimaryInstancesList(state),
  previewMode: getPreviewMode(state),
});

class ElementsContext extends Component {
  @service models;
  @service data;
  @service applicationState;
  @service tracking;
  @service redux;
  @service contextMenu;

  @tracked switched = false;
  @tracked isMousedown = false;
  @tracked isDragging = false;
  @tracked draggedId;
  @tracked draggedDescendants = [];
  @tracked draggedType;
  @tracked draggedOverId;
  @tracked draggedOverArea;
  @tracked mouseCoordinatesX = 0;
  @tracked mouseCoordinatesY = 0;

  @tracked environmentNodes = [];
  @tracked nodesFromInvention = [];

  dragDistanceThreshold = 5;

  domElementId = `elements-context-${uuid()}`;

  get styleNamespace() {
    return podNames['elements-context'];
  }

  get classNames() {
    let classNames = [this.styleNamespace, 'elements-context'];
    return classNames.join(' ');
  }

  @action
  didInsert() {
    this._graphIndex = this.graphIndex;
    this._productId = this.args.productId;
    this._collapsedNodesLength = this.collapsedNodesList.length;

    this._handleMouseup = this.handleMouseup.bind(this);
    window.addEventListener('mouseup', this._handleMouseup);

    this._handleMousemove = this.handleMousemove.bind(this);
    window.addEventListener('mousemove', this._handleMousemove);

    const domElement = document.getElementById(this.domElementId);
    if (domElement) {
      this._handleMouseleave = this.handleMouseleave.bind(this);
      domElement.addEventListener('mouseleave', this._handleMouseleave);
    }

    this.updateNodes();
  }

  @action
  willDestroyNode() {
    window.removeEventListener('mouseup', this._handleMouseup);
    const domElement = document.getElementById(this.domElementId);
    if (domElement) {
      domElement.removeEventListener('mouseleave', this._handleMouseleave);
    }
  }

  @action
  onUpdate(elem, [graphIndex, productId, collapsedNodesLength]) {
    let detailsChanged = false;

    if (this._graphIndex !== graphIndex) {
      this._graphIndex = graphIndex;
      detailsChanged = true;
    }

    if (this._productId !== productId) {
      this._productId = productId;
      detailsChanged = true;
    }

    if (this._collapsedNodesLength !== collapsedNodesLength) {
      this._collapsedNodesLength = collapsedNodesLength;
      detailsChanged = true;
    }

    if (detailsChanged) {
      this.updateNodes();
    }
  }

  updateNodes() {
    const state = this.redux.getState();
    this.environmentNodes = this.getEnvironmentNodes(state);
    this.nodesFromInvention = this.getNodesFromInvention(state);
    console.log('updating nodes')
  }

  startDrag() {
    if (this.draggedId && this.draggedType) {
      // set application state dragging
      this.data.selectElement(this.draggedId);
      const nodesMap = this.getNodesMap();
      const node = nodesMap[this.draggedId];
      this.isDragging = true;
      this.draggedDescendants = (node && node.descendants) || [];
    }
  }

  resetDrag() {
    this.isDragging = false;
    this.draggedId = null;
    this.draggedDescendants = [];
    this.draggedType = null;
    this.draggedOverId = null;
    this.draggedOverArea = null;
    this.isMousedown = false;
    this.mouseCoordinatesX = 0;
    this.mouseCoordinatesY = 0;
  }

  @action
  onMousedown(event, id, type) {
    // this.data.selectElement(id);
    this.draggedId = id;
    this.draggedType = type;
    this.isMousedown = true;
    this.mouseCoordinatesX = event.clientX || 0;
    this.mouseCoordinatesY = event.clientY || 0;
  }

  @action
  onElementClick(id, isSelected) {
    this.data.selectElement(id, isSelected);
  }

  @action
  onElementContextMenu(event, elementId) {
    event.preventDefault();
    this.data.selectElement(elementId);

    this.contextMenu.open('element', {
      targetId: elementId,
      x: event.clientX,
      y: event.clientY,
    });
  }
  

  handleMousemove(event) {
    // this.dragDistance =
    // const dragDistanceThresholdReached = this.dragDistance > this.dragDistanceThreshold;

    // const dx = d3Event.dx;
    // const dy = d3Event.dy;
    // const distance = Math.sqrt(dx * dx + dy * dy);

    // this.incrementProperty('dragDistance', distance);

    if (this.isMousedown) {
      const dx = event.clientX - this.mouseCoordinatesX;
      const dy = event.clientY - this.mouseCoordinatesY;
      const distance = Math.sqrt(dx * dx + dy * dy);
      const dragDistanceThresholdReached =
        distance > this.dragDistanceThreshold;
      if (dragDistanceThresholdReached) {
        this.startDrag();
      }
    }
  }

  handleMouseleave() {
    this.draggedOverId = null;
    this.draggedOverArea = null;
  }

  handleMouseup() {
    if (this.isDragging) {
      this.handleDrop();
    }
    this.resetDrag();
  }

  handleDrop() {
    const state = this.redux.getState();
    const collapsedDescendantsList = getCollapsedDescendantsList(
      state,
      this.args.productId
    );
    const nodesMap = this.getNodesMap();
    const draggedNode = this.draggedId && nodesMap[this.draggedId];
    const droppedNode = this.draggedOverId && nodesMap[this.draggedOverId];
    const droppedNodeArea = this.draggedOverArea;
    if (draggedNode && droppedNode && droppedNodeArea) {
      const canDrop = this.canDrop(
        draggedNode,
        droppedNode,
        droppedNodeArea,
        collapsedDescendantsList
      );
      if (canDrop) {
        this.args.onDropNode(
          draggedNode.id,
          draggedNode.type,
          droppedNode.id,
          droppedNode.type,
          droppedNodeArea
        );
      }
    }
  }
  canDrop(draggedNode, droppedNode, droppedNodeArea, collapsedDescendantsList) {
    const isSameNode = draggedNode.id === droppedNode.id;
    const isSameCategory = draggedNode.category === droppedNode.category;
    const isSameType = draggedNode.type === droppedNode.type;
    const droppedNodeIsDescendant =
      draggedNode.descendants &&
      draggedNode.descendants.includes(droppedNode.id);
    const isDraggedParent = draggedNode.parent === droppedNode.id;
    const droppedNodeIsCollapsed = !collapsedDescendantsList.includes(
      droppedNode.id
    );
    const hasChildren = droppedNode.children && droppedNode.children.length;
    const hasVisibleChildren = hasChildren && !droppedNodeIsCollapsed;

    let canDrop =
      isSameType && isSameCategory && !droppedNodeIsDescendant && !isSameNode;

    if (canDrop && droppedNodeArea) {
      switch (droppedNodeArea) {
        case 'top':
          canDrop = true;
          break;
        case 'middle':
          canDrop = !isDraggedParent;
          break;
        case 'bottom':
          canDrop = !hasVisibleChildren && !isDraggedParent;
          break;
      }
    }

    return canDrop;
  }

  @computed('applicationState.shiftKeyDown')
  get shiftKeyDown() {
    return this.applicationState.shiftKeyDown;
  }

  @computed('shiftKeyDown')
  get multiSelectMode() {
    return this.shiftKeyDown;
  }

  // eslint-disable-next-line ember/require-computed-property-dependencies
  @computed('selectedElements.[]', 'selectedElementVersions.[]')
  get selectedDescendants() {
    const state = this.redux.getState();
    return getSelectedDescendants(state);
  }

  @computed('selectedElements.[]', 'selectedElementVersions.[]')
  get selectedChildren() {
    const state = this.redux.getState();
    return getSelectedChildren(state);
  }

  getNodesFromInvention(state, productId) {
    productId = productId || getActiveProductId(state);
    const nodesMap = productId
      ? getElementsMap(state, productId)
      : getElementVersionsMap(state, productId);
    const collapsedDescendantsList = getCollapsedDescendantsList(
      state,
      productId
    );
    const rootNodeId = getRootNodeId(state);
    const rootNode = nodesMap[rootNodeId];
    const allNodes = [rootNodeId, ...rootNode.descendants];

    const visibleDescendants = allNodes.filter(
      (nodeId) => !collapsedDescendantsList.includes(nodeId)
    );

    const mappedNodes = visibleDescendants.map((nodeId) => {
      const node = nodesMap[nodeId];
      const type = node?.type;

      let model;
      let instanceOfModel;
      let preferredElementVersionModel;

      switch (type) {
        case 'element':
          model = getElement(state, nodeId);

          if (model.instanceOf) {
            instanceOfModel = getElement(state, model.instanceOf);
          }

          if (productId) {
            preferredElementVersionModel = getPreferredElementVersion(
              state,
              nodeId,
              productId
            );
          }

          break;
        case 'element-version':
          model = getElementVersion(state, nodeId);
          break;
      }

      return {
        id: node.id,
        depth: node.depth,
        type,
        model,
        category: model.category,
        instanceOfModel,
        preferredElementVersionModel,
        parent: node.parent,
        children: node.children,
      };
    });

    return mappedNodes;
  }

  getNodesMap() {
    const state = this.redux.getState();
    return this.args.productId
      ? getElementsMap(state, this.args.productId)
      : getElementVersionsMap(state, this.args.productId);
  }

  getEnvironmentNodes(state, productId) {
    productId = productId || getActiveProductId(state);
    const mappedNodes = [];

    const nodesMap = productId
      ? getElementsMap(state, productId)
      : getElementVersionsMap(state, productId);
    const collapsedDescendantsList = getCollapsedDescendantsList(
      state,
      productId
    );
    const orphanNodesList = getOrphanNodesList(state);

    orphanNodesList.forEach((orphanNodeId) => {
      const orphanNode = nodesMap[orphanNodeId];
      let descendants = orphanNode && orphanNode.descendants;

      const allNodes = [orphanNodeId, ...descendants];

      const visibleDescendants = allNodes.filter(
        (nodeId) => !collapsedDescendantsList.includes(nodeId)
      );

      visibleDescendants.forEach((nodeId) => {
        const node = nodesMap[nodeId];
        const type = node?.type;

        let model;
        let instanceOfModel;
        let preferredElementVersionModel;
        switch (type) {
          case 'element':
            model = getElement(state, nodeId);
            if (model.instanceOf) {
              instanceOfModel = this.getModel(state, model.instanceOf, type);
            }

            if (productId) {
              preferredElementVersionModel = getPreferredElementVersion(
                state,
                nodeId,
                productId
              );
            }
            break;
          case 'element-version':
            model = getElementVersion(state, nodeId);
            break;
        }
        mappedNodes.push({
          id: node.id,
          depth: node.depth,
          type,
          category: model.category,
          model,
          instanceOfModel,
          preferredElementVersionModel,
          parent: node.parent,
          children: node.children,
        });
      });
    });

    return mappedNodes;
  }


  getModel(state, id, type) {
    let model;
    switch (type) {
      case 'element': {
        // let element = getElement(state, id);
        model = getElement(state, id);
        // model =
        //   this.models.find(element.id) ||
        //   this.models.findOrCreate(element.id, 'element', element);
        break;
      }
      case 'element-version':
        model = getElementVersion(state, id);
        // model =
        //   this.models.find(id) ||
        //   this.models.findOrCreate(
        //     id,
        //     'element-version',
        //     getElementVersion(state, id)
        //   );
        break;
    }
    return model;
  }

  @action
  createElement(category) {
    this.args.onCreateParentlessElement(null, null, category);
    this.tracking.trackEvent('explorer_created_system');
  }

  @action
  zoomToNode(nodeId) {
    if (this.args.onZoomToNode) {
      this.args.onZoomToNode(nodeId);
      this.tracking.trackEvent('explorer_zoomed_to_node');
    }
  }

  @action
  onToggleCollapsedNode(nodeId) {
    if (this.args.onToggleCollapsedNode) {
      this.args.onToggleCollapsedNode(nodeId);
    }
  }

  @action
  selectElementVersion(elementVersionId, isSelected) {
    let dispatch;
    if (isSelected) {
      if (this.multiSelectMode) {
        dispatch = deselectElementVersion(elementVersionId);
      }
    } else {
      dispatch = selectElementVersion(elementVersionId, this.multiSelectMode);
      this.tracking.trackEvent('explorer_selected_system');
    }

    if (dispatch) {
      this.redux.store.dispatch(dispatch);
    }
  }

  @action
  onDragover(event, type, id, area) {
    this.draggedOverId = id;
    this.draggedOverArea = area;
  }

  @action
  onToggleCollapsedProducts() {}
}

export default connect(stateToComputed, dispatchToActions)(ElementsContext);
