import { get } from '@ember/object';
import { getPresentState } from '../utils/redux';

export const getTerm = (state, termId) =>
  get(getPresentState(state), `terms.entities.${termId}`);
export const getTermsList = (state) => get(getPresentState(state), `terms.ids`);
export const getTerms = (state) =>
  get(getPresentState(state), `terms.entities`);
export const getTermName = (state, termId) => {
  const term = getTerm(state, termId);
  return term && term.name;
};
