import Component from '@ember/component';
import { connect } from 'ember-redux';

const dispatchToActions = {};


const ImageUpload = Component.extend({
  attributeBindings: ['testId:data-test-id'],
  testId: null,
  actions: {
    uploadImage(dropped) {
      const file = dropped.files ? dropped.files[0] : dropped.blob;
      this.onFileUpload(file);
    },
    getExternalImage(url, file) {
      this.onFileUpload(file, url);
    }
  }
});

export default connect(
  null,
  dispatchToActions
)(ImageUpload);
