export default {
  attributes: {
    product: {
      type: 'uuid',
      defaultValue: null
    },
    present: {
      type: 'uuid',
      defaultValue: null
    },
    past: {
      type: 'array',
      defaultValue: []
    },
    path: {
      type: 'array',
      defaultValue: []
    },
    view: {
      type: 'string',
      defaultValue: 'graph' // methods, drawings
    },
    activeContextTab: {
      type: 'string',
      defaultValue: 'elements' // history, terms
    },
    showingExplorer: {
      type: 'boolean',
      defaultValue: false
    },
    reviewMode: {
      type: 'boolean',
      defaultValue: false
    }
  }
};

